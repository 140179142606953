import React, { useState, useEffect, useContext } from 'react';
import { Form } from 'react-bootstrap'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import CustomizedDialogs from '../../modalWindowComponent/CustomizedDialogs'
import service from './service'
import { ROUTE_SUPER_DICTIONARIES, PAGING_END_INDEX, DEFAULT_PAGING_SIZE, PAYMENT_ADJUSTMENT_TYPE_CSV_NAME } from '../../../utilities/staticConfigs';
import { ADD_SUCCESS, ADD_ERROR, UPDATE_SUCCESS, DELETE_SUCCESS } from '../../../utilities/labelConfigs';
import Notify from '../../commons/notify';
import Pagination from '../../commons/pagination';
import LoadingContext from '../../../container/loadingContext'
import i18n from '../../../utilities/i18n';
import TextInput from '../../commons/input/input';
import { csvFileCheck } from '../../../utilities/validations';
import CustomizedSmallDialogs from '../../modalWindowComponent/CustomisedSmallDialog';
import Table from '../../commons/Table/Table';
import { checkPermission, commonTableBody } from '../../../utilities/commonUtilities';
import { PaymentAdjustmentTableData } from './PaymentAdjustmentTable';
import BackArrowWithLabel from "../../commons/Back"; 
import CommonButton from "../../commons/Buttons";
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import { super_admin_permission_key_value, super_admin_privileges } from '../../../utilities/permissions';

const PaymentAdjustmentType = (props) => {
	const [showModalWindow, setShowModalWindow] = useState(false);
	const [showDeleteModalWindow, setShowDeleteModalWindow] = useState(false);
	const [paymentAdjustmentType, setPaymentAdjustmentType] = useState('');
	const [description, setDescription] = useState('');
	const [deletePaymentAdjustmentType, setDeletePaymentAdjustmentType] = useState('');
	const [deletePaymentAdjustmentTypeId, setDeletePaymentAdjustmentTypeId] = useState('');
	const [editPaymentAdjustmentTypeId, setEditPaymentAdjustmentTypeId] = useState('');
	const [paymentAdjustmentTypeList, setPaymentAdjustmentTypeList] = useState([]);
	const [form_error, setFormError] = useState({ 'paymentAdjustmentType': '', 'description': '' });
	const [editForm, setEditForm] = useState(false);
	const [header, setHeader] = useState('')
	const [searchValueClose, setSearchValueClose] = useState(0);
	const [searchValue, setSearchValue] = useState('');
	const [orderType, setOrderType] = useState('');
	const [orderingField, setOrderingField] = useState('name'); /* show the triangle only in clicked header */
	const [permission, setPermission] = useState(false);
	//start ==== Alert message properties
	const [showNotify, setShowNotify] = useState('hide');
	const [notifyDescription, setNotifyDescription] = useState('');
	const [notifyType, setNotifyType] = useState('success');
	const setShowLoadingBar = useContext(LoadingContext);

	function showNotifyWindow(action, type, desc, age = 3000) {
		if (action === 'show') {
			setTimeout(() => {
				setShowNotify('hide');
			}, age)
		}
		setShowNotify(action);
		setNotifyType(type);
		setNotifyDescription(desc);

	}
	// end

	//Pagination start
	const [totalPage, setTotalPage] = useState(1);
	const [activePage, setActivePage] = useState(1);
	const [startIndex, setStartIndex] = useState(0);
	const [endIndex, setEndIndex] = useState(PAGING_END_INDEX);

	function onPagePrevious() {
		let previousPage = startIndex + 1 - PAGING_END_INDEX;
		setActivePage(previousPage);

		if (startIndex !== 0) {
			setStartIndex(startIndex - PAGING_END_INDEX);
			setEndIndex(endIndex - PAGING_END_INDEX);
		}
		getPaymentAdjustmentTypeData(DEFAULT_PAGING_SIZE, previousPage, orderingField);
	}

	function onPageUp(e) {
		let page = Number(e.target.id)
		setActivePage(page);
		getPaymentAdjustmentTypeData(DEFAULT_PAGING_SIZE, page, orderingField);
	}

	function onPageNext() {
		let nextPage = startIndex + 1 + PAGING_END_INDEX;
		if (endIndex === totalPage || totalPage <= PAGING_END_INDEX) {
			setActivePage(nextPage);
			setStartIndex(startIndex);
			setStartIndex(endIndex);

		} else {
			setActivePage(nextPage);
			setStartIndex(startIndex + PAGING_END_INDEX);
			setEndIndex(endIndex + PAGING_END_INDEX);

		}
		getPaymentAdjustmentTypeData(DEFAULT_PAGING_SIZE, nextPage, orderingField);

	}
	//Pagination ends

	function addNew() {
		if (!permission) {
			showNotifyWindow('show','error', i18n.t('errorMessages.permission_error'));
			return;
		}
		setHeader(i18n.t("dictionariesPages.dictPaymentAdjustmentType.addHeader"));
		setShowModalWindow(true);
	}

	function onHide() {
		setEditForm(false);
	}
	function onDeleteHide() {
		setDeletePaymentAdjustmentType('');
		setDeletePaymentAdjustmentTypeId('');
		setShowDeleteModalWindow(false);
	}

	function getPaymentAdjustmentTypeData(pageSize, page, fieldName, sorting) {
		let fieldOrderType = '';
		if (sorting) {
			if (!orderType) fieldOrderType = '-';
		} else {
			fieldOrderType = orderType;
		}
		setShowLoadingBar(true);
		const result = service.GetListPaymentAdjustmentType(pageSize, page, searchValue, fieldName, fieldOrderType);

		result.then(response => {
			if (response.data.results !== undefined) {
				setTotalPage(Math.ceil(response.data.count / response.data.page_size));
			}
            const rowArray = commonTableBody(response.data.results, PaymentAdjustmentTableData.tableBodyData[0]);
            PaymentAdjustmentTableData.tableBodyData = rowArray;
			setPaymentAdjustmentTypeList(response.data.results);
			setShowLoadingBar(false);
		});
	}

	useEffect(() => {
		getPaymentAdjustmentTypeData(DEFAULT_PAGING_SIZE, activePage);
		setPermission(checkPermission(super_admin_privileges.super_admin_full_privilege,super_admin_permission_key_value.dictionary_add))
	}, [searchValueClose]);

	function onEditPaymentAdjustmentType(id) {
		setShowLoadingBar(true);
		const data = service.GetPaymentAdjustmentType(id);
		data.then(response => {
			setShowLoadingBar(false);
			setHeader(i18n.t("dictionariesPages.dictPaymentAdjustmentType.editHeader"));
			setShowModalWindow(true);
			setPaymentAdjustmentType(response.data.name);
			setDescription(response.data.description);
			setEditForm(true);
			setEditPaymentAdjustmentTypeId(id);
		});
	}
	function onDeletePaymentAdjustmentType(id) {
		paymentAdjustmentTypeList.map((item) => {
			if (item.id === parseInt(id)) {
				setDeletePaymentAdjustmentType(item.name);
				setDeletePaymentAdjustmentTypeId(item.id);
				setShowDeleteModalWindow(true);
			}

		});
	}

	function onDeleteAction() {
		setShowLoadingBar(true);
		const data = service.DeletePaymentAdjustmentType(deletePaymentAdjustmentTypeId);
		data.then(() => {
			setShowLoadingBar(false);
			setDeletePaymentAdjustmentType('');
			setDeletePaymentAdjustmentTypeId('');
			setShowDeleteModalWindow(false);
			showNotifyWindow('show', 'success', DELETE_SUCCESS);
			getPaymentAdjustmentTypeData(DEFAULT_PAGING_SIZE, activePage);
		});
	}

	function resetForm() {
		setPaymentAdjustmentType('');
		setDescription('');
		setEditForm(false);
		setEditPaymentAdjustmentTypeId("");
		setFormError({
			...form_error,
			['paymentAdjustmentType']: '',
			['description']: ''
		});
		setSearchValueClose(0)
	}
	function onSaveFormData(e) {
		e.preventDefault();
		setFormError({
			...form_error,
			['paymentAdjustmentType']: !paymentAdjustmentType.trim() ? 'error' : '',
			['description']: !description ? 'error' : ''
		});
		if (paymentAdjustmentType && description) {
			setShowLoadingBar(true);
			let result = null;
			result = editForm ? service.UpdatePaymentAdjustmentType(editPaymentAdjustmentTypeId, { name: paymentAdjustmentType, description: description }) :
				service.AddPaymentAdjustmentType({ name: paymentAdjustmentType, description: description });

			result.then(response => {
				setShowLoadingBar(false);
				if (response.status === 201 || response.status === 200) {
					if (response.status === 201)
						showNotifyWindow('show', 'success', ADD_SUCCESS);
					else showNotifyWindow('show', 'success', UPDATE_SUCCESS);

					getPaymentAdjustmentTypeData(DEFAULT_PAGING_SIZE, activePage);
					resetForm();
					setShowModalWindow(false);

				} else if (response.status === 400 && response.data.record_already_exists !== undefined) {
					showNotifyWindow('show', 'error', i18n.t('errorMessages.record_exists'));
				} else {
					showNotifyWindow('show', 'error', ADD_ERROR);
				}


			});
		}

	}
	function onHandleChange(e) {
		const { name } = e.target;
		let value = e.target.value;
		let trimmedValue = '';
		trimmedValue = value;
		if (!trimmedValue) {
			setFormError({
				...form_error,
				[name]: 'error'
			});
		} else {
			setFormError({
				...form_error,
				[name]: ''
			});
		}
		if (name === 'paymentAdjustmentType')
			setPaymentAdjustmentType(trimmedValue);
		else if (name === 'searchValue') setSearchValue(trimmedValue);
		else { setDescription(trimmedValue) }
	}

	
	function backToDictionaryPage() {
		props.history.push(ROUTE_SUPER_DICTIONARIES)
	}

	function onHandleCsvImport(e) {
		e.preventDefault();
		document.getElementById('file').click();
	}

	function onHandleCSVChange(e) {
		const value =
			e.target.type === "file" ? e.target.files[0] : e.target.value;

		if (e.target.type === "file") {
			if (e.target.files[0].name !== PAYMENT_ADJUSTMENT_TYPE_CSV_NAME) {
				showNotifyWindow('show', 'error', i18n.t('errorMessages.invalid_file_name'));
			} else if (csvFileCheck(e.target.files[0].name)) {
				const data = new FormData();
				data.append('file', value);
				let result = service.ImportCSVData(data);
				result.then(response => {
					setShowLoadingBar(false);
					if (response.data.message !== undefined) {
						if (response.data.message === 'no_file_records') {
							showNotifyWindow('show', 'error', i18n.t('errorMessages.empty_file'));
						} else if (response.data.message === 'invalid_headings') {
							showNotifyWindow('show', 'error', i18n.t('errorMessages.invalid_headings'));
						} else if (response.data.message === 'data_imported') {
							if (response.data.existing_name && response.data.existing_name.length > 0) {
								showNotifyWindow('show', 'warning', i18n.t('errorMessages.record_not_imported') + ' \n ' + response.data.existing_name.join(',\n'));
							} else {
								showNotifyWindow('show', 'success', i18n.t('validations.success.import_success'));
							}
							getPaymentAdjustmentTypeData(DEFAULT_PAGING_SIZE, activePage);
						}
					} else {
						showNotifyWindow('show', 'error', i18n.t('errorMessages.import_failed'));
					}
				});
			} else {
				showNotifyWindow('show', 'error', i18n.t('errorMessages.invalid_file_format'));
			}
		}
	}

	const handleKeyDown = (e) => {
		if (e.key === 'Enter') {
			onSearchPaymentAdjustmentType(e);
		}
	}

	function onSearchPaymentAdjustmentType(e, fieldName) {
		e.preventDefault();
		setActivePage(1);
		setStartIndex(0);
		setEndIndex(PAGING_END_INDEX);
		if (!fieldName) { // if not field name then call from ,search button click
			setOrderType('');
		}
		setTotalPage(0)
		getPaymentAdjustmentTypeData(DEFAULT_PAGING_SIZE,1, fieldName, true);
	}
	function onSortingPaymentAdjustmentType(e, fieldName) {
		e.preventDefault();
		setStartIndex(0);
		setEndIndex(PAGING_END_INDEX);
		if (!fieldName) { 
			setOrderType('');
		}
		setTotalPage(0)
		getPaymentAdjustmentTypeData(DEFAULT_PAGING_SIZE,activePage, fieldName, true);
	}


	const tableSorting = (e) => {
		setOrderingField('name');
		PaymentAdjustmentTableData.tableHeadings[0].initialOrdering = false;
		if (!orderType) {
			PaymentAdjustmentTableData.tableHeadings[0].orderType = '-';
			setOrderType('-');
		}
		else {
			PaymentAdjustmentTableData.tableHeadings[0].orderType = '';
			setOrderType('');
		}
		onSortingPaymentAdjustmentType(e, 'name');
	}

	function dropDownFunction(id, name) {
		if (!permission) {
			showNotifyWindow('show','error', i18n.t('errorMessages.permission_error'));
			return;
		}
        if (name.toLowerCase() == 'edit') {
            onEditPaymentAdjustmentType(Number(id));
        }
        if (name.toLowerCase() == 'delete') {
            onDeletePaymentAdjustmentType(Number(id));
        }
    }

	return (
		<React.Fragment>
			<Notify showNotify={showNotify} setShowNotify={setShowNotify} notifyDescription={notifyDescription} setNotifyType={setNotifyType} setNotifyDescription={setNotifyDescription} notifyType={notifyType} />
			<div className="col-md-12">
				<div className="box box-content-white">
					<div className="row margin-top4 margin-bottom8">
						<div className="col">
							<div className={'dataTables_filter'}>
								<div className="link dictionaries-back pl-0" onClick={backToDictionaryPage}><BackArrowWithLabel label={i18n.t("dictionariesPages.dictionaries")} /></div>
							</div>
						</div>
					</div>
					<div className="box-content">
						<div className="common-search-wrapper-style">
							<div className="input-content-box">
								<div className="form-group padding-top15">
									<TextInput type="text" 
									name="searchValue" 
									value={searchValue} 
									onValueChange={onHandleChange} 
									label={i18n.t("dictionariesPages.dictPaymentAdjustmentType.codeOrDescription")} 
									onKeyDown={handleKeyDown} />
									{(searchValue.length !== 0) && <IconButton
										className="templateIconBtn_close"
										onClick={() => {
										setSearchValue('');
										setActivePage(1);
										setSearchValueClose(searchValueClose + 1)
										}} >
										<CloseIcon sx={{ fontSize: "small", color: '#9093a4' }} />
									</IconButton>
									}
								</div>
							</div>
							<div className="padding-top17">
								<div className="input-content-box padding-top24">
									<button type="button" onClick={(e) => onSearchPaymentAdjustmentType(e)} className="btn btn-primary-blue">{i18n.t("dictionariesPages.dictPaymentAdjustmentType.search")}</button>
								</div>
							</div>
						</div>
					</div>
				</div>

				<div className="box box-content-white margin-top10">
					<div className="">
						<div className="alignRight margin-bottom10 margin-top3">
							<OverlayTrigger
								placement="top"
								delay={{ show: 250, hide: 400 }}
								overlay={<Tooltip id="download-tooltip" style={{
									opacity: 0.4,
								}}>{i18n.t('commons.importCSV')}
								</Tooltip>}
							>
								<CommonButton icon="download" variant="outlined" label="Import" onClick={(e) => onHandleCsvImport(e)}  /> 
							</OverlayTrigger>
							<input name="csvFile" type="file" accept=".csv" id="file" onChange={onHandleCSVChange} style={{ display: "none" }} />
							<div className="alignRight margin-left15">
								<CommonButton variant='contained' onClick={addNew} label={i18n.t("buttons.addNew")} />
							</div>
						</div>
						<div className="table-responsive">
							<Table tableObject={PaymentAdjustmentTableData} dropDownFunction={dropDownFunction} sortingFunction={tableSorting} />
						</div>
						<div className='mt-2 mb-2'>
						<Pagination totalPage={totalPage} activePage={activePage} startIndex={startIndex} endIndex={endIndex} onPagePrevious={onPagePrevious} onPageUp={onPageUp} onPageNext={onPageNext} />
						</div>
					</div>
				</div>
			</div>
			<CustomizedDialogs showModal={showModalWindow} type="save" header={header} setShowModalWindow={setShowModalWindow} resetForm={resetForm} onHide={onHide}>
				<Form id="form_dataEntry" autoComplete="off" onSubmit={(e) => onSaveFormData(e)} encType="multipart/form-data">
					<Form.Group>
						<div className="row">
							<div className="col-md-12">
								<div className="form-group padding-top15">
									<TextInput type="text" id="paymentAdjustmentType" name="paymentAdjustmentType" required={true} label={i18n.t('dictionariesPages.dictPaymentAdjustmentType.lblPaymentAdjustmentType')} onValueChange={onHandleChange} className={form_error.paymentAdjustmentType ? 'input-error' : ""} value={paymentAdjustmentType} />
								</div>
								<div className="form-group">
									<label htmlFor='input-description'>{i18n.t('dictionariesPages.dictPaymentAdjustmentType.lblDescription')}</label>
									<div className={'text-input'}>
										<textarea id='input-description' placeholder={i18n.t('dictionariesPages.dictPaymentAdjustmentType.lblDescription')} name="description" required={true} className={!description ? 'textarea-100 input-error' : "textarea-100"} value={description} onChange={(e) => onHandleChange(e)}></textarea>
									</div>
								</div>
							</div>
						</div>
					</Form.Group>
				</Form>
			</CustomizedDialogs>
			<CustomizedSmallDialogs showModal={showDeleteModalWindow} header={i18n.t("commons.confirmDelete")} type="delete" deleteItem={onDeleteAction} resetForm={resetForm} onHide={onDeleteHide} setShowModalWindow={setShowDeleteModalWindow}>
				{i18n.t("dictionariesPages.dictPaymentAdjustmentType.deletConfirmation") + "'" + deletePaymentAdjustmentType + "'?"}
			</CustomizedSmallDialogs>
		</React.Fragment>
	)
}

export default PaymentAdjustmentType