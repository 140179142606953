import i18n from "../../../utilities/i18n"

const TableHeadData = [
    {
        "name": i18n.t("dictionariesPages.dictICD10.icd10"),
        "type": "string",
        "width": "small",
        "sort": true,
        "initialOrdering": true,
        "orderType": ''
    },
    {
        "name": i18n.t("dictionariesPages.dictICD10.shortDescription"),
        "type": "string",
        "width": "large",
        "sort": false,
    },
    {
        "name": i18n.t("dictionariesPages.dictICD10.gender"),
        "type": "string",
        "width": "small",
        "sort": false,
    },
    {
        "name": i18n.t("dictionariesPages.dictICD10.lowAge"),
        "type": "string",
        "width": "small",
        "sort": false,
    },
    {
        "name": i18n.t("dictionariesPages.dictICD10.highAge"),
        "type": "string",
        "width": "small",
        "sort": false,
    },
    {
        "name": i18n.t("dictionariesPages.dictICD10.effectiveDate"),
        "type": "string",
        "width": "small",
        "sort": false,
    },
    {
        "name": i18n.t("dictionariesPages.dictICD10.deletionDate"),
        "type": "string",
        "width": "small",
        "sort": false,
    },
    {
        "name": i18n.t("dictionariesPages.action"),
        "type": "number",
        "width": "action_column",
        "sort": false,
    },
]

export const TableBodyData = [
    [
        {
            "id": "",
            "name": "name",
            "value": "",
            "type": "string",
            "align": "left",
            "width": "small",
        },
        {
            "id": "",
            "name": "short_description",
            "value": "",
            "type": "string",
            "align": "left",
            "width": "large",
        },
        {
            "id": "",
            "name": "gender_name",
            "value": "",
            "type": "string",
            "align": "center",
            "width": "small",
        },
        {
            "id": "",
            "name": "low_age",
            "value": "",
            "type": "string",
            "align": "center",
            "width": "small",
        },
        {
            "id": "",
            "name": "high_age",
            "value": "",
            "type": "string",
            "align": "center",
            "width": "small",
        },
        {
            "id": "",
            "name": "effective_date",
            "value": "",
            "type": "Date",
            "align": "center",
            "width": "small",
            "needDateFormat" : "yes"
        },
        {
            "id": "",
            "name": "deletion_date",
            "value": "",
            "type": "Date",
            "align": "center",
            "width": "small",
            "needDateFormat" : "yes"
        },
        {
            "id": "",
            "name": "action",
            "value": "",
            "type": "button",
            "align": "center",
            "width": "action_column",
            "dropdown": [
                {
                    'name': 'Edit'
                },
                {
                    'name': 'Delete'
                },
            ]
        },
    ]
]

export const TableBodyAdminData = [
    [
        {
            "id": "",
            "name": "name",
            "value": "",
            "type": "string",
            "align": "left",
            "width": "small",
        },
        {
            "id": "",
            "name": "short_description",
            "value": "",
            "type": "string",
            "align": "left",
            "width": "large",
        },
        {
            "id": "",
            "name": "gender_name",
            "value": "",
            "type": "string",
            "align": "center",
            "width": "small",
        },
        {
            "id": "",
            "name": "low_age",
            "value": "",
            "type": "string",
            "align": "center",
            "width": "small",
        },
        {
            "id": "",
            "name": "high_age",
            "value": "",
            "type": "string",
            "align": "center",
            "width": "small",
        },
        {
            "id": "",
            "name": "effective_date",
            "value": "",
            "type": "Date",
            "align": "center",
            "width": "small",
        },
        {
            "id": "",
            "name": "deletion_date",
            "value": "",
            "type": "Date",
            "align": "center",
            "width": "small",
        },
        {
            "id": "",
            "name": "action",
            "value": "",
            "type": "button",
            "align": "center",
            "width": "action_column",
            "dropdown": [
                {
                    'name': 'Edit'
                },
            ]
        },
    ]
]

export const ICD10Table = {
    "name": "simpleTable",
    "isResize": false,
    "isFixedHead": true,
    "tableHeadings": TableHeadData,
    "tableBodyData": TableBodyData
}

export const ICD10AdminTable = {
    "name": "simpleTable",
    "isResize": false,
    "isFixedHead": true,
    "tableHeadings": TableHeadData,
    "tableBodyData": TableBodyAdminData
}

// ************************ TABLE FOR REMITTANCE REMARK CODE - ADD SCREEN ******************************

const ICD10AddTableHeader =  [
    {
        "name": i18n.t("dictionariesPages.dictICD10.icd10"),
        "type": "string",
        "width": "small",
        "sort": false,
    },
    {
        "name": i18n.t("dictionariesPages.dictICD10.shortDescription"),
        "type": "string",
        "width": "large",
        "sort": false
    },
    {
        "name": i18n.t("commons.select"),
        "type": "string",
        "width": "check_box",
        "sort": false,
    },
];

const ICD10AddTableBody = [
    [
        {   "id" : "",
            "name": "name",
            "value": "",
            "type": "string",
            "align": "left",
            "width": "small"
        }, 
        {   "id" : "",
            "name": "short_description",
            "value": "",
            "type": "string",
            "align": "left",
            "width": "large"
        },
        { 
            "id": "",
            "name": "checkbox",
            "type": "checkbox",
            "width": "check_box",
            "align": "center",
            "value": false
        },
    ]
]

export const ICD10AddTableObject = {
    "name": "simpleTable",
    "isResize": false,
    "isFixedHead": false,
    "noRecordsMsg" : i18n.t('commons.noRecords'), 
    "tableHeadings": ICD10AddTableHeader,
    "tableBodyData" : ICD10AddTableBody
}


