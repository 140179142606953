
import React from 'react'
const styles = {
    fontFamily: 'Lato',
    fontWeight: 400,
    fontSize: 13,
    color: '#757575'
}

const Label = (props) => {
    return (
        <label style={styles} className={props.labelClassName ? props.labelClassName : "" }>{props.label}</label>
    )
}

export default Label;