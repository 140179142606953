import i18n from "../../../utilities/i18n";

export const AssociateUsersTableBodyOnly = [
        [
            {   "id" : "",
                "name": "full_name",
                "value": "",
                "type": "string",
                "align": "left",
                "width": "small"
            },
            {   "id" : "",
                "name": "email",
                "value": "",
                "type": "string",
                "align": "left",
                "width": "small"
            },
            {   "id" : "",
                "name": "username",
                "value": "",
                "type": "string",
                "align": "left",
                "width": "small"
            },
            {
            "id": "",
            "name": "role",
            "value": "",
            "type": "string",
            "align": "center",
            "width": "small"
            },
            {   "id" : "",
                "name": "phone",
                "value": "",
                "type": "phone",
                "align": "left",
                "width": "small"
            },
            {   "id" : "",
                "name": "last_login",
                "value": "",
                "type": "string",
                "align": "left",
                "width": "small"
            },
            { "id": "",
                "name": "action",
                "type": "button",
                "align": "center",
                "width": "action_column",
                "dropdown": [
                    { "id": "",
                        "name": "Edit",
                    },
                ]
            },
        ]
    ]

export const AssociateUsersTableObject = {
    "name": "simpleTable",
    "isResize": false,
    "isFixedHead": false,
    "noRecordsMsg" : i18n.t('commons.noRecords'), 
    "tableHeadings": [
        {
            "name": i18n.t("userPages.associateUsers.labelName"),
            "type": "string",
            "width": "small",
            "sort": false
        },
        {
            "name": i18n.t("userPages.associateUsers.labelEmail"),
            "type": "string",
            "width": "small",
            "sort": false
        },
        {
            "name": i18n.t("userPages.associateUsers.labelUserID"),
            "type": "string",
            "width": "small",
            "sort": false
        },
        {
            "name": i18n.t("userPages.associateUsers.labelRole"),
            "type": "string",
            "width": "small",
            "sort": false
        },
        {
            "name": i18n.t("userPages.associateUsers.labelPhone"),
            "type": "string",
            "width": "small",
            "sort": false
        },
        {
            "name": i18n.t("userPages.associateUsers.labelLastLogin"),
            "type": "string",
            "width": "small",
            "sort": false
        },
        {
            "name": i18n.t("dictionariesPages.action"),
            "type": "button",
            "width": "action_column",
            "sort": false
        },
    ],
    "tableBodyData" : AssociateUsersTableBodyOnly
}

