import React, { useState, useEffect } from 'react';
import i18n from '../../utilities/i18n';
import SelectInput from '../commons/input/SelectInput';
import CustomizedDialogs from '../modalWindowComponent/CustomizedDialogs';
import { getStorage } from '../../utilities/browserStorage';
import service from "./service";
import claimService from '../ClaimsModule/SearchClaims/service';
import Pagination from '../commons/pagination';
import {PAGING_END_INDEX, DEFAULT_PAGING_SIZE} from '../../utilities/staticConfigs';
import { checkPermission, commonTableBody } from '../../utilities/commonUtilities';
import { FacilityNameTable } from './FacilityNameTable';
import Table from '../commons/Table/Table';
import { permission_key_values } from '../../utilities/permissions';
import Notify from '../commons/notify'; 

function FacilityNameMatch() {
    const practicePK = getStorage('practice');
    const [showAssignModal, setShowAssignModal] = useState(false);
    const [inputValue, setInputValue] = useState('');
    const [inputValueList, setInputValueList] = useState([]);
    const [matchingValue, setMatchingValue] = useState();
    const [matchingLocation, setMatchingLocation] = useState([]);
    const [matchingValueList, setMatchingValueList] = useState([]);
    const [matchingFacilityList, setMatchingFacilityList] = useState([]);
    const [isEdit, setIsEdit] = useState(false);
    const [editPK, setEditPK] = useState('');

    // notify
    const [showNotify, setShowNotify] = useState('hide');
    const [notifyDescription, setNotifyDescription] = useState('');
    const [notifyType, setNotifyType] = useState('success');

    //Pagination start
    const [totalPage, setTotalPage] = useState(1);
    const [activePage, setActivePage] = useState(1);
    const [startIndex, setStartIndex] = useState(0);
    const [endIndex, setEndIndex] = useState(PAGING_END_INDEX);
    const [headerFacilityNameMatch, setHeaderFacilityNameMatch] = useState("");

    useEffect(() => {
        ListMatchingValues(DEFAULT_PAGING_SIZE, 1);
        getServiceLocations();
    }, [])

    function onPagePrevious() {
      let previousPage = startIndex+1-PAGING_END_INDEX;
      setActivePage(previousPage);

      if(startIndex!==0){
          setStartIndex(startIndex - PAGING_END_INDEX);
          setEndIndex(endIndex - PAGING_END_INDEX);
      }
      ListMatchingValues(DEFAULT_PAGING_SIZE,previousPage);          
    }

    function onPageUp(e){
        let page = Number(e.target.id)
        setActivePage(page);
        ListMatchingValues(DEFAULT_PAGING_SIZE,page); 
    }

    function onPageNext() {
        let nextPage = startIndex+1+PAGING_END_INDEX;
        if(endIndex===totalPage || totalPage<=PAGING_END_INDEX){
            setActivePage(nextPage);
            setStartIndex(startIndex);
            setStartIndex(endIndex);
          
        }else{
          setActivePage(nextPage);
          setStartIndex(startIndex + PAGING_END_INDEX);
          setEndIndex(endIndex + PAGING_END_INDEX);

        }
        ListMatchingValues(DEFAULT_PAGING_SIZE,nextPage);
                
    }
    //Pagination ends

    function showNotifyWindow(action, type, desc, age = 3000) {
        if (action == 'show') {
            setTimeout(() => {
                setShowNotify('hide');
            }, age)
        }
        setShowNotify(action);
        setNotifyType(type);
        setNotifyDescription(desc);
    }

    function showAssign() {
        setHeaderFacilityNameMatch(i18n.t('interface.facilityNameMatch.new_match')); 
        setEditPK('');
        setInputValue('');
        setMatchingValue();
        getInputValues();
        setIsEdit(false);
        setShowAssignModal(true);
    }

    function onHandleChange(e) {
        let name = e.target.name;
        let value = e.target.value;
        if (name === 'inputValue') setInputValue(value);
        if (name === 'matchingValue'){
            let location_value = value.split("||")[0];
            let service_location_type = value.split("||")[1];
            setMatchingLocation({'id': location_value, 'location_type': service_location_type});
            setMatchingValue(value);
        } 
    }

    function ListMatchingValues(pageSize,page){
        const result = service.GetListMatchingFacility(pageSize,page, practicePK);
        result.then((response) => {
            if(response.data.results !== undefined){
                setTotalPage(Math.ceil(response.data.count/response.data.page_size));
            }
            const rowArray = commonTableBody(response.data.results, FacilityNameTable.tableBodyData[0]);
            FacilityNameTable.tableBodyData = rowArray;
            setMatchingFacilityList(response.data.results);
        })
    }

    function addAssigned(){
        if (!inputValue || !matchingValue) return;
        let data = {'practice': practicePK, 'service_location': matchingLocation, 'external_facility': inputValue};
        let result = '';
        if (!isEdit) {
            result = service.SaveNewMatchingFacility(data);
        } else {
            result = service.UpdateMatchingFacility(editPK, data);
        }
        if(result){
            result.then((response) => {
                if(response.status === 200|| response.status === 201){
                    ListMatchingValues(DEFAULT_PAGING_SIZE, 1);
                    setEditPK('');
                    setIsEdit(false);
                    setShowAssignModal(false);
                } else if(response.status === 400){
                    if(response.data.message) {
                        showNotifyWindow('show', 'error', i18n.t('errorMessages.'+response.data.message));
                    }
                    else if (Object.keys(response.data).length > 0) {
                        showNotifyWindow('show', 'error', i18n.t('errorMessages.'+Object.keys(response.data)[0]));
                    }
                }
            });
        }
        setShowAssignModal(false);
    }

    const getServiceLocations = () => {
        const result = claimService.ServiceLocationLists(practicePK);
        result.then(response => {
            let locations = []
            response.data.map((item) => {
                item.id = item.Id + '||' + item.location_type
                locations.push(item)
            })
            setMatchingValueList(locations);
        });
    };

    const getInputValues = (facilityPK) => {
        const result = service.ListInputFacilities(practicePK, facilityPK);
        result.then(response => {
            setInputValueList(response.data.inputs);
        });
    }

    const onEditFacilityMatching = (facilityPK) => {
        setHeaderFacilityNameMatch(i18n.t('interface.facilityNameMatch.edit_match')); 
        getInputValues(facilityPK);
        setIsEdit(true);
        setEditPK(facilityPK);
        const result = service.GetMatchingFacility(facilityPK);
        result.then((response) => {
            setInputValue(response.data.external_facility);
          
            let location_value = response.data.facility_location ? response.data.facility_location : 
                response.data.practice_location ? response.data.practice_location : '';
            let service_location_type = response.data.facility_location ? 'facility' : response.data.practice_location ?
                'practice' : '';
            setMatchingLocation({'id': location_value, 'location_type': service_location_type});
            setMatchingValue(location_value + '||' + service_location_type);
            setShowAssignModal(true);
        });
    }

    function dropDownFunction(id, name) {
        if (name === 'Edit'){
            onEditFacilityMatching(id);
        }
    }

    return (
        <div className='col-md-12'>
             <Notify showNotify={showNotify} setShowNotify={setShowNotify} notifyDescription={notifyDescription} setNotifyType={setNotifyType} setNotifyDescription={setNotifyDescription} notifyType={notifyType} />
            <div className='box basic-info-padding'>
                <div className='d-flex justify-content-between mb-2'>
                    <div className=''>
                        <h4>{i18n.t("interface.facilityNameMatch.matched_list")}</h4>
                    </div>
                    {/* permission checking */}
                    {checkPermission(permission_key_values.interface_facility_name_matching_add)&&
                    <button type='button' onClick={showAssign} className='btn btn-primary-blue px-4'>
                        {i18n.t("interface.facilityNameMatch.add_new")}
                    </button>
                    }
                </div>
                <div className=''>
                    <Table tableObject={FacilityNameTable} onLinkClick={dropDownFunction} list={matchingFacilityList} />
                
                    <Pagination totalPage={totalPage} activePage={activePage} startIndex={startIndex} endIndex={endIndex} onPagePrevious={onPagePrevious} onPageUp={onPageUp} onPageNext={onPageNext}/>
                </div>
                <div className=''>
                    <CustomizedDialogs header={headerFacilityNameMatch} showModal={showAssignModal} setShowModalWindow={setShowAssignModal}  type='assign' addAssigned={addAssigned}>
                        <div className='row justify-content-between'>
                            <div className='col mr-2'>
                                <SelectInput data={inputValueList} name={'inputValue'} value={inputValue} onValueChange={onHandleChange} label={i18n.t('interface.facilityNameMatch.input')} className={inputValue ? '' : 'input-error'} />
                            </div>
                            <div className='col ml-2'>
                                <SelectInput data={matchingValueList} name={'matchingValue'} value={matchingValue} onValueChange={onHandleChange} label={i18n.t('interface.facilityNameMatch.matching_name')} className={matchingValue ? '' : 'input-error'} />
                            </div>
                        </div>
                        
                    </CustomizedDialogs>
                </div>
            </div>
        </div>
    )
}

export default FacilityNameMatch