import React, { useEffect, useState, useContext } from "react";
import { Form } from "react-bootstrap";
import service from "./service";
import i18n from "../../../utilities/i18n";
import {
  YesOrNo,
} from "../../../utilities/dictionaryConstants";
import {
  DEFAULT_PAGING_SIZE,
  DEFAULT_ASSIGNMENT_OF_BENEFITS,
} from "../../../utilities/staticConfigs";
import CalendarInput from "../../commons/input/CalendarInput";
import TextInput from "../../commons/input/input";
import SelectInput from "../../commons/input/SelectInput";
import LoadingContext from "../../../container/loadingContext";
import AsyncTypeInput from "../../commons/input/AsyncTypeHead/AsyncTypeInput";

import { getStorage } from "../../../utilities/browserStorage";
import { useSelector, useDispatch } from "react-redux";
import {
  OtherBillingBasicData,
  updatePatientBillingData,
} from "../../../store/patient/PatientBillingInfo/patientBillingSlice";
import { Divider } from "@mui/material";
import { formatDateUS } from "../../../utilities/commonUtilities";

function OtherBilling(props) {
  const dispatch = useDispatch();
  const setShowLoadingBar = useContext(LoadingContext);


  const otherBillingData = useSelector(
    (state) => state.otherBillingData?.OtherBillingBasicData
  );

  const assignmentBenifitList = useSelector(
    (state) => state.otherBillingData?.assignmentBenifit
  );


  const patientAddDetails = useSelector(state => state.patientData.patientAddDetails);

  const billiInfoPk = useSelector(
    (state) => state.otherBillingData?.billingInfoPks
  );

  const practicePK = getStorage("practice");
  const [icdList, setIcdList] = useState([]);
  const [cptList, setCptList] = useState([]);

  // array api list
  const [listOfLocation, setListOfLocation] = useState([]);
  const [typeOfAccident, setTypeOfAccident] = useState([]);
  // variables
  const [diagnosisValidation, setDiagnosisValidation] = useState({
    "dx1": "", "dx2": "", "dx3": "", "dx4": "", "dx5": "", "dx6": "",
    "dx7": "", "dx8": "", "dx9": "", "dx10": "", "dx11": "", "dx12": ""
})
 const [cptValidation, setCptValidation] = useState({
  "cpt1": "", "cpt2": "", "cpt3": "", "cpt4": "", "cpt5": "", "cpt6": "",
  "cpt7": "", "cpt8": "", "cpt9": "", "cpt10": "", "cpt11": "", "cpt12": ""
 })
  const [otherBillingVariables, setOtherBillingVariables] = useState({
    accident_date: "",
    accident_location_id: "",
    accident_type_id: "",
    other_accident: "",
    assignment_benefit_id: "",
    accept_assignments: 1,
    initial_treatment_date: "",
    admission_date: "",
    last_menstural_date: "",
    cliam_casuality_number: "",
    cpt1: "",
    cpt2: "",
    cpt3: "",
    cpt4: "",
    cpt5: "",
    cpt6: "",
    cpt7: "",
    cpt8: "",
    cpt9: "",
    cpt10: "",
    cpt11: "",
    cpt12: "",
    dx1: "",
    dx2: "",
    dx3: "",
    dx4: "",
    dx5: "",
    dx6: "",
    dx7: "",
    dx8: "",
    dx9: "",
    dx10: "",
    dx11: "",
    dx12: "",
  });


  function onSearchCPTCodes(query) {
    let type = "dropdown";
    let page = 0;
    const page_size = 0;
    if (typeof query === "string") {
      let result = service.GetCPTList(page_size, page, practicePK, type, query);
      result.then((response) => {
        setCptList(response.data);
      });
    }
  }

  const onHandleCPTChange = (e, name) => {
    let label = "";
    if (Array.isArray(e) && e.length > 0) {
      label = e[0].id;
    } 
    setOtherBillingVariables({
      ...otherBillingVariables,
      [name]: label,
      [name + "List"]: e,
    });

    updateDXCPTReduxValue(name,e)
  };

  function onSearchICD10(query) {
    let type = "dropdown";
    let page = 0;
    const page_size = 0;
    let result = service.GetICD10List(page_size, page, practicePK, type, query);
    result.then((response) => {
      setIcdList(response.data);
    });
  }

  const onHandleICDChange = (e, name) => {
    let label = "";
    if (Array.isArray(e) && e.length > 0) {
      label = e[0].label;
    } 
    setOtherBillingVariables({
      ...otherBillingVariables,
      [name]: label,
      [name + "List"]: e,
    });

    updateDXCPTReduxValue(name,e)
  };

  /**
   * updating dx and cpt code inside redux 
   * @param {*} name 
   * @param {*} e 
   */
  const updateDXCPTReduxValue=(name,e)=>{
    const code_name=name+'_name';
    if(e.length>0){
      dispatch(updatePatientBillingData({
        billinginfopk: props.patientBillingPK,
        patientBillingBasicData: {
         [code_name]:e[0].name,
         [name]:e[0].label ? e[0].label : e[0].id
        },
      }))
    }

    else{
      dispatch(updatePatientBillingData({
        billinginfopk: props.patientBillingPK,
        patientBillingBasicData: {
         [code_name]:"",
         [name]:""
        },
      }))
    }
    
  }

  function handleChange(e) {
    let name = e.target.name;
    let value = e.target.type === "file" ? e.target.files[0] : e.target.value;
    if (name === "accident" && (value === props.accidentNoId || value === "")) {
      otherBillingVariables["accident_location_id"] = "";
      otherBillingVariables["other_accident"] = "";
      updateBasicReduxValue(name,value)
    } else {
      updateBasicReduxValue(name,value)
    }
    // updateBasicReduxValue(name,value)
  }

   /**
    * updaing basic billing data like accident type date location and other info
    * @param {*} name 
    * @param {*} value 
    */
  const updateBasicReduxValue=(name, value)=>{
    const key=name.split('_id');
    if(name === "accident" && (value === props.accidentNoId || value === ""))
    {
      dispatch(updatePatientBillingData({
        billinginfopk: props.patientBillingPK,
        patientBillingBasicData: {
          accident_location: "",
          other_accident:""
        },
      }))
    }
    else{
      dispatch(updatePatientBillingData({
        billinginfopk: props.patientBillingPK,
        patientBillingBasicData: {
         [key[0]]:value
        },
      }))
    }
  }

  function handleDate(selected, name) {
    if (!selected || selected === "none" || selected == "Invalid Date") {
      props.setDateError(true);
     
      /**
       * updating billing data in reducer on field value change
       */
      dispatch(
        updatePatientBillingData({
          billinginfopk: props.patientBillingPK,
          patientBillingBasicData: {
            [name]: selected,
          },
        })
      );
    } else if (selected) {
      props.setDateError(false);
     
      /**
       * updating billing data in reducer on field value change
       */
      dispatch(
        updatePatientBillingData({
          billinginfopk: props.patientBillingPK,
          patientBillingBasicData: {
            [name]: formatDateUS(selected),
          },
        })
      );
    }
  }

  function listOfAccidentType() {
    const result = service.ListTypeOfAccident(
      DEFAULT_PAGING_SIZE,
      props.patientPK
    );
    result.then((response) => {
      setTypeOfAccident(response.data);
      if (response.data.length > 0) {
        let accident_no = response.data.find((item => item.name.toLowerCase() == 'not related to accident'))
        props.setAccidentNoId(accident_no.id ?? "");
      }
    });
  }
  function listOfAccidentLocation() {
    const result = service.ListAccidentLocation();
    result.then((response) => {
      setListOfLocation(response.data);
    });
  }

  useEffect(() => {
    if (props.patientBillingPK) {
      if (!billiInfoPk?.includes(props.patientBillingPK)) {
        dispatch(OtherBillingBasicData({ pk: props.patientBillingPK, pageSize: DEFAULT_PAGING_SIZE, patientPK: props.patientPK }));
      }
      else{
        getOtherBillingInfo();
      }
    }
  }, [props.patientBillingPK]);

  useEffect(() => {
    getOtherBillingInfo();
  }, [otherBillingData]);

  function getOtherBillingInfo() {
    setShowLoadingBar(true);
    const result = otherBillingData?.filter(item => item.billinginfopk === props.patientBillingPK)
    if (result?.length > 0) {
      let obj = assignmentBenifitList.find(
        (item) => item.name.toLowerCase() == DEFAULT_ASSIGNMENT_OF_BENEFITS.toLowerCase()
      );
      const data = result[0].patientBillingBasicData;
      setOtherBillingVariables({
        id: data.id,
        patient: data.patient,
        accident_date: data.accident_date,
        accident_location_id: data.accident_location,
        accident_type_id: data.accident_type,
        accept_assignments: data.accept_assignments,
        assignment_benefit_id: data.assignment_benefit ? data.assignment_benefit : obj.id,
        initial_treatment_date: data.initial_treatment_date,
        admission_date: data.admission_date,
        last_menstural_date: data.last_menstural_date,
        cliam_casuality_number: data.cliam_casuality_number ?? "",
        cpt1: data.cpt1,
        cpt1List: data.cpt1 ? [{ id: data.cpt1, name: data.cpt1_name }] : [],
        cpt2: data.cpt2,
        cpt2List: data.cpt2 ? [{ id: data.cpt2, name: data.cpt2_name }] : [],
        cpt3: data.cpt3,
        cpt3List: data.cpt3 ? [{ id: data.cpt3, name: data.cpt3_name }] : [],
        cpt4: data.cpt4,
        cpt4List: data.cpt4 ? [{ id: data.cpt4, name: data.cpt4_name }] : [],
        cpt5: data.cpt5,
        cpt5List: data.cpt5 ? [{ id: data.cpt5, name: data.cpt5_name }] : [],
        cpt6: data.cpt6,
        cpt6List: data.cpt6 ? [{ id: data.cpt6, name: data.cpt6_name }] : [],
        cpt7: data.cpt7,
        cpt7List: data.cpt7 ? [{ id: data.cpt7, name: data.cpt7_name }] : [],
        cpt8: data.cpt8,
        cpt8List: data.cpt8 ? [{ id: data.cpt8, name: data.cpt8_name }] : [],
        cpt9: data.cpt9,
        cpt9List: data.cpt9 ? [{ id: data.cpt9, name: data.cpt9_name }] : [],
        cpt10: data.cpt10,
        cpt10List: data.cpt10
          ? [{ id: data.cpt10, name: data.cpt10_name }]
          : [],
        cpt11: data.cpt11,
        cpt11List: data.cpt11
          ? [{ id: data.cpt11, name: data.cpt11_name }]
          : [],
        cpt12: data.cpt12,
        cpt12List: data.cpt12
          ? [{ id: data.cpt12, name: data.cpt12_name }]
          : [],
        dx1: data.dx1,
        dx1List: data.dx1 ? [{ label: data.dx1, name: data.dx1_name }] : [],
        dx2: data.dx2,
        dx2List: data.dx2 ? [{ label: data.dx2, name: data.dx2_name }] : [],
        dx3: data.dx3,
        dx3List: data.dx3 ? [{ label: data.dx3, name: data.dx3_name }] : [],
        dx4: data.dx4,
        dx4List: data.dx4 ? [{ label: data.dx4, name: data.dx4_name }] : [],
        dx5: data.dx5,
        dx5List: data.dx5 ? [{ label: data.dx5, name: data.dx5_name }] : [],
        dx6: data.dx6,
        dx6List: data.dx6 ? [{ label: data.dx6, name: data.dx6_name }] : [],
        dx7: data.dx7,
        dx7List: data.dx7 ? [{ label: data.dx7, name: data.dx7_name }] : [],
        dx8: data.dx8,
        dx8List: data.dx8 ? [{ label: data.dx8, name: data.dx8_name }] : [],
        dx9: data.dx9,
        dx9List: data.dx9 ? [{ label: data.dx9, name: data.dx9_name }] : [],
        dx10: data.dx10,
        dx10List: data.dx10 ? [{ label: data.dx10, name: data.dx10_name }] : [],
        dx11: data.dx11,
        dx11List: data.dx11 ? [{ label: data.dx11, name: data.dx11_name }] : [],
        dx12: data.dx12,
        dx12List: data.dx12 ? [{ label: data.dx12, name: data.dx12_name }] : [],
        other_accident: data.other_accident,
      });
    }
    setShowLoadingBar(false);
  }

  useEffect(() => {
    listOfAccidentType();
    listOfAccidentLocation();
  }, []);

  useEffect(() => {
    validateDx("dx");
    validateDx("cpt");
    props.setOtherBillingInfoApiData(otherBillingVariables);
  }, [otherBillingVariables]);

  function validateDx(code) {
    let dgnValid = diagnosisValidation;
    
    let cptValid =  cptValidation;
    let tmpName = "";
    let foundTopOrderValue = false;
    let isValidted = true;
    let isDuplicated = true;
    let codeList = [];
    for (let dgCnt = 12; dgCnt > 0; dgCnt--) {
      tmpName = code + String(dgCnt);
      if (otherBillingVariables[tmpName]) {
        foundTopOrderValue = true;
                dgnValid[tmpName] = "";
                cptValid[tmpName]="";
        codeList.push(parseInt(otherBillingVariables[tmpName]));
      }
      else if (foundTopOrderValue && !otherBillingVariables[tmpName]) {
        dgnValid[tmpName] = " input-error";
        cptValid[tmpName] =" input-error";
        isValidted = false; 
    }
    else if (!foundTopOrderValue) {
      dgnValid[tmpName] = "";
      cptValid[tmpName] = "";
  }
    }
    codeList = findDuplicates(codeList);
    if (isValidted && codeList.length > 0) {
      isDuplicated = false;
    }
    if (code === "dx"){
      setDiagnosisValidation(dgnValid);
     props.setDiagnosisesValidated(isValidted);
     props.setDiagnosisduplicated(isDuplicated);
    }
    else if (code === "cpt") 
    setCptValidation(cptValid);
    props.setCptValidated(isValidted);
    props.setCptduplicated(isDuplicated);

  }

  function findDuplicates(arr) {
    return arr.filter((item, index) => arr.indexOf(item) !== index);
  }

  const onBlur = (e, name, searchType) => {
    let type = "dropdown";
    let page = 0;
    const page_size = 0;
    if (searchType === "CPTCODE") {
      let result = service.GetCPTList(page_size, page, practicePK, type, e?.target?.value ?? (typeof e == 'string' ? e : ""));
      result.then((response) => {
        if (Array.isArray(response.data) && response.data.length === 1) {
          updateDXCPTReduxValue(name,response.data)
        } 
      });
    } else {
      let input_value = e?.target?.value?.toUpperCase() ?? (typeof e == 'string' ? e.toUpperCase() : "");
      let result = service.GetICD10List(
        page_size,
        page,
        practicePK,
        type,
        input_value
      );
      result.then((response) => {
        if (Array.isArray(response.data) && response.data.length === 1) {
          updateDXCPTReduxValue(name,response.data)
        } 
      });
    }
  };

  return (
    <div>
      <Form autoComplete="off">
        <div className="row subheading pb-2  padding-left-10">
          <div className="div-inline">
            <label className="margin-top-3px mt-4">
              {i18n.t("OtherBilling.injuryInfo")}
            </label>
          </div>
        </div>
        <Divider style={{ color: '#000', marginTop: 2, marginBottom: 20 }} />
        <div className="row pb-2">
          <div className="col-3 ">
            <CalendarInput
              name="accident_date"
              selected={otherBillingVariables.accident_date}
              onValueChange={(selected) =>
                handleDate(selected, "accident_date")
              }
              label={i18n.t("OtherBilling.accidentDate")}
              maxDate={patientAddDetails.date_of_death ? patientAddDetails.date_of_death : new Date()}
              minDate={patientAddDetails.date_of_birth}
            />
          </div>
          <div className="col-3 pl-0">
            <SelectInput
              data={typeOfAccident}
              name="accident_type_id"
              id="accident_type_id"
              value={otherBillingVariables.accident_type_id}
              onValueChange={handleChange}
              label={i18n.t("OtherBilling.accidentType")}
            />
          </div>
          { otherBillingVariables.accident_type_id && 
            otherBillingVariables.accident_type_id !== props.accidentNoId && // If accident type is 1 i.e Not Related to accident then accident location input field is not required 
            <div className="col-3 pl-0">
              <SelectInput
                data={listOfLocation}
                name="accident_location_id"
                id="accident_location_id"
                value={otherBillingVariables.accident_location_id}
                onValueChange={handleChange}
                label={i18n.t("OtherBilling.accidentLocation")}
              />
            </div>

          }
          {otherBillingVariables.accident_type_id === 4 && (
            <div className="col-3 pl-0">
              <TextInput
                type="text"
                name="other_accident"
                id="other_accident"
                value={otherBillingVariables.other_accident}
                onValueChange={handleChange}
                label={i18n.t("OtherBilling.otherAccident")}
              />
            </div>
          )}
        </div>
        <div className="row subheading pb-2 padding-left-10">
          <div className="div-inline">
            <label className="margin-top-3px">
              {i18n.t("OtherBilling.assignmentOfBenefits")}
            </label>
          </div>
        </div>
        <Divider style={{ color: '#000', marginTop: 2, marginBottom: 20 }} />
        <div className="row pb-2">
          <div className="col-3 ">
            <SelectInput
              data={assignmentBenifitList}
              name="assignment_benefit_id"
              id="assignment_benefit_id"
              value={otherBillingVariables.assignment_benefit_id}
              onValueChange={handleChange}
              label={i18n.t("OtherBilling.assignmentOfBenefits")}
              selectOptionRemove={true}
            />
          </div>
          <div className="col-3 pl-0">
            <SelectInput
              data={YesOrNo}
              defaultValue={true}
              required={true}
              name="accept_assignments"
              id="accept_assignments"
              value={otherBillingVariables.accept_assignments}
              onValueChange={handleChange}
              label={i18n.t("OtherBilling.acceptAssignment")}
              selectOptionRemove={true}
            />
          </div>
        </div>
        <div className="row subheading pb-2  padding-left-10">
          <div className="div-inline">
            <label className="margin-top-3px">
              {i18n.t("OtherBilling.otherInfo")}
            </label>
          </div>
        </div>
        <Divider style={{ color: '#000', marginTop: 2, marginBottom: 20 }} />
        <div className="row ">
          <div className="col-3">
            <CalendarInput
              name="initial_treatment_date"
              id="initial_treatment_date"
              selected={otherBillingVariables.initial_treatment_date}
              onValueChange={(selected) =>
                handleDate(selected, "initial_treatment_date")
              }
              label={i18n.t("OtherBilling.initialTreatmentDate")}
              minDate={patientAddDetails.date_of_birth}
              maxDate={patientAddDetails.date_of_death ? patientAddDetails.date_of_death : new Date()}
            />
          </div>
          <div className="col-3 pl-0">
            <CalendarInput
              name="admission_date"
              id="admission_date"
              selected={otherBillingVariables.admission_date}
              onValueChange={(selected) =>
                handleDate(selected, "admission_date")
              }
              label={i18n.t("OtherBilling.admissionDate")}
              minDate={patientAddDetails.date_of_birth}
              maxDate={patientAddDetails.date_of_death ? patientAddDetails.date_of_death : new Date()}
            />
          </div>
          {props.patientGender==2?
          <div className="col-3 pl-0">
            <CalendarInput
              name="last_menstural_date"
              id="last_menstural_date"
              selected={otherBillingVariables.last_menstural_date}
              onValueChange={(selected) =>
                handleDate(selected, "last_menstural_date")
              }
              label={i18n.t("OtherBilling.lastMenstrualDate")}
              minDate={patientAddDetails.date_of_birth}
              maxDate={patientAddDetails.date_of_death ? patientAddDetails.date_of_death : new Date()}

            />
          </div>:null
          }
          <div className="col-3 pl-0">
            <div className="form-group">
              <div className="padding-top">
                <TextInput
                  name="cliam_casuality_number"
                  id="cliam_casuality_number"
                  value={otherBillingVariables.cliam_casuality_number}
                  onValueChange={handleChange}
                  type="text"
                  label={i18n.t("OtherBilling.claimCasualtyNumber")}
                />
              </div>
            </div>
          </div>
        </div>

        <div className="row subheading pb-2 padding-left15">
          <div className="div-inline">
            <label className="margin-top-3px">
              {i18n.t("OtherBilling.defaultDX")}
            </label>
          </div>
        </div>
        <Divider style={{ color: '#000', marginTop: 2, marginBottom: 20 }} />
        <div className="row padding-left7 margin-bottom-12">
          <div className="col-2">
            <div className="margin-top-16">
              <AsyncTypeInput
                labelKey="name"
                class_name={
                  diagnosisValidation.dx1 === ""? "" : "input-error"
                }
                name="dx1"
                minLength={1}
                onValueChange={(e) => onHandleICDChange(e, "dx1")}
                options={icdList}
                functionInvoke={true}
                onBlur={(e) => onBlur(e, "dx1", "DXCODE")}
                onSearch={onSearchICD10}
                selected={otherBillingVariables.dx1List}
                id={"dx1"}
                placeholder=""
                label={i18n.t("OtherBilling.dx.a")}
                dropup={true}
                isMenuToggleCallbackNeeded={true}
              />
            </div>
          </div>
          <div className="col-2">
            <div className="margin-top-16">
              <AsyncTypeInput
                labelKey="name"
                class_name={
                  diagnosisValidation.dx2 ==="" ? "" :  "input-error"
                }
                name="dx2"
                minLength={1}
                onValueChange={(e) => onHandleICDChange(e, "dx2")}
                options={icdList}
                functionInvoke={true}
                onBlur={(e) => onBlur(e, "dx2", "DXCODE")}
                onSearch={onSearchICD10}
                selected={otherBillingVariables.dx2List}
                id={"dx2"}
                placeholder=""
                label={i18n.t("OtherBilling.dx.b")}
                dropup={true}
                isMenuToggleCallbackNeeded={true}
              />
            </div>
          </div>
          <div className="col-2">
            <div className="margin-top-16">
              <AsyncTypeInput
                labelKey="name"
                class_name={
                  diagnosisValidation.dx3 === ""? "" : "input-error"
                }
                name="dx3"
                minLength={1}
                onValueChange={(e) => onHandleICDChange(e, "dx3")}
                options={icdList}
                functionInvoke={true}
                onBlur={(e) => onBlur(e, "dx3", "DXCODE")}
                onSearch={onSearchICD10}
                selected={otherBillingVariables.dx3List}
                id={"dx3"}
                placeholder=""
                label={i18n.t("OtherBilling.dx.c")}
                dropup={true}
                isMenuToggleCallbackNeeded={true}
              />
            </div>
          </div>
          <div className="col-2">
            <div className="margin-top-16">
              <AsyncTypeInput
                labelKey="name"
                class_name={
                  diagnosisValidation.dx4 === ""? "" : "input-error"
                }
                name="dx4"
                minLength={1}
                onValueChange={(e) => onHandleICDChange(e, "dx4")}
                options={icdList}
                functionInvoke={true}
                onBlur={(e) => onBlur(e, "dx4", "DXCODE")}
                onSearch={onSearchICD10}
                selected={otherBillingVariables.dx4List}
                id={"dx4"}
                placeholder=""
                label={i18n.t("OtherBilling.dx.d")}
                dropup={true}
                isMenuToggleCallbackNeeded={true}
              />
            </div>
          </div>
          <div className="col-2">
            <div className="margin-top-16">
              <AsyncTypeInput
                labelKey="name"
                class_name={
                  diagnosisValidation.dx5 === ""? "" : "input-error"
                }
                name="dx5"
                minLength={1}
                onValueChange={(e) => onHandleICDChange(e, "dx5")}
                options={icdList}
                functionInvoke={true}
                onBlur={(e) => onBlur(e, "dx5", "DXCODE")}
                onSearch={onSearchICD10}
                selected={otherBillingVariables.dx5List}
                id={"dx5"}
                placeholder=""
                label={i18n.t("OtherBilling.dx.e")}
                dropup={true}
                alignTooltip={"left"}
                isMenuToggleCallbackNeeded={true}
              />
            </div>
          </div>
          <div className="col-2">
            <div className="margin-top-16">
              <AsyncTypeInput
                labelKey="name"
                class_name={
                  diagnosisValidation.dx6 === ""? "" : "input-error"
                }
                name="dx6"
                minLength={1}
                onValueChange={(e) => onHandleICDChange(e, "dx6")}
                options={icdList}
                functionInvoke={true}
                onBlur={(e) => onBlur(e, "dx6", "DXCODE")}
                onSearch={onSearchICD10}
                selected={otherBillingVariables.dx6List}
                id={"dx6"}
                label={i18n.t("OtherBilling.dx.f")}
                alignTooltip={"left"}
                dropup={true}
                placeholder=""
                isMenuToggleCallbackNeeded={true}
              />
            </div>
          </div>
        </div>
        <div className="row pb-2 padding-left7 margin-bottom-12">
          <div className="col-2">
            <div className="margin-top-16">
              <AsyncTypeInput
                labelKey="name"
                class_name={
                  diagnosisValidation.dx7 === ""? "" : "input-error"
                }
                name="dx7"
                minLength={1}
                onValueChange={(e) => onHandleICDChange(e, "dx7")}
                options={icdList}
                functionInvoke={true}
                onBlur={(e) => onBlur(e, "dx7", "DXCODE")}
                onSearch={onSearchICD10}
                selected={otherBillingVariables.dx7List}
                id={"dx7"}
                placeholder=""
                label={i18n.t("OtherBilling.dx.g")}
                dropup={true}
                isMenuToggleCallbackNeeded={true}
              />
            </div>
          </div>
          <div className="col-2">
            <div className="margin-top-16">
              <AsyncTypeInput
                labelKey="name"
                class_name={
                  diagnosisValidation.dx8 === ""? "" : "input-error"
                }
                name="dx8"
                minLength={1}
                onValueChange={(e) => onHandleICDChange(e, "dx8")}
                options={icdList}
                functionInvoke={true}
                onBlur={(e) => onBlur(e, "dx8", "DXCODE")}
                onSearch={onSearchICD10}
                selected={otherBillingVariables.dx8List}
                id={"dx8"}
                placeholder=""
                label={i18n.t("OtherBilling.dx.h")}
                dropup={true}
                isMenuToggleCallbackNeeded={true}
              />
            </div>
          </div>
          <div className="col-2">
            <div className="margin-top-16">
              <AsyncTypeInput
                labelKey="name"
                class_name={
                  diagnosisValidation.dx9 === ""? "" : "input-error"
                }
                name="dx9"
                minLength={1}
                onValueChange={(e) => onHandleICDChange(e, "dx9")}
                options={icdList}
                functionInvoke={true}
                onBlur={(e) => onBlur(e, "dx9", "DXCODE")}
                onSearch={onSearchICD10}
                selected={otherBillingVariables.dx9List}
                id={"dx9"}
                placeholder=""
                label={i18n.t("OtherBilling.dx.i")}
                dropup={true}
                isMenuToggleCallbackNeeded={true}
              />
            </div>
          </div>
          <div className="col-2">
            <div className="margin-top-16">
              <AsyncTypeInput
                labelKey="name"
                class_name={
                  diagnosisValidation.dx10 === ""? "" : "input-error"
                }
                name="dx10"
                minLength={1}
                onValueChange={(e) => onHandleICDChange(e, "dx10")}
                options={icdList}
                functionInvoke={true}
                onBlur={(e) => onBlur(e, "dx10", "DXCODE")}
                onSearch={onSearchICD10}
                selected={otherBillingVariables.dx10List}
                id={"dx10"}
                placeholder=""
                label={i18n.t("OtherBilling.dx.j")}
                dropup={true}
                isMenuToggleCallbackNeeded={true}
              />
            </div>
          </div>
          <div className="col-2">
            <div className="margin-top-16">
              <AsyncTypeInput
                labelKey="name"
                class_name={
                  diagnosisValidation.dx11 === ""? "" : "input-error"
                }
                name="dx11"
                minLength={1}
                onValueChange={(e) => onHandleICDChange(e, "dx11")}
                options={icdList}
                functionInvoke={true}
                onBlur={(e) => onBlur(e, "dx11", "DXCODE")}
                onSearch={onSearchICD10}
                selected={otherBillingVariables.dx11List}
                id={"dx11"}
                placeholder=""
                label={i18n.t("OtherBilling.dx.k")}
                alignTooltip={"left"}
                dropup={true}
                isMenuToggleCallbackNeeded={true}
              />
            </div>
          </div>
          <div className="col-2">
            <div className="margin-top-16">
              <AsyncTypeInput
                labelKey="name"
                class_name={
                  diagnosisValidation.dx12 === ""? "" : "input-error"
                }
                name="dx12"
                minLength={1}
                onValueChange={(e) => onHandleICDChange(e, "dx12")}
                options={icdList}
                functionInvoke={true}
                onBlur={(e) => onBlur(e, "dx12", "DXCODE")}
                onSearch={onSearchICD10}
                selected={otherBillingVariables.dx12List}
                id={"dx12"}
                placeholder=""
                label={i18n.t("OtherBilling.dx.l")}
                alignTooltip={"left"}
                dropup={true}
                isMenuToggleCallbackNeeded={true}
              />
            </div>
          </div>
        </div>

        <div className="row subheading pb-2" style={{paddingLeft:"17px"}}>
          <div className="div-inline">
            <label className="margin-top-3px">
              {i18n.t("OtherBilling.defaultCPT")}
            </label>
          </div>
        </div>
        <Divider style={{ color: '#000', marginTop: 2, marginBottom: 20 }} />
        <div className="row pb-2 padding-left7 margin-bottom-12">
          <div className="col-2">
            <div className="margin-top-16">
              <AsyncTypeInput
                labelKey="name"
                class_name={
                  cptValidation.cpt1 === "" ? "" : "input-error"
                }
                name="cpt1"
                minLength={1}
                onValueChange={(e) => onHandleCPTChange(e, "cpt1")}
                functionInvoke={true}
                onBlur={(e) => onBlur(e, "cpt1", "CPTCODE")}
                options={cptList}
                onSearch={onSearchCPTCodes}
                selected={otherBillingVariables.cpt1List}
                id={"cpt1"}
                placeholder=""
                label={i18n.t("OtherBilling.cpt")}
                dropup={true}
                isMenuToggleCallbackNeeded={true}
              />
            </div>
          </div>
          <div className="col-2">
            <div className="margin-top-16">
              <AsyncTypeInput
                labelKey="name"
                class_name={
                  cptValidation.cpt2 === "" ? "" : "input-error"
                }
                name="cpt2"
                minLength={1}
                onValueChange={(e) => onHandleCPTChange(e, "cpt2")}
                functionInvoke={true}
                onBlur={(e) => onBlur(e, "cpt2", "CPTCODE")}
                options={cptList}
                onSearch={onSearchCPTCodes}
                selected={otherBillingVariables.cpt2List}
                id={"cpt2"}
                placeholder=""
                label={i18n.t("OtherBilling.cpt")}
                dropup={true}
                isMenuToggleCallbackNeeded={true}
              />
            </div>
          </div>
          <div className="col-2">
            <div className="margin-top-16">
              <AsyncTypeInput
                labelKey="name"
                class_name={
                  cptValidation.cpt3 === "" ? "" : "input-error"
                }
                name="cpt3"
                minLength={1}
                onValueChange={(e) => onHandleCPTChange(e, "cpt3")}
                options={cptList}
                functionInvoke={true}
                onBlur={(e) => onBlur(e, "cpt3", "CPTCODE")}
                onSearch={onSearchCPTCodes}
                selected={otherBillingVariables.cpt3List}
                id={"cpt3"}
                placeholder=""
                label={i18n.t("OtherBilling.cpt")}
                dropup={true}
                isMenuToggleCallbackNeeded={true}
              />
            </div>
          </div>
          <div className="col-2">
            <div className="margin-top-16">
              <AsyncTypeInput
                labelKey="name"
                class_name={
                  cptValidation.cpt4 === "" ? "" : "input-error"
                }
                name="cpt4"
                minLength={1}
                onValueChange={(e) => onHandleCPTChange(e, "cpt4")}
                options={cptList}
                functionInvoke={true}
                onBlur={(e) => onBlur(e, "cpt4", "CPTCODE")}
                onSearch={onSearchCPTCodes}
                selected={otherBillingVariables.cpt4List}
                id={"cpt4"}
                placeholder=""
                label={i18n.t("OtherBilling.cpt")}
                dropup={true}
                isMenuToggleCallbackNeeded={true}
              />
            </div>
          </div>
          <div className="col-2">
            <div className="margin-top-16">
              <AsyncTypeInput
                labelKey="name"
                class_name={
                  cptValidation.cpt5 === "" ? "" : "input-error"
                }
                name="cpt5"
                minLength={1}
                onValueChange={(e) => onHandleCPTChange(e, "cpt5")}
                options={cptList}
                functionInvoke={true}
                onBlur={(e) => onBlur(e, "cpt5", "CPTCODE")}
                onSearch={onSearchCPTCodes}
                selected={otherBillingVariables.cpt5List}
                id={"cpt5"}
                placeholder=""
                label={i18n.t("OtherBilling.cpt")}
                alignTooltip={"left"}
                dropup={true}
                isMenuToggleCallbackNeeded={true}
              />
            </div>
          </div>
          <div className="col-2">
            <div className="margin-top-16">
              <AsyncTypeInput
                labelKey="name"
                class_name={
                  cptValidation.cpt6 === "" ? "" : "input-error"
                }
                name="cpt6"
                minLength={1}
                onValueChange={(e) => onHandleCPTChange(e, "cpt6")}
                options={cptList}
                functionInvoke={true}
                onBlur={(e) => onBlur(e, "cpt6", "CPTCODE")}
                onSearch={onSearchCPTCodes}
                selected={otherBillingVariables.cpt6List}
                id={"cpt6"}
                placeholder=""
                label={i18n.t("OtherBilling.cpt")}
                alignTooltip={"left"}
                dropup={true}
                isMenuToggleCallbackNeeded={true}
              />
            </div>
          </div>
        </div>
        <div className="row pb-2 padding-left7 margin-bottom-12">
          <div className="col-2">
            <div className="margin-top-16">
              <AsyncTypeInput
                labelKey="name"
                class_name={
                  cptValidation.cpt7 === "" ? "" : "input-error"
                }
                name="cpt7"
                minLength={1}
                onValueChange={(e) => onHandleCPTChange(e, "cpt7")}
                options={cptList}
                functionInvoke={true}
                onBlur={(e) => onBlur(e, "cpt7", "CPTCODE")}
                onSearch={onSearchCPTCodes}
                selected={otherBillingVariables.cpt7List}
                id={"cpt7"}
                placeholder=""
                label={i18n.t("OtherBilling.cpt")}
                dropup={true}
                isMenuToggleCallbackNeeded={true}
              />
            </div>
          </div>
          <div className="col-2">
            <div className="margin-top-16">
              <AsyncTypeInput
                labelKey="name"
                class_name={
                  cptValidation.cpt8 === "" ? "" : "input-error"
                }
                name="cpt8"
                minLength={1}
                onValueChange={(e) => onHandleCPTChange(e, "cpt8")}
                options={cptList}
                functionInvoke={true}
                onBlur={(e) => onBlur(e, "cpt8", "CPTCODE")}
                onSearch={onSearchCPTCodes}
                selected={otherBillingVariables.cpt8List}
                id={"cpt8"}
                placeholder=""
                label={i18n.t("OtherBilling.cpt")}
                dropup={true}
                isMenuToggleCallbackNeeded={true}
              />
            </div>
          </div>
          <div className="col-2">
            <div className="margin-top-16">
              <AsyncTypeInput
                labelKey="name"
                class_name={
                  cptValidation.cpt9 === "" ? "" : "input-error"
                }
                name="cpt9"
                minLength={1}
                onValueChange={(e) => onHandleCPTChange(e, "cpt9")}
                options={cptList}
                functionInvoke={true}
                onBlur={(e) => onBlur(e, "cpt9", "CPTCODE")}
                onSearch={onSearchCPTCodes}
                selected={otherBillingVariables.cpt9List}
                id={"cpt9"}
                placeholder=""
                label={i18n.t("OtherBilling.cpt")}
                dropup={true}
                isMenuToggleCallbackNeeded={true}
              />
            </div>
          </div>
          <div className="col-2">
            <div className="margin-top-16">
              <AsyncTypeInput
                labelKey="name"
                class_name={
                  cptValidation.cpt10 === "" ? "" : "input-error"
                }
                name="cpt10"
                minLength={1}
                onValueChange={(e) => onHandleCPTChange(e, "cpt10")}
                options={cptList}
                functionInvoke={true}
                onBlur={(e) => onBlur(e, "cpt10", "CPTCODE")}
                onSearch={onSearchCPTCodes}
                selected={otherBillingVariables.cpt10List}
                id={"cpt10"}
                placeholder=""
                label={i18n.t("OtherBilling.cpt")}
                dropup={true}
                isMenuToggleCallbackNeeded={true}
              />
            </div>
          </div>
          <div className="col-2">
            <div className="margin-top-16">
              <AsyncTypeInput
                labelKey="name"
                class_name={
                  cptValidation.cpt11 === "" ? "" : "input-error"
                }
                name="cpt11"
                minLength={1}
                onValueChange={(e) => onHandleCPTChange(e, "cpt11")}
                options={cptList}
                functionInvoke={true}
                onBlur={(e) => onBlur(e, "cpt11", "CPTCODE")}
                onSearch={onSearchCPTCodes}
                selected={otherBillingVariables.cpt11List}
                id={"cpt11"}
                placeholder=""
                label={i18n.t("OtherBilling.cpt")}
                dropup={true}
                alignTooltip={"left"}
                isMenuToggleCallbackNeeded={true}
              />
            </div>
          </div>
          <div className="col-2">
            <div className="margin-top-16">
              <AsyncTypeInput
                labelKey="name"
                class_name={
                  cptValidation.cpt12  === "" ? "" : "input-error"
                }
                name="cpt12"
                minLength={1}
                onValueChange={(e) => onHandleCPTChange(e, "cpt12")}
                options={cptList}
                functionInvoke={true}
                onBlur={(e) => onBlur(e, "cpt12", "CPTCODE")}
                onSearch={onSearchCPTCodes}
                selected={otherBillingVariables.cpt12List}
                id={"cpt12"}
                placeholder=""
                label={i18n.t("OtherBilling.cpt")}
                alignTooltip={"left"}
                dropup={true}
                isMenuToggleCallbackNeeded={true}
              />
            </div>
          </div>
        </div>
      </Form>
    </div>
  );
}

export default OtherBilling;
