import { FormControl, Grid, MenuItem, Select, TextField } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { humanizeText } from "../../../../utilities/commonUtilities";
import {
  addFilter,
  updateFilter,
} from "../../../../store/reports/reportViewerSlice";
import ReportContext from "../report-context";

let isInitial = true;
const TEXT_VALS = [
  { key: "All", value: "All" },
  { key: "Equl", value: "Equals" },
  { key: "DoesNotEqual", value: "Does Not Equals" },
  { key: "Contains", value: "Contains" },
  { key: "BeginsWith", value: "Begins With" },
  { key: "EndsWith", value: "Ends With" },
  { key: "IsNotEmpty", value: "Is Not Empty" },
  { key: "IsEmpty", value: "Is Empty" },
];

const StringFieldSelector = ({ item }) => {
  const ctx = useContext(ReportContext);
  const dispatch = useDispatch();

  const [filterObject, setFilterObject] = useState({
    fieldId: item.id,
    queryField: item.field,
    searchMap: {
      condition: "All",
      Minimum: "",
      Maximum: "",
    },
  });
  const [condition, setCondition] = useState(TEXT_VALS[0].key);
  const [show, setShow] = useState(false);

  const handleChange = (e) => setCondition(e.target.value);

  const handleTextChange = (event) => {
    setFilterObject((prev) => {
      return {
        ...prev,
        searchMap: { ...prev.searchMap, Maximum: event.target.value },
      };
    });
  };

  useEffect(() => {
    dispatch(addFilter({ filter: filterObject, reportUuid: ctx.reportUuid }));
  }, []);

  useEffect(() => {
    if (isInitial) {
      isInitial = false;
      return;
    }
    dispatch(
      updateFilter({ filter: filterObject, reportUuid: ctx.reportUuid })
    );
  }, [filterObject, dispatch]);

  useEffect(() => {
    setFilterObject((prev) => {
      return {
        ...prev,
        searchMap: { ...prev.searchMap, condition: condition },
      };
    });
    switch (condition) {
      case "All":
        setShow(false);
        break;
      case "IsNotEmpty":
        setShow(false);
        break;
      case "IsEmpty":
        setShow(false);
        break;
      default:
        setShow(true);
        break;
    }
  }, [condition]);

  return (
    <Grid container spacing={2} className="mt-2">
      <Grid item xs={3}>
        <strong>{humanizeText(item.field)}</strong>
        <FormControl fullWidth>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={condition}
            label="Condition"
            onChange={handleChange}
          >
            {TEXT_VALS.map((items, index) => (
              <MenuItem key={index} value={items.key}>
                {items.value}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs={3}>
        <FormControl fullWidth className="mt-2">
          {show && (
            <TextField
              className="mt-4"
              value={filterObject.searchMap.value}
              onChange={handleTextChange}
            />
          )}
        </FormControl>
      </Grid>
      <Grid item xs={6}></Grid>
    </Grid>
  );
};

export default StringFieldSelector;
