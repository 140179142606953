import React, { useEffect } from 'react'
import OutlinedInput from '@material-ui/core/OutlinedInput';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemText from '@material-ui/core/ListItemText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Checkbox from '@material-ui/core/Checkbox';
import "./style.css"
import Label from '../components/commons/Label';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    getContentAnchorEl: null,
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        },
    },
};


export const MaterialMultiselect = (props) => {
    
    useEffect(() => {
        changeOpacity();
    }, [])
    
    function changeOpacity() {
        const selectIcon = document.querySelectorAll('svg.MuiSvgIcon-root.MuiSvgIcon-fontSizeMedium.MuiSelect-icon.MuiSelect-iconOutlined');
        selectIcon.forEach(el => {
            el.style.display = 'none';
        });

        const checkboxLabel = document.querySelectorAll('label.demo-multiple-checkbox-label');
        checkboxLabel.forEach(el => {
            el.style.transform = 'translate(2px, 19px) scale(1)';
        });
    }

    function RenderingFunction() {
        let nameArray = props.options.reduce((acc, element) => {
            if (props.value.indexOf(element.id) > -1) {
                acc.push(element.label || element.name || element.full_name);
            }
            else if (props.value.indexOf(element) > -1) {
                acc.push(element);
            }
            return acc;
        }, []);

        if (props.showAllSelected) {
            return nameArray.join(', ');
        }
        else {
            if (nameArray.length > 5) {
                return nameArray[0] + '+' + (parseInt(nameArray.length, 10) - 1) + '  Selected';
            }
            else {
                return nameArray.join(', ');
            }
        }
    }

    return (
        <div className='form-group'>
            <Label label={props.label} />
            <FormControl className=''>
                {/* <InputLabel id={props.id ?? "demo-multiple-checkbox-label"} aria-labelledby={props.label ?? "aria-label"} className='demo-multiple-checkbox-label'>{props.label}</InputLabel> */}
                <Select
                    labelId="demo-multiple-checkbox-label"
                    id={props.id ?? "demo-multiple-checkbox"}
                    aria-labelledby={props.label ?? "aria-label"}
                    multiple
                    name={props.name}
                    value={props.value}
                    style={{ height: 36 }}
                    className={props.required ? (props.value.length > 0 ? "" : "input-error") : ""}
                    onChange={props.onValueChange}
                    input={<OutlinedInput />}
                    renderValue={RenderingFunction}
                    MenuProps={MenuProps}
                    required={props.required ? props.required : false}
                >
                    {props.options && props.options.map((name) => {
                        return (
                            (typeof (name) === "object") ?
                                <MenuItem key={name.id} value={name.id} style={{ backgroundColor: props.value.indexOf(name.id) > -1 ? 'rgba(158,207,250,0.3)' :'inherit'}}>
                                    <Checkbox checked={props.value.indexOf(name.id) > -1} style={{ color: props.value.indexOf(name.id) > -1 ? '#24A7E0' : 'inherit' }} />
                                    <ListItemText primary={name.label ? name.label : name.name ? name.name : name.full_name}
                                        style={{ fontFamily: 'Lato' }} />
                                </MenuItem>
                                :
                                <MenuItem key={name} value={name} style={{ backgroundColor: props.value.indexOf(name) > -1 ? 'rgba(158,207,250,0.3)' : 'inherit' }}>
                                    <Checkbox checked={props.value.indexOf(name) > -1} style={{ color: props.value.indexOf(name) > -1 ? '#24A7E0' : 'inherit' }} />
                                    <ListItemText primary={name} style={{ fontFamily: 'Lato' }} />
                                </MenuItem>
                        )
                    })
                    }
                </Select>
                <i className='la la-sort-down positionSvgOfMaterialMultiSelelct' style={{ marginBottom: 3 }} />
            </FormControl>
        </div>
    )
}
