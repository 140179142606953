import React, { useEffect, useState, useContext } from 'react'
import ImageCrop from '../../commons/image-cropper/ImageCrop';
import { imageExtension } from '../../../utilities/validations';
import profileImage from '../../../assets/images/profile-image.png';
import TextInput from '../../commons/input/input';
import i18n from '../../../utilities/i18n';
import service from './service';
import LoadingContext from '../../../container/loadingContext';
import PhoneInput from '../../commons/PhoneInput/PhoneInput';
import EmailInput from '../../commons/EmailInput/EmailInput';
import SelectInput from '../../commons/input/SelectInput';
import CommonButton from '../../commons/Buttons';
import { ValidateEmail } from '../../../utilities/commonUtilities';
import AssociateUsersPracticeRoleSelector from './AssociateUsersPracticeRoleSelector';
import { DEFAULT_PAGING_SIZE, RESET_PASSWORD_DURATION, ROUTE_ASSOCIATE_USERS, ROUTE_PRACTICE_ASSOCIATE_USERS } from '../../../utilities/staticConfigs';
import {
    getStorage, setStorage,
} from '../../../utilities/browserStorage';
import { Link } from 'react-router-dom';
import Notify from '../../commons/notify';
import { useLocation,useHistory } from 'react-router-dom'
import { ADD_ERROR, UPDATE_SUCCESS, USER_CREATED_SUCCESS } from '../../../utilities/labelConfigs';
import StaffUserRoleSelector from './StaffUserRoleSelector';

const ACTIVE_STATUS = [{ "id": 1, "name": "InActive" }, { "id": 2, "name": "Active" },];
const options = {
    month: '2-digit',
    day: '2-digit',
    year: 'numeric',
    hour: '2-digit',
    minute: '2-digit',
    hour12: true // ensures 12-hour format with AM/PM
  };

function AssociateUsersEdit() {
    const isAdminModule = getStorage('isAdminModule');
    const location = useLocation();
    const history = useHistory();
    const [selectedID, setSelectedID] = useState(location?.state?.associateUserID);
    const [editForm, setEditForm] = useState(location?.state?.editForm)


    const [showNotify, setShowNotify] = useState('hide');
    const [notifyDescription, setNotifyDescription] = useState('');
    const [notifyType, setNotifyType] = useState('success');
    const [notifyPrimaryText, setNotifyPrimaryText] = useState('');
    const setShowLoadingBar = useContext(LoadingContext);

    const practicePK = getStorage("practice");
    const [activeStatus, setActiveStatus] = useState(2);
    const [imageUrl, setImageUrl] = useState(profileImage);
    const [imgUrl, setImgUrl] = useState(null);
    const [imageName, setImageName] = useState('');
    const isLogoValid = false;
    const [reloadPage, setReloadPage] = useState(false);
    const [showImageCropperModalWindow, setShowImageCropperModalWindow] = useState(false);
    const [profilePicture,setProfilePicture] = useState('');
    const [role, setRole] = useState('')
    const [firstName, setFirstName] = useState('');
    const [middleName, setMiddleName] = useState('');
    const [lastName, setLastName] = useState('');
    const [phone, setPhone] = useState('');
    const [email, setEmail] = useState('');
    const [userID, setUserID] = useState('')
    const [forcePassword, setForcePassword] = useState('');
    const [nextReset, setNextReset] = useState('');
    const [userPK, setUserPK] = useState('');
    const [userIdValidate, setUserIDValidate] = useState(false);
    const [emailVerified, setEmailVerified] = useState(false);
    const [isAimaStaffUser, setIsAimaStaffUser] = useState(false);
    const [staffUserRole, setStaffUserRole] = useState('');
    const [practicesList, setPracticesList] = useState([]);
    const [selectedPracticesAndRoles, setSelectedPracticesAndRoles] = useState({})
    const [defaultPracticeList, setDefaultPracticeList] = useState([]);
    const [defaultPractice, setDefaultPractice] = useState('');
    const [practicesAndRoles, setPracticesAndRoles] = useState([]);
    const [associateUserSelectedPractices, setAssociateUserSelectedPractices] = useState([]);
    const [isUserAccountLockedOut, setIsUserAccountLockedOut] = useState(false);
    const [isProfilePictureChanged, setIsProfilePictureChanged] = useState(false);


    const [form_error, setFormError] = useState({
        'userID': '',
        'email': '', 'firstName': '', 'middleName': '', 'lastName': '',
        'role': '', 'practices': '', 'defaultPractice': '', 'profilePicture': ''
    });

    const removePractice = (practiceId) => {
        let newArr = associateUserSelectedPractices.filter(item => item!=practiceId); 
        setAssociateUserSelectedPractices(newArr); 
    }

    function resetForm() {
        setAssociateUserSelectedPractices([]);
        setDefaultPracticeList([]);
        setSelectedPracticesAndRoles({});
        setRole('');
        if (isAdminModule === "true" || (isAdminModule === "false" && !isAimaStaffUser)) {
            setDefaultPractice('');
            setUserPK('');
            setUserID('');
            setEmail('');
            setPhone('');
            setFirstName('');
            setMiddleName('');
            setLastName('');
            setForcePassword(RESET_PASSWORD_DURATION);
            var nextResetDate = new Date();
            nextResetDate.setDate(nextResetDate.getDate() + RESET_PASSWORD_DURATION);
            if (RESET_PASSWORD_DURATION) {
            const formattedDate = nextResetDate.toLocaleString('en-US', options);
            setNextReset(formattedDate)
            }
            setProfilePicture('');
            setImageUrl(profileImage);
        } 
        setFormError({
            ...form_error,
            ['userID']: '',
            ['email']: '',
            ['firstName']: '',
            ['middleName']: '',
            ['lastName']: '',
            ['role']: '',
            ['practices']: '',
            ['defaultPractice']: '',
            ['profilePicture']: ''
        });
    }

    function showNotifyWindow(action, type, desc, age = 3000, primaryText) {
        if (action === 'show') {
            setTimeout(() => {
                setShowNotify('hide');
                setNotifyPrimaryText('');
            }, age)
        }
        setShowNotify(action);
        setNotifyType(type);
        setNotifyDescription(desc);
        setNotifyPrimaryText(primaryText)
    }

    useEffect(() => {
        if (reloadPage && getStorage('practiceName')) {
            setReloadPage(false);
            window.location.reload();
        }
    }, [getStorage('practiceName')]);

    useEffect(() => {
        setDefaultPractice(defaultPractice);
      }, [defaultPractice]);

    function onChangeShowImageCropperModalWindow(newValue) {
        setShowImageCropperModalWindow(newValue);
    }

    function onCroppedImageChange(newValue) {
        setProfilePicture(newValue);
        setImageUrl(URL.createObjectURL(newValue));
    }
    function openImage() {
        document.getElementById("file_userProfile").click();
        setIsProfilePictureChanged(true);    
    }

    function onRemoveImage() {
        setImageUrl("");
        setProfilePicture("");
        setImageUrl(profileImage);
        setIsProfilePictureChanged(true);
    }

    function resendVerificationEmail() {
        setShowLoadingBar(true);
        let result = service.ResendEmail({ "user_id": userPK });
        result.then(response => {
            if (response.status === 200) {
                showNotifyWindow('show', 'success', i18n.t('errorMessages.email_resending_success'));
            } else {
                showNotifyWindow('show', 'error', i18n.t('errorMessages.email_resending_failed'));
            }
            setShowLoadingBar(false);
        })
    }

    function onHandleChange(e) {
        const name = e.target.name;
        let value = e.target.type === "file" ? e.target.files[0] : e.target.value;
        let trimmedValue = '';
        let inputFields = [
            "userID", "email", "phone", "firstName", "middleName", "lastName",
            "role", "practices", "defaultPractice"
        ]
        if (inputFields.includes(name)) {
            trimmedValue = value;
        }
        else if (name === 'forcePassword') {
            if (e.target.validity.valid)
                trimmedValue = value;
            else
                trimmedValue = forcePassword;
        }
        else {
            trimmedValue = value;
        }
        let img_url, img_obj, nameValue;
        switch (name) {
            case 'userID':
                setUserID(trimmedValue);
                if (ValidateEmail(trimmedValue)) {
                    setUserIDValidate(false);
                } else {
                    setUserIDValidate(true);
                }
                break;
            case 'email':
                setEmail(trimmedValue);
                setUserID(trimmedValue);
                if (ValidateEmail(trimmedValue)) {
                    setUserIDValidate(false);
                } else {
                    setUserIDValidate(true);
                }
                break;
            case 'phone':
                setPhone(trimmedValue);
                break;
            case 'firstName':
                setFirstName(trimmedValue);
                break;
            case 'middleName':
                setMiddleName(trimmedValue);
                break;
            case 'lastName':
                setLastName(trimmedValue);
                break;
            case 'role':
                setRole(trimmedValue);
                break;
            case 'defaultPractice':
                setDefaultPractice(trimmedValue);
                break;
            case 'is_active':
                setActiveStatus(trimmedValue);
                break;
            case 'forcePassword':
                setForcePassword(trimmedValue);
                if (trimmedValue) {
                    let nextResetDate = new Date();
                    nextResetDate.setDate(nextResetDate.getDate() + parseInt(trimmedValue));
                    const formattedDate = nextResetDate.toLocaleString('en-US', options);
                    setNextReset(formattedDate)
                }
                break;
            case 'file_userProfile':
                nameValue = e.target.name;
                if (imageExtension(e.target.files[0].name)) {
                    img_obj = e.target.files[0];
                    img_url = URL.createObjectURL(e.target.files[0]);
                }
                setImgUrl(img_url);
                setImageName(img_obj.name);
                document.getElementById(nameValue).value = '';
                setShowImageCropperModalWindow(true);
                break;
            case 'staffUserRole' : 
                setStaffUserRole(value);
                break;
            default:
                break;
        }

    }

    useEffect(() => {
        if(!editForm) {
            getPractices(DEFAULT_PAGING_SIZE, 1)
        } else {
            onEditAssociateUser(selectedID)
        }
    }, [editForm])

    function onEditAssociateUser(associateID) {
        setShowLoadingBar(true);
        const data = service.GetAssociateUser(associateID);
        data.then(response => {

            let practices_ids = [];
            getPractices();
            setShowLoadingBar(false);
            setUserPK(response?.data?.user.id);
            setUserID(response.data.user.username);
            setFirstName(response.data.first_name);
            setMiddleName(response.data.middle_name)
            setLastName(response.data.last_name);
            setStaffUserRole(response?.data?.staff_user_role?.group?.toString())
            setRole(response.data.role ? response.data.role.id : '');
            setEmail(response.data.user.email);
            setPhone(response.data.phone);
            setEmailVerified(response.data.email_verified);
            setIsAimaStaffUser((response.data.user_type == 'staff_user' && isAdminModule === "false") ? true : false);
            setIsUserAccountLockedOut(response.data.is_user_account_locked || false)
            if (response.data.user.is_active) {
                setActiveStatus(2);
            }
            else {
                setActiveStatus(1);
            }
            response.data.practices.forEach((item) => {
                practices_ids.push(item.id);
            });
            setAssociateUserSelectedPractices(practices_ids);
            if (response.data.default_practice) {
                setDefaultPractice(response.data.default_practice.id);
            }
            setDefaultPracticeList(response.data.practices);
            const date = new Date(response.data.next_reset_date);
            const formattedDate = date.toLocaleString('en-US', options);
            setNextReset(formattedDate)
            setForcePassword(response.data.password_reset_days??RESET_PASSWORD_DURATION);
            if (response.data.practice_groups?.length) {
                let practiceAndRolesObj = {};
                response.data.practice_groups.forEach((item) => {
                    practiceAndRolesObj[`${item.practice_id}`] = {
                        [item.group_id]: true
                    }
                })

                setSelectedPracticesAndRoles({ ...practiceAndRolesObj })
            }
            if (response.data.password_reset_days && !response.data.next_reset_date) {
                setForcePassword(response.data.password_reset_days);
                let nextResetDate = new Date();
                nextResetDate.setDate(nextResetDate.getDate() + parseInt(response.data.password_reset_days));
                if (response.data.password_reset_days){  
                const formattedDate = nextResetDate.toLocaleString('en-US', options);
                setNextReset(formattedDate)}
            }
            if (response.data.profile_picture)
                setImageUrl(response.data.profile_picture)
        });
    }

    function getPractices(pageSize) {
        let pageNumber = 0;
        let result = null;
        if (isAdminModule === 'true' || (getStorage('userData') && JSON.parse(getStorage('userData')).group_id === 1))
            result = service.ListPractices(pageSize, pageNumber);
        else
            result = service.ListPractices(pageSize, pageNumber, practicePK);
        result.then(response => {
            setPracticesList(response.data);
        });
    }


    useEffect(() => {
        fetchPracticeAndRoles()

        // Clean up function
        return () => {
            setSelectedID(null);
            setEditForm(null);
        };
    }, [])


    const fetchPracticeAndRoles = async () => {
        try {
            const response = await service.getPracticeSAndRoles();
            // console.log("fetch role resp--> ",response)
            if (response.data.length) {
                setPracticesAndRoles(response.data);
            } else {
                setPracticesAndRoles([]);
            }
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    };

    function addPracticeDataToBox(value) {
        setAssociateUserSelectedPractices(value);
        const anArray = [];
        practicesList.forEach((item) => {
            value.forEach((item2) => {
                if (Number(item2) === Number(item.id)) {
                    anArray.push(item);
                }
            })
        });
    }

    const onCancel = () => {
        history.goBack()
    }

    /**
     * creating form data to send to back end
     * @returns 
     */
    function createFormData() {
        const dataObject = {
            'page': isAdminModule === 'false' ? 'associate' : 'sa_associate',
            'username': userID,
            'email': email,
            'phone': phone,
            'first_name': firstName,
            'middle_name': middleName,
            'last_name': lastName,
            'role': role,
            'is_active': activeStatus,
            'practices': associateUserSelectedPractices,
            'default_practice': defaultPractice ? defaultPractice : '',
            'password_reset_days': forcePassword,
            'practice_pk': isAdminModule === 'false' ? practicePK : '',
        };

        const formData = new FormData();
        /**
         * appending key and key value to formData
         */

        if(isProfilePictureChanged) {
            if (profilePicture !== "" && profilePicture !== null) {
                dataObject["profile_picture"] = profilePicture
            } else {
                dataObject["profile_picture"] = null;
            }
            setIsProfilePictureChanged(false);
        }

        for (const key in dataObject) {
            formData.append(key, dataObject[key]);
        }

        return formData;
    }
    

    async function onSaveFormData(e) {
        e.preventDefault();
        let has_error = false;

        if(isAdminModule == 'true'){
            has_error = false;
          } else if (!defaultPractice || defaultPractice === '' || defaultPractice == 0) {
            has_error = true;
          }
         if (email) {
            const email_validation = ValidateEmail(email);
            if (!email_validation) has_error = true;
        }
         if (!email) {
            has_error = true;
        }
        if (userID.trim() === '') {
            setUserID('');
            has_error = true;
        }
        if (userID) {
            const email_validation = ValidateEmail(userID);
            if (!email_validation) has_error = true;
        }
        if (isAdminModule == 'true' && (staffUserRole == '' || !staffUserRole)) {
            has_error = true;
        }

        if (Object.keys(selectedPracticesAndRoles)?.length > 0 && !defaultPractice) {
            has_error = true;
        }

        // Get the selected practices and its associated roles from the state object
        // For each practice user selected, user also should choose a role against it
        let practiceAndRoles = [];
        let practiceAndRoleErrorFlag;

        // Extracting the selected practice id and the group role id
        for (const key in selectedPracticesAndRoles) {
            const selectedRoles = selectedPracticesAndRoles[key];
            
            if (!selectedRoles || Object.keys(selectedRoles).length === 0) {
                practiceAndRoleErrorFlag = practicesAndRoles.find((item) => item.practice_id == key);
                break;
            } else {
                const groupIds = Object.keys(selectedRoles);
                practiceAndRoles.push({ "practice_id": Number(key), "group_id": Number(groupIds[0]) });
            }
        }

        if (practiceAndRoleErrorFlag) {
            showNotifyWindow('show', 'error', i18n.t('errorMessages.role_selection_error'));
            return;
        }

        if (!has_error) {
            setShowLoadingBar(true);
            let result = null;
            const data = await createFormData()

            // Convert the practiceAndRoles array to a JSON string
            const practicesAndRolesJSON = JSON.stringify(practiceAndRoles);

            // Append the JSON string to the formData with the key "user_groups"
            data.append("user_groups", practicesAndRolesJSON);

            if(isAdminModule == 'true') {
                data.append('staff_user_role',staffUserRole)
            }

            if (editForm) {
                result = service.UpdateAssociateUser(selectedID, data);
            } else {
                result = service.AddAssociateUser(data);
            }
            result.then(response => {
                setShowLoadingBar(false);
                if (response.status === 201 || response.status === 200) {
                    if (response.status === 201) {
                        showNotifyWindow('show', 'success', USER_CREATED_SUCCESS);
                    }
                    else {
                        showNotifyWindow('show', 'success', UPDATE_SUCCESS);
                    }
                    setTimeout(() => {
                        if (isAdminModule == "true") history.push(ROUTE_ASSOCIATE_USERS)
                        else history.push(ROUTE_PRACTICE_ASSOCIATE_USERS)
                    }, 2000)
                    setShowLoadingBar(false);
                } else {
                    /**
                    * handling all errors
                    */
                    if (response.data.default_practice_changed) {
                        handleDefaultPracticeChange(response.data.default_practice, 1);
                    } else if (response.status === 400 && response.data.user_already_exists !== undefined) {
                        handleUserExistsError();
                    } else if (response.status === 400 && response.data.message !== undefined && response.data.message === 'email_sending_failed') {
                        handleEmailSendingFailedError();
                    } else if (response.status === 400 && response.data.message !== undefined && (response.data.message === 'invalid_username' || response.data.message === 'invalid_username-whitespace' || response.data.message === 'username-already-exists')) {
                        handleInvalidUsernameError(response.data.message);
                    } else {
                        showNotifyWindow('show', 'error', ADD_ERROR);
                    }
                }
            });
        }
    }

    function handleDefaultPracticeChange(defaultPractice) {
        if (defaultPractice) {
            setStorage("practice", defaultPractice.id);
            setStorage("practiceName", defaultPractice.name + '(' + defaultPractice.custom_practice_id + ')');
            setReloadPage(true);
        } else {
            setStorage("practiceName", '');
            localStorage.removeItem("practice");
            window.location.reload();
        }
    }

     /**
     *user already exists error 
     */
     function handleUserExistsError() {
        showNotifyWindow('show', 'error', i18n.t('errorMessages.user_exists'));
    }

    /**
     * email related issues handled here
     * @param {*} message 
     */
    function handleEmailSendingFailedError() {
        showNotifyWindow('show', 'error', i18n.t('errorMessages.email_sending_failed'));
        setShowLoadingBar(false);
    }


    /**
     * username related errors handled heree
     * @param {*} message 
     */
    function handleInvalidUsernameError(message) {
        if (message === 'invalid_username-whitespace') {
            showNotifyWindow('show', 'error', i18n.t('errorMessages.invalid_username-whitespace'));
        } else if (message === 'username-already-exists') {
            showNotifyWindow('show', 'error', i18n.t('errorMessages.username-already-exists'));
        } else {
            showNotifyWindow('show', 'error', i18n.t('errorMessages.invalid_username'));
        }
    }


    async function handleUserUnLockBtnClick() {
        setShowLoadingBar(true);
        const response = await service.unlockUserAccount({
            id: userPK,
        });

        if (response.data.status === 'success') {
            showNotifyWindow('show', 'success', 'Account has been unlocked successfully.', 4000, 'Account unlocked');
            setIsUserAccountLockedOut(false)
        } else {
            showNotifyWindow('show', 'error', 'An error occurred while unlocking the account.');
        }
        setShowLoadingBar(false);
    }


    return (
        <>

            <div className='col-md-12'>
                <Notify showNotify={showNotify} notifyPrimaryText={notifyPrimaryText} setShowNotify={setShowNotify} notifyDescription={notifyDescription} setNotifyType={setNotifyType} setNotifyDescription={setNotifyDescription} notifyType={notifyType} />
                <div className="box box-content-white">
                    
                    <div className='row'>
                        <div className="modal-body col-2  text-center" style={{ marginLeft: '-21px' }}>
                            <div className="company-logo">
                                <img src={imageUrl} alt="Profile Name" onClick={openImage} />
                                <p className={isLogoValid ? 'logo-txt' : 'text-red logo-txt'}>
                                    <small>{''}</small>
                                    {(form_error.profilePicture.length > 0) &&
                                        <div><small>{form_error.profilePicture}</small>
                                        </div>
                                    }
                                    <input type="file" id="file_userProfile" name="file_userProfile" onChange={onHandleChange} accept=".png, .jpg, .jpeg" />
                                </p>
                                < ImageCrop imgUrl={imgUrl} imageName={imageName} show={showImageCropperModalWindow} showModal={onChangeShowImageCropperModalWindow} changeCroppedImage={onCroppedImageChange} />

                            </div>
                        </div>
                        <div className='ml-4' style={{ marginTop: "55px" }}>
                            <CommonButton variant="contained"
                                onClick={openImage}
                                icon='add'
                                label={i18n.t('commons.uploadNewPicture')} />

                            <input type="file" style={{ display: 'none' }} id="myProfile_input" name="myProfile_input" accept=".jpeg, .jpg, .png, .gif" />
                        </div>
                        <div className='' style={{ marginTop: "55px", marginLeft: '30px' }}>
                            <CommonButton variant="outlined"
                                onClick={onRemoveImage}
                                label={i18n.t('commons.delete')} />
                        </div>
                    </div>

                    <div className='row'>
                        <div className='col-4'>
                            {isUserAccountLockedOut ? <>This user account has been locked out, <span>
                                <Link to="#" onClick={handleUserUnLockBtnClick}
                                >
                                    Click here to unlock!
                                </Link></span> </> : <></>}
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-4">
                            <div className="form-group padding-top15">
                                <TextInput type="text" id="lastName"
                                    disabled={isAimaStaffUser}
                                    name="lastName" label={i18n.t('userPages.associateUsers.labelLastName')} onValueChange={onHandleChange} required={true} value={lastName} />
                            </div>
                        </div>
                        <div className="col-4">
                            <div className="form-group padding-top15">
                                <TextInput type="text" id="firstName"
                                    disabled={isAimaStaffUser}
                                    name="firstName" label={i18n.t('userPages.associateUsers.labelFirstName')} onValueChange={onHandleChange} required={true} value={firstName} />
                            </div>
                        </div>
                        <div className="col-4">
                            <div className="form-group padding-top15">
                                <TextInput type="text" id="middleName"
                                    disabled={isAimaStaffUser}
                                    name="middleName" label={i18n.t('userPages.associateUsers.labelMiddleName')} onValueChange={onHandleChange} value={middleName} />
                            </div>
                        </div>
                    </div>

                    <div className='row'>
                        <div className='col-4'>
                            <div className="form-group padding-top3">
                                <EmailInput id="email"
                                    disabled={isAimaStaffUser}
                                    name="email" label={i18n.t('userPages.associateUsers.labelEmail')} onValueChange={onHandleChange} required={true} value={email} />
                            </div>
                            {!emailVerified && (
                                <div className="margin-top-10 ml-3">
                                    {!isAimaStaffUser ? (
                                        <Link to="#"
                                            className={editForm ? 'showLink' : 'hide'} 
                                            onClick={() => resendVerificationEmail()}>
                                            {i18n.t('userPages.associateUsers.lblResendEmailVerification')}
                                        </Link>
                                    )
                                        : (
                                            <span>{i18n.t('userPages.associateUsers.lblResendEmailVerification')}</span>
                                        )}
                                </div>
                            )}

                        </div>
                        <div className='col-4'>
                            <PhoneInput name="phone"
                                disabled={isAimaStaffUser}
                                label={i18n.t('userPages.associateUsers.labelPhone')} onValueChange={setPhone} required={true}
                                value={phone} className={phone > 5 ? "" : "input-error"} />
                        </div>
                        <div className='col-4 mt-2'>
                            <TextInput type="text"
                                disabled={isAimaStaffUser}
                                id="userID" name="userID" label={i18n.t('userPages.associateUsers.labelUsernamewithemail')} onValueChange={onHandleChange} required={true} value={userID}
                                defaultClassName={userIdValidate && 'input-error'}
                            />
                        </div>
                        <div className='col-4 mt-2'>

                            {isAdminModule == 'true' && <StaffUserRoleSelector 
                            value = {staffUserRole}
                            onValueChange={onHandleChange}
                            />}
                        </div>
                    </div>

                    <div className='row'>
                        <div className='col-12'>
                            <AssociateUsersPracticeRoleSelector
                                practicesAndRoles={practicesAndRoles}
                                associateUserSelectedPractices={associateUserSelectedPractices}
                                addPracticeDataToBox={addPracticeDataToBox}
                                setSelectedPracticesAndRoles={setSelectedPracticesAndRoles}
                                selectedPracticesAndRoles={selectedPracticesAndRoles}
                                practicesList={practicesList}
                                resetForm={resetForm}
                                defaultPracticeList={defaultPracticeList}
                                defaultPractice={defaultPractice}
                                removePractice={removePractice}
                                isAimaStaffUser={isAimaStaffUser}
                                setDefaultPractice={setDefaultPractice}
                            />
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-3 mt-3">
                            <SelectInput
                                data={practicesAndRoles?.filter(item => associateUserSelectedPractices.includes(item?.practice_id))?.map(practice => ({ id: practice.practice_id, name: practice.practice_name }))}
                                // disabled={isAimaStaffUser}
                                name="defaultPractice" id="defaultPractice"
                                value={defaultPractice}
                                onValueChange={onHandleChange}
                                label={i18n.t('userPages.associateUsers.labelDefaultPractice')}
                                required={true}
                                showInRed={!defaultPractice} />
                        </div>
                        <div className="col-3">
                            <div className="form-group padding-top15">
                                <TextInput type="text" id="forcePassword"
                                     disabled={isAimaStaffUser}
                                    pattern="[0-9]*" name="forcePassword" label={i18n.t('userPages.associateUsers.labelPasswordReset')} onValueChange={onHandleChange} value={forcePassword} />
                            </div>
                        </div>
                        <div className="col-3 padding-top15">
                            {/* {forcePassword.length > 0 ? */}
                            <label className="form-group padding-top20 mt-3">{i18n.t('userPages.associateUsers.labelNextReset')} : {nextReset}</label>
                        </div>
                        <div className="col-3 mt-3">
                            <SelectInput
                                data={ACTIVE_STATUS}
                                 disabled={isAimaStaffUser}
                                name={'is_active'} value={activeStatus} onValueChange={onHandleChange} label={'Is Active'} selectOptionRemove={true} defaultValue={true} />
                        </div>
                    </div>


                    <div className='row justify-content-end' >
                        <div className='' style={{marginRight:'20px'}}>
                            <CommonButton label="Cancel" onClick={onCancel} variant="outlined" />
                        </div>
                        <div className='ml-2 mr-3' >
                            <CommonButton label="Save" variant="contained"
                            onClick={(e)=> onSaveFormData(e)} />
                        </div>
                    </div>

                </div>

            </div>

        </>
    )
}

export default AssociateUsersEdit