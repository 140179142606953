import api from '../../../service/api';

const ListICD10 = async(...params) => {
    let [pageSize, page, isAdminModule, practicePK, practiceDict, searchValue, listType, orderingField, orderType,status] = params;
    let path = null;
    if (isAdminModule === 'true')
        path = 'super-admin/icd10/?page_size='+ pageSize + '&page='+ page;
    else if(practiceDict)
        path = 'super-admin/icd10/?page_size='+ pageSize + '&page='+ page +'&practice_pk='+ practicePK + '&column=name';
    else
        path = 'practice/icd10/?page_size='+ pageSize + '&page='+ page +'&practice_pk='+ practicePK +'&column=master_icd__name';
    if (searchValue)
        path += '&search='+ searchValue;
    if (listType)
        path += '&list_type='+ listType;
    if(orderingField){
        if(isAdminModule !== 'true')
            orderingField = 'master_icd__' + orderingField
        let fieldOrder = '';
        if (orderType) fieldOrder+= orderType;
        fieldOrder += orderingField;
        path += '&column='+fieldOrder;   
    } else {
        if(isAdminModule === 'true' || practiceDict)
            path += '&column=name';
        else
            path += '&column=master_icd__name';
    }
    if (status)
    path += '&status=' + status;
    return api.get(path);
}

const ImportCSVData = async(data) =>{
    let path = 'super-admin/import-icd10/'
    return api.post(path,data);
}

const AddICD10sToPractice = async(data) => {
    let path = 'practice/icd10/';
    return api.post(path, data);
}

const DeleteICDFromPractice = async(pk,path) => {
    let fullPath = path+'/icd10/'+ pk;
    return api.destroy(fullPath);
}

const GetICDCodeData = async(pk,path) => {
    let fullPath = path + '/icd10/'+ pk;
    return api.get(fullPath);
}

const UpdateICD10 = async(pk, data,path) => {
    let fullPath = path +'/icd10/'+ pk;
    return api.put(fullPath, data);
}

const ExportICD = async(type,practicePk) =>{
    let path = 'practice/icd10/?practice_pk='+practicePk+'&export=true&export_type='+type
    let result = null;
	if (type === 'xls') {
		result = api.getXLS(path);
    } else {
		result = api.get(path);
    }
    return result;
}

export default { ListICD10, ImportCSVData, AddICD10sToPractice, DeleteICDFromPractice, GetICDCodeData, UpdateICD10, ExportICD}