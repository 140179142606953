import api from '../../../service/api'

const ListAppointmentTypes = async(pageSize, page, isAdminModule, practicePK, searchValue) =>{
    let path = null;
    if (isAdminModule === 'false')
        path = 'practice/appointment-type/?page_size='+ pageSize +'&page='+ page + '&practice_pk='+ practicePK;
    else
        path = 'super-admin/appointment-type/?page_size='+pageSize+'&page='+page;
    if (searchValue) {
        path += `&search=${searchValue}`;
    }	
    return api.get(path);
}

const AddAppointmentType = async(data) =>{
    let path = null;
    if (Object.prototype.hasOwnProperty.call(data, 'practice'))
        path = 'practice/appointment-type/';
    else
        path = 'super-admin/appointment-type/';
        return api.post(path,data);
}

const GetAppointmentType = async(accidentId, practicePK) =>{
    let path = null;
    if (practicePK)
        path = 'practice/appointment-type/' + accidentId;
    else
        path = 'super-admin/appointment-type/' + accidentId;
        return api.get(path);
}

const UpdateAppointmentType = async(accidentId, data) =>{
    let path = null;
    if (Object.prototype.hasOwnProperty.call(data, 'practice'))
        path = 'practice/appointment-type/' + accidentId;
    else
        path = 'super-admin/appointment-type/' + accidentId;
        return api.put(path,data);
}

const DeleteAppointmentType = async(accidentId, practicePK) =>{
    let path = null;
    if (practicePK)
        path = 'practice/appointment-type/' + accidentId;
    else
        path = 'super-admin/appointment-type/' + accidentId;
        return api.destroy(path);
}

export default {ListAppointmentTypes, AddAppointmentType, GetAppointmentType, UpdateAppointmentType, DeleteAppointmentType}