import api from '../../../service/api'

const ListPayerClasses = async (pageSize, page, isAdminModule, practicePK, searchValue) => {
    let path = null;
    if (isAdminModule === 'false')
        path = 'practice/payer-class/?page_size=' + pageSize + '&page=' + page + '&practice_pk=' + practicePK;
    else
        path = 'super-admin/payer-class/?page_size=' + pageSize + '&page=' + page;
    if (searchValue) {
        path += `&search=${searchValue}`;
    }
        return api.get(path);
}

const AddPayerClass = async (data) => {
    let path = null;
    if (Object.prototype.hasOwnProperty.call(data, "practice"))
        path = 'practice/payer-class/';
    else
        path = 'super-admin/payer-class/';
        return api.post(path, data);
}
const GetPayerClass = async (payerClassId, practicePK) => {
    let path = null;
    if (practicePK)
        path = 'practice/payer-class/' + payerClassId;
    else
        path = 'super-admin/payer-class/' + payerClassId
        return api.get(path);
}

const UpdatePayerClass = async (payerClassId, data) => {
    let path = null;
    if (Object.prototype.hasOwnProperty.call(data, "practice"))
        path = 'practice/payer-class/' + payerClassId
    else
        path = 'super-admin/payer-class/' + payerClassId;
        return api.put(path, data);
}

const DeletePayerClass = async (payerClassId, practicePK) => {
    let path = null;
    if (practicePK)
        path = 'practice/payer-class/' + payerClassId;
    else
        path = 'super-admin/payer-class/' + payerClassId;
        return api.destroy(path);
}


const exportPayerClass = async (type, practicePK) => {
    const path = 'practice/payer-class/?practice_pk=' + practicePK + '&export=true&export_type=' + type;
    if (type == 'csv') {
        return api.get(path);
    }
    else {
        return api.getXLS(path)
    }
}

export default { ListPayerClasses, AddPayerClass, GetPayerClass, UpdatePayerClass, DeletePayerClass, exportPayerClass }