import React, { useState, useEffect, useRef } from 'react';
import { useHistory } from "react-router-dom";
import { AsyncTypeahead } from 'react-bootstrap-typeahead'
import "./style.css";
import service from "./service";

import i18n from "../../../utilities/i18n";
import { getStorage } from "../../../utilities/browserStorage";
import Notify from "../../commons/notify";
import { LOGIN_ROUTE } from '../../../utilities/staticConfigs';
import Label from '../../commons/Label';

const PatientDropDownSearch = (props) => {
  const history = useHistory();
  const practicePK = getStorage("practice")
    ? parseInt(getStorage("practice"))
    : "";
  const dateTypeAheadRef = useRef();
  const nameTypeAheadRef = useRef();
  const [searchDate, setSearchDate] = useState("");
  const [patientDropdown, setPatientDropdown] = useState([]);
  const [patientSelected, setPatientSelected] = useState([]);
  const [dobSelected, setDobSelected] = useState([]);
  const [validDate, setValidDate] = useState(false);
  const [maxResults, setMaxResults] = useState(5);
  const [className, setClassName] = useState("");

  const searchValueName = props?.searchValueName
    ? props.searchValueName
    : "searchValue";
  const searchDateName = props?.searchDateName
    ? props.searchDateName
    : "searchDate";

  const [showNotify, setShowNotify] = useState("hide");
  const [notifyDescription, setNotifyDescription] = useState("");
  const [notifyType, setNotifyType] = useState("success");

  useEffect(() => {
    if (patientSelected.length == 0) {
      setDobSelected([])
    }
  }, [patientSelected])

  function showNotifyWindow(action, type, desc, age = 3000) {
    if (action == "show") {
      setTimeout(() => {
        setShowNotify("hide");
      }, age);
    }
    setShowNotify(action);
    setNotifyType(type);
    setNotifyDescription(desc);
  }

  function onHandleChange(e, fieldType) {

    if (props?.tabsOpen >= props?.MAX_OPEN_TABS_PATIENTS) {
      showNotifyWindow(
        "show",
        "error",
        i18n.t("errorMessages.max_patient_tabs")
      );
    } else {
      if (e[0] && props.addRemoveFromSelectedTab) {
        // TO PREVENT DUPLICATE API CALLING ON addRemoveFromSelectedTab AS onHandleChange TRIGERRED ON BOTH setPatientSelected AND setDobSelected
        if (
          patientSelected.length == 0 ||
          (patientSelected.length &&
            parseInt(patientSelected[0].id) != parseInt(e[0].id))
        ) {
          if (props?.tabsOpen >= props?.MAX_OPEN_TABS_PATIENTS) {
            showNotifyWindow(
              "show",
              "error",
              i18n.t("errorMessages.max_patient_tabs")
            );
          } else {
            setPatientSelected(e);
            setDobSelected(e);
            let item = {
              pk: e[0].id,
              type: "patients",
              action: "add",
              practice_pk: practicePK,
            };
            props.addRemoveFromSelectedTab(item, true);
          }
        }
      } else {
        if (e[0]) {
          if (props.setSelectPatient) {
            props.setSelectPatient(e[0].name);
          }
          props.setPatientPK(e[0].id);
          props.setPatientSelected(e);
          setPatientSelected(e);
          setDobSelected(e);
        } else {
          if (props.setPatientSelected) {
            props.setPatientSelected([]);
            if (props.setSelectPatient) {
              props.setSelectPatient("");
            }
            props.setPatientPK("");


          }
        }

        if (fieldType === searchValueName) {
          setPatientSelected(e);
          if (props.onSearchPatientList && patientSelected.length > 0) {
            props.onSearchPatientList();
          }
        }
      }
    }
  }

  // USED TO CLEAR SELECTED PATIENT IF RESET OR CLEAR BUTTON IS USED
  useEffect(() => {
    if (props.clearSelectedPatient > 0) {
      setPatientDropdown([]);
      setPatientSelected([]);
      setDobSelected([]);
      // Remove focus from Typeahead Search component once user selected a patient
      dateTypeAheadRef.current.clear();
      dateTypeAheadRef.current.getInput().blur();
      nameTypeAheadRef.current.clear();
      nameTypeAheadRef.current.getInput().blur();
    }
  }, [props?.clearSelectedPatient]);

  // USED TO LOAD PATIENT WHEN PATIENT IS SELECTED FROM ADVANCED SEARCH
  useEffect(() => {
    if (props?.patientAdvSearchData && props?.patientAdvSearchData.length) {
      handleTypeheadOnchange(props?.patientAdvSearchData, searchValueName);
    }
  }, [props?.patientAdvSearchData]);


  const onSearchPatient = (query) => {
    listPatientDropdown(query);
  };

  const listPatientDropdown = async (query) => {
    try {
      const response = await service.GetListPatientDropdown(query);

      if (response.data?.length) {
        setPatientDropdown(response.data);
      } else if (response.status === 401) { // To handle session out issue
        history.push(LOGIN_ROUTE)
      } else {
        setPatientDropdown([]);
      }
    } catch (error) {
      showNotifyWindow("show", "error", i18n.t("errorMessages.commonError"));
    }
  };


  const onSearchPatientByDOB = (query) => {
    let dtValue = String(query.trim().replace(/[^\d/]/g, ""))
      .substr(0, 10)
      .replace("//", "/");
    setDobSelected([{ id: 0, name: "", label: "", dob: dtValue }]);
    if (dtValue && dtValue.length === 10) {
      let arrDate = dtValue.split("/");
      if (
        arrDate.length === 3 &&
        arrDate[0].length === 2 &&
        arrDate[1].length === 2 &&
        arrDate[2].length === 4
      ) {
        listPatientDropdownByDOB(dtValue);
      }
    }
  };
 
const listPatientDropdownByDOB = async (query) => {
  try{
      const response = await service.GetListPatientDropdown(query, "date");
      
        if (response.data?.length) {
          setPatientDropdown(response.data);
        } else if (response.status === 401){
          history.push(LOGIN_ROUTE)
        } else {
          setPatientDropdown([]);
        }
      }catch (error) {
        showNotifyWindow("show", "error", i18n.t("errorMessages.commonError"));
      }
    };
  const handleTypeheadOnchange = (e, name) => {
    onHandleChange(e, name);
    if (e.length == 0) {
      if (props.setPatientRemoved) {
        props.setPatientRemoved(true);
      }
    }
    else {
      if (props.setPatientRemoved) {
        props.setPatientRemoved(false);
      }
    }
  };

  const dummyFunction = (e) => {
    if (e[0] && props.addRemoveFromSelectedTab) {
      // TO PREVENT DUPLICATE API CALLING ON addRemoveFromSelectedTab AS onHandleChange TRIGERRED ON BOTH setPatientSelected AND setDobSelected
      if (
        patientSelected.length == 0 ||
        (patientSelected.length &&
          parseInt(patientSelected[0].id) != parseInt(e[0].id))
      ) {
        if (props?.tabsOpen >= props?.MAX_OPEN_TABS_PATIENTS) {
          showNotifyWindow(
            "show",
            "error",
            i18n.t("errorMessages.max_patient_tabs")
          );
        } else {
          setPatientSelected(e);
          setDobSelected(e);
          let item = {
            pk: e[0].id,
            type: "patients",
            action: "add",
            practice_pk: practicePK,
          };
          props.addRemoveFromSelectedTab(item, true);
        }
      }
    } else {
      if (e[0]) {
        if (props.setSelectPatient) {
          props.setSelectPatient(e[0].name);
        }
        props.setPatientPK(e[0].id);
        props.setPatientSelected(e);
        setPatientSelected(e);
        setDobSelected(e);
        if (props.onSearchPatientList && patientSelected.length > 0) {
          props.onSearchPatientList();
        }
      } else {
        if (props.setPatientSelected) {
          props.setPatientPK("");

        }
        if (props.required) {
          setClassName('input-error-bottom');
        }
      }
      if (e.length == 0) {
        if (props.setPatientRemoved) {
          props.setPatientRemoved(true);
        }
      }
      else {
        if (props.setPatientRemoved) {
          props.setPatientRemoved(false);
        }
      }
    }
    if (!e[0]) {
      setDobSelected([])
      setPatientSelected([])
    }

  };

  useEffect(() => {
    if (searchDate && searchDate.length == 10) {
      let arrTmp = searchDate.split("/");
      if (
        arrTmp &&
        arrTmp.length === 3 &&
        arrTmp[0].length === 2 &&
        arrTmp[1].length === 2 &&
        arrTmp[2].length === 4
      ) {
        setValidDate(true);
        onSearchPatientByDOB(searchDate);
      } else {
        setValidDate(false);
      }
    } else {
      setValidDate(false);
    }
  }, [searchDate]);

  const onAsyncInputChange = (stringValue, event, calledFrom) => {
    if (stringValue.trim()) {
      let tmpVal = stringValue.trim();
      const trimmedValue = String(stringValue.trim()).replace(/[^\d]/g, '').substr(0, 10);
      const formattedValue = formatDobData(trimmedValue);
      if (calledFrom === searchDateName) {
        if (event.nativeEvent.inputType === "deleteContentBackward") {
          // IF BACKSPACE PRESSED
          setPatientDropdown([{ id: 0, name: "", label: "", dob: formattedValue }]);
          setDobSelected([{ id: 0, name: "", label: "", dob: formattedValue }]);
          setSearchDate("");
        } else {
          let dtValue = String(tmpVal.replace(/[^\d/]/g, ""))
            .substr(0, 10)
            .replace("//", "/");

          if ((dtValue && dtValue.length === 2 && dtValue.indexOf("/") < 0) ||
            (dtValue && dtValue.length === 5 && dtValue.indexOf("/") === 2)) {
            setPatientDropdown([{ id: 0, name: "", label: "", dob: formattedValue }]);
            setDobSelected([{ id: 0, name: "", label: "", dob: formattedValue }]);
            setSearchDate("");
          } else if (!dtValue || (dtValue && (dtValue.length < 10 || dtValue.length > 10))) {
            setPatientDropdown([{ id: 0, name: "", label: "", dob: formattedValue }]);
            setDobSelected([{ id: 0, name: "", label: "", dob: formattedValue }]);
            setSearchDate("");
          } else if (dtValue.length === 10) {
            // Call the DOB search function
            listPatientDropdownByDOB(dtValue);  
          }
        }
      } else {
        // Handle patient ID search
        if (stringValue.length >= 3) {
          // Call the patient ID search function
          listPatientDropdown(stringValue);  
        } else {
          setPatientDropdown([]);
        }
      }
    } else {
      setPatientDropdown([{ id: 0, name: "", label: "", dob: "" }]);
      setPatientSelected([{ id: 0, name: "", label: "", dob: "" }]);
      setDobSelected([{ id: 0, name: "", label: "", dob: "" }]);
    }
  };

const formatDobData = (rawValue) => {
  
  if (rawValue.length > 8) {
      rawValue = rawValue.slice(0, 10);
  }

  const monthStr = rawValue.substr(0, 2);
  const dayStr = rawValue.substr(2, 2);
  const yearStr = rawValue.substr(4, 4);

  const month = parseInt(monthStr, 10);
  const day = parseInt(dayStr, 10);
  const year = parseInt(yearStr, 10);

  let formattedValue = '';
  
  // Validation
  if (monthStr.length === 2 && (month < 1 || month > 12)) {
      return ''; 
  }
  if (dayStr.length === 2 && (day < 1 || day > 31)) {
      return ''; 
  }
  if (yearStr.length === 4 && (year < 1000 || year > 9999)) {
      return ''; 
  }

  if (monthStr.length > 0) {
      formattedValue += monthStr;
      if (dayStr.length == 2) {
          formattedValue += '/';
      }
  }
  if (dayStr.length > 0) {
      formattedValue += dayStr;
      if (yearStr.length > 0) {
          formattedValue += '/';
      }
  }
  if (yearStr.length > 0) {
      formattedValue += yearStr;
  }
  return formattedValue;
};

  useEffect(() => {
    if (props.required && (patientSelected.length == 0 || dobSelected?.length == 0)) {
      setClassName('input-error-bottom');
    } else {
      setClassName('');
    }
  }, [props.required, patientSelected?.length, dobSelected?.length])


  function selectOnTab(e) {
    if (e.key === "Tab") {
      document.querySelector('button[aria-label="Advanced Search"]')?.click();
    } else if (
      e.key === "Enter" &&
      patientSelected.length > 0 &&
      props.onSearchPatientList
    ) {
      props.onSearchPatientList();
    }
  }

  useEffect(() => {
    if (props.patientSelected && patientSelected.length) {
      setPatientSelected(props.patientSelected);
    }
  }, [props.patientSelected]);

  let orPaddingTop = props.orPaddingTop ? props.orPaddingTop : "30px";

  const renderMenuItem = (option, field) => {
    return (
      <div
        onClick={() =>
          handleTypeheadOnchange(
            [
              {
                id: option.id,
                dob: option.dob,
                label: option.label,
                name: option.name,
              },
            ],
            searchDateName
          )
        }
        value={field == "dob" ? option.dob : option.name}
      >
        {option.label}
      </div>
    );
  }

  let dateFieldClass = props.dateFieldClass ? props.dateFieldClass : "";

  return (
    <React.Fragment>
      <Notify
        showNotify={showNotify}
        setShowNotify={setShowNotify}
        notifyDescription={notifyDescription}
        setNotifyType={setNotifyType}
        setNotifyDescription={setNotifyDescription}
        notifyType={notifyType}
      />

      <div className={props.fullWidth ? 'col' : "col-3"}>
        <div
          className={"form-group " + props.inputClass ? props.inputClass : ""}
        >
          <div className="">
            <Label label={i18n.t("patientPages.patientList.patientNameID")} />
            <div className="typeHeadStyle">
              <AsyncTypeahead
                className={className}
                id={props.id ? props.id : "basic-typeahead-single"}
                labelKey={"name"}
                maxResults={maxResults}
                onPaginate={() => setMaxResults(maxResults + 5)}
                paginationText={"Load More"}
                minLength={3}
                options={patientDropdown}
                placeholder=""
                onSearch={onSearchPatient}
                name={searchValueName}
                ref={nameTypeAheadRef}
                onChange={(e) => handleTypeheadOnchange(e, searchValueName)}
                inputProps={{
                  id: props.id ? `${props.id}-input-element` : ""
                }}
                selected={patientSelected}
                clearButton={true}
                onKeyDown={(e) => selectOnTab(e)}
                onInputChange={(stringValue, event) =>
                  onAsyncInputChange(stringValue, event, searchValueName)
                }
                override={{
                  dropdownMenu: {
                    maxHeight: "185px !important",
                    overflowY: "scroll",
                  },
                }}
                renderMenuItemChildren={(option) => (
                  renderMenuItem(option, "dob")
                )}
              />
            </div>
          </div>
        </div>
      </div>

      <span style={{ paddingTop: orPaddingTop, marginRight: '9px', marginLeft: '-4px', fontSize: '14px' }}>or</span>

      <div className={(validDate ? "" : "  ") + (props.fullWidth ? "col pl-0" : "col-3 pl-0") + (props.noRightMargin ? " margin-right0 padding-right0 " : "") }>
        <div
          className={"form-group " + props.inputClass ? props.inputClass : ""}
        >
          <div className="">  
            <Label label={i18n.t("patientPages.patientList.dob")} />
            <div className={(dateFieldClass+" typeHeadStyle")} id="div-patient-dob-search">
              <AsyncTypeahead
                id={props.id ? props.id: "basic-typeahead-single"}
                className={className}
                labelKey={"dob"}
                maxResults={maxResults}
                onPaginate={() => setMaxResults(maxResults + 5)}
                paginationText={"Load More"}
                minLength={10}
                options={patientDropdown}
                placeholder=""
                onSearch={onSearchPatientByDOB}
                name={searchDateName}
                // onChange={(e) => handleTypeheadOnchange(e, searchDateName)}
                onChange={(e) => dummyFunction(e, searchDateName)}
                selected={dobSelected}
                ref={dateTypeAheadRef}
                clearButton={
                  dobSelected.some((d) => d.dob == "") ? false : true
                }
                onKeyDown={(e) => selectOnTab(e)}
                onInputChange={(stringValue, event) =>
                  onAsyncInputChange(stringValue, event, searchDateName)
                }
                override={{
                  dropdownMenu: {
                    maxHeight: "185px !important",
                    overflowY: "scroll",
                  },
                }}
                renderMenuItemChildren={(option) => (
                  renderMenuItem(option, "name")
                )}
                inputProps={{
                  id: props.id ? `${props.id}-input-dob-element` : ""
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};
export default PatientDropDownSearch;

