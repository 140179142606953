import React, { useState, useContext, useCallback, useRef } from "react";
import i18n from "../../../utilities/i18n";
import LoadingContext from "../../../container/loadingContext";
import { ListStandardFeeTableData, StandardTableBodyData } from "./listFeeTableData";
import Table from "../../commons/Table/Table";
import {
    PAGING_END_INDEX,
    DEFAULT_PAGING_SIZE,
    ROUTE_SUPER_DICTIONARIES,
} from "../../../utilities/staticConfigs";
import Pagination from "../../commons/pagination";
import { getStorage } from "../../../utilities/browserStorage";
import Notify from "../../commons/notify";
import BackArrowWithLabel from "../../commons/Back";
import CommonButton from "../../commons/Buttons";
import service from "./service";
import SearchAndLoadSchedule from "./SearchAndLoadSchedule";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { csvFileCheck } from "../../../utilities/validations";
import { useHistory } from "react-router-dom";
const StandardFeeSchedule = () => {
    const setShowLoadingBar = useContext(LoadingContext);
    const isAdminModule = getStorage("isAdminModule");

    const fileRef = useRef();
    const history = useHistory();

    const [year, setYear] = useState();
    const [state, setState] = useState();
    const [locality, setLocality] = useState();
    // ----state---------------y
    const [showNotify, setShowNotify] = useState("hide");
    const [notifyDescription, setNotifyDescription] = useState("");
    const [notifyType, setNotifyType] = useState("success");

    //Pagination start
    const [totalPage, setTotalPage] = useState(1);
    const [activePage, setActivePage] = useState(1);
    const [startIndex, setStartIndex] = useState(0);
    const [endIndex, setEndIndex] = useState(PAGING_END_INDEX);
    const [scheduleState, setScheduleState] = useState();
    const [scheduleLocality, setScheduleLocality] = useState();
    const [stanardFeeList, setStanardFeeList] = useState([]);

    // -----functions-----------
    const showNotifyWindow = useCallback((action, type, desc, age = 3000) => {
        if (action == "show") {
            setTimeout(() => {
                setShowNotify("hide");
            }, age);
        }
        setShowNotify(action);
        setNotifyType(type);
        setNotifyDescription(desc);
    }, []);

    const onPagePrevious = () => {
        let previousPage = startIndex + 1 - PAGING_END_INDEX;
        setActivePage(previousPage);

        if (startIndex !== 0) {
            setStartIndex(startIndex - PAGING_END_INDEX);
            setEndIndex(endIndex - PAGING_END_INDEX);
        }
        fetchAndSetData(year, state, locality, DEFAULT_PAGING_SIZE, previousPage);
    };

    const onPageUp = (e) => {
        let page = Number(e.target.id);
        setActivePage(page);
        fetchAndSetData(year, state, locality, DEFAULT_PAGING_SIZE, page);
    };

    const onPageNext = () => {
        let nextPage = startIndex + 1 + PAGING_END_INDEX;
        if (endIndex === totalPage || totalPage <= PAGING_END_INDEX) {
            setActivePage(nextPage);
            setStartIndex(startIndex);
            setStartIndex(endIndex);
        } else {
            setActivePage(nextPage);
            setStartIndex(startIndex + PAGING_END_INDEX);
            setEndIndex(endIndex + PAGING_END_INDEX);
        }
        fetchAndSetData(year, state, locality, DEFAULT_PAGING_SIZE, nextPage);
    };

    const onSearchFeeSchedule = (
        scheduleYear,
        scheduleState,
        scheduleLocality
    ) => {
        setYear(scheduleYear);
        setState(scheduleState);
        setLocality(scheduleLocality);
        fetchAndSetData(
            scheduleYear,
            scheduleState,
            scheduleLocality,
            DEFAULT_PAGING_SIZE,
            activePage
        );
    };

    const clearData = () => {
        ListStandardFeeTableData.tableBodyData = StandardTableBodyData
        setTotalPage(0);
        setActivePage(1);
        setScheduleState();
        setScheduleLocality();
    }

    /**
     * Fetches standard fee list data from the server and updates state with the result.
     *
     * @param {number} selectedYear - The selected year to filter the results by.
     * @param {string} selectedState - The selected state to filter the results by.
     * @param {string} selectedLocality - The selected locality to filter the results by.
     * @param {number} pageSize - The number of results to include in a page.
     * @param {number} page - The current page number to fetch.
     */
    const fetchAndSetData = (
        selectedYear,
        selectedState,
        selectedLocality,
        pageSize,
        page
    ) => {
        // Show the loading bar while data is being fetched
        setShowLoadingBar(true);

        // Call the service to get the standard fee list
        service
            .getStandardFeeList(selectedYear, selectedState, selectedLocality, pageSize, page > 0 ? page : 1)
            .then((response) => {
                // Hide the loading bar when the data is received
                setShowLoadingBar(false);

                // Check the response status
                if (response.status === 200) {
                    // Update the total page count and active page if necessary
                    if (response.data.results !== undefined) {
                        let dataTotalPage = Math.ceil(response.data.count / response.data.page_size);
                        setTotalPage(dataTotalPage);
                        if (dataTotalPage < activePage) {
                            setActivePage(dataTotalPage);
                        }
                    }

                    // Parse the response data and create an array of objects for each row and column
                    let anArray = [];
                    let rowArray = [];
                    let newData = Array(response.data.results.length).fill(
                        JSON.parse(JSON.stringify(ListStandardFeeTableData.tableBodyData[0]))
                    );

                    newData.map((row, rowIndex) => {
                        row.map((col) => {
                            let colObject = {};
                            colObject = {
                                ...col,
                                value: response.data.results[rowIndex][col.name],
                                id: response.data.results[rowIndex].id,
                            };
                            anArray.push(colObject);
                        });
                        rowArray.push(anArray);
                        anArray = [];
                    });

                    // Update the table body data with the parsed response data
                    if (rowArray.length > 0) {
                        ListStandardFeeTableData.tableBodyData = rowArray;
                    }

                    // Update state with the response data
                    setStanardFeeList(response.data);
                }
            })
            .catch(() => {
                // Hide the loading bar and show an error message if the data fetch fails
                setShowLoadingBar(false);
                showNotifyWindow("show", "error", i18n.t("errorMessages.commonError"));
            });
    };

    function onHandleCsvImport(e) {
        e.preventDefault();
        document.getElementById("claimfile").value = null;
        document.getElementById("claimfile").click();
    }
    function onHandleCSVChange(e) {
        setShowLoadingBar(true);
        const value = e.target.type === "file" ? e.target.files[0] : e.target.value;

        if (e.target.type === "file") {
            if (e.target.files[0] && csvFileCheck(e.target.files[0].name)) {
                const data = new FormData();
                data.append("file", value);
                data.append("practice_pk", getStorage("practice"));
                let result = service.ImportStandardFee(data);
                result.then((response) => {
                    setShowLoadingBar(false);
                    if (response.data.message !== undefined) {
                        if (response.data.message === "no_file_records") {
                            showNotifyWindow(
                                "show",
                                "error",
                                i18n.t("errorMessages.empty_file")
                            );
                        } else if (response.data.message === "invalid_headings") {
                            showNotifyWindow(
                                "show",
                                "error",
                                i18n.t("errorMessages.invalid_headings")
                            );
                        } else if (response.data.message === "data_imported") {

                            showNotifyWindow(
                                "show",
                                "success",
                                i18n.t("validations.success.import_success")
                            );
                        }
                    } else {
                        showNotifyWindow(
                            "show",
                            "error",
                            i18n.t("errorMessages.import_failed")
                        );
                    }
                });
            } else {
                showNotifyWindow(
                    "show",
                    "error",
                    i18n.t("errorMessages.invalid_file_format")
                );
            }
        }
    }

    function backToDictionaryPage() {
        history.push(ROUTE_SUPER_DICTIONARIES)
    }

    // ------------------------
    return (
        <>
            <Notify
                showNotify={showNotify}
                setShowNotify={setShowNotify}
                notifyDescription={notifyDescription}
                setNotifyType={setNotifyType}
                setNotifyDescription={setNotifyDescription}
                notifyType={notifyType}
            /> <div className="col-md-12">
                <div className="box box-content-white">

                    {
                        isAdminModule === 'true' &&
                        <div className="row mb-2">
                            <div className="col">
                                <div className={'dataTables_filter'}>
                                    <div className="link dictionaries-back pl-0" onClick={backToDictionaryPage} >
                                        <BackArrowWithLabel label={i18n.t("dictionariesPages.dictionaries")} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    }

                    <div className="box-content">
                        <div className="standard_fee_search">
                            <SearchAndLoadSchedule
                                onSearch={onSearchFeeSchedule}
                                setShowLoadingBar={setShowLoadingBar}
                                showNotification={showNotifyWindow}
                                onclearData={clearData}
                                scheduleState={scheduleState}
                                setScheduleState={setScheduleState}
                                scheduleLocality={scheduleLocality}
                                setScheduleLocality={setScheduleLocality}
                            />
                        </div>
                    </div>
                    </div>
                    <div className="box box-content-white margin-top10">
                        <div className="">
                            <div className="">
                                <div className='justify-right margin-bottom7'>
                                    <input
                                        ref={fileRef}
                                        name="claimfile"
                                        type="file"
                                        accept=".csv"
                                        id="claimfile"
                                        onChange={onHandleCSVChange}
                                        style={{ display: "none" }}
                                    />
                                    {isAdminModule !== "false" &&
                                        <div className="div-float-right margin-export-icon mt-3">
                                            <OverlayTrigger
                                                placement="top"
                                                delay={{ show: 250, hide: 400 }}
                                                overlay={
                                                    <Tooltip
                                                        id="download-tooltip"
                                                        style={{
                                                            opacity: 0.4,
                                                        }}
                                                    >
                                                        {i18n.t("commons.importCSV")}
                                                    </Tooltip>
                                                }
                                            >
                                                <CommonButton icon="download" variant="outlined" label="Import" onClick={(e) => onHandleCsvImport(e)} />
                                            </OverlayTrigger>
                                        </div>}
                            </div>
                            <div className="table-responsive">
                                <Table tableObject={ListStandardFeeTableData} list={stanardFeeList} />
                            </div>
                            <div className='padding-top10'></div>
                                <Pagination
                                    totalPage={totalPage}
                                    activePage={activePage}
                                    startIndex={startIndex}
                                    endIndex={endIndex}
                                    onPagePrevious={onPagePrevious}
                                    onPageUp={onPageUp}
                                    onPageNext={onPageNext}
                                />
                            </div>
                        </div>
                    </div>
            </div>
        </>
    );
}

export default StandardFeeSchedule;