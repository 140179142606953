import React, { useState, useEffect, useContext } from 'react';
import {Form } from 'react-bootstrap';
import CustomizedDialogs from '../../modalWindowComponent/CustomizedDialogs';
import service from './service';
import { useLocation } from 'react-router-dom';
import Notify from '../../commons/notify';
import Table from '../../commons/Table/Table';
import TextInput from '../../commons/input/input';
import Pagination from '../../commons/pagination';
import BackArrowWithLabel from "../../commons/Back"; 

import LoadingContext from '../../../container/loadingContext';

import i18n from '../../../utilities/i18n';
import { getStorage } from '../../../utilities/browserStorage';
import { checkPermission, commonTableBody } from '../../../utilities/commonUtilities';
import { ADD_SUCCESS, ADD_ERROR, UPDATE_SUCCESS, DELETE_SUCCESS } from '../../../utilities/labelConfigs';
import { ROUTE_SUPER_DICTIONARIES, ROUTE_PRACTICE_DICTIONARIES, PAGING_END_INDEX, DEFAULT_PAGING_SIZE, ROUTE_REFERRAL_SOURCES } from '../../../utilities/staticConfigs';
import { permission_key_values_practice_dictionaries, super_admin_privileges, super_admin_permission_key_value } from '../../../utilities/permissions';

import CustomizedSmallDialogs from '../../modalWindowComponent/CustomisedSmallDialog';

import { ReferalTableData } from './ReferalTable';
import CommonButton from '../../commons/Buttons';

const ReferralList = (props) => {
    const isAdminModule = getStorage('isAdminModule');
    const location = useLocation();
    const practicePK = getStorage("practice");
    const [showModalWindow, setShowModalWindow] = useState(false);
    const [showDeleteModalWindow, setShowDeleteModalWindow] = useState(false);
    const [referralType, setReferralType] = useState('');
    const [deleteReferralName, setDeleteReferralName] = useState('');
    const [deleteReferralId, setDeleteReferralId] = useState('');
    const [editReferralId, setEditReferralId] = useState('');
    const [referralList, setReferralList] = useState([]);
    const [form_error, setFormError] = useState({ 'ReferralType': '' });
    const [editForm, setEditForm] = useState(false);
    const [header, setHeader] = useState('');
    const [permission, setPermission] = useState(false);
    const [searchValue, setSearchValue] = useState("");

    //start ==== Alert message properties
    const [showNotify, setShowNotify] = useState('hide');
    const [notifyDescription, setNotifyDescription] = useState('');
    const [notifyType, setNotifyType] = useState('success');
    const setShowLoadingBar = useContext(LoadingContext);

    function showNotifyWindow(action, type, desc, age = 3000) {
        if (action == 'show') {
            setTimeout(() => {
                setShowNotify('hide');
            }, age)
        }
        setShowNotify(action);
        setNotifyType(type);
        setNotifyDescription(desc);

    }
    //End ====

    //Pagination start
    const [totalPage, setTotalPage] = useState(1);
    const [activePage, setActivePage] = useState(1);
    const [startIndex, setStartIndex] = useState(0);
    const [endIndex, setEndIndex] = useState(PAGING_END_INDEX);

    function onPagePrevious() {
        let previousPage = startIndex + 1 - PAGING_END_INDEX;
        setActivePage(previousPage);

        if (startIndex !== 0) {
            setStartIndex(startIndex - PAGING_END_INDEX);
            setEndIndex(endIndex - PAGING_END_INDEX);
        }
        getReferralData(DEFAULT_PAGING_SIZE, previousPage, searchValue);
    }

    function onPageUp(e) {
        let page = Number(e.target.id)
        setActivePage(page);
        getReferralData(DEFAULT_PAGING_SIZE, page, searchValue);
    }

    function onPageNext() {
        let nextPage = startIndex + 1 + PAGING_END_INDEX;
        if (endIndex === totalPage || totalPage <= PAGING_END_INDEX) {
            setActivePage(nextPage);
            setStartIndex(startIndex);
            setStartIndex(endIndex);
        } else {
            setActivePage(nextPage);
            setStartIndex(startIndex + PAGING_END_INDEX);
            setEndIndex(endIndex + PAGING_END_INDEX);
        }
        getReferralData(DEFAULT_PAGING_SIZE, nextPage, searchValue);
    }
    //Pagination ends

    function addNew() {
        if (!permission) {
			showNotifyWindow('show','error', i18n.t('errorMessages.permission_error'));
			return;
		}
        setHeader(i18n.t("dictionariesPages.referralList.addHeader"));
        setShowModalWindow(true);
    }

    function onHide() {
        setEditForm(false);
    }
    function onDeleteHide() {
        setDeleteReferralName('');
        setDeleteReferralId('');
        setShowDeleteModalWindow(false);
    }

    function getReferralData(pageSize, page, searchString) {
        setShowLoadingBar(true);
        let result = null;
        result = service.ListReferrals(pageSize, page, isAdminModule,isAdminModule === 'true' ? '' : practicePK, searchString);
        result.then(response => {
            if (response.data.results !== undefined) {
                setTotalPage(Math.ceil(response.data.count / response.data.page_size));
            }
            const rowArray = commonTableBody(response.data.results, ReferalTableData.tableBodyData[0])
			ReferalTableData.tableBodyData = rowArray;
            setReferralList(response.data.results);
            setShowLoadingBar(false);
        });
    }

    useEffect(() => {
        getReferralData(DEFAULT_PAGING_SIZE, activePage);
        if(location.pathname === ROUTE_REFERRAL_SOURCES){
            setPermission(checkPermission(super_admin_privileges.super_admin_full_privilege,super_admin_permission_key_value.dictionary_add)) 
        } else {
            setPermission(checkPermission(permission_key_values_practice_dictionaries.practice_dictionaries_sub_module_modify,
                permission_key_values_practice_dictionaries.practice_dictionaries_sub_module_add,
                super_admin_privileges.super_admin_full_privilege))
        }
    }, []);

    function onEditReferralType(referralId) {
        setHeader(i18n.t("dictionariesPages.referralList.editHeader"));
        setShowLoadingBar(true);
        let data = null;
        if (isAdminModule === 'true')
            data = service.GetReferral(referralId);
        else
            data = service.GetReferral(referralId, practicePK);
        data.then(response => {
            setShowModalWindow(true);
            setShowLoadingBar(false);
            setReferralType(response.data.name);
            setEditForm(true);
            setEditReferralId(referralId);
        });
    }
    function onDeleteReferralType(referralId) {
        referralList.map((item) => {
            if (item.id == parseInt(referralId)) {
                setDeleteReferralName(item.name);
                setDeleteReferralId(item.id);
                setShowDeleteModalWindow(true);
            }

        });
    }

    function onDeleteAction() {
        setShowLoadingBar(true);
        let data = null;
        if (isAdminModule === 'true')
            data = service.DeleteReferral(deleteReferralId);
        else
            data = service.DeleteReferral(deleteReferralId, practicePK);
        data.then(() => {
            setShowLoadingBar(false);
            setDeleteReferralName('');
            setDeleteReferralId('');
            setShowDeleteModalWindow(false);
            showNotifyWindow('show', 'success', DELETE_SUCCESS);
            getReferralData(DEFAULT_PAGING_SIZE, activePage, searchValue);
        });
    }

    function resetForm() {
        setReferralType('');
        setEditForm(false);
        setFormError({
            ...form_error,
            ['referralType']: ''
        });

    }
    function onSaveFormData(e) {
        e.preventDefault();
            setFormError({
                ...form_error,
                ['referralType']: !referralType.trim()  ? 'error': ''
            });
       if(referralType){
            setShowLoadingBar(true);
            let result = null;
            let data = null;
            data=isAdminModule === 'true' ? { name: referralType } : { practice: practicePK, name: referralType }
            result=editForm ? service.UpdateReferral(editReferralId, data) : service.AddReferral(data);
            result.then(response => {
                setShowLoadingBar(false);
                if (response.status == 201 || response.status == 200) {
                    if (response.status == 201)
                        showNotifyWindow('show', 'success', ADD_SUCCESS);
                    else showNotifyWindow('show', 'success', UPDATE_SUCCESS);
                    getReferralData(DEFAULT_PAGING_SIZE, activePage, searchValue);
                    resetForm();
                    setShowModalWindow(false);
                } else if (response.status === 400 && response.data.record_already_exists !== undefined) {
                    showNotifyWindow('show', 'error', i18n.t('errorMessages.record_exists'));
                } else {
                    showNotifyWindow('show', 'error', ADD_ERROR);
                }


            });
        }

    }
    function onHandleChange(e) {
        const { name } = e.target;
        let value = e.target.value;
        let trimmedValue = '';
        if (e.target.type === "text") {
            trimmedValue = value;
        }
         if (name == "searchValue") {
             setSearchValue(trimmedValue);
             return;
         }
        if (!trimmedValue) {
            setFormError({
                ...form_error,
                [name]: 'error'
            });
        } else {
            setFormError({
                ...form_error,
                [name]: ''
            });
        }
        setReferralType(trimmedValue);
    }

     const handleKeyDown = (e) => {
         if (e.key === "Enter" && searchValue) {
             onSearch(e, searchValue);
         }
     };

    function onSearch(e, searchString) {
        e.preventDefault();
        setActivePage(1);
        setStartIndex(0);
         getReferralData(DEFAULT_PAGING_SIZE, 1, searchString);
    }

    const onClose = () => {
        setSearchValue("");
        setActivePage(1);
        setStartIndex(0);
        getReferralData(DEFAULT_PAGING_SIZE, 1);
    };
   
    function backToDictionaryPage() {
        if (isAdminModule === 'true')
            props.history.push(ROUTE_SUPER_DICTIONARIES);
        else
            props.history.push(ROUTE_PRACTICE_DICTIONARIES);
    }

    function dropDownFunction(id, name) {
        if (!permission) {
            showNotifyWindow('show', 'error', i18n.t('errorMessages.permission_error'));
            return;
        }
        if (name === 'Edit'){
            onEditReferralType(id);
        }
        else{
            onDeleteReferralType(id);
        }
    }


    return (
        <React.Fragment>
            <Notify
                showNotify={showNotify}
                setShowNotify={setShowNotify}
                notifyDescription={notifyDescription}
                setNotifyType={setNotifyType}
                setNotifyDescription={setNotifyDescription}
                notifyType={notifyType}
            />
            <div className="col-md-8">
                <div className="box box-content-white mb-3">
                    <div className="row mr-0 mb-2">
                        <div className="col pl-4 mb-1">
                            <div className={"dataTables_filter"}>
                                <div
                                    className="link dictionaries-back"
                                    onClick={backToDictionaryPage}>
                                    <BackArrowWithLabel
                                        label={i18n.t(
                                            "dictionariesPages.gotoDictionaries"
                                        )}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="box-content ">
                        <div className="common-search-wrapper-style">
                            <div className="input-content-box">
                                <div className="form-group padding-top15 relative">
                                    <TextInput
                                        type="text"
                                        id="searchValue"
                                        name="searchValue"
                                        value={searchValue}
                                        onValueChange={onHandleChange}
                                        label={i18n.t(
                                            "dictionariesPages.referralList.lblReferralSource"
                                        )}
                                        onKeyDown={handleKeyDown}
                                        labelClassName={"margin-bottom0"}
                                        clearButton={
                                            typeof searchValue == "string" &&
                                            searchValue.length !== 0
                                        }
                                        clearInput={onClose}
                                    />
                                </div>
                            </div>
                            <div className="input-content-box padding-top15">
                                <div className="margin-top18">
                                    <CommonButton
                                        variant="contained"
                                        label="Search"
                                        onClick={(e) =>
                                            onSearch(e, searchValue)
                                        }
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="box box-content-white">
                    <div className="box-content">
                        <div className="alignRight margin-bottom3">
                            <CommonButton
                                variant="contained"
                                onClick={addNew}
                                label={i18n.t("buttons.addNew")}
                            />
                        </div>
                        <div className="table-responsive">
                            <Table
                                tableObject={ReferalTableData}
                                dropDownFunction={dropDownFunction}
                            />
                        </div>
                        <div className="mt-2 mb-2">
                            <Pagination
                                totalPage={totalPage}
                                activePage={activePage}
                                startIndex={startIndex}
                                endIndex={endIndex}
                                onPagePrevious={onPagePrevious}
                                onPageUp={onPageUp}
                                onPageNext={onPageNext}
                            />
                        </div>
                    </div>
                </div>
            </div>
            <CustomizedDialogs
                showModal={showModalWindow}
                type="save"
                header={header}
                setShowModalWindow={setShowModalWindow}
                resetForm={resetForm}
                onHide={onHide}>
                <Form
                    id="form_dataEntry"
                    onSubmit={(e) => onSaveFormData(e)}
                    encType="multipart/form-data">
                    <Form.Group>
                        <div className="row">
                            <div className="col-md-12">
                                <div className="form-group padding-top15">
                                    <TextInput
                                        type="text"
                                        required={true}
                                        id="referralType"
                                        name="referralType"
                                        label={i18n.t(
                                            "dictionariesPages.referralList.lblReferralSource"
                                        )}
                                        onValueChange={onHandleChange}
                                        className={
                                            form_error.referralType
                                                ? "input-error"
                                                : ""
                                        }
                                        value={referralType}
                                    />
                                </div>
                            </div>
                        </div>
                    </Form.Group>
                </Form>
            </CustomizedDialogs>
            <CustomizedSmallDialogs
                showModal={showDeleteModalWindow}
                header={i18n.t("commons.confirmDelete")}
                type="delete"
                deleteItem={onDeleteAction}
                resetForm={resetForm}
                onHide={onDeleteHide}
                setShowModalWindow={setShowDeleteModalWindow}>
                {i18n.t("dictionariesPages.referralList.deletConfirmation") +
                    "'" +
                    deleteReferralName +
                    "'?"}
            </CustomizedSmallDialogs>
        </React.Fragment>
    );
}
export default ReferralList