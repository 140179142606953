import React, { useContext, useEffect, useState } from 'react'
import { useHistory } from "react-router-dom";
import * as staticConfigs from '../../utilities/staticConfigs'
import SelectInput from '../commons/input/SelectInput';
import { TASK_STATUS, ASSIGNED_TYPE, LINK_TYPE } from '../../utilities/dictionaryConstants';
import { getStorage } from '../../utilities/browserStorage';
import service from './service';
import LoadingContext from '../../container/loadingContext';
import Notify from '../commons/notify';
import { DELETE_SUCCESS, TASK_NOT_FOUND, UPDATE_SUCCESS } from '../../utilities/labelConfigs';
import i18n from '../../utilities/i18n';
import RightSidePanel from '../commons/RightSidePanel/RightSidePanel';
import CalendarInput from '../commons/input/CalendarInput';
import TextInput from '../commons/input/input';
import { MaterialMultiselect } from '../../MaterialMultiselect/MaterialMultiselect';
import { checkPermission } from '../../utilities/commonUtilities';
import { permission_key_values_tasks } from '../../utilities/permissions';
import TaskListTable from './TaskListTable';
import { useDispatch } from 'react-redux';
import { resetTaskDetails } from './TaskSlice/taskSlice';

function TaskList() {
    const dispatch = useDispatch();
    const practicePK = getStorage('practice');
    const history = useHistory();
    const setShowLoadingBar = useContext(LoadingContext);
    const [activeRowId, setActiveRowId] = useState(1);
    const [task,setTask]=useState('');
    const [tasksList, setTasksList] = useState([]);

    const [associateUsersList, setAssociateUsersList] = useState([]);
    // notify
    const [showNotify, setShowNotify] = useState("hide");
    const [notifyDescription, setNotifyDescription] = useState("");
    const [notifyType, setNotifyType] = useState("success");
    const [assignedType, setAssignedType] = useState("1");

    const [searchBy, setSearchBy] = useState({
        "task_title": "", "due_dateFrom": "", "due_dateTo": "", "assignedTo": [], "description": "", "status": [],
        "TaskLinkType": [],
    });

    function getTaskList() {
        let assigneType = "assigned_by_me"
        if (assignedType === "1") {
            assigneType = "assigned_to_me"
        }
        let result = service.ListTasks(practicePK, assigneType);
        setShowLoadingBar(true);
        result.then((response) => {
            setTasksList(response.data);
        }).then(() => {
            setShowLoadingBar(false);
        });
    }
   
    function onDeleteTask(id) {
        setShowLoadingBar(true);
        let data = {
            "task_id": [id]
        }
        let result = service.DeleteTask(data);
        result.then((response) => {
            setShowLoadingBar(false);
            if (response.status === 200) {
                showNotifyWindow("show", "success", DELETE_SUCCESS);
                getTaskList();
            }
        });
    }
    const onEditTask = (id) => {
        history.push('/my-tasks/edit', { task_id: id });
    }

    function showNotifyWindow(action, type, desc, age = 3000) {
        if (action === "show") {
            setTimeout(() => {
                setShowNotify("hide");
            }, age);
        }
        setShowNotify(action);
        setNotifyType(type);
        setNotifyDescription(desc);
    }

    useEffect(() => {
        getTaskList();
    }, [assignedType])

    function onHandleTaskChange(e, id) {
        setShowLoadingBar(true);
        let name = e.target.name;
        let value = e.target.value;
        if (name === 'status') {
            let data = {
                "task_id": id,
                "status": value
            }
            let result = service.UpdateTaskStatus(data);
            result.then((response) => {
                if (response.status === 200) {
                    showNotifyWindow("show", "success", UPDATE_SUCCESS);
                    getTaskList();
                }
                else if (response.status === 400) {
                    showNotifyWindow("show", "error", TASK_NOT_FOUND);
                    getTaskList();
                }
            })
                .catch((err) => {
                    showNotifyWindow("show", "error", TASK_NOT_FOUND);
                    console.error(err);
                })
                .finally(() => {
                    setShowLoadingBar(false);
                });
        }
    }

    function onShowActionButtons(event, rowId) {
        let dropdown = document.getElementById('dropdown-' + rowId);
        let dropdownMenu = document.getElementById('dropdown-menu-' + rowId);
        dropdown.classList.toggle('show');
        dropdownMenu.classList.toggle('show');
        if (activeRowId === 0) {
            setActiveRowId(rowId);
        } else if (activeRowId === rowId) {
            setActiveRowId(0);
        }
    }

    function onHideActionButtons(event, rowId) {
        let dropdown = document.getElementById('dropdown-' + rowId);
        let dropdownMenu = document.getElementById('dropdown-menu-' + rowId);
        dropdown.classList.remove('show');
        dropdownMenu.classList.remove('show');
        setActiveRowId(0);
    }
    function onHandleTypeChange(e) {
        setAssignedType(e.target.value);
    }

    function onHandleSearch(e) {
        let name = e.target.name;
        let value = e.target.value;
        setSearchBy({
            ...searchBy, [name]: value
        })
    }

    function onHandleSearchDate(value, name) {
        setSearchBy({
            ...searchBy, [name]: value
        })
    }

    function onCancel() {
        document.querySelector('button.close-right-side').click();
    }

    function getAssociateUsers(pageSize, page) {
        let result = service.ListAssociateUsers(pageSize, page);
        result.then((response) => {
            setAssociateUsersList(response.data);
        })
            .catch((err) => {
                console.error(err);
            })
    }

    useEffect(() => {
        let page = 0;
        getAssociateUsers(staticConfigs.DEFAULT_PAGING_SIZE, page);
    }, []);

    function onSearchTasks() {

        setShowLoadingBar(true);
        setTask('')
        let result = service.SearchTasks(practicePK, searchBy);
        result.then((response) => {
            setTasksList(response.data);
        })
            .catch(() => {
                showNotifyWindow('show', 'error', i18n.t('errorMessages.commonError'));
                setShowLoadingBar(false);
            })
            .finally(() => {
                setShowLoadingBar(false);
            });
        
    }
    function onSearchTasksByTitleOrDescrptn(event) {
        setTask(event.target.value)
        let value = event.target.value
        if (value.length > 2 || value == null || value == undefined || value == "") {
            let result = service.SearchTasksByTitle(practicePK, value);
            result.then((response) => {
                setShowLoadingBar(true);
                setTasksList(response.data);
            })
                .catch(() => {
                    showNotifyWindow('show', 'error', i18n.t('errorMessages.commonError'));
                })
                .finally(() => {
                    setShowLoadingBar(false);
                });
        }
    }

    function RoutetoTask() {
        dispatch(resetTaskDetails());
        history.push(staticConfigs.ROUTE_MY_TASKS);
    }

    return (
        <div className='col-md-12'>
            <div className="box payment-box border-radius-8 padding-left20  margin-right15">
                <Notify showNotify={showNotify} setShowNotify={setShowNotify} notifyDescription={notifyDescription} setNotifyType={setNotifyType} setNotifyDescription={setNotifyDescription} notifyType={notifyType} />
                <div className='row margin-right10 padding-bottom15'>
                    <div className='col-4 margin-top15'>
                        <TextInput
                            id={'searchByTaskTitle'}
                            name={'searchByTaskTitle'}
                            onValueChange={onSearchTasksByTitleOrDescrptn}
                            label={'Search for tasks by title or description'}
                            value={task}
                        />
                    </div>
                    <div className='col-4 margin-top40'>
                        <RightSidePanel title={i18n.t("commons.advancedSearch")} onclickLabel={i18n.t("commons.advancedSearch")}>
                            <div className="form-group mt-5">
                                <TextInput  type='text' data-testid="task-id" name='task_title' className='task-name-title' id='task-id' value={searchBy.task_title}
                                    onValueChange={(e) => onHandleSearch(e)} label={i18n.t('task.taskListScreen.TaskTitle')}
                                />
                            </div>
                            <div className='d-flex justify-content-between'>
                                <div className='mr-2'>
                                    <CalendarInput id='Task Due Date From' name={'due_dateFrom'} selected={searchBy.due_dateFrom}
                                        onValueChange={(e) => onHandleSearchDate(e, 'due_dateFrom')} label={i18n.t('task.taskListScreen.TaskDueDate')}
                                    />
                                </div>
                                <div className='ml-2'>
                                    <CalendarInput id='Task Due Date To' name={'due_dateTo'} selected={searchBy.due_dateTo}
                                        onValueChange={(e) => onHandleSearchDate(e, 'due_dateTo')}
                                        label={i18n.t('task.taskListScreen.TaskDueDateTo')} minDate={searchBy.due_dateFrom}
                                    />
                                </div>
                            </div>
                            <div className="form-group">
                                <TextInput name={'description'} value={searchBy.description}
                                    onValueChange={onHandleSearch} label={i18n.t('task.taskListScreen.TaskDescription')}
                                />
                            </div>
                            <div className="pb-3">
                                <MaterialMultiselect name={'assignedTo'} value={searchBy.assignedTo} options={associateUsersList}
                                    onValueChange={onHandleSearch} label={i18n.t('task.taskListScreen.TaskAssignedTo')}
                                />
                            </div>
                            <div className="pb-3">
                                <MaterialMultiselect options={TASK_STATUS} name={'status'} value={searchBy.status}
                                    onValueChange={onHandleSearch} label={i18n.t('task.taskListScreen.TaskStatus')}
                                />
                            </div>
                            <div className="pb-3">
                                <MaterialMultiselect name={'TaskLinkType'} value={searchBy.TaskLinkType} options={LINK_TYPE}
                                    onValueChange={onHandleSearch} label={i18n.t('task.taskListScreen.TaskLinkType')}
                                />
                            </div>
                            <div className='d-flex justify-content-end'>
                                <button type='button' id='close-id' data-testid='close-button' onClick={onCancel} className='btn btn-secondary px-4'>Cancel</button>
                                <button type='button' id='saveButton' onClick={onSearchTasks} className='btn btn-primary-blue ml-4'>Search</button>
                            </div>
                        </RightSidePanel>
                    </div>


                    <div className='col-4  margin-top40 justify-right '>
                        <SelectInput data={ASSIGNED_TYPE} name={'assignedType'} value={assignedType}
                            onValueChange={(e) => onHandleTypeChange(e)}
                            label={''}
                            selectOptionRemove={true}
                        />
                    </div>
                </div>
            </div>    

            <div className="box margin-right15 margin-top20">
                <div className='justify-right'>
                    {/* permission checking */}
                    {checkPermission(permission_key_values_tasks.task_add) &&
                        <button type='button' data-testid='add-task-btn' onClick={RoutetoTask} className='btn btn-primary-blue'>
                            {i18n.t("task.taskListScreen.addTask")}
                        </button>
                    }
                </div>
                <div className='margin-top15 pb-5 margin-bottom15' >
                    <TaskListTable tasksList={tasksList}  onHandleTaskChange={onHandleTaskChange} onShowActionButtons={onShowActionButtons} onHideActionButtons={onHideActionButtons} onDeleteTask={onDeleteTask} onEditTask={onEditTask} tblRadiusClass={' table-border-radius8 '} />
                </div>
            </div>
        </div>
    )
}

export default TaskList