import React from 'react';
import { Link } from 'react-router-dom';
import {PRACTICE_DICT_TYPE_CODES} from '../../../utilities/dictionaryConstants';

const PracticeCodes = () => { 
	const arrDictTypes_1 = [ PRACTICE_DICT_TYPE_CODES];
	return (
		<React.Fragment>
            <div className="col-md-12">
                <div className="box dictionary-box bordering border-radius-8">
                    <div className="dictionaries-list-round dictionary-box"> 
                        <div className="dictionary-column-round dictionary-box">
                            {Array.isArray(arrDictTypes_1) && arrDictTypes_1.length && 
                                arrDictTypes_1.map((mainItem) => { 
                                    return( 
                                        <div className="dictionary-type" key={mainItem}>
                                            <div className="dictionary-caption">{mainItem.dictType}</div>

                                            {Array.isArray(mainItem.dictItems) && mainItem.dictItems.length && 
                                                    mainItem.dictItems.map((eachItem, mainItemIndex)  => {
                                                        
                                                if(eachItem.routeLink.length > 1){
                                                    return( 
                                                        <div className="dictionary-item" key={mainItemIndex}>
                                                            <Link to={eachItem.routeLink}>{eachItem.name}</Link>
                                                        </div>
                                                        )
                                                }else{
                                                    return( 
                                                        <div className="dictionary-item" key={mainItemIndex}>
                                                            <div className="dictionary-non-link-text">{eachItem.name}</div>
                                                            {/* <label className='dictLabel'>{eachItem.name}</label> */}
                                                        </div>
                                                    )
                                                } 
                                            })
                                            }
                                        </div>
                                    )
                                })
                            }
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
	)
}
export default PracticeCodes
