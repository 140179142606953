import React, { useEffect, useState } from 'react'
import { DEFAULT_PAGING_SIZE } from '../../../utilities/staticConfigs';
import service from './service';
import SelectInput from '../../commons/input/SelectInput';


function StaffUserRoleSelector({value,onValueChange}) {
    
    const [roleList, setRoleList] =  useState([]);

    useEffect(()=> {
        getUserRoleData(DEFAULT_PAGING_SIZE,0)
    },[])

    function getUserRoleData(pageSize,page) {
        const result = service.getRolesToUserMgmnt(pageSize,page);
        result.then(response => {
          const data = response?.data?.map(item => {
            return {
                id: item.group_id,
                name:item.role_name,
            }
            });
          setRoleList(data);
        })
      }

  return (
    <div>
          <SelectInput
              data={roleList}
              name='staffUserRole'
              id='staffUserRole'
              label='Staff User Role'
              value={value}
              onValueChange={onValueChange}
              required={true}
              error={!value}
          />
    </div>
  )
}

export default StaffUserRoleSelector