import React from 'react';
import { Link } from "react-router-dom";
import Moment from "react-moment";
import actionImg from "../../../assets/images/action.png";
import i18n from "../../../utilities/i18n";
import { checkPermission, currencyFormat } from "../../../utilities/commonUtilities";
import imgCardFrontdAdd from "../../../assets/images/card_blank_front.svg"; // card-front-add.png
import imgCardBackdAdd from "../../../assets/images/card_blank_back.svg"; // card-back-add.png
import ProfileCardForInsurance from "../../commons/ProfileCardForInsurance";
import { format } from "date-fns";
import { permission_key_values_patient } from '../../../utilities/permissions';
import CommonButton from '../../commons/Buttons';

export const PatientBillingInfoTable = (props) => {
    return (
        <table id="" className="table lh-1_5">
            <thead>
                <tr>
                    <th className="width-75">
                        {i18n.t(
                            "patientPages.insuranceInfo.manageAuthorization.identifier"
                        )}
                    </th>
                    <th className="width-75">
                        {i18n.t(
                            "patientPages.insuranceInfo.manageAuthorization.type"
                        )}
                    </th>
                    <th className="width-75">
                        {i18n.t(
                            "patientPages.insuranceInfo.manageAuthorization.unitsApproved"
                        )}
                    </th>
                    <th className="width-75">
                        {i18n.t(
                            "patientPages.insuranceInfo.manageAuthorization.unitsUsed"
                        )}
                    </th>
                    <th className="width-75">
                        {i18n.t(
                            "patientPages.insuranceInfo.manageAuthorization.unitsRemaining"
                        )}
                    </th>
                    <th className="width-75">
                        {i18n.t(
                            "patientPages.insuranceInfo.manageAuthorization.validFrom"
                        )}
                    </th>
                    <th className="width-75">
                        {i18n.t(
                            "patientPages.insuranceInfo.manageAuthorization.validTo"
                        )}
                    </th>
                    <th className="width-75">
                        {i18n.t(
                            "patientPages.insuranceInfo.manageAuthorization.provider"
                        )}
                    </th>
                    <th className="width-75">
                        {i18n.t("dictionariesPages.action")}
                    </th>
                </tr>
            </thead>
            <tbody>
                {props.authorizedContactsList &&
                    props.authorizedContactsList.length === 0 && (
                        <tr>
                            <td align="center" colSpan="9">
                                {props.initialTableData}
                            </td>
                        </tr>
                    )}
                {props.authorizedContactsList.map(
                    (authConitem, index1) => {
                        return (
                            <tr key={index1}>
                                <td align={props.insuranceType == "primaryInsurance" ? "center" : ""}>
                                    {authConitem.identifier}
                                </td>
                                <td>
                                    {authConitem.authorization_referral_name}
                                </td>
                                <td align="center">
                                    {authConitem.units_approved}
                                </td>
                                <td align="center">
                                    {authConitem.units_used}
                                </td>
                                <td align="center">
                                    {authConitem.units_remaining}
                                </td>
                                <td align="center">
                                    <Moment format="MM/DD/YYYY">
                                        {authConitem.valid_from}
                                    </Moment>
                                </td>
                                <td align="center">
                                    <Moment format="MM/DD/YYYY">
                                        {authConitem.valid_to}
                                    </Moment>
                                </td>
                                <td>{(authConitem.authorization_referral==2)
                                ? authConitem.referring_provider_name
                                : authConitem.provider_name}</td>
                                <td align="center">
                                    <div
                                        className="dropdown"
                                        id={"dropdown-" + authConitem.id}
                                    >
                                        <button
                                            id={
                                                "dropdownMenuButton-" +
                                                authConitem.id
                                            }
                                            className="dropdown-toggle"
                                            type="button"
                                            data-testid={"dropdown-menu-item" + authConitem.id}
                                            data-toggle="dropdown"
                                            aria-haspopup="true"
                                            onClick={(e) =>
                                                props.onShowActionButtons(
                                                    e,
                                                    authConitem.id
                                                )
                                            }
                                            onBlur={(e) =>
                                                props.onHideActionButtons(
                                                    e,
                                                    authConitem.id
                                                )
                                            }
                                        >
                                            <img
                                                id="test"
                                                src={actionImg}
                                                alt="Action"
                                            />
                                        </button>
                                        <div
                                            className="dropdown-menu"
                                            id={
                                                "dropdown-menu-" + authConitem.id
                                            }
                                            data-testid={"dropdown-menu" + index1}
                                        >
                                            <Link
                                                className="dropdown-item edit"
                                                to="#"
                                                onMouseDown={() =>
                                                    props.onEditAuthorization(
                                                        authConitem.id
                                                    )
                                                }
                                                data-testid={"dropdown-item-edit" + index1}
                                            >
                                                {"Edit"}
                                            </Link>
                                            <Link
                                                className="dropdown-item edit"
                                                to="#"
                                                onMouseDown={() =>
                                                    props.onDeleteAuthorization(
                                                        authConitem.id
                                                    )
                                                }
                                                data-testid={"dropdown-item-delete" + index1}
                                            >
                                                {"Delete"}
                                            </Link>
                                        </div>
                                    </div>
                                </td>
                            </tr>
                        );
                    }
                )}
            </tbody>
        </table>
    )
}

export const InsuranceDataTable = (props) => {
    return (
        <>
            {props.insuraceList &&
                props.insuraceList.length !== 0 &&
                props.insuraceList.map((item, index) => {
                    return (
                        <div
                            className="mt-1 "
                            key={index}
                        >
                            
                            {getInsuranceName(props.insuranceName, item)}
  

                            <div className='row margin-top25'>
                                <div className='col-5'>
                                    <div className='justify-space-between'>
                                        <div className="pl-0 padding-top10" >
                                            <label className="subscriber-name padding-top8" htmlFor="labelSubsriber">
                                                {i18n.t("patientPages.insuranceInfo.subscriber")}:{" "}
                                                <span className="">
                                                    {item.name} ({item.relationship_name})
                                                </span>
                                            </label>
                                        </div>
                                        <div className="justify-right">
                                            {checkPermission(permission_key_values_patient.can_change_insurance_info) &&
                                                
                                                <CommonButton variant="outlined" onClick={() => props.editInsurance(item.id)} label={i18n.t("patientPages.buttons.edit")} />
        
                                            }
                                        </div>
                                    </div> 
                                </div>
                                <div className='col-6 justify-left' style={{ paddingLeft: "351px"}}>
                                    <CommonButton variant="outlined" onClick={() => props.showAuthorizations(item.id)} label={i18n.t("patientPages.buttons.manageAuthorization")}/>
                                </div>
                                <div className='col-1'>
                                    &nbsp;  
                                </div>
                            </div>

                            <div className='row mt-3'>
                                <div className='col-5'>
                                    <table className="insurance_table">
                                        <tbody>
                                            <tr className="policy-data-td">
                                                <td className="width-45-perc policy-data-td">
                                                    {i18n.t(
                                                        "patientPages.insuranceInfo.labelPolicyID"
                                                    )}
                                                </td>
                                                <td className="width-55-perc policy-data-td" style={{fontWeight:600}}>
                                                    {item.policy_id}
                                                </td>
                                            </tr>
                                            <tr className="policy-data-td">
                                                <td className="policy-data-td">
                                                    {i18n.t(
                                                        "patientPages.insuranceInfo.labelGroupNumber"
                                                    )}
                                                </td>
                                                <td className="policy-data-td" style={{fontWeight:600}}>{item.group_number}</td>
                                            </tr>
                                            <tr className="policy-data-td">
                                                <td className="policy-data-td">
                                                    {i18n.t(
                                                        "patientPages.insuranceInfo.labelCopay"
                                                    )}
                                                </td>
                                                <td className="policy-data-td" style={{fontWeight:600}}>{currencyFormat(item.copay)}</td>
                                            </tr>
                                            <tr className="policy-data-td">
                                                <td className="policy-data-td">
                                                    {i18n.t(
                                                        "patientPages.insuranceInfo.labelCoInsurance"
                                                    )}
                                                </td>
                                                <td className="policy-data-td" style={{fontWeight:600}}>
                                                    {currencyFormat(item.co_insurance
                                                        ? parseFloat(item.co_insurance).toFixed(2)
                                                        : "0.00")}
                                                </td>
                                            </tr>
                                            <tr className="policy-data-td">
                                                <td className="policy-data-td">
                                                    {i18n.t(
                                                        "patientPages.insuranceInfo.labelDeductible"
                                                    )}
                                                </td>
                                                <td className="policy-data-td" style={{fontWeight:600}}>{currencyFormat(item.deductibles)}</td>
                                            </tr>
                                            <tr className="policy-data-td">
                                                <td className="policy-data-td">
                                                    {i18n.t(
                                                        "patientPages.insuranceInfo.labelOutOfPocket"
                                                    )}
                                                </td>
                                                <td className="policy-data-td" style={{fontWeight:600}}>{currencyFormat(item.out_of_pocket)}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                                <div className='col-6'>
                                    {getImageCard(item,props.insuranceName,index,props)}
                                </div>
                                <div className='col-1'>
                                    &nbsp;  
                                </div>
                            </div>

                            <div className='row mt-3'>
                                <div className='col-8 flex'>
                                    {checkPermission(permission_key_values_patient.can_view_eligibility_insurance_info) &&
                                        <div className='mt-4'>
                                        <CommonButton  variant="contained" onClick={(e) =>
                                            props.checkInsuranceEligibility(e, item)} label={i18n.t(
                                                "patientPages.buttons.checkEligibility"
                                            )} />
                                            {
                                            item.last_checked_on && (
                                                <Link
                                                    to="#"
                                                    data-testid={`get-elibility-${item.id}`}
                                                    className={"absolute  pl-4 padding-top19"}
                                                    onClick={(e) =>
                                                        props.getInsuranceEligibility(e, item)
                                                    }
                                                >
                                                    {i18n.t(
                                                        "patientPages.insuranceInfo.lastCheckedOn"
                                                    )}
                                                    :{" "}
                                                    {format(
                                                        new Date(item.last_checked_on),
                                                        "MM/dd/yyyy hh:mm a"
                                                    )}
                                                </Link>
                                                )
                                            }
                                        </div>
                                    }
                                    
                                </div>
                                <div className='col-4 justify-space-between'>
                                    &nbsp; 
                                </div>
                            </div>








                            
                            
                        </div>
                    );
                })}
        </>
    )
}

//insurance name is displayed here
const getInsuranceName = (insuranceName, item) => {
    if (insuranceName == "Primary Insurance") {
        return (
            <div className="row" style={{ marginLeft: "0px", marginRight: "0px", borderBottom: "1px solid #E0E0E0", paddingBottom: "12px"}} >
                <div className="div-inline">
                    <span className='insurance-type'>{i18n.t("patientPages.insuranceInfo.labelPrimary")}{" - "}</span>
                    <span className='insurance-name'>{" ("} {item.insurance_name} ({item.insurance_id}){" "}
                    {" )"}</span>
                </div>
            </div>
        )
    }
    else if (insuranceName == "Secondary Insurance") {
        return (
            <div className="row mt-5" style={{ marginLeft: "0px", marginRight: "0px", borderBottom: "1px solid #E0E0E0", paddingBottom: "5px"}}>
                <label className="div-inline">
                    <span className='insurance-type'>{i18n.t("patientPages.insuranceInfo.labelSecondary")}{" - "}</span>
                    <span className='insurance-name'> {" ("} {item.insurance_name} ({item.insurance_id}){" "}
                    {" )"}</span>
                </label>
            </div>
        )
    }
    else {
        return (
            <div className="row mt-5" style={{ marginLeft: "0px", marginRight: "0px", borderBottom: "1px solid #E0E0E0", paddingBottom: "5px"}} >
                <label className="div-inline">
                    <span className='insurance-type'> {i18n.t("patientPages.insuranceInfo.labelTertiary")}{" - "}</span>
                    <span className='insurance-name'>{" - ("} {item.insurance_name} ({item.insurance_id}){" "}
                    {" )"}</span>
                </label>
            </div>
        )
    }
}

//image card rendering with insurance type

const getImageCard = (item, insuranceName,index,props) => {
    if (insuranceName == 'Primary Insurance') {
        return (
            <div
                className="imageCardMainRow"
                style={{
                    display: "flex",
                    justifyContent: "flex-start"
                }}
            >
                <div className="imageCardCol margin-right25">
                    <div className={"imgCardRow"} >
                        {item.card_front_page && (
                            <div className="width-100-perc insurance-card-bg">
                                <ProfileCardForInsurance
                                    image={item.card_front_page}
                                    imageUploaded={true}
                                    alt={i18n.t(
                                        "patientPages.insuranceInfo.insuranceCardFront"
                                    )}
                                    id={"insuranceCardFront" + index}
                                    onClick={() =>
                                        props.callClickEvent(
                                            item.id,
                                            "file_PrimaryFrontCard" + index
                                        )
                                    }
                                    onMouseOver={() =>
                                        props.showImageAddEdit(
                                            "show",
                                            "divCardImageAddFront" + index
                                        )
                                    }
                                    onMouseLeave={() =>
                                        props.showImageAddEdit(
                                            "hide",
                                            "divCardImageAddFront" + index
                                        )
                                    }
                                    onRemoveImage={() =>
                                        props.resetImage(
                                            item.id,
                                            "insuranceCardFront"
                                        )
                                    }
                                >
                                    <div className="fontSize14">
                                        {i18n.t(
                                            "patientPages.insuranceInfo.insuranceCardFront"
                                        )}
                                    </div>
                                </ProfileCardForInsurance>
                            </div>
                        )}
                        {!item.card_front_page && (
                            <div className="width-100-perc insurance-card-bg">
                                <ProfileCardForInsurance
                                    image={imgCardFrontdAdd}
                                    imageUploaded={false}
                                    id={"insuranceCardFront1" + index}
                                    alt={i18n.t(
                                        "patientPages.insuranceInfo.insuranceCardFront"
                                    )}
                                    style={{ marginLeft: "12px", width: "min-content" }}
                                    className={"card-wh"}
                                    onClick={() =>
                                        props.callClickEvent(
                                            item.id,
                                            "file_PrimaryFrontCard" + index
                                        )
                                    }
                                >
                                    <div className="fontSize14">
                                        {i18n.t(
                                            "patientPages.insuranceInfo.insuranceCardFront"
                                        )}
                                    </div>
                                </ProfileCardForInsurance>
                            </div>
                        )}
                        <div
                            className="hidden"
                            id={"divCardImageAddFront" + index}
                        >
                            {checkPermission(permission_key_values_patient.can_change_insurance_card) &&
                            <div
                                className="card-image"
                                onMouseOver={() =>
                                    props.showImageAddEdit(
                                        "show",
                                        "divCardImageAddFront" + index
                                    )
                                }
                            >
                                <input
                                    type="file"
                                    id={"file_PrimaryFrontCard" + index}
                                    name={"file_PrimaryFrontCard" + index}
                                    onChange={(e) =>
                                        props.onHandleImageChange(e, item.id)
                                    }
                                    accept=".png, .jpg, .jpeg"
                                />
                                </div>}
                        </div>
                    </div>
                </div>

                <div className="imageCardCol">
                    <div className={"imgCardRow"} >
                        {item.card_back_page && (
                            <div className="width-100-perc insurance-card-bg">
                                <ProfileCardForInsurance
                                    image={item.card_back_page}
                                    imageUploaded={true} 
                                    alt={i18n.t(
                                        "patientPages.insuranceInfo.insuranceCardBack"
                                    )}
                                    id={"insuranceCardBack" + index}
                                    onMouseOver={() =>
                                        props.showImageAddEdit(
                                            "show",
                                            "divCardImageAddBack" + index
                                        )
                                    }
                                    onMouseLeave={() =>
                                        props.showImageAddEdit(
                                            "hide",
                                            "divCardImageAddBack" + index
                                        )
                                    }
                                    onClick={() =>
                                        props.callClickEvent(
                                            item.id,
                                            "file_PrimaryBackCard" + index
                                        )
                                    }
                                    onRemoveImage={() =>
                                        props.resetImage(item.id, "insuranceCardBack")
                                    }
                                >
                                    <div className="fontSize14">
                                        {i18n.t(
                                            "patientPages.insuranceInfo.insuranceCardBack"
                                        )}
                                    </div>
                                </ProfileCardForInsurance>
                            </div>
                        )}
                        {!item.card_back_page && (
                            <div>
                                <div className="width-100-perc insurance-card-bg">
                                    <ProfileCardForInsurance
                                        image={imgCardBackdAdd}
                                        imageUploaded={false}
                                        alt={i18n.t(
                                            "patientPages.insuranceInfo.insuranceCardBack"
                                        )}
                                        id={"insuranceCardBack11" + index}
                                        title={i18n.t(
                                            "patientPages.insuranceInfo.insuranceCardBack"
                                        )}
                                        style={{ marginLeft: "12px", width: "min-content" }}
                                        className={"card-wh"}
                                        onClick={() =>
                                            props.callClickEvent(
                                                item.id,
                                                "file_PrimaryBackCard" + index
                                            )
                                        }
                                    >
                                        <div className="fontSize14">
                                            {i18n.t(
                                                "patientPages.insuranceInfo.insuranceCardBack"
                                            )}
                                        </div>
                                    </ProfileCardForInsurance>
                                </div>
                            </div>
                        )}
                        <div
                            className="hidden"
                            id={"divCardImageAddBack" + index}
                        >
                            {checkPermission(permission_key_values_patient.can_change_insurance_card) &&
                                <div
                                    className="card-image"
                                    onMouseOver={() =>
                                        props.showImageAddEdit(
                                            "show",
                                            "divCardImageAddBack" + index
                                        )
                                    }
                                >
                                    <input
                                        type="file"
                                        id={"file_PrimaryBackCard" + index}
                                        name={"file_PrimaryBackCard" + index}
                                        onChange={(e) =>
                                            props.onHandleImageChange(e, item.id)
                                        }
                                        accept=".png, .jpg, .jpeg"
                                    />
                                </div>}
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    else if (insuranceName == 'Secondary Insurance') {
        return (
            <div
                className="imageCardMainRow"
                style={{
                    display: "flex",
                    justifyContent: "flex-start"
                }}
            >
                <div className="imageCardCol margin-right25">
                    <div className={"imgCardRow"} >
                        {item.card_front_page && (
                            <div className="width-100-perc insurance-card-bg">
                                <ProfileCardForInsurance
                                    image={item.card_front_page}
                                    imageUploaded={true}
                                    alt={i18n.t(
                                        "patientPages.insuranceInfo.insuranceCardFront"
                                    )}
                                    id={"insuranceCardFront21" + index}
                                    onMouseOver={() =>
                                        props.showImageAddEdit(
                                            "show",
                                            "divCardImageAddFrontSecondary" +
                                            index
                                        )
                                    }
                                    onMouseLeave={() =>
                                        props.showImageAddEdit(
                                            "hide",
                                            "divCardImageAddFrontSecondary" +
                                            index
                                        )
                                    }
                                    onRemoveImage={() =>
                                        props.resetImage(
                                            item.id,
                                            "insuranceCardFront"
                                        )
                                    }
                                    onClick={() =>
                                        props.callClickEvent(
                                            item.id,
                                            "file_SecondaryFrontCard" + index
                                        )
                                    }
                                >
                                    <div className="fontSize14">
                                        {i18n.t(
                                            "patientPages.insuranceInfo.insuranceCardFront"
                                        )}
                                    </div>
                                </ProfileCardForInsurance>
                            </div>
                        )}
                        {!item.card_front_page && (
                            <div className="width-100-perc insurance-card-bg"> 
                                <ProfileCardForInsurance
                                    image={imgCardFrontdAdd}
                                    imageUploaded={false}
                                    id={"insuranceCardFront22" + index}
                                    style={{ marginLeft: "12px", width: "min-content" }}
                                    className={"card-wh"}
                                    alt={i18n.t(
                                        "patientPages.insuranceInfo.insuranceCardFront"
                                    )}
                                    title={i18n.t(
                                        "patientPages.insuranceInfo.insuranceCardFront"
                                    )}
                                    onClick={() =>
                                        props.callClickEvent(
                                            item.id,
                                            "file_SecondaryFrontCard" + index
                                        )
                                    }
                                >
                                    <div className="fontSize14">
                                        {i18n.t(
                                            "patientPages.insuranceInfo.insuranceCardFront"
                                        )}
                                    </div>
                                </ProfileCardForInsurance>
                            </div>
                        )}
                        <div
                            className="hidden"
                            id={"divCardImageAddFrontSecondary" + index}
                        >
                            {checkPermission(permission_key_values_patient.can_change_insurance_card) &&
                                <div
                                    className="card-image"
                                    onMouseOver={() =>
                                        props.showImageAddEdit(
                                            "show",
                                            "divCardImageAddFrontSecondary" + index
                                        )
                                    }
                                >
                                    <input
                                        type="file"
                                        id={"file_SecondaryFrontCard" + index}
                                        name={"file_SecondaryFrontCard" + index}
                                        onChange={(e) =>
                                            props.onHandleImageChange(e, item.id)
                                        }
                                        accept=".png, .jpg, .jpeg"
                                    />
                                </div>}
                        </div>
                    </div>
                </div>
                <div className="imageCardCol">
                    <div className={"imgCardRow"}>
                        {item.card_back_page && (
                            <div className="width-100-perc insurance-card-bg">
                                <ProfileCardForInsurance
                                    image={item.card_back_page}
                                    imageUploaded={true}
                                    alt={i18n.t(
                                        "patientPages.insuranceInfo.insuranceCardBack"
                                    )}
                                    id={"insuranceCardBack22" + index}
                                    onMouseOver={() =>
                                        props.showImageAddEdit(
                                            "show",
                                            "divCardImageAddBackSecondary" + index
                                        )
                                    }
                                    onMouseLeave={() =>
                                        props.showImageAddEdit(
                                            "hide",
                                            "divCardImageAddBackSecondary" + index
                                        )
                                    }
                                    onClick={() =>
                                        props.callClickEvent(
                                            item.id,
                                            "file_SecondaryBackCard" + index
                                        )
                                    }
                                    onRemoveImage={() =>
                                        props.resetImage(item.id, "insuranceCardBack")
                                    }
                                >
                                    <div className="fontSize14">
                                        {i18n.t(
                                            "patientPages.insuranceInfo.insuranceCardBack"
                                        )}
                                    </div>
                                </ProfileCardForInsurance>
                            </div>
                        )}
                        {!item.card_back_page && (
                            <div className="width-100-perc insurance-card-bg">
                                <ProfileCardForInsurance
                                    image={imgCardBackdAdd}
                                    imageUploaded={false} 
                                    id={"insuranceCardBack23" + index}
                                    style={{ marginLeft: "12px", width: "min-content" }}
                                    className={"card-wh"}
                                    alt={i18n.t(
                                        "patientPages.insuranceInfo.insuranceCardBack"
                                    )}
                                    title={i18n.t(
                                        "patientPages.insuranceInfo.insuranceCardBack"
                                    )}
                                    onClick={() =>
                                        props.callClickEvent(
                                            item.id,
                                            "file_SecondaryBackCard" + index
                                        )
                                    }
                                >
                                    <div className="fontSize14">
                                        {i18n.t(
                                            "patientPages.insuranceInfo.insuranceCardBack"
                                        )}
                                    </div>
                                </ProfileCardForInsurance>
                            </div>
                        )}

                        <div
                            className="hidden"
                            id={"divCardImageAddBackSecondary" + index}
                        >
                            {checkPermission(permission_key_values_patient.can_change_insurance_card) &&
                                <div
                                    className="card-image"
                                    onMouseOver={() =>
                                        props.showImageAddEdit(
                                            "show",
                                            "divCardImageAddBackSecondary" + index
                                        )
                                    }
                                >
                                    {/* <label htmlFor="file_SecondaryBackCard"><i className="las la-pen"></i></label> */}
                                    <input
                                        type="file"
                                        id={"file_SecondaryBackCard" + index}
                                        name={"file_SecondaryBackCard" + index}
                                        onChange={(e) =>
                                            props.onHandleImageChange(e, item.id)
                                        }
                                        accept=".png, .jpg, .jpeg"
                                    />
                                </div>}
                        </div>
                    </div>
                </div>
            </div>
        )
    }
    else {
        return (
            <div
                className="imageCardMainRow"
                style={{
                    display: "flex",
                    justifyContent: "flex-start"
                }}
            >
                <div className="imageCardCol margin-right25">
                    <div className={"imgCardRow"}>
                        {item.card_front_page && (
                            <div className="width-100-perc insurance-card-bg">
                                <ProfileCardForInsurance
                                    image={item.card_front_page}
                                    imageUploaded={true}
                                    alt={i18n.t(
                                        "patientPages.insuranceInfo.insuranceCardFront"
                                    )}
                                    id={"insuranceCardFront32" + index}
                                    onMouseOver={() =>
                                        props.showImageAddEdit(
                                            "show",
                                            "divCardImageAddFrontTertiary" +
                                            index
                                        )
                                    }
                                    onMouseLeave={() =>
                                        props.showImageAddEdit(
                                            "hide",
                                            "divCardImageAddFrontTertiary" +
                                            index
                                        )
                                    }
                                    onClick={() =>
                                        props.callClickEvent(
                                            item.id,
                                            "file_TertiaryFrontCard" + index
                                        )
                                    }
                                    onRemoveImage={() =>
                                        props.resetImage(
                                            item.id,
                                            "insuranceCardFront"
                                        )
                                    }
                                >
                                    <div className="fontSize14">
                                        {i18n.t(
                                            "patientPages.insuranceInfo.insuranceCardFront"
                                        )}
                                    </div>
                                </ProfileCardForInsurance>
                            </div>
                        )}
                        {!item.card_front_page && (
                            <div className="width-100-perc insurance-card-bg">
                                <ProfileCardForInsurance
                                    image={imgCardFrontdAdd}
                                    imageUploaded={false}
                                    alt={i18n.t(
                                        "patientPages.insuranceInfo.insuranceCardFront"
                                    )}
                                    id={"insuranceCardFront32" + index}
                                    style={{ marginLeft: "12px", width: "min-content" }}
                                    className={"card-wh"}
                                    title={i18n.t(
                                        "patientPages.insuranceInfo.insuranceCardFront"
                                    )}
                                    onClick={() =>
                                        props.callClickEvent(
                                            item.id,
                                            "file_TertiaryFrontCard" + index
                                        )
                                    }
                                >
                                    <div className="fontSize14">
                                        {i18n.t(
                                            "patientPages.insuranceInfo.insuranceCardFront"
                                        )}
                                    </div>
                                </ProfileCardForInsurance>
                            </div>
                        )}
                        <div
                            className="hidden"
                            id={"divCardImageAddFrontTertiary" + index}
                        >
                            {checkPermission(permission_key_values_patient.can_change_insurance_card) &&
                                <div
                                    className="card-image"
                                    onMouseOver={() =>
                                        props.showImageAddEdit(
                                            "show",
                                            "divCardImageAddFrontTertiary" + index
                                        )
                                    }
                                >
                                    {/* <label htmlFor={"file_TertiaryFrontCard" + tIndex}><i className="las la-pen"></i></label> */}
                                    <input
                                        type="file"
                                        id={"file_TertiaryFrontCard" + index}
                                        name={"file_TertiaryFrontCard" + index}
                                        onChange={(e) =>
                                            props.onHandleImageChange(e, item.id)
                                        }
                                        accept=".png, .jpg, .jpeg"
                                    />
                                </div>}
                        </div>
                    </div>
                </div>
                <div className="imageCardCol">
                    <div className={"imgCardRow"}>
                        {item.card_back_page && (
                            <div className="width-100-perc insurance-card-bg">
                                <ProfileCardForInsurance
                                    image={item.card_back_page}
                                    imageUploaded={true}
                                    alt={i18n.t(
                                        "patientPages.insuranceInfo.insuranceCardBack"
                                    )}
                                    onMouseOver={() =>
                                        props.showImageAddEdit(
                                            "show",
                                            "divCardImageAddBackTertiary" + index
                                        )
                                    }
                                    onMouseLeave={() =>
                                        props.showImageAddEdit(
                                            "hide",
                                            "divCardImageAddBackTertiary" + index
                                        )
                                    }
                                    onClick={() =>
                                        props.callClickEvent(
                                            item.id,
                                            "file_TertiaryBackCard" + index
                                        )
                                    }
                                    id={"insuranceCardFront34" + index}
                                    onRemoveImage={() =>
                                        props.resetImage(
                                            item.id,
                                            "insuranceCardBack"
                                        )
                                    }
                                    title={i18n.t(
                                        "patientPages.insuranceInfo.insuranceCardBack"
                                    )}
                                >
                                    <div className="fontSize14">
                                        {i18n.t(
                                            "patientPages.insuranceInfo.insuranceCardBack"
                                        )}
                                    </div>
                                </ProfileCardForInsurance>
                            </div>
                        )}
                        {!item.card_back_page && (
                            <div className="width-100-perc insurance-card-bg">
                                <ProfileCardForInsurance
                                    image={imgCardBackdAdd} 
                                    imageUploaded={false}
                                    alt={i18n.t(
                                        "patientPages.insuranceInfo.insuranceCardBack"
                                    )}
                                    id={"insuranceCardFront34" + index}
                                    style={{ marginLeft: "12px", width: "min-content" }}
                                    className={"card-wh"}
                                    title={i18n.t(
                                        "patientPages.insuranceInfo.insuranceCardBack"
                                    )}
                                    onClick={() =>
                                        props.callClickEvent(
                                            item.id,
                                            "file_TertiaryBackCard" + index
                                        )
                                    }
                                >
                                    <div className="fontSize14">
                                        {i18n.t(
                                            "patientPages.insuranceInfo.insuranceCardBack"
                                        )}
                                    </div>
                                </ProfileCardForInsurance>
                            </div>
                        )}
                        <div
                            className="hidden"
                            id={"divCardImageAddBackTertiary" + index}
                        >
                            {checkPermission(permission_key_values_patient.can_change_insurance_card) &&
                                <div
                                    className="card-image"
                                    onMouseOver={() =>
                                        props.showImageAddEdit(
                                            "show",
                                            "divCardImageAddBackTertiary" + index
                                        )
                                    }
                                >
                                    {/* <label htmlFor={"file_TertiaryBackCard" + tIndex}><i className="las la-pen"></i></label> */}
                                    <input
                                        type="file"
                                        id={"file_TertiaryBackCard" + index}
                                        name={"file_TertiaryBackCard" + index}
                                        onChange={(e) =>
                                            props.onHandleImageChange(e, item.id)
                                        }
                                        accept=".png, .jpg, .jpeg"
                                    />
                                </div>}
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}