import React from 'react';
export const Sorting = (props) => {
    return (
        <>
            {props.tableSortingData.initialOrdering ? <span>
                <i className=' la la-sort-up sortUpArrow ml-2' data-testid="sortUpArrow-i" />
                <i className=' la la-sort-down sortDownArrow ml-2' data-testid="sortDownArrow-i" />
                {/* <img src={upArrowImage} className='sortUpArrow ml-2' alt={''} />
                <img src={downArrowImage} className={'sortDownArrow ml-2'} alt={""} /> */}
            </span>
                :
                props.tableSortingData.orderType ? <span>
                    {/* <img src={upArrowImage} className='singleSortUpArrow ml-2' alt={''} /> */}
                    <i className=' la la-sort-up singleSortUpArrow ml-2' data-testid="sortUpArrow-i" />
                </span>
                    :
                    <span>
                        {/* <img src={downArrowImage} className={'singleSortDownArrow ml-2'} alt={""} /> */}
                        <i className=' la la-sort-down singleSortDownArrow ml-2' data-testid="sortDownArrow-i" />
                    </span>
            }
        </>
    )
}

