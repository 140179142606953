import React from 'react';

function ExportPatientsListCheckBox({ columnsList, selectedColumns, onSelectOptionalColumn, optionalColumnsList }) {
    return (
        <div className="row">
            {columnsList.map((columns, colIndex) => (
                <div className="col-2 max-width-none" key={colIndex}>
                    {columns.map((item, index) => (
                        <div className="row" key={item}>
                            <div className="form-groupp lh-0 justify-right">
                                <div className="custom-checkbox lh-0">
                                    <input
                                        type="checkbox"
                                        data-testid={`checkbox-column${colIndex + 1}${index}`}
                                        id={`${colIndex}-${index}`}
                                        name={item}
                                        checked={selectedColumns.indexOf(item) !== -1}
                                        onChange={(e) => {
                                            // eslint-disable-next-line
                                            debugger;
                                            onSelectOptionalColumn(e, item)
                                        }
                                        }
                                    />
                                    <label className="checkbox top-14" htmlFor={`${colIndex}-${index}`}></label>
                                </div>
                                <div className="padding-top5 padding-left-5">
                                    <label className="chk-column margin-top-3px" style={{ cursor: 'pointer' }} htmlFor={`${colIndex}-${index}`}>
                                        {optionalColumnsList[item]}
                                    </label>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            ))}
        </div>
    );
}

export default ExportPatientsListCheckBox;
