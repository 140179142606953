import i18n from "../../../utilities/i18n";


const TableHeadData = [

    {
        "id": "",
        "name": "checkbox",
        "type": "checkbox",
        "width": "check_box",
        "align": "center",
        "value": false
    },
    {
        "name": i18n.t("arClaims.claimId"),
        "type": "number",
        "width": "small",
        "sort": true,
        "initialOrdering": true,
    },
    {
        "name": i18n.t("arClaims.patientName"),
        "type": "string",
        "width": "medium",
        "sort": true,
        "initialOrdering": true,
    },
    {
        "name": i18n.t("arClaims.dos"),
        "type": "Date",
        "width": "small",
        "sort": true,
        "initialOrdering": true,
    },
    {
        "name": i18n.t("arClaims.charges"),
        "type": "currency",
        "width": "small",
        "sort": false,
    },
    {
        "name": i18n.t("arClaims.balance"),
        "type": "currency",
        "width": "small",
        "sort": false,
    },
    {
        "name": i18n.t("arClaims.responsible"),
        "type": "string",
        "width": "medium",
        "sort": false,
    },
    {
        "name": i18n.t("arClaims.responsibleType"),
        "type": "string",
        "width": "small",
        "sort": false,
    },
    {
        "name": i18n.t("arClaims.claimStatus"),
        "type": "string",
        "width": "small",
        "sort": false,
    },
    {
        "name": i18n.t("arClaims.lastFuDT"),
        "type": "Date",
        "width": "small",
        "sort": false,
    },
    {
        "name": i18n.t("arClaims.nextFuDT"),
        "type": "Date",
        "width": "small",
        "sort": false,
    },
    {
        "name": i18n.t("arClaims.age"),
        "type": "number",
        "width": "small",
        "sort": false,
    },
    {
        "name": i18n.t("arClaims.assigned"),
        "type": "string",
        "width": "medium",
        "sort": false,
    },
];

export const TableBodyData = [
    [
        {
            "id": "",
            "name": "checkbox",
            "type": "checkbox",
            "width": "check_box",
            "align": "center",
            "value": false
        },
        {
            "id": "",
            "name": "custom_claim_id",
            "value": "",
            "type": "string",
            "needLink": true,
            "align": "center",
            "width": "small",
        },
        {
            "id": "",
            "name": "patient_name",
            "value": "",
            "type": "string",
            "align": "left",
            "width": "medium",
            "needLink": true,
        },
        {
            "id": "",
            "name": "dos_from",
            "value": "",
            "type": "Date",
            "needDateFormat": "yes",
            "align": "center",
            "width": "small",
        },
        {
            "id": "",
            "name": "charges",
            "value": "",
            "type": "currency",
            "align": "right",
            "width": "small",
        },
        {
            "id": "",
            "name": "balance",
            "value": "",
            "type": "currency",
            "align": "right",
            "width": "small",
        },
        {
            "id": "",
            "name": "responsible",
            "value": "",
            "type": "string",
            "align": "left",
            "width": "medium",
        },
        {
            "id": "",
            "name": "responsible_type",
            "value": "",
            "type": "string",
            "align": "left",
            "width": "small",
        },
        {
            "id": "",
            "name": "claim_status_name",
            "value": "",
            "type": "string",
            "align": "left",
            "width": "medium",
        },
        {
            "id": "",
            "name": "last_fu_dt",
            "value": "",
            "type": "Date",
            "needDateFormat": "yes",
            "align": "center",
            "width": "small",
        },
        {
            "id": "",
            "name": "next_fu_dt",
            "value": "",
            "type": "Date",
            "needDateFormat": "yes",
            "align": "center",
            "width": "small",
        },
        {
            "id": "",
            "name": "age",
            "value": "",
            "type": "number",
            "align": "left",
            "width": "small",
        },
        {
            "id": "",
            "name": "assigned_to",
            "value": "",
            "type": "string",
            "align": "left",
            "width": "medium",
        },

    ]
]

export const ARClaimTableData = {
    "name": "simpleTable",
    "isResize": true,
    "isFixedHead": true,
    "tableHeadings": TableHeadData,
    "tableBodyData": TableBodyData
}


// ************** TABLE FOR MYAR TAB PAGE *******************************************

const MYARTableHeadData = [
    
    {
        "name": i18n.t("arClaims.claimId"),
        "type": "number",
        "width": "small",
        "sort": true,
        "initialOrdering": true,
    },
    {
        "name": i18n.t("arClaims.patientName"),
        "type": "string",
        "width": "medium",
        "sort": true,
        "initialOrdering": true,
    },
    {
        "name": i18n.t("arClaims.dos"),
        "type": "Date",
        "width": "small",
        "sort": true,
        "initialOrdering": true,
    },
    {
        "name": i18n.t("arClaims.charges"),
        "type": "currency",
        "width": "small",
        "sort": false,
    },
    {
        "name": i18n.t("arClaims.balance"),
        "type": "currency",
        "width": "small",
        "sort": false,
    },
    {
        "name": i18n.t("arClaims.responsible"),
        "type": "string",
        "width": "medium",
        "sort": false,
    },
    {
        "name": i18n.t("arClaims.responsibleType"),
        "type": "string",
        "width": "small",
        "sort": false,
    },
    {
        "name": i18n.t("arClaims.claimStatus"),
        "type": "string",
        "width": "small",
        "sort": false,
    },
    {
        "name": i18n.t("arClaims.lastFuDT"),
        "type": "Date",
        "width": "small",
        "sort": false,
    },
    {
        "name": i18n.t("arClaims.nextFuDT"),
        "type": "Date",
        "width": "small",
        "sort": false,
    },
    {
        "name": i18n.t("arClaims.age"),
        "type": "number",
        "width": "small",
        "sort": false,
    },
];

export const MYARTableBodyData = [
    [
        
        {
            "id": "",
            "name": "custom_claim_id",
            "value": "",
            "type": "number",
            "needLink": true,
            "align": "left",
            "width": "small",
        },
        {
            "id": "",
            "name": "patient_name",
            "value": "",
            "type": "string",
            "align": "left",
            "width": "medium",
            "needLink": true,
        },
        {
            "id": "",
            "name": "dos_from",
            "value": "",
            "type": "Date",
            "needDateFormat": "yes",
            "align": "center",
            "width": "small",
        },
        {
            "id": "",
            "name": "charges",
            "value": "",
            "type": "currency",
            "align": "right",
            "width": "small",
        },
        {
            "id": "",
            "name": "balance",
            "value": "",
            "type": "currency",
            "align": "right",
            "width": "small",
        },
        {
            "id": "",
            "name": "responsible",
            "value": "",
            "type": "responsiblePayer",
            "align": "left",
            "width": "medium",
            "needLink": true,
        },
        {
            "id": "",
            "name": "responsible_type",
            "value": "",
            "type": "string",
            "align": "left",
            "width": "small",
        },
        {
            "id": "",
            "name": "claim_status_name",
            "value": "",
            "type": "string",
            "align": "left",
            "width": "medium",
        },
        {
            "id": "",
            "name": "last_fu_dt",
            "value": "",
            "type": "Date",
            "needDateFormat": "yes",
            "align": "center",
            "width": "small",
        },
        {
            "id": "",
            "name": "next_fu_dt",
            "value": "",
            "type": "Date",
             "needDateFormat": "yes",
            "align": "center",
            "width": "small",
        },
        {
            "id": "",
            "name": "age",
            "value": "",
            "type": "number",
            "align": "left",
            "width": "small",
        },
    ]
]

export const MYARClaimTableData = {
    "name": "simpleTable",
    "isResize": true,
    "isFixedHead": true,
    "tableHeadings": MYARTableHeadData,
    "tableBodyData": MYARTableBodyData
}