import React, { useState, useEffect, useContext } from "react";
import { AsyncTypeahead } from "react-bootstrap-typeahead";
import "../../commons/input/AsyncTypeHead/style.css";
import service from "./service";
import LoadingContext from "../../../container/loadingContext";
import { getStorage } from "../../../utilities/browserStorage";
import { DEFAULT_PAGING_SIZE } from "../../../utilities/staticConfigs";

export const CPTSearchBox = (props) => {
  const isAdminModule = getStorage("isAdminModule");
  const practicePK = getStorage("practice");
  const setShowLoadingBar = useContext(LoadingContext);
  const [cptList, setCptList] = useState([]);

  const onSearch = (query) => {
    setShowLoadingBar(true);
    service
      .ListCPTCodes(
        DEFAULT_PAGING_SIZE,
        1,
        isAdminModule,
        practicePK,
        true,
        query,
        "dropdown"
      )
      .then((result) => {
        setShowLoadingBar(false);
        setCptList(result.data.results);
      })
      .catch(() => {
        setShowLoadingBar(false);
      });
  };

  const getAllCpt = () => {
    setShowLoadingBar(true);
    service
      .ListCPTCodes(
        DEFAULT_PAGING_SIZE,
        1,
        isAdminModule,
        practicePK,
        true,
        null,
        "dropdown"
      )
      .then((result) => {
        setShowLoadingBar(false);
        setCptList(result.data.results);
      })
      .catch(() => {
        setShowLoadingBar(false);
      });
  };

  useEffect(() => {
    getAllCpt();
  }, []);
  const handleTypeheadOnchange = (option) => {
    props.onSearchFound(option);
  };

  const handleInputChange = (input) => {
   
    if (!input) {
   
      props.onClear();
    }
  };
  return (
    <div className="typeHeadStyle" style={{ paddingTop: "15px" }}>
      <AsyncTypeahead
        id={"searchName"}
        className={props.class_name ? props.class_name : ""}
        name={props.name}
        labelKey={"name"}
        onSearch={onSearch}
        options={cptList}
        clearButton={true}
        searchText={"type Here"}
        placeholder={props.label}
        onInputChange={handleInputChange}
        renderMenuItemChildren={(option) => (
          <div
            onClick={() => handleTypeheadOnchange(option)}
            value={option.name}
          >
            {option.name}
          </div>
        )}
        inputProps={{
          "aria-label": props.label,
          required: true,
        }}
      />
      {/* <label className="text" id={props.id ? props.id : "my"}>
        {props.label}
      </label> */}
    </div>
  );
};
