import React, { useEffect, useState } from 'react';
import { getStorage, setStorage } from '../../utilities/browserStorage';
import i18n from '../../utilities/i18n';
import service from './service';
import './style.css';
import { currencyFormat } from '../../utilities/commonUtilities';


export const ViewReceipts = () => {
    const paymentPK =getStorage('paymentPK');
    const [receiptNumber, setReceiptNumber] = useState("");
    const [practiceName, setPracticeName] = useState("");
    const [practiceAddress, setPracticeAddress] = useState("");
    const [practicePhone, setPracticePhone] = useState("");
    const [paymentDate, setPaymentDate] = useState("");
    const [patientCustomID, setPatientCustomID] = useState("");

    const [patientName, setPatientName] = useState("");
    const [patientDOB, setPatientDOB] = useState("");
    const [patientAddress, setPatientAddress] = useState("");
    const [patientPhone, setPatientPhone] = useState("");
    const [patientEmail, setPatientEmail] = useState("");
    const [lastSeen, setLastSeen] = useState("");
    const [paymentReceivedAmount, setPaymentReceivedAmount] = useState("");

    const [paymentMode, setPaymentMode] = useState("");
    const [paymentType, setPaymentType] = useState("");
    const [additionalPaymentNotes, setAdditionalPaymentNotes] = useState("");
    const [patientVerifiedString, setPatientVerifiedString] = useState("");
    const [imageUrl, setImageUrl] = useState("");

    useEffect(() => {
        localStorage.removeItem("paymentPK");
        const result = service.GetReceipts(paymentPK);
        result.then(response => {
            let data = response.data;
            setReceiptNumber(data.receipt_number);
            setPracticeName(data.practice_name);
            setPracticeAddress(data.practice_address);
            setPracticePhone(data.practice_phone);
            setPaymentDate(data.payment_date);
            setPatientCustomID(data.patient_custom_id);
            setPatientName(data.patient_name);
            setPatientDOB(data.patient_dob);
            setPatientAddress(data.patient_address ? data.patient_address : "");
            setPatientPhone(data.patient_phone);
            setPatientEmail(data.patient_email);
            setLastSeen(data.last_seen_date);
            setPaymentReceivedAmount(data.receipts);
            setPaymentMode(data.payment_mode_name);
            setPaymentType(data.secondary_payment_type_name);
            setAdditionalPaymentNotes(data.note);
            if (response.data.practice_logo) setImageUrl(response.data.practice_logo)
            let patientVerified = '';
            let patientVerifiedStringObj = i18n.t('payments.viewReceipts.amountVerifiedText');
            patientVerified = patientVerifiedStringObj.replace(/{(\w+)}/g, function (_, k) {
                return data[k];
            });
            setPatientVerifiedString(patientVerified);
        });
        // addEventListener and removeEventListener is added to get paymentPK in reload
        window.addEventListener("beforeunload", getPaymentPK);
        return () => {
            window.removeEventListener("beforeunload", getPaymentPK);
        };
    }, [])
    const onPrintReceipts = () => {
      
        var content = document.getElementById('printarea');
        var pri = document.getElementById('ifmcontentstoprint').contentWindow;
      
        pri.document.open();
        pri.document.write(content.innerHTML);
        pri.document.close();
        pri.focus();
        pri.print();
    };
   

    const getPaymentPK = (e) => {
        // payment Pk set to local storage before refresh, after page load it removing from local storage.
        setStorage('paymentPK', paymentPK);
        e.preventDefault();
        e.returnValue = "";
    };

    return (
        <div className="fullContent">
            <div className="justify-right headerReceipts">
                <i className="fas fa-print " onClick={onPrintReceipts} style={{ fontSize:25 ,cursor:'pointer',marginRight:'180px'  }}></i>
            
            </div>
            <iframe id="ifmcontentstoprint" style={{
                        height: '0px',
                        width: '0px',
                        position: 'absolute'
                    }}></iframe>
            <div className="box margin-top55" id="printarea">
                <div className="justify-space-between receipts-logo">
                    <div className="company-logo">
                        {imageUrl &&
                            <img src={imageUrl} className="logoImg head_image" alt="Menu" />
                        }
                    </div>
                    <div style={{paddingTop:30,paddingRight:30,marginRight:150}}>
                        <p className='liSpanBold'>{practiceName}</p>
                        <p className='uiPaymentfontsize'>{practiceAddress}</p>
                        <p className='uiPaymentfontsize'>{practicePhone}</p>
                    </div>
                </div>
                <div className="border padding-top20">
                    <h1 className="textCenterAlignPaymentRecipt"><u>PAYMENT RECEIPT</u></h1>
                    <ul className="uitextAlign">
                        <li>{i18n.t('payments.viewReceipts.receipt')}<span className="liSpanBold">{receiptNumber}</span></li>
                        <li>{i18n.t('payments.viewReceipts.paymentReceivedDate')}<span className="liSpanBold">{paymentDate}</span></li>
                        <li>{i18n.t('payments.viewReceipts.patientID')}<span className="liSpanBold">{patientCustomID}</span></li>
                        <li>{i18n.t('payments.viewReceipts.PatientName')}<span className="liSpanBold">{patientName}</span></li>
                        <li>{i18n.t('payments.viewReceipts.patientDOB')}<span className="liSpanBold">{patientDOB}</span></li>
                        <li>{i18n.t('payments.viewReceipts.patientAddress')}<span className="liSpanBold">{patientAddress}</span></li>
                        <li>{i18n.t('payments.viewReceipts.phoneNumber')}<span className="liSpanBold">{patientPhone}</span></li>
                        <li>{i18n.t('payments.viewReceipts.email')}<span className="liSpanBold">{patientEmail}</span></li>
                        <li>{i18n.t('payments.viewReceipts.lastSeen')}<span className="liSpanBold">{lastSeen}</span></li>
                        <li>{i18n.t('payments.viewReceipts.paymentReceivedAmount')}<span className="liSpanBold">{currencyFormat(paymentReceivedAmount)}</span></li>
                        <li>{i18n.t('payments.viewReceipts.paymentMode')}<span className="liSpanBold">{paymentMode}</span></li>
                        <li>{i18n.t('payments.viewReceipts.paymentType')}<span className="liSpanBold">{paymentType}</span></li>
                        <li>{i18n.t('payments.viewReceipts.additionalPaymentNotes')}<span className="liSpanBold">{additionalPaymentNotes}</span></li>
                        <li>{patientVerifiedString}</li>
                    </ul>
                    <small className="uinoteText">{i18n.t('payments.viewReceipts.note')}</small>
                </div>
            </div>
        </div>
    )

}