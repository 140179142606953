/**
 * This module not in use - May need in future when implementing Dynamic report generation
 */

import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  reports: [],
};

export const allReportListSlice = createSlice({
  name: "allReports",
  initialState,
  reducers: {
    setReports: (state, action) => {
      state.reports = action.payload.reports;
    },
    setItemOpenStatus: (state, action) => {
      const selectedReport = action.payload.report;
      state.reports = state.reports.map((report) => {
        if (report.id === selectedReport.id) {
          return { ...report, isOpen: !selectedReport.isOpen };
        }
        return report;
      });
    },
  },
});

export const { setReports, setItemOpenStatus } = allReportListSlice.actions;

export default allReportListSlice.reducer;
