import api from "../../../service/api";

const getPatientLedgerSummary = async(patientPK) => {
	let path = 'patient/patient-ledger-summary/?patient_pk='+ patientPK;
	return api.get(path);
}

const PatientLedgerClaimList = async (patientPK) => {
	let path = '/patient/patient-ledger-claims/?patient_pk='+patientPK;
	return api.get(path);
}

const PatientLedgerProceduresList = async(claimPK) => {
	let path = 'patient/patient-ledger-procedures/?claim_pk='+ claimPK;
	return api.get(path);
}

const GetCreditBalanceView = async(patientPK) => {
	let path = 'patient/patient-credit-balance-view/?patient_pk='+ patientPK;
	return api.get(path);
}

export default {
	getPatientLedgerSummary, PatientLedgerClaimList, PatientLedgerProceduresList, GetCreditBalanceView
}
