import api from '../../../service/api'

const ListCustomerProfiles = async(pageSize, page) =>{
	let path = 'user/customer-profile/?page_size='+ pageSize +'&page='+ page
	return api.get(path);
	
}

const AddCustomerProfile = async(data) =>{
	let path = 'user/customer-profile/';
	return  api.post(path, data);
}

const GetCustomerProfile = async(customerProfileID) =>{
	let path = '/user/customer-profile/' + customerProfileID;
	return api.get(path);
	
}

const UpdateCustomerProfile = async(customerProfileID, data) =>{
	let path = 'user/customer-profile/' + customerProfileID;
	return api.put(path, data);
}
const ResendEmailCustomerProfile = async(data) =>{
	let path = 'user/resend-email/';
	return api.post(path,data);
}

const UpdateSftpCredentials = async (practiceId, data) => {
	let path = `user/clearinghouse-sftp-credential/${practiceId}`
	return api.put(path, data);
}

const GetSftpCredentials = async (practiceId) => {
	let path = `user/clearinghouse-sftp-credential/${practiceId}`
	return api.get(path);
}

const UpdateRteCredentials = async (practiceId, data) => {
	let path = `user/clearinghouse-rte-credential/${practiceId}`
	return api.put(path, data);
}

const GetRteCredentials = async (practiceId) => {
	let path = `user/clearinghouse-rte-credential/${practiceId}`
	return api.get(path);
}

export default {
	ListCustomerProfiles,
	AddCustomerProfile,
	GetCustomerProfile,
	UpdateCustomerProfile,
	ResendEmailCustomerProfile,
	UpdateSftpCredentials,
	UpdateRteCredentials,
	GetSftpCredentials,
	GetRteCredentials
}
