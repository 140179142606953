import React, { useState, useEffect, useContext } from "react";
import { Form } from "react-bootstrap";
import "./Style.css";
import { Divider } from "@material-ui/core";
import { permissionsList as newPermissionList } from "./PermissionList";
import { getStorage } from "../../../utilities/browserStorage";
import service from "./service";
import Notify from "../../commons/notify";
import i18n from "../../../utilities/i18n";
import LoadingContext from "../../../container/loadingContext";


/**
 * A React component for managing role-based permissions.
 * @param {string} selectedRoleId - The ID of the role being managed (if available).
 * @param {function} closeModalWindow - Callback function to close the edit or add modal on save or edit.
 */
const ListRolePermissionsNew = ({ selectedRoleId, closeModalWindow}) => {
    // State declarations
    const isAdminModule = getStorage('isAdminModule');
    const [showNotify, setShowNotify] = useState("hide");
    const [notifyDescription, setNotifyDescription] = useState("");
    const [notifyType, setNotifyType] = useState("success");
    const setShowLoadingBar = useContext(LoadingContext);
    const [roleName, setRoleName] = useState('');
    const [permissionsList, setPermissionsList] = useState(newPermissionList); // The Roles Table To render
    const [permissionsCheckedState, setPermissionsCheckedState] = useState({}); // Checked States
    const [isTableReadyToRender, setIsTableReadyToRender] = useState(false); // Flag to render the table after data manipulation
    const [isAllPermissionChecked, setIsAllPermissionsChecked] = useState(false); // state hold select all checkbox value

    function showNotifyWindow(action, type, desc, age = 4000) {
        if (action === "show") {
            setTimeout(() => {
                setShowNotify("hide");
            }, age);
        }
        setShowNotify(action);
        setNotifyType(type);
        setNotifyDescription(desc);
    }

    // Initialize the component when mounted
    useEffect(() => {
        // If a role is passed from the parent then fetch the pre-saved permission table of a that role
        if (selectedRoleId ){
            renderExistingPermissionTable();
        }
        // else fetch clean permission table from the backend and render the same
        else{
            renderNewPermissionTable();
        }
    }, []);


    // Render permissions table for a new role
    const renderNewPermissionTable = async () => {
        setShowLoadingBar(true);
        try {
            const response = await service.GetAllPermissionsList(isAdminModule);
            if (isAdminModule === 'true'){
                setPermissionsList(response.data?.filter((item) => item.is_super_admin_module));
            } else  {
                setPermissionsList(response.data?.filter((item) => !item.is_super_admin_module));
            }
            // Initialize a state object to store selected permissions
            const tempPermissionsCheckedState = {};
            // Iterate over permissionsList to create the state structure
            response?.data?.forEach((item) => {
                tempPermissionsCheckedState[item.permission_id] = {
                    isAllChecked: false,
                    modulePermissionId: item.permission_id,
                    sub_module: {}
                };

                item.sub_module.forEach((subItem) => {

                    const tempSubPermission = {};

                    subItem.categories.forEach((catogaryItem) => {
                        const subPermissionIds = subItem.sub_module_permission[catogaryItem]?.map(element => element.id) || [];
                        tempSubPermission[catogaryItem] = {
                            isChecked: false,
                            subPermissionIds: subPermissionIds
                        };
                    })
                    tempPermissionsCheckedState[item.permission_id].sub_module[subItem.permission_id] = tempSubPermission;
                })
            })
            // Update state
            setPermissionsCheckedState(tempPermissionsCheckedState);
            setIsTableReadyToRender(true);
        } catch (error) {
            showNotifyWindow(
                "show",
                "error",
                i18n.t('roles-permissions.error-getting-permission-list')
            );
        }
        setShowLoadingBar(false);
    }

    const renderExistingPermissionTable = async () => {
        setShowLoadingBar(true);
        let response;
            response = await service.GetSavedRoleData(selectedRoleId);
        // Set initial values based on savedRole
        if (isAdminModule === 'true'){
            setPermissionsList(response?.data?.selected_permissions?.filter((item) => item.is_super_admin_module)); 
        } else {
            setPermissionsList(response?.data?.selected_permissions?.filter((item) => !item.is_super_admin_module));
        }
        setRoleName(response?.data?.role_name);

        // Object to save all the permission checked state
        const tempPermissionsCheckedState = {};
        // Iterate over savedRole api response data to populate modules, submodules and its checked state
        response?.data?.selected_permissions?.forEach((item) => {
            tempPermissionsCheckedState[item.permission_id] = {
                isAllChecked: item.is_checked ?? false,
                modulePermissionId: item.permission_id,
                sub_module: {}
            };

            item.sub_module.forEach((subItem) => {

                const tempSubPermission = {};

                subItem.categories.forEach((catogaryItem) => {
                    const subPermissionIds = subItem.sub_module_permission[catogaryItem]?.map(element => element.id) || [];
                    tempSubPermission[catogaryItem] = {
                        isChecked: subItem.selected_categories.includes(catogaryItem) && subItem.is_checked ? true : false,
                        subPermissionIds: subPermissionIds
                    };
                })
                tempPermissionsCheckedState[item.permission_id].sub_module[subItem.permission_id] = tempSubPermission;
            })
        })

        // Check and update isAllPermissionChecked state for the checkbox in the header
        let isAllCheckedFlag = true;
        for (const key in tempPermissionsCheckedState) {
            if (!tempPermissionsCheckedState[key]['isAllChecked']) {
                isAllCheckedFlag = false;
                break;
            } else {
                isAllCheckedFlag = true;
            }
        }
        setIsAllPermissionsChecked(isAllCheckedFlag);

        setPermissionsCheckedState(tempPermissionsCheckedState);
        setIsTableReadyToRender(true);
        setShowLoadingBar(false);
    }


    /**
     * 
     * @param {*} e 
     * @param {*} moduleId - parent module id
     * When user clicks on the parent module's check box it should trigger either select or unselect of all of its permissions like view and modify etc..  
     */
    const handleParentModuleCheckBoxClick = (e, moduleId) => {
        const isChecked = e.target.checked;
        setPermissionsCheckedState(prevState => {
            // Update permissionsCheckedState based on module click
            const updatedState = {
                ...prevState,
                [moduleId]: {
                    ...prevState[moduleId],
                    isAllChecked: isChecked,
                    sub_module: Object.keys(prevState[moduleId].sub_module).reduce((acc, subModuleId) => {
                        acc[subModuleId] = {
                            ...prevState[moduleId].sub_module[subModuleId],
                            ...Object.keys(prevState[moduleId].sub_module[subModuleId]).reduce((subAcc, subItemId) => {
                                subAcc[subItemId] = {
                                    ...prevState[moduleId].sub_module[subModuleId][subItemId],
                                    isChecked: isChecked
                                };
                                return subAcc;
                            }, {})
                        };
                        return acc;
                    }, {})
                }
            };

            // Check and update isAllPermissionChecked state for the checkbox in the header
            let isAllCheckedFlag;
            for (const key in updatedState) {
                if (!updatedState[key]['isAllChecked']) {
                    isAllCheckedFlag = false;
                    break;
                } else {
                    isAllCheckedFlag = true;
                }
            }
            setIsAllPermissionsChecked(isAllCheckedFlag);

            return updatedState;
        });
    };


    // Handle click on sub-module permission checkbox
    const handleSubModulePermissionCheckBoxClick = (e, moduleId, subModuleId, permissionCatagory) => {
        // permissionCatagory === 1; means "View"
        // permissionCatagory === 2; means "Add & Modify"
        // isChecked === whether event is checked or unchecked

        const isChecked = e.target.checked;
        setPermissionsCheckedState(prevState => {
            // First finding the parent module the update is needed
            const tempObj1 = { ...prevState[moduleId] };
            // then finding its subModule the update is needed
            const tempObj = { ...prevState[moduleId].sub_module[subModuleId] };

            // when event is not checked and requested catogary is 1 mean view then update view, add and edit all to false
            // because to add and modify view is needed.
            if (!isChecked && permissionCatagory === 1) {
                for (const key in tempObj) {
                    tempObj[key].isChecked = false;
                }
            }
            // When event is not checked but requested permission catagary is edit then directly allow it to set to false 
            else if (!isChecked && permissionCatagory === 2 && (tempObj[1]?.isChecked || !tempObj[1])) {
                tempObj[2].isChecked = false;
            }
            // When the event is checked
            else if (isChecked) {
                // Condition 1: if permissionCatagory is 2 means add or modify, update all sub items to true also allow checked to true when item has only one catagary of view
                // Condition 2: If permissionCatagory is 1 means view, update item with key "1" to true
                for (const key in tempObj) {
                    if (permissionCatagory === 2 || (permissionCatagory === 1 && key === "1")) {
                        tempObj[key].isChecked = true;
                    } else {
                        tempObj[key].isChecked = false;
                    }
                }
            }

            tempObj1.sub_module[subModuleId] = tempObj;
            // Handover the complete state to the function updateParentIsAllChecked to update parent modules based on...
            //..whether all the sub module items are checked 
            const isAllChecked = updateParentIsAllChecked({
                ...prevState,
                [moduleId]: tempObj1
            });
            return isAllChecked
        });
    }

    /**
     * Update parent module's isAllChecked depending on sub-module checked status
     * Also when any sub module is checked or unChecked then its parent module should also be updated respectively 
     * @param {tempPermissionsCheckedState} state 
     * @returns {updatedState}
     */
    function updateParentIsAllChecked(state) {
        const updatedState = { ...state };

        for (const moduleId in updatedState) {
            const module = updatedState[moduleId];
            const subModules = module.sub_module;

            const anySubModuleChecked = Object.keys(subModules).some(subModuleId => {
                const subModule = subModules[subModuleId];
                const subItems = Object.values(subModule);

                return subItems.some(subItem => subItem.isChecked === true);
            });

            updatedState[moduleId].isAllChecked = Object.keys(subModules).length === 0 ?
                updatedState[moduleId].isAllChecked : anySubModuleChecked;
        }

        // Check and update isPermissionChecked state associate with the checkbox in the header
        let isAllCheckedFlag = true;
        for (const key1 in updatedState) {
            if (!updatedState[key1]['isAllChecked']) {
                isAllCheckedFlag = false;
                break; // No need to continue checking
            }

            for (const key2 in updatedState[key1]['sub_module']) {
                for (const key3 in updatedState[key1]['sub_module'][key2]) {
                    if (!updatedState[key1]['sub_module'][key2][key3]['isChecked']) {
                        isAllCheckedFlag = false;
                        break; // No need to continue checking
                    }
                }
            }
        }

        setIsAllPermissionsChecked(isAllCheckedFlag);

        return updatedState;
    }

    /**
     * HANDLE CHANGE FOR SELECT ALL CHECK BOX ON THE TABLE HEADER 
     */
    function handleSelectAllCheckBoxClick() {
        setIsAllPermissionsChecked(!isAllPermissionChecked);

        const tempPermissionState = { ...permissionsCheckedState }; // Create a copy of the state

        for (const key1 in tempPermissionState) {
            tempPermissionState[key1]['isAllChecked'] = !isAllPermissionChecked;

            for (const key2 in tempPermissionState[key1]['sub_module']) {
                for (const key3 in tempPermissionState[key1]['sub_module'][key2]) {
                    tempPermissionState[key1]['sub_module'][key2][key3]['isChecked'] = !isAllPermissionChecked;
                }
            }
        }

        setPermissionsCheckedState(tempPermissionState); // Update state with the modified copy
    }



    // Handle the form submission
    async function onSave(e) {
        e.preventDefault();
        if (!roleName) return;

        // Construct the data object to send to backend
        const data = {
            role_name: roleName,
            practice: isAdminModule=== 'true' ? "": Number(getStorage('practice')),
            permissions: []
        };
        setShowLoadingBar(true);

        // Extract only the permission ids of the selected parent modules, its submodules as well from the selected catagories i.e view or add modify to send to the server
        for (const key in permissionsCheckedState) {
            const item = permissionsCheckedState[key];
            // if the parent module is checked then enter the next nested loop in the block
            if (item.isAllChecked) {
                // First push the parent modules permission code that is the key of permissionCheckedState variable
                data.permissions.push(Number(key));
                // Not lets iterate over all of its sub modules
                for (const subModuleKey in item.sub_module) {
                    let parentIdPushed = false; // just a flag to mark if the subModule permission code is pushed to the array
                    for (const permissionKey in item.sub_module[subModuleKey]) {
                        if (item.sub_module[subModuleKey][permissionKey].isChecked) {
                            if (!parentIdPushed) {
                                data.permissions.push(Number(subModuleKey));
                                parentIdPushed = true;
                            }
                            data.permissions.push(...item.sub_module[subModuleKey][permissionKey].subPermissionIds.map(Number));
                        }

                    }
                }
            }
        }
        try {
            if (!data.permissions.length) {
                showNotifyWindow("show", "error", i18n.t('roles-permissions.no-role-selected'));
                setShowLoadingBar(false);
                return;
            }
            let response;
            if (selectedRoleId) {
                response = await service.UpdateRole(selectedRoleId, data);
            } 
            else {
                response = await service.AddNewPracticeRole(data)
            }


            if (response?.status === 200 || response?.status === 201) {
                let successMessage;
                if (selectedRoleId){
                    successMessage =  i18n.t("roles-permissions.role-update-success")
                } 
                 else {
                    successMessage = i18n.t("roles-permissions.role-create-success");
                }
                showNotifyWindow("show", "success", successMessage);
                setTimeout(() => {
                    closeModalWindow();
                }, 3000);
            } else {
                if (response.data.message == "role_name_already_exists") {
                    showNotifyWindow(
                        "show",
                        "error",
                        i18n.t("roles-permissions.already_exist")
                    );
                } else {
                    showNotifyWindow(
                        "show",
                        "error",
                        i18n.t(
                            "roles-permissions.error-creating-or-updating-role"
                        )
                    );
                }
            }
        } catch (error) {
            showNotifyWindow("show", "error", i18n.t('roles-permissions.error-creating-or-updating-role'));
        } finally {
            setShowLoadingBar(false);
        }
    }


    return (
        <div style={{ width: "100%" }}>
            <Notify
                showNotify={showNotify}
                setShowNotify={setShowNotify}
                notifyDescription={notifyDescription}
                setNotifyType={setNotifyType}
                setNotifyDescription={setNotifyDescription}
                notifyType={notifyType}
            />
            <div className="col-md-12">
                <Form
                    id="form_dataEntry"
                    autoComplete="off"
                    onSubmit={(e) => onSave(e)}
                    encType="multipart/form-data"
                >
                    <div className="form-group row">
                        <div className="col-md-3 mb-2">
                            <label className="d-block">Role Name</label>
                        </div>
                        <div className="col-md-9">
                            <input
                                type="text"
                                required
                                id="role-name"
                                onChange={(e) => setRoleName(e.target.value)}
                                value={roleName}
                                className="form-control"
                            />
                        </div>
                    </div>
                    <div className="table-modal-div">
                        <table className="permissions-table table table-bordered">
                            <thead className="sticky-top">
                                <tr>
                                    <th>
                                        <div className="d-flex align-items-center">
                                            <input
                                                type="checkbox"
                                                className="module-name-checkbox"
                                                id={`select-all-permissions1`}
                                                onChange={(e) => handleSelectAllCheckBoxClick(e)}
                                                checked={isAllPermissionChecked}
                                            />
                                            <label className="mb-0" htmlFor={`select-all-permissions1`} style={{ fontWeight: 'bold' }}>
                                                Module
                                            </label>
                                        </div>
                                    </th>
                                    <th>Sub-Module</th>
                                    <th>Permissions</th>
                                </tr>
                            </thead>
                            <tbody>
                                {isTableReadyToRender && permissionsList?.map((item, index) => (
                                    <tr key={index}>
                                        <td>
                                            <div className="d-flex align-items-center">
                                                <input
                                                    type="checkbox"
                                                    className="module-name-checkbox mr-3"
                                                    id={`checkbox-${index}`}
                                                    onChange={(e) => handleParentModuleCheckBoxClick(e, item.permission_id)}
                                                    checked={permissionsCheckedState[item.permission_id]?.isAllChecked}
                                                />
                                                <label className="mb-0" htmlFor={`checkbox-${index}`} style={{ fontWeight: 'bold' }}>
                                                    {item.module_name}
                                                </label>
                                            </div>
                                        </td>
                                        <td>
                                            <ul className="list-unstyled">
                                                {item.sub_module?.map((subModule, subIndex) => (
                                                    <li key={`${index}-${subIndex}`} className="mb-3">
                                                        <label key={`${index}-${subIndex}`}>
                                                            <div className="d-flex align-items-center custom-label custom-label-no-hover">
                                                                {/* <input type="checkbox" id={`checkbox-${index}-${subIndex}-${catIndex}`} className="mr-2" /> */}
                                                                <span className="mr-3" style={{ fontWeight: 'bold', fontSize: '1em', opacity: 0.8 }}>
                                                                    {!subModule.system_generated ? subModule.sub_module_name : ""}
                                                                </span>
                                                            </div>
                                                        </label>
                                                        <Divider />
                                                    </li>
                                                ))}
                                            </ul>
                                        </td>
                                        <td>
                                            <ul className="list-unstyled">
                                                {item.sub_module?.map((subModule, subIndex) => (
                                                    <li key={`${index}-${subIndex}`} className="mb-3">
                                                        {subModule.categories?.map((category, catIndex) => (
                                                            <label key={`${index}-${subIndex}-${catIndex}`} htmlFor={`checkbox-${index}-${subIndex}-${catIndex}`}>
                                                                <div className="d-flex align-items-center custom-label">
                                                                    <input
                                                                        type="checkbox"
                                                                        id={`checkbox-${index}-${subIndex}-${catIndex}`}
                                                                        className="mr-2"
                                                                        onChange={(e) => handleSubModulePermissionCheckBoxClick(e, item.permission_id, subModule.permission_id, category)}
                                                                        checked={
                                                                            permissionsCheckedState[`${item.permission_id}`]?.sub_module[subModule?.permission_id][`${category}`]?.isChecked}
                                                                    />
                                                                    <span className="mr-3">
                                                                        {category === 1 ? i18n.t('roles-permissions.view-permission-display-text') : category === 2 ? i18n.t('roles-permissions.add-and-modify-permission-display-text') : ''}
                                                                    </span>
                                                                </div>
                                                            </label>
                                                        ))}
                                                        <Divider />
                                                    </li>
                                                ))}
                                            </ul>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </Form>
            </div>
        </div>
    );
};

export default ListRolePermissionsNew;