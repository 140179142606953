import React from "react";
import "../StatementStyles.css";
import ImageChangeCard from "./ImageChangeCard";
import ProfileImage from "../../../../assets/images/profile-image.png";
import classes from "./PracticeAddressCard.module.css";
const PracticeAddressCard = ({
  practiceDetails,
  editable,
  setImage,
  practiceLogo,
}) => {
  return (
    <>
      <div className="row mt-4">
        <div className="col-12">
          <div className="row">
            <div style={{ borderRadius: '8px' }} className="col-2 d-flex justify-content-center align-items-center">
              {editable ? (
                <ImageChangeCard setImage={setImage} imgUrl={practiceLogo} />
              ) : (
                <img
                    style={{ borderRadius: '8px' }}
                  src={practiceLogo ? practiceLogo : ProfileImage}
                  className={classes.image} alt="image"
                />
              )}
            </div>
            <div className="col-10 mt-2">
              <h3 style={{ fontWeight: 'bold', marginBottom: '12px' }}>
                <strong>
                  {practiceDetails ? practiceDetails.name : "Demo Practice --001"}
                </strong>
              </h3>
              <h4>
                {practiceDetails
                  ? practiceDetails?.location?.location
                  : "6101 Iliff Rd NW Albuquerque New York 87121"}
              </h4>
              <h6>{`Phone: ${practiceDetails?.phone ? practiceDetails.phone : "+1 484 263 4648"}   Fax: ${practiceDetails?.fax ? practiceDetails.fax : "+1 484 263 4649"}`} </h6>
              <h6>{`Email: ${practiceDetails?.email ? practiceDetails.email : "demo@demopractice.com"}`}</h6>
            </div>
          </div>
        </div>

        <div style={{ marginTop: '50px' }} className="col-12">
          <div style={{ borderRadius: '4px', padding: '5px', paddingTop: 0 }} className="col-12 d-flex justify-content-center mb-2">
            <h3 style={{ fontWeight: 'bold', textDecoration: "underline" }}>
              Patient Statement & Payment Request
            </h3>
          </div>
        </div>
      </div>
    </>
  );
};

export default PracticeAddressCard;
