import React from "react";
import "../StatementStyles.css";

function TextBox({ label, disabled }) {
  return (
    <div className="form-group mt-1">
      <label>{label}</label>
      <input
        type={"text"}
        className="form-control"
        {...(disabled ? { disabled: "disabled" } : "")}
      />
    </div>
  );
}

const ChangeHealthInfoCard = ({ patientName, patientId }) => {
  return (
    <div className="row mt-4">
      <div className="container">
        <div className="row">
          <div className="col-9">
            <h5 style={{ fontWeight: 'bold' }}>Change of Address or Health Insurance Information</h5>
            <p>
              If you have new health insurance or a new address, please enter
              the information below.
            </p>
          </div>
          <div className="col-3">
            <span>{patientName}</span>
            <br />
            <span>{patientId}</span>
          </div>
        </div>
        <div className="row m-2">
          <div className="col-12 container card-border">
            <div className="row">
              <div className="col-6 card-border">
                <div className="row">
                  <div className="col-4">
                    <TextBox label={"NEW ADDRESS"} disabled={true} />
                  </div>
                  <div className="col-4">
                    <TextBox label={"CITY"} disabled={true} />
                  </div>
                  <div className="col-2">
                    <TextBox label={"STATE"} disabled={true} />
                  </div>
                  <div className="col-2">
                    <TextBox label={"ZIP CODE"} disabled={true} />
                  </div>
                </div>
              </div>
              <div className="col-2 card-border">
                <TextBox label={"NEW PHONE"} disabled={true} />
              </div>
              <div className="col-4 card-border">
                <TextBox
                  label={"POLICY HOLDERS NAME/ RELATIONSHIP TO PATIENT"}
                  disabled={true}
                />
              </div>
            </div>

            <div className="row card-border">
              <div className="col-12">
                <div className="row">
                  <div className="col-3 card-border">
                    <TextBox label={"POLICY ID#"} disabled={true} />
                  </div>
                  <div className="col-3 card-border">
                    <TextBox label={"GROUP#"} disabled={true} />
                  </div>
                  <div className="col-3 card-border">
                    <TextBox label={"EFFECTIVE DATE"} disabled={true} />
                  </div>
                  <div className="col-3 card-border">
                    <TextBox label={"HMO/PPO/OTHER"} disabled={true} />
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-3 card-border">
                <TextBox label={"BIRTH DATE OF INSURED"} disabled={true} />
              </div>
              
              <div className="col-3 card-border">
                <TextBox label={"INSURANCE PHONE#"} disabled={true} />
              </div>
                <div className="col-6 card-border">
                  <TextBox
                    label={
                      "IF GROUP INSURANCE, NAME OF THE GROUP (EMPLOYER, UNION/ASSOCIATION)"
                    }
                    disabled={true}
                  />
                </div>
            </div>
            <div className="row">
              
            </div>
            <div className="row">
              <div className="col-6 card-border">
                <TextBox label={"INSURANCE COMPANY NAME"} disabled={true} />
              </div>
              <div className="col-6 card-border">
                <TextBox label={"INSURANCE ADDRESS"} disabled={true} />
              </div>
            </div>
            <div className="row">
              <div className="col-6 card-border">
                <TextBox label={"EMPLOYER"} disabled={true} />
              </div>
              <div className="col-6 card-border">
                <TextBox label={"EMPLOYER ADDRESS"} disabled={true} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ChangeHealthInfoCard;
