import React, { useState } from 'react'
import './style.css';
import TextField from '@material-ui/core/TextField';
import Label from '../Label';
const style = { width: "95%", border: "1px solid black", borderRadius: "4px" }
function NumericInput(props) {
    const [value, setValue] = useState(props.value)
    const required = props.required;

    const onchange = (e) => {
        let re = /^[1-9][.\d]*(,\d+)?$/;
        if (e.target.value === '' || re.test(e.target.value)) {
            setValue(e.target.value)
            props.onValueChange(e)
        }   
}
    let defaultClassName = props.defaultClassName ? props.defaultClassName : '';

    return (
        <div className="form-group">
            <Label label={props.label} />
            <TextField
                style={props.isBackgroundEnabled ? style : { width: "100%" }} id={props.id ? props.id : "standard-basic"} variant="outlined"
                required={required ? required : false}
                type={props.type}
                value={value}
                name={props.name}
                onChange={onchange}
                className={defaultClassName ? defaultClassName : required ? (value ? "" : "input-error") : ""}
            />
        </div>
    )
}

export default NumericInput;