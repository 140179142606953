import api from '../../../service/api'

const ListDelayReasonCode = async (pageSize, page, searchValue) => {
    let path = "super-admin/delay-reason-code/?page_size="+pageSize +"&page="+page;
    if (searchValue) {
        path += `&search=${searchValue}`;
    }
    return api.get(path);
};

const AddDelayReasonCode = async(data) =>{
	let path = 'super-admin/delay-reason-code/'
	return api.post(path,data);
}
const GetDelayReasonCode = async(delayCodeId) =>{
	let path = 'super-admin/delay-reason-code/' + delayCodeId
	return api.get(path);
}

const UpdateDelayReasonCode = async(delayCodeId, data) =>{
	let path = 'super-admin/delay-reason-code/' + delayCodeId
	return api.put(path,data);
}

const DeleteDelayCode = async(facilityId) =>{
	let path = 'super-admin/delay-reason-code/' + facilityId
	return api.destroy(path);
}

export default {ListDelayReasonCode, AddDelayReasonCode, GetDelayReasonCode, UpdateDelayReasonCode, DeleteDelayCode}