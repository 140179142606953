import React, {  useState, useContext,useEffect } from 'react';
import CommonButton from "../commons/Buttons";
import i18n from "../../utilities/i18n";
import { TabPanel } from "@mui/lab";
import { DEFAULT_DATE_FORMAT, TAB4 } from "../../utilities/staticConfigs";
import { checkPermission } from '../../utilities/commonUtilities';
import { permission_key_values_claim } from "../../utilities/permissions";
import Notify from '../commons/notify';
import { format } from 'date-fns';
import CustomizedDialogs from '../modalWindowComponent/CustomizedDialogs';
import service from "./Services/service";
import LoadingContext from '../../container/loadingContext';
import { NOTES_CLAIM_TYPES } from '../../utilities/dictionaryConstants';
import { ADD_ERROR, ADD_SUCCESS, DELETE_SUCCESS, UPDATE_SUCCESS } from '../../utilities/labelConfigs';
import SelectInput from '../commons/input/SelectInput';
import CustomizedSmallDialogs from '../modalWindowComponent/CustomisedSmallDialog';
import TextAreaInput from '../commons/textarea/textarea';
import { Form } from 'react-bootstrap';
import actionImg from '../../assets/images/action.png';
import { Link } from '@mui/material';

function ClaimNotes(props) {

    const [showModalWindow, setShowModalWindow] = useState(false);
    const [showNotify, setShowNotify] = useState("hide");
    const [notifyDescription, setNotifyDescription] = useState("");
    const [notifyType, setNotifyType] = useState("success");
    const [header, setHeader] = useState("");
    const [claimNotesText, setClaimNotesText] = useState("");
    const [notesTypeList, setNotesTypeList] = useState([]);
    const [claimNotesList, setClaimNotesList] = useState("");
    const [editClaimNotes, setEditClaimNotes] = useState(false);
    const [editClaimId, setEditClaimId] = useState(false);
    const setShowLoadingBar = useContext(LoadingContext);
    const [deleteClaimNoteId, setDeleteClaimNoteId] = useState(false);
    const [isSaving, setIsSaving] = useState(false);
    const [claimNotesTextType, setClaimNotesTextType] = useState("2");
    const [deleteClaimNoteModal, setDeleteClaimNoteModal] = useState(false);
    const [deleteClaimNoteMessage, setDeleteClaimNoteMessage] = useState("");
   
    function showNotifyWindow(action, type, desc, age = 3000) {
        if (action === "show") {
            setTimeout(() => {
                setShowNotify("hide");
            }, age);
        }
        setShowNotify(action);
        setNotifyType(type);
        setNotifyDescription(desc);
    }

    const getNotesData = () => {
        setNotesTypeList(NOTES_CLAIM_TYPES);
    };

    function addNewClaimNotes() {
        if(checkPermission(permission_key_values_claim.can_add_patient_claim_notes)){ 
            if (!props.claimPK) {
                return null;
            } else if (props.claimPK) {
                resetForm();
                setHeader(i18n.t('searchClaims.professionalClaims.addClaimNote'));
                setShowModalWindow(true);
            }
        } else {
            showNotifyWindow('show', 'error', i18n.t('errorMessages.permission_error'));
        }
    }
    
    useEffect(() => {
        if (props.claimPK) {
            getNotesData();
            ListNewClaimNotes();
           
        }
    }, [props.claimPK]);

    function ListNewClaimNotes() {
        let result = service.ListClaimNotes(props.claimPK, 0, 0);
        result.then((response) => {
            setClaimNotesList(response.data);
        });

    }

    function onDeleteClaimNote(id) {
        if (checkPermission(permission_key_values_claim.search_claims_sub_module_modify)) {
            claimNotesList.map((item) => {
                if (item.id === parseInt(id)) {
                    setDeleteClaimNoteMessage(item.notes);
                    setDeleteClaimNoteModal(true);
                    setDeleteClaimNoteId(item.id);
                }
                return null;
            })
        }
        else {
            showNotifyWindow('show', 'error', i18n.t('errorMessages.permission_error'));
        }
    }
    function DeleteClaimNote() {
        let result = service.DeleteClaimNotes(deleteClaimNoteId);
        result.then(() => {
            setDeleteClaimNoteModal(false);
            setDeleteClaimNoteMessage("");
            showNotifyWindow("show", "success", DELETE_SUCCESS);
            ListNewClaimNotes();
        });
    }
    function resetForm() {
        setClaimNotesText("");
        setHeader("");
        setEditClaimId("");
        setEditClaimNotes(false);
    }
    function onHandleNoteTypeChange(e) {
        let value = e.target.value;
        setClaimNotesTextType(value);
    }
    function onHandleNoteTextChange(e) {
        let value = e.target.value;
        setClaimNotesText(value);
    }

    function onShowActionButtonsClaim(event, rowId) {
        let dropdown = document.getElementById('dropdown-' + rowId);
        let dropdownMenu = document.getElementById('dropdown-menu-' + rowId);
        dropdown.classList.toggle('show');
        dropdownMenu.classList.toggle('show');
    }
    function onHideActionButtonsClaim(event, rowId) {
        let dropdown = document.getElementById('dropdown-' + rowId);
        let dropdownMenu = document.getElementById('dropdown-menu-' + rowId);
        dropdown.classList.remove('show');
        dropdownMenu.classList.remove('show');
    }
    function onEditClaimNote(id) {
        if (checkPermission(permission_key_values_claim.search_claims_sub_module_modify)) {
            let result = service.GetClaimNotes(id);
            result.then((response) => {
                setHeader(i18n.t('searchClaims.professionalClaims.editClaimNote'));
                setShowModalWindow(true);
                setClaimNotesText(response.data.notes);
                setEditClaimNotes(true);
                setEditClaimId(response.data.id);
            });
        }
        else {
            showNotifyWindow('show', 'error', i18n.t('errorMessages.permission_error'));
        }
    }
    async function onSaveClaimNotes(e) {
        e.target.disabled = true;
        setShowLoadingBar(true);
        if (isSaving) {
            e.target.disabled = false;
            return;
        }
        try {
            setIsSaving(true);
            e.preventDefault();
            let result = "";
            let data = "";
            if (props.claimPK && claimNotesTextType && claimNotesText.trim()) {
                setShowModalWindow(true);
                data = { "claim": props.claimPK, "notes_type": claimNotesTextType, "notes": claimNotesText, "category": 'claim' };
                if (!editClaimNotes) result = await service.SaveNewClaimNotes(data);
                else if (editClaimNotes) result = await service.UpdateClaimNotes(editClaimId, data);

                resetForm();
                ListNewClaimNotes();
                if (result.status === 201 || result.status === 200) {
                    if (result.status === 201) showNotifyWindow("show", "success", ADD_SUCCESS);
                    else showNotifyWindow("show", "success", UPDATE_SUCCESS);
                } else {
                    showNotifyWindow("show", "error", ADD_ERROR);
                }
                setShowModalWindow(false);
                setIsSaving(false);
                setShowLoadingBar(false);
            }
        }
        catch (error) {
            showNotifyWindow("show", "error", ADD_ERROR);
            setShowLoadingBar(false);
        }
    }
    return (
        <>
        <Notify showNotify={showNotify} setShowNotify={setShowNotify} notifyDescription={notifyDescription} setNotifyType={setNotifyType} setNotifyDescription={setNotifyDescription} notifyType={notifyType} />
        <div>
        {/* {(props.activeSubTab === TAB4 || props.activeSubTab === undefined) && */}
              <TabPanel value={TAB4} className='searchBoxTab'>
                                  {props.activeTab != "editClaims" && props.claimPK &&  checkPermission(permission_key_values_claim.claims_search_claims_add) ?
                                    <div className="alignRight padding-bottom10 padding-top10">
                                        <CommonButton icon="add" variant="contained" onClick={addNewClaimNotes} label={i18n.t("buttons.addNew")} />
                                    </div> : null}
                                <table className="table margin-top20">
                                    <thead>
                                        <tr>
                                            <th className='width-15-perc'>{i18n.t("searchClaims.professionalClaims.created_on")}</th>
                                            <th className='width-10-perc'>{i18n.t("searchClaims.professionalClaims.noteType")}</th>
                                            <th className='width-50-perc'>{i18n.t("searchClaims.professionalClaims.note")}</th>
                                            <th className='width-15-perc'>{i18n.t("searchClaims.professionalClaims.createdBy")}</th>
                                            <th className='width-10-perc'>{i18n.t("searchClaims.professionalClaims.action")}</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {claimNotesList && claimNotesList.length === 0 &&
                                            <tr>
                                                <td align="center" colSpan={5}>{i18n.t("commons.noRecords")}</td>
                                            </tr>
                                        }
                                        {claimNotesList && claimNotesList.map((item, index) => {
                                            return (
                                                <tr key={index}>
                                                    <td align='center'>{format(new Date(item.created_on), DEFAULT_DATE_FORMAT)}</td>
                                                    <td>{item.notes_type_name}</td>
                                                    <td style={{
                                                        whiteSpace: "pre-line",
                                                        wordWrap: 'break-word',
                                                        overflowWrap: 'break-word',
                                                        wordBreak:'break-word'
                                                    }}>
                                                        {item.notes}
                                                    </td>
                                                    <td>{item.created_by_name}</td>
                                                    {/* {professionalClaimVariable.claim_editable && ( */}
                                                        <td align='center'>
                                                            <div className="dropdown" id={'dropdown-' + item.id}>
                                                                <button
                                                                    id={'dropdownMenuButton-' + item.id}
                                                                    className="dropdown-toggle"
                                                                    type="button"
                                                                    data-toggle="dropdown"
                                                                    aria-haspopup="true"
                                                                    onClick={(e) => onShowActionButtonsClaim(e, item.id)}
                                                                    onBlur={(e) => onHideActionButtonsClaim(e, item.id)}
                                                                >
                                                                    <img id="test" src={actionImg} alt="Action" />
                                                                </button>
                                                                <div className="dropdown-menu" id={'dropdown-menu-' + item.id}>
                                                                    <Link
                                                                        className="dropdown-item edit"
                                                                        to="#"
                                                                        onMouseDown={() => onEditClaimNote(item.id)}
                                                                    >
                                                                        {'Edit'}
                                                                    </Link>
                                                                    <Link
                                                                        className="dropdown-item edit"
                                                                        to="#"
                                                                        onMouseDown={() => onDeleteClaimNote(item.id)}
                                                                    >
                                                                        {'Delete'}
                                                                    </Link>
                                                                </div>
                                                            </div>
                                                        </td>
                                                     {/* )}  */}
                                                </tr>
                                            )
                                        })
                                        }
                                    </tbody>
                                </table>
                                <div className="clear-b-scroll-sector">&nbsp;</div>
                            </TabPanel>
        {/* }             */}
                        <CustomizedDialogs showModal={showModalWindow} type="formSubmit" onSaveFormData={onSaveClaimNotes} setShowModalWindow={setShowModalWindow} header={header} resetForm={resetForm}  >
                <Form id="formSubmit" autoComplete="off" >
                    <div className="col-6 pl-0 padding-top5">
                        <div className="form-group">
                            <SelectInput disabled={true} required={true} data={notesTypeList} name="claimNotesTextType" value={claimNotesTextType}
                                label={i18n.t("searchClaims.professionalClaims.noteType")} setValue={setClaimNotesTextType}
                                onValueChange={(e) => onHandleNoteTypeChange(e)} />
                        </div>
                    </div>
                    <div className="">
                        <div className="form-group padding-top10">
                            <TextAreaInput required={true} rows={3} name="claimNotesText" value={claimNotesText}
                                label={i18n.t("searchClaims.professionalClaims.labelNote")}
                                onValueChange={(e) => onHandleNoteTextChange(e)} />
                        </div>
                    </div>
                </Form>
            </CustomizedDialogs>
            <CustomizedSmallDialogs showModal={deleteClaimNoteModal} setShowModalWindow={setDeleteClaimNoteModal} header={i18n.t("commons.confirmDelete")} type="delete" deleteItem={DeleteClaimNote} resetForm={resetForm} >
                {i18n.t("searchClaims.professionalClaims.ClaimNoteDeleteConfirmation") + " '" + deleteClaimNoteMessage + "'?"}
            </CustomizedSmallDialogs>
        </div>
        </>
    );

}
export default ClaimNotes;
