import { createSlice,createAsyncThunk } from "@reduxjs/toolkit";
import i18n from "../../../utilities/i18n";
import { csvxlsFileCheck } from "../../../utilities/validations";
import service from "../patients/service";


const initialState = {
    previewData: [],
    selectedInsurances: [],
    isCheckedAll: false,
    isImportBatchSuccess: false,
    isSuccess: false,
    isError: false,
    message: '',
    isLoading: false,
    importBatchError: {},
    importBatchErrorFlag: false,
    selectedImportedFilename: ''
}


export const getImportBatchEligibiliity = createAsyncThunk(
    "batchImportEligibility/getImportBatchEligibiliity",
    async (payload, thunkAPI) => {
        try {
            if (payload.type === "file") {
                if (payload.e.target.files[0] && csvxlsFileCheck(payload.e.target.files[0].name)) {
                    const data = new FormData();
                    data.append("file", payload.value);
                    data.append("practice_pk", payload.practice_pk);
                    let result = await service.ImportBatch(data);
                    if (result?.status === 200) {
                        return {
                            result: result,
                            filename: payload.e.target.files[0].name,
                        };
                    } else {
                        throw result;
                    }
                }
                else {
                    return thunkAPI.rejectWithValue({ errorMessage: i18n.t("errorMessages.invalid_file_format") })
                }
            }
        } catch (err) {
                if (err.data && err.status === 422) {
                    if (err.data.message == "invalid_headings") {
                        return thunkAPI.rejectWithValue({ errorMessage: i18n.t("errorMessages.invalid_headings") });
                    }
                    if (err.data.message == "csv_exceeds_max_allowed_row_count") {
                        return thunkAPI.rejectWithValue({ errorMessage: i18n.t("errorMessages.import_batch_max_allowed_rows_exceeds") + err.data?.import_batch_max_allowed_rows });
                    }
                }
               else if (err.data && err.status === 404) {
                    if (err.data.message == "no_file_records") {
                        return thunkAPI.rejectWithValue({ errorMessage: i18n.t("errorMessages.empty_file") });
                }
              }
                else {
                    return thunkAPI.rejectWithValue({ errorMessage: "Batch eligibility error." })
            }
        }
    }
)

export const runImportBatchEligibiliity = createAsyncThunk(
    "batchImportEligibility/runImportBatchEligibiliity",
    async (payload, thunkAPI) => {
        let insurance_info_ids = [];
        try {
            const practice_pk = payload.practice_id;
            let selectedInsurances = thunkAPI.getState().importBatch.selectedInsurances;
            selectedInsurances.forEach((item) => {
                if (item.insurance_info_id) {
                    insurance_info_ids.push(item.insurance_info_id);
                }
            });
            let data = { insurance_info_ids: insurance_info_ids, practice_id: practice_pk }
            let result = await service.RunImportedBatchEligibility(data);
            if (result?.status === 200) {
                return result;
            } else {
                throw result;
            }
        } catch (err) {
            return thunkAPI.rejectWithValue({ errorMessage: i18n.t("Eligibility.batchEligibility.batchAddingFailed") })
        }
    }
)





const importBatch = createSlice({
    name: 'importBatch',
    initialState,
    reducers: {
        /**
         * remove previous data
         * @param {*} state 
         */
        removeTableDataAndSelectedIDs: (state) => {
            state.previewData = [];
            state.selectedInsurances = [];
            state.selectedPatients = [];
            state.isCheckedAll = false;
        },

        /**
         * on checkbox click storing data here
         * @param {*} state 
         * @param {*} actions 
         */
        oncheckEvent: (state, actions) => {
            /**
             * changing ischecked value with payload checked value to change checkbox value
             */
            const tableIndex = state.previewData.findIndex((ele) => ele?.patient_id === actions?.payload?.item?.patient_id);
            if (tableIndex !== -1) {
                let data = {
                    ...state.previewData[tableIndex],
                    isChecked: actions.payload.checked
                }
                state.previewData[tableIndex] = data;
            }

            /**removing and adding insurance id and patient id to send to backend */
            const insIndex = state.selectedInsurances.findIndex((ele) => ele?.patient_id === actions?.payload?.item?.patient_id);
            if (insIndex !== -1) { 
                state.selectedInsurances.splice(insIndex, 1);
            }
            else {
                let item = {
                    patient_id: actions.payload.item?.patient_id,
                    insurance_info_id: actions.payload.item?.insurance_info_id
                }
                state.selectedInsurances.push(item)
            }

            if (state.selectedInsurances.length == state.previewData.length) {
                state.isCheckedAll = true;
            }
            else {
                state.isCheckedAll = false;
            }
        },

         SelectAllEvent: (state, actions) => {
             if (actions.payload.checked) {
                 let insuranceData = [];
                 /**making all data unchecked in the table */
                 const tableData = state.previewData.map(item => ({
                     ...item,
                     isChecked: true
                 }));

                 state.previewData = tableData;
                 state.isCheckedAll = true;
                    state.previewData.map((item => {
                        let element = {
                            patient_id: item.patient_id,
                            insurance_info_id: item.insurance_info_id
                        }
                        insuranceData.push(element);
                    }))
                 
                 state.selectedInsurances=insuranceData

             }
             else {
                 /**making all data unchecked in the table */
                 const tableData = state.previewData.map(item => ({
                     ...item,
                     isChecked: false
                 }));

                 state.previewData = tableData;
                 state.isCheckedAll = false;
                 state.selectedInsurances = [];
             }
        },
         
        
       
    
 


         
         /**
          * changing isImport batch success flag if run eligibility api data is sucess
          */

        updateFieldValues: (state, actions) => {
            let field=actions.payload.field
            let value = actions.payload.value;
            
            return {
                ...state,
                [field]: value,
            }
        }

    },

    extraReducers: (builder) => { 
        builder.addCase(getImportBatchEligibiliity.pending, (state) => {
            state.isLoading = true;
            state.isError = false;
            state.isSuccess = false;
            state.importBatchError = {};
            state.importBatchErrorFlag = false;
        })

        builder.addCase(getImportBatchEligibiliity.fulfilled, (state, actions) => {
            let tabledata = [];
            state.isLoading = false;
            if (actions?.payload?.result?.data?.message == "success") {
                state.isError = false;
                state.isSuccess = true;
                state.message = "Import batch successful"
                if (actions?.payload?.result?.data?.data?.data) {
                    actions.payload.result.data.data.data.map((item => {
                        let element = {
                            ...item,
                            isChecked: true
                        }
                        tabledata.push(element);
                    }))
                    /**storing table data */
                    state.previewData = tabledata;
                    /**storing all insurance ids */
                    state.selectedInsurances = actions?.payload?.result?.data?.data?.patient_data;
                    state.isCheckedAll = true;
                    state.selectedImportedFilename=actions.payload.filename;

                    if (Object.keys(actions?.payload?.result?.data?.data?.error).length) {
                        state.importBatchError = actions?.payload?.result?.data?.data?.error;
                        state.importBatchErrorFlag = true;
                    }

                }
            }
        })

        builder.addCase(getImportBatchEligibiliity.rejected, (state, actions) => {
            state.isLoading = false;
            state.isError = true;
            state.isSuccess = false;
            state.importBatchErrorFlag = false;
            state.importBatchError = {};
            state.selectedImportedFilename="";
            state.message = actions.payload.errorMessage || 'An error occurred while importing batch';
        })

        builder.addCase(runImportBatchEligibiliity.pending, (state) => {
            state.isLoading = true;
            state.isError = false;
            state.isSuccess = false;
            state.importBatchError = {};
            state.importBatchErrorFlag = false;
        })

        builder.addCase(runImportBatchEligibiliity.fulfilled, (state, actions) => {
            if (actions.payload.data.message === "success") {
                state.isLoading = false;
                state.isImportBatchSuccess = true;
                state.selectedInsurances = [];
                state.previewData = [];
                state.isCheckedAll = false;
            }
        })

        builder.addCase(runImportBatchEligibiliity.rejected, (state, actions) => {
            state.isLoading = false;
            state.isError = true;
            state.isSuccess = false;
            state.importBatchErrorFlag = false;
            state.importBatchError = {};
            state.message = actions.payload.errorMessage || 'An error occurred';
        })
    }
})

export const {
    updateBatchTableData,
    removeTableDataAndSelectedIDs,
    oncheckEvent,
    SelectAllEvent,
    updateFieldValues,
} = importBatch.actions;

export default importBatch.reducer;

