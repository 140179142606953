import React, { useState } from 'react';
import { getStorage } from '../../utilities/browserStorage';
import { CHARGE_TYPE } from '../../utilities/dictionaryConstants';
import i18n from '../../utilities/i18n';
import TextInput from '../commons/input/input';
import SelectInput from '../commons/input/SelectInput';
import service from './service'; 
import { AsyncTypeahead } from 'react-bootstrap-typeahead';

export const ProcedureComponent = (props) => {
    const index = props.index;
    const [cptList, setCptList] = useState([]);
    const [posCodeList, setPosCodeList] = useState([]);
    const [modifierList1, setModifierList1] = useState([]);
    const [modifierList2, setModifierList2] = useState([]);
    const [modifierList3, setModifierList3] = useState([]);
    const [modifierList4, setModifierList4] = useState([]);

    const practicePK = getStorage('practice');
    const page = 0;
    const page_size = 0;

    function getPosCodes(query) {
        let result = service.SearchPosCodes(query);
        result.then((response)=>{
            let anArray = [];
            response.data.results.map((item) => {
                let anObject = {
                    'id': item.id,
                    'name': item.drop_down_name,
                    'label': item.drop_down_name
                }
                anArray.push(anObject);
            });
            setPosCodeList(anArray);
        });
    }

    const onSearchCPTCodes = (query) => {
        let type = 'dropdown'
        let result = service.GetCPTCodeList(page_size, page, practicePK, type, query);
        result.then((response) => {
            setCptList(response.data)
        })
    }

    const onSearchModifiers1 = (query) => {
        let result = service.GetModifier(query);
        result.then((response) => {
            setModifierList1(response.data.results)
        })
    }
    const onSearchModifiers2 = (query) => {
        let result = service.GetModifier(query);
        result.then((response) => {
            setModifierList2(response.data.results)
        })
    }
    const onSearchModifiers3 = (query) => {
        let result = service.GetModifier(query);
        result.then((response) => {
            setModifierList3(response.data.results)
        })
    }
    const onSearchModifiers4 = (query) => {
        let result = service.GetModifier(query);
        result.then((response) => {
            setModifierList4(response.data.results)
        })
    }

    return (
        <div>
            <div className='justify-space-between pl-0'>
                <div className="form-group padding-top15 pl-0 col-1" >
                    <AsyncTypeahead
                        id="basic-typeahead-single"
                        labelKey="name"
                        minLength={1}
                        onChange={(e) => props.onHandleTypeHead(e, "cpt_hcpcs", index)}
                        options={cptList}
                        placeholder={i18n.t('interface.testCodeSetup.cpt')}
                        onSearch={onSearchCPTCodes}
                        selected={props.procedureData.cpt_hcpcsList}
                    />
                </div>
                <div className="pl-0 form-group padding-top15 col-2" >
                    <AsyncTypeahead
                        id={"Pos" + index}
                        labelKey="name"
                        minLength={1}
                        onChange={(e) => props.onHandleTypeHead(e, "pos_id", index)}
                        options={posCodeList}
                        placeholder={i18n.t('interface.testCodeSetup.pos')}
                        onSearch={getPosCodes}
                        selected={props.procedureData.pos_idList}
                    />
                </div>
                <div className="pl-0 form-group padding-top15  mr-3" >
                    <AsyncTypeahead
                        id={"m1" + index}
                        labelKey="name"
                        minLength={1}
                        onChange={(e) => props.onHandleTypeHead(e, "m1", index)}
                        options={modifierList1}
                        onSearch={onSearchModifiers1}
                        placeholder={i18n.t('interface.testCodeSetup.m1')}
                        selected={props.procedureData.m1List}
                    />
                </div>
                <div className="pl-0 form-group padding-top15  mr-3" >
                    <AsyncTypeahead
                        id={"m2" + index}
                        labelKey="name"
                        minLength={1}
                        onChange={(e) => props.onHandleTypeHead(e, "m2", index)}
                        options={modifierList2}
                        onSearch={onSearchModifiers2}
                        placeholder={i18n.t('interface.testCodeSetup.m2')}
                        selected={props.procedureData.m2List}
                    />
                </div>
                <div className="pl-0 form-group padding-top15  mr-3" >
                    <AsyncTypeahead
                        id={"m3" + index}
                        labelKey="name"
                        minLength={1}
                        onChange={(e) => props.onHandleTypeHead(e, "m3", index)}
                        options={modifierList3}
                        onSearch={onSearchModifiers3}
                        placeholder={i18n.t('interface.testCodeSetup.m3')}
                        selected={props.procedureData.m3List}
                    />
                </div>
                <div className="pl-0 form-group padding-top15  mr-3" >
                    <AsyncTypeahead
                        id={"m4" + index}
                        labelKey="name"
                        minLength={1}
                        onChange={(e) => props.onHandleTypeHead(e, "m4", index)}
                        options={modifierList4}
                        onSearch={onSearchModifiers4}
                        placeholder={i18n.t('interface.testCodeSetup.m4')}
                        selected={props.procedureData.m4List}
                    />
                </div>
                <div className="pl-0 form-group col-2" >
                    <SelectInput id={"charge_type" + index} data={CHARGE_TYPE} name="charge_type" value={props.procedureData.charge_type} 
                        onValueChange={(e) => props.onHandleChange(e, index)}
                        label={i18n.t('interface.testCodeSetup.chargeType')} />
                </div>
                <div className="pl-0 form-group input_right_align col-2" >
                    <TextInput id={"charge_amount" + index} name="charge_amount" value={props.procedureData.charge_amount}
                        onValueChange={(e) => props.onHandleChange(e, index)}
                        label={i18n.t('interface.testCodeSetup.chargeAmount')} />
                </div>
                <div className="pl-0 form-group padding-top22 justify-right" >
                    {props.index > 0 ? <div className='justify-right text-danger cursor-pointer' onClick={(e) => props.removeProcedureRow(e, index)}>Remove</div> : <div>&nbsp;</div>}
                </div>
            </div>
        </div>
    )
}
