import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import service from '../service';
import { getStorage } from "../../../utilities/browserStorage";

// Initial State
const initialState = {
    isLoading: false,
    isErrorGettingDropdownData: false,
    isFilterDropDownDataReceived: false,
    renderingProviderList: [],
    referringProviderList: [],
    insuranceCompanyList: [],
    praticeUserList: [],
    serviceLocationList: [],
    cptList: [],
    icdList: [],
    payerTypes: [],
    claimStatusList: [],
    claimSubStatusList: [],
};


/*
 * Function to get call the api for all the dropdown list data 
 */
export const getDropDownListData = createAsyncThunk(
    "report/getDropDownListData",
    async (payload, thunkAPI) => {
        const pageSize = 0;
        const page = 0;
        const practicePK = getStorage('practice');
        const type = 'dropdown';
        /**
         * The promises array contains the promises returned by each API call, 
         * which can be resolved using Promise.all(promises) to wait for all the requests to complete.
         */
        const promises = [
            service.GetICD10List(pageSize, page, practicePK, type).then(response => response.data),
            service.GetPayerTypes().then(response => response.data.results),
            service.ListReferringProviders(pageSize, page, practicePK).then(response => response.data),
            service.getInsuranceCompany(pageSize, page, practicePK).then(response => response.data),
            service.praticeUserList(pageSize, page, practicePK).then(response => response.data),
            service.ServiceLocationLists(practicePK).then(response => {
                let locations = []
                response.data.map((item) => {
                    item.id = item.Id + '||' + item.location_type
                    locations.push(item)
                })
                return locations
            }),
            service.ListRenderingProviders(pageSize, page, practicePK).then(response => response.data),
            service.GetCPTCodeList(pageSize, page, practicePK, type).then(response => response.data),
            service.ListClaimStatus(pageSize, page, practicePK).then(response => response.data),
            service.ListClaimSubStatus(pageSize, page, practicePK).then(response => response.data),
        ];

        try {
            const [
                icdList,
                payerTypes,
                referringProviderList,
                insuranceCompanyList,
                praticeUserList,
                serviceLocationList,
                renderingProviderList,
                cptList,
                claimStatusList,
                claimSubStatusList,
            ] = await Promise.all(promises);

            return {
                icdList,
                payerTypes,
                referringProviderList,
                insuranceCompanyList,
                praticeUserList,
                serviceLocationList,
                renderingProviderList,
                cptList,
                claimStatusList,
                claimSubStatusList,
            };
        } catch (error) {
            // Handle error
            return thunkAPI.rejectWithValue({ errorMessage: 'Error getting Drop-down list data!' })
        }
    }
);

const reportSlice = createSlice({
    name: "reportFilters",
    initialState,
    reducers: {
        reset: () => initialState,
    },
    extraReducers: (builder) => {
        builder
            .addCase(getDropDownListData.pending, (state) => {
                state.isLoading = true;
                state.isErrorGettingDropdownData = false;
            })
            .addCase(getDropDownListData.fulfilled, (state, action) => {
                state.isLoading = false;
                state.isErrorGettingDropdownData = false;
                state.isFilterDropDownDataReceived = true
                const {
                    icdList,
                    payerTypes,
                    referringProviderList,
                    insuranceCompanyList,
                    praticeUserList,
                    serviceLocationList,
                    renderingProviderList,
                    cptList,
                    claimStatusList,
                    claimSubStatusList,
                } = action.payload;
                state.icdList = icdList;
                state.payerTypes = payerTypes;
                state.referringProviderList = referringProviderList;
                state.insuranceCompanyList = insuranceCompanyList;
                state.praticeUserList = praticeUserList.map((item) => ({ ...item, id: item.user_id }));
                state.serviceLocationList = serviceLocationList;
                state.renderingProviderList = renderingProviderList;
                state.cptList = cptList;
                state.claimStatusList = claimStatusList;
                state.claimSubStatusList = claimSubStatusList;
            })
            .addCase(getDropDownListData.rejected, (state) => {
                state.isLoading = false;
                state.isErrorGettingDropdownData = true;
            });
    },
});

export const { onResetData } = reportSlice.actions
export default reportSlice.reducer;