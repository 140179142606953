import React, { useContext, useEffect, useState } from 'react'
import { Link, useHistory, useLocation } from "react-router-dom";
import { format } from 'date-fns';

import './style.css';
import service from './service';

import Notify from '../commons/notify';
import TextInput from '../commons/input/input'
import SelectInput from '../commons/input/SelectInput';
import TextAreaInput from '../commons/textarea/textarea';
import CalendarInput from '../commons/input/CalendarInput';
import AsyncTypeInput from '../commons/input/AsyncTypeHead/AsyncTypeInput';

import CustomizedDialogs from '../modalWindowComponent/CustomizedDialogs';

import MultiSearch from '../../MaterialMultiselect/MultiSearch';

import i18n from '../../utilities/i18n';
import LoadingContext from '../../container/loadingContext';
import { getStorage } from '../../utilities/browserStorage';
import * as staticConfigs from '../../utilities/staticConfigs'
import { ADD_ERROR, ADD_SUCCESS, UPDATE_SUCCESS } from '../../utilities/labelConfigs';
import { LINK_TYPE, LINK_TYPE_CLAIM_ONLY, TASK_PRIORITIES, TASK_STATUS } from '../../utilities/dictionaryConstants';
import { IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import LinkTypeTable from './LinkTypeTable';
import { checkPermission } from '../../utilities/commonUtilities';
import { permission_key_values_tasks } from '../../utilities/permissions';
import { useDispatch } from 'react-redux';
import { getTaskDetail, removeTaskLinkAdded, resetTaskDetails, updateTaskDetails, updateTaskLinks } from './TaskSlice/taskSlice';
import { useSelector } from 'react-redux';



const Tasks = (props) => {
    const loading = useSelector(state => state.taskData.loading);
    const taskDetails = useSelector(state => state.taskData.taskDetails);
    const dispatch=useDispatch()
    const practicePK = getStorage('practice');
    const location = useLocation();
    const setShowLoadingBar = useContext(LoadingContext);
    const history = useHistory();
    const user = getStorage('userData');

    const [taskId, setTaskId] = useState("");

    const [task_title, setTask_title] = useState('');
    const [task_due_date, setTask_due_date] = useState('');
    const [status, setStatus] = useState(1);
    const [priority, setPriority] = useState(1);
    const [description, setDescription] = useState('');
    const [task_link_type, setTask_link_type] = useState(1);

    const [claimList, setClaimList] = useState([]);

    // searchlink options
    const [searchLinkOptions, setSearchLinkOptions] = useState([]);
    // notifications
    const [showNotify, setShowNotify] = useState("hide");
    const [notifyDescription, setNotifyDescription] = useState("");
    const [notifyType, setNotifyType] = useState("success");
    // modal window
    const [showTaskLinkModal, setShowTaskLinkModal] = useState(false);

   // multisearch
   const [optionItems, setOptionItems] = useState([]);
   const [optionValues, setOptionValues] = useState([]);
    const [selectedValues, setSelectedValues] = useState([]);
    const [selectedItems, setSelectedItems] = useState([]);
    // user List
    const [userList, setUserList] = useState([]);

    // get link type
    const [linkTypesListArrays, setLinkTypesListArrays] = useState({
        "claim":[],"patient":[], "payment":[]
    });
    // added task link list
    const [taskLinkListAdded, setTaskLinkListAdded] = useState({
        "claim":[],"patient":[]
    });

    // 
    const [patientList, setPatientList] = useState([]);
    const activePage = 1;
    const pageSize = staticConfigs.DEFAULT_PAGING_SIZE;

    const patientHeaders = [
        i18n.t("patientPages.patients.labelPatientName"),
        i18n.t("patientPages.patients.labelPatientID"),
        i18n.t("patientPages.patients.labelDOB"),
        i18n.t("patientPages.patients.labelPatientType"),
    ]
    const claimHeader = [
        i18n.t("searchClaims.claimId"),
        i18n.t("searchClaims.patientName"),
        i18n.t("searchClaims.dos"),
        i18n.t("searchClaims.charges"),
        i18n.t("searchClaims.balance"),
        i18n.t("searchClaims.billedDate"),
        i18n.t("searchClaims.responsiblePayer"),
    ]

    useEffect(() => {
        
        setLinkTypesListArrays(()=>{
            let claimArray = [];
            let patientArray = [];
            taskLinkListAdded.claim.map((item)=>claimArray.push(item.id));
            taskLinkListAdded.patient.map((item)=>patientArray.push(item.id));
            return {
                "patient" : patientArray,
                "claim" : claimArray,
                "payment" :[]
            }
        })      
    }, [taskLinkListAdded.claim, taskLinkListAdded.patient]);
    
    function onSearchContact(query) {
        if (query){
            let result = service.SearchPracticeUsers(practicePK,query);
            result.then((response)=>{
                setOptionItems(response.data);
            })
        }
    }
  

   
    //link search
    function onSearchLinkType(query) {
        if (query) {
            let task_link = LINK_TYPE.find((item) => item.id == task_link_type)
            let result = service.SearchLinkType(practicePK, task_link.name.toLowerCase(), query);
            result.then((response) => {
                const newArray = createNewArray(response.data, task_link.name.toLowerCase());
                setSearchLinkOptions(newArray);
            });
        }
    }
    
    //search option array creation
    function createNewArray(data, linkType) {
        let newArray = [];
        data.map((item) => {
            let newObject;
            if (linkType === "patient") {
                newObject = {"id": item.id, "label": item.name, "name": item.drop_down_name}
            } else {
                newObject = {"id": item.id, "label": item.custom_claim_id, "name": item.patient_name}
            }
            newArray.push(newObject);
        });
        return newArray;
    }

    function getUserList() {
        setShowLoadingBar(true);
        let result = service.ListPracticeUsers(practicePK);
        result.then((response)=>{
            let data = response.data;
            setUserList(data);
            setShowLoadingBar(false);
        });
    }

    function getListClaims() {
        let query = '?list=true&last10=true&practice_pk=' + getStorage("practice") + '&page=0';
        if(props.calledFrom && props.calledFrom==='patientLedger' && props.patientPK) {
            query += '&patient_pk='+props.patientPK;
        } 
        const result = service.GetListPatientClaims(query);
        result.then(response => {
            setClaimList(response.data);
        })
    }

    function getPatientsData() {
        setShowLoadingBar(true);
        let query = "?list_type=list&practice_pk=" + practicePK + "&page_size=" + pageSize + "&page=" + activePage + '&last10=' + true;
        const result = service.ListPatients(query);
        result.then(response => {
            setShowLoadingBar(false);
            if (response.data) {
                setPatientList(response.data.results);
            }
        });
    }

    
    function getTaskDetails(id) {
        dispatch(getTaskDetail({ pk: practicePK, id: id }));
    }

    useEffect(() => {
        if (taskDetails) {
            let patientArray = [];
            let claimArray = [];
            setTaskId(taskDetails?.id);
            setTask_title(taskDetails?.task_title);
            setStatus(parseInt(taskDetails?.status));
            setPriority(taskDetails?.priority);
            setDescription(taskDetails?.description);
            setSelectedValues(taskDetails?.assignee);
            setTask_due_date(taskDetails?.due_date ? new Date(taskDetails?.due_date) : "");
            taskDetails?.patient_tasks?.length > 0 && taskDetails?.patient_tasks?.map((item) => {
                let newObject = { "id": item.patient_pk, "name": item.name }
                patientArray.push(newObject);
                return patientArray
            });

            taskDetails?.claim_tasks?.length > 0 && taskDetails?.claim_tasks?.map((item) => {
                let newObject = { "id": item.claim_pk, "name": item.name }
                claimArray.push(newObject);
                return claimArray
            });

            setTaskLinkListAdded(() => {
                return { ...taskLinkListAdded, "patient": patientArray, "claim": claimArray }
            });
        }
    },[taskDetails])

    useEffect(() => {
        if (loading) {
            setShowLoadingBar(true);
        } 
        else {
            setShowLoadingBar(false);
        }
    },[loading])
    

    useEffect(()=>{
        let id = location && location.state?.task_id;
        if (id){
            getTaskDetails(id);
        }
        getUserList();
        getPatientsData();
        getListClaims();
    }, []);

    useEffect(() => {
        if (selectedItems.length > 0) {
            dispatch(updateTaskDetails({node:"assignee",value:selectedItems}))
        }
    },[selectedItems])
    
    function onHandleTaskChange(e){
        if (e.target) {
            let name = e.target.name;
            let value = e.target.value;
            dispatch(updateTaskDetails({ node: name, value: value }));
        }
        else if (e[0]){
            onAddTaskLinkList(e[0]);
            setShowTaskLinkModal(false);
        }
    }

    //adding task link
    function onAddTaskLinkList(taskLinkList) {
        if (taskLinkList.id > 0) {
            let task_name = LINK_TYPE.find((item) => item.id == task_link_type);
            let newObject;
            if (task_name.name.toLowerCase() === "patient") {
                newObject = { patient_pk: taskLinkList.label ,"id": taskLinkList.id, "name": taskLinkList.name};
                updateTaskLinkListAdded("patient_tasks", newObject);
            } else if (task_name.name.toLowerCase() === "claim") {
                newObject = {"id": taskLinkList.id, "name": taskLinkList.name, "claim_pk": taskLinkList.label};
                updateTaskLinkListAdded("claim_tasks", newObject);
            }
            setShowTaskLinkModal(false);
        }
    }
    
    //updating task link list state array
    function updateTaskLinkListAdded(type, newObject) {
        let present = taskDetails[type].find((item => item.id === newObject.id));
        if(!present){
             dispatch(updateTaskLinks({node:type,value:newObject}))
        }
        else {
            showNotifyWindow("show", "error", i18n.t("errorMessages.addlinkduplicate"));
        }
    }    

    function onRemoveItem(id) {
        dispatch(removeTaskLinkAdded(id))
    }

    function onHandleTasDatekChange(value,name) {
        if (name === 'task_due_date') dispatch(updateTaskDetails({ node:"due_date",value:value}))
    }

    function onSaveTask() {
    
        let has_error = false;
        if (!task_title || !description || optionValues.length === 0 || !task_due_date || linkTypesListArrays.length === 0){
          has_error = true;
        }
        if(Object.values(taskLinkListAdded.patient).concat(Object.values(taskLinkListAdded.claim)).length == 0)
        {
            has_error = true;
        }
        if (!has_error){
          setShowLoadingBar(true);
            let user_id;
            try {
                if (user) {
                    const userData = JSON.parse(user);
                    user_id = userData.user_id;
                } else {
                    throw new Error('Empty JSON input');
                }
            } catch (error) {
                console.error('Error parsing JSON:', error);
            }
            let data = {
                "task_title": task_title,
                "description": description,
                "assignee": [user_id],
                "assigned_to": optionValues,
                "status": status,
                "priority": priority,
                "due_date": format(task_due_date, 'yyyy-MM-dd'),
                "link_types":
                    props.patientPK ?
                    { ...linkTypesListArrays, patient: [...linkTypesListArrays.patient, props.patientPK] }
                    : linkTypesListArrays
            }
          let result = null;
          if (taskId) {
            data.task_id = taskId;
            result = service.UpdateTask(data);
          }
          else {
            data.practice=parseInt(practicePK);
            result = service.SendTask(data);
          }
          sendTaskRequest(result, setShowLoadingBar, showNotifyWindow, history, props);
        }
      }
      
      function sendTaskRequest(result, setShowLoadingBar, showNotifyWindow, history, props) {
        result.then((response)=>{
          setShowLoadingBar(false);
          if (response.status === 201 || response.status === 200) {
            if (response.status === 201) showNotifyWindow("show", "success", ADD_SUCCESS);
            if (response.status === 200) showNotifyWindow("show", "success", UPDATE_SUCCESS);
            if(props?.calledFrom && props?.calledFrom==="patientLedger" ) {
                setOptionValues([]);
                resetForm(); 
                dispatch(resetTaskDetails());
            }
            else {
              setTimeout(()=>{
                history.push(staticConfigs.ROUTE_MY_TASKS_LIST);
              },2000)
            }
      
          }
          else {
            showNotifyWindow("show", "error", ADD_ERROR);
          }
       
        });
      }      

    
    function resetForm() {
        setTask_title('');
        setTask_due_date('');
        setStatus(1);
        setPriority(1);
        setDescription('');
        setTask_link_type(1); 
        setSelectedValues([]);
        setTaskLinkListAdded({"claim":[],"patient":[]});
        setOptionValues([]);
    }

    function openTaskModalWindow() {
        setShowTaskLinkModal(true);
    }

    function RedirectBack() {
        history.push(staticConfigs.ROUTE_MY_TASKS_LIST);
        dispatch(resetTaskDetails());
    }

    function showNotifyWindow(action, type, desc, age = 3000) {
        if (action === "show") {
            setTimeout(() => {
                setShowNotify("hide");
            }, age);
        }
        setShowNotify(action);
        setNotifyType(type);
        setNotifyDescription(desc);
    }
  
  let calledFromPLedger = props?.calledFrom && props?.calledFrom==="patientLedger" ? true : false;  

  return (
    <div className='col-md-7'>
        <div className={calledFromPLedger ? ' pb-4 ' : 'box basic-info-padding bordering border-radius-8 padding-left25 padding-right25 pb-5'} >
        <Notify showNotify={showNotify} setShowNotify={setShowNotify} notifyDescription={notifyDescription} setNotifyType={setNotifyType} setNotifyDescription={setNotifyDescription} notifyType={notifyType} />
            <div className='row'>
                  <div className='col-12 relative'>
                      <div className='form-group mt-4'>
                          <TextInput type='text' required={true} name={'task_title'} id={'Task-Title'} value={task_title} onValueChange={onHandleTaskChange} label={i18n.t("task.taskScreen.task_title")} />
                      </div>
                    {task_title!=="" && task_title?.length!==0 && <IconButton
             className="taskIconbtn_close mr-1"
              onClick={()=>setTask_title('')} >
                    <CloseIcon sx={{ fontSize: "small",color:'#9093a4' }} />
                    </IconButton>}
                </div>
            </div>
            <div className='row'>
                <div className='col-4'>
                        
                      <CalendarInput  required={true} minDate={new Date()} selected={task_due_date} id={'Due Date'} name={'due_date'} onValueChange={(e)=>onHandleTasDatekChange(e,'task_due_date')} label={i18n.t("task.taskScreen.due_date")} />
                </div>
                <div className='col-4'>
                      <SelectInput data={TASK_STATUS} name={'status'} id='status-id' role='list-box' value={status} onValueChange={onHandleTaskChange} label={i18n.t("task.taskScreen.status")}
                     selectOptionRemove={true} required={true}
                    />
                </div>
                <div className='col-4'>
                      <SelectInput data={TASK_PRIORITIES} name={'priority'} id='priority-id' value={priority} onValueChange={onHandleTaskChange} label={i18n.t("task.taskScreen.priority")}
                    selectOptionRemove={true} required={true}
                    />
                </div>
            </div>
            <div className='row'>
                <div className='col-12'>
                      <TextAreaInput rows={5} name={'description'} id='description' required={true} value={description} onValueChange={onHandleTaskChange} label={i18n.t("task.taskScreen.description")} />
                </div>
            </div>
            <div className='row mt-4'>
                <div className='col-12'>
                    <div className='task_linkBox_head'>
                        {i18n.t("task.taskScreen.task_links")}
                        {checkPermission(permission_key_values_tasks.can_modify_task) && <Link to='#' data-testid='linkAdd' onClick={openTaskModalWindow}>{i18n.t("task.taskScreen.add_link")}</Link>}
                    </div>
                    { 
                    Object.values(taskLinkListAdded.patient).concat(Object.values(taskLinkListAdded.claim)).length == 0 ?
                            <div className='task_link_box_body'>
                                <span className='danger'>{i18n.t("task.taskScreen.no_link_available")}</span>
                            </div> 
                        : 
                        Object.values(taskLinkListAdded.patient).concat(Object.values(taskLinkListAdded.claim)).map((item)=>{
                            return(
                                <div className='task_link_box_body' data-testid='task-link-box' key={item.id}>
                                    <label><i className='fas fa-user mx-2'></i>{item.name+(item?.custom_claim_id ? ' - '+item.custom_claim_id : '')}</label>
                                    <span onClick={()=>onRemoveItem(item.id, item.name)}>
                                       {checkPermission(permission_key_values_tasks.can_modify_task) &&<i className='fas fa-trash-alt mr-2'></i>}
                                        </span>
                                </div>
                            )
                        })
                    }
                </div>
            </div>
            <div className='row mt-4'>
                <div className='col-12'>
                    <div className="form-group">
                       {checkPermission(permission_key_values_tasks.can_modify_task) &&
                        <MultiSearch onSearchApi={onSearchContact} 
                            optionItems={optionItems} setOptionItems={setOptionItems}
                            setOptionValues={setOptionValues} dataList={userList}
                            selectedValues={selectedValues}
                            label={i18n.t("task.taskScreen.to")}
                            name={"ToContacts"} required={true}
                              optionValues={optionValues}
                              setSelectedItems={setSelectedItems}
                        />}
                    </div>
                </div>
            </div>
            <div className='row mt-4'>
                <div className='col-12 justify-right'>
                    {props?.calledFrom && props?.calledFrom==="patientLedger" ? null : <button type='button' onClick={RedirectBack} className='btn btn-secondary mr-3'>{i18n.t("task.taskScreen.cancel")}</button>}
                    {checkPermission(permission_key_values_tasks.can_modify_task,permission_key_values_tasks.task_add)  && 
                      <button type='button' onClick={onSaveTask} className='btn btn-primary-blue'>{i18n.t("task.taskScreen.save")}</button>}
                </div>
            </div>
            <div className=''>
                <CustomizedDialogs submit={false} header={'Add Task Link'} showModal={showTaskLinkModal} 
                setShowModalWindow={setShowTaskLinkModal}>
                    <form id="form_dataEntry" autoComplete="off">
                    <div className=''>
                        <SelectInput data={calledFromPLedger ? LINK_TYPE_CLAIM_ONLY : LINK_TYPE} name={'task_link_type'} value={task_link_type} onValueChange={onHandleTaskChange} 
                        label={i18n.t("task.taskScreen.task_link_type")} selectOptionRemove={true}
                        />
                    </div>
                    <div className='row mt-5'>
                        <div className='col'>
                            <AsyncTypeInput 
                                id={"taskLink"}
                                labelKey={"label"}
                                name={"label"}
                                minLength={1}
                                onValueChange={onHandleTaskChange}
                                options={searchLinkOptions}
                                label={i18n.t("task.taskScreen.task_label")}
                                onSearch={onSearchLinkType}
                            />
                        </div> 
                    </div>
                    <div className=''>
                        { task_link_type === 2 &&
                        <div className='row'>
                            <div className="col-1 max-width-3">
                                <div className="form-group">
                                    <div className="custom-checkbox lh-1">
                                        <input type="checkbox" id="search_inactive_patient" name="search_inactive_patient"
                                            // checked={} onChange={onHandleChange}
                                        />
                                        <label className="checkbox" htmlFor="search_inactive_patient">Search Inactive Patients</label>
                                    </div>
                                </div>
                            </div>
                            <div className='col-4'>
                                <label className="margin-top5" htmlFor="search_inactive_patient">{i18n.t("task.taskScreen.searchInactivePatient")}</label>
                            </div>
                        </div>
                        }
                        <div className=''>
                            <div className='mt-4 font-bold' style={{position: 'relative', top: "2px"}}>{i18n.t("task.taskScreen.las10Opened")}</div>
                            {task_link_type==1 ? 
                            <LinkTypeTable task_link_type={task_link_type} claimHeader={claimHeader} claimList={claimList} onAddTaskLinkList={onAddTaskLinkList} /> : 
                            <LinkTypeTable task_link_type={task_link_type} patientHeaders={patientHeaders} patientList={patientList} onAddTaskLinkList={onAddTaskLinkList}/>}
                        </div>
                    </div>
                    </form>
                </CustomizedDialogs>  
            </div>
        </div>
    </div>
  )
}

export default Tasks