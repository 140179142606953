import format from 'date-fns/format';
import { ACTIVE_STATUS, defaultIdForICD } from '../../../utilities/dictionaryConstants';
import { DEFAULT_COUNTRY, DEFAULT_PAGING_SIZE, PAGING_END_INDEX, TAB1 } from '../../../utilities/staticConfigs';
/**procedure structure */
export const procedure = {
    id: `New-${crypto.randomUUID()}`,
    patient_death_date: "",
    dos_from: "",
    dos_to: "",
    pos_id: "",
    pos_id_List: [],
    emg: 2,
    cpt_hcpcs: "",
    cpt_hcpcsList: [],
    m1_id: [],
    m2_id: "",
    m3_id: "",
    m4_id: "",
    m1List: [],
    m2List: [],
    m3List: [],
    m4List: [],
    dx_pointers: "",
    fee: "0.00",
    fee_units: "1",
    charges: "0.00",
    cpt_epsdt: 2,
    proced_description: "",
    line_note: "",
    drug_code: "",
    drug_unit_type: "",
    drug_unit_value: "",
    measurement_unit_type: "",
    measurement_unit_value: "",
    anesthesia: "",
    responsibility_type: "",
    payment_doneList: []
};

export const initialState = {
    isLoading: false,
    isTableLoading: true,
    isSearchTableLoading:false,
    dataLoading: false,
    apiError: "",
    activeParentTab: 'last10',
    activeSubTab:TAB1,
    claimParentTabs: [],
    openTabPks: [],
    claimSearchQuery: "",
    last10Table: {
        initialOrderingClaimId: true,
        initialOrderingDos: true,
        initialOrderingBillDate: true,
        initialOrderingPatientName: true,
        orderType: "",
        sortField: "",
        tableData: []
    },
    searchClaimsTable: {
        initialOrderingClaimId: true,
        initialOrderingDos: true,
        initialOrderingBillDate: true,
        initialOrderingPatientName: true,
        patientPk: "",
        patientSelected: [],
        patientAdvanceSearchData: [],
        searchByClaimId: "",
        activeStatus: ACTIVE_STATUS.find((item) => item.name == "Active")?.id,
        dosFrom: null,
        dosTo: null,
        claimEnteredFrom: null,
        claimEnteredTo: null,
        pageSize: DEFAULT_PAGING_SIZE,
        page: 1,
        orderType: "",
        sortField: "",
        totalPages: 0,
        startIndex: 0,
        endIndex: PAGING_END_INDEX,
        tableData: []
    },
    tabsOpen: 0,
    selectedClaimType: "",
    claimPK: "",
    claim_pk: "",
    diagnosis_pk: "",
    billing_info_pk: "",
    addition_info_pk: "",
    patient_PK: "",
    professionalClaim: [],
    professionalClaimDetails: {
        "id": "", "pks": {
            "billing_info_pk": "", "diagnosises_pk": "", "procedures_pks": [], "additional_claim_info_pk": ""
        },
        "custom_claim_id": "", "reference_number": "", "posted_date": format(new Date(), 'yyyy-MM-dd'), "service_from_date": format(new Date(), 'yyyy-MM-dd'),
        "service_to_date": format(new Date(), 'yyyy-MM-dd'), "authorization_referral": "", "claim_type": 1, "patient_id": "", "patient_name": "",
        "claim_status": "", "claim_sub_status": "", "identifier": "", "patient": "",
        "locked_by_name": "", "claim_inactive": false, "claim_editable": true, "claim_locked": false, "active": true,"identifierData":[]
    },
    billingInfo: [],
    billingInfoDetails: {
        "pk": "", "accept_assignments": 1, "patient_signature": 1,
        "insured_signature": 1, "claim": "", "billing_provider": "",
        "rendering_provider": "", "supervising_provider": "", "referring_provider": "",
        "ordering_provider": "", "service_location": "", "service_location_value": "",
        "primary_insurance": "", "secondary_insurance": "", "tertiary_insurance": "",
        "insurer_relation": "", "claim_fequency": 1, "original_claim_id": "",
        "claim_reference": "", "payer_info": [], "added_insurance_info": [],
        "payerInsuranceList": [],"primaryInsuranceDataList": [],"secondaryInsuranceDataList": [],
        "tertiaryInsuranceDataList": [], "primarypolicyId": "", "insurancePrimary": "", "secondarypolicyId": "",
        "insurancesecondary": "","tertiarypolicyId": "","insuranceTertiary": "",
    },
    serviceLineData: [],
    serviceLineDetails: {
        "icd": defaultIdForICD,
        "diagnosis1": "", "diagnosis2": "", "diagnosis3": "", "diagnosis4": "", "diagnosis5": "", "diagnosis6": "",
        "diagnosis7": "", "diagnosis8": "", "diagnosis9": "", "diagnosis10": "", "diagnosis11": "", "diagnosis12": "",
        "diagnosis1List": [], "diagnosis2List": [], "diagnosis3List": [], "diagnosis4List": [], "diagnosis5List": [], "diagnosis6List": [],
        "diagnosis7List": [], "diagnosis8List": [], "diagnosis9List": [], "diagnosis10List": [], "diagnosis11List": [], "diagnosis12List": [],
        "procedures": [],"responsibility_type":[],"claim_status":[],"claim_sub_status":[]
    },
    diagnosisValidation: {
        "diagnosis1": "", "diagnosis2": "", "diagnosis3": "", "diagnosis4": "", "diagnosis5": "", "diagnosis6": "",
        "diagnosis7": "", "diagnosis8": "", "diagnosis9": "", "diagnosis10": "", "diagnosis11": "", "diagnosis12": ""  
    },
    additionalClaimData: [],
    additionalClaimDetails: {
        "id": "", "employment": 2, "other_accident": "", "accident_date": "", "last_menstural_date": "",
        "initial_treatment_date": "", "last_seen_date": null, "unable_work_from_date": "",
        "unable_work_to_date": null, "hospitalization_from_date": "", "hospitalization_to_date": null,
        "assumed_care_date": null, "disability_begin_date": null, "disability_end_date": null,
        "relinquished_date": null, "mammography_cert_number": "", "care_plan_number": "",
        "service_authorization_code": "", "clia_number": "", "ide_number": "", "project_demonstration_id": "",
        "special_program_code": "", "patient_homebound": "", "claim_delay_reason": "",
        "claim_note": "", "lab_sales_rep": "", "claim_service_type": "", "clinic_site_name": "",
        "accession_number": "", "ambulance_claim": 2, "weight": "",
        "distance": "", "return_trip_reason": "", "stretcher_reason": "", "certification": null,
        "condition_indicator": {
            "patient_admitted_hospital": false, "patient_moved_by_stretcher": false, "patient_unconscious_shock": false,
            "patient_transported_emergency": false, "patient_physically_restrained": false, "patient_visible_haemorrhaging": false,
            "ambulance_service_medical_necessary": false, "patient_confined_bed_chair": false,
        },
        "lab_notes": "",
        "claim": "", "accident": "", "state": "", "transport_reason": null,
        "pickup_location_data": {
            "street1": "", "street2": "", "city": "", "state": "", "country": DEFAULT_COUNTRY, "zip_code": ""
        },
        "dropoff_location_data": {
            "street1": "", "street2": "", "city": "", "state": "", "country": DEFAULT_COUNTRY, "zip_code": ""
        },
        "transport_code": ""
    },
    open_pks: [],
    pks:[],
    insuranceLoading: false,
    ModifiersValidated: [],
    inputM1Validated: [],
    inputM2Validated: [],
    inputM3Validated: [],
    inputM4Validated: [],
    checkForDuplicatediagnosis: false,
    diagnosisesValidated:true,
    CallInsurance: false,
    infoLoaded: false,
    patientSelectedData: [],
    patientAdvanceSearchData: [],
    selectedPatient: "",
    insuranceCompleted: false,
    serviceLocationPosList: [],
    notifyMessage: "",
    showNotification: false,
    statusTag:"",
    identifierData: [],
    isClose: false,
    alertMessage: "",
    alertModalOpen: false,
    patientAlertModal:false,
    saveConfirmation: false,
    saveCloseConfirmation: false,
    inactiveClaimModal: false,
    activeClaimModal: false,
    patientHistoryModal:false,
    formWarningMessage: "",
    formWarningData: [],
    formWarningStatus: false,
    savedClaimDetails: {
        "claimId": "",
        "claimPK": "",
        "isClose": false,
        "isSave":false
    },
    patientHistoryICD: [],
    patientHistoryCPT:[],
    claimSubStatus:[]
}