import api from "../../../service/api";

const getStateList = async (year) => {
  let fullPath = `super-admin/get-states/?year=${year}`;
  return api.get(fullPath);
};
const getLocalityList = async (year, stateCode) => {
  let fullPath = `super-admin/get-locality/?year=${year}&state_code=${stateCode}`;
  return api.get(fullPath);
};
const getStandardFeeList = async (
  year,
  stateCode,
  locality,
  pageSize,
  page
) => {
  let fullPath = ''
  if (stateCode && year && locality)
  {
    fullPath = `super-admin/standard-fee-list/?year=${year}&state_code=${stateCode}&locality=${locality}&page_size=${pageSize}&page=${page}`;
  }
  else if (stateCode && year)
  {
    fullPath = `super-admin/standard-fee-list/?year=${year}&state_code=${stateCode}&page_size=${pageSize}&page=${page}`;
  }
  else
  {
    fullPath = `super-admin/standard-fee-list/?year=${year}&page_size=${pageSize}&page=${page}`;
  }
  return api.get(fullPath);
};
const getFeeFiles = async (practiceId, pageSize, page) => {
  let fullPath = `practice/custom-fee-files/?practice_pk=${practiceId}&page_size=${pageSize}&page=${page}`;
  return api.get(fullPath);
};
const getFeeFileById = async (fileId, pageSize, page) => {
  let fullPath = `practice/custom-fee-list/?id=${fileId}&page_size=${pageSize}&page=${page}`;
  return api.get(fullPath);
};
const deleteFeeSchedules = async (deleteIds) => {
  let fullPath = `practice/custom-fee-modify/?deletion_ids=[${deleteIds}]`;
  return api.destroy(fullPath);
};
const deleteCustomSchedules = async (deleteIds) => {
  let fullPath = `practice/custom-fee-files/${deleteIds}`;
  return api.destroy(fullPath);
};
const updateEditedCustomSchedules = async (body) => {
  let fullPath = `practice/custom-fee-modify/`;
  return api.put(fullPath, body);
};
const addCustomSchedules = async (body) => {
  let fullPath = `practice/custom-fee-schedule/`;
  return api.post(fullPath, body);
};
const postCSVFile = async (body) => {
  let fullPath = `practice/import-custom-fee/`;
  return api.postUpload(fullPath, body);
};

const ImportStandardFee = async (data) => {
  let path = 'super-admin/import-fee-schedule/'
  return api.post(path, data);
}

export default {
  getStateList,
  getLocalityList,
  getStandardFeeList,
  postCSVFile,
  getFeeFiles,
  getFeeFileById,
  deleteCustomSchedules,
  deleteFeeSchedules,
  updateEditedCustomSchedules,
  ImportStandardFee,
  addCustomSchedules
};
