import React from 'react';
import './style.css';
import i18n from '../../../utilities/i18n';
import PasswordManagement from './passwordManagement';

const ChangePasswordComponent = () => {

return (
        <>

        	<div className="center-div white-bg">
		        <PasswordManagement type="reset" header={i18n.t('changePassword.header.title')}/>
	       </div>
        </>
    )
}

export default ChangePasswordComponent