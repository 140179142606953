import i18n from "../../../utilities/i18n";


const TableHeadData = [
    {
        "name": i18n.t("patientPages.family.patientName"),
        "type": "number",
        "width": "medium",
        "sort": false,
    },
    {
        "name": i18n.t("patientPages.family.patientId"),
        "type": "string",
        "width": "small",
        "sort": false,
    },
    {
        "name": i18n.t("patientPages.family.dob"),
        "type": "Date",
        "width": "small",
        "sort": false,
    },
    {
        "name": i18n.t("patientPages.family.patientType"),
        "type": "string",
        "width": "small",
        "sort": false,
    },
    {
        "name": i18n.t("patientPages.family.insurance"),
        "type": "string",
        "width": "medium",
        "sort": false,
    },
    {
        "name": i18n.t("patientPages.family.select"),
        "type": "string",
        "width": "checkbox",
        "sort": false,
    },
];
 
export const TableBodyData = [
    [
        {
            "id": "",
            "name": "name",
            "value": "",
            "type": "string",
            "align": "left",
            "width": "medium",
        },
        {
            "id": "",
            "name": "custom_patient_id",
            "value": "",
            "type": "string",
            "align": "left",
            "width": "small",
        },
        {
            "id": "",
            "name": "date_of_birth",
            "value": "",
            "type": "Date",
            "needDateFormat": "yes",
            "align": "center",
            "width": "small",
        },
        {
            "id": "",
            "name": "patient_type",
            "value": "",
            "type": "string",
            "align": "left",
            "width": "small",
        },
        {
            "id": "",
            "name": "insurance_name",
            "value": "",
            "type": "string",
            "align": "left",
            "width": "medium",
        },
        {
            "id": "",
            "name": "",
            "value": "",
            "type": "plusButton",
            "align": "center",
            "width": "checkbox",
        },
    ]
]

export const PatientFamilyTableData = {
    "name": "simpleTable",
    "isResize": true,
    "isFixedHead": true,
    "tableHeadings": TableHeadData,
    "tableBodyData": TableBodyData
}
