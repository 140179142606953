import React from "react";
import BootStrapCardWithHeader from "../ReportBuilder/BootStrapCardWithHeader";
import TableHolderCard from "./TableHolderCard";

const DUMMY_FAV = [
  { category: "PATIENTS", report: "All Patients" },
  { category: "CLAIMS", report: "All Claims" },
];

const RecentReports = () => {
  const recents= DUMMY_FAV;
  return (
    <div className="row mt-4">
      <div className="col-12">
        <BootStrapCardWithHeader cardTitle={"Recently Ran"}>
          <TableHolderCard items={recents} />
        </BootStrapCardWithHeader>
      </div>
    </div>
  );
};

export default RecentReports;
