import api from '../../../service/api';

const ListTaxonomies = async(pageSize, page, searchValue, orderingField, orderType) => {
	let path = 'super-admin/taxonomy/?page_size='+ pageSize + '&page='+ page;
    if (searchValue)
        path += '&search='+ searchValue;
    if(orderingField){
        let fieldOrder = '';
        if (orderType) fieldOrder+= orderType;
        fieldOrder += orderingField;
        path += '&column='+fieldOrder;   
    } else {
        path += '&column=name';
    }
	return api.get(path);
}

const ImportCSVData = async (data) => {
	let path = 'super-admin/import-taxonomy/'
	return api.post(path,data);
}

export default {ListTaxonomies, ImportCSVData}