import i18n from "../../../utilities/i18n";


export const UserProfileBoxTableBodyOnly = [
    [
        {
            "id": "",
            "name": "name",
            "value": "",
            "type": "string",
            "align": "left",
            "width": "medium",
            "clickOnRowCondition": ""
        },
        {
            "id": "",
            "name": "custom_practice_id",
            "value": "",
            "type": "string",
            "align": "left",
            "width": "small",
            "clickOnRowCondition": ""
        },
        {
            "id": "",
            "name": "address",
            "value": "",
            "type": "string",
            "align": "left",
            "width": "medium",
            "clickOnRowCondition": ""
        },
        {
            "id": "",
            "name": "npi",
            "value": "",
            "type": "string",
            "align": "left",
            "width": "small",
            "clickOnRowCondition": ""
        },

    ]
]

export const UserProfileBoxTableObject = {
    "name": "clickOnRowsTable",
    "isResize": true,
    "isFixedHead": false,
    "noRecordsMsg": i18n.t('commons.noRecords'),
    "tableHeadings": [
        {
            "name": i18n.t("switchPractice.practiceName"),
            "type": "string",
            "width": "medium",
            "sort": false
        },
        {
            "name": i18n.t("switchPractice.practiceId"),
            "type": "string",
            "width": "small",
            "sort": false
        },
        {
            "name": i18n.t("switchPractice.address"),
            "type": "string",
            "width": "medium",
            "sort": false
        },
        {
            "name": i18n.t("switchPractice.npi"),
            "type": "string",
            "width": "small",
            "sort": false
        },
    ],
    "tableBodyData": UserProfileBoxTableBodyOnly
}

