import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from "react-redux";

import { PowerBIEmbed } from 'powerbi-client-react';
import { models } from 'powerbi-client';

import { getPowerBiEmbedToken } from './service';
import { setCommonState } from '../../store/CommonStateSlice';
import Notify from '../commons/notify';
import i18n from '../../utilities/i18n';

const DashboardComponent = () => {
    const dispatch = useDispatch();
    const dashboardData = useSelector((state) => state.commonStates.dashboardEmbedData);

    /** NOTIFY CONFIG */
    const [showNotify, setShowNotify] = useState('hide');
    const [notifyDescription, setNotifyDescription] = useState('');
    const [notifyType, setNotifyType] = useState('success');

    function showNotifyWindow(action, type, desc, age = 3000) {
        if (action === 'show') {
            setShowNotify(action);
            setNotifyType(type);
            setNotifyDescription(desc);
            setTimeout(() => {
                setShowNotify('hide');
            }, age);
        }
    }
    /** NOTIFY CONFIG ENDS HERE */
    /****************************/

    useEffect(() => {
        const fetchToken = async () => {
            if (!dashboardData?.embed_token?.token || new Date(dashboardData?.embed_token?.expiration).getTime() < Date.now()) {
                const response = await getPowerBiEmbedToken();

                if (response?.data?.embed_token?.token) {
                    dispatch(setCommonState({ name: "dashboardEmbedData", value: response.data }));
                    localStorage.setItem("dashboardEmbedData", JSON.stringify(response.data));
                } else {
                    dispatch(setCommonState({ name: "dashboardEmbedData", value: null }));
                    localStorage.removeItem("dashboardEmbedData");
                    showNotifyWindow("show", "error", i18n.t("commons.dashboardPowerBiTokenError"))
                }
            }
        }

        fetchToken();
    }, [dispatch, dashboardData]);


    return (
        <React.Fragment>
            <Notify showNotify={showNotify} setShowNotify={setShowNotify} notifyDescription={notifyDescription} setNotifyType={setNotifyType} setNotifyDescription={setNotifyDescription} notifyType={notifyType} />
            <div className="dashboard dash_width">
                <div className="container">
                    <div className="col-md-12">
                        {
                            dashboardData?.embed_token?.token &&
                            <PowerBIEmbed
                                embedConfig={{
                                    type: 'report',
                                    /** @description IN THE BELOW THE REPORT NAME IS EXPLICITLY PASSING HERE;
                                     *  @todo THIS SHOULD TAKE FROM THE ENV IN THE FUTURE WHEN WE WILL HAVE MULTIPLE REPORTS AGAINST THE ENVIRONMENTS IN THE POWER BI CONSOLE
                                     */
                                    id: dashboardData?.embed_url_response?.value?.find((item) => item.name === "SLA_Migration")?.id,
                                    embedUrl: dashboardData?.embed_url_response?.value?.find((item) => item.name === "SLA_Migration")?.embedUrl,
                                    accessToken: dashboardData?.embed_token?.token ?? '',
                                    tokenType: models.TokenType.Embed,
                                    settings: {
                                        panes: {
                                            filters: {
                                                expanded: false,
                                                visible: true
                                            }
                                        },
                                    }
                                }}
                                eventHandlers={
                                    /* eslint-disable */
                                    new Map([
                                        ['loaded', function () { console.log('Report loaded'); }],
                                        ['rendered', function () { console.log('Report rendered'); }],
                                        ['error', function (event) { showNotifyWindow("show", "error", i18n.t("commons.dashboardPowerBiTokenError")); }]
                                    ])
                                    /* eslint-enable */
                                }
                                cssClassName={"Embed-container"}
                                getEmbeddedComponent={(embeddedReport) => {
                                    window.report = embeddedReport;
                                }}
                            />
                        }
                    </div>
                </div>
            </div>
        </React.Fragment>
    )

}

export default DashboardComponent;
