import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
    Chip,
    Paper,
    Tooltip,
    Typography,
    Autocomplete,
    Box,
    Checkbox,
    TextField,
    Popper
} from '@mui/material';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';

import { getStorage } from '../../utilities/browserStorage';
import service from './service';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

/**
 * RemitanceCodeMultiSelect
 * @param {string} key - Required. A key prop.
 * @param {string} name - Required. HTML name.
 * @param {function} onValueChange - Required. Callback function to trigger when value changes.
 * @param {Array} selectedValues - Required. An array of current selection.
 * @param {boolean} [disabled=false] - Optional. Whether the component is disabled or not.
 * @returns {JSX.Element} An MUI Autocomplete multi-select component with remittance code options.
 */

const RemitanceCodeMultiSelect = ({ key, name, onValueChange, selectedValues, disabled }) => {
    const practicePK = getStorage('practice');
    const [remittanceOptions, setRemittanceOptions] = useState([]);
    const [isFocused, setIsFocused] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [isHovered, setIsHovered] = useState(false);


    const onChange = (values) => {
        onValueChange(values);
    }

    /**
     * On Component mount clone  the selected values array to the options variable
     * so that for the selected values will have its respective option visible in the option suggestion box
     */
    useEffect(() => {
        if (selectedValues?.length > 0 && remittanceOptions?.length === 0) {
            setRemittanceOptions(selectedValues);
        }
    }, [selectedValues?.length]);

    const SearchRemittances = (event) => {
        if (event.length > 1) {
            setIsLoading(true);
            service.SearchRemittanceRemarkCode(practicePK, event).then((response) => {
                const anArray = response.data.map((item) => ({
                    id: item.id,
                    name: item.name,
                    checked: false,
                    description: item.description,
                }));

                const combinedArray = [...anArray, ...selectedValues];
                const uniqueArray = combinedArray.filter((item, index) => {
                    return combinedArray.findIndex(obj => obj.id === item.id) === index;
                });

                setRemittanceOptions(uniqueArray);
            }).catch(error => {
                console.error(error);
            });
            setIsLoading(false);
        }
    };

    return (
        // Render selected values in tooltip when hovering over the field or when focused
        <Tooltip
            title={
                <Box>
                    {selectedValues?.map((item, i) => (
                        <Typography key={i} variant="body2">
                            {item.name}
                        </Typography>
                    ))}
                </Box>
            }
            arrow
            placement="right"
            open={(isFocused || isHovered) && selectedValues?.length > 0}
        >
            {/* Triggering tooltip render while disabled */}
            <div
                onMouseEnter={() => {
                    if (disabled) {
                        setIsHovered(true);
                    }
                }}
                onMouseLeave={() => {
                    if (disabled) {
                        setIsHovered(false);
                    }
                }}
            >
                <Autocomplete
                    key={key}
                    multiple
                    id="remittanceOptions-remarks-code-selector"
                    name={name}
                    size='medium'
                    options={remittanceOptions}
                    loading={isLoading}
                    noOptionsText={'Could not find any remittance codes, Type at least 2 characters to search'}
                    openOnFocus
                    disableCloseOnSelect
                    getOptionLabel={(option) => option?.name}
                    value={selectedValues} // An array of selected values as in the exact data structure of options
                    onChange={(event, newValue) => {
                        if (!disabled) {
                            onChange(newValue);
                        } else {
                            return
                        }
                    }}
                    onInputChange={(event, inputString) => { // Call back to trigger api on type search 
                        SearchRemittances(inputString);
                    }}
                    PopperComponent={({ children, ...props }) => (
                        <Popper {...props} placement="bottom-start">
                            <Paper elevation={4}>
                                {children}
                            </Paper>
                        </Popper>
                    )}
                    isOptionEqualToValue={(option, value) => option.id === value.id} // Matching the selected values in the options array
                    renderOption={(props, option, { selected }) => ( // Rendering the options box with option items
                        <Tooltip
                            key={option.id}
                            title={
                                <Box>
                                    <Typography variant="body2">
                                        {option?.description}
                                    </Typography>
                                </Box>
                            }
                            arrow
                            placement="left"
                        >
                            <li {...props}>
                                <Checkbox
                                    icon={icon}
                                    checkedIcon={checkedIcon}
                                    style={{ marginRight: 8 }}
                                    checked={selected}
                                />
                                {option.name}
                            </li>
                        </Tooltip>
                    )}
                    disabled={disabled}
                    renderTags={(value, getTagProps) =>
                        // Customizing and rendering the selected values in the field
                        // When focused, restricted rendering the selected values in the field so there'll be enough room for user to type and search
                        isFocused ? null : value.slice(0, 1).map((option, index) => (
                            <Tooltip
                                key={index}
                                title={
                                    <Box>
                                        <Typography variant="body2">
                                            {option.name}
                                        </Typography>
                                        {value.length > 1 && value.slice(1).map((item, i) => (
                                            <Typography key={i} variant="body2">
                                                {item.name}
                                            </Typography>
                                        ))}
                                    </Box>
                                }
                                arrow
                                placement="top"
                            >
                                <Chip
                                    sx={{ padding: 0, fontSize: '1em' }}
                                    {...getTagProps({ index })}
                                    label={`${option.name} ${value.length > 1 ? `+ ${value.length - 1} more...` : ''}`}
                                    deleteIcon={<></>}
                                />
                            </Tooltip>
                        ))
                    }
                    renderInput={(params) => (
                        <TextField
                            sx={{
                                '&& .MuiOutlinedInput-root': {
                                    padding: 0,
                                    minWidth: '150px',
                                    height: '35px',
                                    marginTop: '5px',
                                },
                                '&& .MuiIconButton-root': {
                                    padding: '4px !important',
                                },
                            }}
                            {...params}
                            onFocus={() => setIsFocused(true)}
                            onBlur={() => setIsFocused(false)}
                        />
                    )}
                />
            </div>
        </Tooltip>
    )
}

export default RemitanceCodeMultiSelect;


RemitanceCodeMultiSelect.propTypes = {
    key: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    onValueChange: PropTypes.func.isRequired,
    selectedValues: PropTypes.array.isRequired,
    disabled: PropTypes.bool,
};