import { TextField } from "@material-ui/core";
import IconButton from '@material-ui/core/IconButton';
import ClearIcon from '@material-ui/icons/Clear';
import './style.css'
import React, { useState } from "react";
import Label from "../Label";
const input = (props) => {
    const [patternFormat] = useState(props.pattern ? props.pattern : '.*');
    const required = props.required;
    const value = props.value;

    let defaultClassName = props.defaultClassName ? props.defaultClassName : '';
    return (
        <div className="form-group">
            <Label label={props.label} labelClassName={props.labelClassName ? props.labelClassName : ""} />
            < TextField
                style={{ width: "100%" }} id={props.id ? props.id : "standard-basic"} variant="outlined"
                required={required ? required : false}
                InputLabelProps={props.value ? { shrink: props.value ? true : false } : {}}
                type={props.type}
                name={props.name} placeholder={props.placeholder} value={props.value}
                onChange={props.onValueChange}
                onBlur={props.onValueBlur && props.onValueBlur}
                autoComplete="off"
                inputProps={{ pattern: patternFormat, maxLength: props.maxLength }
                }
                pattern={patternFormat}
                disabled={props.disabled ? props.disabled : false}
                tabIndex={props.tabIndex ? props.tabIndex : ''}
                className={defaultClassName ? defaultClassName : required ? (value ? "" : "input-error") : ""}
                onKeyDown={props.onKeyDown && props.onKeyDown}
                helperText={props.helperText}
                error={props.error ?? false}
                InputProps={{
                    // clearButton within the MUI TexField to clear the value
                    endAdornment: (
                        props.clearButton && props.value?.length >= 1 ?
                            (<IconButton onClick={props.clearInput} data-testid="clear-button" id={props.id ? props.id + "-clear-button" : 'clear-button'}>
                                <ClearIcon />
                            </IconButton>) :
                            ''
                    ),
                    autoComplete: props?.newPassword ? 'new-password' : ''
                }}
            />
        </div>
    )

}

export default input;