import React, { useState, useEffect } from 'react';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import i18n from '../../utilities/i18n';

const TableWarningPopUp = (props) => {
    const [description, setDescription] = useState([]);
    useEffect(() => {
        let desc = [];
        let formWarningData = props.formWarningData;
        formWarningData.map((item, index) => {
            if (item.tabName) {
                Object.keys(item).forEach((key) => {
                    if ((item[key] === '' || item[key] === null || item[key] === 'none') && (key !== 'tabName')) {
                        desc.push(<dt key={index} className="warning_label"><span className="dot margin-left5 mr-2"></span><label> {item.tabName} - {key}</label></dt>)
                    }
                })

            } else {
                Object.keys(item).forEach((key) => {
                    if ((item[key] === '' || item[key] === null || item[key] === 'none') && key !== 'tabName') {
                        desc.push(<dt key={index}><span className="dot margin-left5 mr-2"></span> {key}</dt>)
                    }
                })
            }


        })
        if (desc.length > 0) {
            description.splice(0, 0, <dt><span className="">{i18n.t("warning.followingMandatoryFields")} : </span></dt>)
        }
        setDescription(desc)
    }, [props.formWarningStatus])

    return (

            <OverlayTrigger
                placement="bottom-start"
                delay={{ show: 100, hide: 250 }}
                overlay={<div
                    {...props}
                    style={{
                        marginLeft: '0px',
                        marginTop: '2px',
                        zIndex: 80,
                        backgroundColor: '#ffbaba',/*#fbcd78*/
                        padding: '10px 10px 1px 10px',
                        color: '#606162',
                        borderRadius: 3,
                        border: "1px solid #ff6347",
                        ...props.style,
                    }}
                >
                    <dl className="margin-bottom0">{description}</dl>
                </div>}
            >
                <span>
                    <span className="cursor-pointer"><i className="fa fa-exclamation exclamationIcon"></i>
                    </span>
                </span>

            </OverlayTrigger>
    )
}



export default TableWarningPopUp;