import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import service from "../service";
const initialState = {
    taskDetails: {
        "id": "",
        "assignee": [
        ],
        "assigned_by": "",
        "task_title": "",
        "description": "",
        "short_description": "",
        "due_date": "",
        "status": 1,
        "priority": 1,
        "claim_tasks": [],
        "payment_tasks": [],
        "patient_tasks": [],
        "task_link_type":'',
    },
    loading:false
}

export const getTaskDetail = createAsyncThunk(
    "task/gettaskdetails",
    async (payload) => {
        const response = await service.GetTask(payload.pk,payload.id);
        return response.data ? response.data[0] : {};
    }
);


export const TaskSlice = createSlice({
    name: 'taskslice',
    initialState,
    reducers: {

        /**
         * update details on input field change
         * @param {*} state 
         * @param {*} actions 
         */
        updateTaskDetails: (state, actions) => {
            state.taskDetails = {
                ...state.taskDetails,
                [actions.payload.node]:actions.payload.value    
          }
        },

        /**
         * update task link to state
         * @param {*} state 
         * @param {*} actions 
         */
        updateTaskLinks: (state, actions) => {
            if (actions.payload.node == "patient_tasks") {
                state?.taskDetails?.patient_tasks?.push(actions.payload.value)
            } else if (actions.payload.node == "claim_tasks") {
                state?.taskDetails?.claim_tasks?.push(actions.payload.value)
            }
            else {
                state?.taskDetails?.payment_tasks?.push(actions.payload.value) 
            }
        },

        removeTaskLinkAdded: (state, actions) => {
            const index = state?.taskDetails?.patient_tasks?.findIndex(item=>item.patient_pk == actions.payload);
            if (index > 0) {
                state?.taskDetails?.patient_tasks?.splice(index, 1)
            }
            else {
                const index = state?.taskDetails?.claim_tasks?.findIndex(item => item.claim_pk == actions.payload); 
                state?.taskDetails?.claim_tasks?.splice(index, 1);
            }
        },

        resetTaskDetails: (state) => {
            state.loading = false;
            state.taskDetails = initialState.taskDetails;
        }

    },
    /**
     * handle api call here
     * @param {*} builder 
     */
    extraReducers: (builder) => {
        /**
         * api call pending state
         */
        builder.addCase(getTaskDetail.pending, (state) => {
            state.loading = true;
            state.taskDetails = initialState.taskDetails;
        })
        /**
         * api call success case
         */
        builder.addCase(getTaskDetail.fulfilled, (state, actions) => {
            state.loading = false;
            state.taskDetails=actions.payload
        })
        /**
         * api call error case here
         */
        builder.addCase(getTaskDetail.rejected, (state) => {
            state.loading = false;
            state.taskDetails = initialState.taskDetails;
        })
    }
});

export const { updateTaskDetails, resetTaskDetails, updateTaskLinks, removeTaskLinkAdded } = TaskSlice.actions
export default TaskSlice.reducer;
