import React from 'react'
import Chip from '@material-ui/core/Chip';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
    summaryChip: {
        display: 'flex',
        width: '75px',
        padding: '3px 0px',
        justifyContent: 'center',
        alignItems: 'center',
        gap: '10px',
        flexShrink: 0,
        borderRadius: '4px',
        background: 'var(--primary-p-2, #1479BB)',
        color: 'var(--grey-ff, #FFF)',
        fontFeatureSettings: "'clig' off, 'liga' off",
        fontFamily: 'Lato',
        fontSize: '12px',
        fontStyle: 'normal',
        fontWeight: 400,
        lineHeight: 'normal',
        '&:hover': {
            background: 'linear-gradient(0deg, rgba(0, 0, 0, 0.10) 0%, rgba(0, 0, 0, 0.10) 100%), #1479BB !important',
        },
    },
    processingLabelChip: {
        display: 'flex',
        padding: '3px 12px',
        justifyContent: 'center',
        alignItems: 'center',
        gap: '10px',
        borderRadius: '4px',
        background: 'rgba(36, 167, 224, 0.15)',
        color: 'var(--primary-p-2, #1479BB)',
        fontFeatureSettings: "'clig' off, 'liga' off",
        fontFamily: 'Lato',
        fontSize: '12px',
        fontStyle: 'normal',
        fontWeight: 400,
        lineHeight: 'normal',
    },
    noPayer: {
        display: 'flex',
        padding: '3px 12px',
        justifyContent: 'center',
        alignItems: 'center',
        gap: '10px',
        borderRadius: '4px',
        background: '#cfd8dc',
        color: 'var(--grey-g-800, #424242)',
        fontFeatureSettings: "'clig' off, 'liga' off",
        fontFamily: 'Lato',
        fontSize: '12px',
        fontStyle: 'normal',
        fontWeight: 400,
        lineHeight: 'normal',
    },
});

function Chips(props) {
    const classes = useStyles();

    return (
        <>
            {props.type === 'summary' && <Chip label="View" className={classes.summaryChip} clickable onClick={props.onClick} />}
            {props.type === 'processing' && <Chip label="Processing" className={classes.processingLabelChip} />}
            {props.type === 'noPayer' && <Chip label="No Payer Data" className={classes.noPayer} />}
        </>
    );
}

export default Chips;