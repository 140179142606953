import api from '../../../service/api'

const ListPracticesTypes = async(pageSize, page, searchValue) =>{
	let path = 'super-admin/practice-type/?page_size='+pageSize+'&page='+page
	if (searchValue) {
        path += `&search=${searchValue}`;
    }
	return api.get(path);
}

const AddPracticesType = async(data) =>{
	let path = 'super-admin/practice-type/'
	return api.post(path,data);
}
const GetPracticesType = async(practiceId) =>{
	let path = 'super-admin/practice-type/' + practiceId
	return api.get(path);
}

const UpdatePracticesType = async(practiceId, data) =>{
	let path = 'super-admin/practice-type/' + practiceId
	return api.put(path,data);
}

const DeletePracticesType = async(practiceId) =>{
	let path = 'super-admin/practice-type/' + practiceId
	return api.destroy(path);
}

export default {ListPracticesTypes, AddPracticesType, GetPracticesType, UpdatePracticesType, DeletePracticesType}