import React, { useState, useEffect, useContext } from 'react';
import { Form } from "react-bootstrap";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import { Link } from "react-router-dom";
import ReactHtmlParser from "react-html-parser";
import "./style.css";
import service from "../service";
import PatientsAdd from "../PatientsAdd";
import PatientsAdvancedSearch from "../PatientsAdvancedSearch";
import PatientDropDownSearch from "../patientDropDownSearch";
import { AdvSearchTableData, Last10TableData, TableBodyData } from "./PatientListTableData";
import Notify from "../../../commons/notify";
import Pagination from "../../../commons/pagination";
import RightSidePanel from "../../../commons/RightSidePanel/RightSidePanel";
import CustomizedDialogs from "../../../modalWindowComponent/CustomizedDialogs";
// import { MaterialMultiselect } from "../../../../MaterialMultiselect/MaterialMultiselect";
import LoadingContext from "../../../../container/loadingContext";
import i18n from "../../../../utilities/i18n";
import { getStorage } from "../../../../utilities/browserStorage";
import { csvxlsFileCheck } from "../../../../utilities/validations";


import {
  PAGING_END_INDEX,
  DEFAULT_PAGING_SIZE,
  MAX_OPEN_TABS_PATIENTS,
  PATIENT_MANDATE_LIST,
  DEFAULT_SELECTED_PATIENTS_TAB,
  DEFAULT_SELECTED_PATIENTS_TAB_PK,
} from "../../../../utilities/staticConfigs";
import Table from "../../../commons/Table/Table";
import {
  checkPermission,
  commonTableBody,
} from "../../../../utilities/commonUtilities";


import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import Grid from "@mui/material/Grid";
import Badge from "@mui/material/Badge";
import FolderIcon from "@mui/icons-material/Folder";

import CustomizedSmallDialogs from "../../../modalWindowComponent/CustomisedSmallDialog";
import { permission_key_values_patient } from "../../../../utilities/permissions";
import { useDispatch, useSelector } from 'react-redux';
import { resetPatientData } from '../../../../store/patient/patientInfoSlice';
import CommonButton from '../../../commons/Buttons';
import AddIcon from '@mui/icons-material/Add';

import {
  patienfFieldArray,
  PatientTableHead,columnsNameList1,
  columnsNameList2, columnsNameList3, columnsNameList4, columnsNameList5, columnsNameList6, defaultSelectedColumnsList,
} from "./constants"

import { styled } from '@mui/material/styles';
import TableHeaderSelector from '../../../commons/TableHeaderSelector/TableHeaderSelector';
import ExportPatientsListCheckBox from './ExportPatientsListCheckBox';

// Custom style for the indicator
const CustomIndicator = styled('span')(() => ({
  // Define your custom indicator styles here
  backgroundColor: 'white',
  height: 7,
  position: 'absolute',
  bottom: 0,
  width: 'calc(100% - 4px)',
  marginLeft: '2px'

}));

const StyledTabList = styled(TabList)`
  & .MuiTabs-indicator {
    background-color: transparent !important;
    height: 0px !important;
  }
`;
const Patientslist = (props) => {

  const { tableHeaderSelected } = useSelector((state) => state.patientAdvanceSearchData);
  // eslint-disable-next-line no-undef
  var fileDownload = require("js-file-download");
  const [activeTab] = useState("listLast10Patients");
  const [patientTypeList, setPatientTypeList] = useState([]);
  const [showAlertModalWindow, setShowAlertModalWindow] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [openedTabPKs, setOpenedTabPKs] = useState([]);
  const [advancedQuery, setAdvancedQuery] = useState([]);

  const setShowLoadingBar = useContext(LoadingContext);
  const [selectedPatients, setSelectedPatients] = useState([]);
  const [patientAdvList, setPatientAdvList] = useState([]);
  const [showAdvancedSearchTab, setShowAdvancedSearchTab] = useState(false);
  const practicePK = getStorage("practice")
    ? parseInt(getStorage("practice"))
    : "";
  const pageSize = DEFAULT_PAGING_SIZE;

  // TO HANDLE THIS PAGE IS CALLED FROM OTHER PAGES e.g. FROM USER LOG
  const [hrefPatientId, setHrefPatientId] = useState(
    props.location.state && props.location.state.selectedID
      ? props.location.state.selectedID
      : 0
  );
  const [hrefPatientIdReload, setHrefPatientIdReload] = useState(true);
  const [patientAdvSearchData, setPatientAdvSearchData] = useState([]);

  // //Excel Columns
  const [columnModalHeader, setColumnModalHeader] = useState("");
  const [columnModalOpen, setColumnModalOpen] = useState(false);
  const [exportType, setExportType] = useState("");
  const [isFieldsChecked, setIsFieldsChecked] = useState(false);
  const [isSelected, setIsSelected] = useState(false);
  const [selectedColumns, setSelectedColumns] = useState([]);

  const [patientList, setPatientList] = useState([]);

  // table column sorting
  const [orderType, setOrderType] = useState("");
  const [sortField, setSortField] = useState("");

  const [initialOrderingPatientName, setInitialOrderingPatientName] =
    useState(true);
  const [initialOrderingPatientId, setInitialOrderingPatientId] =
    useState(true);
  const [initialOrderingPatientDob, setInitialOrderingPatientDob] =
    useState(true);
  AdvSearchTableData.tableHeadings[0].initialOrdering =
    initialOrderingPatientName;
  AdvSearchTableData.tableHeadings[0].orderType = orderType;
  AdvSearchTableData.tableHeadings[3].initialOrdering =
    initialOrderingPatientId;
  AdvSearchTableData.tableHeadings[3].orderType = orderType;
  AdvSearchTableData.tableHeadings[5].initialOrdering =
    initialOrderingPatientDob;
  AdvSearchTableData.tableHeadings[5].orderType = orderType;

  const [optionalColumnsList] = useState({
    // defualt selected fields
    // note : backend dev , please make sure the key is not conflict with model filed while annotate
    full_name: "Patient Name", //default selected 1
    patient_gender: "Gender", //default selected 2
    dob: "Date of birth", //default selected 3
    practice_patient_type: "Patient type", //default selected 4
    street1: "Street1", //default selected 5
    street2: "Street2", //default selected 6
    city: "City", //default selected 7
    state: "State", //default selected 8
    patient_zip: "ZIP", //default selected 9
    home_phone: "Home Phone", //default selected 10
    p_insurance_name: "Primary Insurance Name", //default selected 11
    p_insurance_policy_id: "Primary Insurance Policy ID", //default selected 12
    s_insurance_name: "Secondary Insurance Name", //default selected 13
    s_insurance_policy_id: "Secondary Insurance Policy ID", //default selected 14
    t_insurance_name: "Tertiary Insurance Name", //default selected 15
    t_insurance_policy_id: "Tertiary Insurance Policy ID", //default selected 16
    // default nonselected fields
    suffix: "Suffix", //1
    dod: "Date of Death", //2
    ssn: "SSN", //3
    country: "Country", //4
    work_phone: "Work Phone", //5
    cell_phone: "Cell phone", //6
    email: "Email", //7
    ethnicity: "Ethnicity", //8
    race: "Race", //9
    language_preferred: "Language preferred", //10
    rendering_provider: "Default rendering provider", //11
    service_location: "Default service location", //12
    referring_provider: "Default referring provider", //13
    referring_provider_group: "Default referring provider group", //14
    guarantor_last_name: "Guarantor Last Name", //15
    guarantor_first_name: "Guarantor First Name", //16
    guarantor_middle_intial: "Guarantor Middle Intial", //17
    guarantor_suffix: "Guarantor Suffix", //18
    guarantor_relationship: "Guarantor Relationship", //19
    guarantor_street1: "Guarantor Street1", //20
    guarantor_street2: "Guarantor Street2", //21
    guarantor_city: "Guarantor City", //22
    guarantor_state: "Guarantor State", //23
    guarantor_zip: "Guarantor ZIP", //24
    guarantor_country: "Guarantor Country", //25
    guarantor_home_phone: "Guarantor Home Phone", //26
    guarantor_work_phone: "Guarantor Work Phone", //27
    guarantor_cell_phone: "Guarantor Cell phone", //28
    guarantor_email: "Guarantor Email", //29
    emergency_last_name: "Emergency Last Name", //30
    emergency_first_name: "Emergency First Name", //31
    emergency_middle_intial: "Emergency Middle Intial", //32
    emergency_suffix: "Emergency Suffix", //33
    emergency_relationship: "Emergency Relationship", //34
    emergency_street1: "Emergency Street1", //35
    emergency_street2: "Emergency Street2", //36
    emergency_city: "Emergency City", //37
    emergency_state: "Emergency State", //38
    emergency_zip: "Emergency ZIP", //39
    emergency_country: "Emergency Country", //40
    emergency_home_phone: "Emergency Home Phone", //41
    emergency_work_phone: "Emergency Work Phone", //42
    emergency_cell_phone: "Emergency Cell phone", //43
    emergency_email: "Emergency Email", //44
    p_insurance_group_number: "Primary Insurance Group Number", //45
    p_effective_date: "Primary effective date", //46
    p_term_date: "Primary Term Date", //47
    p_copay: "Primary Copay", //48
    p_coins: "Primary Coins", //49
    p_ded: "Primary Ded", //50
    p_out_of_pocket_max: "Primary Out of pocket max", //51
    p_subscriber_relationship: "Primary Subscriber Relationship", //52
    p_subscriber_Last_name: "Primary Subscriber Last name", //53
    p_subscriber_first_name: "Primary Subscriber first name", //54
    p_subscriber_middle_initial: "Primary Subscriber Middle initial", //55
    p_subscriber_suffix: "Primary Subscriber Suffix", //56
    p_subscriber_gender: "Primary Subscriber gender", //57
    p_subscriber_dob: "Primary Subscriber Date of birth", //58
    p_subscriber_street1: "Primary Subscriber Street1", //59
    p_subscriber_street2: "Primary Subscriber Street2", //60
    p_subscriber_city: "Primary Subscriber City", //61
    p_subscriber_state: "Primary Subscriber State", //62
    p_subscriber_zip: "Primary Subscriber Zip", //63
    p_subscriber_country: "Primary Subscriber Country", //64
    p_subscriber_phone: "Primary Subscriber Phone", //65
    s_insurance_group_number: "Secondary Insurance Group Number", //66
    s_effective_date: "Secondary effective date", //67
    s_term_date: "Secondary Term Date", //68
    s_copay: "Secondary Copay", //69
    s_coins: "Secondary Coins", //70
    s_ded: "Secondary Ded", //71
    s_out_of_pocket_max: "Secondary Out of pocket max", //72
    s_subscriber_relationship: "Secondary Subscriber Relationship", //73
    s_subscriber_Last_name: "Secondary Subscriber Last name", //74
    s_subscriber_first_name: "Secondary Subscriber first name", //75
    s_subscriber_middle_initial: "Secondary Subscriber Middle initial", //76
    s_subscriber_suffix: "Secondary Subscriber Suffix", //77
    s_subscriber_gender: "Secondary Subscriber gender", //78
    s_subscriber_dob: "Secondary Subscriber Date of birth", //79
    s_subscriber_street1: "Secondary Subscriber Street1", //80
    s_subscriber_street2: "Secondary Subscriber Street2", //81
    s_subscriber_city: "Secondary Subscriber City", //82
    s_subscriber_state: "Secondary Subscriber State", //83
    s_subscriber_zip: "Secondary Subscriber Zip", //84
    s_subscriber_country: "Secondary Subscriber Country", //85
    s_subscriber_phone: "Secondary Subscriber Phone", //86
    t_insurance_group_number: "Tertiary Insurance Group Number", //87
    t_effective_date: "Tertiary effective date", //88
    t_term_date: "Tertiary Term Date", //89
    t_copay: "Tertiary Copay", //90
    t_coins: "Tertiary Coins", //91
    t_ded: "Tertiary Ded", //92
    t_out_of_pocket_max: "Tertiary Out of pocket max", //93
    t_subscriber_relationship: "Tertiary Subscriber Relationship", //94
    t_subscriber_Last_name: "Tertiary Subscriber Last name", //95
    t_subscriber_first_name: "Tertiary Subscriber first name", //96
    t_subscriber_middle_initial: "Tertiary Subscriber Middle initial", //97
    t_subscriber_suffix: "Tertiary Subscriber Suffix", //98
    t_subscriber_gender: "Tertiary Subscriber gender", //99
    t_subscriber_dob: "Tertiary Subscriber Date of birth", //100
    t_subscriber_street1: "Tertiary Subscriber Street1", //101
    t_subscriber_street2: "Tertiary Subscriber Street2", //102
    t_subscriber_city: "Tertiary Subscriber City", //103
    t_subscriber_state: "Tertiary Subscriber State", //104
    t_subscriber_zip: "Tertiary Subscriber Zip", //105
    t_subscriber_country: "Tertiary Subscriber Country", //106
    t_subscriber_phone: "Tertiary Subscriber Phone", //107
  });

  //Pagination start
  const [totalPage, setTotalPage] = useState(1);
  const [activePage, setActivePage] = useState(1);
  const [startIndex, setStartIndex] = useState(0);
  const [endIndex, setEndIndex] = useState(PAGING_END_INDEX);
  const [showNotify, setShowNotify] = useState("hide");
  const [notifyDescription, setNotifyDescription] = useState("");
  const [notifyType, setNotifyType] = useState("success");
  const [showImportErrorModalWindow, setShowImportErrorModalWindow] =
    useState(false);
  const [importErrorMessage, setImportErrorMessage] = useState("");

  const [tabsOpen, setTabsOpen] = useState(0);
  const [validateData, setValidateData] = useState([]);
  const [value, setValue] = React.useState("listLast10Patients");
  const [clearSelectedPatient, setClearSelectedPatient] = useState(0);

  const tabChange = (event, newValue) => {
    let patientID;
    // ignore tab change if the close icon was clicked
    if (event.target.id === "closeTabIcon") {
      return;
    }

    // determine which tab was selected and perform the corresponding action
    switch (newValue) {
      case "listLast10Patients":
        setValue(newValue);
        addRemoveFromSelectedTab(
          {
            pk: "last10",
            type: "patients",
            action: "add",
            practice_pk: practicePK,
          },
          false
        );
        getPatientsData();
        break;
      case "listAdvSearchPatients":
        setValue(newValue);
        ListAdvancedPatients(DEFAULT_PAGING_SIZE, activePage);
        break;
      case "addNewPatient":
        setValue(newValue);
        addRemoveFromSelectedTab(
          {
            pk: "add_new",
            type: "patients",
            action: "add",
            practice_pk: practicePK,
          },
          false
        );
        break;
      default:
        // if the selected tab corresponds to a specific patient, extract the patient ID and add it to the selected tab list
        patientID = newValue ? newValue.split(" ")[1] : null;
        if (patientID) {
          setValue(newValue);
          addRemoveFromSelectedTab(
            {
              pk: Number(patientID),
              type: "patients",
              action: "add",
              practice_pk: practicePK,
            },
            false
          );
        }
        break;
    }
  };

  function resetSorting() {
    setInitialOrderingPatientDob(true);
    setInitialOrderingPatientId(true);
    setInitialOrderingPatientName(true);
    setOrderType('');
  }

  async function onSelectFieldNames(pTableSelecter) {
    // THE FIELD SELECTION CONTROL IS SAME FOR BOTH 'LAST10' & 'ADV. SEARCH RESULTS',
    let tmpTblData = [];
    tmpTblData = Last10TableData;
    let tblHead = [];
    let tblData = [];
    let dataColHideStatus = [];
    tmpTblData.tableHeadings.map((item) => {
      if (pTableSelecter.find((str) => str === item.name)) {
        // if table heading is available in pTableSelecter then its not hidden
        tblHead.push({ ...item, hideValue: false });
        dataColHideStatus.push(false);
      } else {
        // if table heading is not available in pTableSelecter then its not hidden
        tblHead.push({ ...item, hideValue: true });
        dataColHideStatus.push(true);
      }
    });

    let newRow = [];
    tmpTblData.tableBodyData.map((rowItem) => {
      newRow = [];
      rowItem.map((colItem, colIndex) => {
        colItem.hideValue = dataColHideStatus[colIndex];
        newRow.push(colItem);
      });
      tblData.push(newRow);
    });
    Last10TableData.tableHeadings = tblHead;
    Last10TableData.tableBodyData = tblData;

    newRow = [];
    tblData = [];
    AdvSearchTableData.tableBodyData.map((rowItem) => {
      newRow = [];
      rowItem.map((colItem, colIndex) => {
        colItem.hideValue = dataColHideStatus[colIndex];
        newRow.push(colItem);
      });
      tblData.push(newRow);
    });
    AdvSearchTableData.tableHeadings = tblHead;
    AdvSearchTableData.tableBodyData = tblData;
  }

  function onPagePrevious() {
    setShowLoadingBar(true);
    let previousPage = startIndex + 1 - PAGING_END_INDEX;
    setActivePage(previousPage);
    if (startIndex !== 0) {
      setStartIndex(startIndex - PAGING_END_INDEX);
      setEndIndex(endIndex - PAGING_END_INDEX);
    }
    ListAdvancedPatients(DEFAULT_PAGING_SIZE, previousPage);
  }

  function onPageUp(e) {
    setShowLoadingBar(true);
    let page = Number(e.target.id);
    setActivePage(page);
    ListAdvancedPatients(DEFAULT_PAGING_SIZE, page);
  }

  function onPageNext() {
    setShowLoadingBar(true);
    let nextPage = startIndex + 1 + PAGING_END_INDEX;
    if (endIndex === totalPage || totalPage <= PAGING_END_INDEX) {
      setActivePage(nextPage);
      setStartIndex(startIndex);
      setStartIndex(endIndex);
    } else {
      setActivePage(nextPage);
      setStartIndex(startIndex + PAGING_END_INDEX);
      setEndIndex(endIndex + PAGING_END_INDEX);
    }
    ListAdvancedPatients(DEFAULT_PAGING_SIZE, nextPage);
  }
  //Pagination ends

 async function getSelectedPatients() {
    setShowLoadingBar(true);
    try {
      const response = await service.GetSelectedTabs("patients", practicePK);
      if (response?.data?.data) {
        setSelectedPatients(response?.data?.data);
      }
      if (response?.data?.opened_tab_pks) {
        setOpenedTabPKs(response?.data?.opened_tab_pks);
      }
      // When there's no opened tab data in the server against user, then route to last 10 by default
      if (response?.data?.code === 204) {
        setSelectedPatients(DEFAULT_SELECTED_PATIENTS_TAB);
        setOpenedTabPKs(DEFAULT_SELECTED_PATIENTS_TAB_PK);
      }
    } catch (error) {
      console.error("Error fetching patients data:", error)
    } finally {
      setShowLoadingBar(false);
    }
  }


  useEffect(
    () => {
      let arrPTabs = [];
      if (openedTabPKs && Array.isArray(openedTabPKs)) {
        arrPTabs = openedTabPKs.filter((item) => parseInt(item, 10) > 0);
        setTabsOpen(arrPTabs ? arrPTabs.length : 0);
      } else {
        setTabsOpen(0);
      }
    },
    [openedTabPKs]
  );

  useEffect(() => {
    if (value !== "addNewPatient") {
      if (selectedPatients && selectedPatients.length > 0) {
        let openedTab = selectedPatients.find((element) => {
          return element.is_opend;
        });
        if (openedTab.pk === "last10") {
          setValue("listLast10Patients");
          getPatientsData("");
        } else if (openedTab.pk === "add_new") {
          setValue("addNewPatient");
        } else {
          setValue("editPatient " + openedTab.pk);
        }
      } else if (!selectedPatients.length) {
        setValue("listLast10Patients");
        getPatientsData("");
      }
    } else if (value === "addNewPatient") {
      setShowLoadingBar(false);
    }
  }, [selectedPatients]);

  function getPatientsData(sorting, fieldName, orderType) {
    setShowLoadingBar(true);
    let query =
      "?list_type=list&practice_pk=" +
      practicePK +
      "&page_size=" +
      pageSize +
      "&page=" +
      activePage +
      "&last10=" +
      true;

    if (sorting) {
      query += "&column=" + orderType + fieldName;
    }
    const result = service.ListPatients(query);
    result?.then((response) => {
      if (response?.data?.results?.length === 0)
        Last10TableData.tableBodyData = TableBodyData
      if (response?.data?.results !== undefined) {
        setTotalPage(Math.ceil(response.data.count / response.data.page_size));
      }
      if (response?.data?.results?.length > 0) {
        const rowArray = commonTableBody(
          response.data.results,
          Last10TableData.tableBodyData[0]
        );
        Last10TableData.tableBodyData = rowArray;
        setPatientList(response.data.results);
      }
    }).catch((err) => {
      console.error(`An unexpected error occured in Last 10:${JSON.stringify(err)}`);
    }).finally (()=> {
      setShowLoadingBar(false);
    })
  }

  function sortingFunction(e, name) {
    setShowLoadingBar(true);
    let fieldName = getFieldName(name);
    if (!orderType) setOrderType("-");
    else setOrderType("");
    if (fieldName) getPatientsData(true, fieldName, orderType ? "" : "-");
  }

  function getFieldName(name) {
    name = name.replace(" ", "_").toLowerCase();
    let fieldName = "";
    if (name == "patient_name") {
      fieldName = "full_name";
      setSortField("full_name")
      setInitialOrderingPatientName(false);
      setInitialOrderingPatientId(true);
      setInitialOrderingPatientDob(true);
    } else if (name == "patient_id") {
      fieldName = "custom_patient_id";
      setSortField("custom_patient_id")
      setInitialOrderingPatientId(false);
      setInitialOrderingPatientName(true);
      setInitialOrderingPatientDob(true);
    } else if (name == "dob") {
      fieldName = "date_of_birth";
      setSortField("date_of_birth")
      setInitialOrderingPatientDob(false);
      setInitialOrderingPatientName(true);
      setInitialOrderingPatientId(true);
    }
    return fieldName;
  }

  function advanceSortingFunction(e, name) {
    setShowLoadingBar(true);
    let fieldName = getFieldName(name);
    if (!orderType) setOrderType("-");
    else setOrderType("");
    if (fieldName)
      ListAdvancedPatients(DEFAULT_PAGING_SIZE, activePage, fieldName, orderType ? "" : "-", true);
  }

  function dropDownFunction(id, name) {
    if (name.toLowerCase() === "edit") {
      onEditPatient(id, name);
    }
  }

  const dispatch = useDispatch();

  async function onEditPatient(patientID) {
    let patientOpened = false;
    if (openedTabPKs && Array.isArray(openedTabPKs)) {
      patientOpened = openedTabPKs.includes(patientID);
    }

    if (!patientOpened && tabsOpen >= MAX_OPEN_TABS_PATIENTS) {
      showNotifyWindow(
        "show",
        "error",
        i18n.t("errorMessages.max_patient_tabs")
      );
    } else {
      if (openedTabPKs && openedTabPKs.includes(patientID)) onAlertOk();
      else CheckForPatientAlert(patientID);
      let item = {
        pk: patientID,
        type: "patients",
        action: "add",
        practice_pk: practicePK,
      };
      addRemoveFromSelectedTab(item, true);
    }
    dispatch(resetPatientData())
  }

  function CheckForPatientAlert(patientPK) {
    let data = {
      patient_pk: patientPK,
      alert_type: 2, // Alert type of Patient
    };
    const result = service.CheckForAlert(data);
    result.then((response) => {
      if (response?.data?.has_alert) {
        setShowAlertModalWindow(true);
        setAlertMessage(response.data.alert_message);
      }
    });
  }

  function onAlertOk() {
    setShowAlertModalWindow(false);
  }

  function onImportErrorOK() {
    setShowImportErrorModalWindow(false);
  }

  function addRemoveFromSelectedTab(item, reloadData, closeFlag) {
    setShowLoadingBar(true)
    const result = service.AddRemoveSelectedTab(item);
    result.then((response) => {
      if (reloadData) {
        getSelectedPatients(item);
        setHrefPatientIdReload(false);
        setClearSelectedPatient(clearSelectedPatient + 1)
        setHrefPatientId(0);
        if (!closeFlag)
          setValue("editPatient " + item.pk);
        else
          dispatch({ type: 'patientInfo/resetPatientDataOnTabClose', payload: item }); // Dispatch the patientPK to redux to remove its data from state

      }
      if (response?.data?.code === 404 && item?.pk) {
        showNotifyWindow("show", "error", i18n.t("errorMessages.not_found"));
      }
    }).catch(err => {
      console.error("An error occurred while fetching data:",err)
    }).finally(() => {
      setShowLoadingBar(false)
    })
  }

  function closePatientTab(e, item) {
    if (e.target.id == "closeTabIcon") {
      setShowLoadingBar(true);
      item["action"] = "remove";
      item["type"] = "patients";
      item["practice_pk"] = practicePK;
      addRemoveFromSelectedTab(item, true, true);
    }
  }

  useEffect(() => {
    if (patientAdvList?.length > 0) {
      const rowArray = commonTableBody(
        patientAdvList,
        AdvSearchTableData.tableBodyData[0]
      );
      AdvSearchTableData.tableBodyData = rowArray;
      setPatientList(rowArray);
    } else {
      AdvSearchTableData.tableBodyData =TableBodyData;
    }
  }, [patientAdvList]);

  function ListAdvancedPatients(pageSize, page, fieldName, orderType, sortFlag) {
    const query = queryBuilderForAdvancedPatientsList(pageSize, page, fieldName, orderType, sortFlag);

    const result = service.ListPatients(query);
    result.then((response) => {
      setShowLoadingBar(false);
      if (response.data.results !== undefined) {
        setTotalPage(Math.ceil(response.data.count / response.data.page_size));
      }
      setPatientAdvList(response.data.results);
      setShowAdvancedSearchTab(true);
    });
  }

  function queryBuilderForAdvancedPatientsList(pageSize, page, newSortField, newOrderType, sortFlag) {
    let column;
    let sortOrder;
    if (sortFlag) {
      column = newSortField;
      sortOrder = newOrderType;
    } else {
      column = sortField;
      sortOrder = orderType;
    }
    const sortingParams = (sortFlag || sortOrder || column) ? `&column=${sortOrder}${column}` : "";
    return `${advancedQuery}&page_size=${pageSize}&page=${page}${sortingParams}`;
  }


  function getPatientTypes(pageSize, page) {
    page = 0;
    const result = service.ListPatientTypes(pageSize, page, practicePK);
    result.then((response) => {
      if (response?.data) {
        setPatientTypeList(response.data);
      }
    }).catch(error => {
      console.error("Error fetching patient types:", error);
    });
  }

  useEffect(() => {
    if (
      hrefPatientId &&
      parseInt(hrefPatientId, 10) > 0 &&
      hrefPatientIdReload
    ) {
      let item = {
        pk: parseInt(hrefPatientId, 10),
        type: "patients",
        action: "add",
        practice_pk: practicePK,
      };
      if (hrefPatientIdReload) {
        setShowLoadingBar(true)
        setHrefPatientId(0);
        addRemoveFromSelectedTab(item, hrefPatientIdReload);
        setHrefPatientIdReload(false);
      }
    } else {
      getSelectedPatients();
    }
    getPatientTypes(DEFAULT_PAGING_SIZE, activePage);
  }, []);

  function closeAdvancedSearchData() {
    setShowAdvancedSearchTab(false);
    if (selectedPatients && selectedPatients.length > 0) {
      getSelectedPatients();
    } else {
      setValue("listLast10Patients");
      getPatientsData("");
    }
  }

  useEffect(() => {
    resetSorting()
    if (showAdvancedSearchTab) setValue("listAdvSearchPatients");
  }, [showAdvancedSearchTab]);

  async function MaterialMultiselectHandleChange(e) {
    let value = [...tableHeaderSelected]
    if (value.find(item => item == e)) {
      const index = value.indexOf(e)
      value.splice(index, 1)
    }
    else {
      value = [...value, e]
    }
    let anArray = [];
    patienfFieldArray.forEach((item) => {
      //selection and unselection of table fields
      let itemObj = value.filter((field) => {
        if (field === item) return item;
      });
      if (itemObj.length > 0) {
        anArray.push(itemObj[0]);
      }
    });
    await onSelectFieldNames(anArray);
    dispatch({ type: 'patientAdvanceSearchSlice/patientHeaderSelectedChange', payload: { selected: anArray } });
  }

  function remShowExport() {
    document.getElementById("export-dropdown").classList.remove("show");
    document.getElementById("export-menu").classList.remove("show");
    document.getElementById("export-dropdown").classList.remove("active");
    document.getElementById("export-menu").classList.remove("active");
  }

  function onShowExport() {
      document.getElementById("export-dropdown").classList.toggle("show"); 
      document.getElementById("export-menu").classList.toggle("show");
  }

  function onHandleCsvImport(e) {
    if (checkPermission(permission_key_values_patient.patients_patients_list_add)) {
        e.preventDefault();
        document.getElementById("file").value = null;
        document.getElementById("file").click();
    } else {
      showNotifyWindow('show', 'error', i18n.t('errorMessages.permission_error'));
    }
  }
    
   

  function onHandleCSVChange(e) {
    setShowLoadingBar(true);
    const value = e.target.type === "file" ? e.target.files[0] : e.target.value;

    if (e.target.type === "file") {
      if (e.target.files[0] && csvxlsFileCheck(e.target.files[0].name)) {
        const data = new FormData();
        data.append("file", value);
        data.append("practice_pk", practicePK);
        let result = service.ImportCSVData(data);
        result?.then((response) => {
          setShowLoadingBar(false);
          if (response?.data?.message !== undefined) {
            if (response.data.message === "no_file_records") {
              showNotifyWindow(
                "show",
                "error",
                i18n.t("errorMessages.empty_file")
              );
            } else if (response.data.message === "invalid_headings") {
              showNotifyWindow(
                "show",
                "error",
                i18n.t("errorMessages.invalid_headings")
              );
            } else if (response.data.mandate_msg) {
              setImportErrorMessage(
                '<span class="bold-font">' +
                i18n.t("errorMessages.mandate_field_list") +
                "</span><span>" +
                PATIENT_MANDATE_LIST.join(",") +
                '</span><span class="bold-font">' +
                i18n.t("errorMessages.row_not_imported") +
                "</span><span>" +
                response.data.row_list.join(",\n") +
                "</span>"
              );
              setShowImportErrorModalWindow(true);
            } else if (response.data.message === "data_imported") {
              if (response.data.row_list) {
                setValidateData(response.data.row_list);
                let validate_data = "";
                for (var key in response.data.row_list) {
                  let mandatory = [];
                  let invalidData = [];
                  response.data.row_list[key]["mandatory"].forEach((e) => {
                    mandatory.push(`&nbsp; ${e}`);
                  });
                  response.data.row_list[key]["invalid_data"].forEach((e) => {
                    invalidData.push(`&nbsp; ${e}`);
                  });

                  validate_data += `<p><u><strong>Row </strong>${key} </u> <br/> <strong>First Name :</strong> ${response.data.row_list[key]["first_name"]}<br/>
                                        <strong>Last Name :</strong> ${response.data.row_list[key]["last_name"]}<br/> <strong>Mandatory :</strong> ${mandatory}<br/>
                                        <strong>Invalid_Data :</strong> ${invalidData} <br/></p>`;
                }
                setImportErrorMessage(
                  `<p style='margin-bottom:10px;'>${i18n.t(
                    "errorMessages.record_not_imported"
                  )}</p>` +
                  "<br/><br/>" +
                  validate_data
                );
                setShowImportErrorModalWindow(true);
              } else {
                showNotifyWindow(
                  "show",
                  "success",
                  i18n.t("validations.success.import_success")
                );
              }
            }
          } else {
            showNotifyWindow(
              "show", "error",
              response?.data?.error_message ?? i18n.t("errorMessages.import_failed"),
              5000
            );
          }
        });
      } else {
        showNotifyWindow(
          "show",
          "error",
          i18n.t("errorMessages.invalid_file_format")
        );
      }
    }
  }

  function onDownloadCSV(e) {
    let csvData = ['"ROW","FIRST NAME","LAST NAME","MANDATORY","INVALID DATA"'];
    for (var key in validateData) {
      let mandatory = "";
      let invalidData = "";
      for (var k in validateData[key]["mandatory"]) {
        mandatory += validateData[key]["mandatory"][k] + ",";
      }
      for (var j in validateData[key]["invalid_data"]) {
        invalidData += validateData[key]["invalid_data"][j] + ",";
      }
      csvData.push(
        '"' +
        key +
        '",' +
        '"' +
        validateData[key]["first_name"] +
        '",' +
        '"' +
        validateData[key]["last_name"] +
        '",' +
        '"' +
        mandatory +
        '",' +
        '"' +
        invalidData +
        '"'
      );
    }
    var CSV = csvData.join("\n");
    window.URL = window.webkitURL || window.URL;
    var contentType = "text/csv";
    var csvFile = new Blob([CSV], {
      type: contentType,
    });
    e.target.href = window.URL.createObjectURL(csvFile);
    e.target.dataset.downloadurl = [
      contentType,
      e.target.download,
      e.target.href,
    ].join(":");
  }

  function showNotifyWindow(action, type, desc, age = 3000) {
    if (action == "show") {
      setTimeout(() => {
        setShowNotify("hide");
      }, age);
    }
    setShowNotify(action);
    setNotifyType(type);
    setNotifyDescription(desc);
  }

  function onSelectAllColumns(e) {
    setIsFieldsChecked(!isFieldsChecked);
    let selected = e.target.checked;
    if (selected) {
      let fullFields = [];
      fullFields = fullFields.concat(columnsNameList1);
      fullFields = fullFields.concat(columnsNameList2);
      fullFields = fullFields.concat(columnsNameList3);
      fullFields = fullFields.concat(columnsNameList4);
      fullFields = fullFields.concat(columnsNameList5);
      fullFields = fullFields.concat(columnsNameList6);
      setSelectedColumns(fullFields);
    } else {
      setSelectedColumns([]);
    }
  }

  function loadExcelColumnModals(e, type) {
    setIsFieldsChecked(false);
    setExportType(type);
    setSelectedColumns(defaultSelectedColumnsList);
    setColumnModalOpen(true);
    setColumnModalHeader(i18n.t("payments.eraPage.addOptionalColumns"));
  }

  function onSelectOptionalColumn(e, item) {
    let tmp = selectedColumns;
    if (tmp.indexOf(item) >= 0) {
      tmp.splice(tmp.indexOf(item), 1);
    } else {
      tmp.push(item);
    }
    setSelectedColumns(tmp);
    setIsSelected(!isSelected);
  }

  async function exportData() {
    setShowLoadingBar(true);
    const query = buidlQueryForExportData(value, exportType);

    if (exportType !== "") {
      try {
        const response = await service.ExportPatientsList(query, exportType);

        if (response && response.data && response.status !== 500) {
          fileDownload(response.data, response.headers["content-disposition"]);
        }

        if (response && response.status === 500) {
          showNotifyWindow("show", "error", response.statusText.toString());
        }

      } catch (error) {
        showNotifyWindow("show", "error", i18n.t("errorMessages.commonError"));
      }
      setShowLoadingBar(false);
    }
  }

  const buidlQueryForExportData = (value, exportType) => {
    let query = ''
    let page = 0;
    let fields = JSON.stringify(selectedColumns);
    if (value === "listLast10Patients") {
      query +=
        "?list_type=export&export_type=" +
        exportType +
        "&practice_pk=" +
        practicePK +
        "&page_size=" +
        pageSize +
        "&page=" +
        page +
        "&last10=true";
    } else if (value === "listAdvSearchPatients") {
      query +=
        advancedQuery +
        "&list_type=export&export_type=" +
        exportType +
        "&page_size=" +
        pageSize +
        "&page=" +
        page;
    }
    return query += "&fields=" + fields;
  }

  return (
    <React.Fragment>
      <Notify
        showNotify={showNotify}
        setShowNotify={setShowNotify}
        notifyDescription={notifyDescription}
        setNotifyType={setNotifyType}
        setNotifyDescription={setNotifyDescription}
        notifyType={notifyType}
      />

      <div className="col-md-12 min-height-500">
        <div className="box ">
          <Form autoComplete="off">
            <div className="box-content" style={{ marginBottom: "34px" }}>
              <div className="patientslist-patient-search " style={{ backgroundColor: '#fff', marginRight: '16px' }}>
                <PatientDropDownSearch
                  id="patients-page-patient-selector"
                  inputClass={"padding-top15"}
                  addRemoveFromSelectedTab={addRemoveFromSelectedTab}
                  orPaddingTop="45px"
                  patientAdvSearchData={patientAdvSearchData}
                  tabsOpen={tabsOpen}
                  MAX_OPEN_TABS_PATIENTS={MAX_OPEN_TABS_PATIENTS}
                  clearSelectedPatient={clearSelectedPatient}
                ></PatientDropDownSearch>
                <div className='col justify-right'
                  style={{ marginTop: "37px" }}
                >
                  <RightSidePanel
                    title={i18n.t("commons.advancedSearch")}
                    onclickLabel={i18n.t("commons.advancedSearch")}
                  >
                    <PatientsAdvancedSearch
                      getPatientsData={getPatientsData}
                      setPatientAdvList={setPatientAdvList}
                      setShowAdvancedSearchTab={setShowAdvancedSearchTab}
                      setAdvancedQuery={setAdvancedQuery}
                      setTotalPage={setTotalPage}
                      setActivePage={setActivePage}
                      setPatientAdvSearchData={setPatientAdvSearchData}
                    ></PatientsAdvancedSearch>
                  </RightSidePanel>
                </div>
              </div>
            </div>

            <TabContext value={value}>

              <Grid
                direction="row"
                container
                spacing={0}
                alignItems="center"
              >
                <Grid item xs={9} className={'patient-list-tab-xs-9'} >
                  <StyledTabList
                    TabIndicatorProps={{

                      children: <CustomIndicator />, // Assigning the custom indicator here
                    }}
                    onChange={tabChange}
                    scrollable
                    scrollButtons="auto"
                    aria-label="Patients Tab"
                    variant="scrollable"
                  >
                    <Tab

                      label={
                        <span id="list-last10-patient-tab">
                          {i18n.t("patientPages.patients.last10")}
                        </span>
                      }
                      value="listLast10Patients"
                      className="tabMainMenu"
                    />
                    {showAdvancedSearchTab && (
                      <Tab
                        label={
                          <span>
                            <span onClick={tabChange} id="list-advanced-search-patient-tab">
                              {i18n.t(
                                "patientPages.patients.advancedSearch"
                              )}
                            </span>
                            <span className="margin-left15"></span>
                            <span
                              className="closeIconCommonTab"
                              onClick={closeAdvancedSearchData}
                              data-testid="close-btn-advanced"
                              id="close-btn-advanced-patient-search"
                            >
                              <i
                                id="closeTabIcon"
                                className="fa fa-times"
                              />
                            </span>
                          </span>
                        }
                        value="listAdvSearchPatients"
                        className="tabMainMenu"
                      />
                    )}
                    {selectedPatients &&
                      selectedPatients.map((item, index) => {
                        if (item.pk !== "last10" && item.pk !== "add_new") {
                          return (
                            <Tab
                              key={index}
                              className="tabMainMenu"
                              value={"editPatient " + item.pk}
                              label={
                                <div className="justify-normal">
                                  <span
                                    onClick={tabChange}
                                    id={`patient-opened-tab-${item.custom_patient_id}`}
                                  >
                                    {item.name.toUpperCase()} ({item.custom_patient_id})
                                  </span>
                                  <span className="margin-left15"></span>
                                  <span
                                    id={`patient-tab-close-btn-${item.custom_patient_id}`}
                                    className="closeIconCommonTab"
                                    onClick={(e) =>
                                      closePatientTab(e, item)
                                    }
                                  >
                                    <i
                                      id="closeTabIcon"
                                      className="fa fa-times"
                                    />
                                  </span>
                                </div>
                              }
                            />
                          );
                        }
                      })}

                    {checkPermission(
                      permission_key_values_patient.patients_patients_list_add
                    ) &&
                      tabsOpen >= MAX_OPEN_TABS_PATIENTS && (
                        <Tab
                          label={
                          <span
                            className="closeIconCommonTab"
                            id="add-new-patient-tab"
                          >
                              <AddIcon
                                className="add-new-disabled"
                                style={{
                                  fontSize: '2rem',
                                  fontWeight: 400,
                                  color: activeTab === 'addNewPatient' ? '#1479BB !important' : 'inherit'
                                }}
                              />
                            </span>
                          }
                          value={"addNewPatient"}
                          className=""
                          disabled
                        />
                      )}

                    {checkPermission(
                      permission_key_values_patient.patients_patients_list_add
                    ) &&
                      tabsOpen < MAX_OPEN_TABS_PATIENTS && (
                        <Tab
                          label={
                          <span
                            className="closeIconCommonTab"
                            id="add-new-patient-tab"
                          >
                              <AddIcon
                                className=""
                                style={{
                                  fontSize: '2rem',
                                  fontWeight: 400,
                                }}
                              />
                            </span>
                          }
                          value={"addNewPatient"}
                          className="tabMainMenu"
                        />
                      )}
                  </StyledTabList>
                </Grid>

                <Grid
                  item
                  xs={3}
                  justifyContent="flex-end"
                  alignItems="center"

                >
                  <Grid container alignItems="center" justify="center">
                    {tabsOpen >= 4 && (
                      <Grid item xs={1.4}>
                        <OverlayTrigger
                          placement="top"
                          delay={{ show: 250, hide: 250 }}
                          overlay={
                            <Tooltip
                              id="button-tooltip-2"
                              style={{
                                opacity: 0.6,
                              }}
                            >
                              {tabsOpen <= 4 &&
                                tabsOpen + " Patient Tabs are Open"}
                              {tabsOpen == 5 &&
                                "A maximum of 5 patient tabs can be opened at a time. You may need to close one tab to open a new one."}
                            </Tooltip>
                          }
                        >
                          <Badge badgeContent={tabsOpen} color="primary">
                            <FolderIcon color="action" />
                          </Badge>
                        </OverlayTrigger>
                      </Grid>
                    )}



                    {value == "listLast10Patients" ||
                      value === "listAdvSearchPatients" ? (

                      <Grid item xs={10.5} >

                        <div>
                          <div className="d-flex patientListIcons">
                              <div className="mr-2">
                                    <OverlayTrigger
                                      placement="top"
                                      delay={{ show: 250, hide: 400 }}
                                      overlay={
                                        <Tooltip
                                          id="download-tooltip"
                                          style={{
                                            opacity: 0.4,
                                          }}
                                        >
                                          {i18n.t("commons.importCSV")}
                                        </Tooltip>
                                      }
                                    >
                                      <CommonButton variant="outlined" label="Download" icon={'download'}
                                    onClick={onShowExport} onBlur={remShowExport} id="patients_export_btn" />

                                    </OverlayTrigger>
                                <input
                                  name="csvFile"
                                  type="file"
                                  accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                                  id="file"
                                  onChange={onHandleCSVChange}
                                  style={{ display: "none" }}
                                />
                              </div>

                              <div className="">
                                <ul
                                  style={{ marginRight: '116px' }}
                                  className="patientList dropdown-menu download-dropdown"
                                  id="export-menu"
                                >
                                  <li
                                    className="download-dropdown-menu-item"
                                    id="download-dropdown-menu-item-csv"
                                    onMouseDown={(e) =>
                                      loadExcelColumnModals(e, "csv")
                                    }
                                  >
                                    <Link to="#" className="text-dec-none" data-testid="download-dropdown-menu-item-csv">
                                      {i18n.t("commons.exportCSV")}
                                    </Link>
                                  </li>
                                  <li
                                    className="download-dropdown-menu-item"
                                    onMouseDown={(e) =>
                                      loadExcelColumnModals(e, "xls")
                                    }
                                  >
                                    <Link to="#" className="text-dec-none" data-testid="download-dropdown-menu-item-xls">
                                      {i18n.t("commons.exportXLS")}
                                    </Link>
                                  </li>
                                </ul>

                              </div>
                              <div className="dropdown" id="export-dropdown">
                                  <CommonButton variant="outlined" label="Upload" icon={'upload'}
                                    onClick={(e) => onHandleCsvImport(e)} />
                              </div>
                              <TableHeaderSelector
                                selected={tableHeaderSelected}
                                options={PatientTableHead}
                                MaterialMultiselectHandleChange={(e) => MaterialMultiselectHandleChange(e)}
                              />
                          </div>
                        </div>
                      </Grid>
                    ) : (
                      ""
                    )}
                  </Grid>
                </Grid>

              </Grid>


              <TabPanel value="listLast10Patients" className="bordering border-radius">
                <div className="mt-2">
                  <div className="mb-2 table-responsive">
                    <Table
                      tableObject={Last10TableData}
                      isActionVisible={checkPermission(
                        permission_key_values_patient.patients_patients_list_modify
                      )}
                      onLinkClick={onEditPatient}
                      list={patientList}
                      dropDownFunction={dropDownFunction}
                      sortingFunction={sortingFunction}
                    />
                  </div>
                  <div className="height-75">&nbsp;</div>
                </div>
              </TabPanel>

              {showAdvancedSearchTab && (
                <TabPanel value={"listAdvSearchPatients"} className="bordering border-radius" style={{ padding: '24px' }}>
                  <div className="mt-2">
                    <div className="mb-4 table-responsive">
                      <Table
                        tableObject={AdvSearchTableData}
                        isActionVisible={checkPermission(
                          permission_key_values_patient.patients_patients_list_modify
                        )}
                        onLinkClick={onEditPatient}
                        dropDownFunction={dropDownFunction}
                        sortingFunction={advanceSortingFunction}
                      />
                    </div>
                    <Pagination
                      totalPage={totalPage}
                      activePage={activePage}
                      startIndex={startIndex}
                      endIndex={endIndex}
                      onPagePrevious={onPagePrevious}
                      onPageUp={onPageUp}
                      onPageNext={onPageNext}
                    />
                    {totalPage <= 1 && (
                      <div className="clear-b-scroll-sector">&nbsp;</div>
                    )}
                    <div className="height-75">&nbsp;</div>
                  </div>
                </TabPanel>
              )}
              <TabPanel value={"addNewPatient"} className="bordering border-radius">
                <PatientsAdd
                  patientTypeList={patientTypeList}
                  patientPK={""}
                  addRemoveFromSelectedTab={addRemoveFromSelectedTab}
                  patientListTab={setValue}
                  activeTab={activeTab}
                />
              </TabPanel>
              {selectedPatients &&
                selectedPatients.map((item, index) => {
                  if (
                    item.pk !== "last10" &&
                    item.pk !== "add_new" &&
                    value === "editPatient " + item.pk
                  ) {
                    return (
                      <TabPanel
                        key={index}
                        value={"editPatient " + item.pk}
                        className="bordering border-radius"
                      >
                        <PatientsAdd
                          patientTypeList={patientTypeList}
                          patientPK={item.pk}
                          activeSubTab={
                            item.active_tab ? item.active_tab : "tab1"
                          }
                          activeTab={value}
                          addRemoveFromSelectedTab={addRemoveFromSelectedTab}
                        />
                      </TabPanel>
                    );
                  }
                })}
            </TabContext>
          </Form>
        </div>
      </div>
      <CustomizedSmallDialogs
        showModal={showAlertModalWindow}
        header={i18n.t("commons.alert")}
        alertOK={onAlertOk}
        type="alert"
        setShowModalWindow={setShowAlertModalWindow}
      >
        {alertMessage}
      </CustomizedSmallDialogs>
      <CustomizedSmallDialogs
        showModal={showImportErrorModalWindow}
        header={i18n.t("commons.importErrorHeader")}
        alertOK={onImportErrorOK}
        onDownload={onDownloadCSV}
        type="alert"
        setShowModalWindow={setShowImportErrorModalWindow}
      >
        <div className="display-grid">
          {ReactHtmlParser(importErrorMessage)}
        </div>
      </CustomizedSmallDialogs>
      <CustomizedDialogs
        type={"export"}
        exportData={exportData}
        modalBodyId={"columnModalBody"}
        modalId={"columnModal"}
        header={columnModalHeader}
        showModal={columnModalOpen}
        setShowModalWindow={setColumnModalOpen}
        onCheckBoxChange={onSelectAllColumns}
        fieldsChecked={isFieldsChecked}
      >
        <ExportPatientsListCheckBox
          columnsList={[
            columnsNameList1,
            columnsNameList2,
            columnsNameList3,
            columnsNameList4,
            columnsNameList5,
            columnsNameList6,
          ]}
          selectedColumns={selectedColumns}
          onSelectOptionalColumn={onSelectOptionalColumn}
          optionalColumnsList={optionalColumnsList}
        />
      </CustomizedDialogs>
    </React.Fragment>
  );
};

export default Patientslist;