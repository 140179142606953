import i18n from "../../utilities/i18n";

const TemplateTableHeadData = [
  {
    name: i18n.t("inboxScreens.template.tableHeadings.templateName"),
    type: "string",
    width: "large",
    sort: false,
  },
  {
    name: i18n.t("inboxScreens.template.tableHeadings.subject"),
    type: "string",
    width: "large",
    sort: false,
  },
  {
    name: i18n.t("dictionariesPages.action"),
    type: "button",
    width: "action_column",
    sort: false,
  },
];

const TemplateModalHeadData = [
  {
    name: i18n.t("inboxScreens.composeScreen.tableHeadings.templateName"),
    type: "string",
    width: "large",
    sort: false,
  },
];

export const TemplateTableBodyData = [
  [
    {
      id: "",
      name: "template_name",
      value: "",
      type: "string",
      align: "left",
      width: "large",
      needLink: true,
    },
    {
      id: "",
      name: "subject",
      value: "",
      type: "string",
      align: "left",
      width: "large",
    },
    {
      id: "",
      name: "action",
      type: "button",
      align: "center",
      width: "action_column",
      dropdown: [
        { id: "", name: "Delete" },
      ],
    },
  ],
];

const TemplateModalBodyData = [
  [
    {
      id: "",
      name: "template_name",
      value: "",
      type: "string",
      align: "left",
      width: "large",
      needLink: true,
    },
  ],
];

export const ListTemplateTableData = {
  name: "simpleTable",
  isResize: true,
  isFixedHead: true,
  tableHeadings: TemplateTableHeadData,
  tableBodyData: TemplateTableBodyData,
};

export const ListTemplateModal = {
  name: "simpleTable",
  isResize: true,
  isFixedHead: true,
  tableHeadings: TemplateModalHeadData,
  tableBodyData: TemplateModalBodyData,
};
