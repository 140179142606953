import React from 'react'
import { useState } from 'react';
import {
    Chip,
    Paper,
    Tooltip,
    Typography,
    Autocomplete,
    Box,
    Checkbox,
    TextField,
    Popper
} from '@mui/material';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import service from '../patientManagement/patients/service';
import Label from './Label';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;
export default function Tags(props) {
    const [patientList, setPatientList] = useState([]);
    const [isFocused, setIsFocused] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [isHovered, setIsHovered] = useState(false);
    const SearchPatients = async(query) => {
        setIsLoading(true);
        if (query.length >=3) {
            setIsLoading(true);
            service.GetListPatientDropdown(query).then((response) => {
                const anArray = response.data.map((item) => ({
                    id: item.id,
                    name: item.name,
                    checked: false,
                    description: item.description,
                }));

                const combinedArray = [...anArray, ...props.value];
                const uniqueArray = combinedArray.filter((item, index) => {
                    return combinedArray.findIndex(obj => obj.id === item.id) === index;
                });

                setPatientList(uniqueArray);
            }).catch(error => {
                console.error(error);
            });
            setIsLoading(false);
        }
    }

    return (
        <Tooltip
            title={
                <Box>
                    {props.value?.map((item, i) => (
                        <Typography key={i} variant="body2">
                            {item.name}
                        </Typography>
                    ))}
                </Box>
            }
            arrow
            placement="right"
            open={(isFocused || isHovered) && props.value?.length > 0}
        >
            {/* Triggering tooltip render while disabled */}
            <div
                onMouseEnter={() => {
                    setIsHovered(true);
                    setIsLoading(true)
                }}
                onMouseLeave={() => {
                    setIsHovered(false);
                    setIsLoading(false);
                }}
            >
                <Label label={props.label} />
                <Autocomplete
                    multiple
                    id="remittanceOptions-remarks-code-selector"
                    name={name}
                    noOptionsText={'Could not find any patients, Type at least 3 characters to search'}
                    size='medium'
                    options={patientList}
                    loading={isLoading}
                    openOnFocus
                    disableCloseOnSelect
                    getOptionLabel={(option) => option?.name}
                    value={props.value} // An array of selected values as in the exact data structure of options
                    onChange={(event, newValue) => {
                            props.onChange(newValue);
                    }}
                    onInputChange={(event, inputString) => { // Call back to trigger api on type search 
                        SearchPatients(inputString);
                    }}
                    PopperComponent={({ children, ...props }) => (
                        <Popper {...props} placement="bottom-start">
                            <Paper elevation={4}>
                                {children}
                            </Paper>
                        </Popper>
                    )}
                    isOptionEqualToValue={(option, value) => option.id === value.id} // Matching the selected values in the options array
                    renderOption={(props, option, { selected }) => ( // Rendering the options box with option items
                            <li {...props}>
                                <Checkbox
                                    icon={icon}
                                    checkedIcon={checkedIcon}
                                    style={{ marginRight: 8 }}
                                    checked={selected}
                                />
                                {option.name}
                            </li>
                    )}
                    renderTags={(value, getTagProps) =>
                        // Customizing and rendering the selected values in the field
                        // When focused, restricted rendering the selected values in the field so there'll be enough room for user to type and search
                        isFocused ? null : value.slice(0, 1).map((option, index) => (
                                <Chip
                                    key={index}
                                    sx={{ padding: 0, fontSize: '1em' }}
                                    {...getTagProps({ index })}
                                    label={`${option.name} ${value.length > 1 ? `+ ${value.length - 1} more...` : ''}`}
                                    deleteIcon={<></>}
                                />
                        ))
                    }
                    renderInput={(params) => (
                        <TextField
                            sx={{
                                '&& .MuiOutlinedInput-root': {
                                    padding: 0,
                                    minWidth: '150px',
                                    height: '37px',
                                },
                                '&& .MuiIconButton-root': {
                                    padding: '4px !important',
                                },
                            }}
                            {...params}
                            onFocus={() => setIsFocused(true)}
                            onBlur={() => setIsFocused(false)}
                        />
                    )}
                />
            </div>
        </Tooltip>
    );
}