import i18n from "../../../utilities/i18n";

export const CPTTableBodyOnly = [
  [
    {
      id: "",
      name: "name",
      value: "",
      type: "string",
      align: "center",
      width: "small",
    },
    {
      id: "",
      name: "medium_description",
      value: "",
      type: "string",
      align: "left",
      width: "medium",
    },
    {
      id: "",
      name: "low_age",
      value: "",
      type: "string",
      align: "center",
      width: "small",
    },
    {
      id: "",
      name: "high_age",
      value: "",
      type: "string",
      align: "center",
      width: "small",
    },
    {
      id: "",
      name: "gender_name",
      value: "",
      type: "string",
      align: "center",
      width: "small",
    },
    {
      id: "",
      name: "add_on_code_name",
      value: "",
      type: "string",
      align: "center",
      width: "small",
    },
    {
      id: "",
      name: "effective_date",
      value: "",
      type: "Date",
      align: "center",
      width: "small",
      needDateFormat: "yes",
    },
    {
      id: "",
      name: "deletion_date",
      value: "",
      type: "Date",
      align: "center",
      width: "small",
      needDateFormat: "yes",
    },
    {
      id: "",
      name: "action",
      type: "button",
      align: "center",
      width: "action_column",
      dropdown: [
        { id: "", name: "Edit" },
      ],
    },
  ],
];

const CPTTableHeader = [
  {
    name: i18n.t("dictionariesPages.dictCPTCode.cpt"),
    type: "string",
    width: "small",
    sort: true,
    initialOrdering: true,
    orderType: "",
  },
  {
    name: i18n.t("dictionariesPages.dictCPTCode.mediumDescription"),
    type: "string",
    width: "large",
    sort: false,
  },
  {
    name: i18n.t("dictionariesPages.dictCPTCode.lowAge"),
    type: "string",
    width: "small",
    sort: false,
  },
  {
    name: i18n.t("dictionariesPages.dictCPTCode.highAge"),
    type: "string",
    width: "small",
    sort: false,
  },
  {
    name: i18n.t("dictionariesPages.dictCPTCode.gender"),
    type: "string",
    width: "small",
    sort: false,
  },
  {
    name: i18n.t("dictionariesPages.dictCPTCode.addOnCode"),
    type: "string",
    width: "small",
    sort: false,
  },
  {
    name: i18n.t("dictionariesPages.dictCPTCode.effectiveDate"),
    type: "Date",
    width: "small",
    sort: false,
  },
  {
    name: i18n.t("dictionariesPages.dictCPTCode.expirationDate"),
    type: "Date",
    width: "small",
    sort: false,
  },
  {
    name: i18n.t("dictionariesPages.action"),
    type: "button",
    width: "action_column",
    sort: false,
  },
];

export const CPTTableObject = {
  name: "simpleTable",
  isResize: true,
  isFixedHead: true,
  noRecordsMsg: i18n.t("commons.noRecords"),
  tableHeadings: CPTTableHeader,
  tableBodyData: CPTTableBodyOnly,
};

// ******************************* Admin Table Body **********************
export const CPTAdminTableBodyOnly = [
  [
    {
      id: "",
      name: "name",
      value: "",
      type: "string",
      align: "center",
      width: "small",
    },
    {
      id: "",
      name: "medium_description",
      value: "",
      type: "string",
      align: "left",
      width: "medium",
    },
    {
      id: "",
      name: "low_age",
      value: "",
      type: "string",
      align: "center",
      width: "small",
    },
    {
      id: "",
      name: "high_age",
      value: "",
      type: "string",
      align: "center",
      width: "small",
    },
    {
      id: "",
      name: "gender_name",
      value: "",
      type: "string",
      align: "center",
      width: "small",
    },
    {
      id: "",
      name: "add_on_code_name",
      value: "",
      type: "string",
      align: "center",
      width: "small",
    },
    {
      id: "",
      name: "effective_date",
      value: "",
      type: "Date",
      align: "center",
      width: "small",
      needDateFormat: "yes",
    },
    {
      id: "",
      name: "deletion_date",
      value: "",
      type: "Date",
      align: "center",
      width: "small",
      needDateFormat: "yes",
    },
    {
      id: "",
      name: "action",
      type: "button",
      align: "center",
      width: "action_column",
      dropdown: [
        { id: "", name: "Edit" },
        { id: "", name: "Delete" },
      ],
    },
  ],
];

const CPTAdminTableHeader = [
  {
    name: i18n.t("dictionariesPages.dictCPTCode.cpt"),
    type: "string",
    width: "small",
    sort: true,
    initialOrdering: true,
    orderType: "",
  },
  {
    name: i18n.t("dictionariesPages.dictCPTCode.mediumDescription"),
    type: "string",
    width: "large",
    sort: false,
  },
  {
    name: i18n.t("dictionariesPages.dictCPTCode.lowAge"),
    type: "string",
    width: "small",
    sort: false,
  },
  {
    name: i18n.t("dictionariesPages.dictCPTCode.highAge"),
    type: "string",
    width: "small",
    sort: false,
  },
  {
    name: i18n.t("dictionariesPages.dictCPTCode.gender"),
    type: "string",
    width: "small",
    sort: false,
  },
  {
    name: i18n.t("dictionariesPages.dictCPTCode.addOnCode"),
    type: "string",
    width: "small",
    sort: false,
  },
  {
    name: i18n.t("dictionariesPages.dictCPTCode.effectiveDate"),
    type: "Date",
    width: "small",
    sort: false,
  },
  {
    name: i18n.t("dictionariesPages.dictCPTCode.expirationDate"),
    type: "Date",
    width: "small",
    sort: false,
  },
  {
    name: i18n.t("dictionariesPages.action"),
    type: "button",
    width: "action_column",
    sort: false,
  },
];

export const CPTAdminTableObject = {
  name: "simpleTable",
  isResize: true,
  isFixedHead: true,
  noRecordsMsg: i18n.t("commons.noRecords"),
  tableHeadings: CPTAdminTableHeader,
  tableBodyData: CPTAdminTableBodyOnly,
};

// ************************ TABLE FOR CPT CODE - ADD SCREEN ******************************

const CPTCODEAddTableHeader = [
  {
    name: i18n.t("dictionariesPages.dictCPTCode.cpt"),
    type: "string",
    width: "small",
    sort: false,
  },
  {
    name: i18n.t("dictionariesPages.dictCPTCode.description"),
    type: "string",
    width: "large",
    sort: false,
  },
  {
    name: i18n.t("dictionariesPages.dictCPTCode.type"),
    type: "string",
    width: "small",
    sort: false,
  },
  {
    name: "Add",
    type: "string",
    width: "plus_button",
    sort: false,
  },
];

const CPTCODEAddTableBody = [
  [
    {
      id: "",
      name: "name",
      value: "",
      type: "string",
      align: "left",
      width: "small",
    },
    {
      id: "",
      name: "short_description",
      value: "",
      type: "string",
      align: "left",
      width: "large",
    },
    {
      id: "",
      name: "code_type",
      value: "",
      type: "string",
      align: "center",
      width: "small",
    },
    {
      id: "",
      name: "plusbutton",
      type: "plusButton",
      width: "plus_button",
      align: "center",
      value: false,
    },
  ],
];

export const CPTCODEAddTableObject = {
  name: "simpleTable",
  isResize: false,
  isFixedHead: false,
  noRecordsMsg: i18n.t("commons.noRecords"),
  tableHeadings: CPTCODEAddTableHeader,
  tableBodyData: CPTCODEAddTableBody,
};
