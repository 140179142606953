import React from "react";
const ReportContext = React.createContext({
  reportUuid: "",
});
export default ReportContext;
const getRandomString = (name, id) => {
  var result = "";
  var characters = `ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789${name}${id}`;
  var charactersLength = characters.length;
  for (var i = 0; i < 15; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
};

export const ReportContextProvider = (props) => {
  const { reportName, reportId } = props;
  const randomReportId=getRandomString(reportName, reportId)

  return (
    <ReportContext.Provider value={{ reportUuid: randomReportId }}>
      {props.children}
    </ReportContext.Provider>
  );
};
