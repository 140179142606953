import i18n from '../../../utilities/i18n';
export  const mainHeadings = [
    { "value": i18n.t("patientPages.patientLedger.ledgerTab.tableHedings.claim"), "thClass": "textCenter small", "tdClass": "textCenter" },
    { "value": i18n.t("patientPages.patientLedger.ledgerTab.tableHedings.dos"), "thClass": "textCenter small", "tdClass": "textCenter" },
    { "value": i18n.t("patientPages.patientLedger.ledgerTab.tableHedings.provider"), "thClass": "textCenter medium", "tdClass": "" },
    { "value": i18n.t("patientPages.patientLedger.ledgerTab.tableHedings.charges"), "thClass": "textCenter small", "tdClass": "textRight amount" },
    { "value": i18n.t("patientPages.patientLedger.ledgerTab.tableHedings.payments"), "thClass": "textCenter small", "tdClass": "textRight amount" },
    { "value": i18n.t("patientPages.patientLedger.ledgerTab.tableHedings.adjustments"), "thClass": "textCenter small", "tdClass": "textRight amount" },
    { "value": i18n.t("patientPages.patientLedger.ledgerTab.tableHedings.balance"), "thClass": "textCenter small", "tdClass": "textRight amount" },
    { "value": i18n.t("patientPages.patientLedger.ledgerTab.tableHedings.insuranceBal"), "thClass": "textCenter small", "tdClass": "textRight amount" },
    { "value": i18n.t("patientPages.patientLedger.ledgerTab.tableHedings.patientBal"), "thClass": "textCenter small", "tdClass": "textRight amount" },
    { "value": i18n.t("patientPages.patientLedger.ledgerTab.tableHedings.claimStatus"), "thClass": "textCenter medium", "tdClass": "textCenter" },
];

export  const subHedaings1 = [
    { "value": i18n.t("patientPages.patientLedger.ledgerTab.tableHedings.cpt"), "thClass": "textCenter small", "tdClass": "textCenter" },
    { "value": i18n.t("patientPages.patientLedger.ledgerTab.tableHedings.description"), "thClass": "textCenter  medium", "tdClass": "" },
    { "value": i18n.t("patientPages.patientLedger.ledgerTab.tableHedings.units"), "thClass": "textCenter small", "tdClass": "textCenter" },
    { "value": i18n.t("patientPages.patientLedger.ledgerTab.tableHedings.charges"), "thClass": "textCenter small", "tdClass": "textRight amount" },
    { "value": i18n.t("patientPages.patientLedger.ledgerTab.tableHedings.payments"), "thClass": "textCenter small", "tdClass": "textRight amount" },
    { "value": i18n.t("patientPages.patientLedger.ledgerTab.tableHedings.adjustments"), "thClass": "textCenter small", "tdClass": "textRight amount" },
    { "value": i18n.t("patientPages.patientLedger.ledgerTab.tableHedings.balance"), "thClass": "textCenter small", "tdClass": "textRight amount" },
    { "value": i18n.t("patientPages.patientLedger.ledgerTab.tableHedings.insuranceBal"), "thClass": "textCenter small", "tdClass": "textRight amount" },
    { "value": i18n.t("patientPages.patientLedger.ledgerTab.tableHedings.patientBal"), "thClass": "textCenter small", "tdClass": "textRight amount" },
    { "value": i18n.t("patientPages.patientLedger.ledgerTab.tableHedings.claimStatus"), "thClass": "textCenter medium", "tdClass": "textCenter" },
];

export  const subHedaings2 = [
    { "value": i18n.t("patientPages.patientLedger.ledgerTab.tableHedings.date"), "thClass": "textCenter small", "tdClass": "textCenter" },
    { "value": i18n.t("patientPages.patientLedger.ledgerTab.tableHedings.activity"), "thClass": "textCenter medium", "tdClass": "textCenter" },
    { "value": i18n.t("patientPages.patientLedger.ledgerTab.tableHedings.charges"), "thClass": "textCenter small", "tdClass": "textRight amount" },
    { "value": i18n.t("patientPages.patientLedger.ledgerTab.tableHedings.payments"), "thClass": "textCenter small", "tdClass": "textRight amount" },
    { "value": i18n.t("patientPages.patientLedger.ledgerTab.tableHedings.adjustments"), "thClass": "textCenter small", "tdClass": "textRight amount" },
    { "value": i18n.t("patientPages.patientLedger.ledgerTab.tableHedings.balance"), "thClass": "textCenter small", "tdClass": "textRight amount" },
    { "value": i18n.t("patientPages.patientLedger.ledgerTab.tableHedings.remarks"), "thClass": "textCenter medium", "tdClass": "textCenter" }
];