/**
 * This module not in use - May need in future when implementing Dynamic report generation
 */

import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  reportData: [],
};

export const reportViewerSlice = createSlice({
  name: "reportViewer",
  initialState,
  reducers: {
    addFilter: (state, action) => {
      const { reportUuid, filter } = action.payload;
      if (reportUuid) {
        const selectedReport = state.reportData.find(
          (reportItem) => reportItem.reportTabId === reportUuid
        );
        selectedReport.reportDetails.filters.push(filter);
      }
    },
    updateFilter: (state, action) => {
      const { reportUuid, filter } = action.payload;
      const selectedReport = state.reportData.find(
        (reportItem) => reportItem.reportTabId === reportUuid
      );
      if (selectedReport) {
        selectedReport.reportDetails.filters.forEach((element) => {
          if (element.fieldId === filter.fieldId) {
            element.searchMap = filter.searchMap;
          }
        });
      }
    },
    addReportDataItem: (state, action) => {
      if (action.payload.reportId) {
        const reportObject = {
          reportName: action.payload.reportName,
          reportId: action.payload.reportId,
          reportTabId: action.payload.reportTabId,
          reportDetails: {
            report_pk: action.payload.reportId,
            order_by: [],
            filters: [],
          },
        };
        state.reportData.push(reportObject);
      }
    },
  },
});

export const { addFilter, updateFilter, addReportDataItem } =
  reportViewerSlice.actions;

export default reportViewerSlice.reducer;
