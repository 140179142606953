import * as React from 'react';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';

export default function LabTabs() {
    const [value, setValue] = React.useState('1');

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <div className="col-md-12">
            <div className="box">
                <Box sx={{ width: '100%', typography: 'body1' }}>
                    <TabContext value={value}>
                        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                            <TabList onChange={handleChange} aria-label="lab API tabs example">
                                <Tab label="Item One" value="listLast10Patients" className='tab' />
                                <Tab label="Item Two" value="2" className='tab' />
                                <Tab label="Item Three" value="3" className='tab' />
                            </TabList>
                        </Box>
                        <TabPanel value="1" className='p-0'>Item One</TabPanel>
                        <TabPanel value="2" className='p-0'>Item Two</TabPanel>
                        <TabPanel value="3" className='p-0'>Item Three</TabPanel>
                    </TabContext>
                </Box>
            </div>
        </div>
    );
}
