import i18n from "../../../utilities/i18n"

const TableHeadData = [
    {
        "name": i18n.t("dictionariesPages.referralList.lblReferralSource"),
        "type": "string",
        "width": "large",
        "sort": false,
    },
    {
        "name": i18n.t("dictionariesPages.action"),
        "type": "number",
        "width": "action_column",
        "sort": false,
    },
]
const TableBodyData = [
    [
        {
            "id": "",
            "name": "name",
            "value": "",
            "type": "string",
            "align": "left",
            "width": "large",
        },
        {
            "id": "",
            "name": "action",
            "value": "",
            "type": "button",
            "align": "center",
            "width": "action_column",
            "dropdown": [
                {
                    'name': 'Edit'
                },
                {
                    'name': 'Delete'
                },
            ]
        },
    ]
]

export const ReferalTableData = {
    "name": "simpleTable",
    "isResize": true,
    "isFixedHead": true,
    "tableHeadings": TableHeadData,
    "tableBodyData": TableBodyData
}