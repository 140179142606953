import React, { useContext, useEffect, useState } from "react";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import { Link } from "react-router-dom";
import { Form } from "react-bootstrap";
import { format } from "date-fns";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";

import LoadingContext from "../../../container/loadingContext";

import i18n from "../../../utilities/i18n";
import { csvFileCheck } from '../../../utilities/validations';
import { getStorage } from "../../../utilities/browserStorage";
import { UPLOAD_CSV_ERROR } from "../../../utilities/labelConfigs";
import { checkPermission } from "../../../utilities/commonUtilities";
import { DEFAULT_PAGING_SIZE, } from "../../../utilities/staticConfigs";
import { permission_key_values_patient } from "../../../utilities/permissions";
import { NUMBER_OF_STATEMENTS, SELECTED_PATIENT_DROPDOWN } from '../../../utilities/dictionaryConstants'

import Notify from "../../commons/notify";
import CommonButton from '../../commons/Buttons';
import TextInput from "../../commons/input/input";
import RangeInput from "../../commons/input/RangeInput";
import SelectInput from "../../commons/input/SelectInput";
import CalendarInput from "../../commons/input/CalendarInput";
import { ResizableTable } from "../../commons/resizabletable/ResizableTable";

import service from "../patients/service";

import { MaterialMultiselect } from "../../../MaterialMultiselect/MaterialMultiselect";

import { getImportPatientTableData, setImportStateData, getDropDownListData, onTab2StateReset} from "./StatementManagmentRedux/statementManagementSlice";

function ImportPatientList(props) {
  const dispatch = useDispatch();
  const ImportPSMData = useSelector(
    (state) => state.statementMgData?.tab2ImportPatient
  );
  const loading = useSelector(
    (state) => state.statementMgData?.loading
  );

  const errorMsg = useSelector((state)=>state.statementMgData.errorState)

  const resetFlagtable = useSelector((state) => state.statementMgData.resetFlag)

  const { dosFirst, dosSecond, claimDate, claimDate2, balanceType, noStatementSent, lastStatement, selectPatientDropdown
    , selectInsurance, selectFacility, selectProvider, billPR, clmStatus, blnce1, blnce2, patientList, numberOfStatements } = useSelector(
      (state) => state.statementMgData?.tab2ImportPatient?.importpatientStateData
    );

  const { isLoading,
    isFilterDropDownDataReceived,
    InsuranceNameList,
    ProviderList,
    FacilityList,
    BillPRList,
    ClaimStatusList } = useSelector((state) => state.statementMgData?.dropDownListData)

  const [initialTableData, setInitialTableData] = useState(
    i18n.t("commons.noRecords")
  );
  const [patientPK, setPatientPK] = useState("");
  const activePage = 1;
  const setShowLoadingBar = useContext(LoadingContext);
  const [statementQuery, setStatementQuery] = useState("");
  const [patientsStatement, setPatientsStatement] = useState([]);
 // const [balanceError, setBalanceError] = useState("");
 

  const balanceTypeList = [
    { id: 1, name: 'All Balance', alert: '' },
    { id: 2, name: 'Patient Balance', alert: '' },
    { id: 3, name: 'Insurance Balance', alert: '' },
    { id: 4, name: 'All Charges Including Zero Balance', alert: '' },
  ];
  const [form_error, setFormError] = useState({
    dosFirst: "",
    dosSecond: "",
    claimDate: "",
    claimDate2: "",
    balanceType: "",
    selectInsurance: "",
    noStatementSent: "",
    lastStatement: "",
    blnce1: "",
    blnce2: "",
    selectProvider: "",
    selectFacility: "",
    clmStatus: "",
  });
  const practicePK = getStorage("practice");
  // eslint-disable-next-line no-undef
  var fileDownload = require('js-file-download');
  const [innerWidth, setInnerWidth] = useState(window.innerWidth);
  useEffect(() => {
    let newWidth = "";
    newWidth = window.innerWidth;
    let menuWidth = document
      .getElementById("push-bar")
      .classList.contains("open");
    if (!menuWidth) {
      newWidth = newWidth + 250;
    } else {
      newWidth = window.innerWidth;
    }
    setInnerWidth(newWidth);
  }, [window.innerWidth]);



  const [showNotify, setShowNotify] = useState('hide');
  const [notifyDescription, setNotifyDescription] = useState('');
  const [notifyType, setNotifyType] = useState('success');

  const [selectedFileName, setSelectedFileName] = useState("");
  function showNotifyWindow(action, type, desc, age = 3000) {
    if (action == 'show') {
      setTimeout(() => {
        setShowNotify('hide');
      }, age)
    }
    setShowNotify(action);
    setNotifyType(type);
    setNotifyDescription(desc);
  }

  function onHandleDosFirst(selected) {
    dispatch(setImportStateData({ value: selected, node: "dosFirst" }))
  }
  function onHandleDosSecond(selected) {
    dispatch(setImportStateData({ value: selected, node: "dosSecond" }))
  }
  function onHandleClaimDate(selected) {
    dispatch(setImportStateData({ value: selected, node: "claimDate" }))
  }
  function onHandleClaimDate2(selected) {
    dispatch(setImportStateData({ value: selected, node: "claimDate2" }))
  }

  useEffect(()=> {
    if(errorMsg)
      showNotifyWindow('show', 'error', errorMsg )
  },[errorMsg])
 

  useEffect(() => {
    if(resetFlagtable){
     setInitialTableData(i18n.t("commons.noRecords"));
     setPatientsStatement('');
    }
  },[resetFlagtable])

  
  function onHandleChange(e) {
    if (e.target) {
      const name = e.target.name;
      let value = e.target.type === "file" ? e.target.files[0] : e.target.value;
      let trimmedValue = "";
      let inputFields = [
        "dosFirst",
        "dosSecond",
        "claimDate",
        "claimDate2",
        "balanceType",
        "selectInsurance",
        "noStatementSent",
        "lastStatement",
        "blnce1",
        "blnce2",
        "selectProvider",
        "selectFacility",
        "clmStatus",
        "selectPatientDropdown",
        "numberOfStatements",
      ];
      if (inputFields.includes(name)) {
        trimmedValue = value;
      }
      let mandatoryFields = [
        "dosFirst",
        "dosSecond",
        "claimDate",
        "claimDate2",
        "balanceType",
        "selectInsurance",
        "noStatementSent",
        "lastStatement",
        "blnce1",
        "blnce2",
        "selectProvider",
        "selectFacility",
        "clmStatus",
      ];
      if (!trimmedValue && mandatoryFields.includes(name)) {
        setFormError({
          ...form_error,
          [name]: "error",
        });
      } else {
        setFormError({
          ...form_error,
          [name]: "",
        });
      }
      if (name === "noStatementSent" || name === "lastStatement"
        || name === "blnce1" || name === "blnce2") {
        dispatch(setImportStateData({
          value: trimmedValue.replace(/[^0-9.]/g, "")
            .replace(/\.{2,}/g, '.')
            .replace(/^0*([^0]\d*\.\d{1,2}).*/g, "$1"), node: name
        }))
      }
      else {
        dispatch(setImportStateData({ value: trimmedValue, node: name }))
      }
    } else {
      if (e[0]) {
        setPatientPK(e[0].id);
      } else if (!e[0]) {
        setPatientPK("");
      }
    }
  }

  const [checkedPatient, setCheckedPatient] = useState([]);
  /**
   * adding patient on checkbox click 
   * @param {*} e 
   * @param {*} id 
   */
  const onCheck = (e, id) => {
    setShowLoadingBar(true);
    let currentSelectedIds = [];
    checkedPatient.forEach((item) => {
      currentSelectedIds.push(item);
    });
    setCheckedPatient([]);
    let index = currentSelectedIds.indexOf(id);
    if (e.target.checked) {
      if (index === -1) {
        currentSelectedIds.push(id);
      }
    } else {
      if (index !== -1) {
        currentSelectedIds.splice(index, 1);
      }
    }
    setCheckedPatient(currentSelectedIds);
    setShowLoadingBar(false);

  };

  // Dispatch Redux function to get the drop-down list data if it not there already in the state
  useEffect(() => {
    if (!isFilterDropDownDataReceived)
      dispatch(getDropDownListData())
  }, []);


  // Display error notification if any error occurred while fetching filter dropdown list data
  useEffect(() => {
    if (isLoading === true)
      setShowLoadingBar(true)
  }, [isLoading])


  useEffect(() => {
    if (!loading) {   //PSM-> patientStatementData
      setInitialTableData(i18n.t("commons.noRecords"));
      setPatientsStatement(ImportPSMData?.importPatientTableData);
      setShowLoadingBar(false);
    }
  }, [loading])



  function onPrintSelected() {
    setShowLoadingBar(true);
    const result = service.PrintPatientStatement(
      checkedPatient,
      numberOfStatements,
      practicePK
    );
    result.then((response) => {
      if (response.data) {
        fileDownload(response.data, response.headers['content-disposition']);
        getPatientStatement(DEFAULT_PAGING_SIZE, activePage)
        setShowLoadingBar(false);
      } else {
        setShowLoadingBar(false);
      }
    });
  }

  function handleRunStatement() {
    if (patientList.length === 0 || patientList === null) {
      showNotifyWindow('show', 'error', UPLOAD_CSV_ERROR)
    } 
    else  if (blnce1 !== '' && blnce2 !== '' && parseFloat(blnce1) > parseFloat(blnce2)) {
      return;
  } 
    else {
      getPatientStatement(DEFAULT_PAGING_SIZE, activePage)
    }
  }

  /**
   * get patient statment using query query will change depends on the filter change
   * @param {*} pageSize 
   * @param {*} page 
   */
  function getPatientStatement(pageSize, page) {
    setShowLoadingBar(true);
    let query = "";
    if (patientPK) {
      query += "&patient_pk=" + patientPK;
    }
    if (dosFirst) {
      let dos_from = dosFirst && !isNaN(Date.parse(dosFirst)) ? format(dosFirst, "yyyy-MM-dd") : "";
      query += "&dos_from=" + dos_from;
    }
    if (dosSecond) {
      let dos_to = dosSecond && !isNaN(Date.parse(dosSecond)) ? format(dosSecond, "yyyy-MM-dd") : "";
      query += "&dos_to=" + dos_to;
    }
    if (claimDate) {
      let claim_entered_from = claimDate && !isNaN(Date.parse(claimDate))
        ? format(claimDate, "yyyy-MM-dd")
        : "";
      query += "&entered_from=" + claim_entered_from;
    }
    if (claimDate2) {
      let claim_entered_to = claimDate2 && !isNaN(Date.parse(claimDate2))
        ? format(claimDate2, "yyyy-MM-dd")
        : "";
      query += "&entered_to=" + claim_entered_to;
    }
    if (balanceType) query += "&balance_type=" + balanceType;
    if (selectInsurance) query += "&insurance=" + selectInsurance;
    if (noStatementSent) query += "&no_of_statement=" + noStatementSent;
    if (lastStatement) query += "&since_last_statement=" + lastStatement;
    if (blnce1) query += "&claim_balance_from=" + blnce1;
    if (blnce2) query += "&claim_balance_to=" + blnce2;
    if (selectProvider) query += "&provider=" + selectProvider;
    if (billPR) query += "&bill_pr_code=" + billPR;
    if (clmStatus) query += "&claim_status=" + clmStatus;

    if (selectPatientDropdown === 1) {
      if (patientList) query += "&patient_list=" + patientList;
    }
    else if (selectPatientDropdown === 2) {
      if (patientList) query += "&claim_list=" + patientList;
    }

    const filterDatas = {
      dos_from: dosFirst,
      dos_to: dosSecond,
      entered_from: claimDate,
      entered_to: claimDate2,
      balance_type: balanceType,
      insurance: selectInsurance,
      no_of_statement_sent: noStatementSent,
      since_last_statement: lastStatement,
      claim_balance_from: blnce1,
      claim_balance_to: blnce2,
      provider: selectProvider,
      select_facility: selectFacility,
      bill_pr_code: billPR,
      claim_status: clmStatus,
      patient_list: patientList,
      claim_list: patientList

    }

    let data = {
      query: query,
      pageSize: pageSize,
      page: page,
      practicePK: practicePK,
      filterData: filterDatas
    }
    dispatch(getImportPatientTableData(data));
    setStatementQuery(query);
  }

  function getPatientPrevData() {
    let data = []
    patientsStatement.results.filter(item => {
      if (checkedPatient.includes(item.id)) {
        data.push(item)
      }
    })
    props.OnPreview(data, statementQuery)
  }

 
  function onHandleCsvImport(e) {
    e.preventDefault();
    document.getElementById('file').value = null;
    document.getElementById('file').click();

  }

  function onHandleCSVChange(e) {
    setShowLoadingBar(true);
    const value = e.target.files[0];
    setSelectedFileName(value.name);
    if (value && csvFileCheck(e.target.files[0].name)) {
      const data = new FormData();
      data.append('file', value);
      let result = service.ImportPatientListCsv(selectPatientDropdown, data);
      result.then(response => {
        if (response.status === 200) {
          dispatch(setImportStateData({ value: response.data.data.toString(), node: "patientList" }))
          showNotifyWindow('show', 'success', i18n.t("statementManagement.csvSuccess"))
        } else if (response.data?.message === "invalid_patient" && response.data?.data?.patient_id) {
          const patientId = response.data.data.patient_id;
          showNotifyWindow('show', 'error', `${i18n.t("errorMessages.invalid_patient")} ${patientId}`);
          setSelectedFileName("")
        } else if (response.data?.message === "invalid_headings") {
          showNotifyWindow('show', 'error', i18n.t("errorMessages.invalid_heading"));
          setSelectedFileName("");
        } else {
          showNotifyWindow('show', 'error', i18n.t("errorMessages.commonError"));
        }
      }).finally(() => {
        setShowLoadingBar(false);
      })
    }
    else {
      showNotifyWindow(
        "show",
        "error",
        i18n.t("errorMessages.invalid_file_format")
      );
      setShowLoadingBar(false);
    }
  }

  function onHandleCsvExport(e, type) {
    let query = statementQuery;
    let pageSize = DEFAULT_PAGING_SIZE
    query += '&page=0&export=true&export_type=' + type;
    service.ListPatientsStatements(query, pageSize,type, practicePK).then((result) => {
      if (result.status === 200 || result.status === 201) {
        fileDownload(result.data, result.headers['content-disposition']);
        setShowLoadingBar(false);
        showNotifyWindow('show', 'success', i18n.t("commons.successExportCsv"));
      } else {
        showNotifyWindow('show', 'error', i18n.t("commons.patientNotFound"));
      }
    })
      .catch(() => {
        showNotifyWindow('show', 'error', i18n.t("commons.unexpectedError"));
        setShowLoadingBar(false);
      });
  }

  function MaterialMultiSelectHandle(e) {
    let name = e.target.name;
    let value = e.target.value;
    if (name === "selectInsurance") {
      dispatch(setImportStateData({ value: value, node: name }));
    }
    else if (name === "selectProvider") {
      dispatch(setImportStateData({ value: value, node: name }));
    }
    else if (name === "billPR") {
      dispatch(setImportStateData({ value: value, node: name }));
    }
    else if (name === "clmStatus") {
      dispatch(setImportStateData({ value: value, node: name }));
    }
    else if (name === "selectFacility") {
      dispatch(setImportStateData({ value: value, node: name }));
      let service_locations = [];
      let service_location_types = [];
      if (value.length > 0) {
        value.forEach((item) => {
          service_locations.push(item.split("||")[0]);
          service_location_types.push(item.split("||")[1]);
        });
      }
      dispatch(setImportStateData({ value: value, node: "serviceLocation" }));
    }
  }

  function onResetFilter() {
    dispatch(onTab2StateReset())
    setSelectedFileName("")
  }

  return (
    <div className="">
      <Notify showNotify={showNotify} setShowNotify={setShowNotify} notifyDescription={notifyDescription} setNotifyType={setNotifyType} setNotifyDescription={setNotifyDescription} notifyType={notifyType} />

      <Form autoComplete="off" className="searchBox pl-4 pb-5">
        <div className="row" >
          <div className="col-2">
            <SelectInput
              data={SELECTED_PATIENT_DROPDOWN}
              selectOptionRemove={true}
              name="selectPatientDropdown"
              value={selectPatientDropdown}
              onValueChange={onHandleChange}
              label={i18n.t("statementManagement.importOptions")}
            />
          </div>
          <div className="col-2 margin-top-25" >
            <OverlayTrigger
              placement="top"
              delay={{ show: 250, hide: 400 }}
              overlay={
                <Tooltip
                  id="download-tooltip"
                  style={{
                    opacity: 0.4,
                  }}
                >
                  {i18n.t("commons.importCSV")}
                </Tooltip>
              }
            >
              <CommonButton variant="outlined" label="Upload" icon={'upload'}
                                    onClick={(e) => onHandleCsvImport(e)} />                      
            </OverlayTrigger>
            <input name="csvFile" type="file" accept=".csv" id="file" onChange={onHandleCSVChange} style={{ display: "none" }} />
          </div>
        </div>
            {selectedFileName?
             <div style={{marginBottom:'7px'}}>
              <span style={{color:'green',fontSize:'12px' }}>Currently Processing File : {selectedFileName}</span></div>
              :null}
        
        {selectPatientDropdown !== 2 && <div>
          <div className="row">
            <div className="col-3">
              <CalendarInput
                label={i18n.t("statementManagement.dosBw")}
                name="dosFirst"
                selected={dosFirst}
                onValueChange={(selected) => onHandleDosFirst(selected)}
              />
            </div>
            <div className="col-3 padding-top7">
                <CalendarInput
                  label={""}
                  name="dosSecond"
                  selected={dosSecond}
                  minDate={dosFirst}
                  onValueChange={(selected) => onHandleDosSecond(selected)}
                />
            </div>
            <div className="col-3 ">
              <CalendarInput
                label={i18n.t("statementManagement.claimDate")}
                name="claimDate"
                selected={claimDate}
                onValueChange={(selected) => onHandleClaimDate(selected)}
              />
            </div>
            <div className="col-3 ">
              <div className="padding-top7">
                <CalendarInput
                  label=""
                  name="claimDate2"
                  selected={claimDate2}
                  minDate={claimDate}
                  onValueChange={(selected) => onHandleClaimDate2(selected)}
                />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-3">
              <SelectInput
                data={balanceTypeList}
                name="balanceType"
                value={balanceType}
                onValueChange={onHandleChange}
                label={i18n.t("statementManagement.balanceType")}
              />
            </div>
            <div className="col-3 ">
              <MaterialMultiselect
                name="selectInsurance"
                value={selectInsurance}
                onValueChange={MaterialMultiSelectHandle}
                options={InsuranceNameList}
                label={i18n.t("statementManagement.selectInsurance")}
              />
            </div>
            <div className="col-3 ">
              <TextInput
                type="text"
                name="noStatementSent"
                label={i18n.t("statementManagement.noStatement")}
                onValueChange={onHandleChange}
                value={noStatementSent}
              />
            </div>
            <div className="col-3 ">
              <TextInput
                type="text"
                name="lastStatement"
                label={i18n.t("statementManagement.lastStatement")}
                onValueChange={onHandleChange}
                value={lastStatement}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-3 padding-top7">
              <RangeInput
                name1="blnce1"
                name2="blnce2"
                value1={blnce1}
                value2={blnce2}
                onValueChange1={onHandleChange}
                onValueChange2={onHandleChange}
                ValidationMsg={(i18n.t("commons.balanceError"))}
                label={i18n.t("statementManagement.claimBlnce")}
              />
           
            </div>
            <div className="col-3  padding-top7">
              <MaterialMultiselect
                name="selectProvider"
                value={selectProvider}
                onValueChange={MaterialMultiSelectHandle}
                options={ProviderList}
                label={i18n.t("statementManagement.seleProvider")}
              />
            </div>
            <div className="col-3  padding-top7">
              <MaterialMultiselect
                name="selectFacility"
                value={selectFacility}
                onValueChange={MaterialMultiSelectHandle}
                options={FacilityList}
                label={i18n.t("statementManagement.selFacility")}
              />
            </div>
            <div className="col-3  padding-top7">
              <MaterialMultiselect
                name="billPR"
                value={billPR}
                onValueChange={MaterialMultiSelectHandle}
                options={BillPRList}
                label={i18n.t("statementManagement.billPR")}
              />
            </div>
          </div>
        </div>}

        <div className="row">
          {selectPatientDropdown !== 2 &&
            <div className="col-3 padding-top7">
              <MaterialMultiselect
                name="clmStatus"
                value={clmStatus}
                onValueChange={MaterialMultiSelectHandle}
                options={ClaimStatusList}
                label={i18n.t("statementManagement.clmStatus")}
              />
            </div>}
          <div className="col-9">
            <div className="form-group padding-top20">
              <div className="div-float-right padding-top6">
                <span className="margin-right15" >  
                  <CommonButton 
                    onClick={onResetFilter} variant="outlined" label={i18n.t("claimsFilter.reset")}
                  />
                </span>

                <CommonButton 
                  onClick={() => handleRunStatement() } 
                  variant="contained"
                  label={i18n.t("statementManagement.runStatement")}
                />
              </div>
            </div>
          </div>
        </div>
      </Form>
      <Form autoComplete="off">
        <div className="row mt-3">
          <div className="col">

            <div className="div-float-right padding-top25">
              {checkPermission(permission_key_values_patient.import_patient_list_tab_modify) &&
                <button type="button" className="btn btn-primary-blue"
                  disabled={checkedPatient.length <= 0}
                >
                  {i18n.t("statementManagement.sndSelected")}
                </button>
              }
            </div>
            <div className="div-float-right col-3">
              {checkPermission(permission_key_values_patient.import_patient_list_tab_modify) &&
                <SelectInput
                  data={NUMBER_OF_STATEMENTS}
                  name="numberOfStatements"
                  value={numberOfStatements}
                  onValueChange={onHandleChange}
                  label={"No of Statement"}
                  selectOptionRemove={true}
                />
              }
            </div>
            <div className="div-float-right padding-right20 padding-top26">
              {checkPermission(permission_key_values_patient.import_patient_list_tab_modify) &&
                <button type="button" className="btn btn-primary-blue"
                  onClick={onPrintSelected} disabled={checkedPatient.length <= 0}
                >
                  {i18n.t("statementManagement.prntSelected")}
                </button>
              }
            </div>
            <div className="div-float-right margin-bottom3 margin-top26 padding-right20">
              {checkPermission(permission_key_values_patient.import_patient_list_tab_modify) &&
                <button type="button" className="btn btn-primary-blue btn-common-height35"
                  onClick={getPatientPrevData} disabled={checkedPatient.length <= 0}
                >
                  {i18n.t("statementManagement.previewAll")}
                </button>
              }
            </div>
            <div className="div-float-right padding-right20 margin-top26">
              {
                <OverlayTrigger
                  placement="top"
                  delay={{ show: 250, hide: 400 }}
                  overlay={
                    <Tooltip
                      id="download-tooltip"
                      style={{
                        opacity: 0.4,
                      }}
                    >
                    </Tooltip>
                  }
                >
                  <CommonButton disabled={!statementQuery.split("&patient_list=")?.[1]} variant="outlined" label="Download" icon={'download'}
                    onClick={(e) => onHandleCsvExport(e, 'csv')} />
                </OverlayTrigger>

              }
            </div>
          </div>
        </div>
        <div className="form-group margin-top10">
          <div
            style={{
              overflowX: "auto",
              width: "100%",
              maxWidth: innerWidth - 300 + "px",
              maxHeight: "500px",
            }}
          >
            <ResizableTable
              className="fixed_head "
              id={"overflow_auto"}
              tableHeadings={
                <TableHeadings />
              }
              tableRows={
                <TableBodyData
                  patientsStatement={patientsStatement} 
                  initialTableData={initialTableData}
                  statementQuery={statementQuery}
                  OnPreview={([item], statementQuery) => props.OnPreview([item], statementQuery)}
                  onCheck={onCheck}
                  checkedPatient={checkedPatient} />
              }
            />
          </div>
        </div>
      </Form>
    </div>
  );
}

/**
 * table heading rendered here
 * @returns 
 */
function TableHeadings() {
  return (
    <tr>
      <th className="longTableHead">
        {i18n.t("statementManagement.guarntorName")}
        <span className="resize-handle"></span>
      </th>
      <th className="longTableHead">
        {i18n.t("statementManagement.patientName")}
        <span className="resize-handle"></span>
      </th>
      <th className="shortTableHead">
        {i18n.t("statementManagement.patientId")}
        <span className="resize-handle"></span>
      </th>
      <th className="shortTableHead">
        {i18n.t("statementManagement.statementBlnc")}
        <span className="resize-handle"></span>
      </th>
      <th className="shortTableHead">
        {i18n.t("statementManagement.statementSent")}
        <span className="resize-handle"></span>
      </th>
      <th className="shortTableHead">
        {i18n.t("statementManagement.preview")}
        <span className="resize-handle"></span>
      </th>
      <th className="max-width_21percent">
        {i18n.t("statementManagement.select")}
        <span className="resize-handle"></span>
      </th>
    </tr>
  )
}

/**
 * table body rendered here
 * @param {*} props 
 * @returns 
 */
function TableBodyData(props) {
  return props.patientsStatement.length === 0 ? (
    <tr>
      <td align="center" colSpan={8}>
        {props.initialTableData}
      </td>
    </tr>
  ) : (
    props.patientsStatement.results.map((item, index) => {
      return (
        <tr key={item.id}>
          <td>{item.guarantor_name}</td>
          <td>{item.patient_name}</td>
          <td className="width-125px" align="center">
            {item.custom_patient_id}
          </td>
          <td className="width-125px" align="right">
            {item.statement_balance
              ? "$ " + Number(item.statement_balance).toFixed(2)
              : ""}
          </td>
          <td className="width-125px" align="center">
            {item.no_of_statement_sent}
          </td>
          <td className="width-125px" align="center">
            <Link to="#" className="button"
              onClick={() => props.OnPreview([item], props.statementQuery)}
            >
              {i18n.t("statementManagement.view")}
            </Link>
          </td>
          <td className="width-125px" align="center">
            <div className="custom-checkbox lh-1">
              <input
                type="checkbox"
                id={`chkPatient${index}`}
                checked={
                  props.checkedPatient.indexOf(item.id) === -1
                    ? false
                    : true
                }
                name="chkPatient"
                disabled={!checkPermission(permission_key_values_patient.import_patient_list_tab_modify)}
                onChange={(e) =>
                  props.onCheck(e, item.id)
                }
                className={`checkbox-${props.checkedPatient.indexOf(item.id) === -1 ? false : true}`}
              />
              <label
                className="checkbox"
                htmlFor={`chkPatient${index}`}
              >
              </label>
            </div>
          </td>
        </tr>
      );
    })
  )
}

export default ImportPatientList;
