import i18n from "../../utilities/i18n";

export const claimListHeadings = [
    i18n.t("payments.post_payments.insurancePayment.table1.post"),
    i18n.t("payments.post_payments.insurancePayment.table1.claimId"),
    i18n.t("payments.post_payments.insurancePayment.table1.patientName"),
    i18n.t("payments.post_payments.insurancePayment.table1.patientId"),
    i18n.t("payments.post_payments.insurancePayment.table1.billingProvider"),
    i18n.t("payments.post_payments.insurancePayment.table1.dosFrom"),
    i18n.t("payments.post_payments.insurancePayment.table1.submitted"),
    i18n.t("payments.post_payments.insurancePayment.table1.charges"),
    i18n.t("payments.post_payments.insurancePayment.table1.payments"),
    i18n.t("payments.post_payments.insurancePayment.table1.adjustments"),
    i18n.t("payments.post_payments.insurancePayment.table1.balance"),
    i18n.t("payments.post_payments.insurancePayment.table1.respPayer"),
    i18n.t("payments.post_payments.insurancePayment.table1.claimStatus"),
];


export const procedureHeadings = [
    i18n.t("payments.post_payments.insurancePayment.table2.claimId"),
    i18n.t("payments.post_payments.insurancePayment.table2.dos"),
    i18n.t("payments.post_payments.insurancePayment.table2.procedure"),
    i18n.t("payments.post_payments.insurancePayment.table2.charges"),
    i18n.t("payments.post_payments.insurancePayment.table2.receipts"),
    i18n.t("payments.post_payments.insurancePayment.table2.opening_balance"),
    i18n.t("payments.post_payments.insurancePayment.table2.allowedAmount"),
    i18n.t("payments.post_payments.insurancePayment.table2.paidAmount"),
    i18n.t("payments.post_payments.insurancePayment.table2.adjustment_1_code"),
    i18n.t("payments.post_payments.insurancePayment.table2.adjustment-1"),
    i18n.t("payments.post_payments.insurancePayment.table2.adjustment_2_code"),
    i18n.t("payments.post_payments.insurancePayment.table2.adjustment-2"),
    i18n.t("payments.post_payments.insurancePayment.table2.coPay"),
    i18n.t("payments.post_payments.insurancePayment.table2.coInsurance"),
    i18n.t("payments.post_payments.insurancePayment.table2.deductible"),
    'Total PR',
    i18n.t("payments.post_payments.insurancePayment.table2.remarkCode"),
    i18n.t("payments.post_payments.insurancePayment.table2.closingBalance"),
    i18n.t("payments.post_payments.insurancePayment.table2.responsibilityType"),
    i18n.t("payments.post_payments.insurancePayment.table2.remove"),
    i18n.t("payments.post_payments.insurancePayment.table2.reverse"),
  ];