import api from '../../../service/api'

const ListAssociateUsers = async (pageSize, page, practicePK,status) => {
	let path = 'user/associate-user/?page_size=' + pageSize + '&page=' + page;
	if (practicePK)
		path += '&practice_pk=' + practicePK;
		if (status)
		path += '&status=' + status;
	return api.get(path);
}

const AddAssociateUser = async (data) => {
	let path = 'user/associate-user/';
	return api.post(path, data);
}

const GetAssociateUser = async (associateId) => {
	let path = 'user/associate-user/' + associateId;
	return api.get(path);
}

const UpdateAssociateUser = async (associateId, data) => {
	let path = 'user/associate-user/' + associateId;
	return api.put(path, data);
}

const DeleteAssociateUser = async (associateId, page, currentPracticePK) => {
	let path = 'user/associate-user/' + associateId;
	if (page) path += '?page=' + page + '&current_practice_pk=' + currentPracticePK;
	return api.destroy(path);
}

const ListPractices = async (pageSize, page, practicePK) => {
	let path = 'practice/practice/?page_size=' + pageSize + '&page=' + page + '&list=true';
	if (practicePK)
		path += '&practice_pk=' + practicePK;
	return api.get(path);
}

const ListRoles = async (pageSize, page, practicePK) => {
	let path = 'user/user-roles/?page_size=' + pageSize + '&page=' + page + '&list=true';
	if (practicePK)
		path += '&practice_pk=' + practicePK;
	return api.get(path);
}
const ResendEmail = async (data) => {
	let path = 'user/resend-email/';
	return api.post(path, data);
}

const getPracticeSAndRoles = async () => {
	const isAdminModule = localStorage.getItem('isAdminModule');
	const queryParams = isAdminModule === 'true' ? "" : `/?current_practice_pk=${localStorage.getItem('practice')}`
	return await api.get(`practice/practice-role${queryParams}`);
}
const getRolesToUserMgmnt = async (pageSize,page) => {
	const path = 'user/staff-user-roles/?page_size=' + pageSize + '&page=' + page;
	return api.get(path);
}

const unlockUserAccount = async (data) => {

	return api.post('user/unlock-user-account/', data)
}

export default {
	ListAssociateUsers, AddAssociateUser, GetAssociateUser,getRolesToUserMgmnt,
	UpdateAssociateUser, DeleteAssociateUser, ListPractices, ListRoles, ResendEmail, getPracticeSAndRoles, unlockUserAccount
}