import { format } from "date-fns";
import api from "../../service/api";

// inbox listing
const ListInbox = (query) => {
    let path = 'practice/messages/detail/' + query;
    return api.get(path);
}
// inbox delete
const DeleteMessage = async (data) => {
    let path = 'practice/messages/delete/';
    return api.put(path, data);
}

// compose list contacts
const ListPracticeUsers = (practicePK) => {
    let path = 'practice/practice-users/?practice_pk=' + practicePK;
    return api.get(path);
}
const SearchPracticeUsers = (practicePK, query) => {
    let path = 'practice/practice-users/?practice_pk=' + practicePK + '&search=' + query;
    return api.get(path);
}
// compose message
const ListSendMessages = (query) => {
    let path = 'practice/messages/' + query;
    return api.get(path);
}
const SendMessage = async (data) => {
    let path = 'practice/messages/'
    return api.post(path, data);
}

// send read status
const SendReadStatus = async (id, data) => {
    let path = 'practice/messages/detail/';
    return api.put(path, data);
}
const SendUnReadStatus = async (practicePK, data) => {
    let path = 'practice/messages/unread/count/?practice_pk=' + practicePK;
    return api.put(path, data);
}
// draft listing
const ListDraftMessages = (query) => {
    let path = 'practice/messages/draft/' + query;
    return api.get(path);
}

const SendDraftMessages = async (data) => {
    let path = 'practice/messages/draft/';
    return api.put(path, data);
}

// get inbox message by id
const GetInboxMessageDetail = (id, practicePK, page_size, page) => {
    let path = 'practice/messages/detail/?message_id=' + id + '&practice_pk=' + practicePK + '&page_size=' + page_size + '&page=' + page;
    return api.get(path);
}
const GetSendMessageDetail = (id, practicePK, page_size, page) => {
    let path = 'practice/messages/?message_id=' + id + '&practice_pk=' + practicePK + '&page_size=' + page_size + '&page=' + page;
    return api.get(path);
}
const GetDraftMessageDetail = (id) => {
    let path = 'practice/messages/draft/?message_id=' + id;
    return api.get(path);
}

const SearchInboxMessage = (...args) => {
    let [practicePK, from_user, to_user, subject, body, dateOfServiceRange, dateOne, dateTwo] = args;
    let path = 'practice/messages/search/?practice_pk=' + practicePK;
    if (from_user) {
        path = path + '&from_user=' + from_user
    }
    if (to_user) {
        path = path + '&to_user=' + to_user
    }
    if (subject) {
        path = path + '&subject=' + subject
    }
    if (body) {
        path += '&message_body=' + body;
    }
    if (dateOfServiceRange) {
        let date_filter = {
            'start_date': dateOne ? format(dateOne, "yyyy-MM-dd") : "",
            'end_date': dateTwo ? format(dateTwo, "yyyy-MM-dd") : ""
        }
        path += '&date_filter=' + JSON.stringify(date_filter);
    }
    return api.get(path);
}
const SearchSendMessage = (practicePK, to_user, subject, body, dateOfServiceRange, dateOne, dateTwo) => {
    let path = 'practice/messages/?practice_pk=' + practicePK;
    if (to_user) {
        path = path + '&to_user=' + to_user
    }
    if (subject) {
        path = path + '&subject=' + subject
    }
    if (body) {
        path += '&message_body=' + body;
    }
    if (dateOfServiceRange) {
        let date_filter = { "start_date": dateOne, "end_date": dateTwo }
        path += '&date_filter=' + JSON.stringify(date_filter);
    }
    return api.get(path);
}

const GetMessageTemplateList = async (id, pageSize, page, searchValue) => {
    let path = `practice/messages/templates/?practice_pk=${id}&page_size=${pageSize}&page=${page}`;
    if (searchValue)
        path = path +  '&search='+ searchValue;
        return await api.get(path);
}
const CreateMessageTemplate = async (data) => {
    let path = 'practice/messages/templates/';
    return await api.post(path, data);
}

const GetMessageTemplateDetail = async (id) => {
    let path = 'practice/messages/templates/' + id;
    return await api.get(path);
}

const UpdateMessageTemplate = async (body, id) => {
    let path = 'practice/messages/templates/' + id;
    return await api.put(path, body);
}

const DeleteMessageTemplate = async (id) => {
    let path = 'practice/messages/templates/' + id;
    return api.destroy(path);
}

export default {
    // query inbox message
    SearchInboxMessage, SearchSendMessage,
    // delete
    DeleteMessage, DeleteMessageTemplate,
    // post
    SendMessage, CreateMessageTemplate,
    // get List
    ListSendMessages, ListInbox, ListPracticeUsers, ListDraftMessages, SearchPracticeUsers, GetMessageTemplateList,
    // get by id
    GetInboxMessageDetail, GetSendMessageDetail, GetDraftMessageDetail, GetMessageTemplateDetail,
    // put
    SendReadStatus, SendDraftMessages, SendUnReadStatus, UpdateMessageTemplate
}