import React from 'react';
import './style.css';
import { Link } from 'react-router-dom';
import { DICT_TYPE_PAYERS, DICT_TYPE_CODES, DICT_TYPE_PATIENT_DEMOGRAPHICS, DICT_TYPE_BILLING, DICT_TYPE_APPOINTMENTS, DICT_TYPE_GENERAL, DICT_TYPE_CONSTANTS_COL1, DICT_TYPE_CONSTANTS_COL2, DICT_TYPE_CONSTANTS_COL3 } from '../../../utilities/dictionaryConstants'; 

const DictionariesListAll = () => {  
    const arrDictTypes_1 = [ DICT_TYPE_PAYERS, DICT_TYPE_CODES,  ];
    const arrDictTypes_2 = [ DICT_TYPE_BILLING];
    const arrDictTypes_3 = [ DICT_TYPE_APPOINTMENTS, DICT_TYPE_PATIENT_DEMOGRAPHICS, DICT_TYPE_GENERAL ];

    const arrDictTypes_4 = [DICT_TYPE_CONSTANTS_COL1];
    const arrDictTypes_5 = [DICT_TYPE_CONSTANTS_COL2];
    const arrDictTypes_6 = [DICT_TYPE_CONSTANTS_COL3];

    function ListDicTypes({data}){
        return( 
            <div className="dictionary-type" >
                <div className="dictionary-caption" data-testid='dictionary-caption-1'>{data.dictType}</div>
                {Array.isArray(data.dictItems) && data.dictItems.length && 
                data.dictItems.map((eachItem, index1)  => {
                    if(eachItem.routeLink.length > 1){
                        return( 
                            <div key={index1} className="dictionary-item" data-testid='dictionary-item-1'>
                                <Link data-testid='dictionary-item-1-link' to={eachItem.routeLink}>{eachItem.name}</Link>
                            </div>
                            )
                    }
                    else if(eachItem.subItems.length>0){
                        return( 
                            <div key={index1}>
                                <div className="dictionary-constant-caption" data-testid='dict-constant-caption'>{eachItem.name}</div>
                                {Array.isArray(eachItem.subItems) && eachItem.subItems.length && 
                                  eachItem.subItems.map((subItem, index2)  => {
                                    return(
                                          <div key={index2} className="dictionary-constant-item" data-testid='dict-constant-item'>{subItem.name}</div>)
                                  })}
                                
                            </div>
                        ) 
                    }
                    else{
                        return( 
                            <div className="dictionary-item" key={index1}>
                                <div className="dictionary-non-link-text">{eachItem.name}</div>
                            </div>
                        )
                    } 

                })
                }
            </div>
        )
    }


    return (
        <React.Fragment>
            

            <div className="col-md-12">
                <div className="box dictionary-box">
                    <div className="dictionaries-list dictionary-box"> 


                        <div className="dictionary-column dictionary-box">
                            {Array.isArray(arrDictTypes_1) && arrDictTypes_1.length && 
                                arrDictTypes_1.map((mainItem) => {
                                    return <ListDicTypes key={mainItem} data={mainItem} />   
                                })
                            }  
                        </div>
                    
                        <div className="dictionary-column">
                            {Array.isArray(arrDictTypes_2) && arrDictTypes_2.length && 
                                arrDictTypes_2.map((mainItem) => {
                                    return <ListDicTypes key={mainItem} data={mainItem} />  
                                })
                            }  
                        </div>
                    
                        <div className="dictionary-column">
                            {Array.isArray(arrDictTypes_3) && arrDictTypes_3.length && 
                                arrDictTypes_3.map((mainItem) => {
                                    return <ListDicTypes key={mainItem} data={mainItem} />  
                                })
                            }  
                        </div>
                    </div>
                </div>
            </div>


            <div className="col-md-12">   
                <div className="box dictionary-box">
                    <div className="dictionaries-list  dictionary-box"> 

                        <div className="dictionary-column">
                            {Array.isArray(arrDictTypes_4) && arrDictTypes_4.length && 
                                arrDictTypes_4.map((mainItem) => {
                                    return <ListDicTypes key={mainItem} data={mainItem} />  
                                })
                            }  
                        </div>

                        <div className="dictionary-column">
                            {Array.isArray(arrDictTypes_5) && arrDictTypes_5.length && 
                                arrDictTypes_5.map((mainItem) => {
                                    return <ListDicTypes key={mainItem} data={mainItem} />  
                                })
                            }  
                        </div>

                        <div className="dictionary-column">
                            {Array.isArray(arrDictTypes_6) && arrDictTypes_6.length && 
                                arrDictTypes_6.map((mainItem) => {
                                    return <ListDicTypes key={mainItem} data={mainItem} />  
                                })
                            }  
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment> 
    )
}

export default DictionariesListAll; 