import React, { useState, useEffect, useContext } from 'react';
import { Form } from 'react-bootstrap';
import { useLocation  } from 'react-router-dom';

import service from './service';
import { ClaimSubStatusTableObject, ClaimSubStatusTableBodyOnly } from './ClaimSubStatusTable';

import LoadingContext from '../../../container/loadingContext';

import CustomizedDialogs from '../../modalWindowComponent/CustomizedDialogs';
import CustomizedSmallDialogs from '../../modalWindowComponent/CustomisedSmallDialog';

import i18n from '../../../utilities/i18n';
import { getStorage } from '../../../utilities/browserStorage';
import { checkPermission, commonTableBody } from '../../../utilities/commonUtilities';
import { ADD_SUCCESS, ADD_ERROR, UPDATE_SUCCESS, DELETE_SUCCESS } from '../../../utilities/labelConfigs';
import { permission_key_values_practice_dictionaries, super_admin_privileges, super_admin_permission_key_value } from '../../../utilities/permissions';
import { ROUTE_SUPER_DICTIONARIES, ROUTE_PRACTICE_DICTIONARIES, PAGING_END_INDEX, DEFAULT_PAGING_SIZE, ROUTE_CLAIM_SUB_STATUSES } from '../../../utilities/staticConfigs';

import Notify from '../../commons/notify';
import Table from '../../commons/Table/Table'; 
import Pagination from '../../commons/pagination';
import TextInput from '../../commons/input/input';
import BackArrowWithLabel from "../../commons/Back"; 
import CommonButton from '../../commons/Buttons';
import MultiSelectAutoComplete from '../../../MaterialMultiselect/MultiSelectAutoComplete';

const ClaimSubStatusList = (props) => {
    const isAdminModule = getStorage('isAdminModule');
    const location = useLocation();
    const practicePK = getStorage("practice");
    const [showModalWindow, setShowModalWindow] = useState(false);
    const [showDeleteModalWindow, setShowDeleteModalWindow] = useState(false);
    const [ClaimSubStatusType, setClaimSubStatusType] = useState('');
    const [deleteClaimSubStatusName, setDeleteClaimSubStatusName] = useState('');
    const [deleteClaimSubStatusId, setDeleteClaimSubStatusId] = useState('');
    const [editClaimSubStatusId, setEditClaimSubStatusId] = useState('');
    const [ClaimSubStatusTypeList, setClaimSubStatusTypeList] = useState([]);
    const [form_error, setFormError] = useState({ 'ClaimSubStatusType': '' });
    const [editForm, setEditForm] = useState(false);
    const [header, setHeader] = useState('');
    const [permission, setPermission] = useState(false);
    const [searchValue, setSearchValue] = useState('');
    const [defaultValue, setdefaultValue] = useState(false);

    const [adjustmentCodesList, setAdjustmentCodesList] = useState([]);
    const [adjustmentCodesData, setAdjustmentCodesData] = useState([]);

    //start ==== Alert message properties
    const [showNotify, setShowNotify] = useState('hide');
    const [notifyDescription, setNotifyDescription] = useState('');
    const [notifyType, setNotifyType] = useState('success');
    const setShowLoadingBar = useContext(LoadingContext);

    //Pagination start
    const [totalPage, setTotalPage] = useState(1);
    const [activePage, setActivePage] = useState(1);
    const [startIndex, setStartIndex] = useState(0);
    const [endIndex, setEndIndex] = useState(PAGING_END_INDEX);

    function onPagePrevious() {
        let previousPage = startIndex + 1 - PAGING_END_INDEX;
        setActivePage(previousPage);
        if (startIndex !== 0) {
            setStartIndex(startIndex - PAGING_END_INDEX);
            setEndIndex(endIndex - PAGING_END_INDEX);
        }
        getClaimSubStatusData(DEFAULT_PAGING_SIZE, previousPage,searchValue);
    }

    function onPageUp(e) {
        let page = Number(e.target.id)
        setActivePage(page);
        getClaimSubStatusData(DEFAULT_PAGING_SIZE, page,searchValue);
    }

    function onPageNext() {
        let nextPage = startIndex + 1 + PAGING_END_INDEX;
        if (endIndex === totalPage || totalPage <= PAGING_END_INDEX) {
            setActivePage(nextPage);
            setStartIndex(startIndex);
            setStartIndex(endIndex);
        } else {
            setActivePage(nextPage);
            setStartIndex(startIndex + PAGING_END_INDEX);
            setEndIndex(endIndex + PAGING_END_INDEX);
        }
        getClaimSubStatusData(DEFAULT_PAGING_SIZE, nextPage,searchValue);
    }
    //Pagination ends

    const getAdjustmentCodes = async (pageSize, page, search = "") => {
        try {
            const listType = "auto";
            const isAdminModuleFlag = isAdminModule;
    
            const response = await service.GetListAdjustmentReasonCode(
                pageSize,
                page,
                search,
                listType,
                isAdminModuleFlag,
                practicePK
            );
    
            const responseData = response.data?.results?.map(item => ({
                id: item.id,
                name: item.name?.length > 50 ? `${item.name.substring(0,90)}...` : item.name
            }));
            setAdjustmentCodesList(responseData);
        } catch (error) {
            console.error("Error fetching adjustment codes: ", error);
        }
    };
    
    const handleValueChange = (newSelectedValues) => {    
        setAdjustmentCodesData(newSelectedValues);
    };

    function showNotifyWindow(action, type, desc, age = 3000) {
        if (action == 'show') {
            setTimeout(() => {
                setShowNotify('hide');
            }, age)
        }
        setShowNotify(action);
        setNotifyType(type);
        setNotifyDescription(desc);
    }
    //End ====
    function addNew() {
        if (!permission) {
			showNotifyWindow('show','error', i18n.t('errorMessages.permission_error'));
			return;
		}
        setHeader(i18n.t("dictionariesPages.claimSubStatusList.addHeader"));
        setShowModalWindow(true);
        setAdjustmentCodesData([]);
        setAdjustmentCodesList([]);
    }
    function onHide() {
        setEditForm(false);
        setdefaultValue(false);
        setAdjustmentCodesData([]);
        setAdjustmentCodesList([]);
    }
    function onDeleteHide() {
        setDeleteClaimSubStatusName('');
        setDeleteClaimSubStatusId('');
        setShowDeleteModalWindow(false);
    }

    function getClaimSubStatusData(pageSize, page, searchValue) {
        setShowLoadingBar(true);
        let result = null;
        //result based on admin or practice if admin all claim substatus is listed 
        //if practice claimsubstatus based on practice will listed
        result = service.ListClaimSubStatusTypes(pageSize, page, isAdminModule, isAdminModule === 'true' ? '' : practicePK,searchValue);    
        result.then(async response => {
            if (response.data.results !== undefined) {
                setTotalPage(Math.ceil(response.data.count / response.data.page_size));
            }
            setShowLoadingBar(false);
            if(response.data?.results) {
				response.data.results = response.data.results?.map(item => {
					if (item.not_delete === true) {
						item.isDefault = "Yes";
					} else {
						item.isDefault = "No";
					}
					return item;
				});
			}
            const rowArray =  commonTableBody(response.data.results, ClaimSubStatusTableObject.tableBodyData[0])
            if(rowArray.length > 0) {
                ClaimSubStatusTableObject.tableBodyData = rowArray;
            }
            else {
                ClaimSubStatusTableObject.tableBodyData = ClaimSubStatusTableBodyOnly;
            }

            setClaimSubStatusTypeList(response.data.results);
        });
    }

    const onClose = () => {
        setSearchValue('');
        setActivePage(1);
        setStartIndex(0);
        getClaimSubStatusData(DEFAULT_PAGING_SIZE, activePage,);
    }

    useEffect(() => {
        getClaimSubStatusData(DEFAULT_PAGING_SIZE, activePage);
        if(location.pathname === ROUTE_CLAIM_SUB_STATUSES){
            setPermission(checkPermission(super_admin_privileges.super_admin_full_privilege,super_admin_permission_key_value.dictionary_add))
        } else {
            setPermission(checkPermission(permission_key_values_practice_dictionaries.practice_dictionaries_sub_module_modify,
                permission_key_values_practice_dictionaries.practice_dictionaries_sub_module_add,
                super_admin_privileges.super_admin_full_privilege))
        }
    }, []);
    function dropDownFunction(id, name) {
        if (!permission) {
            showNotifyWindow('show', 'error', i18n.t('errorMessages.permission_error'));
            return;
        }
		// if the relation is not self then proceed;
		if (name.toLowerCase() == 'edit') {
            onEditClaimSubStatusType(Number(id));
        }
        if (name.toLowerCase() == 'delete') {
            onDeleteClaimSubStatusType(Number(id));
        }
    }

    function onEditClaimSubStatusType(ClaimSubStatusId) {
        setHeader(i18n.t("dictionariesPages.claimSubStatusList.editHeader"));
        setShowLoadingBar(true);
        let data = null;
        data=service.GetClaimSubStatusType(ClaimSubStatusId,isAdminModule=='false' ? practicePK : '')
        data.then(response => {
            setShowLoadingBar(false);
            setShowModalWindow(true);
            setClaimSubStatusType(response.data.name);
            setEditForm(true);
            setEditClaimSubStatusId(ClaimSubStatusId);
            setAdjustmentCodesData(response.data?.denial_codes)
            setdefaultValue(response.data?.not_delete)
        });
    }

    function onDeleteClaimSubStatusType(ClaimSubStatusId) {
        ClaimSubStatusTypeList.map((item) => {
            if (item.id == parseInt(ClaimSubStatusId)) {
                setDeleteClaimSubStatusName(item.name);
                setDeleteClaimSubStatusId(item.id);
                setShowDeleteModalWindow(true);
            }
        });
    }

    function onDeleteAction() {
        setShowLoadingBar(true);
        let data = null;
        data = service.DeleteClaimSubStatusType(deleteClaimSubStatusId,isAdminModule=='false' ? practicePK : '');
        data.then(() => {
            setShowLoadingBar(false);
            setDeleteClaimSubStatusName('');
            setDeleteClaimSubStatusId('');
            setShowDeleteModalWindow(false);
            showNotifyWindow('show', 'success', DELETE_SUCCESS);
            getClaimSubStatusData(DEFAULT_PAGING_SIZE, activePage);
        });
        setSearchValue("");
    }

    function resetForm() {
        setClaimSubStatusType('');
        setEditForm(false);
        setFormError({
            ...form_error,
            ['ClaimSubStatusType']: ''
        });

    }

    function onSaveFormData(e) {
        e.preventDefault();
        if (!ClaimSubStatusType.trim()) {
            setFormError({
                ...form_error,
                ['ClaimSubStatusType']: 'error'
            });
        } else {
            setShowLoadingBar(true);
            let result = null;
            let data;
            let isDefaultValue = defaultValue;
            const denialArr = adjustmentCodesData.map(item=>item.id)
            if (isAdminModule === 'true'){
                // If defaultValue is true, do not include 'name' in the payload
                data = isDefaultValue 
                    ? { denial_codes: denialArr} 
                    : { name: ClaimSubStatusType, denial_codes: denialArr }
            }
            else {
                data = isDefaultValue
                    ? { practice: practicePK ,denial_codes: denialArr}
                    : { practice: practicePK, name: ClaimSubStatusType ,denial_codes: denialArr};
            }
            result=editForm ? result = service.UpdateClaimSubStatusType(editClaimSubStatusId, data) : result = service.AddClaimSubStatusType(data);
            result.then(response => {
                setShowLoadingBar(false);
                if (response.status == 201 || response.status == 200) {
                    if (response.status == 201)
                        showNotifyWindow('show', 'success', ADD_SUCCESS);
                    else
                        showNotifyWindow('show', 'success', UPDATE_SUCCESS);
                    getClaimSubStatusData(DEFAULT_PAGING_SIZE, activePage);
                    resetForm();
                    setShowModalWindow(false);
                } else if (response.status === 400 && response.data.record_already_exists !== undefined) {
                    showNotifyWindow('show', 'error', i18n.t('errorMessages.record_exists'));
                } else {
                    showNotifyWindow('show', 'error', ADD_ERROR);
                }
            });
            setSearchValue('');
        }
    }
    function onHandleChange(e) {
        const { name, value, type } = e.target;
        let trimmedValue = '';
        if (type === "text") {
            trimmedValue = value;
        }
        if (name == "searchValue") {
            setSearchValue(trimmedValue)
            return;
        }
        if (!trimmedValue) {
            setFormError({
                ...form_error,
                [name]: 'error'
            });
        } else {
            setFormError({
                ...form_error,
                [name]: ''
            });
        }
        setClaimSubStatusType(trimmedValue);
    }

    const handleKeyDown = (e) => {
        if (e.key === 'Enter' && searchValue) {
            onSearch(e, searchValue);
        }
    }

    function onSearch(e,searchValue){
        e.preventDefault();
        setActivePage(1)
        setStartIndex(0);
        getClaimSubStatusData(DEFAULT_PAGING_SIZE, 1, searchValue);
    }

    const handleSearch = (query) => {
        getAdjustmentCodes(20,1,query)
    };

    const handleOnBlur = () => {
        setAdjustmentCodesList([]); 
    }

    function backToDictionaryPage() {
        if (isAdminModule === 'true')
            props.history.push(ROUTE_SUPER_DICTIONARIES)
        else
            props.history.push(ROUTE_PRACTICE_DICTIONARIES)
    }

    return (
        <React.Fragment>
            <Notify showNotify={showNotify} setShowNotify={setShowNotify} notifyDescription={notifyDescription} setNotifyType={setNotifyType} setNotifyDescription={setNotifyDescription} notifyType={notifyType} />
            <div className="col-md-8">

            <div className="box box-content-white  mb-3">
                    <div className="row mr-0 mb-2">
                        <div className="col pl-4 mb-1">
                            <div className={"dataTables_filter"}>
                                    <div className="link dictionaries-back" onClick={backToDictionaryPage}>
                                        <BackArrowWithLabel label={i18n.t("dictionariesPages.gotoDictionaries")} />
                                    </div>
                            </div>
                        </div>
                    </div>

                    <div className="box-content ">
                        <div className="common-search-wrapper-style">
                            <div className="input-content-box">
                                <div className="form-group padding-top15 relative">
                                    <TextInput
                                        type="text"
                                        id="searchValue"
                                        name="searchValue"
                                        value={searchValue}
                                        onValueChange={onHandleChange}
                                        label={i18n.t("dictionariesPages.claimSubStatusList.lblClaimSubStatus")}
                                        onKeyDown={handleKeyDown}
                                        labelClassName={"margin-bottom0"}
                                        clearButton={typeof searchValue == 'string' && searchValue.length !== 0}
                                        clearInput={onClose}
                                    />
                                </div>
                            </div>
                            <div className="input-content-box padding-top15">
                                <div className="margin-top18">
                                <CommonButton  variant="contained" onClick={(e) => onSearch(e, searchValue)} label={i18n.t("dictionariesPages.dictInsuranceCompanies.search")} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                
                <div className="box box-content-white">
                    <div className="box-content">
                        <div className="alignRight margin-bottom3">
                            <CommonButton  variant="contained" onClick={addNew} label={i18n.t("buttons.addNew")} />
                        </div>
                        <div className="table-responsive">
                            <Table tableObject={ClaimSubStatusTableObject} dropDownFunction={dropDownFunction} />
                        </div>
                        <div className='mt-2 mb-2'>
                            <Pagination totalPage={totalPage} activePage={activePage} startIndex={startIndex} endIndex={endIndex} onPagePrevious={onPagePrevious} onPageUp={onPageUp} onPageNext={onPageNext} />
                        </div>
                    </div>
                </div>
            </div>
            <CustomizedDialogs showModal={showModalWindow} type="save" header={header} setShowModalWindow={setShowModalWindow} resetForm={resetForm} onHide={onHide}>
                <Form id="form_dataEntry" autoComplete="off" onSubmit={(e) => onSaveFormData(e)} encType="multipart/form-data">
                    <Form.Group>
                        <div className="row">
                            <div className="col-md-12">
                                <div className="form-group padding-top15">
                                    <TextInput type="text" id="ClaimSubStatusType" name="ClaimSubStatusType" label={i18n.t('dictionariesPages.claimSubStatusList.lblClaimSubStatus')} onValueChange={onHandleChange} defaultClassName={form_error.ClaimSubStatusType ? 'input-error' : ""} value={ClaimSubStatusType}
                                        disabled={defaultValue} 
                                    />
                                </div>
                                <div className="form-group">
                                    <MultiSelectAutoComplete
                                        options={adjustmentCodesList}
                                        label={"Denial Codes"}
                                        value={adjustmentCodesData}
                                        onChange={(e)=>handleValueChange(e)}
                                        onSearch={(e)=>handleSearch(e)}
                                        onBlur={(e)=>handleOnBlur(e)}
                                    />
                                </div>
                            </div>
                        </div>
                    </Form.Group>
                </Form>
            </CustomizedDialogs>  
            <CustomizedSmallDialogs showModal={showDeleteModalWindow} header={i18n.t("commons.confirmDelete")} type="delete" deleteItem={onDeleteAction} resetForm={resetForm} onHide={onDeleteHide} setShowModalWindow={setShowDeleteModalWindow}>
                {i18n.t("dictionariesPages.claimSubStatusList.deletConfirmation") + "'" + deleteClaimSubStatusName + "'?"}
            </CustomizedSmallDialogs>  
        </React.Fragment>
    )
}
export default ClaimSubStatusList