import React, { useContext, useState } from "react";
import service from "../service";
import TextInput from "../../commons/input/input";
import CustomizedSmallDialogs from "../../modalWindowComponent/CustomisedSmallDialog";
import LoadingContext from "../../../container/loadingContext";
import { getStorage } from "../../../utilities/browserStorage";

const AddNewCategoryCard = ({
  show,
  setShow,
  onShowMessage,
  setReportCategories,
}) => {
  const practicePK = getStorage("practice");
  const [newCategory, setNewCategory] = useState("");
  const setShowLoadingBar = useContext(LoadingContext);
  const onCategoryEdit = (event) => setNewCategory(event.target.value);
  const onSaveClicked = () => {
    if (newCategory === "") {
      onShowMessage("show", "error", "Enter a valid category");
    } else {
      setShowLoadingBar(true);
      service
        .saveCategory({ name: newCategory, practice: practicePK })
        .then((response) => {
          setShowLoadingBar(false);
          if (response.status === 200 || response.status === 201) {
            setReportCategories(response.data);
            onShowMessage("show", "success", "Category Added Successfully");
          } else {
            throw Error("Something went wrong");
          }
        })
        .catch((err) => {
          setShowLoadingBar(false);
          onShowMessage("show", "error", err.message);
        });
    }
  };
  return (
    <CustomizedSmallDialogs
      showModal={show}
      type={"save"}
      submit={false}
      onSaveData={onSaveClicked}
      header={"New Category"}
      setShowModalWindow={setShow}
      fullWidth={false}
    >
      <div className="container ">
        <div className="row">
          <div className="col-12">
            <TextInput
              onValueChange={onCategoryEdit}
              value={newCategory}
              required="required"
              type={"text"}
              name={"categoryName"}
              label={"Title"}
            />
          </div>
        </div>
      </div>
    </CustomizedSmallDialogs>
  );
};

export default AddNewCategoryCard;
