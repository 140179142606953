import React, { useState, useEffect, useContext } from 'react';
import {Form } from 'react-bootstrap';
import CustomizedDialogs from '../../modalWindowComponent/CustomizedDialogs';
import service from './service';
import { useLocation  } from 'react-router-dom';


import Notify from '../../commons/notify';
import Table from '../../commons/Table/Table';
import TextInput from '../../commons/input/input';
import Pagination from '../../commons/pagination';
import BackArrowWithLabel from "../../commons/Back"; 
import ExportIconButton from '../../commons/exportIconButton';

import LoadingContext from '../../../container/loadingContext'
import CustomizedSmallDialogs from '../../modalWindowComponent/CustomisedSmallDialog';

import i18n from '../../../utilities/i18n';
import { getStorage } from '../../../utilities/browserStorage';
import { checkPermission, commonTableBody } from '../../../utilities/commonUtilities';
import { ADD_SUCCESS, ADD_ERROR, UPDATE_SUCCESS, DELETE_SUCCESS } from '../../../utilities/labelConfigs';
import { permission_key_values_accountsetup, super_admin_privileges, super_admin_permission_key_value  } from '../../../utilities/permissions';
import { ROUTE_SUPER_DICTIONARIES, PAGING_END_INDEX, DEFAULT_PAGING_SIZE, ROUTE_CODE_MANAGEMENT, ROUTE_PAYER_CLASSESS } from '../../../utilities/staticConfigs';

import { PayerClassTable } from './PayerClassTable';
import CommonButton from '../../commons/Buttons';

const PayerClassList = (props) => {
    const isAdminModule = getStorage('isAdminModule');
    const location = useLocation();
    const practicePK = getStorage("practice");
    const [showModalWindow, setShowModalWindow] = useState(false);
    const [showDeleteModalWindow, setShowDeleteModalWindow] = useState(false);
    const [modalHeader, setModalHeader] = useState('Add Payer Class');
    const [payerClass, setPayerClass] = useState('');
    const [deletePayerClassName, setDeletePayerClassName] = useState('');
    const [deletePayerClassId, setDeletePayerClassId] = useState('');
    const [editPayerClassId, setEditPayerClassId] = useState('');
    const [payerClassList, setPayerClassList] = useState([]);
    const [form_error, setFormError] = useState({ 'payerClass': '' });
    const [editForm, setEditForm] = useState(false);
    const [permission, setPermission] = useState(false);
    const [searchValue, setSearchValue] = useState("");

    //start ==== Alert message properties
    const [showNotify, setShowNotify] = useState('hide');
    const [notifyDescription, setNotifyDescription] = useState('');
    const [notifyType, setNotifyType] = useState('success');
    const setShowLoadingBar = useContext(LoadingContext);

    function showNotifyWindow(action, type, desc, age = 3000) {
        if (action == 'show') {
            setTimeout(() => {
                setShowNotify('hide');
            }, age)
        }
        setShowNotify(action);
        setNotifyType(type);
        setNotifyDescription(desc);
    }
    //End ====
    //Pagination start
    const [totalPage, setTotalPage] = useState(1);
    const [activePage, setActivePage] = useState(1);
    const [startIndex, setStartIndex] = useState(0);
    const [endIndex, setEndIndex] = useState(PAGING_END_INDEX);

    function onPagePrevious() {
        let previousPage = startIndex + 1 - PAGING_END_INDEX;
        if (previousPage === 0) {
            previousPage = 1;
        }
        setActivePage(previousPage);
        if (startIndex !== 0) {
            setStartIndex(startIndex - PAGING_END_INDEX);
            setEndIndex(endIndex - PAGING_END_INDEX);
        }
        getPayerClassData(DEFAULT_PAGING_SIZE, previousPage, searchValue);
    }

    function onPageUp(e) {
        let page = Number(e.target.id)
        setActivePage(page);
        getPayerClassData(DEFAULT_PAGING_SIZE, page, searchValue);
    }

    function onPageNext() {
        let nextPage = startIndex + 1 + PAGING_END_INDEX;
        if (endIndex === totalPage || totalPage <= PAGING_END_INDEX) {
            setActivePage(nextPage);
            setStartIndex(startIndex);
            setEndIndex(endIndex);

        } else {
            setActivePage(nextPage);
            setStartIndex(startIndex + PAGING_END_INDEX);
            setEndIndex(endIndex + PAGING_END_INDEX);
        }
        getPayerClassData(DEFAULT_PAGING_SIZE, nextPage, searchValue);
    }
    //Pagination ends

    function addNew() {
        if (!permission) {
			showNotifyWindow('show','error', i18n.t('errorMessages.permission_error'));
			return;
		}
        setModalHeader(i18n.t("dictionariesPages.payerClassList.addHeader"));
        setShowModalWindow(true);
    }

    function onHide() {
        setEditForm(false);
    }

    function onDeleteHide() {
        setDeletePayerClassName('');
        setDeletePayerClassId('');
        setShowDeleteModalWindow(false);
    }

    function getPayerClassData(pageSize, page, searchString) {
        setShowLoadingBar(true);
        let result = null;
        //if admin payerclass list is called admin data if it is called on practice side payer class called based on practice
        //only payerclass selected by practice is displayed
        result=service.ListPayerClasses(pageSize, page, isAdminModule,isAdminModule=='false' ? practicePK : '',searchString)
        if (result)  {
            result.then(response => {
                if (response.data.results !== undefined) {
                    let dataTotalPage = Math.ceil(response.data.count / response.data.page_size);
                    setTotalPage(dataTotalPage);
                    if (dataTotalPage < activePage) {
                        if (dataTotalPage === 0) dataTotalPage = 1;
                        setActivePage(dataTotalPage);
                    }
                }
                setShowLoadingBar(false);
                if(response.data?.results) {
                    response.data.results = response.data.results?.map(item => {
                        if (item.not_delete === true) {
                            item.isDefault = "Yes";
                        } else {
                            item.isDefault = "No";
                        }
                        return item;
                    });
                }
                if (response.data.results !== undefined) {
                    //common table format is generated for list payer class
                    const rowArray = commonTableBody(response.data.results, PayerClassTable.tableBodyData[0])
                    PayerClassTable.tableBodyData = rowArray;
                    setPayerClassList(response.data.results);
                }
            });
        }
       
    }

    useEffect(() => {
        getPayerClassData(DEFAULT_PAGING_SIZE, activePage);
        if(location.pathname === ROUTE_PAYER_CLASSESS){
            setPermission(checkPermission(super_admin_privileges.super_admin_full_privilege,super_admin_permission_key_value.dictionary_add))
        } else {
            setPermission(checkPermission(permission_key_values_accountsetup.account_setup_code_management_add,
                 permission_key_values_accountsetup.account_setup_code_management_modify,
                super_admin_privileges.super_admin_full_privilege));
        }
    }, []);

    function onEditPayerClass(payerClassId) {
        setModalHeader(i18n.t("dictionariesPages.payerClassList.editHeader"));
        setShowLoadingBar(true);
        let data = null;
        data = service.GetPayerClass(payerClassId,isAdminModule=='false' ?  practicePK : '');
        data.then(response => {
            setShowLoadingBar(false);
            setShowModalWindow(true);
            setPayerClass(response.data.name);
            setEditForm(true);
            setEditPayerClassId(payerClassId);
        });
    }

    function onDeletePayerClass(payerClassId) {
        payerClassList.map((item) => {
            if (item.id == parseInt(payerClassId)) {
                setDeletePayerClassName(item.name);
                setDeletePayerClassId(item.id);
                setShowDeleteModalWindow(true);
            }
        });
    }

    function onDeleteAction() {
        setShowLoadingBar(true);
        let data = null;
        data = service.DeletePayerClass(deletePayerClassId,isAdminModule=='false' ? practicePK : '');
        data.then(() => {
            setShowLoadingBar(false);
            setDeletePayerClassName('');
            setDeletePayerClassId('');
            setShowDeleteModalWindow(false);
            showNotifyWindow('show', 'success', DELETE_SUCCESS);
            getPayerClassData(DEFAULT_PAGING_SIZE, activePage, searchValue);
           
        });
    }

    function resetForm() {
        setPayerClass('');
        setEditForm(false);
        setFormError({
            ...form_error,
            ['payerClass']: ''
        });

    }
    function onSaveFormData(e) {
        e.preventDefault();
        if (!payerClass.trim()) {
            setFormError({
                ...form_error,
                ['payerClass']: 'error'
            });
        } else {
            setShowLoadingBar(true);
            let result = null;
            let data = null;
            data=isAdminModule === 'true' ?  { name: payerClass } :  { practice: practicePK, name: payerClass }
            result = editForm ?service.UpdatePayerClass(editPayerClassId, data) :   service.AddPayerClass(data);
            result.then(response => {
                setShowLoadingBar(false);
                if (response.status == 201 || response.status == 200) {
                    if (response.status == 201)
                        showNotifyWindow('show', 'success', ADD_SUCCESS);
                    else showNotifyWindow('show', 'success', UPDATE_SUCCESS);
                    if (editForm) {
                        // if page edit then list the same page
                        getPayerClassData(DEFAULT_PAGING_SIZE, activePage, searchValue);
                    } else {
                        // if add new then list from starting(get latest first)
                        setActivePage(1);
                        getPayerClassData(DEFAULT_PAGING_SIZE, 1);
                    }
                    resetForm();
                    setShowModalWindow(false);
                } else if (response.status === 400 && response.data.record_already_exists !== undefined) {
                    showNotifyWindow('show', 'error', i18n.t('errorMessages.record_exists'));
                } else {
                    showNotifyWindow('show', 'error', ADD_ERROR);
                }
            });
        }
    }
    function onHandleChange(e) {
        const { name } = e.target;
        let value = e.target.value;
        let trimmedValue = '';
        if (e.target.type === "text") {
            trimmedValue = value;
        }
        if (name == "searchValue") {
            setSearchValue(trimmedValue);
            return
        }
        if (!trimmedValue) {
            setFormError({
                ...form_error,
                [name]: 'error'
            });
        } else {
            setFormError({
                ...form_error,
                [name]: ''
            });
        }
        setPayerClass(trimmedValue);
    }
   const handleKeyDown = (e) => {
        if (e.key === "Enter" && searchValue) {
            onSearch(e, searchValue);
        }
    };

    function onSearch(e, searchString) {
        e.preventDefault();
        setActivePage(1);
        setStartIndex(0);
        getPayerClassData(DEFAULT_PAGING_SIZE, 1, searchString);
    }
    const onClose = () => {
        setSearchValue("");
        setActivePage(1);
        setStartIndex(0);
         getPayerClassData(DEFAULT_PAGING_SIZE, activePage);
    };

    function backToDictionaryPage() {
        props.history.push(ROUTE_SUPER_DICTIONARIES)
    }
    function backToCodes() {
        props.history.push(ROUTE_CODE_MANAGEMENT)
    }
    function dropDownFunction(id, name) {
        if (!permission) {
            showNotifyWindow('show', 'error', i18n.t('errorMessages.permission_error'));
            return;
        }
		// default prayer are not allowed to edit or delete from the database;
		const isDefaultPrayer = payerClassList.some((item) => {
			if (item.id === id && item.not_delete) {
				return true;
			} else {
				return false;
			}
		});
		if (isDefaultPrayer) {
			showNotifyWindow('show', 'error', i18n.t('dictionariesPages.payerClassList.editRestrictionMessage'));
			return;
		}
		// if the prayer is not self then proceed;
		if (name.toLowerCase() == 'edit') {
            onEditPayerClass(Number(id));
        }
        if (name.toLowerCase() == 'delete') {
            onDeletePayerClass(Number(id));
        }
    
}

    const OnExportMenuItemClicked = (e, type) => {
        // eslint-disable-next-line no-undef
        const fileDownload = require('js-file-download');
        setShowLoadingBar(true);
        service.exportPayerClass(type, practicePK)
            .then((result) => {
                if (result.status === 200 || result.status === 201) {
                    fileDownload(result.data, result.headers['content-disposition']);
                    setShowLoadingBar(false);
                    showNotifyWindow('show', 'success', 'Export Success');
                } else if (result.status === 404 && result.message === 'no_data_available_for_export') {
					showNotifyWindow('show', 'error', i18n.t('errorMessages.no_record_found_to_export'));
					setShowLoadingBar(false);
				}
                 else {
                    throw Error(result.statusText);
                }
            })
            .catch(() => {
                showNotifyWindow('show', 'error', 'Unexpected Error');
                setShowLoadingBar(false);
            });
    };


    return (
        <React.Fragment>
            <Notify
                showNotify={showNotify}
                setShowNotify={setShowNotify}
                notifyDescription={notifyDescription}
                setNotifyType={setNotifyType}
                setNotifyDescription={setNotifyDescription}
                notifyType={notifyType}
            />
            <div className="col-md-8">
                {isAdminModule === "true" ? (
                    <div className="box box-content-white mb-3">
                        <div className="row mr-0 mb-2">
                            <div className="col pl-4 mb-1">
                                <div className={"dataTables_filter"}>
                                    <div className="link dictionaries-back" onClick={backToDictionaryPage}>
                                        <BackArrowWithLabel label={i18n.t( "dictionariesPages.gotoDictionaries")} />
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="box-content">
                            <div className="common-search-wrapper-style">
                                <div className="input-content-box">
                                    <div className="form-group padding-top15 relative">
                                        <TextInput
                                            type="text"
                                            id="searchValue"
                                            name="searchValue"
                                            value={searchValue}
                                            onValueChange={onHandleChange}
                                            label={i18n.t("dictionariesPages.payerClassList.lblPayerClass")}
                                            onKeyDown={handleKeyDown}
                                            labelClassName={"margin-bottom0"}
                                            clearButton={ typeof searchValue =="string" && searchValue.length !== 0 }
                                            clearInput={onClose}
                                        />
                                    </div>
                                </div>
                                <div className="input-content-box padding-top15">
                                    <div className="margin-top18">
                                        <CommonButton
                                            variant="contained"
                                            label="Search"
                                            onClick={(e) =>
                                                onSearch(e, searchValue)
                                            }
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                ) : (
                    <div className="box box-content-white">
                        <div className="box-head pl-0 pr-0">
                            <div className={"dataTables_filter"}>
                                <div className="link dictionaries-back"  onClick={backToCodes}>
                                    <BackArrowWithLabel label={i18n.t("codes.codes")} />
                                </div>
                            </div>
                        </div>
                    </div>
                )}
               
                <div className="box box-content-white">
                    <div className="box-content">
                        <div className="alignRight margin-bottom8">
                            <CommonButton
                                variant="contained"
                                onClick={addNew}
                                label={i18n.t("buttons.addNew")}
                            />
                        </div>
                        <div className="alignRight margin-bottom8 mr-4">
                            <ExportIconButton
                                disabled={!payerClassList.length}
                                aria-label="export-button"
                                onExportCsv={(e) =>
                                    OnExportMenuItemClicked(e, "csv")
                                }
                                onExportXls={(e) =>
                                    OnExportMenuItemClicked(e, "xls")
                                }
                            />
                        </div>
                    </div>
                    <div className="" aria-label="payerTable">
                        <div className="table-responsive">
                            <Table tableObject={PayerClassTable} dropDownFunction={dropDownFunction}/>
                        </div>
                        <div className="padding-top10"></div>
                        <Pagination totalPage={totalPage} activePage={activePage} startIndex={startIndex} endIndex={endIndex} onPagePrevious={onPagePrevious} onPageUp={onPageUp} onPageNext={onPageNext} />
                    </div>
                </div>
            </div>
            <CustomizedDialogs showModal={showModalWindow}  type="save"  header={modalHeader} setShowModalWindow={setShowModalWindow} resetForm={resetForm}  onHide={onHide}>
                <Form
                    id="form_dataEntry"
                    onSubmit={(e) => onSaveFormData(e)}
                    encType="multipart/form-data">
                    <Form.Group>
                        <div className="row">
                            <div className="col-md-12">
                                <div className="form-group padding-top15">
                                    <TextInput
                                        type="text"
                                        id="payerClass"
                                        name="payerClass"
                                        label={i18n.t( "dictionariesPages.payerClassList.lblPayerClass" )}
                                        onValueChange={onHandleChange}
                                        className={
                                            form_error.payerClass
                                                ? "input-error"
                                                : ""
                                        }
                                        value={payerClass}
                                        required={true}
                                    />
                                </div>
                            </div>
                        </div>
                    </Form.Group>
                </Form>
            </CustomizedDialogs>
            <CustomizedSmallDialogs
                showModal={showDeleteModalWindow}
                header={i18n.t("commons.confirmDelete")}
                type="delete"
                deleteItem={onDeleteAction}
                resetForm={resetForm}
                onHide={onDeleteHide}
                setShowModalWindow={setShowDeleteModalWindow}>
                {i18n.t("dictionariesPages.payerClassList.deletConfirmation") +
                    "'" +
                    deletePayerClassName +
                    "'?"}
            </CustomizedSmallDialogs>
        </React.Fragment>
    );
}
export default PayerClassList