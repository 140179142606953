import React, { useState } from "react";
import TextInput from "../../commons/input/input";
import SelectInput from "../../commons/input/SelectInput";
import TextAreaInput from "../../commons/textarea/textarea";
import AddNewCategoryCard from "./AddNewCategoryCard";
import { useHistory } from "react-router-dom";
import "./reportStyles.css";
import { ROUTE_USER_REPORTS_BUILDER } from "../../../utilities/staticConfigs";

const AddTemplateCard = ({
  title,
  onTitleChanged,
  description,
  onDescriptionChanged,
  category,
  onCategoryChaged,
  categoryList,
  saveHandler,
  onShowMessage,
  setReportCategories,
}) => {
  const history = useHistory();
  const [show, setShow] = useState(false);
  const onAddClickHandler = () => setShow(true);
  const onCancelClickHanlder = () => {
    history.push(ROUTE_USER_REPORTS_BUILDER);
  };

  return (
    <>
      <div className="rounded-box">
        <div className="container mt-2">
          <div className="row">
            <div className="col-6">
              <TextInput
                type={"text"}
                name={"title"}
                value={title}
                onValueChange={onTitleChanged}
                label={"Title"}
              />
            </div>
            <div className="col-6 mt-4">
              <TextAreaInput
                label={"Description"}
                placeholder={"Description"}
                name={"description"}
                value={description}
                onValueChange={onDescriptionChanged}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-6">
              <div className="row">
                <div className="col-10">
                  <SelectInput
                    data={categoryList}
                    name={"primary_procedure"}
                    value={category}
                    onValueChange={onCategoryChaged}
                    label={"Category"}
                    selectOptionRemove={true}
                    defaultValue={true}
                  />
                </div>
                <div className="col-2">
                  <button
                    className="btn btn-primary-blue mt-3"
                    onClick={onAddClickHandler}
                  >
                    <i className="fa fa-plus"></i>
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="row mt-4 mb-2">
            <div className="col-6">
              <button className="btn btn-primary-blue" onClick={saveHandler}>
                Save
              </button>
              <button
                className="btn btn-primary-blue ml-4"
                onClick={onCancelClickHanlder}
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      </div>
      <AddNewCategoryCard
        show={show}
        setShow={setShow}
        onShowMessage={onShowMessage}
        setReportCategories={setReportCategories}
      />
    </>
  );
};

export default AddTemplateCard;
