import api from '../../../service/api';

const GetLockedPatients = async (pageSize, page, practicePK, search) => {
    let path = 'patient/locked-patients/?page_size='+ pageSize +'&page='+ page +'&practice_pk='+ practicePK;
    if (search)
        path += '&search='+ search;
    return api.get(path);
}

const GetLockedClaims = async (pageSize, page, practicePK, search) => {
    let path = 'claim/locked-claims/?page_size='+ pageSize +'&page='+ page +'&practice_pk='+ practicePK;
    if (search)
        path += '&search='+ search;
    return api.get(path);
}

const UnlockPage = async (data) => {
    let path = 'user/unlock-page/';
    return api.post(path, data);
}

export default { GetLockedPatients, GetLockedClaims, UnlockPage }