import React, {useEffect, useState} from 'react';
import { Link } from "react-router-dom";
import i18n from '../../../utilities/i18n';
import './style.css';
import { getStorage } from '../../../utilities/browserStorage';

const Settings = () => {
    const practicePK = getStorage('practice');
    const [settingsMenuItems, setSettingsMenuItems] = useState([]);

    function onHideSettings() {
        document.getElementById("right-menu-content").classList.remove('active');
    }

    function onClickMenu() {
        onHideSettings();
    }

    useEffect(() => {
        if (getStorage('isAdminModule') === 'false') {
            const practiceModuleRightSideMenuSet = JSON.parse(localStorage.getItem('practiceModuleMenuSet'));

            const filteredAndExtractedMenus = practiceModuleRightSideMenuSet
                ?.filter((item) => item.isRightSideMenu)
                .map((item) => item.sub_menu);

            setSettingsMenuItems(filteredAndExtractedMenus[0]);
        } else if (getStorage('isAdminModule') === 'true') {
            const adminModuleRightSideMenuSet = JSON.parse(localStorage.getItem('adminModuleMenuSet'));

            const filteredAndExtractedMenus = adminModuleRightSideMenuSet
                ?.filter((item) => item.isRightSideMenu)
                .map((item) => item.sub_menu);

            setSettingsMenuItems(filteredAndExtractedMenus[0]);
        }
    },[])

    return (
        <div className='right-menu-content' id='right-menu-content' >
            <ul>
                <li className="head">
                    <h2>{i18n.t('rightSideMenu.HeadingText')} <button className="close-right-menu" onClick={onHideSettings}> <span aria-hidden="true">&times;</span></button></h2>
                </li>
            </ul>

            <div className="settings-menu">
                {getStorage('isAdminModule') === 'true' &&
                    <ul>
                        {
                            settingsMenuItems && settingsMenuItems
                                .filter(menu => menu.page !== "backward")
                                .map(menu => (
                                    <li key={menu.route} onClick={(e) => onClickMenu(e, menu.route)}>
                                        <Link to={menu.route} className={'rightside-menu-text '}>
                                            {menu.name}
                                        </Link>
                                    </li>
                                ))
                        }

                        {/* 
                            ---------FUTURE IMPLEMENTATION------------ 
                            ROLES AND PERMISSION IN SUPER ADMIN MODULE
                        */}
                        
                    </ul>
                }

                <ul>
                    {   getStorage('isAdminModule') === 'false' && practicePK &&
                        settingsMenuItems && settingsMenuItems
                                .filter(menu => menu.page !== "backward")
                                .map(menu => (
                                    <li key={menu.route} onClick={(e) => onClickMenu(e, menu.route)}>
                                        <Link to={menu.route} className={'rightside-menu-text '}>
                                            {menu.name}
                                        </Link>
                                    </li>
                                ))
                    }
                </ul>


            </div>

        </div>
    )
}

export default Settings