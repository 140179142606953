import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Avatar, IconButton, Tooltip } from '@material-ui/core';
import RedditIcon from '@material-ui/icons/Reddit';
import { useDispatch } from 'react-redux';
import { openChatBox } from './ChatBotSlice/ChatBotSlice';
import Draggable from 'react-draggable';

const useStyles = makeStyles({
    IconButtonContainer: {
        width: 60,
        height: 60,
        color: '#fffff !important',
        borderRadius: '12px !important',
        backgroundColor: '#24A7E0 !important',
    },
    avatarConatiner: {
        width: 60,
        height: 60,
        position: 'fixed !important',
        bottom: '10px !important',
        right: '5px !important',
        display: 'flex',
        alignItems: 'center',
        zIndex: 99,
        color: '#ffffff !important',
        borderRadius: '12px !important',
        backgroundColor: '#24A7E0 !important',
        borderColor: '#24A7E0 !important',
        boxShadow: "3px 3px 8px rgba(6, 15, 20, 0.65098)"
    },
    icon: {
        transition: 'transform 0.3s',
        '&:hover': {
            transform: 'scale(1.5)',
        },
    }
});


const ChatBotButton = () => {
    const classes = useStyles();
    const [position, setPosition] = useState({ x: 0, y: 0 });
    const [isDragStoppedByClick, setIsDragStoppedByClick] = useState(false);

    const dispatch = useDispatch();

    const handleOpen = () => {
        if (!isDragStoppedByClick) {
            // If the button was not dragged and stopped by a click, then dispatch the action
            dispatch(openChatBox(true));
        } else {
            setIsDragStoppedByClick(false)
        }
    }


    /**
     * @description This method handles the Chat bot button drag feature
     * certain restrictions are added not to drag and drop beyond screen height and width as-well-as allowed to drag only in x axis
     * @param {*} e mouse event
     * @param {*} data contains data on last position
     * @returns void
     */
    const handleDrag = (e, data) => {
        const { clientX, clientY } = e;
        const screenWidth = window.innerWidth;
        const screenHeight = window.innerHeight;

        if (clientX < 5 || clientX > screenWidth - 20 || clientY < 10 || clientY > screenHeight - 20) {
            setPosition({ x: 0, y: 0 });
            setIsDragStoppedByClick(false)
        } else {
            setPosition({ x: data.x, y: 0 });
            setIsDragStoppedByClick(true)
        }
    };

    return (
        <div style={{ position: 'fixed', bottom: '10px', right: '5px', zIndex: 999 }}>
            <Draggable
                allowAnyClick={false}
                axis="x"
                position={position}
                onDrag={handleDrag}
            >
                <IconButton className={classes.root} size="small" onClick={handleOpen}>
                    <Tooltip title="Open chat">
                        <Avatar className={classes.avatarConatiner}>
                            <RedditIcon className={classes.icon} fontSize='large' />
                        </Avatar>
                    </Tooltip>
                </IconButton>
            </Draggable>
        </div>
    );
}

export default ChatBotButton;
