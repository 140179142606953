import * as React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import { Tooltip } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import ChatBotComponent from './ChatBotComponent';
import InputBase from '@mui/material/InputBase';
import SendIcon from '@mui/icons-material/Send';
import Paper from '@mui/material/Paper';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { getreplayData, openChatBox, updateMessage } from './ChatBotSlice/ChatBotSlice';
import { getStorage } from '../../utilities/browserStorage';

export default function ResponsiveDialog() {
    const open = useSelector(state => state.chatboxData.openChatBox);
    const dispatch = useDispatch();
    const theme = useTheme();
    const userData = getStorage('userData');
    const user = JSON.parse(userData);
    const custom_practice_id = getStorage('custom_practice_id') 
    const fullScreen = useMediaQuery(theme.breakpoints.down('md')); 
    const messageViewRef = React.useRef(null);
    const [message, setMessage] = React.useState('');
    const handleClose = () => {
        dispatch(openChatBox(false))
    };


    const sendMessage = () => {
        if (message) {
            dispatch(updateMessage({ msg: message, user: user?.user_id }))
            setMessage("")
            setTimeout(() => {
                dispatch(getreplayData({ msg: message }))
            }, 200)
        }
    }
    

    const onChangeHandler = () => {
        if(message){
        dispatch(updateMessage({ msg: message, user: user?.user_id }))
        setMessage("")
        setTimeout(() => {
            dispatch(getreplayData({ msg: message, practicePK: custom_practice_id }))
        }, 200)
    }
    }

    const handleClick = (item) => {
            dispatch(updateMessage({ msg: item, user: user?.user_id }))
            setMessage("")
            setTimeout(() => {
                dispatch(getreplayData({ msg: item, practicePK: custom_practice_id }))
            }, 200)
    }

    return (
        <React.Fragment>
            <Dialog
                fullScreen={fullScreen}
                open={open}
                onClose={handleClose}
                aria-labelledby="responsive-dialog-title"
                PaperProps={{
                    style: {
                        position: 'absolute',
                        right: 0,
                        bottom: 0,
                        minHeight: 'calc(100vh - 100px)',
                        minWidth:'350px'
                    }
                }}
            >
                <DialogTitle id="responsive-dialog-title"
                    sx={{
                        backgroundColor: '#24A7E0',
                        color: '#fff',
                    }}>
                    <div style={{display:'flex'}}>
                    <div>
                        <Tooltip title="Ask questions about your account with our billi bot">
                            <strong>BILLI Bot</strong>
                        </Tooltip>
                    </div>
                    <div style={{ display: 'flex',marginLeft:'auto',marginRight:20}}>
                        <Tooltip title="Close chat">
                            <IconButton
                                edge="end"
                                color="inherit"
                                onClick={handleClose}
                                aria-label="close"
                                    sx={{
                                        borderRadius: '50% !important',
                                        transition: 'background-color 0.3s, color 0.3s',
                                        '&:hover': {
                                            color: '#24A7E0 !important',
                                            backgroundColor: '#fff',
                                        },
                                        '& .MuiSvgIcon-root:hover': {
                                            color: '#24A7E0 !important',
                                        },
                                    }}
                            >
                                <CloseIcon />
                            </IconButton>
                        </Tooltip>
                        </div>
                    </div>
                </DialogTitle>
                <DialogContent className='custom-scrollbar' ref={messageViewRef}>
                    <DialogContentText>
                        <ChatBotComponent messageViewRef={messageViewRef} handleClick={handleClick} />
                    </DialogContentText>
                </DialogContent>
                <div style={{ width: '100%', height: '55px', borderTop:'1px solid #dee2e6'}}>
                    <Paper
                        sx={{ display: 'flex',width: 400,marginBottom:2,marginTop:2}}
                    >
                        <InputBase
                            sx={{ ml: 2, flex: 1 }}
                            placeholder="Type here to send....."
                            inputProps={{ 'aria-label': 'search google maps' }}
                            value={message}
                            required={true}
                            onChange={(e) => setMessage(e.target.value)}
                            onKeyDown={(e) => {
                                if (e.key === 'Enter') {
                                    // Call your onChange handler when Enter key is pressed
                                    // You may want to prevent the default behavior (e.g., form submission)
                                    e.preventDefault();
                                    // Call your onChange handler
                                    onChangeHandler();
                                }
                            }}
                        />
                    <IconButton color="primary" aria-label="directions"
                        onClick={()=>sendMessage()}>
                        <SendIcon style={{ color:'#24A7E0'}}/>
                        </IconButton>
                </Paper>
                </div>
            </Dialog>
        </React.Fragment>
    );
}