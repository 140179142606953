import {
  ArrowDropDown,
  ArrowRight,
  Menu,
  Print,
  Refresh,
  Save,
} from "@material-ui/icons";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import React, { useCallback, useEffect, useState } from "react";
import { useDispatch,useSelector } from "react-redux";
import { setOpen } from "../../../store/reports/reportResultSlice";
import classes from "./ShowReportCard.module.css";
const MONTHS = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

const ShowReportCard = () => {
  const dispatch = useDispatch();
  const headerNames = useSelector((state) => state.reportResult.headerNames);
  const reportData = useSelector((state) => state.reportResult.reportData);
  const isGrouped = useSelector((state) => state.reportResult.isGrouped);
  const [currentDate, setCurrentDate] = useState();
  useEffect(() => {
    const dt = new Date();
    setCurrentDate(formatDate(dt));
  }, []);
  const formatDate = useCallback((date) => {
    const d = new Date(date),
      month = MONTHS[d.getMonth() + 1],
      year = d.getFullYear();
    let day = d.getDate(),
      hours = d.getHours(),
      minutes = "" + d.getMinutes();
    const ampm = hours >= 12 ? "PM" : "AM";
    hours = hours % 12;
    hours = hours ? hours : 12;
    day = day < 10 ? "0" + day : day;
    hours = hours < 10 ? "0" + hours : hours;
    minutes = minutes < 10 ? "0" + minutes : minutes;

    return `${month} ${day}, ${year} at ${hours}:${minutes} ${ampm}`;
  });

  const hanldeOpenClose = (id) => {
    dispatch(setOpen({ id: id }));
  };
  return (
    <div className="container">
      <div className="row">
        <button className="btn btn-primary-blue ml-2">
          <Print /> &nbsp; Print
        </button>
        <button className="btn btn-primary-blue ml-2">
          <Save /> &nbsp; Save
        </button>
        <button className="btn btn-primary-blue ml-2">
          <Refresh /> &nbsp; Refresh
        </button>
        <button className="btn btn-primary-blue ml-2">
          <Menu />
          &nbsp; More
        </button>
      </div>
      <div className="row">
        <span className="mt-2">
          Run Date: {currentDate}
          <br />
          Customer is {"Demo"}
        </span>
      </div>
      <div className="row">
        <div className="col-12">
          {isGrouped ? (
            <TableContainer component={Paper} className={classes.tableFixHead}>
              <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    {headerNames.map((name, index) => (
                      <TableCell align="right" key={index}>
                        <strong>{name}</strong>
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {reportData.map((groupObject) => (
                    <>
                      <TableRow className={classes.tableGroupHeader}>
                        {groupObject.header.map((head, headIndex) => (
                          <>
                            <TableCell key={headIndex}>
                              <span
                                className={classes.expandButton}
                                onClick={() => hanldeOpenClose(groupObject.id)}
                              >
                                {groupObject.isOpen ? (
                                  <ArrowDropDown />
                                ) : (
                                  <ArrowRight />
                                )}
                              </span>
                              &nbsp;
                              <strong>{head}</strong>
                            </TableCell>
                            {[
                              ...Array(
                                headerNames.length - groupObject.header.length
                              ),
                            ].map((e, dummyHeadIndex) => (
                              <TableCell key={dummyHeadIndex}></TableCell>
                            ))}
                          </>
                        ))}
                      </TableRow>
                      {groupObject.isOpen
                        ? groupObject.data.map((rowData, index) => (
                          <TableRow key={index}>
                              <TableCell></TableCell>
                            {rowData.map((colData, colIndex) => (
                              <TableCell key={colIndex}>{colData}</TableCell>
                              ))}
                            </TableRow>
                          ))
                        : null}
                    </>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          ) : (
            <TableContainer component={Paper} className={classes.tableFixHead}>
              <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    {headerNames.map((name, index) => (
                      <TableCell align="right" key={index}>
                        <strong>{name}</strong>
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {reportData.map((row, index) => (
                    <TableRow
                      key={index}
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    >
                      {row.map((cell, i) => (
                        <TableCell component="th" scope="row" key={i}>
                          {cell}
                        </TableCell>
                      ))}
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          )}
        </div>
      </div>
    </div>
  );
};

export default ShowReportCard;
