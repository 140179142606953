import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import payment_service from '../../Payments/service';
import delay_reason_code_service from '../../dictionaries/delayReasonCode/service';
import service from "../SearchClaims/service";

const initialState = {
    delayReasonCodeList: [],
    responsibilityType: [],
    claimStatusList: [],
    claimSubStatusList: [],
}

//service line reponsebility list api call
export const GetResponsibilityType = createAsyncThunk(
    "claimselect/responsibility", async (payload) => {
        const result = await payment_service.GetResponsibilityType(payload);
        return result?.data?.responsibility_list;
    }
)

//getting delay reason code list
export const getDelayReasonCode = createAsyncThunk(
    "claimselect/delay_reason_code",
    async () => {
        const result = await delay_reason_code_service.ListDelayReasonCode(0, 0)
        return result.data;
    }
)

//Procedure claimstatus list api
export const getClaimStatusListData = createAsyncThunk(
    "claimselect/claimStatusList", async ({ claimPK, page, pageSize, patientPK, practicePK }) => {
        const result = await service.ListClaimStatus( pageSize, page, practicePK, null, patientPK, claimPK );
        return result.data;
    }
)

export const getClaimSubStatusData = createAsyncThunk(
    "claimselect/claimSubStatusList", async ({ pageSize, page, practicePK}) => {
        const result = await service.ListClaimStatusSubStatusData( pageSize, page, practicePK );
        return result.data;
    }
)


export const claimSelectInputSlice = createSlice({
    name: 'claimSelectInput',
    initialState,
    reducers: {
        removeResponsibility: (state) => {
            state.responsibilityType = [];
        }
    },
    extraReducers: (builder) => {
        //delay reason code sucesss
        builder.addCase(GetResponsibilityType.fulfilled, (state, actions) => {
            state.responsibilityType = actions.payload;
        })
        //delay reason code sucesss
        builder.addCase(getDelayReasonCode.fulfilled, (state, actions) => {
            const delayData = [];
            actions.payload.forEach((item) => {
                delayData.push({ "id": item.id, "name": item.name_description })
            })
            state.delayReasonCodeList = delayData;
        })
        builder.addCase(getClaimStatusListData.fulfilled, (state, action) => {
            state.claimStatusList = action.payload;
        })
        builder.addCase(getClaimSubStatusData.fulfilled, (state, action) => {
            state.claimSubStatusList = action.payload
        })
    }
})
export const { removeResponsibility } = claimSelectInputSlice.actions;
export default claimSelectInputSlice.reducer;
