import i18n from "../../../utilities/i18n"


const TableHeadData = [
    {
        "name": i18n.t("patientPages.patientLedgerList.patientName"),
        "type": "string",
        "width": "medium",
        "sort": true,
        "initialOrdering": true,
        "orderType": ''
    },
    {
        "name": i18n.t("patientPages.patientLedgerList.patientId"),
        "type": "number",
        "width": "small",
        "sort": true,
        "initialOrdering": true,
        "orderType": ''
    },
    {
        "name": i18n.t("patientPages.patientLedgerList.accountBalance"),
        "type": "currency",
        "width": "small",
        "sort": false,
    },
    {
        "name": i18n.t("patientPages.patientLedgerList.insuranceBalance"),
        "type": "currency",
        "width": "small",
        "sort": false,
    },
    {
        "name": i18n.t("patientPages.patientLedgerList.patientBalance"),
        "type": "currency",
        "width": "small",
        "sort": false,
    },
];

export const TableBodyData = [
    [
        {
            "id": "",
            "name": "name",
            "value": "",
            "type": "string",
            "align": "left",
            "width": "medium",
            "needLink": true,
        },
        {
            "id": "",
            "name": "custom_patient_id",
            "value": "",
            "type": "number",
            "align": "center",
            "width": "small",
        },
        {
            "id": "",
            "name": "account_balance",
            "value": "",
            "type": "currency",
            "align": "right",
            "width": "small",
        },
        {
            "id": "",
            "name": "insurance_balance",
            "value": "",
            "type": "currency",
            "align": "right",
            "width": "small",
        },
        {
            "id": "",
            "name": "patient_balance",
            "value": "",
            "type": "currency",
            "align": "right",
            "width": "small",
        },
    ]
]

export const LedgerListTableData = {
    "name": "simpleTable",
    "isResize": true,
    "isFixedHead": true,
    "tableHeadings": TableHeadData,
    "tableBodyData": TableBodyData
}