import React from 'react'
import CustomizedDialogs from '../../modalWindowComponent/CustomizedDialogs'
import TextInput from '../../commons/input/input';
import SelectInput from '../../commons/input/SelectInput';
import CalendarInput from '../../commons/input/CalendarInput';
import i18n from '../../../utilities/i18n';

function UpdateInsuranceDetails({
    openUpdateModal,
    setOpenUpdateModal,
    onSaveFormData,
    updateHeader,
    onUpdateHide,
    resetUpdateForm,
    onHandleChange,
    memberName,
    setCoPayType,
    coPayTrue,
    coPayTypeList,
    subscriberName,
    coInsuranceTrue,
    coPay,
    coPayType,
    coInsurance,
    coInsuranceType,
    coInsuranceTypeList,
    setCoInsuranceType,
    deductibleTypeList,
    address,
    deductibleType,
    setDeductibleType,
    deductibleTrue,
    deductible,
    claimPolicyID,
    outOfPocketTypeList,
    pocketExpenseType,
    setPocketExpenseType,
    outOfPocketMaxTrue,
    outOfPocketMax,
    groupNumberTrue,
    groupNumber,
    effDateTrue,
    onHandleEffDate,
    effDate

}) {
    return (
        <>
            <CustomizedDialogs type="save" submit={false} onSaveData={onSaveFormData} header={updateHeader} showModal={openUpdateModal} setShowModalWindow={setOpenUpdateModal} resetForm={resetUpdateForm} onHide={onUpdateHide} >
                <div className="col-md-12">
                    <div className="row">
                        <div className="col-4">
                            <div className="row">
                                <div className="col-11 pr-0">
                                    <div className="form-group padding-top">
                                        <TextInput type="text" name="memberName" label={i18n.t("Eligibility.eligibilityTab1.window.memberName")} value={memberName} onValueChange={onHandleChange} />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-4">
                            <div className="row">
                                <div className="col-11 pr-0">
                                    <SelectInput data={coInsuranceTypeList} name="coInsuranceType" value={coInsuranceType} setValue={setCoInsuranceType} onValueChange={onHandleChange} label={i18n.t("Eligibility.eligibilityTab1.window.coInsuranceType")} />
                                </div>                                <div className="col-10 pr-0">

                                </div>
                            </div>
                        </div>
                        <div className="col-4">
                            <div className="row">
                                <div className="col-1">
                                    <div className="max-width-3">
                                        <div className="form-group padding-top15">
                                            <div className="custom-checkbox lh-0">
                                                <input type="checkbox" id="coInsurance"
                                                    disabled={coInsuranceType ? false : true} checked={coInsuranceTrue} name="coInsuranceTrue" onChange={onHandleChange}
                                                />
                                                <label className="checkbox" htmlFor="coInsurance">same as practice</label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-10 pr-0">
                                    <div className="form-group padding-top">
                                        <TextInput type="text" name="coInsurance" label={i18n.t("Eligibility.eligibilityTab1.window.coInsurance")} value={coInsurance} onValueChange={onHandleChange} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-4">
                            <div className="row">
                                <div className="col-11 pr-0">
                                    <div className="form-group padding-top">
                                        <TextInput type="text" name="subscriberName" label={i18n.t("Eligibility.eligibilityTab1.window.subscriberName")} value={subscriberName} onValueChange={onHandleChange} />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-4 padding-top">
                            <div className="row">
                                <div className="col-11 pr-0">
                                    <SelectInput name="coPayType" data={coPayTypeList} value={coPayType} setValue={setCoPayType} onValueChange={onHandleChange} label={i18n.t("Eligibility.eligibilityTab1.window.coPayType")} />
                                </div>
                            </div>
                        </div>
                        <div className="col-4">
                            <div className="row">
                                <div className="col-1">
                                    <div className="max-width-3">
                                        <div className="form-group padding-top15">
                                            <div className="custom-checkbox lh-0">
                                                <input type="checkbox" id="coPayTrue"
                                                    disabled={coPayType ? false : true} checked={coPayTrue} name="coPayTrue" onChange={onHandleChange}
                                                />
                                                <label className="checkbox" htmlFor="coPayTrue">same as practice</label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-10 pr-0">
                                    <div className="form-group padding-top">
                                        <TextInput type="text" name="coPay" value={coPay} label={i18n.t("Eligibility.eligibilityTab1.window.coPay")} onValueChange={onHandleChange} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-4">
                            <div className="row">
                                <div className="col-11 pr-0">
                                    <div className="form-group padding-top">
                                        <TextInput type="text" name="address" label={i18n.t("Eligibility.eligibilityTab1.window.address")} value={address} onValueChange={onHandleChange} />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-4 padding-top">
                            <div className="row">
                                <div className="col-11 pr-0">
                                    <SelectInput name="deductibleType" data={deductibleTypeList} value={deductibleType} setValue={setDeductibleType} onValueChange={onHandleChange} label={i18n.t("Eligibility.eligibilityTab1.window.deductibleType")} />
                                </div>
                            </div>
                        </div>
                        <div className="col-4">
                            <div className="row">
                                <div className="col-1">
                                    <div className="max-width-3">
                                        <div className="form-group padding-top15">
                                            <div className="custom-checkbox lh-0">
                                                <input type="checkbox" id="deductibleTrue"
                                                    disabled={deductibleType ? false : true} checked={deductibleTrue} name="deductibleTrue" onChange={onHandleChange}
                                                />
                                                <label className="checkbox" htmlFor="deductibleTrue">{i18n.t("Eligibility.eligibilityTab1.window.sameAsPractice")}</label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-10 pr-0">
                                    <div className="form-group padding-top">
                                        <TextInput type="text" name="deductible" value={deductible} label={i18n.t("Eligibility.eligibilityTab1.window.deductible")} onValueChange={onHandleChange} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-4">
                            <div className="row">
                                <div className="col-11 pr-0">
                                    <div className="form-group padding-top">
                                        <TextInput type="text" name="claimPolicyID" value={claimPolicyID} label={i18n.t("Eligibility.eligibilityTab1.window.policyId")} onValueChange={onHandleChange} />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-4">
                            <div className="row">
                                <div className="col-11 pr-0">
                                    <SelectInput name="pocketExpenseType" data={outOfPocketTypeList} value={pocketExpenseType} setValue={setPocketExpenseType} onValueChange={onHandleChange} label={i18n.t("Eligibility.eligibilityTab1.window.outPocketExpenses")} />
                                </div>
                            </div>
                        </div>
                        <div className="col-4">
                            <div className="row">
                                <div className="col-1">
                                    <div className="max-width-3">
                                        <div className="form-group padding-top15">
                                            <div className="custom-checkbox lh-0">
                                                <input disabled={pocketExpenseType ? false : true} type="checkbox" id="outOfPocketMaxTrue"
                                                    checked={outOfPocketMaxTrue} name="outOfPocketMaxTrue" onChange={onHandleChange}
                                                />
                                                <label className="checkbox" htmlFor="outOfPocketMaxTrue">same as practice</label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-10 pr-0">
                                    <div className="form-group padding-top">
                                        <TextInput type="text" name="outOfPocketMax" value={outOfPocketMax} label={i18n.t("Eligibility.eligibilityTab1.window.outPocketMax")} onValueChange={onHandleChange} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-4">
                            <div className="row">
                                <div className="col-1">
                                    <div className="max-width-3">
                                        <div className="form-group padding-top15">
                                            <div className="custom-checkbox lh-0">
                                                <input type="checkbox" id="groupNumber"
                                                    checked={groupNumberTrue} name="groupNumberTrue" onChange={onHandleChange}
                                                />
                                                <label className="checkbox" htmlFor="groupNumber">same as practice</label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-10 pr-0">
                                    <div className="form-group padding-top">
                                        <TextInput type="text" name="groupNumber" value={groupNumber} label={i18n.t("Eligibility.eligibilityTab1.window.groupNumber")} onValueChange={onHandleChange} />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-4">
                            <div className="row">
                                <div className="col-1">
                                    <div className="max-width-3">
                                        <div className="form-group padding-top15">
                                            <div className="custom-checkbox lh-0">
                                                <input type="checkbox" id="effDate"
                                                    checked={effDateTrue} name="effDateTrue" onChange={onHandleChange}
                                                />
                                                <label className="checkbox" htmlFor="effDate">same as practice</label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-10 pr-0">
                                    <CalendarInput label={i18n.t("Eligibility.eligibilityTab1.window.effectiveDate")} selected={effDate}
                                        name="effDate" onValueChange={(selected) => onHandleEffDate(selected)} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </CustomizedDialogs>
        </>
    )
}

export default UpdateInsuranceDetails