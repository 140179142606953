//Remove: This function is not called from anywhere in the directory, need to remove after review;
export const checkNumDot = (numVal) =>{  
    const re = /^[0-9.]+$/;
    let result = false;
    if(numVal !==0 || numVal !==''){
        result = re.test(numVal);
    } 
    return result;
};

//Remove: This function is not called from anywhere in the directory, need to remove after review;
export const checkNumDotZero = (numVal) =>{ 
    const re = /^[0-9.]+$/;
    let result = false;
    if(numVal !==''){
        result = re.test(numVal);
    } 
    return result;
};

//Remove: This function is not called from anywhere in the directory, need to remove after review;
export const checkNumColon = (numVal) =>{ 
    const re = /^[0-9:]+$/;
    let result = false;
    if (numVal !== '') {
        result = re.test(numVal);
    }
    return result;
}

//Remove: This function is not called from anywhere in the directory, need to remove after review;
export const checkNum = (numVal) =>{ 
    const re = /^[0-9-]+$/;
    let result = false;
    if(numVal !=='') {
        result = re.test(numVal);
    }
    return result;
    
};

export const mobileRegex = /^[0-9]{1}[0-9]{9}$/;

export const emailRegex = /^([A-Za-z0-9_\-.+*#$%&"'@<>(){}!?^~/|:;=])+@([A-Za-z0-9_\-.])+.([A-Za-z]{2,4})$/;

export const decimalNumRegex = /^\d{1,10}(\.\d{1,4})?$/;

export const numRegex = /^\d+$/;

export const imageExtension = (imageName)=>{
    if ( /\.(jpe?g|png|gif)$/i.test(imageName) === false ){
        return false;
    }
    return true;
}
export const fileExtension = (fileName)=>{
    if ( /\.(jpe?g|png|pdf|doc|docx|xlsx|xls)$/i.test(fileName) === false ){
        return false;
    }
    return true;
}

export const fileSize = (sizeInBytes)=>{
    const sizeInMB  = sizeInBytes / 1024 / 1024; // in MB
    if (sizeInMB > 10) {
        return false;
    }    
    return true;
}

export const csvFileCheck = (imageName)=>{
    if ( /\.(csv)$/i.test(imageName) === false ){
        return false;
    }
    return true;
}
export const csvxlsFileCheck = (imageName)=>{
    if (( imageName.split('.').pop() === 'csv' )|| ( imageName.split('.').pop() === 'xls' )|| ( imageName.split('.').pop() === 'xlsx' )){
        return true;
    }
    return false;
}
export const validateEmail = (email)  => {
    const re = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/
    return re.test(email.toLowerCase());
}