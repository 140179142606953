import React, { useState, useEffect, useContext } from 'react';
import { format } from 'date-fns';
import './style.css';
import service from './service';
import { AssociateUsersTableObject, AssociateUsersTableBodyOnly } from './AssociateUsersTable';
import i18n from '../../../utilities/i18n';
import { getStorage } from '../../../utilities/browserStorage';
import { PAGING_END_INDEX, DEFAULT_PAGING_SIZE,ACTIVE_TYPES, ROUTE_ASSOCIATE_USERS_EDIT, ROUTE_PRACTICE_ASSOCIATE_USERS_ADD, ROUTE_ASSOCIATE_USERS_ADD,  } from '../../../utilities/staticConfigs';
import Table from '../../commons/Table/Table';
import Pagination from '../../commons/pagination';
import SelectInput from "../../commons/input/SelectInput";
import LoadingContext from '../../../container/loadingContext';
import CustomizedSmallDialogs from '../../modalWindowComponent/CustomisedSmallDialog';
import { checkPermission } from '../../../utilities/commonUtilities';
import { super_admin_privileges, permission_key_values_administration, customer_admin_privilege, permission_key_values_super_Admin } from '../../../utilities/permissions';
import Notify from '../../commons/notify';
import {  useHistory } from "react-router-dom";

const AssociateUsersList = (props) => {
    const history = useHistory();
    const [gotoAdd, setGotoAdd] = useState(false);
    const [permission, setPermission] = useState(false);
    const [showModalWindow, setShowModalWindow] = useState(false);
    const [editForm, setEditForm] = useState(false);
    const [deleteAssociateId, setDeleteAssociateId] = useState('');
    const [editAssociateId, setEditAssociateId] = useState('');
    const [header, setHeader] = useState('');
    const [deleteAssociateName, setDeleteAssociateName] = useState('');
    const [showDeleteModalWindow, setShowDeleteModalWindow] = useState(false);
    const [associateUserList, setAssociateUserList] = useState([]);
    const [activeType, setActiveType] = useState("2");

    const setShowLoadingBar = useContext(LoadingContext);
    const [reloadPage, setReloadPage] = useState(false);

    const practicePK = getStorage("practice");
    const isAdminModule = getStorage('isAdminModule');

    //start ==== Alert message properties
    const [showNotify, setShowNotify] = useState('hide');
    const [notifyDescription, setNotifyDescription] = useState('');
    const [notifyType, setNotifyType] = useState('success');

    function showNotifyWindow(action, type, desc, age = 3000) {
        if (action === 'show') {
            setTimeout(() => {
                setShowNotify('hide');
            }, age)
        }
        setShowNotify(action);
        setNotifyType(type);
        setNotifyDescription(desc);

    }
    // end

    //Pagination start
    const [totalPage, setTotalPage] = useState(1);
    const [activePage, setActivePage] = useState(1);
    const [startIndex, setStartIndex] = useState(0);
    const [endIndex, setEndIndex] = useState(PAGING_END_INDEX);
    function onPagePrevious() {
        let previousPage = startIndex + 1 - PAGING_END_INDEX;
        setActivePage(previousPage);
        if (startIndex !== 0) {
            setStartIndex(startIndex - PAGING_END_INDEX);
            setEndIndex(endIndex - PAGING_END_INDEX);
        }
        getAssociateUsersData(DEFAULT_PAGING_SIZE, previousPage);
    }
    function onHandleChange(e) {
        setActiveType(e.target.value);
      }
    

    function onPageUp(e) {
        let page = Number(e.target.id)
        setActivePage(page);
        getAssociateUsersData(DEFAULT_PAGING_SIZE, page);
    }

    function onPageNext() {
        let nextPage = startIndex + 1 + PAGING_END_INDEX;
        if (endIndex === totalPage || totalPage <= PAGING_END_INDEX) {
            setActivePage(nextPage);
            setStartIndex(startIndex);
            setStartIndex(endIndex);
        } else {
            setActivePage(nextPage);
            setStartIndex(startIndex + PAGING_END_INDEX);
            setEndIndex(endIndex + PAGING_END_INDEX);
        }
        getAssociateUsersData(DEFAULT_PAGING_SIZE, nextPage);
    }
    //Pagination ends

    
    useEffect(() => {
        if (props.location.state && props.location.state.selectedID && props.location.state.selectedID !== 0) {
            setEditAssociateId(props.location.state.selectedID)
            setEditForm(true)
            setShowModalWindow(true)
            setGotoAdd(true)
            setHeader(i18n.t("userPages.associateUsers.modalEditHeader"));
        }
        if(isAdminModule === 'true'){
            setPermission(checkPermission(super_admin_privileges.super_admin_full_privilege,permission_key_values_super_Admin.user_management_super_admin_add))
        } else {
            setPermission(checkPermission(super_admin_privileges.super_admin_full_privilege, permission_key_values_administration.associate_user_modify,customer_admin_privilege))
        }
        
    }, []);


    useEffect(() => {
        setShowLoadingBar(true);
        if (getStorage('userData')) {
            getAssociateUsersData(DEFAULT_PAGING_SIZE, activePage);
        }
      }, [activeType]);
    

    function addNew() {
        if(!permission){
            showNotifyWindow('show', 'error', i18n.t('errorMessages.permission_error'));
            return;
        }

        if (isAdminModule == "true") {
            history.push(ROUTE_ASSOCIATE_USERS_ADD)
        } else {
            history.push(ROUTE_PRACTICE_ASSOCIATE_USERS_ADD)
        }

    }

    function onDeleteHide() {
        setDeleteAssociateName('');
        setDeleteAssociateId('');
        setShowDeleteModalWindow(false);
    }

    /**
     * calling associated user list 
     * @param {*} pageSize 
     * @param {*} page 
     */
    function getAssociateUsersData(pageSize, page) {
        setShowLoadingBar(true);
        let status = activeType === "1" ? "all" : activeType === "2" ? "active" : "inactive";
        let result = service.ListAssociateUsers(pageSize, page, isAdminModule === 'true' ? undefined : practicePK,status);

        result.then(async response => {
            setShowLoadingBar(false);

            if (response?.data?.results !== undefined) {
                setTotalPage(Math.ceil(response.data.count / response.data.page_size));
                let newData = await getRowData(response?.data?.results)
                AssociateUsersTableObject.tableBodyData = newData?.length > 0 ? newData : AssociateUsersTableBodyOnly;
                setAssociateUserList(response?.data?.results || []);
            } else {
                AssociateUsersTableObject.tableBodyData = AssociateUsersTableBodyOnly;
                setAssociateUserList([]);
            }
        });
    }

    /**
     * generating users table data from api result 
     * @param {*} results 
     * @returns 
     */
    function getRowData(results) {
        let newData = results?.map(result => {
            let row = AssociateUsersTableObject.tableBodyData[0].map(col => {
                let value = '';
                switch (col.name) {
                    case 'email':
                        value = result.user.email;
                        break;
                    case 'username':
                        value = result.user.username;
                        break;
                    case 'last_login':
                        value = (result.user.last_login && result.user.last_login.length > 1) ? format(new Date(result.user.last_login), 'MM-dd-yyyy hh:mm:ss a') : '';
                        break;
                    default:
                        value = result[col.name];
                        break;
                }

                return {
                    ...col,
                    'value': value,
                    'id': result.id
                };
            });

            return row;
        });

        return newData;
    }

    // Check if a user profile is allowed to edit
    function isAllowedToEditThisUser(id) {
        const userData = associateUserList.find((user) => user.id === id);
        const isUserCustomerAdmin = userData.user_type === "customer_admin";
        const isSuperAdmin = userData.user_type === "superadmin";
        // verify if the profile trying to edit is of the same user logged in, which should not be allowed.
        const isSelfUser = userData.user.id === JSON.parse(getStorage('userData')).user_id;
        if (!isSuperAdmin && !isUserCustomerAdmin && !isSelfUser) {
            return 'proceed'
        } else if (isSelfUser) {
            return i18n.t("userPages.associateUsers.selfEditRestrictionMessage");
        } else if (isUserCustomerAdmin) {
            return i18n.t("userPages.associateUsers.customerAdminEditRestrictionMessage");
        } else if (isSuperAdmin) {
            return i18n.t("userPages.associateUsers.superAdminEditRestrictionMessage");
    } 

    }

    function dropDownFunction(id, name) {
        if (!permission) {
            showNotifyWindow('show', 'error', i18n.t('errorMessages.permission_error'));
            return;
        }
        const isModifyAllowed = isAllowedToEditThisUser(id);
        if (isModifyAllowed !== 'proceed') {
            showNotifyWindow('show', 'error', isModifyAllowed);
            return;
        } 
        if (name.toLowerCase() === 'edit') {
            setEditAssociateId(Number(id))
            setEditForm(true)
            setShowModalWindow(true);
            setHeader(i18n.t("userPages.associateUsers.modalEditHeader"));
            setGotoAdd(true);
        } else if (name.toLowerCase() === 'delete') {
            onDeleteAssociateUser(Number(id));
        }
    }

    if (gotoAdd) {
        history.push(ROUTE_ASSOCIATE_USERS_EDIT, {associateUserID: editAssociateId, editForm : editForm})
      }


    function onDeleteAssociateUser(associateID) {
        associateUserList.map((item) => {
            if (item.id === parseInt(associateID)) {
                setDeleteAssociateName(item.full_name);
                setDeleteAssociateId(item.id);
                setShowDeleteModalWindow(true);
            }
        });
    }

    function onDeleteAction() {
        setShowLoadingBar(true);
        let page = 'associate';
        if (isAdminModule === 'true') page = 'sa_associate';
        const data = service.DeleteAssociateUser(deleteAssociateId, page, practicePK);
        data.then(() => {
            setShowLoadingBar(false);
            setDeleteAssociateName('');
            setDeleteAssociateId('');
            setShowDeleteModalWindow(false);
            getAssociateUsersData(DEFAULT_PAGING_SIZE, activePage);
        });
    }


    useEffect(() => {
        if (reloadPage && getStorage('practiceName')) {
            setReloadPage(false);
            window.location.reload();
        }
    }, [getStorage('practiceName')]);

    return (
        <React.Fragment>
            <Notify showNotify={showNotify} setShowNotify={setShowNotify} notifyDescription={notifyDescription} setNotifyType={setNotifyType} setNotifyDescription={setNotifyDescription} notifyType={notifyType} />
            <div className="col-md-12">
                <div className="box basic-info-padding bordering border-radius-8"  style={{ padding: "15px"}}>
               
                    <div className="box-head pr-0">
                   
                        <div className="alignRight">
                        <div className="row">
                        <div className="mr-1 justify-right">
                       
                  <SelectInput data={ACTIVE_TYPES} name="ActiveType" id="ActiveType" value={activeType} onValueChange={onHandleChange} label={i18n.t("commons.status")} selectOptionRemove={true} />
                </div>
                <div style={{marginTop:"25px",marginRight:"15px",marginLeft:'5px'}}>
                                <button type="button" className="btn btn-primary-blue" onClick={addNew}>{i18n.t("buttons.addNew")}</button>
                        </div>
                        </div>
                    </div>
                    </div>

                    <div className="box-content">
                        <div className="table-responsive">
                            <Table tableObject={AssociateUsersTableObject} dropDownFunction={dropDownFunction} />
                        </div>
                        <div className='mt-2'>
                            <Pagination totalPage={totalPage} activePage={activePage} startIndex={startIndex} endIndex={endIndex} onPagePrevious={onPagePrevious} onPageUp={onPageUp} onPageNext={onPageNext} />
                        </div>
                    </div>
                </div>
            </div>
            <CustomizedSmallDialogs showModalWindow={showModalWindow}  headers={header}  showModal={showDeleteModalWindow} header={i18n.t("commons.confirmDelete")} type="delete" deleteItem={onDeleteAction} onHide={onDeleteHide} setShowModalWindow={setShowDeleteModalWindow}>
                {i18n.t("userPages.associateUsers.deletConfirmation") + "'" + deleteAssociateName + "'?"}
            </CustomizedSmallDialogs>
        </React.Fragment >
    )
}
export default AssociateUsersList