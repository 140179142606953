import React, { useContext, useEffect, useState } from 'react';
import { AsyncTypeahead } from 'react-bootstrap-typeahead';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { MenuItem, Menu } from '@material-ui/core';
import service from './service';
import { UserProfileBoxTableObject, UserProfileBoxTableBodyOnly } from './UserProfileBoxTable';
import { styled } from "@mui/material/styles";
import LoadingContext from '../../../container/loadingContext';
import { Divider } from '@mui/material';
import CustomizedDialogs from '../../modalWindowComponent/CustomizedDialogs';
import Avatar from '@mui/material/Avatar';
import action from '../../../assets/images/action.png'

import i18n from '../../../utilities/i18n';
import { setStorage, getStorage } from '../../../utilities/browserStorage';
import { checkBrowserAppleSafari, checkPermission, extractPermissions, getPathToRouteOnLoginToAdminModule, getPathToRouteOnLoginToPractice, stringToColor } from '../../../utilities/commonUtilities';
import { ROUTE_DASHBOARD, LOGIN_ROUTE, ROUTE_CHANGE_PASSWORD, PAGING_END_INDEX, DEFAULT_PAGING_SIZE, ROUTE_MYPROFILE } from '../../../utilities/staticConfigs';

import TextInput from '../../commons/input/input';
import Pagination from '../../commons/pagination';
import Table from '../../commons/Table/Table';
import classes from './userprofilebox.module.css';
import { IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

import { useSelector, useDispatch } from 'react-redux';

import { CSSTransition } from 'react-transition-group';
import { pms_staff_user_privilege, super_admin_privileges } from '../../../utilities/permissions';

const isSafariBrowser = checkBrowserAppleSafari();

import './UserProfileBoxStyle.css'

const StyledMenu = styled(Menu)`
  && {
    font-family: "Lato", sans-serif;
    font-size: small;
    margin-top: 45px;
  }
`;

const StyledMenuItem = styled(MenuItem)`
  && {
    padding: px;
    text-align: center;
    height: 34px;
  }
`;

const StyledLink = styled(Link)`
  && {
    font-family: "Lato", sans-serif;
    color: #000000;
    text-decoration: none; /* Override default link styling */
  }
`;

const searchTypeList = [
    { value: "custom_practice_id", name: i18n.t("switchPractice.practiceId") },
    { value: "practice_name", name: i18n.t("switchPractice.practiceName") },
    { value: "custom_provider_id", name: i18n.t("switchPractice.providerId") },
    { value: "provider_name", name: i18n.t("switchPractice.providerName") },
    { value: "custom_facility_id", name: i18n.t("switchPractice.facilityId") },
    { value: "facility_name", name: i18n.t("switchPractice.facilityName") },
    { value: "custom_patient_id", name: i18n.t("switchPractice.patientId") },
    { value: "patient_name", name: i18n.t("switchPractice.patientName") },
    { value: "custom_claim_id", name: i18n.t("switchPractice.claimId") },
    { value: "payment_id", name: i18n.t("switchPractice.paymentId") },
    { value: "npi", name: i18n.t("switchPractice.npi") }
]

function UserProfileBox() {
    const dispatch = useDispatch();
    const history = useHistory();
    const location = useLocation();
    let groupName = '';
    if (getStorage('userData')) {
        groupName = JSON.parse(getStorage('userData')).group_name;
    }
    const [showSwitchPracticeWindow, setShowSwitchPracticeWindow] = useState(false);
    const [customer, setCustomer] = useState("");
    const [customerDropdown, setCustomerDropdown] = useState([]);
    const [searchBy, setSearchBy] = useState("custom_practice_id");
    const [search, setSearch] = useState("");
    const [includeInactivePractice, setIncludeInactivePractice] = useState(false);
    const [defaultPractice, setDefaultPractice] = useState(groupName === "superadmin" ? true : false);
    const [tableValues, setTableValues] = useState([]);
    const setShowLoadingBar = useContext(LoadingContext);
    const [practiceName, setPracticeName] = useState(getStorage('practiceName'));
    const [practicePK, setPracticePK] = useState(getStorage('practice'));

    const [clearBtn, setClearBtn] = useState(0)
    const [customersList, setCustomersList] = useState([]);

    // Redux report status state, this is required to render Progress component in header instead of user profile pic while the report is loading
    const mainReportStatus = useSelector((state) => state.mainReportStatus);

    const [practiceSwitchFlag, setPracticeSwitchFlag] = useState(null);

    function onShowSwitchPractice() {
        setShowSwitchPracticeWindow(true);
        onSearchPractice(DEFAULT_PAGING_SIZE,activePage);
    }

    function resetSwitchPractice() {
        setShowSwitchPracticeWindow(false);
        setCustomer("");
        setSearchBy("custom_practice_id");
        setSearch("");
        setIncludeInactivePractice(false);
        setCustomersList([]);
        setTableValues([]);
    }

    useEffect(() => {
        return () => {
            setPracticeSwitchFlag(null);
        }
    }, [])


    useEffect(() => {
        if (location && location.state?.practiceName && !practiceName)
            setPracticeName(location.state.practiceName);
    })


    function listCustomerDropdaown(query) {
        const result = service.GetListCustomerDropdown(query);
        result.then((response) => {
            setCustomerDropdown(response.data.results);
        });
    }

    function onHandleIncludeInactivePracticeChange() {
        setIncludeInactivePractice(!includeInactivePractice)
    }
    function onHandleDefaultPracticeChange() {
        setDefaultPractice(!defaultPractice);
    }

    function onHandleChange(e) {
        let name = e.target.name;
        let value = e.target.type === "checkbox" ? e.target.checked : e.target.value;
        let trimmedValue = "";
        trimmedValue = value;
        if (name === "searchBy") setSearchBy(trimmedValue);
        else if (name === "searchBox") setSearch(trimmedValue);
        else if (name === "chkIncludeInactive") setIncludeInactivePractice(trimmedValue);
    }

    function onSearchCustomer(query) {
        listCustomerDropdaown(query);
    }

    function onSearchPractice(DEFAULT_PAGING_SIZE,activePage) {
        setShowLoadingBar(true);
        let query = '?default_pratice=' + defaultPractice + '&inactive=' + includeInactivePractice + '&page=' + activePage + '&pageSize=' + DEFAULT_PAGING_SIZE;
        if (customersList.length > 0)
            query += '&customer_pk=' + customersList[0].id;
        if (searchBy.trim())
            query += '&type=' + searchBy;
        if (search.trim())
            query += '&value=' + search;
        const result = service.ListPracticesForSwitching(query);
        result.then(async (response) => {
            if (response?.data?.results !== undefined) {
                setTotalPage(Math.ceil(response.data.count / response.data.page_size));
            }
            const rowArray = await getRowData(response?.data)
            if (rowArray.length > 0) {
                UserProfileBoxTableObject.tableBodyData = rowArray;
            }
            else {
                UserProfileBoxTableObject.tableBodyData = UserProfileBoxTableBodyOnly;
            }

            setTableValues(response?.data?.results);
            setShowLoadingBar(false);
        })
    }

    //making table format data
    function getRowData(data) {
        let anArray = [];
        let rowArray = [];
        if (data?.results?.length) {
            let newData = Array(data.results.length).fill(JSON.parse(JSON.stringify(UserProfileBoxTableObject.tableBodyData[0])));
            newData.forEach((row, rowIndex) => {
                row.forEach((col) => {
                    let colObject = {};
                    colObject = {
                        ...col, 'value': data.results[rowIndex][col.name],
                        "id": data.results[rowIndex].id,
                        "clickOnRowCondition": practicePK
                    }

                    anArray.push(colObject)
                })
                rowArray.push(anArray)
                anArray = [];
            });
        }
        return rowArray;
    }

    async function onSelectPractice(e, id, name, custom_practice_id) {
        setShowLoadingBar(true);
        const result=service.SwitchPractice({
            old_practice: parseInt(getStorage('practice')),
            new_practice: parseInt(id)
        });
        result.then(response => {
            localStorage.removeItem('custom_practice_id');
            setStorage("custom_practice_id", response?.data?.custom_practice_id);
        })
        let selectedPracticeName = name + '(' + custom_practice_id + ')';
        localStorage.removeItem("practice");
        localStorage.removeItem("practiceName");
        let selectedPractice = id;
        setStorage("practice", selectedPractice);
        setStorage("practiceName", selectedPracticeName);
        setPracticeName(selectedPracticeName);
        setPracticePK(selectedPractice);
        resetSwitchPractice();

        /**UPDATE THE PRACTICE LEVEL SIDEBAR MENU AND PERMISSIONS */
        const practiceSideBarMenuAndPermissions = await service.GetPracticeMenuAndPermissionsOnPracticeSwitch(selectedPractice);
        const practiceModuleMenuSet = [];
        const adminModuleMenuSet = [];

        practiceSideBarMenuAndPermissions?.data?.practice_role_permissions?.forEach((item) => {
            // When the module item is of practice module
            if (item.is_super_admin_module === false) {
                // get the sub module lists for sidebar
                const subModules = item.sub_module?.map((subItem) => {
                    if (subItem?.system_generated === false) {
                        return {
                            ...subItem.ui_integration_data
                        }
                    } else {
                        return;
                    }
                });

                // Pushing the module and its sub item to practice module menu along with a dummy sub item
                // that is needed for utility 
                practiceModuleMenuSet.push({
                    ...item.ui_integration_data,
                    sub_menu:
                        subModules?.length ?
                            subModules : []
                });
            } else if (item.is_super_admin_module === true) {
                // get the sub module lists for sidebar
                const subModules = item.sub_module?.map((subItem) => {
                    if (subItem?.system_generated === false) {
                        return {
                            ...subItem.ui_integration_data
                        }
                    } else {
                        return;
                    }
                })
                // Pushing the module and its sub item to admin module menu set along with a dummy sub item
                // that is needed for utility
                adminModuleMenuSet.push({
                    ...item.ui_integration_data,
                    sub_menu: subModules?.length ?
                        subModules : []
                });
            }
        });

        // This code sets the menu's route to a sub-menu item's route if no matching route is found in sub-menu,
        //  offering a default route based on sub-menu content when conditions allow.
        practiceModuleMenuSet.forEach((menu, index) => {
            if (menu.sub_menu?.length && menu.route !== '#') {
                const defaultRoute = menu?.route;
                const defaultSubMenuAvailable = menu?.sub_menu?.findIndex((item) => item?.route === defaultRoute);

                if (defaultSubMenuAvailable === -1) {
                    const defaultSubMenuToSet = menu?.sub_menu?.find((item) => item?.route !== '#')?.route ?? menu?.route;

                    if (defaultSubMenuToSet) {
                        practiceModuleMenuSet[index].route = defaultSubMenuToSet;
                    }
                }
            }
        });

        practiceModuleMenuSet.forEach((item, index) => {
            if (!item?.sub_menu[0]) {
                practiceModuleMenuSet[index].sub_menu = []
            }
        })

        adminModuleMenuSet.forEach((item, index) => {
            if (!item?.sub_menu[0]) {
                adminModuleMenuSet[index].sub_menu = []
            }
        })

        setStorage('practiceModuleMenuSet', JSON.stringify(practiceModuleMenuSet));
        setStorage('adminModuleMenuSet', JSON.stringify(adminModuleMenuSet));
        setStorage('permissionCodeSet', JSON.stringify(extractPermissions(practiceSideBarMenuAndPermissions?.data?.practice_role_permissions, practiceSideBarMenuAndPermissions?.data?.user_role_permissions)));

        setShowLoadingBar(false);
        history.push(getPathToRouteOnLoginToPractice(practiceModuleMenuSet))
        window.location.reload();
    }

    let profileName = '';
    let uName = '';
    let profileImgTag = '';
    let profileImg = '';
    let avatar_name = '';
    let userData = getStorage('userData');

    if (userData) {
        userData = JSON.parse(userData);
        if (userData && typeof userData === 'object' && Object.keys(userData).length > 0) {
            uName = userData.username || '';
            profileName = userData.profile_name ? userData.profile_name.trim() : '';
            profileImg = userData.profile_image || '';
            avatar_name = userData.avatar_name || '';

            if (!profileImg && avatar_name) {
                const backgroundColor = stringToColor(avatar_name);
                profileImgTag = (
                    <Avatar style={{ backgroundColor }}>
                        {avatar_name[0]}
                    </Avatar>
                );
            } else if (profileImg) {
                profileImgTag = (
                    <Avatar src={profileImg} />
                );
            }
        }
    }


    async function onLogout() {
        setShowLoadingBar(true);
        await service.LogOut();
        setShowLoadingBar(false);
        localStorage.clear();
        history.push(LOGIN_ROUTE);
        // Reset all the Redux-states
        dispatch({ type: 'chargesDebit/onResetData' });
        dispatch({ type: 'paymentAdjustment/onResetData' });
        dispatch({ type: 'openArReport/onResetData' });
        dispatch({ type: 'reportFilters/reset' })
        dispatch({ type: "statementMgmnt/resetData" });
        dispatch({ type: "patientInfo/setInitialState" });
        dispatch({ type: "claimManagement/resetClaimtoInitalState" });
        dispatch({ type: "claimSaveManagement/setClaimSavetoInitialState" })
        dispatch({ type: "arClaims/ResetData" })
        dispatch({ type: "ClaimFilter/reset" })
        dispatch({ type: "batchEligibility/onResetData"})
        dispatch({ type: "chatbox/reset" })
        dispatch({ type: "commonStates/reset" })
        dispatch({ type: "paperclaim/reset" });
        dispatch({ type: "claimManagement/resetToInitial"});
        dispatch({ type: "DropDownOptionSlice/resetToInitial"});
    }

    function onSwitchToAdmin() {
        const adminModuleMenuSet = JSON.parse(localStorage.getItem('adminModuleMenuSet'));
        history.push(getPathToRouteOnLoginToAdminModule(adminModuleMenuSet));
        service.SwitchAdmin({
            practice_pk: practicePK,
        });
        setStorage('isAdminModule', true);
        window.location.reload();
    }

    function routeToMyprofile() {
        history.push(ROUTE_MYPROFILE)
        setAnchorEl(null);
    }

    function onSwitchToPractice() {
        // Instead of statically routing the user back to the practice from the admin module (e.g., history.push(ROUTE_DASHBOARD)), 
        // first determine the routes the user has access to, and then route to the appropriate one.
        const practiceModuleMenuSet = JSON.parse(localStorage.getItem('practiceModuleMenuSet'));

        if (practiceModuleMenuSet[0]?.route === ROUTE_DASHBOARD || (practiceModuleMenuSet[0]?.route !== '#' && practiceModuleMenuSet[0]?.route !== '')) {
            history.push(practiceModuleMenuSet[0]?.route);
        } else {
            // If the first module sub modules then find one from the sub module list and route to that sub module page
            const route = practiceModuleMenuSet[0]?.sub_menu.find((subMenu) => {
                return subMenu.route !== '#';
            })?.route;

            if (route) {
                history.push(route);
            }
        }
        setStorage('isAdminModule', false);
        window.location.reload();
    }

    function onChangePassword() {
        history.push(ROUTE_CHANGE_PASSWORD);
    }

    //Pagination start
    const [totalPage, setTotalPage] = useState(1);
    const [activePage, setActivePage] = useState(1);
    const [startIndex, setStartIndex] = useState(0);
    const [endIndex, setEndIndex] = useState(PAGING_END_INDEX);

    function onPagePrevious() {
        let previousPage = startIndex + 1 - PAGING_END_INDEX;
        setActivePage(previousPage);
        if (startIndex !== 0) {
            setStartIndex(startIndex - PAGING_END_INDEX);
            setEndIndex(endIndex - PAGING_END_INDEX);
        }

    }

    function onPageUp(e) {
        let page = Number(e.target.id)
        setActivePage(page);
    }

    function onPageNext() {
        let nextPage = startIndex + 1 + PAGING_END_INDEX;
        if (endIndex === totalPage || totalPage <= PAGING_END_INDEX) {
            setActivePage(nextPage);
            setStartIndex(startIndex);
            setStartIndex(endIndex);
        } else {
            setActivePage(nextPage);
            setStartIndex(startIndex + PAGING_END_INDEX);
            setEndIndex(endIndex + PAGING_END_INDEX);
        }
    }
    //Pagination ends

    useEffect(() => {
        if (practiceSwitchFlag){
            onSearchPractice(DEFAULT_PAGING_SIZE,activePage);
        }
    }, [activePage, defaultPractice, clearBtn, practiceSwitchFlag]);


    function selectOnTab(e) {
        if (e.key === "Tab") {
            document.querySelector("a.dropdown-item.active").click();
        }
    }

    function onRowClick(e, itemId) {
        let obj = tableValues.find(item => item.id == itemId);
        onSelectPractice(e, obj.id, obj.name, obj.custom_practice_id);
    }

    // Reload the tab if user switched the practice in a different tab
    useEffect(() => {
        window.addEventListener('storage', handleStorageEvent);

        return () => {
            window.removeEventListener('storage', handleStorageEvent);
        };
    }, []);

    function handleStorageEvent(event) {

        if (event.key === 'practice') {
            // Reload the page
            window.location.reload();
        }
    }

    const [anchorEl, setAnchorEl] = useState(null);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    // profile box link 
    function getUserProfileBoxLink() {
        return (
            <StyledMenu
                id="dropdown-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
                elevation={2}
            >

                <StyledMenuItem onClick={() => {routeToMyprofile()}}>
                    <StyledLink to="#">{i18n.t("commons.myProfile")}</StyledLink>
                </StyledMenuItem>
                {(getStorage('isAdminModule') === 'true' && groupName === 'superadmin' && getStorage('practice')) && (
                    <>
                        <Divider orientation="horizontal" />
                        <StyledMenuItem onClick={() => { onSwitchToPractice(); setAnchorEl(null); }}>
                            <StyledLink to="#">{i18n.t("commons.backToPractice")}</StyledLink>
                        </StyledMenuItem>
                    </>
                )}
                {(getStorage('isAdminModule') === 'true' && groupName === 'staff_user' && getStorage('practice')) && (
                    <>
                        <Divider orientation="horizontal" />
                        <StyledMenuItem onClick={() => { onSwitchToPractice(); setAnchorEl(null); }}>
                            <StyledLink to="#">{i18n.t("commons.backToPractice")}</StyledLink>
                        </StyledMenuItem>
                    </>
                )}
                {getStorage('isAdminModule') === 'false' && checkPermission(super_admin_privileges.super_admin_full_privilege, pms_staff_user_privilege) && (
                    <>
                        <Divider orientation="horizontal" />
                        <StyledMenuItem onClick={() => { onSwitchToAdmin(); setAnchorEl(null); }}>
                            <StyledLink to="#">{i18n.t("commons.switchToAdmin")}</StyledLink>
                        </StyledMenuItem>
                    </>

                )}
                {getStorage('isAdminModule') === 'false' && (
                    <>
                        <Divider orientation="horizontal" />
                        <StyledMenuItem onClick={() => { onShowSwitchPractice(); setPracticeSwitchFlag(1); setAnchorEl(null); }}>
                            <StyledLink to="#">{i18n.t("switchPractice.switchPractice")}</StyledLink>
                        </StyledMenuItem>
                    </>
                )}
                <Divider orientation="horizontal" />
                <StyledMenuItem onClick={() => { onChangePassword(); setAnchorEl(null); }}>
                    <StyledLink to="#">{i18n.t("commons.changePassword")}</StyledLink>
                </StyledMenuItem>
                <Divider orientation="horizontal" />
                <StyledMenuItem onClick={() => { onLogout(); setAnchorEl(null); }}>
                    <StyledLink to="#">{i18n.t("commons.logout")}</StyledLink>
                </StyledMenuItem>
            </StyledMenu>
        );
    }
    useEffect(() => {
        if (!customersList || customersList.length === 0) {
            onSearchPractice(DEFAULT_PAGING_SIZE,1);
            setActivePage(1);
        }
    }, [customersList]);
    return (
        <div className="container">
            <div className="row">
                <div className="dropdown" id="profile-dropdown">
                    <button className="btn header-btn " type="button" data-toggle="dropdown" onClick={handleClick}>
                        <div style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            margin: "0px minimal",
                            gap: '10px'
                        }}>
                            <div style={{ alignItems: 'center', justifyContent: 'center', textAlign: 'center' }}> {profileImgTag} </div>
                            {isSafariBrowser && <input type="image" id="img-profile-logo" src={action} alt="" style={{ width: '0px' }} />}
                            <div style={{
                                display: "flex",
                                alignItems: "center",
                                gap: '10px'
                            }}>
                                <div style={{ display: "flex", flexDirection: "column", justifyContent: "flex-start", alignItems: "start" }}>
                                    <div
                                        style={{
                                        lineHeight: "16px", 
                                        whiteSpace: "nowrap",
                                        fontFamily: "Inter, Lato", 
                                        overflow: "hidden", 
                                        textOverflow: "ellipsis" 
                                    }}
                                    >
                                        {practiceName?.trim()?.substring(0, 16)}
                                    </div>
                                    <div style={{
                                        color: "var(--grey-g-600, #757575)",
                                        fontFeatureSettings: "'clig' off, 'liga' off",
                                        fontFamily: "Inter, Lato",
                                        fontSize: "12px",
                                        fontStyle: "normal",
                                        fontWeight: 400,
                                        lineHeight: "16px",
                                        paddingTop: "2px"
                                    }}>
                                        {(profileName?.trim()?.length > 1) ? profileName?.trim()?.substring(0, 25) : uName?.trim()?.substring(0, 25)}
                                    </div>
                                </div>
                                <div style={{ marginLeft: '13px' }}> 
                                    <img src={action} alt="settings" />
                                </div>
                            </div>
                        </div>
                        {/** If Any Report is generating in the background Render progress component instead of Profile Picture */}
                        <div className='row' style={{ height: '3px', marginTop: '2px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                            {mainReportStatus.isReportRunning && (
                                <CSSTransition
                                    in={mainReportStatus.isReportRunning}
                                    timeout={500} 
                                    classNames="fade"
                                    unmountOnExit
                                >
                                    <div className="progress-bar-container">
                                        <div className="progress-bar" />
                                    </div>
                                </CSSTransition>
                            )}
                        </div>
                    </button>
                    {getUserProfileBoxLink()}
                </div>

                <CustomizedDialogs modalId={'longWidth'} showModal={showSwitchPracticeWindow} header={i18n.t("switchPractice.switchPractice")} setShowModalWindow={setShowSwitchPracticeWindow} resetForm={resetSwitchPractice}>
                    <div className="basic-info-padding">
                        <div className="row">
                            {groupName === 'superadmin' &&
                                <div className="col-3">
                                    <div className="form-group padding-top32">
                                        <div className="searchformfld">
                                            <AsyncTypeahead
                                                id="basic-typeahead-single"
                                                labelKey="name"
                                                minLength={3}
                                                onChange={setCustomersList}
                                                options={customerDropdown}
                                                placeholder={i18n.t("switchPractice.selectCustomer")}
                                                onSearch={onSearchCustomer}
                                                selected={customersList}
                                                onKeyDown={(e) => selectOnTab(e)}
                                                name="customer"
                                                value={customer}
                                                clearButton={true} />
                                        </div>
                                    </div>
                                </div>
                            }
                            <div className="col-3 pl-0 padding-top7">
                                <div className="padding-left15">{i18n.t("switchPractice.searchBy")}</div>
                                <div className="form-group select-input padding-left15 padding-top5">
                                    <select name="searchBy" id="searchBy"
                                        value={searchBy} onChange={onHandleChange}
                                    style={{height:"35px", borderColor: "#ced4da" }}>
                                        {searchTypeList.map((item, index) => {
                                            return <option key={index} value={item.value}>{item.name}</option>
                                        })}
                                    </select>
                                </div>
                            </div>
                            <div className="col-3 pl-0 ">
                                <div className="form-group padding-top4 relative">
                                    <TextInput name="searchBox" type="text" label={i18n.t("switchPractice.search")} value={search} onValueChange={onHandleChange} />
                                    {(search.length !== 0) && <IconButton className='mr-1 mt-2 profileIconBtn_close' onClick={() => { setSearch(''); setClearBtn(clearBtn + 1) }}
                                    >
                                        <CloseIcon sx={{ fontSize: "small", color: '#9093a4' }} />
                                    </IconButton>}
                                </div>
                            </div>
                            <div className="col-3 pl-0 padding-top4">
                                <div className="row">
                                    <div className="col-6">
                                        <div className="padding-top25">
                                            <button type="button" 
                                             onClick={() =>  {onSearchPractice(DEFAULT_PAGING_SIZE,1);setActivePage(1); }}
                                             className="btn btn-primary-blue">{i18n.t("switchPractice.search")}</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-3">
                                <input type="checkbox" id="chkIncludeInactive" checked={includeInactivePractice} onMouseDown={e => onHandleIncludeInactivePracticeChange(e)} />
                                <label className="chk-label" >{i18n.t("switchPractice.inactivePractice")}</label>
                            </div>
                            {groupName === 'superadmin' && <div className="col-3">
                                <input type="checkbox" id="chkDefaultPractices" checked={defaultPractice} onMouseDown={e => onHandleDefaultPracticeChange(e)} />
                                <label className="chk-label" >{i18n.t("switchPractice.showSelectedPractices")}</label>
                            </div>}
                        </div>
                        <div className={`form-class ${classes.WrapperDiv}`}>
                            <Table tableObject={UserProfileBoxTableObject} onRowClick={onRowClick} />
                            <Pagination totalPage={totalPage} activePage={activePage} startIndex={startIndex} endIndex={endIndex} onPagePrevious={onPagePrevious} onPageUp={onPageUp} onPageNext={onPageNext} />
                            <div className="clear-b-scroll-sector">&nbsp;</div>
                        </div>
                    </div>
                </CustomizedDialogs>

            </div>
        </div>
    )
}

export default UserProfileBox