import {
  Build,
  ElectricalServices,
  FilterAlt,
  Medication,
  Money,
  Person,
} from "@mui/icons-material";
import { Box, Tab, Tabs } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import LoadingContext from "../../../container/loadingContext";
import service from "../service";
import ChargeFacilityIdFilterGroupCard from "./ChargeFacilityIdFilterGroupCard";
import ChargeRenderingProviderIdFilterGroupCard from "./ChargeRenderingProviderIdFilterGroupCard";
import ChargeStatusFilterGroupCard from "./ChargeStatusFilterGroupCard";
import ClaimPracticeIdFilterGroupCard from "./ClaimPracticeIdFilterGroupCard";
import CustomTabPanel from "./CustomTabPanel";
import GeneralFilterCard from "./GeneralFilterCard";
import ReportContext from "./report-context";
import ShowReportCard from "./ShowReportCard";
import StaticFilterGroupCard from "./StaticFilterGroupCard";
import { useDispatch, useSelector } from "react-redux";
import { addReportDataItem } from "../../../store/reports/reportViewerSlice";
import { setResults } from "../../../store/reports/reportResultSlice";
import { BarChart, Clear, Save } from "@material-ui/icons";

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    "aria-controls": `vertical-CustomTabPanel-${index}`,
  };
}
const ReportResultCard = ({ reportName, reportId }) => {
  const ctx = useContext(ReportContext);
  const dispatch = useDispatch();
  const setShowLoadingBar = useContext(LoadingContext);
  const reportData = useSelector((state) => state.reportViewer);
  const [value, setValue] = useState(0);
  const randomReportId=ctx.reportUuid;
  const [showreport, setShowreport] = useState(false);
  const [reportDetails, setReportDetails] = useState();

  const fetchReportDetails = () => {
    setShowLoadingBar(true);
    service
      .getSavedReportData(reportId)
      .then((response) => {
        setShowLoadingBar(false);
        if (response.status === 200 || response.status === 201) {
          setReportDetails(response.data);
        }
      })
      .catch(() => {
        setShowLoadingBar(false);
      });
  };

  useEffect(() => {
    dispatch(
      addReportDataItem({
        reportName: reportName,
        reportId: reportId,
        reportTabId: ctx.reportUuid,
      })
    );
    fetchReportDetails();
  }, []);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const runReportHandler = () => {
    const currentReportFilters = reportData.reportData.find(
      (report) => report.reportTabId === ctx.reportUuid
    );
    if (currentReportFilters) {
      const body = { ...currentReportFilters.reportDetails };
      setShowLoadingBar(true);
      service
        .runReport(body)
        .then((response) => {
          if (response.status === 200) {
            setShowLoadingBar(false);
            dispatch(setResults({ results: response.data }));
            setShowreport(true);
          } else {
            setShowLoadingBar(false);
            throw new Error(response.statusText);
          }
        })
        .catch((err) => {
          return err.message
        });
    }
  };
  return (
    <Box
      sx={{
        flexGrow: 1,
        bgcolor: "background.paper",
        display: "flex",
      }}
    >
      {showreport ? (
        <ShowReportCard />
      ) : (
        <>
          <Tabs
            orientation="vertical"
            variant="scrollable"
            value={value}
            onChange={handleChange}
            aria-label="Vertical tabs example"
            sx={{ borderRight: 2, borderColor: "divider" }}
          >
            {reportDetails &&
              reportDetails.filters.map((element, index) => (
                <Tab
                  key={index}
                  className="tab"
                  icon={getIconByType(element)}
                  label={element.name}
                  {...a11yProps(index)}
                />
              ))}
          </Tabs>
          <div className="container">
            <div className="row">
              <div className="col-12">
                <div>
                  <button
                    className="btn btn-primary-blue"
                    onClick={runReportHandler}
                  >
                    <BarChart />
                    &nbsp; Run Report
                  </button>
                  <button className="btn btn-primary-blue ml-2">
                    <Save />
                    &nbsp; Saved Filters
                  </button>
                  <button className="btn btn-primary-blue ml-2">
                    <Clear />
                    &nbsp; Cancel
                  </button>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-12">
                {reportDetails &&
                  reportDetails.filters.map((element, index) => (
                    <CustomTabPanel value={value} index={index} key={index}>
                      {getElementByType(element, randomReportId)}
                    </CustomTabPanel>
                  ))}
              </div>
            </div>
          </div>
        </>
      )}
    </Box>
  );
};

function getIconByType(element) {
  let icon;
  switch (element.id) {
    case 1:
      icon = <FilterAlt sx={{ fontSize: 60 }} />;
      break;
    case 2:
      icon = <Build sx={{ fontSize: 60 }} />;
      break;
    case 3:
      icon = <Medication sx={{ fontSize: 60 }} />;
      break;
    case 4:
      icon = <Person sx={{ fontSize: 60 }} />;
      break;
    case 5:
      icon = <ElectricalServices sx={{ fontSize: 60 }} />;
      break;
    case 6:
      icon = <Money sx={{ fontSize: 60 }} />;
      break;
    default:
      icon = <div>Invalid tab Selected</div>;
      break;
  }
  return icon;
}

function getElementByType(element, randomReportId) {
  let component;
  switch (element.id) {
    case 1:
      component = (
        <GeneralFilterCard filter={element} randomReportId={randomReportId} />
      );
      break;
    case 2:
      component = (
        <StaticFilterGroupCard
          filter={element}
          randomReportId={randomReportId}
        />
      );
      break;
    case 3:
      component = (
        <ChargeStatusFilterGroupCard
          filter={element}
          randomReportId={randomReportId}
        />
      );
      break;
    case 4:
      component = (
        <ClaimPracticeIdFilterGroupCard
          filter={element}
          randomReportId={randomReportId}
        />
      );
      break;
    case 5:
      component = (
        <ChargeFacilityIdFilterGroupCard
          filter={element}
          randomReportId={randomReportId}
        />
      );
      break;
    case 6:
      component = (
        <ChargeRenderingProviderIdFilterGroupCard
          filter={element}
          randomReportId={randomReportId}
        />
      );
      break;
    default:
      component = <div>Invalid tab Selected</div>;
      break;
  }
  return component;
}
export default ReportResultCard;
