import React, { useEffect, useState, useContext, useRef } from 'react'
import { Grid, Divider, Typography } from '@mui/material'
import CalendarInput from '../commons/input/CalendarInput';
import CommonButton from '../commons/Buttons';
import { useHistory,useLocation } from "react-router-dom";
import { DateTime } from 'luxon';
import service from './service';
import InfiniteScroll from 'react-infinite-scroller';
import { AsyncTypeahead } from 'react-bootstrap-typeahead'
import LoadingContext from '../../container/loadingContext';

import i18n from '../../utilities/i18n';
import Notify from '../commons/notify';
import Label from '../commons/Label';
import AsyncTypeInput from '../commons/input/AsyncTypeHead/AsyncTypeInput';
import { getStorage } from '../../utilities/browserStorage';
import checkPermissionMiddleware from '../../container/permissionCheckMiddleware';
import { ROUTE_PATIENTS_LIST, ROUTE_PRACTICES_ADD, ROUTE_SEARCH_CLAIMS } from '../../utilities/staticConfigs';


const linkText = (linkText, path, data) => {
    const history = useHistory();

    return (
        <span
            style={{
                color: 'var(--primary-p-2, #1479BB)',
                fontFeatureSettings: "'clig' off, 'liga' off",
                fontFamily: 'Lato',
                fontSize: '16px',
                fontStyle: 'normal',
                fontWeight: 500,
                lineHeight: 'normal',
                cursor: 'pointer',
                whiteSpace: 'nowrap',
                padding: '0'
            }}
            onClick={() => history.push(path, data)}
        >
            {` ${linkText} `}
        </span>
    );
}

const subText = (subText) => {
    return (
        <span style={{
            color: 'var(--grey-700, #616161)',
            fontFeatureSettings: "'clig' off, 'liga' off",
            fontFamily: 'Lato',
            fontSize: '16px',
            fontStyle: 'normal',
            fontWeight: 400,
            lineHeight: 'normal'
        }}>
            {subText}
        </span>
    );
}


function UserActionLogs1() {
    const nameTypeAheadRef = useRef();
    const [actionLog, setActionLog] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [hasMore, setHasMore] = useState(false);
    const [nextOffset, setNextOffset] = useState(0);
    const [fromDate, setFromDate] = useState();
    const [milliSeconds1, setMilliSeconds1] = useState('');
    const [milliSeconds2, setMilliSeconds2] = useState('')
    const [toDate, setToDate] = useState();
    const [patientlist, setPatientList] = useState();
    const [patientSelected, setPatientSelected] = useState([]);
    const [showNotify, setShowNotify] = useState("hide");
    const [notifyDescription, setNotifyDescription] = useState("");
    const [notifyType, setNotifyType] = useState("success");
    const [maxResults, setMaxResults] = useState(5);
    const [masterClaims, setMasterClaims] = useState([]);
    const [claimId, setClaimId] = useState("");
    const [selectedClaimVals, setSelectedClaimVals] = useState([]);
    const [parmsClaimid, setParamsClaimid] = useState('');
   
   
    const limit = 20;
    const setShowLoadingBar = useContext(LoadingContext);
    const pathName = useLocation().pathname;
    /**
     * initial api call for action log
     */
    useEffect(() => {
        if (window.claimInfo && pathName === "/logs/claim") {
            let claimData = window.claimInfo;

            setParamsClaimid(claimData.claim_ID);
        } else {
           
           // setParamsClaimid('');
            onReset();
        }
    }, [pathName]);


 useEffect(() => {
     if (parmsClaimid) {
         getUserActionLogs(nextOffset);
     }
 }, [parmsClaimid]);

    const getUserActionLogs = async (offset) => {
        
        setIsLoading(true);
        setShowLoadingBar(true);
        let path = `audit-logs/?measurement=audit&limit=${limit}&offset=${offset}`;
        if (fromDate && toDate) {
            path = path + `&time_range=[${milliSeconds1},${milliSeconds2}]&tags={"practice_id": [${Number(getStorage('practice')) ? Number(getStorage('practice')) : 0}]}`
        }
       
        if (patientSelected.length > 0) {
            path = path + `&tags={"patient":["${patientSelected[0].id}"], "practice_id": [${Number(getStorage('practice')) ? Number(getStorage('practice')) : 0}]}`
        }
       
        if (selectedClaimVals?.length > 0  ) {
            
            path = path + `&tags={"claim":["${selectedClaimVals[0]?.id}"], "practice_id": [${Number(getStorage('practice')) ? Number(getStorage('practice')) : 0}]}`
        }
        
        if (parmsClaimid != '') {
            path = path + `&tags={"claim":["${parmsClaimid}"], "practice_id": [${Number(getStorage('practice')) ? Number(getStorage('practice')) : 0}]}`
        }

        // if path does not have any practice_id tag then add practice_id tag
        if (!path.includes('practice_id')) {
            path = path + `&tags={"practice_id": [${Number(getStorage('practice')) ? Number(getStorage('practice')) : 0}]}`
        }
      

        const result = await service.getLogs(path);
        if (result.data && result.data.length > 0) {
            let data = actionLog;
            result.data.forEach(element => {
                data.push(element)
            });
            setActionLog(data)
            setShowLoadingBar(false);
            setHasMore(true)
        }
        else {
            setHasMore(false)
            setShowLoadingBar(false);
            // showNotifyWindow("show", 'error', "No record found")
        }
        setTimeout(() => {
            setIsLoading(false);
        }, 500);
        
    }

    const loadNextPage = () => {
        if (!isLoading) {
            let offset = nextOffset + 20;
            getUserActionLogs(offset);
            setNextOffset(offset);
        }
    }

    function formatTimestamp(timestampString) {
        const unixTimestamp = parseInt(timestampString);
        const timestamp = new Date(unixTimestamp);
        const formattedTimestamp = timestamp.toLocaleString('en-US', {
            year: 'numeric',
            month: 'short',
            day: 'numeric',
            hour: 'numeric',
            minute: 'numeric',
            second: 'numeric',
            hour12: true,
        });
        return formattedTimestamp;
    }

    function parseLogString(logString, logData) {
        let parsedLogString = logString;

        const replaceItem = (item) => {
            const variable = logData?.variables[item];
            if (variable) {
                const { id, value, type } = variable;
                const linkPaths = {
                    claim: ROUTE_SEARCH_CLAIMS,
                    patient: ROUTE_PATIENTS_LIST,
                    practice: ROUTE_PRACTICES_ADD
                };

                if (['claim', 'patient', 'practice'].includes(type)) {
                    const permissions = checkPermissionMiddleware(linkPaths[type]);
                    return permissions && (id && !isNaN(id)) ? linkText(value, linkPaths[type], { selectedID: id }) : subText(value);
                } else if (['datetime'].includes(type)) {
                    return formatTimestamp(value)
                }
                else {
                    return subText(value ?? "");
                }
            }
        };

        const elements = parsedLogString
            ?.split(/(<<[^>>]+>>)/g)
            ?.map((part) => {
                if (part.startsWith("<<") && part.endsWith(">>")) {
                    return replaceItem(part.slice(2, -2));
                } else {
                    return part;
                }
            });

        return <Typography variant='h6' style={{ fontSize: '16px' }}> {elements}</Typography>;
    }

    const parseLogs = (log) => {
        const logString = log.log_str;
        const timeStamp = log?.variables?.time.value;

        return (
            <Grid item container direction='row' justifyContent='space-between' sx={{ paddingBottom: '2px', paddingTop: '2px' }}>
                {parseLogString(logString, log)}
                <span style={{
                    color: 'var(--grey-g-600, #757575)',
                    textAlign: 'right',
                    fontFeatureSettings: "'clig' off, 'liga' off",
                    fontFamily: 'Lato',
                    fontSize: '14px',
                    fontStyle: 'normal',
                    fontWeight: 500,
                    lineHeight: 'normal',
                    width:'100%'
                }}>
                    {DateTime.fromMillis(parseInt(timeStamp)).toRelative({ style: 'long' })}
                </span>
                <Divider sx={{ height: '2px', width: '100%'}} />
            </Grid>
        );
    };

    const onChangeDate = (date, type) => {
        if (date != 'Invalid Date') {
            if (type == 'fromDate') {
                setFromDate(date);
                const date1 = new Date(date).toISOString();
                const milliSeconds1 = DateTime.fromISO(date1).toFormat('x');
                setMilliSeconds1(milliSeconds1)
            }
            else {
                setToDate(date);
                const date2 = new Date(date).toISOString();
                const milliSeconds2 = DateTime.fromISO(date2).toFormat('x');
                setMilliSeconds2(milliSeconds2)
            }
        }
    }

    const onSearch = () => {
        getSearchData()
    }


    const getSearchData = (resetflag=false) => {
        

        setShowLoadingBar(true);
        let path = `audit-logs/?measurement=audit&limit=${limit}&offset=${0}`;

        if (!resetflag && fromDate && toDate) {
            path = path + `&time_range=[${milliSeconds1},${milliSeconds2}]&tags={"practice_id": [${Number(getStorage('practice')) ? Number(getStorage('practice')) : 0}]}`
        }

        if (!resetflag && patientSelected.length > 0) {
            path = path + `&tags={"patient":["${patientSelected[0].id}"],"practice_id": [${Number(getStorage('practice')) ? Number(getStorage('practice')) : 0}]}`
        }

        if (!resetflag && selectedClaimVals.length > 0) {
            path = path + `&tags={"claim":["${selectedClaimVals[0].id}"],"practice_id": [${Number(getStorage('practice')) ? Number(getStorage('practice')) : 0}]}`
        }

        // if path does not have any practice_id tag then add practice_id tag
        if (!path.includes('practice_id')) {
            path = path + `&tags={"practice_id": [${Number(getStorage('practice')) ? Number(getStorage('practice')) : 0}]}`
        }


        const result = service.getLogs(path);
        result.then(response => {
            if (response.data && response.data.length > 0) {
                setActionLog(response.data)
                setShowLoadingBar(false);
                setHasMore(true)
                setNextOffset(0)
            }
            else {
                setNextOffset(0)
                setActionLog([])
                setHasMore(false)
                setShowLoadingBar(false);
                //showNotifyWindow("show", 'error', "No record found")
                
            }
        })
    }

    const handleTypeheadOnchange = (e) => {
        onHandleChange(e);
    };

    const onHandleChange = (e) => {
        if (e[0]) {
            if (
                patientSelected.length == 0 ||
                (patientSelected.length &&
                    parseInt(patientSelected[0].id) != parseInt(e[0].id))
            ) {
                setPatientSelected(e);
            }
        } else {
            if (e[0]) {
                setPatientSelected(e);
            }
            else {
                setPatientSelected([]);
            }
        }
    }

    function onSearchInsurances(query) {
        listInsuranceDropdown(query);
    }

    function listInsuranceDropdown(query) {
        getMasterClaims(query);
    }

    //api call for claim id
    function getMasterClaims(query) {
        query = query + '&filter=1';
        const result = service.ListClaimsDropDown(
            query,
            getStorage("practice")
        );

        result.then((response) => {
            setMasterClaims(response.data);
        });
    }

    //patient dropdown search
    const onSearchPatient = (query) => {
        listPatientDropdown(query)
    }

    //show error message
    function showNotifyWindow(action, type, desc, age = 3000) {
        if (action == "show") {
            setTimeout(() => {
                setShowNotify("hide");
            }, age);
        }
        setShowNotify(action);
        setNotifyType(type);
        setNotifyDescription(desc);
    }

    //calling patient list on type
    const listPatientDropdown = async (query) => {
        try {
            const response = await service.GetListPatientDropdown(query);
            if (response.data?.length) {
                setPatientList(response.data);
            } else {
                setPatientList([]);
            }
        } catch (error) {
            showNotifyWindow("show", "error", i18n.t("errorMessages.commonError"));
        }
    };

    //claimid selecting function

    function onHandleClaimIDChange(e) {
        if (e[0]) {
            setClaimId(e[0].id);
            setSelectedClaimVals(e)
        } else {
            setClaimId("");
            setSelectedClaimVals([])
        }
    }

    const onReset = () => {
        setNextOffset(0)
        setFromDate('')
        setToDate('')
        setClaimId('')
       setParamsClaimid('');
        setSelectedClaimVals([])
        setPatientSelected([])
        getSearchData(true)
    }


    return (
        <div className="col-md-12 min-height-500">
            <Notify
                showNotify={showNotify}
                setShowNotify={setShowNotify}
                notifyDescription={notifyDescription}
                setNotifyType={setNotifyType}
                setNotifyDescription={setNotifyDescription}
                notifyType={notifyType}
            />
            <div className='margin-right15'>
            <Grid
                container
                sx={{
                    border: '1px solid var(--grey-300, #E0E0E0)',
                    background: 'var(--grey-ff, #FFF)',
                    padding: '16px 24px',
                    borderRadius: '8px 8px 0px 0px',
                }}
            >
               
                {/* Filters */}
                <Grid item xs={12}>
                    <Grid item xs={12}>
                    {!parmsClaimid?
                        <Grid container direction="row" spacing={2}>
                      
                            <Grid item>
                                <Label label="Search Patient" />
                                <AsyncTypeahead
                                    className="patient_name"
                                    id="basic-typeahead-single"
                                    labelKey={"name"}
                                    maxResults={maxResults}
                                    onPaginate={() => setMaxResults(maxResults + 5)}
                                    paginationText={"Load More"}
                                    minLength={3}
                                    options={patientlist}
                                    placeholder=""
                                    onSearch={onSearchPatient}
                                    name="patient_name"
                                    ref={nameTypeAheadRef}
                                    onChange={(e) => handleTypeheadOnchange(e)}
                                    // inputProps={props.inputProps}
                                    selected={patientSelected}
                                    clearButton={true}
                                    override={{
                                        dropdownMenu: {
                                            maxHeight: "185px !important",
                                            overflowY: "scroll",
                                        },
                                    }}
                                  
                                />
                            </Grid>
                        
                            <Grid item>
                                <AsyncTypeInput
                                    id="searchByClaimId"
                                    labelKey="name"
                                    minLength={0}
                                    // onChange={setPatientList}
                                    options={masterClaims}
                                    onSearch={onSearchInsurances}
                                    name="selectClaim"
                                    value={claimId}
                                    onValueChange={onHandleClaimIDChange}
                                    selected={selectedClaimVals}
                                    label={i18n.t(
                                        "payments.post_payments.insurancePayment.searchByClaimId"
                                    )}
                                  
                                />
                            </Grid>

                            <Grid item>
                                <CalendarInput
                                    selected={fromDate}
                                    onValueChange={(date) => onChangeDate(date, 'fromDate')}
                                    name="date_from"
                                    id="date_from"
                                    label={"Date From"}
                                    disableFuture={true}
                                    maxDate={toDate}
                                />
                            </Grid>

                            <Grid item style={{ marginBottom: 3 }}>
                                <CalendarInput
                                    selected={toDate}
                                    onValueChange={(date) => onChangeDate(date, 'toDate')}
                                    name="date_to"
                                    id="date_to"
                                    label={"Date To"}
                                    disableFuture={true}
                                    minDate={fromDate}
                                />
                            </Grid>
                            <Grid item sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                <CommonButton
                                    variant='outlined'
                                    label='Reset'
                                    onClick={() => onReset()}
                                    style={{ width: 'auto' }}
                                />
                            </Grid>
                            <Grid item sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                <CommonButton
                                    variant='contained'
                                    label='Search'
                                    onClick={() => onSearch()}
                                    style={{ width: 'auto', minWidth: '120px' }}
                                />
                            </Grid>
                        </Grid>:null}
                    </Grid>

                    <Grid item xs={4} container justifyContent="flex-end" alignItems="center">

                    </Grid>
                </Grid>  
                {!parmsClaimid?
                <Divider sx={{ height: '2px', width: '100%' }} />:null}
              
                {actionLog?.length > 0 ?
                    <Grid item xs={12} sx={{ padding: '5px' }}>
                        <Grid container />
                        <InfiniteScroll
                            pageStart={0}
                            loadMore={loadNextPage}
                            hasMore={(!isLoading && hasMore)}
                            threshold={20}
                            loader={<div className="loader" key={0}>Loading ...</div>}
                        >
                            {actionLog.map((log) => {
                                return log?.log_str ? parseLogs(log) : null;
                            })}
                        </InfiniteScroll>
                        <Grid />
                    </Grid> :
                    <Grid item xs={12} sx={{ paddingTop: 10, justifyContent:'center',textAlign:'center'}}>
                        <span style={{ fontSize: 16 }}>No Record found</span>
                    </Grid>
                }
            </Grid>
            </div>
        </div>
    )
}

export default UserActionLogs1;