import React, { useContext, useState } from "react";
import { Form } from "react-bootstrap";
import { Link } from "react-router-dom";
import format from "date-fns/format";

import service from "./service";
import { PatientFamilyTableData, TableBodyData } from './PatientsToAddFamilyTable';

import LoadingContext from "../../../container/loadingContext";

import i18n from "../../../utilities/i18n";
import { commonTableBody } from '../../../utilities/commonUtilities';

import Table from '../../commons/Table/Table';
import CalendarInput from "../../commons/input/CalendarInput";
import AsyncTypeInput from "../../commons/input/AsyncTypeHead/AsyncTypeInput";

import CustomizedDialogs from "../../modalWindowComponent/CustomizedDialogs";
import CommonButton from "../../commons/Buttons";

function PatientsToAddFamily(props) {
    const practicePK = props.practicePK;
    const patientPK = props.patientPK;
    const setShowLoadingBar = useContext(LoadingContext);
    const [header, setHeader] = useState("");
    const [search, setSearch] = useState("");
    const [familyDOB, setFamilyDOB] = useState("");
    const [isFamilyMember, setisFamilyMember] = useState({});
    const [showPatientFamilyModalWindow, setShowPatientFamilyModalWindow] = useState(false);
    const [familyMembers, setFamilyMembers] = useState([]);
    const [FamilyData, setFamilyData] = useState([]);
    const [patientDropdown, setPatientDropdown] = useState([]);

    function showFamilyMembers() {
        getFamilyMembers();
        setShowPatientFamilyModalWindow(true);
        setHeader(i18n.t("patientPages.insuranceInfo.family.header"));
    }

    function getFamilyMembers() {
        const data = service.GetFamilyMembers(patientPK);
        data.then((response) => {
            setFamilyMembers(response.data);
        });
    }

    function resetFamilyForm() {
        setSearch("");
        setFamilyDOB("");
        setisFamilyMember({});
    }

    function onHandleChange(e) {
            if (e[0]) {
                setSearch(e[0].id);
                SearchFamilyMembers(e[0].id);
            }
            else if (!e[0]) {
                setSearch('');
            }
     }

    function SearchFamilyMembers(searchedPatientPK) {
        if (!familyDOB && !search) return;
        setShowLoadingBar(true);
        let DOB;
        if (familyDOB) {
            DOB = format(familyDOB, "yyyy-MM-dd");
        }
        let result = service.ListPatientsToAdd(practicePK, patientPK, DOB, searchedPatientPK);
        result.then((response) => {
            const rowArray = commonTableBody(response.data, PatientFamilyTableData.tableBodyData[0])
            PatientFamilyTableData.tableBodyData = rowArray;
            setShowLoadingBar(false);
            setFamilyData(rowArray);
        });
    }

    function oncheck(e, id) {
        setShowLoadingBar(true);
        let name = e.target.name;
        let fam = isFamilyMember;
        fam[name] = true;
        setisFamilyMember(fam);
        fam[name] = false;
        let data = { patient: patientPK, family_member: id };
        const result = service.AddToFamilyMember(data);
        result.then(() => {
            setisFamilyMember(fam);
            getFamilyMembers();
            setShowLoadingBar(false);
            PatientFamilyTableData.tableBodyData = TableBodyData;
            setFamilyDOB("");
        });
    }
    function onDelete(id) {
        setShowLoadingBar(true);
        let result = service.DeleteFamilyMember(patientPK, id);
        result.then(() => {
            getFamilyMembers();
            setShowLoadingBar(false);
        });
    }

    function onSearchPatients(query) {
        listPatientDropdown(query);
    }

    function listPatientDropdown(query) {
        const result = service.GetListPatientDropdown(query, 'family', patientPK);
        result.then((response) => {
            setPatientDropdown(response.data);
        });
    }

    function onPlusButtonClick(e, id) {
        oncheck(e,id); 
    }

    return (
        <div>
            <Form autoComplete="off">
                <Form.Group>
                    <div className="">
                        <button type="button" data-testid="addFamily-btn" className="btn btn-secondary" id="addNewInsurance" onClick={showFamilyMembers} >
                            {i18n.t("patientPages.buttons.family")}
                        </button>
                    </div>
                </Form.Group>
            </Form>
            <CustomizedDialogs
                showModal={showPatientFamilyModalWindow}
                header={header}
                modalId={'longWidth'}
                setShowModalWindow={setShowPatientFamilyModalWindow}
                resetForm={resetFamilyForm}
            >
                <div className="">
                    <Form
                        id="form_patient_family"
                        encType="multipart/form-data"
                        autoComplete="off"
                    >
                        <div className="margin-bottom10">
                            {familyMembers.length > 0 &&
                                <div className="margin-bottom10">
                                    <div className="bold">{i18n.t("patientPages.family.family")}</div>
                                </div>
                            }
                            {familyMembers.map((item, index) => {
                                return (
                                    <div key={index} className="row">
                                        <div className="col-2" >
                                            <label data-testid="member-label">
                                                {item.member_name}
                                            </label>
                                        </div>
                                        <div className="col-1">
                                            <Link to="#" onClick={() => onDelete(item.id)} data-testid="member-remove-btn">
                                                <small className="danger">
                                                    {i18n.t("patientPages.family.remove")}
                                                </small>
                                            </Link>
                                        </div>
                                    </div>
                                );
                            })}
                        </div>


                        <div className="row">
                            <div className="col-3">
                                <div className="form-group padding-top15">
                                    <AsyncTypeInput labelKey="name"
                                     id="insuranceFamily" 
                                     minLength={3} 
                                     options={patientDropdown}
                                     label={i18n.t("patientPages.family.label.searchBy")} 
                                     onSearch={onSearchPatients} name="selectPatient"
                                     onValueChange={onHandleChange}
                                     selected= {[]}
                                    />
                                </div>
                            </div>
                            <div className="col-3 mt-3 pl-0">
                                <CalendarInput
                                    label={i18n.t("patientPages.family.dob")}
                                    name="DOB"
                                    id="DOB"
                                    placeholder="DOB"
                                    selected={familyDOB}
                                    onValueChange={(selected) => setFamilyDOB(selected)} />
                            </div>
                            <div className="col-1 mt-5 pl-0" >
                            <CommonButton variant="contained"  label="Search" onClick={() => SearchFamilyMembers()}/>
                            </div>
                        </div>

                        <Table tableObject={PatientFamilyTableData} list={FamilyData} onPlusButtonClick={onPlusButtonClick} />

                    </Form>
                </div>
            </CustomizedDialogs>
        </div>
    );
}

export default PatientsToAddFamily;
