import i18n from "../../../utilities/i18n";

export const colmonth=i18n.t("reports.month");
export const colreceivedDate=i18n.t("reports.receivedDate");
export const colbilledAmount=i18n.t("reports.billedAmount");
export const colnoofbilled=i18n.t("reports.noofbilled");
export const colnoofpaid=i18n.t("reports.noofpaid");
export const coltotalpaid=i18n.t("reports.totalpaid");
export const colexpectedRevenue=i18n.t("reports.expectedRevenue");
export const colnoofexceptions=i18n.t("reports.noofexceptions");
export const coltotal_amount_exception=i18n.t("reports.total_amount_exception");
export const colnoofpendingunbilled=i18n.t("reports.noofpendingunbilled");
export const coltotal_amount_unbilled=i18n.t("reports.total_amount_unbilled");
export const colnoofclientbill=i18n.t("reports.noofclientbill");
export const coltotalAmountClientBill=i18n.t("reports.totalAmountClientBill");
export const colnoofselfpay=i18n.t("reports.noofselfpay");
export const coltotalAmountselfpay=i18n.t("reports.totalAmountselfpay");
export const colinsurancePayerName=i18n.t("reports.insurancePayerName");

export const ReportTableHead = [
    colmonth,
    colreceivedDate,
    colbilledAmount,
    colnoofbilled,
    colnoofpaid,
    coltotalpaid,
    colexpectedRevenue,
    colnoofexceptions,
    coltotal_amount_exception,
    colnoofpendingunbilled,
    coltotal_amount_unbilled,
    colnoofclientbill,
    coltotalAmountClientBill,
    colnoofselfpay,
    coltotalAmountselfpay,
    colinsurancePayerName
]

export const reportFieldArray=[
    colmonth,
    colreceivedDate,
    colbilledAmount,
    colnoofbilled,
    colnoofpaid,
    coltotalpaid,
    colexpectedRevenue,
    colnoofexceptions,
    coltotal_amount_exception,
    colnoofpendingunbilled,
    coltotal_amount_unbilled,
    colnoofclientbill,
    coltotalAmountClientBill,
    colnoofselfpay,
    coltotalAmountselfpay,
    colinsurancePayerName
]

