import React from 'react';  
import { PAGING_END_INDEX } from '../../utilities/staticConfigs';

const Pagination = (props) =>{
    const activePage = props.activePage;
    const totalPage = props.totalPage;
    const startIndex = props.startIndex;
    const endIndex = props.endIndex;

    const pageNumbers = [];
    for (let i = 1; i <= totalPage; i++) {
      pageNumbers.push(i);
    }  

    let previous = '<';
    let next = '>'
    return(
        <div className="rsdt rsdt-paginate">
            <button style={{ textAlign: 'center' }} hidden={pageNumbers.length === 0 || totalPage <= 1} disabled={(activePage === 1) || (endIndex - PAGING_END_INDEX <= 1)} className="rsdt rsdt-paginate button" type="button" onClick={e => props.onPagePrevious(e)} data-testid="prev-page-btn">{previous}</button>
            {pageNumbers.slice(startIndex, endIndex).map(number => {  
                return (
                    <button hidden={pageNumbers.length === 0 || totalPage <= 1} key={number} id={number} className={activePage === number ? 'rsdt rsdt-paginate button active' : 'rsdt rsdt-paginate button'} type="button" onClick={e => props.onPageUp(e)} data-testid={`page-${number}-btn`} >{number}</button>
                )
            })                  
            }        
            <button hidden={pageNumbers.length === 0 || totalPage <= 1} disabled={(activePage === totalPage) || (startIndex + PAGING_END_INDEX >= totalPage)} className="rsdt rsdt-paginate button" type="button" onClick={e => props.onPageNext(e)} data-testid="next-page-btn">{next}</button>
        </div>
    )
}

export default Pagination; 