import api from '../../../service/api'
const getProviderType = async (pageSize, page) => {
	let path = 'super-admin/provider-group/?page_size=' + pageSize + '&page=' + page;
	return api.get(path);
}
const UpdateProvider = async (data, editProviderId) => {
	let path = '/user/provider/' + editProviderId
	return api.put(path, data);
}

const AddProvider = async (data) => {
	let path = '/user/provider/'
	return api.post(path, data);
}

const getProvidersData = async (pageSize, page, practiceId, list, status) => {
	let path = 'user/provider/?practice_pk=' + practiceId + '&page_size=' + pageSize + '&page=' + page
	if (list)
		path += '&list=' + list;
	if (status)
		path += '&status=' + status;
	return api.get(path);
}

const getEditProvidersData = async (providerId) => {
	let path = 'user/provider/' + providerId
	return api.get(path);
}

const deleteProvider = async (providerId) => {
	let path = 'user/provider/' + providerId
	return api.destroy(path);
}

const callNPISearchAPI = async (searchObj) => {
	let path = 'super-admin/api-call/';
	let urlString = 'https://npiregistry.cms.hhs.gov/api/?version=2.1&limit=200';
	if (searchObj.orgName && searchObj.orgName.trim().length > 0) urlString += '&organization_name=' + searchObj.orgName
	if (searchObj.city && searchObj.city.trim().length > 0) urlString += '&city=' + searchObj.city
	if (searchObj.state && searchObj.state.trim().length > 0) urlString += '&state=' + searchObj.state
	let urlData = { "url": urlString };
	return api.post(path, urlData);
}

const PracticeDefaultLocation = async (practicePK) => {
	let path = 'practice/practice-default-location/' + practicePK;
	return api.get(path);
}

const PracticeLocation = async (practicePK) => {
	let path = 'practice/practice-original-location/' + practicePK;
	return api.get(path);
}

const Taxonomy = async (pageSize, page) => {
	let path = 'super-admin/taxonomy/?page_size=' + pageSize + '&page=' + page;
	return api.get(path);
}

const UpdateDefaultProvider = async (editProviderId, data) => {
	let path = '/user/default-provider/' + editProviderId
	return api.put(path, data);
}

const exportProvider = async (type, practicePK, pageSize, page) => {
	const path = 'user/provider/?practice_pk=' + practicePK + '&page_size=' + pageSize + '&page=' + page + '&export=true&export_type=' + type;
	let result = null;
	if (type === 'xls')
		result = api.getXLS(path);
	else
		result = api.get(path);
	return result;
}


export default {
	exportProvider,
	getProviderType, UpdateProvider, AddProvider, getProvidersData, getEditProvidersData, callNPISearchAPI,
	deleteProvider, PracticeDefaultLocation, PracticeLocation, Taxonomy, UpdateDefaultProvider
}