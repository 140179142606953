import React, { useEffect, useState, useContext } from 'react'
import { useHistory, useLocation } from 'react-router-dom'; 
import { getStorage } from '../../utilities/browserStorage';
import service from './service';
import LoadingContext from '../../container/loadingContext';
import i18n from '../../utilities/i18n'
import Notify from "../commons/notify";
import TextInput from '../commons/input/input'
import RichTextEditor from '../commons/RichTextEditor/RichTextEditor';
import MultiSearch from '../../MaterialMultiselect/MultiSearch';
import * as staticConfigs from '../../utilities/staticConfigs'
import { Form } from 'react-bootstrap';
import { ADD_ERROR, ADD_SUCCESS, SEND_MESSAGE_FAILED, SEND_MESSAGE_SUCCESS } from '../../utilities/labelConfigs';
import { checkPermission } from '../../utilities/commonUtilities';
import { permission_key_values_messages } from '../../utilities/permissions';

function DraftMessage() {
    const practicePK = getStorage('practice');
    const setShowLoadingBar = useContext(LoadingContext);
    const history = useHistory();
    const location = useLocation();
    const [warningRichText, setWarningRichText] = useState(false);
    // draft message detail
    const [selectedValues, setSelectedValues] = useState([]);
    // user List
    const [userList, setUserList] = useState([]);

    const [subject, setSubject] = useState("");
    const [richText, setRichText] = useState('');

    const [draftMessageId, setDraftMessageId] = useState("")
    
    // multisearch
    const [optionItems, setOptionItems] = useState([]);
    const [optionValues, setOptionValues] = useState([]);

    // notify
    const [showNotify, setShowNotify] = useState("hide");
    const [notifyDescription, setNotifyDescription] = useState("");
    const [notifyType, setNotifyType] = useState("success");

    function onHandleChange(e) {
        let value = e.target.value;
        let name = e.target.name;
        if (name == 'subject') setSubject(value);
    }

    function getUserList() {
        setShowLoadingBar(true);
        let result = service.ListPracticeUsers(practicePK);
        result.then((response)=>{
            let data = response.data;
            setUserList(data);
            setShowLoadingBar(false);
        });
    }
    function getDraftMessage(id) {
        setShowLoadingBar(true);
        let result = service.GetDraftMessageDetail(id);
        let data ;
        result.then((response)=>{
            data = response.data.results;
        })
            .catch(() => {
            showNotifyWindow('show', 'error', 'Unexpected Error');
            setShowLoadingBar(false);
        })
        .then(()=>{
            setSubject(data[0].subject);
            setSelectedValues(data[0].receivers);
            setDraftMessageId(data[0].id);
            setRichText(data[0].body);
        })
        .finally(()=>{
            setShowLoadingBar(false);
        })
    }

    useEffect(() => {
        let id = location.state.id;
        getDraftMessage(id);
        getUserList();
    }, []);

    function showNotifyWindow(action, type, desc, age = 3000) {
        if (action === "show") {
            setTimeout(() => {
                setShowNotify("hide");
            }, age);
        }
        setShowNotify(action);
        setNotifyType(type);
        setNotifyDescription(desc);
    }

    function onSendMessage() {
        if (optionValues.length == 0) {
            showNotifyWindow("show", "error", "Please select To contact from list");   
            return
        }
        if (!subject) return;
        if (!richText) {
            setWarningRichText(true);
            setTimeout(() => {
                setWarningRichText(false);
            }, 3000);
            return;
        }
        let data = {
            "subject": subject,
            "body": richText,
            "receivers":optionValues,
            "is_draft": false,
            "message_id": draftMessageId,
        }
        let result = service.SendDraftMessages(data);
        result.then((response)=>{
            if (response.status === 201 || response.status === 200) {
                 showNotifyWindow("show", "success", SEND_MESSAGE_SUCCESS);
                 setTimeout(() => {
                    history.push(staticConfigs.ROUTE_DRAFT_INBOX);
                }, 2000);
            }
            else {
                showNotifyWindow("show", "error", SEND_MESSAGE_FAILED);
            }
        });
    }
    function onSaveAsDraft() {
        if (!subject) return;
        let data = {
            "body": richText,
            "subject":subject,
            "receivers":optionValues,
            "is_draft": true,
            "message_id": draftMessageId,

        }
        let result = service.SendDraftMessages(data);
        result.then((response)=>{
            if (response.status === 201 || response.status === 200) {
                showNotifyWindow("show", "success", ADD_SUCCESS);
                setTimeout(() => {
                    history.push(staticConfigs.ROUTE_INBOX);
                }, 2000);
            }
            else {
                showNotifyWindow("show", "error", ADD_ERROR);
            }
        });
    }
    function onCancelMessage() {
        history.push(staticConfigs.ROUTE_DRAFT_INBOX);
    }
    
    function onSearchContact(query) {
        if (query){
            let result = service.SearchPracticeUsers(practicePK,query);
            result.then((response)=>{
                setOptionItems(response.data);
            })
        }
    }

  return (
    <div className='col-md-12'>
        <div className='box basic-info-padding'>
        <Notify showNotify={showNotify} setShowNotify={setShowNotify} notifyDescription={notifyDescription} setNotifyType={setNotifyType} setNotifyDescription={setNotifyDescription} notifyType={notifyType} />
              <div className="row justify-space-between">
                  <div className="col">
                    {checkPermission(permission_key_values_messages.can_add_send_draft_messages) &&
                    <button type="button" className="small_box btn-primary-blue btn" onClick={onSendMessage}>
                          <i className="fas fa-paper-plane"></i>{i18n.t("inboxScreens.composeScreen.send")}
                    </button>}
                    {checkPermission(permission_key_values_messages.can_add_message_draft) &&
                    <button type="button" className="small_box btn-primary-blue btn mx-4" onClick={onSaveAsDraft}>
                          <i className="fas fa-pencil-alt"></i>{i18n.t("inboxScreens.composeScreen.saveDraft")}
                    </button>}
                    {checkPermission(permission_key_values_messages.can_add_message_template) &&
                    <button type="button" className="small_box btn-primary-blue btn">
                          <i className="fas fa-sticky-note"></i>{i18n.t("inboxScreens.composeScreen.saveAsTemplates")}
                    </button>}
                      <button type="button" className="small_box btn-secondary btn px-4 mx-4" onClick={onCancelMessage}>
                        {i18n.t("inboxScreens.composeScreen.cancel")}
                    </button>
                </div>
            </div>
            <Form id={'composeScreen'} autoComplete='off' onSubmit={onSendMessage} >
                <div className='row mt-4'>
                    <div className='col'>
                    {checkPermission(permission_key_values_messages.can_add_send_draft_messages) &&
                     <div className="form-group">
                            <MultiSearch onSearchApi={onSearchContact} 
                                optionItems={optionItems} setOptionItems={setOptionItems}
                                setOptionValues={setOptionValues} optionValues={optionValues}
                                selectedValues={selectedValues}
                                dataList={userList}
                                label={i18n.t("inboxScreens.composeScreen.to")}
                                name={"ToContacts"} 
                            />
                        </div>}
                    </div>
                </div>
                <div className='row'>
                    <div className='col'>
                        <TextInput type='text' name={'subject'} value={subject} onValueChange={onHandleChange}  label={i18n.t("inboxScreens.composeScreen.subject")} />
                    </div>
                    <div className='margin-right15 margin-top25'>
                          <button type="button" className="small_box btn-primary-blue btn">
                              <i className='fas fa-sticky-note ml-3'></i>{i18n.t("inboxScreens.composeScreen.useTemplate")}
                        </button>
                    </div>
                </div>
                  <div className='mt-4'>
                      {warningRichText && <span className='danger'><strong>{i18n.t("inboxScreens.composeScreen.MessageIsMandatory")}</strong></span>}
                    <RichTextEditor state={richText} setState = {setRichText} />
                </div>
            </Form>
        </div>
    </div>
  )
}

export default DraftMessage