import React from "react";
import BootStrapCardWithHeader from "./BootStrapCardWithHeader";
import { Droppable } from "react-beautiful-dnd";
import DragDropContainer from "./DragDropContainer";
import FieldListItem from "./FieldListItem";
const DroppableCard = ({
  type,
  itemList,
  title,
  className,
  droppableId,
  columnSize,
}) => {
  return (
    <Droppable droppableId={droppableId}>
      {(provided) => (
        <div
          className={columnSize}
          {...provided.droppableProps}
          ref={provided.innerRef}
        >
          <BootStrapCardWithHeader
            cardTitle={title}
            className={className}
            cardInnerHeight={`height-50`}
          >
            {itemList.length === 0 ? (
              <DragDropContainer
                description={`Drag & Drop fields <br /> here to add report ${type}s.`}
              />
            ) : (
              itemList.map((item, index) => (
                <FieldListItem key={index} fieldObjecct={item} />
              ))
            )}
          </BootStrapCardWithHeader>
          {provided.placeholder}
        </div>
      )}
    </Droppable>
  );
};

export default DroppableCard;
