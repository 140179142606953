import React, { useContext, useEffect, useState } from 'react';
import { useHistory } from "react-router-dom";
import { Dropdown } from 'react-bootstrap';
import moment from 'moment';

import service from './service';
import SearchMessage from './SearchMessage';
import { SentTableData, TableBodyData } from './SentMessagesTable';

import LoadingContext from '../../container/loadingContext';

import Notify from "../commons/notify";
import Table from '../commons/Table/Table';
import Pagination from '../commons/pagination';
import { getStorage } from '../../utilities/browserStorage';
import * as staticConfigs from '../../utilities/staticConfigs';
import { DELETE_MESSAGE_SUCCESS } from '../../utilities/labelConfigs';
import actionImg from '../../assets/images/action.png';
import { checkPermission } from '../../utilities/commonUtilities';
import { permission_key_values_messages } from '../../utilities/permissions';
import i18n from '../../utilities/i18n';

export const SendMessagesList = () => {
    const history = useHistory();
    const setShowLoadingBar = useContext(LoadingContext);
    const practicePK = getStorage('practice');
    const [sendMessageLists, setSendMessageLists] = useState([]);
    const [selectedMessageList, setSelectedMessageList] = useState([]);
    const [allCheckBoxChecked, setAllCheckBoxChecked] = useState(false);
    const [disableDropdown, setDisableDropdown] = useState(false);

    const [arrayOfIdOfMessageList, setArrayOfIdOfMessageList] = useState([]);


    // notify
    const [showNotify, setShowNotify] = useState("hide");
    const [notifyDescription, setNotifyDescription] = useState("");
    const [notifyType, setNotifyType] = useState("success");


    // pagination start
    const [totalPage, setTotalPage] = useState(1);
    const [activePage, setActivePage] = useState(1);
    const [startIndex, setStartIndex] = useState(0);
    const [endIndex, setEndIndex] = useState(staticConfigs.PAGING_END_INDEX);

    function onPagePrevious() {
        let previousPage = startIndex + 1 - staticConfigs.PAGING_END_INDEX;
        setActivePage(previousPage);
        if (startIndex !== 0) {
            setStartIndex(startIndex - staticConfigs.PAGING_END_INDEX);
            setEndIndex(endIndex - staticConfigs.PAGING_END_INDEX);
        }
        getSendMessages(staticConfigs.DEFAULT_PAGING_SIZE, previousPage);
    }

    function onPageUp(e) {
        let page = Number(e.target.id)
        setActivePage(page);
        getSendMessages(staticConfigs.DEFAULT_PAGING_SIZE, page);
    }

    function onPageNext() {
        let nextPage = startIndex + 1 + staticConfigs.PAGING_END_INDEX;
        if (endIndex === totalPage || totalPage <= staticConfigs.PAGING_END_INDEX) {
            setActivePage(nextPage);
            setStartIndex(startIndex);
            setStartIndex(endIndex);
        } else {
            setActivePage(nextPage);
            setStartIndex(startIndex + staticConfigs.PAGING_END_INDEX);
            setEndIndex(endIndex + staticConfigs.PAGING_END_INDEX);
        }
        getSendMessages(staticConfigs.DEFAULT_PAGING_SIZE, nextPage);
    }

    function getSendMessages(pageSize, page) {
        let query = '?practice_pk=' + practicePK + '&page_size=' + pageSize + '&page=' + page;
        setShowLoadingBar(true);
        let result = service.ListSendMessages(query);
        let newArray = [];
        let arrayId = [];
        let rowArray = [];
        result.then((response) => {
            setAllCheckBoxChecked(false);
            let data = response.data.results;
            data.map((item, index) => {
                newArray[index] = { ...item, 'checked': false };
                arrayId.push(item.id);
            });

            setArrayOfIdOfMessageList(arrayId);
            setTotalPage(Math.ceil(response.data.count / response.data.page_size));

            let anArray = [];
            let newData = Array(response.data.results.length).fill(JSON.parse(JSON.stringify(SentTableData.tableBodyData[0])));
            newData.map((row, rowIndex) => {
                row.map((col) => {
                    let colObject = {};
                    if (col.type === 'checkbox') {
                        colObject = {
                            ...col, 'value': false,
                            "id": response.data.results[rowIndex].message_id,
                            'is_read': response.data.results[rowIndex].is_read
                        }
                    }
                    else if (col.name === 'sender' || col.name === 'receivers') {
                        let senders = response.data.results[rowIndex][col.name].map(snd => snd.name)
                        colObject = {
                            ...col, 'value': senders,
                            "id": response.data.results[rowIndex].message_id,
                            'is_read': response.data.results[rowIndex].is_read
                        }
                    }
                    else if (col.name === 'created_on') {
                        colObject = {
                            ...col, 'value': moment(response.data.results[rowIndex][col.name]).format('MM/DD/YYYY  h:mm a'),
                            "id": response.data.results[rowIndex].message_id,
                            'is_read': response.data.results[rowIndex].is_read
                        }
                    }
                    else {
                        colObject = {
                            ...col, 'value': response.data.results[rowIndex][col.name],
                            "id": response.data.results[rowIndex].message_id,
                            'is_read': response.data.results[rowIndex].is_read
                        }
                    }
                    anArray.push(colObject)
                })
                rowArray.push(anArray)
                anArray = [];
            });
            if (rowArray.length > 0) {
                SentTableData.tableBodyData = rowArray;
            }
            else {
                SentTableData.tableBodyData = TableBodyData;
            }

            setSendMessageLists(newArray);
            setShowLoadingBar(false);
        })
    }


    useEffect(() => {

        let rowArray = [];

        let anArray = [];
        let newData = Array(sendMessageLists.length).fill(JSON.parse(JSON.stringify(SentTableData.tableBodyData[0])));
        newData.map((row, rowIndex) => {
            row.map((col) => {
                let colObject = {};
                if (col.type === 'checkbox') {
                    colObject = {
                        ...col, 'value':sendMessageLists[rowIndex].checked,
                        "id": sendMessageLists[rowIndex].message_id,
                        'is_read': sendMessageLists[rowIndex].is_read
                    }
                }
                else if (col.name === 'sender' || col.name === 'receivers') {
                    let senders = sendMessageLists[rowIndex][col.name].map(snd => snd.name)
                    colObject = {
                        ...col, 'value': senders,
                        "id": sendMessageLists[rowIndex].message_id,
                        'is_read': sendMessageLists[rowIndex].is_read
                    }
                }
                else if (col.name === 'created_on') {
                    colObject = {
                        ...col, 'value': moment(sendMessageLists[rowIndex][col.name]).format('MM/DD/YYYY  h:mm a'),
                        "id": sendMessageLists[rowIndex].message_id,
                        'is_read': sendMessageLists[rowIndex].is_read
                    }
                }
                else {
                    colObject = {
                        ...col, 'value': sendMessageLists[rowIndex][col.name],
                        "id": sendMessageLists[rowIndex].message_id,
                        'is_read': sendMessageLists[rowIndex].is_read
                    }
                }
                anArray.push(colObject)
            })
            rowArray.push(anArray)
            anArray = [];
        });
        if (rowArray.length > 0) {
            SentTableData.tableBodyData = rowArray;
        }
        else {
            SentTableData.tableBodyData = TableBodyData;
        }


        setSelectedMessageList(() => {
            let selected_ids = [];
            sendMessageLists.filter((item) => item.checked ).map((item) => {
                selected_ids.push(item.id);

            });
            return selected_ids;

        });
    }, [sendMessageLists]);

    function DeleteSendMessageList() {
        if (selectedMessageList.length === 0) return;

        if(checkPermission(permission_key_values_messages.can_modify_sentMails)) {
            setShowLoadingBar(true);
            let data = {
                "message_type": "sent",
                "message_id": selectedMessageList
            }
            let result = service.DeleteMessage(data);
            result.then((response) => {
                setShowLoadingBar(false);
                if (response.status === 200) {
                    let page = 1;
                    showNotifyWindow("show", "success", DELETE_MESSAGE_SUCCESS);
                    getSendMessages(staticConfigs.DEFAULT_PAGING_SIZE, page);
                }
            })
        } else {
            showNotifyWindow('show','error', i18n.t('errorMessages.permission_error'))
        } 
    }

    useEffect(() => {
        let page = 1;
        getSendMessages(staticConfigs.DEFAULT_PAGING_SIZE, page);
    }, []);

    function onSelectRowAll(e) {
        let selected = e.target.checked;
        setAllCheckBoxChecked(selected);

        SentTableData.tableBodyData.forEach(rowItem => {
            let index = rowItem.findIndex(cItem => cItem.type == 'checkbox');
            rowItem[index].value = selected;
        });

        setSendMessageLists(() => {
            let newArray = [];
            sendMessageLists.map((item, index) => {
                newArray[index] = { ...item, 'checked': selected };
            });
            return newArray
        });
    }

    function onSelectRow(e, id) {
        e.stopPropagation();
        let selected = e.target.checked;

        SentTableData.tableBodyData.forEach(rowItem => {
            if (rowItem.find(colItem => colItem.id == id)) {
                let index = rowItem.findIndex(cItem => cItem.type == 'checkbox');
                rowItem[index].value = selected;
            }
        });
        let newArray = [];
        setSendMessageLists(() => {
            sendMessageLists.map((item, index) => {
                if (item.id == id) newArray[index] = { ...item, 'checked': selected };
                else newArray[index] = { ...item }
            });
            let selectedArray = newArray.filter((item) => item.checked == true);
            if (selectedArray.length == newArray.length) {
                setAllCheckBoxChecked(true);
            } else {
                setAllCheckBoxChecked(false);
            }
            return newArray;
        });
    }


    useEffect(() => {
        setDisableDropdown(() => {
            return selectedMessageList.length === 0 ? true : false;
        })
    }, [selectedMessageList])


    function showNotifyWindow(action, type, desc, age = 3000) {
        if (action === "show") {
            setTimeout(() => {
                setShowNotify("hide");
            }, age);
        }
        setShowNotify(action);
        setNotifyType(type);
        setNotifyDescription(desc);
    }

    function to_Inbox(id, index) {
        history.push(staticConfigs.ROUTE_SEND_INBOX_MESSAGES, { 'id': id, 'list': arrayOfIdOfMessageList, 'index': index });
    }

    function Refresh() {
        window.location.reload()
    }

    function onRowClick(e, id) {
        let index = 0;
        SentTableData.tableBodyData.forEach(rowItem => {
            if (rowItem[0].id == id) {
                to_Inbox(rowItem[0].id, index);
                return true;
            }
            index++;
        });
    }

    function onClickGridCheckBox(e, clickedFrom, id) {
        e.stopPropagation();
        if (clickedFrom == 'data') {
            onSelectRow(e, id);
        }
    }

    return (
        <div className="col-md-12">
            <div className="box basic-info-padding bordering border-radius-8"  style={{ padding: "15px"}}>
                <Notify showNotify={showNotify} setShowNotify={setShowNotify} notifyDescription={notifyDescription} setNotifyType={setNotifyType} setNotifyDescription={setNotifyDescription} notifyType={notifyType} />
                <div className="row">
                    <div className="col d-flex">
                    {checkPermission(permission_key_values_messages.can_modify_sentMails) &&
                        <Dropdown className="padding-top2">
                            <Dropdown.Toggle variant="" id="dropdown-basic">
                                <input type="checkbox" name={'all'} value={'all'} className='mr-1 sizeChecbox' checked={allCheckBoxChecked} onChange={(e) => onSelectRowAll(e, 'all')} />
                            </Dropdown.Toggle>
                        </Dropdown>}
                        <Dropdown>
                            <Dropdown.Toggle variant="" id="dropdown-basic" onClick={Refresh}>
                                <i className="fa-solid fa-rotate refreshIcon"></i>
                            </Dropdown.Toggle>
                        </Dropdown>

                       
                        <Dropdown className="">
                            <Dropdown.Toggle variant="" id="dropdown-basic" align={{ lg: 'end' }}>
                                <img src={actionImg} alt='' />
                            </Dropdown.Toggle>
                            <Dropdown.Menu >
                            <Dropdown.Item href="#/action-3" disabled={disableDropdown} onClick={DeleteSendMessageList}>Delete</Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>
                    </div>
                    <div className="padding-top10 padding-right8" >
                        <SearchMessage type={'sent'} setValue={setSendMessageLists} onClear={getSendMessages} />
                    </div>
                </div>
                <div className="table-responsive">
                    <Table tableObject={SentTableData} isCheckboxDisabled={checkPermission(permission_key_values_messages.can_modify_sentMails)} onRowClick={onRowClick} onClickGridCheckBox={onClickGridCheckBox} />
                </div>
                <div>
                    <Pagination totalPage={totalPage} activePage={activePage} startIndex={startIndex} endIndex={endIndex} onPagePrevious={onPagePrevious} onPageUp={onPageUp} onPageNext={onPageNext} />
                </div>
            </div>
        </div>
    )
}
