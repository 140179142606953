import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import service from '../SearchClaims/service';
import { getStorage } from "../../../utilities/browserStorage";
import service1 from '../../customerSetup/providerManagement/service';
import service3 from '../../dictionaries/revenueCode/service';
import { PAGING_END_INDEX } from "../../../utilities/staticConfigs";

const initialState = {
    tab1LoadClaims: {
        searchQuery: {
            selectedPatient: [],
            dosFrom: "",
            dosTo: "",
            claimEnteredFrom: "",
            claimEnteredTo: "",
            claimSubmittedFrom: "",
            claimSubmittedTo: "",
            claimStatus: [],
            claimType: 1,
            provider: [],
            serviceLocation: [],
            selectedServiceLocationsIds: [],
            serviceLocationType: [],
            currentPayer: [],
            primaryPayer: [],
            secondaryPayer: [],
            payerType: [],
            selectedCpt: [],
            revenueCodes: [],
            icd: [],
        },
        arClaimsTableData: [],
        pageSize: 0,
        currentPage: 1,
        count: 0,
        totalPage: 0,
        startIndex: 0,
        endIndex: PAGING_END_INDEX,
        sortField: null, // Current SortField
        orderType: null, // Current Sort Order Asc or Dsc
        allSortFieldStates: {
            custom_claim_id: true,
            patient__full_name: true,
            service_from_date: true
        },
    },
    dropDownListData: {
        isLoading: false,
        isErrorGettingDropdownData: false,
        isDropDownDataReceived: false,
        ClaimStatusList: [],
        ProviderList: [],
        ServiceLocationList: [],
        PayersList: [],
        CptCodeList: [],
        RevenueCodeList: [],
    },
    loading: false,
    tabSwitchFlag: false,
    resetFlag: false,
    patientAdvanceData:[],
    patient_Pk: ''
};

/**
 * API Call functionality to fetch ar-claim table data against a given query
 */
export const getARClaimsData = createAsyncThunk(
    "arClaims/getARClaimsData",
    async (payload, thunkAPI) => {
        try {
            const res = await service.GetListARPatientClaims(payload);
            if (res) {
                return { data: res.data };
            }
        } catch (error) {
            return thunkAPI.rejectWithValue({ errorMessage: 'Error getting AR Claim table data!' })
        }
    }
);

/*
 * Function getDropDownListData fetches all the drop-down-data required for the page in one shot using promise chaining 
 */
export const getDropDownListData = createAsyncThunk(
    "arClaims/getDropDownListData",
    async (payload, thunkAPI) => {
        const pageSize = 0;
        const page = 0;
        const practicePK = getStorage('practice');
        const type = 'dropdown';
        /**
         * The promises array contains the promises returned by each API call, 
         * which can be resolved using Promise.all(promises) to wait for all the requests to complete.
         */
        const promises = [
            service.ListClaimStatus(pageSize, page, practicePK, type).then(response => response.data),
            service1.getProvidersData(pageSize, page, practicePK, true).then(response => response.data),
            service.ServiceLocationLists(practicePK, null, null, true).then(response => {
                let locations = []
                response.data.map((item) => {
                    item.id = item.Id + '||' + item.location_type
                    locations.push(item)
                })
                return locations
            }),
            service3.GetListRevenueCode(pageSize, page).then(response => response.data),
            service.ListInsuranceNames(pageSize, 0, practicePK).then(response => response.data),
            service.ListCPTCodes(practicePK).then(response => response.data)
        ];
        try {
            const [
                claimStatusList,
                providerList,
                serviceLocationList,
                revenueCodeList,
                payerList,
                cptCodesList
            ] = await Promise.all(promises);

            return {
                claimStatusList,
                providerList,
                serviceLocationList,
                revenueCodeList,
                payerList,
                cptCodesList
            };
        } catch (error) {
            return thunkAPI.rejectWithValue({ errorMessage: 'Error getting Drop-down list data!' })
        }
    }
);


const arClaimsSlice = createSlice({
    name: "arClaims",
    initialState,
    reducers: {
        /**
         * Set state function to update user search query inputs
         */
        setSearchQueryInputs: (state, actions) => {
            let service_locations = [];
            let service_location_types = [];
            if (actions.payload.name === "serviceLocation") {
                if (actions.payload.value.length > 0) {
                    actions.payload.value.forEach((item) => {
                        service_locations.push(item.split("||")[0]);
                        service_location_types.push(item.split("||")[1]);
                    });
                }
            }

            let data = {
                ...state.tab1LoadClaims.searchQuery,
                [actions.payload.name]: actions.payload.value,
                ...(actions.payload.name === "serviceLocation"
                    ? {
                        ["serviceLocationType"]: service_location_types,
                        ["selectedServiceLocationsIds"]: service_locations,
                    }
                    : null),
            };
            state.tab1LoadClaims.searchQuery = data
        },
        /**
         * Handle Pagination states of Ar-claim tab 1
         */
        updatePagination: (state, actions) => {
            return {
                ...state,
                tab1LoadClaims: {
                    ...state.tab1LoadClaims,
                    currentPage: actions.payload.currentPage,
                    endIndex: actions.payload.endIndex,
                    startIndex: actions.payload.startIndex
                }
            }
        },
        /**
         * Handle Sorting states of Ar-claim tab 1
         */
        updateSortField: (state, actions) => {
            // toggle all the sortfields to true except for the currently sorting field
            const newSortState = Object.keys(state.tab1LoadClaims.allSortFieldStates).reduce((acc, key) => {
                acc[key] = key === actions.payload.sortField ? false : true;
                return acc;
            }, {});
            return {
                ...state,
                tab1LoadClaims: {
                    ...state.tab1LoadClaims,
                    sortField: actions.payload.sortField,
                    orderType: actions.payload.sortOrder,
                    allSortFieldStates: newSortState
                }

            };
        },

        resetSortFields: (state) => {
            state.tab1LoadClaims.allSortFieldStates = initialState.tab1LoadClaims.allSortFieldStates;
            state.tab1LoadClaims.sortField = initialState.tab1LoadClaims.sortField;
            state.tab1LoadClaims.orderType = initialState.tab1LoadClaims.orderType;
            state.tab1LoadClaims.currentPage = initialState.tab1LoadClaims.currentPage;
            state.tab1LoadClaims.endIndex = initialState.tab1LoadClaims.endIndex;
            state.tab1LoadClaims.startIndex = initialState.tab1LoadClaims.startIndex;
            state.tab1LoadClaims.pageSize = initialState.tab1LoadClaims.pageSize;
        },
        /**
         * Reset ar-claims tab1 state on Reset btn click
         */
        onTab1Reset: (state) => {
            state.tab1LoadClaims = initialState.tab1LoadClaims
            state.resetFlag = true;
        },
        patientAdvanceSearchData: (state, actions) => {
            state.tab1LoadClaims.searchQuery.selectedPatient = actions.payload.patientSelected;
            state.patientAdvanceData = actions.payload.patientAdvSearchData;
            state.patient_Pk = actions.payload.patientPk
        },
        /**
         * Reset entire state when user returns from the page
         */
        ResetData: () => initialState,
    },

    extraReducers: (builder) => {
        /**
         * Extra-reducers for api data fetch functions - (getARClaimsData)
         */
        builder.addCase(getARClaimsData.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(getARClaimsData.fulfilled, (state, action) => {
            state.tab1LoadClaims.arClaimsTableData = action.payload.data.results;
            state.tab1LoadClaims.count = action.payload.data.count;
            state.tab1LoadClaims.pageSize = action.payload.data.page_size;
            state.tab1LoadClaims.totalPage = Math.ceil(action.payload.data.count / action.payload.data.page_size)
            state.loading = false;
            state.resetFlag = false;
        });
        builder.addCase(getARClaimsData.rejected, (state) => {
            state.tab1LoadClaims.arClaimsTableData = [];
            state.loading = false;
        });
        /**
         * Extra-reducers for api data fetch functions - (getDropDownListData)
         */
        builder
            .addCase(getDropDownListData.pending, (state) => {
                state.dropDownListData.isLoading = true;
                state.dropDownListData.isErrorGettingDropdownData = false;
            })
            .addCase(getDropDownListData.fulfilled, (state, action) => {
                const { claimStatusList, providerList, serviceLocationList, revenueCodeList, payerList, cptCodesList } = action.payload;
                state.dropDownListData.ClaimStatusList = claimStatusList;
                state.dropDownListData.ProviderList = providerList;
                state.dropDownListData.ServiceLocationList = serviceLocationList;
                state.dropDownListData.RevenueCodeList = revenueCodeList;
                state.dropDownListData.PayersList = payerList;
                state.dropDownListData.CptCodeList = cptCodesList;

                state.dropDownListData.isLoading = false;
                state.dropDownListData.isErrorGettingDropdownData = false;
                state.isDropDownDataReceived = true
            })
            .addCase(getDropDownListData.rejected, (state) => {
                state.dropDownListData.isLoading = false;
                state.dropDownListData.isErrorGettingDropdownData = true;
            });
    },

});


export const { setSearchQueryInputs, updateSortField,
    updatePagination, ResetData, onTab1Reset,
    resetSortFields, updateFlag, patientAdvanceSearchData } = arClaimsSlice.actions

export default arClaimsSlice.reducer;









