import React, { Suspense, useState } from "react";
import { withRouter, Switch, useLocation } from "react-router-dom";
import styled from '@mui/system/styled';

import * as staticConfigs from "../utilities/staticConfigs";
import PrivateRoute from "./privateRoute";

import DashboardComponent from "../components/dashboard/dashboard";

import NotfoundComponent from "../components/commons/notfoundComponent";
import Header from "../components/mainLayoutComponent/headerComponent/Header";
import Settings from "../components/mainLayoutComponent/settingsComponent/Settings";
import LeftSideBar from "../components/mainLayoutComponent/leftSideBarComponent/LeftSideBar";
import Loading from "../components/commons/loading";
import "../components/mainLayoutComponent/style.css";
import { LoadingProvider } from "./loadingContext";
import SetPassword from "../components/commons/passwordManagement/SetPassword";
import LabTabs from "../components/MyTasksScreen/LabTabs";
import RolesAndPermissions from "../components/userManagement/rolesAndPermissions/rolesAndPermissions";
import RunEligibility from "../components/patientManagement/RunEligibility/RunEligibility";
import ChangePasswordComponent from "../components/commons/passwordManagement/changePassword";
import PolicyTypesList from "../components/dictionaries/policyTypes/PolicyTypesList";
import PatientTypesList from "../components/dictionaries/patientTypes/PatientTypesList";
import PatientLanguagesList from "../components/dictionaries/patientLanguages/PatientLanguage";
import RelationshipList from "../components/dictionaries/relationShips/RelationshipList";
import DictionriesList from "../components/dictionaries/dictionariesListAll/dictionariesListAll";
import PracticeDictionariesListAll from "../components/dictionaries/dictionariesListAll/practiceDictionariesListAll";
import PayersList from "../components/dictionaries/payerType/PayersList";
import ReferralList from "../components/dictionaries/referralSources/ReferralList";
import TypeOfNotes from "../components/dictionaries/typeOfNotes/dictTypeOfNotes";
import FacilityList from "../components/dictionaries/facilityTypes/FacilityList";
import PracticesList from "../components/dictionaries/practiceTypes/PracticesList";
import BenefitList from "../components/dictionaries/assignmentBenefits/BenefitList";
import ClaimStatusList from "../components/dictionaries/claimStatus/ClaimStatusList";
import AccidentList from "../components/dictionaries/accidentTypes/AccidentList";
import ClaimSubStatusList from "../components/dictionaries/claimSubStatus/ClaimSubStatusList";
import DocumentationMethodList from "../components/dictionaries/documentationMethods/DocumentationMethodList";
import DocumentList from "../components/dictionaries/documentTypes/DocumentList";
import ExceptionList from "../components/dictionaries/serviceAuthorizationException/ExceptionList";
import TransportList from "../components/dictionaries/transportReasons/TransportList";
import TrackingStatusList from "../components/dictionaries/trackingStatusSources/TrackingStatusList";
import AppointmentList from "../components/dictionaries/appointmentStatus/AppointmentList";
import AppointmentTypesList from "../components/dictionaries/appointmentType/AppointmentTypesList";
import FinancialClassList from "../components/dictionaries/financialClass/FinancialClassList";
import PayerClassList from "../components/dictionaries/payerClass/PayerClassList";
import RevenueCode from "../components/dictionaries/revenueCode/revenueCode";
import RemittanceRemarkCode from "../components/dictionaries/remittanceRemarkCode/remittanceRemarkCode";
import AdjustmentReasonCode from "../components/dictionaries/adjustmentReasonCode/adjustmentReasonCode";
import ClaimFrequency from "../components/dictionaries/claimFrequency/claimFrequency";
import ServiceTypesList from "../components/dictionaries/serviceTypes/ServiceTypesList";
import DelayReasonCode from "../components/dictionaries/delayReasonCode/delayReasonCode";
import PaymentAdjustmentType from "../components/dictionaries/paymentAdjustmentType/paymentAdjustmentType";
import AssociateUsersList from "../components/userManagement/associateUsers/AssociateUsersList";
import CustomerProfileList from "../components/userManagement/customerProfile/CustomerProfileList";
import Practices from "../components/userManagement/practices/practices";
import FacilitiesList from "../components/userManagement/facilities/FacilitiesList";
import PracticesAdd from "../components/userManagement/practices/practices-add";
import ProviderManagement from "../components/customerSetup/providerManagement/providerManagement";
import ProviderAdd from "../components/customerSetup/providerManagement/providerAdd";
import ReferringProviderGroup from "../components/dictionaries/referringProviderGroup/referringProviderGroup";
import PageLocks from "../components/userManagement/pageLocks/pageLocks";
// import UserActionLogs from "../components/userActionLogs/userActionLogs";
import ReferringProviderList from "../components/userManagement/referringProvider/ReferringProviderList";
import "../components/mainLayoutComponent/style.css";
import POSCodesList from "../components/dictionaries/posCodes/POSCodesList";
import ModifiersList from "../components/dictionaries/modifiers/ModifiersList";
import PatientAdd from "../components/patientManagement/patients/PatientsAdd";
import PatientsLedgerList from "../components/patientManagement/patientLedgerList/patientLedgerList";
import PatientsLedger from "../components/patientManagement/patientLedger/patientLedger";
import StatementManagement from "../components/patientManagement/StatementManagement/StatementManagement";
import PaymentPlans from "../components/patientManagement/PaymentPlan/PaymentPlans";
import ActivePayment from "../components/patientManagement/PaymentPlan/ActivePayment";
import PatientLetters from "../components/patientManagement/PatientLetters/PatientLetters";
import ClaimsFilter from "../components/ClaimsModule/ClaimsFilter";
import ARClaims from "../components/ClaimsModule/ARManagement/ARClaims";
import PaperClaims from "../components/ClaimsModule/PaperClaims";
import InsuranceCompanies from "../components/dictionaries/InsuranceCompanies/InsuranceCompanies";
import Taxonomies from "../components/dictionaries/taxonomies/taxonomies";
import CPTCodes from "../components/dictionaries/CPTCodes/cptcodes";
import ICD10 from "../components/dictionaries/ICD10/icd10";
import { PostPayments } from "../components/Payments/postPayments";
import { ViewPayments } from "../components/Payments/ViewPayments";
import { ViewReceipts } from "../components/Payments/viewReceipts";
import FeeSchedule from "../components/customerSetup/feeScheduleManagement/feeScheduleManagement";
import PracticeCodes from "../components/customerSetup/codeManagement/practiceCodeManagement";
import { FacilityAdd } from "../components/userManagement/facilities/FacilityAdd";
import { ReferringProviderAdd } from "../components/userManagement/referringProvider/ReferringProviderAdd";
import { CSVTemplates } from "../components/dictionaries/csvTemplates/CSVTemplates";
import { ERAPosting } from "../components/Payments/ERA_Posting/ERAPosting";
import { InsuranceCompanyAdd } from "../components/dictionaries/InsuranceCompanies/InsuranceCompanyAdd";
import { Inbox } from "../components/InboxScreens/inbox";
import InboxMessageScreen from "../components/InboxScreens/InboxMessageScreen";
import ComposeScreen from "../components/InboxScreens/ComposeScreen";
import Tasks from "../components/MyTasksScreen/Tasks";
import TaskList from "../components/MyTasksScreen/TaskList";
import { SendMessagesList } from "../components/InboxScreens/SendMessagesList";
import DraftList from "../components/InboxScreens/DraftList";
import SendMessageScreen from "../components/InboxScreens/SendMessageScreen";
import DraftMessage from "../components/InboxScreens/DraftMessage";
import InterFaceList from "../components/Interface/InterFaceList";
import SearchInterface from "../components/Interface/SearchInterface";
import ProviderNameMatch from "../components/Interface/ProviderNameMatch";
import PayerNameMatch from "../components/Interface/PayerNameMatch";
import FacilityNameMatch from "../components/Interface/FacilityNameMatch";
import TestCodeSetup from "../components/Interface/TestCodeSetup";
import Patientslist from "../components/patientManagement/patients/PatientLists/patientsList";
import ReportBuilder from "../components/reports/ReportBuilder/ReportBuilder";
import ReportViewer from "../components/reports/ReportViewer/ReportViewer";
import Template from "../components/InboxScreens/Template";
import AddTemplate from "../components/InboxScreens/AddTemplate";
import NewFromScratchReport from "../components/reports/ReportBuilder/NewFromScratchReport";
import NewFromTemplate from "../components/reports/ReportBuilder/NewFromTemplate";
import EditReport from "../components/reports/ReportBuilder/EditReport";
import ChargeDebitReport from "../components/reports/ChargesDebit/ChargesDebit";
import PaymentAdjustmentReport from "../components/reports/PaymentAdjustment/PaymentAdjustmentReport";
import OpenARReport from "../components/reports/OpenARReport/openARReport";
import TransactionReport from "../components/reports/TransactionReport/TransactionReport";
import TrackingStatusTypesList from "../components/dictionaries/trackingStatusTypes/TrackingStatusTypesList";
import Report360 from "../components/reports/360Report/360Report";
import { checkPermission } from "../utilities/commonUtilities";
import { permission_key_values_claim, permission_key_values_messages, permission_key_values_patient, permission_key_values_payments } from "../utilities/permissions";
import NoPermission from "../components/NoPermissionPage/NoPermission";
import UserActionLogs from "../components/userActionLogs/UserActionLogs";
import MyProfile from "../components/mainLayoutComponent/headerComponent/MyProfile";
import AssociateUsersEdit from "../components/userManagement/associateUsers/AssociateUsersEdit";
import AssociateUserAdd from "../components/userManagement/associateUsers/associateUserAdd"
import StandardFeeSchedule from "../components/customerSetup/feeScheduleManagement/standardFeeSchedule";
import PaymentSource from "../components/dictionaries/paymentSource/PaymentSource";

import SearchClaimModule from "../components/ClaimModuleNew/ClaimModuleNew" 

const AppContainer = styled("div")(({ theme }) => ({
  display: "grid",
  gridTemplateColumns: "240px 1fr",
  gridTemplateRows: "80px 1fr",
  gridTemplateAreas: `
    'sidebar header'
    'sidebar content'
  `,
  gap: "0 16px",
  [theme.breakpoints.down("sm")]: {
    gridTemplateColumns: "1fr",
    gridTemplateAreas: `
      'header'
      'content'
    `,
    "& > :nth-child(2)": {
      display: "none", 
    },
  },
}));

const HeaderContainer = styled("div")({
  display: 'flex',
  flexDirection: 'column',
  gridArea: "header",
  justifyContent: 'center',
  alignItems: 'left'
});

const SidebarContainer = styled("div")({
  gridArea: "sidebar",
  height: '100vh',
  position: 'fixed',
  overflowX: 'hidden !important'
});

const MainContent = styled("div")({
  gridArea: "content",
  flexGrow: 1,
  padding: theme => theme.spacing(5),
  overflowY: "auto",
});

const ContentComponent = (props) => {
  const [showLoadingBar, setShowLoadingBar] = useState(false);

  let location = useLocation();
  const [leftMenuSet, setLeftMenuSet] = useState(false);

  function onChangeModule(changeStatus) {
    setLeftMenuSet(changeStatus);
  }

  if (
    location.pathname === "/" ||
    location.pathname === staticConfigs.LOGIN_ROUTE_SUPER ||
    location.pathname === staticConfigs.LOGIN_ROUTE_SUPER + "/" ||
    location.pathname === staticConfigs.ROUTE_VERIFY_EMAIL ||
    location.pathname === staticConfigs.ROUTE_RESET_PASSWORD ||
    location.pathname === staticConfigs.ROUTE_FORGOT_PASSWORD ||
    location.pathname === staticConfigs.ROUTE_FORCE_RESET_PASSWORD ||
    location.pathname === staticConfigs.ROUTE_VIEW_RECEIPTS ||
    location.pathname === staticConfigs.ROUTE_SET_PASSWORD
  )
    return null;
  return (
    <AppContainer>
      <LoadingProvider value={setShowLoadingBar}>
        <Loading showLoadingBar={showLoadingBar} />
        <div className="pm-push-pixel open" id="push-bar"></div>
        <HeaderContainer>
          <Header
            state={props.location.state ? props.location.state : {}}
            leftMenuSet={leftMenuSet}
            onChangeModule={onChangeModule}
          />
        </HeaderContainer>
        <SidebarContainer>
          <Settings />
          <LeftSideBar leftMenuSet={leftMenuSet} />
        </SidebarContainer>
        <MainContent>
          <Suspense fallback={<div>Loading...</div>}>
            <Switch>
              <PrivateRoute
                exact
                path={staticConfigs.ROUTE_DASHBOARD}
                component={DashboardComponent}
              />
              <PrivateRoute
                exact
                path={staticConfigs.ROUTE_SET_PASSWORD}
                component={SetPassword}
              />
              <PrivateRoute
                exact
                path={staticConfigs.ROUTE_CHANGE_PASSWORD}
                component={ChangePasswordComponent}
              />
              <PrivateRoute
                exact
                path={staticConfigs.ROUTE_MYPROFILE}
                component={MyProfile}
              />
              <PrivateRoute
                exact
                path={staticConfigs.ROUTE_POLICY_TYPE}
                component={PolicyTypesList}
              />
              <PrivateRoute
                exact
                path={staticConfigs.ROUTE_PATIENT_TYPE}
                component={PatientTypesList}
              />
              <PrivateRoute
                exact
                path={staticConfigs.ROUTE_LANGUAGE_TYPE}
                component={PatientLanguagesList}
              />
               <PrivateRoute
                exact
                path={staticConfigs.ROUTE_PRACTICE_LANGUAGE_TYPE}
                component={PatientLanguagesList}
              />
              <PrivateRoute
                exact
                path={staticConfigs.ROUTE_RELATIONSHIP}
                component={RelationshipList}
              />
              <PrivateRoute
                exact
                path={staticConfigs.ROUTE_SUPER_DICTIONARIES}
                component={DictionriesList}
              />
              <PrivateRoute
                exact
                path={staticConfigs.ROUTE_PRACTICE_DICTIONARIES}
                component={PracticeDictionariesListAll}
              />
              <PrivateRoute
                exact
                path={staticConfigs.ROUTE_PAYER_TYPES}
                component={PayersList}
              />
              <PrivateRoute
                exact
                path={staticConfigs.ROUTE_REFERRAL_SOURCES}
                component={ReferralList}
              />
              <PrivateRoute
                exact
                path={staticConfigs.ROUTE_PRACTICE_REFERRAL_SOURCES}
                component={ReferralList}
              />
              <PrivateRoute
                exact
                path={staticConfigs.ROUTE_TYPE_OF_NOTES}
                component={TypeOfNotes}
              />
              <PrivateRoute
                exact
                path={staticConfigs.ROUTE_PRACTICE_TYPES}
                component={PracticesList}
              />
              <PrivateRoute
                exact
                path={staticConfigs.ROUTE_FACILITY_TYPES}
                component={FacilityList}
              />
              <PrivateRoute
                exact
                path={staticConfigs.ROUTE_ASSIGNMENT_BENEFITS}
                component={BenefitList}
              />
              <PrivateRoute
                exact
                path={staticConfigs.ROUTE_ACCIDENT_TYPES}
                component={AccidentList}
              />
              <PrivateRoute
                exact
                path={staticConfigs.ROUTE_CLAIM_STATUSES}
                component={ClaimStatusList}
              />
              <PrivateRoute
                exact
                path={staticConfigs.ROUTE_PRACTICE_CLAIM_STATUSES}
                component={ClaimStatusList}
              />
              <PrivateRoute
                exact
                path={staticConfigs.ROUTE_CLAIM_SUB_STATUSES}
                component={ClaimSubStatusList}
              />
              <PrivateRoute
                exact
                path={staticConfigs.ROUTE_PRACTICE_CLAIM_SUB_STATUSES}
                component={ClaimSubStatusList}
              />
              <PrivateRoute
                exact
                path={staticConfigs.ROUTE_DOCUMENTATION_METHODS}
                component={DocumentationMethodList}
              />
              <PrivateRoute
                exact
                path={staticConfigs.ROUTE_DOCUMENT_TYPES}
                component={DocumentList}
              />
              <PrivateRoute
                exact
                path={staticConfigs.ROUTE_PRACTICE_DOCUMENT_TYPES}
                component={DocumentList}
              />
              <PrivateRoute
                exact
                path={staticConfigs.ROUTE_SERVICE_EXCEPTIONS}
                component={ExceptionList}
              />
               <PrivateRoute
                exact
                path={staticConfigs.ROUTE_PRACTICE_SERVICE_EXCEPTIONS}
                component={ExceptionList}
              />
              <PrivateRoute
                exact
                path={staticConfigs.ROUTE_POS_CODES}
                component={POSCodesList}
              />
              <PrivateRoute
                exact
                path={staticConfigs.ROUTE_MODIFIERS}
                component={ModifiersList}
              />
              <PrivateRoute
                exact
                path={staticConfigs.ROUTE_TRANSPORT_REASONS}
                component={TransportList}
              />
              <PrivateRoute
                exact
                path={staticConfigs.ROUTE_TRACKING_STATUS_TYPES}
                component={TrackingStatusTypesList}
              />
              <PrivateRoute
                exact
                path={staticConfigs.ROUTE_TRACKING_STATUS_SOURCES}
                component={TrackingStatusList}
              />
              <PrivateRoute
                exact
                path={staticConfigs.ROUTE_APPOINTMENT_STATUSES}
                component={AppointmentList}
              />
              <PrivateRoute
                exact
                path={staticConfigs.ROUTE_PRACTICE_APPOINTMENT_STATUSES}
                component={AppointmentList}
              />
              <PrivateRoute
                exact
                path={staticConfigs.ROUTE_APPOINTMENT_TYPES}
                component={AppointmentTypesList}
              />
              <PrivateRoute
                exact
                path={staticConfigs.ROUTE_PRACTICE_APPOINTMENT_TYPES}
                component={AppointmentTypesList}
              />
              <PrivateRoute
                exact
                path={staticConfigs.ROUTE_FINANCIAL_CLASSESS}
                component={FinancialClassList}
              />
              <PrivateRoute
                exact
                path={staticConfigs.ROUTE_PRACTICE_FINANCIAL_CLASSESS}
                component={FinancialClassList}
              />
              <PrivateRoute
                exact
                path={staticConfigs.ROUTE_PAYER_CLASSESS}
                component={PayerClassList}
              />
              <PrivateRoute
                exact
                path={staticConfigs.ROUTE_PRACTICE_PAYER_CLASSESS}
                component={PayerClassList}
              />
              <PrivateRoute
                exact
                path={staticConfigs.ROUTE_REVENUE_CODE}
                component={RevenueCode}
              />
              <PrivateRoute
                exact
                path={staticConfigs.ROUTE_REMITTANCE_REMARK_CODE}
                component={RemittanceRemarkCode}
              />
              <PrivateRoute
                exact
                path={staticConfigs.ROUTE_PRACTICE_REMITTANCE_REMARK_CODE}
                component={RemittanceRemarkCode}
              />
              <PrivateRoute
                exact
                path={staticConfigs.ROUTE_ADJUSTMENT_REASON_CODE}
                component={AdjustmentReasonCode}
              />
              <PrivateRoute
                exact
                path={staticConfigs.ROUTE_PRACTICE_ADJUSTMENT_REASON_CODE}
                component={AdjustmentReasonCode}
              />
              <PrivateRoute
                exact
                path={staticConfigs.ROUTE_CLAIM_FREQUENCY}
                component={ClaimFrequency}
              />
              <PrivateRoute
                exact
                path={staticConfigs.ROUTE_SERVICE_TYPES}
                component={ServiceTypesList}
              />
              <PrivateRoute
                exact
                path={staticConfigs.ROUTE_INSURANCE_COMPANIES}
                component={InsuranceCompanies}
              />
              <PrivateRoute
                exact
                path={staticConfigs.ROUTE_INSURANCE_COMPANIES_ADD}
                component={InsuranceCompanyAdd}
              />
              <PrivateRoute
                exact
                path={staticConfigs.ROUTE_INSURANCE_COMPANIES_EDIT}
                component={InsuranceCompanyAdd}
              />
              <PrivateRoute
                exact
                path={staticConfigs.ROUTE_USER_INSURANCE_COMPANIES}
                component={InsuranceCompanies}
              />
              <PrivateRoute
                exact
                path={staticConfigs.ROUTE_USER_INSURANCE_COMPANIES_ADD}
                component={InsuranceCompanyAdd}
              />
              <PrivateRoute
                exact
                path={staticConfigs.ROUTE_USER_INSURANCE_COMPANIES_EDIT}
                component={InsuranceCompanyAdd}
              />
              <PrivateRoute
                exact
                path={staticConfigs.ROUTE_TAXONOMIES}
                component={Taxonomies}
              />
              <PrivateRoute
                exact
                path={staticConfigs.ROUTE_CPT_CODES}
                component={CPTCodes}
              />
              <PrivateRoute
                exact
                path={staticConfigs.ROUTE_PRACTICE_CPT_CODES}
                component={CPTCodes}
              />
              <PrivateRoute
                exact
                path={staticConfigs.ROUTE_ICD10}
                component={ICD10}
              />
              
              <PrivateRoute
                exact
                path={staticConfigs.ROUTE_PRACTICE_ICD10}
                component={ICD10}
              />
              <PrivateRoute
                exact
                path={staticConfigs.ROUTE_DELAY_REASON_CODE}
                component={DelayReasonCode}
              />
              <PrivateRoute
                exact
                path={staticConfigs.ROUTE_PAYMENT_ADJUSTMENT_TYPE}
                component={PaymentAdjustmentType}
              />
              <PrivateRoute
                exact
                path={staticConfigs.ROUTE_PAYMENT_SOURCE}
                component={PaymentSource}
              />
              <PrivateRoute
                exact
                path={staticConfigs.ROUTE_ASSOCIATE_USERS}
                component={AssociateUsersList}
              />
              <PrivateRoute
                exact
                path={staticConfigs.ROUTE_PRACTICE_ASSOCIATE_USERS}
                component={AssociateUsersList}
              />
              <PrivateRoute
                exact
                path={staticConfigs.ROUTE_PRACTICE_ASSOCIATE_USERS_ADD}
                component={AssociateUserAdd}
              />
              <PrivateRoute
                exact
                path={staticConfigs.ROUTE_ASSOCIATE_USERS_ADD}
                component={AssociateUserAdd}
              />
              <PrivateRoute
                exact
                path={staticConfigs.ROUTE_ASSOCIATE_USERS_EDIT}
                component={AssociateUsersEdit}
              />
              <PrivateRoute
                exact
                path={staticConfigs.ROUTE_PAGE_LOCKS}
                component={PageLocks}
              />
              <PrivateRoute
                exact
                path={staticConfigs.ROUTE_USER_ACTION_LOGS}
                component={UserActionLogs}
              />
              <PrivateRoute
                exact
                path={staticConfigs.ROUTE_CLAIM_ACTION_LOGS}
                component={UserActionLogs}
              />
              <PrivateRoute
                exact
                path={staticConfigs.ROUTE_CUSTOMER_PROFILE}
                component={CustomerProfileList}
              />
              <PrivateRoute
                exact
                path={staticConfigs.ROUTE_PRACTICES}
                component={Practices}
              />
              <PrivateRoute
                exact
                path={staticConfigs.ROUTE_PRACTICES_ADD}
                component={PracticesAdd}
              />
              <PrivateRoute
                exact
                path={staticConfigs.ROUTE_PRACTICES_EDIT}
                component={PracticesAdd}
              />
              <PrivateRoute
                exact
                path={staticConfigs.ROUTE_REFERRING_PROVIDER}
                component={ReferringProviderList}
              />
              <PrivateRoute
                exact
                path={staticConfigs.ROUTE_REFERRING_PROVIDER_ADD}
                component={ReferringProviderAdd}
              />
              <PrivateRoute
                exact
                path={staticConfigs.ROUTE_REFERRING_PROVIDER_EDIT}
                component={ReferringProviderAdd}
              />
              <PrivateRoute
                exact
                path={staticConfigs.ROUTE_FACILITIES}
                component={FacilitiesList}
              />
              <PrivateRoute
                exact
                path={staticConfigs.ROUTE_FACILITIES_ADD}
                component={FacilityAdd}
              />
              <PrivateRoute
                exact
                path={staticConfigs.ROUTE_FACILITIES_EDIT}
                component={FacilityAdd}
              />
              <PrivateRoute
                exact
                path={staticConfigs.ROUTE_PROVIDER_MANAGEMENT}
                component={ProviderManagement}
              />
              <PrivateRoute
                exact
                path={staticConfigs.ROUTE_PROVIDER_ADD}
                component={ProviderAdd}
              />
              <PrivateRoute
                exact
                path={staticConfigs.ROUTE_PROVIDER_EDIT}
                component={ProviderAdd}
              />
              <PrivateRoute
                exact
                path={staticConfigs.ROUTE_REFERRING_PROVIDER_GROUP}
                component={ReferringProviderGroup}
              />
               <PrivateRoute
                exact
                path={staticConfigs.ROUTE_PRACTICE_REFERRING_PROVIDER_GROUP}
                component={ReferringProviderGroup}
              />
              <PrivateRoute
                key="patients"
                exact
                path={staticConfigs.ROUTE_PATIENTS_LIST + "/:id?"}
                component={checkPermission(permission_key_values_patient.patient_list_sub_module_view)
                  ? Patientslist : NoPermission}
              />
              <PrivateRoute
                exact
                path={staticConfigs.ROUTE_PATIENT_ADD}
                component={checkPermission(permission_key_values_patient.patients_patients_list_add)
                  ? PatientAdd : NoPermission}
              />
              <PrivateRoute
                exact
                path={staticConfigs.ROUTE_RUN_ELIGIBILITY}
                component={RunEligibility}
              />
              <PrivateRoute
                exact
                path={staticConfigs.ROUTE_STATEMENT_MANAGEMENT}
                component={StatementManagement}
              />
              <PrivateRoute
                exact
                path={staticConfigs.ROUTE_PAYMENT_PLAN}
                component={PaymentPlans}
              />
              <PrivateRoute
                exact
                path={staticConfigs.ROUTE_ACTIVE_PAYMENT_PLAN}
                component={ActivePayment}
              />
              <PrivateRoute
                exact
                path={staticConfigs.ROUTE_PATIENT_LETTERS}
                component={PatientLetters}
              />
              <PrivateRoute
                exact
                path={staticConfigs.ROUTE_PATIENTS_LEDGER}
                component={PatientsLedger}
              />
              <PrivateRoute
                exact
                path={staticConfigs.ROUTE_PATIENTS_LEDGER_LIST}
                component={PatientsLedgerList}
              />
              <PrivateRoute
                exact
                path={staticConfigs.ROUTE_SEARCH_CLAIMS}
                component={checkPermission(permission_key_values_claim.claims_search_claims_view)
                  ? SearchClaimModule : NoPermission}
              />
              <PrivateRoute
                exact
                path={staticConfigs.ROUTE_CLAIMS_FILTER}
                component={checkPermission(permission_key_values_claim.claims_claim_filter_view) ?
                  ClaimsFilter : NoPermission}
              />
              <PrivateRoute
                exact
                path={staticConfigs.ROUTE_AR_CLAIMS}
                component={checkPermission(permission_key_values_claim.ar_claims_sub_module_view) ?
                  ARClaims : NoPermission}
              />
              <PrivateRoute
                exact
                path={staticConfigs.ROUTE_AR_PAPER_CLAIMS}
                component={checkPermission(permission_key_values_claim.claims_paper_claims_view) ?
                  PaperClaims : NoPermission}
              />
              <PrivateRoute
                exact
                path={staticConfigs.ROUTE_POST_PAYMENTS}
                component={
                  checkPermission(permission_key_values_payments.post_payments)
                    ? PostPayments
                    : NoPermission
                }
              />
              <PrivateRoute
                exact
                path={staticConfigs.ROUTE_VIEW_PAYMENTS}
                component={
                  checkPermission(permission_key_values_payments.view_payments)
                    ? ViewPayments
                    : NoPermission
                }
              />
              <PrivateRoute
                exact
                path={staticConfigs.ROUTE_VIEW_RECEIPTS}
                component={
                  checkPermission(permission_key_values_payments.view_payments, permission_key_values_payments.post_payments)
                    ? ViewReceipts
                    : NoPermission
                }
              />
              <PrivateRoute
                exact
                path={staticConfigs.ROUTE_FEE_SCHEDULE_MANAGEMENT}
                component={FeeSchedule}
              />
              <PrivateRoute
                exact
                path={
                  staticConfigs.ROUTE_STANDARD_FEE_SCHEDULE_MANAGEMENT
                }
                component={StandardFeeSchedule}
              />
              <PrivateRoute
                exact
                path={staticConfigs.ROUTE_CODE_MANAGEMENT}
                component={PracticeCodes}
              />
              <PrivateRoute
                exact
                path={staticConfigs.ROUTE_CSVTEMPLATES}
                component={CSVTemplates}
              />
              <PrivateRoute
                exact
                path={staticConfigs.ROUTE_ERA_POSTING}
                component={
                  checkPermission(permission_key_values_payments.era_posting)
                    ? ERAPosting
                    : NoPermission
                }
              />
              <PrivateRoute
                exact
                path={staticConfigs.ROUTE_INBOX}
                component={checkPermission(permission_key_values_messages.inbox_message_sub_module_view)
                  ? Inbox : NoPermission}
              />
              <PrivateRoute
                exact
                path={staticConfigs.ROUTE_INBOX_MESSAGES}
                component={checkPermission(permission_key_values_messages.inbox_message_sub_module_view)
                  ? InboxMessageScreen : NoPermission}
              />
              <PrivateRoute
                exact
                path={staticConfigs.ROUTE_SEND_INBOX}
                component={checkPermission(permission_key_values_messages.sent_message_sub_module_view)
                  ? SendMessagesList : NoPermission}
              />
              <PrivateRoute
                exact
                path={staticConfigs.ROUTE_SEND_INBOX_MESSAGES}
                component={checkPermission(permission_key_values_messages.sent_message_sub_module_view) ?
                  SendMessageScreen : NoPermission}
              />
              <PrivateRoute
                exact
                path={staticConfigs.ROUTE_DRAFT_INBOX}
                component={checkPermission(permission_key_values_messages.draft_message_sub_module_view) ?
                  DraftList : NoPermission}
              />
              <PrivateRoute
                exact
                path={staticConfigs.ROUTE_DRAFT_INBOX_MESSAGES}
                component={checkPermission(permission_key_values_messages.draft_message_sub_module_view) ?
                  DraftMessage : NoPermission}
              />
              <PrivateRoute
                exact
                path={staticConfigs.ROUTE_COMPOSE_MESSAGES}
                component={ComposeScreen}
              />
              <PrivateRoute
                exact
                path={staticConfigs.ROUTE_REPLY_MESSAGES}
                component={checkPermission(permission_key_values_messages.can_add_reply_messages)
                  ? ComposeScreen : NoPermission}
              />
              <PrivateRoute
                exact
                path={staticConfigs.ROUTE_MY_TASKS}
                component={Tasks}
              />
              <PrivateRoute
                exact
                path={staticConfigs.ROUTE_MY_TASKS_LIST}
                component={TaskList}
              />
              <PrivateRoute
                exact
                path={staticConfigs.ROUTE_MY_TASKS_EDIT}
                component={Tasks}
              />
              <PrivateRoute
                exact
                path={staticConfigs.ROUTE_INTERFACE_SEARCH}
                component={SearchInterface}
              />
              <PrivateRoute
                exact
                path={staticConfigs.ROUTE_INTERFACE_GRID}
                component={InterFaceList}
              />
              <PrivateRoute
                exact
                path={staticConfigs.ROUTE_PROVIDER_NAME_MATCH}
                component={ProviderNameMatch}
              />
              <PrivateRoute
                exact
                path={staticConfigs.ROUTE_PAYER_NAME_MATCH}
                component={PayerNameMatch}
              />
              <PrivateRoute
                exact
                path={staticConfigs.ROUTE_FACILITY_NAME_MATCH}
                component={FacilityNameMatch}
              />
              <PrivateRoute
                exact
                path={staticConfigs.ROUTE_TEST_CODE_SETUP}
                component={TestCodeSetup}
              />
              <PrivateRoute
                exact
                path={staticConfigs.ROUTE_USER_REPORTS_BUILDER}
                component={ReportBuilder}
              />
              <PrivateRoute
                exact
                path={staticConfigs.ROUTE_USER_REPORTS_CHARGES_DEBIT_REPORT}
                component={ChargeDebitReport}
              />
              <PrivateRoute
                exact
                path={staticConfigs.ROUTE_USER_REPORTS_PAYMENT_ADJUSTMENTS_REPORT}
                component={PaymentAdjustmentReport}
              />
              <PrivateRoute
                exact
                path={staticConfigs.ROUTE_USER_REPORTS_OPENAR_REPORT}
                component={OpenARReport}
              />
              <PrivateRoute
                exact
                path={staticConfigs.ROUTE_USER_REPORTS_TRANSACTION_REPORT}
                component={TransactionReport}
              />
              <PrivateRoute
                exact
                path={staticConfigs.ROUTE_USER_REPORTS_360DEGREE_REPORT}
                component={Report360}
              />
              <PrivateRoute
                exact
                path={staticConfigs.ROUTE_USER_REPORTS_BUILDER_NEW}
                component={NewFromScratchReport}
              />
              <PrivateRoute
                exact
                path={
                  staticConfigs.ROUTE_USER_REPORTS_BUILDER_NEW_FROM_TEMP
                }
                component={NewFromTemplate}
              />
              <PrivateRoute
                exact
                path={staticConfigs.ROUTE_USER_REPORTS_BUILDER_EDIT}
                component={EditReport}
              />
              <PrivateRoute
                exact
                path={staticConfigs.ROUTE_USER_REPORTS_VIEWER}
                component={ReportViewer}
              />
              <PrivateRoute
                exact
                path={staticConfigs.ROUTE_TEMPLATE}
                component={Template}
              />
              <PrivateRoute
                exact
                path={staticConfigs.ROUTE_ADD_TEMPLATE}
                component={AddTemplate}
              />
              <PrivateRoute
                exact
                path={staticConfigs.ROUTE_ROLES_AND_PERMISSIONS}
                component={RolesAndPermissions}
              />
              <PrivateRoute
                exact
                path={staticConfigs.ROUTE_ADMIN_ROLES_AND_PERMISSIONS}
                component={RolesAndPermissions}
              />

              <PrivateRoute exact path={"/example"} component={LabTabs} />
              {/* <PrivateRoute exact path={'/examplePatient'} component={ExamplePatientsList} /> */}
              {/* <PrivateRoute key="quote"  exact path={staticConfigs.ROUTE_QUOTES+'/:id?'} component={EventList} />
                     */}
              <PrivateRoute path="*" component={NotfoundComponent} />
            </Switch>
          </Suspense>
        </MainContent>
      </LoadingProvider>
    </AppContainer>
  );
};

export default withRouter(ContentComponent);
