import { CloseSharp } from "@material-ui/icons";
import { CropSquareOutlined } from "@mui/icons-material";
import {
  IconButton,
  ListItem,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import React from "react";

const FieldListItem = ({ fieldObjecct }) => {
  return (
    <ListItem
      secondaryAction={
        <IconButton edge="end" aria-label="delete">
          <CloseSharp />
        </IconButton>
      }
    >
      <ListItemIcon>
        <CropSquareOutlined className="rotated-icon" />
      </ListItemIcon>
      <ListItemText primary={fieldObjecct.display_name} />
    </ListItem>
  );
};

export default FieldListItem;
