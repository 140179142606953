import api from '../../../service/api'

const ListFacilityTypes = async(pageSize, page, searchValue) =>{
	let path = 'super-admin/facility-type/?page_size='+pageSize+'&page='+page
	if (searchValue) {
        path += `&search=${searchValue}`;
    }
	return api.get(path);
}

const AddFacilityType = async(data) =>{
	let path = 'super-admin/facility-type/'
	return api.post(path,data);
}
const GetFacilityType = async(facilityId) =>{
	let path = 'super-admin/facility-type/' + facilityId
	return api.get(path);
}

const UpdateFacilityType = async(facilityId, data) =>{
	let path = 'super-admin/facility-type/' + facilityId
	return api.put(path,data);
}

const DeleteFacilityType = async(facilityId) =>{
	let path = 'super-admin/facility-type/' + facilityId
	return api.destroy(path);
}

export default {ListFacilityTypes, AddFacilityType, GetFacilityType, UpdateFacilityType, DeleteFacilityType}