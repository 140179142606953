import i18n from "../../utilities/i18n";

const TableHeadData = [
    {
        "name": "",
        "type": "string",
        "width": "check_box",
        "sort": false,
        "value": false,
    },
    {
        "name": i18n.t("inboxScreens.inboxScreen.tableHeadings.from"),
        "type": "string",
        "width": "small",
        "sort": false,
    },
    {
        "name": i18n.t("inboxScreens.inboxScreen.tableHeadings.subject"),
        "type": "number",
        "width": "medium",
        "sort": false,
    },
    {
        "name": i18n.t("inboxScreens.inboxScreen.tableHeadings.message"),
        "type": "string",
        "width": "medium",
        "sort": false,
    },
    {
        "name": i18n.t("inboxScreens.inboxScreen.tableHeadings.to"),
        "type": "string",
        "width": "medium",
        "sort": false,
    },
    {
        "name": i18n.t("inboxScreens.inboxScreen.tableHeadings.date"),
        "type": "string",
        "width": "small",
        "sort": false,
    },
];

export const TableBodyData = [
    [
        {
            "id": "",
            "name": "message_id",
            "type": "checkbox",
            "width": "check_box",
            "align": "center",
            "value": false
        },
        {
            "id": "",
            "name": "sender",
            "value": "",
            "type": "string",
            "align": "left",
            "width": "medium",
        },
        {
            "id": "",
            "name": "subject",
            "value": "",
            "type": "string",
            "align": "left",
            "width": "medium",
        },
        {
            "id": "",
            "name": "short_body",
            "value": "",
            "type": "string",
            "align": "left",
            "width": "medium",
        }, 
        {
            "id": "",
            "name": "receivers",
            "value": "",
            "type": "string",
            "align": "left",
            "width": "medium",
        }, 
        {
            "id": "",
            "name": "created_on",
            "value": "",
            "type": "string",
            "align": "center",
            "width": "medium",
            "lrPadding" : "low"
        }
    ]
]

export const InboxTableData = {
    "name": "clickOnRowsTable",
    "isResize": false,
    "isFixedHead": false,
    "tableHeadings": TableHeadData,
    "tableBodyData": TableBodyData
};

export const templateData = {
    "name": "clickOnRowsTable",
    "isResize": false,
    "isFixedHead": false,
    "tableHeadings": TableHeadData,
    "tableBodyData": TableBodyData
};

