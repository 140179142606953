import * as React from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import Checkbox from '@mui/material/Checkbox';
import Label from '../components/commons/Label';
import { Box } from '@mui/material';
import Chip from '@mui/material/Chip';

const MultiSelectAutoComplete = (props) => {
    
    const [inputValue, setInputValue] = React.useState('');
    React.useEffect(() => {
        if (inputValue.length >= 2) {
            props.onSearch(inputValue);
        }
    }, [inputValue]);

    const handleDelete = (itemToDelete) => {
        const newValue = props.value.filter((item) => item.id !== itemToDelete.id);
        props.onChange(newValue);
    };

    return (
        <>
            <Label label={props.label} />
            <Autocomplete
                sx={{
                    '& .MuiAutocomplete-tag': {
                        display: 'none' 
                    }
                }}
                multiple
                value={props.value}
                id="demo-multiple-select"
                options={props.options}
                disableCloseOnSelect
                getOptionLabel={(option) => option.name}
                isOptionEqualToValue={(option, value) => option.id === value.id}
                onChange={(event, newValue) => {
                    props.onChange(newValue);
                }}
                onInputChange={(event, newInputValue) => {
                    setInputValue(newInputValue); 
                }}
                onBlur={()=> {props.onBlur()}}
                renderOption={(props, option, { selected }) => {
                    return (
                        <li {...props}>
                            <Checkbox
                                style={{ marginRight: 8 }}
                                checked={selected}
                            />
                            {option.name}
                        </li>
                    );
                }}
                renderInput={(params) => (
                    <TextField {...params} />
                )}
            />

            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1, mb: 2, mt:4 }}>
                {props.value && props.value?.map((item,index) => (
                    <Chip 
                        sx={{ fontSize: '1rem' }}
                        key={item.id ?? index}
                        label={`${item?.name?.length > 30 ? item.name.substring(0, 30) + '...' : item?.name}`}
                        variant="outlined" 
                        onDelete={()=>handleDelete(item)} 
                    />
                ))}
            </Box>
        </>
    );
};

export default MultiSelectAutoComplete;
