import React from "react";
import "./style.css";
import Label from "../Label";

const TextAreaInput = (props) => {
    const value = props.value;
    const req = props.required;

    return (
        <div className="textarea-floating-label">
            <Label label={props.label}/>
            <textarea
                style={{borderRadius:"4px" }}
                className={(req ? (value ? " " : "input-error ") : " ") + props.className }
                id={props.id}
                name={props.name}
                onChange={props.onValueChange}
                cols={props.cols}
                rows={props.rows}
                required={!req ? "" : req}
                value={props.value}
                disabled={props.disabled ? props.disabled : ''}
            >
            </textarea>
        </div>
    );
};
export default TextAreaInput;
