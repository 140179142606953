import React from 'react';
import { currencyFormat } from "../../../utilities/commonUtilities";
import i18n from "../../../utilities/i18n";

export function CreditBalanceTable({ data }) {
    return (
        <table className="table mt-5" style={{ width: "100%"}}>
            <thead>
                <tr>
                    <th>{i18n.t("patientPages.patientLedger.creditBalanceTab.transactionDate")}</th>
                    <th>{i18n.t("patientPages.patientLedger.creditBalanceTab.transactionCarrier")}</th>
                    <th>{i18n.t("patientPages.patientLedger.creditBalanceTab.referenceNum")}</th>
                    <th>{i18n.t("patientPages.patientLedger.creditBalanceTab.transactionAmount")}</th>
                    <th>{i18n.t("patientPages.patientLedger.creditBalanceTab.unapplied")}</th>
                    <th>{i18n.t("dictionariesPages.action")}</th>
                </tr>
            </thead>
            <tbody>
                {data.length === 0 && (
                    <tr>
                        <td align="center" colSpan="2">
                            {i18n.t("commons.noRecords")}
                        </td>
                    </tr>
                )}
                {data.map((item) => {
                    return (
                        <tr key={item.id}>
                            <td align="center">{item.transaction_date}</td>
                            <td>{item.transaction_carrier}</td>
                            <td>{item.reference_number}</td>
                            <td align="right">{currencyFormat(item.transaction_amount)}</td>
                            <td align="right">{currencyFormat(item.unapplied_amount)}</td>
                            <td></td>
                        </tr>
                    );
                })}
            </tbody>
        </table>
    );
}


export function PatientSummaryTable({ paymentSummary, table }) {
    return (
        <table>
            {table === 'table1' ?
                <tbody>
                    <tr>
                        <td>{paymentSummary.name}</td>
                        <td></td>
                    </tr>
                    <tr>
                        <td>{i18n.t("patientPages.patientLedger.accountSummaryTab.totalCharges")}</td>
                        <td className="bold align-right">{currencyFormat(paymentSummary.charges)}</td>
                    </tr>
                    <tr>
                        <td>{i18n.t("patientPages.patientLedger.accountSummaryTab.totalPayments")}</td>
                        <td className="bold align-right">{currencyFormat(paymentSummary.payments)}</td>
                    </tr>
                    <tr>
                        <td>{i18n.t("patientPages.patientLedger.accountSummaryTab.totalAdjustments")}</td>
                        <td className="bold align-right">{currencyFormat(paymentSummary.adjustments)}</td>
                    </tr>
                    <tr>
                        <td>{i18n.t("patientPages.patientLedger.accountSummaryTab.totalBalance")}</td>
                        <td className="bold align-right">{currencyFormat(paymentSummary.balance)}</td>
                    </tr>
                    <tr>
                        <td>{i18n.t("patientPages.patientLedger.accountSummaryTab.insuranceBalance")}</td>
                        <td className="bold align-right">{currencyFormat(paymentSummary.insurance_balance)}</td>
                    </tr>
                    <tr>
                        <td>{i18n.t("patientPages.patientLedger.accountSummaryTab.patientBalance")}</td>
                        <td className="bold align-right">{currencyFormat(paymentSummary.patient_balance)}</td>
                    </tr>
                    <tr>
                        <td>{i18n.t("patientPages.patientLedger.accountSummaryTab.insuranceCredits")}</td>
                        <td className="bold align-right">{currencyFormat(paymentSummary.insurance_credits)}</td>
                    </tr>
                    <tr>
                        <td>{i18n.t("patientPages.patientLedger.accountSummaryTab.patientCredits")}</td>
                        <td className="bold align-right">{currencyFormat(paymentSummary.patient_credits)}</td>
                    </tr>

                </tbody>
                :
                <tbody>
                    <tr>
                        <td>{i18n.t("patientPages.patientLedger.accountSummaryTab.totalClaimsBilled")}</td>
                        <td className="bold align-right">{paymentSummary.claims_billed}</td>
                    </tr>
                    <tr>
                        <td>{i18n.t("patientPages.patientLedger.accountSummaryTab.totalProceduresBilled")}</td>
                        <td className="bold align-right">{paymentSummary.procedures_billed}</td>
                    </tr>
                    <tr>
                        <td>{i18n.t("patientPages.patientLedger.accountSummaryTab.avgPayClaim")}</td>
                        <td className="bold align-right">{currencyFormat(paymentSummary.avg_paid_claims)}</td>
                    </tr>
                    <tr>
                        <td>{i18n.t("patientPages.patientLedger.accountSummaryTab.avgPayProcedure")}</td>
                        <td className="bold align-right">{currencyFormat(paymentSummary.avg_paid_procedures)}</td>
                    </tr>
                    <tr>
                        <td>{i18n.t("patientPages.patientLedger.accountSummaryTab.grossCollectionRate")}</td>
                        <td className="bold align-right">{paymentSummary.gross_rate}%</td>
                    </tr>
                    <tr>
                        <td>{i18n.t("patientPages.patientLedger.accountSummaryTab.netCollectionRate")}</td>
                        <td className="bold align-right">{paymentSummary.net_rate}%</td>
                    </tr>
                    <tr>
                        <td>{i18n.t("patientPages.patientLedger.accountSummaryTab.arDays")}</td>
                        <td className="bold align-right">{paymentSummary.ar_days}</td>
                    </tr>
                    <tr>
                        <td>{i18n.t("patientPages.patientLedger.accountSummaryTab.firstPassRate")}</td>
                        <td className="bold align-right">{paymentSummary.first_pass_rate}</td>
                    </tr>
                    <tr>
                        <td>{i18n.t("patientPages.patientLedger.accountSummaryTab.denailAge")}</td>
                        <td className="bold align-right">{paymentSummary.denial}</td>
                    </tr>
                </tbody>
            }
        </table>
    )
}