import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    tabSwitchFlag: false,
    permissionErrorFlag: false,
    dashboardEmbedData: JSON.parse(localStorage.getItem('dashboardEmbedData')) ?? null, // this will be initially null but once the data is received from the backend then will be an object
};

export const commonStateSlice = createSlice({
    name: "commonStates",
    initialState,
    reducers: {
        setCommonState: (state, actions) => {
            return {
                ...state,
                [actions.payload.name] : actions.payload.value
            }
        },
        // eslint-disable-next-line
        reset: (state) => initialState,
    }
});


export const { setCommonState, reset } = commonStateSlice.actions

export default commonStateSlice.reducer;
