import { Divider, Grid } from "@mui/material";
import React from "react";
import DateFieldSelector from "./DateFieldSelector";

const DateGroupFields = ({ fields }) => {
  return (
    <Grid container spacing={2} className="mt-4">
      <Grid xs={6}>
        <strong>Filter Date Fields</strong>
        <Divider className="mt-2 mb-2" />
        {fields.fields.map((item, index) => (
          <DateFieldSelector item={item} key={index} />
        ))}
      </Grid>
      <Grid xs={6}></Grid>
    </Grid>
  );
};

export default DateGroupFields;
