import React from 'react'
import 'date-fns';
import TextField from '@mui/material/TextField';
import Grid from '@material-ui/core/Grid';
import "./style.css";
import { KeyboardDatePicker } from '@material-ui/pickers';
import Label from '../Label';

const CalendarInput = (props) => {
    let class_name = "form-group";
    if (props.required && !props.selected) {
        class_name += " input-error";
    }
    let date = props.selected && typeof props.selected === 'string' ? props.selected + " 00:00:00" : props.selected
    return (
        <>
            {!props.noLabel && <Label label={props.label} /> }
            <Grid container justifyContent="space-around">
                <div className={class_name}>
                    <KeyboardDatePicker
                        format="MM/dd/yyyy"
                        autoOk={true}
                        variant="inline"
                        margin="normal"
                        id={props.id ? props.id : "date-picker-inline"}
                        minDate={props.minDate}
                        maxDate={props.maxDate}
                        value={date ? date : null}
                        onChange={props.onValueChange}
                        renderInput={(params) => <TextField {...params} />}
                        disableFuture={props.disableFuture ? props.disableFuture : false}
                        required={props.required ? props.required : ""}
                        autoComplete="off"
                        disabled={props.disabled ? true : false}
                        inputVariant='outlined'
                    />
                </div>
            </Grid>
        </>
    )
}

export default CalendarInput
