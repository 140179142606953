import React, { useEffect, useState } from "react";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import { Box, Tab } from "@mui/material";
import { TAB1 } from "../../../utilities/staticConfigs";
import RunReportCard from "./RunReportCard";
import { Close } from "@material-ui/icons";
import ReportResultCard from "./ReportResultCard";
import { ReportContextProvider } from "./report-context";

const ReportViewer = () => {
  const [value, setValue] = useState(TAB1);
  const [openTabs, setOpenTabs] = useState([]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const onReportItemClickHandler = (item) =>
    setOpenTabs((prev) => [...prev, item]);

  useEffect(() => {
    const currentTabs = [...openTabs];
    if (currentTabs.length !== 0) {
      setValue(`${currentTabs.length}`);
    } else {
      setValue(TAB1);
    }
  }, [openTabs]);

  const onCloseTab = (item) => {
    setValue(TAB1);
    const currentState = [...openTabs];
    const newState = currentState.filter((element) => element.id !== item.id);
    setOpenTabs(newState);
  };
  return (
    <div className="container">
      <div className="box">
        <div className="box-content">
          <Box sx={{ width: "100%", typography: "body1" }}>
            <TabContext value={value}>
              <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                <TabList onChange={handleChange}>
                  <Tab label="Run Report" value={TAB1} className="tab" />
                  {openTabs.length !== 0 &&
                    openTabs.map((tabData, index) => (
                      <Tab
                        key={index}
                        label={tabData.report_name}
                        value={`${index + 1}`}
                        className="tab"
                        icon={
                          <Close onClick={() => onCloseTab(tabData, index)} />
                        }
                        iconPosition="end"
                      />
                    ))}
                </TabList>
              </Box>
              <TabPanel value={TAB1}>
                <RunReportCard
                  onReportItemClickHandler={onReportItemClickHandler}
                />
              </TabPanel>
              {openTabs.map((tabData, index) => (
                <TabPanel key={index} value={`${index + 1}`}>
                  <ReportContextProvider
                    reportName={tabData.report_name}
                    reportId={tabData.id}
                  >
                    <ReportResultCard
                      reportId={tabData.id}
                      reportName={tabData.report_name}
                    />
                  </ReportContextProvider>
                </TabPanel>
              ))}
            </TabContext>
          </Box>
        </div>
      </div>
    </div>
  );
};

export default ReportViewer;
