import api from '../../../service/api'

const ListPatientTypes = async(pageSize, page, isAdminModule, practicePK) =>{
	let path = null;
	if (isAdminModule === 'false')
		path = 'practice/patient-type/?page_size='+ pageSize +'&page='+ page +'&practice_pk='+ practicePK;
	else
		path = 'super-admin/patient-type/?page_size='+ pageSize +'&page='+ page;
	return api.get(path);
}

const AddPatientType = async(data) =>{
	let path = null;
	if (Object.prototype.hasOwnProperty.call(data, "practice"))
		path = 'practice/patient-type/';
	else
		path = 'super-admin/patient-type/';
	return api.post(path, data);
}
const GetPatientType = async(patientId, practicePK) =>{
	let path = null;
	if (practicePK)
		path = 'practice/patient-type/' + patientId;
	else
		path = 'super-admin/patient-type/' + patientId;
	return api.get(path);
}

const UpdatePatientType = async(patientId, data) =>{
	let path = null;
	if (Object.prototype.hasOwnProperty.call(data, "practice"))
		path = 'practice/patient-type/' + patientId;
	else
		path = 'super-admin/patient-type/' + patientId;
	return api.put(path, data);
}

const DeletePatientType = async(patientId, practicePK) =>{
	let path = null;
	if (practicePK)
		path = 'practice/patient-type/' + patientId;
	else
		path = 'super-admin/patient-type/' + patientId;
	return api.destroy(path);
}

export default {ListPatientTypes, AddPatientType, GetPatientType, UpdatePatientType, DeletePatientType}