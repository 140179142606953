import React from 'react'
import { useState } from 'react'
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { CommonGridPhoneNumber, currencyFormat, YMD_TO_MDY } from '../../../utilities/commonUtilities'
import i18n from '../../../utilities/i18n'
import TableWarningPopUp from '../tableWarningPopUp'
import api from '../../../service/api';

import IconButton from '@material-ui/core/IconButton';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import StarIcon from '@material-ui/icons/Star';
import Chips from '../Chips';
import AnimationLoader from '../AnimationLoader';
import DownloadIcon from '@mui/icons-material/Download';


const TableBody = (props) => {
    const [loading, setLoading] = useState(false);
    const [payerData, setPayerData] = useState('');
    // Finding the index of Claims and storing in variable, where payer_types === "patient", to render as Self-Pay in the Tablecell, instead of rendering Patient Names as Responsible Payer - JS    
    function findPatientIndexOfSelfPay(arr) {
        let patientIndex = []
        if (arr[0].some(obj => obj.name === "payer_types") || arr[0].some(obj => obj.name === "responsible_type")) {
            if (arr[0].some(obj => obj.name === "payer_types")) {
                // Validating Claims Tables other than AR Claims tables
                for (let i = 0; i < arr.length; i++) {
                    for (let j = 0; j < arr[i].length; j++) {
                        if (arr[i][j].name === "payer_types" &&
                            arr[i][j].value.type === "patient") {
                            patientIndex.push(i);
                            break;
                        }
                    }
                }
            } else {
                // Validating AR Claims Table
                for (let i = 0; i < arr.length; i++) {
                    for (let j = 0; j < arr[i].length; j++) {
                        if (arr[i][j].name === "responsible_type" && arr[i][j].value === "Patient") {
                            patientIndex.push(i);
                            break;
                        }
                        // Validating if Patient has no insurance assiged
                        else if (arr[i][j].name === "responsible_type" && arr[i][j].value === "") {
                            patientIndex.push(i);
                            continue;
                        }
                    }
                }
            }
        }
        return patientIndex;
    }
    const selfPayPatientIndex = findPatientIndexOfSelfPay(props.tableBodyData)

    // for Patient History Table
    function checkIsICDAlreadyInList(icdList, item) {
        let flag = false;
        for (let i = 1; i <= 12; i++){
            const diagnosis = `diagnosis${i}List`;
            if (icdList[diagnosis][0]?.name === item[0].value) {
                flag = true;
                break;
            }
        }

        return flag;
    }

    // for Patient History Table of cpt
    function checkIsCPTAlreadyInList(procedure, item) {
        let flag = false;
        for (let key in procedure) {
            if (procedure[key].cpt_hcpcsList[0]?.name === item[0].value) {
                flag = true;
                break;
            }
        }
        return flag;
    }


    // To display responsible payer details in Tooltip;
    async function getPayerDetails(id) {
        try {
            setLoading(true);
            const response = await api.get(`practice/practice-responsible-payer/${id}`);
            setPayerData(response.data);
        } catch (error) {
            setLoading(false);
            return;
        } finally {
            setLoading(false);
        }
    }

    function getRowClassName(item, props) {
        const { id, clickOnRowCondition, is_read } = item[0];
        const { defaultBold, tableType } = props;

        let className = '';

        if (id === clickOnRowCondition) {
            className += 'def-practice-row-bg ';
        } else if (defaultBold === id) {
            className += 'unreaded ';
        } else if (tableType === "clickOnRowsTable") {
            className += is_read ? '' : 'cursor-pointer ';
        }

        if (item[0].id === props.rowBackgroundChange) {
            className += ' row-bg-change-last-opened-indication';
        }

        return className.trim();
    }



    return (
        <>
            <tbody>
                {
                    props.tableBodyData?.map((item, index) => {
                        return (
                            !item[0]?.id ?
                                <tr key={item[0]?.id}>
                                    <td colSpan={item.length} align="center">
                                        {props.noRecordsMsg}
                                    </td>
                                </tr>
                                :
                                <>
                                    <tr key={item[0]?.id}
                                        className={getRowClassName(item, props)}
                                        onClick={(props.tableType == "clickOnRowsTable" && item[0].id != item[0].clickOnRowCondition) ? (e) => props.onRowClick(e, item[0].id) : null} >
                                        {   // (.filter) --removing the 'payer_types' object from the tableData as it is not required to show in the table...
                                            // but needed to check if the responsible payer is a selfPay or Insurance not assigned!
                                            item.filter(tableData => tableData.name !== 'payer_types').map((tableData, indexValue) => {
                                                let hide = tableData.hideValue ? " hide " : ""
                                                return tableData.name === 'action' ? (
                                                    <td key={indexValue} align={tableData.align} className={tableData.width + " " + hide}>
                                                        {
                                                            tableData.dropdown.map((dropdownlist, dropdownIndex) => {
                                                                let Icon;
                                                                switch (dropdownlist.name) {
                                                                    case 'Edit':
                                                                        Icon = EditIcon;
                                                                        break;
                                                                    case 'Delete':
                                                                        Icon = DeleteIcon;
                                                                        break;
                                                                    case 'Set as default':
                                                                        Icon = StarIcon;
                                                                        break;
                                                                    case 'Download':
                                                                        Icon = DownloadIcon;
                                                                            break;
                                                                    default:
                                                                        return null;
                                                                }

                                                                return (
                                                                    <OverlayTrigger
                                                                        key={dropdownIndex}
                                                                        overlay={
                                                                            <Tooltip id={`tooltip-${dropdownIndex}`}>
                                                                                {dropdownlist.name}
                                                                            </Tooltip>
                                                                        }
                                                                    >
                                                                        <IconButton
                                                                            id={`dropdown-option-${dropdownlist.name}-${tableData.id}`}
                                                                            data-testid={'dropdown-btn-' + dropdownlist.name + tableData.id}
                                                                            onClick={() => {
                                                                                props.dropDownFunction(tableData.id, dropdownlist.name);
                                                                            }}
                                                                            style={{ marginRight: '10px', marginLeft: '10px' }}
                                                                            disabled={props.defaultBold === tableData.id && dropdownlist.name === 'Set as default'}
                                                                        >
                                                                            <Icon />
                                                                        </IconButton>
                                                                    </OverlayTrigger>
                                                                );
                                                            })
                                                        }
                                                    </td>
                                                )
                                                    :
                                                    <td onClick={tableData.name === "expand" ? () => props.onExpandClick(tableData.id, tableData.name) : null} key={indexValue} align={tableData.align} className={tableData.width + " " + hide + (
                                                        // tableData?.lrPadding === 'low' ? ' pr-1 pl-1' :
                                                        tableData.is_read == false ? "isreadinbox" : '') + (tableData?.type === 'expand' ? 'cursor-pointer ' : '')} >
                                                        {(() => {
                                                            switch (tableData.type) {
                                                                case "currency": return currencyFormat(tableData.value)
                                                                case "Date":
                                                                    return tableData?.needDateFormat == "yes" ? YMD_TO_MDY(tableData.value) : tableData.value
                                                                case "phone": return CommonGridPhoneNumber(tableData.value)
                                                                case "boolean": return tableData.value ? tableData.booleanCondition.split(' ')[0] : tableData.booleanCondition.split(' ')[1]
                                                                case "download": return <a className="pl-0" href={tableData.directLinkPath} download={item.name}>{tableData.value}</a>
                                                                case "responsiblePayer":
                                                                    if (tableData.directLinkPath == "" && tableData.value !== "Paid") {
                                                                        return <Link style={{ textDecoration: "none" }} className="pl-0" to='#' onClick={() => props?.onLinkClick(tableData?.id, tableData?.name)}>
                                                                            {tableData?.value?.name?.toUpperCase()}
                                                                        </Link>
                                                                    }
                                                                    else {
                                                                        return <div style={{ textDecoration: "none" }} className="pl-0" >
                                                                            {/* Value of responsiblePayer is reciving in the format as follows "Molina || PayerID || Phone#" hence splitting the data 
                                                                                                                                    for display as Payer/Patient Name, Payer ID, Phone#. */}
                                                                            {!selfPayPatientIndex.includes(index) && (tableData?.value?.name && tableData?.value?.name !== "Paid") ?
                                                                                <OverlayTrigger onEnter={() => { getPayerDetails(tableData.directLinkState?.selectedID); }} placement="top" delay={{ show: 250 }} rootClose={true} trigger='click' overlay={
                                                                                    <Tooltip id={index} style={{ opacity: 0.7, }}>
                                                                                        {loading ?
                                                                                            <>
                                                                                                <div >Loading...</div>
                                                                                            </>
                                                                                            :
                                                                                            <div style={{ padding: '10px', marginLeft: '5px', marginRight: '5px' }}>
                                                                                                <div className='row'><h6> {payerData.name} </h6> </div>
                                                                                                <div className='row'>{payerData.payer_id && <strong> {'Payer ID: ' + payerData.payer_id} </strong>}</div>
                                                                                                <div className='row'>{payerData.street1 && payerData.street2 && <strong> {'Address: ' + payerData.street1 + ' ' + payerData.street2} </strong>}</div>
                                                                                                <div className='row'>{payerData.city && payerData.state && payerData.zip_code && <strong> {payerData.city + ' ' + payerData.state + ' ' + payerData.zip_code} </strong>}</div>
                                                                                                <dvi className='row'>{payerData.phone && <strong> {"Phone: " + payerData.phone} </strong>}</dvi>
                                                                                                {(!payerData.payer_id && !payerData.street1 && !payerData.street2 && !payerData.city && !payerData.state && !payerData.zip_code && !payerData.phone) && <strong>No details available</strong>}<br />
                                                                                            </div>
                                                                                        }
                                                                                    </Tooltip>
                                                                                } >
                                                                                    <span className="anchor-like-span">
                                                                                        {tableData?.value?.name?.toUpperCase()}
                                                                                    </span>
                                                                                </OverlayTrigger>
                                                                                // Checking the if the index of this item includes in the selfPayPatientIndex List to display as 'Self-Pay / Insurance not Assgined'
                                                                                : <span> {selfPayPatientIndex.includes(index) ? 'Patient' : tableData.value} </span>
                                                                            }
                                                                        </div>
                                                                    }
                                                                case "checkbox":
                                                                    if (tableData.name == 'message_id') {
                                                                        return <div className="">
                                                                            <input type="checkbox" name={tableData.message_id}
                                                                                checked={tableData.value}
                                                                                data-testid={'checkbox-' + index}
                                                                                onClick={(e) => e.stopPropagation()}
                                                                                onChange={(e) => props.onClickGridCheckBox(e, 'data', tableData.id)} className={`mt-1 sizeChecbox checkbox-${tableData.value}`} />
                                                                        </div>
                                                                    }
                                                                    else {
                                                                        return <div className="form-groupp lh-1">
                                                                            <div className={tableData?.hideCheckbox == 'true' ? ' hidden ' : "custom-checkbox"}>
                                                                                <input type="checkbox" name="gridCheckBox" id={`gridCheckBox${index}`} checked={tableData.value ? tableData.value : false} className={`checkbox-${tableData.value}`} onChange={(e) => props.onClickGridCheckBox(e, 'data', tableData.id)}
                                                                                    disabled={props.checkboxEnable} />
                                                                                <label className="checkbox" htmlFor={`gridCheckBox${index}`}>{''}</label>
                                                                            </div>
                                                                        </div>
                                                                    }
                                                                case "warning":
                                                                    return tableData.is_warnings ?
                                                                        <>
                                                                            {(props.isLoading.loading && props.isLoading.id == tableData.id && tableData.value.length == 0) ?
                                                                                <AnimationLoader /> :
                                                                                <> <span onMouseOver={(e) => props.onMouseOverWarning(e, tableData.id)}>
                                                                                    <TableWarningPopUp notifyDescription={i18n.t("warning.incompleteData")}
                                                                                        formWarningData={tableData.value} formWarningStatus={tableData.status} />
                                                                                </span>
                                                                                </>
                                                                            } </> : ''

                                                                case "exportOptionsHCFA":
                                                                    return tableData.value.map((oltItem, oltIndex) => {
                                                                        return (
                                                                            <OverlayTrigger
                                                                                key={oltIndex}
                                                                                placement="bottom"
                                                                                delay={{ show: 250, hide: 400 }}
                                                                                overlay={<Tooltip id="download-tooltip" style={{
                                                                                    opacity: 0.4,
                                                                                }}>{oltItem.toolTipLabel}
                                                                                </Tooltip>}
                                                                            >
                                                                                <span className="grid-icon-span" onClick={(e) => props.exportFunction(e, oltItem.secondParameter, oltItem.thirdParameter)}>
                                                                                    <i className={oltItem.fileIconClass}></i>
                                                                                </span>
                                                                            </OverlayTrigger>
                                                                        )
                                                                    })

                                                                case "exportOptionsERAPosting":
                                                                    return tableData.value.map((eraItem, eraIndex) => {
                                                                        return (
                                                                            <OverlayTrigger
                                                                                key={eraIndex}
                                                                                placement="bottom"
                                                                                delay={{ show: 250, hide: 400 }}
                                                                                overlay={<Tooltip id="download-tooltip" style={{
                                                                                    opacity: 0.4,
                                                                                }}>{eraItem.toolTipLabel}
                                                                                </Tooltip>}
                                                                            >
                                                                                {
                                                                                    <span className="grid-icon-span" onClick={(e) => props.exportFunction(e, eraItem.secondParameter, eraItem.thirdParameter, eraItem.toolTipLabel)}>
                                                                                        <i className={eraItem.fileIconClass}></i>
                                                                                    </span>
                                                                                }
                                                                            </OverlayTrigger>
                                                                        )
                                                                    })

                                                                case "plusButton":
                                                                    return props.diagnosisVariable ?
                                                                        <>
                                                                            {checkIsICDAlreadyInList(props.diagnosisVariable, item) ? // for Patient history table on Claim module
                                                                                <>
                                                                                    <OverlayTrigger
                                                                                        placement="right"
                                                                                        rootClose={true} trigger={['hover', 'click']}
                                                                                        delay={{ show: 100, }}
                                                                                        overlay={<Tooltip id="addICDToClaim" style={{
                                                                                            opacity: .6, zIndex: '100001'
                                                                                        }}>Dx <strong>{item[0].value}</strong> already in the claim.
                                                                                            <br />
                                                                                            Click to remove from the list.
                                                                                        </Tooltip>}
                                                                                    >
                                                                                        <button type="button" className="btn btn-round-blue" id={`isFam` + indexValue} name={`isFam` + indexValue} onClick={() => props.removeDiagnosisFromPatientHistory(item)}>
                                                                                            <i className="fa fa-minus"></i>
                                                                                        </button>
                                                                                    </OverlayTrigger>
                                                                                </> :
                                                                                <>
                                                                                    <button type="button" className="btn btn-round-blue" data-testid={`plus-btn-` + indexValue} id={`isFam` + indexValue} name={`isFam` + indexValue} onClick={(e) => props.addDiagnosisFromPatientHistory(e, tableData.id, item)} >
                                                                                        <i className="fas fa-plus"></i>
                                                                                    </button>
                                                                                </>
                                                                            }
                                                                        </>
                                                                        :
                                                                        <>
                                                                            {props.cptHistoryEnable ?
                                                                                <>
                                                                                    {checkIsCPTAlreadyInList(props.procedureRows, item) ? // for Patient history table on Claim module
                                                                                        <>
                                                                                            <OverlayTrigger
                                                                                                placement="right"
                                                                                                rootClose={true} trigger={['hover', 'click']}
                                                                                                delay={{ show: 100, }}
                                                                                                overlay={<Tooltip id="addCPTToClaim" style={{
                                                                                                    opacity: .6, zIndex: '100001'
                                                                                                }}>CPT <strong>{item[0].value}</strong> already in the claim.
                                                                                                    <br />
                                                                                                </Tooltip>}
                                                                                            >
                                                                                                <button type="button" disabled className="btn btn-round-blue" id={`isFam` + indexValue} name={`isFam` + indexValue}>
                                                                                                    <i className="fa fa-minus"></i>
                                                                                                </button>
                                                                                            </OverlayTrigger>
                                                                                        </> :
                                                                                        <>
                                                                                            <button type="button" className="btn btn-round-blue" data-testid={`plus-btn-` + indexValue} id={`isFam` + indexValue} name={`isFam` + indexValue}
                                                                                                onClick={(e) => props.addCPTFromPatientHistory(e, tableData.id, item)}>
                                                                                                <i className="fas fa-plus"></i>
                                                                                            </button>
                                                                                        </>
                                                                                    }
                                                                                </>
                                                                                : 
                                                                                <button type="button" className="btn btn-round-blue" id={`isFam` + indexValue} data-testid={`plus-btn-` + indexValue} name={`isFam` + indexValue} onClick={(e) => props.onPlusButtonClick(e, tableData.id)}><i className="fas fa-plus"></i></button>}
                                                                        </>
                                                                        

                                                                case "expand":
                                                                    return <div className="expandDatas div-100-perc" >
                                                                        {tableData?.isExpanded ?
                                                                            <i className='fa fa-minus padding-top10' /> : <i className='fa fa-plus padding-top10' />}
                                                                    </div>
                                                                default:
                                                                    if (tableData.needLink) {
                                                                        return <Link style={{ textDecoration: "none" }} data-testid={tableData.value + '-' + index} to='#' className={tableData.name === 'name' || tableData.name === 'patient_name' ? 'link-text-color' : ' pl-0'} onClick={() => props?.onLinkClick(tableData?.id, tableData?.name)}>
                                                                            <strong>
                                                                                {typeof (tableData.value) === 'string' ? tableData.value.toUpperCase() : tableData.value}
                                                                            </strong>
                                                                        </Link>
                                                                    }

                                                                    if (tableData.needChip) {
                                                                        if (tableData.summaryChip) {
                                                                            return <Chips type="summary"onClick={() => props?.onLinkClick(tableData?.id, tableData?.name)} />;
                                                                        } else if (tableData.processingChip) {
                                                                            return <Chips type="Processing" />;
                                                                        }
                                                                    }

                                                                    if (tableData.name == "status" && tableData?.commentButton == 'show') {
                                                                        return <>
                                                                            <div className="div-float-left padding-top5">{tableData.value}</div>
                                                                            <div className="div-float-right">
                                                                                <span type='button' onClick={() => props.onShowMessage(tableData.id)} className='iconSpan'>
                                                                                    <i className="las la-comment"></i>
                                                                                </span>
                                                                            </div>
                                                                        </>
                                                                    }
                                                                    
                                                                    if (( tableData.name === 'claim_status' || tableData.name === 'patient_notes' ) && tableData.type === 'string') {
                                                                        return <>
                                                                            <td key={indexValue} align={tableData.align} className={tableData.width + " " + hide}>
                                                                                {
                                                                                    <OverlayTrigger
                                                                                        placement={'left'}
                                                                                        overlay={
                                                                                            <Tooltip id={`tooltip-${indexValue}`}>
                                                                                                {tableData.value}
                                                                                            </Tooltip>
                                                                                        }
                                                                                    >
                                                                                        <span className="truncate-text">
                                                                                            {tableData.value} 
                                                                                        </span>
                                                                                    </OverlayTrigger>
                                                                                }
                                                                            </td>
                                                                        </>
                                                                    }

                                                                    if (tableData.name == "responsible") {
                                                                        return <>
                                                                            <div className="div-float-left padding-top5">
                                                                                {tableData?.value?.name?.split("||")[1] || tableData.value?.name?.split("||")[2] ?
                                                                                    <OverlayTrigger placement="right" delay={{ show: 250 }} rootClose={true} trigger='click' overlay={
                                                                                        <Tooltip id={index} style={{ opacity: 0.6, }}>
                                                                                            <div>
                                                                                                <strong> {tableData?.value?.name?.includes("||") ? 'Payer ID: ' + tableData?.value?.name?.split("||")[1]?.toUpperCase() : 'No Details'} </strong>
                                                                                                <br />
                                                                                                <strong> {tableData?.value?.name?.includes("||") ? 'Payer Ph: ' + tableData?.value?.name?.split("||")[2]?.toUpperCase() : 'No Details'} </strong>
                                                                                            </div>
                                                                                        </Tooltip>
                                                                                    } >
                                                                                        <span className="anchor-like-span">
                                                                                            {tableData.value.name?.split("||")[0].toUpperCase()}
                                                                                        </span>
                                                                                    </OverlayTrigger>
                                                                                    // Checking the if the index of this item includes in the selfPayPatientIndex List to display as 'Self-Pay / Insurance not Assgined'
                                                                                    : <span> {selfPayPatientIndex.includes(index) ? 'Patient' : tableData?.value?.name?.split("||")[0]?.toUpperCase()} </span>
                                                                                }
                                                                            </div>
                                                                        </>
                                                                    }
                                                                   
                                                                    if(tableData.value || tableData.value === 0 ){
                                                                      return tableData.value
                                                                    }

                                                            }
                                                        })()}
                                                    </td>
                                            })
                                        }
                                    </tr>
                                    {props?.rowExpansion && props.rowExpansion === "allRows" &&
                                        <tr className={item[0]?.isExpanded ? '' : "hidden"}>
                                            <td colSpan={props?.colSpanNum ? props.colSpanNum : 1}>
                                                {item[0].expandData.dataFrom === "interface" ? item[0].expandData.dataObject : ''}
                                            </td>
                                        </tr>}
                                </>
                        )
                    })
                }
            </tbody>
        </>
    )
}

export default TableBody