import React from "react";
import ArrowBackIos from "@material-ui/icons/ArrowBackIos";

/**
 * @name BackButton
 * @param {string} label  
 * @param {string} iconColor 
 * @param {string} iconFontSize 
 * @param {string} labelClassname
 * @returns jsx div with backarrow icon and its label  
 */

const BackArrowWithLabel = (props) => {
    const { label, labelClassname } = props;
    return (
        <div className={"back-arrow"}>
            <ArrowBackIos fontSize="small" /> <span className={labelClassname ? labelClassname : ""} >{label ? label : ""}</span>
        </div>
    );
};

export default BackArrowWithLabel;
