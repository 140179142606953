import React, { useState, useEffect, useContext, useRef } from "react";
import CalendarInput from "../../commons/input/CalendarInput";
import TextInput from "../../commons/input/input";
import TextAreaInput from "../../commons/textarea/textarea";
import { getStorage } from "../../../utilities/browserStorage";
import LoadingContext from "../../../container/loadingContext";
import {DEFAULT_PAGING_SIZE } from '../../../utilities/staticConfigs'
import service from "./service";
import { CPTSearchBox } from "./cptSearchBox";
import SelectInput from "../../commons/input/SelectInput";
import CustomizedSmallDialogs from "../../modalWindowComponent/CustomisedSmallDialog";
import SearchAndLoadSchedule from "../../customerSetup/feeScheduleManagement/SearchAndLoadSchedule";
import CurrencyInputs from "../../commons/input/CurrencyInput";
import i18n from "../../../utilities/i18n";
import { decimalConverter } from "../../../utilities/commonUtilities";
import { AsyncTypeahead } from 'react-bootstrap-typeahead';
import { OverlayTrigger, Tooltip,Form } from 'react-bootstrap'
import Label from '../../commons/Label';

const ACTIVE_STATUS = [
  { id: 1, name: "InActive" },
  { id: 2, name: "Active" },
];

const FEE_STANDARD = [
  { id: 1, name: "CUSTOM" },
  { id: 2, name: "STANDARD" },
];

const GENDER = [
  { id: 1, name: "Male" },
  { id: 2, name: "Female" },
  { id: 3, name: "Transgender" },
  { id: 4, name: "N/A" },
];
const YES_NO = [
  { id: 1, name: "Yes" },
  { id: 2, name: "No" },
  { id: 3, name: "NA" },
];
const CODE_STATUS = [
  { id: 1, name: "Publish" },
  { id: 2, name: "New" },
  { id: 3, name: "Revised" },
  { id: 4, name: "Deleted" },
];

export const CptAddForm = (props) => {
  const formRef = useRef();
  const [POSCodeList, setPOSCodeList] = useState([]);
  const [TOSCodeList, setTOSCodeList] = useState([]);
  const isAdminModule = getStorage("isAdminModule");
  const [pos, setPOS] = useState('');
  const [tos, setTOS] = useState('');
  const practicePK = getStorage("practice");
  const setShowLoadingBar = useContext(LoadingContext);
  const [showWarningAlert, setShowWarningAlert] = useState(false);
  const [feeStandard, setFeeStandard] = useState(2);
  const [scheduleYear, setScheduleYear] = useState("2022");
  const [scheduleState, setScheduleState] = useState();
  const [scheduleLocality, setScheduleLocality] = useState();
  const [addFields, setAddFields] = useState({
    id: null,
    gender_name: null,
    add_on_code_name: "",
    code_version_name: null,
    is_active_name: "",
    primary_procedure_name: "",
    modifier_exempt_name: "",
    applicable_codes: null,
    created_on: "",
    active: true,
    code_type: "",
    name: "",
    mod_26_tc: null,
    medium_description: "",
    short_description: "",
    full_description: "",
    global_days: "",
    non_facility_total: "",
    facility_total: "",
    multiple_modifier: "",
    bilateral_modifier: "",
    assistant_surgeon: "",
    co_surgeon: "",
    surgical_team: "",
    endoscopic_base_code: null,
    low_age: "",
    high_age: "",
    gender: null,
    primary_procedure: null,
    conscious_sedation: null,
    telemedicine: null,
    is_active: 2,
    effective_date: null,
    deletion_date: null,
    chapter: "",
    code_group: "",
    code_range: "",
    cpt_status: "",
    alternate_code_range: null,
    modifier_exempt: null,
    code_version: null,
    additional_notes: null,
    add_on_code: null,
    default_fee: decimalConverter(0,2),
    default_pos: null,
    default_units: 1,
    clia: "",
    modifier1: "",
    modifier2: "",
    modifier3: "",
    modifier4: "",
  });
  const [attr, setAttr] = useState({});
  const [reqAttr, setReqAttr] = useState({});
  const [modifierList1, setModifierList1] = useState([]);
  const [modifierList2, setModifierList2] = useState([]);
  const [modifierList3, setModifierList3] = useState([]);
  const [modifierList4, setModifierList4] = useState([]);
  const [modifiers, setModifiers] = useState({
    m1List: [],
    m1_id:"",
    m2List: [],
    m2_id: "",
    m3List: [],
    m3_id: "",
    m4List: [],
    m4_id: "",
  })

  useEffect(() => {
    getPOSCodesData(DEFAULT_PAGING_SIZE);
    getTOSCodesData();
    if (props.manualAdding) {
      setReqAttr({ required: true });
    } else {
      setAttr({ disabled: "disabled" });
    }
  }, []);

  useEffect(() => {
    setAddFields((prev) => {
      return { ...prev, active: !props.activeStatus };
    });
  }, [props.activeStatus]);

  const loadCptDetails = async (cptId) => {
    setShowLoadingBar(true);
    // used try catch while loading cpt details
    try {
      const result = await service.getCptCode("super-admin", cptId);
      setShowLoadingBar(false);

      const { data } = result;

      setAddFields((prev) => {
        return {
          ...prev,
          ...data,
        };
      });
    } catch (ex) {
      setShowLoadingBar(false);
    }
    setFeeStandard(2)
  };


  const submitData = () => {
    setShowLoadingBar(true);
    const formData = tranformData();

    const path = isAdminModule === "true" ? "super-admin" : "practice";
    service
      .addCPTCode(path, formData)
      .then((result) => {
        if (result.status === 200 || result.status === 201) {
          setShowLoadingBar(false);
          props.onSaveNewCPT("success", "CPT Added Successfully");
          props.closeModal(false);
        }
        else if (result.status === 400 || result.status === 404) {
          setShowLoadingBar(false);
          if (result.data.message) {
            if (result.data.message == "name_exists_in_practice") {
              props.onSaveNewCPT("error", "Sorry CPT already exist.");
            }
          }
          else {
            props.onSaveNewCPT("error", "Sorry, Unable to save CPT."); 
          }
          props.closeModal(false);
        } else {
          setShowLoadingBar(false);
          props.onSaveNewCPT("error", "Sorry, Unable to complete request.");
          props.closeModal(false);
        }
      })
      .catch(() => {
        setShowLoadingBar(false);
      });
  };

  const onSave = (e) => {
    e.preventDefault();
    if (addFields.deletion_date < addFields.effective_date) {
      return;
    }

    if (isAdminModule !== "true" && addFields.default_fee == 0) {
      setShowWarningAlert(true);
    } else {
      submitData();
    }
  };
  const onHandleChange = (e) => {
    let value = e.target.value;
    let name = e.target.name;
    setAddFields((prev) => {
      if (name == "default_fee") {
        let newValue = value.trim().replace(/[^0-9.]/g, '').replace(/(\.\d{2})\d*([^0-9]|$)/, '$1');
        return { ...prev, [name]: newValue };
      } else {
        return { ...prev, [name]: value };
      }
    });
  };

  const onSearchFound = (option) => {
    loadCptDetails(option.id);
  };

  const onClear =()=>{
 
    setAddFields({
      id: null,
      gender_name: null,
      add_on_code_name: "",
      code_version_name: null,
      is_active_name: "",
      primary_procedure_name: "",
      modifier_exempt_name: "",
      applicable_codes: null,
      created_on: "",
      active: true,
      code_type: "",
      name: "",
      mod_26_tc: null,
      medium_description: "",
      short_description: "",
      full_description: "",
      global_days: "",
      non_facility_total: "",
      facility_total: "",
      multiple_modifier: "",
      bilateral_modifier: "",
      assistant_surgeon: "",
      co_surgeon: "",
      surgical_team: "",
      endoscopic_base_code: null,
      low_age: "",
      high_age: "",
      gender: null,
      primary_procedure: null,
      conscious_sedation: null,
      telemedicine: null,
      is_active: 1,
      effective_date: null,
      deletion_date: null,
      chapter: "",
      code_group: "",
      code_range: "",
      cpt_status: "",
      alternate_code_range: null,
      modifier_exempt: null,
      code_version: null,
      additional_notes: "",
      add_on_code: null,
      default_fee: "0",
      default_pos: null,
      default_units: 1,
      clia: "",
      modifier1: "",
      modifier2: "",
      modifier3: "",
      modifier4: "",
    });
    setPOS('');
    setTOS('');
   setScheduleState();
   setScheduleLocality();
  }
  const formatDataForUsers = () => {
    //created commonData variable for common-items to be kept
    const commonData = {
      active: addFields.active,
      code_type: addFields.code_type,
      name: addFields.name,
      mod_26_tc: addFields.mod_26_tc,
      medium_description: addFields.medium_description,
      short_description: addFields.short_description,
      full_description: addFields.full_description,
      global_days: addFields.global_days,
      non_facility_total: addFields.non_facility_total,
      facility_total: addFields.facility_total,
      multiple_modifier: addFields.multiple_modifier,
      bilateral_modifier: addFields.bilateral_modifier,
      assistant_surgeon: addFields.assistant_surgeon,
      co_surgeon: addFields.co_surgeon,
      surgical_team: addFields.surgical_team,
      endoscopic_base_code: addFields.endoscopic_base_code,
      low_age: addFields.low_age,
      high_age: addFields.high_age,
      gender: addFields.gender,
      primary_procedure: addFields.primary_procedure,
      conscious_sedation: addFields.conscious_sedation,
      telemedicine: addFields.telemedicine,
      is_active: addFields.is_active,
      effective_date: addFields.effective_date,
      deletion_date: addFields.deletion_date,
      chapter: addFields.chapter,
      code_group: addFields.code_group,
      code_range: addFields.code_range,
      cpt_status: addFields.cpt_status,
      alternate_code_range: addFields.alternate_code_range,
      modifier_exempt: addFields.modifier_exempt,
      code_version: addFields.code_version,
      additional_notes: addFields.additional_notes,
      add_on_code: addFields.add_on_code,
      applicable_codes:
        addFields.applicable_codes === "" ? null : addFields.applicable_codes,
      master_cpt: addFields.id,
      practice: practicePK,
      fee_standard:feeStandard,
      year: scheduleYear,
      state: scheduleState,
      locality:scheduleLocality
    }

    if (isAdminModule === "true") {
      return {
        ...commonData
      }
    } else {
      return {
        ...commonData,
        default_fee: addFields.default_fee,
        default_units: addFields.default_units,
        clia_num: addFields.clia,
        default_modifier_1: modifiers.m1_id ? modifiers.m1_id  :"",
        default_modifier_2: modifiers.m2_id ? modifiers.m2_id : "",
        default_modifier_3: modifiers.m3_id ? modifiers.m3_id : "",
        default_modifier_4: modifiers.m4_id ? modifiers.m4_id : "",
        default_pos: pos,
        default_tos: tos,
      };
    }
  };

  const tranformData = () => {
    const data = formatDataForUsers();
    if (props.manualAdding) {
      delete data.master_cpt;
    }
    else {
      data.is_master = 1;
    }
    return data;
  };

  function getPOSCodesData(pageSize) {
    setShowLoadingBar(true);
    let pageNumber = 0
    const result = service.ListPOSCodes(pageSize, pageNumber);
    result.then(response => {
        if (response.data !== undefined) {
            let data = response.data;
            data.forEach((item) => {
                item.description = item.name + ' - ' + item.service_place_name
            })
            setPOSCodeList(data)
        }
        setShowLoadingBar(false);
    });
  }

  function getTOSCodesData() {
    setShowLoadingBar(true);
    const result = service.ListTOSCodes();
    result.then(response => {
      if (response.data?.length > 0) {
        setTOSCodeList(response.data)
      }
      setShowLoadingBar(false);
    })
  }

  function onHandleChangeEditDate(value, name) {
    const formattedDate = formatDate(value);
    const val = { [name]: formattedDate };

    if (formattedDate === "1970-01-01") {
        // Reset the state to null
        setAddFields((prev) => {
            return { ...prev, [name]: null};
        });
    } else {
        // Update the state with the selected date
        setAddFields((prev) => {
            return { ...prev, ...val };
        });
    }
}


  const formatDate = (date) => {
    var d = new Date(date),
      month = "" + (d.getMonth() + 1),
      day = "" + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;

    return [year, month, day].join("-");
  };

  const onLoadFee = (year, state, locality) => {
    if (addFields.name) {
      setShowLoadingBar(true);
      service
        .loadScheduleFee(year, state, locality, addFields.name)
        .then((response) => {
          setShowLoadingBar(false);
          if (response.status === 200) {
            if (response.data.results.length > 0)
              setAddFields((prev) => {
                return {
                  ...prev,
                  ["default_fee"]: response.data.results[0].procedure_rate,
                };
              });
            else
              setAddFields((prev) => {
                return { ...prev, ["default_fee"]: 0 };
              });
          } else throw Error(response.statusText);
        })
        .catch((err) => {
          setShowLoadingBar(false);
          props.showNotification("show", "error", err.message);
        });
    } else {
      props.showNotification("show", "error", "Select a procedure first");
    }
  };

  const onFeeStandardChangeHandler = (e) => {
    setFeeStandard(e.target.value)
  }
  const onPosodedChangeHandler = (e) => {
    setPOS(e.target.value)
  }

  const onTosodedChangeHandler = (e) => {
    setTOS(e.target.value)
  }
  useEffect(() => {
    if (feeStandard == 1) {
      service.loadCustomScheduleFee(addFields.name, practicePK).then((response) => {
        setShowLoadingBar(false);
        if (response.status === 200) {
          if (response.data.length > 0)
            setAddFields((prev) => {
              return {
                ...prev,
                ["default_fee"]: response.data[0].professional_fee,
              };
            });
          else
            setAddFields((prev) => {
              return { ...prev, ["default_fee"]: 0 };
            });
        } else throw Error(response.statusText);
      })
        .catch((err) => {
          setShowLoadingBar(false);
          props.showNotification("show", "error", err.message);
        });
    }
  }, [feeStandard])

  const onSearchModifiers1 = (query) => {
    if (typeof (query) == 'string') {
      let result = service.GetModifier(query);
      result.then((response) => {
        setModifierList1(response.data.results)
      })
    } else if (typeof (query) === 'object' && query.length) {
      let result = service.GetModifier(query[0].name);
      result.then((response) => {
        setModifierList1(response.data.results)
      })
    }
  }

  const onSearchModifiers2 = (query) => {
    if (typeof (query) == 'string') {
      let result = service.GetModifier(query);
      result.then((response) => {
        setModifierList2(response.data.results)
      })
    } else if (typeof (query) === 'object' && query.length) {
      let result = service.GetModifier(query[0].name);
      result.then((response) => {
        setModifierList2(response.data.results)
      })
    }
  }

  const onSearchModifiers3 = (query) => {
    if (typeof (query) == 'string') {
      let result = service.GetModifier(query);
      result.then((response) => {
        setModifierList3(response.data.results)
      })
    } else if (typeof (query) === 'object' && query.length) {
      let result = service.GetModifier(query[0].name);
      result.then((response) => {
        setModifierList3(response.data.results)
      })
    }
  }

  const onSearchModifiers4 = (query) => {
    if (typeof (query) == 'string') {
      let result = service.GetModifier(query);
      result.then((response) => {
        setModifierList4(response.data.results)
      })
    } else if (typeof (query) === 'object' && query.length) {
      let result = service.GetModifier(query[0].name);
      result.then((response) => {
        setModifierList4(response.data.results)
      })
    }
  }

  function validatingM1(e) {
    if (e) {
      onSearchModifiers1(e);
    }
  }

  function validatingM2(e) {
    if (e) {
      onSearchModifiers2(e);
    }
  }
  function validatingM3(e) {
    if (e) {
      onSearchModifiers3(e);
    }
  }
  function validatingM4(e) {
    if (e) {
      onSearchModifiers4(e);
    }
  }

  const onProcedureModifierHandleChange = (e, name) => {
    if (e.length > 0) {
      if (isItemExists(e[0].id)) {
        props.showNotification("show", "error", "Modifier already exist");
      } else {
        setModifiers({
          ...modifiers,
          [name]: e[0].id,
          [name.split('_')[0] + 'List']: e
        })
      }
    }
    else {
      setModifiers({
        ...modifiers,
        [name]: "",
        [name.split('_')[0] + 'List']: e
      })
    }
  };


  // Function to check if item exists
  const isItemExists = (item) => {
    for (const key in modifiers) {
      if (modifiers[key] === item) {
        return true;
      }
    }
    return false;
  };


  return (
    <div>
      <CustomizedSmallDialogs
        header={"Warning"}
        type="yes"
        showModal={showWarningAlert}
        setShowModalWindow={setShowWarningAlert}
        inactveItem={submitData}
      >
        {"Default Fee is '0'. Do you still want to proceed?"}
      </CustomizedSmallDialogs>
      <Form
        ref={formRef}
        id="form_dataEntry"
        autoComplete="off"
        onSubmit={(e) => onSave(e)}
        encType="multipart/form-data"
      >
        <div className="row">
          <div className="col-3 mb-4">
            {props.manualAdding ? (
              <TextInput
                type="text"
                name={"name"}
                value={addFields.name}
                onValueChange={onHandleChange}
                {...attr}
                {...reqAttr}
                label="Code Name"
              />
            ) : (
              <CPTSearchBox onSearchFound={onSearchFound} onClear={onClear} label={"CPT Name"} class_name={addFields.name ? addFields.name : "input-error"} />
            )}
          </div>
        </div>
        <div className="row">
          <div className="col-3">
            <TextInput
              type="text"
              name={"code_type"}
              value={addFields.code_type}
              onValueChange={onHandleChange}
              {...attr}
              label="Code Type"
            />
          </div>
          <div className="col-6">
            <TextAreaInput
              type="text"
              name={"medium_description"}
              value={addFields.medium_description}
              onValueChange={onHandleChange}
              {...attr}
              {...reqAttr}
              label="Procedure Description"
            />
          </div>
        </div>
        <div className="row">
          <div className="col-3">
            <TextInput
              type="text"
              name={"high_age"}
              value={addFields.high_age}
              onValueChange={onHandleChange}
              {...attr}
              label="High Age Limit"
            />
          </div>
          <div className="col-3">
            <TextInput
              type="text"
              name={"low_age"}
              value={addFields.low_age}
              onValueChange={onHandleChange}
              {...attr}
              label="Low Age Limit"
            />
          </div>
          <div className="col-3">
            <SelectInput
              data={GENDER}
              {...attr}
              name={"gender"}
              value={addFields.gender}
              onValueChange={onHandleChange}
              label={"Sex Specific"}
              selectOptionRemove={true}
              defaultValue={true}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-3">
            <CalendarInput
              name={"effective_date"}
              selected={addFields.effective_date}
              onValueChange={(e) => onHandleChangeEditDate(e, "effective_date")}
              label={i18n.t("dictionariesPages.dictCPTCode.effectiveDate")}
            />
          </div>
          <div className="col-3">
            <CalendarInput
              name={"deletion_date"}
              selected={addFields.deletion_date}
              minDate={addFields.effective_date}
              onValueChange={(e) => onHandleChangeEditDate(e, "deletion_date")}
              label={i18n.t("dictionariesPages.dictCPTCode.deletionDate")}

            />
          </div>
        </div>
        <div className="row">
          <div className="col-3">
            <SelectInput
              data={YES_NO}
              {...attr}
              name={"add_on_code"}
              value={addFields.add_on_code}
              onValueChange={onHandleChange}
              label={"Add-On-Code"}
              selectOptionRemove={true}
              defaultValue={true}
            />
          </div>
          <div className="col-6">
            <TextAreaInput
              type="text"
              name={"applicable_codes"}
              placeholder={"Add Codes Using commas(,). Eg: 1,2,3,"}
              value={addFields.applicable_codes}
              onValueChange={onHandleChange}
              {...attr}
              label="Applicable Primary Codes"
            />
          </div>
        </div>
        {isAdminModule === "true" ? (
          <div></div>
        ) : (

          <div >
            <div className="row col-3">
              <SelectInput
                required="required"
                data={FEE_STANDARD}
                name="fee_standard"
                selectOptionRemove={true}
                disabled={false}
                value={feeStandard}
                onValueChange={onFeeStandardChangeHandler}
                label={"Fee Standard"}
              />
            </div>
            {feeStandard === 1 ?
              <></> :
              <SearchAndLoadSchedule
                showNotification={props.showNotification}
                setShowLoadingBar={setShowLoadingBar}
                onSearch={onLoadFee}
                scheduleState={scheduleState}
                setScheduleState={setScheduleState}
                scheduleLocality={scheduleLocality}
                setScheduleLocality={setScheduleLocality}
                scheduleYear={scheduleYear}
                setScheduleYear={setScheduleYear}
               
              />}
            <div className="row">
              <div className="col-3">
                <CurrencyInputs
                  type="text"
                  name={"default_fee"}
                  value={addFields.default_fee}
                  onValueChange={onHandleChange}
                  label="Default Fee"
                />
              </div>
              <div className="col-3">
              <SelectInput data={POSCodeList} 
               label= {i18n.t('userPages.facilities.labelPOS')} 
                name=" default_pos"
                id=" default_pos"
                value={pos} 
                onValueChange={onPosodedChangeHandler}/>
                </div>
                <div className="col-3">
                  <SelectInput data={TOSCodeList}
                    label={i18n.t('userPages.facilities.labelTOS')}
                    name=" default_tos"
                    id=" default_tos"
                    value={tos}
                    onValueChange={onTosodedChangeHandler} />
                </div>
                <div className="col-3">
                <TextInput
                  type="text"
                  name={"default_units"}
                  value={addFields.default_units}
                  onValueChange={onHandleChange}
                  label="Default Units"
                />
              </div>
              </div>
              <div className="row">
                <div className="col-3">
                  <TextInput
                    type="text"
                    name={"clia"}
                    value={addFields.clia}
                    onValueChange={onHandleChange}
                    label="CLIA"
                  />
                </div>
              <div className="col-6">
                <div className="row">
                  <div className="col-3">
                      <Label label="Modifier 1" />
                      <div className="form-group" >
                        <AsyncTypeahead
                          id="basic-typeahead-single"
                          labelKey="name"
                          minLength={1}
                          style={{ backgroundColor: '#fff', borderRadius: 10 }}
                          onChange={(e) => onProcedureModifierHandleChange(e, "m1_id")}
                          options={modifierList1}
                          placeholder="M1"
                          onInputChange={validatingM1}
                          onSearch={onSearchModifiers1}
                          onMenuToggle={() => {
                            if (!modifiers?.m1List?.length) return;
                            const isItemAlreadyInList = modifierList1.some(item => item.name === modifiers.m1List[0].name);
                            if (!isItemAlreadyInList) {
                              onSearchModifiers1(modifiers.m1List)
                            }
                          }}
                          selected={modifiers.m1List}
                          renderMenuItemChildren={(option) => {
                            let description = option.description;
                            let isDescriptionTooLong = description.length > 40;
                            let shortDescription = isDescriptionTooLong ? `- ${description.substring(0, 40)}...` : `- ${description}`;
                            return (
                              <>
                                {isDescriptionTooLong ?
                                  <OverlayTrigger
                                    placement={'right'}
                                    delay={{ show: 250 }}
                                    overlay={<Tooltip id={`tooltip-right`} style={{ opacity: 0.5 }}>{description}</Tooltip>}
                                  >
                                    <div>
                                      <strong>{option.name}</strong> <span>{shortDescription}</span>
                                    </div>
                                  </OverlayTrigger>
                                  :
                                  <div>
                                    <strong>{option.name}</strong> <span>{shortDescription}</span>
                                  </div>
                                }
                              </>
                            );
                          }}
                        />
                      </div>
                  </div>
                  <div className="col-3">
                      <Label label="Modifier 2" />
                      <div className="form-group" >
                        <AsyncTypeahead
                          id="basic-typeahead-single"
                          labelKey="name"
                          minLength={1}
                          style={{ backgroundColor: '#fff', borderRadius: 10 }}
                          onChange={(e) => onProcedureModifierHandleChange(e, "m2_id")}
                          options={modifierList2}
                          placeholder="M2"
                          onInputChange={validatingM2}
                          onSearch={onSearchModifiers2}
                          onMenuToggle={() => {
                            if (!modifiers?.m2List?.length) return;
                            const isItemAlreadyInList = modifierList2.some(item => item.name === modifiers.m2List[0].name);
                            if (!isItemAlreadyInList) {
                              onSearchModifiers2(modifiers.m2List)
                            }
                          }}
                          selected={modifiers.m2List}
                          renderMenuItemChildren={(option) => {
                            let description = option.description;
                            let isDescriptionTooLong = description.length > 40;
                            let shortDescription = isDescriptionTooLong ? `- ${description.substring(0, 40)}...` : `- ${description}`;
                            return (
                              <>
                                {isDescriptionTooLong ?
                                  <OverlayTrigger
                                    placement={'right'}
                                    delay={{ show: 250 }}
                                    overlay={<Tooltip id={`tooltip-right`} style={{ opacity: 0.5 }}>{description}</Tooltip>}
                                  >
                                    <div>
                                      <strong>{option.name}</strong> <span>{shortDescription}</span>
                                    </div>
                                  </OverlayTrigger>
                                  :
                                  <div>
                                    <strong>{option.name}</strong> <span>{shortDescription}</span>
                                  </div>
                                }
                              </>
                            );
                          }}
                        />
                      </div>
                  </div>
                  <div className="col-3">
                      <Label label="Modifier 3" />
                      <div className="form-group" >
                        <AsyncTypeahead
                          id="basic-typeahead-single"
                          labelKey="name"
                          minLength={1}
                          style={{ backgroundColor: '#fff', borderRadius: 10 }}
                          onChange={(e) => onProcedureModifierHandleChange(e, "m3_id")}
                          options={modifierList3}
                          placeholder="M3"
                          onInputChange={validatingM3}
                          onSearch={onSearchModifiers3}
                          onMenuToggle={() => {
                            if (!modifiers?.m3List?.length) return;
                            const isItemAlreadyInList = modifierList3.some(item => item.name === modifiers.m3List[0].name);
                            if (!isItemAlreadyInList) {
                              onSearchModifiers3(modifiers.m3List)
                            }
                          }}
                          selected={modifiers.m3List}
                          renderMenuItemChildren={(option) => {
                            let description = option.description;
                            let isDescriptionTooLong = description.length > 40;
                            let shortDescription = isDescriptionTooLong ? `- ${description.substring(0, 40)}...` : `- ${description}`;
                            return (
                              <>
                                {isDescriptionTooLong ?
                                  <OverlayTrigger
                                    placement={'right'}
                                    delay={{ show: 250 }}
                                    overlay={<Tooltip id={`tooltip-right`} style={{ opacity: 0.5 }}>{description}</Tooltip>}
                                  >
                                    <div>
                                      <strong>{option.name}</strong> <span>{shortDescription}</span>
                                    </div>
                                  </OverlayTrigger>
                                  :
                                  <div>
                                    <strong>{option.name}</strong> <span>{shortDescription}</span>
                                  </div>
                                }
                              </>
                            );
                          }}
                        />
                      </div>
                  </div>
                  <div className="col-3">
                      <Label label="Modifier 4" />
                      <div className="form-group" >
                        <AsyncTypeahead
                          id="basic-typeahead-single"
                          labelKey="name"
                          minLength={1}
                          style={{ backgroundColor: '#fff', borderRadius: 10 }}
                          onChange={(e) => onProcedureModifierHandleChange(e, "m4_id")}
                          options={modifierList4}
                          placeholder="M4"
                          onInputChange={validatingM4}
                          onSearch={onSearchModifiers4}
                          onMenuToggle={() => {
                            if (!modifiers?.m4List?.length) return;
                            const isItemAlreadyInList = modifierList4.some(item => item.name === modifiers.m4List[0].name);
                            if (!isItemAlreadyInList) {
                              onSearchModifiers1(modifiers.m4List)
                            }
                          }}
                          selected={modifiers.m4List}
                          renderMenuItemChildren={(option) => {
                            let description = option.description;
                            let isDescriptionTooLong = description.length > 40;
                            let shortDescription = isDescriptionTooLong ? `- ${description.substring(0, 40)}...` : `- ${description}`;
                            return (
                              <>
                                {isDescriptionTooLong ?
                                  <OverlayTrigger
                                    placement={'right'}
                                    delay={{ show: 250 }}
                                    overlay={<Tooltip id={`tooltip-right`} style={{ opacity: 0.5 }}>{description}</Tooltip>}
                                  >
                                    <div>
                                      <strong>{option.name}</strong> <span>{shortDescription}</span>
                                    </div>
                                  </OverlayTrigger>
                                  :
                                  <div>
                                    <strong>{option.name}</strong> <span>{shortDescription}</span>
                                  </div>
                                }
                              </>
                            );
                          }}
                        />
                      </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
        <div className="row">
          <div className="col-3">
            <TextInput
              type="text"
              name={"mod_26_tc"}
              value={addFields.mod_26_tc}
              onValueChange={onHandleChange}
              label="Mode 26"
            />
          </div>
          <div className="col-3">
            <TextInput
              type="text"
              name={"non_facility_total"}
              value={addFields.non_facility_total}
              onValueChange={onHandleChange}
              label="RVU Transitioned Non-Facility Total"
            />
          </div>
          <div className="col-3">
            <TextInput
              type="text"
              name={"facility_total"}
              value={addFields.facility_total}
              onValueChange={onHandleChange}
              label="RVU Transitioned Facility Total"
            />
          </div>
          <div className="col-3">
            <TextInput
              type="text"
              name={"global_days"}
              value={addFields.global_days}
              onValueChange={onHandleChange}
              label="Global Days"
            />
          </div>
        </div>
        <div className="row">
          <div className="col-3">
            <TextInput
              type="text"
              name={"multiple_modifier"}
              value={addFields.multiple_modifier}
              onValueChange={onHandleChange}
              label="Multiple Modifier (Mod. 51)"
            />
          </div>
          <div className="col-3">
            <TextInput
              type="text"
              name={"bilateral_modifier"}
              value={addFields.bilateral_modifier}
              onValueChange={onHandleChange}
              label="Bilateral modifier (Mod. 50)"
            />
          </div>
          <div className="col-3">
            <TextInput
              type="text"
              name={"assistant_surgeon"}
              value={addFields.assistant_surgeon}
              onValueChange={onHandleChange}
              label="Assistant Surgeon (Mod. 80)"
            />
          </div>
          <div className="col-3">
            <TextInput
              type="text"
              name={"co_surgeon"}
              value={addFields.co_surgeon}
              onValueChange={onHandleChange}
              label="Co-Surgeon (Mod. 62)"
            />
          </div>
        </div>
        <div className="row">
          <div className="col-3">
            <TextInput
              type="text"
              name={"surgical_team"}
              value={addFields.surgical_team}
              onValueChange={onHandleChange}
              label="Surgical Team (Mod. 66)"
            />
          </div>
          <div className="col-3">
            <TextInput
              type="text"
              name={"endoscopic_base_code"}
              value={addFields.endoscopic_base_code}
              onValueChange={onHandleChange}
              label="Endoscopic Base Code"
            />
          </div>
        </div>
        <div className="row">
          <div className="col-3">
            <SelectInput
              data={YES_NO}
              name={"primary_procedure"}
              value={addFields.primary_procedure}
              onValueChange={onHandleChange}
              label={"Primary Procedure"}
              selectOptionRemove={true}
              defaultValue={true}
            />
          </div>
          <div className="col-3">
            <TextInput
              type="text"
              name={"conscious_sedation"}
              value={addFields.conscious_sedation}
              onValueChange={onHandleChange}
              label="Conscious Sedation"
            />
          </div>
          <div className="col-3">
            <TextInput
              type="text"
              name={"telemedicine"}
              value={addFields.telemedicine}
              onValueChange={onHandleChange}
              label="Telemedicine"
            />
          </div>
        </div>
        <div className="row">
          <div className="col-3">
            <TextInput
              type="text"
              name={"chapter"}
              value={addFields.chapter}
              onValueChange={onHandleChange}
              label="Chapter"
            />
          </div>
          <div className="col-3">
            <TextInput
              type="text"
              name={"code_group"}
              value={addFields.code_group}
              onValueChange={onHandleChange}
              label="Code Group"
            />
          </div>
          <div className="col-3">
            <TextInput
              type="text"
              name={"code_range"}
              value={addFields.code_range}
              onValueChange={onHandleChange}
              label="Code Range Sub-Group"
            />
          </div>
          <div className="col-3">
            <TextInput
              type="text"
              name={"cpt_status"}
              value={addFields.cpt_status}
              onValueChange={onHandleChange}
              label="CPT Status"
            />
          </div>
        </div>
        <div className="row">
          <div className="col-3">
            <TextInput
              type="text"
              name={"alternate_code_range"}
              value={addFields.alternate_code_range}
              onValueChange={onHandleChange}
              label="Alternate Code Range"
            />
          </div>
          <div className="col-3">
            <SelectInput
              data={YES_NO}
              name={"modifier_exempt"}
              value={addFields.modifier_exempt}
              onValueChange={onHandleChange}
              label={"Modifier 63 Except"}
              selectOptionRemove={true}
              defaultValue={true}
            />
          </div>
          <div className="col-3">
            <SelectInput
              data={CODE_STATUS}
              name={"code_version"}
              value={addFields.code_version}
              onValueChange={onHandleChange}
              label={"Code Version"}
              selectOptionRemove={true}
              defaultValue={true}
            />
          </div>
          <div className="col-3">
            <TextInput
              type="text"
              name={"additional_notes"}
              value={addFields.additional_notes}
              onValueChange={onHandleChange}
              label="Additional Notes"
            />
          </div>
        </div>
        <div className="row">
          <div className="col-3">
            <SelectInput
              data={ACTIVE_STATUS}
              name={"is_active"}
              value={addFields.is_active}
              onValueChange={onHandleChange}
              label={"Is Active"}
              selectOptionRemove={true}
              defaultValue={true}
            />
          </div>
        </div>
      </Form>
    </div>
  );
};
