import React from 'react';
const BootStrapCardWithHeader = ({ cardTitle, children, className, cardInnerHeight }) => {
    return (
        <div className={`card ${className}`}>
            <div className="card-header bg-blue">
                {cardTitle}
            </div>
            <div className={`card-body ${cardInnerHeight}`}>
                {children}
            </div>
        </div>
    )
}

export default BootStrapCardWithHeader;