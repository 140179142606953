import React,{ useEffect,useState } from "react";
import { commonTableBody } from "../../../utilities/commonUtilities";
import { useSelector,useDispatch } from "react-redux";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import { TAB1, TAB2 } from "../../../utilities/staticConfigs";
import { Box, Tab } from "@mui/material";
import Table from "../../commons/Table/Table";
import { PatientHistoryTableDataCPT, PatientHistoryTableDataICD } from "./patientHistoryTableData";
import { addCPTFrompatientHistoryTable, addDiganosisVariablePatientHistory, removeDiganosisVariablePatientHistory } from "../StateManagement/ClaimModuleSlice";
const PatientHistory = () => {
    const { patientHistoryICD, patientHistoryCPT, serviceLineDetails,claimPK } =
        useSelector((state) => state.claimManagement);
    
    const dispatch = useDispatch();
    const [activeTab, setActiveTab] = useState(TAB1);

    const [patientHistory, setPatientHistory] = useState([]);

    const onTabChange = (e, tab) => {
        setActiveTab(tab);
    };

    /**
     * managing patient history data here
     */

    useEffect(() => {
        if (patientHistoryCPT?.length > 0) {
            const rowArray = commonTableBody(
                patientHistoryCPT,
                PatientHistoryTableDataCPT.tableBodyData[0]
            );

            PatientHistoryTableDataCPT.tableBodyData = rowArray;
        }

        if (patientHistoryICD?.length > 0) {
            const rowArray = commonTableBody(
                patientHistoryICD,
                PatientHistoryTableDataICD.tableBodyData[0]
            );

            PatientHistoryTableDataICD.tableBodyData = rowArray;
        }

        setPatientHistory(patientHistoryICD);
    }, [patientHistoryCPT, patientHistoryICD]);

    /**
     * add new diagnosis variable from the history table
     * @param {*} e
     * @param {*} id
     * @param {*} item
     */

    const addDiagnosisFromPatientHistory = (e, id, item) => {
        dispatch(addDiganosisVariablePatientHistory({ item: item, claimPK: claimPK }));
    };

    /**
     * remove existing diagnosis variable from the history table
     * @param {*} e
     * @param {*} id
     * @param {*} item
     */

    const removeDiagnosisFromPatientHistory = (item) => {
        dispatch(removeDiganosisVariablePatientHistory({ item: item, claimPK: claimPK }))
    };

    /**
     * add new cpt from patient history
     * @param {*} e 
     * @param {*} id 
     * @param {*} item 
     */
    const addCPTFromPatientHistory = (e, id, item) => {
        dispatch(addCPTFrompatientHistoryTable({ item: item, claimPK: claimPK }));
    }

    return (
        <TabContext value={activeTab}>
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                <TabList
                    onChange={onTabChange}
                    aria-label="lab API tabs example"
                    style={{
                        borderBottom: "1px solid #E0E0E0",
                        marginTop: 20,
                    }}>
                    <Tab
                        label="ICD Patient History"
                        value={TAB1}
                        className="tab"
                    />
                    <Tab
                        label="CPT Patient History"
                        value={TAB2}
                        className="tab"
                    />
                </TabList>
            </Box>
            <TabPanel value={TAB1} className="searchBoxTab">
                <div style={{ marginTop: 20 }}>
                    <Table
                        tableObject={PatientHistoryTableDataICD}
                        list={patientHistory}
                        addDiagnosisFromPatientHistory={
                            addDiagnosisFromPatientHistory
                        }
                        removeDiagnosisFromPatientHistory={
                            removeDiagnosisFromPatientHistory
                        }
                        diagnosisVariable={serviceLineDetails}
                    />
                </div>
            </TabPanel>
            <TabPanel value={TAB2} className="searchBoxTab">
                <div style={{ marginTop: 20 }}>
                    <Table
                        tableObject={PatientHistoryTableDataCPT}
                        cptHistoryEnable={true}
                        procedureRows={serviceLineDetails?.procedures}
                        addCPTFromPatientHistory={addCPTFromPatientHistory}
                    />
                </div>
            </TabPanel>
        </TabContext>
    );
};


export default PatientHistory;