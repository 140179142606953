import React, { useEffect } from 'react'
import i18n from '../../../utilities/i18n';
import { makeStyles } from '@material-ui/core/styles';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';

import CCVisa from '../../../assets/images/visa.svg';
import CCMaster from '../../../assets/images/mastercard.svg';
import CCDiscover from '../../../assets/images/discover.svg';
import CCAmex from '../../../assets/images/amex.svg';
import Label from '../Label';

const ccImages = { 'CCVisa': CCVisa, 'CCMaster': CCMaster, 'CCDiscover': CCDiscover, 'CCAmex': CCAmex }

const useStyles = makeStyles((theme) => ({
    formControl: {
        width: "100%",
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
}));

const menuItemlistStyle = {
    overflow:"hidden",
    textOverflow:"ellipsis",
    whiteSpace:'nowrap',
    maxWidth:'350px'
}

function SelectInput(props) {
    const classes = useStyles();
    let value = props.value;
    const showInRed = props.showInRed;
    const menuItemClassName = props.menuItemClassName ? props.menuItemClassName : '';

    if (props.data && Array.isArray(props.data)) {
        value = props.data.find((element) => {
            if (element.pId && element.pId === props.value) {
                return element.pId
            } else if (element.id && element.id === props.value) {
                return element.id
            } else if ( element.abbreviation && element.abbreviation === props.value ) { // for US State selects to pass the state abbreviation as value
                return element.abbreviation
            }
        })
    }

    useEffect(() => {
        if (props.data && props.data.length === 1) {
            if (Array.isArray(props.data) && props.setValue) {
                props.setValue(props.data[0].id ? props.data[0].id : props.data[0].pId);
            }
        }
    }, [JSON.stringify(props.data)])

    let className = props.className ? props.className : ''; 
    let selectedValue = value === undefined ? '' : value?.pId ? value.pId : value?.id ? value?.id : props.defaultValues ? props.defaultValues : props.value ? props.value : '';


    function selectOnTab(e) {
        if(e.key === "Tab"){
            document.querySelector(`[data-value = "${e.target.dataset.value}"]`).click();
        }         
    }

    function onSelChange(e) {
        props.onValueChange(e);  
    }

    return (
        <div className="form-group">
            <Label label={props.label} labelClassName={props.labelClassName ? props.labelClassName : ""} />
            <FormControl className={classes.formControl}>
                <Select 
                    labelId={props.id ? props.id + '-label' : "demo-simple-select-label"}
                    aria-labelledby={props.label ?? "aria-label"}
                    id={props.id ? props.id : "demo-simple-select"}
                    data-testid={props.id ? `select-input-${props.id}` : `select-input` }
                    value={selectedValue}
                    style={{
                        border: '1px solid #ced4da', // Add the border style
                        borderRadius: '4px',
                        height: 36,// Add the border radius
                        backgroundColor: props.background ?? '#fff'
                    }}
                    name={props.name}
                    onChange={onSelChange} 
                    className={props.required ? (showInRed ? className+" input-error " : (props.value ? className+"" : className+" input-error")) : className+""}
                    tabIndex={props.tabIndex ?? ""}
                    required={props.required ?? ""}
                    disabled={props.disabled ?? ""}
                >
                    {!props.selectOptionRemove ? <MenuItem value={""}>{props.messageForNoRecords ?? i18n.t("commons.select")} </MenuItem> : ''}

                    {props.data && Array.isArray(props.data) && props.data.map((item, index) => {
                        switch (true) {
                            case props.showCreditCardIcons:
                                // If showCreditCardIcons prop is true, return MenuItem with credit card icon
                                return (
                                    <MenuItem key={index} onKeyDown={(e) => selectOnTab(e)} value={(item.pId ? item.pId : item.id)} className={menuItemClassName} tabIndex={1}>
                                        {<ListItemIcon><img src={ccImages[item.icon]} alt={''} className="height-22 padding-right5" /> <span className="height-22"> {item.description ? item.description : item.pName ? item.pName : item.name ? item.name : item.full_name}</span></ListItemIcon>}
                                    </MenuItem>
                                );
                            case props.listItem == 'US_StateList':
                                // If listItem prop is 'US_StateList', return MenuItem with state abbreviation
                                return (
                                    <MenuItem key={index} onKeyDown={(e) => selectOnTab(e)} value={item.abbreviation}>
                                        {item.name + ' (' + item.abbreviation + ')'}
                                    </MenuItem>
                                );
                            case !props.needUniqueOptions:
                                // If needUniqueOptions prop is false, return MenuItem with item description or name
                                return (
                                    <MenuItem key={index} onKeyDown={(e) => selectOnTab(e)} value={(item.pId ? item.pId : item.id)} className={menuItemClassName} tabIndex={1}>
                                        <div style={menuItemlistStyle}>
                                            {props.includeId && item.name + " - "}
                                            {item.description ? item.description : item.name ? item.name : item.pName ? item.pName : item.full_name}
                                        </div>
                                    </MenuItem>
                                );
                            case props.needUniqueOptions && ((props.value && props.value == item.id) || (!item.uniqueId || parseInt(item.uniqueId, 10) == 0)):
                                // If needUniqueOptions prop is true and item is not selected or has no uniqueId, return MenuItem with item description or name
                                return (
                                    <MenuItem key={index} onKeyDown={(e) => selectOnTab(e)} value={(item.pId ? item.pId : item.id)} tabIndex={1}>
                                        {item.description ? item.description : item.pName ? item.pName : item.name ? item.name : item.full_name}
                                    </MenuItem>
                                );
                            case props.needUniqueOptions && item.uniqueId:
                                // If needUniqueOptions prop is true and item is already selected, return nothing
                                return ''
                            default:
                                // If none of the cases match, return nothing
                                return null;
                        }
                    })
                    }
                </Select>
                <i className='la la-sort-down positionSvgOfMaterialMultiSelelct' style={{ marginBottom: '3px' }} />
            </FormControl>
        </div>
    )
}

export default SelectInput;
