import React, { useState, useEffect, useContext, useCallback } from "react";
import LoadingContext from "../../../container/loadingContext";
import { getStorage } from "../../../utilities/browserStorage";
import "./reportStyles.css";
import service from "../service";
import CustomDropdownButton from "./CustomDropdownButton";
import { Menu } from "@material-ui/icons";
import RecentReports from "../ReportViewer/RecentReports";
import CustomizedSmallDialogs from "../../modalWindowComponent/CustomisedSmallDialog";
import { useDispatch } from "react-redux";
import { setReports } from "../../../store/reports/builder/allReportListSlice";
import ReportSelectorModal from "./ReportSelectorModal";

const ReportBuilder = () => {
  // Global State
  const dispatch = useDispatch();
  //--------- localStrage data -------------
  const practicePK = getStorage("practice");
  const setShowLoadingBar = useContext(LoadingContext);
  const [showAllModel, setShowAllModel] = useState(false);

  const formatData = (responseData) => {
    let tempData = [];
    responseData.forEach((element) => {
      tempData.push({ ...element, isOpen: false });
    });
    return tempData;
  };
  const fetchReports = useCallback(() => {
    setShowLoadingBar(true);
    service
      .getSavedReports(practicePK)
      .then((response) => {
        setShowLoadingBar(false);
        if (response.status === 200 || response.status === 201) {
          const data = response.data.data;
          dispatch(setReports({ reports: formatData(data) }));
        }
      })
      .catch(() => {
        setShowLoadingBar(false);
      });
  }, []);

  useEffect(() => {
    fetchReports(practicePK);
  }, []);

  const onShowClickHandler = () => {
    setShowAllModel(true);
  };
  return (
    <div className="col-12">
      <div className="box">
        <div className="box-content">
          <div className="row">
            <div className="col-2 mt-4">
              <CustomDropdownButton />
              <div className="clear-fix"></div>
              <button
                className="btn btn-primary-blue btn-block mt-2"
                onClick={onShowClickHandler}
              >
                <Menu /> Show All
              </button>
            </div>
            <div className="col-6">
              <RecentReports />
            </div>
          </div>
        </div>
        <CustomizedSmallDialogs
          showModal={showAllModel}
          type="alert"
          header={"All Reports"}
          setShowModalWindow={setShowAllModel}
          fullWidth={true}
          alertOK={() => {
            setShowAllModel(false);
          }}
        >
          <ReportSelectorModal />
        </CustomizedSmallDialogs>
      </div>
    </div>
  );
};

export default ReportBuilder;
