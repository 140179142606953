import React, { useState, useEffect, useContext } from 'react';
import { Form } from 'react-bootstrap';
import service from './service';
import { RefProviderGroupTableObject, RefProviderGroupTableBodyOnly } from './RefProviderGroupTable';
import LoadingContext from '../../../container/loadingContext';

import i18n from '../../../utilities/i18n';
import { getStorage } from '../../../utilities/browserStorage';
import { checkPermission } from '../../../utilities/commonUtilities';
import { ADD_SUCCESS, ADD_ERROR, UPDATE_SUCCESS, DELETE_SUCCESS } from '../../../utilities/labelConfigs'; 
import { permission_key_values_practice_dictionaries, super_admin_privileges } from '../../../utilities/permissions';
import { ROUTE_PRACTICE_DICTIONARIES, PAGING_END_INDEX, DEFAULT_PAGING_SIZE } from '../../../utilities/staticConfigs';

import Notify from '../../commons/notify';
import Table from '../../commons/Table/Table'; 
import Pagination from '../../commons/pagination';
import TextInput from '../../commons/input/input';
import BackArrowWithLabel from "../../commons/Back"; 

import CustomizedDialogs from '../../modalWindowComponent/CustomizedDialogs'
import CustomizedSmallDialogs from '../../modalWindowComponent/CustomisedSmallDialog';
import CommonButton from '../../commons/Buttons';

const ProviderGoupList = (props) => {
    const practicePK = getStorage("practice");
    const [customerPK, setCustomerPK] = useState('');
    const [showModalWindow, setShowModalWindow] = useState(false);
    const [showDeleteModalWindow, setShowDeleteModalWindow] = useState(false);
    const [providerGoupType, setProviderGoupType] = useState('');
    const [deleteProviderGoupName, setDeleteProviderGoupName] = useState('');
    const [deleteProviderGoupId, setDeleteProviderGoupId] = useState('');
    const [editProviderGoupId, setEditProviderGoupId] = useState('');
    const [providerGoupTypeList, setProviderGoupTypeList] = useState([]);
    const [form_error, setFormError] = useState({ 'providerGoupType': '' });
    const [editForm, setEditForm] = useState(false);
    const [header, setHeader] = useState('');
    const [permission, setPermission] = useState(false);

    //start ==== Alert message properties
    const [showNotify, setShowNotify] = useState('hide');
    const [notifyDescription, setNotifyDescription] = useState('');
    const [notifyType, setNotifyType] = useState('success');
    const setShowLoadingBar = useContext(LoadingContext);

    function showNotifyWindow(action, type, desc, age = 3000) {
        if (action == 'show') {
            setTimeout(() => {
                setShowNotify('hide');
            }, age)
        }
        setShowNotify(action);
        setNotifyType(type);
        setNotifyDescription(desc);
    }
    //End ====

    //Pagination start
    const [totalPage, setTotalPage] = useState(1);
    const [activePage, setActivePage] = useState(1);
    const [startIndex, setStartIndex] = useState(0);
    const [endIndex, setEndIndex] = useState(PAGING_END_INDEX);

    function onPagePrevious() {
        let previousPage = startIndex + 1 - PAGING_END_INDEX;
        setActivePage(previousPage);

        if (startIndex !== 0) {
            setStartIndex(startIndex - PAGING_END_INDEX);
            setEndIndex(endIndex - PAGING_END_INDEX);
        }
        getProviderGoupData(DEFAULT_PAGING_SIZE, previousPage);
    }

    function onPageUp(e) {
        let page = Number(e.target.id)
        setActivePage(page);
        getProviderGoupData(DEFAULT_PAGING_SIZE, page);
    }

    function onPageNext() {
        let nextPage = startIndex + 1 + PAGING_END_INDEX;
        if (endIndex === totalPage || totalPage <= PAGING_END_INDEX) {
            setActivePage(nextPage);
            setStartIndex(startIndex);
            setStartIndex(endIndex);
        } else {
            setActivePage(nextPage);
            setStartIndex(startIndex + PAGING_END_INDEX);
            setEndIndex(endIndex + PAGING_END_INDEX);
        }
        getProviderGoupData(DEFAULT_PAGING_SIZE, nextPage);
    }
    //Pagination ends

    function addNew() {
        setHeader(i18n.t("dictionariesPages.dictProviderGoupType.addHeader"));
        setShowModalWindow(true);
    }

    function onHide() {
        setEditForm(false);
    }

    function onDeleteHide() {
        setDeleteProviderGoupName('');
        setDeleteProviderGoupId('');
        setShowDeleteModalWindow(false);
    }

    /**
     * calling refferring group list api here
     * @param {*} pageSize 
     * @param {*} page 
     */
    function getProviderGoupData(pageSize,pageNum) {
        setShowLoadingBar(true);
        const result = service.ListProviderGoupTypes(pageSize, pageNum, practicePK);
        result.then(async response => {
            if (response?.data?.results?.length) {
                setTotalPage(Math.ceil(response.data.count / response.data.page_size));
            }
            setShowLoadingBar(false);
            let rowArray = await getRowData(response.data.results)
            if(rowArray.length > 0) {
                RefProviderGroupTableObject.tableBodyData = rowArray;
            }
            else {
                RefProviderGroupTableObject.tableBodyData = RefProviderGroupTableBodyOnly;
            }
            setProviderGoupTypeList(response.data.results);

        });
    }
    
    /**
     * making table format for listing refferring group list
     * @param {*} data 
     */
    function getRowData(data){
        let anArray = [];
        let rowArray = [];
        let newData = Array(data.length).fill(JSON.parse(JSON.stringify(RefProviderGroupTableObject.tableBodyData[0])));
        newData.map((row, rowIndex) => {
            row.map((col) => {
                let colObject = {};
                colObject = {
                    ...col, 'value': data[rowIndex][col.name],
                    "id": data[rowIndex].id,
                }
                anArray.push(colObject)
            })
            rowArray.push(anArray)
            anArray = [];
        });

        return rowArray;
    }

    useEffect(() => {
        getProviderGoupData(DEFAULT_PAGING_SIZE, activePage);
        getCustomerOfPrcatice()
        setPermission(checkPermission(permission_key_values_practice_dictionaries.practice_dictionaries_sub_module_modify,
            permission_key_values_practice_dictionaries.practice_dictionaries_sub_module_add,
            super_admin_privileges.super_admin_full_privilege))
    }, []);

    function getCustomerOfPrcatice() {
        setShowLoadingBar(true);
        const result = service.GetCustomerOfPrcatice(practicePK);
        result.then(response => {
            setShowLoadingBar(false);
            setCustomerPK(response.data.customer_profile_id);
        });
    }

    function dropDownFunction(id, name) {
        if (!permission) {
            showNotifyWindow('show', 'error', i18n.t('errorMessages.permission_error'));
            return;
        }
        if (name.toLowerCase() == 'edit') {
            onEditProviderGoupType(Number(id));
        } else if (name.toLowerCase() == 'delete') {
            onDeleteProviderGoupType(Number(id));
        }
    }

    function onEditProviderGoupType(providerGoupId) {
        setHeader(i18n.t("dictionariesPages.dictProviderGoupType.editHeader"));
        setShowLoadingBar(true);
        const data = service.GetProviderGoupType(providerGoupId);
        data.then(response => {
            setShowLoadingBar(false);
            setShowModalWindow(true);
            setProviderGoupType(response.data.name);
            setEditForm(true);
            setEditProviderGoupId(providerGoupId);
        });
    }

    function onDeleteProviderGoupType(providerGoupId) {
        providerGoupTypeList.map((item) => {
            if (item.id == parseInt(providerGoupId)) {
                setDeleteProviderGoupName(item.name);
                setDeleteProviderGoupId(item.id);
                setShowDeleteModalWindow(true);
            }
        });
    }

    function onDeleteAction() {
        setShowLoadingBar(true);
        const data = service.DeleteProviderGoupType(deleteProviderGoupId);
        data.then(() => {
            setShowLoadingBar(false);
            setDeleteProviderGoupName('');
            setDeleteProviderGoupId('');
            setShowDeleteModalWindow(false);
            showNotifyWindow('show', 'success', DELETE_SUCCESS);
            getProviderGoupData(DEFAULT_PAGING_SIZE, activePage);
        });
    }

    function resetForm() {
        setProviderGoupType('');
        setEditForm(false);
        setFormError({
            ...form_error,
            ['providerGoupType']: ''
        });

    }
    function onSaveFormData(e) {
        e.preventDefault();
        setFormError({
            ...form_error,
            ['providerGoupType']: !providerGoupType.trim() ? 'error' : ''
        });
        if(providerGoupType) {
            setShowLoadingBar(true);
            let result = null;
            //calling update and add api with condition if edit form is true update api called else add new refferring group
            result=editForm ? service.UpdateProviderGoupType(editProviderGoupId, { name: providerGoupType, customer: customerPK, practice: practicePK }):
            service.AddProviderGoupType({ name: providerGoupType, customer: customerPK, practice: practicePK });
            result.then(response => {
                setShowLoadingBar(false);
                if (response.status == 201 || response.status == 200) {
                    if (response.status == 201)
                        showNotifyWindow('show', 'success', ADD_SUCCESS);
                    else showNotifyWindow('show', 'success', UPDATE_SUCCESS);
                    getProviderGoupData(DEFAULT_PAGING_SIZE, activePage);
                    resetForm();
                    setShowModalWindow(false);
                } else if (response.status === 400 && response.data.record_already_exists !== undefined) {
                    showNotifyWindow('show', 'error', i18n.t('errorMessages.record_exists'));
                } else {
                    showNotifyWindow('show', 'error', ADD_ERROR);
                }
            });
        }
    }
    function onHandleChange(e) {
        const { name } = e.target;
        let value = e.target.value;
        let trimmedValue = '';
        if (e.target.type === "text") {
            trimmedValue = value;
        }
        if (!trimmedValue.trim()) {
            setFormError({
                ...form_error,
                [name]: 'error'
            });
        } else {
            setFormError({
                ...form_error,
                [name]: ''
            });
        }
        setProviderGoupType(trimmedValue);
    }

   
    function backToDictionaryPage() {
        props.history.push(ROUTE_PRACTICE_DICTIONARIES)
    }

    return (
        <React.Fragment>
            <Notify showNotify={showNotify} setShowNotify={setShowNotify} notifyDescription={notifyDescription} setNotifyType={setNotifyType} setNotifyDescription={setNotifyDescription} notifyType={notifyType} />
            <div className="col-md-8">
                <div className="box box-content-white">
                    <div className="box-head ">
                        <div className={'dataTables_filter'}>
                            <div className="link dictionaries-back" onClick={backToDictionaryPage}>
                                <BackArrowWithLabel label={i18n.t("dictionariesPages.gotoDictionaries")} /> 
                            </div>
                        </div>
                        <div className="alignRight margin-bottom3">
                            { permission &&
                            <CommonButton  variant="contained" onClick={addNew} label={i18n.t("buttons.addNew")} />
                            } 
                        </div>
                    </div>
                    <div className="box-content">
                        <div className="table-responsive">
                            <Table tableObject={RefProviderGroupTableObject} dropDownFunction={dropDownFunction} />
                        </div>
                        <div className='mt-2 mb-2'>
                            <Pagination totalPage={totalPage} activePage={activePage} startIndex={startIndex} endIndex={endIndex} onPagePrevious={onPagePrevious} onPageUp={onPageUp} onPageNext={onPageNext} />
                        </div>
                    </div>
                </div>
            </div>
            <CustomizedDialogs showModal={showModalWindow} type="save" header={header} setShowModalWindow={setShowModalWindow} resetForm={resetForm} onHide={onHide}>
                <Form id="form_dataEntry" onSubmit={(e) => onSaveFormData(e)} encType="multipart/form-data">
                    <Form.Group>
                        <div className="row">
                            <div className="col-md-12">
                                <div className="form-group padding-top15">
                                    <TextInput type="text" id="providerGoupType" name="providerGoupType" label={i18n.t('dictionariesPages.dictProviderGoupType.lblProviderGoupType')} onValueChange={onHandleChange} className={form_error.providerGoupType ? 'input-error' : ""} value={providerGoupType} />
                                </div>
                            </div>
                        </div>
                    </Form.Group>
                </Form>
            </CustomizedDialogs>  
            <CustomizedSmallDialogs showModal={showDeleteModalWindow} header={i18n.t("commons.confirmDelete")} type="delete" deleteItem={onDeleteAction} resetForm={resetForm} onHide={onDeleteHide} setShowModalWindow={setShowDeleteModalWindow}>
                {i18n.t("dictionariesPages.dictProviderGoupType.deletConfirmation") + "'" + deleteProviderGoupName + "'?"}
            </CustomizedSmallDialogs>  
        </React.Fragment>
    )
}
export default ProviderGoupList