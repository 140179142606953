import React, { useState, useEffect, useContext } from "react"
import { Form } from 'react-bootstrap';
import i18n from "../../../utilities/i18n"
import CalendarInput from "../../commons/input/CalendarInput"
import TextInput from "../../commons/input/input"
import SelectInput from "../../commons/input/SelectInput"
import TextAreaInput from "../../commons/textarea/textarea"
import LoadingContext from '../../../container/loadingContext';
import service from './service';
import { getStorage } from "../../../utilities/browserStorage";


const ACTIVE_STATUS = [{ "id": 1, "name": "InActive" }, { "id": 2, "name": "Active" },];
const GENDER = [{ "id": 1, "name": "Male" }, { "id": 2, "name": "Female" }, { "id": 3, "name": "Transgender" }, { "id": 4, "name": "N/A" }];
const CODE_STATUS = [{ "id": 1, "name": "Publish" }, { "id": 2, "name": "New" }, { "id": 3, "name": "Revised" }, { "id": 4, "name": "Deleted" }];



export const IDC10Edit = (props) => {
    const isAdminModule = getStorage('isAdminModule');
    const practicePK = getStorage("practice");
    const setShowLoadingBar = useContext(LoadingContext);
    const [checked, setChecked] = React.useState(true);
    const [attr,setAttr]=useState({});
    const [reqAttr,setReqAttr]=useState({});
    const [mIcd,setMIcd]=useState(false);


    const [editIcd10, setEditIcd10] = useState({
        "id": null,
        "gender_name": "",
        "code_status_name": "",
        "is_active_name": "",
        "active": true,
        "name": "",
        "short_description": "",
        "full_description": "",
        "gender": null,
        "low_age": "",
        "high_age": "",
        "code_status": null,
        "sub_category": "",
        "is_active": null,
        "include_notes": "",
        "exclude_notes1": "",
        "exclude_notes2": "",
        "code_type": "",
        "chapter": "",
        "icd_group": "",
        "code_range": "",
        "icd_status": "",
        "alternate_code_range": "",
        "effective_date": null,
        "deletion_date": null,
        "master_icd": null,
        "practice": null
    });

    function onSaveFormData(e) {
        e.preventDefault();
        if(props.isEdit){
            const data=transformedFormData();
            setShowLoadingBar(true);
            const path=isAdminModule==='true'?'super-admin':'practice';
            service.UpdateICD10(props.pk, data,path)
                .then(() => {
                    setShowLoadingBar(false);
                    props.closeWindow(false);
                    props.onFormSubmit(" '"+data.name+"' Updated Successfully");
                })
                .catch(() => {
                    setShowLoadingBar(false);
                });
        }else{
            const data=transformedFormData();
            setShowLoadingBar(true);
            service.AddICD10sToPractice(data)
                .then(() => {
                    setShowLoadingBar(false);
                    props.onFormSubmit(" '"+data.name+"' Added Successfully");
                    props.closeWindow(false);
                })
                .catch(() => {
                    setShowLoadingBar(false);
                });

        }
    }
      

    /**
     * To get ICD data to Edit
     */
    const getIcdDetails = () => {
        setShowLoadingBar(true);
        const path = isAdminModule === 'true' ? 'super-admin' : 'practice';
      
        service.GetICDCodeData(props.pk, path)
          .then((result) => {
            setShowLoadingBar(false);
      
            if (result.status === 200) {
                const data = result.data;
              const updatedIcd = {
                id: data.id,
                gender_name: data.gender_name,
                code_status_name: data.code_status_name,
                is_active_name: data.is_active_name,
                active: data.active,
                name: data.name,
                short_description: data.short_description,
                full_description: data.full_description,
                gender: data.gender,
                low_age: data.low_age,
                high_age: data.high_age,
                code_status: data.code_status,
                sub_category: data.sub_category,
                is_active: data.is_active,
                include_notes: data.include_notes,
                exclude_notes1: data.exclude_notes1,
                exclude_notes2: data.exclude_notes2,
                code_type: data.code_type,
                chapter: data.chapter,
                icd_group: data.icd_group,
                code_range: data.code_range,
                icd_status: data.icd_status,
                alternate_code_range: data.alternate_code_range,
                effective_date: data.effective_date,
                deletion_date: data.deletion_date,
                master_icd: data.master_icd,
                practice: data.practice
              };
      
              setEditIcd10((prev) => ({ ...prev, ...updatedIcd }));
              setChecked(data.active);
              props.setActiveStatus(!data.active);
      
              if (data.master_icd === null) {
                setMIcd(true);
              }
            }
          })
            .catch(() => { });
      
        if (isAdminModule === 'false') {
          setAttr({ disabled: 'disabled' });
        }
      };
      

    useEffect(() => {
        if(props.isEdit){
            getIcdDetails();
        }else{
            setReqAttr({'required':true});
        }
        
    }, []);
    useEffect(() => {
        setEditIcd10((prev) => {
            return { ...prev, 'active': checked }
        });
    }, [checked]);

    useEffect(()=>{
        setEditIcd10((prev)=>{
            return {...prev,'active':!props.activeStatus}
        });
    },[props.activeStatus])

    function onHandleChangeEdit(e) {
        let value = e.target.value;
        let name = e.target.name;
        setEditIcd10((prev) => {
            return { ...prev, [name]: value }
        });
    }

    function onHandleChangeEditDate(value, name) {
        if (!value || value === "none" || value == "Invalid Date") {
            setEditIcd10((prev) => {
                return { ...prev, [name]: null }
            });
        } else {
            setEditIcd10((prev) => {
                return { ...prev, [name]: formatDate(value) }
            });
        }
    }

    const formatDate=(date)=> {
        var d = new Date(date),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear();
    
        if (month.length < 2) 
            month = '0' + month;
        if (day.length < 2) 
            day = '0' + day;
    
        return [year, month, day].join('-');
    }



    const transformedFormData=()=>{
        const data={...editIcd10};
        if(props.isEdit){
            delete data.gender_name;
            delete data.code_status_name;
            delete data.is_active_name;
            return data;
        }else{
            data.practice=practicePK;
            delete data.master_icd;
            return data;
        }
    };
    return (
        <Form id="form_dataEntry" onSubmit={(e) => onSaveFormData(e)} encType="multipart/form-data" autoComplete="off">

            <div className="">
                <div className="">
                    <div className="row">
                        <div className="col-3">
                            <TextInput  id="ICDCode" {...(mIcd?{}:attr)} {...reqAttr} type="text" name={'name'} value={editIcd10.name} onValueChange={onHandleChangeEdit} label={i18n.t("dictionariesPages.dictICD10.icd10Edit.icd_code")} />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-6">
                            <TextAreaInput placeholder="ICD Code Description" {...(mIcd?{}:attr)} {...reqAttr} type="text" name={'short_description'} value={editIcd10.short_description} onValueChange={onHandleChangeEdit} label={i18n.t("dictionariesPages.dictICD10.icd10Edit.icd_description")} />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-3">
                            <TextInput
                                // {...(mIcd ? {} : attr)}
                                id="low_age" type="text"
                                name={'low_age'}
                                value={editIcd10.low_age}
                                onValueChange={onHandleChangeEdit}
                                label={i18n.t("dictionariesPages.dictICD10.icd10Edit.low_Age_limit")} />
                        </div>
                        <div className="col-3">
                            <TextInput
                                // {...(mIcd ? {} : attr)}
                                id="high_age" type="text"
                                name={'high_age'}
                                value={editIcd10.high_age}
                                onValueChange={onHandleChangeEdit}
                                label={i18n.t("dictionariesPages.dictICD10.icd10Edit.high_Age_limit")} />
                        </div>
                        <div className="col-3">
                            
                            <SelectInput data={GENDER}
                                // {...(mIcd ? {} : attr)}
                                id="gender" name={'gender'}
                                value={editIcd10.gender}
                                onValueChange={onHandleChangeEdit}
                                label={i18n.t("dictionariesPages.dictICD10.icd10Edit.sex_specific")} selectOptionRemove={true} defaultValue={true} />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-3">
                            <CalendarInput
                                // {...(mIcd ? {} : attr)}
                                id="effective_date"
                                selected={editIcd10.effective_date}
                                onValueChange={(e) => onHandleChangeEditDate(e, 'effective_date')}
                                label={i18n.t("dictionariesPages.dictICD10.icd10Edit.effective_date")} />
                        </div>
                        <div className="col-3">
                            <CalendarInput
                                // {...(mIcd ? {} : attr)}
                                id="deletion_date"
                                selected={editIcd10.deletion_date}
                                onValueChange={(e) => onHandleChangeEditDate(e, 'deletion_date')}
                                label={i18n.t("dictionariesPages.dictICD10.icd10Edit.deletion_date")} />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-3">
                            <SelectInput data={CODE_STATUS}
                                id="code_status"
                                // {...(mIcd ? {} : attr)}
                                name={'code_status'} value={editIcd10.code_status}
                                onValueChange={onHandleChangeEdit}
                                label={i18n.t("dictionariesPages.dictICD10.icd10Edit.code_status")} selectOptionRemove={true} defaultValue={true} />
                        </div>
                        <div className="col-3">
                            <TextInput
                                // {...(mIcd ? {} : attr)}
                                id="sub_category" name={'sub_category'}
                                value={editIcd10.sub_category}
                                onValueChange={onHandleChangeEdit}
                                label={i18n.t("dictionariesPages.dictICD10.icd10Edit.sub_category")} />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-6">
                            <TextAreaInput
                                // {...(mIcd ? {} : attr)}
                                id='include_notes' type="text"
                                name={'include_notes'}
                                value={editIcd10.include_notes}
                                onValueChange={onHandleChangeEdit}
                                label={i18n.t("dictionariesPages.dictICD10.icd10Edit.include_notes")} />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-6">
                            <TextAreaInput
                                // {...(mIcd ? {} : attr)}
                                id="exclude_notes1"
                                type="text"
                                name={'exclude_notes1'}
                                value={editIcd10.exclude_notes1}
                                onValueChange={onHandleChangeEdit}
                                label={i18n.t("dictionariesPages.dictICD10.icd10Edit.exclude_notes1")} />
                        </div>
                        <div className="col-6">
                            <TextAreaInput
                                // {...(mIcd ? {} : attr)}
                                id="exclude_notes2" type="text"
                                name={'exclude_notes2'}
                                value={editIcd10.exclude_notes2}
                                onValueChange={onHandleChangeEdit}
                                label={i18n.t("dictionariesPages.dictICD10.icd10Edit.exclude_notes2")} />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-3">
                            <TextInput
                                // {...(mIcd ? {} : attr)}
                                id="code_type"
                                name={'code_type'}
                                value={editIcd10.code_type}
                                onValueChange={onHandleChangeEdit}
                                label={i18n.t("dictionariesPages.dictICD10.icd10Edit.code_type")} />
                        </div>
                        <div className="col-3">
                            <TextInput
                                // {...(mIcd ? {} : attr)}
                                id="chapter" type="text"
                                name={'chapter'}
                                value={editIcd10.chapter}
                                onValueChange={onHandleChangeEdit}
                                label={i18n.t("dictionariesPages.dictICD10.icd10Edit.chapter")} />
                        </div>
                        <div className="col-3">
                            <TextInput
                                // {...(mIcd ? {} : attr)}
                                id="icd_group" type="text"
                                name={'icd_group'}
                                value={editIcd10.icd_group}
                                onValueChange={onHandleChangeEdit}
                                label={i18n.t("dictionariesPages.dictICD10.icd10Edit.icd_group")} />
                        </div>
                        <div className="col-3">
                            <TextInput
                                // {...(mIcd ? {} : attr)}
                                id="code_range"
                                type="text"
                                name={'code_range'}
                                value={editIcd10.code_range}
                                onValueChange={onHandleChangeEdit}
                                label={i18n.t("dictionariesPages.dictICD10.icd10Edit.code_range_sub_group")} />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-3">
                            <TextInput
                                // {...(mIcd ? {} : attr)}
                                id="icd_status" type="text"
                                name={'icd_status'}
                                value={editIcd10.icd_status}
                                onValueChange={onHandleChangeEdit}
                                label={i18n.t("dictionariesPages.dictICD10.icd10Edit.icd_status")} />
                        </div>
                        <div className="col-3">
                            <TextInput
                                // {...(mIcd ? {} : attr)}
                                id="alternate_code_range"
                                type="text" name={'alternate_code_range'}
                                value={editIcd10.alternate_code_range}
                                onValueChange={onHandleChangeEdit}
                                label={i18n.t("dictionariesPages.dictICD10.icd10Edit.alternate_code_range")} />
                        </div>
                        <div className="col-3">
                        <SelectInput data={ACTIVE_STATUS} id="is_active"  name={'is_active'} value={editIcd10.is_active} onValueChange={onHandleChangeEdit} label={'Is Active'} selectOptionRemove={true} defaultValue={true} />    
                        </div>
                        <div className="col-3">
                        </div>
                    </div>
                </div>
            </div>
        </Form>


    )
}