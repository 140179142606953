import i18n from "../../../utilities/i18n"

const TableHeadData = [
    {
        "name": i18n.t('dictionariesPages.paymentSourceCode.name'),
        "type": "string",
        "width": "medium",
        "sort": false,
    },
    {
        "name": i18n.t('dictionariesPages.paymentSourceCode.short_code'),
        "type": "string",
        "width": "small",
        "sort": false,
    },
    {
        "id": "",
        "name": i18n.t('dictionariesPages.dictClaimStatus.isDefault'),
        "value": "",
        "type": "string",
        "align": "center",
        "width": "small",
    },
    {
        "name": i18n.t("dictionariesPages.action"),
        "type": "button",
        "width": "action_column",
        "sort": false,
    }
]

const TableBodyData = [
    [
        {
            "id": "",
            "name": "name",
            "value": "",
            "type": "string",
            "align": "center",
            "width": "medium",
        },
        {
            "id": "",
            "name": "short_code",
            "value": "",
            "type": "string",
            "align": "left",
            "width": "small",
        },
        {
            "name": "isDefault",
            "type": "string",
            "width": "small",
            "sort": false,
        },
        {
            "id": "",
            "name": "action",
            "type": "button",
            "align": "center",
            "width": "action_column",
            "dropdown": [
                {
                    "name": "Edit"
                },
                {
                    "name": "Delete"
                },
            ]
        },
    ]
]

export const PaymentSourceTableData = {
    "name": "payment-source-master-table",
    "isResize": false,
    "isFixedHead": false,
    "tableHeadings": TableHeadData,
    "tableBodyData": TableBodyData
}