import React from 'react';
import './style.css';
import i18n from '../../../utilities/i18n';
import PasswordManagement from './passwordManagement';


const ForceResetPassword = () => {
return (
        <div>
        	<div className="center-div white-bg">
		        <PasswordManagement type="force-reset" header={i18n.t('changePassword.header.title')}/>
            </div>
        </div>
)
}

export default ForceResetPassword