import React from 'react';
import { currencyFormat } from '../../../utilities/commonUtilities';

const TableComponent = (props) => {

    function onClickExpand1(tabId) {
        const mainDatas = props.mainDatas.map((mainData) => {
            const shouldExpand = Number(tabId) === Number(mainData[0]);
            const isKeptExpanded = props.keepExpandedState;
            const isExpanded = shouldExpand ? !mainData[mainData.length - 2] : false;

            if(shouldExpand){
                mainData[mainData.length - 2] = !mainData[mainData.length - 2];
            } else if (!isKeptExpanded || isExpanded) {
                mainData[mainData.length - 2] = isExpanded;
            }

            return mainData;
        });

        props.setMainDatas(mainDatas);
    }  

    function onClickExpand2(tabId, mainDataIndex, subDataIndex, mainLength, subLength) {
        const { mainDatas, keepExpandedState, setMainDatas } = props;

        const expandedDatas = mainDatas.map((mainData, index) => {
            if (index !== mainDataIndex) {
                return mainData;
            }

            const subData = mainData[mainLength][subDataIndex];
            if (subData[0] === Number(tabId)) {
                subData[subLength + 1] = !subData[subLength + 1];
            } else if (!keepExpandedState) {
                subData[subLength + 1] = false;
            }

            return mainData;
        });

        setMainDatas(expandedDatas);
    }



    return (
        <React.Fragment>
            <table id="data-table" className="table" style={{ width: "100%" }}>
                <thead>
                    <tr style={{ backgroundColor: '#E0E0E0' }}>
                        <th className={'width-30 textCenter'}></th>
                        {props.mainHeadings && props.mainHeadings.map((mainHeading, index) => {
                            return <th key={index} className={mainHeading.thClass ? mainHeading.thClass : "textCenter"}>{mainHeading.value}</th>
                        })}
                    </tr>
                </thead>
                <tbody>
                    {props.mainDatas && props.mainDatas.map((mainData, index) => {
                        return (<React.Fragment key={mainData[0]}>
                            <tr key={mainData[0]} className={mainData[mainData.length - 1]} style={{ backgroundColor: '#FFDBDB' }}>
                                <td onMouseDown={() => onClickExpand1(mainData[0], mainData[mainData.length - 3])} className="fontSize18 expandDatas textRight">{mainData[mainData.length - 2] ? "-" : "+"}</td>
                                {mainData && mainData.slice(1, mainData.length - 3).map((itemm, mainDataIndex) => {
                                    return (
                                        <td key={mainDataIndex} className={props.mainHeadings[mainDataIndex].tdClass}>{props.mainHeadings[mainDataIndex].tdClass.includes("amount") ? currencyFormat(itemm) : itemm}</td>
                                    )
                                })}
                            </tr>
                            {mainData[mainData.length - 2] && props.subHedaings1 && mainData[mainData.length - 3] &&
                                <tr style={{ backgroundColor: 'white' }}>
                                    <td ></td>
                                    <td colSpan="11" className="innerColSpan">
                                        <table className="innerTable">
                                            <thead>
                                                <tr style={{ backgroundColor: '#E0E0E0' }}>
                                                    <th className={'width-30 textCenter'}></th>
                                                    {props.subHedaings1.map((subHedaing1, index1) => {
                                                        return (<th key={index1} className={subHedaing1.thClass ? subHedaing1.thClass : "textCenter"} colSpan={subHedaing1.value === '' ? 1 : 0}>{subHedaing1.value}</th>)
                                                    })}
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {mainData[mainData.length - 3].map((subData1, index1) => {
                                                    return (<React.Fragment key={subData1[0]}>
                                                        <tr key={subData1[0]} className={'fontSize14 ' + subData1[subData1.length - 1]} style={{ backgroundColor: '#FFDBDB' }}>
                                                            <td className="expandDatas fontSize18 textRight" onMouseDown={() => onClickExpand2(subData1[0], index, index1, mainData.length - 3, subData1.length - 3)}>{subData1[subData1.length - 2] ? "-" : "+"}</td>
                                                            {subData1 && subData1.slice(1, subData1.length - 3).map((itemm, index2) => {
                                                                return (
                                                                    <td key={index2} className={props.subHedaings1[index2].tdClass} >{props.subHedaings1[index2].tdClass.includes("amount") ? currencyFormat(itemm) : itemm}</td>
                                                                )
                                                            })}
                                                        </tr>
                                                        {subData1[subData1.length - 2] && props.subHedaings2 && subData1[subData1.length - 3] &&
                                                            <tr style={{ backgroundColor: '#ffff' }}>
                                                                <td></td>
                                                                <td colSpan="11" className="innerColSpan">
                                                                    <table className="innerTable">
                                                                        <thead>
                                                                            <tr style={{ backgroundColor: '#E0E0E0' }}>
                                                                                {
                                                                                    props.subHedaings2.map((item2, index2) => {
                                                                                        return (<th key={index2} className={item2.thClass ? item2.thClass : "textCenter"}>{item2.value}</th>)
                                                                                    })
                                                                                }
                                                                            </tr>
                                                                        </thead>
                                                                        <tbody>
                                                                            {subData1[subData1.length - 3].map((subData2) => {
                                                                                return (<React.Fragment key={subData2[0]}>
                                                                                    <tr key={subData2[0]}>
                                                                                        {subData2 && subData2.slice(1, subData2.length - 2).map((itemm, subDataIndex) => {
                                                                                            return (
                                                                                                <td key={subDataIndex} className={props.subHedaings2[subDataIndex].tdClass}>{props.subHedaings2[subDataIndex].tdClass.includes("amount") ? [currencyFormat(itemm)] : [itemm]}</td>
                                                                                            )
                                                                                        })}
                                                                                    </tr>
                                                                                </React.Fragment>)
                                                                            })}
                                                                            
                                                                        </tbody>
                                                                    </table>
                                                                </td>
                                                            </tr>
                                                        }
                                                    </React.Fragment>)
                                                })}
                                            </tbody>
                                        </table>
                                    </td>
                                </tr>
                            }</React.Fragment>)
                    })}
                </tbody>
            </table>
        </React.Fragment>
    );
};
export default TableComponent;