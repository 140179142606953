import React, { useState, useEffect, useContext } from "react";
import CalendarInput from "../../commons/input/CalendarInput";
import TextInput from "../../commons/input/input";
import TextAreaInput from "../../commons/textarea/textarea";
import { getStorage } from "../../../utilities/browserStorage";
import LoadingContext from "../../../container/loadingContext";
import service from "./service";
import SelectInput from "../../commons/input/SelectInput";
import { DEFAULT_PAGING_SIZE} from '../../../utilities/staticConfigs'
import SearchAndLoadSchedule from "../../customerSetup/feeScheduleManagement/SearchAndLoadSchedule";
import CustomizedSmallDialogs from "../../modalWindowComponent/CustomisedSmallDialog";
import i18n from "../../../utilities/i18n";
import { decimalConverter } from "../../../utilities/commonUtilities";
import { AsyncTypeahead } from 'react-bootstrap-typeahead';
import { OverlayTrigger, Tooltip, Form } from 'react-bootstrap'
import Label from '../../commons/Label';

const GENDER = [
  { id: 1, name: "Male" },
  { id: 2, name: "Female" },
  { id: 3, name: "Transgender" },
  { id: 4, name: "N/A" },
];
const ACTIVE_STATUS = [
  { id: 1, name: "InActive" },
  { id: 2, name: "Active" },
];
const FEE_STANDARD = [
  { id: 1, name: "CUSTOM" },
  { id: 2, name: "STANDARD" },
];
const YES_NO = [
  { id: 1, name: "Yes" },
  { id: 2, name: "No" },
  { id: 3, name: "NA" },
];
const CODE_STATUS = [
  { id: 1, name: "Publish" },
  { id: 2, name: "New" },
  { id: 3, name: "Revised" },
  { id: 4, name: "Deleted" },
];
const disabledAtr = { disabled: "disabled" };

export const CptEdit = (props) => {
  const [POSCodeList, setPOSCodeList] = useState([]);
  const [TOSCodeList, setTOSCodeList] = useState([]);
  const [pos, setPOS] = useState('');
  const [tos, setTOS] = useState('');
  const [scheduleState, setScheduleState] = useState();
  const [scheduleLocality, setScheduleLocality] = useState();
  const [scheduleYear, setScheduleYear] = useState("2022");
  const isAdminModule = getStorage("isAdminModule");
  const practicePK = getStorage("practice");
  const setShowLoadingBar = useContext(LoadingContext);
  const [attr, setAttr] = useState({});
  const [mCpt, setMCpt] = useState(false);
  const [showWarningAlert, setShowWarningAlert] = useState(false);
  const [modifierList1, setModifierList1] = useState([]);
  const [modifierList2, setModifierList2] = useState([]);
  const [modifierList3, setModifierList3] = useState([]);
  const [modifierList4, setModifierList4] = useState([]);
  const [modifiers, setModifiers] = useState({
    m1List: [],
    m1_id: "",
    m2List: [],
    m2_id: "",
    m3List: [],
    m3_id: "",
    m4List: [],
    m4_id: "",
  })
  const [cptEdit, setCptEdit] = useState({
    id: 0,
    gender_name: null,
    add_on_code_name: "",
    code_version_name: null,
    is_active_name: "",
    primary_procedure_name: "",
    modifier_exempt_name: "",
    applicable_codes: null,
    created_on: "",
    active: true,
    code_type: "",
    name: "",
    mod_26_tc: null,
    medium_description: "",
    short_description: "",
    full_description: "",
    global_days: "",
    non_facility_total: "",
    facility_total: "",
    multiple_modifier: "",
    bilateral_modifier: "",
    assistant_surgeon: "",
    co_surgeon: "",
    surgical_team: "",
    endoscopic_base_code: null,
    low_age: "",
    high_age: "",
    gender: null,
    primary_procedure: 1,
    conscious_sedation: null,
    telemedicine: null,
    is_active: 2,
    effective_date: "",
    deletion_date: null,
    chapter: "",
    code_group: "",
    code_range: "",
    cpt_status: "",
    alternate_code_range: null,
    modifier_exempt: 3,
    code_version: null,
    additional_notes: null,
    add_on_code: 3,
    default_fee: 0,
    default_pos: null,
    default_tos: null,
    default_units: "",
    clia_num: "",
    fee_standard:1,
  });

  useEffect(() => {
    getPOSCodesData(DEFAULT_PAGING_SIZE);
    getTOSCodesData();
    loadCptDetails(props.pk);
    if (isAdminModule === "false") {
      setAttr(disabledAtr);
    }
  }, []);
  useEffect(() => {
    setCptEdit((prev) => {
      return { ...prev, active: !props.activeStatus };
    });
    
  }, [props.activeStatus]);

  const loadCptDetails = async (cptId) => {
    setShowLoadingBar(true);
    const path = isAdminModule === "true" ? "super-admin" : "practice";
    try {
      const result = await service.getCptCode(path, cptId);
      setShowLoadingBar(false);

      const { data } = result;
      setCptEdit((prev) => ({
        ...prev,
        ...data,default_fee:decimalConverter(data.default_fee,2),
      }));
      setModifiers({
        m1_id: data.m1_id ? data.m1_id : "",
        m1List: data.m1List ? data.m1List : [],
        m2_id: data.m1_id ? data.m2_id : "",
        m2List: data.m2List ? data.m2List : [],
        m3_id: data.m3_id ? data.m3_id : "",
        m3List: data.m3List ? data.m3List : [],
        m4_id: data.m4_id ? data.m4_id : "",
        m4List: data.m4List ? data.m4List : [],
      })
    const yearString = data?.year?.toString();
    setScheduleYear(yearString);
    setScheduleState(data?.state);
    setScheduleLocality(data?.locality);
      props.setEditStatus(!data.active);
      setPOS(data.default_pos)
      setTOS(data.default_tos)
  
      if (data.master_cpt == null) {
        setMCpt(true);
      }
    } catch (ex) {
      setShowLoadingBar(false);
    }
  };
  


  const onLoadFee = (year, state, locality) => {
    if (cptEdit.name) {
      setShowLoadingBar(true);
      service
        .loadScheduleFee(year, state, locality, cptEdit.name)
        .then((response) => {
          setShowLoadingBar(false);
          if (response.status === 200) {
            if (response.data.results.length > 0)
            setCptEdit((prev) => {
                return {
                  ...prev,
                  ["default_fee"]: response.data.results[0].procedure_rate,
                };
              });
            else
            setCptEdit((prev) => {
                return { ...prev, ["default_fee"]: 0 };
              });
          } else throw Error(response.statusText);
        })
        .catch((err) => {
          setShowLoadingBar(false);
          props.showNotification("show", "error", err.message);
        });
    } else {
      props.showNotification("show", "error", "Select a procedure first");
    }
  };

  const onSave = (e) => {
    e.preventDefault();
    if (cptEdit.deletion_date instanceof Date && cptEdit.deletion_date < cptEdit.effective_date) {
      return;
    }
    
    if (isAdminModule !== "true" && cptEdit.default_fee == 0) {
      setShowWarningAlert(true);
    } else {
      submitData();
    }
  };

  const onHandleChange = (e) => {
    let value = e.target.value;
    let name = e.target.name;
    if (name == "default_fee") {
      value = e.target.value.trim().replace(/[^0-9.]/g, '').replace(/(\.\d{2})\d*([^0-9]|$)/, '$1');
    }
    setCptEdit((prev) => {
      return { ...prev, [name]: value };
    });
  };
  

  const tranformData = () => {
    const commonData = {
      active: cptEdit.active,
      code_type: cptEdit.code_type,
      name: cptEdit.name,
      mod_26_tc: cptEdit.mod_26_tc,
      medium_description: cptEdit.medium_description,
      short_description: cptEdit.short_description,
      full_description: cptEdit.full_description,
      global_days: cptEdit.global_days,
      non_facility_total: cptEdit.non_facility_total,
      facility_total: cptEdit.facility_total,
      multiple_modifier: cptEdit.multiple_modifier,
      bilateral_modifier: cptEdit.bilateral_modifier,
      assistant_surgeon: cptEdit.assistant_surgeon,
      co_surgeon: cptEdit.co_surgeon,
      surgical_team: cptEdit.surgical_team,
      endoscopic_base_code: cptEdit.endoscopic_base_code,
      low_age: cptEdit.low_age,
      high_age: cptEdit.high_age,
      gender: cptEdit.gender,
      primary_procedure: cptEdit.primary_procedure,
      conscious_sedation: cptEdit.conscious_sedation,
      telemedicine: cptEdit.telemedicine,
      is_active: cptEdit.is_active,
      effective_date: cptEdit.effective_date,
      deletion_date: cptEdit.deletion_date,
      chapter: cptEdit.chapter,
      code_group: cptEdit.code_group,
      code_range: cptEdit.code_range,
      cpt_status: cptEdit.cpt_status,
      alternate_code_range: cptEdit.alternate_code_range,
      modifier_exempt: cptEdit.modifier_exempt,
      code_version: cptEdit.code_version,
      additional_notes: cptEdit.additional_notes,
      add_on_code: cptEdit.add_on_code,
      applicable_codes: cptEdit.applicable_codes,
      master_cpt: cptEdit.master_cpt,
      practice: practicePK,
      fee_standard:cptEdit.fee_standard,
      year: scheduleYear,
      state: scheduleState,
      locality:scheduleLocality
    };
  
    if (isAdminModule === "true") {
      return {
        ...commonData,
      };
    } else {
      return {
        ...commonData,
        default_fee: cptEdit.default_fee,
        default_units: cptEdit.default_units,
        clia_num: cptEdit.clia_num,
        default_modifier_1: modifiers.m1_id ? modifiers.m1_id  : "",
        default_modifier_2: modifiers.m2_id ? modifiers.m2_id : "",
        default_modifier_3: modifiers.m3_id ? modifiers.m3_id : "",
        default_modifier_4: modifiers.m4_id ? modifiers.m4_id : "",
        default_pos: pos,
        default_tos: tos
      };
    }
  };
  

  function getPOSCodesData(pageSize) {
    setShowLoadingBar(true);
    let pageNumber = 0
    const result = service.ListPOSCodes(pageSize, pageNumber);
    result.then(response => {
        if (response.data !== undefined) {
            let data = response.data;
            data.forEach((item) => {
                item.description = item.name + ' - ' + item.service_place_name
            })
            setPOSCodeList(data)
        }
        setShowLoadingBar(false);
    });
  }

  function getTOSCodesData() {
    setShowLoadingBar(true);
    const result = service.ListTOSCodes();
    result.then(response => {
      if (response.data?.length > 0) {
        setTOSCodeList(response.data)
      }
      setShowLoadingBar(false);
    })
  }

const onPosodedChangeHandler = (e) => {
  setPOS(e.target.value)
}

  const onTosodedChangeHandler = (e) => {
    setTOS(e.target.value)
  }
  function onHandleChangeEditDate(value, name) {
    if (!value || value === "none" || value == "Invalid Date") {
      const val = { [name]: null };
      setCptEdit((prev) => {
        return { ...prev, ...val };
      });
    } else {
      const val = { [name]: formatDate(value) };
      setCptEdit((prev) => {
        return { ...prev, ...val };
      });
    }
  }

  const formatDate = (date) => {
    var d = new Date(date),
      month = "" + (d.getMonth() + 1),
      day = "" + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;

    return [year, month, day].join("-");
  };

  const submitData = () => {
    setShowLoadingBar(true);
    const formData = tranformData();
    const path = isAdminModule === "true" ? "super-admin" : "practice";
    service
      .UpdateCPTCode(path, props.pk, formData)
      .then((result) => {
        if (result.status == 200 || result.status === 201) {
          setShowLoadingBar(false);
          props.onSaveNewCPT("success", "CPT Updated Successfully");
          props.closeModal(false);
        } else {
          setShowLoadingBar(false);
          props.onSaveNewCPT("error", "Sorry, Unable to complete request.");
          props.closeModal(false);
        }
      })
      .catch(() => {
        setShowLoadingBar(false);
      });
  };

  const onSearchModifiers1 = (query) => {
    if (typeof (query) == 'string') {
      let result = service.GetModifier(query);
      result.then((response) => {
        setModifierList1(response.data.results)
      })
    } else if (typeof (query) === 'object' && query.length) {
      let result = service.GetModifier(query[0].name);
      result.then((response) => {
        setModifierList1(response.data.results)
      })
    }
  }

  const onSearchModifiers2 = (query) => {
    if (typeof (query) == 'string') {
      let result = service.GetModifier(query);
      result.then((response) => {
        setModifierList2(response.data.results)
      })
    } else if (typeof (query) === 'object' && query.length) {
      let result = service.GetModifier(query[0].name);
      result.then((response) => {
        setModifierList2(response.data.results)
      })
    }
  }

  const onSearchModifiers3 = (query) => {
    if (typeof (query) == 'string') {
      let result = service.GetModifier(query);
      result.then((response) => {
        setModifierList3(response.data.results)
      })
    } else if (typeof (query) === 'object' && query.length) {
      let result = service.GetModifier(query[0].name);
      result.then((response) => {
        setModifierList3(response.data.results)
      })
    }
  }

  const onSearchModifiers4 = (query) => {
    if (typeof (query) == 'string') {
      let result = service.GetModifier(query);
      result.then((response) => {
        setModifierList4(response.data.results)
      })
    } else if (typeof (query) === 'object' && query.length) {
      let result = service.GetModifier(query[0].name);
      result.then((response) => {
        setModifierList4(response.data.results)
      })
    }
  }

  function validatingM1(e) {
    if (e) {
      onSearchModifiers1(e);
    }
  }

  function validatingM2(e) {
    if (e) {
      onSearchModifiers2(e);
    }
  }
  function validatingM3(e) {
    if (e) {
      onSearchModifiers3(e);
    }
  }
  function validatingM4(e) {
    if (e) {
      onSearchModifiers4(e);
    }
  }

  const onProcedureModifierHandleChange = (e, name) => {
    if (e.length > 0) {
      if (isItemExists(e[0].id)) {
        props.showNotification("show", "error", "Modifier already exist");
      } else {
        setModifiers({
          ...modifiers,
          [name]: e[0].id,
          [name.split('_')[0] + 'List']: e
        })
      }
    }
    else {
      setModifiers({
        ...modifiers,
        [name]: "",
        [name.split('_')[0] + 'List']: e
      })
    }
  };

  // Function to check if item exists
  const isItemExists = (item) => {
    for (const key in modifiers) {
      if (modifiers[key] === item) {
        return true;
      }
    }
    return false;
  };

  return (
    <div>
      <CustomizedSmallDialogs
        header={"Warning"}
        type="yes"
        showModal={showWarningAlert}
        setShowModalWindow={setShowWarningAlert}
        inactveItem={submitData}
      >
        {"Default Fee is '0'. Do you still want to proceed?"}
      </CustomizedSmallDialogs>
      <Form
        id="form_dataEntry"
        autoComplete="off"
        onSubmit={(e) => onSave(e)}
        encType="multipart/form-data"
      >
        <div className="row">
          <div className="col-3">
            <TextInput
              type="text"
              name={"name"}
              value={cptEdit.name}
              onValueChange={onHandleChange}
              {...(mCpt ? {} : attr)}
              label="Code Name"
              id="code-name"
            />
          </div>
        </div>
        <div className="row">
          <div className="col-3">
            <TextInput
              type="text"
              name={"code_type"}
              value={cptEdit.code_type}
              onValueChange={onHandleChange}
              {...(mCpt ? {} : attr)}
              label="Code Type"
              id="code-type"
            />
          </div>
          <div className="col-6">
            <TextAreaInput
              type="text"
              name={"short_description"}
              value={cptEdit.medium_description}
              onValueChange={onHandleChange}
              {...(mCpt ? {} : attr)}
              label="Procedure Description"
              id="short_description"
            />
          </div>
        </div>
        <div className="row">
          <div className="col-3">
            <TextInput
              type="text"
              name={"high_age"}
              value={cptEdit.high_age}
              onValueChange={onHandleChange}
              // {...(mCpt ? {} : attr)}
              label="High Age Limit"
              id="high_age"
            />
          </div>
          <div className="col-3">
            <TextInput
              type="text"
              name={"low_age"}
              value={cptEdit.low_age}
              onValueChange={onHandleChange}
              // {...(mCpt ? {} : attr)}
              label="Low Age Limit"
              id="low_age"
            />
          </div>
          <div className="col-3">
            <SelectInput
              type="text"
              name={"gender"}
              value={cptEdit.gender}
              onValueChange={onHandleChange}
              selectOptionRemove={true}
              defaultValue={true}
              // {...(mCpt ? {} : attr)}
              label="Sex Specific"
              id="gender"
              data={GENDER}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-3">
            <CalendarInput
              name={"effective_date"}
              selected={cptEdit.effective_date}
              onValueChange={(e) => onHandleChangeEditDate(e, "effective_date")}
              // {...(mCpt ? {} : attr)}
              label={i18n.t("dictionariesPages.dictCPTCode.effectiveDate")}
              id="effective-date"
            />
          </div>
          <div className="col-3">
            <CalendarInput
              name={"deletion_date"}
              selected={cptEdit.deletion_date}
              minDate={cptEdit.effective_date && cptEdit.effective_date !== "" ? cptEdit.effective_date : null }
              onValueChange={(e) => onHandleChangeEditDate(e, "deletion_date")}
              // {...(mCpt ? {} : attr)}
              label={i18n.t("dictionariesPages.dictCPTCode.deletionDate")}
              id="deletion-date"
            />
          </div>
        </div>
        <div className="row">
          <div className="col-3">
            <SelectInput
              data={YES_NO}
              {...(mCpt ? {} : attr)}
              name={"add_on_code"}
              value={cptEdit.add_on_code}
              onValueChange={onHandleChange}
              label={"Add-On-Code"}
              selectOptionRemove={true}
              defaultValue={true}
              id="add_on_code"
            />
          </div>
          <div className="col-6">
            <TextAreaInput
              type="text"
              name={"applicable_codes"}
              value={cptEdit.applicable_codes}
              placeholder={"Add Codes Using commas(,). Eg: 1,2,3,"}
              onValueChange={onHandleChange}
              {...(mCpt ? {} : attr)}
              label="Applicable Primary Codes"
            />
          </div>
        </div>
        {isAdminModule === "true" ? (
          <div></div>
        ) : (
          <div>

<div className="row col-3">
              <SelectInput
                required="required"
                data={FEE_STANDARD}
                name="fee_standard"
                selectOptionRemove={true}
                disabled={false}
                value={cptEdit.fee_standard}
                onValueChange={onHandleChange}
                label={"Fee Standard"}
              />
            </div> 

            {cptEdit.fee_standard === 1 ?
              <></> :
              <SearchAndLoadSchedule
                showNotification={props.showNotification}
                setShowLoadingBar={setShowLoadingBar}
                onSearch={onLoadFee}
                scheduleState={scheduleState}
                setScheduleState={setScheduleState}
                scheduleLocality={scheduleLocality}
                setScheduleLocality={setScheduleLocality}
                scheduleYear={scheduleYear}
                setScheduleYear={setScheduleYear}
              />}
            <div className="row">
              <div className="col-3">
                <TextInput
                  type="text"
                  name={"default_fee"}
                  value={cptEdit.default_fee}
                  onValueChange={onHandleChange}
                  label="Default Fee"
                  id="default_fee"
                />
              </div>
              <div className="col-3">
              <SelectInput data={POSCodeList}   
          label={i18n.t('userPages.facilities.labelPOS')} 
          name=" default_pos"
           id=" default_pos"
           value={pos} 
           onValueChange={onPosodedChangeHandler}/>
                </div>
                <div className="col-3">
                  <SelectInput data={TOSCodeList}
                    label={i18n.t('userPages.facilities.labelTOS')}
                    name=" default_tos"
                    id=" default_tos"
                    value={tos}
                    onValueChange={onTosodedChangeHandler} />
                </div>
                <div className="col-3">
                <TextInput
                  type="text"
                  name={"default_units"}
                  value={cptEdit.default_units}
                  onValueChange={onHandleChange}
                  label="Default Units"
                  id="default_units"
                />
              </div>
              </div>
              <div className="row">
                <div className="col-3">
                  <TextInput
                    type="text"
                    name={"clia_num"}
                    value={cptEdit.clia_num}
                    onValueChange={onHandleChange}
                    label="CLIA"
                    id="clia"
                  />
                </div>
                <div className="col-6">
                  <div className="row">
                    <div className="col-3">
                      <Label label="Modifier 1" />
                      <div className="form-group" >
                        <AsyncTypeahead
                          id="basic-typeahead-single"
                          labelKey="name"
                          minLength={1}
                          style={{ backgroundColor: '#fff', borderRadius: 10 }}
                          onChange={(e) => onProcedureModifierHandleChange(e, "m1_id")}
                          options={modifierList1}
                          placeholder="M1"
                          onInputChange={validatingM1}
                          onSearch={onSearchModifiers1}
                          onMenuToggle={() => {
                            if (!modifiers?.m1List?.length) return;
                            const isItemAlreadyInList = modifierList1.some(item => item.name === modifiers.m1List[0].name);
                            if (!isItemAlreadyInList) {
                              onSearchModifiers1(modifiers.m1List)
                            }
                          }}
                          selected={modifiers.m1List}
                          renderMenuItemChildren={(option) => {
                            let description = option.description;
                            let isDescriptionTooLong = description.length > 40;
                            let shortDescription = isDescriptionTooLong ? `- ${description.substring(0, 40)}...` : `- ${description}`;
                            return (
                              <>
                                {isDescriptionTooLong ?
                                  <OverlayTrigger
                                    placement={'right'}
                                    delay={{ show: 250 }}
                                    overlay={<Tooltip id={`tooltip-right`} style={{ opacity: 0.5 }}>{description}</Tooltip>}
                                  >
                                    <div>
                                      <strong>{option.name}</strong> <span>{shortDescription}</span>
                                    </div>
                                  </OverlayTrigger>
                                  :
                                  <div>
                                    <strong>{option.name}</strong> <span>{shortDescription}</span>
                                  </div>
                                }
                              </>
                            );
                          }}
                        />
                      </div>
                    </div>
                    <div className="col-3">
                      <Label label="Modifier 2" />
                      <div className="form-group" >
                        <AsyncTypeahead
                          id="basic-typeahead-single"
                          labelKey="name"
                          minLength={1}
                          style={{ backgroundColor: '#fff', borderRadius: 10 }}
                          onChange={(e) => onProcedureModifierHandleChange(e, "m2_id")}
                          options={modifierList2}
                          placeholder="M2"
                          onInputChange={validatingM2}
                          onSearch={onSearchModifiers2}
                          onMenuToggle={() => {
                            if (!modifiers?.m2List?.length) return;
                            const isItemAlreadyInList = modifierList2.some(item => item.name === modifiers.m2List[0].name);
                            if (!isItemAlreadyInList) {
                              onSearchModifiers2(modifiers.m2List)
                            }
                          }}
                          selected={modifiers.m2List}
                          renderMenuItemChildren={(option) => {
                            let description = option.description;
                            let isDescriptionTooLong = description.length > 40;
                            let shortDescription = isDescriptionTooLong ? `- ${description.substring(0, 40)}...` : `- ${description}`;
                            return (
                              <>
                                {isDescriptionTooLong ?
                                  <OverlayTrigger
                                    placement={'right'}
                                    delay={{ show: 250 }}
                                    overlay={<Tooltip id={`tooltip-right`} style={{ opacity: 0.5 }}>{description}</Tooltip>}
                                  >
                                    <div>
                                      <strong>{option.name}</strong> <span>{shortDescription}</span>
                                    </div>
                                  </OverlayTrigger>
                                  :
                                  <div>
                                    <strong>{option.name}</strong> <span>{shortDescription}</span>
                                  </div>
                                }
                              </>
                            );
                          }}
                        />
                      </div>
                    </div>
                    <div className="col-3">
                      <Label label="Modifier 3" />
                      <div className="form-group" >
                        <AsyncTypeahead
                          id="basic-typeahead-single"
                          labelKey="name"
                          minLength={1}
                          style={{ backgroundColor: '#fff', borderRadius: 10 }}
                          onChange={(e) => onProcedureModifierHandleChange(e, "m3_id")}
                          options={modifierList3}
                          placeholder="M3"
                          onInputChange={validatingM3}
                          onSearch={onSearchModifiers3}
                          onMenuToggle={() => {
                            if (!modifiers?.m3List?.length) return;
                            const isItemAlreadyInList = modifierList3.some(item => item.name === modifiers.m3List[0].name);
                            if (!isItemAlreadyInList) {
                              onSearchModifiers3(modifiers.m3List)
                            }
                          }}
                          selected={modifiers.m3List}
                          renderMenuItemChildren={(option) => {
                            let description = option.description;
                            let isDescriptionTooLong = description.length > 40;
                            let shortDescription = isDescriptionTooLong ? `- ${description.substring(0, 40)}...` : `- ${description}`;
                            return (
                              <>
                                {isDescriptionTooLong ?
                                  <OverlayTrigger
                                    placement={'right'}
                                    delay={{ show: 250 }}
                                    overlay={<Tooltip id={`tooltip-right`} style={{ opacity: 0.5 }}>{description}</Tooltip>}
                                  >
                                    <div>
                                      <strong>{option.name}</strong> <span>{shortDescription}</span>
                                    </div>
                                  </OverlayTrigger>
                                  :
                                  <div>
                                    <strong>{option.name}</strong> <span>{shortDescription}</span>
                                  </div>
                                }
                              </>
                            );
                          }}
                        />
                      </div>
                    </div>
                    <div className="col-3">
                      <Label label="Modifier 4" />
                      <div className="form-group" >
                        <AsyncTypeahead
                          id="basic-typeahead-single"
                          labelKey="name"
                          minLength={1}
                          style={{ backgroundColor: '#fff', borderRadius: 10 }}
                          onChange={(e) => onProcedureModifierHandleChange(e, "m4_id")}
                          options={modifierList4}
                          placeholder="M4"
                          onInputChange={validatingM4}
                          onSearch={onSearchModifiers4}
                          onMenuToggle={() => {
                            if (!modifiers?.m4List?.length) return;
                            const isItemAlreadyInList = modifierList4.some(item => item.name === modifiers.m4List[0].name);
                            if (!isItemAlreadyInList) {
                              onSearchModifiers1(modifiers.m4List)
                            }
                          }}
                          selected={modifiers.m4List}
                          renderMenuItemChildren={(option) => {
                            let description = option.description;
                            let isDescriptionTooLong = description.length > 40;
                            let shortDescription = isDescriptionTooLong ? `- ${description.substring(0, 40)}...` : `- ${description}`;
                            return (
                              <>
                                {isDescriptionTooLong ?
                                  <OverlayTrigger
                                    placement={'right'}
                                    delay={{ show: 250 }}
                                    overlay={<Tooltip id={`tooltip-right`} style={{ opacity: 0.5 }}>{description}</Tooltip>}
                                  >
                                    <div>
                                      <strong>{option.name}</strong> <span>{shortDescription}</span>
                                    </div>
                                  </OverlayTrigger>
                                  :
                                  <div>
                                    <strong>{option.name}</strong> <span>{shortDescription}</span>
                                  </div>
                                }
                              </>
                            );
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
          </div>
        )}
        <div className="row">
          <div className="col-3">
            <TextInput
              type="text"
              name={"mod_26_tc"}
              value={cptEdit.mod_26_tc}
              onValueChange={onHandleChange}
              label="Mode 26"
              id="mode_26"
            />
          </div>
          <div className="col-3">
            <TextInput
              type="text"
              name={"non_facility_total"}
              value={cptEdit.non_facility_total}
              onValueChange={onHandleChange}
              label="RVU Transitioned Non-Facility Total"
              id="non_facility_total"
            />
          </div>
          <div className="col-3">
            <TextInput
              type="text"
              name={"facility_total"}
              value={cptEdit.facility_total}
              onValueChange={onHandleChange}
              label="RVU Transitioned Facility Total"
              id="facility_total"
            />
          </div>
          <div className="col-3">
            <TextInput
              type="text"
              name={"global_days"}
              value={cptEdit.global_days}
              onValueChange={onHandleChange}
              label="Global Days"
              id="global_days"
            />
          </div>
        </div>
        <div className="row">
          <div className="col-3">
            <TextInput
              type="text"
              name={"multiple_modifier"}
              value={cptEdit.multiple_modifier}
              onValueChange={onHandleChange}
              label="Multiple Modifier (Mod. 51)"
              id="multiple_modifier"
            />
          </div>
          <div className="col-3">
            <TextInput
              type="text"
              name={"bilateral_modifier"}
              value={cptEdit.bilateral_modifier}
              onValueChange={onHandleChange}
              label="Bilateral modifier (Mod. 50)"
              id="bilateral_modifier"
            />
          </div>
          <div className="col-3">
            <TextInput
              type="text"
              name={"assistant_surgeon"}
              value={cptEdit.assistant_surgeon}
              onValueChange={onHandleChange}
              label="Assistant Surgeon (Mod. 80)"
              id="assistant_surgeon"
            />
          </div>
          <div className="col-3">
            <TextInput
              type="text"
              name={"co_surgeon"}
              value={cptEdit.co_surgeon}
              onValueChange={onHandleChange}
              label="Co-Surgeon (Mod. 62)"
              id="co_surgeon"
            />
          </div>
        </div>
        <div className="row">
          <div className="col-3">
            <TextInput
              type="text"
              name={"surgical_team"}
              value={cptEdit.surgical_team}
              onValueChange={onHandleChange}
              label="Surgical Team (Mod. 66)"
              id="surgical_team"
            />
          </div>
          <div className="col-3">
            <TextInput
              type="text"
              name={"endoscopic_base_code"}
              value={cptEdit.endoscopic_base_code}
              onValueChange={onHandleChange}
              label="Endoscopic Base Code"
              id="endoscopic_base_code"
            />
          </div>
        </div>
        <div className="row">
          <div className="col-3">
            <SelectInput
              data={YES_NO}
              name={"primary_procedure"}
              value={cptEdit.primary_procedure}
              onValueChange={onHandleChange}
              label={"Primary Procedure"}
              selectOptionRemove={true}
              defaultValue={true}
              id="primary_procedure"
            />
          </div>
          <div className="col-3">
            <TextInput
              type="text"
              name={"conscious_sedation"}
              value={cptEdit.conscious_sedation}
              onValueChange={onHandleChange}
              label="Conscious Sedation"
              id="conscious_sedation"
            />
          </div>
          <div className="col-3">
            <TextInput
              type="text"
              name={"telemedicine"}
              value={cptEdit.telemedicine}
              onValueChange={onHandleChange}
              label="Telemedicine"
              id="telemedicine"
            />
          </div>
        </div>
        <div className="row">
          <div className="col-3">
            <TextInput
              type="text"
              name={"chapter"}
              value={cptEdit.chapter}
              onValueChange={onHandleChange}
              label="Chapter"
              id="chapter"
            />
          </div>
          <div className="col-3">
            <TextInput
              type="text"
              name={"code_group"}
              value={cptEdit.code_group}
              onValueChange={onHandleChange}
              label="Code Group"
              id="code_group"
            />
          </div>
          <div className="col-3">
            <TextInput
              type="text"
              name={"code_range"}
              value={cptEdit.code_range}
              onValueChange={onHandleChange}
              label="Code Range Sub-Group"
              id="code_range"
            />
          </div>
          <div className="col-3">
            <TextInput
              type="text"
              name={"cpt_status"}
              value={cptEdit.cpt_status}
              onValueChange={onHandleChange}
              label="CPT Status"
              id="cpt_status"
            />
          </div>
        </div>
        <div className="row">
          <div className="col-3">
            <TextInput
              type="text"
              name={"alternate_code_range"}
              value={cptEdit.alternate_code_range}
              onValueChange={onHandleChange}
              label="Alternate Code Range"
              id="alternate_code_range"
            />
          </div>
          <div className="col-3">
            <SelectInput
              data={YES_NO}
              name={"modifier_exempt"}
              value={cptEdit.modifier_exempt}
              onValueChange={onHandleChange}
              label={"Modifier 63 Except"}
              selectOptionRemove={true}
              defaultValue={true}
              id="modifier_exempt"
            />
          </div>
          <div className="col-3">
            <SelectInput
              data={CODE_STATUS}
              name={"code_version"}
              value={cptEdit.code_version}
              onValueChange={onHandleChange}
              label={"Code Version"}
              selectOptionRemove={true}
              defaultValue={true}
              id="code_version"
            />
          </div>
          <div className="col-3">
            <TextInput
              type="text"
              name={"additional_notes"}
              value={cptEdit.additional_notes}
              onValueChange={onHandleChange}
              label="Additional Notes"
              id="additional_notes"
            />
          </div>
        </div>
        <div className="row">
          <div className="col-3">
            <SelectInput
              data={ACTIVE_STATUS}
              name={"is_active"}
              value={cptEdit.is_active}
              onValueChange={onHandleChange}
              label={"Is Active"}
              selectOptionRemove={true}
              defaultValue={true}
              id="is_active"
            />
          </div>
        </div>
      </Form>
    </div>
  );
};
