import React from "react";
import {
  Collapse,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import { ExpandLess, ExpandMore, Folder } from "@material-ui/icons";
import { Droppable, Draggable } from "react-beautiful-dnd";
import BootStrapCardWithHeader from "./BootStrapCardWithHeader";
import { CropSquareOutlined } from "@mui/icons-material";
const ReportFieldCard = ({ categoryList, onChangeOpenStatus }) => {
  return (
    <Droppable droppableId={"dataBaseFields"}>
      {(provided) => (
        <div
          className="col-4"
          {...provided.droppableProps}
          ref={provided.innerRef}
        >
          <BootStrapCardWithHeader
            cardTitle={"Report Fields"}
            className={"mb-4"}
            cardInnerHeight={"height-100"}
          >
            <List dense={false} component={"nav"}>
              {categoryList.map((category, index) => (
                <div key={index}>
                  <ListItemButton
                    onClick={(e) => onChangeOpenStatus(e, category)}
                  >
                    <ListItemIcon>
                      <Folder />
                    </ListItemIcon>
                    <ListItemText primary={category.name} />
                    {category.isOpen ? <ExpandLess /> : <ExpandMore />}
                  </ListItemButton>
                  <Collapse in={category.isOpen} timeout="auto" unmountOnExit>
                    <List component="div" disablePadding>
                      {category.fields.map((item, indexCategory) => (
                        <Draggable
                          key={indexCategory}
                          draggableId={`${category.name}-${item.field_name}`}
                          dragbleItem={item}
                          index={indexCategory}
                        >
                          {(providedCategory) => (
                            <div
                              ref={providedCategory.innerRef}
                              {...providedCategory.draggableProps}
                              {...providedCategory.dragHandleProps}
                            >
                              <ListItemButton sx={{ pl: 4 }}>
                                <ListItemIcon>
                                  <CropSquareOutlined className="rotated-icon" />
                                </ListItemIcon>
                                <ListItemText primary={item.display_name} />
                              </ListItemButton>
                            </div>
                          )}
                        </Draggable>
                      ))}
                    </List>
                  </Collapse>
                </div>
              ))}
            </List>
          </BootStrapCardWithHeader>
          {provided.placeholder}
        </div>
      )}
    </Droppable>
  );
};

export default ReportFieldCard;
