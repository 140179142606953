export const ADD_SUCCESS = 'Record added successfully';
export const ADD_ERROR 	 = 'Saving failed !';
export const DELETE_SUCCESS = 'Record deleted successfully';
export const UPDATE_SUCCESS = 'Record updated successfully';
export const UPDATE_ERROR = 'Updated failed !';
export const USER_CREATED_SUCCESS = 'Associate user created successfully.';

export const SEND_MESSAGE_SUCCESS = 'Message Sent Successfully';
export const SEND_MESSAGE_FAILED = 'Message Sent Failed';
export const SAVE_MESSAGE_DRAFT = 'Message Saved as Draft';
export const DELETE_MESSAGE_SUCCESS = 'Message deleted successfully';

export const UPLOAD_CSV_ERROR = 'Please upload a CSV file before proceeding'; 
export const SUCCESS_IMPORT = 'Imported Successfully'; 
export const TASK_NOT_FOUND = 'Task not found.';

export const PREVIOUS_MESSAGE = 'Previous Message On';
export const MESSAGE_COPIED_SUCCESS = 'Message copied';

export const MESSAGE_TEMPLATE_CREATION_SUCCESS = 'Message Template Created Successfully';
export const MESSAGE_TEMPLATE_CREATION_FAILED = 'Message Template Creation Failed';