import React from 'react';

const NotfoundComponent = () => {
 
        return(
            <div className="col-md-12">
                <h1>not found</h1> 
            </div>   
        )

}

export default NotfoundComponent;
