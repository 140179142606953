import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import React from "react";
import classes from "./TableHolder.module.css";
const TableHolderCard = ({ items, onItemClick }) => {
  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell component={"th"}>#</TableCell>
            <TableCell component={"th"}>Category</TableCell>
            <TableCell component={"th"}>Report</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {items.map((row, index) => (
            <TableRow
              key={index}
              onClick={() => onItemClick(row)}
              className={classes.tableItem}
            >
              <TableCell component="th" scope="row">
                {index + 1}
              </TableCell>
              <TableCell>{row.category}</TableCell>
              <TableCell>{row.report}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default TableHolderCard;
