import React from "react";
import BooleanGroupFields from "./FilterFields/BooleanGroupFields";
import DateGroupFields from "./FilterFields/DateGroupFields";
import ListGroupFields from "./FilterFields/ListGroupFields";
import MonetaryGroupFields from "./FilterFields/MonetaryGroupFields";
import NumberGroupFields from "./FilterFields/NumberGroupFields";
import StringGroupFields from "./FilterFields/StringGroupFields";

const GeneralFilterCard = (props) => {
  const { filter, randomReportId } = props;
  return (
    <>
      {filter.field_types.map((item, index) => {
        if (item.name === "String") {
          return (
            <StringGroupFields
              key={index}
              fields={item}
              randomReportId={randomReportId}
            />
          );
        } else if (item.name === "Number") {
          return (
            <NumberGroupFields
              key={index}
              fields={item}
              randomReportId={randomReportId}
            />
          );
        } else if (item.name === "Date") {
          return (
            <DateGroupFields
              key={index}
              fields={item}
              randomReportId={randomReportId}
            />
          );
        } else if (item.name === "Monetary") {
          return (
            <MonetaryGroupFields
              key={index}
              fields={item}
              randomReportId={randomReportId}
            />
          );
        } else if (item.name === "List") {
          return (
            <ListGroupFields
              key={index}
              fields={item}
              randomReportId={randomReportId}
            />
          );
        } else if (item.name === "Boolean") {
          return (
            <BooleanGroupFields
              key={index}
              fields={item}
              randomReportId={randomReportId}
            />
          );
        }
        return <div key={index}>Unknown Field</div>;
      })}
    </>
  );
};

export default GeneralFilterCard;
