import i18n from "../../../utilities/i18n";


const TableHeadData = [
    {
        "name": i18n.t("reports.month"),
        "type": "Date",
        "width": "small",
        "sort": false,
        "hideValue": false,
    },
    {
        "name": i18n.t("reports.receivedDate"),
        "type": "Date",
        "width": "small",
        "sort": false,
        "hideValue": false,
    },
    {
        "name": i18n.t("reports.noofbilled"),
        "type": "number",
        "width": "small",
        "sort": false,
        "hideValue": false,
    },
    {
        "name": i18n.t("reports.noofpaid"),
        "type": "number",
        "width": "small",
        "sort": false,
        "hideValue": false,
    },
    {
        "name": i18n.t("reports.totalpaid"),
        "type": "number",
        "width": "small",
        "sort": false,
        "hideValue": false,
    },
    {
        "name": i18n.t("reports.expectedRevenue"),
        "type": "number",
        "width": "small",
        "sort": false,
        "hideValue": true,
    },
    {
        "name": i18n.t("reports.noofexceptions"),
        "type": "number",
        "width": "small",
        "sort": false,
        "hideValue": true,
    },
    {
        "name": i18n.t("reports.total_amount_exception"),
        "type": "number",
        "width": "small",
        "sort": false,
        "hideValue": true,
    },
    {
        "name": i18n.t("reports.noofpendingunbilled"),
        "type": "number",
        "width": "small",
        "sort": false,
        "hideValue": true,
    },
    {
        "name": i18n.t("reports.noofclientbill"),
        "type": "number",
        "width": "small",
        "sort": false,
        "hideValue": true,
    },
    {
        "name": i18n.t("reports.totalAmountClientBill"),
        "type": "number",
        "width": "small",
        "sort": false,
        "hideValue": true,
    },
    {
        "name": i18n.t("reports.noofselfpay"),
        "type": "number",
        "width": "small",
        "sort": false,
        "hideValue": true,
    },
    {
        "name": i18n.t("reports.totalAmountselfpay"),
        "type": "number",
        "width": "small",
        "sort": false,
        "hideValue": true,
    },
    {
        "name": i18n.t("reports.insurancePayerName"),
        "type": "string",
        "width": "small",
        "sort": false,
        "hideValue": true,
    },
];

export const TableBodyData = [
    [
        {
            "id": "",
            "name": "month",
            "value": "",
            "type": "Date",
            "needDateFormat": "yes",
            "align": "center",
            "width": "small",
            "hideValue": false,
        },
        {
            "id": "",
            "name": "receivedDate",
            "value": "",
            "type": "Date",
            "needDateFormat": "yes",
            "align": "center",
            "width": "small",
            "hideValue": false,
        },
        {
            "id": "",
            "name": "billed_amount",
            "value": "",
            "type": "number",
            "align": "left",
            "width": "small",
            "hideValue": false,
        },
        {
            "id": "",
            "name": "no_of_billed",
            "value": "",
            "type": "number",
            "align": "left",
            "width": "small",
            "hideValue": false,
        },
        {
            "id": "",
            "name": "no_of_paid",
            "value": "",
            "type": "number",
            "align": "left",
            "width": "small",
            "hideValue": false,
        },
        {
            "id": "",
            "name": "total_paid",
            "value": "",
            "type": "number",
            "align": "left",
            "width": "small",
            "hideValue": false,
        },
        {
            "id": "",
            "name": "expected_revenue",
            "value": "",
            "type": "number",
            "align": "center",
            "width": "small",
            "hideValue": true,
        },
        {
            "id": "",
            "name": "no_of_exceptions",
            "value": "",
            "type": "number",
            "align": "center",
            "width": "small",
            "hideValue": true,
        },
        {
            "id": "",
            "name": "totalamount_exceptions",
            "value": "",
            "type": "number",
            "align": "center",
            "width": "small",
            "hideValue": true,
        },
        {
            "id": "",
            "name": "no_of_pending_unbilled",
            "value": "",
            "type": "number",
            "align": "center",
            "width": "small",
            "hideValue": true,
        },
        {
            "id": "",
            "name": "totalamount_pending_unbilled",
            "value": "",
            "type": "number",
            "align": "center",
            "width": "small",
            "hideValue": true,
        },

        {
            "id": "",
            "name": "no_of_clientbill",
            "value": "",
            "type": "number",
            "align": "center",
            "width": "small",
            "hideValue": true,
        },
        {
            "id": "",
            "name": "totalamount_clientbill",
            "value": "",
            "type": "number",
            "align": "center",
            "width": "small",
            "hideValue": true,
        },
        {
            "id": "",
            "name": "no_of_selfpay",
            "value": "",
            "type": "number",
            "align": "center",
            "width": "small",
            "hideValue": true,
        },
        {
            "id": "",
            "name": "totalamount_selfpay",
            "value": "",
            "type": "number",
            "align": "center",
            "width": "small",
            "hideValue": true,
        },
        {
            "id": "",
            "name": "insurance_payerName",
            "value": "",
            "type": "string",
            "align": "center",
            "width": "small",
            "hideValue": true,
        },        
    ]
]

export const Report360TableData = {
    "name": "dynamicTable",
    "isResize": false,
    "isFixedHead": true,
    "tableHeadings": TableHeadData,
    "tableBodyData": TableBodyData
}
