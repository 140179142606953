import React, { useEffect } from 'react'
import './style.css'
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

type Rule = {
    id: number,
    rule: string,
    isSatisfied: boolean
}

type PasswordStrength = {
    pStrength: number,
    rules: Rule[]
}

type PasswordStrengthCheckProps = {
    readonly inputValue: string,
    setPasswordStrength: (passwordStrength: PasswordStrength) => void,
    passwordStrength: PasswordStrength
}

const PasswordStrengthCheck: React.FC<PasswordStrengthCheckProps> = ({ inputValue, setPasswordStrength, passwordStrength }) => {

    const validatePasswordStrength = (password: string): PasswordStrength => {
        const rules: Rule[] = [
            { id: 1, rule: 'Password should be at least 8 characters.', isSatisfied: password.length >= 8 },
            { id: 2, rule: 'Include at least one uppercase letter to make it stronger.', isSatisfied: /[A-Z]/.test(password) },
            { id: 3, rule: 'Make sure to add at least one number.', isSatisfied: /\d/.test(password) },
            {
                id: 4,
                rule: "Don't forget to include at least one special character (!, @, #, $, &, % ) to increase its complexity.",
                isSatisfied: /[!@#$%^&*(),.?":{}|<>]/.test(password) && !/[{}\/\\]/.test(password)
            },

        ];

        const pStrength = rules.filter(rule => rule.isSatisfied).length;

        return {
            pStrength: pStrength,
            rules: rules
        }
    }

    useEffect(() => {
        setPasswordStrength(validatePasswordStrength(inputValue))
    }, [inputValue, setPasswordStrength]);

    return (
        <>
            <div className="password-strength">
                <div className={`strength-bar ${passwordStrength?.pStrength === 1 ? 'red' : passwordStrength?.pStrength === 2 ? 'light-red' : passwordStrength?.pStrength === 3 ? 'blue' : passwordStrength?.pStrength === 4 ? 'green' : 'grey'}`} />
                <div className={`strength-bar ${passwordStrength?.pStrength >= 2 ? (passwordStrength?.pStrength === 2 ? 'light-red' : passwordStrength?.pStrength === 3 ? 'blue' : 'green') : 'grey'}`} />
                <div className={`strength-bar ${passwordStrength?.pStrength >= 3 ? (passwordStrength?.pStrength === 3 ? 'blue' : 'green') : 'grey'}`} />
                <div className={`strength-bar ${passwordStrength?.pStrength === 4 ? 'green' : 'grey'}`} />
            </div>
            {passwordStrength?.rules?.map((rule) => (
                <div key={rule.id}>
                    <CheckCircleIcon color={rule.isSatisfied ? 'success' : 'error'} style={{ marginRight: '5px' }} />
                    <span>{rule.rule}</span>
                </div>
            ))}
        </>
    )
}

export default PasswordStrengthCheck