import React, { useState } from "react";
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import Content from './contentComponent';
import LoginComponent from '../components/authentications/login'; 
import {ViewReceipts} from '../components/Payments/viewReceipts'; 
import ForgotPasswordComponent from '../components/commons/passwordManagement/forgotPassword';
import ForceResetPasswordComponent from '../components/commons/passwordManagement/forceResetPassword';

import * as staticConfigs from '../utilities/staticConfigs'; 
import { LoadingProvider } from "./loadingContext";
import Loading from "../components/commons/loading";

const Router = () => {
    const [showLoadingBar, setShowLoadingBar] = useState(false);       
    return (
        <BrowserRouter>

        <Content />           

        <Switch>    
            <LoadingProvider value={setShowLoadingBar}>    
                <Loading showLoadingBar={showLoadingBar} />    
                <Route path='/' exact={true} component={LoginComponent} />
                <Route exact path={staticConfigs.LOGIN_ROUTE_SUPER} component={LoginComponent} />
                <Route exact path={staticConfigs.LOGIN_ROUTE_SUPER+'/'} component={LoginComponent} />
                {/* <Route exact path={staticConfigs.ROUTE_RESET_PASSWORD} component={ResetPasswordComponent} />
                <Route exact path={staticConfigs.ROUTE_VERIFY_EMAIL} component={EmailVerificationComponent} /> */}
                <Route exact path={staticConfigs.ROUTE_FORGOT_PASSWORD} component={ForgotPasswordComponent} />
                <Route exact path={staticConfigs.ROUTE_FORCE_RESET_PASSWORD} component={ForceResetPasswordComponent} />
                <Route exact path={staticConfigs.ROUTE_VIEW_RECEIPTS} component={ViewReceipts} />
            </LoadingProvider>  
        </Switch>


        </BrowserRouter>
    )
}

export default Router;