import { createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import service from "../../../components/patientManagement/patients/service";

const initialState = {
  OtherBillingBasicData: [],
  billingInfoPks: [],
  otherBillingDetails: {
    accident_date: "",
    accident_location_id: "",
    accident_type_id: "",
    other_accident: "",
    assignment_benefit_id: "",
    accept_assignments: 1,
    initial_treatment_date: "",
    admission_date: "",
    last_menstural_date: "",
    cliam_casuality_number: "",
    cpt1: "",
    cpt2: "",
    cpt3: "",
    cpt4: "",
    cpt5: "",
    cpt6: "",
    cpt7: "",
    cpt8: "",
    cpt9: "",
    cpt10: "",
    cpt11: "",
    cpt12: "",
    dx1: "",
    dx2: "",
    dx3: "",
    dx4: "",
    dx5: "",
    dx6: "",
    dx7: "",
    dx8: "",
    dx9: "",
    dx10: "",
    dx11: "",
    dx12: "",
  },
  loading: false,
  assignmentBenifit: []
};

/**
 * Api call for get other billing info data
 * @param payload
 */
export const OtherBillingBasicData = createAsyncThunk(
  "patient/otherBilling",
  async (payload) => {
    let result = await service.GetOtherBillingInfoData(payload.pk);
    let result2 = await assignmentBenifit(payload.pageSize, payload.patientPK)
    return { patientBillingBasicData: result.data, billinginfopk: payload.pk, assignmentBenifit: result2 };
  }
);

export const assignmentBenifit = async (pageSize, patientPK) => {
  const result = await service.ListTypeOfAssignmentBenefits(pageSize, patientPK);
    return result.data;
}

export const patientBillingSlice = createSlice({
  name: "patientOtherBilling",
  initialState,
  reducers: {
    /***
     * Making otherbilling data blank when loging in
     * @param {*} state
     * @param {*} actions
     */
    setOtherBillingInitalState: () => initialState,


     /**
     * getting billing info  data storing inside array in the perticualr posistin with billing info pk
     * @param {*} state 
     * @param {*} actions 
     */
    getOtherBillingData:(state,actions)=>{
      if (state.OtherBillingBasicData.length > 0) {
        let data = state.OtherBillingBasicData.filter(
          (item) => item.billinginfopk === actions.payload
        );
        state.otherBillingDetails=data[0].patientBillingBasicData
      }
    },

    /**
     * update otherBillingBasicData storing inside array in the perticualr posistio
     * @param {*} state 
     * @param {*} actions 
     */

    updatePatientBillingData: (state, actions) => {
      let index = state.OtherBillingBasicData.findIndex(
        (item) => item.billinginfopk === actions.payload.billinginfopk
      );

      let data = {
        ...state.OtherBillingBasicData[index],
        patientBillingBasicData: {
          ...state.OtherBillingBasicData[index].patientBillingBasicData,
          ...actions.payload.patientBillingBasicData,
        },
      };
      state.OtherBillingBasicData[index] = data;
    },
  },
  extraReducers: (builder) => {
    /**
     * checking api call is success
     * @param {*} state
     * @param {*} actions
     */
    builder.addCase(OtherBillingBasicData.fulfilled, (state, actions) => {
      /**
       * checking billing details is already exist in otherbillingbasic data
       */
      const Index = state.OtherBillingBasicData.findIndex(
        (item) => item.billinginfopk === actions.payload.billinginfopk
      );
      /**
       * if data not exist in the aaray pushing new data to the aaray
       * @param {*} state
       * @param {*} actions
       */
      if (Index === -1) state.OtherBillingBasicData.push(actions.payload);
      state.billingInfoPks.push(actions.payload.billinginfopk);
      state.loading = false;
      state.assignmentBenifit = actions.payload.assignmentBenifit
    });

    /**
     * checking api status is pending
     *  * @param {*} state
     * @param {*} actions
     */
    builder.addCase(OtherBillingBasicData.pending, (state) => {
      state.loading=true;
    });

    /**
     *checking api call response is rejected or any error
     * @param {*} state
     * @param {*} actions
     */
    builder.addCase(OtherBillingBasicData.rejected, (state) => {
      state.loading=false;
    });
  },
});

export const {
  setOtherBillingInitalState,
  getOtherBillingData,
  updatePatientBillingData,
} = patientBillingSlice.actions;

export default patientBillingSlice.reducer;
