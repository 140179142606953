import React, { useState } from 'react';
import { Form } from 'react-bootstrap';
import { Box, Tab } from '@mui/material';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import { styled } from '@mui/material/styles';

import Notify from '../../commons/notify';

import i18n from '../../../utilities/i18n';
import { TAB1, TAB2 } from '../../../utilities/staticConfigs';

import CustomFeeSchedule from './customFeeSchedule';
import StandardFeeSchedule from './standardFeeSchedule';

const CustomIndicator = styled('span')(() => ({
    backgroundColor: 'white',
    height: 7,
    position: 'absolute',
    bottom: 0,
    width: 'calc(100% - 4px)',
    marginLeft: '2px'

}));

const StyledTabList = styled(TabList)`
  & .MuiTabs-indicator {
    background-color: transparent !important;
    height: 0px !important;
  }
`;

const FeeSchedule = () => {
    const [activeTab, setActiveTab] = useState(TAB1);

    //start ==== Alert message properties
    const [showNotify, setShowNotify] = useState('hide');
    const [notifyDescription, setNotifyDescription] = useState('');
    const [notifyType, setNotifyType] = useState('success');

    const onTabChange = (e, tab) => {
        setActiveTab(tab);
    }

    // ---------------------
    return (
        <React.Fragment>
            <div className="col-md-12">
            <Notify showNotify={showNotify} setShowNotify={setShowNotify} notifyDescription={notifyDescription} setNotifyType={setNotifyType} setNotifyDescription={setNotifyDescription} notifyType={notifyType} />
            <Form.Group>
                <div className="box margin-right15">
                    <TabContext value={activeTab}>  
                        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                            {/* <TabList onChange={onTabChange} aria-label="lab API tabs example"> */}
                            <StyledTabList
                                TabIndicatorProps={{
                                    children: <CustomIndicator />
                                }}
                                onChange={onTabChange}
                                scrollable
                                scrollButtons="auto"
                                aria-label="lab API tabs example"
                                variant="scrollable"
                            > 
                                <Tab label={i18n.t("customerSetup.feeSchedule.tabHeader.customFeeSchedule")} value={TAB1} className='tabMainMenu bgBaseWhite' />
                                <Tab label={i18n.t("customerSetup.feeSchedule.tabHeader.standardFeeSchedule")} value={TAB2} className='tabMainMenu' />
                            </StyledTabList>
                        </Box>
                        <TabPanel value={TAB1} className='searchBoxTab box-content-white border-radius-topleft-0'>
                            <CustomFeeSchedule/>
                        </TabPanel>
                        <TabPanel value={TAB2} className='searchBoxTab box-content-white border-radius-topleft-0 table-responsive '>
                            <StandardFeeSchedule/>
                        </TabPanel>
                    </TabContext>
                </div>
            </Form.Group>
            </div>
        </React.Fragment>
    )    
}

export default FeeSchedule;