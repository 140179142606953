import i18n from "../../../utilities/i18n";

export const mainHeadings=[
    { "value": i18n.t("patientPages.patientLedger.ledgerTab.tableHedings.claim"), "thClass": "textCenter small  fontSize14", "tdClass": "textCenter fontSize14" },
    { "value": i18n.t("patientPages.patientLedger.ledgerTab.tableHedings.dos"), "thClass": "textCenter small  fontSize14", "tdClass": "textCenter fontSize14" },
    { "value": i18n.t("patientPages.patientLedger.ledgerTab.tableHedings.provider"), "thClass": "textCenter medium  fontSize14", "tdClass": "fontSize14 tblComponentMinWidth300" },
    { "value": i18n.t("patientPages.patientLedger.ledgerTab.tableHedings.charges"), "thClass": "textCenter small  fontSize14", "tdClass": "textRight fontSize14 amount" },
    { "value": i18n.t("patientPages.patientLedger.ledgerTab.tableHedings.payments"), "thClass": "textCenter small  fontSize14", "tdClass": "textRight fontSize14 amount" },
    { "value": i18n.t("patientPages.patientLedger.ledgerTab.tableHedings.adjustments"), "thClass": "textCenter small  fontSize14", "tdClass": "textRight fontSize14 amount" },
    { "value": i18n.t("patientPages.patientLedger.ledgerTab.tableHedings.balance"), "thClass": "textCenter small  fontSize14", "tdClass": "textRight fontSize14 amount" },
    { "value": i18n.t("patientPages.patientLedger.ledgerTab.tableHedings.insuranceBal"), "thClass": "textCenter small  fontSize14", "tdClass": "textRight fontSize14 amount" },
    { "value": i18n.t("patientPages.patientLedger.ledgerTab.tableHedings.patientBal"), "thClass": "textCenter small  fontSize14", "tdClass": "textRight fontSize14 amount" },
    { "value": i18n.t("patientPages.patientLedger.ledgerTab.tableHedings.claimStatus"), "thClass": "textCenter medium fontSize14", "tdClass": "textCenter fontSize14" },
    { "value": i18n.t("patientPages.patientLedger.ledgerTab.tableHedings.balancePayer"), "thClass": "textCenter small  fontSize14", "tdClass": "fontSize14 tblComponentMinWidth300" }
]

export const subHedaings1=[
    { "value": i18n.t("patientPages.patientLedger.ledgerTab.tableHedings.cpt"), "thClass": "textCenter  small fontSize12", "tdClass": "textCenter fontSize12" },
    { "value": i18n.t("patientPages.patientLedger.ledgerTab.tableHedings.description"), "thClass": "textCenter  medium fontSize12", "tdClass": "fontSize12" },
    { "value": i18n.t("patientPages.patientLedger.ledgerTab.tableHedings.units"), "thClass": "textCenter  small fontSize12", "tdClass": "textCenter fontSize12" },
    { "value": i18n.t("patientPages.patientLedger.ledgerTab.tableHedings.charges"), "thClass": "textCenter  small fontSize12", "tdClass": "textRight fontSize12 amount" },
    { "value": i18n.t("patientPages.patientLedger.ledgerTab.tableHedings.payments"), "thClass": "textCenter  small fontSize12", "tdClass": "textRight fontSize12 amount" },
    { "value": i18n.t("patientPages.patientLedger.ledgerTab.tableHedings.adjustments"), "thClass": "textCenter  small fontSize12", "tdClass": "textRight fontSize12 amount" },
    { "value": i18n.t("patientPages.patientLedger.ledgerTab.tableHedings.balance"), "thClass": "textCenter  small fontSize12", "tdClass": "textRight fontSize12 amount" },
    { "value": i18n.t("patientPages.patientLedger.ledgerTab.tableHedings.insuranceBal"), "thClass": "textCenter  small fontSize12", "tdClass": "textRight fontSize12 amount" },
    { "value": i18n.t("patientPages.patientLedger.ledgerTab.tableHedings.patientBal"), "thClass": "textCenter  small fontSize12", "tdClass": "textRight fontSize12 amount" },
    { "value": i18n.t("patientPages.patientLedger.ledgerTab.tableHedings.claimStatus"), "thClass": "textCenter  small fontSize12", "tdClass": "textCenter fontSize12" },
    { "value": i18n.t("patientPages.patientLedger.ledgerTab.tableHedings.balancePayer"), "thClass": "textCenter  small fontSize12", "tdClass": "fontSize12 tblComponentMinWidth300" }
];

export const subHedaings2=[
    { "value": i18n.t("patientPages.patientLedger.ledgerTab.tableHedings.date"), "thClass": "textCenter small fontSize10", "tdClass": "textCenter fontSize10" },
    { "value": i18n.t("patientPages.patientLedger.ledgerTab.tableHedings.activity"), "thClass": "textCenter medium fontSize10", "tdClass": "textCenter fontSize10 tblComponentMinWidth300" },
    { "value": i18n.t("patientPages.patientLedger.ledgerTab.tableHedings.charges"), "thClass": "textCenter small fontSize10", "tdClass": "textRight fontSize10 amount" },
    { "value": i18n.t("patientPages.patientLedger.ledgerTab.tableHedings.payments"), "thClass": "textCenter small fontSize10", "tdClass": "textRight fontSize10 amount" },
    { "value": i18n.t("patientPages.patientLedger.ledgerTab.tableHedings.adjustments"), "thClass": "textCenter small fontSize10", "tdClass": "textRight fontSize10 amount" },
    { "value": i18n.t("patientPages.patientLedger.ledgerTab.tableHedings.balance"), "thClass": "textCenter small fontSize10", "tdClass": "textRight fontSize10 amount" },
    { "value": i18n.t("patientPages.patientLedger.ledgerTab.tableHedings.remarks"), "thClass": "textCenter medium fontSize10", "tdClass": "textCenter fontSize10" }
]