import { FormControl, Grid, MenuItem, Select, TextField } from "@mui/material";
import React, { useCallback, useContext, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { humanizeText } from "../../../../utilities/commonUtilities";
import {
  addFilter,
  updateFilter,
} from "../../../../store/reports/reportViewerSlice";
import ReportContext from "../report-context";

let isInitial = true;

const DateFieldSelector = ({ item }) => {
  const ctx = useContext(ReportContext);
  const dispatch = useDispatch();

  const [filterObject, setFilterObject] = useState({
    fieldId: item.id,
    queryField: item.field,
    searchMap: {
      condition: "all",
      Minimum: "",
      Maximum: "",
    },
  });

  const [condition, setCondition] = useState("all");
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndtDate] = useState(new Date());

  const handleChange = (e) => setCondition(e.target.value);

  const onStartDateChangeHandler = (e) =>
    setStartDate(formatDate(e.target.value));

  const onEndDateChangeHandler = (e) => setEndtDate(formatDate(e.target.value));

  const setDates = (start, end) => {
    setStartDate(start);
    setEndtDate(end);
  };

  const getYesterday = useCallback(() => {
    const today = new Date();
    return formatDate(today.setDate(today.getDate() - 1));
  });
  const setThisWeek = useCallback(() => {
    let curr = new Date();
    let first = curr.getDate() - curr.getDay();
    let last = first + 6;
    let firstday = new Date(curr.setDate(first)).toUTCString();
    let lastday = new Date(curr.setDate(last)).toUTCString();
    setDates(formatDate(firstday), formatDate(lastday));
  });
  const setNextWeek = useCallback(() => {
    var today = new Date();
    var nextWeek = new Date(
      today.getFullYear(),
      today.getMonth(),
      today.getDate() + 7
    );
    let first = nextWeek.getDate() - nextWeek.getDay();
    let last = first + 6;
    let firstday = new Date(nextWeek.setDate(first)).toUTCString();
    let lastday = new Date(nextWeek.setDate(last)).toUTCString();
    setDates(formatDate(firstday), formatDate(lastday));
  });

  const setThisMonth = useCallback(() => {
    let date = new Date();
    let y = date.getFullYear();
    let m = date.getMonth();
    let firstDay = new Date(y, m, 1);
    let lastDay = new Date(y, m + 1, 0);
    setDates(formatDate(firstDay), formatDate(lastDay));
  });
  const setLastMonth = useCallback(() => {
    let date = new Date();
    let y = date.getFullYear();
    let m = date.getMonth();
    let firstDay = new Date(y, m - 1, 1);
    let lastDay = new Date(y, m, 0);
    setDates(formatDate(firstDay), formatDate(lastDay));
  });
  const setNextMonth = useCallback(() => {
    let date = new Date();
    let y = date.getFullYear();
    let m = date.getMonth();
    let firstDay = new Date(y, m + 1, 1);
    let lastDay = new Date(y, m + 2, 0);
    setDates(formatDate(firstDay), formatDate(lastDay));
  });
  const setThisYear = useCallback(() => {
    const currentYear = new Date().getFullYear();
    const firstDay = new Date(currentYear, 0, 1);
    const lastDay = new Date(currentYear, 11, 31);
    setDates(formatDate(firstDay), formatDate(lastDay));
  });
  const setLastYear = useCallback(() => {
    const currentYear = new Date().getFullYear();
    const firstDay = new Date(currentYear - 1, 0, 1);
    const lastDay = new Date(currentYear - 1, 11, 31);
    setDates(formatDate(firstDay), formatDate(lastDay));
  });
  const setNextYear = useCallback(() => {
    const currentYear = new Date().getFullYear();
    const firstDay = new Date(currentYear + 1, 0, 1);
    const lastDay = new Date(currentYear + 1, 11, 31);
    setDates(formatDate(firstDay), formatDate(lastDay));
  });
  const setLastWeek = useCallback(() => {
    let d = new Date();
    let to = d.setTime(
      d.getTime() - (d.getDay() ? d.getDay() : 7) * 24 * 60 * 60 * 1000
    );
    let from = d.setTime(d.getTime() - 6 * 24 * 60 * 60 * 1000);
    setDates(formatDate(from), formatDate(to));
  });
  const setLastNDays = useCallback((days) => {
    let now = new Date();
    const backdate = new Date(now.setDate(now.getDate() - days));
    setDates(formatDate(backdate), formatDate(new Date()));
  });
  const setNextNDays = useCallback((days) => {
    let now = new Date();
    const backdate = new Date(now.setDate(now.getDate() + days));
    setDates(formatDate(new Date()), formatDate(backdate));
  });

  useEffect(() => {
    setFilterObject((prev) => {
      return {
        ...prev,
        searchMap: { ...prev.searchMap, condition: condition },
      };
    });
    let tomorrow, today;
    switch (condition) {
      case "all":
        break;
      case "other":
        break;
      case "today":
        today = formatDate(new Date());
        setDates(today, today);
        break;
      case "yesterday":
        setDates(getYesterday(), getYesterday());
        break;
      case "this_week":
        setThisWeek();
        break;
      case "this_month":
        setThisMonth();
        break;
      case "this_year":
        setThisYear();
        break;
      case "last_week":
        setLastWeek();
        break;
      case "last_month":
        setLastMonth();
        break;
      case "last_year":
        setLastYear();
        break;
      case "last_7_days":
        setLastNDays(7);
        break;
      case "last_30_days":
        setLastNDays(30);
        break;
      case "last_60_days":
        setLastNDays(60);
        break;
      case "last_90_days":
        setLastNDays(90);
        break;
      case "last_12_months":
        setLastNDays(365);
        break;
      case "tomorrow":
        tomorrow = new Date().setDate(new Date().getDate() - 1);
        setDates(formatDate(tomorrow), formatDate(tomorrow));
        break;
      case "next_week":
        setNextWeek();
        break;
      case "next_month":
        setNextMonth();
        break;
      case "next_year":
        setNextYear();
        break;
      case "next_7_days":
        setNextNDays(7);
        break;
      case "next_30_days":
        setNextNDays(30);
        break;
      case "next_60_days":
        setNextNDays(60);
        break;
      case "next_90_days":
        setNextNDays(90);
        break;
      case "next_12_months":
        setNextNDays(365);
        break;

      default:
        break;
    }
  }, [condition]);

  const formatDate = useCallback((date) => {
    let d = new Date(date),
      month = "" + (d.getMonth() + 1),
      day = "" + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;

    return [year, month, day].join("-");
  });

  useEffect(() => {
    setFilterObject((prev) => {
      return {
        ...prev,
        searchMap: { ...prev.searchMap, Maximum: endDate, Minimum: startDate },
      };
    });
  }, [startDate, endDate]);

  useEffect(() => {
    dispatch(addFilter({ filter: filterObject, reportUuid: ctx.reportUuid }));
  }, []);

  useEffect(() => {
    if (isInitial) {
      isInitial = false;
      return;
    }
    dispatch(
      updateFilter({ filter: filterObject, reportUuid: ctx.reportUuid })
    );
  }, [filterObject, dispatch]);

  return (
    <Grid container spacing={2} className="mt-2">
      <Grid item xs={3}>
        <strong>{humanizeText(item.field)}</strong>
        <FormControl fullWidth>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={condition}
            label="Condition"
            onChange={handleChange}
          >
            <MenuItem value={"all"}>All</MenuItem>
            <MenuItem value={"other"}>Other</MenuItem>
            <MenuItem value={"today"}>Today</MenuItem>
            <MenuItem value={"yesterday"}>Yesterday</MenuItem>
            <MenuItem value={"this_week"}>This Week</MenuItem>
            <MenuItem value={"this_month"}>This Month</MenuItem>
            <MenuItem value={"this_year"}>This Year</MenuItem>
            <MenuItem value={"last_week"}>Last Week</MenuItem>
            <MenuItem value={"last_month"}>Last Month</MenuItem>
            <MenuItem value={"last_year"}>Last Year</MenuItem>
            <MenuItem value={"last_7_days"}>Last 7 Days</MenuItem>
            <MenuItem value={"last_30_days"}>Last 30 Days</MenuItem>
            <MenuItem value={"last_60_days"}>Last 60 Days</MenuItem>
            <MenuItem value={"last_90_days"}>Last 90 Days</MenuItem>
            <MenuItem value={"last_12_months"}>Last 12 Months</MenuItem>
            <MenuItem value={"tomorrow"}>Tomorrow</MenuItem>
            <MenuItem value={"next_week"}>Next Week</MenuItem>
            <MenuItem value={"next_month"}>Next Month</MenuItem>
            <MenuItem value={"next_year"}>Next Year</MenuItem>
            <MenuItem value={"next_7_days"}>Next 7 Days</MenuItem>
            <MenuItem value={"next_30_days"}>Next 30 Days</MenuItem>
            <MenuItem value={"next_60_days"}>Next 60 Days</MenuItem>
            <MenuItem value={"next_90_days"}>Next 90 Days</MenuItem>
            <MenuItem value={"next_12_months"}>Next 12 Months</MenuItem>
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs={3}>
        <FormControl fullWidth className="mt-2">
          <TextField
            type={"date"}
            className="mt-4"
            value={startDate}
            onChange={onStartDateChangeHandler}
          />
        </FormControl>
      </Grid>
      <Grid item xs={3}>
        <FormControl fullWidth className="mt-2">
          <TextField
            type={"date"}
            className="mt-4"
            value={endDate}
            onChange={onEndDateChangeHandler}
          />
        </FormControl>
      </Grid>
      <Grid item xs={6}></Grid>
    </Grid>
  );
};

export default DateFieldSelector;
