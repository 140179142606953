import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import service from '../../../customerSetup/providerManagement/service'
import service3 from '../../../dictionaries/revenueCode/service';
import searchClaimService from '../../SearchClaims/service';
import { getStorage } from "../../../../utilities/browserStorage";
import { PAGING_END_INDEX } from "../../../../utilities/staticConfigs";
let initialState = {
    searchQuery: {
        dateOfServiceFrom: "",
        dateOfServiceTo: "",
        claimEnteredFrom: "",
        claimEnteredTo: "",
        claimSubmittedFrom: "",
        claimSubmittedTo: "",
        claimStatus: [],
        serviceLocation: [],
        serviceLocationType: [],
        claimType: 1,
        provider: [],
        selectServiceLocation: [],
        currentPayer: [],
        primaryPayer: [],
        secondaryPayer: [],
        payerType: [],
        selectedCptIDs: [],
        revenueCodes: [],
        icd: [],
    },
    dropDownListData: {
        isLoading: false,
        isErrorGettingDropdownData: false,
        isDropDownDataReceived: false,
        revenueCodeList: [],
        ICD: [],
        cptCodeList: [],
        PAYER_TYPE_LIST: [],
        payersList: [],
        ServiceLocationList: [],
        providerList: [],
        claimStatusList: []
    },

    sorting: {
        initialOrderingPatientName: true,
        initialOrderingClaimId: true,
        initialOrderingDos: true,
    },
    startIndex: 0,
    endIndex: PAGING_END_INDEX,
    activePage: 1,
    orderType: "",
    claim_order_type:"-",
    selectedClaimIDs: [],
    allchecked: false,
    claimBulkStatusUpdate: "",
    searched: false,
    reseted: false,
    validated: false,
    bulkupdated: false,
    patientSelectedData: [],
    patientAdvanceData: [],
    patient_PK:''
}


/*
 * Function getDropDownListData fetches all the drop-down-data required for the page in one shot using promise chaining 
 */
export const getFilterDataSet = createAsyncThunk(
    "claimFilter/getFilterDataSet",
    async (payload, thunkAPI) => {
        const pageSize = 0;
        const page = 0;
        const practicePK = getStorage('practice');

        const promises = [
            service.getProvidersData(pageSize, page, practicePK, true).then(response => response.data),
            service3.GetListRevenueCode(pageSize, page).then(response => response.data),
            searchClaimService.ServiceLocationLists(practicePK, null, null, true).then(response => {
                let locations = []
                response.data.map((item) => {
                    item.id = item.Id + '||' + item.location_type
                    locations.push(item)
                })
                return locations
            }),
            searchClaimService.ListInsuranceNames(pageSize, 0, practicePK).then(response => response.data),
            searchClaimService.ListClaimStatus(pageSize, page, practicePK).then(response => response.data),
            searchClaimService.ListCPTCodes(practicePK).then(response => response.data)
        ];
        try {
            const [
                providerList,
                revenueCodeList,
                ServiceLocationList,
                payerList,
                claimStatusList,
                cptCodesList
            ] = await Promise.all(promises);

            return {
                providerList,
                revenueCodeList,
                ServiceLocationList,
                payerList,
                claimStatusList,
                cptCodesList
            };
        } catch (error) {
            return thunkAPI.rejectWithValue({ errorMessage: 'Error getting Drop-down list data!' })
        }
    })


const claimFilterSlice = createSlice({
    name: "ClaimFilter",
    initialState,
    reducers: {
        /**
        * Set state function to update user search query inputs
        */
        updateFilterData: (state, actions) => {
            let data = {
                ...state.searchQuery,
                [actions.payload.name]: actions.payload.value
            }

            state.searchQuery = data;
        },

        updateServiceLocation: (state, actions) => {
            let data = {
                ...state.searchQuery,
                serviceLocation: actions.payload.service_location,
                serviceLocationType: actions.payload.service_location_type
            }

            state.searchQuery = data;
        },

        updateSeletedIDs: (state, actions) => {
            state.selectedClaimIDs = actions.payload.selected;
        },

        /**
         * updating sorting function
         * @param {*} state 
         * @param {*} actions 
         */
        updateSorting: (state, actions) => {
            let data;
            if (actions.payload.name == "patient_name") {
                data = {
                    initialOrderingPatientName: false,
                    initialOrderingClaimId: true,
                    initialOrderingDos: true,
                }
            }
            else if (actions.payload.name == "claim_id") {
                data = {
                    initialOrderingPatientName: true,
                    initialOrderingClaimId: false,
                    initialOrderingDos: true,
                }
            }
            else {
                data = {
                    initialOrderingPatientName: true,
                    initialOrderingClaimId: true,
                    initialOrderingDos: false,
                }
            }

            state.sorting = data;
        },

        /**
         * reset sorting of table
         * @param {*} state 
         */
        resetSorting: (state) => {
            let data = {
                initialOrderingPatientName: true,
                initialOrderingClaimId: true,
                initialOrderingDos: true,
            }
            state.sorting = data;
        },

        /**
         * updating order type
         * @param {*} state 
         * @param {*} actions 
         */
        updateOrderType: (state, actions) => {
            state.orderType = actions.payload.order_type
        },

        updateClaimOrderType: (state, actions) => {
            state.claim_order_type = actions.payload.claim_order_type
        },
        /**
                         * pagination active page state handling
                         * @param {*} state 
                         * @param {*} actions 
                         */
        setActivePage: (state, actions) => {
            state.activePage = actions.payload.active
        },
        /**
                 * pagination next state handling
                 * @param {*} state 
                 * @param {*} actions 
                 */
        onPageNext: (state, actions) => {
            state.startIndex = actions.payload.startIndex;
            state.endIndex = actions.payload.endIndex;
        },
        /**
                 * pagination previous state handling
                 * @param {*} state 
                 * @param {*} actions 
                 */
        onPagePrevious: (state, actions) => {
            state.startIndex = actions.payload.startIndex;
            state.endIndex = actions.payload.endIndex;
        },
        /**
         * storing all checked claims data here
         * @param {*} state 
         * @param {*} actions 
         */
        checkAllClaimSelected: (state, actions) => {
            state.allchecked = actions.payload.checked;
        },
        /**
         * storing bulk status update data
         * @param {*} state 
         * @param {*} actions 
         */
        bulkStatusUpdate: (state, actions) => {
            state.claimBulkStatusUpdate = actions.payload.value
        },
        /**
                 * resetting all data
                 * @param {*} state 
                 * @param {*} actions 
                 */
        resetFiltersAndData: (state, actions) => {
            state.searchQuery = {
                dateOfServiceFrom: "",
                dateOfServiceTo: "",
                claimEnteredFrom: "",
                claimEnteredTo: "",
                claimSubmittedFrom: "",
                claimSubmittedTo: "",
                claimStatus: [],
                serviceLocation: [],
                serviceLocationType: [],
                claimType: 1,
                provider: [],
                selectServiceLocation: [],
                currentPayer: [],
                primaryPayer: [],
                secondaryPayer: [],
                payerType: [],
                selectedCptIDs: [],
                revenueCodes: [],
                icd: [],
            }

            state.sorting = {
                initialOrderingPatientName: true,
                initialOrderingClaimId: true,
                initialOrderingDos: true,
            }

            state.orderType = "";
            state.selectedClaimIDs = [];
            state.allchecked = false;
            state.claimBulkStatusUpdate = "";
            state.startIndex = 0;
            state.endIndex = PAGING_END_INDEX;
            state.activePage = 1;
            state.patientSelectedData = []
            state.patientAdvanceData = [];
            state.patient_PK = ''
            state.reseted = actions.payload.status
        },
        /**
         * make data back to noraml after search action is performed
         * @param {*} state 
         * @param {*} actions 
         */
        statusUpdateSearched: (state, actions) => {
            state.sorting = {
                initialOrderingPatientName: true,
                initialOrderingClaimId: true,
                initialOrderingDos: true,
            }

            state.orderType = "";
            state.selectedClaimIDs = [];
            state.allchecked = false;
            state.claimBulkStatusUpdate = "";
            state.reseted = actions.payload.status
            state.startIndex = 0;
            state.endIndex = PAGING_END_INDEX;
            state.activePage = 1;
            state.searched = actions.payload.searched
        },
        /**
         * make data back to noraml after claim validate
         * @param {*} state 
         * @param {*} actions 
         */
        validateStatusUpdate: (state, actions) => {
            state.sorting = {
                initialOrderingPatientName: true,
                initialOrderingClaimId: true,
                initialOrderingDos: true,
            }

            state.orderType = "";
            state.selectedClaimIDs = [];
            state.allchecked = false;
            state.claimBulkStatusUpdate = "";
            state.reseted = actions.payload.status
            state.startIndex = 0;
            state.endIndex = PAGING_END_INDEX;
            state.patientSelectedData = []
            state.patientAdvanceData = [];
            state.patient_PK = ''
            state.validated = actions.payload.validated
        },
        /**
         * make data back to noraml after claim bulk status update
         * @param {*} state 
         * @param {*} actions 
         */
        bulkStatusUpdated: (state, actions) => {
            state.sorting = {
                initialOrderingPatientName: true,
                initialOrderingClaimId: true,
                initialOrderingDos: true,
            }

            state.orderType = "";
            state.selectedClaimIDs = [];
            state.allchecked = false;
            state.reseted = actions.payload.status
            state.startIndex = 0;
            state.endIndex = PAGING_END_INDEX;
            state.patientSelectedData = []
            state.patientAdvanceData = [];
            state.patient_PK = ''
            state.bulkupdated = actions.payload.bulkupdated
        },
        /**
         * storing advance search data here
         * @param {*} state 
         * @param {*} actions 
         */
        patientAdvanceSearchData: (state, actions) => {
            state.patientSelectedData = actions.payload.patient_selected;
            state.patientAdvanceData = actions.payload.patient_advance_data;
            state.patient_PK = actions.payload.patient_pk
        },
        // eslint-disable-next-line
        reset: (state) => initialState,
    },
    extraReducers: (builder) => {
        /**
        * Extra-reducers for api data fetch functions - (getDropDownListData)
        */
        builder
            .addCase(getFilterDataSet.pending, (state) => {
                state.dropDownListData.isLoading = true;
                state.dropDownListData.isErrorGettingDropdownData = false;
            })
            .addCase(getFilterDataSet.fulfilled, (state, action) => {
                const { providerList,
                    revenueCodeList,
                    ServiceLocationList,
                    payerList,
                    claimStatusList,
                    cptCodesList } = action.payload;
                state.dropDownListData.providerList = providerList;
                state.dropDownListData.revenueCodeList = revenueCodeList;
                state.dropDownListData.ServiceLocationList = ServiceLocationList;
                state.dropDownListData.payersList = payerList;
                state.dropDownListData.claimStatusList = claimStatusList;
                state.dropDownListData.cptCodeList = cptCodesList;
                state.dropDownListData.isLoading = false;
                state.dropDownListData.isErrorGettingDropdownData = false;
                state.isDropDownDataReceived = true
            })
            .addCase(getFilterDataSet.rejected, (state) => {
                state.dropDownListData.isLoading = false;
                state.dropDownListData.isErrorGettingDropdownData = true;
            });
    }
})

export const {
    updateFilterData,
    updateServiceLocation,
    updateSeletedIDs,
    resetFiltersAndData,
    patientAdvanceSearchData,
    updateSorting,
    updateOrderType,
    resetSorting,
    checkAllClaimSelected,
    bulkStatusUpdated,
    validateStatusUpdate,
    updateClaimOrderType,
    setActivePage,
    onPageNext,
    onPagePrevious,
    tableLoadedState,
    statusUpdateSearched,
    reset
} = claimFilterSlice.actions
export default claimFilterSlice.reducer;