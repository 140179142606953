import api from '../../service/api';

const GetCPTCodeList = async(pageSize, page, practicePK, type, search, claimPK) =>{
    let path = "practice/cpt-codes/?page_size=" + pageSize + "&page=" + page + "&practice_pk=" + practicePK + "&search=" +search;
    if(type){
        path += "&type="+type
    }
    if (claimPK){
        path += '&opened_claim_pk='+ claimPK;
    }
    return api.get(path);
}

export default {
    GetCPTCodeList
}