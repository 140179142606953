import React from "react";
import { Link } from "react-router-dom";
import Moment from "react-moment";
import actionImg from "../../../assets/images/action.png";
import i18n from "../../../utilities/i18n";
import {
    checkPermission,
    currencyFormat,
} from "../../../utilities/commonUtilities";
import imgCardFrontdAdd from "../../../assets/images/card-front-add.png";
import imgCardBackdAdd from "../../../assets/images/card-back-add.png";
import ProfileCardForInsurance from "../../commons/ProfileCardForInsurance";
import { format } from "date-fns";
import { permission_key_values_patient } from "../../../utilities/permissions";
import { Grid, Typography } from "@mui/material";

export const PatientMangeAuthorInfoTable = (props) => {
    return (
        <Grid container spacing={2}  style={{  padding:"10px"}} >
           
            {props.authorizedContactsList &&
                props.authorizedContactsList.length === 0 && (
                <Grid className="ml-10">
                    <Typography variant="body1" align="left" style={{ margin: '16px', padding: '16px' }}>
                        {props.initialTableData}
                    </Typography>
                </Grid>
                )}
            {props.authorizedContactsList.map((authConitem) => {
                return (

                    <Grid
                        key={authConitem?.id}
                        container
                        style={{
                            backgroundColor: "#f3f3f3",
                            height: "190px",
                            borderRadius: "8px",
                            borderColor: "#dedede",
                            marginTop: "20px",
                            paddingLeft:"10px",
                            border: "1px solid #BDBDBD"
                            
                        }}>
                          <Grid item xs={12}
                             style={{ textAlign: 'right'}}
                            >
                            <div  style={{ position: "absolute",
                                width: "200px",
                                right: "40px"}}
                                className="dropdown"
                                id={"dropdown-" + authConitem.id}>
                                <button
                               
                                    id={"dropdownMenuButton-" + authConitem.id}
                                    className="dropdown-toggle"
                                    type="button"
                                    data-testid={
                                        "dropdown-menu-item" + authConitem.id
                                    }
                                    data-toggle="dropdown"
                                    aria-haspopup="true"
                                    onClick={(e) =>
                                        props.onShowActionButtons(
                                            e,
                                            authConitem.id
                                        )
                                    }
                                    onBlur={(e) =>
                                        props.onHideActionButtons(
                                            e,
                                            authConitem.id
                                        )
                                    }
                                    >
                                    <img
                                        id="test"
                                        src={actionImg}
                                        alt="Action"
                                    />
                                </button>
                                <div
                                    className="dropdown-menu option-popup-width"
                                    id={"dropdown-menu-" + authConitem.id}
                                    data-testid={"dropdown-menu" + authConitem?.id}>
                                    <Link
                                        className="dropdown-item edit edit-alert-dropdownMenu"
                                        to="#"
                                        onMouseDown={() =>
                                            props.onEditAuthorization(
                                                authConitem.id
                                            )
                                        }
                                        data-testid={
                                            "dropdown-item-edit" + authConitem?.id
                                        }>
                                        {"Edit"}
                                    </Link>
                                    <Link
                                        className="dropdown-item edit edit-alert-dropdownMenu"
                                        to="#"
                                        onMouseDown={() =>
                                            props.onDeleteAuthorization(
                                                authConitem.id
                                            )
                                        }
                                        data-testid={
                                            "dropdown-item-delete" + authConitem?.id
                                        }>
                                        {"Delete"}
                                    </Link>
                                </div>
                            </div>
                        

                                </Grid> 
                           
                        <Grid item xs={2} sm={2} md={2} lg={2} xl={2}
                           
                            align={
                                props.insuranceType == "primaryInsurance"
                                    ? "left"
                                    : ""
                            }>
                            <Typography
                                style={{
                                    color: "#878787",
                                    fontSize: 14,
                                    fontFamily: "Lato",
                                }}>
                                {i18n.t(
                                    "patientPages.insuranceInfo.manageAuthorization.identifier"
                                )}
                            </Typography>
                            <Typography  style={{
                                    color: "#212121",
                                    fontWeight:"600",
                                    fontSize: 14,
                                    lineHeight:2,
                                    fontFamily: "Lato",
                                    wordWrap:"break-word"
                                }}>{authConitem.identifier}
                                </Typography>
                        </Grid>
                        <Grid item xs={2} sm={2} md={2} lg={2} xl={2}>
                            <Typography
                                style={{
                                    color: "#878787",
                                    fontSize: 14,
                                   
                                    fontFamily: "Lato",
                                }}>
                                {" "}
                                {i18n.t(
                                    "patientPages.insuranceInfo.manageAuthorization.type"
                                )}
                            </Typography>
                            <Typography style={{
                                    color: "#212121",
                                    fontWeight:"600",
                                    fontSize: 14,
                                    lineHeight:2,
                                    fontFamily: "Lato",
                                }}>
                                {" "}
                                {authConitem.authorization_referral_name}
                            </Typography>
                        </Grid>

                        <Grid item xs={2} sm={2} md={2} lg={2} xl={2}>
                            <Typography
                                style={{
                                    color: "#878787",
                                    fontSize: 14,
                                    
                                    fontFamily: "Lato",
                                }}>
                                {" "}
                                {i18n.t(
                                    "patientPages.insuranceInfo.manageAuthorization.unitsApproved"
                                )}
                            </Typography>
                            <Typography style={{
                                    color: "#212121",
                                    fontWeight:"600",
                                    fontSize: 14,
                                    lineHeight:2,
                                    fontFamily: "Lato",
                                }}>
                                {" "}
                                {authConitem.units_approved}
                            </Typography>
                        </Grid>

                        <Grid item xs={2} sm={2} md={2} lg={2} xl={2}>
                            <Typography
                                style={{
                                    color: "#878787",
                                    fontSize: 14,
                                   
                                    fontFamily: "Lato",
                                }}>
                                {" "}
                                {i18n.t(
                                    "patientPages.insuranceInfo.manageAuthorization.unitsUsed"
                                )}
                            </Typography>
                            <Typography style={{
                                    color: "#212121",
                                    fontWeight:"600",
                                    fontSize: 14,
                                    lineHeight:2,
                                    fontFamily: "Lato",
                                }}>{authConitem.units_used}</Typography>
                        </Grid>

                        <Grid item xs={2} sm={2} md={2} lg={2} xl={2}>
                            <Typography
                                style={{
                                    color: "#878787",
                                    fontSize: 14,
                                    
                                    fontFamily: "Lato",
                                }}>
                                {" "}
                                {i18n.t(
                                    "patientPages.insuranceInfo.manageAuthorization.unitsRemaining"
                                )}
                            </Typography>
                            <Typography style={{
                                    color: "#212121",
                                    fontWeight:"600",
                                    fontSize: 14,
                                    lineHeight:2,
                                    fontFamily: "Lato",
                                }}>
                                {" "}
                                {authConitem.units_remaining}
                            </Typography>
                        </Grid>

                       
                        <Grid item xs={2} sm={2} md={2} lg={2} xl={2}>
                            <Typography
                                style={{
                                    color: "#878787",
                                    fontSize: 14,
                                    
                                    fontFamily: "Lato",
                                }}>
                                {" "}
                                {i18n.t(
                                    "patientPages.insuranceInfo.manageAuthorization.validFrom"
                                )}
                            </Typography>
                           
                                <Typography style={{
                                    color: "#212121",
                                    fontWeight:"600",
                                    fontSize: 14,
                                    lineHeight:2,
                                    fontFamily: "Lato",
                                }}>
                                     <Moment format="MM/DD/YYYY">
                                    {authConitem.valid_from}
                                    </Moment>
                                </Typography>
                         
                        </Grid>
                        <Grid item xs={2} sm={2} md={2} lg={2} xl={2}>
                            <Typography
                                style={{
                                    color: "#878787",
                                    fontSize: 14,
                                    
                                    fontFamily: "Lato",
                                }}>
                                {" "}
                                {i18n.t(
                                    "patientPages.insuranceInfo.manageAuthorization.validTo"
                                )}
                            </Typography>
                            
                                <Typography  style={{
                                    color: "#212121",
                                    fontWeight:"600",
                                    fontSize: 14,
                                    lineHeight:2,
                                    fontFamily: "Lato",
                                }}>
                                    <Moment format="MM/DD/YYYY">
                                    {authConitem.valid_to}
                                    </Moment>
                                </Typography>
                           
                        </Grid>

                        <Grid item xs={8} sm={8} md={8} lg={8} xl={8}>
                            <Typography  style={{
                                   color: "#878787",
                                    fontSize: 14,
                                   
                                    fontFamily: "Lato",
                                }}>
                                {" "}
                                {i18n.t(
                                    "patientPages.insuranceInfo.manageAuthorization.provider"
                                )}
                            </Typography>
                            <Typography style={{
                                    color: "#212121",
                                    fontWeight:"600",
                                    fontSize: 14,
                                    fontFamily: "Lato",
                                    lineHeight:2
                                }}>
                                {authConitem.authorization_referral == 2
                                    ? authConitem.referring_provider_name
                                    : authConitem.provider_name}
                            </Typography>
                        </Grid>

                    </Grid>
                );
            })}
        </Grid>
    );
};

export const InsuranceDataTable = (props) => {
    return (
        <>
            {props.insuraceList &&
                props.insuraceList.length !== 0 &&
                props.insuraceList.map((item, index) => {
                    return (
                        <div
                            className="bordering insurance-panel-box margin-bottom15"
                            style={{ paddingBottom: "0px" }}
                            key={index}>
                            {getInsuranceName(props.insuranceName, item)}
                            <div className="mb-5">
                                <div className="absolute">
                                    <label
                                        className=""
                                        htmlFor="labelSubsriber">
                                        {i18n.t(
                                            "patientPages.insuranceInfo.subscriber"
                                        )}
                                        :{" "}
                                        <span className="labelSubsriberName">
                                            {item.name} (
                                            {item.relationship_name})
                                        </span>
                                    </label>
                                </div>
                            </div>
                            <div className="insurance_body">
                                {(props.insuranceName ==
                                    "Secondary Insurance" ||
                                    props.insuranceName ==
                                        "Tertiary Insurance") &&
                                    props.patientEditable && (
                                        <div className="row lh-0">
                                            {item.last_checked_on && (
                                                <div className="padding-left20 mt-3 insurance_functional_links  text-heading-insurance">
                                                    <Link
                                                        to="#"
                                                        style={{
                                                            padding: "10px",
                                                        }}
                                                        onClick={(e) =>
                                                            props.getInsuranceEligibility(
                                                                e,
                                                                item
                                                            )
                                                        }>
                                                        {i18n.t(
                                                            "patientPages.insuranceInfo.lastCheckedOn"
                                                        )}
                                                        :{" "}
                                                        {format(
                                                            new Date(
                                                                item.last_checked_on
                                                            ),
                                                            "MM/dd/yyyy hh:mm a"
                                                        )}
                                                    </Link>
                                                </div>
                                            )}
                                        </div>
                                    )}
                                <div className="row">
                                    <div
                                        className="col-4 margin-top5"
                                        id="uitable">
                                        <table className="insurance_table">
                                            <tbody>
                                                <tr>
                                                    <td className="width-45-perc">
                                                        {i18n.t(
                                                            "patientPages.insuranceInfo.labelPolicyID"
                                                        )}
                                                    </td>
                                                    <td className="width-55-perc">
                                                        {item.policy_id}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        {i18n.t(
                                                            "patientPages.insuranceInfo.labelGroupNumber"
                                                        )}
                                                    </td>
                                                    <td>{item.group_number}</td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        {i18n.t(
                                                            "patientPages.insuranceInfo.labelCopay"
                                                        )}
                                                    </td>
                                                    <td>
                                                        {currencyFormat(
                                                            item.copay
                                                        )}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        {i18n.t(
                                                            "patientPages.insuranceInfo.labelCoInsurance"
                                                        )}
                                                    </td>
                                                    <td>
                                                        {currencyFormat(
                                                            item.co_insurance
                                                                ? parseFloat(
                                                                      item.co_insurance
                                                                  ).toFixed(2)
                                                                : "0.00"
                                                        )}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        {i18n.t(
                                                            "patientPages.insuranceInfo.labelDeductible"
                                                        )}
                                                    </td>
                                                    <td>
                                                        {currencyFormat(
                                                            item.deductibles
                                                        )}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        {i18n.t(
                                                            "patientPages.insuranceInfo.labelOutOfPocket"
                                                        )}
                                                    </td>
                                                    <td>
                                                        {currencyFormat(
                                                            item.out_of_pocket
                                                        )}
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                    <div className="col insurance_cards margin-top-m35">
                                        {checkPermission(
                                            permission_key_values_patient.can_change_insurance_info
                                        ) && (
                                            <button
                                                type="button"
                                                data-testid={`${props.insuranceName}Edit-${index}`}
                                                className="btn btn-primary-blue btn-common-height28"
                                                onClick={() =>
                                                    props.editInsurance(item.id)
                                                }>
                                                {i18n.t(
                                                    "patientPages.buttons.edit"
                                                )}
                                            </button>
                                        )}
                                        <button
                                            type="button"
                                            className="btn btn-primary-blue  btn-common-height28"
                                            data-testid={`${props.insuranceName}ShowAuthorization-${index}`}
                                            onClick={() =>
                                                props.showAuthorizations(
                                                    item.id
                                                )
                                            }>
                                            {i18n.t(
                                                "patientPages.buttons.manageAuthorization"
                                            )}
                                        </button>
                                        {getImageCard(
                                            item,
                                            props.insuranceName,
                                            index,
                                            props
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    );
                })}
        </>
    );
};

//insurance name is displayed here
const getInsuranceName = (insuranceName, item) => {
    if (insuranceName == "Primary Insurance") {
        return (
            <div className="insurance_names">
                <label className="div-inline">
                    {i18n.t("patientPages.insuranceInfo.labelPrimary")} {" - ("}{" "}
                    {item.insurance_name} ({item.insurance_id}) {" )"}
                </label>
            </div>
        );
    } else if (insuranceName == "Secondary Insurance") {
        return (
            <div className="insurance_names">
                <label className="div-inline">
                    {i18n.t("patientPages.insuranceInfo.labelSecondary")}{" "}
                    {" - ("} {item.insurance_name} ({item.insurance_id}) {" )"}
                </label>
            </div>
        );
    } else {
        return (
            <div className="insurance_names">
                <label className="div-inline">
                    {i18n.t("patientPages.insuranceInfo.labelTertiary")}{" "}
                    {" - ("} {item.insurance_name} ({item.insurance_id}) {" )"}
                </label>
            </div>
        );
    }
};

//image card rendering with insurance type

const getImageCard = (item, insuranceName, index, props) => {
    if (insuranceName == "Primary Insurance") {
        return (
            <div
                className="imageCardMainRow"
                style={{
                    display: "flex",
                    justifyContent: "normal",
                }}>
                <div className="imageCardCol">
                    <div className="imageCardRow">
                        {item.card_front_page && (
                            <div>
                                <ProfileCardForInsurance
                                    image={item.card_front_page}
                                    alt={i18n.t(
                                        "patientPages.insuranceInfo.insuranceCardFront"
                                    )}
                                    id={"insuranceCardFront" + index}
                                    onClick={() =>
                                        props.callClickEvent(
                                            item.id,
                                            "file_PrimaryFrontCard" + index
                                        )
                                    }
                                    onMouseOver={() =>
                                        props.showImageAddEdit(
                                            "show",
                                            "divCardImageAddFront" + index
                                        )
                                    }
                                    onMouseLeave={() =>
                                        props.showImageAddEdit(
                                            "hide",
                                            "divCardImageAddFront" + index
                                        )
                                    }
                                    onRemoveImage={() =>
                                        props.resetImage(
                                            item.id,
                                            "insuranceCardFront"
                                        )
                                    }>
                                    {i18n.t(
                                        "patientPages.insuranceInfo.insuranceCardFront"
                                    )}
                                </ProfileCardForInsurance>
                            </div>
                        )}
                        {!item.card_front_page && (
                            <div className="width-100-perc">
                                <ProfileCardForInsurance
                                    image={imgCardFrontdAdd}
                                    id={"insuranceCardFront1" + index}
                                    alt={i18n.t(
                                        "patientPages.insuranceInfo.insuranceCardFront"
                                    )}
                                    style={{ marginLeft: "12px" }}
                                    className={"width-125px"}
                                    onClick={() =>
                                        props.callClickEvent(
                                            item.id,
                                            "file_PrimaryFrontCard" + index
                                        )
                                    }>
                                    <div className="fontSize14">
                                        {i18n.t(
                                            "patientPages.insuranceInfo.insuranceCardFront"
                                        )}
                                    </div>
                                </ProfileCardForInsurance>
                            </div>
                        )}
                        <div
                            className="hidden"
                            id={"divCardImageAddFront" + index}>
                            {checkPermission(
                                permission_key_values_patient.can_change_insurance_card
                            ) && (
                                <div
                                    className="card-image"
                                    onMouseOver={() =>
                                        props.showImageAddEdit(
                                            "show",
                                            "divCardImageAddFront" + index
                                        )
                                    }>
                                    <input
                                        type="file"
                                        id={"file_PrimaryFrontCard" + index}
                                        name={"file_PrimaryFrontCard" + index}
                                        onChange={(e) =>
                                            props.onHandleImageChange(
                                                e,
                                                item.id
                                            )
                                        }
                                        accept=".png, .jpg, .jpeg"
                                    />
                                </div>
                            )}
                        </div>
                        <div className="mt-5-copy">
                            {checkPermission(
                                permission_key_values_patient.can_view_eligibility_insurance_info
                            ) && (
                                <button
                                    type="button"
                                    className="btn btn-primary-blue  btn-common-height28"
                                    data-testid={`check-elibility-${item.id}`}
                                    onClick={(e) =>
                                        props.checkInsuranceEligibility(e, item)
                                    }>
                                    {i18n.t(
                                        "patientPages.buttons.checkEligibility"
                                    )}
                                </button>
                            )}
                            {
                                item.last_checked_on && (
                                    <Link
                                        to="#"
                                        data-testid={`get-elibility-${item.id}`}
                                        className={"absolute"}
                                        onClick={(e) =>
                                            props.getInsuranceEligibility(
                                                e,
                                                item
                                            )
                                        }>
                                        {i18n.t(
                                            "patientPages.insuranceInfo.lastCheckedOn"
                                        )}
                                        :{" "}
                                        {format(
                                            new Date(item.last_checked_on),
                                            "MM/dd/yyyy hh:mm a"
                                        )}
                                    </Link>
                                )
                                // </div>
                            }
                        </div>
                    </div>
                </div>

                <div className="imageCardCol">
                    <div
                        className=" imageCardRow"
                        style={{
                            display: "flex",
                            justifyContent: "space-around",
                        }}>
                        {item.card_back_page && (
                            <div>
                                <ProfileCardForInsurance
                                    image={item.card_back_page}
                                    alt={i18n.t(
                                        "patientPages.insuranceInfo.insuranceCardBack"
                                    )}
                                    id={"insuranceCardBack" + index}
                                    onMouseOver={() =>
                                        props.showImageAddEdit(
                                            "show",
                                            "divCardImageAddBack" + index
                                        )
                                    }
                                    onMouseLeave={() =>
                                        props.showImageAddEdit(
                                            "hide",
                                            "divCardImageAddBack" + index
                                        )
                                    }
                                    onClick={() =>
                                        props.callClickEvent(
                                            item.id,
                                            "file_PrimaryBackCard" + index
                                        )
                                    }
                                    onRemoveImage={() =>
                                        props.resetImage(
                                            item.id,
                                            "insuranceCardBack"
                                        )
                                    }>
                                    <div className="fontSize14 width-100-perc d-flex justify-content-between">
                                        {i18n.t(
                                            "patientPages.insuranceInfo.insuranceCardBack"
                                        )}
                                    </div>
                                </ProfileCardForInsurance>
                            </div>
                        )}
                        {!item.card_back_page && (
                            <div>
                                <div className="width-100-perc">
                                    <ProfileCardForInsurance
                                        image={imgCardBackdAdd}
                                        alt={i18n.t(
                                            "patientPages.insuranceInfo.insuranceCardBack"
                                        )}
                                        id={"insuranceCardBack11" + index}
                                        title={i18n.t(
                                            "patientPages.insuranceInfo.insuranceCardBack"
                                        )}
                                        style={{ marginLeft: "12px" }}
                                        className={"width-125px"}
                                        onClick={() =>
                                            props.callClickEvent(
                                                item.id,
                                                "file_PrimaryBackCard" + index
                                            )
                                        }>
                                        <div className="fontSize14">
                                            {i18n.t(
                                                "patientPages.insuranceInfo.insuranceCardBack"
                                            )}
                                        </div>
                                    </ProfileCardForInsurance>
                                </div>
                            </div>
                        )}
                        <div
                            className="hidden"
                            id={"divCardImageAddBack" + index}>
                            {checkPermission(
                                permission_key_values_patient.can_change_insurance_card
                            ) && (
                                <div
                                    className="card-image"
                                    onMouseOver={() =>
                                        props.showImageAddEdit(
                                            "show",
                                            "divCardImageAddBack" + index
                                        )
                                    }>
                                    <input
                                        type="file"
                                        id={"file_PrimaryBackCard" + index}
                                        name={"file_PrimaryBackCard" + index}
                                        onChange={(e) =>
                                            props.onHandleImageChange(
                                                e,
                                                item.id
                                            )
                                        }
                                        accept=".png, .jpg, .jpeg"
                                    />
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        );
    } else if (insuranceName == "Secondary Insurance") {
        return (
            <div
                className="imageCardMainRow"
                style={{
                    display: "flex",
                    justifyContent: "normal",
                }}>
                <div className="imageCardCol">
                    <div className="imageCardRow">
                        {item.card_front_page && (
                            <div>
                                <ProfileCardForInsurance
                                    image={item.card_front_page}
                                    alt={i18n.t(
                                        "patientPages.insuranceInfo.insuranceCardFront"
                                    )}
                                    id={"insuranceCardFront21" + index}
                                    onMouseOver={() =>
                                        props.showImageAddEdit(
                                            "show",
                                            "divCardImageAddFrontSecondary" +
                                                index
                                        )
                                    }
                                    onMouseLeave={() =>
                                        props.showImageAddEdit(
                                            "hide",
                                            "divCardImageAddFrontSecondary" +
                                                index
                                        )
                                    }
                                    onRemoveImage={() =>
                                        props.resetImage(
                                            item.id,
                                            "insuranceCardFront"
                                        )
                                    }
                                    onClick={() =>
                                        props.callClickEvent(
                                            item.id,
                                            "file_SecondaryFrontCard" + index
                                        )
                                    }>
                                    <div className="fontSize14 width-100-perc d-flex justify-content-between">
                                        {i18n.t(
                                            "patientPages.insuranceInfo.insuranceCardFront"
                                        )}
                                    </div>
                                </ProfileCardForInsurance>
                            </div>
                        )}
                        {!item.card_front_page && (
                            <div>
                                <ProfileCardForInsurance
                                    image={imgCardFrontdAdd}
                                    id={"insuranceCardFront22" + index}
                                    style={{ marginLeft: "12px" }}
                                    className={"width-125px"}
                                    alt={i18n.t(
                                        "patientPages.insuranceInfo.insuranceCardFront"
                                    )}
                                    title={i18n.t(
                                        "patientPages.insuranceInfo.insuranceCardFront"
                                    )}
                                    onClick={() =>
                                        props.callClickEvent(
                                            item.id,
                                            "file_SecondaryFrontCard" + index
                                        )
                                    }>
                                    <div className="fontSize14">
                                        {i18n.t(
                                            "patientPages.insuranceInfo.insuranceCardFront"
                                        )}
                                    </div>
                                </ProfileCardForInsurance>
                            </div>
                        )}
                        <div
                            className="hidden"
                            id={"divCardImageAddFrontSecondary" + index}>
                            {checkPermission(
                                permission_key_values_patient.can_change_insurance_card
                            ) && (
                                <div
                                    className="card-image"
                                    onMouseOver={() =>
                                        props.showImageAddEdit(
                                            "show",
                                            "divCardImageAddFrontSecondary" +
                                                index
                                        )
                                    }>
                                    <input
                                        type="file"
                                        id={"file_SecondaryFrontCard" + index}
                                        name={"file_SecondaryFrontCard" + index}
                                        onChange={(e) =>
                                            props.onHandleImageChange(
                                                e,
                                                item.id
                                            )
                                        }
                                        accept=".png, .jpg, .jpeg"
                                    />
                                </div>
                            )}
                        </div>
                        <div className="mt-5-copy">
                            {checkPermission(
                                permission_key_values_patient.can_view_eligibility_insurance_info
                            ) && (
                                <button
                                    type="button"
                                    className="btn btn-primary-blue  btn-common-height28"
                                    onClick={(e) =>
                                        props.checkInsuranceEligibility(e, item)
                                    }>
                                    {i18n.t(
                                        "patientPages.buttons.checkEligibility"
                                    )}
                                </button>
                            )}
                        </div>
                    </div>
                </div>
                <div className="imageCardCol">
                    <div className="imageCardRow">
                        {item.card_back_page && (
                            <div>
                                <ProfileCardForInsurance
                                    image={item.card_back_page}
                                    alt={i18n.t(
                                        "patientPages.insuranceInfo.insuranceCardBack"
                                    )}
                                    id={"insuranceCardBack22" + index}
                                    onMouseOver={() =>
                                        props.showImageAddEdit(
                                            "show",
                                            "divCardImageAddBackSecondary" +
                                                index
                                        )
                                    }
                                    onMouseLeave={() =>
                                        props.showImageAddEdit(
                                            "hide",
                                            "divCardImageAddBackSecondary" +
                                                index
                                        )
                                    }
                                    onClick={() =>
                                        props.callClickEvent(
                                            item.id,
                                            "file_SecondaryBackCard" + index
                                        )
                                    }
                                    onRemoveImage={() =>
                                        props.resetImage(
                                            item.id,
                                            "insuranceCardBack"
                                        )
                                    }>
                                    <div className="fontSize14 width-100-perc d-flex justify-content-between">
                                        {i18n.t(
                                            "patientPages.insuranceInfo.insuranceCardBack"
                                        )}
                                    </div>
                                </ProfileCardForInsurance>
                            </div>
                        )}
                        {!item.card_back_page && (
                            <div>
                                <ProfileCardForInsurance
                                    image={imgCardBackdAdd}
                                    id={"insuranceCardBack23" + index}
                                    style={{ marginLeft: "12px" }}
                                    className={"width-125px"}
                                    alt={i18n.t(
                                        "patientPages.insuranceInfo.insuranceCardBack"
                                    )}
                                    title={i18n.t(
                                        "patientPages.insuranceInfo.insuranceCardBack"
                                    )}
                                    onClick={() =>
                                        props.callClickEvent(
                                            item.id,
                                            "file_SecondaryBackCard" + index
                                        )
                                    }>
                                    <div className="fontSize14">
                                        {i18n.t(
                                            "patientPages.insuranceInfo.insuranceCardBack"
                                        )}
                                    </div>
                                </ProfileCardForInsurance>
                            </div>
                        )}

                        <div
                            className="hidden"
                            id={"divCardImageAddBackSecondary" + index}>
                            {checkPermission(
                                permission_key_values_patient.can_change_insurance_card
                            ) && (
                                <div
                                    className="card-image"
                                    onMouseOver={() =>
                                        props.showImageAddEdit(
                                            "show",
                                            "divCardImageAddBackSecondary" +
                                                index
                                        )
                                    }>
                                    {/* <label htmlFor="file_SecondaryBackCard"><i className="las la-pen"></i></label> */}
                                    <input
                                        type="file"
                                        id={"file_SecondaryBackCard" + index}
                                        name={"file_SecondaryBackCard" + index}
                                        onChange={(e) =>
                                            props.onHandleImageChange(
                                                e,
                                                item.id
                                            )
                                        }
                                        accept=".png, .jpg, .jpeg"
                                    />
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        );
    } else {
        return (
            <div
                className="imageCardMainRow"
                style={{
                    display: "flex",
                    justifyContent: "normal",
                }}>
                <div className="imageCardCol">
                    <div className="imageCardRow">
                        {item.card_front_page && (
                            <div>
                                <ProfileCardForInsurance
                                    image={item.card_front_page}
                                    alt={i18n.t(
                                        "patientPages.insuranceInfo.insuranceCardFront"
                                    )}
                                    id={"insuranceCardFront32" + index}
                                    onMouseOver={() =>
                                        props.showImageAddEdit(
                                            "show",
                                            "divCardImageAddFrontTertiary" +
                                                index
                                        )
                                    }
                                    onMouseLeave={() =>
                                        props.showImageAddEdit(
                                            "hide",
                                            "divCardImageAddFrontTertiary" +
                                                index
                                        )
                                    }
                                    onClick={() =>
                                        props.callClickEvent(
                                            item.id,
                                            "file_TertiaryFrontCard" + index
                                        )
                                    }
                                    onRemoveImage={() =>
                                        props.resetImage(
                                            item.id,
                                            "insuranceCardFront"
                                        )
                                    }>
                                    <div className="fontSize14 width-100-perc d-flex justify-content-between">
                                        {i18n.t(
                                            "patientPages.insuranceInfo.insuranceCardFront"
                                        )}
                                    </div>
                                </ProfileCardForInsurance>
                            </div>
                        )}
                        {!item.card_front_page && (
                            <div>
                                <ProfileCardForInsurance
                                    image={imgCardFrontdAdd}
                                    alt={i18n.t(
                                        "patientPages.insuranceInfo.insuranceCardFront"
                                    )}
                                    id={"insuranceCardFront32" + index}
                                    style={{ marginLeft: "12px" }}
                                    className={"width-125px"}
                                    title={i18n.t(
                                        "patientPages.insuranceInfo.insuranceCardFront"
                                    )}
                                    onClick={() =>
                                        props.callClickEvent(
                                            item.id,
                                            "file_TertiaryFrontCard" + index
                                        )
                                    }>
                                    <div className="fontSize14">
                                        {i18n.t(
                                            "patientPages.insuranceInfo.insuranceCardFront"
                                        )}
                                    </div>
                                </ProfileCardForInsurance>
                            </div>
                        )}
                        <div
                            className="hidden"
                            id={"divCardImageAddFrontTertiary" + index}>
                            {checkPermission(
                                permission_key_values_patient.can_change_insurance_card
                            ) && (
                                <div
                                    className="card-image"
                                    onMouseOver={() =>
                                        props.showImageAddEdit(
                                            "show",
                                            "divCardImageAddFrontTertiary" +
                                                index
                                        )
                                    }>
                                    {/* <label htmlFor={"file_TertiaryFrontCard" + tIndex}><i className="las la-pen"></i></label> */}
                                    <input
                                        type="file"
                                        id={"file_TertiaryFrontCard" + index}
                                        name={"file_TertiaryFrontCard" + index}
                                        onChange={(e) =>
                                            props.onHandleImageChange(
                                                e,
                                                item.id
                                            )
                                        }
                                        accept=".png, .jpg, .jpeg"
                                    />
                                </div>
                            )}
                        </div>
                        <div className="mt-5-copy">
                            {checkPermission(
                                permission_key_values_patient.can_view_eligibility_insurance_info
                            ) && (
                                <button
                                    type="button"
                                    className="btn btn-primary-blue btn-common-height28"
                                    onClick={(e) =>
                                        props.checkInsuranceEligibility(e, item)
                                    }>
                                    {i18n.t(
                                        "patientPages.buttons.checkEligibility"
                                    )}
                                </button>
                            )}
                        </div>
                    </div>
                </div>
                <div className="imageCardCol">
                    <div className="imageCardRow">
                        {item.card_back_page && (
                            <div>
                                <ProfileCardForInsurance
                                    image={item.card_back_page}
                                    alt={i18n.t(
                                        "patientPages.insuranceInfo.insuranceCardBack"
                                    )}
                                    onMouseOver={() =>
                                        props.showImageAddEdit(
                                            "show",
                                            "divCardImageAddBackTertiary" +
                                                index
                                        )
                                    }
                                    onMouseLeave={() =>
                                        props.showImageAddEdit(
                                            "hide",
                                            "divCardImageAddBackTertiary" +
                                                index
                                        )
                                    }
                                    onClick={() =>
                                        props.callClickEvent(
                                            item.id,
                                            "file_TertiaryBackCard" + index
                                        )
                                    }
                                    id={"insuranceCardFront34" + index}
                                    onRemoveImage={() =>
                                        props.resetImage(
                                            item.id,
                                            "insuranceCardBack"
                                        )
                                    }
                                    title={i18n.t(
                                        "patientPages.insuranceInfo.insuranceCardBack"
                                    )}>
                                    <div className="fontSize14">
                                        {i18n.t(
                                            "patientPages.insuranceInfo.insuranceCardBack"
                                        )}
                                    </div>
                                </ProfileCardForInsurance>
                            </div>
                        )}
                        {!item.card_back_page && (
                            <div>
                                <ProfileCardForInsurance
                                    image={imgCardBackdAdd}
                                    alt={i18n.t(
                                        "patientPages.insuranceInfo.insuranceCardBack"
                                    )}
                                    id={"insuranceCardFront34" + index}
                                    style={{ marginLeft: "12px" }}
                                    className={"width-125px"}
                                    title={i18n.t(
                                        "patientPages.insuranceInfo.insuranceCardBack"
                                    )}
                                    onClick={() =>
                                        props.callClickEvent(
                                            item.id,
                                            "file_TertiaryBackCard" + index
                                        )
                                    }>
                                    <div className="fontSize14">
                                        {i18n.t(
                                            "patientPages.insuranceInfo.insuranceCardBack"
                                        )}
                                    </div>
                                </ProfileCardForInsurance>
                            </div>
                        )}
                        <div
                            className="hidden"
                            id={"divCardImageAddBackTertiary" + index}>
                            {checkPermission(
                                permission_key_values_patient.can_change_insurance_card
                            ) && (
                                <div
                                    className="card-image"
                                    onMouseOver={() =>
                                        props.showImageAddEdit(
                                            "show",
                                            "divCardImageAddBackTertiary" +
                                                index
                                        )
                                    }>
                                    {/* <label htmlFor={"file_TertiaryBackCard" + tIndex}><i className="las la-pen"></i></label> */}
                                    <input
                                        type="file"
                                        id={"file_TertiaryBackCard" + index}
                                        name={"file_TertiaryBackCard" + index}
                                        onChange={(e) =>
                                            props.onHandleImageChange(
                                                e,
                                                item.id
                                            )
                                        }
                                        accept=".png, .jpg, .jpeg"
                                    />
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        );
    }
};
