import i18n from "../../utilities/i18n"

const TableHeadData = [
    {
        "name": i18n.t("interface.interfaceGrid.table.expand"),
        "type": "string",
        "width": "checkbox",
        "sort": false,
    },
    {
        "name": i18n.t("interface.interfaceGrid.table.select"),
        "type": "string",
        "width": "checkbox",
        "sort": false,
    },
    {
        "name": i18n.t("interface.interfaceGrid.table.status"),
        "type": "string",
        "width": "small",
        "sort": false,
    },
    {
        "name": i18n.t("interface.interfaceGrid.table.message_type"),
        "type": "string",
        "width": "small",
        "sort": false,
    },
    {
        "name": i18n.t("interface.interfaceGrid.table.patientName"),
        "type": "string",
        "width": "small",
        "sort": false,
    },
    {
        "name": i18n.t("interface.interfaceGrid.table.dateReceived"),
        "type": "string",
        "width": "small",
        "sort": false,
    },
    {
        "name": i18n.t("interface.interfaceGrid.table.dateOfService"),
        "type": "string",
        "width": "small",
        "sort": false,
    },
    {
        "name": i18n.t("interface.interfaceGrid.table.interface"),
        "type": "string",
        "width": "small",
        "sort": false,
    },
    {
        "name": i18n.t("interface.interfaceGrid.table.reprocessed"),
        "type": "string",
        "width": "small",
        "sort": false,
    },
];

export const TableBodyData = [
    [
        {
            "id": "",
            "name": "expand",
            "value": "",
            "type": "expand",
            "align": "center",
            "width": "checkbox",
            "isExpanded": false,
            "expandData": {"dataFrom":"interface", dataObject:""}
        },
        {
            "id": "",
            "name": "checkbox",
            "value": "",
            "type": "checkbox",
            "align": "center",
            "width": "checkbox",
            "isExpanded": false,
            "hideCheckbox": "false",
            "checkStatusSuccessToHide": "yes"
        },
        {
            "id": "",
            "name": "status",
            "value": "",
            "type": "string",
            "align": "left",
            "width": "small",
            "commentButton": 'show',
            "isExpanded": false,
        },
        {
            "id": "",
            "name": "message_types",
            "value": "",
            "type": "string",
            "align": "left",
            "width": "small",
            "isExpanded": false,
        },
        {
            "id": "",
            "name": "patient_name",
            "value": "",
            "type": "string",
            "align": "left",
            "width": "small",
            "isExpanded": false,
        },
        {
            "id": "",
            "name": "created",
            "value": "",
            "type": "string",
            "align": "center",
            "width": "small",
            "isExpanded": false,
        },
        {
            "id": "",
            "name": "dos",
            "value": "",
            "type": "string",
            "align": "center",
            "width": "small",
            "isExpanded": false,
        },
        {
            "id": "",
            "name": "",
            "value": "Test",
            "type": "string",
            "align": "left",
            "width": "small",
            "isExpanded": false,
        }, 
        {
            "id": "",
            "name": "reprocessed",
            "value": "",
            "type": "string",
            "align": "center",
            "width": "small",
            "isExpanded": false,
        },
    ]
];

export const InterFaceListTable = {
    "name": "simpleTable",
    "isResize": false,
    "isFixedHead": false,
    "tableHeadings": TableHeadData,
    "tableBodyData": TableBodyData,
    "rowExpansion": "allRows",
    "colSpanNum": 9
}
