import api from "../../../service/api";
const PatientsLegderList = async (pageSize, page, practicePK, searchValue,
	// DOBDate,
	patientPK, orderingField, orderType) => {
	let path = '/patient/patients-ledger-list/?page_size=' + pageSize + '&page=' + page + '&practice_pk=' + practicePK;
	if (orderingField) {
		let fieldOrder = "";
		if (orderType)
			fieldOrder += orderType;
		fieldOrder += orderingField;
		path += "&column=" + fieldOrder;
	}
	// if (DOBDate)
	//     path = path + '&dob=' + DOBDate;
	if (searchValue && searchValue !== '')
		path = path + '&search=' + searchValue;

	if (patientPK)
		path += "&patient_pk=" + patientPK;
	return api.get(path);
}

export default {
	PatientsLegderList
}