import React, { useState, useEffect, useContext } from 'react';
import { format } from 'date-fns';
import { Redirect } from 'react-router-dom';

import './style.css';
import ledgerService from './service';
import PatientsLedgerHeader from './PatientsLedgerHead';

import service from '../patients/service';
import PatientAddNotes from '../patients/PatientAddNotes';

import arClaimService from '../../ClaimsModule/ARManagement/service';
import i18n from '../../../utilities/i18n';
import { getStorage, setStorage } from '../../../utilities/browserStorage';
import { ROUTE_VIEW_PAYMENTS, ROUTE_PATIENTS_LEDGER_LIST } from '../../../utilities/staticConfigs';

import TableComponent from '../../commons/tableComponent/tableComponent';
import RightSidePanel from '../../commons/RightSidePanel/RightSidePanelIndivdualPatient';

import PatientLedgerFilter from '../../patientManagement/patientLedger/patientLedgerFilter';

import Tasks from '../../../components/MyTasksScreen/Tasks';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import { Box, Divider, Tab } from '@mui/material';
import { mainHeadings, subHedaings1, subHedaings2 } from './patientLedgerConstant';
import { CreditBalanceTable, PatientSummaryTable } from './patientLedgerTables';
import { checkPermission } from '../../../utilities/commonUtilities';
import { permission_key_values_patient } from '../../../utilities/permissions';
import CommonButton from '../../commons/Buttons';
import BackArrowWithLabel from '../../commons/Back';
import LoadingContext from '../../../container/loadingContext';

const PatientsLedger = (props) => {
    const innerWidth=window.innerWidth;
    const [activeTab, setActiveTab] = useState("ledger");
    const [patientPK, setPatientPK] = useState("");
    const [gotoLedgerList, setGotoLedgerList] = useState(false);
    const startDate=format(new Date(new Date() - (180 * 86400000)), 'yyyy-MM-dd');
    const endDate=format(new Date(), 'yyyy-MM-dd');
    const [paymentSummary, setPaymentSummary] = useState('');

    const [advancedQuery, setAdvancedQuery] = useState([]);
    const [searchFilter, setSearchFilter] = useState(false);
    const [gotoURL, setGotoURL] = useState(false);
    const [URLPath, setURLPath] = useState('');
    const [selectedID, setSelectedID] = useState('');
    const [mainDatas, setMainDatas] = useState([[]])
    const [subDatas1, setSubDatas1] = useState([[]]);
    const [subDatas2, setSubDatas2] = useState([
        []
    ]);
    const setShowLoadingBar = useContext(LoadingContext); 

    const [patientCredits, setPatientCredits] = useState([])

    const [insuranceCredits, setInsuranceCredits] = useState([])

    useEffect(() => {
    
        if (searchFilter) {
            GetPatientPaymentSummary(patientPK, advancedQuery);
            setSearchFilter(false);
        }
       
    }, [searchFilter])

    // routeState is set for get location.state after when refresh after taking filter by righg side menu link 
    let routeState
    if (props.location.state) {
        setStorage('routeState', JSON.stringify(props.location.state))
        routeState = props.location.state
    } else {
        routeState = getStorage('routeState')
        if (routeState) routeState = JSON.parse(routeState)
    }

    let logCodeRouteIds = {
        payment_payment: ROUTE_VIEW_PAYMENTS,
    }
    let logCodeRoutes = {
        // 
    }

    // SOME CODES JUST NEED TO BE HIGHLIGHTED ADD THEM HERE (NO CLICK AND REDIRECTION)
    let logCodeHighLight = {
    }

    function onTabChange(e, tab) {
        setActiveTab(tab);
        if(tab === 'creditBalance'){
            GetCreditBalanceView(patientPK);
        }
    }

    function GetCreditBalanceView(patient_pk){
        const result = ledgerService.GetCreditBalanceView(patient_pk);
        result.then((response) => {
            setPatientCredits(response.data.patient_credits);
            setInsuranceCredits(response.data.insurance_credits);
        });
    }

    function GetPatientPaymentSummary(patientID, query) {
        setShowLoadingBar(true);
        const result = service.GetPatientPaymentSummaryDetails(patientID, startDate, endDate, query);
        result.then((response) => {
            setPaymentSummary(response.data);
            setShowLoadingBar(false);
        });
    }

    useEffect(() => {
       
        setPatientPK(routeState.patientPK);
        GetPatientPaymentSummary(routeState.patientPK);
        GetPatientLedgerCliams(routeState.patientPK);
       
    }, []);

    function redirectToList() {
        setGotoLedgerList(true);
    }

    if (gotoLedgerList) {
        return <Redirect to={{ pathname: ROUTE_PATIENTS_LEDGER_LIST }} />;
    }

    function getArrayLogCode(arrLogCode,content){
        let logFromArray = [];
        let arrLogCodeNext = [];
        let codeValue = '';
        let dataObj = {};
        let titleData = '';
        let logCodeType = '';
        let logTypeData = '';
        logFromArray.push(arrLogCode[0]);
        arrLogCode.forEach(logCodeItem => {
            if (logCodeItem.indexOf('>>') >= 0)  {
                arrLogCodeNext = logCodeItem.trim().split('>>'); // e.g. "userby>>"  OR "practice>>." 
                codeValue = String(arrLogCodeNext[0]).trim();
                if (content[codeValue]) {
                    dataObj = content[codeValue];
                    titleData = (dataObj.title.trim().length) ? dataObj.title : 'unknown';
                    logTypeData = (dataObj.type.trim().length) ? dataObj.type : '';
                    logCodeType = codeValue + '_' + logTypeData;
                    let idValue = '';
                    let filterRoute = '';
                    let textBold = '';
                    if (logCodeRouteIds[logCodeType]) {
                        filterRoute = logCodeRouteIds[logCodeType];
                        idValue = (dataObj.id) ? dataObj.id : '';
                    }
                    else if (logCodeRoutes[logCodeType]) {
                        filterRoute = logCodeRoutes[logCodeType];
                        idValue = "";
                    }
                    else if (logCodeHighLight[logCodeType]) {
                        filterRoute = "";
                        idValue = "";
                        textBold = 'text-bold';
                    }
                    if (filterRoute && filterRoute.length) {
                        if (logCodeType === 'payment_payment') {
                            // TO OPEN THE CLAIM IN SEARCH CLAIMS PAGE, WE NEED CLAIM PKID AND CLAIMID 
                            idValue = content[codeValue];
                        }
                        logFromArray.push(<span className="logTxtColorA" onClick={e => redirectPage(e, filterRoute, idValue)} > {titleData} </span>);
                    }
                    else {
                        logFromArray.push(<span className={textBold}> {titleData} </span>);
                    }
                    logFromArray.push(arrLogCodeNext.splice(1, arrLogCodeNext.length).join(' '));
                }
            }
        });

        return logFromArray;
    }

    function GetPatientLedgerCliams(patient_PK) {
        const result = ledgerService.PatientLedgerClaimList(patient_PK);
        result.then((response) => {
            let mainData = [];
            if (response.data && response.data.length > 0) {
                mainData = response.data.map((mainDataList ) => {
                    let arrLogCode = [];
                    mainDataList[12].forEach(item =>  {
                        if (item[12]) {
                            let index = 0;
                            item[12].forEach(item2 => {
                                index += 1;
                                let logFromArray =[];
                                // console.log(item2)
                                let logStr = item2.splice(2, 1)[0];
                                let content = item2.splice(6, 1)[0];
                                arrLogCode = logStr.trim().split('<<');
                                logFromArray=getArrayLogCode(arrLogCode,content)
                                item2.splice(2, 0, logFromArray);
                                let colorCode = '#';
                                if (item2[7] === '') {
                                    if (index % 2 === 0)
                                        colorCode += 'eea5a5';
                                    else
                                        colorCode += 'ffffff';
                                } else {
                                    colorCode += item2[7];
                                }
                                item2.splice(7, 0, colorCode);
                                item2.splice(8, 1);

                            })
                        }
                    });
                    //here ,The patient ledger page is to be crashed when clicking the patient name.
                    // the entire code checks if each item in the mainDataList array is an object and has a "payer_id" property. 
                    //If true, it returns the value of the "name" property of that object; otherwise, it returns the original item. 
                    return mainDataList.map((item) => typeof item === "object" && Object.prototype.hasOwnProperty.call(item, "payer_id") ? item?.name : item);
                });
            }
            setMainDatas(mainData);
        });
    }

    function GetClaimProcedure(pk) {
        const result = ledgerService.PatientLedgerProceduresList(pk);
        result.then((response) => {
            let subData1 = [];
            subData1 = response.data.map((subData1List, index) => {
                subData1List.push(false);
                if (index % 2 === 0) {
                    subData1List.push("bgRed");
                } else {
                    subData1List.push("bgWhite");
                }
                return subData1List
            });
            setSubDatas1(subData1);

        });
    }

    function redirectPage(e, routeValue, idValue) {
        if (routeValue) {
            setURLPath(routeValue);
            setSelectedID(idValue);
            setGotoURL(true);
        }
    }

    if (gotoURL) {
        return <Redirect to={{ pathname: URLPath, state: { selectedID: selectedID } }} />;
    }

    function GetCPTActionLogs(pk) {
        const result = arClaimService.CPTActionLogs(pk);
        result.then((response) => {
            let subData2 = [];
            let arrLogCode = [];
            let bgColorCodes = {
                'red': 'bgBaseRed',
                'green': 'bgBaseGreen',
                'blue': 'bgBaseBlue',
                'white': 'bgBaseWhite',
            }
            let txtColorCodes = {
                'red': 'txtBaseRed',
                'green': 'txtBaseGreen',
                'blue': 'txtBaseBlue',
                'white': 'txtBaseWhite',
            }

            subData2 = response.data.map((subData2List, index) => {
                let logFromArray = [];
                let logStr = subData2List.splice(2, 1)[0];
                let content = subData2List.splice(6, 1)[0];
                arrLogCode = logStr.trim().split('<<');
                logFromArray=getArrayLogCode(arrLogCode,content)
                subData2List.splice(2, 0, logFromArray);
                subData2List.splice(7, 0, false);
                let colorCode = '';
                if (bgColorCodes[subData2List[8]]) {
                    colorCode += bgColorCodes[subData2List[8]];
                } else {
                    if (index % 2 === 0) {
                        colorCode += 'bgRed';
                    } else {
                        colorCode += 'bgWhite';
                    }
                }
                if (txtColorCodes[subData2List[9]]) {
                    colorCode += ' ' + txtColorCodes[subData2List[9]];
                } else {
                    if (index % 2 === 0) {
                        colorCode += ' ' + 'bgRed';
                    } else {
                        colorCode += ' ' + 'bgWhite';
                    }
                }
                subData2List.splice(8, 0, colorCode);
                subData2List.splice(9, 1);
                return subData2List
            });
            setSubDatas2(subData2);

        });

    }

    return (
        <React.Fragment>
            <div className="col-md-12">
                    <div className='mb-4 ml-2' onClick={redirectToList}><BackArrowWithLabel  label={"Patient Ledger"}/></div>
                    <div className="patient-ledger-box mr-3" >
                        <div className='col-md-12 div-full'>
                            <div className="header-text div-float-left">{paymentSummary.name}</div>
                        </div>
                        <TabContext value={activeTab} >
                            <Box className="margin-left-24">
                                <TabList onChange={onTabChange} aria-label="lab API tabs example" >
                                    <Tab label={i18n.t("patientPages.patientLedger.ledger")} value={"ledger"} className='tab' />
                                    {checkPermission(permission_key_values_patient.patient_ledger_sub_module_modify) &&
                                        <Tab label={i18n.t("patientPages.patientLedger.assignTask")} value={"assignTask"} className='tab' />
                                    }
                                    <Tab label={i18n.t("patientPages.patientLedger.creditBalance")} value={"creditBalance"} className='tab' />
                                    <Tab label={i18n.t("patientPages.patientLedger.accountSummary")} value={"accountSummary"} className='tab' />
                                    <Tab label={i18n.t("patientPages.patientLedger.notes")} value={"notes"} className='tab' />
                                </TabList>
                                <Divider style={{ color: '#E0E0E0', marginBottom: 20 }} />
                            </Box>

                            <TabPanel value={"ledger"} className='searchBoxTab' >
                                <PatientsLedgerHeader patientPK={patientPK ? patientPK : routeState.patientPK} />
                                <div className='align-right mt-4 mb-2'><CommonButton variant="contained" onClick={redirectToList} label={i18n.t('buttons.close')} /></div>
                                <div className="row" style={{padding:"15px"}}>
                                    <div className="table-responsive">
                                        <div className='' style={{ width: "100%", maxWidth: (innerWidth - 290) + "px", maxHeight: '500px' }}>
                                            <TableComponent mainHeadings={mainHeadings} mainDatas={mainDatas} setMainDatas={setMainDatas} subHedaings1={subHedaings1} subDatas1={subDatas1} setSubDatas1={setSubDatas1} subHedaings2={subHedaings2} subDatas2={subDatas2}
                                                getSubData1={GetClaimProcedure} getSubData2={GetCPTActionLogs} keepExpandedState={true} calledFrom={"patientLedger"} />
                                        </div>
                                    </div>
                                </div>
                            </TabPanel>
                            <TabPanel value={"assignTask"} className='searchBoxTab'>
                                <div className="header-sub-text">{i18n.t("commons.patient") + ': ' + paymentSummary.name}</div>
                                <div className="">
                                    <Tasks calledFrom={"patientLedger"} patientPK={patientPK ? patientPK : routeState.patientPK} />
                                </div>
                            </TabPanel>
                            <TabPanel value={"creditBalance"} className='searchBoxTab'>
                                <PatientsLedgerHeader patientPK={patientPK ? patientPK : routeState.patientPK} />
                                <CreditBalanceTable data={patientCredits} />
                                <CreditBalanceTable data={insuranceCredits} />
                            </TabPanel>
                            <TabPanel value={"accountSummary"} className='searchBoxTab'>
                                <PatientsLedgerHeader patientPK={patientPK ? patientPK : routeState.patientPK} />
                                <div className="mt-4 mb-4">
                                    < RightSidePanel title={i18n.t("patientaccountsummary.filterBy")} onclickLabel={i18n.t("patientaccountsummary.filterBy")} states={{ patientPK: patientPK }}>
                                        <PatientLedgerFilter setAdvancedQuery={setAdvancedQuery} setSearchFilter={setSearchFilter}>
                                        </PatientLedgerFilter>
                                    </ RightSidePanel>
                                </div>
                                <div className="row uitable uitableRemovePadT">
                                    <div className="col-4 pl-0">
                                       <PatientSummaryTable paymentSummary={paymentSummary} table={'table1'}/>
                                    </div>
                                    <div className="col-5 ">
                                      <PatientSummaryTable paymentSummary={paymentSummary} table={'table2'}/>
                                    </div>
                                    <div className="col-3">
                                    </div>
                                </div>
                            </TabPanel>
                            <TabPanel value={"notes"} className='searchBoxTab'>
                                <PatientAddNotes patientPK={patientPK} noEditColumns={true} />
                            </TabPanel>
                        </TabContext>
                    </div>
            </div>
        </React.Fragment>
    )
}
export default PatientsLedger
