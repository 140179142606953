import React from 'react'

function CreditCardBox() {
    return (
        <>
            <div style={{ border: '1px solid #ccc', padding: '20px', borderRadius: '8px', backgroundColor: '#f9f9f9', width: '97%', boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)', margin: 'auto' }}>
                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '15px' }}>
                    <h5 style={{ margin: '0' }}>Credit Card Information</h5>
                    <img src="https://img.icons8.com/color/48/000000/mastercard-logo.png" alt="Credit Card Logo" style={{ height: '30px' }} />
                </div>
                <div style={{ marginBottom: '20px' }}>
                    <label htmlFor="cardNumber" style={{ fontSize: '0.8em' }}>Credit Card Number:</label><br />
                    <span id="cardNumber" style={{ fontSize: '1.2em', fontWeight: 'bold' }}></span>
                </div>
                <div style={{ marginBottom: '20px' }}>
                    <label htmlFor="cardHolderName" style={{ fontSize: '0.8em' }}>Card Holder Name:</label><br />
                    <span id="cardHolderName" style={{ fontSize: '1.2em', fontWeight: 'bold' }}></span>
                </div>
                <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '20px' }}>
                    <div style={{ flex: '1', marginRight: '20px' }}>
                        <label htmlFor="expiryDate" style={{ fontSize: '0.8em' }}>Expiry Date:</label><br />
                        <span id="expiryDate" style={{ fontSize: '1.2em', fontWeight: 'bold' }}></span>
                    </div>
                    <div style={{ flex: '1' }}>
                        <label htmlFor="cvv" style={{ fontSize: '0.8em' }}>CVV:</label><br />
                        <span id="cvv" style={{ fontSize: '1.2em', fontWeight: 'bold' }}></span>
                    </div>
                </div>

                <div style={{ marginBottom: '10px' }}>
                    <label style={{ fontSize: '0.8em' }}>Card Type</label><br />
                    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginTop: '5px' }}>
                        <label style={{ marginRight: '10px', display: 'flex', alignItems: 'center' }}>
                            <input type="checkbox" value="visa" style={{ marginRight: '5px', alignSelf: 'center' }} />
                            <img src="https://img.icons8.com/color/48/000000/visa.png" alt="Visa Logo" style={{ height: '20px', marginRight: '5px' }} />
                            Visa
                        </label>
                        <label style={{ marginRight: '10px', display: 'flex', alignItems: 'center' }}>
                            <input type="checkbox" value="master" style={{ marginRight: '5px', alignSelf: 'center' }} />
                            <img src="https://img.icons8.com/color/48/000000/mastercard-logo.png" alt="Mastercard Logo" style={{ height: '20px', marginRight: '5px' }} />
                            Master
                        </label>
                        <label style={{ marginRight: '10px', display: 'flex', alignItems: 'center' }}>
                            <input type="checkbox" value="discover" style={{ marginRight: '5px', alignSelf: 'center' }} />
                            <img src="https://img.icons8.com/color/48/000000/discover.png" alt="Discover Logo" style={{ height: '20px', marginRight: '5px' }} />
                            Discover
                        </label>
                        <label style={{ display: 'flex', alignItems: 'center' }}>
                            <input type="checkbox" value="amex" style={{ marginRight: '5px', alignSelf: 'center' }} />
                            <img src="https://img.icons8.com/color/48/000000/amex.png" alt="American Express Logo" style={{ height: '20px', marginRight: '5px' }} />
                            American Express
                        </label>
                    </div>
                </div>

                <p style={{ fontSize: '0.8em', marginTop: '10px', color: '#888' }}>
                    Please fill out the information and make sure all details are accurate and legible.
                </p>
            </div>
        </>
    )
}

export default CreditCardBox