import React, { useContext, useEffect, useState } from "react";
import { useHistory } from 'react-router-dom';
import { Dropdown } from "react-bootstrap";
import moment from 'moment';

import service from "./service";
import { DraftsTableData, TableBodyData } from './DraftsTable';

import LoadingContext from "../../container/loadingContext";

import Notify from "../commons/notify";
import Table from '../commons/Table/Table';
import Pagination from "../commons/pagination";

import { getStorage } from "../../utilities/browserStorage";
import * as staticConfigs from '../../utilities/staticConfigs'; 
import { DELETE_MESSAGE_SUCCESS } from "../../utilities/labelConfigs";

import actionImg from '../../assets/images/action.png';
import { checkPermission } from "../../utilities/commonUtilities";
import { permission_key_values_messages } from "../../utilities/permissions";

function DraftList() {
    const practicePK = getStorage('practice');
    const setShowLoadingBar = useContext(LoadingContext);

    const [draftMessagesList, setDraftMessagesList] = useState([]);
    const [selectedMessageList, setSelectedMessageList] = useState([]);
    const [allCheckBoxChecked, setAllCheckBoxChecked] = useState(false);
    const [disableDropdown, setDisableDropdown] = useState(false);

    // notify
    const [showNotify, setShowNotify] = useState("hide");
    const [notifyDescription, setNotifyDescription] = useState("");
    const [notifyType, setNotifyType] = useState("success");

    const history = useHistory();

    // pagination start
    const [totalPage, setTotalPage] = useState(1);
    const [activePage, setActivePage] = useState(1);
    const [startIndex, setStartIndex] = useState(0);
    const [endIndex, setEndIndex] = useState(staticConfigs.PAGING_END_INDEX);

    function onPagePrevious() {
        let previousPage = startIndex + 1 - staticConfigs.PAGING_END_INDEX;
        setActivePage(previousPage);
        if (startIndex !== 0) {
            setStartIndex(startIndex - staticConfigs.PAGING_END_INDEX);
            setEndIndex(endIndex - staticConfigs.PAGING_END_INDEX);
        }
        getDraftMessages(staticConfigs.DEFAULT_PAGING_SIZE, previousPage);
    }

    function onPageUp(e) {
        let page = Number(e.target.id)
        setActivePage(page);
        getDraftMessages(staticConfigs.DEFAULT_PAGING_SIZE, page);
    }

    function onPageNext() {
        let nextPage = startIndex + 1 + staticConfigs.PAGING_END_INDEX;
        if (endIndex === totalPage || totalPage <= staticConfigs.PAGING_END_INDEX) {
            setActivePage(nextPage);
            setStartIndex(startIndex);
            setStartIndex(endIndex);
        } else {
            setActivePage(nextPage);
            setStartIndex(startIndex + staticConfigs.PAGING_END_INDEX);
            setEndIndex(endIndex + staticConfigs.PAGING_END_INDEX);
        }
        getDraftMessages(staticConfigs.DEFAULT_PAGING_SIZE, nextPage);
    }


    function To_inbox_messages(id) {
        history.push(staticConfigs.ROUTE_DRAFT_INBOX_MESSAGES, { id: id });
    }

    function onSelectRowAll(e) {
        let selected = e.target.checked;
        setAllCheckBoxChecked(selected);

        DraftsTableData.tableBodyData.forEach(rowItem => {
            let index = rowItem.findIndex(cItem => cItem.type=='checkbox'); 
            rowItem[index].value =selected; 
        });

        setDraftMessagesList(() => {
            let newArray = [];
            draftMessagesList.map((item, index) => {
                newArray[index] = { ...item, 'checked': selected };
            });
            return newArray
        });
    }

    useEffect(() => {
        setSelectedMessageList(() => {
            let selected_ids = [];
            draftMessagesList.filter((item) => item.checked ).map((item) => {
                selected_ids.push(item.id);
            });
            return selected_ids;
        });
    }, [draftMessagesList]);

    function onSelectRow(e, id) {
        e.stopPropagation();
        let selected = e.target.checked;

        DraftsTableData.tableBodyData.forEach(rowItem => {
            if(rowItem.find(colItem => colItem.id==id)) {
                let index = rowItem.findIndex(cItem => cItem.type=='checkbox');
                rowItem[index].value =selected; 
            }
        });

        let newArray = [];
        setDraftMessagesList(() => {
            draftMessagesList.map((item, index) => {
                if (item.id == id) newArray[index] = { ...item, 'checked': selected };
                else newArray[index] = { ...item }
            });
            let selectedArray = newArray.filter((item) => item.checked );
            if (selectedArray.length == newArray.length) {
                setAllCheckBoxChecked(true);
            } else {
                setAllCheckBoxChecked(false);
            }
            return newArray;
        });
    }

    useEffect(() => {
        let page = 1;
        getDraftMessages(staticConfigs.DEFAULT_PAGING_SIZE, page);
    }, []);

    useEffect(() => {
        setDisableDropdown(()=>{
            return selectedMessageList.length === 0 ? true : false;
        })
    }, [selectedMessageList])

    function getDraftMessages(pageSize, page) {
        let query = '?practice_pk=' + practicePK + '&page_size=' + pageSize + '&page=' + page;
        setShowLoadingBar(true);
        let result = service.ListDraftMessages(query);
        let newArray = [];
        let rowArray = [];
        result.then((response) => {
            setAllCheckBoxChecked(false);
            let data = response.data.results;
            data.map((item, index) => {
                newArray[index] = { ...item, 'checked': false };
            });
            
            setTotalPage(Math.ceil(response.data.count / response.data.page_size));

            let anArray = [];
            let newData = Array(response.data.results.length).fill(JSON.parse(JSON.stringify(DraftsTableData.tableBodyData[0])));
            newData.map((row, rowIndex) => {
                row.map((col) => {
                    let colObject = {};
                    if (col.type === 'checkbox') {
                        colObject = {
                            ...col, 'value': false,
                            "id": response.data.results[rowIndex].message_id, 
                            'is_read': response.data.results[rowIndex].is_read
                        }
                    } 
                    else if (col.name === 'receivers') {
                        let senders = response.data.results[rowIndex][col.name].map(snd => snd.name)
                        colObject = {
                            ...col, 'value': senders,
                            "id": response.data.results[rowIndex].message_id, 
                            'is_read': response.data.results[rowIndex].is_read
                        }
                    }    
                    else if (col.name === 'created_on') {
                        colObject = {
                            ...col, 'value': moment(response.data.results[rowIndex][col.name]).format('MM/DD/YYYY  h:mm a'),
                            "id": response.data.results[rowIndex].message_id,
                            'is_read': response.data.results[rowIndex].is_read
                        }
                    }
                    else {
                        colObject = {
                            ...col, 'value': response.data.results[rowIndex][col.name],
                            "id": response.data.results[rowIndex].message_id,
                            'is_read': response.data.results[rowIndex].is_read 
                        }
                    }
                    anArray.push(colObject)
                })
                rowArray.push(anArray)
                anArray = [];
            });
            if (rowArray.length > 0) {
                DraftsTableData.tableBodyData = rowArray;
            }
            else {
                DraftsTableData.tableBodyData = TableBodyData;
            }

            setDraftMessagesList(newArray);
            setShowLoadingBar(false);

        })
    }

    function DeleteDraftMessageList() {
        if (selectedMessageList.length === 0) return;
        setShowLoadingBar(true);
        let data = {
            "message_type": "draft",
            "message_id": selectedMessageList
        }
        let result = service.DeleteMessage(data);
        result.then((response) => {
            setShowLoadingBar(false);
            if (response.status === 200) {
                showNotifyWindow("show", "success", DELETE_MESSAGE_SUCCESS);
                let page = 1;
                getDraftMessages(practicePK, page);
            }
        })
    }

    function showNotifyWindow(action, type, desc, age = 3000) {
        if (action === "show") {
            setTimeout(() => {
                setShowNotify("hide");
            }, age);
        }
        setShowNotify(action);
        setNotifyType(type);
        setNotifyDescription(desc);
    }

    function Refresh() {
        window.location.reload()
    }

    function onRowClick(e, id) {
        let index = 0; 
        DraftsTableData.tableBodyData.forEach(rowItem => {
            if(rowItem[0].id==id) {
                To_inbox_messages(rowItem[0].id); 
                return true; 
            }
            return index++; 
        });
    }

    function onClickGridCheckBox (e,clickedFrom,id) {
        e.stopPropagation(); 
        if(clickedFrom=='data') {
            onSelectRow(e, id);
        }
    }

    return (
        <div className="col-md-12">
            <div className="box basic-info-padding bordering border-radius-8"  style={{ padding: "15px"}} >
                <Notify showNotify={showNotify} setShowNotify={setShowNotify} notifyDescription={notifyDescription} setNotifyType={setNotifyType} setNotifyDescription={setNotifyDescription} notifyType={notifyType} />
                <div className="row justify-space-between">
                    <div className="col d-flex">
                        
                    {checkPermission(permission_key_values_messages.can_modify_message_draft) &&
                    <Dropdown className="padding-top2">
                            <Dropdown.Toggle variant="" id="dropdown-basic">
                                <input type="checkbox" name={'all'} value={'all'} data-testid="selectAll" className='mr-1 sizeChecbox' checked={allCheckBoxChecked} onChange={(e) => onSelectRowAll(e, 'all')} />
                            </Dropdown.Toggle>
                        </Dropdown>}
                        <Dropdown>
                            <Dropdown.Toggle variant="" id="dropdown-basic" data-testid="dropdown-basic-refresh" onClick={Refresh}>
                                <i className="fa-solid fa-rotate refreshIcon"></i>
                            </Dropdown.Toggle>
                        </Dropdown>
                        {checkPermission(permission_key_values_messages.can_modify_message_draft) &&
                         <Dropdown className="">
                            <Dropdown.Toggle variant="" id="dropdown-basic" data-testid="dropdown-basic-delete" align={{ lg: 'end' }}>
                                <img src={actionImg} alt='' />
                            </Dropdown.Toggle>
                            <Dropdown.Menu className="message_dropddown">
                                <Dropdown.Item href="#/action-3">
                                    <button data-testid="deleteButton" style={{ border: 'none', background: 'none', color: 'inherit',padding: 0,font: 'inherit',cursor: 'pointer',}} disabled={disableDropdown} onClick={DeleteDraftMessageList}>Delete</button>
                                </Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown> }
                    </div>
                </div>
                <div className="table-responsive">
                    <Table tableObject={DraftsTableData} isCheckboxDisabled={checkPermission(permission_key_values_messages.can_modify_message_draft)} onRowClick={onRowClick} onClickGridCheckBox={onClickGridCheckBox} />
                </div>
                <div className="mt-2">
                    <Pagination totalPage={totalPage} activePage={activePage} startIndex={startIndex} endIndex={endIndex} onPagePrevious={onPagePrevious} onPageUp={onPageUp} onPageNext={onPageNext} />
                </div>
            </div>
        </div>
    )
}

export default DraftList