import React, { useCallback, useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

import { getStorage } from "../../utilities/browserStorage";
import i18n from "../../utilities/i18n";
import Notify from "../commons/notify";
import TextInput from "../commons/input/input";
import RichTextEditor from "../commons/RichTextEditor/RichTextEditor";
import * as staticConfigs from "../../utilities/staticConfigs";
import { Form } from "react-bootstrap";
import {
  MESSAGE_TEMPLATE_CREATION_SUCCESS,
  MESSAGE_TEMPLATE_CREATION_FAILED,
} from "../../utilities/labelConfigs";
import service from "./service";
import LoadingContext from "../../container/loadingContext";
import { checkPermission } from "../../utilities/commonUtilities";
import { permission_key_values_messages } from "../../utilities/permissions";

const AddTemplate = ({ selectedTemplate, cancelEdit }) => {
  const practicePK = getStorage("practice");
  const history = useHistory();
  // user List

  const [subject, setSubject] = useState("");
  const [template, setTemplate] = useState("");

  const [richText, setRichText] = useState("");
  const [warningRichText, setWarningRichText] = useState(false);
  const [draftMessageId, setDraftMessageId] = useState("");
  const [isButtonClicked, setButtonClicked] = useState(false); 

  //notify
  const [showNotify, setShowNotify] = useState("hide");
  const [notifyDescription, setNotifyDescription] = useState("");
  const [notifyType, setNotifyType] = useState("success");

  const setShowLoader = useContext(LoadingContext);

  useEffect(() => {
    if (selectedTemplate) {
      setTemplate(selectedTemplate.template_name);
      setSubject(selectedTemplate.subject);
      setRichText(selectedTemplate.message);
    }
  }, []);

  const showNotifyWindow = useCallback((action, type, desc, age = 3000) => {
    if (action === "show") {
      setTimeout(() => {
        setShowNotify("hide");
      }, age);
    }
    setShowNotify(action);
    setNotifyType(type);
    setNotifyDescription(desc);
  }, []);

  const onUpdateTemplate = useCallback(() => {
    const data = {
      message: richText,
      template_name: template,
      subject: subject,
      is_deleted: false,
      practice: practicePK,
    };
    setShowLoader(true);
    service
      .UpdateMessageTemplate(data, selectedTemplate.id)
      .then((response) => {
        if (response.status === 200 || response.status === 201) {
          showNotifyWindow("show", "success", "Updated Successfully");
          cancelEdit();
        } else {
          throw new Error("Unexpected error occured.");
        }
      })
      .catch((err) => {
        setShowLoader(false);
        showNotifyWindow("show", "error", `${err.message}`);
      })
      .finally(() => {
        setShowLoader(false);
    });
  }, [richText, subject]);

  const saveHandler = (e) => {
    e.preventDefault();
    if (!isButtonClicked) {
      setButtonClicked(true);
    if (selectedTemplate) {
      onUpdateTemplate();
    } else {
      onSaveMessageTemplate();
    }
  }
  };

  const onSaveMessageTemplate = async () => {

    try {
      if (!subject) {
        setButtonClicked(false);
        return;
        }

      if (!richText) {
        setWarningRichText(true);
        setTimeout(() => {
          setWarningRichText(false);
        }, 3000);
        setButtonClicked(false);
        return;
      }

      let data = {
        message: richText,
        template_name: template,
        subject: subject,
        is_deleted: false,
        practice: practicePK,
      };

      const response = await service.CreateMessageTemplate(data);
  
        if (response.status === 201) {
          showNotifyWindow("show", "success", MESSAGE_TEMPLATE_CREATION_SUCCESS);
          setTimeout(() => {
            history.push(staticConfigs.ROUTE_TEMPLATE);
          }, 2000);
        } else {
          showNotifyWindow("show", "error", MESSAGE_TEMPLATE_CREATION_FAILED);
          setButtonClicked(false);
        }
      } catch (error) {
        showNotifyWindow("show", "error", error.message);
        setButtonClicked(false);
      }
  };

  const onCancelMessage = () => {
    if (selectedTemplate) {
      cancelEdit();
    } else history.push(staticConfigs.ROUTE_TEMPLATE);
  };

  return (
    <div className="col-md-12">
      <div className="box basic-info-padding bordering border-radius-8" style={{ padding: "20px"}} >
        <Notify
          showNotify={showNotify}
          setShowNotify={setShowNotify}
          notifyDescription={notifyDescription}
          setNotifyType={setNotifyType}
          setNotifyDescription={setNotifyDescription}
          notifyType={notifyType}
        />
        <div className="row justify-space-between">
          <div className="col">
          {checkPermission(permission_key_values_messages.messages_templates_modify) && 
          <button
              type="button"
              className="small_box btn-primary-blue btn"
              onClick={saveHandler}
              disabled={isButtonClicked}
            >
              <i className="fas fa-sticky-note"></i>
              {selectedTemplate
                ? "Update"
                : i18n.t("inboxScreens.addTemplate.saveAsTemplate")}
            </button>}
            <button
              type="button"
              className="small_box btn-secondary btn px-4 mx-4"
              onClick={onCancelMessage}
            >
              {i18n.t("inboxScreens.addTemplate.cancel")}
            </button>
          </div>
        </div>
        <Form
          rm
          id={"composeScreen"}
          autoComplete="off"
          onSubmit={onSaveMessageTemplate}
        >
          <div className="row mt-4">
            <div className="col">
              <TextInput
                type="text"
                required={true}
                disabled={selectedTemplate ? "disabled" : ""}
                name={"templateName"}
                value={template}
                onValueChange={(e) => setTemplate(e.target.value)}
                label={i18n.t("inboxScreens.addTemplate.templateName")}
              />
            </div>
          </div>
          <div className="row">
            <div className="col">
              <TextInput
                type="text"
                required={true}
                name={"subject"}
                value={subject}
                onValueChange={(e) => setSubject(e.target.value)}
                label={i18n.t("inboxScreens.addTemplate.subject")}
              />
            </div>
          </div>
          <div className={"mt-4"}>
            {warningRichText && (
              <span className="danger">
                <strong>
                  {i18n.t("inboxScreens.composeScreen.MessageIsMandatory")}
                </strong>
              </span>
            )}
            {selectedTemplate  ? 

              richText &&
              <RichTextEditor
                state={richText}
                setState={setRichText}
                showNotifyWindow={showNotifyWindow}
                draftMessageId={draftMessageId}
                setDraftMessageId={setDraftMessageId}
              />
              
             :
              <RichTextEditor
                state={richText}
                setState={setRichText}
                showNotifyWindow={showNotifyWindow}
                draftMessageId={draftMessageId}
                setDraftMessageId={setDraftMessageId}
              />
            }
          </div>
        </Form>
      </div>
    </div>
  );
};

export default AddTemplate;