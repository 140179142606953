import React, { useContext, useEffect, useState } from 'react';
import { Link, useLocation, useHistory } from "react-router-dom";
import RichTextEditor from '../commons/RichTextEditor/RichTextEditor';
import * as staticConfigs from '../../utilities/staticConfigs';
import './style.css';
import service from './service';
import LoadingContext from '../../container/loadingContext';
import Moment from 'react-moment';
import i18n from '../../utilities/i18n';
import Notify from '../commons/notify';
import { DELETE_MESSAGE_SUCCESS, MESSAGE_COPIED_SUCCESS } from '../../utilities/labelConfigs';
import copy from 'copy-to-clipboard';
import { checkPermission, removeTags } from '../../utilities/commonUtilities';
import { getStorage } from '../../utilities/browserStorage';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { permission_key_values_messages } from '../../utilities/permissions';

function SendMessageScreen() {
    const practicePK = getStorage('practice');
    const setShowLoadingBar = useContext(LoadingContext);
    const history = useHistory();
    const location = useLocation();
    const [richTextValue, setRichTextValue] = useState('');

    // inbox message detail variable
    const [messageDetail, setMessageDetail] = useState([]);

    // notify
    const [showNotify, setShowNotify] = useState("hide");
    const [notifyDescription, setNotifyDescription] = useState("");
    const [notifyType, setNotifyType] = useState("success");

    const [idOfMessage, setIdOfMessage] = useState('');
    const [arrayOfId, setArrayOfId] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);

    const getInboxMessage = (id, page_size, page) => {
        setShowLoadingBar(true);
        let result = service.GetSendMessageDetail(id, practicePK, page_size, page);
        let body = null;
        result.then((response) => {
            setMessageDetail(response.data);
            body = response.data[0].body;
        }).then(() => {
            setRichTextValue(body);
            setShowLoadingBar(false);
        });
    }

    function DeleteSendMessage() {
        setShowLoadingBar(true);
        let data = {
            "message_type": "sent",
            "message_id": [messageDetail[0].id]
        }
        let result = service.DeleteMessage(data);
        result.then((response) => {
            setShowLoadingBar(false);
            if (response.status === 200) {
                showNotifyWindow("show", "success", DELETE_MESSAGE_SUCCESS);
            }
        }).then(() => {
            setTimeout(() => {
                history.push(staticConfigs.ROUTE_SEND_INBOX);
            }, 2000);
        })
    }
    function showNotifyWindow(action, type, desc, age = 3000) {
        if (action === "show") {
            setTimeout(() => {
                setShowNotify("hide");
            }, age);
        }
        setShowNotify(action);
        setNotifyType(type);
        setNotifyDescription(desc);
    }

    useEffect(() => {
        let id = location.state.id;
        let messageIndex = location.state.index;
        setIdOfMessage(id);
        setArrayOfId(location.state.list);
        if (messageIndex)
            setCurrentPage(messageIndex)
        getInboxMessage(id, 1, 0);
    }, []);

    const nextMessage = async () => {       
        let { data: { results } } = await service.GetSendMessageDetail('', practicePK, 1, currentPage + 1);
        setCurrentPage(currentPage + 1);
        setMessageDetail(results);
        setRichTextValue(results[0].body);
    }
    const PreviousMessage = async () => {        
        let { data: { results } } = await service.GetSendMessageDetail('', practicePK, 1, currentPage - 1);
        setCurrentPage(currentPage - 1);
        setMessageDetail(results);
        setRichTextValue(results[0].body);
    }

    const Print = (e) => {
        e.preventDefault();
        window.print();
    }

    const copyToClipboard = (e) => {
        e.preventDefault();
        let str = removeTags(richTextValue);
        copy(str);
        showNotifyWindow("show", "success", MESSAGE_COPIED_SUCCESS);
    }

    return (
        <div className="col-md-12">
            <div className="box basic-info-padding">
                <Notify showNotify={showNotify} setShowNotify={setShowNotify} notifyDescription={notifyDescription} setNotifyType={setNotifyType} setNotifyDescription={setNotifyDescription} notifyType={notifyType} />
                <div className="row justify-space-between">
                    <div className="col">
                        <Link to={staticConfigs.ROUTE_SEND_INBOX} className='return_to_inbox'>
                            <i className="fas fa-arrow-left mr-2"></i>
                            {i18n.t("inboxScreens.sendMessageScreen.returnToSent")}
                        </Link>
                    </div>
                    <div className='col justify-right'>
                        {arrayOfId.indexOf(idOfMessage) > 0 &&
                            <button type="button" className="btn" onClick={PreviousMessage}>
                                <OverlayTrigger
                                    placement="bottom"
                                    delay={{ show: 250, hide: 400 }}
                                    overlay={<Tooltip id="download-tooltip" style={{
                                        opacity: 0.4,
                                    }}>{i18n.t("inboxScreens.inboxMessageScreen.prevMessage")}
                                    </Tooltip>}
                                >
                                    <i className="fas fa-arrow-left navBarIcons" style={{ fontSize: '17px' }}></i>
                                </OverlayTrigger>
                                {/*  */}
                            </button>}
                        {arrayOfId.indexOf(idOfMessage) + 1 !== arrayOfId.length &&
                            <button type="button" className=" btn" onClick={nextMessage}>
                                <OverlayTrigger
                                    placement="bottom"
                                    delay={{ show: 250, hide: 400 }}
                                    overlay={<Tooltip id="download-tooltip" style={{
                                        opacity: 0.4,
                                    }}>{i18n.t("inboxScreens.inboxMessageScreen.nextMessage")}
                                    </Tooltip>}
                                >
                                    <i className="fas fa-arrow-right navBarIcons" style={{ fontSize: '17px' }}></i>
                                </OverlayTrigger>
                                {/*  */}
                            </button>}
                        <button type="button" className=" btn" onClick={(e) => copyToClipboard(e)}>
                            <OverlayTrigger
                                placement="bottom"
                                delay={{ show: 250, hide: 400 }}
                                overlay={<Tooltip id="download-tooltip" style={{
                                    opacity: 0.4,
                                }}>{i18n.t("inboxScreens.sendMessageScreen.copyMessage")}
                                </Tooltip>}
                            >
                                <i className='fa fa-copy navBarIcons' style={{ fontSize: '17px' }} />
                            </OverlayTrigger>
                        </button>
                        {checkPermission(permission_key_values_messages.can_modify_sentMails) && <button type="button" className=" btn" onClick={DeleteSendMessage}>
                            <OverlayTrigger
                                placement="bottom"
                                delay={{ show: 250, hide: 400 }}
                                overlay={<Tooltip id="download-tooltip" style={{
                                    opacity: 0.4,
                                }}>{i18n.t("inboxScreens.sendMessageScreen.delete")}
                                </Tooltip>}
                            >
                                <i className="fas fa-trash-alt navBarIcons" style={{ fontSize: '17px' }}></i>
                            </OverlayTrigger>
                        </button>}
                        <button type="button" onClick={(e) => Print(e)} className=" btn">
                            <OverlayTrigger
                                placement="bottom"
                                delay={{ show: 250, hide: 400 }}
                                overlay={<Tooltip id="download-tooltip" style={{
                                    opacity: 0.4,
                                }}>{i18n.t("inboxScreens.sendMessageScreen.print")}
                                </Tooltip>}
                            >
                                <i className="fas fa-print navBarIcons" style={{ fontSize: '17px' }}></i>
                            </OverlayTrigger>
                        </button>
                    </div>
                </div>
                <div className=''>
                    <div className='row'>
                        <div className='col-1'>
                            <strong>{i18n.t("inboxScreens.sendMessageScreen.from")}</strong>
                        </div>
                        <div className='col'>
                            <p>{messageDetail?.map((message, index) => <div key={index}>{message.sender}</div>)}</p>
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col-1'>
                            <strong>{i18n.t("inboxScreens.sendMessageScreen.to")}</strong>
                        </div>
                        <div className='col'>
                            <p>{messageDetail?.map((message, index1) => <div key={index1}>{message.receivers.map((item) => item.name)}</div>)}</p>
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col-1'>
                            <strong>{i18n.t("inboxScreens.sendMessageScreen.date")}</strong>
                        </div>
                        <div className='col'>
                            <p>{messageDetail?.map((message, index2) => <Moment key={index2} format="MM/DD/yyyy hh:mm A">{message.created_on}</Moment>)}</p>
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col-1'>
                            <strong>{i18n.t("inboxScreens.sendMessageScreen.subject")}</strong>
                        </div>
                        <div className='col'>
                            <p>{messageDetail?.map((message, index3) => <div key={index3}>{message.subject}</div>)}</p>
                        </div>
                    </div>
                </div>
                <div className='row'>

                </div>
                <div className=''>
                    <RichTextEditor disabled={true} state={richTextValue} setState={setRichTextValue} />
                </div>
            </div>
        </div>
    )
}

export default SendMessageScreen;
