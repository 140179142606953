import i18n from "../../../utilities/i18n";

const TableHeadData = [
    {
        "name": i18n.t("Eligibility.batchEligibility.patientName"),
        "type": "string",
        "width": "medium",
        "sort": false,
    },
    {
        "name": i18n.t("Eligibility.batchEligibility.dob"),
        "type": "Date",
        "width": "small",
        "sort": false,
    },
    {
        "name": i18n.t("Eligibility.batchEligibility.insurance"),
        "type": "string",
        "width": "medium",
        "sort": false,
    },
    {
        "name": i18n.t("Eligibility.batchEligibility.policyId"),
        "type": "number",
        "width": "small",
        "sort": false,
    },
    {
        "name": i18n.t("Eligibility.batchEligibility.summary"),
        "type": "link",
        "width": "small",
        "sort": false,
    },
];

const TableBodyData = [
    [
        {
            "id": "",
            "name": "patient_name",
            "value": "",
            "type": "string",
            "needLink": true,
            "align": "left",
            "width": "medium",
        },
        {
            "id": "",
            "name": "dob",
            "needDateFormat": "yes",
            "value": "",
            "type": "Date",
            "align": "center",
            "width": "small",
        },
        {
            "id": "",
            "name": "insurance_name",
            "value": "",
            "type": "string",
            "align": "left",
            "width": "medium",
        },
        {
            "id": "",
            "name": "policy_id",
            "value": "",
            "type": "string",
            "align": "center",
            "width": "small",
        },
        {
            "id": "",
            "name": "summary",
            "value": "View",
            "needChip":  true,
            "summaryChip": 'yes',
            "type": "link",
            "align": "center",
            "width": "small",
        },
    ]
];

export const EligibilityTabOneTable = {
    "name": "simpleTable",
    "isResize": true,
    "isFixedHead": true,
    "tableHeadings": TableHeadData,
    "tableBodyData": TableBodyData
}