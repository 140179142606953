import i18n from "../../utilities/i18n";

const TableHeadDataSearch = [
    {
        "name": "checkbox",
        "type": "checkbox",
        "width": "check_box",
        "sort": false,
        "value": false,
    },
    {
        "name": i18n.t("payments.eraPage.table1.eraStatus"),
        "type": "number",
        "width": "small",
        "sort": false,
    },
    {
        "name": i18n.t("payments.eraPage.table1.payerName"),
        "type": "string",
        "width": "medium",
        "sort": false,
    }, 
    {
        "name": i18n.t("payments.eraPage.table1.eraUploaded"),
        "type": "Date",
        "width": "small",
        "sort": false,
        "needDateFormat": "yes"
    }, 
    {
        "name": i18n.t("payments.eraPage.table1.paymentDate"),
        "type": "Date",
        "width": "small",
        "sort": false,
        "needDateFormat": "yes"
    }, 
    {
        "name": i18n.t("payments.eraPage.table1.paymentAmount"),
        "type": "currency",
        "width": "small",
        "sort": false,
    },
    {
        "name": i18n.t("payments.eraPage.table1.payment_reference_num"),
        "type": "string",
        "width": "medium",
    }, 
    {
        "name": i18n.t("payments.eraPage.table1.payment_mode"),
        "type": "string",
        "width": "small",
        "sort": false,
    }, 
    {
        "name": i18n.t("payments.eraPage.table1.viewEra"),
        "type": "string",
        "width": "small",
        "sort": false,
    },
    
]; 

const TableBodyDataSearch = [
    [
        {
            "id": "",
            "name": "checkbox",
            "type": "checkbox",
            "width": "check_box",
            "align": "center",
            "value": false
        },
        {
            "id": "",
            "name": "applied_status",
            "value": "",
            "type": "ERAPostingAppliedStatus",
            "needLink": true,
            "align": "center",
            "width": "small",
        }, 
        {
            "id": "",
            "name": "payer_name",
            "value": "",
            "type": "string",
            "align": "left",
            "width": "medium",
        },   
        {
            "id": "",
            "name": "created_on",
            "value": "",
            "type": "Date",
            "align": "center",
            "width": "small",
            "needDateFormat" : "yes"
        }, 
        {
            "id": "",
            "name": "payment_date",
            "value": "",
            "type": "Date",
            "align": "center",
            "width": "small",
            "needDateFormat" : "yes"
        },   
        {
            "id": "",
            "name": "total_check_amount",
            "value": "",
            "type": "currency",
            "align": "right",
            "width": "small",
        },
        {
            "id": "",
            "name": "payment_reference_num",
            "value": "",
            "type": "string",
            "align": "center",
            "width": "medium",
        },   
        {
            "id": "",
            "name": "payment_mode",
            "value": "",
            "type": "string",
            "align": "center",
            "width": "small",
        },
        {
            "id": "",
            "name": "exportOptionsERAPosting",
            "value": [],
            "type": "exportOptionsERAPosting",
            "align": "center",
            "width": "small",
        },
        
    ]
]

export const ERAPostingTableDataSearch = {
    "name": "simpleTable",
    "isResize": true,
    "isFixedHead": true,
    "tableHeadings": TableHeadDataSearch,
    "tableBodyData": TableBodyDataSearch
}