import i18n from "../../utilities/i18n";


const TableHeadData = [
    {
        "name": i18n.t("searchClaims.claimId"),
        "type": "number",
        "width": "small",
        "sort": true,
        "initialOrdering": true,
    },
    {
        "name": i18n.t("searchClaims.patientName"),
        "type": "string",
        "width": "medium",
        "sort": true,
        "initialOrdering": true,
    },
    {
        "name": i18n.t("searchClaims.dos"),
        "type": "Date",
        "width": "small",
        "sort": true,
        "initialOrdering": true,
    },
    {
        "name": i18n.t("searchClaims.charges"),
        "type": "currency",
        "width": "small",
        "sort": false,
    },
    {
        "name": i18n.t("searchClaims.balance"),
        "type": "currency",
        "width": "small",
        "sort": false,
    },
    {
        "name": i18n.t("searchClaims.billedDate"),
        "type": "Date",
        "width": "small",
        "sort": true,
        "initialOrdering": true,
    },
    {
        "name": i18n.t("searchClaims.responsiblePayer"),
        "type": "string",
        "width": "medium",
        "sort": false,
    },
    {
        "name": i18n.t("searchClaims.action"),
        "type": "button",
        "width": "action_column",
        "sort": false,
    },
];

export const TableBodyData = [
    [
        {
            "id": "",
            "name": "custom_claim_id",
            "value": "",
            "type": "string",
            "align": "center",
            "width": "small",
            "needLink": true,
        },
        {
            "id": "",
            "name": "patient_name",
            "value": "",
            "type": "string",
            "align": "left",
            "width": "medium",
            "needLink": true,
        },
        {
            "id": "",
            "name": "dos_from",
            "value": "",
            "type": "Date",
            "align": "center",
            "width": "small",
        },
        {
            "id": "",
            "name": "charges",
            "value": "",
            "type": "currency",
            "align": "right",
            "width": "small",
        },
        {
            "id": "",
            "name": "balance",
            "value": "",
            "type": "currency",
            "align": "right",
            "width": "small",
        },
        {
            "id": "",
            "name": "last_billed_date",
            "value": "",
            "type": "Date",
            "align": "center",
            "width": "small",
        },
        {
            "id": "",
            "name": "responsible_payer",
            "value": "",
            "type": "responsiblePayer",
            "align": "left",
            "width": "medium",
        },
        {
            "id": "",
            "name": "payer_types",
            "value": "",
            "type": "payer_types",
            "align": "left",
            "width": "medium",
        },
        {
            "id": "",
            "name": "action",
            "type": "button",
            "align": "center",
            "width": "action_column",
            "dropdown": [
                {
                    "name": "Edit"
                }
            ]
        },
    ]
]

export const SearchClaimTableData = {
    "name": "simpleTable",
    "isResize": true,
    "isFixedHead": true,
    "tableHeadings": TableHeadData,
    "tableBodyData": TableBodyData
}