import api from '../../../service/api'

const ForgotPassword = async(fToken, userId, expiryTime, token) =>{
	let path = 'user/forgot-password/?fToken='+fToken+'&userId='+userId+'&expiryTime='+expiryTime+'&token='+token;
	return api.getWithoutJWT(path);
}

const ResetPassword = async(data) => {
	let path = 'user/reset-forgot-password/';
	return api.putWithoutJWT(path,data);
}

const ChangePassword = async(data) => {
	let path = 'user/change-password/';
	return api.put(path,data);
}

const ForceChangePassword = async(data) => {
	let path = 'user/force-change-password/';
	return api.putWithoutJWT(path,data);
}

export default {ForgotPassword, ResetPassword, ChangePassword, ForceChangePassword}
