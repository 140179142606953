import { createSlice } from '@reduxjs/toolkit';

let initialState = {
    tableData: [],
    loading: false,
    pageSize: 0,
    count: 0,
    totalPage: 0,
    sortField: null, // Current SortField
    orderType: null, // Current Sort Order Asc or Dsc
    filters: { // Filter input variables
           dateOfServiceFrom: "",
            dateOfServiceTo: "",
            dateOfPaymentEntry: "",
            dateOfEntryFrom: "",
            dateOfEntryTo: "",
            firstBilledDateFrom: "",
            firstBilledDateTo: "",
            lastBilledDateFrom: "",
            lastBilledDateTo: "",
            payerType: [],
            insurance_companies: [],
            claimStatus: [],
            claimSubStatus: [],
            renderingProvider: [],
            refferingProvider: [],
            billingProvider: [],
            serviceLocation: [],
            cptList: [],
            ICD: [],
            chargeEnteredUser: [],
            paymentEnteredUser: [],
            blanceAmountType: "",
            balanceAmountTotals: "",
            balanceAmountTotalsFrom: "",
            balanceAmountTotalsTo: "",
            aging: "",
            patientIds: [],
            transaction_type: [],
            transaction_from:"",
            transaction_to:"",
            transaction_entered_by_user:[]
    },
    allSortFieldStates: {
        "custom_claim_id": true,
        "entered_date": true,
        "first_billed_date": true,
        "last_billed_date": true,
        "service_from_date": true,
        "service_to_date": true
    },
    isError: 'not-set'
}

export const TransactionReportSlice = createSlice({
    name: "transactionReport",
    initialState,
    reducers: {
        onResetData: () => initialState,
        updateTransactionReportData: (state, actions) => {
            if (actions.payload.type === "success") {
                state.count = actions.payload.data.data.count;
                state.pageSize = actions.payload.data.data.page_size;
                state.totalPage = Math.ceil(actions.payload.data.data.count / actions.payload.data.data.page_size)
                state.tableData = actions.payload.data.data.results;
            }
            if (actions.payload.type === "error") {
                state.isError = true
            }
        },
        updateSortField: (state, actions) => {
            // toggle all the sortfields to true except for the currently sorting field
            const newSortState = Object.keys(state.allSortFieldStates).reduce((acc, key) => {
                acc[key] = key === actions.payload.sortField ? false : true;
                return acc;
            }, {});
            return {
                ...state,
                sortField: actions.payload.sortField,
                orderType: actions.payload.sortOrder,
                allSortFieldStates: newSortState
            };
        },
        updateFieldValues: (state, actions) => {
            const updateState = actions.payload.state;
            const value = actions.payload.value;
            const field = actions.payload.field;
            if (updateState !== 'mainState') {
                return {
                    ...state,
                    [updateState]: {
                        ...state[updateState],
                        [field]: value,
                    }
                }
            } else {
                return {
                    ...state,
                    [field]: value,
                }
            }
        },
    }
});

export const { onResetData, updateTransactionReportData, updateSortField,updateFieldValues } = TransactionReportSlice.actions

export default TransactionReportSlice.reducer;