import React, { useState, useEffect, useContext, useCallback } from "react";
import LoadingContext from "../../../container/loadingContext";
import { getStorage } from "../../../utilities/browserStorage";
import Notify from "../../commons/notify";
import AddTemplateCard from "./AddTemplateCard";
import FieldSelectorCard from "./FieldSelectorCard";
import "./reportStyles.css";
import service from "../service";

const NewFromScratchReport = () => {
  // **********States*****************
  //--------- localStrage data -------------
  const practicePK = getStorage("practice");
  //--------- Alert Messages States Start-------------
  const [showNotify, setShowNotify] = useState("hide");
  const [notifyDescription, setNotifyDescription] = useState("");
  const [notifyType, setNotifyType] = useState("success");
  //--------- Alert Messages States End-------------
  const setShowLoadingBar = useContext(LoadingContext);
  const [selectedColumns, setSelectedColumns] = useState([]);
  const [selectedFilters, setSelectedFilters] = useState([]);
  const [selectedGroups, setSelectedGroups] = useState([]);
  const [categoryList, setCategoryList] = useState([]);
  const [reportCategories, setReportCategories] = useState([]);
  const [currentObjectMap, setCurrentObjectMap] = useState({
    query_object: {},
    table_field: {},
    drop_to: "",
  });

  // ***********************************

  // ********States******************
  const [title, setTitle] = useState();
  const [description, setDescription] = useState();
  const [category, setCategory] = useState();

  // *********************************
  // *********Functions***************
  const onTitleChanged = (e) => {
    setTitle(e.target.value);
  };
  const onDescriptionChanged = (e) => {
    setDescription(e.target.value);
  };
  const onCategoryChaged = (e) => {
    setCategory(e.target.value);
  };

  const onFormSave = () => {
    const body = {
      practice_id: practicePK,
      report_name: title,
      report_category: category,
      description: description,
      ...currentObjectMap.query_object,
    };
    setShowLoadingBar(true);
    service
      .saveReport(body)
      .then((response) => {
        setShowLoadingBar(false);
        if (response.status === 200 || response.status === 201) {
          showNotifyWindow("show", "success", "Report Saved");
        } else {
          throw new Error("Something went wrong");
        }
      })
      .catch((err) => {
        setShowLoadingBar(false);
        showNotifyWindow("show", "error", `${err.name} ${err.message}`);
      });
  };
  // *********************************

  const fetchTableData = useCallback(() => {
    setShowLoadingBar(true);
    service
      .GetReportFields(practicePK)
      .then((response) => {
        let data = response.data;
        let category_array = [];
        Object.keys(data).forEach((key, index) => {
          let categoryData = {
            id: index + 1,
            name: key.toUpperCase(),
            fields: data[key],
            isOpen: false,
          };
          category_array.push(categoryData);
        });
        setShowLoadingBar(false);
        setCategoryList(category_array);
      })
      .catch((err) => {
        setShowLoadingBar(false);
        showNotifyWindow(
          "show",
          "error",
          err.message || "Something went wrong.!"
        );
      });
  }, []);

  const fetchReportCategories = useCallback(() => {
    setShowLoadingBar(true);
    service
      .fetchReportCategories(practicePK)
      .then((response) => {
        setShowLoadingBar(false);
        if (response.status === 200) {
          setReportCategories(response.data);
        } else {
          throw Error("Something went wrong");
        }
      })
      .catch((err) => {
        setShowLoadingBar(false);
        showNotifyWindow("show", "error", err.message);
      });
  }, []);

  // **********useEffect*****************
  useEffect(() => {
    fetchTableData(practicePK);
    fetchReportCategories();
  }, []);

  // ***********************************
  // **********Functions*****************
  function showNotifyWindow(action, type, desc, age = 3000) {
    if (action == "show") {
      setTimeout(() => {
        setShowNotify("hide");
      }, age);
    }
    setShowNotify(action);
    setNotifyType(type);
    setNotifyDescription(desc);
  }
  const onChangeOpenStatus = (e, categoryData) => {
    const newState = categoryList.map((obj) => {
      if (obj.id === categoryData.id) {
        return { ...obj, isOpen: !categoryData.isOpen };
      }
      return obj;
    });
    setCategoryList(newState);
  };
  const handleDropOnColumns = (item) => {
    setSelectedColumns((prev) => {
      return [...prev, item];
    });
    setObjectMap(item, "columnFields");
  };
  const handleDropOnFilters = (item) => {
    setSelectedFilters((prev) => {
      return [...prev, item];
    });
    setObjectMap(item, "filterFields");
  };
  const handleDropOnGroups = (item) => {
    setSelectedGroups((prev) => {
      return [...prev, item];
    });
    setObjectMap(item, "groupFields");
  };

  const setAndFormatData = useCallback(
    (data) => {
      setCurrentObjectMap({ query_object: data, table_field: {} });
    },
    [currentObjectMap]
  );

  const setObjectMap = (obj, label) => {
    const body = { ...currentObjectMap, table_field: obj, drop_to: label };
    setShowLoadingBar(true);
    service
      .checkFieldMapping(body)
      .then((response) => {
        setShowLoadingBar(false);
        if (response.status === 200) {
          setAndFormatData(response.data);
        } else {
          throw new Error(`Unexpected Error ${response.status} `);
        }
      })
      .catch((err) => {
        setShowLoadingBar(false);
        showNotifyWindow(
          "show",
          "error",
          err.message || "Something went wrong..!"
        );
      });
  };
  // ************************************
  return (
    <React.Fragment>
      <Notify
        showNotify={showNotify}
        setShowNotify={setShowNotify}
        notifyDescription={notifyDescription}
        setNotifyType={setNotifyType}
        setNotifyDescription={setNotifyDescription}
        notifyType={notifyType}
      />
      <div className="col-12">
        <div className="box">
          <div className="box-content">
            <AddTemplateCard
              category={category}
              categoryList={reportCategories}
              description={description}
              title={title}
              onCategoryChaged={onCategoryChaged}
              onDescriptionChanged={onDescriptionChanged}
              onTitleChanged={onTitleChanged}
              saveHandler={onFormSave}
              onShowMessage={showNotifyWindow}
              setReportCategories={setReportCategories}
            />
            <FieldSelectorCard
              categoryList={categoryList}
              selectedColumns={selectedColumns}
              selectedFilters={selectedFilters}
              selectedGroups={selectedGroups}
              setColumns={handleDropOnColumns}
              setFilters={handleDropOnFilters}
              setGroups={handleDropOnGroups}
              onChangeOpenStatus={onChangeOpenStatus}
            />
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default NewFromScratchReport;
