import { TextField } from '@material-ui/core';
import React  from 'react'
import { ValidateEmail } from '../../../utilities/commonUtilities';
import "../input/style.css"
import Label from '../Label';

function EmailInput(props) {
  const maxLength = props.maxLength ?? 200;
  const required = props.required;
  const value = props.value ?? '';



  /**
   * Validate input based on whether it's required and if a value is provided.
   * If required and a value is provided, validate email using the ValidateEmail function.
   * @returns {string} - Returns an empty string if input is valid, otherwise returns "input-error".
   */
  function validateInput() {
    // If value is provided
    if (value) {
      // Validate email using ValidateEmail function
      if (ValidateEmail(value)) {
        // If email is valid, return empty string
        return "";
      } else {
        // If email is invalid, return "input-error"
        return "input-error";
      }
    } else if (required && !value) {
      // If required but no value is provided, return "input-error"
      return "input-error";
    } else {
      // If input is not required and no value is provided, return empty string
      return "";
    }
  }


  return (
    <div>
      <form autoComplete="off">
        <Label label={props.label} />
        <div className="padding-top5">
          <div className="form-group">
            <TextField style={{ width: "100%" }} id={props.id ? props.id : "email-input"} variant="outlined"
              type="email" name={props.name}
              aria-labelledby='email'
              onChange={props.onValueChange}
              InputLabelProps={props.value ? { shrink: props.value ? true : false } : ""}
              className={props.className ? props.className : validateInput()}
              required={required ? required : ""}
              value={props.value} maxLength={maxLength} autoComplete="off" pattern="/^([a-zA-Z0-9_\-\.]+)([\+?\d+]+)?@([a-zA-Z0-9_\-\.]+)\.([a-z]+)$/"
              disabled={props.disabled ? props.disabled : ''} 
            />
          </div>
        </div>
      </form>
    </div>
  )
}

export default EmailInput;
