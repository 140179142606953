import React, { useContext, useEffect, useState,useCallback } from "react";
import {
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
} from "@mui/material";
import { styled } from '@mui/material/styles';

import LoadingContext from "../../../container/loadingContext";

import i18n from "../../../utilities/i18n";
import { YMD_TO_MDY } from '../../../utilities/commonUtilities'
import { PAGING_END_INDEX } from "../../../utilities/staticConfigs";

import Pagination from "../../commons/pagination";
import CustomizedSmallDialogs from "../../modalWindowComponent/CustomisedSmallDialog";
import service from "./service";
import NewRowCard from "./NewRowCard";
import Notify from "../../commons/notify";

const PAGE_SIZE = 10;

const CustomTextField = styled(TextField)({
  '& .MuiInputBase-root': {
    height: '35px',
  },
});

const FeeFileEditForm = ({ fileId}) => {
  const setShowLoadingBar = useContext(LoadingContext);

  const [showNotify, setShowNotify] = useState("hide");
  const [notifyDescription, setNotifyDescription] = useState("");
  const [notifyType, setNotifyType] = useState("success");

  const [fileContentList, setFileContentList] = useState([]);
  const [deleteIdList, setDeleteIdList] = useState([]);

  //Pagination start
  const [totalPage, setTotalPage] = useState(1);
  const [activePage, setActivePage] = useState(1);
  const [startIndex, setStartIndex] = useState(0);
  const [endIndex, setEndIndex] = useState(PAGING_END_INDEX);
  const [initialTableData] = useState(
    i18n.t("commons.noRecords")
  );
  const [showDeleteModalWindow, setShowDeleteModalWindow] = useState(false);
  const [isedited, setIsedited] = useState(false);
  const [allSelected, setAllSelected] = useState(false);

  const showNotifyWindow = useCallback((action, type, desc, age = 3000) => {
    if (action == "show") {
      setTimeout(() => {
        setShowNotify("hide");
      }, age);
    }
    setShowNotify(action);
    setNotifyType(type);
    setNotifyDescription(desc);
  }, []);


  const getFileContents = (pageSize, page) => {
    try {
      setShowLoadingBar(true);
      service
        .getFeeFileById(fileId, pageSize, page)
        .then((response) => {
          setShowLoadingBar(false);
          if (response.data.results !== undefined || response?.data ) {

          const fileList = response?.data?.results
          ? response.data.results.map((item) => ({
             ...item,isSelected: false }))
          : response.data.map((item) => ({
            ...item,isSelected: false }));
          setFileContentList(fileList);
        
            let dataTotalPage = Math.ceil(
              response.data.count / response.data.page_size
            );
            setTotalPage(dataTotalPage);
            if (dataTotalPage < activePage) {
              setActivePage(dataTotalPage);
            }
          }
        })
    } catch(err) {
        setShowLoadingBar(false);
        showNotifyWindow("show", "error", `${err.message}`);
      }
    }

  useEffect(() => {
    getFileContents(PAGE_SIZE, activePage);
  }, []);

  const onPagePrevious = () => {
    let previousPage = startIndex + 1 - PAGING_END_INDEX;
    setActivePage(previousPage);

    if (startIndex !== 0) {
      setStartIndex(startIndex - PAGING_END_INDEX);
      setEndIndex(endIndex - PAGING_END_INDEX);
    }
    getFileContents(PAGE_SIZE, previousPage);
  };

  const onPageUp = (e) => {
    let page = Number(e.target.id);
    setActivePage(page);
    getFileContents(PAGE_SIZE, page);
  };

  const onPageNext = () => {
    let nextPage = startIndex + 1 + PAGING_END_INDEX;
    if (endIndex === totalPage || totalPage <= PAGING_END_INDEX) {
      setActivePage(nextPage);
      setStartIndex(startIndex);
      setStartIndex(endIndex);
    } else {
      setActivePage(nextPage);
      setStartIndex(startIndex + PAGING_END_INDEX);
      setEndIndex(endIndex + PAGING_END_INDEX);
    }
    getFileContents(PAGE_SIZE, nextPage);
  };

  const handleInputChange = (e, index) => {
    const { name, value } = e.target;
    const list = [...fileContentList];
    list[index][name] = value;
    setFileContentList(list);
    setIsedited(true);
  };

  const handleSelected = (e, item) => {
    const updatedList = fileContentList.map((fileItem) => {
      if (fileItem.id === item.id) {
        return { ...fileItem, isSelected: !fileItem.isSelected };
      }
      return fileItem;
    });
    setFileContentList(updatedList);
  };

  const updateSelectAllCheckbox = () => {
    const allChecked = fileContentList.every((fileItem) => fileItem.isSelected);
    setAllSelected(allChecked);
  };

  useEffect(() => {
    updateSelectAllCheckbox();
  }, [fileContentList]);

  useEffect(() => {
    const list = [...fileContentList];
    const itemsFound = [];
    list.forEach((element) => {
      if (element.isSelected === true) {
        itemsFound.push(element.id);
      }
    });
    setDeleteIdList(itemsFound);
  }, [fileContentList]);

  const deleteSelectedItems = () => {
    setShowLoadingBar(true);
    setShowDeleteModalWindow(false);
    service
      .deleteFeeSchedules(deleteIdList)
      .then((response) => {
        setShowLoadingBar(false);
        if (
          response.status === 200 ||
          response.status === 201 ||
          response.status === 204
        ) {
          showNotifyWindow("show", "success", "Deleted Successfully");
          getFileContents(PAGE_SIZE, activePage);
        } else {
          throw Error(response.statusText);
        }
      })
      .catch((err) => {
        setShowLoadingBar(false);
        showNotifyWindow("show", "error", err.message);
      });
  };

  const updateSchedules = () => {
    setShowLoadingBar(true);
    const body = {
      master_custom_fee_schedule_id: fileId,
      custom_fee_schedules: [...fileContentList],
    };
    service
      .updateEditedCustomSchedules(body)
      .then((response) => {
        setShowLoadingBar(false);
        if (response.status === 200 || response.status === 201) {
          showNotifyWindow("show", "success", "Updated Successfully");
          getFileContents(PAGE_SIZE, activePage);
        } else {
          throw Error(response.statusText);
        }
      })
      .catch((err) => {
        setShowLoadingBar(false);
        showNotifyWindow("show", "error", err.message);
      });
  };

  const addNewSchedule = (item) => {
    setShowLoadingBar(true);
    const body = {
      master_custom_fee_schedule_id: fileId,
      procedure_code: item.procedure_code,
      professional_fee: item.professional_fee
    };
    service
    .addCustomSchedules(body)
      .then((response) => {
        setShowLoadingBar(false);
        if (response.status === 200 || response.status === 201) {
          showNotifyWindow("show", "success", i18n.t('commons.successUpdation'));
          getFileContents(PAGE_SIZE, activePage);
        } else if(response.status === 400 ) {
          showNotifyWindow("show", "error", i18n.t('errorMessages.record_exists'));
        }
         else {
          throw Error(response.statusText);
        }
      })
      .catch((err) => {
        setShowLoadingBar(false);
        showNotifyWindow("show", "error", err.message);
      });
  };
  const onUpdateClickHandler = () => updateSchedules();

  const onDeleteAction = () => setShowDeleteModalWindow(true);

  const addNewItem = ({ procedureCode, professionalFee }) => {
    addNewSchedule({
      procedure_code: procedureCode,
      professional_fee: professionalFee,
    });
  };

  const handleSelectAllClick = () => {
    const updatedList = fileContentList.map((fileItem) => ({
      ...fileItem,
      isSelected: !allSelected,
    }));
    setFileContentList(updatedList);
  };

  return (
    <>
    <Notify
        showNotify={showNotify}
        setShowNotify={setShowNotify}
        notifyDescription={notifyDescription}
        setNotifyType={setNotifyType}
        setNotifyDescription={setNotifyDescription}
        notifyType={notifyType}
      />
    <div className="container">
      <div className="row">
        <div className="col-12">
          {isedited && (
            <button
              className="btn btn-primary-blue ml-2 float-right"
              onClick={onUpdateClickHandler}
            >
              {i18n.t("buttons.update")}
            </button>
          )}
          {deleteIdList.length !== 0 && (
            <button
              className="btn btn-primary-blue mb-2 float-right"
              onClick={onDeleteAction}
              id="DeleteButton"
            >
              {i18n.t("customerSetup.feeSchedule.feeScheduleEdit.deleteSelected")}
            </button>   
          )}
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>
                  <strong>{i18n.t("customerSetup.feeSchedule.feeScheduleEdit.procedureCode")}</strong>
                </TableCell>
                <TableCell>
                  <strong>{i18n.t("customerSetup.feeSchedule.feeScheduleEdit.professionalFee")}</strong>
                </TableCell>
                <TableCell>
                  <strong>{i18n.t("customerSetup.feeSchedule.feeScheduleEdit.effectiveDate")}</strong>
                </TableCell> 
                <TableCell>
                  <div className="justify-center">
                    <div className="padding-top2">{i18n.t("customerSetup.feeSchedule.feeScheduleEdit.selectAll")} </div>
                    <div className="margin-left5 ">
                    <Checkbox
                      id="select-all"
                      type="checkbox"
                      checked={ fileContentList.length== 0 ? false : allSelected}
                      onClick={handleSelectAllClick}
                      aria-label="Select All"
                    />
                    </div>
                  </div>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <NewRowCard
                addNew={addNewItem}
                showNotification={showNotifyWindow}
                effectiveDate={
                  fileContentList.length !== 0
                    ? fileContentList[0].effective_date
                    : ""
                }
              />
              {fileContentList.length === 0 ? (
                <TableRow>
                  <TableCell colSpan={"4"}>{initialTableData}</TableCell>
                </TableRow>
              ) : (
                fileContentList.map((item, index) => (
                  <TableRow key={index}>
                    <TableCell>
                      <CustomTextField 
                        name={"procedure_code"}
                        type={"text"}
                        value={item.procedure_code}
                        onChange={(e) => handleInputChange(e, index)}
                      />
                    </TableCell>
                    <TableCell>
                      <CustomTextField 
                        name={"professional_fee"}
                        type={"text"}
                        value={item.professional_fee.replace(/\D/g, "")}
                        onChange={(e) => handleInputChange(e, index)}
                      />
                    </TableCell>
                    <TableCell>{YMD_TO_MDY(item.effective_date)}</TableCell>
                    <TableCell>
                      <Checkbox
                        id={'checkbox' + '-' + index}
                        data-testid={'checkbox' + '-' + index}
                        checked={item.isSelected}
                        onClick={(e) => handleSelected(e, item)}
                        aria-label="Select"
                      />
                    </TableCell>
                  </TableRow>
                ))
              )}
            </TableBody>
          </Table>
        </div>
        <div className="col-12 mt-2">
          <Pagination
            totalPage={totalPage}
            activePage={activePage}
            startIndex={startIndex}
            endIndex={endIndex}
            onPagePrevious={onPagePrevious}
            onPageUp={onPageUp}
            onPageNext={onPageNext}
          />
        </div>
        <CustomizedSmallDialogs
          showModal={showDeleteModalWindow}
          header={i18n.t("commons.confirmDelete")}
          type="delete"
          deleteItem={deleteSelectedItems}
          setShowModalWindow={setShowDeleteModalWindow}
        >
          Are you sure you want to delete?
        </CustomizedSmallDialogs>
      </div>
    </div>
    </>
  );
};

export default FeeFileEditForm;
