import React, { useContext, useEffect, useState } from "react";
import Notify from "../../commons/notify";
import Table from "../../commons/Table/Table";
import "./Style.css";
import {
  RolesAndPermissionTableBodyData,
  ListRolesAndPermissionsTableData,
} from "./listRolesAndPermissionsTable";
import LoadingContext from "../../../container/loadingContext";
import service from "./service";
import {
  DEFAULT_PAGING_SIZE,
  PAGING_END_INDEX,
} from "../../../utilities/staticConfigs";
import { getStorage } from "../../../utilities/browserStorage";
import Pagination from "../../commons/pagination";
import CustomizedSmallDialogs from "../../modalWindowComponent/CustomisedSmallDialog";
import { checkPermission } from "../../../utilities/commonUtilities";
import { customer_admin_privilege, permission_key_values, permission_key_values_administration, super_admin_privileges,super_admin_roles_permission_privilege} from "../../../utilities/permissions";
import ListRolePermissionsNew from "./ListRolePermission";
import i18n from "../../../utilities/i18n";
import CommonButton from "../../commons/Buttons";

const RolesAndPermissions = () => {
  const [permission, setPermission] = useState(false);
  const [showNotify, setShowNotify] = useState("hide");
  const [notifyDescription, setNotifyDescription] = useState("");
  const [notifyType, setNotifyType] = useState("success");
  const practicePK = getStorage("practice");

  const [showEdit, setShowEdit] = useState(false);
  const [showAdd, setShowAdd] = useState(false);

  const [rolesDatas, setRolesDatas] = useState([]);
  const [selectedRole, setSelectedRole] = useState("");
  // Pagination
  const [totalPage, setTotalPage] = useState(1);
  const [activePage, setActivePage] = useState(1);
  const [startIndex, setStartIndex] = useState(0);
  const [endIndex, setEndIndex] = useState(PAGING_END_INDEX);
  const setShowLoading = useContext(LoadingContext);

  const handleAddRole = () => {
    if (!permission) {
      showNotifyWindow('show', 'error', i18n.t('errorMessages.permission_error'))
      return;
    }
    setShowAdd(true);
  };

  function showNotifyWindow(action, type, desc, age = 3000) {
    if (action === "show") {
      setTimeout(() => {
        setShowNotify("hide");
      }, age);
    }
    setShowNotify(action);
    setNotifyType(type);
    setNotifyDescription(desc);
  }

  useEffect(() => {
    getPracticeRoles(DEFAULT_PAGING_SIZE, activePage);
    const isAdminModule = getStorage("isAdminModule");
    let hasEditPermission = false;

    const isSuperAdmin = checkPermission(
      super_admin_privileges.super_admin_full_privilege,
      super_admin_roles_permission_privilege.super_admin_roles_permission
    );

    if (isAdminModule === "false") {
      if (
        checkPermission(
          super_admin_privileges.super_admin_full_privilege,
          permission_key_values_administration.practice_role_add,
          customer_admin_privilege
        ) ||
        isSuperAdmin
      ) {
        hasEditPermission = true;
      }
    } else if (isSuperAdmin) {
      hasEditPermission = true;
    }

    setPermission(hasEditPermission);
  }, []);
  
  function getPracticeRoles(pageSize, page) {
    //  set loader
    setShowLoading(true);

    let result = service.GetRoles(practicePK, pageSize, page);
    result.then((response) => {
      // off loader
      setShowLoading(false);

      if (response.data?.results !== undefined) {
        let dataTotalPage = Math.ceil(
          response.data.count / response.data.page_size
        );
        setTotalPage(dataTotalPage);
        if (dataTotalPage < activePage) {
          setActivePage(dataTotalPage);
        }
      }

      // When the practices first role is created the data is coming in the response.data array instead of response.data?.results array hence handling it here
      if (!response.data?.results && (Array.isArray(response.data) && response.data?.length)) {
        response.data['results'] = response.data;
      }

      let anArray = [];
      let rowArray = [];
      let newData = Array(response.data.results.length).fill(
        JSON.parse(
          JSON.stringify(ListRolesAndPermissionsTableData.tableBodyData[0])
        )
      );

      newData.forEach((row, rowIndex) => {
        row.forEach((col) => {
          let colObject = {};
          colObject = {
            ...col,
            value: response.data.results[rowIndex][col.name],
            id: response.data.results[rowIndex].id,
          };

          anArray.push(colObject);
        });
        rowArray.push(anArray);
        anArray = [];
      });
      if (rowArray.length > 0) {
        ListRolesAndPermissionsTableData.tableBodyData = rowArray;
      } else {
        ListRolesAndPermissionsTableData.tableBodyData =
          RolesAndPermissionTableBodyData;
      }
      if (response.data) setRolesDatas(response.data.results);
    });
  }
  function dropDownFunction(idValue) {
    if (!permission) {
      showNotifyWindow('show', 'error', i18n.t('errorMessages.permission_error'));
      return;
    }
    let obj = rolesDatas.find((item) => item.id === idValue);
    if (obj.is_default_role) {
      showNotifyWindow('show', 'error', i18n.t('errorMessages.system_default_role_not_allowed_to_delete'));
      return;
    }
    setSelectedRole(obj);
    setShowEdit(true);
  }

  function onPagePrevious() {
    let previousPage = startIndex + 1 - PAGING_END_INDEX;
    setActivePage(previousPage);

    if (startIndex !== 0) {
      setStartIndex(startIndex - PAGING_END_INDEX);
      setEndIndex(endIndex - PAGING_END_INDEX);
    }
    getPracticeRoles(DEFAULT_PAGING_SIZE, previousPage);
  }

  function onPageUp(e) {
    let page = Number(e.target.id);
    setActivePage(page);
    getPracticeRoles(DEFAULT_PAGING_SIZE, page);
  }

  function onPageNext() {
    let nextPage = startIndex + 1 + PAGING_END_INDEX;
    if (endIndex === totalPage || totalPage <= PAGING_END_INDEX) {
      setActivePage(nextPage);
      setStartIndex(startIndex);
      setStartIndex(endIndex);
    } else {
      setActivePage(nextPage);
      setStartIndex(startIndex + PAGING_END_INDEX);
      setEndIndex(endIndex + PAGING_END_INDEX);
    }
    getPracticeRoles(DEFAULT_PAGING_SIZE, nextPage);
  }

  const closeAddWindow = () => {
    setShowAdd(false)
    getPracticeRoles(DEFAULT_PAGING_SIZE, activePage)
  }

  const closeEditWindow = () => {
    setShowEdit(false)
    getPracticeRoles(DEFAULT_PAGING_SIZE, activePage)
  }

  return (
    <div style={{ width: "100%" }}>
      <div className="col-md-12">
        <div className="box basic-info-padding bordering border-radius-8" style={{padding:'15px'}}>
          <Notify
            showNotify={showNotify}
            setShowNotify={setShowNotify}
            notifyDescription={notifyDescription}
            setNotifyType={setNotifyType}
            setNotifyDescription={setNotifyDescription}
            notifyType={notifyType}
          />
          <div className="mt-4 float-right mb-2">
            <CommonButton variant='contained' onClick={handleAddRole} label ={'Add Role'}/>
          </div>
          <div className="table margin-top20">
            <div className="table-responsive">
              <Table
                tableObject={ListRolesAndPermissionsTableData}
                isActionVisible={checkPermission(permission_key_values.dashBoard_add)}
                dropDownFunction={dropDownFunction}
                />
            </div>
              <Pagination
                totalPage={totalPage}
                activePage={activePage}
                startIndex={startIndex}
                endIndex={endIndex}
                onPagePrevious={onPagePrevious}
                onPageUp={onPageUp}
                onPageNext={onPageNext}
              />
          </div>
          <CustomizedSmallDialogs
            type="save"
            showModal={showEdit}
            setShowModalWindow={setShowEdit}
            header={"Roles And Permissions"}
            fullWidth={true}
          >
            <ListRolePermissionsNew
              selectedRoleId={selectedRole.group || selectedRole.group_id}
              isEdit={true}
              closeModalWindow={closeEditWindow}
              setShowEdit={closeEditWindow}
            />
          </CustomizedSmallDialogs>
          <CustomizedSmallDialogs
            showModal={showAdd}
            type="save"
            header={"Roles And Permissions"}
            setShowModalWindow={setShowAdd}
            fullWidth={true}
          >
            <ListRolePermissionsNew
              selectedRole={selectedRole}
              isEdit={false}
              closeModalWindow={closeAddWindow}
            />
          </CustomizedSmallDialogs>
        </div>
      </div>
    </div>
  );
};

export default RolesAndPermissions;