/**
 * This module not in use - May need in future when implementing Dynamic report generation
 */

import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isGrouped: false,
  headerNames: [],
  reportData: [],
};

const formatGroupedData = (result) => {
  const newResult = result.map((res, index) => {
    const keys = Object.keys(res);
    return { id: index, header: [keys[0]], data: res[keys[0]], isOpen: false };
  });
  return newResult;
};

export const reportResultSlice = createSlice({
  name: "reportViewer",
  initialState,
  reducers: {
    setResults: (state, action) => {
      const { data, display_names, grouping_display_name } =
        action.payload.results;
      if (grouping_display_name.length > 0) {
        state.headerNames = [...grouping_display_name, ...display_names];
        state.reportData = formatGroupedData(data);
      } else {
        state.headerNames = display_names;
        state.reportData = data;
      }
      state.isGrouped =
        action.payload.results.grouping_display_name.length > 0 ? true : false;
    },
    setOpen: (state, action) => {
      state.reportData.forEach((report) => {
        if (report.id === action.payload.id) {
          report.isOpen = !report.isOpen;
        }
      });
    },
  },
});

export const { setResults, setOpen } = reportResultSlice.actions;

export default reportResultSlice.reducer;
