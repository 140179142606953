import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { PAGING_END_INDEX } from "../../../utilities/staticConfigs";
import service from "../patients/service";
import service2 from '../../customerSetup/providerManagement/service'
import { getStorage } from "../../../utilities/browserStorage";

const initialState = {
    filterQuery: {
        lastNameStarts: "",
        firstNameStarts: "",
        dob1: "",
        dob2: "",
        dos1: "",
        dos2: "",
        createdFrom: "",
        createdTo: "",
        notCheckAfter: "",
        insuranceValue: [],
        practiceLocation: [],
        facility: [],
        patientType: [],
        renderingProvider: [],
        referingProvider: [],

    },
    tableData: [],
    pageSize: 0,
    activePage: 1,
    count: 0,
    totalPage: 0,
    startIndex: 0,
    endIndex: PAGING_END_INDEX,
    allData: [{
        id: "",
        insurance_id: ""
    }],
    message: '',
    isSuccess: false,
    isError: false,
    loading: false,
    batchRunSuccessFlag: false,
    ProviderGroupList: [],
    Provider: "",
}


/**
 * Handling create batch api call on advanced search hit
 */
export const batchEligibilityListPatients = createAsyncThunk(
    "batchEligibility/batchEligibilityListPatients",
    async ({ query, batchQuery }, thunkAPI) => {
        
        try {

            const promises = [service.ListPatients(batchQuery), service.ListPatients(query)]

            const [allData, tableData] = await Promise.all(promises);
            if ((!allData?.data?.results?.length || !tableData?.data?.results?.length) && (allData.status !== 200 || allData.status !== 200)) {
                throw new Error('No data found.')
            } else {
                return {
                    allData,
                    tableData,
                }
            }

        } catch (err) {
            return thunkAPI.rejectWithValue({ errorMessage: 'Batch eligibility error' });
        }
    }
);

/**
 * api call while paginating.
 */
export const getPaginationData = createAsyncThunk(
    "batchEligibility/getPaginationData",
    async (payload, thunkAPI) => {
        try {
            const res = await service.ListPatients(payload.query);
            if (res.status !== 200 && !res.data?.results?.length) {
                throw new Error('An error occurred while fetching pagination data.')
            } else {
                return { res, page: payload.page };
            }
        } catch (err) {
            return thunkAPI.rejectWithValue({ errorMessage: 'An error occurred while fetching pagination data.' })
        }

    }
);

export const ProvidersData = createAsyncThunk(
    "batchEligibility/ProvidersData",
    async (payload, thunkAPI) => {
        try {
            const praticePK = getStorage("practice");
            const result = service2.getProvidersData(20,0,praticePK);
            return result;

        } catch (err) {
            return thunkAPI.rejectWithValue({errorMessage:"An Error occured while fetching provider data."})
        }
    }
)

export const runBatchEligibiliity = createAsyncThunk(
    "batchEligibility/runBatchEligibiliity",
    async (payload, thunkAPI) => {
        try {

            const provider = payload.provider;
            const practice_id = payload.practice_id;
            let insurance_info_ids = [];
            const allData = thunkAPI.getState().batchEligibilityTab2.allData;
            allData.forEach((item) => {
                if (item.insurance_info_id) {
                    insurance_info_ids.push(item.insurance_info_id);
                }
            });
            if (insurance_info_ids.length > 500 || insurance_info_ids.length < 4) {
                if (insurance_info_ids.length > 500) throw new Error("max_count_error")
                else throw new Error("min_count_error")

            }
            const result = await service.RunBatchEligibility({ provider, practice_id, insurance_info_ids });
            if (result?.status === 200) { 
                return result;
            } else {
                throw result;
            }
        } catch (err) {
            if (err.response && err.response.status === 400) {
                return thunkAPI.rejectWithValue({ errorMessage: "Bad Request: Please check your input data." });
            } else if (err.message == "max_count_error") {
                return thunkAPI.rejectWithValue({ errorMessage: "Only 500 records are allow in a single request." })
            } else if (err.message == "min_count_error") {
                return thunkAPI.rejectWithValue({ errorMessage: "Minimum 4 records are required to run batch eligibility." })
            } else {
                return thunkAPI.rejectWithValue({ errorMessage: "Batch eligibility error." })
            }
        }
    }
)


const batchEligbilitySlice = createSlice({
    name: 'batchEligibility',
    initialState,
    reducers: {

        onPageNext: (state, actions) => {
            state.startIndex = actions.payload.startIndex;
            state.endIndex = actions.payload.endIndex
        },

        onPagePrevious: (state, actions) => {
            state.startIndex = actions.payload.startIndex;
            state.endIndex = actions.payload.endIndex
        },

        setActivePage: (state, actions) => {
            state.activePage = actions.payload.active
        },

        /**handle provider */
        handleProvider: (state, actions) => {
            state.Provider = actions.payload
        },

        /**
         * handling single checkbox click
         * @param {*} state 
         * @param {*} actions 
         */
        handleCheckboxClick: (state, actions) => {
            const isChecked = actions.payload.isChecked
            const patientId = actions.payload.patientId
            const insuraneId = actions.payload.insuranceId

            state.tableData = state.tableData.map(item => {
                if (item.id == patientId) {
                    return {
                        ...item,
                        isChecked: isChecked
                    }
                } else return item
            })
            if (isChecked) {
                state.allData = [...state.allData, { insurance_info_id: insuraneId, patient_id: patientId }]
            } else {
                state.allData = state.allData.filter((item) => item.patient_id !== patientId)
            }
            state.isAllChecked = state.tableData.map(item => { if (item.id == patientId) { return { ...item, isChecked: isChecked } } else return item })?.every(item => item.isChecked);
        },

        /**
         * handling all checkbox click
         * @param {*} state 
         * @param {*} actions 
         */
        handleCheckBoxAllClick: (state, actions) => {
            const isChecked = actions.payload.isChecked;
            if (isChecked) {
                state.tableData = state.tableData.map((item) => ({ ...item, isChecked }));
                state.allData = [...state.allData, ...state.tableData.map((item) => ({ patient_id: item.id, insurance_info_id: item.insurance_id }))];
            } else {
                state.tableData = state.tableData.map((item) => ({ ...item, isChecked }));
                const patientIdsToRemove = state.tableData.map(item => item.id);
                state.allData = JSON.parse(JSON.stringify(state.allData))?.filter((item) => (!patientIdsToRemove.includes(item.patient_id)));
            }
            state.isAllChecked = isChecked;
        },

        /**
         * handle select insurance dropdown
         * @param {*} state 
         * @param {*} actions 
         */
        handleInsuranceChange: (state, actions) => {
            const id = actions.payload.currentSelectedPatientToChangeInsurance;
            const insuranceId = actions.payload.newInsuranceValue;
            state.tableData = state.tableData.map(item => {
                if (item.id == id) {
                    return {
                        ...item,
                        insurance_id: insuranceId
                    }
                } else return item
            })
            state.allData = state.allData.map(item => {
                if (item.patient_id == id) {
                    return {
                        ...item,
                        insurance_info_id: insuranceId
                    }
                } else {
                    return item
                }
            })
        },

        /**
         * to handle query data
         * @param {*} state 
         * @param {*} actions 
         */
        onFilterQuery: (state, actions) => {

            let data = {
                ...state.filterQuery,
                [actions.payload.name]: actions.payload.value,
            }
            state.filterQuery = data;
        },

        resetFilterQuery: state => {
            state.filterQuery = initialState.filterQuery;
        },

        onResetData: (state) => {
            state.allData = initialState.allData
            state.tableData = initialState.tableData
            state.filterQuery = initialState.filterQuery
            state.message = initialState.message
            state.isError = initialState.isError
            state.isSuccess = initialState.isSuccess
            state.activePage = initialState.activePage
            state.pageSize = initialState.pageSize
            state.totalPage = initialState.totalPage
            state.count = initialState.count
            state.startIndex = initialState.startIndex
            state.endIndex = initialState.endIndex
            state.loading = initialState.loading
        },

        updateFieldValues: (state, actions) => {
            const updateState = actions.payload.state;
            const value = actions.payload.value;
            const field = actions.payload.field;
            if (updateState !== 'mainState') {
                return {
                    ...state,
                    [updateState]: {
                        ...state[updateState],
                        [field]: value,
                    }
                }
            } else {
                return {
                    ...state,
                    [field]: value,
                }
            }
        },
    },

    extraReducers: (builder) => {

        /**
         * checking api call response is pending
         */
        builder.addCase(batchEligibilityListPatients.pending, (state) => {
            state.loading = true;
            state.isError = false;
            state.isSuccess = false;
        })

        /**
         * api call success of list patients
         */
        builder.addCase(batchEligibilityListPatients.fulfilled, (state, action) => {
            const { allData, tableData } = action.payload;
            if (allData?.data?.length == 0) {
                state.loading = false;
                state.isError = true;
                state.isSuccess = false;
                state.message = 'No data found.';
                state.tableData = [];
                state.count = 0
                state.pageSize = 0
                state.totalPage = 0
                state.allData = []
            } else {
                state.loading = false
                state.isError = false;
                state.isSuccess = true;
                state.message = 'Batch query successfully fetched.';
                state.tableData = tableData?.data?.results?.map((item) => ({ ...item, isChecked: true })) || [];
                state.count = tableData?.data?.count
                state.activePage = 1
                state.pageSize = tableData.data?.page_size
                state.totalPage = Math.ceil(tableData?.data?.count / tableData?.data?.page_size)
                state.allData = allData?.data
                state.isAllChecked = true
            }
        })
        builder.addCase(batchEligibilityListPatients.rejected, (state) => {
            state.loading = false;
            state.isError = true;
            state.isSuccess = false;
            state.message = 'Error while fetching data.';
        })

        builder.addCase(getPaginationData.pending, (state) => {
            state.loading = true;
            state.isError = false;
            state.isSuccess = false;
            state.message = '';
        })
        builder.addCase(getPaginationData.fulfilled, (state, action) => {
            const data = action?.payload?.res?.data?.results.map((resultItem) => ({
                ...resultItem,
                isChecked: (state.allData.find((item) => item.patient_id === resultItem.id)) ? true : false,
                insurance_id: state.allData.find((item) => item.patient_id === resultItem.id)?.insurance_info_id ?? `${resultItem.insurance_id}`
            })) || [];
            state.tableData = data;
            state.activePage = action.payload.page
            state.isAllChecked = data?.every(item => item.isChecked);
            state.loading = false;
            state.isError = false;
            state.isSuccess = false;
            state.message = '';
        })
        builder.addCase(getPaginationData.rejected, (state) => {

            state.loading = false
            state.isError = true;
            state.isSuccess = false;
            state.message = 'An error occurred while fetching data.';
        })

        builder.addCase(runBatchEligibiliity.pending, (state) => {
            state.loading = true;
            state.isError = false;
            state.isSuccess = false;
            state.message = '';
        })
        builder.addCase(runBatchEligibiliity.fulfilled, (state) => {
            state.loading = false
            state.isError = false;
            state.isSuccess = true;
            state.message = 'Batch created successfully';
            state.allData = [];
            state.tableData = [];
            state.filterQuery = initialState.filterQuery;
            state.pageSize = 0;
            state.activePage = 1;
            state.count = 0;
            state.totalPage = 0;
            state.batchRunSuccessFlag = true;

        })
        builder.addCase(runBatchEligibiliity.rejected, (state, action) => {
            state.loading = false
            state.isError = true;
            state.isSuccess = false;
            state.message = action.payload.errorMessage || 'An error occurred while creating batch';
        });
        builder.addCase(ProvidersData.pending, (state) => {
            state.loading = true;
            state.isError = false;
            state.isSuccess = false;
            state.message = '';
        })
        builder.addCase(ProvidersData.fulfilled, (state, action) => {
            state.loading = false;
            state.isError = false;
            state.isSuccess = false;
            state.message = '';
            let providerResultData = action.payload.data
            let providerData = [];
            const default_Provider = providerResultData?.find(i => i.default_provider === true);
            if (default_Provider) {
                let tempData = { full_name: default_Provider.full_name, id: default_Provider.id };
                providerData.push(tempData);
                state.ProviderGroupList = providerResultData;
                state.Provider = tempData.id
            }
        })
        builder.addCase(ProvidersData.rejected, (state) => {
            state.loading = false
            state.isError = true;
            state.isSuccess = false;
            state.message = 'An error occurred while fetching provider data.';
        })

    }
})

export const { onPagePrevious,
    setActivePage,
    onPageNext,
    toggleSelectAll,
    handleCheckboxClick,
    handleCheckBoxAllClick,
    setPatientInsurance,
    handleInsuranceChange,
    onResetData,
    resetFilterQuery,
    updateFieldValues,
    handleProvider,
} = batchEligbilitySlice.actions;

export default batchEligbilitySlice.reducer;

