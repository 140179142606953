import api from '../../../service/api'; 

const GetModifiersList = async(pageSize,page, searchValue, orderingField, orderType) =>{
	let path = 'super-admin/modifier/?page_size='+pageSize+'&page='+page;
		if (searchValue)
	{
        path += '&search='+ searchValue;
	}
    if(orderingField){
        let fieldOrder = '';
        if (orderType) fieldOrder+= orderType;
        fieldOrder += orderingField;
        path += '&column='+fieldOrder;   
    } else {
        path += '&column=name';
    }
	return api.get(path);
}

const AddModifier = async(data) =>{
	let path = 'super-admin/modifier/'
	return api.post(path,data);
}
const GetModifier= async(payerId) =>{
	let path = 'super-admin/modifier/'+payerId
	return api.get(path);
}
const DeleteModifier= async(payerId) =>{
	let path = 'super-admin/modifier/'+payerId
	return api.destroy(path);
}

const UpdateModifier= async(payerId,data) =>{
	let path = 'super-admin/modifier/'+payerId
	return api.put(path,data);
}

const ImportCSVData = async(data) =>{
	let path = 'super-admin/import-modifiers/'
	return api.post(path,data);
}

export default {GetModifiersList,AddModifier,GetModifier,DeleteModifier,UpdateModifier,ImportCSVData};