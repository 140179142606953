export const permissionsList = [
    {
        "id": 8,
        "module_name": "Analytics",
        "permission_id": 800,
        "permission_code_name": "analytics",
        "sub_module": [
            {
                "id": 101,
                "sub_module_name": "Data Visualization",
                "permission_id": 13,
                "permission_code_name": "view_data",
                "categories": [1, 2, 3],
                "sub_module_permission": {
                    "1": [{ "id": 2100, "codename": "customize_dashboard" }, { "id": 21, "codename": "customize_dashboard" }, { "id": 3100, "codename": "customize_dashboard" }],
                    "2": [{ "id": 23, "codename": "customize_dashboard" }],
                    "3": [{ "id": 26, "codename": "customize_dashboard" }]
                }
            },
            {
                "id": 102,
                "sub_module_name": "Data Export",
                "permission_id": 15,
                "permission_code_name": "export_data",
                "categories": [1, 2, 3],
                "sub_module_permission": {
                    "2": [{ "id": 29, "codename": "select_format" }],
                    "1": [{ "id": 30, "codename": "select_format" }],
                    "3": [{ "id": 33, "codename": "select_format" }]
                }
            }
        ]
    },
    {
        "id": 9,
        "module_name": "Patients",
        "permission_id": 801,
        "permission_code_name": "analytics",
        "sub_module": [
            {
                "id": 201,
                "sub_module_name": "Patients List",
                "permission_id": 55,
                "permission_code_name": "view_data",
                "categories": [1, 2, 3],
                "sub_module_permission": {
                    "1": [{ "id": 70, "codename": "customize_dashboard" }],
                    "2": [{ "id": 71, "codename": "customize_dashboard" }],
                    "3": [{ "id": 72, "codename": "customize_dashboard" }]
                }
            },
            {
                "id": 43,
                "sub_module_name": "Run Eligibility",
                "permission_id": 32,
                "permission_code_name": "export_data",
                "categories": [1, 2, 3],
                "sub_module_permission": {
                    "2": [{ "id": 63, "codename": "select_format" },
                    { "id": 90, "codename": "select_format" }
                    ],
                    "1": [{ "id": 64, "codename": "select_format" }],
                    "3": [{ "id": 65, "codename": "select_format" }]
                }
            }
        ]
    }
];

export const savedRole = {
    "id": 15,
    "role_name": "admin31",
    "practice": 1,
    "group": 10,
    "selected_permissions": [
        {
            "id": 3,
            "module_name": "patient",
            "permission_id": 1,
            "permission_code_name": "add_logentry",
            "is_checked": true,
            "sub_module": [
                {
                    "id": 1,
                    "sub_module_name": "patient_list",
                    "permission_id": 100,
                    "permission_code_name": "change_logentry",
                    "is_checked": true,
                    "categories": [
                        1,
                        2,
                        3
                    ],
                    "selected_categories": [
                        1, 2
                    ],
                    "sub_module_permission": {
                        "1": [
                            {
                                "id": 200,
                                "codename": "delete_logentry"
                            },
                            {
                                "id": 201,
                                "codename": "change_group"
                            },
                            {
                                "id": 203,
                                "codename": "delete_group"
                            }
                        ],
                        "2": [
                            {
                                "id": 204,
                                "codename": "view_group"
                            }
                        ],
                        "3": [
                            {
                                "id": 205,
                                "codename": "add_user"
                            }
                        ]
                    }
                },
                {
                    "id": 2,
                    "sub_module_name": "eligibility",
                    "permission_id": 300,
                    "permission_code_name": "add_contenttype",
                    "is_checked": true,
                    "categories": [
                        1
                    ],
                    "selected_categories": [
                        1
                    ],
                    "sub_module_permission": {
                        "1": [
                            {
                                "id": 207,
                                "codename": "change_logentry"
                            }
                        ],
                        "2": [],
                        "3": []
                    }
                }
            ]
        }
    ]
}