import React, { useEffect } from 'react';
import TableBody from './TableBody';
import { TableHead } from './TableHead';
import './style.css'
import { ResizeTable } from './ResizeTable';
import i18n from '../../../utilities/i18n';

const Table = (props) => {

    const isResizable = props.tableObject.isResize;
    const tableHeadings = props.tableObject.tableHeadings;
    const tableBodyData = props.tableObject.tableBodyData;
    const isReportTable = props.tableObject.isReportTable;
    const checkboxEnable = (Object.prototype.hasOwnProperty.call(props, "isCheckboxDisabled") ? props.isCheckboxDisabled ? false : true : false);

    // The below overflowXStyle should not be applied when rendering report tables
    const overFlowStyle = tableHeadings.length > 4 && !isReportTable ? { overflowX: "",
    //  overflowY: "scroll",
      width: "100%" } : {};

    let tblRadiusClass = props.tblRadiusClass ? props.tblRadiusClass : ""; 


    // Apply border-radius dynamically to the parent div of the element with id  `table-wrapper`
    // Border-radius is applying in the method due to the below styling conflict
    // When border-radius and position sticky passed together the table is loosing sticky feature but by applying to the parent's parent element its working as expected   
    useEffect(() => {
        const tableWrapper = document.getElementById('table-wrapper');
        if (tableWrapper?.parentElement && tableWrapper.parentElement.tagName === 'DIV') {
            if (tableWrapper.parentElement.firstChild === tableWrapper) {
                tableWrapper.parentElement.className += ` ${tblRadiusClass}`;
            }
        }
    }, []);

    return (
        <div id='table-wrapper' className='pb-1 mb-1' style={overFlowStyle}>
            {isResizable ? (
                <ResizeTable
                    id="tr-hover"
                    className={props.tableObject.isFixedHead ? "table fixed_head resize mb-5" : "table resize mb-5"}
                    tableHeadings={
                        <TableHead
                            tableSortingData={props.tableSortingData}
                            sortingFunction={props.sortingFunction}
                            isResize={true}
                            tableHeadData={props.tableObject.tableHeadings}
                            onClickGridCheckBox={props.onClickGridCheckBox ?? null}
                            checkedHeaderCheckBox={props?.checkedHeaderCheckBox ?? false}
                            checkboxEnable={checkboxEnable}
                        />
                    }
                    tableRows={
                        <TableBody
                            isResize={true}
                            tableBodyData={props.tableObject.tableBodyData}
                            dropDownFunction={props.dropDownFunction}
                            defaultBold={props.defaultBold}
                            onLinkClick={props.onLinkClick}
                            procedureRows={props.procedureRows ?? null }
                            noRecordsMsg={props.tableObject?.noRecordsMsg ? props.tableObject.noRecordsMsg : i18n.t('commons.noRecords')}
                            tableType={props.tableObject.name}
                            onRowClick={props?.onRowClick ?? null}
                            onClickGridCheckBox={props.onClickGridCheckBox ?? null}
                            onMouseOverWarning={props.onMouseOverWarning ?? null}
                            isLoading={props.isLoading ?? false}
                            exportFunction={props.exportFunction ?? null}
                            onPlusButtonClick={props.onPlusButtonClick ?? null}
                            colSpanNum={props.tableObject.colSpanNum ?? 1}
                            onExpandClick={props.onExpandClick ?? null}
                            cptHistoryEnable={props.cptHistoryEnable ? props.cptHistoryEnable:false }
                            onShowMessage={props.onShowMessage ?? null}
                            diagnosisVariable={props.diagnosisVariable ?? null}
                            addDiagnosisFromPatientHistory={props.addDiagnosisFromPatientHistory ?? null}
                            addCPTFromPatientHistory={props.addCPTFromPatientHistory??null}
                            removeDiagnosisFromPatientHistory={props.removeDiagnosisFromPatientHistory ?? null}
                            checkboxEnable={checkboxEnable}
                            rowBackgroundChange={props?.rowBackgroundChange}
                        />
                    }
                />
            ) : (
                    <table className={`table ${props.tableObject.isFixedHead ? 'fixed_head' : ''} mb-5`} id="tr-hover">
                    <TableHead
                        tableSortingData={props.tableSortingData}
                        sortingFunction={props.sortingFunction}
                        tableHeadData={tableHeadings}
                            onClickGridCheckBox={props.onClickGridCheckBox ?? null}
                            checkedHeaderCheckBox={props.checkedHeaderCheckBox ?? false}
                            checkboxEnable={checkboxEnable}
                    />
                        <TableBody
                            tableBodyData={tableBodyData}
                            dropDownFunction={props.dropDownFunction}
                            defaultBold={props.defaultBold}
                            onLinkClick={props.onLinkClick}
                            noRecordsMsg={props.tableObject?.noRecordsMsg ? props.tableObject.noRecordsMsg : i18n.t('commons.noRecords')}
                            tableType={props.tableObject.name}
                            onRowClick={props.onRowClick ?? null}
                            onClickGridCheckBox={props.onClickGridCheckBox ?? null}
                            onMouseOverWarning={props.onMouseOverWarning ?? null}
                            isLoading={props.isLoading ?? false}
                            exportFunction={props.exportFunction ?? null}
                            onPlusButtonClick={props.onPlusButtonClick ?? null}
                            rowExpansion={props.tableObject.rowExpansion ?? null}
                            colSpanNum={props.tableObject.colSpanNum ?? 1}
                            onExpandClick={props.onExpandClick ?? null}
                            onShowMessage={props.onShowMessage ?? null}
                            checkboxEnable={checkboxEnable}
                            rowBackgroundChange={props?.rowBackgroundChange}
                            procedureRows={props.procedureRows ?? null}
                            cptHistoryEnable={props.cptHistoryEnable ? props.cptHistoryEnable : false}
                        />
                </table>
            )
            }
        </div>
    )
}

export default Table