import React from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import i18n from "../../utilities/i18n";
import CommonButton from './Buttons';
const ExportIconButton = (props) => {

    const remShowExport = () => {
        document.getElementById("export-dropdown").classList.remove('show');
        document.getElementById("export-menu").classList.remove('show');
        document.getElementById("export-dropdown").classList.remove('active');
        document.getElementById("export-menu").classList.remove('active');
    }

    const onShowExport = () => {
        document.getElementById("export-dropdown").classList.toggle('show');
        document.getElementById("export-menu").classList.toggle('show');
    }

    return (
        <div className="dropdown" id="export-dropdown">
                <OverlayTrigger
                    placement="top"
                    delay={{ show: 250, hide: 400 }}
                    overlay={
                        <Tooltip
                            id="download-tooltip"
                            style={{
                                opacity: 0.4,
                            }}
                        >{i18n.t('commons.exportCSV')}
                        </Tooltip>}
                >
                <CommonButton label="Export" variant="outlined" icon="upload" onClick={onShowExport} onBlur={remShowExport} disabled={props.disabled}/>
            </OverlayTrigger>

            {/* className="dropdown-menu patientList download-dropdown" => className="dropdown-menu  download-dropdown" */}

            {!props.onlyXLS ?
                <ul
                    className="dropdown-menu download-dropdown"
                    id="export-menu">
                    <li
                        style={{ cursor: 'pointer' }}
                        className="download-dropdown-menu-item"
                        onMouseDown={(e) => props.onExportCsv(e)}>
                        {i18n.t('commons.exportCSV')}

                    </li>
                    <li
                        style={{ cursor: 'pointer' }}
                        className="download-dropdown-menu-item"
                        onMouseDown={(e) => props.onExportXls(e)}>
                        {i18n.t('commons.exportXLS')}

                    </li>
                </ul> :
                <ul
                    className="dropdown-menu download-dropdown"
                    id="export-menu">
                    <li
                        style={{ cursor: 'pointer' }}
                        className="download-dropdown-menu-item"
                        onMouseDown={(e) => props.onExportXls(e)}>
                        {i18n.t('commons.exportXLS')}

                    </li>
                </ul>}
        </div>
    )
}

export default ExportIconButton