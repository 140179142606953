import React, { useState, useEffect } from 'react';
import i18n from '../../utilities/i18n';
import CustomizedDialogs from '../modalWindowComponent/CustomizedDialogs';
import { ProcedureComponent } from './ProcedureComponent';
import { getStorage } from '../../utilities/browserStorage';
import service from "./service";
import TextInput from '../commons/input/input';
import Notify from '../commons/notify';
import { checkPermission, commonTableBody } from '../../utilities/commonUtilities';
import Table from '../commons/Table/Table';
import { TestCodeNameTable } from './TestCodeSetupTable';
import { permission_key_values } from '../../utilities/permissions';

const procedureData = {
    "cpt_hcpcs": "",
    "cpt_hcpcsList": [],
    "pos_id": '',
    "pos_idList": [],
    "m1": "",
    "m1List": [],
    "m2": "",
    "m2List": [],
    "m3": "",
    "m3List": [],
    "m4": "",
    "m4List": [],
    "charge_type": "",
    "charge_amount": "",
}

function TestCodeSetup() {
    const practicePK = getStorage('practice');
    const [showAssignModal, setShowAssignModal] = useState(false);
    const [inputValue, setInputValue] = useState('');
    const [inactive, setInactive] = useState(false);
    const [isEdit, setIsEdit] = useState(false);
    const [editPK, setEditPK] = useState('');
    const [testCodeList, setTestCodeList] = useState([]);

    // notify
    const [showNotify, setShowNotify] = useState('hide');
    const [notifyDescription, setNotifyDescription] = useState('');
    const [notifyType, setNotifyType] = useState('success');

    const [procedureDataRows, setProcedureDataRows] = useState(new Array(1).fill(JSON.parse(JSON.stringify(procedureData))))
    const [headerTestCode, setHeaderTestCode] = useState("");

    useEffect(() => {
        getTestCodes();
    }, [])

    function showNotifyWindow(action, type, desc, age = 3000) {
        if (action == 'show') {
            setTimeout(() => {
                setShowNotify('hide');
            }, age)
        }
        setShowNotify(action);
        setNotifyType(type);
        setNotifyDescription(desc);
    }
    

    function showAssign() {
        setHeaderTestCode(i18n.t('interface.testCodeSetup.new_testCode')); 
        setEditPK('');
        setProcedureDataRows(new Array(1).fill(JSON.parse(JSON.stringify(procedureData))));
        setInputValue('');
        setIsEdit(false);
        setInactive(false);
        setShowAssignModal(true);
    }

    function onHandleChange(e) {
        let name = e.target.name;
        let value = e.target.type === 'checkbox' ? e.target.checked : e.target.value;
        if (name === 'inactive') setInactive(value)
        else if(name === 'inputValue') setInputValue(value);

    }


    const procedureDataHandleChange = (e, index) => {
        let name = e.target.name;
        let value = e.target.value;
        setProcedureDataRows((prevValue) => {
            const newData = [...prevValue];
            newData[index] = {...newData[index], [name]: value}
            return newData;
        })
    }

    const procedureDataTypeHeadChange = (e, name, index) => {
        setProcedureDataRows((prevValue) => {
            const newData = [...prevValue];
            if (e.length > 0) {
                newData[index] = { ...newData[index], [name]: e[0].id };
                newData[index] = { ...newData[index], [name + 'List']: e };
            } else {
                newData[index] = { ...newData[index], [name]: '' };
                newData[index] = { ...newData[index], [name + 'List']: [] };
            }
            return newData;
        })
    }

    function checkProcedureEmpty() {
        let itemEmpty = false;
        procedureDataRows.forEach((item) => {
          if (!item.cpt_hcpcs) {
            itemEmpty = true;
          }
        });
        return itemEmpty;
      }

      const addProcedureRow = () => {
        let itemEmpty = checkProcedureEmpty();
        if (itemEmpty) return;
        setProcedureDataRows(prevValue => [...prevValue, procedureData]);
      };

    function saveTestCode() {
        if (!inputValue) {
          showNotifyWindow('show', 'error', i18n.t('errorMessages.invalid_test_code'));
          return;
        }
        let itemEmpty = checkProcedureEmpty();
        if (itemEmpty) {
          showNotifyWindow('show', 'error', i18n.t('errorMessages.invalid_or_duplicate_cpt_codes'));
          return;
        }
        let data = { 'practice': practicePK, 'code': inputValue, 'active': !inactive, 'procedures': procedureDataRows };
        let result = '';
        if (!isEdit) {
          result = service.NewTestCode(data);
        } else {
          result = service.UpdateTestCode(editPK, data);
        }
        if (result) {
          result.then((response) => {
            if (response.status === 200 || response.status === 201) {
              if (getTestCodes) {
                getTestCodes();
              }
              if (setEditPK) {
                setEditPK('');
              }
              if (setIsEdit) {
                setIsEdit(false);
              }
              if (setShowAssignModal) {
                setShowAssignModal(false);
              }
            } else if (response.status === 400) {
              if (response.data.message) {
                showNotifyWindow('show', 'error', i18n.t('errorMessages.' + response.data.message));
              } else if (Object.keys(response.data)) {
                showNotifyWindow('show', 'error', i18n.t('errorMessages.' + Object.keys(response.data)[0]));
              }
            }
          });
        }
      }

    function getTestCodes(){
        const result = service.ListTestCode(practicePK);
        result.then((response) => {
            const rowArray = commonTableBody(response.data, TestCodeNameTable.tableBodyData[0]);
            TestCodeNameTable.tableBodyData = rowArray;
            setTestCodeList(response.data);
        })
    }

    function onEditTestCode(codePK){
        setHeaderTestCode(i18n.t('interface.testCodeSetup.edit_testCode')); 
        const result = service.getTestCode(codePK);
        result.then((response) => {
            setIsEdit(true);
            setEditPK(codePK);
            setInputValue(response.data.code);
            setInactive(!response.data.active);
            let newArray = [];
            let procedureApi = response.data.procedures;
            procedureApi.map((item, index) => {
                newArray[index] = { ...newArray[index], "id": item.id};
                newArray[index] = { ...newArray[index], "cpt_hcpcs": item.cpt ? item.cpt : ""};
                newArray[index] = { ...newArray[index], "cpt_hcpcsList": item.cpt ? [{"id": item.cpt, "name": item.cpt_name}] : []};

                newArray[index] = { ...newArray[index], "pos_id": item.pos ? item.pos : ""};
                newArray[index] = { ...newArray[index], "pos_idList": item.pos ? [{"id":item.pos, "name": item.pos_name}]: []};
                newArray[index] = { ...newArray[index], "m1": item.m1 ? item.m1 : ""};
                newArray[index] = { ...newArray[index], "m1List": item.m1 ? [{"id":item.m1, "name": item.m1_name}]: []};
                newArray[index] = { ...newArray[index], "m2": item.m2 ? item.m2 : ""};
                newArray[index] = { ...newArray[index], "m2List": item.m2 ? [{"id":item.m2, "name": item.m2_name}]: []};
                newArray[index] = { ...newArray[index], "m3": item.m3 ? item.m3 : ""};
                newArray[index] = { ...newArray[index], "m3List": item.m3 ? [{"id":item.m3, "name": item.m3_name}]: []};
                newArray[index] = { ...newArray[index], "m4": item.m4 ? item.m4 : ""};
                newArray[index] = { ...newArray[index], "m4List": item.m4 ? [{"id":item.m4, "name": item.m4_name}]: []};
                newArray[index] = { ...newArray[index], "charge_type": item.charge_type};
                newArray[index] = { ...newArray[index], "charge_amount": item.charge_amount};
            });
            setProcedureDataRows(newArray);
            setShowAssignModal(true);
        })
    }

    function removeProcedureRow(e, id) {
        const newArr = [...procedureDataRows];
        newArr.splice(id, 1)
        setProcedureDataRows(newArr); 
    }

    function dropDownFunction(id, name) {
        if (name === 'Edit'){
            onEditTestCode(id);
        }
    }

    return (
        <div className='col-md-12'>
            <Notify showNotify={showNotify} setShowNotify={setShowNotify} notifyDescription={notifyDescription} setNotifyType={setNotifyType} setNotifyDescription={setNotifyDescription} notifyType={notifyType} />
            <div className='box basic-info-padding'>
                <div className='d-flex justify-content-between mb-2'>
                    <div>
                        <h4>{i18n.t('interface.testCodeSetup.list_of_testCode')}</h4>
                    </div>
                    <div>
                        {/* permission checking */}
                        {checkPermission(permission_key_values.interface_test_code_setup_add)&&
                        <button type='button' onClick={showAssign} className='btn btn-primary-blue px-4'>
                            {i18n.t('interface.testCodeSetup.new_testCode')}
                        </button>
                        }
                    </div>
                </div>
                <div className=''>
                    <Table tableObject={TestCodeNameTable} list={testCodeList} onLinkClick={dropDownFunction} />
                </div>
                <div className=''>
                    <CustomizedDialogs type='save' header={headerTestCode} showModal={showAssignModal} setShowModalWindow={setShowAssignModal}
                        submit={false} onSaveData={saveTestCode}>
                        <div className='row justify-content-between'>
                            <div className='col-4 mt-2'>
                                
                                <TextInput type="text" name="inputValue" id="inputValue" 
                                    label={i18n.t("interface.testCodeSetup.testCode")}
                                    value={inputValue} onValueChange={(e) => onHandleChange(e)}
                                />
                            </div>
                        </div>
                        
                        <div className='mt-2'>
                            {
                                procedureDataRows.map((item, index) => {
                                    return (
                                        <div key={index}>
                                            <ProcedureComponent index={index} procedureData={item}
                                                onHandleChange={procedureDataHandleChange}
                                                onHandleTypeHead={procedureDataTypeHeadChange}
                                                removeProcedureRow={removeProcedureRow}
                                            />
                                        </div>
                                    )
                                })
                            }
                        </div>
                        <div className='row justify-right margin-right0'>
                            <button type='button' className='btn btn-primary-blue' onClick={addProcedureRow}>
                                {i18n.t('interface.testCodeSetup.addProcedure')}
                            </button>
                        </div>
                        <div className='row'>
                            <div className="col-1 max-width-3">
                                <div className="form-group">
                                    <div className="custom-checkbox lh-0">
                                        <input type="checkbox" id="inactive_test_code" name="inactive"
                                            checked={inactive} onChange={(e) => onHandleChange(e)}
                                        />
                                        <label className="checkbox" htmlFor="inactive_test_code">{i18n.t('interface.testCodeSetup.inactive')}</label>
                                    </div>
                                </div>
                            </div>
                            <div className="col padding-top5">
                                <label className="padding-top5 margin-top-3px">{i18n.t('interface.testCodeSetup.inactive')}</label>
                            </div>
                        </div>
                    </CustomizedDialogs>
                </div>
            </div>
        </div>
    )
}

export default TestCodeSetup;