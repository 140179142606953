import React, { useState, useEffect, useContext } from 'react';
import { Form } from 'react-bootstrap'
import CustomizedDialogs from '../../modalWindowComponent/CustomizedDialogs'
import service from './service'
import { ROUTE_SUPER_DICTIONARIES, PAGING_END_INDEX, DEFAULT_PAGING_SIZE } from '../../../utilities/staticConfigs';
import { ADD_SUCCESS, ADD_ERROR, UPDATE_SUCCESS, DELETE_SUCCESS } from '../../../utilities/labelConfigs';
import Notify from '../../commons/notify';
import Pagination from '../../commons/pagination';
import LoadingContext from '../../../container/loadingContext'
import i18n from '../../../utilities/i18n';
import TextInput from '../../commons/input/input';
import CustomizedSmallDialogs from '../../modalWindowComponent/CustomisedSmallDialog';
import Table from '../../commons/Table/Table';
import { checkPermission, commonTableBody } from '../../../utilities/commonUtilities';
import BackArrowWithLabel from "../../commons/Back";
import CommonButton from "../../commons/Buttons";
import { super_admin_permission_key_value, super_admin_privileges } from '../../../utilities/permissions';
import { PaymentSourceTableData } from './PaymentSourceTable';

const PaymentSource = (props) => {
    const [showModalWindow, setShowModalWindow] = useState(false);
    const [showDeleteModalWindow, setShowDeleteModalWindow] = useState(false);
    const [paymentSourceCode, setPaymentSourceCode] = useState('');
    const [shortCode, setShortCode] = useState('');
    const [deletePaymentSourceCode, setDeletePaymentSourceCode] = useState('');
    const [deletePaymentSourceCodeId, setDeletePaymentSourceCodeId] = useState('');
    const [editPaymentSourceCodeId, setEditPaymentSourceCode] = useState('');
    const [paymentSourceCodeList, setPaymentSourceCodeList] = useState([]);
    const [form_error, setFormError] = useState({ 'paymentSourceCode': '', 'shortCode': '' });
    const [editForm, setEditForm] = useState(false);
    const [header, setHeader] = useState('')
    const [permission, setPermission] = useState(false);
    const [searchValue, setSearchValue] = useState("");

    //start ==== Alert message properties
    const [showNotify, setShowNotify] = useState('hide');
    const [notifyDescription, setNotifyDescription] = useState('');
    const [notifyType, setNotifyType] = useState('success');
    const setShowLoadingBar = useContext(LoadingContext);

    function showNotifyWindow(action, type, desc, age = 3000) {
        if (action === 'show') {
            setTimeout(() => {
                setShowNotify('hide');
            }, age)
        }
        setShowNotify(action);
        setNotifyType(type);
        setNotifyDescription(desc);

    }
    // end

    //Pagination start
    const [totalPage, setTotalPage] = useState(1);
    const [activePage, setActivePage] = useState(1);
    const [startIndex, setStartIndex] = useState(0);
    const [endIndex, setEndIndex] = useState(PAGING_END_INDEX);

    function onPagePrevious() {
        let previousPage = startIndex + 1 - PAGING_END_INDEX;
        setActivePage(previousPage);

        if (startIndex !== 0) {
            setStartIndex(startIndex - PAGING_END_INDEX);
            setEndIndex(endIndex - PAGING_END_INDEX);
        }
        getPaymentSourceCodesData(DEFAULT_PAGING_SIZE, previousPage, searchValue);
    }

    function onPageUp(e) {
        let page = Number(e.target.id)
        setActivePage(page);
        getPaymentSourceCodesData(DEFAULT_PAGING_SIZE, page, searchValue);
    }

    function onPageNext() {
        let nextPage = startIndex + 1 + PAGING_END_INDEX;
        if (endIndex === totalPage || totalPage <= PAGING_END_INDEX) {
            setActivePage(nextPage);
            setStartIndex(startIndex);
            setStartIndex(endIndex);

        } else {
            setActivePage(nextPage);
            setStartIndex(startIndex + PAGING_END_INDEX);
            setEndIndex(endIndex + PAGING_END_INDEX);

        }
        getPaymentSourceCodesData(DEFAULT_PAGING_SIZE, nextPage, searchValue);

    }
    //Pagination ends

    function addNew() {
        if (!permission) {
            showNotifyWindow('show', 'error', i18n.t('errorMessages.permission_error'));
            return;
        }
        setHeader("Add New Payment Source Code");
        setShowModalWindow(true);
    }

    function onHide() {
        setEditForm(false);
    }

    function onDeleteHide() {
        setDeletePaymentSourceCode('');
        setDeletePaymentSourceCodeId('');
        setShowDeleteModalWindow(false);
    }

    async function getPaymentSourceCodesData(pageSize, page, searchValue) {
        setShowLoadingBar(true);
        try {
            const response = await service.GetListPaymentSourceCodes(pageSize, page, searchValue);
            if (response.data.results !== undefined) {
                response.data.results = response.data.results?.map(item => {
                    if (item.not_delete === true) {
                        item.isDefault = "Yes";
                    } else {
                        item.isDefault = "No";
                    }
                    return item;
                });
                setTotalPage(Math.ceil(response.data.count / response.data.page_size));
                const rowArray = commonTableBody(response.data.results, PaymentSourceTableData.tableBodyData[0]);
                PaymentSourceTableData.tableBodyData = rowArray;
            }
            setPaymentSourceCodeList(response.data.results);
        } catch (error) {
            console.error("Error fetching payment adjustment type data:", error);
        } finally {
            setShowLoadingBar(false);
        }
    }


    useEffect(() => {
        getPaymentSourceCodesData(DEFAULT_PAGING_SIZE, activePage);
        setPermission(checkPermission(super_admin_privileges.super_admin_full_privilege, super_admin_permission_key_value.dictionary_add))
    }, []);

    async function onEditPaymentSourceCode(id) {
        if (!id) return;
        try {
            setShowLoadingBar(true);
            const response = await service.GetPaymentSourceCode(id);
            if (response?.data?.name) {
                setHeader("Edit Payment Source Code");
                setShowModalWindow(true);
                setPaymentSourceCode(response?.data?.name);
                setShortCode(response?.data?.short_code);
                setEditForm(true);
                setEditPaymentSourceCode(id);
            }

        } catch (error) {
            console.error('Failed to get payment adjustment type:', error);
        } finally {
            setShowLoadingBar(false);
        }
    }


    function onDeletePaymentAdjustmentType(id) {
        if (!id) return;
        paymentSourceCodeList.map((item) => {
            if (item.id === parseInt(id)) {
                setDeletePaymentSourceCode(item.name);
                setDeletePaymentSourceCodeId(item.id);
                setShowDeleteModalWindow(true);
            }

        });
    }

    async function onDeleteAction() {
        setShowLoadingBar(true);
        try {
            const response = await service.DeletePaymentSourceCode(deletePaymentSourceCodeId);
            if (response.status === 204) {
                setDeletePaymentSourceCode('');
                setDeletePaymentSourceCodeId('');
                setShowDeleteModalWindow(false);
                showNotifyWindow('show', 'success', DELETE_SUCCESS);
                getPaymentSourceCodesData(DEFAULT_PAGING_SIZE, activePage, searchValue);
            } else {
                throw new Error("An un-known error occurred while deleting.")
            }
        } catch (error) {
            console.error('Failed to delete payment source code:', error);
            showNotifyWindow('show', 'error', i18n.t("errorMessages.Un_expected_error"));
        } finally {
            setShowLoadingBar(false);
        }
    }

    const handleKeyDown = (e) => {
        if (e.key === "Enter" && searchValue) {
            onSearch(e, searchValue);
        }
    };

    function onSearch(e, searchString) {
        e.preventDefault();
        setActivePage(1);
        setStartIndex(0);
        getPaymentSourceCodesData(DEFAULT_PAGING_SIZE, 1, searchString);
    }
    const onClose = () => {
        setSearchValue("");
        setActivePage(1);
        setStartIndex(0);
        getPaymentSourceCodesData(DEFAULT_PAGING_SIZE, activePage);
    };

    function resetForm() {
        setPaymentSourceCode('');
        setShortCode('');
        setEditForm(false);
        setEditPaymentSourceCode("");
        setFormError({
            ...form_error,
            ['paymentSourceCode']: '',
            ['shortCode']: ''
        });
    }


    async function onSaveFormData(e) {
        e.preventDefault();
        // Validate form fields and update form error state
        setFormError({
            ...form_error,
            paymentSourceCode: !paymentSourceCode.trim() ? 'error' : '',
            shortCode: !shortCode ? 'error' : ''
        });

        if (paymentSourceCode && shortCode) {
            setShowLoadingBar(true);
            try {
                let response;

                if (editForm) {
                    response = await service.UpdatePaymentSourceCode(editPaymentSourceCodeId, {
                        name: paymentSourceCode,
                        short_code: shortCode
                    });
                } else {
                    response = await service.AddPaymentSourceCode({
                        name: paymentSourceCode,
                        short_code: shortCode
                    });
                }

                if (response.status === 201 || response.status === 200) {
                    if (response.status === 201) {
                        showNotifyWindow('show', 'success', ADD_SUCCESS);
                    } else {
                        showNotifyWindow('show', 'success', UPDATE_SUCCESS);
                    }

                    getPaymentSourceCodesData(DEFAULT_PAGING_SIZE, activePage, searchValue);
                    resetForm();
                    setShowModalWindow(false);
                } else if (response.status === 400 && response.data.record_already_exists !== undefined) {
                    showNotifyWindow('show', 'error', i18n.t('errorMessages.record_exists'));
                } else {
                    showNotifyWindow('show', 'error', ADD_ERROR);
                }
            } catch (error) {
                console.error('Failed to save form data:', error);
                showNotifyWindow('show', 'error', ADD_ERROR);
            } finally {
                setShowLoadingBar(false);
            }
        }
    }


    function onHandleChange(e) {
        const { name } = e.target;
        let value = e.target.value;
        let trimmedValue = '';
        trimmedValue = value;
        if (name === "paymentSourceShortCode" && trimmedValue.length > 5) return;
        if (name == "searchValue") {
            setSearchValue(trimmedValue);
            return;
        }
        if (!trimmedValue) {
            setFormError({
                ...form_error,
                [name]: 'error'
            });
        } else {
            setFormError({
                ...form_error,
                [name]: ''
            });
        }
        if (name === 'paymentSourceCode')
            setPaymentSourceCode(trimmedValue);
        else { setShortCode(trimmedValue) }
    }


    function backToDictionaryPage() {
        props.history.push(ROUTE_SUPER_DICTIONARIES)
    }


    function dropDownFunction(id, name) {
        if (!permission) {
            showNotifyWindow('show', 'error', i18n.t('errorMessages.permission_error'));
            return;
        }

        const isSystemDefaultItem = paymentSourceCodeList.some((item) => {
            if (item.id === id && item.not_delete) {
                return true;
            } else {
                return false;
            }
        });
        if (isSystemDefaultItem) {
            showNotifyWindow('show', 'error', i18n.t('dictionariesPages.dictClaimStatus.editRestrictionMessage'));
            return;
        }

        if (name.toLowerCase() == 'edit') {
            onEditPaymentSourceCode(Number(id));
        }
        if (name.toLowerCase() == 'delete') {
            onDeletePaymentAdjustmentType(Number(id));
        }
    }

    return (
        <React.Fragment>
            <Notify showNotify={showNotify} setShowNotify={setShowNotify} notifyDescription={notifyDescription} setNotifyType={setNotifyType} setNotifyDescription={setNotifyDescription} notifyType={notifyType} />
              <div className="col-md-8">
                <div className="box box-content-white  mb-3">
                    <div className="row mr-0 mb-2">
                        <div className="col pl-4 mb-1">
                            <div className={"dataTables_filter"}>
                                <div
                                    className="link dictionaries-back"
                                    onClick={backToDictionaryPage}>
                                    <BackArrowWithLabel
                                        label={i18n.t(
                                            "dictionariesPages.gotoDictionaries"
                                        )}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="box-content ">
                        <div className="common-search-wrapper-style">
                            <div className="input-content-box">
                                <div className="form-group padding-top15 relative">
                                    <TextInput
                                        type="text"
                                        id="searchValue"
                                        name="searchValue"
                                        value={searchValue}
                                        onValueChange={onHandleChange}
                                        label={i18n.t('dictionariesPages.paymentSourceCode.name')}
                                        onKeyDown={handleKeyDown}
                                        labelClassName={"margin-bottom0"}
                                        clearButton={
                                            typeof searchValue == "string" &&
                                            searchValue.length !== 0
                                        }
                                        clearInput={onClose}
                                    />
                                </div>
                            </div>
                            <div className="input-content-box padding-top15">
                                <div className="margin-top18">
                                    <CommonButton
                                        variant="contained"
                                        label="Search"
                                        onClick={(e) =>
                                            onSearch(e, searchValue)
                                        }
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="box box-content-white">
                    <div className="box-content">
                        <div className="alignRight margin-bottom3">
                            <CommonButton  variant="contained" onClick={addNew} label={i18n.t("buttons.addNew")} />
                        </div>
                        <div className="table-responsive">
                            <Table tableObject={PaymentSourceTableData} dropDownFunction={dropDownFunction} />  
                        </div>
                        <div className='mt-2 mb-2'>
                            <Pagination totalPage={totalPage} activePage={activePage} startIndex={startIndex} endIndex={endIndex} onPagePrevious={onPagePrevious} onPageUp={onPageUp} onPageNext={onPageNext} />
                        </div>
                    </div>
                </div>
            </div>
            <CustomizedDialogs
                showModal={showModalWindow}
                type="save"
                header={header}
                setShowModalWindow={setShowModalWindow}
                resetForm={resetForm}
                onHide={onHide}
            >
                <Form
                    id="form_dataEntry"
                    autoComplete="off"
                    onSubmit={(e) => onSaveFormData(e)}
                    encType="multipart/form-data"
                >
                    <Form.Group>
                        <div className="row">
                            <div className="col-md-12">
                                <div className="form-group padding-top15">
                                    <TextInput
                                        type="text"
                                        id="paymentSourceCode"
                                        name="paymentSourceCode"
                                        required={true}
                                        label={i18n.t('dictionariesPages.paymentSourceCode.name')}
                                        onValueChange={onHandleChange}
                                        className={form_error.paymentSourceCode ? 'input-error' : ""}
                                        value={paymentSourceCode}
                                    />
                                </div>

                                <div className="form-group">
                                    <div className={'text-input'}>
                                        <TextInput
                                            type="text"
                                            id="paymentSourceShortCode"
                                            name="paymentSourceShortCode"
                                            required={true}
                                            label={i18n.t('dictionariesPages.paymentSourceCode.short_code')}
                                            onValueChange={onHandleChange}
                                            className={!shortCode ? 'textarea-100 input-error' : "textarea-100"}
                                            value={shortCode}
                                            onChange={(e) => onHandleChange(e)}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Form.Group>
                </Form>
            </CustomizedDialogs>

            <CustomizedSmallDialogs
                showModal={showDeleteModalWindow}
                header={i18n.t("commons.confirmDelete")}
                type="delete"
                deleteItem={onDeleteAction}
                resetForm={resetForm}
                onHide={onDeleteHide}
                setShowModalWindow={setShowDeleteModalWindow}>
                {i18n.t('dictionariesPages.paymentSourceCode.delete_confirmation') + deletePaymentSourceCode + "'?"}
            </CustomizedSmallDialogs>
        </React.Fragment>
    )
}

export default PaymentSource