import i18n from "../../utilities/i18n"

const TableHeadData = [
    {
        "name": i18n.t("interface.testCodeSetup.table.code"),
        "type": "string",
        "width": "large",
        "sort": false,
    },
    {
        "name": i18n.t("interface.testCodeSetup.table.active"),
        "type": "string",
        "width": "small",
        "sort": false,
    },
    {
        "name": i18n.t("interface.testCodeSetup.table.actions"),
        "type": "string",
        "width": "action_column",
        "sort": false,
    },
]
const TableBodyData = [
    [
        {
            "id": "",
            "name": "code",
            "value": "",
            "type": "string",
            "align": "left",
            "width": "large",
        },
        {
            "id": "",
            "name": "active",
            "value": "",
            "type": "boolean",
            "booleanCondition": "Active Inactive",
            "align": "left",
            "width": "small",
        },
        {
            "id": "",
            "name": "Edit",
            "value": "Edit",
            "type": "link",
            "needLink": 'yes',
            "align": "center",
            "width": "action_column",
        },
    ]
]

export const TestCodeNameTable = {
    "name": "simpleTable",
    "isResize": false,
    "isFixedHead": false,
    "tableHeadings": TableHeadData,
    "tableBodyData": TableBodyData
}
