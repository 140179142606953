import React, { } from 'react';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import "./style.css";
import Label from '../Label';
const PhonInput = (props) => {
    const { value, name, label, onValueChange, required, disabled } = props;
    return (
        <div>
            <Label label={label} />
            <div className="form-group phone-control padding-top10">
                <PhoneInput
                    value={value ? value : ""}
                    country={'us'}
                    onlyCountries={['us']}
                    id={name ? name : "myPhoneinput"}
                    onChange={onValueChange}
                    inputClass={required ? (value && value.length > 10 ? "" : "input-error") : ""}
                    disabled={disabled ? disabled : ""}
                />
            </div>
        </div>
    )
}
export default PhonInput
