import api from '../../../service/api'

const GetListAdjustmentReasonCode = async (...args) => {
    let [ pageSize, page, isAdminModule, practicePK, practiceDict, searchValue, orderingField,orderType] = args;
    let path = null;
    if (isAdminModule === "true")
        path = "super-admin/reason-code/?page_size=" + pageSize + "&page=" + page;
    else if (practiceDict)
        path ="super-admin/reason-code/?page_size=" +pageSize + "&page=" + page + "&practice_pk=" + practicePK;
    else
        path = "practice/reason-code/?page_size=" + pageSize + "&page=" + page + "&practice_pk=" +practicePK;
    if (searchValue) path += "&search=" + searchValue;


	if (orderingField && orderingField != "dropdown") {
        if (isAdminModule !== "true"){
            orderingField = "master__" + orderingField;
		}
        let fieldOrder = "";
        if (orderType) fieldOrder += orderType;
		fieldOrder += orderingField;
        path += "&column=" + fieldOrder;
    } else {

        if (isAdminModule === "true" || practiceDict) {
			path += "&column=name";
		} else {
			path += "&column=master__name";
		} 
    }
	return api.get(path);
}

// 	if(isAdminModule === 'true' || practiceDict){
// 		path += '&column=name';
// 	} else {
// 		path += '&column=master__name';
// 	}
// 	// if (searchFromDict)
// 	// 	path += '&search_from_dict=' + searchFromDict
// 	return api.get(path);
// }
const AddAdjustmentReasonCode = async (data) => {
	let path = null;
	if (Object.prototype.hasOwnProperty.call(data, 'practice_pk'))
		path = 'practice/reason-code/';
	else
		path = 'super-admin/reason-code/';
		return api.post(path, data);
}

const GetAdjustmentReasonCode = async (id, practicePK) => {
	let path = null;
	if (practicePK)
		path = 'practice/reason-code/' + id;
	else
		path = 'super-admin/reason-code/' + id;
		return api.get(path);
}

const DeleteAdjustmentReasonCode = async (id, practicePK) => {
	let path = null;
	if (practicePK)
		path = 'practice/reason-code/' + id;
	else
		path = 'super-admin/reason-code/' + id;
		return api.destroy(path);
}

const UpdateAdjustmentReasonCode = async (id, data) => {
	let path = null;
	if (Object.prototype.hasOwnProperty.call(data, 'practice'))
		path = 'practice/reason-code/' + id;
	else
		path = 'super-admin/reason-code/' + id;
		return api.put(path, data);
}

const ImportCSVData = async (data) => {
	let path = 'super-admin/import-adjustment-code/';
	return api.post(path, data);
}



const exportAdjustmentResonCode = async (type, practicePK) => {
	const path = 'practice/reason-code/?practice_pk=' + practicePK + '&export=true&export_type=' + type;
	return api.getXLS(path);
}


export default { exportAdjustmentResonCode, GetListAdjustmentReasonCode, AddAdjustmentReasonCode, GetAdjustmentReasonCode, DeleteAdjustmentReasonCode, UpdateAdjustmentReasonCode, ImportCSVData }