import React, { useState } from 'react'
import CommonButton from '../Buttons'
import Popover from '@mui/material/Popover';
import { Checkbox, Typography } from '@mui/material';
import Stack from '@mui/material/Stack';
import Chip from '@mui/material/Chip';
import i18n from '../../../utilities/i18n';

function MyProfilePracticeSelector({
    myProfilePracticesList,
    myProfileSelectedPractices,
    handleMaterialMultiselectSearch,
    resetMyProfileWindow }) {

    const [popOverAnchor, SetPopOverAnchor] = useState(null);
    const [isPopOverOpen, SetIsPopOverOpen] = useState(false);


    const handleAddPracticeClick = (event) => {
        SetPopOverAnchor(event.currentTarget);
        SetIsPopOverOpen(true)
    };

    const handlePopOverClose = () => {
        SetPopOverAnchor(null);
        SetIsPopOverOpen(false)

    };

    const handleSelection = (id, isChecked) => {

        handleMaterialMultiselectSearch(isChecked ? myProfileSelectedPractices?.filter(item => item !== id) : [...myProfileSelectedPractices, id])
    }

    return (
        <>
            <div >

                <div className='row'>
                    <div className='col-6'>
                        <h5 style={{paddingTop:'15px',fontFamily:'Lato',fontSize:'13px',color:'#616161',paddingLeft:'3px'}}>Practices</h5>
                    </div>
                    <div className='col-6' style={{ display: 'flex', justifyContent:"flex-end" }}>
                        <div style={{ marginRight: '20px' }}>
                            <CommonButton variant="outlined"
                            onClick={resetMyProfileWindow}
                                label={i18n.t('commons.clearAll')}/>
                        </div>
                        <div >
                            <CommonButton variant="contained"
                            icon='add'
                                label={i18n.t('commons.addNewPractices')}
                                onClick={handleAddPracticeClick} />
                            <Popover
                                id={1}
                                open={isPopOverOpen}
                                anchorEl={popOverAnchor}
                                onClose={handlePopOverClose}
                                anchorOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'right',
                                }}
                                transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'right',
                                }}
                            >
                                {
                                    myProfilePracticesList.length > 0 ?
                                        myProfilePracticesList.map((item) => {
                                            const isChecked = myProfileSelectedPractices.some(item1 => item1 === item.id);
                                            return (
                                                <Stack
                                                    key={item.id}
                                                    sx={{
                                                        boarderRadius: '3px',
                                                        minWidth:'300px',
                                                        cursor: 'pointer',
                                                        background: isChecked ? 'rgba(158,207,250,0.3)' : '',
                                                        '&:hover': {
                                                            backgroundColor: !isChecked ? '#dbdfe2a8' : ''
                                                        },
                                                    }}
                                                    onClick={() => { handleSelection(item.id, isChecked) }}
                                                    direction="row"
                                                    alignItems="center"
                                                    gap={1}
                                                    padding="3px"
                                                >
                                                    <Checkbox checked={isChecked} style={{ color: isChecked ? '#24A7E0' : 'inherit' }} />
                                                    <Typography variant='subtitle1'>
                                                        {item.name}
                                                    </Typography>
                                                </Stack>
                                            )
                                        })
                                        : null
                                }
                            </Popover>
                        </div>
                    </div>
                </div>

                <div  style={{minHeight:"100px", border: "1px solid #80808063 ",gap:8, marginTop:'10px', marginLeft: '2px', borderRadius: "4px",display: 'flex', flexWrap: 'wrap', padding: "16px"  }}>
                        {
                            myProfilePracticesList
                                .filter(item => myProfileSelectedPractices.some(selected => selected === item.id))
                                .map((item,index) => {
                                    return (
                                        <>
                                        <Stack direction="row" spacing={1} >
                                            <Chip className='chip-label-mui' key={index}  label={item.name} onDelete={() => handleSelection(item.id, true)} />
                                            </Stack>
                                        </>
                                    )
                                })
                        }
                </div>
                <div>
                </div>
            </div>

        </>
    )
}



export default MyProfilePracticeSelector