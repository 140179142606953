import api from "../../service/api";
import { getStorage } from "../../utilities/browserStorage";

const getUserLogs = async (query) => {
    let path = 'fetch-logs/'+query;
    return api.get(path);
    
}
const ListPractices = async (pageSize, page, practicePK) => {
    let path = 'practice/practice/?page_size=' + pageSize + '&page=' + page + '&list=true&status=all';
    if (practicePK)
        path += '&practice_pk=' + practicePK;
     return api.get(path);
}
const GetListPatientDropdown = async (search, type, patientPK) => {
    let path = 'patient/patients/?list_type=drop_down&page=0&practice_pk=' + getStorage('practice') + '&search=' + search;
    if (type)
        path += '&type=' + type;
    if (patientPK)
        path += '&patient_pk=' + patientPK;
     return api.get(path);
}

const getLogs = async (query) => {
    return api.get(query);
}

const ListClaimsDropDown = async (search, practicePK) => {
    let path =
        "claim/claims/?drop_down=true&search=" +
        search +
        "&page=0&practice_pk=" +
        practicePK;
    return api.get(path);
};

export default { getUserLogs, ListPractices, GetListPatientDropdown, getLogs, ListClaimsDropDown }