import { Divider, Grid } from "@mui/material";
import React from "react";
import StringFieldSelector from "./StringFieldSelector";

const StringGroupFields = ({ fields, randomReportId }) => {
  return (
    <Grid container spacing={2} className="mt-4">
      <Grid xs={6}>
        <strong>Filter Text Fields</strong>
        <Divider className="mt-2 mb-2" />
        {fields.fields.map((item, index) => (
          <StringFieldSelector
            key={index}
            item={item}
            randomReportId={randomReportId}
          />
        ))}
      </Grid>
      <Grid xs={6}></Grid>
    </Grid>
  );
};

export default StringGroupFields;
