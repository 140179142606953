import api from "../../../service/api";

const UploadFiles = async (data, type) => {
    let path = `${type}/file-uploads/`;
    return await api.post(path, data);
}

const DeleteFile = async (id, type) => {
    let path = `${type}/file-uploads/delete-file/` + id + `?parent_type=${type}`
    return api.destroy(path);
}

const GetTableDataList = async (pageSize, page, practicePK, type, parentPK) => {
    let path = `${type}/file-uploads/?page_size=${pageSize}&page=${page}&practice_pk=${practicePK}&parent_pk=${parentPK}&parent_type=${type}`;
    return await api.get(path);
};

const GetSignedUrl = async (id, type) => {
    const path = `${type}/file-uploads/get-signed-url/${id}`
    return await api.get(path);
}

export default {
    UploadFiles,
    DeleteFile,
    GetTableDataList,
    GetSignedUrl
}