
import React from 'react';
import { Link } from "react-router-dom";
import Moment from 'react-moment';
import { currencyFormat } from '../../utilities/commonUtilities';

const LinkTypeTable=(props)=>{
   
    
    function RenderTableHeaders() {
        switch (props.task_link_type) {
          case 1:
            return props.claimHeader.map((item, index) => (
              <th key={index}>{item}</th>
            ));
          default:
            return props.patientHeaders.map((item, index) => (
              <th key={index}>{item}</th>
            ));
        }
    }

    function RenderTableBody() {
        switch (props.task_link_type) {
          case 1:
            return props.claimList.map((item, index)=>{
                if (!item){
                    return <td key={index} align='center' colSpan={7}>{props.initialTableData}</td>
                }
                return(
                    <tr key={index}
                        onClick={() => props.onAddTaskLinkList({ "id": item.id, "label": item.custom_claim_id, "name": item.patient_name })}
                    >
                        <td align="center">
                        <Link to="#" className="advancedSearch" 
                        >
                            {item.custom_claim_id}
                        </Link>
                        </td>
                        <td align="left">
                            <Link to="#" className="advancedSearch">
                                {item.patient_name}
                            </Link>
                        </td>
                        <td align="center">{item.dos_from}</td>
                        <td align="right">{currencyFormat(item.charges)}</td>
                        <td align="right">{currencyFormat(item.balance)}</td>
                        <td align="center">{item.billedDate}</td>
                        <td align="left">
                            <Link to="#" className="advancedSearch">
                                {item?.responsible_payer?.name || 'Self-Pay'}
                            </Link>
                        </td>
                    </tr>
                )
            })
          default:
            return props.patientList.map((item, index)=>{
                if (!item){
                    return <td key={index} align='center' colSpan={4}>{props.initialTableData}</td>
                }
                return(
                    <tr key={index} onClick={() => props.onAddTaskLinkList({ "id": item.id, "label": item.name, "name": item.drop_down_name })}>
                        <td align='left'><span className="patientName">{item.drop_down_name}</span></td>
                        <td>{item.custom_patient_id}</td>
                        <td><Moment format="MM/DD/YYYY">{item.date_of_birth}</Moment></td>
                        <td>{item.patient_type}</td>
                    </tr>
                )
            })
        }
    }

    return(
        <table className='table' id='hover'>
            <RenderTableHeaders />
            <RenderTableBody />
        </table>
    )


}

export default LinkTypeTable;