import {React, useEffect, useContext, useState} from 'react';
import Table from '../../commons/Table/Table';
import { CsvTemplateTableData, TableBodyData } from './CsvTemplateTableData';
import service from "./service";
import { commonTableBody } from '../../../utilities/commonUtilities';
import LoadingContext from '../../../container/loadingContext';

export const CSVTemplates = () => {
    const setShowLoadingBar = useContext(LoadingContext);
    const [pageLoader, setPageLoader] = useState(0);

    async function GetTemplateData() {
        setShowLoadingBar(true);
        try {
            const response = await service.GetTemplate();
            if (response?.data?.templates) {
                if (response.data.templates.length === 0) {
                    CsvTemplateTableData.tableBodyData = TableBodyData;
                }
                else {
                    const rowArray = commonTableBody(response.data.templates, CsvTemplateTableData.tableBodyData[0])
                    CsvTemplateTableData.tableBodyData = rowArray;
                }
            }
            setPageLoader(pageLoader+1);
        } catch (error) {
            console.error("Error while fetching csv template:", error)
        } finally {
            setShowLoadingBar(false);
        }
    }

    useEffect(() => {
        GetTemplateData()
    }, [])

    return (
        <div className="col-md-8">
            <div className="box basic-info-padding bordering border-radius-8"
                style={{ paddingLeft: "32px", paddingRight: "32px", paddingTop: "32px", paddingBottom: '32px' }}>
                <div className='table-responsive'>
                    <Table tableObject={CsvTemplateTableData} />      
                </div>    
            </div>
        </div>
    )
}
