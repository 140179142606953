import React, { useState ,useEffect} from 'react'
import CurrencyInputs from './CurrencyInput';
import Label from "../../../components/commons/Label";

function RangeInput(props) {
    
    const [maxLength] = useState(props.maxLength ? props.maxLength : 200);
    const [patternFormat] = useState(props.pattern ? props.pattern : '.*');
    const [helperText, setHelperText] = useState('');

    useEffect(() => {
        if (!props.value2) {
                setHelperText("")
              }
               else {
        const number1 = parseFloat(props.value1) || 0;
        const number2 = parseFloat(props.value2) || 0;

        if (number2 < number1 ) {
           setHelperText(props.ValidationMsg)
        } else {
            setHelperText('');
        }
               }
    }, [props.value1, props.value2]);
    return (
        <div className="">
            {/* <div className="">{props.label}</div> */}
            <Label label={props.label} />

            <div className="row">
                <div className="form-group col-6" style={{ marginBottom: 0 }}>
                    <CurrencyInputs type="text" name={props.name1} id={props.id1} value={props.value1} onValueChange={props.onValueChange1}
                        className={props.className1} maxLength={maxLength} autoComplete="off" pattern={patternFormat} placeholder="From"
                        disabled={props.disabled ? props.disabled : ''}
                    />
                </div>
               
                <div className="col-6 pl-0" style={{  marginBottom: 0 }}>
                    <div className="form-group">
                        <CurrencyInputs type="text" name={props.name2} id={props.id2} value={props.value2} onValueChange={props.onValueChange2}
                            className={props.className2} maxLength={maxLength} autoComplete="off" pattern={patternFormat} placeholder="To"
                            disabled={props.disabled ? props.disabled : ''}
                        />
                    </div>
                </div>
                {helperText && <div style={{color: '#fc2d1e', fontSize: 10,marginLeft:18,marginTop:-10}}>{helperText}</div>}
            </div>
        </div>
    )
}

export default RangeInput
