import api from '../../../service/api';

const ListFacilities = async (pageSize, page, practicePK, status) => {
	let path = 'user/facility/?page_size=' + pageSize + '&page=' + page + '&practice_pk=' + practicePK;
	if (status)
		path += '&status=' + status;
		return api.get(path);
}

const ListFacilityGroups = async (pageSize, page) => {
	let path = 'super-admin/facility-type/?page_size=' + pageSize + '&page=' + page;
	return api.get(path);
}

const ListPOSCodes = async (pageSize, page) => {
	let path = 'super-admin/service-codes/?page_size' + pageSize + '&page=' + page
	return api.get(path);
}

const GetFacility = async (facilityID) => {
	let path = 'user/facility/' + facilityID;
	return api.get(path);
}

const AddFacility = async (data) => {
	let path = 'user/facility/';
	return api.post(path, data);
}

const UpdateFacility = async (facilityID, data) => {
	let path = 'user/facility/' + facilityID;
	return api.put(path, data);
}

const ListTaxonomies = async (pageSize, page) => {
	let path = 'super-admin/taxonomy/?page_size=' + pageSize + '&page=' + page;
	return api.get(path);
}

const ListStates = async (pageSize, page) => {
	let path = 'super-admin/us-states/?page_size=' + pageSize + '&page=' + page;
	return api.get(path);
}


const exportFacilities = async (type, practicePK, pageSize, page, status) => {
	const path = 'user/facility/?practice_pk=' + practicePK + '&export=true&export_type=' + type + '&page_size=' + pageSize + '&page=' + page + '&status=' + status;
	let result = null;
	if (type === 'xls')
		result = api.getXLS(path);
	else
		result = api.get(path);
	return result;
}

export default {
	exportFacilities,
	ListFacilities, ListFacilityGroups, ListPOSCodes, GetFacility, AddFacility, UpdateFacility, ListTaxonomies, ListStates
}