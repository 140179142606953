import {createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getStorage } from "../../../utilities/browserStorage";
import { replayMessage } from "../service";

const initialState = {
    messages: [],
    message: '',
    openChatBox: false,
    loading: false,
    responseTriggered: false,
    requestTriggered:false,
}

export const getreplayData = createAsyncThunk(
    "chatbox/replay-message",
    async (payload) => {
        let request = {
            "practiceid": getStorage("practice"),
            "texts": payload.msg
        }
        const response = await replayMessage(request);
        return response.data;
    }
);

export const ChatBotSlice = createSlice({
    name: 'chatbox',
    initialState,
    reducers: {
        openChatBox: (state, actios) => {
           state.openChatBox=actios.payload
        },

        updateMessage: (state, actions) => {
            const message = {
                userId:actions.payload.user,
                message: actions.payload.msg,
                timestamp: Date.now()
            }
            state.messages.push(message)
            state.requestTriggered = true;
        },

        changeResponseTriggered: (state) => {
            state.responseTriggered = false;
            state.requestTriggered = false;
        },
        // eslint-disable-next-line
        reset: (state) => initialState,

    },

    extraReducers:(builder)=>{
        builder.addCase(getreplayData.pending,(state)=> {
            state.loading = true;
            const message = {
                userId: 'loading',
                message: "",
                timestamp: Date.now()
            }
            state.messages.push(message);
        }) 
        builder.addCase(getreplayData.fulfilled, (state,actions) => {
            state.loading = false;
            const message = {
                userId: 'bot',
                message: actions.payload.fulfillment_text,
                content:actions.payload.rich_content,
                timestamp: actions.payload.timestamp
            }
            const index=state.messages.indexOf(item => item.userId == 'loading');
            state.messages.splice(index,1)
            state.messages.push(message);
            state.responseTriggered = true;
        }) 
        builder.addCase(getreplayData.rejected, (state) => {
            state.loading = false;
            const message = {
                userId: 'bot',
                message: "Please enter a valid message",
                timestamp: Date.now()
            }
            const index = state.messages.indexOf(item => item.userId == 'loading');
            state.messages.splice(index,1)
            state.messages.push(message);
        }) 
    }
});

export const { openChatBox, updateMessage, changeResponseTriggered, reset } = ChatBotSlice.actions
export default ChatBotSlice.reducer;
