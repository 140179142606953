import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
// import App from './App'; / <App />
import Router from "./container/router";
import reportWebVitals from "./reportWebVitals";
import "bootstrap/dist/css/bootstrap.min.css";
import "./assets/css/line-awesome.min.css";
import "./assets/css/fontawesome.min.css";
import "./assets/css/style.css";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import { Provider } from "react-redux";
import { store } from "./store/store";
import * as Sentry from "@sentry/react";
import { SENTRY_DNS_URL, SITE_URL } from "./utilities/urlConfigs";
import { ThemeProvider } from '@mui/material/styles';
import theme from './theme'
import { getStorage } from "./utilities/browserStorage";

const userGroupData = JSON.parse(getStorage("userData") || "{}");

if (SENTRY_DNS_URL) {
  Sentry.init({
    dsn: SENTRY_DNS_URL,
    integrations: [
      new Sentry.BrowserTracing({
        // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
        tracePropagationTargets: [SITE_URL],
      }),
      new Sentry.Replay(),
    ],
    // Performance Monitoring
    tracesSampleRate: 1.0, // Capture 100% of the transactions, reduce in production!
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  });
  if(userGroupData.username){
    Sentry.setUser({ email: userGroupData.username })
  }
}

ReactDOM.render(
  <ThemeProvider theme={theme}>
    <Provider store={store}>
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <React.Fragment>
          <Router />
        </React.Fragment>
      </MuiPickersUtilsProvider>
    </Provider>
  </ThemeProvider>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
