import React from 'react'
import { Link } from 'react-router-dom';
import "./style.css"

function RightSidePanelWithWidth(props) {

    function onShowWidthPanel() {
        document.getElementById("right-content-w").classList.add('active');
        if (props.setShowPanel)
            props.setShowPanel(true);
    }
    function onHideSettings() {
        if (props.showPanel) {
            document.getElementById("right-content-w").classList.remove('active');
            props.setShowPanel(false);
        }
    }
    if (props.showPanel) {
        onShowWidthPanel();
    } else {
        onHideSettings();
    }
    return (
        <div>
            <div className='row'>
                <div className='col mb-3'>
                    {
                        props.type == 'button' ? <button type='button' onClick={onShowWidthPanel} className='btn btn-primary-blue common-height35'>{props.clickLabel}</button>
                            :
                            !props.hideLabel &&
                            <Link to="#" id='right-side-panel-anchor' onClick={onShowWidthPanel} className="font-bold" >{props.clickLabel}</Link>
                    }
                </div>
            </div>
            <div>
                <div className='right-content-w' id={'right-content-w'} style={{ width: `${props.width ? props.width : "70%"}` }} data-testid='right-content-w' >
                    <ul className="">
                        <li className="p-3 border-bottom">
                            <div className="row">
                                <div className="col-5 text-dark display-flex padding-left15">
                                    <h5 className="padding-top10">
                                        <b style={{ marginLeft: 12,fontSize:22,fontWeight:400,color:'#212121'}}>{props.title}</b>
                                    </h5>
                                </div>
                                <div className="col-7 justify-right">
                                    <button type="button" className="close-right-side" onMouseDown={onHideSettings} data-testid='panel-close-button'>
                                        <span aria-hidden="true">&times;</span>
                                    </button>
                                </div>
                            </div>
                        </li>
                    </ul>
                    <div className="right-panel-box basic-info-padding">
                        {props.children}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default RightSidePanelWithWidth
