import { AddPhotoAlternate } from "@material-ui/icons";
import React, { useRef, useState } from "react";
import ProfileImage from "../../../../assets/images/profile-image.png";
import classes from "./ImageChangeCard.module.css";

const ImageChangeCard = ({ setImage, imgUrl }) => {
  const [picture, setPicture] = useState();
  const fileInput = useRef();
  const OnImageChanged = (event) => {
    const [file] = event.target.files;
    setPicture(URL.createObjectURL(file));
    setImage(event.target.files[0]);
  };

  return (
    <div
      className={classes.imageContainer}
      onClick={() => fileInput.current.click()}
    >
      {imgUrl ? (
        <img src={imgUrl} style={{ borderRadius: '8px' }} className={classes.image} alt="image" />
      ) : (
          <img src={picture ? picture : ProfileImage} style={{ borderRadius: '8px' }} className={classes.image} alt="image" />
      )}
      <AddPhotoAlternate className={classes.icon} />
      <input
        ref={fileInput}
        type={"file"}
        onChange={OnImageChanged}
        className={classes.fileControl}
      />
    </div>
  );
};

export default ImageChangeCard;
