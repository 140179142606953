import React, { useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import i18n from "../../../utilities/i18n";
import CalendarInput from "../../commons/input/CalendarInput";
import TextInput from '../../commons/input/input';
import { MaterialMultiselect } from "../../../MaterialMultiselect/MaterialMultiselect";
import { AGING_LIST, TRANSACTION_TYPES } from "../constants";
import SelectInput from "../../commons/input/SelectInput";
import { claimAmountType } from "../../../utilities/dictionaryConstants";
import CurrencyInputs from "../../commons/input/CurrencyInput";
import CommonButton from '../../commons/Buttons';
import downArrow from '../../../assets/images/arrow-down.png';
import upArrow from '../../../assets/images/arrow-up.png'
import { format } from 'date-fns';
import { useSelector, useDispatch } from 'react-redux';
import { getDropDownListData } from "./reportFilterSlice"
import Notify from '../../commons/notify';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import './style.css'
import moment from 'moment';
import MultiSearchSelect from "../../commons/AutoCompleteSearch";
import RangeInput from "../../commons/input/RangeInput";

/******************
 * *******Accepted Props*********
 * filter - state to store filter variables - Object
 * setFilter - state update function - Function
 * onReset - function to reset the filter input fields - Function
 * onSearch - Function to trigger search - Function
 * reportType - Type of report module eg: debitCharge, paymentAdjustment - String
 ******************/

const ReportFilter = (props) => {

  const currentDate = moment().format('MM/DD/YYYY');

  // Notify Config
  const [showNotify, setShowNotify] = useState('hide');
  const [notifyDescription, setNotifyDescription] = useState('');
  const [notifyType, setNotifyType] = useState('success');
  const [balanceAmountType, setBalanceAmountType] = useState('');

  function showNotifyWindow(action, type, desc, age = 3000) {
    if (action === 'show') {
      setShowNotify(action);
      setNotifyType(type);
      setNotifyDescription(desc);
      setTimeout(() => {
        setShowNotify('hide');
      }, age);
    }
  }
  /****************************/

  const [hideAdvanceFilter, setHideAdvanceFilter] = useState(false)

  const { icdList, payerTypes, referringProviderList, insuranceCompanyList, praticeUserList, serviceLocationList, patientIdList,
    renderingProviderList, cptList, claimStatusList, claimSubStatusList, isFilterDropDownDataReceived, isErrorGettingDropdownData
  } = useSelector((state) => state.filterDropdownData)

  const dispatch = useDispatch();

  // Dispatch Redux function to get the drop-down list data if it not there already in the state
  useEffect(() => {
    if (!isFilterDropDownDataReceived)
      dispatch(getDropDownListData())
  }, []);

  // Display error notification if any error occurred while fetching filter dropdown list data
  useEffect(() => {
    if (isErrorGettingDropdownData === true)
      showNotifyWindow("show", "error", 'An error occurred while fetching drop-down lists, please contact Admin!')
  }, [isErrorGettingDropdownData])

  function onHandleDosSecond(selected) {
      try {
          props.setFilters({
              ...props.filters,
            dateOfServiceTo: format(selected, "yyyy-MM-dd")
          });
      } catch (error) {
          props.setFilters({
              ...props.filters,
              dateOfServiceTo: "",
          });
      }
  }
  function onHandleDosFirst(selected) {
      try {
          props.setFilters({
              ...props.filters,
            dateOfServiceFrom: format(selected, "yyyy-MM-dd")
          });
      } catch (error) {
          props.setFilters({
              ...props.filters,
              dateOfServiceFrom: "",
          });
      }
  }

  function onHandleDateChange(selected, name) {
      try {
          props.setFilters({
              ...props.filters,
            [name]: format(selected, "yyyy-MM-dd")
          });
      } catch (error) {
          props.setFilters({
              ...props.filters,
              [name]: "",
          });
      }
  }
 
  function MaterialMultiSelectHandle(e) {
    let name = e.target.name;
    let value = e.target.value;
    props.setFilters({
      ...props.filters,
      [name]: value
    });
  }

  function onHandleChange(e) {
    if (e.target) {
     
      const { name, value } = e.target;
      if(name==="blanceAmountType")
        {
          claimAmountType.forEach(item=>{
            if(item.id==value)
            setBalanceAmountType(item.name)
          }
          )
        }
      props.setFilters({
        ...props.filters,
        [name]: value
      });
    }
  }

  const onChange=(value) => {
    props.setFilters({
      ...props.filters,
      patientIds: value
    });
  }


  return (
    <div>
      <Notify showNotify={showNotify} setShowNotify={setShowNotify} notifyDescription={notifyDescription} setNotifyType={setNotifyType} setNotifyDescription={setNotifyDescription} notifyType={notifyType} />
      <TransitionGroup>
        <CSSTransition
          key={'report-filter-component-with-transition'}
          timeout={700}
          classNames="fade"
          appear
          exit
          enter={false}
        >
          <Form autoComplete="off" className="searchBox pl-4 pb-5 pr-4">
            <div className="row">
              <div className="col-3">
                <CalendarInput
                  id="report-filter-dos-from"
                  label={i18n.t("reports.filters.dateOfServiceFrom")}
                  name="dateOfServiceFrom"
                  selected={props.filters.dateOfServiceFrom}
                  maxDate={currentDate}
                  onValueChange={(selected) => onHandleDosFirst(selected)}
                />
              </div>
              <div className="col-3 pl-0">
                <CalendarInput
                  id="report-filter-dos-to"
                  label={i18n.t("reports.filters.dateOfServiceTo")}
                  name="dateOfServiceTo"
                  selected={props.filters.dateOfServiceTo}
                  minDate={props.filters.dateOfServiceFrom}
                  maxDate={currentDate}
                  onValueChange={(selected) => onHandleDosSecond(selected)}
                />
              </div>
              {props.reportType != "transaction" ?
                <>
                  <div className="col-3 pl-0">
                    <CalendarInput
                      id="report-filter-date-of-entry-from"
                      label={i18n.t("reports.filters.dateOfEntryFrom")}
                      name="dateOfEntryFrom"
                      selected={props.filters.dateOfEntryFrom}
                      maxDate={currentDate}
                      onValueChange={(selected) => onHandleDateChange(selected, "dateOfEntryFrom")}
                    />
                  </div>
                  <div className="col-3 pl-0">
                    <CalendarInput
                      id="report-filter-date-of-entry-to"
                      label={i18n.t("reports.filters.dateOfEntryTo")}
                      name="dateOfEntryTo"
                      selected={props.filters.dateOfEntryTo}
                      minDate={props.filters.dateOfEntryFrom}
                      maxDate={currentDate}
                      onValueChange={(selected) => onHandleDateChange(selected, "dateOfEntryTo")}
                    />
                  </div>
                </>
                :
                <>
                  <div className="col-3 pl-0">
                    <CalendarInput
                      id="report-filter-date-of-transaction-from"
                      label={i18n.t("reports.filters.transactionFrom")}
                      name="transaction_from"
                      selected={props.filters.transaction_from}
                      maxDate={currentDate}
                      onValueChange={(selected) => onHandleDateChange(selected, "transaction_from")}
                    />
                  </div>
                  <div className="col-3 pl-0">
                    <CalendarInput
                      id="report-filter-date-of-transaction-to"
                      label={i18n.t("reports.filters.transactionTo")}
                      name="transaction_to"
                      selected={props.filters.transaction_to}
                      minDate={props.filters.transaction_from}
                      maxDate={currentDate}
                      onValueChange={(selected) => onHandleDateChange(selected, "transaction_to")}
                    />
                  </div>
                </>}
            </div>
            <div className="row">
              <div className="col-3">
                <CalendarInput
                  id="report-filter-first-billed-date-from"
                  label={i18n.t("reports.filters.firstBilledDateFrom")}
                  name="firstBilledDateFrom"
                  selected={props.filters.firstBilledDateFrom}
                  maxDate={currentDate}
                  onValueChange={(selected) => onHandleDateChange(selected, "firstBilledDateFrom")}
                />
              </div>
              <div className="col-3 pl-0">
                <CalendarInput
                  id="report-filter-first-billed-date-to"
                  label={i18n.t("reports.filters.firstBilledDateTo")}
                  name="firstBilledDateTo"
                  selected={props.filters.firstBilledDateTo}
                  minDate={props.filters.firstBilledDateFrom}
                  maxDate={currentDate}
                  onValueChange={(selected) => onHandleDateChange(selected, "firstBilledDateTo")}
                />
              </div>
              <div className="col-3 pl-0">
                <CalendarInput
                  id="report-filter-last-billed-date-from"
                  label={i18n.t("reports.filters.lastBilledDateFrom")}
                  name="lastBilledDateFrom"
                  selected={props.filters.lastBilledDateFrom}
                  maxDate={currentDate}
                  onValueChange={(selected) => onHandleDateChange(selected, "lastBilledDateFrom")}
                />
              </div>
              <div className="col-3 pl-0">
                <CalendarInput
                  id="report-filter-last-billed-date-to"
                  label={i18n.t("reports.filters.lastBilledDateTo")}
                  name="lastBilledDateTo"
                  selected={props.filters.lastBilledDateTo}
                  minDate={props.filters.lastBilledDateFrom}
                  maxDate={currentDate}
                  onValueChange={(selected) => onHandleDateChange(selected, "lastBilledDateTo")}
                />
              </div>
              {!hideAdvanceFilter &&
                <div className="ml-auto margin-right15">

                  <div className="div-float-left padding-top15 ">
                    <div className="div-float-left btn btn-common-height35 margin-top1 margin-right20 padding-top6" onClick={() => setHideAdvanceFilter(!hideAdvanceFilter)} style={{ borderRadius: "4px", backgroundColor: "transparent", color: "#1479BB", border: "1px solid #1479BB ", cursor: "pointer"}} >
                      {i18n.t("reports.title.advancedFilters")}    
                      <img 
                        src={downArrow}
                        alt="down"
                        style={{ width: '13px', height: '13px', padding: '1px', marginLeft: '5px' }}
                      />  
                    </div>

                    <span className="margin-right15" >  
                      <CommonButton 
                        onClick={props.onReset} variant="outlined" label={i18n.t("reports.title.reset")}
                      />
                    </span>

                    <CommonButton 
                      onClick={props.onSearch} 
                      variant="contained"
                      label={i18n.t("reports.title.search")}
                    />

                    {/* <Link className="advancedSearch ml-3" onClick={() => setHideAdvanceFilter(!hideAdvanceFilter)} to="#">
                      {i18n.t("reports.title.advancedFilters")}  
                    </Link>
                    <img
                      src={downArrow}
                      alt="down"
                      style={{ width: '13px', height: '13px', padding: '1px', marginLeft: '-5px' }}
                    /> */}
                  </div>
                </div>
              }
            </div>
            <div className={`advanced-filter-transition ${hideAdvanceFilter ? "" : "hide"}`}>
              {hideAdvanceFilter &&
                <>
                  <div className="row ">
                    <div className="col-3 padding-top4">
                      <MaterialMultiselect id="report-filter-payer-type-multi-select" name="payerType" value={props.filters.payerType} onValueChange={MaterialMultiSelectHandle}
                        options={payerTypes} label={i18n.t("reports.filters.payerType")}
                      />
                    </div>
                    <div className="col-3 pl-0 padding-top4">
                      <MaterialMultiselect name="insurance_companies" id="report-filter-insurance-multi-select" value={props.filters.insurance_companies} onValueChange={MaterialMultiSelectHandle}
                        options={insuranceCompanyList} label={i18n.t("reports.filters.insuranceCompanies")}
                      />
                    </div>
                    <div className="col-3 padding-top4 pl-0">
                      <MaterialMultiselect name="claimStatus" id="report-claim-status-multi-select" value={props.filters.claimStatus} onValueChange={MaterialMultiSelectHandle}
                        options={claimStatusList} label={i18n.t("reports.filters.claimStatus")}
                      />
                    </div>
                    <div className="col-3 pl-0 padding-top4">
                      <MaterialMultiselect name="claimSubStatus" id="report-filter-claim-sub-status-select" value={props.filters.claimSubStatus} onValueChange={MaterialMultiSelectHandle}
                        options={claimSubStatusList} label={i18n.t("reports.filters.claimSubStatus")}
                      />
                    </div>
                  </div>
                  <div className="row pt-4">
                    <div className="col-3 padding-top4">
                      <MaterialMultiselect name="renderingProvider" id="report-filter-rendering-provider-multi-select" value={props.filters.renderingProvider} onValueChange={MaterialMultiSelectHandle}
                        options={renderingProviderList} label={i18n.t("reports.filters.renderingProvider")}
                      />
                    </div>
                    <div className="col-3 pl-0 padding-top4">
                      <MaterialMultiselect name="refferingProvider" id="report-filter-referring-provider-multi-select" value={props.filters.refferingProvider} onValueChange={MaterialMultiSelectHandle}
                        options={referringProviderList} label={i18n.t("reports.filters.refferingProvider")}
                      />
                    </div>
                    <div className="col-3 padding-top4 pl-0">
                      <MaterialMultiselect name="billingProvider" id="report-filter-billing-provider-multi-select" value={props.filters.billingProvider} onValueChange={MaterialMultiSelectHandle}
                        options={renderingProviderList} label={i18n.t("reports.filters.billingProvider")}
                      />
                    </div>
                    <div className="col-3 pl-0 padding-top4">
                      <MaterialMultiselect name="serviceLocation" id="report-filter-service-loc-multi-select" value={props.filters.serviceLocation} onValueChange={MaterialMultiSelectHandle}
                        options={serviceLocationList} label={i18n.t("reports.filters.serviceLocation")}
                      />
                    </div>
                  </div>
                  <div className="row pt-4">
                    <div className="col-3 padding-top4">
                      <MaterialMultiselect name="cptList" id="report-filter-cpt-multi-select" value={props.filters.cptList} onValueChange={MaterialMultiSelectHandle}
                        options={cptList} label={i18n.t("reports.filters.cptList")}
                      />
                    </div>
                    <div className="col-3 pl-0 padding-top4">
                      <MaterialMultiselect name="ICD" id="report-filter-icd-multi-select" value={props.filters.ICD} onValueChange={MaterialMultiSelectHandle}
                        options={icdList} label={i18n.t("reports.filters.ICD")}
                      />
                    </div>
                    {props.reportType != "openAR" &&
                      <>
                      {
                        props.reportType != "transaction" ?
                        <>
                          <div className="col-3 padding-top4 pl-0">
                            <MaterialMultiselect name="chargeEnteredUser" id="report-filter-charge-entered-user-multi-select" value={props.filters.chargeEnteredUser} onValueChange={MaterialMultiSelectHandle}
                              options={praticeUserList} label={i18n.t("reports.filters.chargeEntered")}
                            />
                          </div>
                          <div className="col-3 pl-0">
                            <MaterialMultiselect name="paymentEnteredUser" id="report-filter-payment-entered-user-multi-select" value={props.filters.paymentEnteredUser} onValueChange={MaterialMultiSelectHandle}
                              options={praticeUserList} label={i18n.t("reports.filters.paymentEntered")}
                            />
                          </div>
                        </>
                        :
                        <>
                          <div className="col-3 pl-0 padding-top4">
                            <MaterialMultiselect name="transaction_entered_by_user" id="report-filter-transaction-entered-user-multi-select" value={props.filters.transaction_entered_by_user} onValueChange={MaterialMultiSelectHandle}
                              options={praticeUserList} label={i18n.t("reports.filters.transactionEnteredUser")}
                            />
                          </div>
                          <div className="col-3 pl-0 padding-top4">
                            <MaterialMultiselect name="transaction_type" id="report-filter-payment-entered-user-multi-select" value={props.filters.transaction_type} onValueChange={MaterialMultiSelectHandle}
                              options={TRANSACTION_TYPES} label={i18n.t("reports.filters.transaction_type")}
                            />
                          </div>
                        </>
                      }
                      </>
                      }
                  </div>

                  <div className="row pt-4">
                    {
                      props.reportType === "paymentAdjustment" && (
                        <div className="col-3">
                          <CalendarInput
                            id="report-filter-date-of-pymnt-entry"
                            label={i18n.t("reports.filters.dateOfPaymentEntry")}
                            name="dateOfPaymentEntry"
                            maxDate={currentDate}
                            selected={props.filters.dateOfPaymentEntry}
                            onValueChange={(selected) => onHandleDateChange(selected, "dateOfPaymentEntry")}
                          />
                        </div>
                      )
                    }
                    <div className={`col-3 ${props.reportType === "paymentAdjustment" ? "pl-0" : ""}`}>
                      <SelectInput
                        id="report-filter-aging-bucket-select"
                        data={AGING_LIST}
                        name="aging"
                        value={props.filters.aging}
                        onValueChange={onHandleChange}
                        label={i18n.t("reports.filters.aging")}
                      />
                    </div>

                    <div className="col-3 pl-0">
                      <SelectInput id="report-filter-balance-amt-type-select" data={claimAmountType} name="blanceAmountType" value={props.filters.blanceAmountType} onValueChange={onHandleChange} label={i18n.t("reports.filters.blnceAmountTotals")}
                      />
                    </div>
                    {
                      props?.filters?.blanceAmountType && props.filters.blanceAmountType !== "Between" ? (
                        <div className="col-3 pl-0">
                          <div className="row">
                            <div className="col">
                              <div className="form-group">
                                <CurrencyInputs
                                  id="report-filter-balance-amnt-from"
                                  type="text"
                                  name="balanceAmountTotalsFrom"
                                  value={props.filters.balanceAmountTotalsFrom.trim().replace(/[^0-9.]/g, "")}
                                  onValueChange={onHandleChange}
                                  label={ props.filters.blanceAmountType ? i18n.t("reports.filters.blnceAmountTotals") + " " + balanceAmountType : ""}
                                  placeholder={i18n.t("reports.filters.dollar")}
                                  required={true}
                                  className="w-100"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                       ) : (<></>)
                    }
                    {
                       props.filters.blanceAmountType === "Between" && (
                          <div className="col-3 pl-0">
                            <div className="col">
                              <div className="form-group">
                                <RangeInput       
                                  name1="balanceAmountTotalsFrom"
                                  name2="balanceAmountTotalsTo"
                                  value1={props.filters.balanceAmountTotalsFrom.trim().replace(/[^0-9.]/g, "")}
                                  value2={props.filters.balanceAmountTotalsTo.trim().replace(/[^0-9.]/g, "")}
                                  onValueChange1={onHandleChange}
                                  onValueChange2={onHandleChange}
                                  placeholder={i18n.t("reports.filters.dollar")}
                                  label={ props.filters.blanceAmountType ? i18n.t("reports.filters.blnceAmountTotals") + " " + balanceAmountType : ""}
                                  ValidationMsg={(i18n.t("commons.balanceError"))}
                                /> 
                              </div>
                           </div>
                         </div>
                      )}
                    {
                      props.reportType == "paymentAdjustment" && (
                        <>
                          <div className={props.filters.blanceAmountType.length > 0 ? "col-3" : "col-3 pl-0"}>
                            <CalendarInput
                              id="report-filter-payment-check-date"
                              label={i18n.t("reports.filters.paymentCheckDate")}
                              name="paymentCheckDate"
                              maxDate={currentDate}
                              selected={props.filters.paymentCheckDate}
                              onValueChange={(selected) => onHandleDateChange(selected, "paymentCheckDate")}
                            />
                          </div>
                          <div className={props.filters.blanceAmountType.length > 0 ? "col-3 pl-0" : "col-3"}>
                            <TextInput
                              type="text"
                              id="report-filter-payment-check-number"
                              name="paymentCheckNumber"
                              value={props.filters.paymentCheckNumber}
                              onValueChange={onHandleChange}
                              label={i18n.t("reports.filters.paymentCheckNumber")}
                            />
                          </div>
                        </>
                      )
                    }
                    {
                      props.reportType == "transaction" &&
                      <div className="col-3 pl-0">
                        <MultiSearchSelect name="paymentIds" id="report-filter-payment-entered-user-multi-select" value={props.filters.patientIds}
                          options={patientIdList} label={i18n.t("reports.filters.patientId")} onChange={onChange}
                        />
                      </div>
                    }
                  </div>
                  <div className="ml-auto margin-right15">
                    <div className="div-float-right padding-top24">
                      <div className="div-float-left btn btn-common-height35 margin-top1 margin-right20 padding-top6" onClick={() => setHideAdvanceFilter(!hideAdvanceFilter)} style={{ borderRadius: "4px", backgroundColor: "transparent", color: "#1479BB", border: "1px solid #1479BB ", cursor: "pointer" }} >
                        {i18n.t("reports.title.advancedFilters")}
                        <img
                          src={upArrow}
                          alt="up"
                          style={{ width: '13px', height: '13px', padding: '1px', marginLeft: '5px' }}
                        />
                      </div>

                      <span className="margin-right15" >
                        <CommonButton
                          onClick={props.onReset} variant="outlined" label={i18n.t("reports.title.reset")}
                        />
                      </span>

                      <CommonButton
                        onClick={props.onSearch}
                        variant="contained"
                        label={i18n.t("reports.title.search")}
                      />

                    </div>
                  </div>
                </>
              }
            </div>
          </Form>
        </CSSTransition>
      </TransitionGroup>
    </div>
  )
}

export default ReportFilter;