import React, { useState, useContext } from 'react';
import { Form } from 'react-bootstrap';
import './style.css';

import service from './service';
import LoadingContext from '../../../container/loadingContext';
import i18n from '../../../utilities/i18n';
import TextInput from '../input/input';
import { US_STATES } from '../../../utilities/usStates';
import { PROVIDER_TYPE_INDIVIDUAL } from "../../../utilities/dictionaryConstants";
import { NPI_SEARCH_INDIVIDUAL_CODE, NPI_SEARCH_ORGANIZATION_CODE } from "../../../utilities/staticConfigs";

const NPISearch = (props) => {
    const [searchNPIProviderType] = useState(props.providerType ? props.providerType : PROVIDER_TYPE_INDIVIDUAL);    // CONSIDERED AS LAST NAME IN CASE OF INDIVIDUALS AND ORGANIZATION NAME IN CASE OF ORGANIZATIONS 
    const [searchNPIName, setSearchNPIName] = useState(props.searchNPIName ? (props.searchNPIName + '*') : '');
    const [searchNPIFirstName, setSearchNPIFirstName] = useState(props.searchNPIFirstName ? (props.searchNPIFirstName + '*') : '');
    const [searchNPINumber, setSearchNPINumber] = useState(props.npi ? props.npi : '');
    const [searchNPICity, setSearchNPICity] = useState('');
    const [searchNPIState, setSearchNPIState] = useState('');
    const [searchNPIValue, setSearchNPIValue] = useState('');
    const [NPISearchList, setNPISearchList] = useState([]);
    const [searchNPIClicked, setSearchNPIClicked] = useState(false);
    const [showNoRecords, setShowNoRecords] = useState(false);
    const [NPIError, setNPIError] = useState(false);
    const [NPIErrorString, setNPIErrorString] = useState('');

    const setShowLoadingBar = useContext(LoadingContext);


    function onHandleChange(e) {
        const name = e.target.name;
        let value = e.target.value;
        let trimmedValue = value;
        if (name === 'searchNPIName') setSearchNPIName(trimmedValue);
        else if (name === 'searchNPIFirstName') setSearchNPIFirstName(trimmedValue);
        else if (name === 'searchNPINumber' && /^\d{0,10}$/.test(trimmedValue)) setSearchNPINumber(trimmedValue);
        else if (name === 'searchNPICity') setSearchNPICity(trimmedValue);
        else if (name === 'searchNPIState') setSearchNPIState(trimmedValue);
        else if (name === 'searchNPIValue') setSearchNPIValue(trimmedValue);
    }

    function onSearchNPI(e) {
        e.preventDefault();
        props.setSelectedNPI(searchNPIValue);
    }

    function setNPISelection(...args) {
        const [NPINum, NPIOrgName, NPIFirstName, NPIMiddleName, NPILastName, NPIAddress1, NPIAddress2, NPICity, NPIState, NPCountry, NPIPostalCode, NPIPhone, NPIEmail] = args;
        const npiData = {
            NPINum: NPINum,
            NPIOrgName: NPIOrgName,
            NPIFirstName: NPIFirstName,
            NPIMiddleName: NPIMiddleName,
            NPILastName: NPILastName,
            NPIAddress1: NPIAddress1,
            NPIAddress2: NPIAddress2,
            NPICity: NPICity,
            NPIState: NPIState,
            NPCountry: NPCountry,
            NPIPostalCode: NPIPostalCode,
            NPIPhone: NPIPhone,
            NPIEmail: NPIEmail
        };

        props.setSelectedNPI(npiData);
    }

    // function to search the NPI using NPPES API and set the list of providers returned from the search result;
    function doNIPSearch(e) {
        e.preventDefault();
        if (searchNPICity=== '' && searchNPIState === '' && searchNPIName === '' && searchNPIValue === ''
         && searchNPIFirstName==='' && searchNPINumber==='') {
            setNPIError(true);
            setNPIErrorString("Type something to search. Try a name or state.")
            setSearchNPIClicked(false);
            setShowNoRecords(false);
            return true;
        }
        if (searchNPINumber !== '') {
            if (searchNPICity !== '' || searchNPIState !== '' || searchNPIName !== '' || searchNPIValue !== ''
                || searchNPIFirstName !== '') {
                setNPIError(true);
                setNPIErrorString("NPI is a unique identifier, please clear all other search criteria and try again")
                setSearchNPIClicked(false);
                setShowNoRecords(false);
                return true;
            }
        }
        if (searchNPINumber !== '' && !/^\d{10}$/.test(searchNPINumber)) {
           setNPIError(true);
            setNPIErrorString("Please enter a valid NPI.")
            setSearchNPIClicked(false);
            setShowNoRecords(false);
            return true;
        }
            setNPIError(false);
            setNPIErrorString("")
            setSearchNPIClicked(true);
            setSearchNPIValue('');
            setNPISearchList([]);
            let searchObj = { 'providerType': searchNPIProviderType, 'lastName': searchNPIName, 'firstName': searchNPIFirstName, 'city': searchNPICity, 'state': searchNPIState,"number":searchNPINumber };
            setShowLoadingBar(true);
            const result = service.callNPISearchAPI(searchObj);
            result.then(response => {
                setShowLoadingBar(false);
                let NIPDataArr = response.data.results;
                let NIPSelList = [];

                let tmpName = '';
                let tmpAddress = '';
                let commaVal = '';
                let tmpOrgName = '';
                let tmpFirstName = '';
                let tmpMiddleName = '';
                let tmpLastName = '';
                let tmpAddr1 = '';
                let tmpAddr2 = '';
                let tmpCity = '';
                let tmpState = '';
                let tmpCountry = '';
                let tmpPostalCode = '';
                let tmpPhone = '';
                let tmpEmail = '';

                if (Array.isArray(NIPDataArr) && NIPDataArr.length > 0) {
                    NIPDataArr.forEach(item => {

                        if (item.enumeration_type == NPI_SEARCH_INDIVIDUAL_CODE) {
                            tmpName = (item.basic.name && item.basic.name.length > 0) ? item.basic.name : '';
                            tmpOrgName = (item.basic.name && item.basic.name.length > 0) ? item.basic.name : '';
                            tmpLastName = (item.basic.last_name && item.basic.last_name.length > 0) ? item.basic.last_name : '';
                            tmpFirstName = (item.basic.first_name && item.basic.first_name.length > 0) ? item.basic.first_name : '';
                            tmpMiddleName = (item.basic.middle_name && item.basic.middle_name.length > 0) ? item.basic.middle_name : '';
                        }
                        else if (item.enumeration_type == NPI_SEARCH_ORGANIZATION_CODE) {
                            tmpName = (item.basic.name && item.basic.name.length > 0) ? item.basic.name : item.basic.organization_name;
                            tmpOrgName = (item.basic.organization_name && item.basic.organization_name.length > 0) ? item.basic.organization_name : '';
                            tmpFirstName = (item.basic.authorized_official_first_name && item.basic.authorized_official_first_name.length > 0) ? item.basic.authorized_official_first_name : '';
                            tmpMiddleName = (item.basic.authorized_official_middle_name && item.basic.authorized_official_middle_name.length > 0) ? item.basic.authorized_official_middle_name : '';
                            tmpLastName = (item.basic.authorized_official_last_name && item.basic.authorized_official_last_name.length > 0) ? item.basic.authorized_official_last_name : '';
                        }

                        if (item.addresses[0] && item.addresses[0].address_purpose && item.addresses[0].address_purpose == "LOCATION") {
                            tmpAddress = tmpAddr1 = item.addresses[0].address_1 ? ('' + item.addresses[0].address_1) : '';

                            if (tmpAddress.length && item.addresses[0].address_2 && item.addresses[0].address_2.trim().length) {
                                commaVal = ', ';
                            }

                            if (item.addresses[0].address_2 && item.addresses[0].address_2.trim().length) {
                                tmpAddress += commaVal + item.addresses[0].address_2;
                                tmpAddr2 = item.addresses[0].address_2;
                            }

                            if (tmpAddress.trim().length && item.addresses[0].city && item.addresses[0].city.trim().length) {
                                commaVal = ', ';
                            }
                            if (item.addresses[0].city && item.addresses[0].city.trim().length) {
                                tmpAddress += commaVal + item.addresses[0].city;
                                tmpCity = item.addresses[0].city;
                            }

                            if (tmpAddress.trim().length && item.addresses[0].state && item.addresses[0].state.trim().length) {
                                commaVal = ', ';
                            }
                            if (item.addresses[0].state && item.addresses[0].state.trim().length) {
                                tmpAddress += commaVal + item.addresses[0].state;
                                tmpState = item.addresses[0].state;
                            }

                            if (tmpAddress.trim().length && item.addresses[0].postal_code && item.addresses[0].postal_code.trim().length) {
                                commaVal = ' - ';
                            }
                            if (item.addresses[0].postal_code && item.addresses[0].postal_code.trim().length) {
                                tmpAddress += commaVal + item.addresses[0].postal_code.substring(0, 5);
                                tmpPostalCode = item.addresses[0].postal_code.substring(0, 5);
                            }

                            if (item.addresses[0].country_name && item.addresses[0].country_name.trim().length) {
                                tmpCountry = item.addresses[0].country_name;
                            }
                            if (item.addresses[0].telephone_number && item.addresses[0].telephone_number.trim().length) {
                                tmpPhone = item.addresses[0].telephone_number;
                            }
                            if (item.endpoints && item.endpoints.length > 0 && item.endpoints[0].endpoint && item.endpoints[0].endpoint.trim().length) {
                                tmpEmail = item.endpoints[0].endpoint;
                            }
                        }

                        NIPSelList.push({ "NPINum": ('' + item.number), "NPIString": tmpName, "NPILocAddress": tmpAddress, "NPIOrgName": tmpOrgName, "NPIFirstName": tmpFirstName, "NPIMiddleName": tmpMiddleName, "NPILastName": tmpLastName, "NPIAddress1": ('' + tmpAddr1), "NPIAddress2": tmpAddr2, "NPICity": tmpCity, "NPIState": tmpState, "NPCountry": tmpCountry, "NPIPostalCode": tmpPostalCode, "NPIPhone": tmpPhone, "NPIEmail": tmpEmail });
                    });

                }
                setNPISearchList(NIPSelList);
                setShowNoRecords(true);
            });
    }

    return (
        <React.Fragment>
            <div>
                <Form id="form_searchNPI" autoComplete="off" onSubmit={(e) => onSearchNPI(e)} >
                    <Form.Group>
                        <div className="padding-left2 helper-text-box">
                            <ul>
                                <li>&#9432;{" "}You can search with the NPI number alone or by entering the {props.providerType === 1 ? `First Name and Last Name` : 'Practice Name'}. But you can&apos;t run both queries at the same time.</li>
                                <li>&#9432;{" "}NPI Number should be 10 digits.</li>
                            </ul>
                        </div>
                        {NPIError &&
                            <div className="padding-left2 npi_error">{"⚠︎"}{"  "}{NPIErrorString}</div>
                        }

                        <div className="row">
                            <div className="col-12">
                                {searchNPIProviderType === PROVIDER_TYPE_INDIVIDUAL ?
                                    <div className="row">
                                        <div className="col-4">
                                            <div className="form-group padding-top15">
                                                <TextInput type="text" id="searchNPIName" name="searchNPIName" label={i18n.t("searchNPI.labelNPILastName")} required={false} value={searchNPIName} tabIndex="1" onValueChange={onHandleChange} />
                                            </div>
                                        </div>
                                        <div className="col-4">
                                            <div className="form-group padding-top15">
                                                <TextInput type="text" id="searchNPIFirstName" name="searchNPIFirstName" label={i18n.t("searchNPI.labelNPIFirstName")} required={false} value={searchNPIFirstName} tabIndex="2" onValueChange={onHandleChange} />
                                            </div>
                                        </div>
                                        <div className="col-4">
                                            <div className="form-group padding-top15">
                                                <TextInput type="text" id="searchNPINumber" name="searchNPINumber" label={i18n.t("searchNPI.labelNPINumber")} required={false} value={searchNPINumber} tabIndex="2" onValueChange={onHandleChange} />
                                            </div>
                                        </div>
                                        <div className="small-text-11 padding-left12">{i18n.t('userPages.practices.labelNPISearchOrgDescr')}</div>
                                    </div>
                                    :
                                    <div className="row">
                                    <div className="col-6">
                                    <div className="form-group padding-top15">
                                        <TextInput type="text" id="searchNPIName" name="searchNPIName" label={i18n.t("searchNPI.labelNPIORGName")} required={false} className="" value={searchNPIName} tabIndex="1" onValueChange={onHandleChange} />
                                        <div className="small-text-11 padding-left2">{i18n.t('userPages.practices.labelNPISearchOrgDescr')}</div>
                                    </div>
                                        </div>
                                        <div className="col-6">
                                            <div className="form-group padding-top15">
                                                <TextInput type="text" id="searchNPINumber" name="searchNPINumber" label={i18n.t("searchNPI.labelNPINumber")} required={false} value={searchNPINumber} tabIndex="2" onValueChange={onHandleChange} />
                                    </div>
                                        </div>
                                    </div>
                                }
                            </div>
                            <div className="col-12">
                                <div className="row">
                                    <div className="col-4">
                                <div className="form-group padding-top15">
                                    <TextInput type="text" id="searchNPICity" name="searchNPICity" label={i18n.t('userPages.practices.labelNPISearchCity')} onValueChange={onHandleChange} value={searchNPICity} tabIndex="3" />
                                    <div className="small-text-11 padding-left2">{i18n.t('userPages.practices.labelNPISearchCityDescr')}</div>
                                </div>
                                    </div>
                                    <div className='col-4'>
                                        <div className={searchNPIProviderType === PROVIDER_TYPE_INDIVIDUAL ? "margin-top25" : 'margin-top25'}>
                                            <div className="padding-left2">{i18n.t('userPages.practices.labelNPISearchState')}</div>
                                            <div className="form-group select-input">
                                                <select name="searchNPIState" id="searchNPIState" value={searchNPIState} onChange={onHandleChange} tabIndex="4" >
                                                    <option value="">{i18n.t('userPages.practices.labelNPISearchStateSel')}</option>
                                                    {US_STATES.map((item) => {
                                                        return (
                                                            <option key={item.abbreviation} value={item.abbreviation} >{item.name + ' (' + item.abbreviation + ') '}</option>)
                                                    })}
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col-4'>
                                <div className="form-group padding-top40 div-float-left">
                                    <button className="btn btn-primary-blue btn-common-height35" onClick={doNIPSearch} tabIndex="5" >{i18n.t('userPages.practices.labelSearchNPI')}</button>
                                        </div>
                                </div>
                            </div>
                            </div>

                            {/* SHOW NPI SEARCH RESULT IN TABLE AND GIVE OPTION TO SELECT - 13Sep2021 */}
                            <div className="col-12">
                                <div className="row"></div>
                                <div className="">
                                    {searchNPIClicked && <table className="table" id="hover">
                                        <thead>
                                            <tr>
                                                <th className='width-5-perc'>{i18n.t("searchNPI.slNum")}</th>
                                                <th className='width-10-perc'>{i18n.t("searchNPI.labelNPI")}</th>
                                                <th className='width-80-perc'>{i18n.t("searchNPI.labelNPIName")}</th>
                                                <th className='width-5-perc'>{i18n.t("searchNPI.labelSelectNPI")}</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {showNoRecords && NPISearchList.length === 0 &&
                                                <tr>
                                                    <td align="center" colSpan={4} >{i18n.t("commons.noRecords")}</td>
                                                </tr>
                                            }
                                            {NPISearchList.map((item, index) => {
                                                return (
                                                    <tr key={index} className={searchNPIValue == item.NPINum ? "row-selected" : ''} >
                                                        <td onClick={() => setNPISelection( item.NPINum, item.NPIOrgName, item.NPIFirstName, item.NPIMiddleName, item.NPILastName, item.NPIAddress1, item.NPIAddress2, item.NPICity, item.NPIState, item.NPCountry, item.NPIPostalCode, item.NPIPhone, item.NPIEmail)}>{index + 1}</td>
                                                        <td align="center" onClick={() => setNPISelection(item.NPINum, item.NPIOrgName, item.NPIFirstName, item.NPIMiddleName, item.NPILastName, item.NPIAddress1, item.NPIAddress2, item.NPICity, item.NPIState, item.NPCountry, item.NPIPostalCode, item.NPIPhone, item.NPIEmail)}>{item.NPINum}</td>
                                                        <td align="center"  onClick={() => setNPISelection( item.NPINum, item.NPIOrgName, item.NPIFirstName, item.NPIMiddleName, item.NPILastName, item.NPIAddress1, item.NPIAddress2, item.NPICity, item.NPIState, item.NPCountry, item.NPIPostalCode, item.NPIPhone, item.NPIEmail)}>{item.NPIString}<br></br>{ searchNPIProviderType == 2 ? item.NPILocAddress : `${item.NPIFirstName} ${item.NPILastName}`}</td>
                                                        <td align="center">
                                                            <button type="button" className="btn btn-primary-blue btn-select-height3135" id={'npiSelectButton' + item.NPINum} aria-label={'add-NPI-To-Claim' + item.NPINum}
                                                            onClick={() => setNPISelection( item.NPINum, item.NPIOrgName, item.NPIFirstName, item.NPIMiddleName, item.NPILastName, item.NPIAddress1, item.NPIAddress2, item.NPICity, item.NPIState, item.NPCountry, item.NPIPostalCode, item.NPIPhone, item.NPIEmail)}>
                                                                <i className='fa fa-plus' />
                                                            </button>
                                                        </td>
                                                    </tr>
                                                )
                                            })}
                                        </tbody>
                                    </table>}
                                </div>
                            </div>

                        </div>
                    </Form.Group>
                </Form>
            </div>

        </React.Fragment >
    )
}
export default NPISearch; 
