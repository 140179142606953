import React, { useState, useEffect, useContext } from 'react';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import i18n from '../../../utilities/i18n';
import { ROUTE_SUPER_DICTIONARIES, PAGING_END_INDEX, DEFAULT_PAGING_SIZE } from '../../../utilities/staticConfigs';
import LoadingContext from '../../../container/loadingContext';
import service from './service';
import Pagination from '../../commons/pagination';
import { csvFileCheck } from '../../../utilities/validations';
import Notify from '../../commons/notify';
import TextInput from '../../commons/input/input';
import Table from '../../commons/Table/Table';
import { TaxonomyTableData } from './TaxonomyTable';
import { commonTableBody } from '../../../utilities/commonUtilities';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import BackArrowWithLabel from "../../commons/Back"; 
import CommonButton from "../../commons/Buttons";

const Taxonomies = (props) => {
    const setShowLoadingBar = useContext(LoadingContext);
    const [taxonomyList, setTaxonomyList] = useState([]);
    const [searchValue, setSearchValue] = useState('');

    //start ==== Alert message properties
    const [showNotify, setShowNotify] = useState('hide');
    const [notifyDescription, setNotifyDescription] = useState('');
    const [notifyType, setNotifyType] = useState('success');
    const [orderType, setOrderType] = useState('');
    const [orderingField, setOrderingField] = useState('name'); /* show the triangle only in clicked header */

    //Pagination start
    const [totalPage, setTotalPage] = useState(1);
    const [activePage, setActivePage] = useState(1);
    const [startIndex, setStartIndex] = useState(0);
    const [endIndex, setEndIndex] = useState(PAGING_END_INDEX);

    function onPagePrevious() {
        let previousPage = startIndex + 1 - PAGING_END_INDEX;
        setActivePage(previousPage);

        if (startIndex !== 0) {
            setStartIndex(startIndex - PAGING_END_INDEX);
            setEndIndex(endIndex - PAGING_END_INDEX);
        }
        getTaxonomyList(DEFAULT_PAGING_SIZE, previousPage, orderingField);
    }

    function onPageUp(e) {
        let page = Number(e.target.id)
        setActivePage(page);
        getTaxonomyList(DEFAULT_PAGING_SIZE, page, orderingField);
    }

    function onPageNext() {
        let nextPage = startIndex + 1 + PAGING_END_INDEX;
        if (endIndex === totalPage || totalPage <= PAGING_END_INDEX) {
            setActivePage(nextPage);
            setStartIndex(startIndex);
            setStartIndex(endIndex);
        } else {
            setActivePage(nextPage);
            setStartIndex(startIndex + PAGING_END_INDEX);
            setEndIndex(endIndex + PAGING_END_INDEX);
        }
        getTaxonomyList(DEFAULT_PAGING_SIZE, nextPage, orderingField);
    }
    //Pagination ends

    function getTaxonomyList(pageSize, page, fieldName,sorting,search) {
        let fieldOrderType = '';
        fieldOrderType=sorting ? !orderType ? '-' : '' : orderType
        setShowLoadingBar(true);
        const result = service.ListTaxonomies(pageSize, page, search , fieldName, fieldOrderType);
        result.then(response => {
            setShowLoadingBar(false);
            if (response.data.results !== undefined) {
                setTotalPage(Math.ceil(response.data.count / response.data.page_size));
            }
            const rowArray = commonTableBody(response.data.results, TaxonomyTableData.tableBodyData[0]);
            TaxonomyTableData.tableBodyData = rowArray;
            setTaxonomyList(response.data.results);
        });
    }

    useEffect(() => {
        getTaxonomyList(DEFAULT_PAGING_SIZE, activePage);
    }, []);

    function backToDictionaryPage() {
        props.history.push(ROUTE_SUPER_DICTIONARIES)
    }

    function showNotifyWindow(action, type, desc, age = 3000) {
        if (action == 'show') {
            setTimeout(() => {
                setShowNotify('hide');
            }, age)
        }
        setShowNotify(action);
        setNotifyType(type);
        setNotifyDescription(desc);
    }

    function onHandleChange(e) {
        const { name } = e.target;
        let value = e.target.value;
        let trimmedValue = '';
        trimmedValue = value;
        if (name === 'searchValue') setSearchValue(trimmedValue);
    }

    function onHandleCsvImport(e) {
        e.preventDefault();
        document.getElementById('file').click();
    }

    function onHandleCSVChange(e) {
        setShowLoadingBar(true);
        const value =
            e.target.type === "file" ? e.target.files[0] : e.target.value;

        if (e.target.type === "file") {
            if (e.target.files[0] && csvFileCheck(e.target.files[0].name)) {
                const data = new FormData();
                data.append('file', value);
                let result = service.ImportCSVData(data);
                result.then(response => {
                    setShowLoadingBar(false);
                    if (response.data.message !== undefined) {
                        if (response.data.message === 'no_file_records') {
                            showNotifyWindow('show', 'error', i18n.t('errorMessages.empty_file'));
                        } else if (response.data.message === 'invalid_headings') {
                            showNotifyWindow('show', 'error', i18n.t('errorMessages.import_file'));
                        } else if (response.data.message === 'data_imported') {
                            if (response.data.existing_name && response.data.existing_name.length > 0) {
                                showNotifyWindow('show', 'warning', i18n.t('errorMessages.record_not_imported') + ' \n ' + response.data.existing_name.join(',\n'));
                            } else {
                                showNotifyWindow('show', 'success', i18n.t('validations.success.import_success'));
                            }
                            getTaxonomyList(DEFAULT_PAGING_SIZE, 1);
                        }
                    } else {
                        showNotifyWindow('show', 'error', i18n.t('errorMessages.import_failed'));
                    }
                });
            } else {
                showNotifyWindow('show', 'error', i18n.t('errorMessages.invalid_file_format'));
            }
            e.target.value = null;
        }
    }

    const handleKeyDown = (e) => {
        if (e.key === 'Enter') {
            onSearchTaxonomy(e);
        }
    }

    function onSearchTaxonomy(e, fieldName) {
        e.preventDefault();
        setActivePage(1)
        setStartIndex(0);
        setEndIndex(PAGING_END_INDEX);
        if (!fieldName) { // if not field name then call from ,search button click
            setOrderType('');
        }
        setTotalPage(0);
        getTaxonomyList(DEFAULT_PAGING_SIZE, 1, fieldName, true,searchValue);
    }

    function onSortingTaxonomy(e, fieldName) {
        e.preventDefault();
        setStartIndex(0);
        setEndIndex(PAGING_END_INDEX);
        if (!fieldName) { // if not field name then call from ,search button click
            setOrderType('');
        }
        setTotalPage(0);
        getTaxonomyList(DEFAULT_PAGING_SIZE, activePage, fieldName, true,searchValue);
    }
    const tableSorting = (e) => {
        TaxonomyTableData.tableHeadings[0].initialOrdering = false;
        setOrderingField('name');
        if (!orderType) {
            TaxonomyTableData.tableHeadings[0].orderType = '-';
            setOrderType('-');
        }
        else {
            TaxonomyTableData.tableHeadings[0].orderType = '';
            setOrderType('');
        }
        onSortingTaxonomy(e, 'name');
    }

    function onHandleClick(){
        setSearchValue("");
        setActivePage(1)
        getTaxonomyList(DEFAULT_PAGING_SIZE, activePage);
    }

    return (
        <React.Fragment>
            <Notify showNotify={showNotify} setShowNotify={setShowNotify} notifyDescription={notifyDescription} setNotifyType={setNotifyType} setNotifyDescription={setNotifyDescription} notifyType={notifyType} />
            <div className="col-md-9">
                <div className="box box-content-white">

                    <div className="row margin-top4 margin-bottom8">
						<div className="col">
							<div className={'dataTables_filter'}>
								<div className="link dictionaries-back pl-0" onClick={backToDictionaryPage}><BackArrowWithLabel label={i18n.t("dictionariesPages.dictionaries")} /></div>
							</div>
						</div>
					</div>

                    <div className="box-content">
                        <div className="common-search-wrapper-style">
                            <div className="input-content-box">
                                <div className="form-group padding-top15">
                                    <TextInput type="text" name="searchValue" value={searchValue} onValueChange={onHandleChange} label={i18n.t("dictionariesPages.dictTaxonomy.codeOrGroup")} onKeyDown={handleKeyDown} />
                                    {(searchValue.length !== 0) && <IconButton
                                        className="templateIconBtn_close"
                                        onClick={onHandleClick} >
                                        <CloseIcon sx={{ fontSize: "small", color: '#9093a4' }} />
                                    </IconButton>
                                    }
                                </div>
                            </div>
                            <div className="padding-top17">
                                <div className="input-content-box padding-top24">
                                    <button type="button" onClick={(e) => onSearchTaxonomy(e)} className="btn btn-primary-blue">{i18n.t("dictionariesPages.dictAdjustmentReasonCode.search")}</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="box box-content-white margin-top10">
                    <div className="">
                        <div className="alignRight margin-bottom8">
                            <OverlayTrigger
                                placement="top"
                                delay={{ show: 250, hide: 400 }}
                                overlay={<Tooltip id="download-tooltip" style={{
                                    opacity: 0.4,
                                }}>{i18n.t('commons.importCSV')}
                                </Tooltip>}
                            >
                                <CommonButton icon="download" variant="outlined" label="Import" onClick={(e) => onHandleCsvImport(e)}  />
                            </OverlayTrigger>
                            <input name="csvFile" type="file" data-testid='csv-upload-input' accept=".csv" id="file" onChange={onHandleCSVChange} style={{ display: "none" }} />
                        </div>
                        <div className="table-responsive">
                            <Table tableObject={TaxonomyTableData} list={taxonomyList} sortingFunction={tableSorting} />
                        </div>
                        <div className='padding-top10'></div>
                        <Pagination totalPage={totalPage} activePage={activePage} startIndex={startIndex} endIndex={endIndex} onPagePrevious={onPagePrevious} onPageUp={onPageUp} onPageNext={onPageNext} />
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}
export default Taxonomies