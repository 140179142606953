import React from "react";
import { currencyFormat } from "../../../../utilities/commonUtilities";

const ClaimsTable = ({ claimsData, tire }) => {
  return (
    <div className="row mt-4">
      <h5 style={{ marginLeft: '10px', fontWeight: 'bold' }}>Claim Level Overview</h5>
      <div className="container card-border m-4">
        <div style={{backgroundColor: '#6883ba'}} className="row p-2 card-border">
         
          <div className="col text-center">
            <strong>DOS</strong>
          </div>
          <div className="col text-center">
            <strong>Claim #</strong>
          </div>
          <div className="col text-center">
            <strong>Provider</strong>
          </div>
          <div className="col text-center">
            <strong>Charges</strong>
          </div>
          <div className="col text-center">
            <strong>Payments</strong>
          </div>
          <div className="col text-center">
            <strong>Adjustments</strong>
          </div>
          <div className="col text-center">
            <strong>Balance</strong>
          </div>
          <div className="col text-center">
            <strong>Insurance Bal</strong>
          </div>
          <div className="col text-center">
            <strong>Patient Bal</strong>
          </div>
          <div className="col text-center">
            <strong>Claim Status</strong>
          </div>
         
        </div>
        {claimsData ? (
          claimsData.map((claim, index) => {
            // setChargedTotal(chargedTotal + Number(claim.charges))
            return (
              <div className="" key={index}>
                <div className="row p-2 card-border">
                 
                  <div className="col text-center">
                    <strong>{claim.dos}</strong>
                  </div>
                  <div className="col text-center">
                    <strong>{claim.custom_claim_id}</strong>
                  </div>
                  <div className="col text-center">
                    <strong>{claim.provider}</strong>
                  </div>
                  <div className="col text-center">
                    <strong>{currencyFormat(claim.charges)}</strong>
                  </div>
                  <div className="col text-center">
                    <strong>{currencyFormat(claim.payments)}</strong>
                  </div>
                  <div className="col text-center">
                    <strong>{currencyFormat(claim.adjustments)}</strong>
                  </div>
                  <div className="col text-center">
                    <strong>{currencyFormat(claim.balance)}</strong>
                  </div>
                  <div className="col text-center">
                    <strong>{currencyFormat(claim.insurance_balance)}</strong>
                  </div>
                  <div className="col text-center">
                    <strong>{currencyFormat(claim.patient_balance)}</strong>
                  </div>
                  <div className="col text-center">
                    <strong>{claim.claims_status}</strong>
                  </div>
                  
                </div>
                {(tire === 2 || tire === 3) && claim.procedure.length !== 0 && (
                  <div className="container card-border">
                    <div style={{backgroundColor: '#6883ba'}} className="row p-2 card-border">
                      <div className="col text-center">
                        <strong>CPT</strong>
                      </div>
                      <div className="col text-center">
                        <strong>Description</strong>
                      </div>
                      <div className="col text-center">
                        <strong>Units</strong>
                      </div>
                      <div className="col text-center">
                        <strong>Charges</strong>
                      </div>
                      <div className="col text-center">
                        <strong>Payments</strong>
                      </div>
                      <div className="col text-center">
                        <strong>Adjustments</strong>
                      </div>
                      <div className="col text-center">
                        <strong>Balance</strong>
                      </div>
                      <div className="col text-center">
                        <strong>Insurance Bal</strong>
                      </div>
                      <div className="col text-center">
                        <strong>Patient Bal</strong>
                      </div>
                      <div className="col text-center">
                        <strong>Claim Status</strong>
                      </div>
                     
                    </div>
                    {claim.procedure.map((procedure, idx) => {
                      return (
                        <div className="container " key={idx}>
                          <div className="row p-2">
                            <div className="col text-center">
                              <strong>{procedure.cpt}</strong>
                            </div>
                            <div className="col text-center">
                              <strong>{procedure.description}</strong>
                            </div>
                            <div className="col text-center">
                              <strong>{procedure.units}</strong>
                            </div>
                            <div className="col text-center">
                              <strong>{currencyFormat(procedure.charges)}</strong>
                            </div>
                            <div className="col text-center">
                              <strong>{currencyFormat(procedure.payments)}</strong>
                            </div>
                            <div className="col text-center">
                              <strong>{currencyFormat(procedure.adjustments)}</strong>
                            </div>
                            <div className="col text-center">
                              <strong>{currencyFormat(procedure.balance_amount)}</strong>
                            </div>
                            <div className="col text-center">
                              <strong>{currencyFormat(procedure.insurance_balance)}</strong>
                            </div>
                            <div className="col text-center">
                              <strong>{currencyFormat(procedure.patient_balance)}</strong>
                            </div>
                            <div className="col text-center">
                              <strong>{procedure.claims_status}</strong>
                            </div>
                           
                          </div>
                          {tire === 3 && procedure.proc_data.length !== 0 && (
                            <div className="container card-border">
                              <div style={{backgroundColor: '#6883ba'}} className="row p-2 card-border ">
                                <div className="col text-center">
                                  <strong>Date</strong>
                                </div>
                                <div className="col text-center">
                                  <strong>Activity</strong>
                                </div>

                                <div className="col text-center">
                                  <strong>Charges</strong>
                                </div>
                                <div className="col text-center">
                                  <strong>Payments</strong>
                                </div>
                                <div className="col text-center">
                                  <strong>Adjustments</strong>
                                </div>
                                <div className="col text-center">
                                  <strong>Balance</strong>
                                </div>
                              </div>
                              {procedure.proc_data.map((proc, ind) => {
                                let activity="";
                                if (proc.activity === "Payment posted to <<payment>>.") {
                                  let a =proc.activity.trim().split('<<')
                                  activity=a[0]+proc.content.payment.title
                                }else{
                                  activity=proc.activity
                                }
                                return (
                                  <div className="row p-2" key={ind}>
                                    <div className="col text-center">
                                      <strong>{proc.date}</strong>
                                    </div>
                                    <div className="col text-center">
                                      <strong>
                                        {activity}
                                        </strong>
                                    </div>

                                    <div className="col text-center">
                                      <strong>{currencyFormat(proc.charges)}</strong>
                                    </div>
                                    <div className="col text-center">
                                      <strong>{currencyFormat(proc.payments)}</strong>
                                    </div>
                                    <div className="col text-center">
                                      <strong>{currencyFormat(proc.adjustments)}</strong>
                                    </div>
                                    <div className="col text-center">
                                      <strong>{currencyFormat(proc.balance)}</strong>
                                    </div>
                                  </div>
                                );
                              })}
                            </div>
                          )}
                        </div>
                      );
                    })}
                  </div>
                )}
              </div>
            );
          })
        ) : (
          <div className="row p-2 card-border">
            <strong className="text-center center-text">
              No Data Available
            </strong>
          </div>
        )}

        {/* <div className='row'>
                    <div className='col-8 card-border'></div>
                    <div className='col-4 card-border'></div>
                </div> */}
      </div>
    </div>
  );
};

export default ClaimsTable;
