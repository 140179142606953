import React, { useEffect, useState } from 'react'
import { GEO_API_KEY } from '../../../utilities/staticConfigs';
import * as gLoc from '../../../utilities/googleConfigs';
import TextInput from './input';

export const CityInput = (props) => {
    const [isCityChange, setIsCityChange] = useState(false);
    const [apiCity, setApiCity] = useState("");

    /**
     * when user inputs zipcode - make api call from here to get the respective city of that zip and store it in a state.
     * so that when user changes the city manually a suggestion can be provided as follows;
     * Change to "apiCity" or Continue as "UserInputCity"
     */
    useEffect(() => {
        handleZipCodeChange(props.zip);
    }, [props.zip]);

    const handleZipCodeChange = async (zipCode) => {
        if (zipCode && zipCode.length === 5) {
            try {
                const response = await fetch(`https://maps.googleapis.com/maps/api/geocode/json?components=country:US|postal_code:${zipCode}&key=${GEO_API_KEY}`);
                const data = await response.json();
                if (data.results.length > 0) {
                    let allAddress = data.results[0].address_components;
                    let foundVar = 0;
                    let tmpVal = '';
                    allAddress.forEach(element => {
                        let addrTypes = element.types;
                        foundVar = 0;
                        addrTypes.forEach(item => {
                            tmpVal = element.long_name.trim().length ? element.long_name.trim() : ((element.short_name.trim().length) ? element.short_name.trim() : '');
                            if (foundVar === 0 && tmpVal !== '') {
                                if (gLoc.GOOGLE_LOCATION_CITY.includes(item)) {
                                    foundVar = 1;
                                    setApiCity(tmpVal);
                                }
                            }
                        })
                    });
                }
            } catch (error) {
                console.error(error);
            }
        }
    }


    function onHandleCity(e) {
        if (props.zip.length === 5) {
            setIsCityChange(true);
            props.onValueChange(e);
        } else {
            setIsCityChange(false);
            props.onValueChange(e);
        }
    }

    function onMouseDownHandle(value) {
        //for objects id should be = objectname + _city
        props.setValue((prev) => {
            if (typeof (prev) === 'object') {
                let objectName = props.id.split("_city")[0];
                let newData = {
                    ...prev, [objectName]: {
                        ...prev[objectName], "city": value
                    }
                }
                setIsCityChange(false);
                return newData;
            }
            else{
                setIsCityChange(false);
                return value
            }
        })
    }

    return (
        <div className="form-group">
            <TextInput type={props.type} id={props.id} name={props.name} required={props.required}
                onValueChange={onHandleCity} defaultClassName={props.className}
                label={props.label} value={props.value}
                onValueBlur={() => setIsCityChange(false)} 
                disabled={props.disabled ? props.disabled : ''} />
            <div className={isCityChange ? "cityDropdown_content" : ""}>
                <ul className='list_style_type_none'>
                    <li onMouseDown={() => onMouseDownHandle(apiCity)}>
                        {isCityChange ? "Change to " + apiCity : ""}
                    </li>
                    {!apiCity.includes(props.value) &&
                        <li onMouseDown={() => onMouseDownHandle(props.value)} >
                            {isCityChange ? "Continue as " + props.value : ""}
                        </li>
                    }
                </ul>
            </div>
        </div>
    )
}
