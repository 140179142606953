import React, { useEffect } from 'react';

import "./style.css";
import CommonButton from '../Buttons';


function RightSidePanel(props) {
    function onShow() {
        if(props?.secondSearchModule) {
            document.querySelector(`[aria-label=${props.secondSearchModule}]`).classList.toggle('active');
        }
        else {
            document.getElementById("right-content").classList.toggle('active');
        }
    }

    useEffect(() => {
        if (props.showSideBar) {
            document.getElementById("right-content").classList.toggle('active');
        } else if (!props.showSideBar) {
            document.getElementById("right-content").classList.remove('active');
        }
    }, [props.showSideBar]);

    return (
        <div>
            <div>
                
                <CommonButton id='btn-right-side-panel' variant="outlined" icon="advanced" label={props.onclickLabel} onClick={onShow} />

            </div>
            <div className='right-content' id='right-content' style={{width:"30%"}} aria-label={props?.secondSearchModule ? props.secondSearchModule : '' } >
                <ul className="pb-2">
                    <li className="head">
                        <div className="row">
                            <div className="col-7 advancedSearch padding-left15">
                                <h5 className="padding-top5" style={{ color: "#000" }}>
                                    <b style={{ fontWeight: 500, marginLeft: 12, fontSize: 20 }}>{props.title}</b>
                                </h5>
                            </div>
                            <div className="col-5 justify-right">
                                <button type="button" data-testid='close-btn' className="close-right-side" onClick={onShow}>
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                        </div>
                    </li>
                </ul>
                <div className="box basic-info-padding" style={{ padding: "30px" }}>
                    {props.children}
                </div>
            </div>
            </div>
    )
}

export default RightSidePanel
