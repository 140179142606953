import React, { useState, useEffect } from 'react';
import i18n from '../../../utilities/i18n';
import { currencyFormat } from '../../../utilities/commonUtilities';
import service from './service';
import { Grid } from '@mui/material';

const PatientsLedgerHead = (props) => {
    const [headerData, setHeaderData] = useState('');
    function GetPatientLedgerData(patientPK) {
        const result = service.getPatientLedgerSummary(patientPK);
        result.then((response) => {
            setHeaderData(response.data);
        });
    }

    useEffect(() => {
        GetPatientLedgerData(props.patientPK);
    }, []);

    return (
        <React.Fragment>

        <Grid container space={2} className='mt-4' >
            <Grid item xs={3} className='styled-grid' style={{borderBottom:0,borderRight:0}}>
                <Grid container spacing={1} >
                    <Grid item xs={6} >
                    {i18n.t("patientPages.patientLedger.ledgerTab.primaryInsurance")}
                    </Grid>
                    <Grid item xs={6} className='bold-font'>
                        {headerData.primary_insurance}
                    </Grid>
                </Grid>
            </Grid> 

            <Grid item xs={3} className='styled-grid'style={{borderBottom:0,borderRight:0}}>
                <Grid container spacing={1} >
                    <Grid item xs={6}>
                    {i18n.t("patientPages.patientLedger.ledgerTab.accountBalance")}
                    </Grid>
                    <Grid item xs={6} className='bold-font'>
                    {currencyFormat(headerData.balance)}
                    </Grid>
                </Grid>

            </Grid>

            <Grid item xs={3} className='styled-grid' style={{borderBottom:0,borderRight:0}}>
                <Grid container spacing={1}>
                    <Grid item xs={6}>
                    {i18n.t("patientPages.patientLedger.ledgerTab.insurancePayments")}
                    </Grid>
                    <Grid item xs={6} className='bold-font'>
                    {currencyFormat(headerData.insurance_pays)}
                    </Grid>
                </Grid>

            </Grid>

            <Grid item xs={3} className='styled-grid'style={{borderBottom:0}}>
                <Grid container spacing={1}>
                    <Grid item xs={6}>
                    {i18n.t("patientPages.patientLedger.ledgerTab.patientPayments")}
                    </Grid>
                    <Grid item xs={6} className='bold-font'>
                    {currencyFormat(headerData.patient_pays)}
                    </Grid>
                </Grid>

            </Grid>

            <Grid item xs={3} className='styled-grid'style={{borderBottom:0,borderRight:0}}>
                <Grid container spacing={1}>
                    <Grid item xs={6}>
                    {i18n.t("patientPages.patientLedger.ledgerTab.secondaryInsurance")}
                    </Grid>
                    <Grid item xs={6} className='bold-font'>
                    {headerData.secondary_insurance}
                    </Grid>
                </Grid>
            </Grid> 

            <Grid item xs={3} className='styled-grid'style={{borderBottom:0,borderRight:0}}>
                <Grid container spacing={1}>
                    <Grid item xs={6}>
                    {i18n.t("patientPages.patientLedger.ledgerTab.insuranceBalance")}
                    </Grid>
                    <Grid item xs={6} className='bold-font'>
                    {currencyFormat(headerData.insurannce_balance)}
                    </Grid>
                </Grid>

            </Grid>

            <Grid item xs={3} className='styled-grid'style={{borderBottom:0,borderRight:0}}>
                <Grid container spacing={1}>
                    <Grid item xs={6}>
                    {i18n.t("patientPages.patientLedger.ledgerTab.insuranceAdjustments")}
                    </Grid>
                    <Grid item xs={6} className='bold-font'>
                    {currencyFormat(headerData.insurance_ajustments)}
                    </Grid>
                </Grid>

            </Grid>

            <Grid item xs={3} className='styled-grid'style={{borderBottom:0}}>
                <Grid container spacing={1}>
                    <Grid item xs={6}>
                    {i18n.t("patientPages.patientLedger.ledgerTab.patientAdjustments")}
                    </Grid>
                    <Grid item xs={6} className='bold-font'>
                    {currencyFormat(headerData.patient_adjustments)}
                    </Grid>
                </Grid>

            </Grid>


            <Grid item xs={3} className='styled-grid'style={{borderRight:0}}>
                <Grid container spacing={1}>
                    <Grid item xs={6}>
                    {i18n.t("patientPages.patientLedger.ledgerTab.tertiaryInsurance")}
                    </Grid>
                    <Grid item xs={6} className='bold-font'>
                    {headerData.tertiary_insurance}
                    </Grid>
                </Grid>

            </Grid>

            <Grid item xs={3} className='styled-grid'style={{borderRight:0}}>
                <Grid container spacing={1}>
                    <Grid item xs={6}>
                    {i18n.t("patientPages.patientLedger.ledgerTab.patientBalance")}
                    </Grid>
                    <Grid item xs={6} className='bold-font'>
                    {currencyFormat(headerData.patient_balance)}
                    </Grid>
                </Grid>

            </Grid>

            <Grid item xs={3}className='styled-grid'style={{borderRight:0}}>
                <Grid container spacing={1}>
                    <Grid item xs={6}>
                    {i18n.t("patientPages.patientLedger.ledgerTab.insuranceCredits")}
                    </Grid>
                    <Grid item xs={6} className='bold-font'>
                    {currencyFormat(headerData.insurance_credits)}
                    </Grid>
                </Grid>

            </Grid>

            <Grid item xs={3} className='styled-grid'>
                <Grid container spacing={1}>
                    <Grid item xs={6}>
                    {i18n.t("patientPages.patientLedger.ledgerTab.patientCredits")}
                    </Grid>
                    <Grid item xs={6} className='bold-font'>
                    {currencyFormat(headerData.patient_credits)}
                    </Grid>
                </Grid>

            </Grid>

        </Grid >
        </React.Fragment >
    );
}

export default PatientsLedgerHead