import api from "../../../service/api";

const ListCPTCodes = async (pageSize, page, isAdminModule, practicePK, practiceDict, searchValue, listType, orderingField, orderType,status) => {
  let path = "";

  if (isAdminModule === "true") {
    path = `super-admin/cpt-codes/?page_size=${pageSize}&page=${page}`;
  } else if (practiceDict) {
    path = `super-admin/cpt-codes/?page_size=${pageSize}&page=${page}&practice_pk=${practicePK}&column=name`;
  } else {
    path = `practice/cpt-codes/?page_size=${pageSize}&page=${page}&practice_pk=${practicePK}`;
  }

  if (searchValue) {
    path += `&search=${searchValue}`;
  }

  if (listType) {
    path += `&list_type=${listType}`;
  }

  if (orderingField) {
    const column = isAdminModule === "true" ? orderingField : `master_cpt__${orderingField}`;
    const fieldOrder = orderType ? `${orderType}${column}` : column;
    path += `&column=${fieldOrder}`;
  } else if (isAdminModule === "true" || practiceDict) {
    path += "&column=name";
  } else {
    path += "&column=master_cpt__name";
  }
  if (status)
  path += '&status=' + status;
  return api.get(path);
};


const ImportCSVData = async (data) => {
  let path = "super-admin/import-cpt-codes/";
  return api.post(path, data);
};

const AddCPTCodesToPractice = async (data) => {
  let path = "practice/cpt-codes/";
  return api.post(path, data);
};

const getCptCode = async (path, pk) => {
  let fullPath = path + "/cpt-codes/" + pk;
  return api.get(fullPath);
};
const getAllPosCodes = async () => {
  let fullPath = "super-admin/service-codes/";
  return api.get(fullPath);
};

const DeleteCPTCodeFromPractice = async (pk, path) => {
  let fullPath = path + "/cpt-codes/" + pk;
  return api.destroy(fullPath);
};

const GetCPTCodeData = async (pk) => {
  let path = "super-admin/cpt-codes/" + pk;
  return api.get(path);
};

const UpdateCPTCode = async (path, pk, data) => {
  let fullPath = path + "/cpt-codes/" + pk;
  return api.put(fullPath, data);
};

const searchPosCode = async (query) => {
  let fullPath = "super-admin/service-codes/?search=" + query;
  return api.get(fullPath);
};

const addCPTCode = async (path, data) => {
  let fullPath = path + "/cpt-codes/";
  return api.post(fullPath, data);
};
const exportCPTCodes = async (type, practicePk) => {
  let fullPath =
    "practice/cpt-codes/?practice_pk=" +
    practicePk +
    "&export=true&export_type=" +
    type;
  let result = null;
  if (type === 'xls')
    result = api.getXLS(fullPath);
  else
    result = api.get(fullPath);
  return result;
};
const loadScheduleFee = async (year, state, locality, procedure) => {
  let fullPath = `super-admin/standard-fee-list/?year=${year}&state_code=${state}&locality=${locality}&procedure=${procedure}`;
  return api.get(fullPath);
};

const loadCustomScheduleFee = async (procedure, practicePk) => {
  let fullPath = `practice/procedure-custom-fee/?procedure_code=${procedure}&practice_pk=${practicePk}`;
  return api.get(fullPath);
};
const ListPOSCodes = async (pageSize, page) => {
	let path = 'super-admin/service-codes/?page_size' + pageSize + '&page=' + page
	return api.get(path);
}

const ListTOSCodes = async () => {
  let path = 'super-admin/service-type/?page=0';
  return api.get(path);
}

const GetModifier = async (query, claimPK) => {
  let path = 'super-admin/modifier/?search=' + query;
  if (claimPK)
    path += '&opened_claim_pk=' + claimPK;
  return api.get(path);
}

export default {
  addCPTCode,
  searchPosCode,
  getAllPosCodes,
  ListCPTCodes,
  ListTOSCodes,
  ImportCSVData,
  AddCPTCodesToPractice,
  DeleteCPTCodeFromPractice,
  GetCPTCodeData,
  UpdateCPTCode,
  getCptCode,
  exportCPTCodes,
  loadScheduleFee,
  loadCustomScheduleFee,
  ListPOSCodes,
  GetModifier
};
