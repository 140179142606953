import {addDays ,subDays, startOfWeek,lastDayOfWeek,subWeeks, startOfMonth,endOfMonth,subMonths, startOfQuarter,endOfQuarter,subQuarters, startOfYear,endOfYear,subYears } from 'date-fns';

import { DATE_FILTER_IDS } from './staticConfigs';

export function filterDateValues(filterId) { 
    var dt = new Date(); 
    var dateValue = new Date(dt.getFullYear(),dt.getMonth(),dt.getDate(),0,0,0)
    const filterData = { 
    [DATE_FILTER_IDS.custom]: {
        fromDate:dateValue, toDate:dateValue
    }, 
    [DATE_FILTER_IDS.today]: {
       fromDate:dateValue, toDate:dateValue
    },
    [DATE_FILTER_IDS.yesterday]: { 
      fromDate:subDays(dateValue,1),toDate:subDays(dateValue,1)
    },
    [DATE_FILTER_IDS.thisWeek]: {
     fromDate:startOfWeek(dateValue), toDate:lastDayOfWeek(dateValue)
    },
    [DATE_FILTER_IDS.thisMonth]:{
     fromDate:startOfMonth(dateValue), toDate:endOfMonth(dateValue) 
    },
    [DATE_FILTER_IDS.thisQuarter]: {
         fromDate:startOfQuarter(dateValue), toDate:endOfQuarter (dateValue)
    },
    [DATE_FILTER_IDS.lastWeek]:{
        fromDate:subWeeks(startOfWeek(dateValue),1), toDate:subWeeks(lastDayOfWeek(dateValue),1)
    },
    [DATE_FILTER_IDS.lastMonth]:{
        fromDate:subMonths(startOfMonth(dateValue),1), toDate:subMonths(endOfMonth(dateValue),1)
    },
    [DATE_FILTER_IDS.lastQuarter]:{
        fromDate:subQuarters(startOfQuarter(dateValue),1), toDate:subQuarters(endOfQuarter(dateValue),1)
    },
   [DATE_FILTER_IDS.thisYear]:{
        fromDate:startOfYear(dateValue), toDate:endOfYear(dateValue) 
    },
    [DATE_FILTER_IDS.lastYear]: {
       fromDate:subYears(startOfYear(dateValue),1), toDate:subYears(endOfYear(dateValue),1)
    },
    [DATE_FILTER_IDS.last7Days]: { 
        fromDate:subDays(dateValue,7),toDate:dateValue
    },
    [DATE_FILTER_IDS.last30Days]: { 
        fromDate:subDays(dateValue,30),toDate:dateValue
    },
    [DATE_FILTER_IDS.last60Days]: { 
    fromDate:subDays(dateValue,60), toDate:dateValue
    },
    [DATE_FILTER_IDS.last90Days ]:{ 
        fromDate:subDays(dateValue,90), toDate:dateValue
    },
    [DATE_FILTER_IDS.last6Months]:{
    fromDate:addDays(subMonths(startOfMonth(dateValue),6),dt.getDate()), toDate:dateValue,
    },
    [DATE_FILTER_IDS.last12Months]:{
        fromDate:addDays(subMonths(startOfMonth(dateValue),12),dt.getDate()), toDate:dateValue
    }
   
   }
    /**
     * In this version of the function, we define an object called filterData that maps each 
     filterId to an object with the corresponding fromDate and toDate values. We also include a default
     property that specifies the default behavior when an unknown filterId is passed in.
     */
    return filterData[filterId] || filterData.default || { fromDate: dateValue, toDate: dateValue };
}



