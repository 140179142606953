import React, { useState, useEffect } from 'react';
import service from '../patients/service';
import service1 from '../../userManagement/practices/service';
import { getStorage } from '../../../utilities/browserStorage';
import { DEFAULT_PAGING_SIZE } from '../../../utilities/staticConfigs';
import i18n from '../../../utilities/i18n';
import CalendarInput from '../../commons/input/CalendarInput';
import TextInput from '../../commons/input/input';
import { MaterialMultiselect } from '../../../MaterialMultiselect/MaterialMultiselect';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { resetFilterQuery } from './BatchEligibilitySlice';

const PatientSearch = (props) => {
    const practicePK = getStorage("practice") ? parseInt(getStorage("practice")) : '';
    const [multiProviders, setMultiProviders] = useState([]);
    const [multiInsurances, setMultiInsurances] = useState([]);
    const [multiPracticeLocations, setMultiPracticeLocations] = useState([]);
    const [multiPatientTypes, setMultiPatientTypes] = useState([]);
    const [multiReferingProviders, setMultiReferingProviders] = useState([]);
    const [multiFacilities, setMultiFacilities] = useState([]);
    const dispatch = useDispatch();
    const inputQuery = useSelector((state)=> state.batchEligibilityTab2.filterQuery)
    //Pagination start
    const activePage = 1;
    

    function onHandleChange(e) {
        let name = e.target.name;
        let value = e.target.value;
        let trimmedValue = "";
        let inputFields = ["searchValue", 'lastNameStarts', 'firstNameStarts'];
        if (inputFields.includes(name)) {
            trimmedValue = value;
        }
        if (name === 'lastNameStarts')
        dispatch({type : "batchEligibility/onFilterQuery", payload : { name : 'lastNameStarts' , value : trimmedValue }})
        else if (name === 'firstNameStarts') 
        dispatch({type : "batchEligibility/onFilterQuery", payload : { name : 'firstNameStarts' , value : trimmedValue }})
    }

    function onHandleDOB1Change(selected) {
        dispatch({type : "batchEligibility/onFilterQuery", payload : { name : 'dob1' , value : selected   }})
    }
    function onHandleDOB2Change(selected) {
        dispatch({type : "batchEligibility/onFilterQuery", payload : { name : 'dob2' , value : selected   }})
    }
    function onHandleChangeDos1(selected) {
        dispatch({type : "batchEligibility/onFilterQuery", payload : { name : 'dos1' , value : selected }})
    }
    function onHandleChangeDos2(selected) {
        dispatch({type : "batchEligibility/onFilterQuery", payload : { name : 'dos2' , value : selected }})
    }
    function onHandleChangeCreatedFrom(selected) {
        dispatch({type : "batchEligibility/onFilterQuery", payload : { name : 'createdFrom' , value : selected }})
    }
    function onHandleChangeCreatedTo(selected) {
        dispatch({type : "batchEligibility/onFilterQuery", payload : { name : 'createdTo' , value : selected }})
    }
    function onHandleChangeNotCheckedAfter(selected) {
        dispatch({type : "batchEligibility/onFilterQuery", payload : { name : 'notCheckAfter' , value : selected   }})
    }

    function getInsuranceCompaniesData(pageSize) {
        props.setShowLoadingBar(true);
       let pageNum = 0;
        const result = service.ListInsuranceNames(pageSize, pageNum, practicePK);
        result.then(response => {
            props.setShowLoadingBar(false);
            if(response.data)
                setMultiInsurances(response.data);
        });
    }

    function getPracticeLocations(pageSize) {
        props.setShowLoadingBar(true);
       let pageNum = 0;
        const result = service1.ListPracticeLocations(pageSize, pageNum, practicePK);
        result.then(response => {
            props.setShowLoadingBar(false);
            if(response.data){
                setMultiPracticeLocations(response.data);
            }
        });
    }

    function getPatientTypes(pageSize) {
        let pageNum = 0;
        const result = service.ListPatientTypes(pageSize, pageNum, practicePK);
        result.then((response) => {
            if(response.data){
                setMultiPatientTypes(response.data);
            }
        });
    }

    function getReferringProviders(pageSize) {
        let pageNum = 0;
        const result = service.ListReferringProviders(pageSize, pageNum, practicePK);
        result.then(response => {
            if(response.data){
                setMultiReferingProviders(response.data);
            }
        });
    }

    function ListRenderingProviders(pageSize) {
        let pageNum = 0;
        const result = service.ListRenderingProviders(pageSize,pageNum, practicePK);
        result.then(response => {
            if(response.data){
                setMultiProviders(response.data)
            }
        })
    }

    function getFacilityGroupData(pageSize) {
        props.setShowLoadingBar(true);
        let pageNum = 0;
        const result = service.ListFacilities(pageSize, pageNum, practicePK);
        result.then(response => {
            props.setShowLoadingBar(false);
            if(response.data){
                setMultiFacilities(response.data);
            }
        });
    }

    useEffect(() => {
        getPatientTypes(DEFAULT_PAGING_SIZE, activePage);
        getReferringProviders(DEFAULT_PAGING_SIZE, activePage);
        getFacilityGroupData(DEFAULT_PAGING_SIZE, activePage);
        getPracticeLocations(DEFAULT_PAGING_SIZE, activePage);
        getInsuranceCompaniesData(DEFAULT_PAGING_SIZE, activePage);
        ListRenderingProviders(DEFAULT_PAGING_SIZE)
    }, []);

    
    function onClearSearch() {
        dispatch(resetFilterQuery())
    }
    
    function MaterialMultiSelectHandle(e) {
        let name = e.target.name;
        let value = e.target.value;

        switch (name) {
            case "insuranceValue":
                dispatch({type : "batchEligibility/onFilterQuery", payload : { name : 'insuranceValue' , value : value   }})
                break;
            case "practiceLocation":
                dispatch({type : "batchEligibility/onFilterQuery", payload : { name : 'practiceLocation' , value : value   }})
                break;
            case "facility":
                dispatch({type : "batchEligibility/onFilterQuery", payload : { name : 'facility' , value : value   }})
                break;
            case "renderingProvider":
                dispatch({type : "batchEligibility/onFilterQuery", payload : { name : 'renderingProvider' , value : value   }})
                break;
            case "referingProvider":
                dispatch({type : "batchEligibility/onFilterQuery", payload : { name : 'referingProvider' , value : value   }})
                break;
            case "patientType":
                dispatch({type : "batchEligibility/onFilterQuery", payload : { name : 'patientType' , value : value   }})
                break;
            default:
                break;
        }
    }

    return (
        <React.Fragment>
            <div className="form-group  padding-top mt-">
                <TextInput type="text" name="lastNameStarts" value={inputQuery.lastNameStarts} onValueChange={onHandleChange} label={i18n.t("patientPages.patients.labelLastNameStarts")} />
            </div>
            <div className="form-group padding-top mt-">
                <TextInput type="text" name="firstNameStarts" value={inputQuery.firstNameStarts} onValueChange={onHandleChange} label={i18n.t("patientPages.patients.firstNameStarts")} />
            </div>
            <div className="row">
                <div className="col-6 padding-top4">
                    <CalendarInput name="dob1" selected={inputQuery.dob1} onValueChange={onHandleDOB1Change} label={i18n.t("patientPages.patients.labeldobBetween")} />
                </div>
                <div className="col-6 padding-top29">
                    <CalendarInput name="dob2" selected={inputQuery.dob2} onValueChange={onHandleDOB2Change}  noLabel={true} />
                </div>
            </div>
            <div className="form-group">
                <MaterialMultiselect name={"insuranceValue"} value={inputQuery.insuranceValue} onValueChange={MaterialMultiSelectHandle}
                    options={multiInsurances} label={i18n.t("patientPages.insuranceInfo.labelPrimary")} />
            </div>
            <div className="form-group">
                <MaterialMultiselect name="practiceLocation" value={inputQuery.practiceLocation} onValueChange={MaterialMultiSelectHandle}
                    options={multiPracticeLocations} label={i18n.t("patientPages.patients.labelClaimPracticeLocation")}
                />
            </div>
            <div className="form-group">
                <MaterialMultiselect name="facility" value={inputQuery.facility} onValueChange={MaterialMultiSelectHandle}
                    options={multiFacilities} label={i18n.t("patientPages.patients.labelClaimFacility")}
                />
                </div>
            <div className="form-group">
                <MaterialMultiselect name="renderingProvider" value={inputQuery.renderingProvider} onValueChange={MaterialMultiSelectHandle}
                    options={multiProviders} label={i18n.t("patientPages.patients.RenderingProvider")}
                />
              </div>
            <div className="form-group">
                <MaterialMultiselect name="referingProvider" value={inputQuery.referingProvider} onValueChange={MaterialMultiSelectHandle}
                    options={multiReferingProviders} label={i18n.t("patientPages.patients.referingProvider")} 
                />
               </div>
            <div className="form-group">
                <MaterialMultiselect name="patientType" value={inputQuery.patientType} onValueChange={MaterialMultiSelectHandle}
                    options={multiPatientTypes} label={i18n.t("patientPages.patients.labelPatientType")}
                />
                </div>
            <div className="">{i18n.t("patientPages.patients.lastClaimdosBetween")}</div>
            <div className="row">
                <div className="col-6 padding-top6">
                    <CalendarInput name="dos1" selected={inputQuery.dos1} onValueChange={onHandleChangeDos1} placeholder="From" noLabel={true} />
                </div>
                <div className="col-6 padding-top6">
                    <CalendarInput name="dos2" selected={inputQuery.dos2} onValueChange={onHandleChangeDos2} placeholder="To" noLabel={true} />
                </div>
            </div>
            <div className="">{i18n.t("patientPages.patients.labelCreatedDateBetween")}</div>
            <div className="row">
                <div className="col-6 padding-top6">
                    <CalendarInput name="createdFrom"  selected={inputQuery.createdFrom} onValueChange={onHandleChangeCreatedFrom} placeholder="From"  noLabel={true} />
                </div>
                <div className="col-6 padding-top6">
                    <CalendarInput name="createdTo" minDate={inputQuery.createdFrom} selected={inputQuery.createdTo} onValueChange={onHandleChangeCreatedTo} placeholder="To"  noLabel={true} />
                </div>
            </div>
            <div className="row">
                <div className="col-6">
                    <CalendarInput name="notCheckAfter" maxDate={new Date()} selected={inputQuery.notCheckAfter} onValueChange={onHandleChangeNotCheckedAfter} label={i18n.t("patientPages.patients.labelNotCheckedAfter")} />
                </div>
            </div>
            <div className=" pb-4">
                <div className="justify-right">
                    <button type="button" id="btn-clear" data-testid="btn-clear" onClick={() => onClearSearch()} className="btn btn-outline-primary btn-common-height35 margin-top1 margin-right15">
                        {i18n.t("commons.clear")}
                    </button>
                    <button type="button" onClick={() => props.onAdvanceSearchPatients(DEFAULT_PAGING_SIZE, activePage)} className="btn btn-primary-blue btn-common-height35">{i18n.t("patientPages.patients.Search")}</button>
                </div>
            </div>
        </React.Fragment >
    )
}
export default PatientSearch;