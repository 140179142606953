import React from 'react';
import { OverlayTrigger, Tooltip } from "react-bootstrap";



const FilterIconRoundButton=(props)=>{

    return (
        <div className="pl-2 pr-2">
            <OverlayTrigger
                placement="bottom"
                delay={{ show: 250, hide: 400 }}
                overlay={
                    <Tooltip 
                        id="download-tooltip" 
                        style={{opacity: 0.4,}}>
                            {props.ToolTip}
                    </Tooltip>}
            >
                <div 
                    className="navBarIcons">               
                    <img
                        src={props.ImageSrc} 
                        alt={props.ImgAlt} 
                        onClick={props.OnClick} 
                        style={{width:'20px',height:'20px', padding:'2px',marginLeft:'auto',marginRight:'auto',display:'block'}}
                        />
                     </div>
            </OverlayTrigger>
        </div>
    );
};

export default FilterIconRoundButton;