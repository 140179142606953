import * as React from 'react';
import Card from '@mui/material/Card';
import CardMedia from '@mui/material/CardMedia';
import Collapse from '@mui/material/Collapse';

export default function ProfileCard(props) {
    const [ expanded ] = React.useState(false);

    return (
        <>
            <Card sx={{ maxWidth: 345, position: 'relative' }} className={'d-flex justify-content-center'} >
                <CardMedia
                    component="img"
                    height="180"
                    image={props.image}
                    alt={props.alt}
                    onClick={() => props.onClick()}
                    className={"radius0 imgCursor " + props.className}
                />
                {props.children}
                <Collapse in={expanded} timeout="auto" unmountOnExit>
                </Collapse>
            </Card>
        </>
    );
}
