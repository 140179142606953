import api from "../../service/api";

const GetReportFields = async (practicePK) => {
  let path = "reports/report-data/?practice_pk=" + practicePK;
  return api.get(path);
};

const checkFieldMapping = (body) => {
  const path = `reports/check-field-maping/`;
  return api.post(path, body);
};

const saveReport = (body) => {
  const path = `reports/save-report/`;
  return api.post(path, body);
};
const saveCategory = (body) => {
  const path = `/reports/category/`;
  return api.post(path, body);
};
const fetchReportCategories = (practiceId) => {
  const path = `/reports/category/?practice_id=${practiceId}`;
  return api.get(path);
};

const getSavedReports = (practiceId) => {
  const path = `/reports/save-report/?practice_id=${practiceId}`;
  return api.get(path);
};

const getSavedReportData = (reportId) => {
  const path = `/reports/save-report/${reportId}`;
  return api.get(path);
};

const runReport = (body) => {
  const path = `/reports/viewer/`;
  return api.post(path, body);
};

/**
 * claim status list api call
 * @param {*} pageSize 
 * @param {*} page 
 * @param {*} practicePK 
 * @param {*} listType 
 * @returns 
 */

const ListClaimStatus = async (pageSize, page, practicePK, listType) => {
    let path = null;
    path = 'practice/claim-status/?page_size=' + pageSize + '&page=' + page + '&practice_pk=' + practicePK + '&list_type=' + listType;
    return api.get(path);
}

/**
 * claim sub status list api call
 * @param {*} pageSize 
 * @param {*} page 
 * @param {*} practicePK 
 * @returns 
 */
const ListClaimSubStatus = async (pageSize, page, practicePK) => {
  let path = null;
  path = 'practice/claim-sub-status/?page_size=' + pageSize + '&page=' + page + '&practice_pk=' + practicePK;
  return api.get(path);
}

/**
 * rendering provider api call
 * @param {*} pageSize 
 * @param {*} page 
 * @param {*} practicePK 
 * @returns 
 */
const ListRenderingProviders = async (pageSize, page, practicePK) => {
  let path = "user/provider/?list=true&page_size=" + pageSize + "&page=" + page + '&practice_pk=' + practicePK + '&list=true';
  return api.get(path);
};

/**
 * referring provide api call
 * @param {*} pageSize 
 * @param {*} page 
 * @param {*} practicePK 
 * @param {*} patientPK 
 * @param {*} claimPK 
 * @returns 
 */
const ListReferringProviders = async (pageSize, page, practicePK) => {
  let path = "user/referring-provider/?list=true&page_size=" + pageSize + "&page=" + page + '&practice_pk=' + practicePK;
  return api.get(path);
};

/**
 * service location api call
 * @param {*} practice_pk 
 * @param {*} no_default 
 * @returns 
 */
const ServiceLocationLists = async (practice_pk, no_default) => {
  let path = 'claim/service-locations/?practice_pk=' + practice_pk;
  if (no_default) path += '&no_default=' + no_default;
  return api.get(path);
}

/**
 * cpt code list api call
 * @param {*} pageSize 
 * @param {*} page 
 * @param {*} practicePK 
 * @returns 
 */
const GetCPTCodeList = async (pageSize, page, practicePK) => {
  let path = "practice/cpt-codes/?page_size=" + pageSize + "&page=" + page + "&practice_pk=" + practicePK ;
  return  api.get(path);

}

const GetPayerTypes = async () => {
  let path = 'super-admin/payer-type/?page_size=' + 0 + '&page=' + 1;
  return api.get(path);
}

/**
 * ICD list api call
 * @param {*} pageSize 
 * @param {*} page 
 * @param {*} practicePK 
 * @returns 
 */
const GetICD10List = async (pageSize, page, practicePK) => {
  let path = "practice/icd10/?page_size=" + pageSize + "&page=" + page + "&practice_pk=" + practicePK;
  return api.get(path);
}


const getInsuranceCompany=async(pageSize,page,practicePK)=>{
  let path = "practice/insurance-company/?page_size=" + pageSize + "&page=" + page + "&practice_pk=" + practicePK;
  return api.get(path);
}

const praticeUserList=async(pageSize,page,practicePK)=>{
  let path = "practice/practice-users/?page_size=" + pageSize + "&page=" + page + "&practice_pk=" + practicePK + "&is_report=true";
  return api.get(path);
}

/**
 * all report api call 
 * @param {*} filter 
 */
const ChargesDebitReportAPI=async (query)=>{
  let path ="reports/charge-debit/"+ query;
  return api.get(path);
}


/**
 * @param {*} filter 
 */
const PaymentAdjustmentReportAPI = async (query) => {
  let path = "reports/payment-adjustment/" + query;
  return api.get(path);
}

/**
 * Export csv or xls api call
 * @param {*} path  eg: reports/charge-debit/ 
 * @param {*} query 
 * @param {*} export_type csv/xls 
 * @returns file
 */
const downloadExcel = async (path, query, exportType) => {
  if (path && query)
    path += query;
  let result = null;
  if (exportType === 'xls')
    result = api.getXLS(path);
  else
    result = api.get(path);
  return result;
}



export default {
  GetReportFields,
  ListClaimSubStatus,
  ListReferringProviders,
  ListRenderingProviders,
  GetPayerTypes,
  GetCPTCodeList,
  GetICD10List,
  ServiceLocationLists,
  checkFieldMapping,
  ListClaimStatus,
  saveReport,
  fetchReportCategories,
  saveCategory,
  getSavedReports,
  getSavedReportData,
  runReport,
  ChargesDebitReportAPI,
  PaymentAdjustmentReportAPI,
  getInsuranceCompany,
  praticeUserList,
  downloadExcel
};
