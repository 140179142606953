import React, { useState, useEffect, useCallback, memo } from "react";
import { styled } from "@mui/material/styles";
import { Link, useHistory, useLocation } from "react-router-dom";
import Drawer from "@mui/material/Drawer";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import aimaLogo from '../../../assets/images/AIMA_Logo.png';
import UserProfileBox from "../headerComponent/UserProfileBox";
import profileImage from '../../../assets/images/profile-image.png';
import './style.css'
import { Accordion, AccordionSummary, AccordionDetails } from "@mui/material";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Typography from '@mui/material/Typography';
import MenuIconRender from "./menuIconRender";
import { imageExtension } from "../../../utilities/validations";
import ImageCrop from "../../commons/image-cropper/ImageCrop";

const themeColor = "#ffff";

const SidebarContainer = styled(Drawer)(() => ({
  gridArea: "sidebar",
  width: 240,
  padding: "10px",
  flexShrink: 0,
  overflowX: 'hidden !important',
  "& .MuiDrawer-paper": {
    width: 240,
    boxSizing: "border-box",
    background: themeColor,
  },
}));

const SidebarContent = styled("div")({
  padding: '6px',
  paddingLeft: '15px !important',
  overflowX: 'hidden !important'
});

const LogoImg = styled("img")({
  maxWidth: "80% !important",
  height: "auto",
});

const StyledAppBar = styled(AppBar)({
  background: "#ffff",
  boxShadow: "none",
  borderBottom: '1px solid #E0E0E0'
});

const StyledToolbar = styled(Toolbar)({
  padding: '3px',
  background: "#ffff",
  display: "flex",
  justifyContent: "left",
  alignItems: "left",
  boxShadow: 'none',
  height: '80px'
});

const StyledUserProfileBox = styled(Toolbar)({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  borderTop: '1px solid #E0E0E0',
  borderBottom: '1px solid #E0E0E0',
  background: '#FAFAFA',
  boxShadow: 'none'
})

const StyledAccordion = styled(Accordion)({
  boxShadow: 'none',
  marginBottom: 0,
  marginTop: 0,
  border: 'none', 
  '&.Mui-expanded': {
    margin: 0,
  },
  '&::before': {
    display: 'none', 
  },
});

const StyledAccordionSummary = styled(AccordionSummary)({
  justifyContent: 'space-between',
  width: '100%',
  '& .MuiAccordionSummary-expandIcon': {
    padding: 0,
    order: 1, 
  },
});

const StyledButton = styled('button')(({ isActive }) => ({
  display: 'flex',
  justifyContent: 'flex-start',
  width: '200px',
  padding: '8px',
  paddingLeft: '17px',
  alignItems: 'flex-start',
  borderRadius: '8px',
  gap: '10px',
  color: isActive ? '#ffff' : 'var(--grey-g-800, #424242)',
  fontFeatureSettings: "'clig' off, 'liga' off",
  fontFamily: 'Lato',
  fontSize: '13px',
  fontStyle: 'normal',
  fontWeight: '500',
  lineHeight: 'normal',
  letterSpacing: '1px',
  background: isActive ? '#24A7E0' : 'transparent',
  border: 'none',
  marginLeft: '-5px',
  textIndent: '10px !important',
  position: 'relative',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
  transition: 'all 0.3s ease-in-out',
  backfaceVisibility: 'hidden',
  transform: 'translateZ(0)',
  WebkitFontSmoothing: 'antialiased',
  ':hover': {
    background: isActive ? '#24A7E0' : '#e0e0e0', // Change this to the color you want
  },
}));

const StyledAccordionDetails = styled(AccordionDetails)({
  position: 'relative',
  '&::before': {
    content: '""',
    position: 'absolute',
    top: '-10px',
    left: '22px',
    width: '1.5px',
    height: 'calc(100% - 28px)',
    backgroundColor: '#E0E0E0',
  },
});


// Caching the ImageCropper Component to avoid un-necessary render
const MemoizedImgCropper = memo(({ imgUrl, imageName, show, showModal, changeCroppedImage }) => {
  return < ImageCrop imgUrl={imgUrl} imageName={imageName} show={show} showModal={showModal} changeCroppedImage={changeCroppedImage} />;
});

MemoizedImgCropper.displayName = 'MemoizedImgCropper';


const LeftSidebar = () => {
  const history = useHistory();
  const location = useLocation();
  const [expandedParentMenu, setExpandedParentMenu] = useState(null);
  const [activeParentMenu, setActiveParentMenu] = useState(null);
  const [activeSubMenu, setActiveSubMenu] = useState(null);

  const isAdminModule = JSON.parse(localStorage.getItem('isAdminModule'));
  const [dashboardItems, setDashboardItems] = useState([]);

  const [myProfileImageUrl, setMyProfileImageUrl] = useState(profileImage);
  const [profilePicture, setProfilePicture] = useState('');
  const [imgUrl, setImgUrl] = useState(null);
  const [showImageCropperModalWindow, setShowImageCropperModalWindow] = useState(false);
  const [imageName, setImageName] = useState('');


  useEffect(() => {
    if (isAdminModule === false) {
      setDashboardItems(JSON.parse(localStorage.getItem('practiceModuleMenuSet')));
    } else {
      setDashboardItems(JSON.parse(localStorage.getItem('adminModuleMenuSet')));
    }
  }, [])


  const handleParentMenuItemClick = (item) => (event, expandedParentMenu) => {
    const hasSubMenus = item.sub_menu && item.sub_menu.length > 0;
    const isDifferentRoute = location.pathname !== item.route;

    if (hasSubMenus) {
      history.push(item.route ? item.route : '#');
      setExpandedParentMenu(expandedParentMenu ? item.name : false);
      if (item.route !== '#') {
        setActiveParentMenu(item.name)
      }
    } else if (isDifferentRoute) {
      history.push(item.route ? item.route : '#');
      setExpandedParentMenu(expandedParentMenu ? item.name : false);
      setActiveParentMenu(item.name);
      setActiveSubMenu(null);
    }
  };

  const handleSubMenuClick = (subItem) => {
    const isDifferentRoute = location.pathname !== subItem.route;
    if (isDifferentRoute) history.push(subItem.route);
  }



  useEffect(() => {
    let parentMenuName;
    let routedSubMenuName;

    for (let index = 0; index < dashboardItems.length; index++) {
      const item = dashboardItems[index];

      if (item?.route === location.pathname) {
        parentMenuName = item?.name;
        if (!item.sub_menu?.length >= 1 && item.sub_menu[0] !== null) {
          break;
        }
      }

      const subMenuItem = item.sub_menu?.find(subMenu => subMenu?.route === location.pathname);
      if (subMenuItem) {
        parentMenuName = item.name;
        routedSubMenuName = subMenuItem.name;
        break;
      }
    }

    if (parentMenuName) {
      setExpandedParentMenu(parentMenuName);
      setActiveParentMenu(parentMenuName)
    }

    if (routedSubMenuName) {
      setActiveSubMenu(routedSubMenuName);
    }

  }, [location.pathname, dashboardItems]);


  const onChangeShowImageCropperModalWindow = useCallback((newValue) => {
    setShowImageCropperModalWindow(newValue);
  }, []);

  const onCroppedImageChange = useCallback((newValue) => {
    setProfilePicture(newValue);
    setMyProfileImageUrl(URL.createObjectURL(newValue));
  }, [])


  function onHandleChange(e) {
    const name = e.target.name;
    let imageUrl = '';
    let imgObj = null;
    if (imageExtension(e.target.files[0].name)) {
      imgObj = e.target.files[0]
      imageUrl = URL.createObjectURL(e.target.files[0]);
    }
    setImgUrl(imageUrl);
    setImageName(imgObj.name);
    document.getElementById(name).value = '';
    setShowImageCropperModalWindow(true);
  }



  return (
    <SidebarContainer variant="permanent" anchor="left">
      <StyledAppBar position="static">

        {/* App Logo Placement */}
        <StyledToolbar>
          <LogoImg src={aimaLogo} alt="Menu" />
        </StyledToolbar>

        {/* User Profile Box */}
        <StyledUserProfileBox>
          <div className="right-menu padding-top2">
            <Link to="#" className="right-menu-icon margin-top5 mr-0" >
              <UserProfileBox setProfilePicture={setProfilePicture} profilePicture={profilePicture}
                myProfileImageUrl={myProfileImageUrl} setMyProfileImageUrl={setMyProfileImageUrl}
              />
            </Link>
            <input style={{display: 'none'}} type="file" id="myProfile_user_profile" name="myProfile_user_profile" onChange={onHandleChange} accept=".jpeg, .jpg, .png, .gif" />
          </div>
          <MemoizedImgCropper
            imgUrl={imgUrl}
            imageName={imageName}
            show={showImageCropperModalWindow}
            showModal={onChangeShowImageCropperModalWindow}
            changeCroppedImage={onCroppedImageChange}
          />
        </StyledUserProfileBox>
      </StyledAppBar>

      {/* Menu and SubMenu Items */}
      <SidebarContent className='custom-scrollbar'>
        {dashboardItems?.map((item, index) => (
          <StyledAccordion
            key={index + 1}
            expanded={expandedParentMenu === item.name}
            onChange={handleParentMenuItemClick(item)}
            disableGutters
          >
            {/* PARENT MENU HEADER */}
            <StyledAccordionSummary
              expandIcon={item.sub_menu?.length ? <ExpandMoreIcon /> : null}
              aria-controls={`panel${index}bh-content`}
              id={`panel${index}bh-header`}
              className="mui-accordion-btn"
            >
              {
                (item.sub_menu.length === 0 && activeParentMenu === item.name) ?
                  <>
                    {/* Render active parent menu with no sub menu */}
                    <div style={{
                      display: 'flex',
                      alignItems: 'center',
                      width: '100%',
                      padding: '5px',
                      borderRadius: '8px',
                      background: 'var(--primary-p-3, #24A7E0)',
                      transition: 'all 0.3s ease-in-out',
                    }}>
                      <div style={{ width: '25px', height: '25px', marginRight: '7px' }}>
                        <MenuIconRender
                          iconName={(item.icon && item.icon !== '') ? item.icon : item.name}
                          isActive={item.sub_menu.length === 0 && activeParentMenu === item.name}
                        />
                      </div>
                      <Typography
                        sx={{
                          color: 'var(--grey-ff, #FFF)',
                          fontFeatureSettings: "'clig' off, 'liga' off",
                          fontFamily: 'Lato',
                          fontSize: '14px',
                          fontStyle: 'normal',
                          fontWeight: '400',
                          lineHeight: 'normal',
                          letterSpacing: '1px',
                        }}
                      >
                        {item.name}
                      </Typography>
                    </div>
                  </>
                  :
                  (activeParentMenu !== expandedParentMenu && activeParentMenu === item.name) ?
                    <>
                      {/* Render active parent menu with sub menu */}
                      <div style={{
                        display: 'flex',
                        alignItems: 'center',
                        width: '100%',
                        padding: '5px',
                        borderRadius: '8px',
                        background: 'var(--primary-p-3, #24A7E0)',
                        transition: 'all 0.3s ease-in-out',
                      }}>
                        <div style={{ width: '25px', height: '25px', marginRight: '7px' }}>
                          <MenuIconRender
                            iconName={(item.icon && item.icon !== '') ? item.icon : item.name}
                            isActive={true}
                          />
                        </div>
                        <Typography
                          sx={{
                            color: 'var(--grey-ff, #FFF)',
                            fontFeatureSettings: "'clig' off, 'liga' off",
                            fontFamily: 'Lato',
                            fontSize: '14px',
                            fontStyle: 'normal',
                            fontWeight: '400',
                            lineHeight: 'normal',
                            letterSpacing: '1px',
                          }}
                        >
                          {item.name}
                        </Typography>
                      </div>
                    </>
                    :
                    <>
                      {/*  Render non-active parent menu */}
                      <div style={{
                        display: 'flex',
                        alignItems: 'center',
                        transition: 'all 0.3s ease-in-out',
                        width: '100%',
                        borderRadius: '8px'
                      }}>
                        <div
                          style={{
                            width: '25px',
                            height: '25px',
                            marginRight: '7px',
                            boxSizing: 'border-box'
                          }}>
                          <MenuIconRender
                            iconName={(item.icon && item.icon !== '') ? item.icon : item.name}
                            isActive={item.sub_menu.length === 0 && expandedParentMenu === item.name}
                          />
                        </div>
                        <Typography
                          sx={{
                            color: 'var(--grey-g-900, #212121)',
                            fontFeatureSettings: "'clig' off, 'liga' off",
                            fontFamily: 'Lato',
                            fontSize: '13px',
                            fontStyle: 'normal',
                            fontWeight: '400',
                            lineHeight: 'normal',
                            letterSpacing: '0.05px',
                          }}
                        >
                          {item.name}
                        </Typography>
                      </div>
                    </>
              }
            </StyledAccordionSummary>

            {/* RENDERING SUB MENUS */}
            {
              item.sub_menu?.length > 0 && (
                <StyledAccordionDetails>
                  <div>
                    {item.sub_menu.map((subItem, subIndex) =>
                      subItem ? (
                        <div
                          key={subIndex}
                          style={{
                            width: '100%',
                            display: 'flex',
                            alignItems: 'flex-start',
                            marginBottom: subIndex === item.sub_menu.length - 1 ? '0' : '8px',
                          }}
                        >
                          <StyledButton key={subIndex} onClick={() => { handleSubMenuClick(subItem, subIndex) }} isActive={activeSubMenu === subItem?.name}>
                            <div style={{ position: 'absolute', left: '6.5px', height: '10px', width: '10px', backgroundColor: '#ffff', border: '1.5px solid #E0E0E0', borderRadius: '50%', top: '0', bottom: '0', margin: 'auto', }} />
                            {subItem.name}
                          </StyledButton>
                        </div>
                      ) : null
                    )}
                  </div>
                </StyledAccordionDetails>
              )
            }
          </StyledAccordion>
        ))}
      </SidebarContent>
    </SidebarContainer>
  );
};

export default LeftSidebar;