import React from 'react';
import Moment from "react-moment"
import i18n from "../../../utilities/i18n"

const PatientsAdvancedSearchTable = (props) => {
    return (
        <table id="hover" className="table" style={{ width: "100%" }}>
            <thead>
                <tr>
                    <th>{i18n.t("patientPages.patients.labelPatientName")}</th>
                    <th>{i18n.t("patientPages.patients.labelPatientID")}</th>
                    <th>{i18n.t("patientPages.patients.labelDOB")}</th>
                    <th>{i18n.t("patientPages.patients.labelPatientType")}</th>
                    <th>{i18n.t("dictionariesPages.action")}</th>
                </tr>
            </thead>
            <tbody>
                {props.AdvancedPatientList && props.AdvancedPatientList.length === 0 &&
                    <tr>
                        <td align="center" className='td-center' colSpan='5'>
                            {props.initialTableData}
                        </td>
                    </tr>
                }
                {props.AdvancedPatientList && props.AdvancedPatientList.map((item) => {
                    return (
                        <tr key={item.id} onClick={(e) => props.setPatientSelection(e, item)}>
                            <td>{item.name}</td>
                            {props.patientApi1.map((itemCol, indexCol) => {
                                if (itemCol.value === "custom_patient_id") {
                                    return (
                                        <td key={indexCol} align="center" className='td-center'>{item.custom_patient_id}</td>
                                    )
                                }
                                else if (itemCol.value === "date_of_birth") {
                                    return (
                                        <td key={indexCol} align="center" className='td-center'>
                                            <Moment format="MM/DD/YYYY">{item.date_of_birth}</Moment>
                                            </td>
                                    )
                                }
                                else if (itemCol.value === "patient_type") {
                                    return (
                                        <td key={indexCol}>{item.patient_type}</td>
                                    )
                                }
                            })}
                            <td align="center" className='td-center'>
                                <button type="button" className="btn btn-primary-blue btn-select-height3135" onClick={(e) => props.setPatientSelection(e, item)}><i className='fa fa-plus' /></button>
                            </td>
                        </tr>)
                })}
            </tbody>
        </table>
    )
}

export default PatientsAdvancedSearchTable;