
import React, { useEffect, useState } from 'react';
import { Report360TableData } from './360ReportTable';
import { mock } from '../ChargesDebit/mockData';
import Table from '../../commons/Table/Table';
import ReportFilter from '../ReportFilter/ReportFilter';
import './style.css';
import ExportIconButton from '../../commons/exportIconButton';
import FilterIconRoundButton from '../../commons/FilterIconRoundButton';
import FilterIcon from "../../../assets//images/filterIcon.png";
import { commonTableBody } from '../../../utilities/commonUtilities';
import { ReportTableHead, colbilledAmount, colmonth, colnoofbilled, colnoofpaid, colreceivedDate, coltotalpaid, reportFieldArray } from './constants';
import { MaterialMultiselect } from '../../../MaterialMultiselect/MaterialMultiselect';
const Report360 = () => {
    const [reportTableSelector, setReportTableSelector] = useState([
        colmonth,
        colreceivedDate,
        colbilledAmount,
        colnoofbilled,
        colnoofpaid,
        coltotalpaid
    ]);
    const [Report360, setReport360] = useState([])
    const [hideFilter, setHideFilter] = useState(false);
    const [innerWidth, setInnerWidth] = useState(window.innerWidth);
    const filters = {
        dateOfServiceFrom: "",
        dateOfServiceTo: "",
        dateOfPaymentEntry: "",
        dateOfEntry: "",
        firstBilledDate: "",
        lastBilledDate: "",
        payerType: [],
        insurance_companies: [],
        claimStatus: [],
        claimSubStatus: [],
        renderingProvider: [],
        refferingProvider: [],
        billingProvider: [],
        serviceLocation: [],
        cptList: [],
        ICD: [],
        chargeEnteredUser: [],
        paymentEnteredUser: [],
        blanceAmountType: "",
        balanceAmountTotals: "",
    }

    useEffect(() => {
        let newWidth = "";
        newWidth = window.innerWidth;
        let menuWidth = document.getElementById("push-bar").classList.contains('open');
        if (!menuWidth) {
            newWidth = newWidth + 250;
        } else {
            newWidth = window.innerWidth;
        }
        setInnerWidth(newWidth);
    }, [window.innerWidth]); 

    useEffect(() => {
        const rowArray = commonTableBody(mock.results, Report360TableData.tableBodyData[0])
        Report360TableData.tableBodyData = rowArray;
        setReport360(mock.results)
    }, [])

    const onShowFilter=()=>{
        setHideFilter(!hideFilter)
    }

    const OnExportMenuItemClicked=()=>{
        /**export excel file api call */
    }

    const MaterialMultiselectHandleChange=async(e)=>{
        /**material multi select */
        let value = e.target.value;
        let anArray = [];
        reportFieldArray.forEach((item) => {
            //selection and unselection of table fields
            let itemObj = value.filter((field) => {
                if (field === item) return item;
            });
            if (itemObj.length > 0) {
                anArray.push(itemObj[0]);
            }
        });
        await onSelectFieldNames(anArray);
        setReportTableSelector(anArray);
    }

    
    async function onSelectFieldNames(pTableSelecter) {
        // THE FIELD SELECTION CONTROL IS SAME FOR BOTH 'LAST10' & 'ADV. SEARCH RESULTS',
        let tmpTblData = [];
        tmpTblData = Report360TableData;
        let tblHead = [];
        let tblData = [];
        let dataColHideStatus = [];
        tmpTblData.tableHeadings.map((item) => {
            if (pTableSelecter.find((str) => str === item.name)) {
                // if table heading is available in pTableSelecter then its not hidden
                tblHead.push({ ...item, hideValue: false });
                dataColHideStatus.push(false);
            } else {
                // if table heading is not available in pTableSelecter then its not hidden
                tblHead.push({ ...item, hideValue: true });
                dataColHideStatus.push(true);
            }
        });

        let newRow = [];
        tmpTblData.tableBodyData.map((rowItem) => {
            newRow = [];
            rowItem.map((colItem, colIndex) => {
                colItem.hideValue = dataColHideStatus[colIndex];
                newRow.push(colItem);
            });
            tblData.push(newRow);
        });
        Report360TableData.tableHeadings = tblHead;
        Report360TableData.tableBodyData = tblData;
    }

    return (
        <div className="col-md-12">
            <div className="box pb-5 basic-info-padding">
                {hideFilter && <ReportFilter filters={filters}/>}
                <div className='row justify-content-end mr-3 mt-2'>
                    <div className='col-4'>
                        <MaterialMultiselect
                            name="patientTableSelecter"
                            value={reportTableSelector}
                            options={ReportTableHead}
                            onValueChange={
                                MaterialMultiselectHandleChange
                            }
                        />
                    </div>
                    <div className="mt-3 ml-2" id="export-dropdown">
                        <ExportIconButton
                            onlyXLS={true}
                            onExportXls={(e) => OnExportMenuItemClicked(e, 'xls')} />
                    </div>
                    <div className="mt-3 ml-1" id="export-dropdown">
                        <FilterIconRoundButton
                            ToolTip={"FIlter"}
                            ImageSrc={FilterIcon}
                            IconName={"fa-filter"}
                            ImgAlt={'filter'}
                            OnClick={()=>onShowFilter()}
                        />
                    </div>
                </div>
                <div>&nbsp;</div>
                <div style={{ overflowX: "auto", width: "100%", maxWidth: (innerWidth - 300) + "px", maxHeight: '1000px' }} className="mb-4">
                    <Table tableObject={Report360TableData} list={Report360} />
                </div>
            </div>
        </div>
    )

}


export default Report360;