import i18n from "../../../utilities/i18n";

const TableHeadData = [
    {
        "name": i18n.t("userPages.facilities.labelName"),
        "type": "string",
        "width": "medium",
        "sort": false,
    },
    {
        "name": i18n.t("userPages.facilities.labelFacilityID"),
        "type": "number",
        "width": "small",
        "sort": false,
    },
    {
        "name": i18n.t("userPages.facilities.labelAddress"),
        "type": "string",
        "width": "large",
        "sort": false,
    },
    {
        "name": i18n.t("userPages.facilities.labelNPI"),
        "type": "string",
        "width": "small",
        "sort": false,
    },
    {
        "name": i18n.t("dictionariesPages.action"),
        "type": "button",
        "width": "action_column",
        "sort": false,
    },
]

export const TableBodyData = [
    [
        {
            "id": "",
            "name": "name",
            "value": "",
            "type": "string",
            "align": "left",
            "width": "medium",
        },
        {
            "id": "",
            "name": "custom_facility_id",
            "value": "",
            "type": "number",
            "align": "center",
            "width": "small",
        },
        {
            "id": "",
            "name": "facility_address",
            "value": "",
            "type": "string",
            "align": "left",
            "width": "large",
        },
        {
            "id": "",
            "name": "npi",
            "value": "",
            "type": "string",
            "align": "center",
            "width": "medium",
        },
        {
            "id": "",
            "name": "action",
            "type": "button",
            "align": "center",
            "width": "action_column",
            "dropdown": [                
                {
                    "name": "Edit"
                },
            ]
        },
    ]
]

export const FacilityTableData = {
    "name": "simpleTable",
    "isResize": true,
    "isFixedHead": true,
    "tableHeadings": TableHeadData,
    "tableBodyData": TableBodyData
};