import { TabContext, TabList, TabPanel } from '@mui/lab';
import { Box, Tab } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { styled } from '@mui/material/styles';

import "./style.css"
import BatchEligibility from './BatchEligibility';
import EligibilityTab1 from './EligibilityTab1';
import { ImportBatch } from './ImportBatch';

// import { checkPermission } from '../../../utilities/commonUtilities';
// import { permission_key_values_patient } from '../../../utilities/permissions';
import i18n from '../../../utilities/i18n'
import { TAB1, TAB2, TAB3 } from '../../../utilities/staticConfigs';

const CustomIndicator = styled('span')(() => ({
    backgroundColor: 'white',
    height: 7,
    position: 'absolute',
    bottom: 0,
    width: 'calc(100% - 4px)',
    marginLeft: '2px'

}));

const StyledTabList = styled(TabList)`
  & .MuiTabs-indicator {
    background-color: transparent !important;
    height: 0px !important;
  }
`;

function RunEligibility() {
    const [activeTab, setActiveTab] = useState(TAB1);
    function onTabChange(e, tab) {
        setActiveTab(tab);
    }
    useEffect(() => {
        setActiveTab(TAB1);
        onTabChange("", TAB1);
    }, []);
    return (
        <React.Fragment>
            <div className="col-md-12">
                <div className="box margin-right15 ">
                    <TabContext value={activeTab}>
                        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                            <StyledTabList
                                TabIndicatorProps={{
                                    children: <CustomIndicator />
                                }}
                                onChange={onTabChange}
                                scrollable
                                scrollButtons="auto"
                                aria-label="Eligibility Tabs"
                                variant="scrollable"
                            >
                                <Tab label={i18n.t("Eligibility.eligiblityCheck")} value={TAB1} className="tabMainMenu" />
                                {/* {checkPermission(permission_key_values_patient.batch_eligibility_check_tab_view) && */}
                                <Tab label={i18n.t("Eligibility.batcheligiblity")} value={TAB2} className="tabMainMenu" />
                                {/* } */}
                                {/* {checkPermission(permission_key_values_patient.import_batch_tab_view) && */}
                                <Tab label={i18n.t("Eligibility.importBatch.importBatch")} value={TAB3} className="tabMainMenu" />
                                {/* } */}
                            </StyledTabList>
                        </Box>
                        <TabPanel value={TAB1} className='eligibility-search-box-tab'>
                            <EligibilityTab1 />
                        </TabPanel>
                        <TabPanel value={TAB2} className='eligibility-search-box-tab'>
                            <BatchEligibility />
                        </TabPanel>
                        <TabPanel value={TAB3} className='eligibility-search-box-tab'>
                            <ImportBatch />
                        </TabPanel>
                    </TabContext>
                </div>
            </div>
        </React.Fragment>
    )
}

export default RunEligibility
