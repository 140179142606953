import React, { useState, useEffect, useContext } from 'react';
// import assign from "../../../assets/images/AR Icons/user_image.png";
import { Form, OverlayTrigger, Tooltip } from 'react-bootstrap';
import actionImg from '../../../assets/images/action.png';
import CustomizedDialogs from '../../modalWindowComponent/CustomizedDialogs';
import service from './service';
import { Link } from 'react-router-dom';
import { PAGING_END_INDEX, DEFAULT_PAGING_SIZE, DEFAULT_COUNTRY } from '../../../utilities/staticConfigs';
import { ADD_SUCCESS, ADD_ERROR, UPDATE_SUCCESS } from '../../../utilities/labelConfigs';
import Notify from '../../commons/notify';
import Pagination from '../../commons/pagination';
import LoadingContext from '../../../container/loadingContext';
import i18n from '../../../utilities/i18n';
import TextInput from '../../commons/input/input';
import GeoCodeSearch from '../../commons/helpers/geoCodeApiSearch';
import PhoneInput from '../../commons/PhoneInput/PhoneInput';
import EmailInput from '../../commons/EmailInput/EmailInput';
import { CommonGridPhoneNumber, ValidateEmail, checkPermission } from '../../../utilities/commonUtilities';
import { CityInput } from '../../commons/input/CityInput';
import { ResizableTable } from '../../commons/resizabletable/ResizableTable';
import { US_STATES } from '../../../utilities/usStates';
import SelectInput from '../../commons/input/SelectInput'
import { Dropdown } from 'react-bootstrap';
import CustomizedSmallDialogs from '../../modalWindowComponent/CustomisedSmallDialog';
import { validateEmail } from '../../../utilities/validations';
import IconButton from '@material-ui/core/IconButton';
import EditIcon from '@material-ui/icons/Edit';
import Avatar from '@material-ui/core/Avatar';
import AvatarGroup from '@material-ui/lab/AvatarGroup';
import './style.css';
import { stringToColor } from '../../../utilities/commonUtilities';
import SvgIconRender from './SvgIconRender'
import CommonButton from '../../commons/Buttons';
import { permission_key_values_super_Admin, super_admin_privileges } from '../../../utilities/permissions';

const CustomerProfileList = (props) => {
    const [header, setHeader] = useState('');
    const [showModalWindow, setShowModalWindow] = useState(false);
    const [showSftpUpdateModal, setShowSftpUpdateModal] = useState(false);
    const [showRteUpdateModal, setShowRteUpdateModal] = useState(false);
    const [editForm, setEditForm] = useState(false);
    // const [activeRowId, setActiveRowId] = useState(0);
    const [editCustomerProfileID, setEditCustomerProfileID] = useState('');
    const [customerList, setCustomerList] = useState([]);
    const [customerName, setCustomerName] = useState('');
    const [customerID, setCustomerID] = useState('');
    const [street1, setStreet1] = useState('');
    const [street2, setStreet2] = useState('');
    const [city, setCity] = useState('');
    const [state, setState] = useState('');
    const [zip, setZip] = useState('');
    const [country, setCountry] = useState("");
    const [phone, setPhone] = useState('');
    const [fax, setFax] = useState('');
    const [email, setEmail] = useState('');
    const [emailVerified, setEmailVerified] = useState(false);
    const [form_error, setFormError] = useState({
        'customerName': '', 'customerID': '', 'street1': '', 'street2': '',
        'city': '', 'state': '', 'country': '', 'phone': '', 'fax': '',
        'email': ''
    });
    const [userID, setUserID] = useState('');
    const [userName, setUserName] = useState('');
    const [userEmail, setUserEmail] = useState('');
    const [userPhone, setUserPhone] = useState('');
    const [userLastName, setUserLastName] = useState('');
    const [userFirstName, setUserFirstName] = useState('');
    const [userMiddleName, setUserMiddleName] = useState('');
    const [permission, setPermission] = useState(false)

    //start ==== Alert message properties
    const [showNotify, setShowNotify] = useState('hide');
    const [notifyDescription, setNotifyDescription] = useState('');
    const [notifyType, setNotifyType] = useState('success');
    const setShowLoadingBar = useContext(LoadingContext);
    const [initialTableData, setInitialTableData] = useState(i18n.t("commons.loading"));
   
    function showNotifyWindow(action, type, desc, age = 3000) {
        if (action === 'show') {
            setTimeout(() => {
                setShowNotify('hide');
            }, age)
        }
        setShowNotify(action);
        setNotifyType(type);
        setNotifyDescription(desc);
    }
    //End ====

    //Pagination start
    const [totalPage, setTotalPage] = useState(1);
    const [activePage, setActivePage] = useState(1);
    const [startIndex, setStartIndex] = useState(0);
    const [endIndex, setEndIndex] = useState(PAGING_END_INDEX);

    function onPagePrevious() {
        let previousPage = startIndex + 1 - PAGING_END_INDEX;
        setActivePage(previousPage);
        if (startIndex !== 0) {
            setStartIndex(startIndex - PAGING_END_INDEX);
            setEndIndex(endIndex - PAGING_END_INDEX);
        }
        getCustomerProfileData(DEFAULT_PAGING_SIZE, previousPage);
    }

    function onPageUp(e) {
        let page = Number(e.target.id)
        setActivePage(page);
        getCustomerProfileData(DEFAULT_PAGING_SIZE, page);
    }

    function onPageNext() {
        let nextPage = startIndex + 1 + PAGING_END_INDEX;
        if (endIndex === totalPage || totalPage <= PAGING_END_INDEX) {
            setActivePage(nextPage);
            setStartIndex(startIndex);
            setStartIndex(endIndex);
        } else {
            setActivePage(nextPage);
            setStartIndex(startIndex + PAGING_END_INDEX);
            setEndIndex(endIndex + PAGING_END_INDEX);
        }
        getCustomerProfileData(DEFAULT_PAGING_SIZE, nextPage);
    }
    //Pagination ends

    useEffect(() => {
        getCustomerProfileData(DEFAULT_PAGING_SIZE, activePage);
        setPermission(checkPermission(super_admin_privileges.super_admin_full_privilege, permission_key_values_super_Admin.customer_management_super_admin_add));
    }, []);

    // function onShowActionButtons(event, rowId) {
    //     let dropdown = document.getElementById('dropdown-' + rowId);
    //     let dropdownMenu = document.getElementById('dropdown-menu-' + rowId);
    //     dropdown.classList.toggle('show');
    //     dropdownMenu.classList.toggle('show');
    //     if (activeRowId === 0) {
    //         setActiveRowId(rowId);
    //     } else if (activeRowId === rowId) {
    //         setActiveRowId(0);
    //     } else if (this.state.activeRowId !== rowId) {
    //         dropdown = document.getElementById('dropdown-' + activeRowId);
    //         dropdownMenu = document.getElementById('dropdown-menu-' + activeRowId);
    //         dropdown.classList.toggle('show');
    //         dropdownMenu.classList.toggle('show');
    //         setActiveRowId(rowId);
    //     }
    // }

    // function onHideActionButtons(event, rowId) {
    //     let dropdown = document.getElementById('dropdown-' + rowId);
    //     let dropdownMenu = document.getElementById('dropdown-menu-' + rowId);
    //     dropdown.classList.remove('show');
    //     dropdownMenu.classList.remove('show');
    //     setActiveRowId(0);
    // }
    function renderAvatar  (user, index)  {
        const { profile_picture, name } = user;
      
        if (profile_picture) {
          return (
            <OverlayTrigger
            key={index}
            overlay={
                <Tooltip id={`tooltip-${index}`}>
                    {name}
                </Tooltip>
            }
            >
           <Avatar key={index} alt={name} src={profile_picture} />
        
            </OverlayTrigger>
          );
        } else {
          return (
            <OverlayTrigger
            key={index}
            overlay={
                <Tooltip id={`tooltip-${index}`}>
                    {name}
                </Tooltip>
            }
            >
          
            <Avatar
             key={index}
              style={{
                backgroundColor: stringToColor(name[0]),
              }}
            >
              {name[0].toUpperCase()}
            </Avatar>
            </OverlayTrigger>
          );
        }
       
      }
      
    function getCustomerProfileData(pageSize, page) {
        setShowLoadingBar(true);
        const result = service.ListCustomerProfiles(pageSize, page);
        result.then(response => {
            setShowLoadingBar(false);
            if (response.data.results !== undefined) {
                setTotalPage(Math.ceil(response.data.count / response.data.page_size));
            }
            setCustomerList(response.data.results);
            if (response.data && response.data.results && response.data.results.length === 0) setInitialTableData(i18n.t('commons.noRecords'))
        });
    }

    function addNew() {
        if (!permission) {
            showNotifyWindow('show', 'error', i18n.t('errorMessages.permission_error'));
            return;
        }
        resetForm();
        setHeader(i18n.t("userPages.customerProfileList.modalAddHeader"));
        setShowModalWindow(true);
        setCountry(DEFAULT_COUNTRY)
    }

    function resetForm() {
        if (!editForm) {
            setUserName('');
            setUserEmail('');
            setUserPhone('');
            setUserLastName('');
            setUserFirstName('');
            setUserMiddleName('');
            setUserID('');
        }
        setEditForm(false);
        setCustomerName('');
        setCustomerID('');
        setStreet1('');
        setStreet2('');
        setCity('');
        setState('');
        setZip('');
        setCountry('');
        setPhone('');
        setFax('');
        setEmail('');
        setFormError({
            ...form_error,
            ['customerName']: '',
            ['street1']: '',
            ['city']: '',
            ['state']: '',
            ['zip']: '',
            ['phone']: ''
        });
    }

    function onHide() {
        setEditForm(false);
    }

    function onHandleChange(e) {
        const name = e.target.name;
        let value = e.target.value;
        let trimmedValue = '';
        trimmedValue = value;

        let mandatoryFields = ['userName', 'userEmail', 'userPhone', 'userLastName', 'userFirstName','customerName','street1','street2','city','state','zip','country','phone','fax','email'];

        if(!trimmedValue && mandatoryFields.includes(name)) {
            if (name === "email" && !validateEmail(value)) {
                setFormError({
                    ...form_error,
                    [name]: 'error',
                
                });
            }
            if (name === "userEmail" && !validateEmail(value)) {
                setFormError({
                    ...form_error,
                    [name]: 'error',
                    "userName": 'error'
                });
            }
            else if (name === "userName" && !validateEmail(value)) {
                setFormError({
                    ...form_error,
                    [name]: 'error'
                });
            }
            else{
                setFormError({
                    ...form_error,
                    [name]: 'error'
                });
            }
            
        } else {
            setFormError({
                ...form_error,
                [name]: ""
            });
        }
      
        if (name === "customerName") setCustomerName(trimmedValue);
        else if (name === "street1") setStreet1(trimmedValue);
        else if (name === "street2") setStreet2(trimmedValue);
        else if (name === "city") setCity(trimmedValue);
        else if (name === "state") setState(trimmedValue);
        else if (name === "zip") setZip(trimmedValue);
        else if (name === "country") setCountry(trimmedValue);
        else if (name === "phone") setPhone(trimmedValue);
        else if (name === "fax") setFax(trimmedValue);
        else if (name === "email") setEmail(trimmedValue); 
        else if (name === 'userName') setUserName(trimmedValue);
        else if (name === 'userEmail') { setUserEmail(trimmedValue);setUserName(trimmedValue);}
        else if (name === 'userPhone') setUserPhone(trimmedValue);
        else if (name === 'userLastName') setUserLastName(trimmedValue);
        else if (name === 'userFirstName') setUserFirstName(trimmedValue);
        else if (name === 'userMiddleName') setUserMiddleName(trimmedValue);
    }

    function onSaveFormData(e) {
        e.preventDefault();
        let has_error = false;
        let user_email_validation = "";
        let email_validation = "";
        let username_validation = "";
        if (email && userEmail && userName) {
            user_email_validation = ValidateEmail(userEmail);
            email_validation = ValidateEmail(email);
            username_validation = ValidateEmail(userName);
            if (!user_email_validation || !email_validation || !username_validation){
                has_error = true;
            } else {
                has_error = false;
            }
         }
         else {
            has_error = true;
        }

        if (validateFields()) {
            return;
        }

        let result = null;
        if (!has_error) {
                let data = {
                    name: customerName, location: {
                        street1: street1, street2: street2,
                        city: city, state: state, zip_code: zip, country: country
                    },
                    phone: phone, fax: fax, email: email
                }
                setShowLoadingBar(true);
                let user_data = {
                    username: userName, email: userEmail, phone: userPhone,
                    last_name: userLastName, first_name: userFirstName, middle_name: userMiddleName
                }
                if (editForm)
                    user_data.id = userID;
                data.user_data = user_data
                if (editForm)
                    result = service.UpdateCustomerProfile(editCustomerProfileID, data)
                else
                    result = service.AddCustomerProfile(data)
                if (result) {
                    result.then(response => {
                        setShowLoadingBar(false);
                        if (response.status === 201 || response.status === 200) {
                            if (response.status === 201)
                                showNotifyWindow('show', 'success', ADD_SUCCESS);
                            else showNotifyWindow('show', 'success', UPDATE_SUCCESS);
                            getCustomerProfileData(DEFAULT_PAGING_SIZE, activePage);
                            resetForm();
                            setShowModalWindow(false);
                        } else if (response.status === 400 && response.data.record_already_exists !== undefined) {
                            setFormError({
                                ...form_error,
                                ['customerName']: 'error'
                            });
                            showNotifyWindow('show', 'error', i18n.t('errorMessages.record_exists'));
                        }
                        else if (response.status === 400 && response.data.user_already_exists !== undefined) {
                            setFormError({
                                ...form_error,
                                ['userName']: 'error'
                            });
                            showNotifyWindow('show', 'error', i18n.t('errorMessages.user_exists'));
                        }
                        else if (response.status === 400 && response.data.error === 'zip_phone_validation_error') {
                            showNotifyWindow('show', 'error', response.data.message)
                        } 
                        else if (response.status === 400 && response.data.message === 'invalid_username-whitespace') {
                            showNotifyWindow('show', 'error', i18n.t('errorMessages.invalid_username-whitespace'));
                        }
                        else
                            showNotifyWindow('show', 'error', ADD_ERROR);
                    });
                }
            }
        
    }

    function validateFields() {
        if( !customerName ||!street1 || !city || !state || !zip || !phone || !userName || !userEmail || !userPhone || !userLastName || !userFirstName ) {
            return true;
        }
    }

    useEffect(() => {
        if (props.location.state && props.location.state.selectedID && props.location.state.selectedID !== 0) {
            onEditCustomerProfile(props.location.state.selectedID);
        }
    }, []);

    const resendVerificationEmail = ()=> {
        setShowLoadingBar(true);
        let result = service.ResendEmailCustomerProfile({ "user_id": userID });
        result.then(response => {
            if (response.status === 200) {
                showNotifyWindow('show', 'success', i18n.t('errorMessages.email_resending_success'));
            } else {
                showNotifyWindow('show', 'error', i18n.t('errorMessages.email_resending_failed'));
            }
            setShowLoadingBar(false);
        })
    }

    function onEditCustomerProfile(customerProfileID) {
        if (!permission) {
            showNotifyWindow('show', 'error', i18n.t('errorMessages.permission_error'));
            return;
        }
        setHeader(i18n.t("userPages.customerProfileList.modalEditHeader"));
        setShowLoadingBar(true);
        const data = service.GetCustomerProfile(customerProfileID);
        data.then(response => {
            setShowLoadingBar(false);
            setShowModalWindow(true);
            setCustomerName(response.data.name);
            setCustomerID(response.data.custom_customer_id);
            setEmailVerified(response.data.associateusers_set.email_verified)

            if (response.data.location) {
                setStreet1(response.data.location.street1);
                setStreet2(response.data.location.street2);
                setCity(response.data.location.city);
                setState(response.data.location.state);
                setZip(response.data.location.zip_code);
                setCountry(response.data.location.country);
            }
            setPhone(response.data.phone);
            setFax(response.data.fax);
            setEmail(response.data.email);
            setUserName(response.data.associateusers_set.user.username);
            setUserFirstName(response.data.associateusers_set.first_name);
            setUserLastName(response.data.associateusers_set.last_name);
            setUserMiddleName(response.data.associateusers_set.middle_name);
            setUserPhone(response.data.associateusers_set.phone);
            setUserEmail(response.data.associateusers_set.user.email);
            setEditForm(true);
            setEditCustomerProfileID(customerProfileID);
            setUserID(response.data.associateusers_set.user.id);
        });
    }

    function onClickExpand(tabId) {
        let propsMainData = customerList;
        let expandDatas = [];
        expandDatas = propsMainData.map((mainData) => {
            if (Number(tabId) === Number(mainData.id))
                mainData.is_expand = !mainData.is_expand;
            return mainData
        })
        setCustomerList(expandDatas);
    }

    /**
     * UPDATE CLEARING HOUSE SFTP FOLDER PASSWORD FUNCTIONALITY
     */
    const initialSftpValues = {
        "practiceDetails": "",
        "waystar_sftp_password": "",
        "waystar_sftp_username": "",
        "clearing_house_client_account_id": "",
        "clientAccountIdError": false,
        "userNameError": false,
        "passwordError": true,
        
    }
    const [sftpCredentials, setSftpCredentials] = useState(initialSftpValues)

    function onSftpValueChange(e) {
        const name = e.target.name;
        const value = e.target.value;

        setSftpCredentials((prevCredentials) => {
            if (name === "sftpUsername") {
                return { ...prevCredentials, waystar_sftp_username: value, userNameError: value.length < 5 };
            }

            if (name === "sftpPassword") {
                return { ...prevCredentials, waystar_sftp_password: value, passwordError: value.length < 5 };
            }
            
            if (name === "clientAccountID") {
                return { ...prevCredentials, clearing_house_client_account_id: value, clientAccountIdError: value.length < 5  ||  value.length > 50 };
            }
           

            return prevCredentials;
        });
    }

    function onClickUpdateSftp(practice) {
        if (!permission) {
            showNotifyWindow('show', 'error', i18n.t('errorMessages.permission_error'));
            return;
        }
        setSftpCredentials({...initialSftpValues, practiceDetails: practice})
        setShowSftpUpdateModal(true);
    }

    async function onSaveSftpFormData(e) {
        e.preventDefault();

       
        if (sftpCredentials.passwordError || sftpCredentials.userNameError || sftpCredentials.waystar_sftp_password === '' || sftpCredentials.clientAccountIdError) {
            return;
        } 
        const formData = {
            "waystar_sftp_password": sftpCredentials.waystar_sftp_password,
            "waystar_sftp_username": sftpCredentials.waystar_sftp_username,
            "clearing_house_client_account_id": sftpCredentials.clearing_house_client_account_id
        };

        try {
            setShowLoadingBar(true);
            const response = await service.UpdateSftpCredentials(sftpCredentials.practiceDetails.id, formData);
            if (response && response.status === 200) {
                setTimeout(() => {
                    setShowSftpUpdateModal(false);
                    setSftpCredentials(initialSftpValues);
                }, 300);
                showNotifyWindow("show", "success", i18n.t('update-sftp-credentials.sftp-credential-update-success'))
            } else if (response?.data?.message == "authentication_failed") {
                showNotifyWindow("show", "error", i18n.t('update-sftp-credentials.invalid-sftp-credential'))
            }
        } catch (error) {
            showNotifyWindow('show', 'error', i18n.t('update-sftp-credentials.sftp-credential-update-error'));
        }
        setShowLoadingBar(false);
    }
    /******************************************************/

    const initialRteValues = {
        "practiceDetails": "",
        "waystar_rte_password": "",
        "waystar_rte_username": "",
        "userNameError": false,
        "passwordError": true
    }
    const [rteCredentials, setRteCredentials] = useState(initialRteValues)


    function onRteValueChange(e) {
        const name = e.target.name;
        const value = e.target.value;

        setRteCredentials((prevsCredentials) => {
            if (name === "rteUsername") {
                return { ...prevsCredentials, waystar_rte_username: value, userNameError: value.length < 4 };
            }

            if (name === "rtePassword") {
                return { ...prevsCredentials, waystar_rte_password: value, passwordError: value.length < 4 };
            }

            return prevsCredentials;
        });
    }

    function onClickUpdateRte(practice) {
        if (!permission) {
            showNotifyWindow('show', 'error', i18n.t('errorMessages.permission_error'));
            return;
        }
        setRteCredentials({ ...initialRteValues, practiceDetails: practice })
        setShowRteUpdateModal(true);
    }

    async function onSaveRteFormData(e) {
        e.preventDefault();
        if (rteCredentials.passwordError || rteCredentials.userNameError || rteCredentials.waystar_rte_password === '') {
            return;
        }
        const formData = {
            "waystar_rte_password":  rteCredentials.waystar_rte_password,
            "waystar_rte_username": rteCredentials.waystar_rte_username
        };
        try {
            setShowLoadingBar(true);
            const response = await service.UpdateRteCredentials(rteCredentials?.practiceDetails?.id, formData);
            if (response && response.status === 200) {
                setTimeout(() => {
                    setShowRteUpdateModal(false);
                    setRteCredentials(initialRteValues);
                }, 300);
                showNotifyWindow("show", "success", i18n.t('update-rte-credentials.rte-credential-update-success'))
            } else if (response?.data?.message == "authentication_failed") {
                showNotifyWindow("show", "error", i18n.t('update-rte-credentials.invalid-rte-credential'))
            }
        } catch (error) {
            showNotifyWindow('show', 'error', i18n.t('update-rte-credentials.rte-credential-update-error'));
        }
        setShowLoadingBar(false);
    }

    useEffect(async () => {
        if (showSftpUpdateModal) {
            await service.GetSftpCredentials(sftpCredentials?.practiceDetails?.id)
                .then((response) => {
                    if (response.data?.waystar_sftp_username) {
                        setSftpCredentials({
                            ...sftpCredentials,
                            "waystar_sftp_password": "", 
                            "waystar_sftp_username": response.data.waystar_sftp_username,
                            "clearing_house_client_account_id" : response.data.clearing_house_client_account_id,
                            "clientAccountIdError": false,
                            "userNameError": false,
                            "passwordError": false
                        })
                    }
                })

        } else if (showRteUpdateModal) {
            await service.GetRteCredentials(rteCredentials?.practiceDetails?.id)
                .then((response) => {
                    if (response.data?.waystar_rte_username) {
                        setRteCredentials({
                            ...rteCredentials,
                            "waystar_rte_password": "", 
                            "waystar_rte_username": response.data.waystar_rte_username,
                            "userNameError": false,
                            "passwordError": false
                        })
                    }
                })
        }
    }, [showSftpUpdateModal, showRteUpdateModal])

    return (
        <React.Fragment>
            <Notify showNotify={showNotify} setShowNotify={setShowNotify} notifyDescription={notifyDescription} setNotifyType={setNotifyType} setNotifyDescription={setNotifyDescription} notifyType={notifyType} />
            <div className="col-md-12">
                <div className="box">
                    <div className="box-head ">
                        <div className="alignRight padding-right5 mb-2">
                            <CommonButton variant='contained' onClick={addNew} label={i18n.t("buttons.addNew")} />
                        </div>
                    </div>
                    <div className="box-content">
                        <div className="table-responsive">
                            <ResizableTable id={"customerProfileTable"} className="table"
                                tableHeadings={
                                    <tr>
                                        <th className='checkbox'></th>
                                        <th className='medium'>{i18n.t("userPages.customerProfileList.labelName")}<span className="resize-handle"></span></th>
                                        <th className='small'>{i18n.t("userPages.customerProfileList.labelCustomerID")}<span className="resize-handle"></span></th>
                                        <th className='medium'>{i18n.t("userPages.customerProfileList.labelAddress")}<span className="resize-handle"></span></th>
                                        <th className='small'>{i18n.t("userPages.customerProfileList.labelPhone")}<span className="resize-handle"></span></th>
                                        <th className='action_column'>{i18n.t("dictionariesPages.action")}<span className="resize-handle"></span></th>
                                    </tr>
                                }
                                tableRows={
                                    customerList.length === 0 ?
                                        <tr>
                                            <td align="center" colSpan='5'>
                                                {initialTableData}
                                            </td>
                                        </tr>
                                        :
                                        customerList.map((item, index) => {
                                            return (<React.Fragment key={index}>
                                                <tr key={item.id}>
                                                    <td className="expandDatas" data-testid={"expand-" + index} align='center' onMouseDown={() => onClickExpand(item.id, item.is_expand)}> <SvgIconRender isExpanded={item.is_expand} /></td>
                                                    <td style={{
                                                        color: 'var(--grey-g-800, #424242)',
                                                        fontFeatureSettings: "'clig' off, 'liga' off",
                                                        fontFamily: 'Lato',
                                                        fontSize: '14px',
                                                        fontStyle: 'normal',
                                                        fontWeight: 600,
                                                        lineHeight: 'normal',
                                                        whiteSpace: 'pre-line'
                                                    }}>
                                                        {item.name}
                                                    </td>
                                                    <td align="center">{item.custom_customer_id}</td>
                                                    <td>{item.customer_address}</td>
                                                    <td align="center">{CommonGridPhoneNumber(item.phone)}</td>
                                                    <td align="center">
                                                        <OverlayTrigger
                                                            key={item.id}
                                                            overlay={
                                                                <Tooltip id={`tooltip-${item.id}`}>
                                                                    {'Edit'}
                                                                </Tooltip>
                                                            }
                                                        >
                                                            <IconButton
                                                                id={`edit-ico-btn-${item.id}`}
                                                                onClick={() => onEditCustomerProfile(item.id)}
                                                                style={{ marginRight: '10px', marginLeft: '10px' }}
                                                            >
                                                                <EditIcon />
                                                            </IconButton>
                                                        </OverlayTrigger>
                                                    </td>
                                                </tr>
                                                {item.is_expand && item.practices &&
                                                    <tr>
                                                        <td></td>
                                                        <td colSpan="11" className="innerColSpan">
                                                            <table >
                                                                <thead >
                                                                    <tr className="customer-profile-inner-table-head">
                                                                        <th className='small' >Name</th>
                                                                        <th className='small' >PracticeID</th>
                                                                        <th className='small' >Practice Type</th>
                                                                        <th className='small' >NPI</th>
                                                                        <th className='small' >Users</th>
                                                                        <th className='small' >{i18n.t('update-sftp-credentials.table-header')}</th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    {item.practices.map((item1, index1) => {
                                                                        return (<React.Fragment key={index1}>
                                                                            <tr>
                                                                                <td>{item1.name}</td>
                                                                                <td>{item1.custom_practice_id}</td>
                                                                                <td>{item1.practice_type}</td>
                                                                                <td>{item1.npi}</td>
                                                                                <td>
                                                                                    <AvatarGroup max={7}>
                                                                                        {item1.practice_users.map((user, index) => (
                                                                                            renderAvatar(user, index)
                                                                                        ))}
                                                                                    </AvatarGroup>

                                                                                </td>
                                                                                <td style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                                                                    <Dropdown>
                                                                                        <Dropdown.Toggle variant="" id={`customer-table-update-sftp-${item1.id}`} key={`customer-table-update-sftp-${item1.id}`} align={{ lg: 'end' }}>
                                                                                            <img src={actionImg} alt={'Action Button'} />
                                                                                        </Dropdown.Toggle>
                                                                                        <Dropdown.Menu className='leftSide'>
                                                                                            <Dropdown.Item id={`customer-table-update-btn-${item1.id}`} key={`customer-table-update-btn-${item1.id}`} onClick={() => onClickUpdateSftp(item1)}>{i18n.t('buttons.updatesftp')}</Dropdown.Item>
                                                                                            <Dropdown.Item id={`customer-table-update-btn-${item1.id}`} key={`customer-table-update-btn-${item1.id}`} onClick={() => onClickUpdateRte(item1)}>{i18n.t('buttons.updateRTE')}</Dropdown.Item>
                                                                                        </Dropdown.Menu>
                                                                                    </Dropdown>
                                                                                </td>
                                                                                {/* <td>{item1.practice_users}</td> */}
                                                                            </tr>
                                                                        </React.Fragment>)
                                                                    })}
                                                                    {item.practices?.length === 0 &&
                                                                        <tr style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                                                                            {i18n.t("commons.noRecords")}
                                                                        </tr>
                                                                    }
                                                                </tbody>
                                                            </table>
                                                        </td>
                                                    </tr>
                                                }
                                            </React.Fragment>)
                                        })
                                }
                            />
                        </div>
                        <div className='mt-2'>
                            <Pagination totalPage={totalPage} activePage={activePage} startIndex={startIndex} endIndex={endIndex} onPagePrevious={onPagePrevious} onPageUp={onPageUp} onPageNext={onPageNext} />
                        </div>
                    </div>
                </div>
            </div>
            <CustomizedDialogs showModal={showModalWindow} type="save" header={header} setShowModalWindow={setShowModalWindow} resetForm={resetForm} onHide={onHide}>
                <Form id="form_dataEntry" newPassword={true} onSubmit={(e) => onSaveFormData(e)} encType="multipart/form-data">
                    <div className="row">
                        <div className="col-6">
                            <div className="form-group padding-top15">
                                <TextInput type="text" id="customerName" name="customerName" required={true} label={i18n.t('userPages.customerProfileList.labelCustomerName')} onValueChange={onHandleChange} className={form_error.customerName ? 'input-error' : ""} value={customerName} />
                            </div>
                        </div>
                        {customerID &&
                            <div className="col-6">
                                <div className="form-group padding-top15">
                                    <TextInput type="text" id="customerID" name="customerID" label={i18n.t('userPages.customerProfileList.labelCustomerID')} onValueChange={onHandleChange} disabled={true} className={'read-only'} value={customerID} />
                                </div>
                            </div>
                        }
                    </div>
                    <div className="row">
                        <div className="col-6">
                            <div className="form-group padding-top15">
                                <TextInput type="text" id="street1" name="street1" required={true} label={i18n.t('userPages.customerProfileList.labelStreet1')} onValueChange={onHandleChange} className={form_error.street1 ? 'input-error' : ""} value={street1} />
                            </div>
                        </div>
                        <div className="col-6">
                            <div className="form-group padding-top15">
                                <TextInput type="text" id="street2" name="street2" label={i18n.t('userPages.customerProfileList.labelStreet2')} onValueChange={onHandleChange} value={street2} />
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-3">
                            <div className="form-group padding-top15">
                                <CityInput type="text" id="city" name="city" required={true} label={i18n.t('userPages.customerProfileList.labelCity')} onValueChange={onHandleChange} className={form_error.city ? 'input-error' : ""}
                                    value={city} setValue={setCity} zip={zip} />
                            </div>
                        </div>
                        <div className="col-3">
                            <div className="form-group padding-top15">
                                <SelectInput listItem={'US_StateList'} required={true} id="state" data={US_STATES} name="state" value={state} onValueChange={onHandleChange} label={i18n.t('patientPages.patientInfo.labelState')}  className={form_error.state ? 'input-error' : ""}/>
                            </div>
                        </div>
                        <div className="col-3">
                            <div className="form-group padding-top15">
                                <GeoCodeSearch id="zip" name="zip" className={form_error.zip ? 'input-error' : ""} required={true}
                                    label={i18n.t("customerSetup.provider.labelZip")} onValueChange={onHandleChange}
                                    setlocState={setState} setLocCity={setCity}
                                    setLocCountry={setCountry} setLocZipCode={setZip} defaultValue={zip} />
                                {/* <TextInput type="text" id="zip" name="zip" required={true} label={i18n.t('userPages.customerProfileList.labelZip')} onValueChange={onHandleChange} className={form_error.zip ? 'input-error' : ""} value={zip} /> */}
                            </div>
                        </div>
                        <div className="col-3">
                            <div className="form-group padding-top15">
                                <TextInput type="text" id="country" name="country" label={i18n.t('userPages.customerProfileList.labelCountry')} onValueChange={onHandleChange} className={form_error.country ? 'input-error' : ""} value={country} />
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-3">
                            <div className="form-group">
                                <PhoneInput id="phone" name="phone" required={true} label={i18n.t('userPages.customerProfileList.labelPhone')} onValueChange={setPhone} className={phone < 5 ? 'input-error' : ""} value={phone} />
                            </div>

                        </div>
                        <div className="col-3">
                            <div className="form-group">
                                <PhoneInput id="fax" name="fax" label={i18n.t('userPages.customerProfileList.labelFax')} onValueChange={setFax} className={form_error.fax ? 'input-error' : ""} value={fax} />
                            </div>
                        </div>
                        <div className="col-6">
                            <div className="form-group padding-top3">
                                <EmailInput id="email" name="email" placeholder="Email" label={i18n.t('userPages.customerProfileList.labelEmail')} onValueChange={onHandleChange} required={true} value={email} />
                            </div>
                        </div>
                    </div>
                    <div id="primaryContactDetails">
                        <div className="row sub-heading">
                            <div className="col-2">
                                <div className="div-inline padding-left0">
                                    <label className="margin-top-3px padding-left0">{i18n.t("userPages.customerProfileList.primaryContact")}</label>
                                </div>
                            </div>

                        </div>

                        <div className="row">
                            <div className="col-4">
                                <div className="form-group padding-top15">
                                    <TextInput type="text" id="userLastName" name="userLastName" required={true} label={i18n.t('userPages.associateUsers.labelLastName')} onValueChange={onHandleChange} className={form_error.userLastName ? 'input-error' : ""} value={userLastName} />
                                </div>
                            </div>
                            <div className="col-4">
                                <div className="form-group padding-top15">
                                    <TextInput type="text" id="userFirstName" name="userFirstName" required={true} label={i18n.t('userPages.associateUsers.labelFirstName')} onValueChange={onHandleChange} className={form_error.userFirstName ? 'input-error' : ""} value={userFirstName} />
                                </div>
                            </div>
                            <div className="col-4">
                                <div className="form-group padding-top15">
                                    <TextInput type="text" id="userMiddleName" name="userMiddleName" label={i18n.t('userPages.associateUsers.labelMiddleName')} onValueChange={onHandleChange} className={form_error.userMiddleName ? 'input-error' : ""} value={userMiddleName} />
                                </div>
                            </div>
                        </div>
                       
                        <div className="row">
                            <div className="col-4">
                                <div className="form-group padding-top3">
                                    <EmailInput id="userEmail" name="userEmail" placeholder="User Email" required={true} label={i18n.t('userPages.associateUsers.labelEmail')} onValueChange={onHandleChange} value={userEmail} />
                                </div>
                                {!emailVerified ?
                                    <div className="margin-top-10"><Link data-testid="resenEmail-link" className={editForm ? 'showLink' : 'hide'} to="#" onClick={resendVerificationEmail}>{i18n.t('userPages.associateUsers.lblResendEmailVerification')}</Link></div>
                                            : ''
                                        }
                            </div>
                            <div className="col-4">
                                <div className="form-group">
                                    <PhoneInput type="text" id="userPhone" name="userPhone" required={true} label={i18n.t('userPages.associateUsers.labelPhone')} onValueChange={setUserPhone} className={userPhone < 5 ? 'input-error' : ""} value={userPhone} />
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-4">
                                <div className="form-group padding-top15">
                                    <TextInput 
                                    type="text"
                                     id="userName" 
                                     name="userName"
                                      required={true}
                                       label={i18n.t('userPages.associateUsers.labelUsernamewithemail')}
                                        onValueChange={onHandleChange}
                                       defaultClassName={!validateEmail(userName)&&'input-error'}
                                       value={userName} />
                                </div>
                            </div>
                        </div>
                    </div>
                </Form>
            </CustomizedDialogs>
            <CustomizedSmallDialogs showModal={showSftpUpdateModal} type="save" header={i18n.t('update-sftp-credentials.sftp-credential-update-header')} setShowModalWindow={setShowSftpUpdateModal} onHide={()=>{""}}>
                <Form id="form_dataEntry" onSubmit={(e) => onSaveSftpFormData(e)} encType="multipart/form-data" autocomplete="chrome-off">
                    <Form.Group>
                        <div className="row">
                            <div className="col-md-12">
                          
                                <div>
                                    <h4>{i18n.t('update-sftp-credentials.label-practice')}: {sftpCredentials.practiceDetails.name}</h4>
                                </div>
                                <div className="form-group padding-top15" style={{width: '80%'}}>
                                    <TextInput
                                        type="text"
                                        id="sftp-u"
                                        name="clientAccountID"
                                        required={true}
                                        label={i18n.t('update-sftp-credentials.client-account-id')}
                                        onValueChange={(e) => onSftpValueChange(e)}
                                        error={sftpCredentials.clientAccountIdError}
                                        helperText={ i18n.t('update-sftp-credentials.client-account-id-helper-text')}
                                        value={sftpCredentials.clearing_house_client_account_id}
                                        minLength={4}
                                    />
                                    <TextInput
                                        type="text"
                                        id="sftp-u"
                                        name="sftpUsername"
                                        required={true}
                                        label={i18n.t('update-sftp-credentials.uname')}
                                        onValueChange={(e) => onSftpValueChange(e)}
                                        error={sftpCredentials.userNameError}
                                        helperText={i18n.t('update-sftp-credentials.username-helper-text')}
                                        value={sftpCredentials.waystar_sftp_username}
                                        minLength={4}
                                    />
                                    <TextInput
                                        type="password"
                                        id="sftp-p"
                                        name="sftpPassword"
                                        required={true}
                                        label={i18n.t('update-sftp-credentials.password')}
                                        onValueChange={(e) => onSftpValueChange(e)}
                                        error={sftpCredentials.passwordError}
                                        helperText={ i18n.t('update-sftp-credentials.password-helper-text')}
                                        value={sftpCredentials.waystar_sftp_password}
                                        newPassword={true}
                                    />
                                </div>
                            </div>
                        </div>
                    </Form.Group>
                </Form>
            </CustomizedSmallDialogs>

              <CustomizedSmallDialogs showModal={showRteUpdateModal} type="save" header={i18n.t('update-rte-credentials.rte-credential-update-header')} setShowModalWindow={setShowRteUpdateModal} onHide={()=>{""}}>
                <Form id="form_dataEntry" onSubmit={(e) => onSaveRteFormData(e)} encType="multipart/form-data" autocomplete="chrome-off">
                    <Form.Group>
                        <div className="row">
                            <div className="col-md-12">
                                <div>
                                    <h4>{i18n.t('update-rte-credentials.label-practice')}: {rteCredentials.practiceDetails.name}</h4>
                                </div>
                                <div className="form-group padding-top15" style={{width: '80%'}}>
                                    <TextInput
                                        type="text"
                                        id="rte-u"
                                        name="rteUsername"
                                        required={true}
                                        label={i18n.t('update-rte-credentials.uname')}
                                        onValueChange={(e) => onRteValueChange(e)}
                                        error={rteCredentials.userNameError}
                                        helperText={i18n.t('update-rte-credentials.username-helper-text')}
                                        value={rteCredentials.waystar_rte_username}
                                        minLength={4}
                                    />
                                    <TextInput
                                        type="password"
                                        id="rte-p"
                                        name="rtePassword"
                                        required={true}
                                        label={i18n.t('update-rte-credentials.password')}
                                        onValueChange={(e) => onRteValueChange(e)}
                                        error={rteCredentials.passwordError}
                                        helperText={i18n.t('update-rte-credentials.password-helper-text') }
                                        value={rteCredentials.waystar_rte_password}
                                        newPassword={true}
                                    />
                                </div>
                            </div>
                        </div>
                    </Form.Group>
                </Form>
            </CustomizedSmallDialogs>
        </React.Fragment>
    )

}
export default CustomerProfileList