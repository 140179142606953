import api from "../../../service/api";

const ListServiceType = async (pageSize, page, searchValue) => {
    let path =
        "super-admin/service-type/?page_size=" + pageSize + "&page=" + page;
    if (searchValue) {
        path += `&search=${searchValue}`;
    }
    return api.get(path);
};

const AddServiceType = async (data) => {
    let path = "super-admin/service-type/";
    return api.post(path, data);
};

const GetServiceType = async (serviceID) => {
    let path = "super-admin/service-type/" + serviceID;
    return api.get(path);
};

const UpdateServiceType = async (serviceID, data) => {
    let path = "super-admin/service-type/" + serviceID;
    return api.put(path, data);
};

const DeleteServiceType = async (serviceID) => {
    let path = "super-admin/service-type/" + serviceID;
    return api.destroy(path);
};

export default {
    ListServiceType,
    AddServiceType,
    GetServiceType,
    UpdateServiceType,
    DeleteServiceType,
};
