import * as React from 'react';
import Card from '@mui/material/Card';
import CardMedia from '@mui/material/CardMedia';
import Collapse from '@mui/material/Collapse';
import { Col, Row } from 'react-bootstrap';

export default function ProfileCardForInsurance(props) {
    const [expanded] = React.useState(false);

    const showCloseIcon = () => {
        document.getElementById(props.id).classList.remove('hide');
        if (props.onMouseOver){
            props.onMouseOver();
        }
    }
    const hideShowIcon = () => {
        document.getElementById(props.id).classList.add('hide');
        if (props.onMouseLeave){
            props.onMouseLeave();
        }
    }

    return (
        <>
            <Row>
                <Col className='justify-right'>
                    <button type='button' aria-label="settings" className='btn profileCloseIcon hide'
                        id={props.id}
                        onClick={props.onRemoveImage} onMouseOver={showCloseIcon}
                        onMouseLeave={hideShowIcon}>
                        <i className='fa fa-times' />
                    </button>
                </Col>
            </Row>
            <Card sx={{ maxWidth: 245, border:'none', borderRadius: '0px' }} className={'d-flex border-none justify-content-center card-add-div'} >
                <CardMedia
                    component="img"
                    height={props.height ? props.height : "130"}
                    width={"min-content"}
                    image={props.image}
                    alt={props.alt}
                    onClick={() => props.onClick()}
                    className={"radius0 imgCursor " + props.className}
                    onMouseOver={props?.imageUploaded ? showCloseIcon : hideShowIcon}
                    onMouseLeave={hideShowIcon}
                    style={props.style}
                />
                <Collapse in={expanded} timeout="auto" unmountOnExit>
                </Collapse>
            </Card>
            {props.children}
        </>
    );
}
