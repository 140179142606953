import React, { useState, useEffect, useCallback } from "react";
import Modal from 'react-bootstrap/Modal';
import i18n from '../../../utilities/i18n'; 
// import ReactDOM from 'react-dom';
import Cropper from 'react-easy-crop';
import Slider from '@material-ui/core/Slider'
import Typography from '@material-ui/core/Typography'
// import { withStyles } from '@material-ui/core/styles'
import getCroppedImg from './cropImageFunctions'
// import { styles } from './styles'
import './style.css';

const ImageCrop = (props, classes) => {
    const [crop, setCrop] = useState({ x: 0, y: 0 })
    const [rotation, setRotation] = useState(0)
    const [zoom, setZoom] = useState(1)
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null)
    const imgUrl = props.imgUrl;
    const imageName = props.imageName;
    const [imgObj, setImgObj] = useState(null);
  const [aspect] = useState(4 / 4);
  const [aspectCard] = useState(4 / 2.5);

  const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels)
  }, [])

  const showCroppedImage = useCallback(async () => {
    try {
      await getCroppedImg(
        imageName,
        imgUrl,
        croppedAreaPixels,
        setImgObj,
        rotation,
      )
      props.showModal(false);
    } catch (e) {
      console.error(e)
    }
    setCrop({ x: 0, y: 0 });
    setRotation(0);
    setZoom(1);
  }, [croppedAreaPixels, rotation])

  useEffect(() => {
    if(imgObj)
      props.changeCroppedImage(imgObj)
  }, [imgObj]);


    function closeModal(){
        props.showModal(false);
        onHide();
    }
    function onHide(){
        setCrop({ x: 0, y: 0 });
        setRotation(0);
        setZoom(1);
    }

  return (
    <Modal show={props.show} id={props.modalId} onHide={onHide} >
        <Modal.Header className="modal-header-custom-crop" closeButton={false} >
            <div className="modal-title modal-title-text" >Crop Image</div>
            <button type="button" className="close close-button" data-dismiss="modal" aria-label="Close" onClick={closeModal}>
                <span aria-hidden="true">&times;</span>
            </button>
        </Modal.Header>
        <Modal.Body className="cropImageModalBody">
            <div className="cropImageDiv">
              <div className={classes.cropContainer}>
                <Cropper
                  image={imgUrl}
                  crop={crop}
                  rotation={rotation}
                  zoom={zoom}
                  aspect={props.cardAspect ? aspectCard : aspect}
                  // aspect={4 / 2.5}
                  onCropChange={setCrop}
                  onRotationChange={setRotation}
                  onCropComplete={onCropComplete}
                  onZoomChange={setZoom}
                />
              </div>
              
            </div>
        </Modal.Body>
        <Modal.Footer className="cropImageModalFooter"> 
            <div className={"row cropImageDiv "+classes.controls} >
                <div className={"col-4 "+classes.sliderContainer} >
                  <Typography
                    variant="overline"
                    classes={{ root: classes.sliderLabel }}
                  >
                    Zoom
                  </Typography>
                  <Slider
                    value={zoom}
                    min={1}
                    max={3}
                    step={0.1}
                    aria-labelledby="Zoom"
                    classes={{ root: classes.slider }}
                    onChange={(e, zoom) => setZoom(zoom)}
                  />
                </div>
                <div className={"col-4 "+classes.sliderContainer} >
                  <Typography
                    variant="overline"
                    classes={{ root: classes.sliderLabel }}
                  >
                    Rotation
                  </Typography>
                  <Slider
                    value={rotation}
                    min={0}
                    max={360}
                    step={90}
                    aria-labelledby="Rotation"
                    classes={{ root: classes.slider }}
                    onChange={(e, rotation) => setRotation(rotation)}
                  />
                </div>
                <div className="col-2">
                    <button form="form_cropImage" onClick={showCroppedImage} className="btn btn-primary-blue float-right btn-common-height35">{i18n.t('buttons.done')}</button>
                </div>
                <div className="col-2">
                    <button onClick={closeModal} className="btn btn-secondary float-right btn-common-height35 ">{i18n.t('buttons.cancel')}</button> 
                </div>
              </div>
        </Modal.Footer>
    </Modal>
  )
}

// const StyledDemo = withStyles(styles)(ImageCrop)

// const rootElement = document.getElementById('root')
// ReactDOM.render(<StyledDemo />, rootElement)
export default ImageCrop;