
import React, { useEffect, useState } from 'react';
import i18n from '../../utilities/i18n';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { Divider } from '@mui/material';
import Collapse from '@material-ui/core/Collapse';
const waringsPopUp = (props) => {
    const [description, setDescription] = useState([]);
    const [checked, setChecked] = useState(false)
    const [clicked, setClicked] = useState(false);
    useEffect(() => {
        let desc = [];
        let formWarningData = props.formWarningData;
        if (formWarningData) {
            formWarningData.forEach((item, index) => {
                if (item.tabName) {
                    Object.keys(item).map((key) => {
                        if ((item[key] === '' || item[key] === null || item[key] === 'none') && (key !== 'tabName')) {
                            desc.push(<dt className="warning_label"><span className="dot margin-left40 mr-2"></span><label> {item.tabName} - {key}</label>{formWarningData.length != index + 1 ? <Divider style={{ borderColor: '#F6E8BE' }} /> : ""}</dt>)
                        }
                    })

                } else {
                    Object.keys(item).forEach((key) => {
                        if ((item[key] === '' || item[key] === null || item[key] === 'none') && key !== 'tabName') {
                            desc.push(<dt className="warning_label"><span className="dot margin-left40 mr-2"></span><label>{key}</label>{formWarningData.length != index + 1 ? <Divider style={{ borderColor: '#F6E8BE' }} /> : ""}</dt>)
                        }
                    })
                }
            })
        }

        if (desc.length > 0) {
            description.splice(0, 0, <dt><span className="">{i18n.t("warning.followingMandatoryFields")} : </span></dt>)
        }
        setDescription(desc)
      
    }, [props.formWarningStatus, props.formWarningData])

    const onClickDiv = () => {
        if (!clicked) {
            setChecked(true)
            setClicked(true)
        }
        else {
            setChecked(false)
            setClicked(false)
        }
    }

    let warningText = props.warningText ? props.warningText : i18n.t("warning.warning");
    return (
        description.length>0 &&
         <div onMouseOver={!clicked ? () => setChecked(true) : null} onMouseLeave={!clicked ? () => setChecked(false) : null}
            onClick={() => onClickDiv()}>
            <div className='warningPopUp'>
                <ErrorOutlineIcon />
                <span className='mx-2'>{warningText}</span>
            </div>
            <div className='warningPopUpDiv'>
                <Collapse in={checked}>
                    <span>{description}</span>
                </Collapse>
            </div>
        </div>
    )

}

export default waringsPopUp;