import api from "../../../service/api";
import { getStorage } from "../../../utilities/browserStorage";


const GetAllPermissionsList = async (admin) => {
    let path;
    if (getStorage('isAdminModule') === 'false') {
        path = 'user/permissions/'
      
    } else if (getStorage('isAdminModule') === 'true') {
        path = 'user/permissions/?is_super_admin='+ admin;
       
    }
    return await api.get(path)
};

const AddNewPracticeRole = async (data) => {
    let path;
    if (getStorage('isAdminModule') === 'true') {
        path = 'user/staff-user-roles/';
    } else {
        path = 'practice/practice-role/';
    }
    return await api.post(path,data);
}

const GetSavedRoleData = async (id) => {
    let path;
    if (getStorage('isAdminModule') === 'true') {
        path = `user/staff-user-role/${id}`      
    } else {
        path = `practice/practice-role/${id}`  
    }
    return await api.get(path)
}

const UpdateRole = async (id, data) => {
    let path;
    if (getStorage('isAdminModule') === 'true') {
        path = `user/staff-user-role/${id}`;
    } else {
        path = `practice/practice-role/${id}`;
    }
    return await api.put(path, data);
}



const CreateRole = async (data, id) => {
    // {
        // "practice_pk": 2,
        // "role_name":"associate",
        // "role_permissions":[]
    // }
    let path = 'practice/practice-role/?practice_pk='+id;
    return await api.post(path, data);
}

const GetRoles = async (id, pageSize, page) => {
    let path;
    if (getStorage('isAdminModule') === 'true') {
        path = `user/staff-user-roles/?&page_size=${pageSize}&page=${page}`;
    } else {
        path = `practice/practice-role/?practice_pk=${id}&page_size=${pageSize}&page=${page}`;
    }
    return await api.get(path);
}

const GetRolePermissions = async (id) => {
    let path = 'practice/practice-role/' + id;
    return await api.get(path);
}

const UpdateRolePermissions = async (body, id) => {
    // {
    //     "role_permissions": [3]
    // }
    let path = 'practice/practice-role/' + id;
    return await api.put(path, body);
}

const DeleteRole = async (id) => {
    let path = 'practice/practice-role/' + id;
    return api.destroy(path);
}

export default {
    // Get all permissions list to create a role
    GetAllPermissionsList,
    // Add a practice role
    AddNewPracticeRole,
    // Get existing single practice role for edit
    GetSavedRoleData,
    // Update a role
    UpdateRole,
    // delete
    DeleteRole,
    // post
    CreateRole, 
    // get List
    GetRoles,
    // get by id
    GetRolePermissions,
    // put
    UpdateRolePermissions
}