import { Grid, Paper } from '@mui/material'
import React, { useEffect, useState, useContext, memo, useCallback } from 'react'
import ProfileCard from '../../commons/ProfileCard'
import profileImage from '../../../assets/images/profile-image.png'
import CommonButton from '../../commons/Buttons';
import TextInput from '../../commons/input/input'
import i18n from '../../../utilities/i18n';
import styled from '@emotion/styled';
import SelectInput from '../../commons/input/SelectInput';
import LoadingContext from "../../../container/loadingContext";
import service2 from "../../userManagement/associateUsers/service";
import EmailInput from '../../commons/EmailInput/EmailInput';
import PhoneInput from '../../commons/PhoneInput/PhoneInput';
import { getStorage, setStorage } from '../../../utilities/browserStorage';
import { format } from "date-fns";
import Notify from '../../commons/notify';
import { nameFormat, ValidateEmail } from "../../../utilities/commonUtilities";
import { ADD_ERROR, ADD_SUCCESS, UPDATE_SUCCESS } from '../../../utilities/labelConfigs';
import { RESET_PASSWORD_DURATION, ROUTE_DASHBOARD } from '../../../utilities/staticConfigs';
import { imageExtension } from "../../../utilities/validations";
import ImageCrop from "../../commons/image-cropper/ImageCrop";
import './style.css'
import service from './service';
import {useHistory } from 'react-router-dom'
import MyProfilePracticeSelector from '../../commons/PracticeSelector/MyProfilePracticeSelector';
import profileSkeletonImg from '../../../assets/images/profile-image.png';


// Caching the ImageCropper Component to avoid un-necessary render
const MemoizedImgCropper = memo(({ imgUrl, imageName, show, showModal, changeCroppedImage }) => {
    return < ImageCrop imgUrl={imgUrl} imageName={imageName} show={show} showModal={showModal} changeCroppedImage={changeCroppedImage} />;
});

MemoizedImgCropper.displayName = 'MemoizedImgCropper';


const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    color: theme.palette.text.secondary,
}));


function MyProfile() {
    const practicePK = getStorage("practice");
    const myProfileUserData = getStorage("userData");
    const isAdminModule = getStorage("isAdminModule");
    const history = useHistory();
    let UserDataObj = "";
    if (myProfileUserData) UserDataObj = JSON.parse(myProfileUserData);

    const [myProfileDefaultPracticeList, setMyProfileDefaultPracticeList] = useState([]);
    const [myProfilePracticesList, setMyProfilePracticesList] = useState([]);
    const [myProfileSelectedPractices, setMyProfileSelectedPractices] = useState([]);
    const [myProfileDefaultPractice, setMyProfileDefaultPractice] = useState("");
    const [myProfilePractices,setMyProfilePractices] = useState("");
    const [isProfilePictureChanged, setIsProfilePictureChanged] = useState(false);

    const [myProfileLastName, setMyprofileLastName] = useState("");
    const [myProfileFirstName, setMyProfileFirstName] = useState("");
    const [myProfileMiddleName, setMyProfileMiddleName] = useState("");
    const [myProfileEmail, setMyProfileEmail] = useState("");
    const [myProfilePhone, setMyProfilePhone] = useState("");
    const [myProfileUserID, setMyProfileUserID] = useState("");
    const [myProfileForcePassword, setMyProfileForcePassword] = useState("");
    const [myProfilePasswordLast, setMyProfilePasswordLast] = useState("");
    const [nextReset, setNextReset] = useState("");

    const [userIdValidate, setUserIDValidate] = useState(false);
    const [editAssociateId, setEditAssociateId] = useState("");
    const [gotoDashboard, setGotoDashboard] = useState(false);

    const [myProfileImageUrl, setMyProfileImageUrl] = useState(profileImage);
    const [profilePicture, setProfilePicture] = useState('');
    const [imgUrl, setImgUrl] = useState(null);
    const [showImageCropperModalWindow, setShowImageCropperModalWindow] = useState(false);
    const [imageName, setImageName] = useState('');

    const setShowLoadingBar = useContext(LoadingContext);
    const [showNotify, setShowNotify] = useState("hide");
    const [notifyDescription, setNotifyDescription] = useState("");
    const [notifyType, setNotifyType] = useState("success");

    function showNotifyWindow(action, type, desc, age = 3000) {
        if (action === "show") {
            setTimeout(() => {
                setShowNotify("hide");
            }, age);
        }
        setShowNotify(action);
        setNotifyType(type);
        setNotifyDescription(desc);
    }


    function handleMaterialMultiselectSearch(value) {
        setMyProfileSelectedPractices(value);
        const anArray = [];
        myProfilePracticesList.map((item) => {
            value.forEach((item2) => {
                if (Number(item2) === Number(item.id)) {
                    anArray.push(item);
                }
            });
        });
    }

    function resetMyProfileWindow() {
        setMyProfileUserID("");
        setMyProfileEmail("");
        setMyProfilePhone("");
        setMyProfileFirstName("");
        setMyProfileMiddleName("");
        setMyprofileLastName("");
        setMyProfilePhone("");
        setMyProfilePractices("");
        setMyProfileDefaultPractice("");
        setMyProfileForcePassword(RESET_PASSWORD_DURATION);
        setMyProfilePasswordLast("");
        setProfilePicture("");
        setMyProfileImageUrl(profileImage);
        setMyProfileSelectedPractices([]);
        setMyProfilePasswordLast("");
        setNextReset("");
    }

    function getMyprofileData
    (associateID) {
        setShowLoadingBar(true);
        const data = service2.GetAssociateUser(associateID);
        data.then((response) => {
            setShowLoadingBar(false);
            setMyProfilePracticesList(response?.data?.practices)
            setMyprofileLastName(response?.data?.last_name)
            setMyProfileUserID(response?.data?.user?.username);
            setMyProfileFirstName(response?.data?.first_name);
            setMyProfileMiddleName(response?.data?.middle_name);
            setMyProfileEmail(response?.data?.user?.email);
            setMyProfilePhone(response?.data?.phone);
            setMyProfileSelectedPractices(response?.data?.practices_ids);
            if (response?.data?.default_practice)
                setMyProfileDefaultPracticeList(response?.data?.practices);
            setMyProfileDefaultPractice(response?.data?.default_practice?.id)
            setMyProfileForcePassword(response?.data?.password_reset_days)
            setMyProfilePasswordLast(format(new Date(response?.data?.password_reset_date), "MM/dd/yyyy hh:mm a"));
            setNextReset(format(new Date(response?.data?.next_reset_date), "MM/dd/yyyy hh:mm a"));
            setMyProfileImageUrl(response?.data?.profile_picture)
            setEditAssociateId(associateID)
        })
    }

    useEffect(() => {
        getMyprofileData(UserDataObj.profile_id);
    }, [])

    function onRemoveImage() {
        setMyProfileImageUrl("");
        setProfilePicture("");
        setMyProfileImageUrl("");
        setIsProfilePictureChanged(true);
    }

    function onSwitchToAdmin() {
        history.push(ROUTE_DASHBOARD);
        setStorage('isAdminModule', true);
        service.SwitchAdmin({
            practice_pk: practicePK,
        });
        window.location.reload();
    }

    const onCancel = () => {
        history.goBack();
    }


    function onHandleChange(e) {
        let name = e.target.name;
        let value =
            e.target.type === "checkbox" ? e.target.checked : e.target.value;
        let trimmedValue = "";
        let inputFields = [
            "myProfileEmail",
            "myProfileLastName",
            "myProfileFirstName",
            "myProfileMiddleName",
            "myProfilePhone",
            "myProfileUserID",
            "myProfileDefaultPractice",
            "myProfilePractices",
            "myProfileForcePassword",
        ]
        if (inputFields.includes(name)) {
            trimmedValue = value;
        }

        switch (name) {

            case "myProfileLastName":
                setMyprofileLastName(trimmedValue);
                break;
            case "myProfileEmail":
                setMyProfileEmail(trimmedValue);
                setMyProfileUserID(trimmedValue);
                if (ValidateEmail(trimmedValue)) {
                    setUserIDValidate(false);
                } else {
                    setUserIDValidate(true);
                }
                break;
            case "myProfileFirstName":
                setMyProfileFirstName(trimmedValue);
                break;
            case "myProfileMiddleName":
                setMyProfileMiddleName(trimmedValue);
                break;
            case "myProfilePhone":
                setMyProfilePhone(trimmedValue);
                break;
            case "myProfileUserID":
                setMyProfileUserID(trimmedValue);
                if (ValidateEmail(trimmedValue)) {
                    setUserIDValidate(false);
                } else {
                    setUserIDValidate(true);
                }
                break;
            case "myProfileDefaultPractice":
                setMyProfileDefaultPractice(trimmedValue);
                break;
            case "myProfileForcePassword":
                setMyProfileForcePassword(trimmedValue);
                if (trimmedValue) {
                    let nextResetDate = new Date();
                    nextResetDate.setDate(nextResetDate.getDate() + parseInt(trimmedValue));
                    setNextReset(format(nextResetDate, "MM/dd/yyyy hh:mm a"));
                }
                break;
            default:
                break;
        }

    }

    function createFormData() {
        const data = new FormData();
        let formData = {
            "page": "profile",
            "username": myProfileUserID,
            "email": myProfileEmail,
            "phone": myProfilePhone,
            "first_name": myProfileFirstName,
            "middle_name": myProfileMiddleName,
            "last_name": myProfileLastName,
            "practices": myProfileSelectedPractices,
            "default_practice": myProfileDefaultPractice,
            "password_reset_days": myProfileForcePassword,
            'is_active': 2,
            "practice_pk": practicePK
        }

        if (isProfilePictureChanged) {
            if (profilePicture !== "" && profilePicture !== null) {
                formData["profile_picture"] = profilePicture;
            }  
            setIsProfilePictureChanged(false)
        }

        for (const key in formData) {
            data.append(key, formData[key])
        }
        return data;
    }

    //on update or add called
    async function onSaveFormData(e) {
        e.preventDefault();
        if (hasValidationErrors()) {
            return;
        }

        setShowLoadingBar(true);
        const data = await createFormData();
        const fullName = nameFormat(
            myProfileFirstName,
            myProfileMiddleName,
            myProfileLastName
        );
        const result = service2.UpdateAssociateUser(editAssociateId, data);

        result
            .then((response) => {
                setShowLoadingBar(false);
                if (response.status === 201 || response.status === 200) {
                    showNotifyWindow("show", "success", response.status === 201 ? ADD_SUCCESS : UPDATE_SUCCESS);
                    //   setTimeout(() => setShowProfileWindow(false), 2000);
                    //updating all new user details
                    updateUserData(response.data, fullName);
                    history.goBack()
                } else if (response.status === 400 && response?.data?.user_already_exists !== undefined) {
                    handleUserExistsError();
                } else if (response.status === 400 && response?.data?.message !== undefined && response?.data?.message === 'email_sending_failed') {
                    handleEmailSendingFailedError();
                } else if (response.status === 400 && response?.data?.message !== undefined && (response?.data?.message === 'invalid_username' || response?.data?.message === 'invalid_username-whitespace' || response?.data?.message === 'username-already-exists')) {
                    handleInvalidUsernameError(response.data.message);
                } else {
                    showNotifyWindow('show', 'error', ADD_ERROR);
                }
            })
    }

    /**
   *user already exists error 
   */
    function handleUserExistsError() {
        showNotifyWindow('show', 'error', i18n.t('errorMessages.user_exists'));
    }

    /**
   * email related issues handled here
   * @param {*} message 
   */
    function handleEmailSendingFailedError() {
        showNotifyWindow('show', 'error', i18n.t('errorMessages.email_sending_failed'));
    }


    /**
     * username related errors handled heree
     * @param {*} message 
     */
    function handleInvalidUsernameError(message) {
        if (message === 'invalid_username-whitespace') {
            showNotifyWindow('show', 'error', i18n.t('errorMessages.invalid_username-whitespace'));
        } else if (message === 'username-already-exists') {
            showNotifyWindow('show', 'error', i18n.t('errorMessages.username-already-exists'));
        } else {
            showNotifyWindow('show', 'error', i18n.t('errorMessages.invalid_username'));
        }
    }

    // validate error of empty field
    function hasValidationErrors() {
        if (!myProfileEmail || !myProfileUserID || !myProfilePhone) {
            return true;
        }

        if (!ValidateEmail(myProfileEmail)) {
            return true;
        }

        if (myProfileSelectedPractices?.length == 0) {
            return true
        }


        if (myProfileSelectedPractices?.length > 0 && (!myProfileDefaultPractice ||
            myProfileDefaultPractice === "" || myProfileDefaultPractice === 0)) {
            return true;
        }

        return false;
    }

    //updating user Data
    function updateUserData(data, fullName) {
        const userData = JSON.parse(getStorage("userData"));
        userData.profile_image = data.profile_picture ?? "";
        userData.profile_name = fullName;
        setStorage("userData", JSON.stringify(userData));
    }

    function onHandleInputChange(e) {
        const name = e.target.name;
        let imageUrl = '';
        let imgObj = null;
        if (imageExtension(e.target.files[0].name)) {
            imgObj = e.target.files[0]
            imageUrl = URL.createObjectURL(e.target.files[0]);
        }
        setImgUrl(imageUrl);
        setImageName(imgObj.name);
        document.getElementById(name).value = '';
        setShowImageCropperModalWindow(true);
    }
    const onChangeShowImageCropperModalWindow = useCallback((newValue) => {
        setShowImageCropperModalWindow(newValue);
    }, []);

    const onCroppedImageChange = useCallback((newValue) => {
        setProfilePicture(newValue);
        setMyProfileImageUrl(URL.createObjectURL(newValue));
    }, [])

    function openImage() {
        document.getElementById('myProfile_input').click();
        setIsProfilePictureChanged(true);
    }


    if (gotoDashboard) {
        setGotoDashboard(false);
        if (
            JSON.parse(getStorage("userData")).group_id === 1 &&
            myProfileSelectedPractices.length === 0
        )
            onSwitchToAdmin();
        else history.push(ROUTE_DASHBOARD);
    } else {

        return (

            <>


                <div className='col-md-12 '>
                <div className='box-content-white'>
                <Grid container spacing={1} >
                    <Notify
                        showNotify={showNotify}
                        setShowNotify={setShowNotify}
                        notifyDescription={notifyDescription}
                        setNotifyType={setNotifyType}
                        setNotifyDescription={setNotifyDescription}
                        notifyType={notifyType}
                    />

                    <Grid item xs={12} style={{padding:'20px'}}>

                        <Grid container spacing={1} className='mt-3'>

                            <Grid item xs={2} >
                                <ProfileCard 
                                    image={myProfileImageUrl ? myProfileImageUrl : profileSkeletonImg}
                                    defaultImage={profilePicture}
                                    className={'pro-style'}
                                    onClick={openImage}
                                    alt={"Profile Name"}
                                    onRemoveImage={onRemoveImage}
                                >
                                </ProfileCard>
                            </Grid>
                            <Grid item xs={4} style={{ marginTop: '60px'}}>

                                <CommonButton variant="contained" 
                                    onClick={openImage}
                                    icon='add'
                                    label={i18n.t('commons.uploadNewPicture')} />

                                <input type="file" style={{ display: 'none' }} id="myProfile_input" name="myProfile_input" onChange={onHandleInputChange} accept=".jpeg, .jpg, .png, .gif" />

                                <MemoizedImgCropper
                                    imgUrl={imgUrl}
                                    imageName={imageName}
                                    show={showImageCropperModalWindow}
                                    showModal={onChangeShowImageCropperModalWindow}
                                    changeCroppedImage={onCroppedImageChange}
                                />
                                <span style={{ marginLeft:'20px'}}>
                                    <CommonButton variant="outlined"
                                        onClick={onRemoveImage}
                                        label={i18n.t('commons.delete')} 
                                    />
                                </span>
                            </Grid>
                            {/* <Grid item xs={1} style={{ marginTop: '60px',paddingLeft:'16px'}}>
                                <CommonButton variant="outlined"
                                    onClick={onRemoveImage}
                                    label={i18n.t('commons.delete')} />
                            </Grid> */}
                            <Grid item xs={6} >

                            </Grid>

                            <Grid item xs={4}>
                                <Item><TextInput
                                    type="text"
                                    id="myProfileLastName"
                                    name="myProfileLastName"
                                    label={i18n.t(
                                        "userPages.associateUsers.labelLastName"
                                    )}
                                    onValueChange={onHandleChange}
                                    required={true}
                                    value={myProfileLastName}
                                /></Item>
                            </Grid>
                            <Grid item xs={4}>
                                <Item> <TextInput
                                    type="text"
                                    id="myProfileFirstName"
                                    name="myProfileFirstName"
                                    label={i18n.t(
                                        "userPages.associateUsers.labelFirstName"
                                    )}
                                    onValueChange={onHandleChange}
                                    required={true}
                                    value={myProfileFirstName}
                                /></Item>
                            </Grid>
                            <Grid item xs={4}>
                                <Item> <TextInput
                                    type="text"
                                    id="myProfileMiddleName"
                                    name="myProfileMiddleName"
                                    label={i18n.t(
                                        "userPages.associateUsers.labelMiddleName"
                                    )}
                                    onValueChange={onHandleChange}
                                    value={myProfileMiddleName}
                                /></Item>
                            </Grid>

                            <Grid item xs={4}>
                                <Item className='mt-1'>
                                    <EmailInput
                                        type="myProfileEmail"
                                        id="myProfileEmail"
                                        name="myProfileEmail"
                                        label={i18n.t(
                                            "userPages.associateUsers.labelEmail"
                                        )}
                                        onValueChange={onHandleChange}
                                        required={true}
                                        value={myProfileEmail}
                                        placeholder="Email"
                                    />
                                </Item>
                            </Grid>
                            <Grid item xs={4}>
                                <Item> <PhoneInput
                                    name="myProfilePhone"
                                    label={i18n.t(
                                        "userPages.associateUsers.labelPhone"
                                    )}
                                    onValueChange={setMyProfilePhone}
                                    value={myProfilePhone}
                                    required={true}
                                /></Item>
                            </Grid>
                            <Grid item xs={4}>
                                <Item className='mt-2'> <TextInput
                                    type="text"
                                    id="myProfileUserID"
                                    name="myProfileUserID"
                                    label={i18n.t(
                                        "userPages.associateUsers.labelUsernamewithemail"
                                    )}
                                    onValueChange={onHandleChange}
                                    required={true}
                                    value={myProfileUserID}
                                    defaultClassName={userIdValidate && 'input-error'}
                                /></Item>
                            </Grid>

                            <Grid item xs={12}>
                                <Item>
                                    <MyProfilePracticeSelector 
                                    myProfilePracticesList={myProfilePracticesList}
                                    myProfileSelectedPractices={myProfileSelectedPractices}
                                    handleMaterialMultiselectSearch={handleMaterialMultiselectSearch}
                                    resetMyProfileWindow={resetMyProfileWindow}/>
                                </Item>
                            </Grid>

                            <Grid item xs={3}>
                                <Item>
                                    <SelectInput
                                        data={myProfileDefaultPracticeList}
                                        defaultLabel={i18n.t(
                                            "userPages.associateUsers.labelDefaultPractice"
                                        )}
                                        name="myProfileDefaultPractice"
                                        id="myProfileDefaultPractice"
                                        value={myProfileDefaultPractice}
                                        onValueChange={onHandleChange}
                                        label={i18n.t(
                                            "userPages.associateUsers.labelDefaultPractice"
                                        )}
                                        required={
                                            isAdminModule !== "true"
                                                ? true
                                                : myProfileSelectedPractices &&
                                                    myProfileSelectedPractices.length > 0
                                                    ? true
                                                    : false
                                        }
                                        showInRed={
                                            myProfilePractices &&
                                                myProfilePractices.length > 0 &&
                                                (!myProfileDefaultPractice ||
                                                    myProfileDefaultPractice == "" ||
                                                    myProfileDefaultPractice == 0)
                                                ? true
                                                : false
                                        }
                                    />
                                </Item>
                            </Grid>
                            <Grid item xs={3}>
                                <Item>
                                    <TextInput
                                        type="text"
                                        pattern="[0-9]*"
                                        id="myProfileForcePassword"
                                        name="myProfileForcePassword"
                                        label={i18n.t(
                                            "userPages.associateUsers.labelPasswordReset"
                                        )}
                                        onValueChange={onHandleChange}
                                        value={myProfileForcePassword}
                                    />
                                </Item>
                            </Grid>
                            <Grid item xs={3}>
                                <Item>
                                    <TextInput
                                        type="text"
                                        id="nextReset"
                                        name="nextReset"
                                        label={i18n.t(
                                            "userPages.associateUsers.labelNextReset"
                                        )}
                                        onValueChange={onHandleChange}
                                        value={nextReset}
                                        readonly
                                        disabled
                                    />
                                </Item>
                            </Grid>
                            <Grid item xs={3}>
                                <Item>
                                    <TextInput
                                        type="text"
                                        id="myProfilePasswordLast"
                                        name="myProfilePasswordLast"
                                        label={i18n.t(
                                            "userPages.associateUsers.labelPasswordLast"
                                        )}
                                        onValueChange={onHandleChange}
                                        value={myProfilePasswordLast}
                                        readonly
                                        disabled
                                    />
                                </Item>
                            </Grid>


                        </Grid>

                            <Grid container >
                                <Grid item xs={10} >

                                </Grid>
                                
                                <Grid item xs={2} >
                                    <div className='justify-right' style={{ display: 'flex', justifyContent:"flex-end", marginRight: "5px" }}>
                                        <div style={{ marginRight: "20px" }}>
                                        <CommonButton label="Cancel" variant="outlined" onClick={onCancel}/>
                                        </div>
                                        <CommonButton  label="Save" variant="contained" onClick={onSaveFormData} />
                                    </div>
                                    
                                </Grid>
                                {/* <Grid item xs={1}>
                                    <div className='justify-right'>
                                        
                                    </div>
                                </Grid> */}
                            </Grid>

                    </Grid>
                </Grid>
                </div>
                </div>
            

            </>
        )
    }
}

export default MyProfile