import React, { useEffect, useState } from "react";
import TextInput from "../input/input";
import * as gLoc from "../../../utilities/googleConfigs";
import { GEO_API_KEY } from "../../../utilities/staticConfigs";

const GeoCodeSearch = (props) => {
  const [zipCode, setZipCode] = useState(props.defaultValue);
  const [locState, setlocState] = useState("");
  const [locCountry, setLocCountry] = useState("");
  const [locCity, setLocCity] = useState("");
  const [disabled, setDisabled] = useState("");

  useEffect(() => {
    if (props.setlocState && locState) props.setlocState(locState, "state");
    if (props.setLocCountry && locCountry)
      props.setLocCountry(locCountry, "country");
    if (props.setLocCity && locCity) props.setLocCity(locCity, "city");

    if (props.checkFormWarning) {
      props.checkFormWarning("state", locState);
      props.checkFormWarning("city", locCity);
    }
  }, [locState, locCountry, locCity]);

  useEffect(() => {
    if (props.disabled) setDisabled(props.disabled);
    else setDisabled(false);
  }, [props.disabled]);
  
  useEffect(() => {
    if (zipCode !== props.defaultValue) setZipCode(props.defaultValue);
  }, [props.defaultValue]);

  function onHandleChange(e) {
    let value = e.target. value.replace(/[^0-9]/g, '');
    setZipCode(value);
    if (props.funcInvoke) {
      props.setValueChanged(true);
    }
    if (props.setLocZipCode) props.setLocZipCode(value, "zip");
    if (props.checkFormWarning) {
      props.checkFormWarning("zip", value);
    }
  }

/**
 * Function retrieve city and state based on Zip code provided, the function is evoked on Blur.
 * Utilizing Google Maps Geocoding API, and sets the corresponding
 * location properties (state, country, and city) using the provided setter functions.
 * @returns {Promise<void>} A Promise that resolves when the location properties have been set.
 */
async function onValueBlur() {
    // If the zip code is falsy, do nothing and return early
    if (!zipCode) {
      return;
    }

    // Retrieve the location information for the zip code from the Google Maps Geocoding API
    const response = await fetch(`https://maps.googleapis.com/maps/api/geocode/json?components=country:US|postal_code:${zipCode}&key=${GEO_API_KEY}`);
    const data = await response.json();

  // If no location information was returned, do nothing and return;
  if (data.results.length === 0) {
    return;
  }

  // Extract the address components from the API response
  const addressComponents = data.results[0].address_components;

  // Helper function to set the location property based on the given component types
  const setLocProperty = (property, types) => {
    for (const component of addressComponents) {
      if (component.types.some(type => types.includes(type))) {
        // Extract the value of the component (either short_name or long_name)
        const value = component.short_name.trim() || component.long_name.trim();
        // Call the property setter function with the extracted value
        property(value);
        // If funcInvoke is truthy, call setValueChanged on the props object
        if (props.funcInvoke) {
          props.setValueChanged(true);
        }
        // Break out of the loop once a matching component is found
        break;
      }
    }
  };

  // Set the state, country, and city location properties using the helper function
  setLocProperty(setlocState, gLoc.GOOGLE_LOCATION_STATE);
  setLocProperty(setLocCountry, gLoc.GOOGLE_LOCATION_COUNTRY);
  setLocProperty(setLocCity, gLoc.GOOGLE_LOCATION_CITY);
}

  return (
    <TextInput
      type="text"
      disabled={disabled}
      maxLength={10}
      onValueBlur={onValueBlur}
      defaultClassName={
        zipCode && zipCode.length < 5
          ? "input-error " + props.className
          : props.className
      }
      required={props.required ? props.required : false}
      id={props.id}
      name={props.name}
      label={props.label}
      onValueChange={onHandleChange}
      value={zipCode}
    />
  );
};
export default GeoCodeSearch;
