import React from 'react'
import { Sorting } from './Sorting'

export const TableHead = (props) => {
    let excludeCenterAlignHeadings  = ['action', 'actions', 'select', 'preview']; 
    let excludeCenterAlignHeadTypes = ['checkbox']; 
    return (
        <>
            <thead>
                <tr className='table-row-height' >
                    {
                        props.tableHeadData.map((item, index) => {
                            let hide = item.hideValue ? " hide " : "";
                            let crPointer = item?.sort ? 'cursor-pointer ' : '';
                            return (
                                <th key={index} className={
                                    (excludeCenterAlignHeadings.includes(item.name.toLowerCase()) || excludeCenterAlignHeadTypes.includes(item.type.toLowerCase()) ? "" : " textLeft ") + crPointer + hide + " " + (item.width)} onClick={(e) => (item?.sort ? props.sortingFunction(e, item.name) : dummyfunction() )}>
                                    {item.type == 'checkbox' ?
                                    <div className="form-groupp lh-1">
                                        <div className="custom-checkbox">
                                            <input type="checkbox" name="gridCheckBox" id={`gridCheckBox`} onChange={(e) => props.onClickGridCheckBox(e, 'header')} checked={props.checkedHeaderCheckBox}
                                                    disabled={props.checkboxEnable} />
                                            <label className="checkbox" htmlFor={`gridCheckBox`}>
                                                </label>
                                        </div>
                                    </div>
                                    : 
                                    item.name}
                                    {
                                        item?.sort ? <Sorting sortingFunction={props.sortingFunction} tableSortingData={item} /> : null
                                    }
                                    {
                                        props.isResize ?
                                            <span className="resize-handle"></span>
                                            :
                                            null
                                    }
                                </th>
                            )
                        })
                    }
                </tr>
            </thead>
        </>
    )
}

function dummyfunction(){
    return;
}


