import api from '../../../service/api';

const ListLanguageTypes = async(pageSize, page, isAdminModule, practicePK, searchValue) =>{
	let path = null;
	if (isAdminModule === 'false')
		path = 'practice/patient-language/?page_size='+ pageSize +'&page='+ page +'&practice_pk='+ practicePK;
	else
		path = 'super-admin/patient-language/?page_size='+pageSize+'&page='+page;
	if (searchValue) {
        path += `&search=${searchValue}`;
    }
	return api.get(path);
}

const AddLanguageType = async(data) =>{
	let path = null;
	if (Object.prototype.hasOwnProperty.call(data, 'practice'))
		path = 'practice/patient-language/';
	else
		path = 'super-admin/patient-language/';
	return api.post(path,data);
}
const GetLanguageType = async(languageId, practicePK) =>{
	let path = null;
	if (practicePK)
		path = 'practice/patient-language/' + languageId;
	else
		path = 'super-admin/patient-language/' + languageId;
	return api.get(path);
}

const UpdateLanguageType = async(languageId, data) =>{
	let path = null;
	if (Object.prototype.hasOwnProperty.call(data, 'practice'))
		path = 'practice/patient-language/' + languageId;
	else
		path = 'super-admin/patient-language/' + languageId;
	return api.put(path,data);
}

const DeleteLanguageType = async(languageId, practicePK) =>{
	let path = null;
	if (practicePK)
		path = 'practice/patient-language/' + languageId;
	else
		path = 'super-admin/patient-language/' + languageId;
	return api.destroy(path);
}

export default {ListLanguageTypes, AddLanguageType, GetLanguageType, UpdateLanguageType, DeleteLanguageType}