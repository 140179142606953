import React, { useEffect, useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import "./style.css";
import i18n from '../../utilities/i18n';

const label = { inputProps: { 'aria-label': 'Checkbox demo' } };
export const ModalButtonWindow = (props) => {
    const [filteringValue, setFilteringValue] = useState([]);
    const [ArrayList, setArrayList] = useState([]);

    useEffect(() => {
        setArrayList(props.data);
    }, [props.data])
    
    function onCloseModalButtonScreen() {
        if (props.resetButton) {
            props.resetButton()
        }
        props.setShow(false);
        setFilteringValue([]);
    }
    function onFilterCheckBoxes(e) {
        let filterValue = e.target.value;
        setFilteringValue(filterValue);
        let newArray = props.data.filter((item)=>item.name.toLowerCase().includes(filterValue.toLowerCase()));
        setArrayList(newArray);
    }
  return (
    <Modal id={'modal_button_screen'} show={props.show}>
        <Modal.Header>
            <h3>{i18n.t("inboxScreens.composeScreen.modalButton.selectContacts")}</h3>
        </Modal.Header>
        <Modal.Body className='modal_button_screen_body'>
            <input type='text' name='filterCheckBoxes' value={filteringValue} onChange={onFilterCheckBoxes} className={"w-100"} />
            <ul className='list-group'>
                {
                    ArrayList.map((item,index)=>{
                        return(
                            <li className='list-group-item' key={index}>
                                <FormControlLabel control={<Checkbox {...label} 
                                    data-testid={"checkbox-" + index}
                                onChange={(e)=>props.onCheckBoxChange(e,item)} value={item.value}
                                name={item.name} className={props.className}
                                checked={Array.isArray(props.optionValues) && props.optionValues.indexOf(item.id) > -1}
                                />}
                                    label={item.name}
                                />
                            </li>
                        )
                    })
                }
            </ul>
        </Modal.Body>
        <Modal.Footer>
              <button type='button' data-testid="searchcontact-2" onClick={onCloseModalButtonScreen} className='btn btn-secondary'>{i18n.t("inboxScreens.composeScreen.modalButton.cancel")}</button>
            <button type='button' onClick={props.onSaveModalButton} className='btn btn-primary-blue'>{i18n.t("inboxScreens.composeScreen.modalButton.selectContacts")}</button>
        </Modal.Footer>
    </Modal>
  )
}
