import i18n from "../../../utilities/i18n";

const RemittanceRemarkHeader =  [
        {
            "name": i18n.t("dictionariesPages.dictRemittanceRemarkCode.lblRemittanceRemarkCode"),
            "type": "string",
            "width": "medium",
            "sort": true,
            "initialOrdering": true,
            "orderType": ''
        },
        {
            "name": i18n.t("dictionariesPages.dictRemittanceRemarkCode.lblDescription"),
            "type": "string",
            "width": "large",
            "sort": false
        },
        {
            "name": i18n.t("dictionariesPages.action"),
            "type": "button",
            "width": "action_column",
            "sort": false
        },
    ];

export const RemittanceRemarkTableBodyAdmin = [
    [
        {   "id" : "",
            "name": "name",
            "value": "",
            "type": "string",
            "align": "left",
            "width": "medium"
        }, 
        {   "id" : "",
            "name": "description",
            "value": "",
            "type": "string",
            "align": "left",
            "width": "large"
        },
        { "id": "",
            "name": "action",
            "type": "button",
            "align": "center",
            "width": "action_column",
            "dropdown": [
                { "id": "",
                    "name": "Edit",
                },
                { "id": "",
                    "name": "Delete",
                }
            ]
        },
    ]
]

export const RemittanceRemarkTableBody = [
        [
            {   "id" : "",
                "name": "name",
                "value": "",
                "type": "string",
                "align": "left",
                "width": "medium"
            }, 
            {   "id" : "",
                "name": "description",
                "value": "",
                "type": "string",
                "align": "left",
                "width": "large"
            },
            { "id": "",
                "name": "action",
                "type": "button",
                "align": "center",
                "width": "action_column",
                "dropdown": [
                    { "id": "",
                        "name": "Delete",
                    }
                ]
            },
        ]
    ]

export const RemittanceRemarkTableObject = { 
    "name": "simpleTable",
    "isResize": true,
    "isFixedHead": false,
    "noRecordsMsg" : i18n.t('commons.noRecords'), 
    "tableHeadings": RemittanceRemarkHeader,
    "tableBodyData" : RemittanceRemarkTableBody
}

export const RemittanceRemarkTableAdminObject = {
    "name": "simpleTable",
    "isResize": true,
    "isFixedHead": false,
    "noRecordsMsg" : i18n.t('commons.noRecords'), 
    "tableHeadings": RemittanceRemarkHeader,
    "tableBodyData" : RemittanceRemarkTableBodyAdmin
}

// ************************ TABLE FOR REMITTANCE REMARK CODE - ADD SCREEN ******************************

const RRCAddTableHeader =  [
    {
        "name": i18n.t("dictionariesPages.dictRemittanceRemarkCode.lblRemittanceRemarkCode"),
        "type": "string",
        "width": "small",
        "sort": false,
    },
    {
        "name": i18n.t("dictionariesPages.dictRemittanceRemarkCode.lblDescription"),
        "type": "string",
        "width": "large",
        "sort": false
    },
    {
        "name": i18n.t("commons.select"),
        "type": "string",
        "width": "check_box",
        "sort": false,
    },
];

const RRCAddTableBody = [
    [
        {   "id" : "",
            "name": "name",
            "value": "",
            "type": "string",
            "align": "left",
            "width": "small"
        }, 
        {   "id" : "",
            "name": "description",
            "value": "",
            "type": "string",
            "align": "left",
            "width": "large"
        },
        { 
            "id": "",
            "name": "checkbox",
            "type": "checkbox",
            "width": "check_box",
            "align": "center",
            "value": false
        },
    ]
]

export const RRCAddTableObject = {
    "name": "simpleTable",
    "isResize": false,
    "isFixedHead": false,
    "noRecordsMsg" : i18n.t('commons.noRecords'), 
    "tableHeadings": RRCAddTableHeader,
    "tableBodyData" : RRCAddTableBody
}


