import React from "react";
import { DragDropContext } from "react-beautiful-dnd";
import "./reportStyles.css";
import DroppableCard from "./DroppableCard";
import ReportFieldCard from "./ReportFieldCard";

const FieldSelectorCard = ({
  categoryList,
  onChangeOpenStatus,
  selectedColumns,
  selectedFilters,
  selectedGroups,
  setFilters,
  setGroups,
  setColumns,
}) => {
  // **************States****************
  // ************************************
  // ********Functions*******************
  const handleOnDragEnd = (result) => {
    const { destination, draggableId } = result;
    if (!destination) {
      return;
    }
    const selectedField = getDroppedField(draggableId);

    if (selectedField) {
      switch (destination.droppableId) {
        case "columnFields":
          setColumns(selectedField);
          break;
        case "groupFields":
          setGroups(selectedField);
          break;
        case "filterFields":
          setFilters(selectedField);
          break;
        default:
          break;
      }
    }
  };
  const getDroppedField = (fieldValue) => {
    const tableData = fieldValue.split("-");
    const droppedTable = categoryList.find(
      (item) => item.name === tableData[0]
    );
    return droppedTable.fields.find(
      (field) => field.field_name === tableData[1]
    );
  };

  // *************************************
  return (
    <DragDropContext onDragEnd={handleOnDragEnd}>
      <div className="rounded-box mt-4">
        <div className="container mt-4">
          <div className="row">
            <ReportFieldCard
              categoryList={categoryList}
              onChangeOpenStatus={onChangeOpenStatus}
            />

            <div className="col-4">
              <div className="row">
                <DroppableCard
                  className={""}
                  title={"Columns"}
                  droppableId={"columnFields"}
                  columnSize={"col-12"}
                  itemList={selectedColumns}
                  type={"column"}
                />
                <DroppableCard
                  className={"mt-4"}
                  title={"Groups"}
                  droppableId={"groupFields"}
                  itemList={selectedGroups}
                  type={"group"}
                  columnSize={"col-12"}
                />
              </div>
            </div>
            <DroppableCard
              droppableId={"filterFields"}
              columnSize={"col-4"}
              title={"Filters"}
              className={"mb-4"}
              itemList={selectedFilters}
              type={"filters"}
            />
          </div>
        </div>
      </div>
    </DragDropContext>
  );
};

export default FieldSelectorCard;
