import React, { useState, useContext } from "react";
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import { useHistory, Link } from 'react-router-dom';
import format from 'date-fns/format';
import SelectInput from "../commons/input/SelectInput";
import CalendarInput from "../commons/input/CalendarInput";
import { ROUTE_INTERFACE_GRID } from "../../utilities/staticConfigs";
import i18n from "../../utilities/i18n";
import service from './service'
import AsyncTypeInput from "../commons/input/AsyncTypeHead/AsyncTypeInput";
import { STATUS_OF_MESSAGE, TYPE_OF_MESSAGE } from "../../utilities/dictionaryConstants";
import LoadingContext from '../../container/loadingContext';
import { getStorage } from '../../utilities/browserStorage';

function SearchInterface() {
    const history = useHistory();
    const practicePK = getStorage('practice');

    const setShowLoadingBar = useContext(LoadingContext);
    const [type_of_message, setType_of_message] = useState('');
    const [status_of_message, setStatus_of_message] = useState('');
    const [transmitted_from, setTransmitted_from] = useState('');
    const [transmitted_to, setTransmitted_to] = useState('');
    const [claim_dos_from, setClaim_dos_from] = useState('');
    const [claim_dos_to, setClaim_dos_to] = useState('');
    const [date_of_appointment_from, setDate_of_appointment_from] = useState('')
    const [date_of_appointment_to, setDate_of_appointment_to] = useState('')

    const [patientDropdown, setPatientDropdown] = useState([]);

    const [Show_reprocessed_message, setShow_reprocessed_message] = useState(false);
    const [Show_fixed_errors, setShow_fixed_errors] = useState(false);

    function GotoInterface() {
        let query = setQueryParams();
        history.push(ROUTE_INTERFACE_GRID, {query: query});
    }

    function setQueryParams(){
        let query = '';
        if(type_of_message)
            query += '&type_of_message='+type_of_message;
        if(status_of_message)
            query += '&status_of_message='+status_of_message;
        if(transmitted_from)
            query += '&transmitted_from='+format(transmitted_from, 'yyyy-MM-dd');
        if(transmitted_to)
            query += '&transmitted_to='+format(transmitted_to, 'yyyy-MM-dd');
        if(claim_dos_from)
            query += '&claim_dos_from='+format(claim_dos_from, 'yyyy-MM-dd');
        if(claim_dos_to)
            query += '&claim_dos_to='+format(claim_dos_to, 'yyyy-MM-dd');
        if(date_of_appointment_from)
            query += '&date_of_appointment_from='+format(date_of_appointment_from, 'yyyy-MM-dd');
        if(date_of_appointment_to)
            query += '&date_of_appointment_to='+format(date_of_appointment_to, 'yyyy-MM-dd');
        return query;
    }

    const listPatientDropdown = (query) => {
        const result = service.GetListPatientDropdown(query);

        result.then((response) => {
            setPatientDropdown(response.data);
        })
    }

    function handleInterfaceTypeHead() {
        // else if (name === "claimNumber") setSelectedClaim(e[0].id);
    }

    function onHandleChange(e) {
        let name = e.target.name;
        let value = e.target.type === 'checkbox' ? e.target.checked : e.target.value;
        if (name === 'type_of_message') setType_of_message(value);
        if (name === 'status_of_message') setStatus_of_message(value);
        if (name === 'Show_reprocessed_message') setShow_reprocessed_message(value);
        if (name === 'Show_fixed_errors') setShow_fixed_errors(value);
    }

    function onHandleDate(value, name) {
        if (name === 'transmitted_from') setTransmitted_from(value);
        if (name === 'transmitted_to') setTransmitted_to(value);
        if (name === 'claim_dos_from') setClaim_dos_from(value);
        if (name === 'claim_dos_to') setClaim_dos_to(value);
        if (name === 'date_of_appointment_from') setDate_of_appointment_from(value);
        if (name === 'date_of_appointment_to') setDate_of_appointment_to(value);
    }

    function remShowExport() {
        document.getElementById("export-dropdown").classList.remove('show');
        document.getElementById("export-menu").classList.remove('show');
        document.getElementById("export-dropdown").classList.remove('active');
        document.getElementById("export-menu").classList.remove('active');
    }

    function onShowExport() {
        document.getElementById("export-dropdown").classList.toggle('show');
        document.getElementById("export-menu").classList.toggle('show');
    }

    var fileDownload = require('js-file-download');
    function onHandleCSVExport() {
        setShowLoadingBar(true);
        let query = '&export=true&export_type=csv';
        let queryParam = setQueryParams();
        if(queryParam)
            query += queryParam;
        const result = service.ListInterfaceData(null, null, practicePK, query);
        result.then(response => {
            fileDownload(response.data, response.headers['content-disposition']);
            setShowLoadingBar(false);
        });
    }

    function onHandleXLSExport() {
        setShowLoadingBar(true);
        let query = '&export=true&export_type=xls';
        let queryParam = setQueryParams();
        if(queryParam)
            query += queryParam;
        const result = service.ListInterfaceData(null, null, practicePK, query, 'xls');
        result.then(response => {
            fileDownload(response.data, response.headers['content-disposition']);
            setShowLoadingBar(false);
        });
    }

  return (
    <div className="col-md-12">
        <div className="box basic-info-padding">
            <div className="row">
                <div className="col-3">
                    <SelectInput data={TYPE_OF_MESSAGE} name={'type_of_message'} value={type_of_message} onValueChange={onHandleChange} label={i18n.t("interface.interfaceSearch.type_of_message")} />
                </div>
            </div>
            <div className="row">
                <div className="col-3">
                    <SelectInput data={STATUS_OF_MESSAGE} name={'status_of_message'} value={status_of_message} onValueChange={onHandleChange} label={i18n.t("interface.interfaceSearch.status_of_message")} />
                </div>
            </div>
            <div className="row">
                <div className="col-3 form-group">
                    <CalendarInput name="transmitted_from" selected={transmitted_from} onValueChange={(e)=> onHandleDate(e, 'transmitted_from')}  placeholder={i18n.t("interface.interfaceSearch.from")} label={i18n.t("interface.interfaceSearch.transmitted_date")} />
                </div>
                <div className="col-3 form-group padding-top15 pl-0">
                    <CalendarInput name="transmitted_to" selected={transmitted_to} onValueChange={(e)=> onHandleDate(e, 'transmitted_to')} placeholder={i18n.t("interface.interfaceSearch.to")} />
                </div>  
            </div>
            <div className="row">
                <div className="col-3 form-group">
                    <AsyncTypeInput
                    id="basic-typeahead-patientName"
                    name={'patientName'} 
                    minLength={3}
                    options={patientDropdown}
                    onSearch={listPatientDropdown}
                    labelKey="name"
                    onValueChange={(e)=>handleInterfaceTypeHead(e,"patientName")}
                    label={i18n.t("interface.interfaceSearch.patientName")} 
                    />
                </div>
            </div>
            <div className="row">
                <div className="col-3 form-group padding-top15">
                    <AsyncTypeInput
                    id="basic-typeahead-claimNumber"
                    name={'claimNumber'} 
                    minLength={1}
                    options={patientDropdown}
                    onSearch={listPatientDropdown}
                    labelKey="name"
                    onValueChange={(e)=>handleInterfaceTypeHead(e,"claimNumber")}
                    label={i18n.t("interface.interfaceSearch.claim_number")}
                    />
                </div>
            </div>
            <div className="row">
                <div className="col-3">
                    <CalendarInput name="claim_dos_from" selected={claim_dos_from} onValueChange={(e)=> onHandleDate(e, 'claim_dos_from')}  placeholder={i18n.t("interface.interfaceSearch.from")} label={i18n.t("interface.interfaceSearch.claim_date_of_service")} />
                </div>
                <div className="col-3 padding-top15 pl-0">
                    <CalendarInput name="claim_dos_to" selected={claim_dos_to} onValueChange={(e)=> onHandleDate(e, 'claim_dos_to')} placeholder={i18n.t("interface.interfaceSearch.to")} />
                </div>  
            </div>
            <div className="row">
                <div className="col-3">
                    <CalendarInput name="date_of_appointment_from" selected={date_of_appointment_from} onValueChange={(e)=> onHandleDate(e, 'date_of_appointment_from')}  placeholder={i18n.t("interface.interfaceSearch.from")} label={i18n.t("interface.interfaceSearch.date_of_appointment")} />
                </div>
                <div className="col-3 padding-top15 pl-0">
                    <CalendarInput name="date_of_appointment_to" selected={date_of_appointment_to} onValueChange={(e)=> onHandleDate(e, 'date_of_appointment_to')} placeholder={i18n.t("interface.interfaceSearch.to")} />
                </div>  
            </div>
            <div className="row">
                <div className="col">
                    <div className="row">
                        <div className="col max-width-3">
                            <div className="form-group">
                                <div className="custom-checkbox lh-0">
                                    <input type="checkbox" id={"Show_reprocessed_message"}
                                        checked={Show_reprocessed_message} 
                                        onChange={onHandleChange}
                                        name="Show_reprocessed_message" 
                                    />
                                    <label className="checkbox top-14" htmlFor={"Show_reprocessed_message"}></label>
                                </div>
                            </div>
                        </div>
                        <div className="col padding-top5 padding-left20">
                            <label className="padding-top5 margin-top-3px">{i18n.t("interface.interfaceSearch.Show_reprocessed_message")}</label>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col">
                    <div className="row">
                        <div className="col max-width-3">
                            <div className="form-group">
                                <div className="custom-checkbox lh-0">
                                    <input type="checkbox" id={"Show_fixed_errors"}
                                        checked={Show_fixed_errors} 
                                        onChange={onHandleChange}
                                        name="Show_fixed_errors" 
                                    />
                                    <label className="checkbox top-14" htmlFor={"Show_fixed_errors"}></label>
                                </div>
                            </div>
                        </div>
                        <div className="col padding-top5 padding-left20">
                            <label className="padding-top5 margin-top-3px">{i18n.t("interface.interfaceSearch.Show_fixed_errors")}</label>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row margin-bottom10">
                <div className="col-6 justify-right padding-top15 padding-bottom80">
                    <div className="mr-3">
                        <button type="button" onClick={GotoInterface} className="btn btn-primary-blue">{i18n.t("interface.interfaceSearch.search")}</button>
                    </div>

                    <div className="dropdown" id="export-dropdown">
                        <button className="header-btn border-none p-0 bg-white" type="button" data-toggle="dropdown" onBlur={remShowExport} onClick={onShowExport}>
                            <div className="margin-top-m2">
                                <div className="">
                                    <OverlayTrigger
                                        placement="top"
                                        delay={{ show: 250, hide: 400 }}
                                        overlay={<Tooltip id="download-tooltip" style={{
                                            opacity: 0.4,
                                        }}>{i18n.t('commons.export')}
                                        </Tooltip>}
                                    >
                                        <i className="las la-file-upload navBarIcons"></i>
                                    </OverlayTrigger>
                                </div>
                            </div>
                        </button>
                        <div className="">
                            <ul className="dropdown-menu patientList download-dropdown" id="export-menu">
                                <li className="download-dropdown-menu-item">
                                    <Link to="#" onMouseDown={()=>onHandleCSVExport()} className="text-dec-none" >{i18n.t('commons.exportCSV')}</Link>
                                </li>
                                <li className="download-dropdown-menu-item">
                                    <Link to="#" onMouseDown={()=>onHandleXLSExport()} className="text-dec-none">{i18n.t('commons.exportXLS')}</Link>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row margin-bottom10">&nbsp;</div>
        </div>
    </div>
  );
}

export default SearchInterface;
