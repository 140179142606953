import React, { useState, useEffect, useContext } from "react";
import { Form } from "react-bootstrap";
import { Link } from "react-router-dom";
import { format } from "date-fns";
import { AsyncTypeahead } from "react-bootstrap-typeahead";

import "./style.css";
import service from "./service";
import service1 from "../patientManagement/patients/service"

import CalendarInput from "../commons/input/CalendarInput";
import TextInput from "../commons/input/input";
import Pagination from "../commons/pagination";
import SelectInput from "../commons/input/SelectInput";
import TextAreaInput from "../commons/textarea/textarea";
import RightSidePanel from "../commons/RightSidePanel/RightSidePanel";

import PatientsAdvancedSearch from "../patientManagement/patients/PatientsAdvancedSearch";
import PatientDropDownSearch from "../patientManagement/patients/patientDropDownSearch";
import { Divider } from '@mui/material';
import i18n from "../../utilities/i18n";
import { checkPermission, CommonDateFormat, currencyFormat } from "../../utilities/commonUtilities";

import { getStorage, setStorage } from "../../utilities/browserStorage";
import {
  PAYEMNT_MODES,
  POST_PAYMENT_SECONDARY_TYPES,POST_PAYMENT_SECONDARY_SUB_TYPES,
  CREDIT_CARD_TYPES,
} from "../../utilities/dictionaryConstants";
import {
  PAGING_END_INDEX,
  DEFAULT_PAGING_SIZE,
  PAYEMNT_MODES_CREDITCARD_ID,
  ROUTE_VIEW_RECEIPTS,
  MAX_OPEN_TABS_CLAIMS,
  ROUTE_SEARCH_CLAIMS,
  PAYMENT_MODE_CASH_ID,
} from "../../utilities/staticConfigs";

import CustomizedDialogs from "../modalWindowComponent/CustomizedDialogs";
import CustomizedSmallDialogs from "../modalWindowComponent/CustomisedSmallDialog";
import LoadingContext from "../../container/loadingContext";
import CurrencyInputs from "../commons/input/CurrencyInput";
import Notify from "../commons/notify";
import { permission_key_values_claim, permission_key_values_payments } from "../../utilities/permissions";
import CommonButton from "../commons/Buttons";
import Label from "../commons/Label";

export const PatientPayments = (props) => {
  // props.setPaymentPK(1);

  const setShowLoadingBar = useContext(LoadingContext);
  const practicePK = getStorage('practice');
  const [responsibilityData, setResponsibilityData] = useState([]);
  // const [innerWidth, setInnerWidth] = useState(window.innerWidth);
  const [paymentDate, setpaymentDate] = useState("");
  const [paymentReference, setpaymentReference] = useState("");
  const [paymentAmount, setpaymentAmount] = useState("");
  const [paymentType, setpaymentType] = useState("");
  const [paymentSubType,setpaymentSubType]= useState("");
  const [paymentMode, setpaymentMode] = useState("");
  const [creditCard, setcreditCard] = useState("");
  const [paymentNote, setpaymentNote] = useState("");
  const [PrintReceiptonsaving, setPrintReceiptonsaving] = useState(false);
  const [radioValue, setRadioValue] = useState("");
  const [includeFamilyMemberBalances, setIncludeFamilyMemberBalances] =
    useState(false);
  const [showPreview, setShowPreview] = useState(false);
  const [patientName, setPatientName] = useState("");
  const [patientId, setPatientId] = useState("");
  const [unAppliedAmount, setUnAppliedAmount] = useState("");
  const [claimPK, setClaimPK] = useState("");

  const [selectPatient, setSelectPatient] = useState("");
  const [patientPK, setPatientPK] = useState("");
  const [patientSelected, setPatientSelected] = useState([]);

  const [postManually, setPostManually] = useState(false);
  const [showPostAsCreditModal, setShowPostAsCreditModal] = useState(false);
  const [header, setHeader] = useState("");
  const [autoPost, setAutoPost] = useState(false);
  const [tableRow, setTableRow] = useState([]);
  const [postType, setPostType] = useState();
  const [procedureData, setProcedureData] = useState({});
  const [flag, setFlag] = useState(false);
  const [sumApplied, setSumApplied] = useState(0);
  const [alreadyApplied, setAlreadyAplied] = useState(0);
  const [sumAdjustments, setSumAdjustments] = useState("");
  const [masterClaims, setMasterClaims] = useState([]);
  const [selectClaim, setSelectClaim] = useState("");
  const [patientAppliedProcedures, setPatientAppliedProcedures] = useState([]);
  const [showAppliedPayments, setShowAppliedPayments] = useState(false);
  const [showContinuePosting, setShowContinuePosting] = useState(false);
  const [paymentBalnceAmount, setPaymentBalnceAmount] = useState(0);
  const [claimSubStatusList, setClaimSubStatusList] = useState([]);
  const [claimStatusList, setClaimStatusList] = useState([]);

  const [showNotify, setShowNotify] = useState("hide");
  const [notifyDescription, setNotifyDescription] = useState("");
  const [notifyType, setNotifyType] = useState("success");
  const [showAlertModalWindow, setShowAlertModalWindow] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [selectCharge, setSelectCharge] = useState("");
  const [redirectToReceipt, setRedirectToReceipt] = useState(false);
  const DROP_CHARGES_DATA = [
    {
      id: "charges",
      name: i18n.t("payments.post_payments.chargesWithBalance"),
    },
    { id: "all", name: i18n.t("payments.post_payments.allCharges") },
  ];

  //Pagination start
  const [totalPage, setTotalPage] = useState(1);
  const [activePage, setActivePage] = useState(1);
  const [startIndex, setStartIndex] = useState(0);
  const [endIndex, setEndIndex] = useState(PAGING_END_INDEX);

  // edit payment modal variables
  const [showEditPaymentModal, setShowEditPaymentModal] = useState(false);
  const [editPaymentVariables, setEditPaymentVariables] = useState({
    payment_id: "",
    payment_date: "",
    payment_reference: "",
    credit_card: "",
    payment_amount: "",
    payment_type: "",
    payment_mode: "",
    patient_name: "",
    payment_note: "",
    secondary_payment_subtype:"",
  });
  const [selectedClaimVals, setSelectedClaimVals] = useState([]);
  const [viewFromLog, setViewFromLog] = useState(true);
  const [patientAdvSearchData, setPatientAdvSearchData] = useState([]);

  const [paymentPlanList, setPaymentPlanList] = useState([]);
  const [selectedPaymentPlan, setSelectedPaymentPlan] = useState();


  // useEffect(() => {
  //   let newWidth = "";
  //   newWidth = window.innerWidth;
  //   let menuWidth = document
  //     .getElementById("push-bar")
  //     .classList.contains("open");
  //   if (!menuWidth) {
  //     newWidth = newWidth + 250;
  //   } else {
  //     newWidth = window.innerWidth;
  //   }
  //   setInnerWidth(newWidth);
  // }, [window.innerWidth]);

  useEffect(() => {
    if (props.claimID && viewFromLog) {
      setViewFromLog(false);
      setSelectedClaimVals([props.selectedClaim]);
      onContinuePosting();
      // onHandleClaimIDChange([{'id': props.claimID}]);
    }
  }, [props.selectedClaim]);

  useEffect(() => {
    if (props.patientObject.id) {
      setPatientPK(props.patientObject.id);
      setSelectPatient(props.patientObject.name);
      setPatientSelected([props.patientObject]);
    }
  }, [props.patientObject]);

  function showNotifyWindow(action, type, desc, age = 3000) {
    if (action === "show") {
      setTimeout(() => {
        setShowNotify("hide");
      }, age);
    }
    setShowNotify(action);
    setNotifyType(type);
    setNotifyDescription(desc);
  }


  // edit payment modal functions
  const [patientPaymentData, setPatientPaymentData] = useState("");
  function getPaymentData() {
    const result = service.GetPaymentData(props.paymentPK);
    result.then((response) => {
      SetPaymentVariablesData(response.data);
      setPatientPaymentData(response.data);
      setPatientId(response?.data?.patient_id)
      if (response?.data?.patient_id) {
        checkForAlert(response?.data?.patient_id)
      }
    });
  }

  /**
   * checkfor payment alert
   * @param {*} patient_id 
   */
  function checkForAlert(patient_id) {
    setShowLoadingBar(true);
      let data = {
        patient_pk: patient_id,
        alert_type: 3, // Alert type of Patient
      };
    const result = service.CheckForAlert(data);
    result.then(response => {
      if (response?.data?.has_alert) {
        setShowAlertModalWindow(true);
        setAlertMessage(response?.data?.alert_message);
      }
      setShowLoadingBar(false);
    })
  }

  const getClaimStatusData = async (pageSize) => {
    try {
      let pageNum = 0;
      const response = await service.ListClaimStatus(pageSize, pageNum, practicePK, null, patientPK, props.claimPK);
      if (response && response?.data) {
        setClaimStatusList(response.data || []);
      }
    } catch (error) {
      console.error("Error while fetching claim status list:", error);    
    }
  }
  
  const getClaimSubStatusData = async (pageSize) => {
    try {
      let pageNum = 0;
      const response = await service.ListClaimStatusSubStatus(pageSize, pageNum, practicePK);
      if (response && response?.data){
        setClaimSubStatusList(response.data || []);
      }
    } catch (error) {
      console.error("Error while fetching claim sub-Status list:", error);    
    }
  }

  function SetPaymentVariablesData(data) {
    setPaymentBalnceAmount(data.balance_amount);
    setPrintReceiptonsaving(data.print_receipt);
    setEditPaymentVariables({
      custom_payment_id: data.custom_payment_id,
      payment_date: data.payment_date,
      reference_check_number: data.reference_check_number,
      card_type: data.card_type,
      amount: data.amount,
      secondary_payment_type: data.secondary_payment_type,
      secondary_payment_subtype:data.secondary_payment_subtype,
      payment_mode: data.payment_mode,
      patient_name: data.patient_name,
      note: data.note,
      secondary_payment_type_name: data.secondary_payment_type_name,
      payment_mode_name: data.payment_mode_name,
    });
  }


  const getPatientPaymentPlans = (id) => {
    setShowLoadingBar(true);
    service
      .getPatientPaymentPlans(id)
      .then((response) => {
        setShowLoadingBar(false);
        if (response.status === 200 || response.status === 201) {
          setPaymentPlanList(response.data.payment_plan);
        }
      })
      .catch(() => {
        setShowLoadingBar(false);
      });
  };

  useEffect(() => {
    if (patientPK)
      getPatientPaymentPlans(patientPK);
  }, [patientPK]);

  useEffect(() => {
    if (!props.updatePayment) {
      setShowPreview(true);
      setShowContinuePosting(true);
    } else {
      setShowPreview(false);
      setShowContinuePosting(false);
    }
    getClaimStatusData(0);
    getClaimSubStatusData(0);
  }, []);

  useEffect(() => {
    if (props.paymentPK) getPaymentData();
  }, [props.paymentPK]);

  function showEditModalWindow() {
    setHeader(i18n.t("payments.post_payments.editPayment"));
    setShowEditPaymentModal(true);
    getPaymentData();
  }
  function resetEditPaymentForm() {
    setHeader("");
    setShowEditPaymentModal(false);
  }
  function isValidDate(dateString) {
    const dateObject = new Date(dateString);
    return dateObject instanceof Date && !isNaN(dateObject);
  }

  function onSaveFormData(e) {
    e.preventDefault();
    if (!isValidDate(editPaymentVariables.payment_date)) {
      showNotifyWindow("show", "danger", i18n.t(
        "payments.post_payments.editPaymentModal.invalid_date"
      ));
      return;
    }
    const result = service.UpdatePostPayment(
      props.paymentPK,
      editPaymentVariables
    );
    result.then((response) => {
      SetPaymentVariablesData(response.data);
      setShowEditPaymentModal(false);
      setPatientPaymentData(response.data);
      getPaymentData()
    });
  }

  function onHandleChangeEditPaymentDate(e) {
    if (!e || e == "" || e == "Invalid Date") {
      setEditPaymentVariables({
        ...editPaymentVariables,
        payment_date: "",
      });
    } else {
      setEditPaymentVariables({
        ...editPaymentVariables,
        payment_date: format(e, "yyyy-MM-dd"),
      });
    }
  }
  function onHandleChangeEditPayment(e) {
    let name = e?.target?.name;
    let value = e.target.value;
    if (name && name === "amount") {
      value = String(e.target.value)
        .trim()
        .replace(/[^0-9.]/g, "");
    }
    setEditPaymentVariables({
      ...editPaymentVariables,
      [name]: value,
    });
    if (name == 'payment_mode') {
      setEditPaymentVariables({
        ...editPaymentVariables,
        ["amount"] : "",
        ["reference_check_number"] : "",
        [name] : value
      })
    }
  }

  function onPagePrevious() {
    let previousPage = startIndex + 1 - PAGING_END_INDEX;
    setActivePage(previousPage);
    if (startIndex !== 0) {
      setStartIndex(startIndex - PAGING_END_INDEX);
      setEndIndex(endIndex - PAGING_END_INDEX);
    }
    getClaimDetails(DEFAULT_PAGING_SIZE, previousPage, claimPK, selectCharge);
  }

  function onPageUp(e) {
    let page = Number(e.target.id);
    setActivePage(page);
    getClaimDetails(DEFAULT_PAGING_SIZE, page, claimPK, selectCharge);
  }

  function onPageNext() {
    let nextPage = startIndex + 1 + PAGING_END_INDEX;
    if (endIndex === totalPage || totalPage <= PAGING_END_INDEX) {
      setActivePage(nextPage);
      setStartIndex(startIndex);
      setStartIndex(endIndex);
    } else {
      setActivePage(nextPage);
      setStartIndex(startIndex + PAGING_END_INDEX);
      setEndIndex(endIndex + PAGING_END_INDEX);
    }
    getClaimDetails(DEFAULT_PAGING_SIZE, nextPage, claimPK, selectCharge);
  }
  //Pagination ends

  const [form_error, setFormError] = useState({
    patientId: "",
    paymentDate: "",
    paymentReference: "",
    paymentAmount: "",
    paymentType: "",
    paymentSubType:"",
    paymentMode: "",
  });

  useEffect(() => {
    if (selectPatient) {
      setPatientName(selectPatient.split("(")[0]);
    } else {
      setPatientName("");
    }
  }, [selectPatient]);


  function onHandleChange(e) {

    if (e.target) {
            let name = e.target.name;
      let value =
        e.target.type === "checkbox" ? e.target.checked : e.target.value;
      let trimmedValue = "";
      let inputFields = ["paymentType", "postPaymentType", "paymentReference", "paymentType", "paymentSubType","paymentMode", "creditCard", "paymentNote", "paymentAmount", "PrintReceiptonsaving", "includeFamilyMemberBalances", "showPreview", "selectedPaymentPlan", "selectCharge"];
      if (inputFields.includes(name)) {
        trimmedValue = value;
      }
      let mandatoryFields = [
        "patientId",
        "paymentDate",
        "paymentReference",
        "paymentAmount",
        "paymentType",
        "paymentMode",
      ];
      if (!("" + trimmedValue).trim() && mandatoryFields.includes(name)) {
        setFormError({
          ...form_error,
          [name]: "error",
        });
        setPostManually(false);
        setAutoPost(false);
      } else {
        setFormError({
          ...form_error,
          [name]: "",
        });
      }
      if (name === "paymentReference") setpaymentReference(trimmedValue);
      else if (name === "paymentType") setpaymentType(trimmedValue);
      else if (name === "paymentSubType") setpaymentSubType(trimmedValue);
      else if (name === "paymentMode") setpaymentMode(trimmedValue), setpaymentReference("");
      else if (name === "creditCard") setcreditCard(trimmedValue);
      else if (name === "paymentNote") setpaymentNote(trimmedValue);
      else if (name === "paymentAmount") {
        let newValue = trimmedValue.trim().replace(/[^0-9.]/g, "");
        setpaymentAmount(newValue);
      }
      else if (name === "PrintReceiptonsaving")
        setPrintReceiptonsaving(trimmedValue);
      else if (name === "includeFamilyMemberBalances")
        setIncludeFamilyMemberBalances(trimmedValue);
      else if (name === "showPreview") setShowPreview(trimmedValue);
      else if (name === "selectedPaymentPlan") setSelectedPaymentPlan(trimmedValue);
      else if (name === "selectCharge") {
        setSelectCharge(trimmedValue);
        getClaimDetails(DEFAULT_PAGING_SIZE, 1, claimPK, trimmedValue);
      }
    } else {
      if (e[0]) {
        setPatientName(e[0].name);
        setPatientId(e[0].id);
      } else {
        setPatientName(e);
        setPatientId("");
      }
    }
  }



  useEffect(() => {

    if (patientId) {
      const result = service.GetResponsibilityType(patientId);
      result.then((response) => {
        setResponsibilityData(response.data.responsibility_list)
      })
    }
  }, [patientId])




  function onHandleDate(value, name) {
    if (name === "paymentDate") setpaymentDate(value);
  }

  const sortByDate = async (sortOrder, obj) => {
    if (sortOrder === "balanceUnderPatientNewestFirst") {
      obj.sort((a, b) => new Date(b.dos_from) - new Date(a.dos_from));
    } else if (sortOrder === "balanceUnderPatientOldestFirst") {
      obj.sort((a, b) => new Date(a.dos_from) - new Date(b.dos_from));
    }
    return obj.map(item => item.id);
  }
  async function onHandleRadioButton(e) {
    if (unAppliedAmount > 0) { // do not execute auto-post when unApplied amount greater than 0
      let value = e.target.value;
      setRadioValue(value);
      // sort the claims depending on the query
      const result = await sortByDate(value, tableRow)
      let procedureToEdit = procedureData;
      let amountToApply = Number(patientPaymentData.amount);

      let sum = [];
      // applying the amount to the sorted claims
      result.forEach((item) => {
        let procedureOpeningBalance = Number(procedureToEdit[item].opening_balance);
        if (parseFloat(procedureOpeningBalance).toFixed(2) >= amountToApply) {

          procedureToEdit[item].apply_payment = parseFloat(amountToApply).toFixed(2);
          procedureToEdit[item].responsibility_type = 4;
          procedureToEdit[item].closing_balance = procedureToEdit[item].opening_balance - procedureToEdit[item].apply_payment;
          procedureToEdit[item].claim_status_id="";
          procedureToEdit[item].claim_sub_status_id="";
          amountToApply = 0;
          sum.push(procedureToEdit[item].apply_payment)
        } else if (procedureOpeningBalance < amountToApply) {

          procedureToEdit[item].apply_payment = parseFloat(procedureOpeningBalance).toFixed(2);
          procedureToEdit[item].responsibility_type = 5
          amountToApply = amountToApply - procedureOpeningBalance;
          procedureToEdit[item].closing_balance = 0.00;
          procedureToEdit[item].claim_status_id="";
          procedureToEdit[item].claim_sub_status_id="";
          sum.push(procedureToEdit[item].apply_payment)

        }
      });
      setProcedureData({ ...procedureToEdit });
      setSumApplied(sum.reduce((acc, curr) => Number(acc) + Number(curr), 0))
    } else {
      props.showNotifyWindow("show", "error", i18n.t("errorMessages.Invalid_balance"));
    }
  }



  const tableHeading = [
    i18n.t("payments.post_payments.table.claimId"),
    i18n.t("payments.post_payments.table.dos"),
    i18n.t("payments.post_payments.table.procedure"),
    i18n.t("payments.post_payments.table.charges"),
    i18n.t("payments.post_payments.table.receipts"),
    i18n.t("payments.post_payments.table.opening_balance"),
    i18n.t("payments.post_payments.table.apply_payment"),
    i18n.t("payments.post_payments.table.apply_adjustment"),
    i18n.t("payments.post_payments.table.closing_balance"),
    i18n.t("payments.post_payments.table.responsibility_type"),
    i18n.t("payments.post_payments.table.claim_status"),
    i18n.t("payments.post_payments.table.claim_sub_status"),

  ];

  const appliedTableHeading = [
    i18n.t("payments.post_payments.table.claimId"),
    i18n.t("payments.post_payments.table.dos"),
    i18n.t("payments.post_payments.table.procedure"),
    i18n.t("payments.post_payments.table.charges"),
    i18n.t("payments.post_payments.table.apply_payment"),
    i18n.t("payments.post_payments.table.apply_adjustment"),
    i18n.t("payments.post_payments.table.balance"),
    i18n.t("payments.post_payments.table.responsibility_type"),
  ];

  function CheckPaymentExists(post_type) {
    setShowLoadingBar(true);
    if ( paymentType == 2 &&  paymentSubType == '') {
      setShowLoadingBar(false);
      return;
    }
    if (parseFloat(paymentAmount) === 0) setpaymentAmount("");
    if (
      selectPatient &&
      paymentDate &&
      paymentAmount &&
      parseFloat(paymentAmount) > 0 &&
      paymentType &&
      paymentMode &&
      ((paymentMode === PAYEMNT_MODES_CREDITCARD_ID && creditCard) ||
        paymentMode !== PAYEMNT_MODES_CREDITCARD_ID)
    ) {
      if (props.paymentPK) changePostPaymentType(post_type);
      else {
        if (paymentMode !== 1 && paymentReference === "") {
          setShowLoadingBar(false);
          showNotifyWindow("show", "danger", i18n.t(
            "payments.post_payments.error.refrence_check_issue"
          ));
        } else {
          let data = {
            reference_check_number: paymentReference,
            amount: paymentAmount.replace(/,/g, ""),
            payment_date: paymentDate ? format(paymentDate, "yyyy-MM-dd") : "",
            payment_type: "patient",
            patient_id: patientPK
          };
          const result = service.CheckForPaymentExists(data);
          result.then((response) => {
            if (response.data.payment_exists) {
              setShowLoadingBar(false)
              setShowAlertModalWindow(true);
              setAlertMessage("Payment Already Exists");
            } else if (post_type == "credit") {
              //modal window
              setShowPostAsCreditModal(true);
              setHeader(i18n.t("payments.post_payments.postAsCreditModalHeader"));
              setShowLoadingBar(false)
            } else changePostPaymentType(post_type);
          });
        }
      }
    } else {
      setShowLoadingBar(false)
    }
  }

  function changePostPaymentType(post_type) {
    if (post_type === "manually") {
      if (!postManually) {
        //CHECK ALL MANDATORY FIELDS ARE FILLED
        setPostManually(!postManually);
        setAutoPost(false);
      } else {
        setPostManually(!postManually);
        setAutoPost(false);
      }
    } else {
      if (!autoPost) {
        //CHECK ALL MANDATORY FIELDS ARE FILLED
        setPostManually(false);
        setAutoPost(!autoPost);
      } else {
        setPostManually(false);
        setAutoPost(!autoPost);
      }
    }
  }

  function paymentPostManually() {
  if(paymentDate != ""){

  const patientDob = new Date(patientSelected[0]?.dob); 
    if(paymentDate >= patientDob)
    {
      CheckPaymentExists("manually");
    setPostType(1);
  }
  else
  {
    showNotifyWindow("show", "error", i18n.t("errorMessages.dobError"));
  }
}
}

  function paymentAutoPost() {
    if(paymentDate != ""){
    const patientDob = new Date(patientSelected[0]?.dob); 
    if(paymentDate >= patientDob)
    {
    CheckPaymentExists("auto");
    setPostType(2);
    }
    else
    {
      showNotifyWindow("show", "error", i18n.t("errorMessages.dobError"));
    }
  }
  }

  function paymentPostAsCredit() {
    if(paymentDate != ""){
    const patientDob = new Date(patientSelected[0]?.dob);
    if(paymentDate >= patientDob )
    {
    setPostManually(false);
    setAutoPost(false);
    CheckPaymentExists("credit");
    setPostType(3);
    }
    else
    {
      showNotifyWindow("show", "error", i18n.t("errorMessages.dobError"));
    }
  }
}

  function getClaimDetails(pageSize, page, claimID, chageType
    , includeFamilyMember
  ) {
    const result = service.ListClaimDetails(
      patientPK,
      pageSize,
      page,
      claimID,
      chageType,
      props.paymentPK,
      includeFamilyMember
    );
    result.then((response) => {
      if (response?.data?.results) {
        setTotalPage(Math.ceil(response.data.count / response.data.page_size));
      }
      if (response?.data?.results) setTableRow(response.data.results);
      let temp = JSON.parse(JSON.stringify(procedureData));
      response?.data?.results?.map((item) => {
        if (!(item.id in temp)) {
          temp[item.id] = {
            apply_payment: 0,
            apply_adjustments: 0,
            claim: item.claim_id,
            procedure: item.id,
            opening_balance: item.opening_balance,
            closing_balance: 0,
            responsibility_type: item.responsibility_type,
            claim_status_id: item.claim_status_id,
            claim_sub_status_id: item.claim_sub_status_id
          };
        }
      });
      setProcedureData(temp);
      setFlag(!flag);
    });
  }

 
  useEffect(() => {
    setUnAppliedAmount((prev) => {
      if (isNaN(paymentBalnceAmount - sumApplied)) {
        return prev;
      } else {
        return paymentBalnceAmount - sumApplied;
      }
    });

    setSumApplied((prev) => {
      if (isNaN(parseFloat(editPaymentVariables?.amount)-paymentBalnceAmount)) {
        return prev;
      } else {
        return parseFloat(editPaymentVariables?.amount) - paymentBalnceAmount;
      }
    });

    setAlreadyAplied((prev) => {
      if (isNaN(parseFloat(editPaymentVariables?.amount) - paymentBalnceAmount)) {
        return prev;
      } else {
        return parseFloat(editPaymentVariables?.amount) - paymentBalnceAmount;
      }
    });
  }, [editPaymentVariables]);

  useEffect(() => {
    let totApplied = 0;
    let totAdjustments = 0;
    for (let [, value] of Object.entries(procedureData)) {
      totApplied += parseFloat(value.apply_payment);
      totAdjustments += parseFloat(value.apply_adjustments);
    }
    setSumAdjustments(() => {
      if (isNaN(totAdjustments)) {
        return 0;
      } else {
        return totAdjustments;
      }
    });
    setUnAppliedAmount(() => {
      if (isNaN(totApplied)) {
        return paymentBalnceAmount - sumApplied;
      } else {
        return paymentBalnceAmount - totApplied;
      }
    });
  }, [flag]);

  useEffect(() => {
    if ((postManually || autoPost) && patientPK) {
      if (!props.paymentPK) onSavePayment();
      getClaimDetails(DEFAULT_PAGING_SIZE, 1, claimPK, selectCharge);
    }
  }, [postManually, autoPost]);

  function resetForm() {
    setHeader("");
    setShowPostAsCreditModal(false);
  }


  function onSavePayment() {
    let data = {
      patient: patientPK,
      payment_date: paymentDate ? format(paymentDate, "yyyy-MM-dd") : "",
      reference_check_number: paymentReference,
      amount: paymentAmount.replace(/,/g, ""),
      payment_mode: paymentMode,
      note: paymentNote,
      post_type: postType,
      payment_plan: selectedPaymentPlan,
      print_receipt: PrintReceiptonsaving,
      secondary_payment_type:paymentType,
      secondary_payment_subtype:(paymentType==1)?3:paymentSubType,
    };

    if (paymentMode === PAYEMNT_MODES_CREDITCARD_ID) data.card_type = creditCard;

    let result = null;
    result = service.SavePostPayment(data);
    result.then((response) => {
      if (postType === 3) {
        window.location.reload();
      }
      if (response.status === 200 || response.status === 201) {
        props.showNotifyWindow("show", "success", i18n.t("successMessages.payment_posted"));
        setTimeout(() => {
          props.setPaymentPK(response.data.payment_pk);
          props.setPatientPaymentPatientPK(response.data.patient_pk);
          setPatientPK(response.data.patient_pk);
          props.setPatientPaymentPostType(response.data.post_type);
        }, 500);
      } else if (response.status === 500) {
        props.showNotifyWindow("show", "error", i18n.t("errorMessages.payment_error"));
      }


    });
  }

  function onSavePaymentProcedures() {
    setShowLoadingBar(true);
    let procedures = [];
    for (var proc in procedureData) {
      const applyPaymentValue = procedureData[proc]["apply_payment"];
      if (applyPaymentValue !== 0 ||
        procedureData[proc]["apply_adjustments"] !== 0) {

        if (applyPaymentValue === "") {
          procedureData[proc]["apply_payment"] = "0.00";
        }
        procedures.push(procedureData[proc]);
      }
    }

    for(let procData of procedures){
      if (!procData.claim_status_id) {
        setShowLoadingBar(false);
        return;
      }

      if (procData.claim_status_id && (claimSubStatusList?.filter(item => item.parent_claim_status_ids?.includes(procData?.claim_status_id)).length > 0 ) && !procData.claim_sub_status_id ){
        showNotifyWindow("show", "error", "please select claim sub status before saving")
        setShowLoadingBar(false);
        return;
      }
    }

    if (procedures.length > 0) {
      if (unAppliedAmount < 0) {
        setShowAlertModalWindow(true);
        setAlertMessage(
          "Total applied amount should be less than or equal to total payment amount."
        );
        setShowLoadingBar(false);
      } else {
        let data = {
          payment: props.paymentPK,
          procedures: procedures,
        };
        const result = service.SavePatientPaymentProcedures(data);
        result.then((response) => {
          if (response?.status == 200) {
            props.showNotifyWindow("show", "success", "Payment Updated...");
            if (PrintReceiptonsaving) {
              paymentPKSetToStorage()
              setRedirectToReceipt(true)
            }
            setTimeout(() => {
              if (props.updatePayment) {
                props.setSelectedEditPaymentPK("");
                props.setActiveTab("viewPayments");
              } else window.location.reload();
            }, 500);
            setShowLoadingBar(false);
          } else {
            if (response.data?.message === 'invalid_responsibility_type') {
              showNotifyWindow("show", "error", `Claim status selected not matching with the Priority on Procedure ${response.data?.data?.cpt ?? ""}`);
            }
            if (response.data?.message === 'cpt_or_claim_status_missing') {
              showNotifyWindow("show", "error", `Procedure or the claim status is missing in a procedure row.`);
            }
            setShowLoadingBar(false);
          }
          
          setTimeout(() => {
            if (props.updatePayment) {
              props.setSelectedEditPaymentPK("");
              props.setActiveTab("viewPayments");
            } else window.location.reload();
          }, 500);
          setShowLoadingBar(false);
        });
      }
    } else {
      setShowAlertModalWindow(true);
      setAlertMessage("No data added to save");
      setShowLoadingBar(false);
    }
  }

  function onHandleApply(e, item, apply_type) {
    let temp = JSON.parse(JSON.stringify(procedureData));
    let responsibilityStatusFlag = false
    let value = String(e.target.value).trim()
    .replace(/[^0-9.]/g, '')  // Remove all non-numeric and non-period characters
    .replace(/(\..*?)\./g, '$1')  // Remove all extra decimal points
    .replace(/(\.\d{2})\d*/g, '$1')  // Allow only two digits after the first decimal point
    .substring(0, 9);  // Limit the length to 9 characters
      
    if (apply_type === "responsibility_type"){
      value = parseInt(value);
      responsibilityStatusFlag = true;
    }
    temp[item.id][apply_type] = value ?  value : 0;

    if (apply_type === "claim_status_id") {
      // Resetting  claim_sub_status_id to null whenever the claim_status changes.
      temp[item.id]["claim_sub_status_id"] = null; 
      value = parseInt(value);
      temp[item.id]["claim_status_id"] = value;
    }

    if (apply_type === "claim_sub_status_id") {
      value = parseInt(value);
      temp[item.id]["claim_sub_status_id"] = value;
    }

    if (
      isNaN(
        temp[item.id]["opening_balance"] -
        temp[item.id]["apply_payment"] -
        temp[item.id]["apply_adjustments"]
      )
    ) {
      setProcedureData(temp);
      setFlag(!flag);
    } else {
      let value1 = (parseFloat(temp[item.id]["apply_payment"] ? temp[item.id]["apply_payment"] : 0)) +
        parseFloat((temp[item.id]["apply_adjustments"] ? temp[item.id]["apply_adjustments"] : 0))
      temp[item.id]["closing_balance"] = (parseFloat(temp[item.id]["opening_balance"])) - value1
    }
    if (!['claim_status_id', 'claim_sub_status_id'].includes(apply_type)){
      if (parseFloat(temp[item.id]["closing_balance"]) <= 0) {
        temp[item.id]["responsibility_type"] = 5;
        responsibilityStatusFlag = true;
      } else
      temp[item.id]["responsibility_type"] = 4;
      responsibilityStatusFlag = true;
    }
    setProcedureData(temp);
    setFlag(!flag);
    let sumOfApplyPayment = 0;

    for (const key in temp) {
      const applyPayment = parseFloat(temp[key].apply_payment);
      if (!isNaN(applyPayment)) {
        sumOfApplyPayment += applyPayment;
      }
    }
    let applied=parseFloat(alreadyApplied)+parseFloat(sumOfApplyPayment)
    setSumApplied(applied)
    if (responsibilityStatusFlag) {
      temp[item.id]["claim_status_id"] = null;
      temp[item.id]["claim_sub_status_id"] = null;
    } else {
      responsibilityStatusFlag = false;
    }
  }


  function onResetPayment() {
    setPatientPK("");
    setPatientSelected([]);
    setPatientName("");
    setPatientId("");
    setpaymentDate("");
    setpaymentReference("");
    setpaymentAmount("");
    setpaymentType("");
    setpaymentMode("");
    setpaymentNote("");
    setSelectedPaymentPlan();
    setPatientAdvSearchData([]);
  }

  function onResetPaymentProcedure() {
    let temp = procedureData;
    for (var proc in temp) {
      temp[proc]["apply_payment"] = 0;
      temp[proc]["apply_adjustments"] = 0;
      temp[proc]["closing_balance"] = 0;
      temp[proc]["responsibility_type"] = 4;
    }
    setProcedureData(temp);
    setSumApplied("")
    setFlag(!flag);
  }

  function onSearchInsurances(query) {
    listInsuranceDropdown(query);
  }

  function listInsuranceDropdown(query) {
    getMasterClaims(query);
  }

  function getMasterClaims(query) {
    const result = service.ListClaimsDropDown(
      query,
      patientPK,
      getStorage("practice")
    );
    result.then((response) => {
      setMasterClaims(response.data);
    });
  }

  function onHandleClaimIDChange(e) {
    if (e[0]) {
      setSelectClaim(e[0].name);
      setClaimPK(e[0].id);
      getClaimDetails(DEFAULT_PAGING_SIZE, 1, e[0].id, selectCharge);
    } else {
      setSelectClaim("");
      setClaimPK("");
    }
  }

  function onAlertOk() {
    setShowAlertModalWindow(false);
  }

  function onSavePostAsCreidt(e) {
    e.preventDefault();
    onSavePayment();
  }

  const paymentPKSetToStorage = () => {
    if (props.paymentPK) setStorage("paymentPK", props.paymentPK);
  };

  useEffect(() => {
    if (props.paymentPK) {
      setPatientPK(props.patientPaymentPatientPK);
      if (props.patientPaymentPostType === 1) {
        setPostManually(true);
        setAutoPost(false);
      } else {
        setAutoPost(true);
        setPostManually(false);
      }
    }
  }, [props.paymentPK]);

  useEffect(() => {
    if (props.patientPaymentPostType === 1) {
      setPostManually(true);
      setAutoPost(false);
    } else if (props.patientPaymentPostType === 2) {
      setAutoPost(true);
      setPostManually(false);
    }
  }, [props.patientPaymentPostType]);

  function onViewAppliedPayments() {
    ListAppliedPayments();
    setShowAppliedPayments(true);
    setShowContinuePosting(false);
  }

  function onContinuePosting() {
    getClaimDetails(DEFAULT_PAGING_SIZE, 1, claimPK, selectCharge, includeFamilyMemberBalances);
    setSelectCharge("charges");
    setShowPreview(true);
    setShowContinuePosting(true);
    setShowAppliedPayments(false);
  }

  function ListAppliedPayments() {
    setShowPreview(true);
    const result = service.GetPatientAppliedPayments(props.paymentPK);
    result.then((response) => {
      setPatientAppliedProcedures(response.data);
    });
  }
  function onCancelProcedures() {
    onResetPayment();
    setPostManually(false);
    setAutoPost(false)
    if (props.setSelectedEditPaymentPK) {
      props.setSelectedEditPaymentPK("");
    }
    props.setPaymentPK("");
  }

  function claimRedirection(e, item) {
    if (!checkPermission(permission_key_values_claim.claims_search_claims_view)) {
      props.showNotifyWindow('show', 'error', i18n.t('errorMessages.permission_error'));
      return;
    }
    let openedPKs = false;
    const result = service1.GetSelectedTabs("claims", getStorage("practice"));
    result.then((response) => {
      openedPKs =
        response.data && response.data.opened_tab_pks
          ? response.data.opened_tab_pks
          : [];

      let allowedPk = ["search", "last10"];
      allowedPk.forEach((itemData) => {
        let index = openedPKs.indexOf(itemData);
        if (index !== -1) {
          openedPKs.splice(index, 1);
        }
      });
      if (
        openedPKs &&
        Array.isArray(openedPKs) &&
        openedPKs.length >= MAX_OPEN_TABS_CLAIMS &&
        !openedPKs.includes(item.claim_id)
      ) {
        props.showNotifyWindow(
          "show",
          "error",
          i18n.t("errorMessages.max_claim_tabs")
        );
      }
      else {
        let redirect_item = {
          pk: item.claim_id,
          claim_id: item.custom_claim_id,
          type: "claims",
          action: "add",
          practice_pk: getStorage("practice"),
        };
        const resultData = service1.AddRemoveSelectedTab(redirect_item);
        resultData.then((responseData) => {
          if (responseData.status === 200 || responseData.data === 201)
            window.open(ROUTE_SEARCH_CLAIMS, "_blank");
        });
      }
    });
  }

  function selectOnTab(e) {
    if (e.key === "Tab") {
      document.querySelector("a.dropdown-item.active").click();
    }
  }
  if (redirectToReceipt) {
    window.open(ROUTE_VIEW_RECEIPTS);
  }
  return (
    <React.Fragment>
      <Notify
        showNotify={showNotify}
        setShowNotify={setShowNotify}
        notifyDescription={notifyDescription}
        setNotifyType={setNotifyType}
        setNotifyDescription={setNotifyDescription}
        notifyType={notifyType}
      />
      <div className="col-md-12">
        <div className={props.updatePayment ? "postPayment" : ""}>
          <div className="">
            <Form autoComplete="off">
              {!props.paymentPK && (
                <div className="patientPayment">
                  <div className="row col pl-0 padding-top20 padding-bottom10">
                    <PatientDropDownSearch
                      setSelectPatient={setSelectPatient}
                      setPatientPK={setPatientPK}
                      patientSelected={patientSelected}
                      setPatientSelected={setPatientSelected}
                      required={true}
                      patientAdvSearchData={patientAdvSearchData}
                    ></PatientDropDownSearch>
                    <div className="col pl-0 margin-top25 margin-rightM20">
                      <div className="justify-right">
                        <RightSidePanel
                          title={i18n.t("commons.advancedSearch")}
                          onclickLabel={i18n.t("commons.advancedSearch")}
                        >
                          <PatientsAdvancedSearch
                            setSelectPatient={setSelectPatient}
                            setPatientPK={setPatientPK}
                            setPatientSelected={setPatientSelected}
                            setPatientAdvSearchData={setPatientAdvSearchData}
                          ></PatientsAdvancedSearch>
                        </RightSidePanel>
                      </div>
                    </div>
                  </div>
                  <div className="row margin-top20 margin-right10">
                    <div className="col-3">
                      <CalendarInput
                        name="paymentDate"
                        maxDate={new Date()}
                        selected={paymentDate}
                        onValueChange={(e) => onHandleDate(e, "paymentDate")}
                        label={i18n.t(
                          "payments.post_payments.deposit/payment_date"
                        )}
                        className={form_error.paymentDate ? "input-error" : ""}
                        required={true}
                      />
                    </div>
                    <div className="col-3">
                      <SelectInput
                        name="paymentMode"
                        data={PAYEMNT_MODES}
                        value={paymentMode}
                        onValueChange={onHandleChange}
                        label={i18n.t("payments.post_payments.payment_mode")}
                        className={form_error.paymentMode ? "input-error" : ""}
                        required={true}
                      />
                    </div>
                    <div className="col-3">
                      <SelectInput
                        name="paymentType"
                        data={POST_PAYMENT_SECONDARY_TYPES}
                        value={paymentType}
                        onValueChange={onHandleChange}
                        label={i18n.t("payments.post_payments.payment_type")}
                        className={form_error.paymentType ? "input-error" : ""}
                        required={true}
                      />
                    </div>
                    {paymentType == 2 && (
                    <div className="col-3">
                      <SelectInput
                        name="paymentSubType"
                        data={POST_PAYMENT_SECONDARY_SUB_TYPES}
                        value={paymentSubType}
                        onValueChange={onHandleChange}
                        label={i18n.t("payments.post_payments.payment_sub_type")}
                        className={form_error.paymentSubType ? "input-error" : ""}
                        required={true}
                      />
                    </div>)}
                    
                  </div>
                
                  <div className="row margin-right10">
                  <div className="col-3">
                      <CurrencyInputs
                        name="paymentAmount"
                        value={paymentAmount}
                        onValueChange={onHandleChange}
                        label={i18n.t(
                          "payments.post_payments.payment_amount"
                        )}
                        defaultClassName={
                          paymentAmount === "" ? "input-error" : ""
                        }
                        required={true}
                      />
                    </div>
                    {paymentMode !== PAYMENT_MODE_CASH_ID && (
                      <div className="col-3">
                        <div className="form-group">
                          <TextInput
                            name="paymentReference"
                            value={paymentReference}
                            onValueChange={onHandleChange}
                            label={(paymentMode === 2) ? i18n.t(
                              "payments.post_payments.payment_refrencecheck"
                            ) :
                              i18n.t(
                                "payments.post_payments.payment_reference")
                            }
                            className={
                              form_error.paymentReference ? "input-error" : ""
                            }
                            required={true}
                          />
                        </div>
                      </div>)}
                    {paymentPlanList && (
                      <div className={paymentMode == PAYMENT_MODE_CASH_ID ? "col-3" : "col-3"}>
                        <SelectInput
                          name="selectedPaymentPlan"
                          data={paymentPlanList}
                          value={selectedPaymentPlan}
                          onValueChange={onHandleChange}
                          label={"Payment Plan"}
                          className={form_error.paymentMode ? "input-error" : ""}
                        // required={true}
                        />
                      </div>)}
                    {paymentMode === PAYEMNT_MODES_CREDITCARD_ID && (
                      <div className="col-3">
                        <SelectInput
                          name="creditCard"
                          data={CREDIT_CARD_TYPES}
                          value={creditCard}
                          onValueChange={onHandleChange}
                          label={i18n.t("payments.post_payments.credit_card")}
                          showCreditCardIcons={true}
                          className={form_error.creditCard ? "input-error" : ""}
                          required={true}
                        />
                      </div>
                    )}
                  </div>
                  <div className="row">
                    <div className="col-4">
                      <TextAreaInput
                        name="paymentNote"
                        value={paymentNote}
                        onValueChange={onHandleChange}
                        rows={3}
                        label={i18n.t("payments.post_payments.payment_note")}
                      />
                    </div>
                    <div className="col-1 max-width-3 lh-1 margin-top85">
                      <div className="form-group">
                        <div className="custom-checkbox">
                          <input
                            type="checkbox"
                            id="PrintReceiptonsaving"
                            name="PrintReceiptonsaving"
                            checked={PrintReceiptonsaving}
                            onChange={(e) => onHandleChange(e)}
                          />
                          <label
                            className="checkbox"
                            htmlFor="PrintReceiptonsaving"
                          ></label>
                        </div>
                      </div>
                    </div>
                    <div className="col margin-top85">
                      <label className="margin-top-3-px">
                        {i18n.t("payments.post_payments.printReceiptOnSaving")}
                      </label>
                    </div>
                  </div>
                  <div className="row padding-top15 justify-right margin-right25">
                    {!props.paymentPK && (
                      <div className="padding-left15">
                        <CommonButton
                          variant="outlined"
                          onClick={onResetPayment}
                          label={i18n.t("payments.post_payments.buttons.reset")}
                        />
                      </div>
                    )}
                    <div className="padding-left15">
                      <CommonButton
                        variant="contained"
                        onClick={paymentPostAsCredit}
                        label={i18n.t("payments.post_payments.buttons.postAsCredit")}
                      />
                    </div>
                    <div className="padding-left15">
                      <CommonButton
                        variant="contained"
                        onClick={paymentPostManually}
                        label={i18n.t("payments.post_payments.buttons.postManually")}
                      />
                    </div>
                    <div className="padding-left15">
                      <CommonButton
                        variant="contained"
                        onClick={paymentAutoPost}
                        label={i18n.t("payments.post_payments.buttons.autoPost")}
                      />
                    </div>
                  </div>
                </div>
              )}
              {autoPost ? (
                <div>
                  <div className="row padding-top15 margin-left20">
                    <div className="col-5">
                      <div className="checkbox-wrapper">
                        <div className="custom-radio">
                          <input
                            type="radio"
                            id="radio1"
                            name="radio"
                            onChange={(e) => onHandleRadioButton(e)}
                            value="balanceUnderPatientOldestFirst"
                            checked={
                              radioValue === "balanceUnderPatientOldestFirst"
                                ? true
                                : false
                            }
                          />
                          <label className="radio" htmlFor="radio1"></label>
                          <label className="" htmlFor="radio1">
                            <div className="custom-radio padding-left-10">
                              {i18n.t(
                                "payments.post_payments.balanceUnderPatientOldestFirst"
                              )}
                            </div>
                          </label>
                        </div>
                      </div>
                      <div className="checkbox-wrapper">
                        <div className="custom-radio">
                          <input
                            type="radio"
                            id="radio3"
                            name="radio"
                            value="balanceUnderPatientNewestFirst"
                            onChange={(e) => onHandleRadioButton(e)}
                            checked={
                              radioValue === "balanceUnderPatientNewestFirst"
                                ? true
                                : false
                            }
                          />
                          <label className="radio" htmlFor="radio3"></label>
                          <label className="" htmlFor="radio3">
                            <div className="custom-radio padding-left-10">
                              {i18n.t(
                                "payments.post_payments.balanceUnderPatientNewestFirst"
                              )}
                            </div>
                          </label>
                        </div>
                      </div>

                    </div>
                    <div className="col-5">
                      {/* <div className="checkbox-wrapper">
                        <div className="custom-radio">
                          <input
                            type="radio"
                            id="radio5"
                            name="radio"
                            value="date"
                            onChange={(e) => onHandleRadioButton(e)}
                            checked={radioValue === "date" ? true : false}
                          />
                          <label className="radio" htmlFor="radio5"></label>
                          <label className="" htmlFor="radio5">
                            <div className="custom-radio padding-left-10">
                              {i18n.t(
                                "payments.post_payments.balanceUnderInsureOldestFirst"
                              )}
                            </div>
                          </label>
                        </div>
                      </div> */}
                      {/* <div className="checkbox-wrapper">
                        <div className="custom-radio">
                          <input
                            type="radio"
                            id="radio4"
                            name="radio"
                            value="balanceUnderInsureNewestFirst"
                            onChange={(e) => onHandleRadioButton(e)}
                            checked={
                              radioValue === "balanceUnderInsureNewestFirst"
                                ? true
                                : false
                            }
                          />
                          <label className="radio" htmlFor="radio4"></label>
                          <label className="" htmlFor="radio4">
                            <div className="custom-radio padding-left-10">
                              {i18n.t(
                                "payments.post_payments.balanceUnderInsureNewestFirst"
                              )}
                            </div>
                          </label>
                        </div>
                      </div> */}

                    </div>
                  </div>
                  <div className="row padding-top15 margin-left20">
                    <div className="col-1 max-width-3 lh-1">
                      <div className="form-group">
                        <div className="custom-checkbox">
                          <input
                            type="checkbox"
                            id="includeFamilyMemberBalances"
                            name="includeFamilyMemberBalances"
                            checked={includeFamilyMemberBalances}
                            onChange={(e) => onHandleChange(e)}
                          />
                          <label
                            className="checkbox"
                            htmlFor="includeFamilyMemberBalances"
                          ></label>
                        </div>
                      </div>
                    </div>
                    <div className="col">
                      <label className="margin-top-3px">
                        {i18n.t(
                          "payments.post_payments.includeFamilyMemberBalances"
                        )}
                      </label>
                    </div>
                  </div>
                  <div className="col justify-right editButton">
                    <CommonButton
                      variant="contained"
                      onClick={showEditModalWindow}
                      label={i18n.t("commons.edit")}
                    />
                  </div>
                </div>
              ) : (
                <div className="" style={{ paddingBottom: "1px" }}></div>
              )}
              {postManually ? (
                <div className={props.updatePayment ? "margin-left30 margin-right40" : "margin-left30 margin-right40 margin-top25"} >
                  <table className="postPaymentTable">
                    <tr>
                      <td>
                        <span className="paymentFormText">
                          {i18n.t("payments.post_payments.payment_from")}
                        </span>:
                      </td>
                      <td>
                        <span className="paymentFormValueText">
                          {patientPaymentData.patient_name}
                        </span>
                      </td>
                      <td>
                        <span className="paymentFormText margin-left50">
                          {i18n.t("payments.post_payments.payment_type")}
                        </span> :
                      </td>
                      <td>
                        <span className="paymentFormValueText">
                          {(patientPaymentData.secondary_payment_type ==1)?patientPaymentData.secondary_payment_type_name:patientPaymentData.secondary_payment_subtype_name}
                        </span>
                      </td>
                      <td style={{ textAlign: 'right' }}>
                        <span className="paymentFormText">
                          {i18n.t("payments.post_payments.payment_mode")}
                        </span> :
                      </td>
                      <td style={{ textAlign: 'right' }}>
                        <span className="paymentFormValueText">
                          {patientPaymentData.payment_mode_name}
                        </span>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <span className="paymentFormText">{i18n.t(
                          "payments.post_payments.deposit/payment_date"
                        )}</span> :
                      </td>
                      <td>
                        <span className="paymentFormValueText">
                          {patientPaymentData.payment_date
                            ? CommonDateFormat(patientPaymentData.payment_date)
                            : ""}
                        </span>
                      </td>
                      <td>
                        <span className="paymentFormText margin-left50">
                          {i18n.t("payments.post_payments.payment_amount")}
                        </span> :
                      </td>
                      <td>
                        <span className="paymentFormValueText">
                          {currencyFormat(patientPaymentData.amount)}
                        </span>
                      </td>
                      {patientPaymentData.payment_mode_name !== 'Cash' &&
                        <>
                          <td style={{ textAlign: 'right' }}>
                            <span className="paymentFormText">
                              {i18n.t("payments.post_payments.payment_reference")}
                            </span> :
                          </td>
                          <td style={{ textAlign: 'right' }}>
                            <span className="paymentFormValueText">
                              {patientPaymentData.reference_check_number}
                            </span>
                          </td>
                        </>
                      }
                    </tr>
                    <tr>
                      {patientPaymentData.payment_mode === PAYEMNT_MODES_CREDITCARD_ID && (
                        <>
                          <td>
                            <span className="paymentFormText">
                              {i18n.t("payments.post_payments.credit_card")}
                            </span> :
                          </td>
                          <td>
                            <span className="paymentFormValueText">
                              {patientPaymentData.card_type}
                            </span>
                          </td>
                        </>
                      )}

                    </tr>
                  </table>
                  <div className="justify-right">
                    {checkPermission(permission_key_values_payments.payments_view_payments_modify,
                      permission_key_values_payments.post_payments
                    ) &&
                      <CommonButton
                        variant="contained"
                        onClick={showEditModalWindow}
                        label={i18n.t("commons.edit")}
                      />}
                  </div>
                </div>
              ) : (
                ""
              )}
              {props.paymentPK && props.updatePayment && (
                <div className="row margin-top25 margin-left30">
                  <div>
                    <CommonButton
                      icon="eye"
                      onClick={onViewAppliedPayments}
                      variant="outlined"
                      label={i18n.t(
                        "payments.post_payments.buttons.viewAppliedPayments"
                      )}
                    />
                  </div>
                  <div className="margin-left20">
                    <CommonButton
                      variant="outlined"
                      onClick={onContinuePosting}
                      label={i18n.t("payments.post_payments.buttons.continuePosting")}
                    />
                  </div>
                  <div className="col-3">
                  </div>
                  <div className="col-3">
                    {patientPaymentData.payment_done ?
                      <Link
                        to={ROUTE_VIEW_RECEIPTS}
                        className="ml-1 mr-2 div-float-right"
                        target="_blank"
                        onClick={() => paymentPKSetToStorage()}
                      >
                        {i18n.t("payments.post_payments.viewReceipt")}
                      </Link> : ""
                    }
                  </div>
                </div>
              )}
              {postManually || (autoPost && showPreview) ? (
                <div>
                  {showAppliedPayments && (
                    <div className="padding-top20">
                      <table className="table">
                        <thead>
                          <tr>
                            {appliedTableHeading &&
                              appliedTableHeading.map((item, index) => {
                                return <th key={index}>{item}</th>;
                              })}
                          </tr>
                        </thead>
                        <tbody>
                          {(!patientAppliedProcedures ||
                            patientAppliedProcedures.length === 0) && (
                              <tr>
                                <td align="center" colSpan={8}>
                                  {i18n.t("commons.noRecords")}{" "}
                                </td>
                              </tr>
                            )}
                          {patientAppliedProcedures &&
                            patientAppliedProcedures.map((item, index) => {
                              return (
                                <tr key={index}>
                                  <td className="width-125px" align="center">
                                    <Link className="p-0" to="#" onClick={(e) => claimRedirection(e, item)}>
                                      {item.custom_claim_id}
                                    </Link>
                                  </td>
                                  <td className="width-125px" align="center">
                                    {item.service_from_date
                                      ? format(
                                        new Date(item.service_from_date),
                                        "MM/dd/yyyy"
                                      )
                                      : ""}
                                  </td>
                                  <td className="width-125px" align="center">
                                    {item.procedure}
                                  </td>
                                  <td className="small" align="right">
                                    {currencyFormat(item.charges)}
                                  </td>
                                  <td className="small" align="right">
                                    {currencyFormat(item.apply_payment)}
                                  </td>
                                  <td className="small" align="right">
                                    {currencyFormat(item.apply_adjustments)}
                                  </td>
                                  <td className="small" align="right">
                                    {currencyFormat(item.balance)}
                                  </td>
                                  <td align="center">
                                    {item.responsibility_type_name}
                                  </td>
                                </tr>
                              );
                            })}
                        </tbody>
                      </table>
                    </div>
                  )}
                  {showContinuePosting && (
                    <div>
                      <Divider style={{ color: '#000', marginTop: 20, marginBottom: 20 }} />
                      <div
                        className={
                          "row margin-left15 padding-top20"
                        }
                      >
                        <div className="col-3">
                          <Label label={i18n.t(
                            "payments.post_payments.table.claimId"
                          )} />
                          <AsyncTypeahead
                            id="basic-typeahead-single"
                            labelKey="name"
                            minLength={0}
                            // onChange={setPatientList}
                            options={masterClaims}
                            placeholder=""
                            onSearch={onSearchInsurances}
                            name="selectClaim"
                            onKeyDown={(e) => selectOnTab(e)}
                            value={selectClaim}
                            selected={
                              selectedClaimVals ? selectedClaimVals : ""
                            }
                            onChange={onHandleClaimIDChange}
                          />
                        </div>
                        <div className="col-3 pl-0">
                          <SelectInput
                            data={DROP_CHARGES_DATA}
                            selectOptionRemove={true}
                            defaultValues={"charges"}
                            onValueChange={onHandleChange}
                            name="selectCharge"
                            value={selectCharge}
                            label={i18n.t("payments.post_payments.charges")}
                          />
                        </div>
                        <div
                          className={
                            "padding-top10 padding-bottom20 margin-leftM7" +
                            (autoPost ? " col-6" : " col-8")
                          }
                        >
                          <Link
                            to="#"
                            style={{ textDecoration: "none", color: "#212529" }}
                          >
                            <span className="paymentFormText">{i18n.t("payments.post_payments.appliedAmount")}</span>:{" "}
                            <span className="paymentFormValueText margin-left8">{currencyFormat(sumApplied)}</span>
                          </Link>

                          <Link
                            to="#"
                            style={{ textDecoration: "none", color: "#212529" }}
                          >
                            <span className="paymentFormText ml-2">{i18n.t("payments.post_payments.unAppliedAmount")}</span>:{" "}
                            <span className="paymentFormValueText margin-left8">{currencyFormat(unAppliedAmount)}</span>
                          </Link>
                        </div>
                      </div>
                      <div
                        className=""
                        style={{
                          overflowX: "auto",
                          width: "100%",
                          maxHeight: "500px",
                          minHeight: "100px",
                        }}
                      >
                        <table
                          className="table fixed_head"
                          id={"overflow_auto"}
                        >
                          <thead>
                            <tr>
                              {tableHeading &&
                                tableHeading.map((item, index) => {
                                  return <th key={index}>{item}</th>;
                                })}
                            </tr>
                          </thead>
                          <tbody>
                            {tableRow.length === 0 && (
                              <td align="center" colSpan={10}>
                                {i18n.t("commons.noRecords")}
                              </td>
                            )}
                            {tableRow &&
                              tableRow.map((item, index) => {
                                return (
                                  <tr key={index}>
                                    <td className="col-1" align="center">
                                      <Link className="advancedSearch" to="#" onClick={(e) => claimRedirection(e, item)}>
                                        {item.custom_claim_id}
                                      </Link>
                                    </td>
                                    <td className="col-1" align="center">

                                      {item.dos_from
                                        ? CommonDateFormat(item.dos_from)
                                        : ""}
                                    </td>
                                    <td className="col-1" align="center">
                                      {item.procedure}
                                    </td>
                                    <td className="small" align="right">
                                      {currencyFormat(item.charges)}
                                    </td>
                                    <td className="small" align="right">
                                      {currencyFormat(item.receipts)}
                                    </td>
                                    <td className="small" align="right">
                                      {currencyFormat(item.opening_balance)}
                                    </td>
                                    <td className="col-1" align="right">
                                      <div className="row">
                                        <div className="padding-left15 padding-top5">
                                          {i18n.t("currency") + " "}
                                        </div>
                                        <div className="tableInput col pl-1">
                                          <input
                                            type="text"
                                            value={
                                              Object.prototype.hasOwnProperty.call(procedureData,
                                                item.id
                                              )
                                                ? procedureData[item.id]
                                                  .apply_payment
                                                : 1
                                            }
                                            onChange={(e) =>
                                              onHandleApply(
                                                e,
                                                item,
                                                "apply_payment"
                                              )
                                            }
                                            // disabled={unAppliedAmount <= 0}
                                          />
                                        </div>
                                      </div>
                                    </td>
                                    <td className="col-1" align="right">
                                      <div className="row">
                                        <div className="padding-left15 padding-top5">
                                          {i18n.t("currency") + " "}
                                        </div>
                                        <div className="tableInput col pl-1">
                                          <input
                                            type="text"
                                            value={
                                              Object.prototype.hasOwnProperty.call(procedureData,
                                                item.id
                                              )
                                                ? procedureData[item.id]
                                                  .apply_adjustments
                                                : 1
                                            }
                                            onChange={(e) =>
                                              onHandleApply(
                                                e,
                                                item,
                                                "apply_adjustments"
                                              )
                                            }
                                            // disabled={unAppliedAmount <= 0}
                                          />
                                        </div>
                                      </div>
                                    </td>
                                    <td className="col-1" align="right">
                                      {Object.prototype.hasOwnProperty.call(procedureData, item.id)
                                        ? currencyFormat(
                                          procedureData[item.id]
                                            .closing_balance
                                        )
                                        : currencyFormat(1)}
                                    </td>
                                    <td className="col">
                                      <div className="form-group_none pl-0">
                                        <SelectInput
                                          data={responsibilityData}
                                          value={
                                            Object.prototype.hasOwnProperty.call(procedureData,
                                              item.id
                                            )
                                              ? procedureData[item.id]
                                                .responsibility_type
                                              : 1
                                          }
                                          onValueChange={(e) =>
                                            onHandleApply(
                                              e,
                                              item,
                                              "responsibility_type"
                                            )
                                          }
                                          // disabled={unAppliedAmount <= 0}
                                        />
                                      </div>
                                    </td>
                                    <td className="col">
                                      <div className="form-group_none pl-0" style={{maxWidth:'120px', minWidth:'104px'}}>
                                        <SelectInput
                                          className={!procedureData[item.id]?.claim_status_id ? "input-error" : ''}
                                          data={claimStatusList?.filter(data => data.priorities?.includes(procedureData[item.id]?.responsibility_type))}
                                          name='claim_status_id'
                                          value={
                                            Object.prototype.hasOwnProperty.call(procedureData,item.id)
                                              ? procedureData[item.id].claim_status_id
                                              : ""
                                          }
                                          onValueChange={(e) =>
                                            onHandleApply(
                                              e,
                                              item,
                                              "claim_status_id"
                                            )
                                          }
                                        />
                                      </div>
                                    </td>
                                    <td className="col">
                                      <div className="form-group_none pl-0" style={{maxWidth:'120px', minWidth:'104px'}}>
                                        <SelectInput
                                          className={claimSubStatusList?.filter(subStatus =>
                                            subStatus.parent_claim_status_ids?.includes(procedureData[item.id]?.claim_status_id)
                                            ).length && !procedureData[item.id]?.claim_sub_status_id ? "input-error" : ''}
                                          data={
                                            procedureData[item.id]?.claim_status_id
                                              ? claimSubStatusList?.filter(subStatus =>
                                                subStatus.parent_claim_status_ids?.includes(procedureData[item.id]?.claim_status_id)
                                              )
                                              : []
                                          }
                                          name='claim_sub_status_id'
                                          value={
                                            Object.prototype.hasOwnProperty.call(procedureData, item.id)
                                              ? procedureData[item.id]?.claim_sub_status_id
                                              : ""
                                          }
                                          onValueChange={(e) =>
                                            onHandleApply(
                                              e,
                                              item,
                                              "claim_sub_status_id"
                                            )
                                          }
                                        />
                                      </div>
                                    </td>
                                  </tr>
                                );
                              })}
                            {tableRow && tableRow.length > 0 && (
                              <tr key={1}>
                                <td colSpan={6}></td>
                                <td align="right">
                                  {currencyFormat(sumApplied)}
                                </td>
                                <td align="right">
                                  {currencyFormat(sumAdjustments)}
                                </td>
                                <td colSpan={2}></td>
                              </tr>
                            )}
                          </tbody>
                        </table>
                      </div>
                      <Pagination
                        totalPage={totalPage}
                        activePage={activePage}
                        startIndex={startIndex}
                        endIndex={endIndex}
                        onPagePrevious={onPagePrevious}
                        onPageUp={onPageUp}
                        onPageNext={onPageNext}
                      />
                      {totalPage <= 1 && (
                        <div className="clear-b-scroll-sector">&nbsp;</div>
                      )}
                    </div>
                  )}
                </div>
              ) : (
                ""
              )}

              {(postManually || autoPost) && showContinuePosting ? (
                <div className="row div-float-right margin-right25">
                  <div className="div-float-right">
                    <button
                      type="button"
                      onClick={onResetPaymentProcedure}
                      // disabled={unAppliedAmount <= 0}
                      className="btn btn-common-height35 px-4 btn-secondary margin-right10"
                    >
                      {i18n.t("payments.post_payments.buttons.reset")}
                    </button>
                    <button
                      type="button"
                      onClick={onCancelProcedures}
                      className="btn btn-common-height35 px-4 btn-primary-blue margin-right10"
                    >
                      {i18n.t("payments.post_payments.buttons.cancel")}
                    </button>
                    {
                      checkPermission(permission_key_values_payments.payments_view_payments_modify,
                        permission_key_values_payments.post_payments) &&
                      <button
                          type="button"
                          onClick={onSavePaymentProcedures}
                          className="btn btn-common-height35 px-4 btn-primary-blue"
                        >
                          {i18n.t("payments.post_payments.buttons.save")}
                        </button>
                    }
                  </div>
                </div>
              ) : (
                ""
              )}

              <div className="">
                <CustomizedDialogs
                  header={header}
                  type="save"
                  showModal={showPostAsCreditModal}
                  setShowModalWindow={setShowPostAsCreditModal}
                  resetForm={resetForm}
                >
                  <Form
                    id="form_dataEntry"
                    autoComplete="off" onSavePostAsCreidt
                    onSubmit={(e) => onSavePostAsCreidt(e)}
                    encType="multipart/form-data"
                  >
                    Payment Has Been Posted To Patient - {patientName} <br />
                    <br />
                    Posted Credit - {currencyFormat(paymentAmount)}
                    <br />
                    Total Credits - {currencyFormat(150.0)}
                    <br />
                    Patient Due - {currencyFormat(150.0)}
                    <br />
                    Total Patient Balance - {currencyFormat(0.0)}
                  </Form>
                </CustomizedDialogs>
              </div>
              <div className="">
                <CustomizedDialogs
                  header={header}
                  type="save"
                  showModal={showEditPaymentModal}
                  setShowModalWindow={setShowEditPaymentModal}
                  resetForm={resetEditPaymentForm}
                >
                  <Form
                    id="form_dataEntry"
                    autoComplete="off"
                    onSubmit={(e) => onSaveFormData(e)}
                    encType="multipart/form-data"
                  >
                    <div className="">
                      <div className="">
                        <div className="row">
                          <div className="col-md-6">
                            <TextInput
                              type="text"
                              disabled={true}
                              name="custom_payment_id"
                              value={editPaymentVariables.custom_payment_id}
                              onValueChange={onHandleChangeEditPayment}
                              label={i18n.t(
                                "payments.post_payments.editPaymentModal.payment_id"
                              )}
                            />
                          </div>
                          <div className="col-md-6">
                            <TextInput
                              type="text"
                              disabled={true}
                              name="patient_name"
                              value={editPaymentVariables.patient_name}
                              onValueChange={onHandleChangeEditPayment}
                              label={i18n.t(
                                "payments.post_payments.editPaymentModal.payment_from"
                              )}
                            />
                          </div>
                        </div>
                        <div className="row">
                        <div className="col-md-6">
                            <div className="row">
                              <div className="col-md">
                            <SelectInput
                              data={POST_PAYMENT_SECONDARY_TYPES}
                              name="secondary_payment_type"
                              value={
                                editPaymentVariables.secondary_payment_type
                              }
                              onValueChange={onHandleChangeEditPayment}
                              label={i18n.t(
                                "payments.post_payments.editPaymentModal.payment_type"
                              )}
                              className={
                                editPaymentVariables.secondary_payment_type
                                  ? ""
                                  : "input-error"
                              }
                              required={true}
                            />
                          </div>
                          {editPaymentVariables.secondary_payment_type ===2
                               && (
                          <div className="col-md pl-0">
                          <SelectInput
                              data={POST_PAYMENT_SECONDARY_SUB_TYPES}
                              name="secondary_payment_subtype"
                              value={
                                editPaymentVariables.secondary_payment_subtype
                              }
                              onValueChange={onHandleChangeEditPayment}
                              label={i18n.t(
                                "payments.post_payments.payment_sub_type"
                              )}
                              className={
                                editPaymentVariables.secondary_payment_subtype
                                  ? ""
                                  : "input-error"
                              }
                              required={true}
                            />
                                  </div>)}
                        </div>
                        </div>
                          <div className="col-md-6">
                            <div className="row">
                              <div className="col-md">
                                <SelectInput
                                  data={PAYEMNT_MODES}
                                  name="payment_mode"
                                  value={editPaymentVariables.payment_mode}
                                  onValueChange={onHandleChangeEditPayment}
                                  label={i18n.t(
                                    "payments.post_payments.editPaymentModal.payment_mode"
                                  )}
                                  className={
                                    editPaymentVariables.payment_mode
                                      ? ""
                                      : "input-error"
                                  }
                                  required={true}
                                />
                              </div>
                              {editPaymentVariables.payment_mode ===
                                PAYEMNT_MODES_CREDITCARD_ID && (
                                  <div className="col-md pl-0">
                                    <SelectInput
                                      name="card_type"
                                      data={CREDIT_CARD_TYPES}
                                      value={editPaymentVariables.card_type}
                                      onValueChange={onHandleChangeEditPayment}
                                      label={i18n.t(
                                        "payments.post_payments.credit_card"
                                      )}
                                      showCreditCardIcons={true}
                                      className={
                                        editPaymentVariables.card_type
                                          ? ""
                                          : "input-error"
                                      }
                                      required={true}
                                    />
                                  </div>
                                )}
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-md-6">
                            <CalendarInput
                              name="payment_date"
                              selected={editPaymentVariables.payment_date}
                              onValueChange={onHandleChangeEditPaymentDate}
                              label={i18n.t(
                                "payments.post_payments.editPaymentModal.payment_date"
                              )}
                              className={
                                editPaymentVariables.payment_date
                                  ? ""
                                  : "input-error"
                              }
                              required={true}
                            />
                          </div>
                          <div className="col-6">
                                <TextInput
                                  type="text"
                                  name="amount"
                                  value={editPaymentVariables.amount}
                                  onValueChange={onHandleChangeEditPayment}
                                  label={i18n.t(
                                    "payments.post_payments.editPaymentModal.payment_amount"
                                  )}
                                  className={
                                    editPaymentVariables.amount
                                      ? ""
                                      : "input-error"
                                  }
                                  required={true}
                            />
                          </div>
                        </div>
                        <div className="row">
                          {editPaymentVariables.payment_mode != 1 &&
                            <div className="col-md-6">
                              <TextInput
                                type="text"
                                name="reference_check_number"
                                value={
                                  editPaymentVariables.reference_check_number
                                }
                                onValueChange={onHandleChangeEditPayment}
                                label={i18n.t(
                                  "payments.post_payments.editPaymentModal.payment_reference"
                                )}
                                className={
                                  editPaymentVariables.reference_check_number
                                    ? ""
                                    : "input-error"
                                }
                                required={true}
                              />
                            </div>
                          }
                        </div>
                        <div className="row">
                          <div className="col">
                            <TextAreaInput
                              name="note"
                              value={editPaymentVariables.note}
                              onValueChange={onHandleChangeEditPayment}
                              rows={3}
                              label={i18n.t(
                                "payments.post_payments.editPaymentModal.payment_note"
                              )}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </Form>
                </CustomizedDialogs>
              </div>
            </Form>
          </div>
        </div>
      </div>
      <CustomizedSmallDialogs
        showModal={showAlertModalWindow}
        header={i18n.t("commons.alert")}
        alertOK={onAlertOk}
        type="alert"
        setShowModalWindow={setShowAlertModalWindow}
      >
        {alertMessage}
      </CustomizedSmallDialogs>
    </React.Fragment>
  );
};
