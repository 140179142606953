import React from 'react';
import Pagination from '../../commons/pagination';
import { Form } from "react-bootstrap"
import i18n from "../../../utilities/i18n";
import TextInput from "../../commons/input/input"
import Table from "../../commons/Table/Table";


const InsuranceCompaniesFilter=(props)=>{
    return (
        <Form autoComplete='off'>
                    <div className=''>
                        <div className='font-bold'>{i18n.t('dictionariesPages.dictInsuranceCompanies_Add.searchFilters')}</div>
                        <div className='row'>
                            <div className='col-4'>
                        <TextInput id="input-box-name_contains" name="name_contains" value={props.searchFilterVariables.name_contains} onValueChange={props.onHandleSearchFilters} label={i18n.t('dictionariesPages.dictInsuranceCompanies_Add.name_contains')} />
                            </div>
                            <div className='col-4'>
                        <TextInput id="input-box-apms-payer-id" name="apms_payer_id" value={props.searchFilterVariables.apms_payer_id} onValueChange={props.onHandleSearchFilters} label={i18n.t('dictionariesPages.dictInsuranceCompanies_Add.apms_payer_id')} />
                            </div>
                            <div className='col-4'>
                        <TextInput id="input-box-payer-id" name="payer_id" value={props.searchFilterVariables.payer_id} onValueChange={props.onHandleSearchFilters} label={i18n.t('dictionariesPages.dictInsuranceCompanies_Add.payer_id')} />
                            </div>
                        </div>
                        <div className=''>
                            <div className="row">
                                <div className="col-1 max-width-3 lh-0">
                                    <div className="form-groupp">
                                        <div className="custom-checkbox margin-top3">
                                            <input type="checkbox" id="exact_match_only"
                                                name="exact_match_only" checked={props.searchFilterVariables.exact_match_only} onChange={props.onHandleSearchFilters}
                                            />
                                            <label className="checkbox" htmlFor="exact_match_only"></label>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-6">
                                    <label className="margin-top-3px">{i18n.t('dictionariesPages.dictInsuranceCompanies_Add.exact_match_only')}</label>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className=''>
                        <Table tableObject={props.InsAddTableData} onRowClick={props.onRowClick} />
                        <Pagination totalPage={props.masterTotalPage} activePage={props.masterActivePage} startIndex={props.masterStartIndex} endIndex={props.masterEndIndex} onPagePrevious={props.onMasterPagePrevious} 
                        onPageUp={props.onMasterPageUp} onPageNext={props.onMasterPageNext} />
                        <div className="clear-b-scroll-sector">&nbsp;</div>
                        <div className="clear-b-scroll-sector">&nbsp;</div>
                    </div>
                </Form>
    )
}

export default InsuranceCompaniesFilter;