import i18n from "../../../utilities/i18n";


const TableHeadData = [
    {
        "name": i18n.t("fileManagement.id"),
        "type": "number",
        "width": "extra-small",
        "sort": false,
        "hideValue": true,
    },
    {
        "name": i18n.t("fileManagement.Description"),
        "type": "string",
        "width": "extra-small",
        "sort": false,
        "hideValue": false,
    },
    {
        "name": i18n.t("fileManagement.Date"),
        "type": "number",
        "width": "extra-small",
        "sort": false,
         "hideValue": false,
    },
    {
        "name": i18n.t("fileManagement.User"),
        "type": "string",
        "width": "small",
        "sort": false,
         "hideValue": false,
    },
    {
        "name": i18n.t("fileManagement.FileName"),
        "type": "string",
        "width": "small",
        "sort": false,
         "hideValue": false,
    },
    {
        "name": i18n.t("dictionariesPages.action"),
        "type": "button",
        "width": "action_column",
        "sort": false
    },
  
 
];

export const TableBodyData = [
    [
        {
            "id": "",
            "name": "id",
            "value": "",
            "type": "number",
            "needLink": true,
            "align": "center",
            "width": "extra-small",
            "hideValue": true,
        },
        {
            "id": "",
            "name": "description",
            "value": "",
            "type": "string",
            "align": "center",
            "width": "extra-small",
            "needLink": false,
            "hideValue": false,
        },
        {
            "id": "",
            "name": "created_on",
            "value": "",
            "type": "Date",
            "needDateFormat": "yes",
            "align": "center",
            "width": "extra-small",
            "needLink": false,
            "hideValue": false,
        },
        {
            "id": "",
            "name": "created_by",
            "value": "",
            "type": "string",
            "align": "center",
            "width": "extra-small",
            "needLink": false,
            "hideValue": false,
        },
        {
            "id": "",
            "name": "file_name",
            "value": "",
            "type": "string",
            "align": "center",
            "width": "extra-small",
            "needLink": false,
            "hideValue": false,
        },
        
        { "id": "",
            "name": "action",
            "type": "button",
            "align": "center",
            "width": "action_column",
            "dropdown": [
                {
                    "id": '',
                    "name": "Delete"
                },
                {
                    "id": '',
                    "name": "Download"
                }
            ]
        },
       
        
    ]
]

export const FileUploadTableData = {
    "name": "sampleTable",
    "isResize": false,
    "isReportTable": true,
    "isFixedHead": true,
    "tableHeadings": TableHeadData,
    "tableBodyData": TableBodyData
}
