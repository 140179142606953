import api from "../../../service/api";

const GetListPolicyType = async (pageSize, page, searchValue) => {
    let path =
        "super-admin/policy-type/?page_size=" + pageSize + "&page=" + page;
    if (searchValue) {
        path += `&search=${searchValue}`;
    }
    return api.get(path);
};

const AddPolicyType = async (data) => {
    let path = "super-admin/policy-type/";
    return api.post(path, data);
};
const GetPolicyType = async (policyId) => {
    let path = "super-admin/policy-type/" + policyId;
    return api.get(path);
};
const DeletePolicyType = async (policyId) => {
    let path = "super-admin/policy-type/" + policyId;
    return api.destroy(path);
};

const UpdatePolicyType = async (policyId, data) => {
    let path = "super-admin/policy-type/" + policyId;
    return api.put(path, data);
};

export default {
    GetListPolicyType,
    AddPolicyType,
    GetPolicyType,
    DeletePolicyType,
    UpdatePolicyType,
};
