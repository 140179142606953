import api from '../../../service/api'

const ListDocumentTypes = async(pageSize, page, isAdminModule, practicePK, searchValue) =>{
	let path = null;
	if (isAdminModule === 'false')
		path = 'practice/document-type/?page_size='+ pageSize +'&page='+ page +'&practice_pk='+ practicePK;
	else
		path = 'super-admin/document-type/?page_size='+ pageSize +'&page='+ page;
		if (searchValue) {
			path += `&search=${searchValue}`;
		}
	return api.get(path);
}

const AddDocumentType = async(data) =>{
	let path = null;
	if (Object.prototype.hasOwnProperty.call(data, 'practice'))
		path = 'practice/document-type/'
	else
		path = 'super-admin/document-type/'
	return api.post(path,data);
}
const GetDocumentType = async(documentId, practicePK) =>{
	let path = null;
	if (practicePK)
		path = 'practice/document-type/' + documentId;
	else
		path = 'super-admin/document-type/' + documentId;
	return api.get(path);
}

const UpdateDocumentType = async(documentId, data) =>{
	let path = null;
	if (Object.prototype.hasOwnProperty.call(data, 'practice'))
		path = 'practice/document-type/' + documentId
	else
		path = 'super-admin/document-type/' + documentId
	return api.put(path,data);
}

const DeleteDocumentType = async(documentId, practicePK) =>{
	let path = null;
	if (practicePK)
		path = 'practice/document-type/' + documentId;
	else
		path = 'super-admin/document-type/' + documentId;
	return api.destroy(path);
}

export default {ListDocumentTypes, AddDocumentType, GetDocumentType, UpdateDocumentType, DeleteDocumentType}