import i18n from "../../../../utilities/i18n"


const TableHeadData = [
    {
        "name": i18n.t("patientPages.patients.labelPatientName"),
        "type": "string",
        "width": "medium",
        "sort": true,
        "initialOrdering": true,
        "dynamic": true,
        "hideValue": false,
    },
    {
        "name": i18n.t("patientPages.patients.labelLastName"),
        "type": "string",
        "width": "small",
        "sort": false,
        "dynamic": true,
        "hideValue": true,
    },
    {
        "name": i18n.t("patientPages.patients.labelFirstName"),
        "type": "string",
        "width": "small",
        "sort": false,
        "dynamic": true,
        "hideValue": true,
    },
    {
        "name": i18n.t("patientPages.patients.labelPatientID"),
        "type": "number",
        "width": "small",
        "sort": true,
        "initialOrdering": true,
        "dynamic": true,
        "hideValue": false,
    },
    {
        "name": i18n.t("patientPages.patients.labelGender"),
        "type": "string",
        "width": "small",
        "sort": false,
        "dynamic": true,
        "hideValue": true,
    },
    {
        "name": i18n.t("patientPages.patients.labelDOB"),
        "type": "string",
        "width": "small",
        "sort": true,
        "initialOrdering": true,
        "dynamic": true,
        "hideValue": false,
    },
    {
        "name": i18n.t("patientPages.patients.labelPatientType"),
        "type": "string",
        "width": "medium",
        "sort": false,
        "dynamic": true,
        "hideValue": false,
    },
    {
        "name": i18n.t("patientPages.patients.labelPatientAddress"),
        "type": "string",
        "width": "small",
        "sort": false,
        "dynamic": true,
        "hideValue": true,
    },
    {
        "name": i18n.t("patientPages.patients.labelPatientHomePhone"),
        "type": "string",
        "width": "small",
        "sort": false,
        "dynamic": true,
        "hideValue": true,
    },
    {
        "name": i18n.t("patientPages.patients.labelGuarantor"),
        "type": "string",
        "width": "small",
        "sort": false,
        "dynamic": true,
        "hideValue": true,
    },
    {
        "name": i18n.t("patientPages.patients.labelGuarantorRelationship"),
        "type": "string",
        "width": "small",
        "sort": false,
        "dynamic": true,
        "hideValue": true,
    },
    {
        "name": i18n.t("patientPages.patients.labelGuarantorAddress"),
        "type": "string",
        "width": "small",
        "sort": false,
        "dynamic": true,
        "hideValue": true,
    },
    {
        "name": i18n.t("patientPages.patients.labelGuarantorHomePhone"),
        "type": "string",
        "width": "small",
        "sort": false,
        "dynamic": true,
        "hideValue": true,
    },
    {
        "name": i18n.t("patientPages.patients.labelEmergency"),
        "type": "string",
        "width": "small",
        "sort": false,
        "dynamic": true,
        "hideValue": true,
    },
    {
        "name": i18n.t("patientPages.patients.labelEmergencyRelationship"),
        "type": "string",
        "width": "small",
        "sort": false,
        "dynamic": true,
        "hideValue": true,
    },
    {
        "name": i18n.t("patientPages.patients.labelEmergencyAddress"),
        "type": "string",
        "width": "small",
        "sort": false,
        "dynamic": true,
        "hideValue": true,
    },
    {
        "name": i18n.t("patientPages.patients.labelEmergencyHomePhone"),
        "type": "string",
        "width": "small",
        "sort": false,
        "dynamic": true,
        "hideValue": true,
    },
    {
        "name": i18n.t("patientPages.patients.labelPrimaryInsuranceName"),
        "type": "string",
        "width": "small",
        "sort": false,
        "dynamic": true,
        "hideValue": true,
    },
    {
        "name": i18n.t("patientPages.patients.labelPrimaryInsurancePolicyID"),
        "type": "string",
        "width": "small",
        "sort": false,
        "dynamic": true,
        "hideValue": true,
    },
    {
        "name": i18n.t("patientPages.patients.labelPrimaryEffectiveDate"),
        "type": "string",
        "width": "small",
        "sort": false,
        "dynamic": true,
        "hideValue": true,
    },
    {
        "name": i18n.t("patientPages.patients.labelPSubscriberRelationship"),
        "type": "string",
        "width": "small",
        "sort": false,
        "dynamic": true,
        "hideValue": true,
    },
    {
        "name": i18n.t("patientPages.patients.labelPSubscriberName"),
        "type": "string",
        "width": "small",
        "sort": false,
        "dynamic": true,
        "hideValue": true,
    },
    {
        "name": i18n.t("patientPages.patients.labelPSubscriberGender"),
        "type": "string",
        "width": "small",
        "sort": false,
        "dynamic": true,
        "hideValue": true,
    },
    {
        "name": i18n.t("patientPages.patients.labelPSubscriberDOB"),
        "type": "string",
        "width": "small",
        "sort": false,
        "dynamic": true,
        "hideValue": true,
    },
    {
        "name": i18n.t("patientPages.patients.labelPSubscriberAddress"),
        "type": "string",
        "width": "small",
        "sort": false,
        "dynamic": true,
        "hideValue": true,
    },
    {
        "name": i18n.t("patientPages.patients.labelAction"),
        "type": "button",
        "width": "action_column",
        "sort": false,
        "dynamic": true,
        "hideValue": false,
    },
]

export const TableBodyData = [
    [
        {
            "id": "",
            "name": "name",
            "value": "",
            "type": "string",
            "align": "left",
            "width": "medium",
            "needLink": true,
            "dynamic": true,
            "hideValue": false,
        },
        {
            "id": "",
            "name": "last_name",
            "value": "",
            "type": "string",
            "align": "left",
            "width": "medium",
            "dynamic": true,
            "hideValue": true,
        },
        {
            "id": "",
            "name": "first_name",
            "value": "",
            "type": "string",
            "align": "left",
            "width": "medium",
            "dynamic": true,
            "hideValue": true,
        },
        {
            "id": "",
            "name": "custom_patient_id",
            "value": "",
            "type": "number",
            "align": "center",
            "width": "small",
            "dynamic": true,
            "hideValue": false,
        },
        {
            "id": "",
            "name": "gender",
            "value": "",
            "type": "string",
            "align": "left",
            "width": "small",
            "dynamic": true,
            "hideValue": true,
        },
        {
            "id": "",
            "name": "date_of_birth",
            "value": "",
            "type": "Date",
            "needDateFormat": "yes",
            "align": "center",
            "width": "small",
            "dynamic": true,
            "hideValue": false,
        },
        {
            "id": "",
            "name": "patient_type",
            "value": "",
            "type": "string",
            "align": "center",
            "width": "medium",
            "dynamic": true,
            "hideValue": false,
        },
        {
            "id": "",
            "name": "address",
            "value": "",
            "type": "string",
            "align": "left",
            "width": "small",
            "dynamic": true,
            "hideValue": true,
        },
        {
            "id": "",
            "name": "home_phone",
            "value": "",
            "type": "string",
            "align": "left",
            "width": "small",
            "dynamic": true,
            "hideValue": true,
        },
        {
            "id": "",
            "name": "guarantor_name",
            "value": "",
            "type": "string",
            "align": "left",
            "width": "small",
            "dynamic": true,
            "hideValue": true,
        },
        {
            "id": "",
            "name": "guarantor_relationship",
            "value": "",
            "type": "string",
            "align": "left",
            "width": "small",
            "dynamic": true,
            "hideValue": true,
        },
        {
            "id": "",
            "name": "guarantor_address",
            "value": "",
            "type": "string",
            "align": "left",
            "width": "small",
            "dynamic": true,
            "hideValue": true,
        },
        {
            "id": "",
            "name": "guarantor_home_phone",
            "value": "",
            "type": "string",
            "align": "left",
            "width": "small",
            "dynamic": true,
            "hideValue": true,
        },
        {
            "id": "",
            "name": "emergency_name",
            "value": "",
            "type": "string",
            "align": "left",
            "width": "small",
            "dynamic": true,
            "hideValue": true,
        },
        {
            "id": "",
            "name": "emergency_relationship",
            "value": "",
            "type": "string",
            "align": "left",
            "width": "small",
            "dynamic": true,
            "hideValue": true,
        },
        {
            "id": "",
            "name": "emergency_address",
            "value": "",
            "type": "string",
            "align": "left",
            "width": "small",
            "dynamic": true,
            "hideValue": true,
        },
        {
            "id": "",
            "name": "emergency_home_phone",
            "value": "",
            "type": "string",
            "align": "left",
            "width": "small",
            "dynamic": true,
            "hideValue": true,
        },
        {
            "id": "",
            "name": "p_insurance_name",
            "value": "",
            "type": "string",
            "align": "left",
            "width": "small",
            "dynamic": true,
            "hideValue": true,
        },
        {
            "id": "",
            "name": "p_insurance_policy_id",
            "value": "",
            "type": "string",
            "align": "left",
            "width": "small",
            "dynamic": true,
            "hideValue": true,
        },
        {
            "id": "",
            "name": "p_effective_date",
            "value": "",
            "type": "string",
            "align": "left",
            "width": "small",
            "dynamic": true,
            "hideValue": true,
        },
        {
            "id": "",
            "name": "p_subscriber_relationship",
            "value": "",
            "type": "string",
            "align": "left",
            "width": "small",
            "dynamic": true,
            "hideValue": true,
        },
        {
            "id": "",
            "name": "p_subscriber_name",
            "value": "",
            "type": "string",
            "align": "left",
            "width": "small",
            "dynamic": true,
            "hideValue": true,
        },
        {
            "id": "",
            "name": "p_subscriber_gender",
            "value": "",
            "type": "string",
            "align": "left",
            "width": "small",
            "dynamic": true,
            "hideValue": true,
        },
        {
            "id": "",
            "name": "p_subscriber_dob",
            "value": "",
            "type": "string",
            "align": "left",
            "width": "small",
            "dynamic": true,
            "hideValue": true,
        },
        {
            "id": "",
            "name": "p_subscriber_address",
            "value": "",
            "type": "string",
            "align": "left",
            "width": "small",
            "dynamic": true,
            "hideValue": true,
        },
        {
            "id": "",
            "name": "action",
            "value": "",
            "type": "button",
            "align": "center",
            "width": "action_column",
            "dynamic": true,
            "hideValue": false,
            "dropdown": [
                {
                    "id": '',
                    "name": "Edit"
                }
            ]
        },
    ]
]

export const Last10TableData = {
    "name": "dynamicTable",
    "isResize": false,
    "isFixedHead": true,
    "tableHeadings": TableHeadData,
    "tableBodyData": TableBodyData
}
export const AdvSearchTableData = {
    "name": "dynamicTable",
    "isResize": false,
    "isFixedHead": false,
    "tableHeadings": TableHeadData,
    "tableBodyData": TableBodyData
}

