import api from '../../../service/api'
import { NPI_SEARCH_URL,NPI_SEARCH_PATH, NPI_SEARCH_INDIVIDUAL_CODE, NPI_SEARCH_ORGANIZATION_CODE } from '../../../utilities/staticConfigs'; 
import { PROVIDER_TYPE_INDIVIDUAL, PROVIDER_TYPE_ORGANIZATION } from "../../../utilities/dictionaryConstants";


// e.g. https://npiregistry.cms.hhs.gov/api/?number=&enumeration_type=NPI-1&taxonomy_description=&first_name=patel*&last_name=&organization_name=&address_purpose=&city=&state=&postal_code=&country_code=&limit=&skip=&version=2.0
// NPI-1:Individual, NPI-2:Organization 
const callNPISearchAPI = async(searchObj) => {
	let path = NPI_SEARCH_PATH; 
	let urlString = NPI_SEARCH_URL; 
	if(searchObj.providerType===PROVIDER_TYPE_INDIVIDUAL) {
		urlString += '&enumeration_type='+NPI_SEARCH_INDIVIDUAL_CODE+'&last_name='+searchObj.lastName+'&first_name='+searchObj.firstName;
	}
	else if(searchObj.providerType===PROVIDER_TYPE_ORGANIZATION) {
		urlString += '&enumeration_type='+NPI_SEARCH_ORGANIZATION_CODE+'&organization_name='+searchObj.lastName;
	}
	else  if(searchObj.lastName && searchObj.lastName.trim().length > 0) {
		urlString += '&organization_name='+searchObj.lastName;
	}
	if(searchObj.city && searchObj.city.trim().length > 0) urlString += '&city='+searchObj.city;
	if (searchObj.state && searchObj.state.trim().length > 0) urlString += '&state=' + searchObj.state; 
	if (searchObj.number && searchObj.number.length > 0) urlString += '&number=' + searchObj.number; 
	let urlData = {"url":urlString}; 
	return api.post(path,urlData); 
}

export default {
	callNPISearchAPI 
}