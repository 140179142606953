import api from '../../../service/api'; 

const GetPOSCodesList = async(pageSize, page, searchValue, orderingField, orderType, claimPK) =>{
	let path = 'super-admin/service-codes/?page_size='+pageSize+'&page='+page;
	if (searchValue)
        path += '&search='+ searchValue;     
    if(orderingField){
        let fieldOrder = '';
        if (orderType) fieldOrder+= orderType;
        fieldOrder += orderingField;
        path += '&column='+fieldOrder;   
    } else {
        path += '&column=name';
    }
    if (claimPK)
		path += '&opened_claim_pk=' + claimPK;
	return api.get(path);
}

const AddPOSCode = async(data) =>{
	let path = 'super-admin/service-codes/'
	return api.post(path,data);
}
const GetPOSCode= async(payerId) =>{
	let path = 'super-admin/service-codes/'+payerId
	return api.get(path);
}
const DeletePOSCode= async(payerId) =>{
	let path = 'super-admin/service-codes/'+payerId
	return api.destroy(path);
}

const UpdatePOSCode= async(payerId,data) =>{
	let path = 'super-admin/service-codes/'+payerId
	return api.put(path,data);
}

const ImportCSVData = async(data) =>{
	let path = 'super-admin/import-service-code/'
	return api.post(path,data);
}

export default {GetPOSCodesList,AddPOSCode,GetPOSCode,DeletePOSCode,UpdatePOSCode,ImportCSVData};