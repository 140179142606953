import api from '../../../service/api';

const ListRelations = async(pageSize, page, claimPK, searchValue) =>{
	let path = 'super-admin/relationship/?page_size='+ pageSize +'&page='+ page;
	if (claimPK)
		path += '&opened_claim_pk='+ claimPK;
	if (searchValue) {
        path += `&search=${searchValue}`;
    }
	return api.get(path);
}

const AddRelation = async(data) =>{
	let path = 'super-admin/relationship/'
	return api.post(path,data);
}
const GetRelation = async(relationId) =>{
	let path = 'super-admin/relationship/' + relationId
	return api.get(path);
}

const UpdateRelation = async(relationId, data) =>{
	let path = 'super-admin/relationship/' + relationId
	return api.put(path,data);
}

const DeleteRelation = async(relationId) =>{
	let path = 'super-admin/relationship/' + relationId
	return api.destroy(path);
}

export default {ListRelations, AddRelation, GetRelation, UpdateRelation, DeleteRelation}