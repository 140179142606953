import React, { useState, useEffect, useContext } from 'react';
import { Form } from 'react-bootstrap';
import actionImg from '../../../assets/images/action.png';
import CustomizedDialogs from '../../modalWindowComponent/CustomizedDialogs';
import service from './service';
import { Link } from 'react-router-dom';
import { ROUTE_SUPER_DICTIONARIES, ROUTE_PRACTICE_DICTIONARIES, PAGING_END_INDEX, DEFAULT_PAGING_SIZE } from '../../../utilities/staticConfigs';
import { ADD_SUCCESS, ADD_ERROR, UPDATE_SUCCESS, DELETE_SUCCESS } from '../../../utilities/labelConfigs';
import Notify from '../../commons/notify';
import Pagination from '../../commons/pagination';
import LoadingContext from '../../../container/loadingContext'
import i18n from '../../../utilities/i18n';
import TextInput from '../../commons/input/input';
import { getStorage } from '../../../utilities/browserStorage';
import CustomizedSmallDialogs from '../../modalWindowComponent/CustomisedSmallDialog';

const PatientTypesList = (props) => {
    const isAdminModule = getStorage('isAdminModule');
    const practicePK = getStorage("practice");
    const [showModalWindow, setShowModalWindow] = useState(false);
    const [showDeleteModalWindow, setShowDeleteModalWindow] = useState(false);
    const [modalHeader] = useState(i18n.t("dictionariesPages.dictPatientType.addHeader"));
    const [patientType, setPatientType] = useState('');
    const [deletePatientName, setDeletePatientName] = useState('');
    const [deletePatientId, setDeletePatientId] = useState('');
    const [editPatientId, setEditPatientId] = useState('');
    const [patientTypeList, setPatientTypeList] = useState([]);
    const [form_error, setFormError] = useState({ 'PatientType': '' });
    const [editForm, setEditForm] = useState(false);
    const [activeRowId, setActiveRowId] = useState(0);

    //start ==== Alert message properties
    const [showNotify, setShowNotify] = useState('hide');
    const [notifyDescription, setNotifyDescription] = useState('');
    const [notifyType, setNotifyType] = useState('success');
    const setShowLoadingBar = useContext(LoadingContext);
    const [initialTableData, setInitialTableData] = useState(i18n.t("commons.loading"))

    function showNotifyWindow(action, type, desc, age = 3000) {
        if (action == 'show') {
            setTimeout(() => {
                setShowNotify('hide');
            }, age)
        }
        setShowNotify(action);
        setNotifyType(type);
        setNotifyDescription(desc);
    }
    //End ====

    //Pagination start
    const [totalPage, setTotalPage] = useState(1);
    const [activePage, setActivePage] = useState(1);
    const [startIndex, setStartIndex] = useState(0);
    const [endIndex, setEndIndex] = useState(PAGING_END_INDEX);

    function onPagePrevious() {
        let previousPage = startIndex + 1 - PAGING_END_INDEX;
        setActivePage(previousPage);
        if (startIndex !== 0) {
            setStartIndex(startIndex - PAGING_END_INDEX);
            setEndIndex(endIndex - PAGING_END_INDEX);
        }
        getPatientData(DEFAULT_PAGING_SIZE, previousPage);
    }

    function onPageUp(e) {
        let page = Number(e.target.id)
        setActivePage(page);
        getPatientData(DEFAULT_PAGING_SIZE, page);
    }

    function onPageNext() {
        let nextPage = startIndex + 1 + PAGING_END_INDEX;
        if (endIndex === totalPage || totalPage <= PAGING_END_INDEX) {
            setActivePage(nextPage);
            setStartIndex(startIndex);
            setStartIndex(endIndex);

        } else {
            setActivePage(nextPage);
            setStartIndex(startIndex + PAGING_END_INDEX);
            setEndIndex(endIndex + PAGING_END_INDEX);
        }
        getPatientData(DEFAULT_PAGING_SIZE, nextPage);
    }
    //Pagination ends

    function addNew() {
        setShowModalWindow(true);
    }

    function onHide() {
        setEditForm(false);
    }

    function onDeleteHide() {
        setDeletePatientName('');
        setDeletePatientId('');
        setShowDeleteModalWindow(false);
    }

    function getPatientData() {
        setShowLoadingBar(true);
       const pageNum = 0;
       const pagingSize = 0;
        let result = null;
        if (isAdminModule === 'true')
            result = service.ListPatientTypes(pagingSize, pageNum, isAdminModule);
        else
            result = service.ListPatientTypes(pagingSize, pageNum, isAdminModule, practicePK);
        result.then(response => {
            if (response.data.results !== undefined) {
                setTotalPage(Math.ceil(response.data.count / response.data.page_size));
            }
            setShowLoadingBar(false);
            setPatientTypeList(response.data);
            if (response.data.length === 0) setInitialTableData(i18n.t('commons.noRecords'))

        });
    }

    useEffect(() => {
        getPatientData(DEFAULT_PAGING_SIZE, activePage);
    }, []);

    function onEditPatientType(patientId) {
        setShowLoadingBar(true);
        let data = null;
        if (isAdminModule === 'true')
            data = service.GetPatientType(patientId);
        else
            data = service.GetPatientType(patientId, practicePK);
        data.then(response => {
            setShowLoadingBar(false);
            setShowModalWindow(true);
            setPatientType(response.data.name);
            setEditForm(true);
            setEditPatientId(patientId);
        });
    }

    function onDeletePatientType(patientId) {
        patientTypeList.map((item) => {
            if (item.id == parseInt(patientId)) {
                setDeletePatientName(item.name);
                setDeletePatientId(item.id);
                setShowDeleteModalWindow(true);
            }
        });
    }

    function onDeleteAction() {
        setShowLoadingBar(true);
        let data = null;
        if (isAdminModule === 'true')
            data = service.DeletePatientType(deletePatientId);
        else
            data = service.DeletePatientType(deletePatientId, practicePK);
        data.then(() => {
            setShowLoadingBar(false);
            setDeletePatientName('');
            setDeletePatientId('');
            setShowDeleteModalWindow(false);
            showNotifyWindow('show', 'success', DELETE_SUCCESS);
            getPatientData(DEFAULT_PAGING_SIZE, activePage);
        });
    }

    function resetForm() {
        setPatientType('');
        setEditForm(false);
        setFormError({
            ...form_error,
            ['patientType']: ''
        });

    }
    function onSaveFormData(e) {
        e.preventDefault();
        if (!patientType.trim()) {
            setFormError({
                ...form_error,
                ['patientType']: 'error'
            });
        } else {
            setShowLoadingBar(true);
            let result = null;
            let data = null;
            if (isAdminModule === 'true')
                data = { name: patientType }
            else
                data = { practice: practicePK, name: patientType }
            if (editForm) {
                result = service.UpdatePatientType(editPatientId, data);
            } else {
                result = service.AddPatientType(data);
            }
            result.then(response => {
                setShowLoadingBar(false);
                if (response.status === 201 || response.status === 200) {
                    if (response.status === 201)
                        showNotifyWindow('show', 'success', ADD_SUCCESS);
                    else
                        showNotifyWindow('show', 'success', UPDATE_SUCCESS);
                    getPatientData(DEFAULT_PAGING_SIZE, activePage);
                    resetForm();
                    setShowModalWindow(false);
                } else if (response.status === 400 && response.data.record_already_exists !== undefined)
                    showNotifyWindow('show', 'error', i18n.t('errorMessages.record_exists'));
                else
                    showNotifyWindow('show', 'error', ADD_ERROR);
            });
        }
    }
    function onHandleChange(e) {
        const { name } = e.target;
        let value = e.target.value;
        let trimmedValue = '';
        if (e.target.type === "text") {
            trimmedValue = value;
        }
        if (!trimmedValue) {
            setFormError({
                ...form_error,
                [name]: 'error'
            });
        } else {
            setFormError({
                ...form_error,
                [name]: ''
            });
        }
        setPatientType(trimmedValue);
    }

    function onShowActionButtons(event, rowId) {
        let dropdown = document.getElementById('dropdown-' + rowId);
        let dropdownMenu = document.getElementById('dropdown-menu-' + rowId);
        dropdown.classList.toggle('show');
        dropdownMenu.classList.toggle('show');
        if (activeRowId === 0) {
            setActiveRowId(rowId);
            document.getElementById('img-action-' + rowId).focus();
        } else if (activeRowId === rowId) {
            setActiveRowId(0);
        } else if (this.state.activeRowId !== rowId) {
            dropdown = document.getElementById('dropdown-' + activeRowId);
            dropdownMenu = document.getElementById('dropdown-menu-' + activeRowId);
            dropdown.classList.toggle('show');
            dropdownMenu.classList.toggle('show');
            setActiveRowId(rowId);
            document.getElementById('img-action-' + rowId).focus();
        }
    }

    function onHideActionButtons(event, rowId) {
        let dropdown = document.getElementById('dropdown-' + rowId);
        let dropdownMenu = document.getElementById('dropdown-menu-' + rowId);
        dropdown.classList.remove('show');
        dropdownMenu.classList.remove('show');
        setActiveRowId(0);
    }

    function backToDictionaryPage() {
        if (isAdminModule === 'true')
            props.history.push(ROUTE_SUPER_DICTIONARIES)
        else
            props.history.push(ROUTE_PRACTICE_DICTIONARIES)
    }

    return (
        <React.Fragment>
            <Notify showNotify={showNotify} setShowNotify={setShowNotify} notifyDescription={notifyDescription} setNotifyType={setNotifyType} setNotifyDescription={setNotifyDescription} notifyType={notifyType} />
            <div className="col-md-12">
                <div className="box">
                    <div className="box-head ">
                        <div className={'dataTables_filter'}>
                            <Link to="#" className="link dictionaries-back" onClick={backToDictionaryPage}>{i18n.t("dictionariesPages.backToDictionaries")}</Link>
                        </div>
                        <div className="alignRight padding-right5">
                            <button type="button" className="btn btn-primary-blue" onClick={addNew}>{i18n.t("buttons.addNew")}</button>
                        </div>
                    </div>
                    <div className="box-content">
                        <div className="table-responsive">
                            <table id="data-table" className="table" style={{ width: "100%" }}>
                                <thead>
                                    <tr>
                                        <th>{i18n.t("dictionariesPages.dictPatientType.lblPatientType")}</th>
                                        <th>{i18n.t("dictionariesPages.action")}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {patientTypeList && patientTypeList.length == 0 &&
                                        <tr>
                                            <td align="center" colSpan='2'>
                                                {initialTableData}
                                            </td>
                                        </tr>
                                    }
                                    {patientTypeList.map((item, index) => {
                                        return <tr key={item.id}>
                                            <td>{item.name}</td>
                                            <td align='center'>
                                                <div className="dropdown" id={'dropdown-' + item.id}>
                                                    <button id={'dropdownMenuButton-' + item.id} className="dropdown-toggle" type="button"
                                                        data-toggle="dropdown"
                                                        aria-haspopup="true" onClick={(e) => onShowActionButtons(e, item.id)}
                                                    >
                                                        <input type="image" id={"img-action-" + item.id} src={actionImg} alt="Action" onBlur={(e) => onHideActionButtons(e, item.id)} />
                                                    </button>
                                                    <div className="dropdown-menu" id={'dropdown-menu-' + item.id}>
                                                        <Link className="dropdown-item edit" data-testid={"edit" + index} to="#" onMouseDown={() => onEditPatientType(item.id)}>{'Edit'}</Link>
                                                        <Link className="dropdown-item edit" data-testid={"delete" + index} to="#" onMouseDown={() => onDeletePatientType(item.id)}>{'Delete'}</Link>
                                                    </div>

                                                </div>
                                            </td>
                                        </tr>
                                    })}
                                </tbody>
                            </table>
                            <Pagination totalPage={totalPage} activePage={activePage} startIndex={startIndex} endIndex={endIndex} onPagePrevious={onPagePrevious} onPageUp={onPageUp} onPageNext={onPageNext} />
                            <div className="clear-b-scroll-sector">&nbsp;</div>
                            <div className="clear-b-scroll-sector">&nbsp;</div>
                            <div className="clear-b-scroll-sector">&nbsp;</div>
                        </div>
                    </div>
                </div>
            </div>
            <CustomizedDialogs showModal={showModalWindow} type="save" header={modalHeader} setShowModalWindow={setShowModalWindow} resetForm={resetForm} onHide={onHide}>
                <Form id="form_dataEntry" onSubmit={(e) => onSaveFormData(e)} encType="multipart/form-data">
                    <Form.Group>
                        <div className="row">
                            <div className="col-md-12">
                                <div className="form-group padding-top15">
                                    {/* <label>{i18n.t('dictionariesPages.dictPatientType.lblPatientType')}</label>
                                <div className={'text-input'}>
                                    <input type="text" placeholder={i18n.t('dictionariesPages.dictPatientType.lblPatientType')} name="patientType" className={form_error.patientType ? 'input-error' : ""} value={patientType} onChange={(e) => onHandleChange(e)}/>
                                </div> */}
                                    <TextInput type="text" id="patientType" name="patientType" label={i18n.t('dictionariesPages.dictPatientType.lblPatientType')} onValueChange={onHandleChange} className={form_error.patientType ? 'input-error' : ""} value={patientType} />
                                </div>
                            </div>
                        </div>
                    </Form.Group>
                </Form>
            </CustomizedDialogs>
            <CustomizedSmallDialogs showModal={showDeleteModalWindow} header={i18n.t("commons.confirmDelete")} type="delete" deleteItem={onDeleteAction} resetForm={resetForm} onHide={onDeleteHide} setShowModalWindow={setShowDeleteModalWindow}>
                {i18n.t("dictionariesPages.dictPatientType.deletConfirmation") + "'" + deletePatientName + "'?"}
            </CustomizedSmallDialogs>
        </React.Fragment>
    )
}
export default PatientTypesList