import i18n from "../../../utilities/i18n";

export const CsvTemplateTableBody = [
    [
        {
            "id": "1",
            "name": "name",
            "value": "1",
            "type": "string",
            "align": "center",
            "width": "small"
        },
        {
            "id": "1",
            "name": "name",
            "value": "Patient Data",
            "type": "string",
            "align": "left",
            "width": "medium"
        },
        {
            "id": "1",
            "name": "Patient Data",
            "value": i18n.t("csvTemplates.download"),
            "type": "download",
            "align": "center",
            "width": "medium",
            "directLinkPath": "https://s3-pms-staging.s3.ap-south-1.amazonaws.com/CSV_import_templates/PMS_Patient_Data.csv"
        },
    ],
    [
        {
            "id": "2",
            "name": "name",
            "value": "2",
            "type": "string",
            "align": "center",
            "width": "small"
        },
        {
            "id": "2",
            "name": "name",
            "value": "Claim Data",
            "type": "string",
            "align": "left",
            "width": "medium"
        },
        {
            "id": "2",
            "name": "Claim Data",
            "value": i18n.t("csvTemplates.download"),
            "type": "download",
            "align": "center",
            "width": "medium",
            "directLinkPath": "https://s3-pms-staging.s3.ap-south-1.amazonaws.com/CSV_import_templates/PMS_Claim_data.csv"
        },
    ],
    [
        {
            "id": "3",
            "name": "name",
            "value": "3",
            "type": "string",
            "align": "center",
            "width": "small"
        },
        {
            "id": "3",
            "name": "name",
            "value": "Batch Eligibility Check",
            "type": "string",
            "align": "left",
            "width": "medium"
        },
        {
            "id": "3",
            "name": "Batch Eligibility Check",
            "value": i18n.t("csvTemplates.download"),
            "type": "download",
            "align": "center",
            "width": "medium",
            "directLinkPath": "https://s3-pms-staging.s3.ap-south-1.amazonaws.com/CSV_import_templates/Batch_Eligibility_Check_List.csv"
        },
    ],
];

export const TableBodyData = [
    [
        {
            "id": "",
            "name": "id",
            "value": "",
            "type": "string",
            "needLink": false,
            "align": "left",
            "width": "small",
        },
        {
            "id": "",
            "name": "name",
            "value": "",
            "type": "string",
            "needLink": false,
            "align": "left",
            "width": "small",
        },
        {
            "id": "",
            "name": "download",
            "value": i18n.t("csvTemplates.download"),
            "type": "download",
            "needLink": true,
            "align": "left",
            "width": "small",
            "directLinkPath": "",
        },
    ]
]

const tableHeadings = [
    {
        "name": i18n.t("csvTemplates.#"),
        "type": "string",
        "width": "small",
        "sort": false
    },
    {
        "name": i18n.t("csvTemplates.template_name"),
        "type": "string",
        "width": "medium",
        "sort": false
    },
    {
        "name": i18n.t("csvTemplates.template_sample"),
        "type": "string",
        "width": "medium",
        "sort": false
    },
];

export const CsvTemplateTableData = {
    "name": "simpleTable",
    "isResize": false,
    "isFixedHead": false,
    "noRecordsMsg": i18n.t('commons.noRecords'),
    "tableHeadings": tableHeadings,
    "tableBodyData": TableBodyData
}

