import React, { useContext, useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import LoadingContext from "../../../container/loadingContext";
import { getStorage } from "../../../utilities/browserStorage";
import { CommonDateFormat, currencyFormat } from "../../../utilities/commonUtilities";
import { PAYMENT_TYPES } from "../../../utilities/dictionaryConstants";
import i18n from "../../../utilities/i18n";
import CalendarInput from "../../commons/input/CalendarInput";
import TextInput from "../../commons/input/input";
import SelectInput from "../../commons/input/SelectInput";
import service from "./service";

const EditPaymentPlan = (props) => {
  const itemId = getStorage("edit-payment-id");
  const paymentTypeList = PAYMENT_TYPES;
  const paymentLinkList = [{ id: 1, name: "Link Payment To Balance" }];
  const dueStatusList = [
    { id: 1, name: "Paid" },
    { id: 2, name: "Unpaid" },
  ];

  const setShowLoadingBar = useContext(LoadingContext);

  const [paymentLinkToBalance, setPaymentLinkToBalance] = useState("");
  const [paymentPlanAmount, setPaymentPlanAmount] = useState("");
  const [paymentType, setPaymentType] = useState("");
  const [noInstalments, setNoInstalments] = useState("");
  const [installmentStartDate, setInstallmentStartDate] = useState("");
  const [dueStatus, setDueStatus] = useState();

  const [procedureList, setProcedureList] = useState([]);
  const [emiList, setEmiList] = useState([]);

  useEffect(() => {
    getPaymentPlan(itemId);
  }, []);
  const getPaymentPlan = (id) => {
    setShowLoadingBar(true);
    service
      .getPaymentPlanById(id)
      .then((response) => {
        setShowLoadingBar(false);
        if (response.status === 200 || response.status === 201) {
          const data = response.data;
          setEmiList(data.installment_detail);
          setProcedureList(data.procedure_detail);
          setDueStatus(data.due_status);
          setPaymentType(data.payment_type);
          setPaymentPlanAmount(data.total_amount);
          setInstallmentStartDate(data.payment_start_date);
          setNoInstalments(data.total_installments);
        } else {
          throw new Error(response.statusText);
        }
      })
      .catch(() => {
        setShowLoadingBar(false);
      });
  };

  const onHandleChange = (e) => {
    let name = e.target.name;
    let value =
      e.target.type === "checkbox" ? e.target.checked : e.target.value;
    let trimmedValue = "";
    trimmedValue = value;
    if (name === "paymentPlanAmount") setPaymentPlanAmount(trimmedValue);
    else if (name === "paymentType") setPaymentType(trimmedValue);
    else if (name === "noInstalments") setNoInstalments(trimmedValue);
    else if (name === "dueStatus") setDueStatus(trimmedValue);
  };
  const onChangeCheck = () => {
  };


  const savePaymentPlan = () => {
    setShowLoadingBar(true);
    const body = { due_status: dueStatus };
    service
      .updatePaymentPlan(itemId, body)
      .then((response) => {
        setShowLoadingBar(true);
        if (
          response.status === 200 ||
          response.status === 201 ||
          response.status === 204
        ) {
          setShowLoadingBar(false);
          props.fetchPatientDetails()
          props.closeModal(false)
          props.showNotifyWindow('show', 'success', "Due status changed.");
        } else {
          throw new Error(response.statusText);
        }
      })
      .catch(() => {
        setShowLoadingBar(false);
      });
  };
  const onSaveClickHandler = () => {
    savePaymentPlan();
  };

  return (
    <>
      <Form autoComplete="off">
        <div className="row pb-2 mt-4">
          <div className="col-2 pl-0">
            <div className="form-group padding-top padding-left20">
              <SelectInput
                name="paymentLinkToBalance"
                value={paymentLinkToBalance}
                setValue={setPaymentLinkToBalance}
                onValueChange={onHandleChange}
                data={paymentLinkList}
                disabled="disabled"
                label={i18n.t("paymentPlan.newPaymentPlan.linkPymntBalance")}
              />
            </div>
          </div>
          <div className="col-2 pl-0">
            <SelectInput
              name="paymentType"
              value={paymentType}
              setValue={setPaymentType}
              disabled="disabled"
              onValueChange={onHandleChange}
              data={paymentTypeList}
              label={i18n.t("paymentPlan.newPaymentPlan.paymentType")}
            />
          </div>
          <div className="col-2 pl-0">
            <div className="form-group padding-top">
              <TextInput
                type="text"
                name="paymentPlanAmount"
                disabled="disabled"
                value={paymentPlanAmount}
                onValueChange={onHandleChange}
                label={i18n.t("paymentPlan.newPaymentPlan.paymentAmount")}
              />
            </div>
          </div>
          <div className="col-2 pl-0">
            <CalendarInput
              label={i18n.t("paymentPlan.newPaymentPlan.installmentDate")}
              name="installmentStartDate"
              selected={installmentStartDate}
              disabled="disabled"
              onValueChange={(selected) => setInstallmentStartDate(selected)}
            />
          </div>
          <div className="col-2 pl-0">
            <SelectInput
              name="dueStatus"
              value={dueStatus}
              setValue={setDueStatus}
              required={true}
              onValueChange={onHandleChange}
              data={dueStatusList}
              label={i18n.t("paymentPlan.newPaymentPlan.dueStatus")}
            />
          </div>
          <div className="col-2 pl-0">
            <div className="form-group padding-top">
              <TextInput
                type="text"
                name="noInstalments"
                required={true}
                value={noInstalments}
                onValueChange={onHandleChange}
                disabled="disabled"
                label={i18n.t("paymentPlan.newPaymentPlan.noInstalments")}
              />
            </div>
          </div>
        </div>
        <div>
          <h5 className="padding-left5 textLeft">
            <b>{i18n.t("paymentPlan.newPaymentPlan.linkToBalance")}</b>
          </h5>
          <table className="table" id={"overflow_auto"}>
            <thead>
              <tr>
                <th className="width-60">
                  {i18n.t("paymentPlan.newPaymentPlan.dateOfService")}
                </th>
                {/* <th className="width-50">
                  {i18n.t("paymentPlan.newPaymentPlan.procedure")}
                </th> */}
                <th className="width-10perc">
                  {i18n.t("paymentPlan.newPaymentPlan.charges")}
                </th>
                <th className="width-10perc">
                  {i18n.t("paymentPlan.newPaymentPlan.insurancePayments")}
                </th>
                <th className="width-10perc">
                  {i18n.t("paymentPlan.newPaymentPlan.patientPayments")}
                </th>
                <th className="width-10perc">
                  {i18n.t("paymentPlan.newPaymentPlan.insuranceBalance")}
                </th>
                <th className="width-10perc">
                  {i18n.t("paymentPlan.newPaymentPlan.patientBalance")}
                </th>
                <th className="width-50">
                  {i18n.t("paymentPlan.newPaymentPlan.Select")}
                </th>
              </tr>
            </thead>
            <tbody>
              {procedureList.map((item, index) => {
                return (
                  <tr key={index}>
                    <td align="center">{item?.procedures?.dos ? CommonDateFormat(item?.procedures?.dos) : ''}</td>
                    {/* <td align="center">{item.procedure}</td> */}
                    <td align="right">{currencyFormat(item?.procedures?.charge)}</td>
                    <td align="right">
                      {currencyFormat(item?.procedures?.insurance_payments)}
                    </td>
                    <td align="right">
                      {currencyFormat(item?.procedures?.patient_payments)}
                    </td>
                    <td align="right">
                      {currencyFormat(item?.procedures?.insurance_balance)}
                    </td>
                    <td align="right">
                      {currencyFormat(item?.procedures?.patient_balance)}
                    </td>
                    <td align="center" className="pt-0">
                      <input
                        onChange={(e) => onChangeCheck(e, item)}
                        type="checkbox"
                        disabled="disabled"
                        checked="checked"
                      />
                    </td>
                    </tr>
                );
              })}
            </tbody>
          </table>
        </div>

        <div className="pt-2">
          <table className="table" id={"overflow_auto"}>
            <thead>
              <tr>
                <th>{i18n.t("paymentPlan.newPaymentPlan.dueDate")}</th>
                <th>
                  {i18n.t("paymentPlan.newPaymentPlan.installmentAmount")}
                </th>
                <th>{i18n.t("paymentPlan.newPaymentPlan.installmentNo")}</th>
              </tr>
            </thead>
            <tbody>
              {emiList.map((item, index) => {
                return (
                  <tr key={index}>
                    <td align="center">{item.due_date}</td>
                    <td align="right">
                      {currencyFormat(item.installment_amount)}
                    </td>
                    <td align="center">{item.installment_count}</td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
        <div className="row">
          <div className="col-12">
            <div className="div-float-right">
              <button
                type="button"
                className="btn btn-primary-blue"
                onClick={onSaveClickHandler}
              >
                {i18n.t("paymentPlan.newPaymentPlan.save")}
              </button>
            </div>
          </div>
        </div>
      </Form>
    </>
  );
};

export default EditPaymentPlan;
