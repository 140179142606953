export const GOOGLE_LOCATION_STREET = [ 'street_address', 'premise', 'subpremise', 'route', 'administrative_area_level_5','sublocality_level_3', 'sublocality_level_4', 'sublocality_level_5', 'floor', 'establishment', 'room', 'street_number' ]; 

export const GOOGLE_LOCATION_STREET2 = [ 'administrative_area_level_3', 'administrative_area_level_4', 'sublocality', 'sublocality_level_1', 'sublocality_level_2','bus_station', 'train_station', 'transit_station' ]; 

export const GOOGLE_LOCATION_CITY = [ 'locality' ]; 

export const GOOGLE_LOCATION_STATE = [ 'administrative_area_level_1' ]; 

export const GOOGLE_LOCATION_COUNTRY = [ 'country' ]; 

export const GOOGLE_LOCATION_POSTCODE = [ 'postal_code', 'post_box' ]; 
