import React, { useContext, useEffect, useState } from "react";
import { getStorage } from "../../../../utilities/browserStorage";
import TextAreaInput from "../../../commons/textarea/textarea";
import ChangeHealthInfoCard from "./ChangeHealthInfoCard";
import ClaimsTable from "./ClaimsTable";
import MessageAndInfoCard from "./MessageAndInfoCard";
import PracticeAddressCard from "./PracticeAddressCard";
import "../StatementStyles.css";
import service from "../Services";
import LoadingContext from "../../../../container/loadingContext";
import { checkPermission } from "../../../../utilities/commonUtilities";
import i18n from "../../../../utilities/i18n";
import { permission_key_values_patient } from "../../../../utilities/permissions";
import AccountAndPaymentDetails from "./AccountAndPaymentDetails";
import CreditCardBox from "./CreditCardBox";

const StatementConfigurationView = ({
  patientId,
  edit,
  OnNotify,
  statementQuery,
  closeOnError
}) => {
  const setShowLoadingBar = useContext(LoadingContext);
  const practicePK = getStorage("practice");
  const [practiceLogo, setPracticeLogo] = useState(null);
  const [formState, setFormState] = useState({
    message: "",
    contact: "",
    payment: "",
    additional: "",
    checkPayable: "",
    mailingAddress: ""
  });
  // eslint-disable-next-line
  const [additionalInfo, setAdditionalInfo] = useState();
  const [claimDetails, setClaimDetails] = useState();
  const [practiceDetails, setPracticeDetails] = useState();
  const [patientDetails, setPatientDetails] = useState();
  const [configId, setConfigId] = useState();
  const [paidAmount, setPaidAmount] = useState(0)

  const getStatementData = () => {
    setShowLoadingBar(true);
    service
      .getStatementConfig(practicePK)
      .then((response) => {
        setShowLoadingBar(false);
        if (response.status === 200) {
          const info = response.data[0];
          setFormState({
            message: info.important_message,
            contact: info.contact_info,
            payment: info.payment_info,
            additional: info.additional_info,
            practiceLogo: info.practice_logo,
            checkPayable: info.check_payable,
            mailingAddress: info.mailing_address
          });
          setConfigId(info.id);
        }
      })
      .catch(() => {
        setShowLoadingBar(false);
      });
  };

  useEffect(() => {
    if (practiceLogo) {
      setFormState({
        ...formState,
        practiceLogo:''
      })
    }
  },[practiceLogo])

  const getPatientStatementData = () => {
    setShowLoadingBar(true);
    service
      .fetchStatementConfig(patientId, practicePK,statementQuery)
      .then((response) => {
        if (response.status !== 200) {
          closeOnError(patientId);
          return;
        }

        setShowLoadingBar(false);
        if (response.status === 200) {
          const info =
            response.data.practice_statement_info[
              response.data.practice_statement_info.length - 1
            ];
            if(info){
            setFormState({
              message: info.important_message,
              contact: info.contact_info,
              payment: info.payment_info,
              additional: info.additional_info,
              practiceLogo: info.practice_logo,
              checkPayable: info.check_payable,
              mailingAddress: info.mailing_address

            }); 
            }
          setAdditionalInfo(response.data.additional_info);
          setClaimDetails(response.data.claim_details);
          setPracticeDetails(response.data.practice_details);
          setPatientDetails(response.data.patient_details);
          setPaidAmount(response.data.paid_amount)
        }
      })
      .catch(() => {
        setShowLoadingBar(false);
        OnNotify("show", "error", i18n.t("errorMessages.commonError"));
      });
  };

  useEffect(() => {
    if (edit) {
      getStatementData();
    } else if (patientId && !edit) {
      getPatientStatementData();
    }
  }, []);

  const onSetValue = (name, event) => {
    setFormState((prev) => {
      return {
        ...prev,
        [name]: event.target.value,
      };
    });
  };


  const updateConfig = (id, body) => {
    if(id !== '') {
      if (practiceLogo != null) {
        body.append("practice_logo", practiceLogo);
      }
      setShowLoadingBar(true);
      service
        .updateStatementConfig(id, body)
        .then((response) => {
          setShowLoadingBar(false);
          if (response.status === 200 || response.status === 201) {
            OnNotify("show", "success", i18n.t("statementManagement.dataAdded"));
          }
        })
        .catch(() => {
          setShowLoadingBar(false);
          OnNotify("show", "error", i18n.t("errorMessages.commonError"));
        });
    } else {
      body.append("practice_logo", practiceLogo);
      setShowLoadingBar(true);
      service
        .setStatementConfig(body)
        .then((response) => {
          setShowLoadingBar(false);
          if (response.status === 200 || response.status === 201) {
            OnNotify("show", "success", i18n.t("statementManagement.dataAdded"));
          }
        })
        .catch(() => {
          setShowLoadingBar(false);
          OnNotify("show", "error", i18n.t("errorMessages.commonError"));
        })
    }
  }
   

  const onFormSubmit = (e) => {
    e.preventDefault();
    const body = new FormData();
    body.append("important_message", formState.message);
    body.append("contact_info", formState.contact);
    body.append("payment_info", formState.payment);
    body.append("additional_info", formState.additional);
    body.append("practice", practicePK);
    body.append("mailing_address", formState.mailingAddress);
    body.append("check_payable", formState.checkPayable);
    if (configId) {
      updateConfig(configId, body);
    } else {
      updateConfig('',body);
    }
  };

  return (
    <div className="searchBox">
      <div className="container report-card p-4">
        <div className="row">
          <div className="col-12">
            <PracticeAddressCard
              practiceLogo={formState.practiceLogo}
              practiceDetails={practiceDetails}
              editable={checkPermission(permission_key_values_patient.statement_configuration_tab_modify) && edit}
              setImage={setPracticeLogo}
            />
          </div>

          <div className="col-12">
            <AccountAndPaymentDetails 
              statementDate={
                additionalInfo ? additionalInfo.current_date : undefined
              }
              dueDate={additionalInfo ? additionalInfo.due_date : undefined}
              account={patientDetails ? patientDetails.id : undefined}
              payAmount={
                patientDetails ? patientDetails.patient_balance : undefined
              }
              patientDetails={patientDetails}
              paidAmount={paidAmount ? paidAmount : 0.00}
            />
          </div>
        </div>


        <MessageAndInfoCard
          editable={edit}
          onValueChange={onSetValue}
          formState={formState}
        />

        <div className="row mt-4">
          <div className="col-12">
            <TextAreaInput
              {...(edit ? {} : { disabled: "disabled" })}
              value={formState.additional}
              onValueChange={(e) => onSetValue("additional", e)}
              placeholder={"Additional Info"}
              label={"Additional Info"}
            />
          </div>
        </div>

        <div className="row" style={{ marginTop: '25px', marginBottom: '15px' }}>

          <div className="col-6">

            <div className="row">
              <div className="col-4">
                {/* eslint-disable-next-line */}
                <img style={{ width: '200px' }} src={require('./QRAima.png').default} alt="QR Code" />
              </div>
              <div className="col-8 d-flex flex-column justify-content-center align-items-center" style={{ height: '100%' }}>
                <h5 style={{ marginBottom: '20px', fontWeight: 'bold' }}>Make Payment Online</h5>
                <h6>Choose our Online Payment Feature for a hassle free transaction</h6>
                <h6>Scan the QR Code to route to our payment page.</h6>
              </div>
            </div>
          </div>
        </div>

        <div style={{ borderBottom: '4px dashed #E0E0E0', width: '100%', height: '1' }}></div>


        <div className="row" style={{ marginTop: '25px' }}>
          <div className="col-6">
            <div className="col-md-12">
              <h5 style={{ marginBottom: '5px', fontWeight: 'bold', marginLeft: '-15px' }}>Pay By Mail</h5>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-6 mb-4">

            <AccountAndPaymentDetails
              statementDate={
                additionalInfo ? additionalInfo.current_date : undefined
              }
              dueDate={additionalInfo ? additionalInfo.due_date : undefined}
              account={patientDetails ? patientDetails.id : undefined}
              payAmount={
                patientDetails ? patientDetails.patient_balance : undefined
              }
              patientDetails={patientDetails}
              paidAmount={paidAmount ? paidAmount : 0.00}

            />

            <div className="col-12" style={{ marginLeft: '-10px' }}>
              <TextAreaInput
                {...(edit ? {} : { disabled: "disabled" })}
                value={formState.checkPayable}
                onValueChange={(e) => onSetValue("checkPayable", e)}
                placeholder={"Check Payable to"}
                label={"Check Payable to"}
              />
            </div>

            <div className="col-12" style={{ marginLeft: '-10px' }}>
              <TextAreaInput
                {...(edit ? {} : { disabled: "disabled" })}
                value={formState.mailingAddress}
                onValueChange={(e) => onSetValue("mailingAddress", e)}
                placeholder={"Mailing Address"}
                label={"Mailing Address"}
              />
            </div>


            <p style={{ fontSize: '0.8em', marginTop: '10px', color: '#888' }}>
              If you prefer to pay by check or cash, simply remove this section and send it to us via registered mail at the address provided below.
            </p>

          </div>

          <div className="col-6">
            <CreditCardBox />
          </div>
        </div>


        <div style={{ borderBottom: '4px dashed #E0E0E0', width: '100%', height: '1' }}></div>

        <ChangeHealthInfoCard
          patientName={patientDetails ? patientDetails.name : "SuperAdmin"}
          patientId={patientDetails ? patientDetails.id : "1234"}
        />

        <ClaimsTable tire={3} claimsData={claimDetails} />
      </div>
      {checkPermission(permission_key_values_patient.statement_configuration_tab_modify) && edit && (
        <div className="row mt-4">
          <div className="col-7">
            <button
              onClick={onFormSubmit}
              type="button"
              className="btn btn-primary-blue float-right"
            >
              Save
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default StatementConfigurationView;
