import React, {
  useEffect,
  useContext,
  useState
} from 'react'
import { Grid, Paper } from '@mui/material'
import LoadingContext from '../../../container/loadingContext';
import Notify from '../../commons/notify';
import CommonButton from '../../commons/Buttons';
import i18n from '../../../utilities/i18n';
import ImageCrop from "../../commons/image-cropper/ImageCrop";
import styled from '@emotion/styled';
import service from './service';
import { useHistory } from "react-router-dom";
import profileImage from '../../../assets/images/profile-image.png';
import TextInput from '../../commons/input/input'
import { format } from 'date-fns';
import EmailInput from '../../commons/EmailInput/EmailInput';
import SelectInput from '../../commons/input/SelectInput';
import PhoneInput from '../../commons/PhoneInput/PhoneInput';
import { imageExtension } from '../../../utilities/validations';
import AssociateUsersPracticeRoleSelector from './AssociateUsersPracticeRoleSelector';
import { getStorage, setStorage } from '../../../utilities/browserStorage';
import { ValidateEmail } from '../../../utilities/commonUtilities';
import { ADD_ERROR, UPDATE_SUCCESS, USER_CREATED_SUCCESS } from '../../../utilities/labelConfigs';
import StaffUserRoleSelector from './StaffUserRoleSelector';
import { RESET_PASSWORD_DURATION, ROUTE_ASSOCIATE_USERS, ROUTE_PRACTICE_ASSOCIATE_USERS } from '../../../utilities/staticConfigs';


const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  color: theme.palette.text.secondary,
}));
const ACTIVE_STATUS = [{ "id": 1, "name": "InActive" }, { "id": 2, "name": "Active" },];


function AssociateUserAdd() {
  const history = useHistory()
  const practicePK = getStorage("practice");
  const isAdminModule = getStorage('isAdminModule');
  const isLogoValid = false;
  const [showImageCropperModalWindow, setShowImageCropperModalWindow] = useState(false);
  const [imageName, setImageName] = useState('');
  const [imgUrl, setImgUrl] = useState(null);
  const [imageUrl, setImageUrl] = useState(profileImage);
  const [profilePicture,setProfilePicture] = useState('');
  const [reloadPage, setReloadPage] = useState(false);
  const [formError, setFormError] = useState({
    'userID': '',
    'email': '', 'firstName': '', 'middleName': '', 'lastName': '',
    'role': '', 'practices': '', 'defaultPractice': '', 'profilePicture': ''
  });


  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [firstName, setFirstName] = useState('');
  const [middleName, setMiddleName] = useState('');
  const [lastName, setLastName] = useState('');
  const [userID, setUserID] = useState('')
  const [userIdValidate, setUserIDValidate] = useState(false);
  const [nextReset, setNextReset] = useState('');
  const [activeStatus, setActiveStatus] = useState(2);
  const [forcePassword, setForcePassword] = useState(`${RESET_PASSWORD_DURATION}`);

  const [defaultPractice,setDefaultPractice] = useState('');
  const [defaultPracticeList, setDefaultPracticeList] = useState([]);
  const [selectedPracticesAndRoles, setSelectedPracticesAndRoles] = useState({})
  const [associateUserSelectedPractices, setAssociateUserSelectedPractices] = useState([]);
  const [practicesList, setPracticesList] = useState([]);
  const [practicesAndRoles, setPracticesAndRoles] = useState([]);
  const [role, setRole] = useState('')
  const [staffUserRole, setStaffUserRole] = useState('');
  const setShowLoadingBar = useContext(LoadingContext);
  const [showNotify, setShowNotify] = useState("hide");
  const [notifyDescription, setNotifyDescription] = useState("");
  const [notifyType, setNotifyType] = useState("success");

  function showNotifyWindow(action, type, desc, age = 3000) {
    if (action === "show") {
      setTimeout(() => {
        setShowNotify("hide");
      }, age);
    }
    setShowNotify(action);
    setNotifyType(type);
    setNotifyDescription(desc);
  }

  function onChangeShowImageCropperModalWindow(newValue) {
    setShowImageCropperModalWindow(newValue);
  }

  function onCroppedImageChange(newValue) {
    setProfilePicture(newValue);
    setImageUrl(URL.createObjectURL(newValue));
  }
  function openImage() {
    document.getElementById("file_userProfileAdd").click();
  }

  function onRemoveImage() {
    setImageUrl("");
    setProfilePicture("");
    setImageUrl(profileImage);
  }

  const onCancel = () => {
    history.goBack()
  }

  const removePractice = (practiceId)=> {
    const newArr = associateUserSelectedPractices.filter(item=>item != practiceId);
    setAssociateUserSelectedPractices(newArr)
  }

  function getPractices(pageSize) {
    let pageNumber = 0;
    let result = null;
    if (isAdminModule === 'true' || (getStorage('userData') && JSON.parse(getStorage('userData')).group_id === 1))
      result = service.ListPractices(pageSize, pageNumber);
    else
      result = service.ListPractices(pageSize, pageNumber, practicePK);
    result.then(response => {
      setPracticesList(response.data);
    });
  }

  useEffect(()=> {
    getPractices()
    fetchPracticeAndRoles()
  },[])

  useEffect(() => {
    if (reloadPage && getStorage('practiceName')) {
      setReloadPage(false);
      window.location.reload();
    }
  }, [getStorage('practiceName')]);

  useEffect(() => {
    if (Object.keys(selectedPracticesAndRoles).length) {
      const selectedPracticeIds = Object.keys(selectedPracticesAndRoles);

      setDefaultPracticeList(() => {
        const defaultOptions = practicesAndRoles
          ?.filter((item) => selectedPracticeIds.includes(`${item.practice_id}`))
          .map((item) => ({ id: Number(item.practice_id), name: item.practice_name }));

        return defaultOptions || [];
      });
    } else if (!Object.keys(selectedPracticesAndRoles).length && defaultPracticeList.length) {
      setDefaultPracticeList([]);
      setDefaultPractice('');
    }
  }, [selectedPracticesAndRoles]);

  const fetchPracticeAndRoles = async () => {
    try {
      const response = await service.getPracticeSAndRoles();
      if (response.data.length) {
        setPracticesAndRoles(response.data);
      } else {
        setPracticesAndRoles([]);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };


  function onHandleChange(e) {
    const name = e.target.name;
    let value = e.target.type === "file" ? e.target.files[0] : e.target.value;
    let trimmedValue = '';
    let inputFields = [
      "userID", "email", "phone", "firstName", "middleName", "lastName",
      "role", "practices", "defaultPractice"
    ]
    if (inputFields.includes(name)) {
      trimmedValue = value;
    }
    else if (name === 'forcePassword') {
      if (e.target.validity.valid)
        trimmedValue = value;
      else
        trimmedValue = forcePassword;
    }
    else {
      trimmedValue = value;
    }
    let img_url, img_obj, nameValue;
    switch (name) {
      case 'userID':
        setUserID(trimmedValue);
        if (ValidateEmail(trimmedValue)) {
          setUserIDValidate(false);
        } else {
          setUserIDValidate(true);
        }
        break;
      case 'email':
        setEmail(trimmedValue);
        setUserID(trimmedValue);
        if (ValidateEmail(trimmedValue)) {
          setUserIDValidate(false);
        } else {
          setUserIDValidate(true);
        }
        break;
      case 'phone':
        setPhone(trimmedValue);
        break;
      case 'firstName':
        setFirstName(trimmedValue);
        break;
      case 'middleName':
        setMiddleName(trimmedValue);
        break;
      case 'lastName':
        setLastName(trimmedValue);
        break;
      case 'role':
        setRole(trimmedValue);
        break;
      case 'defaultPractice':
        setDefaultPractice(trimmedValue);
        break;
      case 'is_active':
        setActiveStatus(trimmedValue);
        break;
      case 'forcePassword':
        setForcePassword(trimmedValue);
        if (trimmedValue) {
          let nextResetDate = new Date();
          nextResetDate.setDate(nextResetDate.getDate() + parseInt(trimmedValue));
          setNextReset(format(nextResetDate, "MM/dd/yyyy hh:mm a"));
        }
        break;
      case 'file_userProfileAdd':
        nameValue = e.target.name;
        if (imageExtension(e.target.files[0].name)) {
          img_obj = e.target.files[0];
          img_url = URL.createObjectURL(e.target.files[0]);
        }
        setImgUrl(img_url);
        setImageName(img_obj.name);
        document.getElementById(nameValue).value = '';
        setShowImageCropperModalWindow(true);
        break;
      case 'staffUserRole' :
        setStaffUserRole(value);
        break;
      default:
        break;
    }

  }

  function addPracticeDataToBox(value) {
    setAssociateUserSelectedPractices(value);
    const anArray = [];
    practicesList.forEach((item) => {
      value.forEach((item2) => {
        if (Number(item2) === Number(item.id)) {
          anArray.push(item);
        }
      })
    });

    setDefaultPracticeList(anArray); 
    
    if (defaultPractice) {
      let defaultP = anArray.filter(obj => {
        return obj.id === defaultPractice
      });
      if (defaultP.length === 0) {
        setDefaultPractice('');
      }
    } else if (anArray.length > 0) {
      setDefaultPractice(value[value.length - 1]);
    }
  }

  function resetForm() {
   
    setUserID('');
    setEmail('');
    setPhone('');
    setFirstName('');
    setMiddleName('');
    setLastName('');
    setAssociateUserSelectedPractices([]);
    setDefaultPracticeList([]);
    setRole('');
    setDefaultPractice('');
    setForcePassword(RESET_PASSWORD_DURATION);
    var nextResetDate = new Date();
    nextResetDate.setDate(nextResetDate.getDate() + RESET_PASSWORD_DURATION);
    if (RESET_PASSWORD_DURATION) setNextReset(format(nextResetDate, "MM/dd/yyyy hh:mm a"));
    setProfilePicture('');
    setImageUrl(profileImage);
    setFormError({
      ...formError,
      ['userID']: '',
      ['email']: '',
      ['firstName']: '',
      ['middleName']: '',
      ['lastName']: '',
      ['role']: '',
      ['practices']: '',
      ['defaultPractice']: '',
      ['profilePicture']: ''
    });
  }
  const [isRequired, setIsRequired] = useState(false);

  /**
     * creating form data to send to back end
     * @returns 
     */
  function createFormData() {
    const dataObject = {
      'page': isAdminModule === 'false' ? 'associate' : 'sa_associate',
      'username': userID,
      'email': email,
      'phone': phone,
      'first_name': firstName,
      'middle_name': middleName,
      'last_name': lastName,
      'role': role,
      'is_active': activeStatus,
      'practices': associateUserSelectedPractices,
      'default_practice': defaultPractice ? defaultPractice : '',
      'password_reset_days': forcePassword,
      'profile_picture': profilePicture,
      'practice_pk': isAdminModule === 'false' ? practicePK : '',
    };

    const formData = new FormData();
    /**
     * appending key and key value to formData
     */
    for (const key in dataObject) {
      formData.append(key, dataObject[key]);
    }

    return formData;
  }


  async function onSaveFormData(e) {
    e.preventDefault();
    let has_error = false;

    if(isAdminModule == "true"){
      has_error = false;
    } else if (!defaultPractice || defaultPractice === '' || defaultPractice == 0) {
      has_error = true;
    }
    if (isAdminModule === 'true' && (staffUserRole == '' || !staffUserRole) ) {
      has_error = true;
    }

    if (Object.keys(selectedPracticesAndRoles)?.length > 0 && !defaultPractice) {
      has_error = true;
    }
     if (email) {
      const email_validation = ValidateEmail(email);
      if (!email_validation) has_error = true;
    }
    if (!email) {
      has_error = true;
    }
    if (userID.trim() === '') {
      setUserID('');
      has_error = true;
    }
    if (userID) {
      const email_validation = ValidateEmail(userID);
      if (!email_validation) has_error = true;
    }

    // Get the selected practices and its associated roles from the state object
    // For each practice user selected, user also should choose a role against it
    let practiceAndRoles = [];
    let practiceAndRoleErrorFlag;

    // Extracting the selected practice id and the group role id
    for (const key in selectedPracticesAndRoles) {
      const selectedRoles = selectedPracticesAndRoles[key];

      if (!selectedRoles || Object.keys(selectedRoles).length === 0) {
        practiceAndRoleErrorFlag = practicesAndRoles.find((item) => item.practice_id == key);
        break;
      } else {
        const groupIds = Object.keys(selectedRoles);
        practiceAndRoles.push({ "practice_id": Number(key), "group_id": Number(groupIds[0]) });
      }
    }

    if (practiceAndRoleErrorFlag) {
      showNotifyWindow('show', 'error', i18n.t('errorMessages.role_selection_error'));
      return;
    }

    if (!has_error) {
      setShowLoadingBar(true);
      let result = null;
      const data = await createFormData()

      if (isAdminModule && defaultPractice !== "") {
        // Convert the practiceAndRoles array to a JSON string
        const practicesAndRolesJSON = JSON.stringify(practiceAndRoles);

        // Append the JSON string to the formData with the key "user_groups"
        data.append("user_groups", practicesAndRolesJSON);
      }
      if (isAdminModule == 'true') {
        data.append('staff_user_role',staffUserRole)
    }

      result = service.AddAssociateUser(data);
      result.then(response => {
        setShowLoadingBar(false);
        if (response.status === 201 || response.status === 200) {
          if (response.status === 201) {
            showNotifyWindow('show', 'success', USER_CREATED_SUCCESS);
          }
          else {
            showNotifyWindow('show', 'success', UPDATE_SUCCESS);
          }
          resetForm();
          setShowLoadingBar(false);

          setTimeout(() => {
            if (isAdminModule == "true") history.push(ROUTE_ASSOCIATE_USERS)
            else history.push(ROUTE_PRACTICE_ASSOCIATE_USERS)
          }, 2000)
        } else {
          /**
          * handling all errors
          */
          if (response.data.default_practice_changed) {
            handleDefaultPracticeChange(response.data.default_practice);
          } else if (response.status === 400 && response.data.user_already_exists !== undefined) {
            handleUserExistsError();
          } else if (response.status === 400 && response.data.message !== undefined && response.data.message === 'email_sending_failed') {
            handleEmailSendingFailedError();
          } else if (response.status === 400 && response.data.message !== undefined && (response.data.message === 'invalid_username' || response.data.message === 'invalid_username-whitespace' || response.data.message === 'username-already-exists')) {
            handleInvalidUsernameError(response.data.message);
          } else {
            showNotifyWindow('show', 'error', ADD_ERROR);
          }
        }
      });
    }
  }

  function handleDefaultPracticeChange(defaultPractice) {
    if (defaultPractice) {
      setStorage("practice", defaultPractice.id);
      setStorage("practiceName", defaultPractice.name + '(' + defaultPractice.custom_practice_id + ')');
      setReloadPage(true);
    } else {
      setStorage("practiceName", '');
      localStorage.removeItem("practice");
      window.location.reload();
    }
  }

  /**
  *user already exists error 
  */
  function handleUserExistsError() {
    showNotifyWindow('show', 'error', i18n.t('errorMessages.user_exists'));
  }

  /**
   * email related issues handled here
   * @param {*} message 
   */
  function handleEmailSendingFailedError() {
    showNotifyWindow('show', 'error', i18n.t('errorMessages.email_sending_failed'));
    setShowLoadingBar(false);
  }


  /**
   * username related errors handled heree
   * @param {*} message 
   */
  function handleInvalidUsernameError(message) {
    if (message === 'invalid_username-whitespace') {
      showNotifyWindow('show', 'error', i18n.t('errorMessages.invalid_username-whitespace'));
    } else if (message === 'username-already-exists') {
      showNotifyWindow('show', 'error', i18n.t('errorMessages.username-already-exists'));
    } else {
      showNotifyWindow('show', 'error', i18n.t('errorMessages.invalid_username'));
    }
  }

  useEffect(() => {
    setIsRequired((associateUserSelectedPractices && associateUserSelectedPractices.length > 0))
  }, [associateUserSelectedPractices, associateUserSelectedPractices])


  return (
    <>

      <div className='col-md-12 '>
        <div className='box-content-white'>
          <Grid container spacing={1} >
            <Notify
              showNotify={showNotify}
              setShowNotify={setShowNotify}
              notifyDescription={notifyDescription}
              setNotifyType={setNotifyType}
              setNotifyDescription={setNotifyDescription}
              notifyType={notifyType}
            />

            <Grid item xs={12} style={{ padding: '20px' }}>

              <Grid container spacing={1} className='mt-3'>

                <Grid item xs={2} >
                  <div className="modal-body col-2  text-center" style={{ marginLeft: '-21px' }}>
                    <div className="company-logo">
                      <button style={{ background: 'none', border: 'none', padding: '0' }} onClick={openImage}>
                        <img src={imageUrl} alt="Profile Name" />
                      </button>
                      <p className={
                        isLogoValid ? 'logo-txt' :
                          'text-red logo-txt'}>
                        <small>{''}</small>
                        {(formError.profilePicture.length > 0) &&
                          <div><small>{formError.profilePicture}</small>
                          </div>
                        }
                        <input type="file" id="file_userProfileAdd" name="file_userProfileAdd" onChange={onHandleChange} accept=".png, .jpg, .jpeg" />
                      </p>
                      < ImageCrop imgUrl={imgUrl} imageName={imageName} show={showImageCropperModalWindow} showModal={onChangeShowImageCropperModalWindow} changeCroppedImage={onCroppedImageChange} />

                    </div>
                  </div>
                </Grid>
                <Grid item xs={2} style={{ marginTop: '60px' }}>

                  <CommonButton variant="contained"
                    onClick={openImage}
                    icon='add'
                    label={i18n.t('commons.uploadNewPicture')} />

                  <input type="file" style={{ display: 'none' }} id="myProfile_input" name="myProfile_input" accept=".jpeg, .jpg, .png, .gif" />

                </Grid>
                <Grid item xs={2} style={{ marginTop: '60px', paddingLeft: '16px' }}>
                  <CommonButton variant="outlined"
                    onClick={onRemoveImage}
                    label={i18n.t('commons.delete')} />
                </Grid>
                <Grid item xs={6} >

                </Grid>

                <Grid item xs={4}>
                  <Item><TextInput
                    type="text"
                    id="lastName"
                    name="lastName"
                    label={i18n.t(
                      "userPages.associateUsers.labelLastName"
                    )}
                    onValueChange={onHandleChange}
                    required={true}
                    value={lastName}
                  /></Item>
                </Grid>
                <Grid item xs={4}>
                  <Item> <TextInput
                    type="text"
                    id="firstName"
                    name="firstName"
                    label={i18n.t(
                      "userPages.associateUsers.labelFirstName"
                    )}
                    onValueChange={onHandleChange}
                    required={true}
                    value={firstName}
                  /></Item>
                </Grid>
                <Grid item xs={4}>
                  <Item> <TextInput
                    type="text"
                    id="middleName"
                    name="middleName"
                    label={i18n.t(
                      "userPages.associateUsers.labelMiddleName"
                    )}
                    onValueChange={onHandleChange}
                    value={middleName}
                  /></Item>
                </Grid>

                <Grid item xs={4}>
                  <Item className='mt-1'>
                    <EmailInput
                      type="email"
                      id="email"
                      name="email"
                      label={i18n.t(
                        "userPages.associateUsers.labelEmail"
                      )}
                      onValueChange={onHandleChange}
                      required={true}
                      value={email}
                      placeholder="Email"
                    />
                  </Item>
                </Grid>
                <Grid item xs={4}>
                  <Item> <PhoneInput
                    name="phone"
                    label={i18n.t(
                      "userPages.associateUsers.labelPhone"
                    )}
                    onValueChange={setPhone}
                    value={phone}
                    required={true}
                  /></Item>
                </Grid>
                <Grid item xs={4}>
                  <Item className='mt-2'> <TextInput
                    type="text"
                    id="userID"
                    name="userID"
                    label={i18n.t(
                      "userPages.associateUsers.labelUsernamewithemail"
                    )}
                    onValueChange={onHandleChange}
                    required={true}
                    value={userID}
                    defaultClassName={userIdValidate && 'input-error'}
                  /></Item>
                </Grid>

               {isAdminModule == 'true' && <Grid item xs={4}>
                  <Item className='mt-2'>
                 <StaffUserRoleSelector
                 value = {staffUserRole}
                 onValueChange = {onHandleChange}
                 />
                  </Item>
                </Grid>}

                <Grid item xs={12}>
                  <Item>

                    <AssociateUsersPracticeRoleSelector
                      practicesList={practicesList}
                      practicesAndRoles={practicesAndRoles}
                      addPracticeDataToBox={addPracticeDataToBox}
                      associateUserSelectedPractices={associateUserSelectedPractices}
                      setSelectedPracticesAndRoles={setSelectedPracticesAndRoles}
                      selectedPracticesAndRoles={selectedPracticesAndRoles}
                      setDefaultPractice={setDefaultPractice}
                      defaultPractice={defaultPractice}
                      removePractice={removePractice}
                      resetForm={resetForm}
                    />
                  </Item>
                </Grid>

                <Grid item xs={3}>
                  <Item>
                    <SelectInput
                      data={practicesAndRoles?.filter(item => associateUserSelectedPractices.includes(item?.practice_id))?.map(practice => ({ id: practice.practice_id, name: practice.practice_name }))}
                      defaultLabel={i18n.t(
                        "userPages.associateUsers.labelDefaultPractice"
                      )}
                      name="defaultPractice"
                      id="defaultPractice"
                      value={defaultPractice}
                      onValueChange={onHandleChange}
                      label={i18n.t(
                        "userPages.associateUsers.labelDefaultPractice"
                      )}

                      required={isRequired}
                      showInRed={!defaultPractice}
                    />
                  </Item>
                </Grid>
                <Grid item xs={3}>
                  <Item>
                    <TextInput
                      type="text"
                      pattern="[0-9]*"
                      id="forcePassword"
                      name="forcePassword"
                      label={i18n.t(
                        "userPages.associateUsers.labelPasswordReset"
                      )}
                      onValueChange={onHandleChange}
                      value={forcePassword}
                    />
                  </Item>
                </Grid>
                <Grid item xs={3}>
                  <Item>

                    <label className="form-group padding-top20 mt-3">{i18n.t('userPages.associateUsers.labelNextReset')} : {nextReset}</label>

                  </Item>
                </Grid>
                <Grid item xs={3}>
                  <Item>
                    <SelectInput data={ACTIVE_STATUS} name={'is_active'} value={activeStatus} onValueChange={onHandleChange} label={'Is Active'} selectOptionRemove={true} defaultValue={true} />
                  </Item>
                </Grid>


              </Grid>

              <Grid container >
                <Grid item xs={10} >

                </Grid>

               
                <Grid item xs={2} sx={{ paddingLeft: '29px', display: 'flex', gap: '20px', justifyContent: 'right',paddingRight:"8px" }}>
                  <CommonButton label="Cancel" variant="outlined"
                    onClick={onCancel}
                  />
                  <CommonButton label="Save" variant="contained"
                    onClick={(e)=> onSaveFormData(e)}
                  />
                </Grid>
              </Grid>

            </Grid>
          </Grid>
        </div>
      </div>


    </>
  )
}

export default AssociateUserAdd