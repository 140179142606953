import React, { useEffect, useState } from "react";
import i18n from "../../../utilities/i18n";
import SelectInput from "../../commons/input/SelectInput";
import service from "./service";
import { checkPermission } from "../../../utilities/commonUtilities";
import { permission_key_values_accountsetup, super_admin_privileges } from "../../../utilities/permissions";
const YEARS = [
  { id: "2022", name: "2022" },
  { id: "2021", name: "2021" },
  { id: "2020", name: "2020" },
];
const SearchAndLoadSchedule = ({
  showNotification,
  setShowLoadingBar,
  onSearch,
  onclearData,
  scheduleState,
  setScheduleState,
  scheduleLocality,
  setScheduleLocality,
}) => {
  const [scheduleYearList] = useState(YEARS);
  const [scheduleStateList, setScheduleStateList] = useState([]);
  const [scheduleLocalityList, setScheduleLocalityList] = useState([]);
  const [loadButtonEnabled, setLoadButtonEnabled] = useState(false);
  const [scheduleYear,setScheduleYear] =useState("2022")


  useEffect(() => {
    setScheduleYear("2022");
  }, []);
  useEffect(() => {
    if (scheduleYear) {
     
      setShowLoadingBar(true);
      service
        .getStateList(scheduleYear)
        .then((response) => {
          setShowLoadingBar(false);
          if (response.status === 200) {
            const list = response.data.state_list;
            const states = [];
            list.forEach((element) => {
              states.push({ id: element, name: element });
            });
            setScheduleStateList(states);
          }
        })
        .catch(() => {
          setShowLoadingBar(false);
          showNotification("show", "error", i18n.t("errorMessages.commonError"));
        });
    }
  }, [scheduleYear]);
  useEffect(() => {
    if (scheduleState && scheduleYear) {
      setShowLoadingBar(true);
      service
        .getLocalityList(scheduleYear, scheduleState)
        .then((response) => {
          setShowLoadingBar(false);
          if (response.status === 200) {
            const list = response.data.locality_list;
            const localities = [];
            list.forEach((element) => {
              localities.push({ id: element, name: element });
            });
            setScheduleLocalityList(localities);
          }
        })
        .catch(() => {
          setShowLoadingBar(false);
          showNotification("show", "error", i18n.t("errorMessages.commonError"));
        });
    }
  }, [scheduleState]);

  const onYearChangeHandler =(e)=>{
   
    setScheduleYear(e.target.value)
    setScheduleState();
    setScheduleLocality();
    setScheduleStateList([]);
    setScheduleLocalityList([]);
    onclearData();
  }

  const onStateChangeHandler = (e) => setScheduleState(e.target.value);
  const onLocalityChangeHandler = (e) => setScheduleLocality(e.target.value);

  useEffect(() => {
    // Enable the button if both state and locality are present.
    if (scheduleState && scheduleLocality) {
      setLoadButtonEnabled(true);
    } else {
      setLoadButtonEnabled(false);
    }
  }, [scheduleState, scheduleLocality]);
  
  const onFormSubmitted = (e) => {
    e.preventDefault();
    if (scheduleYear && scheduleState && scheduleLocality)
      onSearch(scheduleYear, scheduleState, scheduleLocality, e);
    else if (scheduleYear && scheduleState)
      onSearch(scheduleYear, scheduleState, null, e);  
    else
        onSearch(scheduleYear,null,null, e);  
    };

  return (
    <div className="row">
      <div className="input-content-box">
        <SelectInput
          required="required"
          data={scheduleYearList}
          name="schedule_year"
          disabled={false}
          selectOptionRemove={true}
          value={scheduleYear}
          onValueChange={onYearChangeHandler}
          label={i18n.t(
            "customerSetup.feeSchedule.standardSchedule.filter.year"
          )}
        />
      </div>
      <div className="input-content-box">
        <SelectInput
          required="required"
          data={scheduleStateList}
          name="schedule_state"
          selectOptionRemove={true}
          value={scheduleState}
          disabled={false}
          onValueChange={onStateChangeHandler}
          label={i18n.t(
            "customerSetup.feeSchedule.standardSchedule.filter.state"
          )}
        />
      </div>
      <div className="input-content-box">
        <SelectInput
          required="required"
          data={scheduleLocalityList}
          name="schedule_locality"
          selectOptionRemove={true}
          disabled={false}
          value={scheduleLocality}
          onValueChange={onLocalityChangeHandler}
          label={i18n.t(
            "customerSetup.feeSchedule.standardSchedule.filter.locality"
          )}
        />
      </div>
      <div className="col padding-top13">
        {checkPermission(permission_key_values_accountsetup.account_setup_fee_schedule_management_view, super_admin_privileges.super_admin_full_privilege) &&
       <button
          type="button"
          onClick={onFormSubmitted}
          className="btn btn-primary-blue btn-common-height35 margin-top12"
          disabled={!loadButtonEnabled}
        >
          {i18n.t("paperClaims.load")}
        </button>}
      </div>
    </div>
  );
};

export default SearchAndLoadSchedule;
