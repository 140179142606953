import { Add, ChevronLeft, ChevronRight } from "@material-ui/icons";
import { Box, ClickAwayListener } from "@mui/material";
import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { ROUTE_USER_REPORTS_BUILDER_NEW } from "../../../utilities/staticConfigs";
import CustomizedSmallDialogs from "../../modalWindowComponent/CustomisedSmallDialog";
import ReportSelectorModal from "./ReportSelectorModal";
import classes from "./CustomDropdownButton.module.css";

const CustomDropdownButton = () => {
  const history = useHistory();
  const [open, setOpen] = useState(false);
  const [showTemplates, setShowTemplates] = useState(false);
  const handleClick = () => {
    setOpen((prev) => !prev);
  };

  const handleClickAway = () => {
    setOpen(false);
  };
  const onNewClickHanlder = () => {
    history.push(ROUTE_USER_REPORTS_BUILDER_NEW);
  };
  const onFromTemplateClickHanlder = () => {
    setOpen(false);
    setShowTemplates(true);
  };

  return (
    <>
      <CustomizedSmallDialogs
        showModal={showTemplates}
        type="alert"
        header={"Build From Templates"}
        setShowModalWindow={setShowTemplates}
        fullWidth={true}
        alertOK={() => {
          setShowTemplates(false);
        }}
      >
        <ReportSelectorModal />
      </CustomizedSmallDialogs>
      <ClickAwayListener onClickAway={handleClickAway}>
        <Box className={classes.buttonContainer}>
          <button
            type="button"
            className={`btn btn-primary-blue btn-block ${classes.button} ${classes.child}`}
            onClick={handleClick}
          >
            <Add />
            New Report
            {open ? <ChevronLeft /> : <ChevronRight />}
          </button>
          {open ? (
            <Box className={`${classes.popup} ${classes.child}`}>
              <ul className={classes.unorderMenu}>
                <li className={classes.menuItem} onClick={onNewClickHanlder}>
                  From Scratch
                </li>
                <li
                  className={classes.menuItem}
                  onClick={onFromTemplateClickHanlder}
                >
                  From Template
                </li>
              </ul>
            </Box>
          ) : null}
        </Box>
      </ClickAwayListener>
    </>
  );
};

export default CustomDropdownButton;
