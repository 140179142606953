import React from 'react';
import './AnimationLoader.css';


const AnimationLoader = () => {
    return (
        <div className="containerLoader" >
            <div className="cube">
                <div className="cube__inner"></div>
            </div>
            <div className="cube">
                <div className="cube__inner"></div>  
            </div>
            <div className="cube">
                <div className="cube__inner"></div>
            </div>
        </div>
    );
};

export default AnimationLoader;

