/* eslint-disable react/no-unknown-property */
import React, { useEffect, useState } from 'react'
import FormControl from '@mui/material/FormControl';
import AutoSelect from '@mui/material/Autocomplete';  // DOCUMENTATION https://mui.com/components/autocomplete/
import Checkbox from '@mui/material/Checkbox';
import { TextField } from '@material-ui/core';
import "./style.css";
import i18n from '../utilities/i18n';
import { ModalButtonWindow } from '../components/ModalButtonWindow/ModalButtonWindow';
import Label from '../components/commons/Label';

const icon = <Checkbox checked={false} />
const checkedIcon = <Checkbox checked={true} />

function MultiSearch(props) { 
    let labelString = props.label ? props.label : '';

    const [optionValues, setOptionValues] = useState([]);
    const [selectedValues, setSelectedValues] = useState([]);

    // for modal button
    const [showModalButtonScreen, setShowModalButtonScreen] = useState(false);

    function onSearchQuery(e) {
        let value = e.target.value;
        props.onSearchApi(value);
    }
    useEffect(()=>{
     if(props?.optionValues?.length == 0)
     {
        setOptionValues([])
     }
    },[props?.optionValues?.length])

    useEffect(() => {
      props.setOptionValues(optionValues);
    }, [optionValues]);

    useEffect(() => {
        if (selectedValues.length > 0 && props.setSelectedItems) {
            props.setSelectedItems(selectedValues)
        }
    },[selectedValues])

    useEffect(() => {
        if (props.selectedValues) {
            setSelectedValues(props.selectedValues);
            props.selectedValues.map((item)=>{
                optionValues.push(item.Id || item.id);
            });
        }
    }, [props.selectedValues]);
    

    function handleChange(e, id, name) {
        e.stopPropagation();
        let newObject = {"id": id, "name": name};
        setSelectedValues((prevValue)=>{
            let itemPresent = prevValue.find((item)=>item.id == id);
            if(!itemPresent){
                let newArray = [
                    ...prevValue,newObject
                ];
                let check = optionValues;
                check.push(id);
                setOptionValues(check);
                return newArray;
            }else if (itemPresent){
                let newArray = prevValue.filter((item)=>item.id != id);
                let uncheck = optionValues;
                let indexOfUncheckedValue = optionValues.findIndex((item)=> item.id == id);
                uncheck.splice(indexOfUncheckedValue,1);
                setOptionValues(uncheck);
                return newArray;
            }
        });
    }

    function handleOnChange(e, value) {
        setSelectedValues(value);
        setOptionValues(()=>{
            let newArray = [];
            value.map((item)=>{
                newArray.push(item.id);
            })
            return newArray;
        });
        props.setOptionItems([]);
    }

    // for modal button 
    const onSelectContacts=()=>{ 
        setShowModalButtonScreen(true);
    }

    function onHandleCheckBox(e,item){
        let newObject = {"id": item.id, "name": item.name};
        let present = selectedValues.find((obj)=>obj.id == newObject.id);
        if (!present){
            setSelectedValues([...selectedValues, newObject]);
            setOptionValues([...optionValues,newObject.id]);
        }else{
            setSelectedValues((prevValue)=>{
                let newSelectedArray = prevValue.filter((obj)=>obj.id != newObject.id);
                let newOptionValueArray = optionValues.filter((obj)=>obj != newObject.id);
                setOptionValues(newOptionValueArray);
                return newSelectedArray;
            });
        }
    }
    function filterSelectedUsers() {
        setShowModalButtonScreen(false);
    }
  return (
      <div className='row'>
        <div className='col'>
              <FormControl className={(props.required && optionValues.length === 0) ? "multi-select-search input-error" : "multi-select-search"} >
                  <Label label={labelString} />
                <AutoSelect
                    multiple
                    limitTags={1}
                    closeText=''
                      size="small"
                      variant="outlined"
                    id={props.id ? props.id : ''}
                    options={props.optionItems}
                      getOptionLabel={(option) => option.name}
                      isOptionEqualToValue={(option, value) => option.id === value.id && option.name === value.name}
                    noOptionsText=""
                    renderInput={(params) => (
                        <TextField {...params} onChange={onSearchQuery} placeholder={''} variant="outlined" />
                    )}
                      renderOption={(props, option) => (
                        <li className="max-height-35" {...props.optionItems} index={option.id} >
                            <Checkbox
                                id={"chk-box-" + option.id}
                                icon={icon}
                                checkedIcon={checkedIcon}
                                style={{ marginRight: 1 }}
                                checked={Array.isArray(optionValues) && optionValues.indexOf(option.id) > -1}
                                onClick={e => handleChange(e, option.id, option.name)}
                            />
                              <label  index= {option.id} htmlFor={"chk-box-" + option.id} style={{ marginTop: '9px', cursor: 'pointer' }} 
                            onClick={e => handleChange(e, option.id, option.name)}
                            >{option.name}</label>
                        </li>
                    )}
                    value={(selectedValues && Array.isArray(selectedValues)) ? selectedValues : []}
                    onChange={(event, newValue) => handleOnChange(event, newValue)}
                />
            </FormControl>
        </div>
        <div className='margin-right15 margin-top26'>
              <button type="button" data-testid="searchcontact-1" className="small_box btn-primary-blue btn" onClick={onSelectContacts}>
                  <i className='fas fa-address-book'></i>{i18n.t("inboxScreens.composeScreen.selectContacts")}
            </button>
        </div>
        <div>
            <ModalButtonWindow show={showModalButtonScreen} setShow={setShowModalButtonScreen} 
            onSaveModalButton={filterSelectedUsers} optionValues={optionValues}
                  data={props.dataList} onCheckBoxChange={onHandleCheckBox}   
            />
        </div>
    </div>
  )
}

export default MultiSearch