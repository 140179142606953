// Creating a shared worker instance for dispatching functions and receiving data across components
// This allows us to dispatch API request functions from any report component where the instance is imported
// The returned data can be accessed from any other component to update the Redux state


import worker from 'workerize-loader!./worker'; // eslint-disable-line import/no-webpack-loader-syntax

const workerInstance = worker();

export default workerInstance;
