
import React, { useState } from 'react'
import Popover from '@mui/material/Popover';
import { Checkbox, Typography } from '@mui/material';
import Stack from '@mui/material/Stack';
import settings from "../../../assets/images/setting13.svg"

function TableHeaderSelector (props) {
    
    const [popOverAnchor, SetPopOverAnchor] = useState(null);
    const [isPopOverOpen, SetIsPopOverOpen] = useState(false);
     const handleClickPopOver = (event) => {
        SetPopOverAnchor(event.currentTarget);
        SetIsPopOverOpen(true)
     };
    const handlePopOverClose = () => {
        SetPopOverAnchor(null);
        SetIsPopOverOpen(false)

    };


    return (
        <>
            <div  style={{marginBottom:'20px',paddingLeft:'10px'}}>
                <img src={settings} style={{ height: '36px', width: '36px', cursor: 'pointer' }} alt="" onClick={handleClickPopOver} />
                <div className='row'>                  
                    <div >
                            <Popover
                                id={1}
                                open={isPopOverOpen}
                                anchorEl={popOverAnchor}
                                onClose={handlePopOverClose}
                                anchorOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'right',
                                }}
                                transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'right',
                                }}
                            >
                                {
                                props.options.length > 0 ?
                                    props.options.map((item) => {
                                        const isChecked = props.selected.some(item1 => item1 === item);
                                            return (
                                                <Stack
                                                    key={item}
                                                    sx={{
                                                        boarderRadius: '3px',
                                                        cursor: 'pointer',
                                                        background: isChecked ? 'rgba(158,207,250,0.3)' : '',
                                                        '&:hover': {
                                                            backgroundColor: !isChecked ? '#dbdfe2a8' : ''
                                                        },
                                                    }}
                                                    onClick={() => props.MaterialMultiselectHandleChange(item)}
                                                    direction="row"
                                                    alignItems="center"
                                                    gap={1}
                                                    padding="5px"
                                                >
                                                    <Checkbox checked={isChecked} style={{ color: isChecked ? '#404040' : 'inherit' }} />
                                                    <Typography variant='subtitle1'>
                                                        {item}
                                                    </Typography>
                                                </Stack>
                                            )
                                        })
                                        : null
                                }
                            </Popover>
                    </div>
                </div>

          
                <div>
                </div>
            </div>

        </>
    )
}



export default TableHeaderSelector;