import React, { useState, useRef } from 'react'
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import './style.css'
import MessageServices from "./service"
import { getStorage } from '../../../utilities/browserStorage';
import i18n from '../../../utilities/i18n'

function RichTextEditor(props) {
    const [practicePK] = useState(getStorage('practice'));
    const messageId = useRef(''); 

    function uploadAdapter(loader) {
        return {
            upload: () => {
                return new Promise((resolve) => {
                    let imageData = new FormData();
                    loader.file.then((file) => {
                        // data = {'message_id': '', 'is_draft': true, 'practice': 1, 'message_image': <<File>>}
                        imageData.append("message_id",messageId.current);
                        imageData.append("is_draft",  true);
                        imageData.append("practice",  practicePK);
                        imageData.append("message_image", file);      
                        let result = null;
                        result = MessageServices.PostMessageImage(imageData);
                        result.then(response => { 
                            if(response.status === 201 || response.status === 200){
                                messageId.current = response?.data?.id;
                                props.setDraftMessageId(response?.data?.id); 
                                if (props.setMessageImages) {
                                    props.setMessageImages(response?.data?.images);
                                }
                                resolve({ default: response.data.message_image_url }); 
                                messageId.current = response?.data?.id; 
                            } 
                            else{
                                props.showNotifyWindow('show','error',i18n.t("inboxScreens.richTextEditor.msgImageUploadFailed")); 
                            }
                        });
                    })
                })
            }
        }
    }

    function uploadPlugin(editor) {
        editor.plugins.get("FileRepository").createUploadAdapter = (loader) => {
            return uploadAdapter(loader);
        }
    }
    
  return (
    <div className='editor'>
        <CKEditor 
            config={{
                extraPlugins: [uploadPlugin],
                removePlugins:['Indent'],
                link: {
                    addTargetToExternalLinks: true,
                }
            }}
            editor={ClassicEditor}
            disabled={props.disabled}
            data={props.state}
            onChange={(event,editor)=>{
                let data = editor.getData();
                props.setState(data);
            }}
        />
    </div>
  )
}

export default RichTextEditor