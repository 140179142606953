import React, { useContext, useEffect, useState } from "react";
import { useHistory } from 'react-router-dom';
import moment from 'moment';
import { Dropdown } from "react-bootstrap";
import SearchMessage from "./SearchMessage";
import './style.css';
import service from "./service";
import { InboxTableData, TableBodyData } from './InboxTable';

import actionImg from '../../assets/images/action.png';

import LoadingContext from "../../container/loadingContext";

import Notify from "../commons/notify";
import Table from '../commons/Table/Table';
import Pagination from "../commons/pagination";

import i18n from "../../utilities/i18n";
import { getStorage } from "../../utilities/browserStorage";
import * as staticConfigs from '../../utilities/staticConfigs';
import { DELETE_MESSAGE_SUCCESS } from "../../utilities/labelConfigs";
import { checkPermission } from "../../utilities/commonUtilities";
import { permission_key_values_messages } from "../../utilities/permissions";

export const Inbox = () => {
    const practicePK = getStorage('practice');
    const setShowLoadingBar = useContext(LoadingContext);

    const [arrayOfIdOfMessageList, setArrayOfIdOfMessageList] = useState([]);
    
    const [inboxMessageList, setInboxMessageList] = useState([]);
    const [selectedMessageList, setSelectedMessageList] = useState([]);
    const [allCheckBoxChecked, setAllCheckBoxChecked] = useState(false);

    // notify
    const [showNotify, setShowNotify] = useState("hide");
    const [notifyDescription, setNotifyDescription] = useState("");
    const [notifyType, setNotifyType] = useState("success");

    // pagination 
    const [totalPage, setTotalPage] = useState(1);
    const [activePage, setActivePage] = useState(1);
    const [startIndex, setStartIndex] = useState(0);
    const [endIndex, setEndIndex] = useState(staticConfigs.PAGING_END_INDEX);
    const [disableDropdown, setDisableDropdown] = useState(false);
 

    function onPagePrevious() {
        let previousPage = startIndex + 1 - staticConfigs.PAGING_END_INDEX;
        setActivePage(previousPage);
        if (startIndex !== 0) {
            setStartIndex(startIndex - staticConfigs.PAGING_END_INDEX);
            setEndIndex(endIndex - staticConfigs.PAGING_END_INDEX);
        }
        getInboxMessages(staticConfigs.DEFAULT_PAGING_SIZE, previousPage);
    }

    function onPageUp(e) {
        let page = Number(e.target.id)
        setActivePage(page);
        getInboxMessages(staticConfigs.DEFAULT_PAGING_SIZE, page);
    }

    function onPageNext() {
        let nextPage = startIndex + 1 + staticConfigs.PAGING_END_INDEX;
        if (endIndex === totalPage || totalPage <= staticConfigs.PAGING_END_INDEX) {
            setActivePage(nextPage);
            setStartIndex(startIndex);
            setStartIndex(endIndex);
        } else {
            setActivePage(nextPage);
            setStartIndex(startIndex + staticConfigs.PAGING_END_INDEX);
            setEndIndex(endIndex + staticConfigs.PAGING_END_INDEX);
        }
        getInboxMessages(staticConfigs.DEFAULT_PAGING_SIZE, nextPage);
    }

    const history = useHistory();

    function onSelectRowAll(e) {
        let selected = e.target.checked;
        setAllCheckBoxChecked(selected);

        InboxTableData.tableBodyData.forEach(rowItem => {
            let index = rowItem.findIndex(cItem => cItem.type == 'checkbox');
            rowItem[index].value = selected;
        });

        setInboxMessageList(() => {
            let newArray = [];
            inboxMessageList.map((item, index) => {
                newArray[index] = { ...item, 'checked': selected };
            });
            return newArray
        });
    }

    function onSelectRow(e, id) {
        
        
        e.stopPropagation();
        let selected = e.target.checked;

        InboxTableData.tableBodyData.forEach(rowItem => {
            if (rowItem.find(colItem => colItem.id == id)) {
                let index = rowItem.findIndex(cItem => cItem.type == 'checkbox');
                rowItem[index].value = selected;
            }
        });

        let newArray = [];
        setInboxMessageList(() => {
            inboxMessageList.map((item, index) => {
                if (item.message_id == id) newArray[index] = { ...item, 'checked': selected };
                else newArray[index] = { ...item }
            });
            let selectedArray = newArray.filter((item) => item.checked );
            if (selectedArray.length == newArray.length) {
                setAllCheckBoxChecked(true);
            } else {
                setAllCheckBoxChecked(false);
            }
            return newArray;
        });
    }



    useEffect(() => {
        setDisableDropdown(() => {
            return selectedMessageList.length === 0 ? true : false;
        })
    }, [selectedMessageList])


    function showNotifyWindow(action, type, desc, age = 3000) {
        if (action === "show") {
            setTimeout(() => {
                setShowNotify("hide");
            }, age);
        }
        setShowNotify(action);
        setNotifyType(type);
        setNotifyDescription(desc);
    }

    useEffect(() => {
        getInboxMessages(staticConfigs.DEFAULT_PAGING_SIZE, activePage);
    }, []);

    function getInboxMessages(pageSize, page) {
        let query = '?practice_pk=' + practicePK + '&page_size=' + pageSize + '&page=' + page;
        setShowLoadingBar(true);
        let result = service.ListInbox(query);
        let newArray = [];
        let arrayId = [];
        let rowArray = [];
        result.then((response) => {
            setAllCheckBoxChecked(false);
            let data = response.data.results;
            data.map((item, index) => {
                newArray[index] = { ...item, 'checked': false };
                arrayId.push(item.message_id)
            });
            setTotalPage(Math.ceil(response.data.count / response.data.page_size));
            setArrayOfIdOfMessageList(arrayId);

            let anArray = [];
            let newData = Array(response.data.results.length).fill(JSON.parse(JSON.stringify(InboxTableData.tableBodyData[0])));
            newData.map((row, rowIndex) => {
                row.map((col) => {
                    let colObject = {};
                    if (col.type === 'checkbox') {
                        colObject = {
                            ...col, 'value': false,
                            "id": response.data.results[rowIndex].message_id,
                            'is_read': response.data.results[rowIndex].is_read
                        }
                    }
                    else if (col.name === 'sender') {
                        let senders = response.data.results[rowIndex][col.name].map(snd => snd.name)
                        colObject = {
                            ...col, 'value': senders,
                            "id": response.data.results[rowIndex].message_id,
                            'is_read': response.data.results[rowIndex].is_read
                        }
                    }
                    else if (col.name === 'receivers') {
                        let receivers = response.data.results[rowIndex][col.name].map(snd => snd.name)
                        colObject = {
                            ...col, 'value': receivers,
                            "id": response.data.results[rowIndex].message_id,
                            'is_read': response.data.results[rowIndex].is_read
                        } 
                    }
                    else if (col.name === 'created_on') {
                        colObject = {
                            ...col, 'value': moment(response.data.results[rowIndex][col.name]).format('MM/DD/YYYY  h:mm a'),
                            "id": response.data.results[rowIndex].message_id,
                            'is_read': response.data.results[rowIndex].is_read
                        }
                    }
                    else {
                        colObject = {
                            ...col, 'value': response.data.results[rowIndex][col.name],
                            "id": response.data.results[rowIndex].message_id,
                            'is_read': response.data.results[rowIndex].is_read
                        }
                    }
                    anArray.push(colObject)
                })

                rowArray.push(anArray)
                anArray = [];
            });
            if (rowArray.length > 0) {
                InboxTableData.tableBodyData = rowArray;
            }
            else {
                InboxTableData.tableBodyData = TableBodyData;
            }
            setInboxMessageList(newArray);
            setShowLoadingBar(false);
        })
    }

    useEffect(() => {

        let rowArray = [];

        let anArray = [];
        let newData = Array(inboxMessageList.length).fill(JSON.parse(JSON.stringify(InboxTableData.tableBodyData[0])));
        newData.map((row, rowIndex) => {
            row.map((col) => {
                let colObject = {};
                if (col.type === 'checkbox') {

                    colObject = {
                        ...col, 'value':inboxMessageList[rowIndex].checked,
                        "id": inboxMessageList[rowIndex].message_id,
                        'is_read': inboxMessageList[rowIndex].is_read
                    }
                }
                else if (col.name === 'sender') {
                    let senders = inboxMessageList[rowIndex].sender.map(d => d.name)
                    colObject = {
                        ...col, 'value': senders,
                        "id": inboxMessageList[rowIndex].message_id,
                        'is_read': inboxMessageList[rowIndex].is_read
                    }
                }
                else if (col.name === 'receivers') {
                    let receivers = inboxMessageList[rowIndex].receivers.map(d => d.name)
                    colObject = {
                        ...col, 'value': receivers,
                        "id": inboxMessageList[rowIndex].message_id,
                        'is_read': inboxMessageList[rowIndex].is_read
                    }
                }
                else if (col.name === 'created_on') {
                    colObject = {
                        ...col, 'value': moment(inboxMessageList[rowIndex][col.name]).format('MM/DD/YYYY  h:mm a'),
                        "id": inboxMessageList[rowIndex].message_id,
                        'is_read': inboxMessageList[rowIndex].is_read
                    }
                }
                else {
                    colObject = {
                        ...col, 'value': inboxMessageList[rowIndex][col.name],
                        "id": inboxMessageList[rowIndex].message_id,
                        'is_read': inboxMessageList[rowIndex].is_read
                    }
                }
                anArray.push(colObject)
            })
            rowArray.push(anArray)
            anArray = [];
        });
        if (rowArray.length > 0) {
            InboxTableData.tableBodyData = rowArray;
        }
        else {
            InboxTableData.tableBodyData = TableBodyData;
        }


        setSelectedMessageList(() => {
            let selected_ids = [];
            inboxMessageList.filter((item) => item.checked).map((item) => {
                selected_ids.push(item.message_id);
            });
            return selected_ids;
        });
    }, [inboxMessageList]);


  

    function DeleteSendMessageList() {
        if (selectedMessageList.length === 0) return;
        setShowLoadingBar(true);
        let data = {
            "message_type": "inbox",
            "message_id": selectedMessageList
        }
        let result = service.DeleteMessage(data);
        result.then((response) => {
            setShowLoadingBar(false);
            if (response.status === 200) {
                showNotifyWindow("show", "success", DELETE_MESSAGE_SUCCESS);
                let page = 1
                getInboxMessages(staticConfigs.DEFAULT_PAGING_SIZE, page);
            }
        })
    }
    const sendReadStatusForAll = (id) => {
        if (selectedMessageList.length === 0) return;
        setShowLoadingBar(true);
        let data = {
            'message_id': selectedMessageList,
            'is_read': true
        };
        let result = service.SendReadStatus(id, data);
        result.then(() => {
            getInboxMessages(staticConfigs.DEFAULT_PAGING_SIZE, activePage);
        })
            .finally(() => {
                setShowLoadingBar(false);
            })
    }
    function MarkUnread(id) {
        if (selectedMessageList.length === 0) return;
        setShowLoadingBar(true);
        let data = { 'message_id': selectedMessageList, 'is_read': false };
        let result = service.SendReadStatus(id, data);
        result.then(() => {
            getInboxMessages(staticConfigs.DEFAULT_PAGING_SIZE, activePage);
        })
            .finally(() => {
                setShowLoadingBar(false);
            });
    }

    function To_inbox_messages(e, id, isRead, index) {
        history.push(staticConfigs.ROUTE_INBOX_MESSAGES, { 'id': id, 'list': arrayOfIdOfMessageList, 'index': index });
    }

    function Refresh() {
        window.location.reload()
    }

    function onRowClick(e, id) {
        let index = 0;
        InboxTableData.tableBodyData.forEach(rowItem => {
            if (rowItem[0].id == id) {
                To_inbox_messages(e, rowItem[0].id, rowItem[0].is_read, index);
                return true;
            }
            index++;
        });
    }

    function onClickGridCheckBox(e, clickedFrom, id) {
        e.stopPropagation();
        if (clickedFrom == 'data') {
            onSelectRow(e, id);
        }
    }

    return (
        <div className="col-md-12">
            <div className="box basic-info-padding bordering border-radius-8"  style={{ padding: "15px"}}>
                <Notify showNotify={showNotify} setShowNotify={setShowNotify} notifyDescription={notifyDescription} setNotifyType={setNotifyType} setNotifyDescription={setNotifyDescription} notifyType={notifyType} />
                <div className="row justify-space-between">
                    <div className="col d-flex">
                    {checkPermission(permission_key_values_messages.can_modify_inbox_messages) &&
                     <Dropdown className="padding-top2">
                            <Dropdown.Toggle variant="" id="dropdown-basic">
                                <input type="checkbox" data-testid="checkbox-all" name={'all'} value={'all'} className='mr-1 sizeChecbox' checked={allCheckBoxChecked} onChange={(e) => onSelectRowAll(e, 'all')} />
                            </Dropdown.Toggle>
                        </Dropdown>}
                        <Dropdown>
                            <Dropdown.Toggle variant="" id="dropdown-basic-refresh" data-testid="refresh-button" onClick={Refresh}>
                                <i className="fa-solid fa-rotate refreshIcon"></i>
                            </Dropdown.Toggle>
                        </Dropdown>
                        {checkPermission(permission_key_values_messages.can_modify_inbox_messages) &&
                         <Dropdown className="">
                            <Dropdown.Toggle variant="" id="dropdown-basic" align={{ lg: 'end' }} data-testid="dropdown-toggle-3">
                                <img src={actionImg} alt='' />
                            </Dropdown.Toggle>
                            <Dropdown.Menu className="message_dropddown">
                                <Dropdown.Item data-testid="mark-read" href="#/action-1" disabled={disableDropdown} onClick={sendReadStatusForAll}>{i18n.t("inboxScreens.inboxScreen.markAsRead")}</Dropdown.Item>
                                <Dropdown.Item data-testid="mark-un-read" href="#/action-2" disabled={disableDropdown} onClick={MarkUnread}>{i18n.t("inboxScreens.inboxScreen.markAsUnRead")}</Dropdown.Item>
                                <Dropdown.Item data-testid="delete-msg" href="#/action-3" disabled={disableDropdown} onClick={DeleteSendMessageList}>{i18n.t("inboxScreens.inboxScreen.delete")}</Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>}
                    </div>
                    <div className="padding-right8 padding-top10">
                        <SearchMessage type='inbox' setValue={setInboxMessageList} onClear={getInboxMessages} />
                    </div>
                </div>
                <div className="table-responsive">
                    <Table tableObject={InboxTableData} isCheckboxDisabled={checkPermission(permission_key_values_messages.can_modify_inbox_messages)} onRowClick={onRowClick} onClickGridCheckBox={onClickGridCheckBox} />
                </div>
                <div className="mt-2">
                    <Pagination totalPage={totalPage} activePage={activePage} startIndex={startIndex} endIndex={endIndex} onPagePrevious={onPagePrevious} onPageUp={onPageUp} onPageNext={onPageNext} />
                </div>
            </div>
        </div>
    );
};
