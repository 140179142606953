import React, { useState, useEffect, useContext } from 'react';
import { Form } from 'react-bootstrap';
import CustomizedDialogs from '../../modalWindowComponent/CustomizedDialogs';
import service from './service';
import { ROUTE_SUPER_DICTIONARIES, PAGING_END_INDEX, DEFAULT_PAGING_SIZE } from '../../../utilities/staticConfigs';
import { ADD_SUCCESS, ADD_ERROR, UPDATE_SUCCESS, DELETE_SUCCESS } from '../../../utilities/labelConfigs';
import Notify from '../../commons/notify';
import Pagination from '../../commons/pagination';
import LoadingContext from '../../../container/loadingContext';
import i18n from '../../../utilities/i18n';
import TextInput from '../../commons/input/input';
import CustomizedSmallDialogs from '../../modalWindowComponent/CustomisedSmallDialog';
import Table from '../../commons/Table/Table';
import { ClaimFrequencyTableData } from './ClaimFrequencyTable';
import { checkPermission, commonTableBody } from '../../../utilities/commonUtilities';
import BackArrowWithLabel from "../../commons/Back"; 
import CommonButton from '../../commons/Buttons';
import { super_admin_permission_key_value, super_admin_privileges } from '../../../utilities/permissions';


const ClaimFrequency = (props) => {
	const [showModalWindow, setShowModalWindow] = useState(false);
	const [showDeleteModalWindow, setShowDeleteModalWindow] = useState(false);
	const [claimFrequency, setClaimFrequency] = useState('');
	const [description, setDescription] = useState('');
	const [deleteClaimFrequency, setDeleteClaimFrequency] = useState('');
	const [deleteClaimFrequencyId, setDeleteClaimFrequencyId] = useState('');
	const [editClaimFrequencyId, setEditClaimFrequencyId] = useState('');
	const [claimFrequencyList, setClaimFrequencyList] = useState([]);
	const [form_error, setFormError] = useState({ 'claimFrequency': '', 'description': '' });
	const [editForm, setEditForm] = useState(false);
	const [header, setHeader] = useState('');
	const [permission, setPermission] = useState(false);
	const [searchValue, setSearchValue] = useState('');
    

	//start ==== Alert message properties
	const [showNotify, setShowNotify] = useState('hide');
	const [notifyDescription, setNotifyDescription] = useState('');
	const [notifyType, setNotifyType] = useState('success');
	const setShowLoadingBar = useContext(LoadingContext);
	function showNotifyWindow(action, type, desc, age = 3000) {
		if (action === 'show') {
			setTimeout(() => {
				setShowNotify('hide');
			}, age)
		}
		setShowNotify(action);
		setNotifyType(type);
		setNotifyDescription(desc);

	}
	// end

	//Pagination start
	const [totalPage, setTotalPage] = useState(1);
	const [activePage, setActivePage] = useState(1);
	const [startIndex, setStartIndex] = useState(0);
	const [endIndex, setEndIndex] = useState(PAGING_END_INDEX);

	function onPagePrevious() {
		let previousPage = startIndex + 1 - PAGING_END_INDEX;
		setActivePage(previousPage);

		if (startIndex !== 0) {
			setStartIndex(startIndex - PAGING_END_INDEX);
			setEndIndex(endIndex - PAGING_END_INDEX);
		}
		getClaimFrequencyData(DEFAULT_PAGING_SIZE, previousPage, searchValue);
	}

	function onPageUp(e) {
		let page = Number(e.target.id)
		setActivePage(page);
		getClaimFrequencyData(DEFAULT_PAGING_SIZE, page, searchValue);
	}

	function onPageNext() {
		let nextPage = startIndex + 1 + PAGING_END_INDEX;
		if (endIndex === totalPage || totalPage <= PAGING_END_INDEX) {
			setActivePage(nextPage);
			setStartIndex(startIndex);
			setStartIndex(endIndex);

		} else {
			setActivePage(nextPage);
			setStartIndex(startIndex + PAGING_END_INDEX);
			setEndIndex(endIndex + PAGING_END_INDEX);

		}
		getClaimFrequencyData(DEFAULT_PAGING_SIZE, nextPage, searchValue);

	}
	//Pagination ends

	function addNew() {
		if (!permission) {
			showNotifyWindow('show','error', i18n.t('errorMessages.permission_error'));
			return;
		}
		setHeader(i18n.t("dictionariesPages.dictClaimFrequency.addHeader"));
		setShowModalWindow(true);
	}

	function onHide() {
		setEditForm(false);
	}
	function onDeleteHide() {
		setDeleteClaimFrequency('');
		setDeleteClaimFrequencyId('');
		setShowDeleteModalWindow(false);
	}

	function getClaimFrequencyData(pageSize, page, searchString) {
		setShowLoadingBar(true);
		const result = service.GetListClaimFrequency(pageSize, page,"", searchString);

		result.then(response => {
			if (response.data.results !== undefined) {
				setTotalPage(Math.ceil(response.data.count / response.data.page_size));
			}
			setShowLoadingBar(false);
			if(response.data?.results) {
				response.data.results = response.data.results?.map(item => {
					if (item.not_delete === true) {
						item.isDefault = "Yes";
					} else {
						item.isDefault = "No";
					}
					return item;
				});
			}
			const rowArray = commonTableBody(response.data.results, ClaimFrequencyTableData.tableBodyData[0]);
			ClaimFrequencyTableData.tableBodyData = rowArray;
			setClaimFrequencyList(response.data.results);
			//setShowLoadingBar(false);
		});
	}

	useEffect(() => {
		getClaimFrequencyData(DEFAULT_PAGING_SIZE, activePage);
		setPermission(checkPermission(super_admin_privileges.super_admin_full_privilege,super_admin_permission_key_value.dictionary_add))
	}, []);

	function onEditClaimFrequency(id) {
		setShowLoadingBar(true);
		const data = service.GetClaimFrequency(id);
		data.then(response => {
			setShowLoadingBar(false);
			setHeader(i18n.t("dictionariesPages.dictClaimFrequency.editHeader"));
			setShowModalWindow(true);
			setClaimFrequency(response.data.name);
			setDescription(response.data.description);
			setEditForm(true);
			setEditClaimFrequencyId(id);
		});
	}
	function onDeleteClaimFrequency(id) {
		claimFrequencyList.map((item) => {
			if (item.id === parseInt(id)) {
				setDeleteClaimFrequency(item.name);
				setDeleteClaimFrequencyId(item.id);
				setShowDeleteModalWindow(true);
			}

		});
	}

	function onDeleteAction() {
		setShowLoadingBar(true);
		const data = service.DeleteClaimFrequency(deleteClaimFrequencyId);
		data.then(() => {
			setShowLoadingBar(false);
			setDeleteClaimFrequency('');
			setDeleteClaimFrequencyId('');
			setShowDeleteModalWindow(false);
			showNotifyWindow('show', 'success', DELETE_SUCCESS);
			getClaimFrequencyData(DEFAULT_PAGING_SIZE, activePage, searchValue);
		});
	}

	function resetForm() {
		setClaimFrequency('');
		setDescription('');
		setEditForm(false);
		setFormError({
			...form_error,
			['claimFrequency']: '',
			['description']: ''
		});

	}
	function onSaveFormData(e) {
		e.preventDefault();
		setFormError({
			...form_error,
			['claimFrequency']: !claimFrequency.trim() ?'error' : '',
			['description']: !description ? 'error' : ''
		});

		if (claimFrequency && description) {
			setShowLoadingBar(true);
			let result = null;
			result=editForm ? service.UpdateClaimFrequency(editClaimFrequencyId, { name: claimFrequency, description: description }) :
				service.AddClaimFrequency({ name: claimFrequency, description: description });
			result.then(response => {
				setShowLoadingBar(false);
				if (response.status === 201 || response.status === 200) {
					if (response.status === 201)
						showNotifyWindow('show', 'success', ADD_SUCCESS);
					else showNotifyWindow('show', 'success', UPDATE_SUCCESS);

					getClaimFrequencyData(DEFAULT_PAGING_SIZE, activePage, searchValue);
					resetForm();
					setShowModalWindow(false);

				} else if (response.status === 400 && response.data.record_already_exists !== undefined) {
					showNotifyWindow('show', 'error', i18n.t('errorMessages.record_exists'));
				} else {
					showNotifyWindow('show', 'error', ADD_ERROR);
				}


			});
		}

	}

	const handleKeyDown = (e) => {
        if (e.key === 'Enter' && searchValue) {
            onSearch(e, searchValue);
        }
    } 

	function onSearch(e, searchString) {
        e.preventDefault();
        setActivePage(1)
        setStartIndex(0);
        getClaimFrequencyData(DEFAULT_PAGING_SIZE, 1, searchString);
    }
	const onClose = () => {
        setSearchValue('');
        setActivePage(1)
        setStartIndex(0);
		getClaimFrequencyData(DEFAULT_PAGING_SIZE, 1);
    }
	function onHandleChange(e) {
        const { name } = e.target;
        let value = e.target.value;
        let trimmedValue = "";
       if (e.target.type === "text") {
            if (e.target.validity.valid) trimmedValue = value;
            else {
                trimmedValue = claimFrequency;
            }
        } else {
            trimmedValue = value;
        }
        if (!trimmedValue) {
            setFormError({
                ...form_error,
                [name]: "error",
            });
        } else {
            setFormError({
                ...form_error,
                [name]: "",
            });
        }
        if (name === "claimFrequency") setClaimFrequency(trimmedValue);
        else if (name === "searchValue") setSearchValue(trimmedValue);
        else {
            setDescription(trimmedValue);
        }
    }

	
	function backToDictionaryPage() {
		props.history.push(ROUTE_SUPER_DICTIONARIES)
	}
	function dropDownFunction(id, name) {
		if (!permission) {
			showNotifyWindow('show','error', i18n.t('errorMessages.permission_error'));
			return;
		}
		// default Frequency are not allowed to edit or delete from the database;
		const isDefaultFrequency = claimFrequencyList.some((item) => {
			if (item.id === id && item.not_delete) {
				return true;
			} else {
				return false;
			}
		});
		if (isDefaultFrequency) {
			showNotifyWindow('show', 'error', i18n.t('dictionariesPages.dictClaimFrequency.editRestrictionMessage'));
			return;
		}
		// if the Frequency is not self then proceed;
		if (name.toLowerCase() == 'edit') {
			onEditClaimFrequency(Number(id));
        }
        if (name.toLowerCase() == 'delete') {
            onDeleteClaimFrequency(Number(id));
        }
    }
	

	return (
        <React.Fragment>
            <Notify
                showNotify={showNotify}
                setShowNotify={setShowNotify}
                notifyDescription={notifyDescription}
                setNotifyType={setNotifyType}
                setNotifyDescription={setNotifyDescription}
                notifyType={notifyType}
            />
            <div className="col-md-8">
                <div className="box box-content-white  mb-3">
                    <div className="row mr-0 mb-2">
                        <div className="col pl-4 mb-1">
                            <div className={"dataTables_filter"}>
                                <div
                                    className="link dictionaries-back"
                                    onClick={backToDictionaryPage}>
                                    <BackArrowWithLabel
                                        label={i18n.t(
                                            "dictionariesPages.gotoDictionaries"
                                        )}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="box-content ">
                        <div className="common-search-wrapper-style">
                            <div className="input-content-box">
                                <div className="form-group padding-top15 relative">
                                    <TextInput
                                        type="text"
                                        id="searchValue"
                                        name="searchValue"
                                        value={searchValue}
                                        onValueChange={onHandleChange}
                                        label={i18n.t(
                                            "dictionariesPages.dictClaimFrequency.lblClaimFrequency"
                                        )}
                                        onKeyDown={handleKeyDown}
                                        labelClassName={"margin-bottom0"}
                                        clearButton={
                                            typeof searchValue == "string" &&
                                            searchValue.length !== 0
                                        }
                                        clearInput={onClose}
                                    />
                                </div>
                            </div>
                            <div className="input-content-box padding-top15">
                                <div className="margin-top18">
                                    <CommonButton
                                        variant="contained"
                                        onClick={(e) =>
                                            onSearch(e, searchValue)
                                        }
                                        label="Search"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="box box-content-white">
                    <div className="box-content">
                        <div className="alignRight margin-bottom3">
                            <CommonButton
                                variant="contained"
                                onClick={addNew}
                                label={i18n.t("buttons.addNew")}
                            />
                        </div>
                        <div className="table-responsive">
                            <Table
                                tableObject={ClaimFrequencyTableData}
                                dropDownFunction={dropDownFunction}
                            />
                        </div>
                        <div className="mt-2 mb-2">
                            <Pagination
                                totalPage={totalPage}
                                activePage={activePage}
                                startIndex={startIndex}
                                endIndex={endIndex}
                                onPagePrevious={onPagePrevious}
                                onPageUp={onPageUp}
                                onPageNext={onPageNext}
                            />
                        </div>
                    </div>
                </div>
            </div>

            <CustomizedDialogs
                showModal={showModalWindow}
                type="save"
                header={header}
                setShowModalWindow={setShowModalWindow}
                resetForm={resetForm}
                onHide={onHide}>
                <Form
                    id="form_dataEntry"
                    autoComplete="off"
                    onSubmit={(e) => onSaveFormData(e)}
                    encType="multipart/form-data">
                    <Form.Group>
                        <div className="row">
                            <div className="col-md-8">
                                <div className="form-group padding-top15">
                                    <TextInput
                                        type="text"
                                        pattern="[0-9]*"
                                        id="claimFrequency"
                                        name="claimFrequency"
                                        label={i18n.t(
                                            "dictionariesPages.dictClaimFrequency.lblClaimFrequency"
                                        )}
                                        onValueChange={onHandleChange}
                                        className={
                                            form_error.claimFrequency
                                                ? "input-error"
                                                : ""
                                        }
                                        value={claimFrequency}
                                    />
                                </div>
                                <div className="form-group">
                                    <label htmlFor="desc-text-area">
                                        {i18n.t(
                                            "dictionariesPages.dictClaimFrequency.lblDescription"
                                        )}
                                    </label>
                                    <div className={"text-input"}>
                                        <textarea
                                            id="desc-text-area"
                                            placeholder={i18n.t(
                                                "dictionariesPages.dictClaimFrequency.lblDescription"
                                            )}
                                            name="description"
                                            className={
                                                form_error.description
                                                    ? "textarea-100 input-error"
                                                    : "textarea-100"
                                            }
                                            value={description}
                                            onChange={(e) =>
                                                onHandleChange(e)
                                            }></textarea>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Form.Group>
                </Form>
            </CustomizedDialogs>
            <CustomizedSmallDialogs
                showModal={showDeleteModalWindow}
                header={i18n.t("commons.confirmDelete")}
                type="delete"
                deleteItem={onDeleteAction}
                resetForm={resetForm}
                onHide={onDeleteHide}
                setShowModalWindow={setShowDeleteModalWindow}>
                {i18n.t(
                    "dictionariesPages.dictClaimFrequency.deletConfirmation"
                ) +
                    "'" +
                    deleteClaimFrequency +
                    "'?"}
            </CustomizedSmallDialogs>
        </React.Fragment>
    );
}

export default ClaimFrequency