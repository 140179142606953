import React, { useContext, useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import { Link } from "react-router-dom";
import Moment from "react-moment";
import actionImg from "../../../assets/images/action.png";
import LoadingContext from "../../../container/loadingContext";
import i18n from "../../../utilities/i18n";
import { NOTES_TYPES } from "../../../utilities/dictionaryConstants";
import CustomizedDialogs from "../../modalWindowComponent/CustomizedDialogs";
import service from "./service";
import { ADD_ERROR, ADD_SUCCESS, DELETE_SUCCESS, UPDATE_SUCCESS } from "../../../utilities/labelConfigs";
import Notify from "../../commons/notify";
import SelectInput from "../../commons/input/SelectInput";
import TextAreaInput from '../../commons/textarea/textarea';
import CalendarInput from '../../commons/input/CalendarInput';
import { getStorage } from '../../../utilities/browserStorage';
import { format } from 'date-fns';
import CustomizedSmallDialogs from "../../modalWindowComponent/CustomisedSmallDialog";
import { checkPermission } from "../../../utilities/commonUtilities";
import { permission_key_values_patient } from "../../../utilities/permissions";
import CommonButton from "../../commons/Buttons";

function PatientAddNotes(props) {
    const [header, setHeader] = useState("");
    const patientPK = props.patientPK;
    const notesEditable = props.patientEditable;
    const [showAddNoteWindow, setShowAddNoteWindow] = useState(false);
    const [patientAddNote, setpatientAddNote] = useState("");
    const [noteType, setNoteType] = useState("");
    const [form_error, setFormError] = useState({ patientAddNote: "", noteType: "", nextFollowUpDate: "" });
    const setShowLoadingBar = useContext(LoadingContext);
    const [typeOfNotes, setTypeOfNotes] = useState([]);
    const [listOfNotes, setListOfNotes] = useState([]);
    const [activeRowId, setActiveRowId] = useState(0);
    const [edit, setEdit] = useState(false);
    const [selectedID, setSelectedID] = useState("");
    const [deleteProviderName, setDeleteProviderName] = useState("");
    const [deleteProviderId, setDeleteProviderId] = useState("");
    const [showDeleteModalWindow, setShowDeleteModalWindow] = useState(false);
    const [showNotify, setShowNotify] = useState("hide");
    const [notifyDescription, setNotifyDescription] = useState("");
    const [notifyType, setNotifyType] = useState("success");
    const practicePK=getStorage('practice');
    const [filterNote, setFilterNote] = useState('all');
    const [initialTableData, setInitialTableData] = useState(i18n.t("commons.loading"));
    const [category, setCategory] = useState('');
    const [nextFollowUpDate, setNextFollowUpDate] = useState('');
    const [notTypeDisable, setNotTypeDisable] = useState(false);


    function ListTypes(forModelWindow) {
        let addingAll = [];
        if (!forModelWindow) {
            // this is call if data not for model window select input
            let allObject = { id: 'all', name: "All" }
            addingAll.push(allObject);

        }

        NOTES_TYPES.forEach((item) => {
            addingAll.push(item);
        })
        if (!forModelWindow) {
            let arObject = { id: 'ar_notes', name: "AR Notes" }
            addingAll.push(arObject);
        }
        setTypeOfNotes(addingAll);
        if (!forModelWindow) setNoteType(filterNote);
    }
    function addNote() {
        if(checkPermission(permission_key_values_patient.can_add_patient_claim_notes)){
            setNotTypeDisable(false);
            setShowAddNoteWindow(true);
            setCategory('');
            setHeader(i18n.t("patientAddNote.addNotes"));
            ListTypes(true);
            if (filterNote) setNoteType(filterNote);
        } else {
            showNotifyWindow('show', 'error', i18n.t('errorMessages.permission_error'));
        }
        
    }
    function onHideAddNotes() { ListTypes(false) }
    function resetAddNotesWindow() {
        setpatientAddNote("");
        setNoteType("");
        setEdit(false);
        setFormError({
            ...form_error,
            ['patientAddNote']: '',
            ['noteType']: '',
            ['nextFollowUpDate']: '',
        });
    }

    function handleChange(e) {
        let name = e.target.name;
        let value = e.target.type === "file" ? e.target.files[0] : e.target.value;
        let trimmedValue = "";
        let inputFields = ["patientAddNote", "noteType", "filterNote"];
        if (inputFields.includes(name)) {
            trimmedValue = value;
        }
        let mandatoryFields = ["patientAddNote", "noteType"];
        if (!trimmedValue && mandatoryFields.includes(name)) {
            setFormError({
                ...form_error,
                [name]: "error",
            });
        } else {
            setFormError({ ...form_error, [name]: "" });
        }
        if (name === "patientAddNote") setpatientAddNote(trimmedValue);
        else if (name === "noteType") setNoteType(trimmedValue);
        else if (name === 'filterNote') {
            setFilterNote(trimmedValue)
            if (trimmedValue === 'all') GetPatientNoteList(patientPK, "");
            else GetPatientNoteList(patientPK, trimmedValue);
        }
    }

    const validateForm = () => {
        let errors = {};
        if (!patientAddNote.trim()) {
            errors.patientAddNote = "error";
        } else if (!noteType && category !== 'ar_claim') {
            errors.noteType = "error";
        } else if (!nextFollowUpDate && category === 'ar_claim') {
            errors.nextFollowUpDate = "error";
        }
        return errors;
    }
    

    const onSaveAddNote = (e) => {
        e.preventDefault();
        setShowLoadingBar(true);
        const errors = validateForm();
        if (Object.keys(errors).length > 0) {
            setFormError(errors);
            setShowLoadingBar(false);
            return;
        }
            let result = null;
            let next_follow_up_date = null;
            let note_type = noteType;
            if (category === 'ar_claim') {
                next_follow_up_date = format(new Date(nextFollowUpDate), "yyyy-MM-dd");
                note_type = null;
            }
            let data={notes: patientAddNote,patient: patientPK, notes_type: note_type, category: edit ? category : 'patient', next_follow_up_date: next_follow_up_date };
            result = edit ? service.UpdatePatientNote(selectedID, data) : service.SavePatientNote(patientPK, data);
        result.then((response) => {
                setShowLoadingBar(false);
                if (response.status === 201 || response.status === 200) {
                    setEdit(false);
                    if (response.status === 201) {
                        showNotifyWindow("show", "success", ADD_SUCCESS);
                    } else {
                        showNotifyWindow("show", "success", UPDATE_SUCCESS);
                    }
                    GetPatientNoteList(patientPK, noteType);
                    setFilterNote(noteType);
                    resetAddNotesWindow();
                    setShowAddNoteWindow(false);
                } else if (response.status === 400 && response.data.record_already_exists !== undefined) {
                    showNotifyWindow("show", "error", i18n.t("errorMessages.record_exists"));
                } else {
                    showNotifyWindow("show", "error", ADD_ERROR);
                }
                ListTypes(false);
            });
    };

    function GetPatientNoteList(patient_pk, noteTypeValue) {
        if (noteTypeValue === 'all') {
            noteTypeValue = ''
        }
        let result = service.ListPatientNotes(practicePK, patient_pk, noteTypeValue, 'patient');
        result.then((response) => {
            setListOfNotes(response.data);
            if (response.data.length === 0) setInitialTableData(i18n.t('commons.noRecords'))
        });
    }

    useEffect(() => {
        if(patientPK){
            GetPatientNoteList(patientPK);
            ListTypes(false);
        }
    }, [patientPK]);

    function onShowActionButtons(event, rowId) {
        let dropdown = document.getElementById("dropdown-notes" + rowId);
        let dropdownMenu = document.getElementById("dropdown-menu-notes" + rowId);
        dropdown.classList.toggle("show");
        dropdownMenu.classList.toggle("show");
        if (activeRowId === 0) {
            setActiveRowId(rowId);
        } else if (activeRowId === rowId) {
            setActiveRowId(0);
        } else if (this.state.activeRowId !== rowId) {
            dropdown = document.getElementById("dropdown-notes" + activeRowId);
            dropdownMenu = document.getElementById("dropdown-menu-notes" + activeRowId);
            dropdown.classList.toggle("show");
            dropdownMenu.classList.toggle("show");
            setActiveRowId(rowId);
        }
    }

    function onHideActionButtons(event, rowId) {
        let dropdown = document.getElementById("dropdown-notes" + rowId);
        let dropdownMenu = document.getElementById("dropdown-menu-notes" + rowId);
        dropdown.classList.remove("show");
        dropdownMenu.classList.remove("show");
        setActiveRowId(0);
    }
    function onEditNotes(ID, notes_type, category_value) {
        if (checkPermission(permission_key_values_patient.can_change_patient_claim_notes)) {
            setCategory(category_value);
            setNotTypeDisable(true);
            setHeader(i18n.t("patientAddNote.editNote"));
            ListTypes(true);
            setSelectedID(ID);
            setNoteType(notes_type);
            let result = service.GetPatientNote(ID);
            result.then((response) => {
                setpatientAddNote(response.data.notes);
                setEdit(true);
                setShowAddNoteWindow(true);
                if (response.data.next_follow_up_date) {
                    setNextFollowUpDate(new Date(response.data.next_follow_up_date + " 00:00:00"))
                }
            });
        }
        else {
            showNotifyWindow('show', 'error', i18n.t('errorMessages.permission_error'));
        }
    }
    function onDeleteNotes(providerID) {
        if (checkPermission(permission_key_values_patient.can_delete_patient_claim_notes)) {
            listOfNotes.map((item) => {
                if (item.id === parseInt(providerID)) {
                    setDeleteProviderName(item.notes);
                    setDeleteProviderId(item.id);
                    setShowDeleteModalWindow(true);
                }
                return null;
            });
        }
        else {
            showNotifyWindow('show', 'error', i18n.t('errorMessages.permission_error'));
        }
    }
    function onDeleteAction() {
        setShowLoadingBar(true);
        const data = service.DeletePatientNotes(deleteProviderId);
        data.then(() => {
            setShowLoadingBar(false);
            setDeleteProviderName("");
            setDeleteProviderId("");
            setShowDeleteModalWindow(false);
            showNotifyWindow("show", "success", DELETE_SUCCESS);
            GetPatientNoteList(patientPK, filterNote);
        });
    }
    function showNotifyWindow(action, type, desc, age = 3000) {
        if (action === "show") {
            setTimeout(() => {
                setShowNotify("hide");
            }, age);
        }
        setShowNotify(action);
        setNotifyType(type);
        setNotifyDescription(desc);
    }

    function onHandleNextFollowUpDate(e) {
        if (e && e.target) {
            let value = e.target.value;
            setNextFollowUpDate(value);
        } else {
            setNextFollowUpDate(e);
            setFormError({
                ...form_error,
                nextFollowUpDate: "error",
            });
        }
    }

    return (
        <div>
            <Notify showNotify={showNotify} setShowNotify={setShowNotify} notifyDescription={notifyDescription} setNotifyType={setNotifyType} setNotifyDescription={setNotifyDescription} notifyType={notifyType} />
            <Form autoComplete="off">
                <div className="row mt-4">
                    <div className="col-md-8">
                        <div className="row">
                            <div className="col-4">
                                <SelectInput data={typeOfNotes} selectOptionRemove={true} name="filterNote" id="filterNote" value={filterNote} onValueChange={handleChange} />
                            </div>
                            <div className="col width-65-perc justify-right pb-2 mr-3">
                                { notesEditable &&
                                    <CommonButton  variant="contained" onClick={addNote} label={i18n.t("patientAddNote.addNotesPlus")} />
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </Form>
            <Form autoComplete="off">
                <Form.Group className="">
                    <table className="table width-65-perc">
                        <tbody>
                            {listOfNotes.length === 0 &&
                                <tr>
                                    <td align="center">{initialTableData}</td>
                                </tr>
                            }
                            {listOfNotes.map((item, index) => {
                                return (
                                    <tr key={index}>
                                        <td className="w-100">
                                            <div className="d-flex justify-content-between">
                                                <div className="col-sm-8">
                                                       <div>
                                                            {item.created_by_name}
                                                        </div>
                                                        <div>
                                                        <div style={{whiteSpace:"pre-line"}}>{item.notes}</div>
                                                        </div>
                                                 </div>
                                                <div className="col-sm-4 align-items-center d-flex div-float-right justify-content-center">
                                                <Moment format="MM/DD/yyyy hh:mm A">{item.created_on}</Moment></div>
                                            </div>
                                        </td>
                                        {!props.noEditColumns &&
                                            notesEditable &&
                                            <td align="center" className="max-width-40px">
                                                <div className="dropdown" id={"dropdown-notes" + item.id}>
                                                    <button id={"dropdownMenuButton-" + item.id} data-testid={"dropdownMenuButton-" + item.id} className="dropdown-toggle" type="button" data-toggle="dropdown" aria-haspopup="true" onClick={(e) => onShowActionButtons(e, item.id)} onBlur={(e) => onHideActionButtons(e, item.id)}>
                                                        <img id="test" src={actionImg} alt="Action" />
                                                    </button>
                                                    <div className="dropdown-menu" id={"dropdown-menu-notes" + item.id}>
                                                        <Link className="dropdown-item edit" data-testid={"dropdown-menu-edit" + item.id} to="#" onMouseDown={() => onEditNotes(item.id, item.notes_type, item.category)}>
                                                            {i18n.t("patientAddNote.edit")}
                                                        </Link>
                                                        <Link className="dropdown-item edit" data-testid={"dropdown-menu-delete" + item.id} to="#" onMouseDown={() => onDeleteNotes(item.id)}>
                                                            {i18n.t("patientAddNote.delete")}
                                                        </Link>
                                                    </div>
                                                </div>
                                            </td>
                                        }
                                    </tr>
                                );
                            })}
                        </tbody>
                    </table>
                    <div className="clear-b-scroll-sector">&nbsp;</div>
                    <div className="clear-b-scroll-sector">&nbsp;</div>
                </Form.Group>
            </Form>

            <CustomizedDialogs showModal={showAddNoteWindow} type="save" header={header} resetForm={resetAddNotesWindow} setShowModalWindow={setShowAddNoteWindow} onHide={onHideAddNotes}>
                <Form id="form_dataEntry" onSubmit={(e) => onSaveAddNote(e)} encType="multipart/form-data" autoComplete="off">
                    <Form.Group>
                        <div className="row">
                            {category !== 'ar_claim' && <div className="col-6">
                                <div className="padding-top15">
                                    <SelectInput required={true} data={typeOfNotes} disabled={notTypeDisable} name="noteType" id="noteType" value={noteType} setValue={setNoteType} onValueChange={(selected) => handleChange(selected)} label={i18n.t("patientAddNote.notesType")} className={form_error.noteType ? "input-error" : ""} />
                                </div>

                            </div>}
                            {category === 'ar_claim' && <div className='col-6'>
                                <div className="padding-top15">
                                    <CalendarInput required={true} name='next_follow_up_date' className={form_error.nextFollowUpDate ? "input-error" : ""} selected={nextFollowUpDate} onValueChange={(e) => onHandleNextFollowUpDate(e)} label={i18n.t('arClaims.notesModal.next_follow_up_date')} />
                                </div>
                            </div>}
                        </div>
                        <div className="row">
                            <div className="col">
                                <div className="form-group padding-top15">
                                    <TextAreaInput placeholder="" type="text" id="patientAddNote" name="patientAddNote" className={form_error.patientAddNote ? "input-error" : ""} label={i18n.t("patientAddNote.notes")} value={patientAddNote} onValueChange={(e) => handleChange(e)} />
                                </div>
                            </div>
                        </div>
                    </Form.Group>
                </Form>
            </CustomizedDialogs>
            <CustomizedSmallDialogs showModal={showDeleteModalWindow} header={i18n.t("commons.confirmDelete")} type="delete" deleteItem={onDeleteAction} resetForm={resetAddNotesWindow} onHide={onHideAddNotes} setShowModalWindow={setShowDeleteModalWindow}>
                {i18n.t("patientAddNote.deleteConfirmation") +" "+"?"}
                <TextAreaInput type="text" className="mt-2 h-50" value={deleteProviderName} disabled={true} rows={5} placeholder="" />
            </CustomizedSmallDialogs>
        </div>
    );
}

export default PatientAddNotes;
