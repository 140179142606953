import React, { useState, useEffect, useContext } from "react";
import { Form } from "react-bootstrap";
import CustomizedDialogs from "../../modalWindowComponent/CustomizedDialogs";
import service from "./service";
import {
    ROUTE_SUPER_DICTIONARIES,
    PAGING_END_INDEX,
    DEFAULT_PAGING_SIZE,
} from "../../../utilities/staticConfigs";
import {
    ADD_SUCCESS,
    ADD_ERROR,
    UPDATE_SUCCESS,
    DELETE_SUCCESS,
} from "../../../utilities/labelConfigs";
import Notify from "../../commons/notify";
import Pagination from "../../commons/pagination";
import LoadingContext from "../../../container/loadingContext";
import i18n from "../../../utilities/i18n";
import TextInput from "../../commons/input/input";
import CustomizedSmallDialogs from "../../modalWindowComponent/CustomisedSmallDialog";
import {
    checkPermission,
    commonTableBody,
} from "../../../utilities/commonUtilities";
import { AssignmentBenefitsTableData } from "./AssignmentBenefitsTable";
import Table from "../../commons/Table/Table";
import BackArrowWithLabel from "../../commons/Back";
import CommonButton from "../../commons/Buttons";
import {
    super_admin_permission_key_value,
    super_admin_privileges,
} from "../../../utilities/permissions";

const BenefitList = (props) => {
    const [showModalWindow, setShowModalWindow] = useState(false);
    const [showDeleteModalWindow, setShowDeleteModalWindow] = useState(false);
    const [benefitType, setBenefitType] = useState("");
    const [deleteBenefitName, setDeleteBenefitName] = useState("");
    const [deleteBenefitId, setDeleteBenefitId] = useState("");
    const [editBenefitId, setEditBenefitId] = useState("");
    const [benefitTypeList, setBenefitTypeList] = useState([]);
    const [form_error, setFormError] = useState({ benefitType: "" });
    const [editForm, setEditForm] = useState(false);
    const [header, setHeader] = useState("");
    const [permission, setPermission] = useState(false);
    const [searchValue, setSearchValue] = useState("");

    //start ==== Alert message properties
    const [showNotify, setShowNotify] = useState("hide");
    const [notifyDescription, setNotifyDescription] = useState("");
    const [notifyType, setNotifyType] = useState("success");
    const setShowLoadingBar = useContext(LoadingContext);

    function showNotifyWindow(action, type, desc, age = 3000) {
        if (action == "show") {
            setTimeout(() => {
                setShowNotify("hide");
            }, age);
        }
        setShowNotify(action);
        setNotifyType(type);
        setNotifyDescription(desc);
    }
    //End ====

    //Pagination start
    const [totalPage, setTotalPage] = useState(1);
    const [activePage, setActivePage] = useState(1);
    const [startIndex, setStartIndex] = useState(0);
    const [endIndex, setEndIndex] = useState(PAGING_END_INDEX);

    function onPagePrevious() {
        let previousPage = startIndex + 1 - PAGING_END_INDEX;
        setActivePage(previousPage);

        if (startIndex !== 0) {
            setStartIndex(startIndex - PAGING_END_INDEX);
            setEndIndex(endIndex - PAGING_END_INDEX);
        }
        getBenefitData(DEFAULT_PAGING_SIZE, previousPage, searchValue);
    }

    function onPageUp(e) {
        let page = Number(e.target.id);
        setActivePage(page);
        getBenefitData(DEFAULT_PAGING_SIZE, page, searchValue);
    }

    function onPageNext() {
        let nextPage = startIndex + 1 + PAGING_END_INDEX;
        if (endIndex === totalPage || totalPage <= PAGING_END_INDEX) {
            setActivePage(nextPage);
            setStartIndex(startIndex);
            setStartIndex(endIndex);
        } else {
            setActivePage(nextPage);
            setStartIndex(startIndex + PAGING_END_INDEX);
            setEndIndex(endIndex + PAGING_END_INDEX);
        }
        getBenefitData(DEFAULT_PAGING_SIZE, nextPage, searchValue);
    }
    //Pagination ends

    function addNew() {
        if (!permission) {
            showNotifyWindow(
                "show",
                "error",
                i18n.t("errorMessages.permission_error")
            );
            return;
        }
        setHeader(i18n.t("dictionariesPages.dictAssignmentBenefit.addHeader"));
        setShowModalWindow(true);
    }

    function onHide() {
        setEditForm(false);
    }

    function onDeleteHide() {
        setDeleteBenefitName("");
        setDeleteBenefitId("");
        setShowDeleteModalWindow(false);
    }

    function getBenefitData(pageSize, page, searchString) {
        setShowLoadingBar(true);
        const result = service.ListBenefitTypes(pageSize, page, searchString);
        result.then((response) => {
            if (response.data.results !== undefined) {
                setTotalPage(
                    Math.ceil(response.data.count / response.data.page_size)
                );
            }
            setShowLoadingBar(false);
            if (response.data?.results) {
                response.data.results = response.data.results?.map((item) => {
                    if (item.not_delete === true) {
                        item.isDefault = "Yes";
                    } else {
                        item.isDefault = "No";
                    }
                    return item;
                });
            }
            const rowArray = commonTableBody(
                response.data.results,
                AssignmentBenefitsTableData.tableBodyData[0]
            );
            AssignmentBenefitsTableData.tableBodyData = rowArray;
            setBenefitTypeList(response.data.results);
        });
    }

    useEffect(() => {
        getBenefitData(DEFAULT_PAGING_SIZE, activePage);
        setPermission(
            checkPermission(
                super_admin_privileges.super_admin_full_privilege,
                super_admin_permission_key_value.dictionary_add
            )
        );
    }, []);

    function onEditBenefitType(benefitId) {
        setHeader(i18n.t("dictionariesPages.dictAssignmentBenefit.editHeader"));
        setShowLoadingBar(true);
        const data = service.GetBenefitType(benefitId);
        data.then((response) => {
            setShowLoadingBar(false);
            setShowModalWindow(true);
            setBenefitType(response.data.name);
            setEditForm(true);
            setEditBenefitId(benefitId);
        });
    }

    function onDeleteBenefitType(benefitId) {
        benefitTypeList.map((item) => {
            if (item.id == parseInt(benefitId)) {
                setDeleteBenefitName(item.name);
                setDeleteBenefitId(item.id);
                setShowDeleteModalWindow(true);
            }
        });
    }

    function onDeleteAction() {
        setShowLoadingBar(true);
        const data = service.DeleteBenefitType(deleteBenefitId);
        data.then(() => {
            setShowLoadingBar(false);
            setDeleteBenefitName("");
            setDeleteBenefitId("");
            setShowDeleteModalWindow(false);
            showNotifyWindow("show", "success", DELETE_SUCCESS);
            getBenefitData(DEFAULT_PAGING_SIZE, activePage, searchValue);
        });
    }

    function resetForm() {
        setBenefitType("");
        setEditForm(false);
        setFormError({
            ...form_error,
            ["benefitType"]: "",
        });
    }
    function onSaveFormData(e) {
        e.preventDefault();
        if (!benefitType.trim()) {
            setFormError({
                ...form_error,
                ["benefitType"]: "error",
            });
        } else {
            setShowLoadingBar(true);
            let result = editForm
                ? service.UpdateBenefitType(editBenefitId, {
                      name: benefitType,
                  })
                : service.AddBenefitType({ name: benefitType });
            result.then((response) => {
                setShowLoadingBar(false);
                if (response.status == 201 || response.status == 200) {
                    if (response.status == 201)
                        showNotifyWindow("show", "success", ADD_SUCCESS);
                    else showNotifyWindow("show", "success", UPDATE_SUCCESS);
                    getBenefitData(DEFAULT_PAGING_SIZE, activePage, searchValue);
                    resetForm();
                    setShowModalWindow(false);
                } else if (
                    response.status === 400 &&
                    response.data.record_already_exists !== undefined
                ) {
                    showNotifyWindow(
                        "show",
                        "error",
                        i18n.t("errorMessages.record_exists")
                    );
                } else {
                    showNotifyWindow("show", "error", ADD_ERROR);
                }
            });
        }
    }
    function onHandleChange(e) {
        const { name } = e.target;
        let value = e.target.value;
        let trimmedValue = "";
        if (e.target.type === "text") {
            trimmedValue = value;
        }
        if (name == "searchValue") {
            setSearchValue(trimmedValue);
            return;
        }
        if (!trimmedValue) {
            setFormError({
                ...form_error,
                [name]: "error",
            });
        } else {
            setFormError({
                ...form_error,
                [name]: "",
            });
        }
        setBenefitType(trimmedValue);
    }

    const handleKeyDown = (e) => {
        if (e.key === "Enter" && searchValue) {
            onSearch(e, searchValue);
        }
    };

    function onSearch(e, searchString) {
        e.preventDefault();
        setActivePage(1);
        setStartIndex(0);
        getBenefitData(DEFAULT_PAGING_SIZE, 1, searchString);
    }
    const onClose = () => {
        setSearchValue("");
        setActivePage(1);
        setStartIndex(0);
        getBenefitData(DEFAULT_PAGING_SIZE, activePage);
    };

    function backToDictionaryPage() {
        props.history.push(ROUTE_SUPER_DICTIONARIES);
    }
    function dropDownFunction(id, name) {
        if (!permission) {
            showNotifyWindow(
                "show",
                "error",
                i18n.t("errorMessages.permission_error")
            );
            return;
        }
        // default relations are not allowed to edit or delete from the database;
        const isDefaultAssignment = benefitTypeList.some((item) => {
            if (item.id === id && item.not_delete) {
                return true;
            } else {
                return false;
            }
        });
        if (isDefaultAssignment) {
            showNotifyWindow(
                "show",
                "error",
                i18n.t(
                    "dictionariesPages.dictAssignmentBenefit.editRestrictionMessage"
                )
            );
            return;
        }
        // if the relation is not self then proceed;
        if (name.toLowerCase() == "edit") {
            onEditBenefitType(Number(id));
        }
        if (name.toLowerCase() == "delete") {
            onDeleteBenefitType(Number(id));
        }
    }

    return (
        <React.Fragment>
            <Notify
                showNotify={showNotify}
                setShowNotify={setShowNotify}
                notifyDescription={notifyDescription}
                setNotifyType={setNotifyType}
                setNotifyDescription={setNotifyDescription}
                notifyType={notifyType}
            />
            <div className="col-md-8">
                <div className="box box-content-white  mb-3">
                    <div className="row mr-0 mb-2">
                        <div className="col pl-4 mb-1">
                            <div className={"dataTables_filter"}>
                                <div
                                    className="link dictionaries-back"
                                    onClick={backToDictionaryPage}>
                                    <BackArrowWithLabel
                                        label={i18n.t(
                                            "dictionariesPages.gotoDictionaries"
                                        )}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="box-content ">
                        <div className="common-search-wrapper-style">
                            <div className="input-content-box">
                                <div className="form-group padding-top15 relative">
                                    <TextInput
                                        type="text"
                                        id="searchValue"
                                        name="searchValue"
                                        value={searchValue}
                                        onValueChange={onHandleChange}
                                        label={i18n.t(
                                            "dictionariesPages.dictAssignmentBenefit.lblAssignmentBenefit"
                                        )}
                                        onKeyDown={handleKeyDown}
                                        labelClassName={"margin-bottom0"}
                                        clearButton={
                                            typeof searchValue == "string" &&
                                            searchValue.length !== 0
                                        }
                                        clearInput={onClose}
                                    />
                                </div>
                            </div>
                            <div className="input-content-box padding-top15">
                                <div className="margin-top18">
                                    <CommonButton
                                        variant="contained"
                                        label="Search"
                                        onClick={(e) =>
                                        onSearch(e, searchValue)
                                        }
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="box box-content-white">
                    <div className="box-content">
                        <div className="alignRight padding-right5 margin-bottom2">
                            <CommonButton
                                variant="contained"
                                onClick={addNew}
                                label={i18n.t("buttons.addNew")}
                            />
                        </div>

                        <div className="table-responsive">
                            <Table
                                tableObject={AssignmentBenefitsTableData}
                                dropDownFunction={dropDownFunction}
                            />
                        </div>
                        <div className="mt-2 mb-2">
                            <Pagination
                                totalPage={totalPage}
                                activePage={activePage}
                                startIndex={startIndex}
                                endIndex={endIndex}
                                onPagePrevious={onPagePrevious}
                                onPageUp={onPageUp}
                                onPageNext={onPageNext}
                            />
                        </div>
                    </div>
                </div>
            </div>
            <CustomizedDialogs
                showModal={showModalWindow}
                type="save"
                header={header}
                setShowModalWindow={setShowModalWindow}
                resetForm={resetForm}
                onHide={onHide}>
                <Form
                    id="form_dataEntry"
                    autoComplete="off"
                    onSubmit={(e) => onSaveFormData(e)}
                    encType="multipart/form-data">
                    <Form.Group>
                        <div className="row">
                            <div className="col-md-12">
                                <div className="form-group padding-top15">
                                    <TextInput
                                        type="text"
                                        id="benefitType"
                                        name="benefitType"
                                        label={i18n.t(
                                            "dictionariesPages.dictAssignmentBenefit.lblAssignmentBenefit"
                                        )}
                                        onValueChange={onHandleChange}
                                        className={
                                            form_error.benefitType
                                                ? "input-error"
                                                : ""
                                        }
                                        value={benefitType}
                                    />
                                </div>
                            </div>
                        </div>
                    </Form.Group>
                </Form>
            </CustomizedDialogs>
            <CustomizedSmallDialogs
                showModal={showDeleteModalWindow}
                header={i18n.t("commons.confirmDelete")}
                type="delete"
                deleteItem={onDeleteAction}
                resetForm={resetForm}
                onHide={onDeleteHide}
                setShowModalWindow={setShowDeleteModalWindow}>
                {i18n.t(
                    "dictionariesPages.dictAssignmentBenefit.deletConfirmation"
                ) +
                    "'" +
                    deleteBenefitName +
                    "'?"}
            </CustomizedSmallDialogs>
        </React.Fragment>
    );
};
export default BenefitList;
