import React, { useEffect } from "react";
import './styles.css'
import { Container, Row, Col } from "react-bootstrap";
import Avatar from "@mui/material/Avatar";
import Paper from "@mui/material/Paper";
import "./styles.css"; // Import your custom CSS file for styling
import { useSelector } from "react-redux";
import { getStorage } from "../../utilities/browserStorage";
import { Skeleton } from "@mui/material";
import chatBotImg from '../../assets/images/chatbot.png'
import { useDispatch } from "react-redux";
import { changeResponseTriggered } from "./ChatBotSlice/ChatBotSlice";
import { Chip } from "@material-ui/core";
import AssignmentIcon from '@material-ui/icons/Assignment';
import PhoneIcon from '@material-ui/icons/Phone';
import CancelIcon from '@material-ui/icons/Cancel';
import MonetizationOnIcon from '@material-ui/icons/MonetizationOn';

const CustomQuestions = [
    {
        key: 'AIMA contact information',
        icon: <PhoneIcon />
    },
    {
        key: 'Claims',
        icon: <AssignmentIcon />
    },
    {
        key: 'Denials',
        icon: <CancelIcon />
    },
    {
        key: 'Revenue',
        icon: <MonetizationOnIcon />
    }
]

const ChatBotComponent = (props) => {
    const dispatch = useDispatch();
    const chat = useSelector(state => state.chatboxData.messages)
    const responseTriggered = useSelector(state => state.chatboxData.responseTriggered);
    const requestTriggered = useSelector(state => state.chatboxData.requestTriggered)
    const userData = getStorage("userData")
    const user = JSON.parse(userData)
    
    function formatTimestamp(timestampString) {
        const unixTimestamp = timestampString;
        const timestamp = new Date(unixTimestamp);

        const formattedTimestamp = timestamp.toLocaleString('en-US', {
            year: 'numeric',
            month: 'short',
            day: 'numeric',
            hour: 'numeric',
            minute: 'numeric',
            second: 'numeric',
            hour12: true,
        });
        return formattedTimestamp;
    }

    useEffect(() => {
      
        if (responseTriggered || requestTriggered) {
            const scrollDiv = props.messageViewRef?.current;
            if (scrollDiv) {
                scrollDiv.scrollTop = scrollDiv.scrollHeight;
            }

            dispatch(changeResponseTriggered())
        }
    }, [responseTriggered, requestTriggered])
    function MessageComponent({ message }) {
        
        const messageLines = message.split('\n');

        return (
          <div>
            {messageLines?.map((line, index) => (
             line!=""?
              <div key={index} >
                {line}
              </div>
              :<br key={index} /> //identify to display \n\n
            ))}
          </div>
        );
      }
      
    return (
        <Container>
            <Row>
                <Col xs={12}>
                    <div className="chat-container">
                        <div className="custom-questions" style={{ display: 'flex',overflow:'hidden',flexWrap:'wrap',gap:'5px'}}>
                                {CustomQuestions.map(item => (
                                    <Chip
                                        key={item.key} // Add a unique key for each Chip
                                        label={item.key}
                                        onClick={()=>props.handleClick(item.key)}
                                        avatar={<Avatar style={{ color: '#ffffff !important' }}>{item.icon}</Avatar>}
                                        style={{ color: '#000000 !important', marginTop: 3, borderRadius: '10px' }}
                                    />
                                ))}
                        </div>
                        {chat?.map((chat, index) => (
                            <>
                                {chat.userId == "loading" ?
                                    <Skeleton>
                                    <div
                                        key={index}
                                        className="chat-message other-user"
                                    >
                                        {chat.userId !== "loading" && (
                                            <Avatar
                                                alt="User Avatar"
                                                src={chatBotImg}
                                                sx={{
                                                    width: 32,
                                                    height: 32,
                                                    fontSize: "16px",
                                                    marginRight: "10px",
                                                    marginBottom: "10px",
                                                    backgroundColor: "grey"
                                                }}
                                            >
                                            </Avatar>
                                        )}
                                        <Paper
                                            elevation={0}
                                            className="message-paper other-user-paper-custumer"
                                        >
                                            <div className="message-row">
                                                <div className="message-content">
                                                    <>
                                                        <div className="message-text">loading.....</div> 
                                                    </>
                                                </div>
                                            </div>
                                        </Paper>
                                        </div>
                                    </Skeleton>
                                :
                                    <div
                                        key={index}
                                        className={`chat-message ${(chat.userId === user?.user_id) ? "current-user" : "other-user"
                                            }`}
                                    >
                                        {chat.userId !== user?.user_id && (
                                            <Avatar
                                                alt="User Avatar"
                                                src={chatBotImg}
                                                sx={{
                                                    width: 32,
                                                    height: 32,
                                                    fontSize: "16px",
                                                    marginRight: "10px",
                                                    marginBottom: "10px",
                                                    backgroundColor: "grey"
                                                }}
                                            >
                                            </Avatar>
                                        )}
                                        <Paper
                                            elevation={0}
                                            className={`message-paper ${chat.userId === user?.user_id
                                                ? "current-user-paper-custumer"
                                                : "other-user-paper-custumer"
                                                }`}
                                        >
                                            <div className="message-row">
                                                <div className="message-content">
                                                    <>
                                                        {/* <div className="message-text">{chat.message}</div> */}
                                                        <MessageComponent message={chat.message} />
                                            <div>
                                                  {chat?.content?.type === "image" ?(
                                                      <img
                                                          src={chat?.content?.rawUrl} 
                                                          style={{ width: 210, height: 80, borderRadius: 12, margin:"10px 0"}}
                                                     />
                                                  ):null}
                                             </div>
                                             <div>
                                                 {chat?.content?.type === "site" ?(
                                                 <a href={chat?.content?.rawUrl} target="blank">{chat?.content?.rawUrl}</a>

                                                 ):null}
                                             </div>
                                                        <div className={`${(chat.userId !== user.user_id ? "timestamp-row" : 'timestamp-row-same-user')} ${formatTimestamp(chat.timestamp) === 'Just now' ? 'animate' : ''}`}>
                                                   {formatTimestamp(chat.timestamp)}
                                                    </div>
                                                    </>
                                                </div>
                                            </div>
                                        </Paper>
                                    </div>
                                }
                            </>
                        ))}
                    </div>
                </Col>
            </Row>
        </Container>  
   )
}

export default ChatBotComponent