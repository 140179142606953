import { ExpandLess, ExpandMore, Folder, FolderOpen } from "@material-ui/icons";
import {
  Collapse,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import React from "react";

const ReportCategoryList = ({
  category,
  onCategoryClick,
  onReportItemClick,
}) => {
  return (
    <>
      <ListItemButton onClick={() => onCategoryClick(category)}>
        <ListItemIcon>
          {category.isOpen ? <FolderOpen /> : <Folder />}
        </ListItemIcon>
        <ListItemText primary={category.name} />
        {category.isOpen ? <ExpandLess /> : <ExpandMore />}
      </ListItemButton>
      <Collapse in={category.isOpen} timeout="auto" unmountOnExit>
        <List dense={false} component={"div"} className="mr-4">
          {category.reports.map((report, i) => (
            <ListItemButton
              key={i}
              sx={{ pl: 4 }}
              onClick={() => onReportItemClick(report)}
            >
              <ListItemText>{report.report_name}</ListItemText>
            </ListItemButton>
          ))}
        </List>
      </Collapse>
    </>
  );
};

export default ReportCategoryList;
