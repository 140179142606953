import { ExpandLess, ExpandMore, Folder, FolderOpen } from "@material-ui/icons";
import {
  Checkbox,
  Collapse,
  FormControlLabel,
  FormGroup,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import LoadingContext from "../../../container/loadingContext";
import { getStorage } from "../../../utilities/browserStorage";
import TextInput from "../../commons/input/input";
import BootStrapCardWithHeader from "../ReportBuilder/BootStrapCardWithHeader";
import service from "../service";


const AllReportCard = ({ onReportItemClickHandler }) => {
  const setShowLoadingBar = useContext(LoadingContext);
  const practicePK = getStorage("practice");

  const [categories, setCategories] = useState([]);
  const [isExpand, setIsExpand] = useState(false);

  const formatData = (responseData) => {
    let tempData = [];
    responseData.forEach((element) => {
      tempData.push({ ...element, isOpen: false });
    });
    return tempData;
  };

  const handleOnCheckChange = (event) => setIsExpand(event.target.checked);

  const onItemClick = (item) => {
    const newState = categories.map((obj) => {
      if (obj.id === item.id) {
        return { ...obj, isOpen: !item.isOpen };
      }
      return obj;
    });
    setCategories(newState);
  };

  useEffect(() => {
    setShowLoadingBar(true);
    service
      .getSavedReports(practicePK)
      .then((response) => {
        setShowLoadingBar(false);
        if (response.status === 200 || response.status === 201) {
          const data = response.data.data;
          setCategories(formatData(data));
        }
      })
      .catch(() => {
        setShowLoadingBar(false);
      });
  }, []);

  useEffect(() => {
    const newState = categories.map((item) => {
      return { ...item, isOpen: isExpand };
    });
    setCategories(newState);
  }, [isExpand]);

  return (
    <BootStrapCardWithHeader cardTitle={"All Reports"}>
      <div className="m-2 relative" >
        <TextInput placeholder={"Search for Reports (3 Characters)"}
        //  name="values" value={values} onValueChange={e => setValues(e.target.value)}
         />
        <FormGroup>
          <FormControlLabel
            control={
              <Checkbox
                value={isExpand}
                onChange={handleOnCheckChange}
                inputProps={{ "aria-label": "controlled" }}
              />
            }
            label="Expand All"
          />
        </FormGroup>
      </div>
      <List dense={false} component={"nav"}>
        {categories.length !== 0 ? (
          categories.map((category, index) => (
            <div key={index}>
              <ListItemButton onClick={() => onItemClick(category)}>
                <ListItemIcon>
                  {category.isOpen ? <FolderOpen /> : <Folder />}
                </ListItemIcon>
                <ListItemText primary={category.name} />
                {category.isOpen ? <ExpandLess /> : <ExpandMore />}
              </ListItemButton>
              <Collapse in={category.isOpen} timeout="auto" unmountOnExit>
                <List dense={false} component={"div"} className="mr-4">
                  {category.reports.map((report, i) => (
                    <ListItemButton
                      key={i}
                      sx={{ pl: 4 }}
                      onClick={() => onReportItemClickHandler(report)}
                    >
                      <ListItemText>{report.report_name}</ListItemText>
                    </ListItemButton>
                  ))}
                </List>
              </Collapse>
            </div>
          ))
        ) : (
          <div>No Reports Available</div>
        )}
      </List>
    </BootStrapCardWithHeader>
  );
};

export default AllReportCard;
