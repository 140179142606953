import React, { useState, useEffect, useContext } from "react";
import { Form } from "react-bootstrap";

import "./style.css";
import service from "./service";
import { PracticeLocationTableData } from "./PracticeTable";

import CustomizedDialogs from "../../modalWindowComponent/CustomizedDialogs";

import i18n from "../../../utilities/i18n";
import { US_STATES } from '../../../utilities/usStates';
import { setStorage, getStorage } from '../../../utilities/browserStorage';
import { permission_key_values_accountsetup } from "../../../utilities/permissions";
import { ADD_SUCCESS, ADD_ERROR, UPDATE_SUCCESS } from "../../../utilities/labelConfigs";
import { checkPermission, commonTableBody, ValidateEmail } from "../../../utilities/commonUtilities";
import { DEFAULT_PAGING_SIZE, DEFAULT_COUNTRY, ACTIVE_TYPES, ACTIVE_TYPES_ACTIVE_ID } from "../../../utilities/staticConfigs";

import LoadingContext from "../../../container/loadingContext";

import Notify from "../../commons/notify";
import Table from "../../commons/Table/Table";
import TextInput from "../../commons/input/input";
import SelectInput from '../../commons/input/SelectInput';
import { CityInput } from "../../commons/input/CityInput";
import EmailInput from "../../commons/EmailInput/EmailInput";
import PhoneInput from "../../commons/PhoneInput/PhoneInput";
import GeoCodeSearch from '../../commons/helpers/geoCodeApiSearch';

const PracticeLocations = (props) => {
    const practicePK = props.practicePK;
    const currentPracticePK = getStorage("practice");
    const [header, setHeader] = useState("");
    const [showAddLocationModalWindow, setShowAddLocationModalWindow] = useState(false);

    const [practiceLocations, setPracticeLocations] = useState([])
    const [locationName, setLocationName] = useState('');
    const [clia, setCLIA] = useState('');
    const [street1, setStreet1] = useState('');
    const [street2, setStreet2] = useState('');
    const [city, setCity] = useState('');
    const [state, setState] = useState('');
    const [zip, setZip] = useState('');
    const [country, setCountry] = useState(DEFAULT_COUNTRY);
    const [phone, setPhone] = useState('');
    const [fax, setFax] = useState('');
    const [email, setEmail] = useState('');
    const [locationEditPK, setLocationEditPK] = useState('');
    const [form_error, setFormError] = useState({
        'locationName': '', 'street1': '', 'city': '', 'state': '', 'zip': '', 'phone': ''
    });

    const [showNotify, setShowNotify] = useState("hide");
    const [notifyDescription, setNotifyDescription] = useState("");
    const [notifyType, setNotifyType] = useState("success");
    const setShowLoadingBar = useContext(LoadingContext);
    const [inactive, setInactive] = useState(false);
    const [inactivePracticeLocationModal, setInactivePracticeLocationModal] = useState(false);
    const [activeType, setActiveType] = useState(ACTIVE_TYPES_ACTIVE_ID);

    function showNotifyWindow(action, type, desc, age = 3000) {
        if (action === "show") {
            setTimeout(() => {
                setShowNotify("hide");
            }, age);
        }
        setShowNotify(action);
        setNotifyType(type);
        setNotifyDescription(desc);
    }

    //Pagination start
    const activePage = 1;

    useEffect(() => {
        if (practicePK)
            getPracticeLocations(DEFAULT_PAGING_SIZE, activePage);
    }, [practicePK]);

    function getPracticeLocations(pageSize, page, setStatus) {
        setShowLoadingBar(true);
        let selStatusId = setStatus ? setStatus : activeType;
        let arrObj = ACTIVE_TYPES.filter(item => parseInt(item.id, 10) === parseInt(selStatusId, 10));
        let status = arrObj[0].name.toLowerCase();
        const result = service.ListPracticeLocations(pageSize, page, practicePK, true, status);
        result.then(response => {
            setShowLoadingBar(false);
            const rowArray = commonTableBody(response.data.results, PracticeLocationTableData.tableBodyData[0])
            PracticeLocationTableData.tableBodyData = rowArray;
            setPracticeLocations(response.data.results);
        });
    }

    const inputStateSetterMap = {
        'locationName': setLocationName,
        'clia': setCLIA,
        'street1': setStreet1,
        'street2': setStreet2,
        'city': setCity,
        'state': setState,
        'zip': setZip,
        'country': setCountry,
        'phone': setPhone,
        'fax': setFax,
        'locEmail': setEmail,
        'email':setEmail
    };

    function onHandleChange(e) {
        let input_session = getStorage('input_session');

        if (input_session === null || input_session === undefined || input_session === '') {
            setStorage('input_session', JSON.stringify({ 'practice_location': {} }))
            input_session = getStorage('input_session');
        }
        
        input_session = JSON.parse(input_session);
        if (!Object.prototype.hasOwnProperty.call(input_session, "practice_location")) {
            input_session["practice_location"] = {};
        }
        const name = e.target.name;
        let value = e.target.value;
        let trimmedValue = '';
        let inputFields = [
            'locationName', 'clia', 'street1', 'street2', 'city', 'state', 'zip', 'country', 'phone', 'fax', 'locEmail'
        ]
        if (inputFields.includes(name)) trimmedValue = value;
        let mandatoryFields = [
            'locationName', 'street1', 'city', 'state', 'zip', 'phone'
        ]
        if (mandatoryFields.includes(name) && !trimmedValue.trim())
            setFormError({
                ...form_error,
                [name]: "error",
            });
        else
            setFormError({
                ...form_error,
                [name]: "",
            });

        const setterFunction = inputStateSetterMap[name];
        if (setterFunction) {
                setterFunction(trimmedValue);
        }
        input_session['practice_location'][name] = trimmedValue
        setStorage('input_session', JSON.stringify(input_session));
    }

    function onEditPracticeLocation(locationPK) {
        setShowLoadingBar(true);
        setShowAddLocationModalWindow(true);
        setHeader(i18n.t("userPages.practices.locations.modalEditHeader"));
        const data = service.GetPracticeLocationData(locationPK);
        data.then((response) => {
            setLocationEditPK(response.data.id);
            setLocationName(response.data.name);
            setCLIA(response.data.clia);
            if (response.data.location_data) {
                setStreet1(response.data.location_data.street1);
                setStreet2(response.data.location_data.street2);
                setCity(response.data.location_data.city);
                setState(response.data.location_data.state);
                setZip(response.data.location_data.zip_code);
                setCountry(response.data.location_data.country);
                setInactive(!response.data.location_data.active);
            }
            setPhone(response.data.phone);
            setFax(response.data.fax);
            setEmail(response.data.email);
            setInactive(!response.data.active);
            setShowLoadingBar(false);
        });
    }

    function onResetAddLocation() {
        setLocationEditPK('');
        setLocationName('');
        setCLIA('');
        setStreet1('');
        setStreet2('');
        setCity('');
        setState('');
        setZip('');
        setCountry(DEFAULT_COUNTRY);
        setPhone('');
        setFax('');
        setEmail('');
        setInactive(false);
        let input_session = getStorage('input_session');
        if (input_session) {
            input_session = JSON.parse(input_session);
            if (Object.prototype.hasOwnProperty.call(input_session, 'practice_location')) {
                const practice_location = input_session['practice_location'];
                for (const key in practice_location) {
                    const setterFunction = inputStateSetterMap[key];
                    if (setterFunction) {
                            setterFunction(practiceLocations[key]);
                    }
                }
            }

        }
    }

    function onHideAddLocation() {
        setShowAddLocationModalWindow(false);
        onResetAddLocation();
    }

    //validation input field errors
    function validateFormData() {
        const errors = {};
        if (email && !ValidateEmail(email)) {
            errors["email"] = "error";
        }
        if (!locationName.trim()) {
            errors["locationName"] = "error";
        } else if (!street1.trim()) {
            errors["street1"] = "error";
        } else if (!city.trim()) {
            errors["city"] = "error";
        } else if (!state.trim()) {
            errors["state"] = "error";
        } else if (!zip.trim()) {
            errors["zip"] = "error";
        } else if (!phone.trim()) {
            errors["phone"] = "error";
        }
        return errors;
    }

    function onSaveFormData(e) {
        e.preventDefault();
        const errors = validateFormData();
        if (Object.keys(errors).length > 0) {
            setFormError({
                ...form_error,
                ...errors,
            });
        } else {
            setShowLoadingBar(true);
            let result = null;
            let data = {
                practice: practicePK, name: locationName, clia: clia, location: {
                    street1: street1, street2: street2, city: city,
                    state: state, zip_code: zip, country: country
                }, phone: phone, fax: fax, email: email, inactive: inactive,
                currentPracticePK: currentPracticePK,
            }
            result = locationEditPK ? service.UpdatePracticeLocation(locationEditPK, data) : service.SavePracticeLocation(data);
            result.then((response) => {
                setShowLoadingBar(false);
                if (response.status === 201 || response.status === 200) {
                    if (response.status === 201) {
                        showNotifyWindow("show", "success", ADD_SUCCESS);
                    }
                    else {
                        showNotifyWindow("show", "success", UPDATE_SUCCESS);
                    }
                    setActiveType(ACTIVE_TYPES_ACTIVE_ID);
                    getPracticeLocations(DEFAULT_PAGING_SIZE, activePage, ACTIVE_TYPES_ACTIVE_ID);
                    onResetAddLocation();
                    setShowAddLocationModalWindow(false);
                    setInactivePracticeLocationModal(false);
                    setStorage('input_session', JSON.stringify({ 'practice_location': {} }))
                    props.getPracticeLocationData(practicePK);
                } else if (response.status === 400 && response.data.record_already_exists !== undefined) {
                    showNotifyWindow('show', 'error', i18n.t('errorMessages.record_exists'));

                } else {
                    showNotifyWindow("show", "error", ADD_ERROR);
                }

            });
        }
    }

    function addNew() {
        setShowAddLocationModalWindow(true);
        setHeader(i18n.t("userPages.practices.locations.modalAddHeader"));
        onResetAddLocation();
    }

    function onInactivePracticeLocation(e) {
        e.preventDefault();
        setInactivePracticeLocationModal(true);
    }

    useEffect(() => {
        if (practicePK)
            getPracticeLocations(DEFAULT_PAGING_SIZE, 1);
    }, [activeType]);

    function onStatusHandleChange(e) {
        setActiveType(e.target.value);
    }

    function dropdownFunction(id, name) {
        if (name.toLowerCase() === 'edit') {
            if(checkPermission(permission_key_values_accountsetup.account_setup_practice_management_modify)){
            onEditPracticeLocation(id);
            } else {
                showNotifyWindow('show', 'error', i18n.t('errorMessages.permission_error'));
            }
        }
    }

    return (
        <React.Fragment>
            <div>
                <Notify showNotify={showNotify} setShowNotify={setShowNotify} notifyDescription={notifyDescription} setNotifyType={setNotifyType} setNotifyDescription={setNotifyDescription} notifyType={notifyType} />
                <Form id="form_practice_location" autoComplete="off">
                    <Form.Group>
                        <div className="row mt-2">
                            <div className="padding-right20 col">
                                <div className="alignRight padding-top16 padding-left15">
                                    {checkPermission(permission_key_values_accountsetup.account_setup_practice_management_add) &&
                                    <button type="button" className="btn btn-primary-blue margin-top10" onClick={addNew}>+ {i18n.t("userPages.practices.locations.addLocation")}</button>}
                                </div>
                                <div className="alignRight">
                                    <SelectInput data={ACTIVE_TYPES} name="ActiveType" id="ActiveType" value={activeType} onValueChange={onStatusHandleChange} label={i18n.t('commons.status')} selectOptionRemove={true} />
                                </div>
                            </div>
                        </div>
                        <div className="box-content">
                            <div className="padding-left10">
                                <Table tableObject={PracticeLocationTableData} dropDownFunction={dropdownFunction} />
                                <div className="clear-b-scroll-sector">&nbsp;</div>
                            </div>
                        </div>
                    </Form.Group>
                </Form>
                <CustomizedDialogs showModal={showAddLocationModalWindow} type="save" header={header} setShowModalWindow={setShowAddLocationModalWindow} resetForm={onResetAddLocation} onHide={onHideAddLocation} setInactive={setInactive} inactive={inactive} chkInactive={locationEditPK ? true : false}>
                    <Form id="form_dataEntry" onSubmit={inactive ? (e) => onInactivePracticeLocation(e) : (e) => onSaveFormData(e)} encType="multipart/form-data" autoComplete="off">
                        <div className="row">
                            <div className="col-6">
                                <div className="form-group ">
                                    <TextInput type="text" id="locationName" name="locationName" required={true} label={i18n.t('userPages.practices.locations.labellocationName')} onValueChange={onHandleChange} className={form_error.locationName ? 'input-error' : ""} value={locationName} />
                                </div>
                            </div>
                            <div className="col-6">
                                <div className="form-group ">
                                    <TextInput type="text" id="clia" name="clia" label={i18n.t('userPages.practices.locations.labelCLIA')} onValueChange={onHandleChange} value={clia} />
                                </div>
                            </div>
                        </div>
                        <div className="row sub-heading">
                            <div className="col-1">
                                <div className="div-inline">
                                    <label className="margin-top-3px">{i18n.t("userPages.practices.locations.labelAddress")}</label>
                                </div>
                            </div>

                        </div>
                        <div className="row">
                            <div className="col-6">
                                <div className="form-group ">
                                    <TextInput type="text" id="street1" name="street1" required={true} label={i18n.t('userPages.practices.locations.labelStreet1')} onValueChange={onHandleChange} className={form_error.street1 ? 'input-error' : ""} value={street1} />
                                </div>
                            </div>
                            <div className="col-6">
                                <div className="form-group ">
                                    <TextInput type="text" id="street2" name="street2" label={i18n.t('userPages.practices.locations.labelStreet2')} onValueChange={onHandleChange} value={street2} />
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-3">
                                <div className="form-group ">
                                    <CityInput type="text" id="city" name="city" required={true} label={i18n.t('userPages.practices.locations.labelCity')} onValueChange={onHandleChange} className={form_error.city ? 'input-error' : ""}
                                        value={city} setValue={setCity} zip={zip} />
                                </div>
                            </div>
                            <div className="col-3">
                                <div className="form-group ">
                                    <SelectInput listItem={'US_StateList'} required={true} id="state" data={US_STATES} name="state" value={state} onValueChange={onHandleChange} label={i18n.t('patientPages.patientInfo.labelState')} className={form_error.state ? 'input-error' : ""} />

                                </div>
                            </div>
                            <div className="col-3">
                                <div className="form-group ">
                                    <GeoCodeSearch id="zip" name="zip" className={form_error.zip ? 'input-error' : ""} required={true}
                                        label={i18n.t("customerSetup.provider.labelZip")} onValueChange={onHandleChange}
                                        setlocState={setState} setLocCity={setCity}
                                        setLocCountry={setCountry} setLocZipCode={setZip} defaultValue={zip} />
                                </div>
                            </div>
                            <div className="col-3">
                                <div className="form-group ">
                                    <TextInput type="text" id="country" name="country" label={i18n.t('userPages.practices.locations.labelCountry')} onValueChange={onHandleChange} value={country} />
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-3">
                                <div className="form-group">
                                    <PhoneInput type="text" id="phonemodal" name="phonemodal" required={true} label={i18n.t('userPages.practices.locations.labelPhone')} onValueChange={setPhone} value={phone} />
                                </div>
                            </div>
                            <div className="col-3">
                                <div className="form-group">
                                    {/* <TextInput type="text" id="fax" name="fax" label={i18n.t('userPages.practices.locations.labelFax')} onValueChange={onHandleChange} value={fax} /> */}
                                    <PhoneInput type="text" id="faxmodal" name="faxmodal" label={i18n.t('userPages.practices.locations.labelFax')} onValueChange={setFax} value={fax} />
                                </div>
                            </div>
                            <div className="col-6">
                                <div className="form-group padding-top3">
                                    <EmailInput id="locEmail" name="locEmail" label={i18n.t('userPages.practices.locations.labelEmail')} onValueChange={onHandleChange} value={email} />
                                </div>
                            </div>
                        </div>
                    </Form>
                </CustomizedDialogs>
                <CustomizedDialogs showModal={inactivePracticeLocationModal} setShowModalWindow={setInactivePracticeLocationModal} header={i18n.t("commons.confrimInactiveHeader")} type="yes"
                    inactveItem={onSaveFormData} buttonStyle="danger">
                    {i18n.t("commons.confrimInactive") + " practice location?"}
                </CustomizedDialogs>
            </div>
        </React.Fragment>
    );
};
export default PracticeLocations;