import axios from "axios";
import { URL_BASE_API } from "../utilities/urlConfigs";
import { getStorage, setStorage } from "../utilities/browserStorage";
import { store } from "../store/store";

// Create a single axios instance
const api = axios.create({
  baseURL: URL_BASE_API,
  timeout: 60 * 10 * 3000, //30 minutes
});

const ExceptionHandler = (error, errorType) => {
  if (error?.message === 'refresh_token_expired' || error === 'refresh_token_expired') {
    localStorage.clear();

    // If the session got expired any report execution the first setting the isReportRunning variable to false
    // Otherwise when page reload is getting executed it will prompt user to choose "Do you really want to reload the page" confirmation.
    if (store.getState()?.mainReportStatus.isReportRunning) {
      store.dispatch({ type: 'report/setReportRunning', payload: { isRunning: false, report: '' } })
    }
    // Setting a session out flag in cookie as local storage is not a reliable option for this context.
    // This flag will be accessed in the login component to indicate user that they are routed due to session out.
    document.cookie = 'isSessionOut=true; path=/;';
    window.location.href = '/';  // route user back login screen
  }
  if (errorType === "get") {
    if (
      error === "Network Error" ||
      // error?.response?.status === 403 ||
      error?.response === undefined ||
      error?.response?.status === 401 ||
      error?.response?.status === 426
    ) {
      localStorage.clear();
      if (error?.response?.status === 426) {
        setStorage("passwordExpired", true);
        setStorage("userID", error?.response?.data.user_id);
      }
    }
  } else {
    if (
      error?.response?.status === 401 ||
      error?.response?.status === 426 
      // error?.response?.status === 403
    ) {
      localStorage.clear();
      if (error?.response?.status === 426) {
        setStorage("passwordExpired", true);
        setStorage("userID", error?.response?.data?.user_id);
      }
    }
  }
}

// Axios interceptor to add practice_pk parameter to URL if it's not present
// This is required for permission check in the backend
api.interceptors.request.use((config) => {
  const userData = JSON.parse(getStorage("userData") || "{}");
  const isAdminModule = getStorage('isAdminModule');

  if (isAdminModule === 'false' && userData?.accessToken && config.headers?.Authorization) {
    const key = "current_practice_id";
    const practiceStorage = getStorage("practice");
    const practice = practiceStorage !== null ? Number(practiceStorage) : null;

    if (practice && !isNaN(practice)) {
      if (config.method === 'get') {
        // Append the practice variable to the URL search params for GET requests
        const params = new URLSearchParams(config.params);
        params.append(key, practice);

        // If the URL already has query parameters, add an ampersand before appending
        config.url += (config.url.includes('?') ? '&' : '?') + params.toString();
      } else if (['post', 'put', 'delete'].includes(config.method.toLowerCase())) {
        // Append the practice variable to the request body for POST, PUT, and DELETE requests with the Content-Type of application/json or multipart/form-data
        const contentType = config.headers['Content-Type'];
        if (contentType && (contentType.includes('application/json') || contentType.includes('multipart/form-data'))) {
          if (config?.data instanceof FormData) {
            // If the data is a FormData object, append the key and practice to it
            config.data.append(key, practice);
          } else {
            if (config?.data) {
              config.data[key] = practice;
            } else {
              config.data = { [key]: practice };
            }
          }
        }
      }
    }
  }

  return config;
});

/** SESSION LOGICS 
 * When the access token has expired the response will be intercepted within the axios interceptor method.
 * If there is no token refreshing process currently underway, we set the isRefreshing flag to true.
 * Now thats done, from the interceptor execute an api call to refresh the token, which will return new access token if the refresh/master token got expired
 * Mean while moving all the consecutive client requests to a promise queue named as failedQueue.
 * If the refresh token api returned new token then resolve the first api which got 401 from the below interceptor block itself.
 * And finally resolving or rejecting each apis stored in the failedQueue to re-execute the network call.
 * Incase the refresh/master token has expired then processQueue will be receiving error instead of new token, that will then trigger to route user to the login screen   
 */
let isRefreshing = false;
let failedQueue = [];
let refreshPromise = null;

const processQueue = async (error, token = null) => {
  if (error) {
    failedQueue.forEach((prom) => {
      prom.reject(error);
    });
  } else {
    for (const prom of failedQueue) {
      const config = prom.config;
      config.headers.Authorization = `Bearer ${token}`;
      try {
        const result = await api(config);
        prom.resolve(result);
      } catch (err) {
        prom.reject(err);
      }
    }
  }
  isRefreshing = false;
  failedQueue = [];
};

/**
 * Axios interceptor   
 * @param {Object} response - Axios response object
 * @returns {Object} - Axios response object or new Promise with reject or original API request
 */
api.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    const originalRequest = error.config;
    if (error.response.status === 403) {
      store.dispatch({ type: "commonStates/setCommonState", payload: { name: "permissionErrorFlag", value: true } })

    } else if (
      error.response.status === 401 &&
      getStorage("userData")
    ) {
      // Push the failed requests to failedQueue for re-execution after token refresh
      const retryOriginalRequest = new Promise((resolve, reject) => {
        // Check if the data is an instance of FormData
        if (originalRequest.data instanceof FormData) {
          // If it is, skip the JSON.parse() step
          failedQueue.push({ resolve, reject, config: { ...originalRequest, data: originalRequest.data } });
        } else if (originalRequest.data) {
          // If it's not, and data is defined, parse the data as JSON
          failedQueue.push({ resolve, reject, config: { ...originalRequest, data: JSON.parse(originalRequest.data) } });
        } else {
          // If data is not defined, don't include it in the config
          failedQueue.push({ resolve, reject, config: { ...originalRequest } });
        }
      });
      if (!isRefreshing) {
        isRefreshing = true;
        const refreshToken = JSON.parse(getStorage("userData")).refreshToken;
        refreshPromise = api.post("/user/token/refresh", {
          refresh_token: refreshToken,
        });
        try {
          const response = await refreshPromise;
          // If the response is valid, update the access and refresh tokens in the local storage and call the processQueue method to begin re-execution of failed apis
          if (response?.data?.access && response?.data?.refresh) {
            const userData = JSON.parse(getStorage("userData"));
            userData.accessToken = response.data.access;
            userData.refreshToken = response.data.refresh;
            localStorage.setItem('userData', JSON.stringify(userData));
            const renewedToken = response.data.access;
            // Since a new valid token is received call the processQueue method to begin re-execution of failed apis
            processQueue(null, renewedToken);
          } else {
            throw new Error('refresh_token_expired');
          }
        } catch (error) {
          localStorage.clear();
          isRefreshing = false;
          processQueue('refresh_token_expired', null);
        }
      }
      return retryOriginalRequest;
    } else if (error.response.status !== 401) {
      return Promise.reject(error);
    }
  }
);
/** LOGICS FOR ACCESS TOKEN ENDS HERE */

const login = (path, data) => {
  let client = axios.create({
    baseURL: URL_BASE_API,
    timeout: 60 * 10 * 3000, //30 minutes
    headers: {
      "Content-Type": "application/json",
    },
  });
  return client
    .post(path, JSON.stringify(data))
    .then((response) => {
      return response;
    })
    .catch((error) => {
      // return error;
      let errorResp = {
        status: error.response.status,
        statusText: error.response.statusText,
        data: error.response.data,
      };
      return errorResp;
    });
};

const getXLS = async (path) => {
  const userGroupData = JSON.parse(getStorage("userData") || "{}");
  const userGroupToken = userGroupData.accessToken || "";
  const unid = userGroupData.unid || "";

  try {
    const response = await api.get(path, {
      headers: {
        "Content-Type": "blob",
        Authorization: `Bearer ${userGroupToken}`,
        Unid: unid,
      },
      responseType: "blob",
    });
    return response;
  } catch (error) {
    ExceptionHandler(error, "get");
    return {
      status: error?.response?.status || "",
      statusText: error?.response?.statusText || "",
      data: "",
    };
  }
};

const get = async (path) => {
  const userGroupData = JSON.parse(getStorage("userData") || "{}");
  const userGroupToken = userGroupData.accessToken || "";
  const unid = userGroupData.unid || "";

  try {
    const response = await api.get(path, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userGroupToken}`,
        Unid: unid,
      },
    });
    return response;
  } catch (error) {
    ExceptionHandler(error, "get");
    let errorState = {
      status: error?.response?.status,
      statusText: error?.response?.statusText,
      error_data: error?.response?.data ?? "",
      message: error?.response?.data?.message || ""
    };
    return errorState;
  }
};

const post = async (path, data) => {
  const userGroupData = JSON.parse(getStorage("userData") || "{}");
  const userGroupToken = userGroupData.accessToken || "";
  const unid = userGroupData.unid || "";

  try {
    return await api.post(path, data, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userGroupToken}`,
        Unid: unid,
      },
    });
  } catch (error) {
    ExceptionHandler(error);
    let errorState = {
      status: error?.response?.status,
      statusText: error?.response?.statusText,
      data: error?.response?.data ? error?.response?.data : "",
    };
    return errorState;
  }
};

const put = async (path, data) => {
  const userGroupData = JSON.parse(getStorage("userData") || "{}");
  const userGroupToken = userGroupData.accessToken || "";
  const unid = userGroupData.unid || "";

  try {
    return await api.put(path, data, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userGroupToken}`,
        Unid: unid,
      },
    });
  } catch (error) {
    ExceptionHandler(error);
    return {
      status: error?.response?.status,
      statusText: error?.response?.statusText,
      data: error?.response?.data ? error?.response?.data : "",
    };
  }
};

const destroy = (path) => {
  const userGroupData = JSON.parse(getStorage("userData") || "{}");
  const userGroupToken = userGroupData.accessToken || "";
  const unid = userGroupData.unid || "";

  try {
    return api.delete(path, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userGroupToken}`,
        Unid: unid,
      },
    });
  } catch (error) {
    ExceptionHandler(error);
    return {
      status: error?.response?.status,
      statusText: error?.response?.statusText,
      data: "",
    };
  }
};

const getWithoutJWT = async (path) => {
  try {
    return await api.get(path, {
      headers: {
        "Content-Type": "application/json",
      },
    });
  } catch (error) {
    ExceptionHandler(error, "get");
    return {
      status: error.response.status,
      statusText: error.response.statusText,
      data: "",
    };
  }
};

const postWithoutJWT = async (path, data) => {
  try {
    return await api.post(path, data, {
      headers: {
        "Content-Type": "application/json",
      },
    });
  } catch (error) {
    ExceptionHandler(error);
    return {
      status: error?.response?.status,
      statusText: error?.response?.statusText,
      data: error?.response?.data,
    };
  }
};

const putWithoutJWT = async (path, data) => {
  try {
    return await api.put(path, data, {
      headers: {
        "Content-Type": "application/json",
      },
    });
  } catch (error) {
    ExceptionHandler(error);
    return {
      status: error?.response?.status,
      statusText: error?.response?.statusText,
      data: error?.response?.data ? error?.response?.data : "",
    };
  }
};

const postUpload = async (path, data) => {
  const userGroupData = JSON.parse(getStorage("userData") || "{}");
  const userGroupToken = userGroupData.accessToken || "";
  const unid = userGroupData.unid || "";

  try {
    return await api.post(path, data, {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${userGroupToken}`,
        Unid: unid,
      },
    });
  } catch (error) {
    ExceptionHandler(error);
    return {
      status: error?.response?.status,
      statusText: error?.response?.statusText,
      data: error?.response?.data ? error?.response?.data : "",
    };
  }
};

export default {
  login,
  get,
  post,
  put,
  destroy,
  getWithoutJWT,
  postWithoutJWT,
  putWithoutJWT,
  getXLS,
  postUpload,
};