import api from '../../service/api'
import { getStorage } from '../../utilities/browserStorage';

const GetListPatientDropdown = async (search, type, patientPK) => {
    let path = 'patient/patients/?list_type=drop_down&page=0&practice_pk='+ getStorage('practice') +'&search='+ search;
    if (type)
        path += '&type='+ type;
    if (patientPK)
        path += '&patient_pk='+ patientPK;
    return api.get(path);
}

const GetListMatchingProvider = async (pageSize, page, practicePK) => {
    let path = 'interface/assign-matching-provider/?page_size=' + pageSize + '&page=' + page + '&practice_pk='+practicePK;
    return api.get(path);
}

const SearchPosCodes = async (query) => {
    let path = 'super-admin/service-codes/?' + 'column=name&search=' + query;
    return api.get(path);
    
}

const SaveNewMatchingProvider = async (data) => {
    let path = 'interface/assign-matching-provider/';
    return api.post(path, data);
    
}

const UpdateMatchingProvider = async (providerPK, data) => {
    let path = 'interface/assign-matching-provider/'+providerPK;
    return api.put(path, data);
    
}

const GetListMatchingPayer = async (pageSize, page, practicePK) => {
    let path = 'interface/assign-matching-payer/?page_size=' + pageSize + '&page=' + page + '&practice_pk='+practicePK;
    return api.get(path);
}

const GetCPTCodeList = async(pageSize, page, practicePK, type, search, claimPK) =>{
    let path = "practice/cpt-codes/?page_size=" + pageSize + "&page=" + page + "&practice_pk=" + practicePK + "&search=" +search;
    if(type){
        path += "&type="+type
    }
    if (claimPK)
        path += '&opened_claim_pk='+ claimPK;
    return api.get(path);
    
}

const SaveNewMatchingPayer = async (data) => {
    let path = 'interface/assign-matching-payer/';
    return api.post(path, data);
}

const UpdateMatchingPayer = async (providerPK, data) => {
    let path = 'interface/assign-matching-payer/'+providerPK;
    return api.put(path, data);
}

const GetModifier = async (query) => {
    let path = 'super-admin/modifier/?search=' + query;
    return api.get(path);
}

const NewTestCode = async (data) => {
    let path = 'interface/test-code/';
    return api.post(path, data);
}

const ListTestCode = async (practicePK) => {
    let path = 'interface/test-code/?practice_pk='+practicePK;
    return api.get(path);
}

const getTestCode = async (codePK) => {
    let path = 'interface/test-code/'+codePK;
    return api.get(path);
}

const UpdateTestCode = async (codePK, data) => {
    let path = 'interface/test-code/'+codePK;
    return api.put(path, data);
}

const GetListMatchingFacility = async (pageSize, page, practicePK) => {
    let path = 'interface/assign-matching-facility/?page_size=' + pageSize + '&page=' + page + '&practice_pk='+practicePK;
    return api.get(path);
}

const SaveNewMatchingFacility = async (data) => {
    let path = 'interface/assign-matching-facility/';
    return api.post(path, data);
}

const UpdateMatchingFacility = async (facilityPK, data) => {
    let path = 'interface/assign-matching-facility/'+facilityPK;
    return api.put(path, data);
}

const ListInputFacilities = async (practicePK, facilityPK) => {
    let path = 'interface/list-external-facility/?practice_pk='+practicePK;
    if(facilityPK)
        path += '&facility_pk='+facilityPK;
    return api.get(path);
}

const GetMatchingProvider = async (providerPK) => {
    let path = 'interface/assign-matching-provider/'+providerPK;
    return api.get(path);
}

const GetMatchingPayer = async (providerPK) => {
    let path = 'interface/assign-matching-payer/'+providerPK;
    return api.get(path);
}

const GetMatchingFacility = async (providerPK) => {
    let path = 'interface/assign-matching-facility/'+providerPK;
    return api.get(path);
}

const ListInputProvider = async (practicePK, providerPK) => {
    let path = 'interface/list-external-provider/?practice_pk='+practicePK;
    if(providerPK)
        path += '&provider_pk='+providerPK;
    return api.get(path);
}

const ListInputPayer = async (practicePK, payerPK) => {
    let path = 'interface/list-external-payer/?practice_pk='+practicePK;
    if(payerPK)
        path += '&payer_pk='+payerPK;
    return api.get(path);
}

const ListInterfaceData = async (pageSize, page, practicePK, query, exportType) => {
    let path = 'interface/interface-data/?page_size=' + pageSize + '&page=' + page + '&practice_pk='+practicePK;
    if(query)
        path += query;
    let result = '';
    if(exportType === 'xls')
        result = api.getXLS(path);
    else
        result = api.get(path);
    return result;
}

const GetMessage = async(messagePK, practicePK) => {
    let path = 'interface/interface-data/'+messagePK+'?practice_pk='+practicePK;
    return api.get(path);
}

const ReprocessInterfaceData = async(data) => {
    let path = 'interface/reprocess-data/';
    return api.post(path, data);
}

const ErrorFixInterfaceData = async(data) => {
    let path = 'interface/error-fix/';
    return api.post(path, data);
}

export default {
    GetListPatientDropdown, GetListMatchingProvider, SaveNewMatchingProvider, UpdateMatchingProvider,
    GetListMatchingPayer, SaveNewMatchingPayer, UpdateMatchingPayer,
    SearchPosCodes, GetCPTCodeList, GetModifier, NewTestCode, ListTestCode, getTestCode, UpdateTestCode,
    GetListMatchingFacility, SaveNewMatchingFacility, UpdateMatchingFacility, ListInputFacilities, GetMatchingProvider,
    GetMatchingPayer, GetMatchingFacility, ListInputProvider, ListInputPayer, ListInterfaceData, GetMessage,
    ReprocessInterfaceData, ErrorFixInterfaceData
}