import React from "react";
import { Divider, Grid } from "@mui/material";
import NumberFieldSelector from "./NumberFieldSelector";

const NumberGroupFields = ({ fields, randomReportId }) => {
  return (
    <Grid container spacing={2} className="mt-4">
      <Grid xs={6}>
        <strong>Filter Number Fields</strong>
        <Divider className="mt-2 mb-2" />
        {fields.fields.map((item, index) => (
          <NumberFieldSelector
            key={index}
            item={item}
            randomReportId={randomReportId}
          />
        ))}
      </Grid>
      <Grid xs={6}></Grid>
    </Grid>
  );
};

export default NumberGroupFields;
