import React, { useState } from 'react';
import { TASK_STATUS } from "../../utilities/dictionaryConstants"
import Moment from 'react-moment';
import { Link } from "react-router-dom";
import i18n from "../../utilities/i18n";
import moment from 'moment';
import SelectInput from '../commons/input/SelectInput';
import * as staticConfigs from '../../utilities/staticConfigs'
import actionImg from '../../assets/images/action.png';
import { checkPermission } from '../../utilities/commonUtilities';
import { permission_key_values_tasks } from '../../utilities/permissions';
import Notify from '../commons/notify';

const TaskListTable = (props) => {

    const [showNotify, setShowNotify] = useState('hide');
    const [notifyDescription, setNotifyDescription] = useState('');
    const [notifyType, setNotifyType] = useState('success');

    function showNotifyWindow(action, type, desc, age = 3000) {
        if (action === 'show') {
            setTimeout(() => {
                setShowNotify('hide');
            }, age)
        }
        setShowNotify(action);
        setNotifyType(type);
        setNotifyDescription(desc);

    }

    const handleEditTask = (taskId) => {

        if(checkPermission(permission_key_values_tasks.can_modify_task)){
            props.onEditTask(taskId);
        } else {
            setTimeout(() => {
                showNotifyWindow('show', 'error', i18n.t('errorMessages.permission_error'));
            }, 500);
        }
    };

    const handleDeleteTask = (taskId) => {
        if(checkPermission(permission_key_values_tasks.can_modify_task)){
            props.onDeleteTask(taskId)
        } else {
            setTimeout(() => {
                showNotifyWindow('show', 'error', i18n.t('errorMessages.permission_error'));
            }, 500);
        }
    }

    return (
        <> 
        <Notify showNotify={showNotify} setShowNotify={setShowNotify} notifyDescription={notifyDescription} setNotifyType={setNotifyType} setNotifyDescription={setNotifyDescription} notifyType={notifyType} />

        <table id="data-table" className={props?.tblRadiusClass ? (props.tblRadiusClass + ' table ') : " table "}>
            <thead>
                <tr>
                    <th>{i18n.t("task.taskListScreen.due_date")}</th>
                    <th>{i18n.t("task.taskListScreen.title")}</th>
                    <th>{i18n.t("task.taskListScreen.description")}</th>
                    <th>{i18n.t("task.taskListScreen.assigned_to")}</th>
                    <th>{i18n.t("task.taskListScreen.task_link")}</th>
                    <th>{i18n.t("task.taskListScreen.status")}</th>
                    <th>{i18n.t("task.taskListScreen.options")}</th>
                </tr>
            </thead>
            <tbody>
                {
                    props.tasksList.length === 0 ?
                        <td align='center' colSpan={'7'}>{i18n.t("commons.noRecords")}</td>
                        :
                        props.tasksList.map((item, index) => {
                            return (
                                <>
                                <tr key={index}>
                                    <td align="center">
                                        {
                                            moment(new Date()).isAfter(item.due_date) ?
                                                <>
                                                    <span className="cursor-pointer mr-2">
                                                        <i className="fa fa-exclamation exclamationIcon"></i>
                                                    </span>
                                                    <Moment format="MM/DD/YYYY">{item.due_date}</Moment>
                                                </>
                                                :
                                                <Moment format="MM/DD/YYYY">{item.due_date}</Moment>
                                        }
                                    </td>
                                    <td>
                                        <Link className="p-0"
                                            to={{ pathname: staticConfigs.ROUTE_MY_TASKS_EDIT, state: { task_id: item.id } }}>
                                            {item.task_title}
                                        </Link>
                                    </td>
                                    <td>{item.description}</td>
                                    <td>{item.assignee.map((itemOne, index1) => {
                                        return (
                                            <div key={index1}>{itemOne.name}</div>
                                        )
                                    })}
                                    </td>
                                    <td>
                                        {
                                            Object.values(item.claim_tasks).concat(Object.values(item.patient_tasks)).length > 0 ?
                                                Object.values(item.claim_tasks).concat(Object.values(item.patient_tasks)).map((itemOne, index2) => {
                                                    return (
                                                        <div key={index2}>
                                                            {itemOne.claim_pk ?
                                                                <Link className='p-0' to={
                                                                    {
                                                                        pathname: staticConfigs.ROUTE_SEARCH_CLAIMS,
                                                                        state: { selected: itemOne.claim_pk, id: itemOne.name.split(" ")[0] }
                                                                    }
                                                                }
                                                                >{itemOne.name}</Link>
                                                                :
                                                                <Link className='p-0' to={
                                                                    {
                                                                        pathname: itemOne.patient_pk ? staticConfigs.ROUTE_PATIENTS_LIST : staticConfigs.ROUTE_SEARCH_CLAIMS,
                                                                        state: itemOne.patient_pk ? { selectedID: itemOne.patient_pk } : { selectedID: [itemOne.claim_pk] }
                                                                    }
                                                                }>{itemOne.name}</Link>
                                                            }
                                                        </div>
                                                    )
                                                })
                                                :
                                                ""
                                        }
                                    </td>
                                    <td className='width-125px'>
                                        <div className='padding-top15'>
                                            <SelectInput data={TASK_STATUS}
                                                disabled={!checkPermission(permission_key_values_tasks.can_modify_task)}
                                                name={'status'} value={item.status}
                                                onValueChange={(e) => props.onHandleTaskChange(e, item.id, index)}
                                                label={''}
                                                selectOptionRemove={true}
                                            />
                                        </div>
                                    </td>
                                    <td align='center' className='width-125px'>
                                        <div className="dropdown" id={'dropdown-' + index} style={{marginTop: "-6px"}}>
                                            <button
                                                id={'dropdownMenuButton-' + index}
                                                className="dropdown-toggle"
                                                type="button"
                                                data-toggle="dropdown"
                                                aria-haspopup="true"
                                                onClick={(e) => props.onShowActionButtons(e, index)}
                                                onBlur={(e) => props.onHideActionButtons(e, index)}
                                            >
                                                <img id="test" src={actionImg} alt="Action" />
                                            </button>
                                            
                                            <div className="dropdown-menu" id={'dropdown-menu-' + index} style={{zIndex: 9, width: "80px", marginTop: "12px", marginLeft: "14px"}}>
                                                <Link
                                                    className={"dropdown-item"}
                                                    to="#"
                                                    onMouseDown={() => handleEditTask(item.id)}
                                                >
                                                    {'Edit'}
                                                </Link>
                                                <Link className={"dropdown-item"} to="#"
                                                    onMouseDown={() => handleDeleteTask(item.id)}
                                                >{'Delete'}</Link>
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                                {((props.tasksList.length-1)===index) && <tr><td align='center' colSpan={'7'}>&nbsp;</td></tr>}
                                </>
                            )
                        })
                }
            </tbody>
        </table>
        </>
    )
}
export default TaskListTable;