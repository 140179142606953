import api from '../../../service/api'

const ListExceptionTypes = async(pageSize, page, isAdminModule, practicePK, searchValue) =>{
	let path = null;
	if (isAdminModule === 'false')
		path = 'practice/service-authorization-exception/?page_size='+ pageSize +'&page='+ page + '&practice_pk='+ practicePK;
	else
		path = 'super-admin/service-authorization-exception/?page_size='+pageSize+'&page='+page;
	if (searchValue) {
        path += `&search=${searchValue}`;
    }
	return api.get(path);
}

const AddExceptionType = async(data) =>{
	let path = null;
	if (Object.prototype.hasOwnProperty.call(data, 'practice'))
		path = 'practice/service-authorization-exception/';
	else
		path = 'super-admin/service-authorization-exception/';
	return api.post(path,data);
}
const GetExceptionType = async(exceptionId, practicePK) =>{
	let path = null;
	if (practicePK)
		path = 'practice/service-authorization-exception/' + exceptionId
	else
		path = 'super-admin/service-authorization-exception/' + exceptionId
	return api.get(path);
}

const UpdateExceptionType = async(exceptionId, data) =>{
	let path = null;
	if (Object.prototype.hasOwnProperty.call(data, 'practice'))
		path = 'practice/service-authorization-exception/' + exceptionId;
	else
		path = 'super-admin/service-authorization-exception/' + exceptionId;
	return api.put(path,data);
}

const DeleteExceptionType = async(exceptionId, practicePK) =>{
	let path = null;
	if (practicePK)
		path = 'practice/service-authorization-exception/' + exceptionId
	else
		path = 'super-admin/service-authorization-exception/' + exceptionId
	return api.destroy(path);
}

export default {ListExceptionTypes, AddExceptionType, GetExceptionType, UpdateExceptionType, DeleteExceptionType}