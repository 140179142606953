import { format } from "date-fns";
import api from "../../service/api";

const ListTasks = (practicePK,assigneType) =>{
    let path = 'practice/task/?practice_pk=' + practicePK+"&type="+assigneType;
    return api.get(path);
}
const GetTask = (practicePK, id) =>{
    let path = 'practice/task/?practice_pk=' + practicePK + '&task_id=' + id;
    return api.get(path);
}

const SendTask = async(data) =>{
	let path = 'practice/task/'
	return api.post(path,data);
}
const UpdateTask = async(data) =>{
	let path = 'practice/task/'
	return api.put(path,data);
}
const UpdateTaskStatus = async(data) =>{
	let path = 'practice/task/'
	return api.put(path,data);
}

const DeleteTask = async(data) =>{
	let path = 'practice/task/delete/'
	return api.put(path,data);
}

// compose list contacts
const ListPracticeUsers = (practicePK) =>{
    let path = 'practice/practice-users/?practice_pk=' + practicePK;
    return api.get(path);
}

const SearchPracticeUsers = (practicePK,query) =>{
    let path = 'practice/practice-users/?practice_pk=' + practicePK + '&search=' + query;
    return api.get(path);
}

const SearchLinkType = (practicePK, type, query) =>{
    let path = 'practice/task/link-types/?practice_pk=' + practicePK + '&link_type=' + type + '&search=' + query;
    return api.get(path);
}
const GetLinkTypeList = (practicePK) =>{
    let path = 'practice/task/link-types/?practice_pk=' + practicePK + '&link_type=patient';
    return api.get(path);
}

const ListPatients = async (query) => {
    let path = "patient/patients/"+ query;
    return api.get(path);
};
const GetListPatientClaims = async (query, exportType) => {
    let path = "claim/claims/" + query;
    let result = null;
    if(exportType === 'xls')
        result = api.getXLS(path);
    else
        result = api.get(path);
    return result;
}

const ListAssociateUsers = async(pageSize, page, practicePK) =>{
	let path = 'user/associate-user/?page_size='+ pageSize +'&page='+ page;
	if (practicePK)
		path += '&practice_pk='+ practicePK;
	return api.get(path);
}

const SearchTasks = async(practicePK, searchBy) =>{
	let path = 'practice/task/?practice_pk=' + practicePK + '&is_filter=true';
    if (searchBy.task_title ){
        path += '&title=' + searchBy.task_title;
    }
    if (searchBy.due_dateFrom ){
        path += '&due_from=' + format(searchBy.due_dateFrom, "yyyy-MM-dd");
    }
    if (searchBy.due_dateTo ){
        path += '&due_to=' + format(searchBy.due_dateTo, "yyyy-MM-dd");
    }
    if (searchBy.description ){
        path +=  '&description=' + searchBy.description;
    }
    if (searchBy.assignedTo ){
        path +=  '&assigned_to=' + searchBy.assignedTo;
    }
    if (searchBy.status ){
        path +=  '&task_status=' + searchBy.status;
    }
    if (searchBy.TaskLinkType ){
        path +=  '&link_type=' + searchBy.TaskLinkType;
    }
	return api.get(path);
}

const SearchTasksByTitle = async(practicePK, query) =>{
	let path = 'practice/task/?practice_pk=' + practicePK + '&is_filter=true';
    if (query ){
        path += '&title=' + query;
    }   
	return api.get(path);
}

export default {
    // get
    ListTasks, GetTask, ListPracticeUsers, GetLinkTypeList, ListPatients, GetListPatientClaims, ListAssociateUsers,
    // post
    SendTask, SearchPracticeUsers, SearchLinkType, SearchTasks, SearchTasksByTitle,
    // put
    UpdateTask, DeleteTask, UpdateTaskStatus,
}