import React, { useState, useContext, useEffect } from 'react'
import { Form } from 'react-bootstrap'
import i18n from '../../../utilities/i18n';
import PatientSearch from './PatientSearch';
import RightSidePanel from '../../commons/RightSidePanel/RightSidePanelForSecondTab';
import { PAGING_END_INDEX, DEFAULT_PAGING_SIZE } from '../../../utilities/staticConfigs';
import Pagination from '../../commons/pagination';
import LoadingContext from '../../../container/loadingContext';
import { getStorage } from '../../../utilities/browserStorage';
import { format } from 'date-fns';
import service from '../patients/service';
import Notify from '../../commons/notify';
import SelectInput from "../../commons/input/SelectInput";
import CustomizedDialogs from '../../modalWindowComponent/CustomizedDialogs';
import { ResizableTable } from '../../commons/resizabletable/ResizableTable';
import Moment from 'react-moment';
import CustomizedSmallDialogs from '../../modalWindowComponent/CustomisedSmallDialog';
import SummaryView from './summaryView';
import Chips from '../../commons/Chips';
import { Grid,  Typography } from '@mui/material';
import './style.css';
import CommonButton from '../../commons/Buttons';
import ExportIconButton from '../../commons/exportIconButton';
import { useDispatch, useSelector } from 'react-redux';
import {
    batchEligibilityListPatients,
    getPaginationData,
    handleCheckboxClick,
    handleCheckBoxAllClick,
    handleInsuranceChange,
    runBatchEligibiliity,
    ProvidersData,
    handleProvider,
} from './BatchEligibilitySlice';
import UpdateInsuranceDetails from './UpdateInsuranceDetails';

function BatchEligibility(props) {
    const dispatch = useDispatch();
    const tableData = useSelector((state) => state.batchEligibilityTab2);
    const selectedPatientIds = tableData.tableData ? tableData.tableData?.map((item) => item.id) : []
    const isAllChecked = useSelector((state) => state.batchEligibilityTab2.isAllChecked)
    const inputQuery = useSelector((state) => state.batchEligibilityTab2.filterQuery);
    const isLoading = useSelector((state) => state.batchEligibilityTab2.loading);
    const notifyMessage = useSelector((state) => state.batchEligibilityTab2.message);
    const isError = useSelector((state) => state.batchEligibilityTab2.isError);
    const isSuccess = useSelector((state) => state.batchEligibilityTab2.isSuccess);
    const batchRunSuccessFlag = useSelector((state)=>  state.batchEligibilityTab2.batchRunSuccessFlag)
    const { startIndex, endIndex, activePage, ProviderGroupList , Provider } = useSelector((state) => state.batchEligibilityTab2);
    const setShowLoadingBar = useContext(LoadingContext);
    const practicePK = getStorage("practice") ? parseInt(getStorage("practice")) : '';
    const [initialTableData, setInitialTableData] = useState(i18n.t('commons.noRecords'));
    const [selectedPatients, setSelectedPatients] = useState([]);
    const [patientInsuranceDetails, setPatientInsuranceDetails] = useState([]);
    const [currentSelectedPatientToChangeInsurance, setCurrentSelectedPatientToChangeInsurance] = useState("");
    const [openModal, setOpenModal] = useState(false);
    const [eraOpenModal, setEraOpenModal] = useState(false);
    const [patientInsurance, setPatientInsurance] = useState("");
    const [batchEligibilityHistoryList, setBatchEligibilityHistoryList] = useState("");
    const [batchId, setBatchId] = useState("");
    const [batchIdList, setBatchIdList] = useState([]);
    const header = i18n.t("patientPages.insuranceInfo.addInsurance.labelInsurance");
    const [showAlertModalWindow, setShowAlertModalWindow] = useState(false);
    const alertMessage = i18n.t("patientPages.insuranceInfo.alertMessageForMinimumRows");
    const [htmlResponse, setHtmlResponse] = useState({});
    //Pagination start
    const [totalPage, setTotalPage] = useState(1);
    const [insuranceName, setInsuranceName] = useState("");
    // eslint-disable-next-line no-undef
    var fileDownload = require('js-file-download');

    const [openUpdateModal, setOpenUpdateModal] = useState(false);
    const updateHeader = i18n.t("Eligibility.eligibilityTab1.window.eligibilitySummaryUpdate");

    const [coPay, setCoPay] = useState("");
    const [coPayType, setCoPayType] = useState("");
    const [coInsurance, setCoInsurance] = useState("");
    const [coInsuranceType, setCoInsuranceType] = useState("");
    const [subscriberName, setSubscriberName] = useState("");
    const [deductible, setDeductible] = useState("");
    const [deductibleType, setDeductibleType] = useState("");
    const [address, setAddress] = useState("");
    const [pocketExpenseType, setPocketExpenseType] = useState("");
    const [outOfPocketMax, setOutOfPocketMax] = useState("");
    const [claimPolicyID, setClaimPolicyID] = useState("");
    const [groupNumber, setGroupNumber] = useState("");
    const [effDate, setEffDate] = useState("");
    const [effDateTrue, setEffDateTrue] = useState(false);
    const [memberName, setMemberName] = useState("");
    const [coPayTypeList, setCoPayTypeList] = useState([]);
    const [coInsuranceTypeList, setCoInsuranceTypeList] = useState([]);
    const [deductibleTypeList, setDeductibleTypeList] = useState([]);
    const [outOfPocketTypeList, setOutOfPocketTypeList] = useState([]);
    const [coPayTrue, setCoPayTrue] = useState(false);
    const [coInsuranceTrue, setCoInsuranceTrue] = useState(false);
    const [deductibleTrue, setDeductibleTrue] = useState(false);
    const [outOfPocketMaxTrue, setOutOfPocketMaxTrue] = useState(false);
    const [groupNumberTrue, setGroupNumberTrue] = useState(false);
    const [patientPk, setPatientPk] = useState("");
    // eslint-disable-next-line
    const [insuranceId, setInsuranceId] = useState("");

    useEffect(() => {
        let newWidth = "";
        newWidth = window.innerWidth;
        let menuWidth = document.getElementById("push-bar").classList.contains('open');
        if (!menuWidth) {
            newWidth = newWidth + 250;
        } else {
            newWidth = window.innerWidth;
        }
        return newWidth;
    }, [window.innerWidth]);

    const showPatientSearch = () => {
        document.getElementById("right-content-tab").classList.toggle('active');
    }
    function onPagePrevious() {
        let previousPage = startIndex + 1 - PAGING_END_INDEX;
        dispatch({ type: "batchEligibility/setActivePage", payload: { active: previousPage } })
        if (startIndex !== 0) {
            let start = startIndex - PAGING_END_INDEX;
            let end = endIndex - PAGING_END_INDEX;
            dispatch({ type: "batchEligibility/onPagePrevious", payload: { startIndex: start, endIndex: end } })
        }
        OnPaginate(DEFAULT_PAGING_SIZE, previousPage)

    }
    function onPageUp(e) {
        let page = Number(e.target.id)
        dispatch({ type: "batchEligibility/setActivePage", payload: { active: page } });
        OnPaginate(DEFAULT_PAGING_SIZE, page)
    }

    function onPageNext() {
        let nextPage = startIndex + 1 + PAGING_END_INDEX;
        if (endIndex === totalPage || totalPage <= PAGING_END_INDEX) {
            dispatch({ type: "batchEligibility/setActivePage", payload: { active: nextPage } });
            dispatch({ type: "batchEligibility/onPageNext", payload: { startIndex: startIndex, endIndex: endIndex } });
        } else {
            dispatch({ type: "batchEligibility/setActivePage", payload: { active: nextPage } });
            let start = startIndex + PAGING_END_INDEX;
            let end = endIndex + PAGING_END_INDEX;
            dispatch({ type: "batchEligibility/onPageNext", payload: { startIndex: start, endIndex: end } })
        }
        OnPaginate(DEFAULT_PAGING_SIZE, nextPage)
    }
    //Pagination ends
    //Pagination start
    const [totalHistoryPage, setTotalHistoryPage] = useState(1);
    const [activeHistoryPage, setActiveHistoryPage] = useState(1);
    const [startHistoryIndex, setStartHistoryIndex] = useState(0);
    const [endHistoryIndex, setEndHistoryIndex] = useState(PAGING_END_INDEX);
    function onPageHistoryPrevious() {
        let previousPage = startHistoryIndex + 1 - PAGING_END_INDEX;
        setActiveHistoryPage(previousPage);
        if (startHistoryIndex !== 0) {
            setStartHistoryIndex(startHistoryIndex - PAGING_END_INDEX);
            setEndHistoryIndex(endHistoryIndex - PAGING_END_INDEX);
        }
        getBatchEligibilityHistory(DEFAULT_PAGING_SIZE, previousPage);

    }

    function onPageHistoryUp(e) {
        let page = Number(e.target.id)
        setActiveHistoryPage(page);
        getBatchEligibilityHistory(DEFAULT_PAGING_SIZE, page);
    }

    function onPageHistoryNext() {
        let nextPage = startHistoryIndex + 1 + PAGING_END_INDEX;
        if (endHistoryIndex === totalHistoryPage || totalHistoryPage <= PAGING_END_INDEX) {
            setActiveHistoryPage(nextPage);
            setStartHistoryIndex(startHistoryIndex);
            setStartHistoryIndex(endHistoryIndex);
        } else {
            setActiveHistoryPage(nextPage);
            setStartHistoryIndex(startHistoryIndex + PAGING_END_INDEX);
            setEndHistoryIndex(endHistoryIndex + PAGING_END_INDEX);
        }
        getBatchEligibilityHistory(DEFAULT_PAGING_SIZE, nextPage);
    }
    //Pagination ends

    //start ==== Alert message properties
    const [showNotify, setShowNotify] = useState('hide');
    const [notifyDescription, setNotifyDescription] = useState('');
    const [notifyType, setNotifyType] = useState('success');
    function showNotifyWindow(action, type, desc, age = 3000) {
        if (action === 'show') {
            setShowNotify(action);
            setNotifyType(type);
            setNotifyDescription(desc);
            setTimeout(() => {
                setShowNotify('hide');
            }, age)
        }

    }
    //End ====

    function createFilters(inputQuery) {
        const filters = {
            list_type: "list",
            practice_pk: practicePK,
            filter: 1,
        };
    
        if (inputQuery.lastNameStarts.trim()) {
            filters.last_name = inputQuery.lastNameStarts.trim();
        }
    
        if (inputQuery.firstNameStarts.trim()) {
            filters.first_name = inputQuery.firstNameStarts.trim();
        }
    
        if (inputQuery.dob1) {
            filters.dob_from = format(inputQuery.dob1, "yyyy-MM-dd");
        }
    
        if (inputQuery.dob2) {
            filters.dob_to = format(inputQuery.dob2, "yyyy-MM-dd");
        }
    
        if (inputQuery.dos1) {
            filters.dos_from = format(inputQuery.dos1, "yyyy-MM-dd");
        }
    
        if (inputQuery.dos2) {
            filters.dos_to = format(inputQuery.dos2, "yyyy-MM-dd");
        }
    
        if (inputQuery.createdFrom) {
            filters.created_from = format(inputQuery.createdFrom, "yyyy-MM-dd");
        }
    
        if (inputQuery.createdTo) {
            filters.created_to = format(inputQuery.createdTo, "yyyy-MM-dd");
        }
    
        if (inputQuery.notCheckAfter) {
            filters.not_checked_after = format(inputQuery.notCheckAfter, "yyyy-MM-dd");
        }
    
        if (inputQuery.insuranceValue.length > 0) {
            filters.insurances = JSON.stringify(inputQuery.insuranceValue);
        }
    
        if (inputQuery.practiceLocation.length > 0) {
            filters.practice_locations = JSON.stringify(inputQuery.practiceLocation);
        }
    
        if (inputQuery.facility.length > 0) {
            filters.facilities = JSON.stringify(inputQuery.facility);
        }
    
        if (inputQuery.patientType.length > 0) {
            filters.patient_types = JSON.stringify(inputQuery.patientType);
        }
    
        if (inputQuery.renderingProvider.length > 0) {
            filters.rendering_providers = JSON.stringify(inputQuery.renderingProvider);
        }
    
        if (inputQuery.referingProvider.length > 0) {
            filters.referring_providers = JSON.stringify(inputQuery.referingProvider);
        }
    
        return filters;
    }

    /**
     * changed to async/await for readability and used try/catch
     * @param {*} pageSize 
     * @param {*} page 
     */
    async function onAdvanceSearchPatients(pageSize, page) {
        if (props.setShowAdvancedSearchTab) {
            props.setShowAdvancedSearchTab(false);
        }
        setShowLoadingBar(true);

        const filters = createFilters(inputQuery);

        const batchFilters = {
            list_type: "insurances",
            practice_pk: practicePK,
            filter: 1,
        };

        const query = `?${new URLSearchParams(filters).toString()}&page_size=${pageSize}&page=${page}&eligibility_tab=true`;
        const batchQuery = `?${new URLSearchParams({ ...filters, ...batchFilters }).toString()}&page_size=${0}&page=${page}&eligibility_tab=true`;

        if (props.setAdvancedQuery) {
            props.setAdvancedQuery(query);
        }
        dispatch(batchEligibilityListPatients({ query: query, batchQuery: batchQuery }));
        setShowLoadingBar(false);
        document.getElementById("right-content-tab").classList.remove("active");
    }

    useEffect(() => {
        if (activePage > 1) {
            OnPaginate(DEFAULT_PAGING_SIZE, activePage);
        }
    }, [])

    function OnPaginate(pageSize, page) {
        const filters = createFilters(inputQuery);
        const query = `?${new URLSearchParams(filters).toString()}&page_size=${pageSize}&page=${page}&eligibility_tab=true`;
        dispatch(getPaginationData({ query, page }))
    }

    useEffect(() => {
        if (tableData.totalPage) {
            setTotalPage(tableData.totalPage);
        }
        if (tableData?.tableData && tableData?.tableData.length > 0) {
            setInitialTableData("");
        } else if (tableData?.tableData && tableData?.tableData.length === 0) {
            setInitialTableData(i18n.t('commons.noRecords'));
            setSelectedPatients([]);
        }
    }, [tableData])

    const oncheck = (e, item) => {
        dispatch(handleCheckboxClick({isChecked: e.target.checked, patientId: item.id, insuranceId: item.insurance_id}))  
    }

    const onSelectAll = (e) => {
        dispatch(handleCheckBoxAllClick({isChecked: e.target.checked}))
    }

    useEffect(() => {
        if (isLoading) setShowLoadingBar(true);
        else setShowLoadingBar(false);
    }, [isLoading])

    function onHandleChange(e) {
        let value = e.target.value
        if (value) {
            dispatch(handleProvider(value))
        }
        else {
            dispatch(handleProvider(""))
        }
    }

    useEffect(() => {
        if (isSuccess) {
            showNotifyWindow('show', 'success', notifyMessage);
            dispatch({ type: 'batchEligibility/updateFieldValues', payload: { state: 'mainState', field: 'isSuccess', value: false, } });
        }
        if (isError) {
            showNotifyWindow('show', 'error', notifyMessage);
            dispatch({ type: 'batchEligibility/updateFieldValues', payload: { state: 'mainState', field: 'isError', value: false, } });
        }
    }, [isSuccess, isError])

    const runBatchEligibility = () => {
        if (!Provider) return;
        setShowLoadingBar(true);
        if (selectedPatientIds.length < 4) {
            setShowAlertModalWindow(true);
            setShowLoadingBar(false);
        } else {
            dispatch(runBatchEligibiliity({provider: Provider, practice_id: practicePK }))
            setSelectedPatients([]);
            setBatchIdList([])
            setBatchId('')
        }

    }

    const getPatientInsurance = (e,item) => {
        setPatientInsuranceDetails(item.insurances.map((insuranceItem) => ({ "id": insuranceItem.insurance_info_id, "name": insuranceItem.insurance_name })));
        setPatientInsurance(`${item.insurance_id}`)
        setCurrentSelectedPatientToChangeInsurance(item.id)
        setOpenModal(true)
    }

    const onHandlePatientInsuranceDetailsChange = (selected) => {
        setPatientInsurance(selected.target.value);
        dispatch(handleInsuranceChange({currentSelectedPatientToChangeInsurance, newInsuranceValue: selected.target.value}))  
    }

    function resetForm() {
        setOpenModal(false);
    }
    function onHide() {
        setOpenModal(false);
    }

    function getBatchEligibilityHistory(pageSize, page) {
        const result = service.BatchEligibilityHistory(pageSize, page, batchId);
        setShowLoadingBar(true);
        result.then(response => {
            if (response.data.results !== undefined) {
                if (totalHistoryPage) {
                    setTotalHistoryPage(Math.ceil(response.data.count / response.data.page_size));
                }
                else {
                    setTotalHistoryPage(Math.ceil(response.data.count / response.data.page_size));
                }
            }
            setBatchEligibilityHistoryList(response.data.results)
            setShowLoadingBar(false);
        })
    }
    function getBatchIds() {
        const result = service.GetBatchIds(practicePK,false);
        setShowLoadingBar(true);
        result.then(response => {
            setBatchIdList(response.data)
            setShowLoadingBar(false);
            if (response.data.length > 0) {
                setBatchId(response.data[0].id)
            }
        })
    }
    const onHandleBatchIdChange = (e) => {
        setBatchId(e.target.value);
    }
    useEffect(() => {
        if (batchId)
            getBatchEligibilityHistory(DEFAULT_PAGING_SIZE, 1);
    }, [batchId]);

    useEffect(() => {
        getBatchIds();
        dispatch(ProvidersData())
    }, [])

    useEffect(() => {
        if (batchRunSuccessFlag){
            getBatchIds();
            dispatch({ type: 'batchEligibility/updateFieldValues', payload: { state: 'mainState', field: 'batchRunSuccessFlag', value: false, } });
        }
    }, [batchRunSuccessFlag]);

    function onAlertOk() {
        setShowAlertModalWindow(false);
    }



    function onHandleCSVExportPatients() {
        setShowLoadingBar(true);
        let query = '?list_type=export_batch&export_type=csv&practice_pk=' + practicePK + '&filter=1';

        switch (true) {
            case inputQuery.lastNameStarts.trim():
                query += '&last_name=' + inputQuery.lastNameStarts.trim();
                break;
            case inputQuery.firstNameStarts.trim():
                query += '&first_name=' + inputQuery.firstNameStarts.trim();
                break;
            case inputQuery.dob1:
                query += '&dob_from=' + format(inputQuery.dob1, "yyyy-MM-dd");
                break;
            case inputQuery.dob2:
                query += '&dob_to=' + format(inputQuery.dob2, "yyyy-MM-dd");
                break;
            case inputQuery.dos1:
                query += '&dos_from=' + format(inputQuery.dos1, "yyyy-MM-dd");
                break;
            case inputQuery.dos2:
                query += '&dos_to=' + format(inputQuery.dos2, "yyyy-MM-dd");
                break;
            case inputQuery.insuranceValue.length > 0:
                query += '&insurances=' + JSON.stringify(inputQuery.insuranceValue);
                break;
            case inputQuery.practiceLocation.length > 0:
                query += '&practice_locations=' + JSON.stringify(inputQuery.practiceLocation);
                break;
            case inputQuery.facility.length > 0:
                query += '&facilities=' + JSON.stringify(inputQuery.facility);
                break;
            case inputQuery.patientType.length > 0:
                query += '&patient_types=' + JSON.stringify(inputQuery.patientType);
                break;
            case inputQuery.renderingProvider.length > 0:
                query += '&rendering_providers=' + JSON.stringify(inputQuery.renderingProvider);
                break;
            case inputQuery.referingProvider.length > 0:
                query += '&referring_providers=' + JSON.stringify(inputQuery.referingProvider);
                break;
            default:
                break;
        }

        if (props.setAdvancedQuery) {
            props.setAdvancedQuery(query);
        }

        const result = service.ListPatients(query);

        result.then(response => {
            fileDownload(response.data, response.headers['content-disposition']);
            setShowLoadingBar(false);
        });
    }


    function onHandleCSVExportBatch() {
        setShowLoadingBar(true);
        let query = '&export=true&export_type=csv';
        const result = service.BatchEligibilityHistory(0, 0, batchId, query);
        result.then(response => {
            fileDownload(response.data, response.headers['content-disposition']);
            setShowLoadingBar(false);
        });
    }

    function onHandleXLSExportBatch() {
        setShowLoadingBar(true);
        let query = '&export=true&export_type=xls';
        const result = service.BatchEligibilityHistory(0, 0, batchId, query, 'xls');
        result.then(response => {
            fileDownload(response.data, response.headers['content-disposition']);
            setShowLoadingBar(false);
        });
    }

    // function remGridShowExport() {
    //     document.getElementById("grid-export-dropdown").classList.remove('show');
    //     document.getElementById("grid-export-menu").classList.remove('show');
    //     document.getElementById("grid-export-dropdown").classList.remove('active');
    //     document.getElementById("grid-export-menu").classList.remove('active');
    // }

    // function onGridShowExport() {
    //     document.getElementById("grid-export-dropdown").classList.toggle('show');
    //     document.getElementById("grid-export-menu").classList.toggle('show');
    // }

    function showEBResponseSummary(e, id, patient_id, insurance_name) {
        // eslint-disable-next-line
        console.log(batchEligibilityHistoryList)
        setInsuranceName(insurance_name)
        setShowLoadingBar(true);
        const result = service.ShowBatchResponseHtml(id);
        result.then(response => {
            setShowLoadingBar(false);
            setHtmlResponse(response.data.html_response);
            setPatientPk(patient_id)
            setInsuranceId(batchEligibilityHistoryList.find(x => x.id === id).insurance_info_id);
            setEraOpenModal(true);
        })
    }

    function getExtractedData() {
        resetUpdateForm();
        setShowLoadingBar(true);
        let path = 'patient/extract-elg-txt-db/?id=' + htmlResponse.id + '&checking_type=batch' + '&subscriber_pk=' + htmlResponse.subscriber_pk;
        const result = service.GetExtractedData(path);
        result.then(response => {
            setShowLoadingBar(false);
            if (Object.keys(response.data).length > 0) {
                let coPayTypeArr = [];
                response.data && response.data.co_payment?.map((item, index) => {
                    coPayTypeArr.push({ "id": (index + 1).toString(), "name": item.name, "co_payment": item.co_payment })
                })
                setCoPayTypeList(coPayTypeArr);
                let coInsuTypeList = [];
                response.data && response.data.co_insurance?.map((item, index) => {
                    coInsuTypeList.push({ "id": (index + 1).toString(), "name": item.name, "co_insurance": item.co_insurance })
                })
                setCoInsuranceTypeList(coInsuTypeList)
                let deductibleList = [];
                response.data && response.data.deductible?.map((item, index) => {
                    deductibleList.push({ "id": (index + 1).toString(), "name": item.name, "deductible": item.deductible })
                })
                setDeductibleTypeList(deductibleList);
                let outOfPocketList = [];
                response.data && response.data.out_of_pocket?.map((item, index) => {
                    outOfPocketList.push({ "id": (index + 1).toString(), "name": item.name, "out_of_pocket": item.out_of_pocket })
                })

                setOutOfPocketTypeList(outOfPocketList);
                if (response.data) {
                    setSubscriberName(response.data.subscriber);
                    setClaimPolicyID(response.data.member_id);
                    setGroupNumber(response.data.group_number);
                    setMemberName(response.data.member_name);
                    setAddress(response.data.address);
                    setEffDate(response.data.plan_date ? new Date(response.data.plan_date) : "");
                }
                setOpenUpdateModal(true);
            }

        })
    }


    function onSaveFormData() {
        setShowLoadingBar(true);
        let data = {}
        if (groupNumberTrue)
            data["group_number"] = groupNumber;
        if (coPayTrue)
            data["copay"] = coPay
        if (coInsuranceTrue)
            data["co_insurance"] = coInsurance;
        if (outOfPocketMaxTrue)
            data["out_of_pocket"] = outOfPocketMax;
        if (deductibleTrue) {
            data["deductibles"] = deductible;
        }
        if (Object.keys(data).length > 0) {
            data["patient_pk"] = patientPk
            data["policy_id"] = claimPolicyID
            const result = service.UpdateInsuranceInfo(data);
            result.then(response => {
                setShowLoadingBar(false);
                setOpenUpdateModal(false);
                if (response.status === 200) {
                    showNotifyWindow('show', 'success', i18n.t("Eligibility.eligibilityTab1.InsuranceDataUpdated"));
                } else {
                    showNotifyWindow('show', 'error', i18n.t("Eligibility.eligibilityTab1.InsuranceDataUpdateFailed"));
                }

            })
        }
        setShowLoadingBar(false);
    }

    function onUpdateHide() {
        setOpenUpdateModal(false);
    }

    function resetUpdateForm() {
        setMemberName("");
        setCoPayTypeList([]);
        setCoInsuranceTypeList([])
        setDeductibleTypeList([]);
        setOutOfPocketTypeList([]);
        setSubscriberName("");
        setClaimPolicyID("");
        setGroupNumber("");
        setEffDate("");
        setMemberName("");
        setCoPay("");
        setCoInsurance("");
        setSubscriberName("");
        setDeductible("");
        setAddress("");
        setOutOfPocketMax("");
        setGroupNumber("");
        setCoPayTrue(false);
        setCoPayType(false);
        setCoPay("")
        setCoInsuranceTrue(false);
        setCoInsuranceType(false);
        setCoInsurance("")
        setDeductibleTrue(false);
        setDeductibleType("");
        setDeductible("")
        setOutOfPocketMaxTrue(false);
        setPocketExpenseType("");
        setOutOfPocketMax("")
        setClaimPolicyID("");
        setGroupNumberTrue(false);
        setEffDateTrue(false);
        setEffDateTrue(false);
        setOpenUpdateModal(false);
    }

    function onHandleChangeEligibilityUpdate(e) {
        if (e.target) {
            let name = e.target.name;
            let value = e.target.type === "checkbox" ? e.target.checked : e.target.value;
            let inputFields = ["insuranceName", "policyId", "serviceType", "provider", "memberName", "coPay", "coInsurance", "subscriberName", "deductible", "eligiblityDate", "address", "outOfPocketMax", "groupNumber", "coPayTrue", "coPayType", "coInsuranceTrue", "coInsuranceType", "deductibleTrue",
                "deductibleType",
                "outOfPocketMaxTrue",
                "pocketExpenseType",
                "claimPolicyID",
                "groupNumberTrue",
                "effDateTrue",
                "outOfPockedTrue"];

            const trimmedValue = inputFields.includes(name) ? value : "";
            switch (name) {
                case "insuranceName":
                    setInsuranceName(trimmedValue);
                    break;
                case "memberName":
                    setMemberName(trimmedValue);
                    break;
                case "coPay":
                    setCoPay(trimmedValue);
                    break;
                case "coInsurance":
                    setCoInsurance(trimmedValue);
                    break;
                case "subscriberName":
                    setSubscriberName(trimmedValue);
                    break;
                case "deductible":
                    setDeductible(trimmedValue);
                    break;
                case "address":
                    setAddress(trimmedValue);
                    break;
                case "outOfPocketMax":
                    setOutOfPocketMax(trimmedValue);
                    break;
                case "groupNumber":
                    setGroupNumber(trimmedValue);
                    break;
                case "coPayTrue":
                    setCoPayTrue(trimmedValue);
                    break;
                case "coInsuranceTrue":
                    setCoInsuranceTrue(trimmedValue);
                    break;

                case "coPayType":
                    setCoPayType(trimmedValue);
                    if (trimmedValue !== "")
                        setCoPay(coPayTypeList[parseInt(trimmedValue) - 1].co_payment)
                    break;
                case "coInsuranceType":
                    setCoInsuranceType(trimmedValue);
                    if (trimmedValue !== "")
                        setCoInsurance(coInsuranceTypeList[parseInt(trimmedValue) - 1].co_insurance)
                    break;

                case "deductibleTrue":
                    setDeductibleTrue(trimmedValue)
                    break;
                case "deductibleType":
                    setDeductibleType(trimmedValue);
                    if (trimmedValue !== "")
                        setDeductible(deductibleTypeList[parseInt(trimmedValue) - 1].deductible)
                    break;
                case "outOfPocketMaxTrue":
                    setOutOfPocketMaxTrue(trimmedValue)
                    break;
                case "pocketExpenseType":
                    setPocketExpenseType(trimmedValue);
                    if (trimmedValue !== "")
                        setOutOfPocketMax(outOfPocketTypeList[parseInt(trimmedValue) - 1].out_of_pocket)
                    break;

                case "claimPolicyID":
                    setClaimPolicyID(trimmedValue);
                    break;
                case "groupNumberTrue":
                    setGroupNumberTrue(trimmedValue);
                    break;
                case "effDateTrue":
                    setEffDateTrue(trimmedValue);
                    break;
                case "outOfPockedTrue":
                    setEffDateTrue(trimmedValue);
                    break;
                default:
                    break;
            }

        }
    }

    function onHandleEffDate(selected) {
        setEffDate(selected);
    }

    return (
        <React.Fragment>
            <Notify showNotify={showNotify} setShowNotify={setShowNotify} notifyDescription={notifyDescription} setNotifyType={setNotifyType} setNotifyDescription={setNotifyDescription} notifyType={notifyType} />
            <CustomizedSmallDialogs  header={header} showModal={openModal} setShowModalWindow={setOpenModal} resetForm={resetForm}  onHide={onHide} >
                <div className="row">
                    <SelectInput selectOptionRemove={true} 
                    data={patientInsuranceDetails} 
                    value={patientInsurance}
                    required={true}
                    name="patientInsurance" id="patientInsurance" 
                    // setValue={setPatientInsurance} 
                    onValueChange={(selected) => onHandlePatientInsuranceDetailsChange(selected)} 
                    label={i18n.t("patientPages.insuranceInfo.addInsurance.labelInsuranceName")} />
                </div>
            </CustomizedSmallDialogs>


            <Form id="form_patient_info" className='eligibility-search-box' autoComplete="off">

                <Grid container spacing={4} sx={{ marginBottom: '3px' }}>
                    <Grid item xs={4} style={{ display: 'flex', alignItems: 'flex-end' }}>
                        <CommonButton variant="contained" width={'50%'} onClick={showPatientSearch} label={i18n.t("Eligibility.batchEligibility.createBatch")} icon={'add'} />
                    </Grid>

                    <Grid item xs={1} style={{ display: 'flex', alignItems: 'flex-end' }}>
                    </Grid>
                    <Grid item xs={7} style={{ display: 'flex', alignItems: 'flex-end', justifyContent: 'flex-end' }}>
                        <div style={{ flex: '0 0 60%', marginRight: '10px', marginBottom: '-13px', display: 'flex', justifyContent: 'flex-end' }}>
                            <SelectInput
                                required={true}
                                data={ProviderGroupList}
                                name="provider"
                                value={Provider}
                                onValueChange={onHandleChange}
                                label={i18n.t("Eligibility.eligibilityTab1.provider")}
                            />
                        </div>

                        <div style={{ flex: '0 0 30%', marginRight: '10px', display: 'flex', alignItems: 'flex-end', justifyContent: 'flex-end' }}>
                            <CommonButton
                                variant="outlined"
                                onClick={runBatchEligibility}
                                label={i18n.t("Eligibility.batchEligibility.runBatchElgblty")}
                                width={'100%'}
                            />
                        </div>

                        <div className="dropdown" id="export-dropdown" style={{ flex: '0 0 10%', display: 'flex', alignItems: 'flex-end', justifyContent: 'flex-end' }}>
                            <CommonButton
                                variant="outlined"
                                disabled={selectedPatients.length <= 0}
                                onClick={onHandleCSVExportPatients}
                                label={"Export"}
                                icon="download"
                            />
                        </div>
                    </Grid>
                </Grid>


                <div className="padding-bottom20">
                    <div className="row mb-2">
                        <div className="col-md-12 margin-top-m7">
                            <div className="div-float-left margin-top-30">
                                <Typography
                                    sx={{
                                        color: '#212121',
                                        fontFeatureSettings: "'clig' off, 'liga' off",
                                        fontFamily: 'Lato',
                                        fontSize: '16px',
                                        fontStyle: 'normal',
                                        fontWeight: 500,
                                        lineHeight: '20px',
                                    }}
                                >
                                    {i18n.t("Eligibility.batchEligibility.batchPreview")}
                                </Typography>
                            </div>
                        </div>
                    </div>
                    <div className="table-responsive">
                        <ResizableTable id={'eligibilityTab2'} className='fixed_head'
                            tableHeadings={
                                <tr>
                                    <th className='longTableHead'>
                                        {i18n.t("Eligibility.batchEligibility.patientName")}
                                        <span className="resize-handle"></span>
                                    </th>
                                    <th className='shortTableHead'>
                                        {i18n.t("Eligibility.batchEligibility.dob")}
                                        <span className="resize-handle"></span>
                                    </th>
                                    <th className='shortTableHead'>
                                        {i18n.t("Eligibility.batchEligibility.insurance")}
                                        <span className="resize-handle"></span>
                                    </th>
                                    <th className='shortTableHead'>
                                        {i18n.t("Eligibility.batchEligibility.policyId")}
                                        <span className="resize-handle"></span>
                                    </th>

                                    <th className='max-width_21percent'>
                                        <span className="resize-handle"></span>
                                        {tableData.tableData && tableData.tableData.length > 0 && <div className="form-groupp lh-0">
                                            <div className="custom-checkbox">
                                                <input
                                                    type="checkbox"
                                                    id={'selectAll'}
                                                    name={'selectAll'}
                                                    checked={isAllChecked}
                                                    onChange={(e) => onSelectAll(e)}
                                                />
                                                <label
                                                    className="checkbox"
                                                    htmlFor={'selectAll'}
                                                ></label>
                                            </div>
                                        </div>}
                                    </th>
                                </tr>
                            }
                            tableRows={
                                initialTableData ?
                                    <tr>
                                        <td align="center" colSpan={5}>{initialTableData}</td>
                                    </tr>
                                    :
                                    tableData.tableData && tableData.tableData.map((item, index) => {
                                        return (<tr key={item.id}>
                                            <td>
                                                <Typography
                                                    sx={{
                                                        color: '#1479BB',
                                                        fontFeatureSettings: "'clig' off, 'liga' off",
                                                        lineHeight: 'normal',
                                                        textTransform: 'uppercase',
                                                    }}
                                                >
                                                    {item.name}
                                                </Typography>
                                            </td>
                                            <td align="center"><Moment format='MM/DD/YYYY'>{item.date_of_birth}</Moment></td>
                                            <td align="center">
                                                {
                                                    item.insurances?.find((item1) => item1.insurance_info_id == item.insurance_id)?.insurance_name
                                                }
                                                {item.insurance_no > 1 ? <i onClick={(e) => getPatientInsurance(e, item)} className="fas fa-edit edit-icon"></i> : ''}</td>
                                            <td align="center">{item.insurances?.find((data) => data.insurance_info_id == item.insurance_id)?.policy_id}</td>
                                            <td align="center">
                                                {item.insurance_name && item.policy_id &&
                                                    <div className="form-groupp lh-0">
                                                        <div className="custom-checkbox">
                                                            <input
                                                                type="checkbox"
                                                                id={'chk_' + index}
                                                                name={'chk_' + index}
                                                                checked={item.isChecked}
                                                                onChange={(e) => oncheck(e, item)}
                                                            />
                                                            <label
                                                                className="checkbox"
                                                                htmlFor={'chk_' + index}
                                                            ></label>
                                                        </div>
                                                    </div>}
                                            </td>
                                        </tr>)
                                    })}
                        />
                    </div>
                    <div>
                        {tableData?.tableData?.length > 0 && <Pagination totalPage={totalPage} activePage={activePage} startIndex={startIndex} endIndex={endIndex} onPagePrevious={onPagePrevious} onPageUp={onPageUp} onPageNext={onPageNext} />}
                        {totalPage <= 1 && <div className="clear-b-scroll-sector"></div>}
                    </div>
                </div>

                <Grid container spacing={2} sx={{ marginBottom: '3px' }}>
                    <Grid item xs={9} style={{ display: 'flex', alignItems: 'flex-end', justifyContent: 'flex-start' }}>
                        <Typography
                            sx={{
                                color: '#212121',
                                fontFeatureSettings: "'clig' off, 'liga' off",
                                fontFamily: 'Lato',
                                fontSize: '16px',
                                fontStyle: 'normal',
                                fontWeight: 500,
                                lineHeight: '20px',
                            }}
                        >
                            {i18n.t("Eligibility.batchEligibility.batchElgbltyRslt")}
                        </Typography>
                    </Grid>
                    <Grid item xs={3} style={{ display: 'flex', alignItems: 'flex-end', justifyContent: 'flex-end' }}>
                        <div style={{ flex: '0 0 90%', marginRight: '10px', marginBottom: '-13px', display: 'flex', justifyContent: 'flex-end' }}>
                            <SelectInput selectOptionRemove={true} data={batchIdList} required={true} name="batchIdList" id="batchIdList" value={batchId} setValue={setBatchId} onValueChange={(selected) => onHandleBatchIdChange(selected)} label={i18n.t("Eligibility.batchEligibility.batchId")} />
                        </div>
                        <div className="dropdown" id="grid-export-dropdown" style={{ flex: '0 0 5%', display: 'flex', alignItems: 'flex-end', justifyContent: 'flex-end' }}>
                            <ExportIconButton
                                disabled={batchEligibilityHistoryList.length <= 0 ? true : false}
                                onExportCsv={onHandleCSVExportBatch}
                                onExportXls={onHandleXLSExportBatch} />
                        </div>
                    </Grid>
                </Grid>
                <div style={{ height: '10px' }}></div>

                <div className="table-responsive">
                    <ResizableTable id={'eligibilityTab3'} className="table"
                        tableHeadings={
                            <tr>
                                <th className='shortTableHead'>
                                    {i18n.t("Eligibility.batchEligibility.batchId")}
                                    <span className="resize-handle"></span>
                                </th>
                                <th className='longTableHead'>
                                    {i18n.t("Eligibility.batchEligibility.patientName")}
                                    <span className="resize-handle"></span>
                                </th>
                                <th className='shortTableHead'>
                                    {i18n.t("Eligibility.batchEligibility.dob")}
                                    <span className="resize-handle"></span>
                                </th>
                                <th className='shortTableHead'>
                                    {i18n.t("Eligibility.batchEligibility.insurance")}
                                    <span className="resize-handle"></span>
                                </th>
                                <th className='shortTableHead'>
                                    {i18n.t("Eligibility.batchEligibility.policyId")}
                                    <span className="resize-handle"></span>
                                </th>
                                <th className='longTableHead'>
                                    {i18n.t("Eligibility.batchEligibility.summary")}
                                    <span className="resize-handle"></span>
                                </th>
                            </tr>
                        }

                        tableRows={
                            batchEligibilityHistoryList ? batchEligibilityHistoryList.map((item) => {
                                return (
                                    <tr key={item.id}>
                                        <td align='center'>{item.batch_id}</td>
                                        <td>
                                            <Typography
                                                sx={{
                                                    color: '#1479BB',
                                                    fontFeatureSettings: "'clig' off, 'liga' off",
                                                    lineHeight: 'normal',
                                                    textTransform: 'uppercase',
                                                }}
                                            >
                                                {item.patient_name}
                                            </Typography>
                                        </td>
                                        <td><Moment format='MM/DD/YYYY'>{item.dob}</Moment></td>
                                        <td>{item.insurance_name}</td>
                                        <td>{item.policy_id}</td>
                                        <td style={{
                                            textAlign: 'center',
                                            verticalAlign: 'middle',
                                            display: 'flex',
                                            justifyContent: 'center',
                                            alignItems: 'center'
                                        }}>
                                            {item.status === 1 ?
                                                <Chips type="processing" /> :
                                                item.status === 2 ? <Chips type="summary" onClick={(e) => showEBResponseSummary(e, item.id, item.patient_id, item.insurance_name)} />
                                                    : <Chips type="noPayer" />
                                            }
                                        </td>
                                    </tr>
                                )
                            }) :
                                (!batchEligibilityHistoryList || batchEligibilityHistoryList.length === 0) &&
                                <tr>
                                    <td align="center" colSpan={7}>{initialTableData}</td>
                                </tr>
                        }

                    />
                </div>
                <div>
                    <Pagination totalPage={totalHistoryPage} activePage={activeHistoryPage} startIndex={startHistoryIndex} endIndex={endHistoryIndex} onPagePrevious={onPageHistoryPrevious} onPageUp={onPageHistoryUp} onPageNext={onPageHistoryNext} />
                    {totalHistoryPage <= 1 && <div className="clear-b-scroll-sector">&nbsp;</div>}
                </div>


            </Form>
            <CustomizedDialogs header={header} update={getExtractedData} eligibility={(htmlResponse?.status_info?.request_status === "active" && htmlResponse?.subscriber_pk) ? true : false} showModal={eraOpenModal} setShowModalWindow={setEraOpenModal} resetForm={resetForm} alertOK={onHide} onHide={onHide} >
                <SummaryView htmlResponse={htmlResponse} insuranceName={insuranceName} />
            </CustomizedDialogs>
            <UpdateInsuranceDetails
                openUpdateModal={openUpdateModal}
                setOpenUpdateModal={setOpenUpdateModal}
                onSaveFormData={onSaveFormData}
                updateHeader={updateHeader}
                onUpdateHide={onUpdateHide}
                resetUpdateForm={resetUpdateForm}
                onHandleChange={onHandleChangeEligibilityUpdate}
                memberName={memberName}
                setCoPayType={setCoPayType}
                coPayTrue={coPayTrue}
                coPayTypeList={coPayTypeList}
                subscriberName={subscriberName}
                coInsuranceTrue={coInsuranceTrue}
                coPay={coPay}
                coPayType={coPayType}
                coInsurance={coInsurance}
                coInsuranceType={coInsuranceType}
                coInsuranceTypeList={coInsuranceTypeList}
                setCoInsuranceType={setCoInsuranceType}
                deductibleTypeList={deductibleTypeList}
                address={address}
                deductibleType={deductibleType}
                setDeductibleType={setDeductibleType}
                deductibleTrue={deductibleTrue}
                deductible={deductible}
                claimPolicyID={claimPolicyID}
                outOfPocketTypeList={outOfPocketTypeList}
                pocketExpenseType={pocketExpenseType}
                setPocketExpenseType={setPocketExpenseType}
                outOfPocketMaxTrue={outOfPocketMaxTrue}
                outOfPocketMax={outOfPocketMax}
                groupNumberTrue={groupNumberTrue}
                groupNumber={groupNumber}
                effDateTrue={effDateTrue}
                onHandleEffDate={onHandleEffDate}
                effDate={effDate}
            />

            <CustomizedSmallDialogs showModal={showAlertModalWindow} header={i18n.t("commons.alert")} alertOK={onAlertOk} type="alert" setShowModalWindow={setShowAlertModalWindow}>
                {alertMessage}
            </CustomizedSmallDialogs>

            <RightSidePanel title={i18n.t("commons.advancedSearch")}>
                <PatientSearch setShowLoadingBar={setShowLoadingBar}
                    onAdvanceSearchPatients={onAdvanceSearchPatients}
                >
                </PatientSearch>
            </RightSidePanel>
        </React.Fragment>
    )
}

export default BatchEligibility
