import api from "../../../service/api";

const GetPayerTypesList = async (pageSize, page, searchValue) => {
    let path =
        "super-admin/payer-type/?page_size=" + pageSize + "&page=" + page;
    if (searchValue) {
        path += `&search=${searchValue}`;
    }
    return api.get(path);
};

const AddPayerType = async (data) => {
    let path = "super-admin/payer-type/";
    return api.post(path, data);
};
const GetPayerType = async (payerId) => {
    let path = "super-admin/payer-type/" + payerId;
    return api.get(path);
};
const DeletePayerType = async (payerId) => {
    let path = "super-admin/payer-type/" + payerId;
    return api.destroy(path);
};

const UpdatePayerType = async (payerId, data) => {
    let path = "super-admin/payer-type/" + payerId;
    return api.put(path, data);
};

export default {
    GetPayerTypesList,
    AddPayerType,
    GetPayerType,
    DeletePayerType,
    UpdatePayerType,
};
