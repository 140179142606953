import React from "react";
import AllReportCard from "./AllReportCard";
import FavouriteReportCard from "./FavouriteReportCard";
import RecentReports from "./RecentReports";
const RunReportCard = ({ onReportItemClickHandler }) => {
  return (
    <div className="container">
      <div className="row">
        <div className="col-6">
          <AllReportCard onReportItemClickHandler={onReportItemClickHandler} />
        </div>
        <div className="col-6">
          <FavouriteReportCard />
          <RecentReports />
        </div>
      </div>
    </div>
  );
};

export default RunReportCard;
