import React, { useState, useEffect } from 'react';
import i18n from '../../../utilities/i18n';
import './style.css';
function SummaryView(props) {
    const [requestStatus, setRequestStatus] = useState("");
    const [serviceTypeInfo, setServiceTypeInfo] = useState({});

    // useeffects code
    useEffect(() => {
        if(props.htmlResponse.status_info){
            setRequestStatus(props.htmlResponse.status_info.request_status);
        }
        if(props.htmlResponse.service_types_info)
               setServiceTypeInfo(props.htmlResponse.service_types_info);
    }, [])


return (
    <React.Fragment>
        <div className="form-group">
            <div className='row padding-bottom20'>
                <div className="col-6 alignLeft">
                    {i18n.t("Eligibility.eligibilityTab1.summary.payerName")} : {" "}
                    { props.htmlResponse.payer_name ? props.htmlResponse.payer_name : "" }
                </div>
                <div className="col justify-left">
                    <div className="fa-pull-right">
                        {i18n.t("Eligibility.eligibilityTab1.summary.TransactionDate")} : {" "}
                        {props.htmlResponse.transaction_date ? props.htmlResponse.transaction_date : ""}
                    </div>
                    </div>
                </div>
                
                <div className="border border-2  margin-top10">
                    <div className='row margin-right0 margin-left0 blue-header'>
                        <div className="col justify-center">
                            <label className="margin-top-3px">{i18n.t("Eligibility.eligibilityTab1.summary.requestedInfo.header")}</label>
                        </div>
                    </div>
                    <div className='row padding-top20 pl-3'>
                        <div className="col-3">
                            <label className="margin-top-3px">{i18n.t("Eligibility.eligibilityTab1.summary.requestedInfo.eligbilityDate")}</label>
                        </div>
                        <div className="col-3">
                        {
                            props?.htmlResponse?.request_info?.eligibility_dates ?? ""
                        }
                    </div>
                        <div className="col-3">
                            <label className="margin-top-3px">{i18n.t("Eligibility.eligibilityTab1.summary.requestedInfo.serviceTypes")}</label>
                        </div>
                        <div className="col-3">
                            {props.htmlResponse.request_info.service_types}
                        </div>
                    </div>
                    <div className='row pl-3'>
                        <div className="col-3">
                            <label className="margin-top-3px">{i18n.t("Eligibility.eligibilityTab1.summary.requestedInfo.memberID")}</label>
                        </div>
                        <div className="col-3">
                            {props.htmlResponse.request_info.member_id}
                        </div>
                        <div className="col-3">
                            <label className="margin-top-3px">{i18n.t("Eligibility.eligibilityTab1.summary.requestedInfo.requestedProvider")}</label>
                        </div>
                        <div className="col-3">
                            {props.htmlResponse.request_info.provider}
                        </div>
                    </div>
                    <div className='row pl-3'>
                        <div className="col-3">
                            <label className="margin-top-3px">{i18n.t("Eligibility.eligibilityTab1.summary.requestedInfo.dob")}</label>
                        </div>
                        <div className="col-3">
                            {props.htmlResponse.request_info.dob}
                        </div>
                        <div className="col-3">
                            <label className="margin-top-3px">{i18n.t("Eligibility.eligibilityTab1.summary.requestedInfo.providerNPI")}</label>
                        </div>
                        <div className="col-3">
                            {props.htmlResponse.request_info.provider_npi}
                        </div>
                    </div>
                    <div className='row pl-3'>
                        <div className="col-3">
                            <label className="margin-top-3px">{i18n.t("Eligibility.eligibilityTab1.summary.requestedInfo.lastName")}</label>
                        </div>
                        <div className="col-3">
                            {props.htmlResponse.request_info.last_name}
                        </div>
                    </div>
                    <div className='row pl-3'>
                        <div className="col-3">
                            <label className="margin-top-3px">{i18n.t("Eligibility.eligibilityTab1.summary.requestedInfo.firstName")}</label>
                        </div>
                        <div className="col-3">
                            {props.htmlResponse.request_info.first_name}
                        </div>
                    </div>
                </div>
            <div className="border border-2  margin-top10">
                    <div className="row padding-top5 pl-3">
                        {requestStatus !== "" &&
                            <>
                            <div className="col-3">
                                <label className="">{i18n.t("Eligibility.eligibilityTab1.summary.statusInfo.effectiveDate")}</label>
                            </div>
                            <div className="col-3  alignLeft">
                                {props.htmlResponse.status_info.effective_date}
                            </div>
                            <div className="col-3">
                                {props.htmlResponse.status_info.termination_date &&
                                    <div>
                                        <label className="">{i18n.t("Eligibility.eligibilityTab1.summary.statusInfo.terminationDate")}</label>
                                    </div>
                                }
                            </div>
                            <div className="col-3">
                                {props.htmlResponse.status_info.termination_date &&
                                    <>
                                    {props.htmlResponse.status_info.termination_date}
                                    </>
                                }
                            </div>
                            </>
                    }
                </div>
            </div>
            <div className="margin-top10">
                <div className={requestStatus === "active" ? "row margin-right0 margin-left0 green-header" : "row margin-right0 margin-left0 red-light-header"}>
                    <div className="col justify-center">
                        {requestStatus === 'active' &&
                            <label className="margin-top-3px">{i18n.t("Eligibility.eligibilityTab1.summary.statusInfo.headerActive")}</label>
                        }
                        {props.htmlResponse.status_info && props.htmlResponse.status_info.request_status === 'inactive' &&
                            <label className="margin-top-3px">{i18n.t("Eligibility.eligibilityTab1.summary.statusInfo.headerInActive")}</label>
                        }
                        {props.htmlResponse.status_info && props.htmlResponse.status_info.request_status === 'transaction_failed' &&
                            <label className="margin-top-3px">{i18n.t("Eligibility.eligibilityTab1.summary.statusInfo.headerTransactionFailed")}</label>
                        }
                    </div>
                </div>
                {props.htmlResponse.status_info?.request_reject_reason &&
                    <div className={"row margin-right0 margin-left0 red-light-header"}>
                        <div className="col">
                            <label className="margin-top-3px">Reason : {props.htmlResponse.status_info?.request_reject_reason}</label>
                        </div>
                    </div>
                }
                </div>
                {Object.keys(props.htmlResponse.subscriber_info).length === 0 ? "" :
                    <div className="border border-2  margin-top10">
                        <div className="row margin-right0 margin-left0 brown-header">
                            <div className="col justify-center">
                                <label className="margin-top-3px">{i18n.t("Eligibility.eligibilityTab1.summary.subscriberInfo.header")}</label>
                            </div>
                        </div>
                        {props.htmlResponse.subscriber_info.subscriber_name ?
                            <div className="row padding-top5 pl-3">
                                <div className="col-3">
                                    <label className="">{i18n.t("Eligibility.eligibilityTab1.summary.subscriberInfo.memberName")}</label>
                                </div>
                                <div className="col-9">
                                    <label className="">{props.htmlResponse.subscriber_info.subscriber_name}</label>
                                </div>
                            </div> : ""
                        }
                        {props.htmlResponse.subscriber_info.subscriber_dob ?
                            <div className="row pl-3">
                                <div className="col-3">
                                    <label className="">{i18n.t("Eligibility.eligibilityTab1.summary.subscriberInfo.memberDOB")}</label>
                                </div>
                                <div className="col-9">
                                    <label className="">{props.htmlResponse.subscriber_info.subscriber_dob}</label>
                                </div>
                            </div> : ""
                        }
                        {props.htmlResponse.subscriber_info.subscriber_address ? 
                            <div className="row pl-3">
                                <div className="col-3">
                                    <label className="">{i18n.t("Eligibility.eligibilityTab1.summary.subscriberInfo.address")}</label>
                                </div>
                                <div className="col-9">
                                    <label className="">{props.htmlResponse.subscriber_info.subscriber_address}</label>
                                </div>
                            </div> : ""
                        }
                        {props.htmlResponse.subscriber_info.subscriber_gender ? 
                            <div className="row pl-3">
                                <div className="col-3">
                                    <label className="">{i18n.t("Eligibility.eligibilityTab1.summary.subscriberInfo.gender")}</label>
                                </div>
                                <div className="col-9">
                                    <label className="">{props.htmlResponse.subscriber_info.subscriber_gender}</label>
                                </div>
                            </div> : ""
                        }
                        {props.htmlResponse.subscriber_info.member_id ?
                        <div className="row pl-3">
                            <div className="col-3">
                                <label className="">{i18n.t("Eligibility.eligibilityTab1.summary.subscriberInfo.memberID")}</label>
                            </div>
                            <div className="col-9">
                                <label className="">{props.htmlResponse.subscriber_info.member_id}</label>
                            </div>
                        </div> : ""
                        }
                        {props.htmlResponse.subscriber_info.subscriber_relationship ?
                            <div className="row pl-3">
                                <div className="col-3">
                                    <label className="">{i18n.t("Eligibility.eligibilityTab1.summary.subscriberInfo.relationship")}</label>
                                </div>
                                <div className="col-9">
                                    <label className="">{props.htmlResponse.subscriber_info.subscriber_relationship}</label>
                                </div>
                            </div> : ""
                        }
                    </div>
                }
                {Object.keys(props.htmlResponse.coverage_info).length === 0 ? "" :
                    <div className="border border-2  margin-top10">
                        <div className="row margin-right0 margin-left0 brown-header">
                            <div className="col justify-center">
                                <label className="margin-top-3px">{i18n.t("Eligibility.eligibilityTab1.summary.coverageInfo.header")}</label>
                            </div>
                        </div>
                        {props.htmlResponse.coverage_info.plan_number ?
                            <div className="row padding-top5 pl-3">
                                <div className="col-3">
                                    <label className="">{i18n.t("Eligibility.eligibilityTab1.summary.coverageInfo.planNumber")}</label>
                                </div>
                                <div className="col-9">
                                    <label className="">{props.htmlResponse.coverage_info.plan_number}</label>
                                </div>
                            </div> : ""
                        }
                        {props.htmlResponse.coverage_info.plan_name ?
                            <div className="row pl-3">
                                <div className="col-3">
                                    <label className="">{i18n.t("Eligibility.eligibilityTab1.summary.coverageInfo.planName")}</label>
                                </div>
                                <div className="col-9">
                                    <label className="">{props.htmlResponse.coverage_info.plan_name}</label>
                                </div>
                            </div> : ""
                        }
                        {props.htmlResponse.coverage_info.group_name ?
                            <div className="row pl-3">
                                <div className="col-3">
                                    <label className="">{i18n.t("Eligibility.eligibilityTab1.summary.coverageInfo.groupName")}</label>
                                </div>
                                <div className="col-9">
                                    <label className="">{props.htmlResponse.coverage_info.group_name}</label>
                                </div>
                            </div> : ""
                        }
                        {props.htmlResponse.coverage_info.group_number ? 
                            <div className="row pl-3">
                                <div className="col-3">
                                    <label className="">{i18n.t("Eligibility.eligibilityTab1.summary.coverageInfo.groupNumber")}</label>
                                </div>
                                <div className="col-9">
                                    <label className="">{props.htmlResponse.coverage_info.group_number}</label>
                                </div>
                            </div> : ""
                        }
                        {props.htmlResponse.coverage_info.plan_begin_date ?
                            <div className="row pl-3">
                                <div className="col-3">
                                    <label className="">{i18n.t("Eligibility.eligibilityTab1.summary.coverageInfo.planBeginDate")}</label>
                                </div>
                                <div className="col-9">
                                    <label className="">{props.htmlResponse.coverage_info.plan_begin_date}</label>
                                </div>
                            </div> : ""
                        }
                        {props.htmlResponse.coverage_info.plan_end_date ?
                            <div className="row pl-3">
                                <div className="col-3">
                                    <label className="">{i18n.t("Eligibility.eligibilityTab1.summary.coverageInfo.planEndDate")}</label>
                                </div>
                                <div className="col-9">
                                    <label className="">{props.htmlResponse.coverage_info.plan_end_date}</label>
                                </div>
                            </div> : ""
                        }
                    </div>
                }
                {Object.keys(props.htmlResponse.insurance_plan_type).length === 0 ? "" :
                    <div className="border border-2  margin-top10">
                        <div className="row margin-right0 margin-left0 brown-header">
                            <div className="col justify-center">
                                <label className="margin-top-3px">{i18n.t("Eligibility.eligibilityTab1.summary.insurancePlanType.header")}</label>
                            </div>
                        </div>
                        {props.htmlResponse.insurance_plan_type.service_type ?
                            <div className="row padding-top5 pl-3">
                                <div className="col-3">
                                    <label className="">{i18n.t("Eligibility.eligibilityTab1.summary.insurancePlanType.serviceType")}</label>
                                </div>
                                <div className="col-5">
                                    <label className="">{props.htmlResponse.insurance_plan_type.service_type}</label>
                                </div>
                                <div className="col-4">
                                    <label className="">{props.htmlResponse.insurance_plan_type.coverage_level_code}</label>
                                </div>
                            </div> : ""
                        }
                        {props.htmlResponse.insurance_plan_type.insurance_plan_type ?
                            <div className="row pl-3">
                                <div className="col-3">
                                    <label className="">{i18n.t("Eligibility.eligibilityTab1.summary.insurancePlanType.insurancePlanType")}</label>
                                </div>
                                <div className="col-9">
                                    <label className="">{props.htmlResponse.insurance_plan_type.insurance_plan_type}</label>
                                </div>
                            </div> : ""
                        }
                        {props.htmlResponse.insurance_plan_type.insurance_plan ?
                            <div className="row pl-3">
                                <div className="col-3">
                                    <label className="">{i18n.t("Eligibility.eligibilityTab1.summary.insurancePlanType.insurancePlan")}</label>
                                </div>
                                <div className="col-9">
                                    <label className="">{props.htmlResponse.insurance_plan_type.insurance_plan}</label>
                                </div>
                            </div> : ""
                        }
                    </div>
                }
                {Object.keys(props.htmlResponse.additional_plan_type_info).length === 0 ? "" : 
                    <div className="border border-2  margin-top10">
                        <div className="row margin-right0 margin-left0 brown-header">
                            <div className="col justify-center">
                                <label className="margin-top-3px">{i18n.t("Eligibility.eligibilityTab1.summary.addPlanTypeInfo.header")}</label>
                            </div>
                        </div>
                        {props.htmlResponse.additional_plan_type_info && props.htmlResponse.additional_plan_type_info.additional_plan_type.length && 
                            props.htmlResponse.additional_plan_type_info.additional_plan_type.length > 0 &&
                            props.htmlResponse.additional_plan_type_info.additional_plan_type.map((item, index)=>{
                                return(
                                    <div className="row padding-top5 pl-3" key={index}>
                                        <div className="col-3">
                                            <label className="">{item.plan_type}</label>
                                        </div>
                                        <div className="col-9">
                                            <label className="">{item.service_type}</label>
                                        </div>
                                    </div>
                                )
                            })
                        }
                    </div>
                }

                {Object.keys(props.htmlResponse.primary_care_provider).length === 0 ? "" :
                    <div className="border border-2  margin-top10">
                        <div className="row margin-right0 margin-left0 brown-header">
                            <div className="col justify-center">
                                <label className="margin-top-3px">{i18n.t("Eligibility.eligibilityTab1.summary.primaryCareProvider.header")}</label>
                            </div>
                        </div>
                        {Object.keys(props.htmlResponse.primary_care_provider).map((key) => {
                            return (
                                <>
                                {props.htmlResponse.primary_care_provider[key] ?
                                    <div className="row pl-3">
                                        <div className="col-6">
                                            <label className="margin-top-3px">{i18n.t("Eligibility.eligibilityTab1.summary.primaryCareProvider."+key)}</label>
                                        </div>
                                        <div className="col-6">
                                            <label className="margin-top-3px">{props.htmlResponse.primary_care_provider[key]}</label>
                                        </div>
                                    </div> : ""
                                }
                                </>
                            )
                        })}
                    </div>
                }
                {Object.keys(props.htmlResponse.additional_benefit_info).length === 0 ? "" :
                    <div className="border border-2  margin-top10">
                        <div className="row margin-right0 margin-left0 brown-header">
                            <div className="col justify-center">
                                <label className="margin-top-3px">{i18n.t("Eligibility.eligibilityTab1.summary.contactInfoForAddBenefitInfo.header")}</label>
                            </div>
                        </div>
                        {Object.keys(props.htmlResponse.additional_benefit_info).map((key) => {
                            return (
                                <>
                                {props.htmlResponse.additional_benefit_info[key] ?
                                    <div className="row pl-3">
                                        <div className="col-6">
                                            <label className="margin-top-3px">{i18n.t("Eligibility.eligibilityTab1.summary.contactInfoForAddBenefitInfo."+key)}</label>
                                        </div>
                                        <div className="col-6">
                                            <label className="margin-top-3px">{props.htmlResponse.additional_benefit_info[key]}</label>
                                        </div>
                                    </div> : ""
                                }
                                </>
                            )
                        })}
                    </div>
                }
                {Object.keys(props.htmlResponse.other_payer_info).length === 0 ? "" :
                    <div className="border border-2  margin-top10">
                        <div className="row margin-right0 margin-left0 brown-header">
                            <div className="col justify-center">
                                <label className="margin-top-3px">{i18n.t("Eligibility.eligibilityTab1.summary.otherPayerInfo.header")}</label>
                            </div>
                        </div>
                        {Object.keys(props.htmlResponse.other_payer_info).map((key) => {
                            return (
                                <>
                                {props.htmlResponse.other_payer_info[key] ?
                                    <div className="row pl-3">
                                        <div className="col-6">
                                            <label className="margin-top-3px">{i18n.t("Eligibility.eligibilityTab1.summary.otherPayerInfo."+key)}</label>
                                        </div>
                                        <div className="col-6">
                                            <label className="margin-top-3px">{props.htmlResponse.other_payer_info[key]}</label>
                                        </div>
                                    </div> : ""
                                }
                                </>
                            )
                        })}
                    </div>
                }
                {Object.keys(serviceTypeInfo).map((serviceKey, index) => {
                        {/* Service Type looping */}
                    return(
                        <div className="border border-2  margin-top10" key={index}>
                            <div className="row margin-right0 margin-left0 green-header">
                                <div className="col justify-center">
                                    {/* each Service Type header */}
                                    <label className="margin-top-3px">{serviceTypeInfo[serviceKey]['header']}</label>
                                </div>
                            </div>
                                {Object.keys(serviceTypeInfo[serviceKey]['vals']).map((netWorkKey) =>{
                                    {/* Net Work looping */}
                                    return(
                                    <>
                                        <div  className="row margin-right0 margin-left0 margin-top5 margin-bottom5 bgServiceNWColor">
                                            <div className="col justify-center">
                                                    {/* each Net Work header */}
                                                    <label className="margin-top-3px">{serviceTypeInfo[serviceKey]['vals'][netWorkKey]['header']}</label>
                                            </div>
                                        </div>
                                            {Object.keys(serviceTypeInfo[serviceKey]['vals'][netWorkKey]['vals']).map((levelCodeKey, index12) => {
                                                {/* Net Work level code looping */}
                                                return(
                                                    <div className="row margin-right0 margin-left0 border-bottom" key={index12}>
                                                        <div className="col-4">
                                                            {/* each Level Code header */}
                                                            <label className="margin-top-3px">{serviceTypeInfo[serviceKey]['vals'][netWorkKey]['vals'][levelCodeKey]['header']}</label>
                                                        </div>
                                                        <div className="col-8">
                                                            {serviceTypeInfo[serviceKey]['vals'][netWorkKey]['vals'][levelCodeKey]['vals'].map((levelItem, index) =>{
                                                            {/* 
                                                                Net Work level left side values looping, 
                                                                each loop is a 2100C segment it may contain again sub looping.
                                                                it will set a bottom border to diffrentiate(check loop begin div border class) 
                                                            */}
                                                                return(
                                                                    <div className="row border-bottom" key={index}>
                                                                    {levelItem.map((levelItemsChild) => {
                                                                    {/* 
                                                                        Level Item Child looping 
                                                                    */}
                                                                    return(
                                                                    <>
                                                                    <div className="col-3">
                                                                        {/* each Level Item Child header */}
                                                                        <label className="margin-top-3px">{levelItemsChild[0]}</label>
                                                                    </div>
                                                                    <div className="col-9">
                                                                        {Array.isArray(levelItemsChild[1]) && levelItemsChild[1].length &&
                                                                                    levelItemsChild[1].map((itemVal, ind) => {
                                                                            {/* Message level - list of message need to show on by one row */}
                                                                                return(
                                                                                    <div className="row" key={ind}>
                                                                                        <div className="col">
                                                                                            <label className="margin-top-3px">{itemVal}</label>
                                                                                        </div>
                                                                                    </div>
                                                                                )
                                                                            })
                                                                        }
                                                                        {!Array.isArray(levelItemsChild[1]) &&
                                                                            <label className="margin-top-3px">{levelItemsChild[1]}</label>
                                                                        }
                                                                    </div>
                                                                    </>
                                                                    )

                                                                    })}
                                                                </div>
                                                                )
                                                            })}
                                                        </div>
                                                    </div>
                                                )
                                            })}
                                    </>
                                    )
                                })}
                        </div>
                    )
                })}
        </div>
    </React.Fragment>
    )
}

export default SummaryView