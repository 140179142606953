import api from '../../../service/api'

const GetListRemittanceRemarkCode = async (...args) => {
	let [pageSize, page, isAdminModule, practicePK, practiceDict, searchValue, orderingField,orderType] = args;
	let path = null;
	if (isAdminModule === 'true')
		path = 'super-admin/remark-code/?page_size=' + pageSize + '&page=' + page ;
	else if (practiceDict){
		path = 'super-admin/remark-code/?page_size=' + pageSize + '&page=' + page + '&practice_pk=' + practicePK ;
	}
	else {
		path = 'practice/remark-code/?page_size=' + pageSize + '&page=' + page + '&practice_pk=' + practicePK ;
	}
	if (searchValue)
		path += '&search=' + searchValue;
	if (orderingField && orderingField != "dropdown") {
        if (isAdminModule !== "true"){
            orderingField = "master__" + orderingField;
		}
        let fieldOrder = "";
        if (orderType) fieldOrder += orderType;
		fieldOrder += orderingField;
        path += "&column=" + fieldOrder;
    } else {
        if (isAdminModule === "true" || practiceDict) {
			path += "&column=name";
		} else {
			path += "&column=master__name";
		} 
    }
	return api.get(path);
}

const AddRemittanceRemarkCode = async (data) => {
	let path = null;
	if (Object.prototype.hasOwnProperty.call(data, 'practice_pk'))
		path = 'practice/remark-code/';
	else
		path = 'super-admin/remark-code/';
		return api.post(path, data);
}

const GetRemittanceRemarkCode = async (id, practicePK) => {
	let path = null;
	if (practicePK)
		path = 'practice/remark-code/' + id;
	else
		path = 'super-admin/remark-code/' + id;
		return api.get(path);
}

const DeleteRemittanceRemarkCode = async (id, practicePK) => {
	let path = null;
	if (practicePK)
		path = 'practice/remark-code/' + id;
	else
		path = 'super-admin/remark-code/' + id;
		return api.destroy(path);
}

const UpdateRemittanceRemarkCode = async (id, data) => {
	let path = null;
	if (Object.prototype.hasOwnProperty.call(data, 'practice'))
		path = 'practice/remark-code/' + id;
	else
		path = 'super-admin/remark-code/' + id;
		return api.put(path, data);
}

const ImportCSVData = async (data) => {
	let path = 'super-admin/import-remark-code/'
	return api.post(path, data);
}

const exportRemarkCode = async (type, practicePK) => {
	const path = 'practice/remark-code/?practice_pk=' + practicePK + '&export=true&export_type=' + type;
	return api.getXLS(path);
}


export default {
	exportRemarkCode, GetListRemittanceRemarkCode, AddRemittanceRemarkCode, GetRemittanceRemarkCode, DeleteRemittanceRemarkCode, UpdateRemittanceRemarkCode, ImportCSVData
}