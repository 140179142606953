import React, { useState } from 'react';
import { useLocation} from 'react-router-dom';
import './style.css';
import i18n from '../../../utilities/i18n';
import service from './service';
import PasswordManagement from './passwordManagement';

const SetPassword = () => {
    const location = useLocation();
    const params = new URLSearchParams(location.search);
    const fToken = params.get('f_token');
    const userId = params.get('user_id');
    const expiryTime = params.get('expiry_time');
    const [respStatus, setResponseStatus] = useState(false);
    verifyUser();

    function verifyUser(){
        let result = service.ForgotPassword(fToken, userId, expiryTime);
        result.then(response => {
            setResponseStatus(response.data.status);
        });
    }

    return (
        <React.Fragment>
            {respStatus ? 
            <div className="">
                <div className="center-div-with-shadow  white-bg">
                    <PasswordManagement type="forgot" header={i18n.t('resetPassword.createrTextSuper')}/>
                </div>
            </div>
            : <div>
                <div className="center-div-with-shadow  white-bg invalid-link">
                    <span className="forgetpassword-span">{i18n.t('resetPassword.linkExpired')}</span>
                </div>
              </div>
            }
        </React.Fragment>
    )
}

export default SetPassword;