import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import './style.css';
import service from './service';
import { Grid, Avatar, CssBaseline, Paper, Skeleton } from '@mui/material';
import PasswordManagement from './passwordManagement';

// import loginLeft from '../../../assets/images/login_bg_left.png';
import { makeStyles } from '@material-ui/core/styles';
import i18n from '../../../utilities/i18n';
// import { Copyright } from '@material-ui/icons';
import logo from '../../../assets/images/AIMA_Logo.png'
import Notify from '../notify';

const useStyles = makeStyles((theme) => ({
    root: {
        height: '100vh',
        flexDirection: "row",
    },
    image: {
        backgroundImage: `url(https://aimapms-staging-public.s3.amazonaws.com/Login-leftside.png)`,
        backgroundRepeat: 'no-repeat',
        backgroundColor:
            theme.palette.type === 'light' ? theme.palette.grey[50] : theme.palette.grey[900],
        backgroundSize: 'cover',
        backgroundPosition: 'center',
    },
    paper: {
        //margin: theme.spacing(20, 5),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        height: '100%',
        justifyContent: 'center',
    },
    avatar: {
        margin: theme.spacing(1),
        '&.MuiAvatar-root': {
            width: 'auto',
            borderRadius: '0px',
            height: '130px'
        }
    },
    form: {
        width: '55%', // Fix IE 11 issue.
        marginTop: 20,

    },
    submit: {
        margin: theme.spacing(3, 0, 2),
        fontFamily: 'Lato',
        fontSize: 'medium',
        backgroundColor: '#1479BB',
        color: '#fff',
        textTransform: 'none',
        height: 55,
        '&:hover': {
            backgroundColor: '#1479BB',
            boxShadow: 'none',
            color: '#fff',
        },
    },
    login: { fontFamily: 'Lato', fontSize: 20, fontWeight: 600 },
    center: { textAlign: 'center' },

    copyright: {

        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
        //paddingTop:'60px'
    }

}));


const ForgotPasswordComponent = () => {
    const classes = useStyles();
    const location = useLocation();
    const params = new URLSearchParams(location.search);
    const fToken = params.get('f_token');
    const userId = params.get('user_id');
    const expiryTime = params.get('expiry_time');
    const token = params.get('token');
    const [respStatus, setResponseStatus] = useState(false);
    const [loading, setLoading] = useState(true);


    const [showNotify, setShowNotify] = useState("hide");
    const [notifyDescription, setNotifyDescription] = useState("");
    const [notifyType, setNotifyType] = useState("success");
    const [notifyPrimaryText, setNotifyPrimaryText] = useState("")


    function showNotifyWindow(action, type, desc, age = 3000, primaryText) {
        if (action === "show") {
            setTimeout(() => {
                setShowNotify("hide");
                setNotifyPrimaryText("");
            }, age);
        }
        setShowNotify(action);
        setNotifyType(type);
        setNotifyDescription(desc);
        setNotifyPrimaryText(primaryText)
    }



    useEffect(() => {
        verifyUser();
    }, [token])

    function verifyUser() {
        let result = service.ForgotPassword(fToken, userId, expiryTime, token);
        result.then(response => {
            if (response?.data?.message === "Invalid Token") {
                showNotifyWindow("show", "danger", "Please re-try Forgot Password or Connect with Admin to resend verification link.", 7000, "Authentication Link Expired.",);
                setTimeout(() => {
                    window.location.href = '/';
                }, 10000);
            } 
            setResponseStatus(response.data.status);
            setLoading(false);

        });
    }

    return (
        <React.Fragment>
            <Notify
                showNotify={showNotify}
                notifyPrimaryText={notifyPrimaryText}
                setShowNotify={setShowNotify}
                notifyDescription={notifyDescription}
                setNotifyType={setNotifyType}
                setNotifyDescription={setNotifyDescription}
                notifyType={notifyType}
            />
            <Grid container component="main" className={classes.root}>
            {!loading && (
                    <>
                        <CssBaseline />
                        <Grid item xs={false} sm={4} md={7} className={classes.image} />
                        <Grid item xs={6} sx={{ height: '100%', width: '100%' }} sm={8} md={5} component={Paper} elevation={6} square>
                            <div className={classes.paper}>
                                <Avatar className={classes.avatar} src={logo} />
                                {
                                    respStatus ? (
                                        <>
                                            <PasswordManagement type="forgot" header={i18n.t('resetPassword.createrTextSuper')} />
                                        </>
                                    ) :
                                        <>
                                            <div className="login-form-header padding-top20 text-center" >{i18n.t('resetPassword.createrTextSuper')}</div>
                                            <Skeleton variant="rect" width={300} height={70} mb={2} sx={{ borderRadius: '8px', marginBottom: '20px' }} />
                                            <Skeleton variant="rect" width={300} height={70} mb={2} sx={{ borderRadius: '8px', marginBottom: '20px' }} />
                                        </>
                                    }
                            </div>

                        </Grid>
                    </>
                )}
            </Grid>
        </React.Fragment>
    )
}

export default ForgotPasswordComponent