import React, { useEffect, useState } from 'react'
import { Form } from 'react-bootstrap';
import i18n from '../../../utilities/i18n'
import service from './service';
import imgCardFrontdAdd from '../../../assets/images/card-front-add.png';
import imgCardBackdAdd from '../../../assets/images/card-back-add.png';
import CustomizedDialogs from '../../modalWindowComponent/CustomizedDialogs';
import { currencyFormat } from '../../../utilities/commonUtilities';
import moment from 'moment';

function PatientPastCoverage(props) {
    const patientPK = props.patientPK;
    const [insuranceDetails, setInsuranceDetails] = useState([]);


    const [showPatienPastCoverages, setShowPatienPastCoverages] = useState(false);
    const [header, setHeader] = useState("");

    function resetPastCoverages() {
        setHeader("");
    }

    function onShowPastCoverages() {
        setShowPatienPastCoverages(true)
        setHeader(i18n.t("pastCoverages.Addheader"))
        getPastCoverageInsuranceInfosOfPatient();
    }
    function getPastCoverageInsuranceInfosOfPatient() {
        let status = 2;
        const result = service.ListPastCoverageInsuranceInfos(patientPK, status);
        result.then((response) => {
            response.data?.forEach((item) => {
                if (item.termination_date || item.effective_date)
                    if (item.termination_date) {
                        item.termination_date = moment(item.termination_date).format('MM/DD/YYYY');
                    }
                if (item.effective_date) {
                    item.effective_date = moment(item.effective_date).format('MM/DD/YYYY');
                }
            })
            setInsuranceDetails(response.data);
        });
    }
    useEffect(() => {
        if (props.patientPK) {
            getPastCoverageInsuranceInfosOfPatient();
        }
    }, [props.patientPK]);




    return (
        <div>
            <div onClick={onShowPastCoverages} data-testid="pastcoverage-btn">{i18n.t("patientPages.buttons.pastCoverages")}</div>
            <div className="">
                <CustomizedDialogs
                    showModal={showPatienPastCoverages}
                    header={header}
                    setShowModalWindow={setShowPatienPastCoverages}
                    resetForm={resetPastCoverages}
                >
                    <Form autoComplete="off">

                        <Form.Group>
                            {insuranceDetails && insuranceDetails.length === 0 ?
                                < label > {i18n.t("commons.noRecords")}</label>
                                : !insuranceDetails && < label > {i18n.t("commons.noRecords")}</label>
                            }
                            {insuranceDetails && insuranceDetails.length !== 0 && (
                                insuranceDetails.map((item, index) => {
                                    return (
                                        <Form.Group key={index}>
                                            <div className="row margin-top10">
                                                <div className="col">
                                                    <div>
                                                        {item.insurance_name} ({item.insurance_id})
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-4" id="uitable">
                                                    {TableData(item)}
                                                </div>
                                                <div className="col-8 insurance_cards">
                                                    {ImageCard(item)}
                                                </div>
                                            </div>
                                        </Form.Group>
                                    )
                                })
                            )}
                        </Form.Group>
                    </Form>
                </CustomizedDialogs>
            </div>
        </div >
    )
}

function TableData(item) {
    return (
        <table>
            <tbody>
                <tr>
                    <td>{i18n.t("patientPages.insuranceInfo.labelPriority")}</td>
                    <td>{item.priority == 1 ? "Primary" : item.priority == 2 ? "Secondary" : item.priority == 3 ? "Tertiary" : ""}
                    </td>
                </tr>
                <tr>
                    <td>{i18n.t("patientPages.insuranceInfo.labelPolicyID")}</td>
                    <td>{item.policy_id}</td>
                </tr>
                <tr>
                    <td>{i18n.t("patientPages.insuranceInfo.labelGroupNumber")}</td>
                    <td>{item.group_number}</td>
                </tr>
                <tr>
                    <td>{i18n.t("patientPages.insuranceInfo.labelCopay")}</td>
                    <td>{currencyFormat(item.copay)}</td>
                </tr>
                <tr>
                    <td>{i18n.t("patientPages.insuranceInfo.labelCoInsurance")}</td>
                    <td>{currencyFormat(item.co_insurance ? parseFloat(item.co_insurance).toFixed(2) : '0.00')}</td>
                </tr>
                <tr>
                    <td>{i18n.t("patientPages.insuranceInfo.labelDeductible")}</td>
                    <td>{currencyFormat(item.deductibles)}</td>
                </tr>
                <tr>
                    <td>{i18n.t("patientPages.insuranceInfo.labelOutOfPocket")}</td>
                    <td>{currencyFormat(item.out_of_pocket)}</td>
                </tr>
                <tr>
                    <td>{i18n.t("patientPages.insuranceInfo.labelEffectiveDate")}</td>
                    <td>{item.effective_date}</td>
                </tr>
                <tr>
                    <td>{i18n.t("patientPages.insuranceInfo.labelterminationDate")}</td>
                    <td>{item.termination_date}</td>
                </tr>
            </tbody>
        </table>
    )
}

function ImageCard(item) {
    return (
        <div className="row imageCardRow">
            <div className="col-5 imageCardCol">
                <div className="imageCardRow">
                    {item.card_front_page &&
                        <div>
                            <img className="profile_card_insurance" src={item.card_front_page} alt={i18n.t("patientPages.insuranceInfo.insuranceCardFront")} />
                            <div className="fontSize14 width-100-perc">{i18n.t("patientPages.insuranceInfo.insuranceCardFront")} </div>
                        </div>
                    }
                    {!item.card_front_page &&
                        <div>
                            <div className="width-100-perc height-100-perc" >
                                <img className="profile_card_insurance_add" src={imgCardFrontdAdd} alt={i18n.t("patientPages.insuranceInfo.insuranceCardFront")} title={i18n.t("patientPages.insuranceInfo.insuranceCardFront")}
                                />
                            </div>
                            <div className="fontSize14">{i18n.t("patientPages.insuranceInfo.insuranceCardFront")}</div>
                        </div>
                    }
                </div>
            </div>
            <div className="col-5 imageCardCol">
                <div className="row imageCardRow">
                    {item.card_back_page &&
                        <div>
                            <img className="profile_card_insurance" id={'file_PrimaryFrontCard' + item.insurance_id} src={item.card_back_page} alt={i18n.t("patientPages.insuranceInfo.insuranceCardBack")}
                            />
                            <div className="fontSize14 width-100-perc">{i18n.t("patientPages.insuranceInfo.insuranceCardBack")} </div>
                        </div>
                    }
                    {!item.card_back_page && <div>
                        <div className="width-100-perc height-100-perc">
                            <img className="profile_card_insurance_add" src={imgCardBackdAdd} id={'file_PrimaryFrontCard' + item.insurance_id} alt={i18n.t("patientPages.insuranceInfo.insuranceCardBack")} title={i18n.t("patientPages.insuranceInfo.insuranceCardBack")}
                            />
                        </div>
                        <div className="fontSize14">{i18n.t("patientPages.insuranceInfo.insuranceCardBack")}</div>
                    </div>}
                </div>
            </div>
        </div>
    )
}

export default PatientPastCoverage
