import api from "../../../service/api";
import { getStorage } from "../../../utilities/browserStorage";

const ListARClaimData = async (claimId) => {
    let path = null;
    path = "claim/ar-claim-data/" + claimId;
    return api.get(path);
}
const ListARClaimPraciceUsers = async (practicePK) => {
    let path = null;
    path = "practice/practice-users/?practice_pk=" + practicePK + "&ar_claim_tab=1";
    return api.get(path);
}
const AssignUserAgainstPractice = async (data) => {
    let path = null;
    path = "claim/user-claims/";
    return api.post(path, data);
}
const UnAssignUserAgainstPractice = async (claim_id) =>{
    let path = null;
    path = "claim/user-claims/" + claim_id;
    return api.destroy(path);
}
const ListMyArClaim = async (pageSize, page, orderingField, orderType) => {
    let path = null;
    path = `claim/user-claims/?practice_pk=${getStorage('practice')}&page_size=${pageSize}&page=${page}`;
    
    if (orderingField) {
        path += '&column=' + orderType + orderingField;
    } 
    return api.get(path);
    
}
const GetSelectedTabs = async (type,practice_pk) => {
    let path = 'user/user-opened-tab/?type=' + type+'&practice_pk='+practice_pk;
    return api.get(path);
}

const AddRemoveSelectedTab = async (data) => {
    let path = 'user/user-opened-tab/';
    return api.post(path, data);
}

const AddArClaimNotes = async (data) =>{
    let path = 'claim/notes/'
    return api.post(path, data);
}

const ListArClaimNotes = async (claim_id) =>{
    let path = 'claim/notes/?category=ar_claim&claim_pk=' + claim_id;
    return api.get(path);
}
const GetArClaimNotes = async (claim_id) =>{
    let path = 'claim/notes/' + claim_id;
    return api.get(path);
}
const UpdateArClaimNotes = async (claim_id, data) =>{
    let path = 'claim/notes/' + claim_id;
    return api.put(path, data);
}
const DeleteArClaimNotes = async (claim_id) =>{
    let path = 'claim/notes/' + claim_id;
    return api.destroy(path);
}

const GetOpendARClaims = async (claimPK) => {
    let path = '/claim/ar-claim-opend/' + claimPK;
    return api.get(path);
}

const CPTActionLogs = async(procedurePK) => {
    let path = 'claim/claims-action-logs/?procedure_pk='+ procedurePK;
    return api.get(path);
}

const ClaimInactive = async (id, data) => {
    let path = '/claim/claim-activate/' + id;
    return api.put(path, data);
}

export default {
    ListARClaimData, ListARClaimPraciceUsers, AssignUserAgainstPractice,UnAssignUserAgainstPractice, ListMyArClaim, GetSelectedTabs, AddRemoveSelectedTab,
    AddArClaimNotes, ListArClaimNotes, GetArClaimNotes, UpdateArClaimNotes, DeleteArClaimNotes, GetOpendARClaims, CPTActionLogs,
    ClaimInactive
}