import api from '../../../service/api'

const ListTrackingStatusSources = async(pageSize, page, searchValue) =>{
	let path = 'super-admin/tracking-status-source/?page_size='+pageSize+'&page='+page
	if (searchValue) {
        path += `&search=${searchValue}`;
    }
	return api.get(path);
}

const AddTrackingStatusSource = async(data) =>{
	let path = 'super-admin/tracking-status-source/'
	return api.post(path,data);
}
const GetTrackingStatusSource = async(trackingStatusId) =>{
	let path = 'super-admin/tracking-status-source/' + trackingStatusId
	return api.get(path);
}

const UpdateTrackingStatusSource = async(trackingStatusId, data) =>{
	let path = 'super-admin/tracking-status-source/' + trackingStatusId
	return api.put(path,data);
}

const DeleteTrackingStatusSource = async(trackingStatusId) =>{
	let path = 'super-admin/tracking-status-source/' + trackingStatusId
	return api.destroy(path);
}

export default {ListTrackingStatusSources, AddTrackingStatusSource, GetTrackingStatusSource, UpdateTrackingStatusSource, DeleteTrackingStatusSource}