import i18n from "../../../utilities/i18n"

const TableHeadData = [
    {
        "name": i18n.t("dictionariesPages.payerClassList.lblPayerClass"),
        "type": "string",
        "width": "large",
        "sort": false,
    },
    {
        "name": i18n.t('dictionariesPages.payerClassList.isDefault'),
        "type": "string",
        "width": "small",
        "sort": false,
    },
    {
        "name": i18n.t("dictionariesPages.action"),
        "type": "button",
        "width": "action_column",
        "sort": false,
    },
]
const TableBodyData = [
    [
        {
            "id": "",
            "name": "name",
            "value": "",
            "type": "string",
            "align": "left",
            "width": "large",
        },
        {
            "id": "",
            "name": "isDefault",
            "value": "",
            "type": "string",
            "align": "center",
            "width": "small",
        },
        {
            "id": "",
            "name": "action",
            "value": "",
            "type": "button",
            "align": "center",
            "width": "action_column",
            "dropdown": [
                {
                    'name': 'Edit'
                },
                {
                    'name': 'Delete'
                },
            ]
        },
    ]
]

export const PayerClassTable = {
    "name": "simpleTable",
    "isResize": false,
    "isFixedHead": false,
    "tableHeadings": TableHeadData,
    "tableBodyData": TableBodyData
}
