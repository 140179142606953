import React, { } from 'react';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import "./style.css";
import Label from '../Label';

const PhoneInputWithCode = (props) => {
    const { value, name, label, onValueChange, disabled, required } = props;
    return (
        <div>
            <Label label={label} />
            <div className="form-group phone-control">
                <PhoneInput
                    value={value ? value : ""}
                    country={'us'}
                    preferredCountries={['us']}
                    inputProps={{
                        id: name ? name : "myPhoneinput"
                    }}
                    onChange={onValueChange}
                    inputClass={required ? (!value || value.length < 11 ? "input-error" : "") : (value && value.length > 1 && value.length < 11 ? "input-error" : "")}
                    disabled={disabled ? disabled : ""}
                />
            </div>
        </div>
    )
}
export default PhoneInputWithCode
