import React from 'react'

function SvgIconRender({ isExpanded }) {
    return (
        <>
            {
                isExpanded ?
                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                        <path d="M5.792 10.5423C5.64247 10.5423 5.51476 10.4908 5.40885 10.3877C5.30295 10.2847 5.25 10.157 5.25 10.0047C5.25 9.85243 5.30191 9.72339 5.40573 9.61763C5.50955 9.51187 5.63819 9.45898 5.79167 9.45898H14.208C14.3575 9.45898 14.4852 9.5105 14.5911 9.61353C14.697 9.71655 14.75 9.84422 14.75 9.99653C14.75 10.1488 14.698 10.2779 14.5942 10.3836C14.4904 10.4894 14.3618 10.5423 14.2083 10.5423H5.792Z" fill="black" />
                    </svg>
                    :
                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                        <path d="M9.45835 10.541H5.95835C5.80488 10.541 5.67624 10.4895 5.57242 10.3864C5.4686 10.2834 5.41669 10.1557 5.41669 10.0034C5.41669 9.85113 5.4686 9.72209 5.57242 9.61633C5.67624 9.51056 5.80488 9.45768 5.95835 9.45768H9.45835V5.95768C9.45835 5.80421 9.50987 5.67557 9.6129 5.57175C9.71592 5.46793 9.84359 5.41602 9.9959 5.41602C10.1482 5.41602 10.2772 5.46793 10.383 5.57175C10.4888 5.67557 10.5416 5.80421 10.5416 5.95768V9.45768H14.0416C14.1951 9.45768 14.3238 9.5092 14.4276 9.61222C14.5314 9.71525 14.5833 9.84292 14.5833 9.99522C14.5833 10.1475 14.5314 10.2766 14.4276 10.3823C14.3238 10.4881 14.1951 10.541 14.0416 10.541H10.5416V14.041C10.5416 14.1944 10.4901 14.3231 10.3871 14.4269C10.2841 14.5307 10.1564 14.5826 10.0041 14.5826C9.8518 14.5826 9.72276 14.5307 9.617 14.4269C9.51124 14.3231 9.45835 14.1944 9.45835 14.041V10.541Z" fill="black" />
                    </svg>
            }
        </>
    )
}

export default SvgIconRender