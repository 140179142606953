import React, { useContext, useRef, useState } from 'react';
import "./style.css";
import Notify from '../../commons/notify';
import service from './service';
import { getStorage } from '../../../utilities/browserStorage';
import CustomizedDialogs from '../../modalWindowComponent/CustomizedDialogs';
import { Form } from 'react-bootstrap';
import TextInput from '../../commons/input/input';
import i18n from '../../../utilities/i18n';
import LoadingContext from '../../../container/loadingContext';
import { ALLOWED_FILE_EXTENSIONS } from '../../../utilities/staticConfigs';

function FileUploader({ parentPK, type, setAPIResponseFlag, permission }) {
    const setShowLoadingBar = useContext(LoadingContext);
    const [fileData, setFileData] = useState({
        binaryFile: null,
        fileName: "",
    });
    const [isDragging, setIsDragging] = useState(false);
    const [isDropped, setIsDropped] = useState(false);
    const [showModalWindow, setShowModalWindow] = useState(false);
    const header = (i18n.t("fileManagement.AddDescription"));
    
    const [description, setDescription] = useState("");
    const fileInputRef = useRef(null);

    const hideUploadConfirmModal = (openStatus) => {
        setShowModalWindow(false);
        if (!openStatus) {
          resetState();
        }
      };

    // Start - Alert message properties
    const [showNotify, setShowNotify] = useState("hide");
    const [notifyDescription, setNotifyDescription] = useState("");
    const [notifyType, setNotifyType] = useState("success");

    function showNotifyWindow(action, type, desc, age = 3000) {
        if (action === "show") {
            setTimeout(() => {
                setShowNotify("hide");
            }, age);
        }
        setShowNotify(action);
        setNotifyType(type);
        setNotifyDescription(desc);
    }
    // End - Alert message properties

    const handleDropAreaClick = () => {
        if (!permission) {
            showNotifyWindow('show', 'error', i18n.t('errorMessages.permission_error'));
            return;
        }
        fileInputRef.current.click();
    };

    const handleDrop = (event) => {
        event.preventDefault();
        if (!permission) {
            showNotifyWindow('show', 'error', i18n.t('errorMessages.permission_error'));
            return;
        }
        setIsDragging(false);
        const droppedFiles = event?.dataTransfer?.files;

        if (droppedFiles.length > 1) {
            showNotifyWindow("show", "error", (i18n.t("fileManagement.DroppedMessage")));
            return;
        }

        const file = droppedFiles[0];
        setFileData({ binaryFile: file, fileName: file.name }); // Set the single File object and its name
        setIsDropped(true);
        setShowModalWindow(true);
    };

    const handleFileSelect = (event) => {
        const selectedFiles = event.target.files;

        if (selectedFiles.length > 1) {
            showNotifyWindow("show", "error", "Only one file can be selected");
            return;
        }

        const file = selectedFiles[0];
        setFileData({ binaryFile: file, fileName: file.name }); // Set the single File object and its name
        setIsDropped(true);
        setShowModalWindow(true);

        // Reset the input value to allow the same file to be selected again
        event.target.value = "";
    };

    const handleUpload = async () => {
        setShowLoadingBar(true);
        try {
            let data = new FormData();
            data.append("parent_type", type);
            data.append("practice", getStorage("practice"));
            data.append("parent_pk", parentPK);
            data.append("file", fileData.binaryFile);
            data.append("description", description);

            const response = await service.UploadFiles(data, type);
            if (response.status === 201) {
                showNotifyWindow(
                    "show",
                    "success",
                    "File uploaded successfully."
                );
                setAPIResponseFlag(true);
            } else if (response?.data?.message === "exceeds_file_uploads_size") {
                let max_file_upload_size = response?.data?.data?.max_file_upload_size
                showNotifyWindow("show", "error", `File size exceeds max allowed of  ${max_file_upload_size / (1024 * 1024)}Mb.`);
            } else {
                throw new Error("Error occurred while saving data.");
            }
        } catch (error) {
            console.error("File upload failed:", error);
            showNotifyWindow("show", "error", "File upload failed.");
        } finally {
            setShowLoadingBar(false);
            resetState();
        }
    };

    const resetState = () => {
        setFileData({
            binaryFile: null,
            fileName: "",
        });
        setDescription("");
        setIsDragging(false);
        setIsDropped(false);
        setShowModalWindow(false);
    };

    const handleDragOver = (event) => {
        event.preventDefault();
    };

    const handleDragEnter = () => {
        setIsDragging(true);
    };

    const handleDragLeave = () => {
        setIsDragging(false);
    };

    return (
        <>
            <Notify
                showNotify={showNotify}
                setShowNotify={setShowNotify}
                notifyDescription={notifyDescription}
                setNotifyType={setNotifyType}
                setNotifyDescription={setNotifyDescription}
                notifyType={notifyType}
            />
            <div
                onDrop={handleDrop}
                onDragOver={handleDragOver}
                onDragEnter={handleDragEnter}
                onDragLeave={handleDragLeave}
                onClick={handleDropAreaClick}
                style={{
                    cursor: "pointer",
                    margin: 30,
                    height: "80px",
                    border: "2px dashed #cccccc",
                    borderRadius: "4px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    backgroundColor: !permission ? "#d3d3d3" :
                        isDragging
                            ? "#deebff"
                            : isDropped
                                ? "#e3fcef"
                                : "#f5f6f8",
                }}>
                {fileData.fileName ? (
                    <ul className="file-list">
                        <li key={1}>{fileData.fileName}</li>
                    </ul>
                ) : (
                    <span
                        style={{
                            justifyContent: "center",
                            alignItems: "center",
                        }}>
                        <h5>Click to Select or Drop a File here</h5>
                    </span>
                )}
            </div>

            <input
                id={`${type}-file-uploader`}
                type="file"
                ref={fileInputRef}
                style={{ display: "none" }}
                onChange={handleFileSelect}
                accept={ALLOWED_FILE_EXTENSIONS.map(ext => `.${ext.toLowerCase()}`).join(',')}
            />

            <CustomizedDialogs
                showModal={showModalWindow}
                type="save"
                header={header}
                setShowModalWindow={hideUploadConfirmModal}>
                <Form
                    id="form_dataEntry"
                    autoComplete="off"
                    onSubmit={() => handleUpload()}
                    encType="multipart/form-data">
                    <Form.Group>
                        <div className="row">
                            <div className="col-md-12">
                                <div className="form-group padding-top15">
                                    <TextInput
                                        required={true}
                                        type="text"
                                        id={`${type}-file-description`}
                                        name="Description"
                                        value={description}
                                        onValueChange={(e) =>
                                            setDescription(e.target.value)
                                        }
                                        label={i18n.t(
                                            "fileManagement.Description"
                                        )}
                                    />
                                </div>
                            </div>
                        </div>
                    </Form.Group>
                </Form>
            </CustomizedDialogs>
        </>
    );
}

export default FileUploader;
