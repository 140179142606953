
export function getStorage(cname) {
    let storageObject = localStorage.getItem(cname);
    if(storageObject){
      return storageObject;
    }  
  return null;
}

export function setStorage(cname, cvalue) {
  localStorage.setItem(cname, cvalue);
}

