import i18n from "../../../utilities/i18n";


const RolesAndPermissionTableHeadData = [
//   {
//     "name": "id",
//     "type": "string",
//     "width": "small",
//     "sort": false
// },
{
    "name": i18n.t( "userPages.rolesAndPermissions.roles"),
    "type": "string",
    "width": "small",
    "sort": false
},
{
    "name": i18n.t("userPages.rolesAndPermissions.actions"),
    "type": "string",
    "width": "small",
    "sort": false
},
];

export const RolesAndPermissionTableBodyData = [
  [
    // {
    //   id: "",
    //   name: "id",
    //   value: "",
    //   type: "string",
    //   align: "left",
    //   width: "large",
    //   needLink: true,
    // },
    {
      id: "",
      name: "role_name",
      value: "",
      type: "string",
      align: "left",
      width: "large",
    },
    {
      id: "",
      name: "action",
      type: "button",
      align: "center",
      width: "action_column",
      dropdown: [
        { id: 1, name: "Edit" }
      ],
    },
  ],
];


export const ListRolesAndPermissionsTableData = {
  name: "simpleTable",
  isResize: true,
  isFixedHead: true,
  tableHeadings: RolesAndPermissionTableHeadData,
  tableBodyData: RolesAndPermissionTableBodyData,
};
