import api from "../../../service/api";

const searchPatientStatement = (praticeId, patientId) => {
  const path =
    "patient/patient-statement-detail/?patient_pk=" +
    patientId +
    "&practice_pk=" +
    praticeId;
    return api.get(path);
};

const setStatementConfig = (body) => {
  const path = `practice/statement-configuration/`;
  return api.postUpload(path, body);
};

const fetchStatementConfig = (patientId, practiceId,statementQuery) => {
  const path = `patient/patient-statement-preview/?patient_pk=${patientId}&practice_pk=${practiceId}${statementQuery}`;
  return api.get(path);
};

const getStatementConfig = (practicePK) => {
  const path = `/practice/statement-configuration/?practice_pk=${practicePK}`;
  return api.get(path);
};

const updateStatementConfig = (configId, body) => {
  const path = `/practice/statement-configuration-detail/${configId}`;
  return api.put(path, body);
};

const printPatientStatement = async (patientPKs, practicePK) => {
  let path = "patient/print-patient-statement/?patient_pks=" + patientPKs + '&practice_pk=' + practicePK;
  return api.get(path);
}

export default {
  searchPatientStatement,
  setStatementConfig,
  fetchStatementConfig,
  getStatementConfig,
  updateStatementConfig,
  printPatientStatement
};
