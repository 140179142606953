import React from "react";
import TextAreaInput from "../../../commons/textarea/textarea";

const disabledAtr = { disabled: "disabled" };

const MessageAndInfoCard = (props) => {
  const { onValueChange, formState, editable } = props;
  return (
    <div className="row mt-4">
      <div className="col-6">
        <TextAreaInput
          value={formState.message}
          onValueChange={(e) => onValueChange("message", e)}
          rows={7}
          label={"Important Mesage"}
          placeholder={"Important Message"}
          {...(editable ? {} : disabledAtr)}
        />
      </div>
      <div className="col-6">
        <div className="row">
          <div className="col-12">
            <TextAreaInput
              value={formState.contact}
              onValueChange={(e) => onValueChange("contact", e)}
              label={"Contact Info"}
              placeholder={"Contact Info"}
              {...(editable ? {} : disabledAtr)}
            />
          </div>
        </div>
        <div className="row mt-2">
          <div className="col-12">
            <TextAreaInput
              value={formState.payment}
              onValueChange={(e) => onValueChange("payment", e)}
              label={"Payment & Other Info"}
              placeholder={"Payment & Other Info"}
              {...(editable ? {} : disabledAtr)}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default MessageAndInfoCard;
