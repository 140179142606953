import format from "date-fns/format";
import {
    CLAIM_INS_VALIDATION_PRIMARY_IDS, CLAIM_INS_VALIDATION_SECONDARY_IDS,
    CLAIM_INS_VALIDATION_TERTIARY_IDS, CLAIM_POS_IDS_VALIDATION,
    INSURANCE_PRIORITY_PRIMARY_VALUE, INSURANCE_PRIORITY_SECONDARY_VALUE,
    INSURANCE_PRIORITY_TERTIARY_VALUE
} from "../../../utilities/staticConfigs";
import i18n from "../../../utilities/i18n";
import { ADD_ERROR } from "../../../utilities/labelConfigs";
import { validateIsValidDxPointer } from "./claimDataGenerator";
//checking for duplicate code
export const findDuplicates=(array)=>{
    let duplicates = array.filter(
        (item, index) => array.indexOf(item) !== index
    );
    return duplicates;
}

/**check for dos missing or not in each procedure */
export const checkCptsAndDosExistsInProcedure = (procedureRows, claimPK) => {
    let errors = [];
    if (procedureRows.length === 0 && !claimPK) {
        errors.push(1);
        return { errors };
    }

    procedureRows.forEach((procedure, index) => {
        if (!(procedure.dos_from && procedure.dos_to && procedure.cpt_hcpcs)) {
            errors.push(index + 1);
        }
    });

    return { errors };
}


/**
 * claim validation done here on save
 * @param {*} claimManagement 
 * @param {*} claimSelectInputManager 
 * @returns 
 */
export const claimValidation = (claimManagement, claimSelectInputManager) => {
    let {
        professionalClaimDetails,
        serviceLineDetails,
        billingInfoDetails,
        additionalClaimDetails,
        diagnosisesValidated,
        ModifiersValidated,
        checkForDuplicatediagnosis,
    } = claimManagement;

    let { AccidentNoId } = claimSelectInputManager;
    const currentDate = new Date();
    let isError = false;
    let errorMessage = "";
    let status = "";

    if (!professionalClaimDetails?.patient) {
        isError = true;
        errorMessage = "Please select a patient to continue.";
        status = "error";
        return { isError: isError, errorMessage: errorMessage, status: status };
    }

    // Validate if cpt & does from & to are exists in every procedure row
    const isCptAndDosExistsInEveryRows = checkCptsAndDosExistsInProcedure(serviceLineDetails?.procedures);
    if (isCptAndDosExistsInEveryRows?.errors?.length) {
        const errorjoins = isCptAndDosExistsInEveryRows.errors.join(', ');
        isError = true;
        errorMessage = `Either DOS or CPTs are missing in the procedure rows ${errorjoins}`;
        status = "error";
        return { isError: isError, errorMessage: errorMessage, status: status };
    }

    if (professionalClaimDetails?.service_from_date > format(currentDate, 'yyyy-MM-dd')) {
        isError = true;
        errorMessage = "The service start date cannot be in the future.";
        status = "error"
        return { isError: isError, errorMessage: errorMessage, status: status }
    }

    if (professionalClaimDetails?.posted_date) {
        const currentDate = format(new Date(), 'yyyy-MM-dd');
        const dateOfDeath = billingInfoDetails?.date_of_death;
        const patientDOB = professionalClaimDetails?.patient_dob;

        const hasInvalidDate = serviceLineDetails?.procedures?.some((pRowItem) => {
            const { dos_from, dos_to } = pRowItem;
            return (
                dos_from > currentDate ||
                dos_to > currentDate ||
                (dateOfDeath && (dos_from > dateOfDeath || dos_to > dateOfDeath)) ||
                (patientDOB && (dos_from < patientDOB || dos_to < patientDOB))
            );
        });

        if (hasInvalidDate) {
            isError = true;
            errorMessage = i18n.t("errorMessages.dodError");
            status = "error";
            return { isError: isError, errorMessage: errorMessage, status: status }
        }
    }

    let tmpAdnInfoHospDate = additionalClaimDetails?.hospitalization_from_date ? additionalClaimDetails?.hospitalization_from_date : "";
    // Hospitalization Date should be prior than or = DOS When POS code is 21/51/61
    if (tmpAdnInfoHospDate && Array.isArray(serviceLineDetails?.procedures)) {
        serviceLineDetails?.procedures?.forEach((pRowItem) => {
            if (pRowItem.dos_from && CLAIM_POS_IDS_VALIDATION.includes(pRowItem.pos_id) && new Date(tmpAdnInfoHospDate) > new Date(pRowItem.dos_from)) {
                isError = true;
                errorMessage = i18n.t("errorMessages.claim_pos_date_error");
                status = "error";
                return { isError: isError, errorMessage: errorMessage, status: status };
            }
        });
    }

    let arrPayerInfo = billingInfoDetails?.payer_info;

    // CLAIM STATUS MATCH VALUES IN CLAIM_INS_VALIDATION_PRIMARY_IDS ARRAY, THEN PRIMARY INSURANCE DATA MANDATORY 
    if (professionalClaimDetails?.claim_status && CLAIM_INS_VALIDATION_PRIMARY_IDS.includes(professionalClaimDetails?.claim_status)) {
        if (!arrPayerInfo || !arrPayerInfo.length || !arrPayerInfo.find(element => (element.policy_id.trim() && parseInt(element.priority, 10) == parseInt(INSURANCE_PRIORITY_PRIMARY_VALUE, 10)))) {
            isError = true;
            errorMessage = i18n.t("errorMessages.primary_insurance_validation");
            status = "error";
            return { isError: isError, errorMessage: errorMessage, status: status };
        }
    }

    if (professionalClaimDetails?.claim_status && CLAIM_INS_VALIDATION_SECONDARY_IDS.includes(professionalClaimDetails?.claim_status)) {
        // CLAIM STATUS MATCH VALUES IN CLAIM_INS_VALIDATION_SECONDARY_IDS ARRAY, THEN PRIMARY AND SECONDARY INSURANCES DATA MANDATORY 
        if (!arrPayerInfo || !arrPayerInfo.length
            || !arrPayerInfo.find(element => (element.policy_id.trim() && parseInt(element.priority, 10) == parseInt(INSURANCE_PRIORITY_PRIMARY_VALUE, 10)))
            || !arrPayerInfo.find(element => (element.policy_id.trim() && parseInt(element.priority, 10) == parseInt(INSURANCE_PRIORITY_SECONDARY_VALUE, 10)))) {
            isError = true;
            errorMessage = i18n.t("errorMessages.secondary_insurance_validation");
            status = "error";
            return { isError: isError, errorMessage: errorMessage, status: status };
        }
    }

    if (professionalClaimDetails?.claim_status && CLAIM_INS_VALIDATION_TERTIARY_IDS.includes(professionalClaimDetails?.claim_status)) {
        // CLAIM STATUS MATCH VALUES IN CLAIM_INS_VALIDATION_TERTIARY_IDS ARRAY, THEN PRIMARY, SECONDARY AND TERTIARY INSURANCES  DATA ARE MANDATORY
        if (!arrPayerInfo || !arrPayerInfo.length
            || !arrPayerInfo.find(element => (element.policy_id.trim() && parseInt(element.priority, 10) == parseInt(INSURANCE_PRIORITY_PRIMARY_VALUE, 10)))
            || !arrPayerInfo.find(element => (element.policy_id.trim() && parseInt(element.priority, 10) == parseInt(INSURANCE_PRIORITY_SECONDARY_VALUE, 10)))
            || !arrPayerInfo.find(element => (element.policy_id.trim() && parseInt(element.priority, 10) == parseInt(INSURANCE_PRIORITY_TERTIARY_VALUE, 10)))) {
            isError = true;
            errorMessage = i18n.t("errorMessages.tertiary_insurance_validation");
            status = "error";
            return { isError: isError, errorMessage: errorMessage, status: status };
        }
    }

    //checkForDuplicate
    if (checkForDuplicatediagnosis) {
        isError = true;
        errorMessage = i18n.t("errorMessages.duplicateDiagnosis");
        status = "error";
        return { isError: isError, errorMessage: errorMessage, status: status };
    }
    if (!diagnosisesValidated) {
        // IF DIAGNOSIS CODES NOT ADDED IN ORDER, IT WILL NOT ALLOW TO SAVE
        // e.g. IF diagnosis1 FILLED, diagnosis2 BLANK, AND ANY diagnosis3 TO diagnosis12 FILLED, IT WILL GIVE ERROR 
        isError = true;
        errorMessage = i18n.t("errorMessages.invalid_diagonsis_order");
        status = "error";
        return { isError: isError, errorMessage: errorMessage, status: status };
    }

    if (ModifiersValidated && ModifiersValidated.includes(false)) {
        // IF MODIFIERS NOT ADDED IN ORDER, IT WILL NOT ALLOW TO SAVE
        // e.g. IF M1 FILLED, M2 BLANK, AND M3 OR M4 FILLED, IT WILL GIVE ERROR 
        isError = true;
        errorMessage = i18n.t("errorMessages.invalid_modifiers");
        status = "error";
        return { isError: isError, errorMessage: errorMessage, status: status };
    }

    if (professionalClaimDetails?.authorization_referral && !professionalClaimDetails?.identifier) {
        // IF AUTHORIZATION/REFERAL SELECTED BUT IDENTIFIER IS BLANK, IT WILL NOT ALLOW TO SAVE
        // showNotifyWindow("show", "error", i18n.t("errorMessages.invalid_modifiers"));
        isError = true;
        errorMessage = "Authorization or Referral is selected, but the Identification number is missing.";
        status = "error";
        return { isError: isError, errorMessage: errorMessage, status: status };
    }
    // if accident other than no-related to accident then accident date is mandatory
    if (additionalClaimDetails?.accident && additionalClaimDetails?.accident != AccidentNoId) {
        let accidentDate = additionalClaimDetails.accident_date;
        if (!accidentDate || accidentDate > format(new Date(), "yyyy-mm-dd")) {
            isError = true;
            errorMessage = "Either the accident date in additional claim info is missing or an invalid date is provided.";
            status = "error";
            return { isError: isError, errorMessage: errorMessage, status: status };
        }
        if (!additionalClaimDetails.state) {
            isError = true;
            errorMessage = "Accident state is required when the accident type is anything other than 'Not related to an accident'.";
            status = "error";
            return { isError: isError, errorMessage: errorMessage, status: status };
        }
    }

    if (professionalClaimDetails?.claim_status == "") {
        isError = true;
        errorMessage = "Please select a claim status to continue.";
        status = "error";
        return { isError: isError, errorMessage: errorMessage, status: status };
    }

    let invalidCptCodes = []; // Array to collect all invalid CPT codes
    serviceLineDetails?.procedures?.forEach((procedureRowItem) => {
        if (!procedureRowItem.dx_pointers) {
            isError = true
            errorMessage = "Please select Dx Pointer to continue."
            status = "error";
            return { isError: isError, errorMessage: errorMessage, status: status }
        }
        else if (!validateIsValidDxPointer(serviceLineDetails, procedureRowItem.dx_pointers)) {

            let procedureName = procedureRowItem?.cpt_hcpcsList[0]?.name || "Unknown Procedure";
            invalidCptCodes.push(procedureName); //pushing the invalid cpts to invalidCptCodes array
            isError = true;
        }
    })

    if (invalidCptCodes.length > 0) {
        errorMessage = `Invalid Dx Pointers at Procedures: ${invalidCptCodes.join(", ")}` ?? "Invalid Dx Pointers detected at Procedure row.";
        status = "error";
    }

    serviceLineDetails?.procedures?.forEach((procedureRowItem) => {
        if (!procedureRowItem.claim_status) {
            isError = true
            errorMessage = "Please select claim status for procedure to continue."
            status = "error";
            return { isError: isError, errorMessage: errorMessage, status: status }
        }
    })


    return { isError: isError, errorMessage: errorMessage, status: status }
};

/**
 * save or edit api call error handling
 * @param {*} response 
 */
export const APIErrorHandler = (response) => {
    let errorMessage = "";
    let inActiveModal = false;
    if (response.status === 405) {
        if (response.data.message === 'claim_inactive') {
            errorMessage = i18n.t("errorMessages.claim_inactive")
        } else if (response.data.message === 'claim_locked')
            errorMessage=i18n.t("errorMessages.claim_locked");
    } else if (response.status == 400) {
        if (response.data.duplicate_policy_id)
            errorMessage=i18n.t("errorMessages.duplicate_policy_id");
        if (response.data.duplicate_insurance)
            errorMessage=i18n.t("errorMessages.duplicate_insurance");
        if (response.data.message === 'duplicate_modifier')
            errorMessage=i18n.t("errorMessages.duplicate_modifier");
        if (response.data.message === 'unique_procedure')
            errorMessage=i18n.t("errorMessages.unique_procedure");
        if (response.data.message === 'service_location_not_found')
            errorMessage=i18n.t("errorMessages.service_location_error");
    } else {
        errorMessage=ADD_ERROR;
    }

    return { errorMessage: errorMessage, inActiveModal: inActiveModal };
}