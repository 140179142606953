import './style.css'
import { React } from 'react'
import { IconButton, Tooltip } from '@material-ui/core'
import StickyNote2OutlinedIcon from '@mui/icons-material/StickyNote2Outlined';
import ReceiptLongOutlinedIcon from '@mui/icons-material/ReceiptLongOutlined';
import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined';
import HowToRegOutlinedIcon from '@mui/icons-material/HowToRegOutlined';
import MoreVertIcon from '@mui/icons-material/MoreVert';
const IconButtons = (props) => {
    const { icon, onClick, ToolTip } = props;

    const getIcons = (icon) => {
        switch (icon) {
            case 'note':
                return <StickyNote2OutlinedIcon />
            case 'bill':
                return <ReceiptLongOutlinedIcon />
            case 'plus':
                return <AddCircleOutlineOutlinedIcon />
            case 'person':
                return <HowToRegOutlinedIcon />
            case 'three-dot':
                return <MoreVertIcon />
        }
    }

    return (
        <Tooltip arrow title={ToolTip} placement="bottom">
            <IconButton className='Icon_div' onClick={onClick}>
                {getIcons(icon)}
            </IconButton>
        </Tooltip>
    )
}

export default IconButtons;