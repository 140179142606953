import React from 'react'
import { currencyFormat } from '../../../../utilities/commonUtilities'
import moment from 'moment'

function AccountAndPaymentDetails(
    {
        statementDate,
        payAmount,
        account,
        dueDate,
        patientDetails,
        paidAmount
    }) {
    return (
        <>
            <table style={{ marginTop: '12px', marginBottom: '5px', fontSize: '14px' }}>
                <thead style={{ height: '50px !important' }}>
                    <th style={{ padding: '5px', height: '40px' }}>
                        Date
                    </th>
                    <th>
                        Responsible Party
                    </th>
                    <th>
                        Account#
                    </th>
                    <th>
                        Paid Amount
                    </th>
                    <th>
                        Amount Payable
                    </th>
                    <th>
                        Due Date
                    </th>
                </thead>
                <tbody >
                    <tr align='center'>
                        <td>
                            {moment(statementDate).format('MM/DD/YYYY')}
                        </td>
                        <td>
                            <div className="d-flex flex-column align-items-center">
                                <p style={{ marginBottom: '5px' }}>{patientDetails?.name ? patientDetails.name : "Demo Patient --001"}</p>
                                <p style={{ marginBottom: '0' }}>{patientDetails?.location?.location ? patientDetails.location.location : "Demo Address line, Suite 206, Moraga, CA 94556, USA"}</p>
                            </div>
                        </td>

                        <td>
                            {account ? account : "002318382"}
                        </td>
                        <td>
                            {currencyFormat(paidAmount)}
                        </td>
                        <td>
                            <div style={{ padding: '5px', fontWeight: 'bold', borderRadius: '8px', background: '#FFF2CA', display: 'inline-block' }}>
                                {payAmount ? currencyFormat(payAmount) : "$ 0.00"}
                            </div>
                        </td>
                        <td>
                            {dueDate ? moment(dueDate).format('MM/DD/YYYY') : "03/12/2024"}
                        </td>
                    </tr>
                </tbody>
            </table>
        </>
    )
}

export default AccountAndPaymentDetails