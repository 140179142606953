import axios from "axios"
import { CHAT_BOT_URL } from "../../utilities/urlConfigs";

export const replayMessage = (payload) => {
    const headers = {
        'Content-Type': 'application/json',
    };
    return axios.post(CHAT_BOT_URL, payload, { headers }).then(response => {
        return response
    }).catch(error => {
       throw new(error) 
    })
    
}