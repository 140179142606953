import i18n from 'i18n-js';

/* Import the translation json */
import en from './locales/en.json';

/* Default to English (en) */
i18n.defaultLocale = 'en';

/* If the secondary display language is missing some translations,
we are going to display (fallback) the ones from the default language */
i18n.fallbacks = true;

/* List of available locales; Only english for now */
i18n.translations = { en };

export default i18n;


