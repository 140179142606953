import React, { useContext, useEffect, useState } from 'react';
import LoadingContext from '../../container/loadingContext';
import { getStorage } from '../../utilities/browserStorage';
import * as staticConfigs from '../../utilities/staticConfigs';
import service from './service';
import i18n from '../../utilities/i18n';
import CalendarInput from '../commons/input/CalendarInput';
import SelectInput from '../commons/input/SelectInput';
import TextInput from '../commons/input/input';
import MultiSearch from '../../MaterialMultiselect/MultiSearch';
import { filterDateValues } from '../../utilities/dateFilterCalculation';
import RightSidePanelWithWidth from '../commons/RightSidePanelWithWidth/RightSidePanelWithWidth';
import CommonButton from '../commons/Buttons';

function SearchMessage (props){
    const setShowLoadingBar = useContext(LoadingContext);
    const practicePK = getStorage('practice');

    const [optionItemsTwo, setOptionItemsTwo] = useState([]);
    const [optionValuesTwo, setOptionValuesTwo] = useState([]);
    const [dateOne, setDateOne] = useState('');
    const [dateTwo, setDateTwo] = useState('');
    const [subject, setSubject] = useState('');
    const [body, setBody] = useState(''); 

    const [dateOfServiceRange, setDateOfServiceRange] = useState(1);
    const [userList, setUserList] = useState([]);
    const [optionValues, setOptionValues] = useState([]);
    const [showPanel, setShowPanel] = useState(false);

    useEffect(() => {
        getUserList();
    }, []);

    function getUserList() {
        setShowLoadingBar(true);
        let result = service.ListPracticeUsers(practicePK);
        result.then((response) => {
            let data = response.data;
            setUserList(data);
            setShowLoadingBar(false);
        });
    }


    function onHandleChange(e) {
        let name = e.target.name;
        let value = e.target.value;
        if (name === 'subject') setSubject(value);
        else if (name === 'body') setBody(value);
    }

    function searchSendMessages() {
        setShowLoadingBar(true);
        if (props.type === 'sent'){  
           let result = service.SearchSendMessage(practicePK, optionValuesTwo,subject,body,dateOfServiceRange,dateOne,dateTwo);
            result.then((response)=>{
                props.setValue(response.data.results);
                setShowLoadingBar(false);
            })
            .catch((err)=>console.error(err))
            .finally(()=>setShowLoadingBar(false));
        }
        else if (props.type === 'inbox'){
          let result = service.SearchInboxMessage(practicePK,optionValues,optionValuesTwo,subject,body,dateOfServiceRange,dateOne,dateTwo);
            result.then((response) => {
                props.setValue(response.data);
            })
            .catch((err)=>{
                console.error(err);
            })
            .finally(()=>{
                setShowLoadingBar(false);
            });
        }
        
    }

    function clearSendMessages() {
        let page=1;
        props.onClear(staticConfigs.DEFAULT_PAGING_SIZE, page);
        setOptionValues([]);
        setOptionValuesTwo([]);
        setSubject('');
        setBody('');
        setDateOfServiceRange(1);
        setDateOne('');
        setDateTwo('');
    }

    
    function onHandleDateType(selValue,dateType) {
        if(dateType=='dateType') {
            setDateOfServiceRange(selValue.target.value); 
            let dateObj = filterDateValues(selValue.target.value);
            setDateOne(dateObj.fromDate); 
            setDateTwo(dateObj.toDate);
        }
    }

    function onSearchContactTo(query) {
        if (query){
            let result = service.SearchPracticeUsers(practicePK,query);
            result.then((response)=>{
                setOptionItemsTwo(response.data);
            })
        }
    }
    const onHandleDob1Change = (selected)=> {
        setDateOne(selected)
    }
    const onHandleDob2Change = (selected)=> {
        setDateTwo(selected)
    }

    return(
        <>
            <RightSidePanelWithWidth showPanel={showPanel} setShowPanel={setShowPanel} width='600px' clickLabel={
                <CommonButton variant="contained"
                    label={i18n.t("inboxScreens.inboxScreen.search")}
                    icon="search"
                />}
                    title={i18n.t("inboxScreens.searchMessage")}
                    >
                        <div className="mb-3 mt-3">
                            <MultiSearch id='rightSideAutoComplete' onSearchApi={onSearchContactTo}
                            optionItems={optionItemsTwo} setOptionItems={setOptionItemsTwo} 
                            setOptionValues={setOptionValuesTwo} dataList={userList}
                            label={i18n.t("inboxScreens.inboxScreen.to")}
                            />
                        </div>
                        <div className="row">
                            <div className="col">
                        <TextInput type="text" id="subject" name="subject" value={subject} onValueChange={onHandleChange} label={i18n.t("inboxScreens.inboxScreen.subject")} />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col">
                        <TextInput type="text" id="body" name="body" value={body} onValueChange={onHandleChange} label={i18n.t("inboxScreens.inboxScreen.messageContains")} />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-4  padding-top20">
                                <SelectInput data={staticConfigs.CLAIMDATEOFSERVICERANGE} name='dateType' 
                                value={dateOfServiceRange} onValueChange={(e) => onHandleDateType(e,'dateType')}
                                className={"date-filter-select"} selectOptionRemove={true} />
                            </div>
                            <div className="col-4  padding-top20">
                                <CalendarInput
                                    name="dateFrom" 
                                    selected={dateOne}
                                    maxDate={dateTwo}
                                    onValueChange={onHandleDob1Change} 
                                    placeholder={i18n.t("inboxScreens.inboxScreen.from")} 
                                    noLabel={true} 
                                />
                            </div>
                            <div className="col-4  padding-top20 pl-0">
                                <CalendarInput 
                                    name="dateTo" 
                                    selected={dateTwo} 
                                    minDate={dateOne}
                                    onValueChange={onHandleDob2Change} 
                                    placeholder={i18n.t("inboxScreens.inboxScreen.to")} 
                                    noLabel={true} 
                                />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col justify-right">
                        <button type="button" onClick={clearSendMessages} id="clear-value" data-testid="clear-value" className="btn btn-secondary mr-2 px-4 btn-common-height35 margin-top1">{i18n.t("inboxScreens.inboxScreen.clear")}</button>
                        <button type="button" onClick={searchSendMessages} id="search-value" data-testid="search-value" className="btn btn-primary-blue btn-common-height35">{i18n.t("inboxScreens.inboxScreen.search")}</button>
                            </div>
                        </div>
            </RightSidePanelWithWidth>
        </>
    )
}

export default SearchMessage;