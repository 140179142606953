import api from '../../../service/api'

const GetListPaymentAdjustmentType = async(pageSize,page, searchValue, orderingField, orderType) =>{
	let path = 'super-admin/payment-adjustment-type/?page_size='+pageSize+'&page='+page;
    if (searchValue)
        path += '&search='+ searchValue;
    if(orderingField){
        let fieldOrder = '';
        if (orderType) fieldOrder+= orderType;
        fieldOrder += orderingField;
        path += '&column='+fieldOrder;   
    } else {
        path += '&column=name';
    }
	return api.get(path);
}

const AddPaymentAdjustmentType = async(data) =>{
	let path = 'super-admin/payment-adjustment-type/'
	return api.post(path,data);
}
const GetPaymentAdjustmentType= async(id) =>{
	let path = 'super-admin/payment-adjustment-type/'+id
	return api.get(path);
}
const DeletePaymentAdjustmentType= async(id) =>{
	let path = 'super-admin/payment-adjustment-type/'+id
	return api.destroy(path);
}

const UpdatePaymentAdjustmentType= async(id,data) =>{
	let path = 'super-admin/payment-adjustment-type/'+id
	return api.put(path,data);
}

const ImportCSVData = async(data) =>{
	let path = 'super-admin/import-payment-adjustment-type/'
	return api.post(path,data);
}


export default {GetListPaymentAdjustmentType,AddPaymentAdjustmentType,GetPaymentAdjustmentType,DeletePaymentAdjustmentType,UpdatePaymentAdjustmentType,ImportCSVData}