import i18n from "../../../utilities/i18n";


const TableHeadData = [
    {
        "name": i18n.t("reports.visit_id"),
        "type": "number",
        "width": "extra-small",
        "sort": false,
        "hideValue": true,
    },
    {
        "name": i18n.t("reports.claim_id"),
        "type": "number",
        "width": "extra-small",
        "sort": true,
        "initialOrdering": true,
        "hideValue": false,
    },
    {
        "name": i18n.t("reports.accession_number"),
        "type": "string",
        "width": "extra-small",
        "sort": false,
        "hideValue": false,
    },
    {
        "name": i18n.t("reports.patient_id"),
        "type": "number",
        "width": "extra-small",
        "sort": false,
        "hideValue": false,
    },
    {
        "name": i18n.t("reports.firstName"),
        "type": "string",
        "width": "small",
        "sort": false,
        "hideValue": false,
    },
    {
        "name": i18n.t("reports.lastName"),
        "type": "string",
        "width": "small",
        "sort": false,
        "initialOrdering": true,
        "hideValue": false,
    },
    {
        "name": i18n.t("reports.middleName"),
        "type": "string",
        "width": "small",
        "sort": false,
        "initialOrdering": true,
        "hideValue": true,
    },
    {
        "name": i18n.t("reports.gender"),
        "type": "string",
        "width": "small",
        "sort": false,
        "hideValue": true,
    },
    {
        "name": i18n.t("reports.DOB"),
        "type": "date",
        "width": "small",
        "sort": false,
        "initialOrdering": true,
        "hideValue": true,
    },
    {
        "name": i18n.t("reports.dateOfEntry"),
        "type": "Date",
        "width": "small",
        "sort": true,
        "initialOrdering": true,
        "hideValue": true,
    },
    {
        "name": i18n.t("reports.firstBilledDate"),
        "type": "Date",
        "width": "small",
        "sort": true,
        "initialOrdering": true,
        "hideValue": true,
    },
    {
        "name": i18n.t("reports.lastBilledDate"),
        "type": "Date",
        "width": "small",
        "initialOrdering": true,
        "sort": true,
        "hideValue": true,
    },
    {
        "name": i18n.t("reports.dateOfServiceFrom"),
        "type": "Date",
        "width": "medium",
        "sort": true,
        "initialOrdering": true,
        "hideValue": true,
    },
    {
        "name": i18n.t("reports.dateOfServiceTo"),
        "type": "Date",
        "width": "medium",
        "sort": true,
        "initialOrdering": true,
        "hideValue": true,
    },
    {
        "name": i18n.t("reports.refferingProvider"),
        "type": "string",
        "width": "small",
        "sort": false,
        "hideValue": true,
    },
    {
        "name": i18n.t("reports.refferingProviderNPI"),
        "type": "string",
        "width": "small",
        "sort": false,
        "hideValue": true,
    },
    {
        "name": i18n.t("reports.renderingProvider"),
        "type": "string",
        "width": "small",
        "sort": false,
        "hideValue": true,
    },
    {
        "name": i18n.t("reports.renderingProviderNPI"),
        "type": "string",
        "width": "small",
        "sort": false,
        "hideValue": true,
    },
    {
        "name": i18n.t("reports.billingProvider"),
        "type": "string",
        "width": "small",
        "sort": false,
        "hideValue": true,
    },
    {
        "name": i18n.t("reports.billingProviderNPI"),
        "type": "string",
        "width": "small",
        "sort": false,
        "hideValue": true,
    },
    {
        "name": i18n.t("reports.cptcode"),
        "type": "number",
        "width": "small",
        "sort": false,
        "hideValue": true,
    },
    {
        "name": i18n.t("reports.units"),
        "type": "number",
        "width": "small",
        "sort": false,
        "hideValue": true,
    },
    {
        "name": i18n.t("reports.modifier1"),
        "type": "string",
        "width": "small",
        "sort": false,
        "hideValue": true,
    },
    {
        "name": i18n.t("reports.modifier2"),
        "type": "string",
        "width": "small",
        "sort": false,
        "hideValue": true,
    },
    {
        "name": i18n.t("reports.modifier3"),
        "type": "string",
        "width": "small",
        "sort": false,
        "hideValue": true,
    },
    {
        "name": i18n.t("reports.modifier4"),
        "type": "string",
        "width": "small",
        "sort": false,
        "hideValue": true,
    },
    {
        "name": i18n.t("reports.ICD_list"),
        "type": "string",
        "width": "small",
        "sort": false,
        "hideValue": true,
    },
    {
        "name": i18n.t("reports.billed_to_payer_name"),
        "type": "string",
        "width": "small",
        "sort": false,
        "hideValue": true,
    },
    {
        "name": i18n.t("reports.billed_to_payer_id"),
        "type": "number",
        "width": "small",
        "sort": false,
        "hideValue": true,
    },
    {
        "name": i18n.t("reports.billed_to_policy_id"),
        "type": "number",
        "width": "small",
        "sort": false,
        "hideValue": true,
    },
    {
        "name": i18n.t("reports.billed_to_group_no"),
        "type": "number",
        "width": "small",
        "sort": false,
        "hideValue": true,
    },

    {
        "name": i18n.t("reports.current_payer_name"),
        "type": "string",
        "width": "small",
        "sort": false,
        "hideValue": true,
    },
    {
        "name": i18n.t("reports.current_payer_id"),
        "type": "number",
        "width": "small",
        "sort": false,
        "hideValue": true,
    },
    {
        "name": i18n.t("reports.current_payer_policy_id"),
        "type": "number",
        "width": "small",
        "sort": false,
        "hideValue": true,
    },
    {
        "name": i18n.t("reports.current_group_no"),
        "type": "number",
        "width": "small",
        "sort": false,
        "hideValue": true,
    },
    {
        "name": i18n.t("reports.primary_payer_name"),
        "type": "string",
        "width": "small",
        "sort": false,
        "hideValue": true,
    },
    {
        "name": i18n.t("reports.primary_payer_id"),
        "type": "number",
        "width": "small",
        "sort": false,
        "hideValue": true,
    },
    {
        "name": i18n.t("reports.primary_policy_id"),
        "type": "number",
        "width": "small",
        "sort": false,
        "hideValue": true,
    },
    {
        "name": i18n.t("reports.primary_group_no"),
        "type": "number",
        "width": "small",
        "sort": false,
        "hideValue": true,
    },
    {
        "name": i18n.t("reports.secondary_payer_name"),
        "type": "string",
        "width": "small",
        "sort": false,
        "hideValue": true,
    },
    {
        "name": i18n.t("reports.secondary_payer_id"),
        "type": "number",
        "width": "small",
        "sort": false,
        "hideValue": true,
    },
    {
        "name": i18n.t("reports.secondary_policy_id"),
        "type": "number",
        "width": "small",
        "sort": false,
        "hideValue": true,
    },
    {
        "name": i18n.t("reports.secondary_group_no"),
        "type": "number",
        "width": "small",
        "sort": false,
        "hideValue": true,
    },
    {
        "name": i18n.t("reports.entered_by"),
        "type": "string",
        "width": "small",
        "sort": false,
        "hideValue": true,
    },
    {
        "name": i18n.t("reports.serviceLocation"),
        "type": "string",
        "width": "small",
        "sort": false,
        "hideValue": true,
    },
    // Payment Report Specific Headers
    {
        "name": "Check Number",
        "type": "text",
        "width": "small",
        "sort": true,
        "initialOrdering": true,
        "hideValue": false,
    },
    {
        "name": "Check Date",
        "type": "date",
        "width": "small",
        "sort": true,
        "initialOrdering": true,
        "hideValue": false,
    },
    {
        "name": "CPT Paid Amount",
        "type": "number",
        "width": "small",
        "sort": false,
        "hideValue": false,
    },
    {
        "name": "CPT Adj Amount",
        "type": "number",
        "width": "small",
        "sort": false,
        "hideValue": true,
    },
    {
        "name": "Paid Payer Name",
        "type": "text",
        "width": "small",
        "sort": false,
        "hideValue": false,
    },
    {
        "name": "Paid Payer ID",
        "type": "text",
        "width": "small",
        "sort": false,
        "hideValue": true,
    },
    {
        "name": "Paid Policy ID",
        "type": "text",
        "width": "small",
        "sort": false,
        "hideValue": true,
    },
    {
        "name": "Paid Payer Group",
        "type": "text",
        "width": "small",
        "sort": false,
        "hideValue": true,
    },
    {
        "name": "EOB Payer Name",
        "type": "text",
        "width": "small",
        "sort": false,
        "hideValue": true,
    },
    {
        "name": "EOB Payer Id",
        "type": "text",
        "width": "small",
        "sort": false,
        "hideValue": true,
    },
    {
        "name": i18n.t("reports.claimStatus"),
        "type": "text",
        "width": "small",
        "sort": false,
        "hideValue": true,
    },
    {
        "name": i18n.t("reports.claimSubStatus"),
        "type": "text",
        "width": "small",
        "sort": false,
        "hideValue": true,
    },
    {
        "name": i18n.t("reports.lab_sales_report"),
        "type": "text",
        "width": "small",
        "sort": false,
        "hideValue": true,
    },
    {
        "name": i18n.t("reports.clinic_site_name"),
        "type": "text",
        "width": "small",
        "sort": false,
        "hideValue": true,
    },
    {
        "name": i18n.t("reports.claim_service_type"),
        "type": "text",
        "width": "small",
        "sort": false,
        "hideValue": true,
    },
    {
        "name": i18n.t("reports.pos"),
        "type": "text",
        "width": "medium",
        "sort": false,
        "hideValue": true,
    },
    {
        "name": i18n.t("reports.tos"),
        "type": "text",
        "width": "medium",
        "sort": false,
        "hideValue": true,
    },
];

export const TableBodyData = [
    [
        {
            "id": "",
            "name": "reference_number",
            "value": "",
            "type": "string",
            "needLink": true,
            "align": "center",
            "width": "extra-small",
            "hideValue": true,
        },
        {
            "id": "",
            "name": "custom_claim_id",
            "value": "",
            "type": "string",
            "align": "center",
            "width": "extra-small",
            "needLink": true,
            "hideValue": false,
        },
        {
            "id": "",
            "name": "accession_number",
            "value": "",
            "type": "string",
            "align": "center",
            "width": "extra-small",
            "needLink": false,
            "hideValue": false,
        },
        {
            "id": "",
            "name": "custom_patient_id",
            "value": "",
            "type": "string",
            "align": "center",
            "width": "extra-small",
            "needLink": true,
            "hideValue": false,
        },
        {
            "id": "",
            "name": "patient_first_name",
            "value": "",
            "type": "string",
            "align": "center",
            "width": "small",
            "needLink": true,
            "hideValue": false,
        },
        {
            "id": "",
            "name": "patient_last_name",
            "value": "",
            "type": "string",
            "align": "center",
            "width": "small",
            "needLink": true,
            "hideValue": false,
        },
        {
            "id": "",
            "name": "patient_middle_name",
            "value": "",
            "type": "string",
            "align": "center",
            "width": "small",
            "needLink": true,
            "hideValue": true,
        },
        {
            "id": "",
            "name": "patient_gender",
            "value": "",
            "type": "string",
            "align": "center",
            "width": "small",
            "needLink": false,
            "hideValue": true,
        },
        {

            "id": "",
            "name": "date_of_birth",
            "value": "",
            "type": "string",
            "align": "center",
            "width": "small",
            "hideValue": true,
        },
        {

            "id": "",
            "name": "entered_date",
            "value": "",
            "type": "string",
            "align": "center",
            "width": "small",
            "hideValue": true,
        },
        {
            "id": "",
            "name": "first_billed_date",
            "value": "",
            "type": "string",
            "align": "center",
            "width": "small",
            "hideValue": true,
        },
        {
            "id": "",
            "name": "last_billed_date",
            "value": "",
            "type": "string",
            "align": "center",
            "width": "small",
            "hideValue": true,
        },
        {
            "id": "",
            "name": "service_from_date",
            "value": "",
            "type": "string",
            "align": "center",
            "width": "medium",
            "hideValue": true,
        },
        {
            "id": "",
            "name": "service_to_date",
            "value": "",
            "type": "string",
            "align": "center",
            "width": "medium",
            "hideValue": true,
        },
        {
            "id": "",
            "name": "referring_provider_name",
            "value": "",
            "type": "string",
            "align": "center",
            "width": "small",
            "hideValue": true,
        },
        {
            "id": "",
            "name": "referring_provider_npi",
            "value": "",
            "type": "string",
            "align": "center",
            "width": "small",
            "hideValue": true,
        },
        {
            "id": "",
            "name": "rendering_provider_name",
            "value": "",
            "type": "string",
            "align": "center",
            "width": "small",
            "hideValue": true,
        },
        {
            "id": "",
            "name": "rendering_provider_npi",
            "value": "",
            "type": "string",
            "align": "center",
            "width": "small",
            "hideValue": true,
        },
        {
            "id": "",
            "name": "billing_provider_name",
            "value": "",
            "type": "string",
            "align": "center",
            "width": "small",
            "hideValue": true,
        },
        {
            "id": "",
            "name": "billing_provider_npi",
            "value": "",
            "type": "string",
            "align": "center",
            "width": "small",
            "hideValue": true,
        },

        {
            "id": "",
            "name": "cpt_name",
            "value": "",
            "type": "number",
            "align": "center",
            "width": "small",
            "hideValue": true,
        },
        {
            "id": "",
            "name": "fee_units",
            "value": "",
            "type": "number",
            "align": "center",
            "width": "small",
            "hideValue": true,
        },
        {
            "id": "",
            "name": "m1_name",
            "value": "",
            "type": "string",
            "align": "center",
            "width": "small",
            "hideValue": true,
        },
        {
            "id": "",
            "name": "m2_name",
            "value": "",
            "type": "string",
            "align": "center",
            "width": "small",
            "hideValue": true,
        },
        {
            "id": "",
            "name": "m3_name",
            "value": "",
            "type": "string",
            "align": "center",
            "width": "small",
            "hideValue": true,
        },
        {
            "id": "",
            "name": "m4_name",
            "value": "",
            "type": "string",
            "align": "center",
            "width": "small",
            "hideValue": true,
        },
        {
            "id": "",
            "name": "icds",
            "value": "",
            "type": "string",
            "align": "center",
            "width": "small",
            "hideValue": true,
        },
        {
            "id": "",
            "name": "billed_payer_name",
            "value": "",
            "type": "string",
            "align": "center",
            "width": "small",
            "hideValue": true,
        },
        {
            "id": "",
            "name": "billed_payer_id",
            "value": "",
            "type": "number",
            "align": "center",
            "width": "small",
            "hideValue": true,
        },
        {
            "id": "",
            "name": "billed_policy_id",
            "value": "",
            "type": "number",
            "align": "center",
            "width": "small",
            "hideValue": true,
        },
        {
            "id": "",
            "name": "billed_group_number",
            "value": "",
            "type": "number",
            "align": "center",
            "width": "small",
            "hideValue": true,
        },

        {
            "id": "",
            "name": "current_payer_name",
            "value": "",
            "type": "string",
            "align": "center",
            "width": "small",
            "hideValue": true,
        },
        {
            "id": "",
            "name": "current_payer_id",
            "value": "",
            "type": "number",
            "align": "center",
            "width": "small",
            "hideValue": true,
        },
        {
            "id": "",
            "name": "current_policy_id",
            "value": "",
            "type": "number",
            "align": "center",
            "width": "small",
            "hideValue": true,
        },
        {
            "id": "",
            "name": "current_group_number",
            "value": "",
            "type": "number",
            "align": "center",
            "width": "small",
            "hideValue": true,
        },
        {
            "id": "",
            "name": "primary_payer_name",
            "value": "",
            "type": "string",
            "align": "center",
            "width": "small",
            "hideValue": true,
        },
        {
            "id": "",
            "name": "primary_payer_id",
            "value": "",
            "type": "number",
            "align": "center",
            "width": "small",
            "hideValue": true,
        },
        {
            "id": "",
            "name": "primary_policy_id",
            "value": "",
            "type": "number",
            "align": "center",
            "width": "small",
            "hideValue": true,
        },
        {
            "id": "",
            "name": "primary_group_number",
            "value": "",
            "type": "number",
            "align": "center",
            "width": "small",
            "hideValue": true,
        },
        {
            "id": "",
            "name": "secondary_payer_name",
            "value": "",
            "type": "string",
            "align": "center",
            "width": "small",
            "hideValue": true,
        },
        {
            "id": "",
            "name": "secondary_payer_id",
            "value": "",
            "type": "number",
            "align": "center",
            "width": "small",
            "hideValue": true,
        },
        {
            "id": "",
            "name": "secondary_policy_id",
            "value": "",
            "type": "number",
            "align": "center",
            "width": "small",
            "hideValue": true,
        },
        {
            "id": "",
            "name": "secondary_group_number",
            "value": "",
            "type": "number",
            "align": "center",
            "width": "small",
            "hideValue": true,
        },

        {
            "id": "",
            "name": "entered_by",
            "value": "",
            "type": "string",
            "align": "center",
            "width": "small",
            "hideValue": true,
        },
        {
            "id": "",
            "name": "service_location",
            "value": "",
            "type": "string",
            "align": "center",
            "width": "small",
            "hideValue": true,
        },
        // Payment Report Specific Values
        {
            "id": "",
            "name": "check_number",
            "value": "",
            "type": "string",
            "align": "center",
            "width": "small",
            "needLink": false,
            "hideValue": false,
        },
        {
            "id": "",
            "name": "check_date",
            "value": "",
            "type": "string",
            "align": "center",
            "width": "small",
            "needLink": false,
            "hideValue": false,
        },
        {
            "id": "",
            "name": "cpt_paid_amount",
            "value": "",
            "type": "string",
            "align": "center",
            "width": "small",
            "needLink": false,
            "hideValue": false,
        },
        {
            "id": "",
            "name": "cpt_adj_amount",
            "value": "",
            "type": "string",
            "align": "center",
            "width": "small",
            "needLink": false,
            "hideValue": true,
        },
        {
            "id": "",
            "name": "paid_payer_name",
            "value": "",
            "type": "string",
            "align": "center",
            "width": "small",
            "needLink": false,
            "hideValue": false,
        },
        {
            "id": "",
            "name": "paid_payer_id",
            "value": "",
            "type": "string",
            "align": "center",
            "width": "small",
            "needLink": false,
            "hideValue": true,
        },
        {
            "id": "",
            "name": "paid_policy_id",
            "value": "",
            "type": "string",
            "align": "center",
            "width": "small",
            "needLink": false,
            "hideValue": true,
        },
        {
            "id": "",
            "name": "paid_payer_group",
            "value": "",
            "type": "string",
            "align": "center",
            "width": "small",
            "needLink": false,
            "hideValue": true,
        },
        {
            "id": "",
            "name": "eob_payer_name",
            "value": "",
            "type": "string",
            "align": "center",
            "width": "small",
            "needLink": false,
            "hideValue": true,
        },
        {
            "id": "",
            "name": "eob_payer_id",
            "value": "",
            "type": "string",
            "align": "center",
            "width": "small",
            "needLink": false,
            "hideValue": true,
        },
        {
            "id": "",
            "name": "claim_status",
            "value": "",
            "type": "string",
            "align": "center",
            "width": "small",
            "needLink": false,
            "hideValue": true,
        },
        {
            "id": "",
            "name": "claim_sub_status",
            "value": "",
            "type": "string",
            "align": "center",
            "width": "small",
            "needLink": false,
            "hideValue": true,
        },
        {
            // 21
            "id": "",
            "name": "lab_sales_rep",
            "value": "",
            "type": "string",
            "align": "center",
            "width": "small",
            "needLink": false,
            "hideValue": true,
        },
        {
            // 22
            "id": "",
            "name": "clinic_site_name",
            "value": "",
            "type": "string",
            "align": "center",
            "width": "small",
            "needLink": false,
            "hideValue": true,
        },
        {
            // 23
            "id": "",
            "name": "claim_service_type",
            "value": "",
            "type": "string",
            "align": "center",
            "width": "small",
            "needLink": false,
            "hideValue": true,
        },
        {
            // 23
            "id": "",
            "name": "pos",
            "value": "",
            "type": "string",
            "align": "center",
            "width": "medium",
            "needLink": false,
            "hideValue": true,
        },
        {
            // 23
            "id": "",
            "name": "tos",
            "value": "",
            "type": "string",
            "align": "center",
            "width": "medium",
            "needLink": false,
            "hideValue": true,
        },
        
    ]
]

export const ReportTableData = {
    "name": "payment-adjustment-report-fixed-head",
    "isResize": false,
    "isReportTable": true,
    "isFixedHead": true,
    "tableHeadings": TableHeadData,
    "tableBodyData": TableBodyData
}
