import React, { memo, useEffect, useState, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { onResetData, updateSortField } from './PaymentAdjustmentSlice';
import ExportIconButton from "../../commons/exportIconButton";
import Table from '../../commons/Table/Table';
import { ReportTableData } from './PaymentAdjumentTable';
import { commonTableBody } from '../../../utilities/commonUtilities';
import Notify from '../../commons/notify';
import i18n from '../../../utilities/i18n';
import './style.css'
import FilterIconRoundButton from '../../commons/FilterIconRoundButton';
import FilterIcon from "../../../assets//images/filterIcon.png";
import { PaymentAdjustmentTable, colAccessionNumber, colClaimID, colPatientFirstName, colPatientID, colPatientLastName, colPatientMiddleName, colcpt_charge_amount, colcpt_debit_amount } from '../constants';
import ReportFilter from '../ReportFilter/ReportFilter';
import { getStorage } from '../../../utilities/browserStorage';
import Pagination from '../../commons/pagination';
import Chip from '@mui/material/Chip';
import Box from '@mui/material/Box';
import LinearProgress from '@mui/material/LinearProgress';
import { PAGING_END_INDEX, DEFAULT_PAGING_SIZE } from '../../../utilities/staticConfigs';

// Web worker instance to dispatch report api call
import workerInstance from '../ReportWorker/workerInstance';
import { URL_BASE_API } from '../../../utilities/urlConfigs';
import TableHeaderSelector from '../../commons/TableHeaderSelector/TableHeaderSelector';

// Caching the report table to avoid un-necessary table render
const MemoizedTable = memo(({ tableObject, sortingFunction }) => {
    return <Table tableObject={tableObject} onLinkClick={() => { }} sortingFunction={sortingFunction} tblRadiusClass={' table-border-radius12 '}  />;
});

MemoizedTable.displayName = 'MemoizedTable';

const PaymentAdjustmentReport = () => {

    const dispatch = useDispatch();
    const memoizedDispatch = useCallback((sortField, sortOrder) => { dispatch(updateSortField({ sortField, sortOrder })); }, [dispatch]);

    // Payment Adjustment report data from Redux
    const reportData = useSelector(state => state.paymentAdjustmentReport);
    // A Common Report state to keep the api call status, eg: isReportRunning || isError etc..
    const mainReportStatus = useSelector((state) => state.mainReportStatus);

    /** STATES TO PROPERLY RESET THE REPORT TABLE AND FILTER INPUTS */
    const [resetFlag, setResetFlag] = useState(false);
    const [resetTrigger, setResetTrigger] = useState(false);
    /********************************************** */

    /** Header data for report api call */
    const path = "reports/insurance-payment-adjustment/" // api route
    const userGroupData = JSON.parse(getStorage("userData") || '{}'); // JWT from local storage
    const userGroupToken = userGroupData.accessToken || ""; // JWT from local storage
    const unid = userGroupData.unid || "";
    const practicePK = getStorage("practice");
    /**********************************/

    /** State to Keep the active table header variables */
    const [reportTableSelector, setReportTableSelector] = useState([
        colClaimID,
        colAccessionNumber,
        colPatientID,
        colPatientFirstName,
        colPatientLastName,
        colPatientMiddleName,
        colcpt_charge_amount,
        colcpt_debit_amount
    ]);
    /*********************************************** */

    const [innerWidth, setInnerWidth] = useState(window.innerWidth); // State to store width for table's parent div 
    const [hideFilter, setHideFilter] = useState(true); // Filter toggle - Open & Close
    const filters = useSelector((state) => state.paymentAdjustmentReport.filters);
    const setFilters = (updatedFilterState) => {
        dispatch({ type: 'paymentAdjustment/updateFieldValues', payload: { state: 'mainState', field: 'filters', value: updatedFilterState }, })
    }

    /*** SORTING IMPLEMENTATION STARTS HERE */
    /************************************ */
    const sortField = useSelector((state) => state.paymentAdjustmentReport.sortField); // Current SortField
    const orderType = useSelector((state) => state.paymentAdjustmentReport.orderType); // Current Sort order - Asc or Dsc
    const allSortFieldStates = useSelector((state) => state.paymentAdjustmentReport.allSortFieldStates); // All the sort required fields in Redux

    ReportTableData.tableHeadings[1].initialOrdering = allSortFieldStates.custom_claim_id;
    ReportTableData.tableHeadings[1].orderType = orderType;
    ReportTableData.tableHeadings[9].initialOrdering = allSortFieldStates.entered_date;
    ReportTableData.tableHeadings[9].orderType = orderType;
    ReportTableData.tableHeadings[10].initialOrdering = allSortFieldStates.first_billed_date;
    ReportTableData.tableHeadings[10].orderType = orderType;
    ReportTableData.tableHeadings[11].initialOrdering = allSortFieldStates.last_billed_date;
    ReportTableData.tableHeadings[11].orderType = orderType;
    ReportTableData.tableHeadings[12].initialOrdering = allSortFieldStates.service_from_date;
    ReportTableData.tableHeadings[12].orderType = orderType;
    ReportTableData.tableHeadings[13].initialOrdering = allSortFieldStates.service_to_date;
    ReportTableData.tableHeadings[13].orderType = orderType;
    ReportTableData.tableHeadings[45].initialOrdering = allSortFieldStates.check_number;
    ReportTableData.tableHeadings[45].orderType = orderType;
    ReportTableData.tableHeadings[46].initialOrdering = allSortFieldStates.check_date;
    ReportTableData.tableHeadings[46].orderType = orderType;

    // Helper Object to map and find out the correct key for the sortfield as from the table
    // When user clicks on the sort icon its passing the header as argument like "Claim Id" instead of custom_claim_id
    // Below object is used to identify the same
    const helperSortObject = {
        "Claim ID": "custom_claim_id",
        "Date of entry": "entered_date",
        "First Billed Date": "first_billed_date",
        "Last Billed Date": "last_billed_date",
        "Date of Service From": "service_from_date",
        "Date of Service To": "service_to_date",
        "Check Number": "check_number",
        "Check Date": "check_date"
    };
    /**
     * @param {*} e - html native button click event - Not in use
     * @param {*} name  - Name of the field requesting be "Sorted by"
     */
    const sortingFunction = useCallback((e, name) => {
      
        if (checkAnyReportAlreadyRunning() !== 'proceed' || !name) {
            return;
        }

        const sortField = helperSortObject[name];
       
        /**
       
        /**
         * Dispatch current sortField to redux, so when user leaves the report module and returns 
         * the current sort state can be retained
         */
        memoizedDispatch(sortField, !orderType ? "-" : "");
        /**
         * param(1) Requesting page 1 to be fetched
         * param(sortField) filed to be sorted
         * param(orderType) ascending or descending
         */
        executeReportDataFetch(activePage, sortField, !orderType ? "-" : "asc");
    }, [allSortFieldStates, orderType, sortField, mainReportStatus]);
    /*** SORTING IMPLEMENTATION ENDS HERE */
    /************************************ */

    /** NOTIFY CONFIG */
    const [showNotify, setShowNotify] = useState('hide');
    const [notifyDescription, setNotifyDescription] = useState('');
    const [notifyType, setNotifyType] = useState('success');

    function showNotifyWindow(action, type, desc, age = 3000) {
        if (action === 'show') {
            setShowNotify(action);
            setNotifyType(type);
            setNotifyDescription(desc);
            setTimeout(() => {
                setShowNotify('hide');
            }, age);
        }
    }
    /** NOTIFY CONFIG ENDS HERE */
    /****************************/

    function checkAnyReportAlreadyRunning() {
        if (mainReportStatus.isReportRunning === true) {
            showNotifyWindow("show", "error", 'Another report is already running in the background, Please wait till it complete!');
            return 'abort';
        } else {
            return 'proceed'
        }
    }

    /*** PAGINATION FUNCTIONALITY STARTS HERE */
    /**************************************** */
    const [totalPage, setTotalPage] = useState(1);
    const [activePage, setActivePage] = useState(1);
    const [startIndex, setStartIndex] = useState(0);
    const [endIndex, setEndIndex] = useState(PAGING_END_INDEX);

    function onPagePrevious() {
        if (checkAnyReportAlreadyRunning() !== "proceed") { return }
        const previousPage = startIndex - PAGING_END_INDEX + 1;
        setActivePage(previousPage);

        if (startIndex !== 0) {
            setStartIndex(startIndex - PAGING_END_INDEX);
            setEndIndex(endIndex - PAGING_END_INDEX);
        }

        executeReportDataFetch(previousPage);
    }

    function onPageNext() {
        if (checkAnyReportAlreadyRunning() !== "proceed") { return }
        const nextPage = startIndex + PAGING_END_INDEX + 1;
        const canIncrementIndexes = endIndex === totalPage || totalPage <= PAGING_END_INDEX;

        setActivePage(nextPage);
        setStartIndex(canIncrementIndexes ? startIndex : startIndex + PAGING_END_INDEX);
        setEndIndex(canIncrementIndexes ? startIndex : endIndex + PAGING_END_INDEX);

        executeReportDataFetch(nextPage);
    }

    function onPageUp(e) {
        if (checkAnyReportAlreadyRunning() !== "proceed") { return }
        const page = Number(e.target.id);
        setActivePage(page);

        executeReportDataFetch(page);
    }
    /*** PAGINATION FUNCTIONALITY ENDS HERE */
    /**************************************** */


    // Report export function - only xls download allowed, NO CSV
    const OnExportMenuItemClicked = (e, type) => {
        const flag = checkAnyReportAlreadyRunning();
        if (flag === 'proceed') {
            let query = buildReportQuery();
            query += `&export=true&export_type=${type}`;
            const path = 'reports/insurance-payment-adjustment/?';

            if (type !== "") {
                workerInstance.getXLS(URL_BASE_API, path, query, userGroupToken,unid);
                dispatch({ type: 'report/setReportRunning', payload: { isRunning: true, report: 'Payment-Adjustment' } });
            }
        }
    };
    /*********************/


    // Dispatch api call on Search button click with given query
    const onSearch = () => {
        const flag = checkAnyReportAlreadyRunning();
        if (flag === 'proceed') {
            if (filters.blanceAmountType && filters.blanceAmountType == "Between" && filters.balanceAmountTotalsFrom  && filters.balanceAmountTotalsTo !== '' && parseFloat(filters.balanceAmountTotalsFrom) > parseFloat(filters.balanceAmountTotalsTo)) {
                return;
            } 
            if (filters.blanceAmountType && filters.blanceAmountType !== "Between" && !filters.balanceAmountTotalsFrom) {
                showNotifyWindow("show", "error", i18n.t("reports.reportErrorMessage.filterErrMsgOnBalanceAmt"));
                return;
            }
            if (filters.blanceAmountType === "Between" && (!filters.balanceAmountTotalsFrom || !filters.balanceAmountTotalsTo)) {
                showNotifyWindow("show", "error", i18n.t("reports.reportErrorMessage.filterErrMsgOnBalanceAmtFromTO"));
                return;
            }

            const query = buildReportQuery();
            const queryWithPagination = `?page_size=${DEFAULT_PAGING_SIZE}&page=1&${query}`;
            workerInstance.getReportData(URL_BASE_API, path, queryWithPagination, userGroupToken,unid);
            dispatch({ type: 'report/setReportRunning', payload: { isRunning: true, report: 'Payment-Adjustment',search:true } });
            setActivePage(1);
        }
    };

    /**
     * Common function to call worker api function from pagination functions
     * @param {*} page 
     */
    function executeReportDataFetch(page, sortField, orderType) {
        const flag = checkAnyReportAlreadyRunning();
        if (flag === 'proceed') {
            const query = buildReportQuery(sortField, orderType);
            const queryWithPagination = `?page_size=${DEFAULT_PAGING_SIZE}&page=${page}&${query}`;

            workerInstance.getReportData(URL_BASE_API, path, queryWithPagination, userGroupToken,unid);
            dispatch({ type: 'report/setReportRunning', payload: { isRunning: true, report: 'Payment-Adjustment' } });
        }
    }

    useEffect(() => {
        if (mainReportStatus.isError === true) showNotifyWindow("show", "error", i18n.t("reports.reportErrorMessage.errorFetchingReport"));
    }, [mainReportStatus.isError]);

    // Side effect to update the table whenever ReportTableData in redux gets updated by api call
    useEffect(() => {
        const rowArray = commonTableBody(reportData.tableData, ReportTableData.tableBodyData[0])
        ReportTableData.tableBodyData = rowArray;
        // Update the pagination states
        setTotalPage(reportData.totalPage)
    }, [reportData])

    // Set Inner width for the Table
    useEffect(() => {
        let newWidth = "";
        newWidth = window.innerWidth;
        let menuWidth = document.getElementById("push-bar").classList.contains('open');
        if (!menuWidth) {
            newWidth = newWidth + 250;
        } else {
            newWidth = window.innerWidth;
        }
        setInnerWidth(newWidth);
    }, [window.innerWidth]);
    /*********************************/

    /** TABLE HEADER ADD & REMOVE FUNCTIONALITY BEGINS HERE  */
    /***********************************************************/
    async function MaterialMultiselectHandleChange(e) {
        let value = [...reportTableSelector];
        if (value.find(item => item === e)) {
            const index = value.indexOf(e);
            value.splice(index, 1);
        } else {
            value = [...value, e];
        }
        let anArray = [];
        PaymentAdjustmentTable.forEach((item) => {
            //selection and unselection of table fields
            let itemObj = value.filter((field) => {
                if (field === item) return item;
            });
            if (itemObj.length > 0) {
                anArray.push(itemObj[0]);
            }
        });
        await onSelectFieldNames(anArray);
        setReportTableSelector(anArray);
    }

    async function onSelectFieldNames(pTableSelecter) {
        // THE FIELD SELECTION CONTROL IS SAME FOR BOTH 'LAST10' & 'ADV. SEARCH RESULTS',
        let tmpTblData = [];
        tmpTblData = ReportTableData;
        let tblHead = [];
        let tblData = [];
        let dataColHideStatus = [];
        tmpTblData.tableHeadings.map((item) => {
            if (pTableSelecter.find((str) => str === item.name)) {
                // if table heading is available in pTableSelecter then its not hidden
                tblHead.push({ ...item, hideValue: false });
                dataColHideStatus.push(false);
            } else {
                // if table heading is not available in pTableSelecter then its not hidden
                tblHead.push({ ...item, hideValue: true });
                dataColHideStatus.push(true);
            }
        });

        let newRow = [];
        tmpTblData.tableBodyData.map((rowItem) => {
            newRow = [];
            rowItem.map((colItem, colIndex) => {
                colItem.hideValue = dataColHideStatus[colIndex];
                newRow.push(colItem);
            });
            tblData.push(newRow);
        });
        ReportTableData.tableHeadings = tblHead;
        ReportTableData.tableBodyData = tblData;
    }
    /** TABLE HEADER ADD & REMOVE FUNCTIONALITY ENDS HERE  */
    /***********************************************************/

    const onShowFilter=()=>{
        setHideFilter(!hideFilter)
    };

    // HELPER FUNCTION TO GENERATE QUERY PARAMS
    const buildReportQuery = (sortFieldParam, orderTypeParam) => {
        /**
         * If sort filed is passed in function argument it will take from the argument as first preference
         * Else it will take the sort and sort order values from the predecessor's scope that component level scope, where user may have set sort preferences
         * This implementation is required when changing the page with sort option
         */
        const sort = sortFieldParam || sortField;
        const order = typeof orderTypeParam === "undefined" ? orderType : (orderTypeParam === "asc" ? "" : "-");

        /** Building Query Params string */
        let query = 'practice_pk=' + practicePK + '&date_of_service_from=' + filters.dateOfServiceFrom +
            '&date_of_service_to=' + filters.dateOfServiceTo + '&date_of_entry_from=' + filters.dateOfEntryFrom +
            '&date_of_entry_to=' + filters.dateOfEntryTo + '&first_bill_date_from=' +
            filters.firstBilledDateFrom + '&first_bill_date_to=' + filters.firstBilledDateTo + '&last_bill_date_from=' +
            filters.lastBilledDateFrom + '&last_bill_date_to=' + filters.lastBilledDateTo + '&date_of_entry=' + filters.dateOfPaymentEntry + '&payer_type=' + filters.payerType +
            '&insurance_company=' + filters.insurance_companies + '&claim_status=' + filters.claimStatus +
            '&claim_sub_status=' + filters.claimSubStatus + '&rendering_provider=' + filters.renderingProvider +
            '&reffering_provider=' + filters.refferingProvider + '&billing_provider=' + filters.billingProvider +
            '&service_locations=' + filters.serviceLocation.map(item => item.split("||").join("-")).join(",") +
            '&cpt_code=' + filters.cptList + '&icd=' + filters.ICD + '&payment_enter_user=' + filters.paymentEnteredUser +
            '&charge_enter_user=' + filters.chargeEnteredUser + '&aging=' + filters.aging + '&balance_type=' + filters.blanceAmountType + 
            '&balance_amount_start=' + filters.balanceAmountTotalsFrom.replace(/,/g, '') + '&balance_amount_end=' + filters.balanceAmountTotalsTo.replace(/,/g, '') +
            '&payment_check_date=' + filters.paymentCheckDate + '&payment_check_number=' +filters.paymentCheckNumber +
            '&column=' + order + sort
        return query
    }

    /**************************************/
    /** RESET REPORT DATA & FILTER INPUTS */
    const onReset = () => {
        setFilters({
            dateOfServiceFrom: "",
            dateOfServiceTo: "",
            dateOfPaymentEntry: "",
            dateOfEntryFrom: "",
            dateOfEntryTo: "",
            firstBilledDateFrom: "",
            firstBilledDateTo: "",
            lastBilledDateFrom: "",
            lastBilledDateTo: "",
            payerType: [],
            insurance_companies: [],
            claimStatus: [],
            claimSubStatus: [],
            renderingProvider: [],
            refferingProvider: [],
            billingProvider: [],
            serviceLocation: [],
            cptList: [],
            ICD: [],
            chargeEnteredUser: [],
            paymentEnteredUser: [],
            blanceAmountType: "",
            balanceAmountTotals: "",
            balanceAmountTotalsFrom: "",
            balanceAmountTotalsTo: "",
            aging: "",
            paymentCheckDate: "",
            paymentCheckNumber: ""
        })
        dispatch(onResetData());
        setResetTrigger(prevResetTrigger => !prevResetTrigger);
    };

    useEffect(() => {
        // Whenever resetTrigger changes, update the resetFlag state to force re-render component
        setResetFlag(prevResetFlag => !prevResetFlag);
    }, [resetTrigger]);
    /****** RESET FUNCTIONALITY ENDS HERE *******/
    /********************************************/


    return (
        <>
            <Notify showNotify={showNotify} setShowNotify={setShowNotify} notifyDescription={notifyDescription} setNotifyType={setNotifyType} setNotifyDescription={setNotifyDescription} notifyType={notifyType} />
            <div className="col-md-12">
                <div className="box pb-5 basic-info-padding">
                    <div className="margin-right15">
                        {mainReportStatus.isReportRunning && mainReportStatus.currentRunningReport == "Payment-Adjustment" && (
                            <Box sx={{ width: '100%', padding: '5px', margin: '0 auto', border: '1px solid #90a3bd', borderRadius: '10px', textAlign: 'center' }}>
                                <LinearProgress />
                            </Box>
                        )}

                        {hideFilter && (!mainReportStatus.isReportRunning || mainReportStatus.currentRunningReport !== "Payment-Adjustment") && <ReportFilter filters={filters} setFilters={setFilters} onSearch={onSearch} onReset={onReset} reportType="paymentAdjustment" />}
                    </div>
                    <div className='row justify-content-end mr-3 mt-2'>
                        <div className="mt-3">
                            <div className="row">
                                <div className="col-md-4">
                                    <div className="d-flex">
                                        <div className="me-1  mr-1">
                                            <Chip
                                                label={`${i18n.t("reports.reportCount")}: ${reportData.count}`}
                                                variant="outlined"
                                                sx={{
                                                    margin: '3px',
                                                    fontSize: '1rem',
                                                    border: "1px, solid #1479BB !important", 
                                                    backgroundColor: '#F5F5F5',
                                                    borderColor: "#1479BB !important", 
                                                    color: "#1479BB !important",
                                                    cursor: "default",
                                                    paddingLeft: "5px", 
                                                    paddingRight: "5px"
                                                    // "&:hover": {
                                                    //     backgroundColor: '#1074bc',
                                                    //     color: 'white',
                                                    // },
                                                }}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="mt-3 ml-2" id="export-dropdown">
                            <ExportIconButton
                                disabled={!reportData.tableData.length}
                                onlyXLS={true}
                                onExportXls={(e) => OnExportMenuItemClicked(e, 'xls')} />
                        </div>
                        <div className="mt-3" id="table-header-selector">
                            <TableHeaderSelector
                                selected={reportTableSelector}
                                options={PaymentAdjustmentTable}
                                MaterialMultiselectHandleChange={(e) => MaterialMultiselectHandleChange(e)}
                            />
                        </div>
                        <div className="mt-3 ml-1" id="export-dropdown">
                            <FilterIconRoundButton
                                ToolTip={"FIlter"}
                                ImageSrc={FilterIcon}
                                IconName={"fa-filter"}
                                ImgAlt={'filter'}
                                OnClick={() => onShowFilter()}
                            />
                        </div>
                    </div>

                    <div style={{ overflowX: "auto", width: "100%", maxWidth: (innerWidth - 300) + "px", maxHeight: '700px' }} className="mb-4">
                        <MemoizedTable tableObject={ReportTableData} sortingFunction={sortingFunction} reportTableSelector={reportTableSelector} resetFlag={resetFlag} key={resetFlag.toString()} />
                        {/* The Pagination Component will be rendered only when the page count is greater than default page size i.e 20 */}
                    </div>
                    <Pagination totalPage={totalPage} activePage={activePage} startIndex={startIndex} endIndex={endIndex} onPagePrevious={onPagePrevious} onPageUp={onPageUp} onPageNext={onPageNext} />
                </div>
            </div>
        </>
    )

}

export default PaymentAdjustmentReport;