import i18n from "../../../utilities/i18n";

const TableHeadData = [
    {
        "name": i18n.t('dictionariesPages.dictPracticeType.lblPracticeType'),
        "type": "string",
        "width": "large",
        "sort": false,
    },
    {
        "name": i18n.t("dictionariesPages.action"),
        "type": "button",
        "width": "action_column",
        "sort": false,
    },
];

const TableBodyData = [
    [
        {
            "id": "",
            "name": "name",
            "value": "",
            "type": "string",
            "align": "left",
            "width": "large",
        },
        {
            "id": "",
            "name": "action",
            "type": "button",
            "align": "center",
            "width": "action_column",
            "dropdown": [
                {
                    "name": "Edit"
                },
                {
                    "name": "Delete"
                },
            ]
        },
    ]
]

export const PracticeTypeTableData = {
    "name": "simpleTable",
    "isResize": true,
    "isFixedHead": true,
    "tableHeadings": TableHeadData,
    "tableBodyData": TableBodyData
}