import React, { useContext, useEffect, useState } from 'react';
import { Nav, Tab } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import LoadingContext from '../../container/loadingContext';

import service from './service';

import { InterFaceListTable, TableBodyData } from './InterfaceListTable';

import CustomizedDialogs from '../modalWindowComponent/CustomizedDialogs';

import i18n from '../../utilities/i18n';
import { getStorage } from '../../utilities/browserStorage';
import { commonTableBody } from '../../utilities/commonUtilities';
import { ROUTE_INTERFACE_SEARCH, TAB1, TAB2, TAB3, TAB4,PAGING_END_INDEX} from '../../utilities/staticConfigs';

import Notify from '../commons/notify';
import Table from '../commons/Table/Table';
import Pagination from '../commons/pagination';
import AsyncTypeInput from '../commons/input/AsyncTypeHead/AsyncTypeInput';


function InterFaceList() {
    const history = useHistory();
    const setShowLoadingBar = useContext(LoadingContext);
    const interfacePagingSize = 100;
    const practicePK = getStorage('practice');
    const [tableList, setTableList] = useState([]);
    const [activeTab, setActiveTab] = useState('');
    const [showMessageContentModal, setShowMessageContentModal] = useState(false);
    const [messageInfoTab, setMessageInfoTab] = useState({
        // first(info) tab in message
        "message_types": "", "status": "", "created": "", "received_from": ""
    });
    const [messagePatientTab, setMessagePatientTab] = useState({
        // second(patient) tab in message
        'patient_name': '', 'dob': '', 'gender': '', 'ssn': '', 'patient_address': '',
        'city': '', 'state': '', 'zip_code': '', 'phone_number': ''
    });
    const [messageChargesTab, setMessageChargesTab] = useState({
        // third(charge) tab in message
        'icd_codes': '', 'cpt_codes': '', 'test_code': '', 'dos': '', 'accession_numer': ''
    });
    const [messageProviderPayerTab, setMessageProviderPayerTab] = useState({
        // fourth(provider) tab in message
        'attending_provider': '', 'primary_company_name': '', 'prim_ins_policy_id': '', 'secondary_company_name': '',
        'sec_ins_policy_id': ''
    });
    const [checkedIds, setCheckedIds] = useState([]);

    //Pagination start
    const [totalPage, setTotalPage] = useState(1);
    const [activePage, setActivePage] = useState(1);
    const [startIndex, setStartIndex] = useState(0);
    const [endIndex, setEndIndex] = useState(PAGING_END_INDEX);

    // notify
    const [showNotify, setShowNotify] = useState('hide');
    const [notifyDescription, setNotifyDescription] = useState('');
    const [notifyType, setNotifyType] = useState('success');

    useEffect(() => {
        let query = '';
        if(history.location.state?.query){
            query += history.location.state.query;
        }
        ListInterfaceData(interfacePagingSize, 1, query);
    }, [])

    function showNotifyWindow(action, type, desc, age = 3000) {
        // to show notification
        if (action == 'show') {
            setTimeout(() => {
                setShowNotify('hide');
            }, age)
        }
        setShowNotify(action);
        setNotifyType(type);
        setNotifyDescription(desc);
    }

    function onPagePrevious() {
        // pagination previouse
        let previousPage = startIndex + 1 - PAGING_END_INDEX;
        setActivePage(previousPage);

        if (startIndex !== 0) {
            // if not first page the need to set start index and end index
            setStartIndex(startIndex - PAGING_END_INDEX);
            setEndIndex(endIndex - PAGING_END_INDEX);
        }
        ListInterfaceData(interfacePagingSize, previousPage);
    }

    function onPageUp(e) {
        let page = Number(e.target.id)
        setActivePage(page);
        ListInterfaceData(interfacePagingSize, page);
    }

    function onPageNext() {
        let nextPage = startIndex + 1 + PAGING_END_INDEX;
        if (endIndex === totalPage || totalPage <= PAGING_END_INDEX) {
            setActivePage(nextPage);
            setStartIndex(startIndex);
            setStartIndex(endIndex);

        } else {
            setActivePage(nextPage);
            setStartIndex(startIndex + PAGING_END_INDEX);
            setEndIndex(endIndex + PAGING_END_INDEX);

        }
        ListInterfaceData(interfacePagingSize, nextPage);

    }
    //Pagination ends

    const GotoInterfaceSearch = () => {
        history.push(ROUTE_INTERFACE_SEARCH);
    }
    function handleInterfaceTypeHead() {
        // if (name === "filterValues") setSelectedPatient(e[0].id);
    }

    function onShowMessage(messagePK) {
        // to show message modeal window
        setActiveTab(TAB1);
        if (messagePK) {
            const result = service.GetMessage(messagePK, practicePK);
            result.then((response) => {
                let data = response.data;
                setMessageInfoTab({
                    ...messageInfoTab, "message_types": data.message_types,
                    "status": data.status, "created": data.created, "received_from": data.received_from
                });
                setMessagePatientTab({
                    ...messageInfoTab, 'patient_name': data.patient_name, 'dob': data.dob, 'gender': data.gender,
                    'ssn': data.ssn, 'patient_address': data.patient_address, 'city': data.city,
                    'state': data.state, 'zip_code': data.zip_code, 'phone_number': data.phone_number
                });
                setMessageChargesTab({
                    ...messageChargesTab, 'icd_codes': data.icd_codes, 'cpt_codes': data.cpt_codes,
                    'transaction_code': data.transaction_code, 'dos': data.dos
                })
                setMessageProviderPayerTab({
                    ...messageProviderPayerTab, 'attending_provider': data.attending_provider,
                    'primary_company_name': data.primary_company_name,
                    'prim_ins_policy_id': data.prim_ins_policy_id, 'secondary_company_name': data.secondary_company_name,
                    'sec_ins_policy_id': data.sec_ins_policy_id
                })
            })
            setShowMessageContentModal(true);
        }
    }

    const onTabChange = (e, tab) => {
        setActiveTab(tab);
    }

    function onClickExpand(itemId) {
        setTableList(tableList.map((item) => {
            return item.id === itemId ? { ...item, isExpanded: !item.isExpanded } : item;
        }));
    }

    const ListInterfaceData = (pageSize, page, query) => {
        setShowLoadingBar(true);
        const result = service.ListInterfaceData(pageSize, page, practicePK, query);
        result.then((response) => {
            if (response.data.results !== undefined) {
                setTotalPage(Math.ceil(response.data.count / response.data.page_size));
            }
            if (response.data.results && response.data.results.length) {
                let responseData = response.data.results;
                responseData.forEach((item) => {
                    if (item.status_message) {
                        // to show expand message
                        let status_message = item.status_message.split(', '); // each error code is comma seperatred in db so split it to array 
                        let warnings = '';
                        status_message.forEach((message, index) => {
                            if (index !== 0)
                                warnings = warnings + ', ';
                            warnings = warnings + i18n.t('interface.statusMessages.' + message);
                        })
                        item.status_message = warnings;
                    } else if (item.status && item.status.toLowerCase() === 'success') {
                        item.status_message = i18n.t('interface.statusMessages.success');
                    }
                });

                const rowArray = commonTableBody(response.data.results, InterFaceListTable.tableBodyData[0])
                InterFaceListTable.tableBodyData = rowArray;

                setTableList(response.data.results.map(item => { return { ...item, isExpanded: false } }));
            }
            else {
                InterFaceListTable.tableBodyData = TableBodyData;
                setTableList(response.data.results);
            }
            setCheckedIds([]);
            setShowLoadingBar(false);

        })
    }

    const handleCheckedOnChange = (e, id) => {
        let currentSelectedIds = [];
        checkedIds.forEach((item) => {
            currentSelectedIds.push(item);
        })
        setCheckedIds([]);
        let index = currentSelectedIds.indexOf(id);
        if (e.target.checked) {
            if (index === -1) {
                currentSelectedIds.push(id);
            }

        }
        else {
            if (index !== -1) {
                currentSelectedIds.splice(index, 1);
            }
        }
        setCheckedIds(currentSelectedIds);
    }

    const onInterfaceReprocess = () => {
        // reporcess API
        let data = {
            'practice_pk': practicePK, 'pks': checkedIds
        }
        const result = service.ReprocessInterfaceData(data);
        result.then((response) => {
            if (response.status === 200 && response.data && response.data.message === 'duplicate_message') {
                showNotifyWindow('show', 'error', i18n.t('interface.statusMessages.duplicate'));
            } else if (response.status === 200) {
                showNotifyWindow('show', 'success', i18n.t('interface.statusMessages.success'));
            }
            ListInterfaceData(interfacePagingSize, activePage);
        });
    }

    const onInterfaceErrorFix = () => {
        // error fix API
        let data = {
            'practice_pk': practicePK, 'pks': checkedIds
        }
        const result = service.ErrorFixInterfaceData(data);
        result.then((response) => {
            if (response.status === 200 && response.data && response.data.message === 'duplicate_message') {
                showNotifyWindow('show', 'error', i18n.t('interface.statusMessages.duplicate'));
            } else if (response.status === 200) {
                showNotifyWindow('show', 'success', i18n.t('interface.statusMessages.error_fix_success'));
            }
            ListInterfaceData(interfacePagingSize, activePage);
        });
    }

    function onExpandClick(id) { 
        InterFaceListTable.tableBodyData.forEach((dataItem) => {
            let dataObj = dataItem.find((obj) => obj.id == id); 
            if(dataObj){ 
                dataObj.isExpanded = dataObj.isExpanded ? false : true; 
            }
        })

        onClickExpand(id);
        
    }

    function onClickGridCheckBox(e, clickedFrom, id) { 
        if (clickedFrom == 'data') {
            handleCheckedOnChange(e, id); 
        }
    }

    return (
        <div className='col-md-12'>
            <Notify showNotify={showNotify} setShowNotify={setShowNotify} notifyDescription={notifyDescription} setNotifyType={setNotifyType} setNotifyDescription={setNotifyDescription} notifyType={notifyType} />
            <div className='box basic-info-padding'>
                <div className='row padding-left15 form-group'>
                    <div className='col pr-0'>
                        <div className='row'>
                            <div className='mr-2 pt-4'>
                                <button type='button' onClick={onInterfaceReprocess} className='btn btn-primary-blue' disabled={checkedIds.length === 1 ? false : true}>{i18n.t("interface.interfaceGrid.reprocessed")}</button>
                            </div>
                            <div className='mr-2 pt-4'>
                                <button type='button' onClick={onInterfaceErrorFix} className='btn btn-primary-blue' disabled={checkedIds.length === 1 ? false : true}>{i18n.t("interface.interfaceGrid.error_fixed")}</button>
                            </div>
                            <div className='mr-2 pt-4'>
                                <button type='button' onClick={GotoInterfaceSearch} className='btn btn-secondary px-4'>{i18n.t("interface.interfaceGrid.close")}</button>
                            </div>
                            <div className='col-3 pl-0 mr-2 padding-top20'>
                                <AsyncTypeInput
                                    id="basic-typeahead-filterValues"
                                    name="filterValues"
                                    labelKey="name"
                                    minLength={1}
                                    options={[]}
                                    // onSearch={listPatientDropdown}
                                    onValueChange={(e) => handleInterfaceTypeHead(e, "filterValues")}
                                    label={i18n.t("interface.interfaceGrid.filterValues")}
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div className=''>

                    <Table tableObject={InterFaceListTable} onExpandClick={onExpandClick} onClickGridCheckBox={onClickGridCheckBox} onShowMessage={onShowMessage} />
                    <Pagination totalPage={totalPage} activePage={activePage} startIndex={startIndex} endIndex={endIndex} onPagePrevious={onPagePrevious} onPageUp={onPageUp} onPageNext={onPageNext} />
                </div>
                <div className=''>
                    <CustomizedDialogs header={i18n.t("interface.interfaceGrid.modal.messageContent")} showModal={showMessageContentModal} setShowModalWindow={setShowMessageContentModal}
                        className="" minHeight={'380px'}>
                        <Tab.Container activeKey={activeTab}>
                            <Nav variant='pills' className='subTab'>
                                <Nav.Item>
                                    <Nav.Link eventKey={TAB1} onClick={(e) => onTabChange(e, TAB1)}>
                                        {i18n.t("interface.interfaceGrid.modal.messageInfo")}
                                    </Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                    <Nav.Link eventKey={TAB2} onClick={(e) => onTabChange(e, TAB2)}>
                                        {i18n.t("interface.interfaceGrid.modal.patient")}
                                    </Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                    <Nav.Link eventKey={TAB3} onClick={(e) => onTabChange(e, TAB3)}>
                                        {i18n.t("interface.interfaceGrid.modal.charges")}
                                    </Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                    <Nav.Link eventKey={TAB4} onClick={(e) => onTabChange(e, TAB4)}>
                                        {i18n.t("interface.interfaceGrid.modal.providerAndPayer")}
                                    </Nav.Link>
                                </Nav.Item>
                            </Nav>
                            <Tab.Content>
                                <Tab.Pane eventKey={TAB1}>
                                    <div className='pl-3'>
                                        <div className='pt-2'>
                                            {i18n.t("interface.interfaceGrid.modal.mesageType")}  {messageInfoTab.message_types}
                                        </div>
                                        <div className='pt-2'>
                                            {i18n.t("interface.interfaceGrid.modal.messageReceivedOn")}  {messageInfoTab.created}
                                        </div>
                                        <div className='pt-2'>
                                            {i18n.t("interface.interfaceGrid.modal.mesageRecievedFrom")}  {messageInfoTab.received_from}
                                        </div>
                                        <div className='pt-2'>
                                            {i18n.t("interface.interfaceGrid.modal.curentStatus")}  {messageInfoTab.status}
                                        </div>
                                    </div>
                                </Tab.Pane>
                            </Tab.Content>
                            <Tab.Content>
                                <Tab.Pane eventKey={TAB2}>
                                    <div className='pl-3'>
                                        <div className='pt-2'>
                                            {i18n.t("interface.interfaceGrid.modal.patientName")}  {messagePatientTab.patient_name}
                                        </div>
                                        <div className='pt-2'>
                                            {i18n.t("interface.interfaceGrid.modal.patientDob")}  {messagePatientTab.dob}
                                        </div>
                                        <div className='pt-2'>
                                            {i18n.t("interface.interfaceGrid.modal.gender")}  {messagePatientTab.gender}
                                        </div>
                                        <div className='pt-2'>
                                            {i18n.t("interface.interfaceGrid.modal.ssn")}  {messagePatientTab.ssn}
                                        </div>
                                        <div className='pt-2'>
                                            {i18n.t("interface.interfaceGrid.modal.address")}  {messagePatientTab.patient_address}
                                        </div>
                                        <div className='pt-2'>
                                            {i18n.t("interface.interfaceGrid.modal.city")}  {messagePatientTab.city}
                                        </div>
                                        <div className='pt-2'>
                                            {i18n.t("interface.interfaceGrid.modal.state")}  {messagePatientTab.state}
                                        </div>
                                        <div className='pt-2'>
                                            {i18n.t("interface.interfaceGrid.modal.zipCode")}  {messagePatientTab.zip_code}
                                        </div>
                                        <div className='pt-2'>
                                            {i18n.t("interface.interfaceGrid.modal.homePhone")}  {messagePatientTab.phone_number}
                                        </div>
                                        <div className='pt-2'>
                                            {i18n.t("interface.interfaceGrid.modal.cellPhone")}
                                        </div>
                                        <div className='pt-2'>
                                            {i18n.t("interface.interfaceGrid.modal.email")}
                                        </div>
                                    </div>
                                </Tab.Pane>
                            </Tab.Content>
                            <Tab.Content>
                                <Tab.Pane eventKey={TAB3}>
                                    <div className='pl-3'>
                                        <div className='pt-2'>
                                            {i18n.t("interface.interfaceGrid.modal.icdCodes")}  {messageChargesTab.icd_codes}
                                        </div>
                                        <div className='pt-2'>
                                            {i18n.t("interface.interfaceGrid.modal.procedureCodes")}  {messageChargesTab.cpt_codes}
                                        </div>
                                        <div className='pt-2'>
                                            {i18n.t("interface.interfaceGrid.modal.testCodes")}  {messageChargesTab.transaction_code}
                                        </div>
                                        <div className='pt-2'>
                                            {i18n.t("interface.interfaceGrid.modal.serviceDate")}  {messageChargesTab.dos}
                                        </div>
                                        <div className='pt-2'>
                                            {i18n.t("interface.interfaceGrid.modal.admissionDate")}
                                        </div>
                                        <div className='pt-2'>
                                            {i18n.t("interface.interfaceGrid.modal.charges")}
                                        </div>
                                    </div>
                                </Tab.Pane>
                            </Tab.Content>
                            <Tab.Content>
                                <Tab.Pane eventKey={TAB4}>
                                    <div className='pl-3'>
                                        <div className='pt-2'>
                                            {i18n.t("interface.interfaceGrid.modal.bilingProvider")}
                                        </div>
                                        <div className='pt-2'>
                                            {i18n.t("interface.interfaceGrid.modal.referingProvider")}
                                        </div>
                                        <div className='pt-2'>
                                            {i18n.t("interface.interfaceGrid.modal.orderingProvider")}
                                        </div>
                                        <div className='pt-2'>
                                            {i18n.t("interface.interfaceGrid.modal.attendingProviider")}  {messageProviderPayerTab.attending_provider}
                                        </div>
                                        <div className='pt-2'>
                                            {i18n.t("interface.interfaceGrid.modal.primaryPayer")}  {messageProviderPayerTab.primary_company_name}
                                        </div>
                                        <div className='pt-2'>
                                            {i18n.t("interface.interfaceGrid.modal.primaryPolicyId")}  {messageProviderPayerTab.prim_ins_policy_id}
                                        </div>
                                        <div className='pt-2'>
                                            {i18n.t("interface.interfaceGrid.modal.secondaryPolicyId")}  {messageProviderPayerTab.secondary_company_name}
                                        </div>
                                        <div className='pt-2'>
                                            {i18n.t("interface.interfaceGrid.modal.secondaryPayerId")}  {messageProviderPayerTab.sec_ins_policy_id}
                                        </div>
                                    </div>
                                </Tab.Pane>
                            </Tab.Content>
                        </Tab.Container>
                    </CustomizedDialogs>
                </div>
            </div>
        </div>
    )
}

export default InterFaceList