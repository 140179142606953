import React from "react";
import BootStrapCardWithHeader from "../ReportBuilder/BootStrapCardWithHeader";
import TableHolderCard from "./TableHolderCard";

const DUMMY_FAV = [
  { category: "PATIENTS", report: "All Patients" },
  { category: "CLAIMS", report: "All Claims" },
  { category: "CLAIMS", report: "Due Claims" },
  { category: "CLAIMS", report: "Fresh Claims" },
];

const FavouriteReportCard = () => {
  const favourites = DUMMY_FAV;
  const onItemClickHandler = () => {
  };
  return (
    <div className="row">
      <div className="col-12">
        <BootStrapCardWithHeader cardTitle={"Favourites"}>
          <TableHolderCard
            items={favourites}
            onItemClick={onItemClickHandler}
          />
        </BootStrapCardWithHeader>
      </div>
    </div>
  );
};

export default FavouriteReportCard;
