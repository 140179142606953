import api from '../../../service/api'

const ListProviderGoupTypes = async(pageSize, page, practicePK) =>{
	let path = 'user/referring-provider-group/?page_size='+ pageSize +'&page='+ page +'&practice_pk='+ practicePK;
	return api.get(path);
}

const AddProviderGoupType = async(data) =>{
	let path = 'user/referring-provider-group/'
	return api.post(path,data);
}

const GetProviderGoupType = async(providerGroupId) =>{
	let path = 'user/referring-provider-group/' + providerGroupId
	return api.get(path);
}

const UpdateProviderGoupType = async(providerGroupId, data) =>{
	let path = 'user/referring-provider-group/' + providerGroupId
	return api.put(path,data);
}

const DeleteProviderGoupType = async(providerGroupId) =>{
	let path = 'user/referring-provider-group/' + providerGroupId
	return api.destroy(path);
}

const GetCustomerOfPrcatice = async(practicePK) => {
	let path = 'user/customer-of-practice/?practice_pk='+ practicePK;
	return api.get(path);
}

export default {ListProviderGoupTypes, AddProviderGoupType, GetProviderGoupType, UpdateProviderGoupType, DeleteProviderGoupType, GetCustomerOfPrcatice}