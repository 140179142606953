import i18n from "../../../utilities/i18n";

export const DocumentTypesTableBodyOnly = [
        [
            {   "id" : "",
                "name": "name",
                "value": "",
                "type": "string",
                "align": "left",
                "width": "large"
            },
            { "id": "",
                "name": "action",
                "type": "button",
                "align": "center",
                "width": "action_column",
                "dropdown": [
                    { "id": "",
                        "name": "Edit",
                    },
                    { "id": "",
                        "name": "Delete",
                    }
                ]
            },
        ]
    ]

export const DocumentTypesTableObject = {
    "name": "simpleTable",
    "isResize": false,
    "isFixedHead": false,
    "noRecordsMsg" : i18n.t('commons.noRecords'), 
    "tableHeadings": [
        {
            "name": i18n.t("dictionariesPages.documentList.lblDocumentType"),
            "type": "string",
            "width": "large",
            "sort": false
        },
        {
            "name": i18n.t("dictionariesPages.action"),
            "type": "button",
            "width": "action_column",
            "sort": false
        },
    ],
    "tableBodyData" : DocumentTypesTableBodyOnly
}

