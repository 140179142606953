import api from "../../../service/api";

const GetListCustomerDropdown = async (search) =>{
    let path = "user/customer-profile/?list=true&search="+ search;
    return api.get(path);
}

const ListPracticesForSwitching = async(query)=>{
    let path = "patient/practices-for-switching/"+ query;
    return api.get(path);
}

const SwitchPractice = async(data) =>{
    let path = 'user/switch-practice/';
    return api.post(path, data);
}

const LogOut = async() => {
    let path = '/logout/';
    return api.post(path)
}

const SwitchAdmin = async(data) => {
    let path = 'user/switch-admin/';
    return api.post(path, data);
}

const GetUnreadMessageCount = async(practicePK) => {
    let path = 'practice/messages/unread/count/?practice_pk=' + practicePK;
    return api.get(path);
}
const GetUnreadMessage = async(practicePK) => {
    let path = 'practice/messages/unread/?practice_pk=' + practicePK;
    return api.get(path);
}
// task
const GetIncompleteTaskCount = async(practicePK) => {
    let path = 'practice/task/incomplete/count/?practice_pk=' + practicePK;
    return api.get(path);
}
const GetIncompleteTask = async(practicePK) => {
    let path = 'practice/task/incomplete/?practice_pk=' + practicePK;
    return api.get(path);
}

const GetPracticeMenuAndPermissionsOnPracticeSwitch = async (practice) => {
    const path = `user/practice-permissions/?practice_pk=${practice}`
    return await api.get(path)
};

export default {
    GetListCustomerDropdown, ListPracticesForSwitching, SwitchPractice, LogOut, SwitchAdmin, GetUnreadMessageCount, GetUnreadMessage,
    GetIncompleteTask, GetIncompleteTaskCount, GetPracticeMenuAndPermissionsOnPracticeSwitch
};