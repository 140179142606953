import i18n from "../../../utilities/i18n"

const TableHeadData = [
    {
        "name": i18n.t("userPages.referringProvider.labelName"),
        "type": "string",
        "width": "medium",
        "sort": false,
    },
    {
        "name": i18n.t("userPages.referringProvider.labelProviderID"),
        "type": "number",
        "width": "medium",
        "sort": false,
    },
    {
        "name": i18n.t("userPages.referringProvider.labelNPI"),
        "type": "string",
        "width": "medium",
        "sort": false,
    },
    {
        "name": i18n.t("userPages.referringProvider.labelProviderGroup"),
        "type": "string",
        "width": "medium",
        "sort": false,
    },
    {
        "name": i18n.t("dictionariesPages.action"),
        "type": "button",
        "width": "action_column",
        "sort": false,
    },
]

export const TableBodyData = [
    [
        {
            "id": "",
            "name": "full_name",
            "value": "",
            "type": "string",
            "align": "left",
            "width": "medium",
        },
        {
            "id": "",
            "name": "custom_referring_provider_id",
            "value": "",
            "type": "number",
            "align": "center",
            "width": "medium",
        },
        {
            "id": "",
            "name": "npi",
            "value": "",
            "type": "string",
            "align": "center",
            "width": "medium",
        },
        {
            "id": "",
            "obj_name": "referral_provider_group_details",
            "name": "name",
            "value": "",
            "type": "object",
            "align": "center",
            "width": "medium",
        },
        {
            "id": "",
            "name": "action",
            "type": "button",
            "align": "center",
            "width": "action_column",
            "dropdown": [                
                {
                    "name": "Edit"
                },
            ]
        },
    ]
]

export const ReferingProviderTableData = {
    "name": "simpleTable",
    "isResize": true,
    "isFixedHead": true,
    "tableHeadings": TableHeadData,
    "tableBodyData": TableBodyData
}