import React, { useState, useEffect } from "react";
import { useLocation } from 'react-router-dom';

import i18n from "../../utilities/i18n";
import { POST_PAYMENT_PRIMARY_TYPES } from "../../utilities/dictionaryConstants";
import {
  POST_PAYMENT_TYPE_PATIENT_ID,
  POST_PAYMENT_TYPE_INSURANCE_ID,
} from "../../utilities/staticConfigs";

import { PatientPayments } from "./patientPayments";
import InsurancePayments from "./insurancePayments";

import SelectInput from "../commons/input/SelectInput";
import Notify from "../commons/notify";

export const PostPayments = (props) => {
  const location = useLocation();
  const [postPaymentType, setPostPaymentType] = useState(
    POST_PAYMENT_TYPE_INSURANCE_ID
  );
  const [paymentPK, setPaymentPK] = useState("");
  const [insurancePostPayment, setInsurancePostPayment] = useState(false);
  const [patientPaymentPost, setPatientPaymentPost] = useState(false);
  const [patientPaymentPatientPK, setPatientPaymentPatientPK] = useState('');
  const [patientPaymentPostType, setPatientPaymentPostType] = useState(false);

  //start ==== Alert message properties
  const [showNotify, setShowNotify] = useState("hide");
  const [notifyDescription, setNotifyDescription] = useState("");
  const [notifyType, setNotifyType] = useState("success");


  function showNotifyWindow(action, type, desc, age = 3000) {
    if (action === "show") {
      setTimeout(() => {
        setShowNotify("hide");
      }, age);
    }
    setShowNotify(action);
    setNotifyType(type);
    setNotifyDescription(desc);
  }
  //End ====

  function onHandleChange(e) {
    setPostPaymentType(e.target.value);
  }

  
  useEffect(() => {
    if (props.selectedEditPaymentPK) {
      setPostPaymentType(props.selectedEditPaymentType);
      setPaymentPK(props.selectedEditPaymentSubPK);
      if (props.selectedEditPaymentType === 1) {
        setPatientPaymentPost(props.selectedEditPaymentType);
        setPatientPaymentPatientPK(props.selectedEditPtientPK);
        setPatientPaymentPostType(props.selectedEditPatientPostType);
      }
    }
  }, [props.selectedEditPaymentPK]);

  return (
    <React.Fragment>
      <div className={props.updatePayment ? " border-radius-8 " : " border-radius-8 col-md-12"}>
        <Notify
          showNotify={showNotify}
          setShowNotify={setShowNotify}
          notifyDescription={notifyDescription}
          setNotifyType={setNotifyType}
          setNotifyDescription={setNotifyDescription}
          notifyType={notifyType}
        />
        <div className="box basic-info-padding pb-0 payment-box border-radius-8">
          {!paymentPK && postPaymentType === POST_PAYMENT_TYPE_PATIENT_ID ? (
            <div className="row" style={!props.updatePayment ? { paddingTop: 17, paddingLeft: 19 } : { paddingTop: 17, marginLeft: -20 }}>
              <div className="col-3">
                <div className="form-group">
                  <SelectInput
                    name="postPaymentType"
                    data={POST_PAYMENT_PRIMARY_TYPES}
                    value={postPaymentType}
                    onValueChange={onHandleChange}
                    label={i18n.t("payments.post_payments.post_payment_type")}
                    selectOptionRemove={true}
                  />
                </div>
              </div>
            </div>
          ) : (
            !insurancePostPayment &&
            postPaymentType === POST_PAYMENT_TYPE_INSURANCE_ID && (
                <div className="row" style={!props.updatePayment ? { paddingTop: 17, paddingLeft: 19 } : { paddingTop: 17, marginLeft: -20 }}>
                  <div className="col-3">
                  <div className="form-group">
                    <SelectInput
                      name="postPaymentType"
                      data={POST_PAYMENT_PRIMARY_TYPES}
                      value={postPaymentType}
                      onValueChange={onHandleChange}
                      label={i18n.t("payments.post_payments.post_payment_type")}
                      selectOptionRemove={true}
                    />
                  </div>
                </div>
              </div>
            )
          )}
          <div className="row">
            {postPaymentType === POST_PAYMENT_TYPE_PATIENT_ID && (
              <PatientPayments
                showNotifyWindow={showNotifyWindow}
                updatePayment={props.updatePayment}
                setActiveTab={props.setActiveTab}
                setSelectedEditPaymentPK={props.setSelectedEditPaymentPK}
                postPaymentType={postPaymentType}
                paymentPK={paymentPK}
                setPaymentPK={setPaymentPK}
                patientPaymentPost={patientPaymentPost}
                setPatientPaymentPost={setPatientPaymentPost}
                patientPaymentPatientPK={patientPaymentPatientPK}
                patientPaymentPostType={patientPaymentPostType}
                setPatientPaymentPostType={setPatientPaymentPostType}
                setPatientPaymentPatientPK={setPatientPaymentPatientPK}
                selectedClaim={props.selectedClaim}
                claimID={props.claimID}
                customClaimID={props.customClaimID}
                patientObject={location?.state?.patient? location.state.patient : {}}
              ></PatientPayments>
            )}

            {postPaymentType === POST_PAYMENT_TYPE_INSURANCE_ID && (
              <InsurancePayments
                showNotifyWindow={showNotifyWindow}
                onClosePayment={props.onClosePayment}
                updatePayment={props.updatePayment}
                setActiveTab={props.setActiveTab}
                setSelectedEditPaymentPK={props.setSelectedEditPaymentPK}
                insurancePostPayment={insurancePostPayment}
                paymentPK={paymentPK}
                setPaymentPK={setPaymentPK}
                setInsurancePostPayment={setInsurancePostPayment}
                selectedClaim={props.selectedClaim}
                claimID={props.claimID}
                customClaimID={props.customClaimID}
              />
            )}
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};
