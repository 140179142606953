import React from 'react';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import './NoPermission.css';

function NoPermission() {
    return (
        <div className="no-permission-container">
            <div className="icon">
                <ErrorOutlineIcon fontSize="large" color="error" />
            </div>
            <div className="message">
            <h2>Oops, No Permission!</h2>
                <p>Sorry, you do not have permission to access this page or you are trying to access an Invalid page.</p>
                <p>Please contact your administrator for assistance.</p>
            </div>
        </div>
    );
}

export default NoPermission;
