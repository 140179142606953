import i18n from "../../utilities/i18n"

const TableHeadData = [
    {
        "name": i18n.t("interface.facilityNameMatch.table.input_name"),
        "type": "string",
        "width": "medium",
        "sort": false,
    },
    {
        "name": i18n.t("interface.facilityNameMatch.table.matching_name"),
        "type": "string",
        "width": "large",
        "sort": false,
    },
    {
        "name": i18n.t("interface.facilityNameMatch.table.action"),
        "type": "string",
        "width": "action_column",
        "sort": false,
    },
]
const TableBodyData = [
    [
        {
            "id": "",
            "name": "external_facility",
            "value": "",
            "type": "string",
            "align": "left",
            "width": "medium",
        },
        {
            "id": "",
            "name": "location_name",
            "value": "",
            "type": "string",
            "align": "left",
            "width": "large",
        },
        {
            "id": "",
            "name": "Edit",
            "value": "Edit",
            "type": "link",
            "needLink": 'yes',
            "align": "center",
            "width": "action_column",
        },
    ]
]

export const FacilityNameTable = {
    "name": "simpleTable",
    "isResize": false,
    "isFixedHead": false,
    "tableHeadings": TableHeadData,
    "tableBodyData": TableBodyData
}
