import React,{useState, useEffect, useContext} from 'react';
import { Form} from 'react-bootstrap';
import { useLocation } from 'react-router-dom';
import service from './service';
import { SAExTableObject, SAExTableBodyOnly } from './SAExTable';

import CustomizedDialogs from '../../modalWindowComponent/CustomizedDialogs';
import CustomizedSmallDialogs from '../../modalWindowComponent/CustomisedSmallDialog';

import i18n from '../../../utilities/i18n';
import { getStorage } from '../../../utilities/browserStorage'; 
import { checkPermission} from '../../../utilities/commonUtilities';
import { ADD_SUCCESS, ADD_ERROR, UPDATE_SUCCESS, DELETE_SUCCESS} from '../../../utilities/labelConfigs';
import { permission_key_values_practice_dictionaries, super_admin_privileges, super_admin_permission_key_value } from '../../../utilities/permissions';
import { ROUTE_SUPER_DICTIONARIES, ROUTE_PRACTICE_DICTIONARIES, PAGING_END_INDEX, DEFAULT_PAGING_SIZE, ROUTE_SERVICE_EXCEPTIONS} from '../../../utilities/staticConfigs';

import Notify from '../../commons/notify';
import Table from '../../commons/Table/Table'; 
import Pagination from '../../commons/pagination';
import TextInput from '../../commons/input/input';
import BackArrowWithLabel from "../../commons/Back"; 

import LoadingContext from '../../../container/loadingContext';
import CommonButton from '../../commons/Buttons';

const ExceptionList = (props) =>{
    const isAdminModule = getStorage('isAdminModule');
    const location = useLocation();
    const practicePK = getStorage("practice");
    const [showModalWindow, setShowModalWindow] = useState(false);
    const [showDeleteModalWindow, setShowDeleteModalWindow] = useState(false);
    const [exceptionType, setExceptionType] = useState('');
    const [deleteExceptionName, setDeleteExceptionName] = useState('');
    const [deleteExceptionId, setDeleteExceptionId] = useState('');
    const [editExceptionId, setEditExceptionId] = useState('');
    const [exceptionTypeList, setExceptionTypeList] = useState([]);
    const [form_error, setFormError] = useState({'exceptionType':''});
    const [editForm, setEditForm] = useState(false);
    const [header, setHeader] = useState('');
    const [permission, setPermission] = useState(false);
    const [searchValue, setSearchValue] = useState("");

    //start ==== Alert message properties
    const [showNotify,setShowNotify] = useState('hide');
    const [notifyDescription,setNotifyDescription] = useState('');
    const [notifyType,setNotifyType] = useState('success');
    const setShowLoadingBar = useContext(LoadingContext);

    function showNotifyWindow(action,type,desc,age=3000){
        if(action == 'show'){
            setTimeout(()=> {
                setShowNotify('hide');
            },age)
        }
        setShowNotify(action);
        setNotifyType(type);
        setNotifyDescription(desc);
    }
    //End ====

    //Pagination start
    const [totalPage, setTotalPage] = useState(1);
    const [activePage, setActivePage] = useState(1);
    const [startIndex, setStartIndex] = useState(0);
    const [endIndex, setEndIndex] = useState(PAGING_END_INDEX);

    function onPagePrevious() {
      let previousPage = startIndex+1-PAGING_END_INDEX;
      setActivePage(previousPage);

      if(startIndex!==0){ 
          setStartIndex(startIndex - PAGING_END_INDEX);
          setEndIndex(endIndex - PAGING_END_INDEX);
      }
      getExceptionData(DEFAULT_PAGING_SIZE, previousPage, searchValue);          
    }

    function onPageUp(e){
        let page = Number(e.target.id)
        setActivePage(page);
        getExceptionData(DEFAULT_PAGING_SIZE, page, searchValue); 
    }

    function onPageNext() {
        let nextPage = startIndex+1+PAGING_END_INDEX;
        if(endIndex===totalPage || totalPage<=PAGING_END_INDEX){
            setActivePage(nextPage);
            setStartIndex(startIndex);
            setStartIndex(endIndex);
        }else{
          setActivePage(nextPage);
          setStartIndex(startIndex + PAGING_END_INDEX);
          setEndIndex(endIndex + PAGING_END_INDEX);
        }
        getExceptionData(DEFAULT_PAGING_SIZE, nextPage, searchValue);
    }
    //Pagination ends

    function addNew(){
        if (!permission) {
			showNotifyWindow('show','error', i18n.t('errorMessages.permission_error'));
			return;
		}
        setHeader(i18n.t("dictionariesPages.exceptionList.addHeader"));
        setShowModalWindow(true);
    }

    function onHide(){
        setEditForm(false);
    }

    function onDeleteHide(){
        setDeleteExceptionName('');
        setDeleteExceptionId('');
        setShowDeleteModalWindow(false);
    }

    function getExceptionData(pageSize,page, searchString){
        setShowLoadingBar(true);
        let result = service.ListExceptionTypes(pageSize, page, isAdminModule, isAdminModule === 'true' ? '' : practicePK, searchString)
        
        result.then(async response => {
            if (response.data.results !== undefined) {
                setTotalPage(Math.ceil(response.data.count / response.data.page_size));
            }
            setShowLoadingBar(false);
            if(response.data?.results) {
				response.data.results = response.data.results?.map(item => {
					if (item.not_delete === true) {
						item.isDefault = "Yes";
					} else {
						item.isDefault = "No";
					}
					return item;
				});
			}
			
            let rowArray =  getRowExceptionData(response.data.results);
            if(rowArray.length > 0) {
                SAExTableObject.tableBodyData = rowArray;
            }
            else {
                SAExTableObject.tableBodyData = SAExTableBodyOnly;
            }

            setExceptionTypeList(response.data.results);
        });
    }

    /**
     * making table form data
     * @param {*} data 
     * @returns 
     */
    function getRowExceptionData (data) {
        let anArray = [];
        let rowArray = [];
        let newData = Array(data.length).fill(JSON.parse(JSON.stringify(SAExTableObject.tableBodyData[0])));
        newData.map((row, rowIndex) => {
            row.map((col) => {
                let colObject = {
                    ...col,
                    'value': data[rowIndex][col.name],
                    "id": data[rowIndex].id,
                };
                anArray.push(colObject)
            })
            rowArray.push(anArray)
            anArray = [];
        });
        return rowArray;
    }

    useEffect(() => {
        getExceptionData(DEFAULT_PAGING_SIZE, activePage);
        if(location.pathname === ROUTE_SERVICE_EXCEPTIONS){
            setPermission(checkPermission(super_admin_privileges.super_admin_full_privilege,super_admin_permission_key_value.dictionary_add))
        } else {
            setPermission(checkPermission(permission_key_values_practice_dictionaries.practice_dictionaries_sub_module_modify,
                permission_key_values_practice_dictionaries.practice_dictionaries_sub_module_add,
                super_admin_privileges.super_admin_full_privilege))
        }
    }, []);

    function dropDownFunction(id, name) {
        if (!permission) {
            showNotifyWindow('show', 'error', i18n.t('errorMessages.permission_error'));
            return;
        }

        const isDefaultException = exceptionTypeList.some((item) => {
			if (item.id === id && item.not_delete) {
				return true;
			} else {
				return false;
			}
		});
		if (isDefaultException) {
			showNotifyWindow('show', 'error', i18n.t('dictionariesPages.exceptionList.editRestrictionMessage'));
			return;
		}
        if (name.toLowerCase() == 'edit') {
            onEditExceptionType(Number(id));
        } else if (name.toLowerCase() == 'delete') {
            onDeleteExceptionType(Number(id));
        }
    }

    function onEditExceptionType(exceptionId){
        setHeader(i18n.t("dictionariesPages.exceptionList.editHeader"));
        setShowLoadingBar(true);
        let data = service.GetExceptionType(exceptionId, isAdminModule === 'true' ? '' : practicePK);
        data.then(response => {
            setShowLoadingBar(false);
            setShowModalWindow(true);
            setExceptionType(response.data.name);
            setEditForm(true);
            setEditExceptionId(exceptionId);
        });
    }

    function onDeleteExceptionType(exceptionId){
        exceptionTypeList.map((item) => {
            if(item.id == parseInt(exceptionId)){
                setDeleteExceptionName(item.name);
                setDeleteExceptionId(item.id);
                setShowDeleteModalWindow(true);
            }
        });
    }

    function onDeleteAction(){
        setShowLoadingBar(true);
        let data = service.DeleteExceptionType(deleteExceptionId, isAdminModule === 'true' ? '' : practicePK)
        data.then(() => {
            setShowLoadingBar(false);
            setDeleteExceptionName('');
            setDeleteExceptionId('');
            setShowDeleteModalWindow(false);
            showNotifyWindow('show','success',DELETE_SUCCESS);
            getExceptionData(DEFAULT_PAGING_SIZE, activePage,searchValue);
        });
    }

    function resetForm(){
        setExceptionType('');
        setEditForm(false);
        setFormError({
              ...form_error,
              ['exceptionType']: ''
         });

    }

     const handleKeyDown = (e) => {
         if (e.key === "Enter" && searchValue) {
             onSearch(e, searchValue);
         }
     };
      function onSearch(e, searchString) {
          e.preventDefault();
          setActivePage(1);
          setStartIndex(0);
          getExceptionData(DEFAULT_PAGING_SIZE, 1, searchString);
      }

      const onClose = () => {
          setSearchValue("");
          setActivePage(1);
          setStartIndex(0);
          getExceptionData(DEFAULT_PAGING_SIZE, 1);
      };
    function onSaveFormData(e){
        e.preventDefault();

        if(!exceptionType.trim()) {
            setFormError({
                ...form_error,
                ['exceptionType']:'error'
              });
        } else {
            setShowLoadingBar(true);
            // if editForm is true update api is called else Add api is called.
            let data = isAdminModule === 'true' ? {name: exceptionType} : {practice: practicePK, name: exceptionType};
            let result = editForm ? service.UpdateExceptionType(editExceptionId, data) : service.AddExceptionType(data);
        
            result.then(response => {
                setShowLoadingBar(false);
                if(response.status == 201 || response.status == 200){
                    if(response.status == 201) {
                        showNotifyWindow('show','success',ADD_SUCCESS);
                    }
                    else {
                        showNotifyWindow('show','success',UPDATE_SUCCESS);
                    }
                    getExceptionData(DEFAULT_PAGING_SIZE, activePage, searchValue);
                    resetForm();
                    setShowModalWindow(false);
                }else if (response.status === 400 && response.data.record_already_exists !== undefined) {
                    showNotifyWindow('show','error',i18n.t('errorMessages.record_exists'));
                }else{
                    showNotifyWindow('show','error',ADD_ERROR);
                }
            });
        }      
    }

    function onHandleChange(e){
        const {name}  = e.target;
        let value = e.target.value;
        let trimmedValue = '';
        if(e.target.type==="text"){
            trimmedValue = value; 
        }
          if (name == "searchValue") {
              setSearchValue(trimmedValue);
              return;
          }
        if(!trimmedValue){
            setFormError({
              ...form_error,
              [name]: 'error'
            });
        }else{
            setFormError({
              ...form_error,
              [name]: ''
            });
        }
        setExceptionType(trimmedValue);
    }

    function backToDictionaryPage(){
        if (isAdminModule === 'true')
            props.history.push(ROUTE_SUPER_DICTIONARIES);
        else
            props.history.push(ROUTE_PRACTICE_DICTIONARIES);
    }
    
    return (
        <React.Fragment>
            <Notify
                showNotify={showNotify}
                setShowNotify={setShowNotify}
                notifyDescription={notifyDescription}
                setNotifyType={setNotifyType}
                setNotifyDescription={setNotifyDescription}
                notifyType={notifyType}
            />
            <div className="col-md-8">
                <div className="box box-content-white mb-3">
                    <div className="row mr-0 mb-2">
                        <div className="col pl-4 mb-1">
                            <div className={"dataTables_filter"}>
                                <div
                                    className="link dictionaries-back"
                                    onClick={backToDictionaryPage}>
                                    <BackArrowWithLabel
                                        label={i18n.t(
                                            "dictionariesPages.gotoDictionaries"
                                        )}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="box-content ">
                        <div className="common-search-wrapper-style">
                            <div className="input-content-box">
                                <div className="form-group padding-top15 relative">
                                    <TextInput
                                        type="text"
                                        id="searchValue"
                                        name="searchValue"
                                        value={searchValue}
                                        onValueChange={onHandleChange}
                                        label={i18n.t(
                                            "dictionariesPages.exceptionList.lblServiceAuthorizationException"
                                        )}
                                        onKeyDown={handleKeyDown}
                                        labelClassName={"margin-bottom0"}
                                        clearButton={
                                            typeof searchValue == "string" &&
                                            searchValue.length !== 0
                                        }
                                        clearInput={onClose}
                                    />
                                </div>
                            </div>
                            <div className="input-content-box padding-top15">
                                <div className="margin-top18">
                                    <CommonButton
                                        variant="contained"
                                        label="Search"
                                        onClick={(e) =>
                                            onSearch(e, searchValue)
                                        }
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="box box-content-white">
                    <div className="box-content">
                        <div className="alignRight margin-bottom3">
                            <CommonButton
                                variant="contained"
                                onClick={addNew}
                                label={i18n.t("buttons.addNew")}
                            />
                        </div>
                        <div className="table-responsive">
                            <Table
                                tableObject={SAExTableObject}
                                dropDownFunction={dropDownFunction}
                            />
                        </div>
                        <div className="mt-2 mb-2">
                            <Pagination
                                totalPage={totalPage}
                                activePage={activePage}
                                startIndex={startIndex}
                                endIndex={endIndex}
                                onPagePrevious={onPagePrevious}
                                onPageUp={onPageUp}
                                onPageNext={onPageNext}
                            />
                        </div>
                    </div>
                </div>
            </div>
            <CustomizedDialogs
                showModal={showModalWindow}
                type="save"
                header={header}
                setShowModalWindow={setShowModalWindow}
                resetForm={resetForm}
                onHide={onHide}>
                <Form
                    id="form_dataEntry"
                    onSubmit={(e) => onSaveFormData(e)}
                    encType="multipart/form-data">
                    <Form.Group>
                        <div className="row">
                            <div className="col-md-12">
                                <div className="form-group padding-top15">
                                    <TextInput
                                        type="text"
                                        id="exceptionType"
                                        name="exceptionType"
                                        label={i18n.t(
                                            "dictionariesPages.exceptionList.lblServiceAuthorizationException"
                                        )}
                                        onValueChange={onHandleChange}
                                        defaultClassName={
                                            form_error.exceptionType
                                                ? "input-error"
                                                : ""
                                        }
                                        value={exceptionType}
                                    />
                                </div>
                            </div>
                        </div>
                    </Form.Group>
                </Form>
            </CustomizedDialogs>
            <CustomizedSmallDialogs
                showModal={showDeleteModalWindow}
                header={i18n.t("commons.confirmDelete")}
                type="delete"
                deleteItem={onDeleteAction}
                resetForm={resetForm}
                onHide={onDeleteHide}
                setShowModalWindow={setShowDeleteModalWindow}>
                {i18n.t("dictionariesPages.exceptionList.deletConfirmation") +
                    "'" +
                    deleteExceptionName +
                    "'?"}
            </CustomizedSmallDialogs>
        </React.Fragment>
    );
}
export default ExceptionList