import { List } from "@mui/material";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { setItemOpenStatus } from "../../../store/reports/builder/allReportListSlice";
import {
  ROUTE_USER_REPORTS_BUILDER_EDIT,
  ROUTE_USER_REPORTS_BUILDER_NEW_FROM_TEMP,
} from "../../../utilities/staticConfigs";
import ReportCategoryList from "./ReportCategoryList";

const ReportSelectorModal = ({ isEdit }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const reports = useSelector((state) => state.allReports.reports);
  const onReportItemClickHandler = (report) => {
    if (isEdit) {
      history.push(ROUTE_USER_REPORTS_BUILDER_EDIT, { report: report });
    } else {
      history.push(ROUTE_USER_REPORTS_BUILDER_NEW_FROM_TEMP, {
        report: report,
      });
    }
  };
  const onItemClick = (category) => {
    dispatch(setItemOpenStatus({ report: category }));
  };
  return (
    <List dense={false} component={"nav"}>
      {reports.length !== 0 ? (
        reports.map((category, index) => (
          <ReportCategoryList
            key={index}
            category={category}
            onCategoryClick={onItemClick}
            onReportItemClick={onReportItemClickHandler}
          />
        ))
      ) : (
        <div>No Reports Available</div>
      )}
    </List>
  );
};

export default ReportSelectorModal;
