import i18n from "../../../utilities/i18n";


const TableHeadData = [
    {
        "name": i18n.t("userPages.practices.labelPracticeName"),
        "type": "string",
        "width": "medium",
        "sort": false,
    },
    {
        "name": i18n.t("userPages.practices.labelPracticeID"),
        "type": "number",
        "width": "small",
        "sort": false,
    },
    {
        "name": i18n.t("userPages.practices.labelAddress"),
        "type": "string",
        "width": "medium",
        "sort": false,
    },
    {
        "name": i18n.t("userPages.practices.labelNPI"),
        "type": "string",
        "width": "small",
        "sort": false,
    },
    {
        "name": i18n.t("dictionariesPages.action"),
        "type": "button",
        "width": "action_column",
        "sort": false,
    },
];

const TableBodyData = [
    [
        {
            "id": "",
            "name": "name",
            "value": "",
            "type": "string",
            "align": "left",
            "width": "medium",
        },
        {
            "id": "",
            "name": "custom_practice_id",
            "value": "",
            "type": "number",
            "align": "center",
            "width": "small",
        },
        {
            "id": "",
            "name": "prctice_address",
            "value": "",
            "type": "string",
            "align": "left",
            "width": "large",
        },
        {
            "id": "",
            "name": "npi",
            "value": "",
            "type": "string",
            "align": "left",
            "width": "medium",
        },
        {
            "id": "",
            "name": "action",
            "type": "button",
            "align": "center",
            "width": "action_column",
            "dropdown": [
                {
                    "name": "Edit"
                }
            ]
        },
    ]
];



export const PracticeTableData = {
    "name": "simpleTable",
    "isResize": false,
    "isFixedHead": false,
    "tableHeadings": TableHeadData,
    "tableBodyData": TableBodyData
}

const LocationTableHeadData = [
    {
        "name": i18n.t("userPages.practices.locations.labellocationName"),
        "type": "string",
        "width": "medium",
        "sort": false,
    },
    {
        "name": i18n.t("userPages.practices.locations.labelAddress"),
        "type": "string",
        "width": "medium",
        "sort": false,
    },
    {
        "name": i18n.t("userPages.practices.locations.labelPhone"),
        "type": "string",
        "width": "small",
        "sort": false,
    },
    {
        "name": i18n.t("dictionariesPages.action"),
        "type": "button",
        "width": "small",
        "sort": false,
    },
];

const LocationTableBodyData = [
    [
        {
            "id": "",
            "name": "name",
            "value": "",
            "type": "string",
            "align": "left",
            "width": "medium",
        },
        {
            "id": "",
            "name": "address",
            "value": "",
            "type": "string",
            "align": "left",
            "width": "medium",
        },
        {
            "id": "",
            "name": "phone",
            "value": "",
            "type": "phone",
            "align": "center",
            "width": "small",
        },
        {
            "id": "",
            "name": "action",
            "value": "",
            "type": "button",
            "align": "center",
            "width": "action_column",
            "dropdown": [
                {
                    "name": "Edit"
                }
            ]
        },
    ]
]
export const PracticeLocationTableData = {
    "name": "simpleTable",
    "isResize": false,
    "isFixedHead": false,
    "tableHeadings": LocationTableHeadData,
    "tableBodyData": LocationTableBodyData
}
