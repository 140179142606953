import i18n from "../../../utilities/i18n";
export const colVisitID = i18n.t("reports.visit_id");
export const colClaimID = i18n.t("reports.claim_id");
export const colAccessionNumber = i18n.t("reports.accession_number");
export const colPatientID = i18n.t("reports.patient_id");
export const colPatientFirstName = i18n.t("reports.firstName");
export const colPatientLastName = i18n.t("reports.lastName");
export const colPatientMiddleName = i18n.t("reports.middleName");
export const colPatientGender = i18n.t("reports.gender");
export const colDateOfBirth = i18n.t("reports.DOB");
export const coldateOfEntry = i18n.t("reports.dateOfEntry");
export const colfirstBilledDate = i18n.t("reports.firstBilledDate");
export const collastBilledDate = i18n.t("reports.lastBilledDate");
export const coldateOfServiceFrom = i18n.t("reports.dateOfServiceFrom");
export const coldateOfServiceTo = i18n.t("reports.dateOfServiceTo");
export const colrefferingProvider = i18n.t("reports.refferingProvider");
export const colrefferingProviderNPI = i18n.t("reports.refferingProviderNPI");
export const colrenderingProvider = i18n.t("reports.renderingProvider");
export const colrenderingProviderNPI = i18n.t("reports.renderingProviderNPI");
export const colbillingProvider = i18n.t("reports.billingProvider");
export const colbillingProviderNPI = i18n.t("reports.billingProviderNPI");
export const colServiceLocation = i18n.t("reports.serviceLocation");
export const colCPTCODE= i18n.t("reports.cptcode");
export const colUnits= i18n.t("reports.units");
export const colmodifier1= i18n.t("reports.modifier1");
export const colmodifier2= i18n.t("reports.modifier2");
export const colmodifier3= i18n.t("reports.modifier3");
export const colmodifier4= i18n.t("reports.modifier4");
export const colICD_list= i18n.t("reports.ICD_list");
export const colcpt_charge_amount= i18n.t("reports.cpt_charge_amount");
export const colInsurancePayment= i18n.t("reports.insurancePayment");
export const colPatientPayment= i18n.t("reports.patientPayments");
export const colTotalPayment= i18n.t("reports.totalPayments");
export const colInsuranceAdjustments= i18n.t("reports.insuranceAdjustments");
export const colPatientWriteOff= i18n.t("reports.patientWriteoff");
export const colTotalAdjustments= i18n.t("reports.totalAdjustments");
export const colInsuranceBalance= i18n.t("reports.insuranceBalance");
export const colPatientBalance= i18n.t("reports.patientBalance");
export const colTotalBalance= i18n.t("reports.totalBalance");
export const colPayerType= i18n.t("reports.currentPayerType");
export const colcurrent_payer_name= i18n.t("reports.current_payer_name");
export const colcurrent_payer_id= i18n.t("reports.current_payer_id");
export const colcurrent_payer_policy_id= i18n.t("reports.current_payer_policy_id");
export const colcurrent_group_no= i18n.t("reports.current_group_no");
export const colprimary_payer_name= i18n.t("reports.primary_payer_name");
export const colprimary_payer_id= i18n.t("reports.primary_payer_id");
export const colprimary_policy_id= i18n.t("reports.primary_policy_id");
export const colprimary_group_no= i18n.t("reports.primary_group_no");
export const colsecondary_payer_name= i18n.t("reports.secondary_payer_name");
export const colsecondary_payer_id= i18n.t("reports.secondary_payer_id");
export const colsecondary_policy_id= i18n.t("reports.secondary_policy_id");
export const colsecondary_group_no= i18n.t("reports.secondary_group_no");
export const colagefromDOS= i18n.t("reports.ageFromDos");
export const colagefromDateofEntry= i18n.t("reports.ageFromDateofEntry");
export const colagefromfirstbilleddate= i18n.t("reports.agefromfirstbillDate");
export const colagefromlastbilleddate= i18n.t("reports.agefromlstbillDate");
export const colClaimStatus= i18n.t("reports.claimStatus");
export const colClaimSubStatus= i18n.t("reports.claimSubStatus");
export const colPatientNote= i18n.t("reports.patientNote");
export const colPatientNoteEnteredBy= i18n.t("reports.lastPatientNoteEnteredBy");
export const colClaimNote= i18n.t("reports.claimNote");
export const colClaimNoteEnteredBy= i18n.t("reports.lastClaimNoteEnteredBy");
export const colClaimEnteredBy= i18n.t("reports.claimEnteredBy");
export const colLabSaleReport= i18n.t("reports.lab_sales_report");
export const colClinincSiteName= i18n.t("reports.clinic_site_name");
export const colClaimServiceType = i18n.t("reports.claim_service_type");
export const colclaimPos = i18n.t("reports.pos");
export const colclaimTos = i18n.t("reports.tos");


export   const ReportTableHead = [
    colClaimID,
    colAccessionNumber,
    colPatientID,
    colVisitID,
    colPatientFirstName,
    colPatientLastName,
    colPatientMiddleName,
    colPatientGender,
    colDateOfBirth,
    coldateOfEntry,
    coldateOfServiceFrom,
    coldateOfServiceTo,
    collastBilledDate,
    colfirstBilledDate,
    colrefferingProvider,
    colrefferingProviderNPI,
    colrenderingProvider,
    colrenderingProviderNPI,
    colbillingProvider,
    colbillingProviderNPI,
    colServiceLocation,
    colCPTCODE,
    colUnits,
    colmodifier1,
    colmodifier2,
    colmodifier3,
    colmodifier4,
    colICD_list,
    colcpt_charge_amount,
    colInsurancePayment,
    colPatientPayment,
    colTotalPayment,
    colInsuranceAdjustments,
    colPatientWriteOff,
    colTotalAdjustments,
    colInsuranceBalance,
    colPatientBalance,
    colTotalBalance,
    colPayerType,
    colcurrent_payer_name,
    colcurrent_payer_id,
    colcurrent_payer_policy_id,
    colcurrent_group_no,
    colprimary_payer_name,
    colprimary_payer_id,
    colprimary_policy_id,
    colprimary_group_no,
    colsecondary_payer_name,
    colsecondary_payer_id,
    colsecondary_policy_id,
    colsecondary_group_no,
    colagefromDOS,
    colagefromDateofEntry,
    colagefromfirstbilleddate,
    colagefromlastbilleddate,
    colClaimStatus,
    colClaimSubStatus,
    colPatientNote,
    colPatientNoteEnteredBy,
    colClaimNote,
    colClaimNoteEnteredBy,
    colClaimEnteredBy,
    colLabSaleReport,
    colClinincSiteName,
    colClaimServiceType,
    colclaimPos,
    colclaimTos
];

export const reportFieldArray=[
    colClaimID,
    colAccessionNumber,
    colPatientID,
    colVisitID,
    colPatientFirstName,
    colPatientLastName,
    colPatientMiddleName,
    colPatientGender,
    colDateOfBirth,
    coldateOfEntry,
    coldateOfServiceFrom,
    coldateOfServiceTo,
    collastBilledDate,
    colfirstBilledDate,
    colrefferingProvider,
    colrefferingProviderNPI,
    colrenderingProvider,
    colrenderingProviderNPI,
    colbillingProvider,
    colbillingProviderNPI,
    colServiceLocation,
    colCPTCODE,
    colUnits,
    colmodifier1,
    colmodifier2,
    colmodifier3,
    colmodifier4,
    colICD_list,
    colcpt_charge_amount,
    colInsurancePayment,
    colPatientPayment,
    colTotalPayment,
    colInsuranceAdjustments,
    colPatientWriteOff,
    colTotalAdjustments,
    colInsuranceBalance,
    colPatientBalance,
    colTotalBalance,
    colPayerType,
    colcurrent_payer_name,
    colcurrent_payer_id,
    colcurrent_payer_policy_id,
    colcurrent_group_no,
    colprimary_payer_name,
    colprimary_payer_id,
    colprimary_policy_id,
    colprimary_group_no,
    colsecondary_payer_name,
    colsecondary_payer_id,
    colsecondary_policy_id,
    colsecondary_group_no,
    colagefromDOS,
    colagefromDateofEntry,
    colagefromfirstbilleddate,
    colagefromlastbilleddate,
    colClaimStatus,
    colClaimSubStatus,
    colPatientNote,
    colPatientNoteEnteredBy,
    colClaimNote,
    colClaimNoteEnteredBy,
    colClaimEnteredBy,
    colLabSaleReport,
    colClinincSiteName,
    colClaimServiceType,
    colclaimPos,
    colclaimTos
    
]
