import api from '../../../service/api'

const GetListPaymentSourceCodes = async (pageSize, page, searchValue) => {
	let path = 'super-admin/payment-source/?page_size=' + pageSize + '&page=' + page;
	if (searchValue) {
        path += `&search=${searchValue}`;
    }
	return await api.get(path)
}

const GetPaymentSourceCode = async (id) => {
	let path = 'super-admin/payment-source/' + id
	return await api.get(path);
}

const UpdatePaymentSourceCode = async (id, data) => {
	let path = 'super-admin/payment-source/' + id
	return api.put(path, data);
}

const AddPaymentSourceCode = async (data) => {
	let path = 'super-admin/payment-source/'
	return await api.post(path, data);
}

const DeletePaymentSourceCode = async (id) => {
	let path = 'super-admin/payment-source/' + id
	return await api.destroy(path);
}


export default {
	GetListPaymentSourceCodes,
	AddPaymentSourceCode,
	GetPaymentSourceCode,
	DeletePaymentSourceCode,
	UpdatePaymentSourceCode
}