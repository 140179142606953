import React from 'react';
import './style.css';
import { Link } from 'react-router-dom';
import { PRACTICE_DICT_TYPE_PATIENT_DEMOGRAPHICS, PRACTICE_DICT_TYPE_GENERAL, PRACTICE_DICT_TYPE_BILLING, PRACTICE_DICT_TYPE_APPOINTMENTS} from '../../../utilities/dictionaryConstants';

const PracticeDictionariesListAll = () => {  
    const arrDictTypes_1 = [ PRACTICE_DICT_TYPE_PATIENT_DEMOGRAPHICS, PRACTICE_DICT_TYPE_GENERAL];
    const arrDictTypes_2 = [ PRACTICE_DICT_TYPE_BILLING, PRACTICE_DICT_TYPE_APPOINTMENTS ];

    return (
        <React.Fragment>
            <div className="col-md-12 ">
                <div className="dictionary-box-round border-radius-15 margin-right15">
                    <div className="box dictionaries-list border-radius-15"> 
                        <div className="dictionary-column dictionary-box">
                            {Array.isArray(arrDictTypes_1) && arrDictTypes_1.length && 
                                arrDictTypes_1.map((mainItem, index)  => { 
                                    return( 
                                        <div className="dictionary-type" key={index}>
                                            <div className="dictionary-caption" data-testid='dictionary-caption-1'>{mainItem.dictType}</div>

                                            {Array.isArray(mainItem.dictItems) && mainItem.dictItems.length && 
                                                    mainItem.dictItems.map((eachItem, index1)  => {
                                                        
                                                if(eachItem.routeLink.length > 1){
                                                    return( 
                                                        <div className="dictionary-item" key={index1} data-testid="dictionary-item-1">
                                                            <Link data-testid='dictionary-item1-link' to={eachItem.routeLink}>{eachItem.name}</Link>
                                                        </div>
                                                        )
                                                }else{
                                                    return( 
                                                        <div className="dictionary-item" key={index1} data-testid="dictionary-item-1">
                                                            <div className="dictionary-non-link-text">{eachItem.name}</div>
                                                        </div>
                                                    )
                                                } 
                                            })
                                            }
                                        </div>
                                    )
                                })
                            }  
                        </div>
                    
                        <div className="dictionary-column">
                            {Array.isArray(arrDictTypes_2) && arrDictTypes_2.length && 
                                arrDictTypes_2.map((mainItem, index2)  => {
                                    return( 
                                        <div className="dictionary-type" key={index2}>
                                            <div className="dictionary-caption" data-testid='dictionary-caption-2'>{mainItem.dictType}</div>

                                            {Array.isArray(mainItem.dictItems) && mainItem.dictItems.length && 
                                                    mainItem.dictItems.map((eachItem, index3)  => {
                                                        if(eachItem.routeLink.length > 1){
                                                            return( 
                                                                <div className="dictionary-item" key={index3} data-testid='dictionary-item-2'>
                                                                    <Link data-testid='dictionary-item2-link' to={eachItem.routeLink}>{eachItem.name}</Link>
                                                                </div>
                                                                )
                                                        }else{
                                                            return( 
                                                                <div className="dictionary-item" key={index3} data-testid="dictionary-item-2">
                                                                    <div className="dictionary-non-link-text">{eachItem.name}</div>
                                                                </div>
                                                            )
                                                        } 
                                                    })
                                            }
                                        </div>
                                    )
                                })
                            }  
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment> 
    )
}

export default PracticeDictionariesListAll; 