import api from '../../../service/api'

const ListAccidentTypes = async(pageSize, page, claimPK,searchValue) =>{
	let path = 'super-admin/accident-type/?page_size='+ pageSize +'&page='+ page;
	if (claimPK)
		path += '&opened_claim_pk='+ claimPK;
	if (searchValue) {
        path += `&search=${searchValue}`;
    }
	return api.get(path);
}

const AddAccidentType = async(data) =>{
	let path = 'super-admin/accident-type/'
	return api.post(path,data);
}
const GetAccidentType = async(accidentId) =>{
	let path = 'super-admin/accident-type/' + accidentId
	return api.get(path);
}

const UpdateAccidentType = async(accidentId, data) =>{
	let path = 'super-admin/accident-type/' + accidentId
	return api.put(path,data);
}

const DeleteAccidentType = async(accidentId) =>{
	let path = 'super-admin/accident-type/' + accidentId
	return api.destroy(path);
}

export default {ListAccidentTypes, AddAccidentType, GetAccidentType, UpdateAccidentType, DeleteAccidentType}