import i18n from "../../utilities/i18n"

export const ViewPaymentsTableBodyOnly = [
        [
            {   "id" : "",
                "name": "custom_payment_id",
                "value": "",
                "type": "string",
                "align": "center",
                "width": "small",
                "needLink": true
            },
            {   "id" : "",
                "name": "payment_from",
                "value": "",
                "type": "string",
                "align": "center",
                "width": "small"
            },
            {   "id" : "",
                "name": "payer_name",
                "value": "",
                "type": "string",
                "align": "left",
                "width": "large"
            },
            {   "id" : "",
                "name": "payment_date",
                "value": "",
                "type": "Date",
                "align": "center",
                "width": "small",
                "needDateFormat": "yes"
            },
            {   "id" : "",
                "name": "entered_date",
                "value": "",
                "type": "Date",
                "align": "center",
                "width": "small",
                "needDateFormat": "yes"
            },
            {   "id" : "",
                "name": "payment_amount",
                "value": "",
                "type": "currency",
                "align": "right",
                "width": "small"
            },
            {   "id" : "",
                "name": "applied",
                "value": "",
                "type": "currency",
                "align": "right",
                "width": "small"
            },
            {   "id" : "",
                "name": "level_adjustments",
                "value": "",
                "type": "currency",
                "align": "right",
                "width": "small"
            },
            {   "id" : "",
                "name": "un_applied",
                "value": "",
                "type": "currency",
                "align": "right",
                "width": "small"
            },
            
        ]
    ]

export const ViewPaymentsTableObject = {
    "name": "simpleTable",
    "isResize": false,
    "isFixedHead": false,
    "noRecordsMsg" : i18n.t('commons.noRecords'), 
    "tableHeadings": [
        {
            "name": i18n.t("payments.viewPayments.table.payment_id"),
            "type": "string",
            "width": "small",
            "sort": false
        },
        {
            "name": i18n.t("payments.viewPayments.table.payment_from"),
            "type": "string",
            "width": "small",
            "sort": false
        },
        {
            "name": i18n.t("payments.viewPayments.table.payer_name"),
            "type": "string",
            "width": "large",
            "sort": false
        },
        {
            "name": i18n.t("payments.viewPayments.table.payment_date"),
            "type": "string",
            "width": "small",
            "sort": false
        },
        {
            "name": i18n.t("payments.viewPayments.table.payment_entered_date"),
            "type": "string",
            "width": "small",
            "sort": false
        },  
        {
            "name": i18n.t("payments.viewPayments.table.payment"),
            "type": "string",
            "width": "small",
            "sort": false
        },
        {
            "name": i18n.t("payments.viewPayments.table.applied"),
            "type": "string",
            "width": "small",
            "sort": false
        },
        {
            "name": i18n.t("payments.viewPayments.table.adjustments"),
            "type": "string",
            "width": "small",
            "sort": false
        },
        {
            "name": i18n.t("payments.viewPayments.table.unApplied"),
            "type": "string",
            "width": "small",
            "sort": false
        },
    ],
    "tableBodyData" : ViewPaymentsTableBodyOnly
}

