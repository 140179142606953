import i18n from "../../../utilities/i18n";

const TableHeadData = [
    {
        "name": i18n.t("dictionariesPages.dictInsuranceCompanies.payerID"),
        "type": "string",
        "width": "small",
        "sort": true,
        "initialOrdering": true,
        "orderType": ''
    },
    {
        "name": i18n.t("dictionariesPages.dictInsuranceCompanies.lblInsuranceCompany"),
        "type": "number",
        "width": "medium",
        "sort": true,
        "initialOrdering": true,
        "orderType": ''
    },
    {
        "name": i18n.t("dictionariesPages.dictInsuranceCompanies.ampsPayerId"),
        "type": "string",
        "width": "small",
        "sort": false,
    },
    {
        "name": i18n.t("dictionariesPages.dictInsuranceCompanies.professionalClaims"),
        "type": "string",
        "width": "small",
        "sort": false,
    },
    {
        "name": i18n.t("dictionariesPages.dictInsuranceCompanies.institutionalClaims"),
        "type": "string",
        "width": "small",
        "sort": false,
    },
    {
        "name": i18n.t("dictionariesPages.dictInsuranceCompanies.eligibility"),
        "type": "string",
        "width": "small",
        "sort": false,
    },
    {
        "name": i18n.t("dictionariesPages.dictInsuranceCompanies.remits"),
        "type": "string",
        "width": "small",
        "sort": false,
    },
    {
        "name": i18n.t("dictionariesPages.dictInsuranceCompanies.claimsMonitoring"),
        "type": "string",
        "width": "small",
        "sort": false,
    },
    {
        "name": i18n.t("dictionariesPages.dictInsuranceCompanies.claimsAttachment"),
        "type": "string",
        "width": "small",
        "sort": false,
    },
    {
        "name": i18n.t("dictionariesPages.action"),
        "type": "button",
        "width": "action_column",
        "sort": false,
    },
];

const TableBodyData = [
    [
        {
            "id": "",
            "name": "payer_id",
            "value": "",
            "type": "string",
            "align": "left",
            "width": "small",
        },
        {
            "id": "",
            "name": "name",
            "value": "",
            "type": "string",
            "align": "left",
            "width": "medium",
        },
        {
            "id": "",
            "name": "apms_payer_id",
            "value": "",
            "type": "string",
            "align": "left",
            "width": "small",
        },
        {
            "id": "",
            "name": "professional_claims_value",
            "value": "",
            "type": "string",
            "align": "left",
            "width": "small",
        },
        {
            "id": "",
            "name": "institutional_claims_value",
            "value": "",
            "type": "string",
            "align": "left",
            "width": "small",
        },
        {
            "id": "",
            "name": "eligibility_value",
            "value": "",
            "type": "string",
            "align": "left",
            "width": "small",
        },
        {
            "id": "",
            "name": "remits_value",
            "value": "",
            "type": "string",
            "align": "left",
            "width": "small",
        },
        {
            "id": "",
            "name": "claims_monitoring_value",
            "value": "",
            "type": "string",
            "align": "left",
            "width": "small",
        },
        {
            "id": "",
            "name": "claims_attachments_value",
            "value": "",
            "type": "string",
            "align": "left",
            "width": "small",
        },
        {
            "id": "",
            "name": "action",
            "type": "button",
            "align": "center",
            "width": "action_column",
            "dropdown": [
                {
                    "name": "Edit"
                },
                {
                    "name": "Delete"
                },
            ]
        },
    ]
]

export const InsuranceCompanyTableData = {
    "name": "simpleTable",
    "isResize": false,
    "isFixedHead": false,
    "tableHeadings": TableHeadData,
    "tableBodyData": TableBodyData
};

// **************** TABLE FOR 'CREATE FROM CLEARING HOUSE CONNECTION' *************** 

const InsAddTableHeadData = [
    {
        "name": i18n.t("dictionariesPages.dictInsuranceCompanies_Add.table.apms_payer_id"),
        "type": "string",
        "width": "small",
        "sort": false,
        "orderType": ''
    },
    {
        "name": i18n.t("dictionariesPages.dictInsuranceCompanies_Add.table.payer_name"),
        "type": "number",
        "width": "medium",
        "sort": false,
        "orderType": ''
    },
    {
        "name": i18n.t("dictionariesPages.dictInsuranceCompanies_Add.table.waystar_payer_id"),
        "type": "string",
        "width": "small",
        "sort": false,
    },
    {
        "name": i18n.t("dictionariesPages.dictInsuranceCompanies_Add.table.professional_claims"),
        "type": "string",
        "width": "small",
        "sort": false,
    },  
    {
        "name": i18n.t("dictionariesPages.dictInsuranceCompanies_Add.table.institutional_claims"),
        "type": "string",
        "width": "small",
        "sort": false,
    },
    {
        "name": i18n.t("dictionariesPages.dictInsuranceCompanies_Add.table.remits"),
        "type": "string",
        "width": "small",
        "sort": false,
    }
];

const InsAddTableBodyData = [ 
    [
        {
            "id": "",
            "name": "apms_payer_id",
            "value": "",
            "type": "string",
            "align": "left",
            "width": "small",
        },
        {
            "id": "",
            "name": "name",
            "value": "",
            "type": "string",
            "align": "left",
            "width": "medium",
        },
        {
            "id": "",
            "name": "payer_id",
            "value": "",
            "type": "string",
            "align": "left",
            "width": "small",
        },
        {
            "id": "",
            "name": "professional_claims_value",
            "value": "",
            "type": "string",
            "align": "left",
            "width": "small",
        },
        {
            "id": "",
            "name": "institutional_claims_value",
            "value": "",
            "type": "string",
            "align": "left",
            "width": "small",
        },
        {
            "id": "",
            "name": "remits_value",
            "value": "",
            "type": "string",
            "align": "left",
            "width": "small",
        },
    ]
]

export const InsAddTableData = {
    "name": "clickOnRowsTable",
    "isResize": false,
    "isFixedHead": false,
    "tableHeadings": InsAddTableHeadData, 
    "tableBodyData": InsAddTableBodyData
};