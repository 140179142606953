import api from '../../../service/api'

const ListReferringProviderGroups = async (pageSize, page, practicePK) => {
	let path = 'user/referring-provider-group/?page_size=' + pageSize + '&page=' + page + '&practice_pk=' + practicePK;
	return api.get(path);
}

const ListReferringProviders = async (pageSize, page, practicePK, status) => {
	let path = 'user/referring-provider/?page_size=' + pageSize + '&page=' + page + '&practice_pk=' + practicePK;
	if (status)
		path += '&status=' + status;
	return api.get(path);
}

const GetReferringProvider = async (referringID) => {
	let path = 'user/referring-provider/' + referringID;
	return api.get(path);
}

const AddReferringProvider = async (data) => {
	let path = 'user/referring-provider/';
	return api.post(path, data);
}

const UpdateReferringProvider = async (referringID, data) => {
	let path = 'user/referring-provider/' + referringID;
	return api.put(path, data);
}

const ListTaxonomies = async (pageSize, page) => {
	let path = 'super-admin/taxonomy/?page_size=' + pageSize + '&page=' + page;
	return api.get(path);
}

const ListStates = async (pageSize, page) => {
	let path = 'super-admin/us-states/?page_size=' + pageSize + '&page=' + page;
	return api.get(path);
}
const exportReferingProvider = async (type, practicePK, status) => {
	const path = 'user/referring-provider/?practice_pk=' + practicePK + '&export=true&export_type=' + type + '&status=' + status;
	let result = null;
	if (type === 'xls')
		result = api.getXLS(path);
	else
		result = api.get(path);
	return result;
}
export default {
	exportReferingProvider,
	ListReferringProviderGroups, ListReferringProviders, GetReferringProvider,
	AddReferringProvider, UpdateReferringProvider, ListTaxonomies, ListStates
}